import React, { useEffect, useState } from "react";
import _ from "lodash";
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { zcs } from "zcs";
import {
  emailRegex,
  maxDOB,
  minDOB,
  populateYears,
  genderOptions,
  maritalOptions,
  getCityStateFromZip,
} from "../../../utils/appConstants";
import { getStateName, stateArray } from "../../../utils/getUSStateName";
import { changeDateToUtcIsoString } from "../../utils";
import { addNewMembers } from "../../../api/membersApi";
import { getAllClients } from "../../../api/clientsApi";
import Loader from "../../Loader/Loader";

import "./AddMembers.css";

const ZCS = zcs();

export default function AddMembers() {
  const [loading, setLoading] = useState(true);
  const [clientOptions, setClientOptions] = useState([]);
  const [programOptions, setProgramOptions] = useState([]);
  const [planOptions, setPlanOptions] = useState([]);
  const [yearOptions, setYearOptions] = useState([]);
  const [allMappingData, setAllMappingData] = useState([]);
  const [planValue, setPlanValue] = useState();
  const [programValue, setProgramValue] = useState();
  const [clientValue, setClientValue] = useState();
  const [yearValue, setYearValue] = useState();
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    // watch,
    setValue,
    reset,
  } = useForm(
    {
      mode: "all",
    },
    {
      defaultValues: {
        age: 0,
        // state: "",
      },
    },
  );

  const getMemberApiData = async () => {
    await getAllClients()
      .then((response) => {
        setLoading(false);
        setAllMappingData(response?.data?.data.map((data) => data));
      })
      .catch((error) => {
        return;
      });
  };

  // const handleClientChange = (e) => {
  //   /* get particular object based on change value */
  //   setClientValue(e?.target?.value);
  //   let selectedClientBasedObjectValue = _.find(allMappingData, {
  //     clientName: e?.target?.value,
  //   });
  //   setPlanOptions([selectedClientBasedObjectValue]);
  //   setProgramOptions(selectedClientBasedObjectValue?.program);
  //   setYearOptions(selectedClientBasedObjectValue?.yearList);
  // };

  const handlePlanChange = (e) => {
    if (!_.isEmpty(e?.target?.value)) {
      setPlanValue(e?.target?.value);
      let selectedClientBasedObjectValue = _.find(allMappingData, {
        plan: e?.target?.value,
      });
      setProgramOptions(selectedClientBasedObjectValue?.program);
    } else {
      setProgramOptions([]);
    }
  };

  const handleProgramChange = (e) => {
    setProgramValue(e?.target?.value);
    if (!_.isEmpty(e?.target?.value)) {
      let yearList = _.find(programOptions, {
        name: e?.target?.value,
      });
      setYearOptions((yearList?.yearList).reverse());
    } else {
      setYearOptions([]);
    }
  };

  const handleYearChange = (e) => {
    setYearValue(e?.target?.value);
  };

  useEffect(() => {
    getMemberApiData();
    return () => {
      /* this now gets called when the component unmounts */
    };
  }, []);

  const stateOptions = Object.keys(stateArray).map((stateCode) => {
    return getStateName(stateCode).toUpperCase();
    // return {
    //   shortCode:stateCode,
    //   fullName:getStateName(stateCode).toUpperCase()
    // }
  });
  const dialogBtn = {
    width: "131px",
    height: "32px",
    flexShrink: "0",
    borderRadius: "5px",
    background: "var(--mainTheme850)",
    marginLeft: "10px",
    color: "white",
    "&:hover": {
      boxShadow: 8,
      background: "var(--mainTheme)",
    },
  };

  /*

  this code can be used for updating one field based on value of another field

  const dobValue = watch("dob");
  useEffect(() => {
    if (dobValue) {
      const dateSelected = dobValue.date();
      const ageComputed = calculateAge(dobValue);
      setValue("age", ageComputed, { shouldDirty: true });
    }
  }, [dobValue]);

  */

  const calculateAge = (birthdate) => {
    const today = new Date();
    const birthDate = new Date(birthdate);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age;
  };

  const createMembers = (newMemberData) => {
    setLoading(true);
    toast.promise(addNewMembers(newMemberData), {
      loading: "Adding Members Data",
      success: (data) => {
        setLoading(false);
        reset({
          dob: null,
          termDate: null,
          effectiveDate: null,
          planValue: "",
          programValue: "",
          clientValue: "",
          yearValue: "",
          value: "",
        });
        reset();
        return `${data.data.msg}`;
      },
      // success: <b>Member added successfully</b>,
      error: (data) => {
        setLoading(false);
        return data?.data?.msg ? (
          <b>{`${data.data.msg}`}</b>
        ) : (
          <b>Error when adding Member</b>
        );
      },
    });
    setLoading(false);
  };

  const handleAddMemberSubmit = (data) => {
    let newMemberData = {
      ...data,
      client: clientValue,
      plan: planValue,
      program: programValue,
      year: yearValue,
      dob: changeDateToUtcIsoString(data.dob).substring(0, 10),
      effectiveDate: changeDateToUtcIsoString(data.effectiveDate).substring(
        0,
        10,
      ),
      termDate: changeDateToUtcIsoString(data.termDate).substring(0, 10),
    };
    newMemberData["mStatus"] = `${newMemberData.mStatus}`;
    delete newMemberData.age;
    newMemberData.middleName === "" && delete newMemberData.middleName;
    newMemberData.hicn === "" && delete newMemberData.hicn;
    newMemberData.email === "" && delete newMemberData.email;
    newMemberData.pFax === "" && delete newMemberData.pFax;
    newMemberData.memberIdTwo === "" && delete newMemberData.memberIdTwo;
    newMemberData.medicareId === "" && delete newMemberData.medicareId;
    newMemberData.medicaidId === "" && delete newMemberData.medicaidId;
    newMemberData.uniqueId === "" && delete newMemberData.uniqueId;
    newMemberData.pOfcName === "" && delete newMemberData.pOfcName;
    createMembers(newMemberData);
  };

  return (
    <>
      <form onSubmit={handleSubmit(handleAddMemberSubmit)}>
        <div className="add-form-content">
          <div className="form-content-left">
            <div className="demographics-section">
              <div className="demo-header">Demographics</div>
              <div className="demo-content">
                <Grid container>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <TextField
                      type="text"
                      required
                      label="Member First Name"
                      className="inputAddMember"
                      fullWidth
                      {...register("firstName", {
                        required: {
                          value: true,
                          message: "First name is required",
                        },
                        pattern: {
                          value: /^[a-zA-Z]+(?:[.'-][a-zA-Z]+)*$|^(Jr\.|Sr\.)$/,
                          message: "Invalid First Name Format",
                        },
                        // minLength: { value: 3, message: "Min length is 3" },
                        maxLength: { value: 32, message: "Max length is 32" },
                      })}
                    />
                    {errors?.firstName && (
                      <div className="error-container">
                        <span className="error">
                          {errors?.firstName?.message}
                        </span>
                      </div>
                    )}
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <TextField
                      type="text"
                      // required
                      label="Member Middle Name"
                      className="inputAddMember"
                      fullWidth
                      {...register("middleName", {
                        // required: {
                        //   value: true,
                        //   message: "Middle Name is required",
                        // },
                        pattern: {
                          value: /^[a-zA-Z]+(?:[.'-][a-zA-Z]+)*$|^(Jr\.|Sr\.)$/,
                          message: "Invalid Middle Name Format",
                        },
                        // minLength: {
                        //   value: 3,
                        //   message: "Minimum Characters are 3",
                        // },
                        maxLength: {
                          value: 32,
                          message: "Maximum Characters are 32",
                        },
                      })}
                    />
                    {errors?.middleName && (
                      <div className="error-container">
                        <span className="error">
                          {errors?.middleName?.message}
                        </span>
                      </div>
                    )}
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <TextField
                      type="text"
                      required
                      label="Member Last Name"
                      className="inputAddMember"
                      fullWidth
                      {...register("lastName", {
                        required: {
                          value: true,
                          message: "Last Name is required",
                        },
                        pattern: {
                          value: /^[a-zA-Z]+(?:[.'-][a-zA-Z]+)*$|^(Jr\.|Sr\.)$/,
                          message: "Invalid Last Name Format",
                        },
                        // minLength: {
                        //   value: 3,
                        //   message: "Minimum Characters are 3",
                        // },
                        maxLength: {
                          value: 32,
                          message: "Maximum Characters are 32",
                        },
                      })}
                    />
                    {errors?.lastName && (
                      <div className="error-container">
                        <span className="error">
                          {errors?.lastName?.message}
                        </span>
                      </div>
                    )}
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <FormControl required fullWidth>
                      <Controller
                        name={"dob"}
                        control={control}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <DesktopDatePicker
                            label="DOB"
                            className="inputAddMember"
                            value={value}
                            onChange={onChange}
                            disableFuture
                            minDate={minDOB}
                            maxDate={maxDOB}
                            views={["year", "month", "day"]}
                            slotProps={{
                              textField: {
                                required: true,
                                readOnly: true,
                                error: !!error,
                              },
                            }}
                          />
                        )}
                        validateDateTime
                        rules={{
                          required: {
                            value: true,
                            message: "Date Of Birth is Required",
                          },
                          validate: {
                            updateAge: (fieldValue) => {
                              try {
                                const ageComputed = calculateAge(fieldValue);
                                setValue("age", ageComputed, {
                                  shouldDirty: true,
                                  shouldTouch: true,
                                  // shouldValidate: true,
                                });
                              } catch (error) {
                                return "Age can't be calculated with this date";
                              }
                            },
                          },
                        }}
                      />
                      {errors?.dob && (
                        <div className="error-container">
                          <span className="error">{errors?.dob?.message}</span>
                        </div>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      type="number"
                      label="Age"
                      className="inputAddMember"
                      readOnly
                      fullWidth
                      {...register("age", {
                        disabled: true,
                        // required: {
                        //   value: true,
                        //   message: "Age is required",
                        // },
                      })}
                    />
                    {errors?.age && (
                      <div className="error-container">
                        <span className="error">{errors?.age?.message}</span>
                      </div>
                    )}
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <FormControl fullWidth required error={!!errors.gender}>
                      <InputLabel>Gender (Female, Male, Unknown)</InputLabel>
                      <Controller
                        name="gender"
                        control={control}
                        rules={{ required: "Gender is Required" }}
                        defaultValue=""
                        render={({ field }) => (
                          <Select
                            {...field}
                            label="Select Field"
                            className="inputAddMember"
                            variant="outlined"
                          >
                            {genderOptions?.map((option) => {
                              return (
                                <MenuItem key={option} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        )}
                      />
                      {errors?.gender && (
                        <div className="error-container">
                          <span className="error">
                            {errors?.gender?.message}
                          </span>
                        </div>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <FormControl required fullWidth error={!!errors.mStatus}>
                      <InputLabel>Member Marital Status</InputLabel>
                      <Controller
                        control={control}
                        name={"mStatus"}
                        defaultValue=""
                        rules={{ required: "Marital Status is Required" }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            label="Select Field"
                            className="inputAddMember"
                            variant="outlined"
                          >
                            {maritalOptions?.map((option) => {
                              return (
                                <MenuItem key={option.id} value={option.id}>
                                  {option.value}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        )}
                      />
                    </FormControl>
                    {errors?.mStatus && (
                      <div className="error-container">
                        <span className="error">
                          {errors?.mStatus?.message}
                        </span>
                      </div>
                    )}
                  </Grid>

                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <TextField
                      type="text"
                      required
                      label="Member Address 1"
                      className="inputAddMember"
                      fullWidth
                      {...register("address1", {
                        required: {
                          value: true,
                          message: "Address 1 is required",
                        },
                      })}
                    />
                    {errors?.address1 && (
                      <div className="error-container">
                        <span className="error">
                          {errors?.address1?.message}
                        </span>
                      </div>
                    )}
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <TextField
                      type="text"
                      required
                      label="Member Address 2"
                      className="inputAddMember"
                      fullWidth
                      {...register("address2", {
                        required: {
                          value: true,
                          message: "Address 2 is required",
                        },
                      })}
                    />
                    {errors?.address2 && (
                      <div className="error-container">
                        <span className="error">
                          {errors?.address2?.message}
                        </span>
                      </div>
                    )}
                  </Grid>

                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <TextField
                      type="text"
                      required
                      label="Member County"
                      className="inputAddMember"
                      fullWidth
                      {...register("county", {
                        required: {
                          value: true,
                          message: "County is required",
                        },
                      })}
                    />
                    {errors?.county && (
                      <div className="error-container">
                        <span className="error">{errors?.county?.message}</span>
                      </div>
                    )}
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <TextField
                      type="number"
                      required
                      label="Member Zip Code"
                      className="inputAddMember"
                      fullWidth
                      {...register("zip", {
                        required: {
                          value: true,
                          message: "Zip Code is required",
                        },
                        validate: {
                          noZipData: (fieldValue) => {
                            if (fieldValue.toString().length >= 5) {
                              try {
                                const locationInfo =
                                  getCityStateFromZip(fieldValue);
                                setValue("city", locationInfo.city, {
                                  shouldDirty: true,
                                  shouldTouch: true,
                                  shouldValidate: true,
                                });
                                const stateFullName = getStateName(
                                  locationInfo.state,
                                ).toUpperCase();
                                setValue("state", stateFullName, {
                                  shouldDirty: true,
                                  shouldTouch: true,
                                  shouldValidate: true,
                                });
                              } catch (error) {
                                return "Invalid Zip Code";
                              }
                            }
                          },
                        },
                      })}
                    />
                    {errors?.zip && (
                      <div className="error-container">
                        <span className="error">{errors?.zip?.message}</span>
                      </div>
                    )}
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <TextField
                      InputLabelProps={{
                        shrink: true,
                        // shrink: !!watch("zipCode"),
                      }}
                      type="text"
                      required
                      label="Member City"
                      className="inputAddMember"
                      fullWidth
                      {...register("city", {
                        required: {
                          value: true,
                          message: "City is required",
                        },
                      })}
                    />
                    {errors?.city && (
                      <div className="error-container">
                        <span className="error">{errors?.city?.message}</span>
                      </div>
                    )}
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <TextField
                      InputLabelProps={{
                        shrink: true,
                      }}
                      type="text"
                      required
                      label="Member State"
                      className="inputAddMember"
                      fullWidth
                      {...register("state", {
                        required: {
                          value: true,
                          message: "State is required",
                        },
                      })}
                    />
                    {/* <FormControl required fullWidth>
                  <InputLabel shrink={true}>State</InputLabel>
                  <Controller
                    render={({ field: { onChange, value } }) => (
                      <Select
                        fullWidth
                        value={value}
                        onChange={onChange}
                        // defaultValue={""}
                        className="inputAddMember"
                      >
                        {stateOptions?.map((option) => {
                          return (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    )}
                    control={control}
                    name={"state"}
                  />
                </FormControl> 
                <FormControl required fullWidth>
                  <InputLabel shrink={true}>State</InputLabel>
                  <Controller
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        fullWidth
                        select
                        label="State"
                        value={value}
                        onChange={onChange}
                        className="inputAddMember"
                        // defaultValue={""}
                        // {...register("state", {
                        //   required: {
                        //     value: true,
                        //     message: "State is required",
                        //   },
                        // })}
                      >
                        {stateOptions.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                    control={control}
                    name={"state"}
                  />
                </FormControl>*/}
                    {errors?.state && (
                      <div className="error-container">
                        <span className="error">{errors?.state?.message}</span>
                      </div>
                    )}
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <TextField
                      type="text"
                      required
                      label="Member Phone Number"
                      className="inputAddMember"
                      fullWidth
                      // {...register("phoneNumber", {
                      {...register("primary", {
                        required: {
                          value: true,
                          message: "Phone Number is required",
                        },
                        pattern: {
                          value: /^\d{10}$|^\d{3}-\d{3}-\d{4}$/,
                          message:
                            "Phone number must have 10 digit without dashas or 12 digit with dashas",
                        },
                        minLength: {
                          value: 10,
                          message: "Minimum Characters are 10",
                        },
                        maxLength: {
                          value: 12,
                          message: "Maximum Characters are 12",
                        },
                      })}
                    />
                    {errors?.primary && (
                      // {errors?.phoneNumber && (
                      <div className="error-container">
                        <span className="error">
                          {errors?.primary?.message}
                          {/* {errors?.phoneNumber?.message} */}
                        </span>
                      </div>
                    )}
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <TextField
                      type="text"
                      required
                      label="Alternate Number 1"
                      className="inputAddMember"
                      fullWidth
                      // {...register("homeNumber", {
                      {...register("alternate1", {
                        required: {
                          value: true,
                          message: "Alternate Number 1 is required",
                        },
                        minLength: {
                          value: 10,
                          message: "Minimum Characters are 10",
                        },
                        maxLength: {
                          value: 12,
                          message: "Maximum Characters are 12",
                        },
                        pattern: {
                          value: /^\d{10}$|^\d{3}-\d{3}-\d{4}$/,
                          message:
                            "Alternate number 1 must have 10 digit without dashas or 12 digit with dashas",
                        },
                      })}
                    />
                    {/* {errors?.homeNumber && ( */}
                    {errors?.alternate1 && (
                      <div className="error-container">
                        {/* <span className="error">{errors?.homeNumber?.message}</span> */}
                        <span className="error">
                          {errors?.alternate1?.message}
                        </span>
                      </div>
                    )}
                  </Grid>

                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <TextField
                      type="text"
                      required
                      label="Alternate Number 2"
                      className="inputAddMember"
                      fullWidth
                      // {...register("homeNumber", {
                      {...register("alternate2", {
                        required: {
                          value: true,
                          message: "Alternate Number 2 is required",
                        },
                        minLength: {
                          value: 10,
                          message: "Minimum Characters are 10",
                        },
                        maxLength: {
                          value: 12,
                          message: "Maximum Characters are 12",
                        },
                        pattern: {
                          value: /^\d{10}$|^\d{3}-\d{3}-\d{4}$/,
                          message:
                            "Alternate number 2 must have 10 digit without dashas or 12 digit with dashas",
                        },
                      })}
                    />
                    {/* {errors?.homeNumber && ( */}
                    {errors?.alternate2 && (
                      <div className="error-container">
                        {/* <span className="error">{errors?.homeNumber?.message}</span> */}
                        <span className="error">
                          {errors?.alternate2?.message}
                        </span>
                      </div>
                    )}
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <TextField
                      type="text"
                      required
                      label="Alternate Number 3"
                      className="inputAddMember"
                      fullWidth
                      // {...register("homeNumber", {
                      {...register("alternate3", {
                        required: {
                          value: true,
                          message: "Alternate Number 3 is required",
                        },
                        minLength: {
                          value: 10,
                          message: "Minimum Characters are 10",
                        },
                        maxLength: {
                          value: 12,
                          message: "Maximum Characters are 12",
                        },
                        pattern: {
                          value: /^\d{10}$|^\d{3}-\d{3}-\d{4}$/,
                          message:
                            "Alternate number 3 must have 10 digit without dashas or 12 digit with dashas",
                        },
                      })}
                    />
                    {errors?.alternate3 && (
                      <div className="error-container">
                        <span className="error">
                          {errors?.alternate3?.message}
                        </span>
                      </div>
                    )}
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <TextField
                      type="email"
                      // required
                      label="Member Email Address"
                      className="inputAddMember"
                      fullWidth
                      {...register("email", {
                        // required: {
                        //   value: true,
                        //   message: "Email is required",
                        // },
                        pattern: {
                          value: emailRegex,
                          message: "Email is invalid",
                        },
                      })}
                    />
                    {errors?.email && (
                      <div className="error-container">
                        <span className="error">{errors?.email?.message}</span>
                      </div>
                    )}
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <TextField
                      type="text"
                      required
                      label="Emergency Contact - Name"
                      className="inputAddMember"
                      fullWidth
                      // {...register("emergencyContactName", {
                      {...register("ename", {
                        required: {
                          value: true,
                          message: "Emergency Contact - Name is required",
                        },
                        pattern: {
                          value: /^[a-zA-Z]+(?:[.'-][a-zA-Z]+)*$|^(Jr\.|Sr\.)$/,
                          message: "Invalid Emergency Contact Name Format",
                        },
                        maxLength: { value: 32, message: "Max length is 32" },
                      })}
                    />
                    {/* {errors?.emergencyContactName && ( */}
                    {errors?.ename && (
                      <div className="error-container">
                        <span className="error">
                          {/* {errors?.emergencyContactName?.message} */}
                          {errors?.ename?.message}
                        </span>
                      </div>
                    )}
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <TextField
                      type="text"
                      required
                      label="Emergency Contact - Phone Number"
                      className="inputAddMember"
                      fullWidth
                      // {...register("emergencyContactNumber", {
                      {...register("eNo", {
                        required: {
                          value: true,
                          message:
                            "Emergency Contact - Phone Number is required",
                        },
                        maxLength: {
                          value: 12,
                          message: "Maximum Characters are 12",
                        },
                        minLength: {
                          value: 10,
                          message: "Minimum Characters are 10",
                        },
                        pattern: {
                          value: /^\d{10}$|^\d{3}-\d{3}-\d{4}$/,
                          message:
                            "Phone number must have 10 digit without dashas or 12 digit with dashas",
                        },
                      })}
                    />
                    {/* {errors?.emergencyContactNumber && ( */}
                    {errors?.eNo && (
                      <div className="error-container">
                        <span className="error">
                          {/* {errors?.emergencyContactNumber?.message} */}
                          {errors?.eNo?.message}
                        </span>
                      </div>
                    )}
                  </Grid>
                </Grid>
              </div>
            </div>

            <div className="pcp-details-section">
              <div className="pcp-details-header">
                Primary Care Physician Details
              </div>
              <div className="pcp-details-content">
                <Grid container>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={4}
                    xl={4}
                  >
                    <TextField
                      type="text"
                      required
                      label="Member PCP First Name"
                      className="inputAddMember"
                      fullWidth
                      // {...register("primaryCarePhysician", {
                      {...register("pFName", {
                        required: {
                          value: true,
                          message: "PCP - First Name is required",
                        },
                        pattern: {
                          value: /^[a-zA-Z]+(?:[.'-][a-zA-Z]+)*$|^(Jr\.|Sr\.)$/,
                          message: "Invalid PCP First Name Format",
                        },
                        maxLength: { value: 32, message: "Max length is 32" },
                      })}
                    />
                    {/* {errors?.primaryCarePhysician && ( */}
                    {errors?.pFName && (
                      <div className="error-container">
                        <span className="error">
                          {/* {errors?.primaryCarePhysician?.message} */}
                          {errors?.pFName?.message}
                        </span>
                      </div>
                    )}
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={4}
                    xl={4}
                  >
                    <TextField
                      type="text"
                      required
                      label="Member PCP Last Name"
                      className="inputAddMember"
                      fullWidth
                      {...register("pLName", {
                        required: {
                          value: true,
                          message: "PCP - Last Name is required",
                        },
                        pattern: {
                          value: /^[a-zA-Z]+(?:[.'-][a-zA-Z]+)*$|^(Jr\.|Sr\.)$/,
                          message: "Invalid PCP Last Name Format",
                        },
                        maxLength: { value: 32, message: "Max length is 32" },
                      })}
                    />
                    {errors?.pLName && (
                      <div className="error-container">
                        <span className="error">{errors?.pLName?.message}</span>
                      </div>
                    )}
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <TextField
                      type="text"
                      required
                      label="Member PCP Phone Number"
                      className="inputAddMember"
                      fullWidth
                      // {...register("pcpPhoneNumber", {
                      {...register("pPhone", {
                        required: {
                          value: true,
                          message: "PCP - Phone Number is required",
                        },
                        maxLength: {
                          value: 12,
                          message: "Maximum Characters are 12",
                        },
                        minLength: {
                          value: 10,
                          message: "Minimum Characters are 10",
                        },
                        pattern: {
                          value: /^\d{10}$|^\d{3}-\d{3}-\d{4}$/,
                          message:
                            "PCP Phone number must have 10 digit without dashas or 12 digit with dashas",
                        },
                      })}
                    />
                    {/* {errors?.pcpPhoneNumber && ( */}
                    {errors?.pPhone && (
                      <div className="error-container">
                        <span className="error">
                          {/* {errors?.pcpPhoneNumber?.message} */}
                          {errors?.pPhone?.message}
                        </span>
                      </div>
                    )}
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <TextField
                      type="text"
                      required
                      label="Member PCP Address"
                      className="inputAddMember"
                      fullWidth
                      // {...register("pcpAddress", {
                      {...register("pAddOne", {
                        required: {
                          value: true,
                          message: "PCP Address is required",
                        },
                      })}
                    />
                    {/* {errors?.pcpAddress && ( */}
                    {errors?.pAddOne && (
                      <div className="error-container">
                        {/* <span className="error">{errors?.pcpAddress?.message}</span> */}
                        <span className="error">
                          {errors?.pAddOne?.message}
                        </span>
                      </div>
                    )}
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <TextField
                      type="text"
                      required
                      label="Member PCP County"
                      className="inputAddMember"
                      fullWidth
                      {...register("pcpcounty", {
                        required: {
                          value: true,
                          message: "PCP County is required",
                        },
                      })}
                    />
                    {errors?.pcpcounty && (
                      <div className="error-container">
                        <span className="error">
                          {errors?.pcpcounty?.message}
                        </span>
                      </div>
                    )}
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <TextField
                      type="number"
                      required
                      label="Member PCP Zip Code"
                      className="inputAddMember"
                      fullWidth
                      {...register("pZip", {
                        required: {
                          value: true,
                          message: "PCP Zip Code is required",
                        },
                        validate: {
                          noZipData: (fieldValue) => {
                            if (fieldValue.toString().length >= 5) {
                              try {
                                const locationInfo =
                                  getCityStateFromZip(fieldValue);
                                setValue("pCity", locationInfo.city, {
                                  shouldDirty: true,
                                  shouldTouch: true,
                                  shouldValidate: true,
                                });
                                const stateFullName = getStateName(
                                  locationInfo.state,
                                ).toUpperCase();
                                setValue("pState", stateFullName, {
                                  shouldDirty: true,
                                  shouldTouch: true,
                                  shouldValidate: true,
                                });
                              } catch (error) {
                                return "Invalid Zip Code";
                              }
                            }
                          },
                        },
                      })}
                    />
                    {errors?.pZip && (
                      <div className="error-container">
                        <span className="error">{errors?.pZip?.message}</span>
                      </div>
                    )}
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <TextField
                      InputLabelProps={{
                        shrink: true,
                      }}
                      type="text"
                      required
                      label="Member PCP City"
                      className="inputAddMember"
                      fullWidth
                      {...register("pCity", {
                        required: {
                          value: true,
                          message: "PCP City is required",
                        },
                      })}
                    />
                    {errors?.pCity && (
                      <div className="error-container">
                        <span className="error">{errors?.pCity?.message}</span>
                      </div>
                    )}
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <TextField
                      InputLabelProps={{
                        shrink: true,
                      }}
                      type="text"
                      required
                      label="Member PCP State"
                      className="inputAddMember"
                      fullWidth
                      {...register("pState", {
                        required: {
                          value: true,
                          message: "PCP State is required",
                        },
                      })}
                    />
                    {errors?.pState && (
                      <div className="error-container">
                        <span className="error">{errors?.pState?.message}</span>
                      </div>
                    )}
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <TextField
                      type="text"
                      // required
                      label="Member PCP Fax Number"
                      className="inputAddMember"
                      fullWidth
                      {...register("pFax", {
                        // required: {
                        //   value: true,
                        //   message: "PCP - Fax Number is required",
                        // },
                        maxLength: {
                          value: 12,
                          message: "Maximum Characters are 12",
                        },
                        minLength: {
                          value: 10,
                          message: "Minimum Characters are 10",
                        },
                        pattern: {
                          value: /^\d{10}$|^\d{3}-\d{3}-\d{4}$/,
                          message:
                            "PCP Fax number must have 10 digit without dashas or 12 digit with dashas",
                        },
                      })}
                    />
                    {errors?.pFax && (
                      <div className="error-container">
                        <span className="error">{errors?.pFax?.message}</span>
                      </div>
                    )}
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <TextField
                      type="text"
                      required
                      label="Member PCP Office ID"
                      className="inputAddMember"
                      fullWidth
                      {...register("pId", {
                        required: {
                          value: true,
                          message: "Office ID is required",
                        },
                        pattern: {
                          value: /^\d+$/,
                          message: "Office Id must have numbers only",
                        },
                      })}
                    />
                    {errors?.pId && (
                      <div className="error-container">
                        <span className="error">{errors?.pId?.message}</span>
                      </div>
                    )}
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <TextField
                      type="text"
                      // required
                      label="Member PCP Office Name"
                      className="inputAddMember"
                      fullWidth
                      {...register("pOfcName", {
                        // required: {
                        //   value: true,
                        //   message: "Office Name is required",
                        // },
                        pattern: {
                          value: /^[a-zA-Z-'. ]*$/,
                          message: "Office Name must have alphabets only",
                        },
                        maxLength: { value: 32, message: "Max length is 32" },
                      })}
                    />
                    {errors?.pOfcName && (
                      <div className="error-container">
                        <span className="error">
                          {errors?.pOfcName?.message}
                        </span>
                      </div>
                    )}
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
          <div className="form-content-right">
            <div className="insurance-details-section">
              <div className="insurance-header">Insurance Details</div>
              <div className="insurance-content">
                <Grid container>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <TextField
                      type="text"
                      required
                      label="Member ID"
                      className="inputAddMember"
                      fullWidth
                      {...register("memberId", {
                        required: {
                          value: true,
                          message: "Member ID is required",
                        },
                        pattern: {
                          value: /^[A-Za-z0-9*]*$/,
                          message:
                            "Member ID can have only Alphanumberic and *",
                        },
                        maxLength: {
                          value: 20,
                          message: "Maximum Characters are 20",
                        },
                      })}
                    />
                    {errors?.memberId && (
                      <div className="error-container">
                        <span className="error">
                          {errors?.memberId?.message}
                        </span>
                      </div>
                    )}
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <TextField
                      type="text"
                      // required
                      label="Member Identification Number Two"
                      className="inputAddMember"
                      fullWidth
                      {...register("memberIdTwo", {
                        // required: {
                        //   value: true,
                        //   message: "Member ID Two is required",
                        // },
                        pattern: {
                          value: /^[A-Za-z0-9*]*$/,
                          message:
                            "Member ID can have only Alphanumberic and *",
                        },
                        maxLength: {
                          value: 20,
                          message: "Maximum Characters are 20",
                        },
                      })}
                    />
                    {errors?.memberIdTwo && (
                      <div className="error-container">
                        <span className="error">
                          {errors?.memberIdTwo?.message}
                        </span>
                      </div>
                    )}
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <TextField
                      type="text"
                      // required
                      label="Medicare ID"
                      className="inputAddMember"
                      fullWidth
                      {...register("medicareId", {
                        // required: {
                        //   value: true,
                        //   message: "Medicare ID is required",
                        // },
                        pattern: {
                          value: /^[A-Za-z0-9*]*$/,
                          message:
                            "Medicare ID can have only Alphanumberic and *",
                        },
                        maxLength: {
                          value: 20,
                          message: "Maximum Characters are 20",
                        },
                      })}
                    />
                    {errors?.medicareId && (
                      <div className="error-container">
                        <span className="error">
                          {errors?.medicareId?.message}
                        </span>
                      </div>
                    )}
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <TextField
                      type="text"
                      // required
                      label="Medicaid ID"
                      className="inputAddMember"
                      fullWidth
                      {...register("medicaidId", {
                        // required: {
                        //   value: true,
                        //   message: "Medicaid ID is required",
                        // },
                        pattern: {
                          value: /^[A-Za-z0-9*]*$/,
                          message:
                            "Medicaid ID can have only Alphanumberic and *",
                        },
                        maxLength: {
                          value: 20,
                          message: "Maximum Characters are 20",
                        },
                      })}
                    />
                    {errors?.medicaidId && (
                      <div className="error-container">
                        <span className="error">
                          {errors?.medicaidId?.message}
                        </span>
                      </div>
                    )}
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <TextField
                      type="text"
                      // required
                      label="Unique ID"
                      className="inputAddMember"
                      fullWidth
                      {...register("uniqueId", {
                        // required: {
                        //   value: true,
                        //   message: "Medicaid ID is required",
                        // },
                        pattern: {
                          value: /^[0-9*]*$/,
                          message: "Unique ID can have only Numbers and *",
                        },
                        maxLength: {
                          value: 20,
                          message: "Maximum Characters are 20",
                        },
                      })}
                    />
                    {errors?.uniqueId && (
                      <div className="error-container">
                        <span className="error">
                          {errors?.uniqueId?.message}
                        </span>
                      </div>
                    )}
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <TextField
                      type="number"
                      label="HICN"
                      className="inputAddMember"
                      fullWidth
                      {...register("hicn", {
                        // required: {
                        //   value: true,
                        //   message: "HICN is required",
                        // },
                        // pattern: {
                        //   value: /^[a-zA-Z ]*$/,
                        //   message: "HICN can have only alphabets",
                        // },

                        minLength: {
                          value: 4,
                          message: "Minimum Characters are 4",
                        },
                        maxLength: {
                          value: 10,
                          message: "Maximum Characters are 10",
                        },
                      })}
                    />
                    {errors?.hicn && (
                      <div className="error-container">
                        <span className="error">{errors?.hicn?.message}</span>
                      </div>
                    )}
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <FormControl required fullWidth>
                      <InputLabel>Member Plan</InputLabel>
                      <Controller
                        name="plan"
                        control={control}
                        rules={{ required: "Plan is Required" }}
                        defaultValue=""
                        render={({ field }) => (
                          <Select
                            {...field}
                            label="Select Field"
                            className="inputAddMember"
                            variant="outlined"
                            onChange={(e) => {
                              field.onChange(e);
                              handlePlanChange(e);
                            }}
                          >
                            <MenuItem value="">Select Plan</MenuItem>
                            {allMappingData?.map((option) => {
                              return (
                                <MenuItem
                                  key={option?._id}
                                  value={option?.plan}
                                >
                                  {_.toUpper(option?.plan)}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        )}
                      />
                    </FormControl>
                    {errors?.plan && (
                      <div className="error-container">
                        <span className="error">{errors?.plan?.message}</span>
                      </div>
                    )}
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <FormControl required fullWidth>
                      <InputLabel>Member Program</InputLabel>
                      <Controller
                        name="program"
                        control={control}
                        rules={{ required: "program is Required" }}
                        defaultValue=""
                        render={({ field }) => (
                          <Select
                            {...field}
                            label="Select Field"
                            className="inputAddMember"
                            variant="outlined"
                            onChange={(e) => {
                              field.onChange(e);
                              handleProgramChange(e);
                            }}
                          >
                            <MenuItem value="">Select Program</MenuItem>
                            {programOptions?.map((option) => {
                              return (
                                <MenuItem
                                  key={option?._id}
                                  value={option?.name}
                                >
                                  {option?.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        )}
                      />
                    </FormControl>
                    {errors?.program && (
                      <div className="error-container">
                        <span className="error">
                          {errors?.program?.message}
                        </span>
                      </div>
                    )}
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <FormControl fullWidth required>
                      <InputLabel>Year List</InputLabel>
                      <Controller
                        name="year"
                        control={control}
                        rules={{ required: "Year is Required" }}
                        defaultValue=""
                        render={({ field }) => (
                          <Select
                            {...field}
                            label="Select Field"
                            className="inputAddMember"
                            variant="outlined"
                            onChange={(e) => {
                              field.onChange(e);
                              handleYearChange(e);
                            }}
                          >
                            <MenuItem value="">Select Year</MenuItem>
                            {yearOptions?.map((option) => {
                              return (
                                <MenuItem key={option} value={option}>
                                  {option}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        )}
                      />
                      {errors?.year && (
                        <div className="error-container">
                          <span className="error">{errors?.year?.message}</span>
                        </div>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <FormControl
                      required
                      fullWidth
                      error={!!errors.effectiveDate}
                    >
                      <Controller
                        name={"effectiveDate"}
                        control={control}
                        render={({ field }) => (
                          <DesktopDatePicker
                            {...field}
                            label="Effective Date"
                            className="inputAddMember"
                            disablePast
                            views={["year", "month", "day"]}
                            slotProps={{
                              textField: {
                                required: true,
                                readOnly: true,
                              },
                            }}
                          />
                        )}
                        rules={{
                          required: {
                            value: true,
                            message: "Effective Date is Required",
                          },
                        }}
                      />
                      {errors.effectiveDate && (
                        <FormHelperText>
                          {errors.effectiveDate.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <FormControl required fullWidth>
                      <Controller
                        name={"termDate"}
                        control={control}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <DesktopDatePicker
                            label="Member Termed Date"
                            className="inputAddMember"
                            value={value}
                            onChange={onChange}
                            disablePast
                            views={["year", "month", "day"]}
                            slotProps={{
                              textField: {
                                required: true,
                                readOnly: true,
                                error: !!error,
                                helperText: error?.message,
                              },
                            }}
                          />
                        )}
                        rules={{
                          required: {
                            value: true,
                            message: "Term Date is Required",
                          },
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        </div>
        <div className="btnsAddMember">
          <Button
            type="submit"
            sx={dialogBtn}
            className="dialogBtn btn-add"
            // onClick={submitAddMembers}
          >
            Add
          </Button>
          <Button
            sx={dialogBtn}
            className="reset-btn"
            onClick={() => {
              reset();
              reset({
                dob: null,
                termDate: null,
                effectiveDate: null,
              });
            }}
          >
            Reset
          </Button>
        </div>
      </form>
      {loading && <Loader />}
    </>
  );
}
