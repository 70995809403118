import MuiInput from "../../../muiinput/MuiInput";
import Button from "@mui/material/Button";
import { useState, useEffect, useRef } from "react";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/system";
import "./AddClient.css";
import Toaster from "../../../toaster/toaster";
import { stateNames } from "../../../Dashboard/ReactDND/userCategories";
import Labels from "../../../../resource_files/Labels.resx";
import { getApi, postApi } from "../../../../utils/axiosApiWrapper";
import { getStateName } from "../../../../utils/getUSStateName";
import ValidationMessage from "../../../../resource_files/ValidationMessage.resx";
import { zcs } from "zcs";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import { MenuItem, Select, InputLabel, FormControl } from "@mui/material";

//import { planOptions, programOptions } from "../../../utils";

const ZCS = zcs();

const initialClientData = {
  clientName: "",
  plan: "",
  healthPlanName: "",
  address: "",
  city: "",
  county: "",
  state: [],
  zipCode: "",
  phoneNumber: "",
  email: "",
  notes: "",
  yearList: [],
  feeSchedule: [],
  visitTypes: [],
  assessmentTypes: [],
  lob: [],
  program: null,
  screeningType: [],
};

const AddClient = ({ fetchClientData, onClose }) => {
  const [clientData, setClientData] = useState(initialClientData);
  const [, setErrors] = useState({});
  const [toasterMessage, setToasterMessage] = useState("");

  const [toasterSeverity, setToasterSeverity] = useState(Labels.SUCCESS);
  const [toasterOpen, setToasterOpen] = useState(false);
  const [zipCodeError, setZipCodeError] = useState("");
  const [emailError, setEmailError] = useState("");

  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [programInputValue, setProgramInputValue] = useState("");
  const [clientDatas, setClientDatas] = useState({
    visitTypes: [],
    assessmentTypes: [],
    program: [],
    lob: [],
  });
  const [planErr, setPlanErr] = useState(""); //[
  const [healthPlanErr, setHealthPlanErr] = useState("");
  const [addressErr, setAddressErr] = useState("");
  const [countryErr, setCountryErr] = useState("");
  const [mobNoErr, setMobNoErr] = useState("");
  const [notesErr, setNotesErr] = useState("");
  const [cityErr, setCityErr] = useState("");
  const [programErr, setProgramErr] = useState("");
  const [clientNameErr, setClientNameErr] = useState("");
  const [stateErr, setStateErr] = useState("");
  const [disableSubmitBtn, setDisableSubmitBtn] = useState(true);
  useEffect(() => {
    const fetchVisitTypes = async () => {
      try {
        const response = await getApi("/visitTypes/get-visit-types");
        const responseData = await response.data.data;

        // if (responseData && Array.isArray(responseData)) {
        const visitTypesOptions = responseData.map((visit) => visit.visitType);
        setClientDatas((prevData) => ({
          ...prevData,
          visitTypes: visitTypesOptions,
        }));
      } catch (error) {
        return;
      }
    };

    fetchVisitTypes();
  }, []);

  useEffect(() => {
    const fetchAssessmentTypes = async () => {
      try {
        const response = await getApi("/assessmentTypes/list");

        if (response.status === 200) {
          const responseData = await response.data.data;

          if (responseData && Array.isArray(responseData)) {
            const assessmentTypesOptions = responseData.map(
              (assessmentType) => assessmentType.assessmentName,
            );
            setClientDatas((prevData) => ({
              ...prevData,
              assessmentTypes: assessmentTypesOptions,
            }));
          } else {
            return;
          }
        } else {
          return;
        }
      } catch (error) {
        return;
      }
    };

    fetchAssessmentTypes();
  }, []);

  useEffect(() => {
    const fetchProgramTypes = async () => {
      try {
        const response = await getApi("/programTypes/list");

        if (response.status === 200) {
          const responseData = await response.data.data;

          if (responseData && Array.isArray(responseData)) {
            const programTypesOptions = responseData.map(
              (programType) => programType.programName,
            );
            setClientDatas((prevData) => ({
              ...prevData,
              program: responseData, //programTypesOptions,
            }));
          } else {
            return;
          }
        } else {
          return;
        }
      } catch (error) {
        return;
      }
    };

    fetchProgramTypes();
  }, []);

  useEffect(() => {
    const fetchLobTypes = async () => {
      try {
        const response = await getApi("/lobTypes/list");

        if (response.status === 200) {
          const responseData = await response.data.data;

          if (responseData && Array.isArray(responseData)) {
            const lobTypesOptions = responseData.map(
              (lobType) => lobType.lobName,
            );
            setClientDatas((prevData) => ({
              ...prevData,
              lob: responseData,
            }));
          } else {
            return;
          }
        } else {
          return;
        }
      } catch (error) {
        return;
      }
    };

    fetchLobTypes();
  }, []);

  const ValidationError = ({ error }) => {
    return error ? (
      <h3
        className="password"
        style={{
          color: "#d32f2f",
          lineHeight: "14px",
          width: "400px",
          marginTop: "-21px",
        }}
      >
        {error}
      </h3>
    ) : null;
  };

  const dialogBtn = {
    width: "131px",
    height: "32px",
    flexShrink: "0",
    borderRadius: "20px",
    background: "var(--mainTheme)",
    marginLeft: "10px",
    color: "white",
    "&:hover": {
      boxShadow: 8,
      color: "var(--mainTheme)",
    },
  };
  const screeningTypeOptions = [
    "A1C",
    "FIT",
    "LDL",
    "PAD",
    "Retinal Eye Exam",
    "DEXA",
    "Urine- Microalbumin",
    "KED",
  ];
  const additionalConditions = [!zipCodeError, !emailError, !phoneNumberError];
  const getLocationInfoByZip = (zipCode) => {
    const parsedZipCode = parseInt(zipCode, 10);
    const locationInfo = ZCS.getByZip(parsedZipCode);
    return locationInfo;
  };

  useEffect(() => {
    if (clientData.zipCode) {
      try {
        if (
          !/^(0|\d+)$/.test(clientData.zipCode) &&
          !/^\d{5}$/.test(clientData.zipCode)
        ) {
          setZipCodeError(ValidationMessage.Zipcode_Format);

          return;
        }
        const locationInfo = getLocationInfoByZip(clientData.zipCode);
        if (locationInfo?.city && locationInfo?.state) {
          const stateFullName = getStateName(locationInfo?.state);
          if (stateErr?.length > 0) {
            setStateErr("");
          }
          if (cityErr?.length > 0) {
            setCityErr("");
          }
          setClientData((prevClientData) => ({
            ...prevClientData,
            city: locationInfo.city,
            state: [stateFullName.toUpperCase()],
          }));
          setZipCodeError("");
        } else {
          setZipCodeError("Invalid zip code");
        }
      } catch (error) {
        if (
          !/^(0|[1-9][0-9]*)$/.test(clientData.zipCode) &&
          !/^\d{5}$/.test(clientData.zipCode)
        ) {
          setZipCodeError(ValidationMessage.Zipcode_Format);
        } else {
          setZipCodeError("Error fetching location information");
        }
      }
    }
  }, [clientData.zipCode]);

  const handleInputChange = (fieldName, inputValue) => {
    if (fieldName === "zipCode") {
      if (!inputValue) {
        setZipCodeError(ValidationMessage.Zipcode_Required);
      } else {
        setZipCodeError("");
        setClientData((prevClientData) => ({
          ...prevClientData,
          zipCode: inputValue,
        }));
      }
    } else if (fieldName === "phoneNumber") {
      if (!inputValue) {
        setPhoneNumberError(ValidationMessage.PhoneNumber_Required);
      } else if (
        !/^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/.test(
          inputValue,
        )
      ) {
        setPhoneNumberError(ValidationMessage.PhoneNumber_Format);
      } else {
        setPhoneNumberError("");
      }
    } else if (fieldName === "email") {
      if (!inputValue || /^\s*$/.test(inputValue)) {
        setEmailError(ValidationMessage.Email_Required);
      } else if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(inputValue)) {
        setEmailError(ValidationMessage.Email_Format_Invalid);
      } else {
        setEmailError("");
      }
    }

    handleChange(fieldName, inputValue);
  };
  const stringRegex = new RegExp(/^[A-Za-z_ ]+$/);
  const mobileNoRegex = new RegExp(/^\d{10}$|^\d{3}-\d{3}-\d{4}$/);
  const strNumRegex = new RegExp(/^[A-Za-z0-9]*$/);
  const mobileNumberWithHyphen = new RegExp(/^\d{3}-\d{3}-\d{4}$/);
  const Nameregex = new RegExp(/^[a-zA-Z]+(?:[.'-][a-zA-Z]+)*$|^(Jr\.|Sr\.)$/);
  console.log("lob", clientDatas.lob);
  console.log("program", clientDatas.program);
  console.log("program", clientData.program);
  console.log("lob", clientData.lob);
  const handleChange = (field, value) => {
    if (
      field === "visitTypes" ||
      field === "yearList" ||
      field === "feeSchedule" ||
      field === "assessmentTypes" ||
      field === "lob" ||
      field === "state" ||
      field === "screeningType"
    ) {
      console.log("value", value);
      const sortedValues = Array.isArray(value) ? [...value].sort() : [value];
      setClientData((prevData) => ({
        ...prevData,
        [field]: sortedValues,
      }));
    } else if (field === "program") {
      // const structuredProgram = value.map((programItem, index) => {
      //   if (typeof programItem === "object") {
      //     return {
      //       id: programItem.id || index.toString(),
      //       name: programItem.name || "",
      //     };
      //   } else {
      //     return {
      //       id: index.toString(),
      //       name: programItem,
      //     };
      //   }
      // });

      setClientData((prevData) => ({
        ...prevData,
        [field]: value, //structuredProgram,
      }));
    } else if (field === "plan") {
      if (!stringRegex.test(value) && value.length > 0) {
        setPlanErr("Please Enter Valid Plan Id");
      } else {
        setPlanErr("");
      }
      setClientData((prevData) => ({
        ...prevData,
        [field]: value,
      }));
    } else if (field == "address") {
      if (!strNumRegex.test(value) && value.length > 0) {
        setAddressErr("Special Char are not allowed");
      } else {
        setAddressErr("");
      }
      setClientData((prevData) => ({
        ...prevData,
        [field]: value,
      }));
    } else if (field == "county") {
      if (!stringRegex.test(value) && value.length > 0) {
        setCountryErr("Enter Valid Country");
      } else {
        setCountryErr("");
      }
      setClientData((prevData) => ({
        ...prevData,
        [field]: value,
      }));
    } else if (field == "phoneNumber") {
      if (
        value.length > 0 &&
        !mobileNoRegex.test(value)
        // ||
        // (value.length > 0 && !mobileNumberWithHyphen.test(value))
      ) {
        setMobNoErr("Enter Valid Phone Number");
      } else {
        setMobNoErr("");
      }
      setClientData((prevData) => ({
        ...prevData,
        [field]: value,
      }));
    } else if (field == "program") {
      if (!stringRegex.test(value) && value.length > 0) {
        setProgramErr("Enter Valid Program");
      } else {
        setProgramErr("");
      }
      setClientData((prevData) => ({
        ...prevData,
        [field]: value,
      }));
    } else if (field == "healthPlanName") {
      if (!stringRegex.test(value) && value.length > 0) {
        setHealthPlanErr("Enter Valid Health Plan Name");
      } else {
        setHealthPlanErr("");
      }
      setClientData((prevData) => ({
        ...prevData,
        [field]: value,
      }));
    } else if (field == "notes") {
      if (!strNumRegex.test(value) && value.length > 0) {
        setNotesErr("Enter Proper Notes");
      } else {
        setNotesErr("");
      }
      setClientData((prevData) => ({
        ...prevData,
        [field]: value,
      }));
    } else if (field == "city") {
      if (!stringRegex.test(value) && value.length > 0) {
        setCityErr("Enter Valid City");
      } else {
        setCityErr("");
      }
      setClientData((prevData) => ({
        ...prevData,
        [field]: value,
      }));
    } else if (field == "state") {
      if (!stringRegex.test(value) && value.length > 0) {
        setStateErr("Enter Valid State");
      } else {
        setStateErr("");
      }
      setClientData((prevData) => ({
        ...prevData,
        [field]: value,
      }));
    } else if (field == "clientName") {
      if (
        (!Nameregex.test(value) && value.length > 0) ||
        [".", "-", "`"].includes(value[0])
      ) {
        setClientNameErr("Enter Valid Name");
      } else {
        setClientNameErr("");
      }
      setClientData((prevData) => ({
        ...prevData,
        [field]: value,
      }));
    } else {
      setClientData((prevData) => ({
        ...prevData,
        [field]: value.toString(),
      }));
    }
  };
  const handleChangeLob = (value) => {
    console.log("value", value);
    setClientData((prevData) => ({
      ...prevData,
      lob: value,
    }));
  };
  const onCloseToaster = () => {
    setToasterOpen(false);
    setToasterSeverity(Labels.SUCCESS);
    setToasterMessage("");
    setToasterMessage("");
  };

  const programInputRef = useRef();

  const handleReset = () => {
    setClientData(initialClientData);
    setErrors({});
    setProgramInputValue("");
  };

  const isFormValid = Object.values(clientData).every(
    (value) =>
      [
        planErr,
        healthPlanErr,
        addressErr,
        countryErr,
        mobNoErr,
        // notesErr,
        cityErr,
        programErr,
        clientNameErr,
      ]?.filter((err) => err.length > 0)?.length <= 0 &&
      ((typeof value === "string" && value.trim() !== "") ||
        (typeof value === "object" && value !== null && value !== undefined)),
  );

  const handleAdd = async () => {
    const selectedLob = clientDatas.lob?.filter((lob) =>
      clientData.lob?.includes(lob?._id),
    );
    const structuredProgran = [clientData.program]?.map((prog) => {
      prog = {
        id: "1", //prog?.clientId,
        name: prog?.programName,
        yearList: prog?.createdDateYear?.map((date) => date?.year), //[2023, 2024],
        isActive: true,
        lob: selectedLob?.map((lob, ind) => {
          return {
            id: ind + 1 + "",
            name: lob?.lobName,
            isActive: true,
            yearList: lob?.year,
          };
        }),
      };
      return prog;
    });
    delete clientData.lob;
    const updatedClientData = { ...clientData, program: structuredProgran };
    if (
      updatedClientData.email !== undefined &&
      typeof updatedClientData.email === "string" &&
      updatedClientData.email.trim() !== ""
    ) {
      try {
        const response = await postApi("/clients/add", updatedClientData);
        if (response?.status === 201) {
          setToasterOpen(true);
          setToasterSeverity(Labels.SUCCESS);
          setToasterMessage(response.data.message);
          setClientData(initialClientData);

          // Call the fetchClientData function passed from ViewClients
          fetchClientData();
          setTimeout(() => {
            onClose();
          }, 2000);
        } else {
          setToasterSeverity(Labels.ERROR);
          if (
            response &&
            response.response &&
            response.response.data &&
            response.response.data.message
          ) {
            setToasterMessage(response.response.data.message);
          } else {
            setToasterMessage("An error occurred.");
          }

          setToasterOpen(true);
        }
      } catch (error) {
        setToasterSeverity(Labels.ERROR);
        setToasterMessage("An error occurred.");
        setToasterOpen(true);
      }
    }
  };

  const getDisable = () => {
    return (
      !Boolean(clientData?.clientName) ||
      !Boolean(clientData?.plan) ||
      !Boolean(clientData?.healthPlanName) ||
      !Boolean(clientData?.address) ||
      !Boolean(clientData?.city) ||
      !Boolean(clientData?.county) ||
      clientData?.state?.length == 0 ||
      !Boolean(clientData?.zipCode) ||
      !Boolean(clientData?.phoneNumber) ||
      !Boolean(clientData?.email) ||
      !Boolean(clientData?.notes) ||
      clientData?.yearList?.length == 0 ||
      !Boolean(clientData?.yearList) ||
      clientData?.feeSchedule?.length == 0 ||
      !Boolean(clientData?.feeSchedule) ||
      clientData?.visitTypes?.length == 0 ||
      !Boolean(clientData?.visitTypes) ||
      clientData?.assessmentTypes?.length == 0 ||
      !Boolean(clientData?.assessmentTypes) ||
      clientData?.lob?.length == 0 ||
      !Boolean(clientData?.lob) ||
      clientData?.program?.length == 0 ||
      !Boolean(clientData?.program) ||
      clientData?.screeningType?.length == 0 ||
      !Boolean(clientData?.screeningType)
    );
  };

  return (
    <div className="addClient">
      <div className="">
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <Box
              component="form"
              sx={{
                "& > :not(style)": { m: 1, width: "100%" },
              }}
              noValidate
              autoComplete="off"
              //className={props.className}
            >
              <TextField
                variant="outlined"
                label="Client Name"
                className="inputAddMember"
                type="input"
                value={clientData.clientName}
                onChange={(event) =>
                  handleChange("clientName", event.target.value)
                }
                required={true}
                error={clientNameErr?.length > 0}
                helperText={clientNameErr.length > 0 ? clientNameErr : ""}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <Box
              component="form"
              sx={{
                "& > :not(style)": { m: 1, width: "100%" },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                variant="outlined"
                label="Plan"
                className="inputAddMember"
                type="input"
                value={clientData.plan}
                onChange={(event) => handleChange("plan", event.target.value)}
                required={true}
                error={planErr.length > 0}
                helperText={planErr.length > 0 ? planErr : ""}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <Autocomplete
              multiple={false}
              clearOnBlur
              id="program-autocomplete"
              style={{ marginTop: "7px", marginLeft: "9px" }}
              options={clientDatas.program}
              getOptionLabel={(option) => option.programName}
              getOptionSelected={(option, value) => option?._id == value}
              value={clientData?.program}
              //inputValue={programInputValue}
              onChange={(event, newValue) => {
                setProgramInputValue(newValue);
                handleChange("program", newValue);
              }}
              freeSolo
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Program"
                  className="inputAddMember"
                  required={true}
                  // onChange={(e) => {
                  //   setProgramInputValue(e.target.value);
                  // }}
                  ref={programInputRef}
                  error={programErr.length > 0}
                  helperText={programErr.length > 0 ? programErr : ""}
                />
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    key={option._id}
                    label={option.programName}
                    {...getTagProps({ index })}
                  />
                ))
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <Box
              component="form"
              sx={{
                "& > :not(style)": { m: 1, width: "100%" },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                variant="outlined"
                label="Health Plan Name"
                className="inputAddMember"
                type="input"
                value={clientData.healthPlanName}
                onChange={(event) =>
                  handleChange("healthPlanName", event.target.value)
                }
                required={true}
                error={healthPlanErr.length > 0}
                helperText={healthPlanErr.length > 0 ? healthPlanErr : ""}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <Box
              component="form"
              sx={{
                "& > :not(style)": { m: 1, width: "100%" },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                variant="outlined"
                label="Address"
                className="inputAddMember"
                type="input"
                value={clientData.address}
                onChange={(event) =>
                  handleChange("address", event.target.value)
                }
                required={true}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <Box
              component="form"
              sx={{
                "& > :not(style)": { m: 1, width: "100%" },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                variant="outlined"
                label="Country"
                className="inputAddMember"
                type="input"
                value={clientData.county}
                onChange={(event) => handleChange("county", event.target.value)}
                required={true}
                error={countryErr.length > 0}
                helperText={countryErr.length > 0 ? countryErr : ""}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <Box
              component="form"
              sx={{
                "& > :not(style)": { m: 1, width: "100%" },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                variant="outlined"
                label="Zip Code"
                className="inputAddMember"
                type="input"
                value={clientData.zipCode}
                onChange={(event) =>
                  handleInputChange("zipCode", event.target.value.toString())
                }
                required={true}
              />
            </Box>
            <ValidationError error={zipCodeError} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <Box
              component="form"
              sx={{
                "& > :not(style)": { m: 1, width: "100%" },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                variant="outlined"
                label="City"
                className="inputAddMember"
                type="input"
                value={clientData.city}
                onChange={(event) => handleChange("city", event.target.value)}
                required={true}
                error={cityErr.length > 0}
                helperText={cityErr.length > 0 ? cityErr : ""}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            {/* <Box
              component="form"
              sx={{
                "& > :not(style)": { m: 1, width: "100%" },
              }}
              noValidate
              autoComplete="off"
            > */}
            <MuiInput
              //  variant="outlined"
              label="State"
              // options={stateNames.options.toLocaleString()}
              options={stateNames.options.map((state) => state.toUpperCase())}
              className="inputAddMember"
              type="select"
              value={clientData.state}
              onChange={(event) => handleChange("state", event.target.value)}
              required={true}
              error={stateErr.length > 0}
              helperText={stateErr.length > 0 ? stateErr : ""}
            />
            {/* </Box> */}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <MuiInput
              label="Email"
              className="inputAddMember"
              type="input"
              value={clientData.email}
              onChange={(event) =>
                handleInputChange("email", event.target.value)
              }
              required={true}
            />
            <ValidationError error={emailError} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <Box
              component="form"
              sx={{
                "& > :not(style)": { m: 1, width: "100%" },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                label="Phone Number"
                className="inputAddMember"
                type="input"
                value={clientData.phoneNumber}
                onChange={(event) =>
                  handleInputChange(
                    "phoneNumber",
                    event.target.value.toString(),
                  )
                }
                required={true}
                error={mobNoErr.length > 0}
                helperText={mobNoErr.length > 0 ? mobNoErr : ""}
              />
            </Box>
            {/* <ValidationError error={phoneNumberError} /> */}
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <MuiInput
              label="Year"
              // options={["2019", "2020", "2021", "2022", "2023", "2024"]}
              options={[new Date().getFullYear().toString()]}
              className="inputAddMember"
              // isMulti={true}
              type="select"
              // value={clientData.yearList}
              value={clientData.yearList}
              onChange={(event) => handleChange("yearList", event.target.value)}
              required={true}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <MuiInput
              label="Visit Types"
              options={clientDatas.visitTypes}
              className="inputAddMember"
              type="select"
              isMulti={true}
              value={clientData.visitTypes}
              onChange={(event) =>
                handleChange("visitTypes", event.target.value)
              }
              required={true}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <MuiInput
              label="Assessments"
              options={clientDatas.assessmentTypes}
              className="inputAddMember"
              type="select"
              isMulti={true}
              value={clientData.assessmentTypes}
              onChange={(event) =>
                handleChange("assessmentTypes", event.target.value)
              }
              required={true}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            {/* <MuiInput
              label="LOB"
              options={clientDatas.lob}
              className="inputAddMember"
              type="select"
              isMulti={true}
              value={clientData.lob}
              onChange={(event) => handleChange("lob", event.target.value)}
              required={true}
            /> */}
            <Box
              sx={{
                "& > :not(style)": {
                  m: 1,
                  width: "100%",
                  padding: "0px",
                },
              }}
              className="inputAddMember"
            >
              <FormControl>
                {/* <InputLabel>{props.label}</InputLabel> */}
                <InputLabel
                  sx={{
                    backgroundColor: "transparent",
                    // padding: '8px',
                  }}
                >
                  LOB
                </InputLabel>
                <Select
                  multiple={true}
                  value={clientData.lob?.length > 0 ? clientData.lob : []}
                  onChange={(e) => {
                    handleChangeLob(e?.target.value);
                  }}
                  label="LOB"
                >
                  {clientDatas.lob?.map((opt, id) => {
                    return (
                      <MenuItem
                        key={id}
                        value={opt?._id}
                        // disabled={isOptionDisabled}
                        sx={{ color: "none" }}
                      >
                        {opt?.lobName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <MuiInput
              label="Fee Schedule"
              //options={clientData.feeSchedule}
              options={["fee schedule 1", "Fee schedule 2"]}
              className="inputAddMember"
              type="select"
              isMulti={true}
              value={clientData.feeSchedule}
              onChange={(event) =>
                handleChange("feeSchedule", event.target.value)
              }
              required={true}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <Box
              component="form"
              sx={{
                "& > :not(style)": { m: 1, width: "100%" },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                variant="outlined"
                label="Notes"
                className="inputAddMembers"
                type="input"
                value={clientData.notes}
                onChange={(event) => handleChange("notes", event.target.value)}
                multiline
                required={true}
              />
            </Box>
          </Grid>
          {/* Screening Types==> A1C, FIT, LDL, PAD, Retinal Eye Exam, DEXA, Urine- Microalbumin, KED  */}
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <MuiInput
              label="Screening Type"
              options={screeningTypeOptions}
              className="inputAddMember"
              type="select"
              isMulti={true}
              value={clientData.screeningType}
              onChange={(event) =>
                handleChange("screeningType", event.target.value)
              }
              required={true}
            />
          </Grid>
        </Grid>
        <div style={{ marginLeft: "74%", marginTop: "50px" }}>
          <Button
            sx={dialogBtn}
            className={`dialogBtn ${
              // !isFormValid ||
              // !additionalConditions.every((condition) => condition)
              getDisable() ? "disabledButton" : ""
            }`}
            // disabled={!isFormValid}
            // disabled={
            //   !isFormValid ||
            //   !additionalConditions.every((condition) => condition)
            // }
            disabled={getDisable()}
            onClick={handleAdd}
          >
            Add
          </Button>
          <Button sx={dialogBtn} onClick={handleReset}>
            Reset
          </Button>
        </div>
      </div>

      <Toaster
        open={toasterOpen}
        onClose={onCloseToaster}
        severity={toasterSeverity}
        message={toasterMessage}
      />
    </div>
  );
};

export default AddClient;
