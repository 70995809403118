import { Grid } from "@mui/material";
import lodash from "lodash";
import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useCallback,
} from "react";
import LoadingOverlay from "react-loading-overlay";
import focusCaresLoader from "../../../../../../loader/focuscares_loader.gif";
import { DFButton } from "../../DFInputs/DFButton";
import { DFCheckbox } from "../../DFInputs/DFCheckbox";
import { DFDate } from "../../DFInputs/DFDate";
import { DFHeading } from "../../DFInputs/DFHeading";
import { DFImage } from "../../DFInputs/DFImage";
import { DFLabel } from "../../DFInputs/DFLabel";
import { DFOptionsTree } from "../../DFInputs/DFOptionsTree";
import DFRadio from "../../DFInputs/DFRadio";
import { DFSelect } from "../../DFInputs/DFSelect";
import DFText from "../../DFInputs/DFText";
import { DFRuler } from "../../DFInputs/DFRuler";
import { useFillHraContext } from "../../../../../../contexts/fillHraFormContext";
import {
  deleteSubTree,
  getExamReviewData,
  getSubTree,
  setExamReviewData,
} from "./examReviewMapping";
import { useUserContext } from "../../../../../../contexts/UserContext";
import { getInputsData } from "./examFormData";
import footImage from "../../../../../../assets/foot_examination.png";
import { useLocation } from "react-router-dom";
import { getApi } from "../../../../../../utils/axiosApiWrapper";

const calcCommentVisibility = (formData) => {
  let tempData = {};
  Object.keys(formData).forEach((stateVar) => {
    if (stateVar.includes("Comment")) {
      let isValueThere = formData[stateVar] !== "" ? true : false;
      tempData = {
        ...tempData,
        [`${stateVar}_toggle`]: isValueThere,
      };
    }
  });

  return tempData;
};

const ExamReview = forwardRef((props, _ref) => {
  const {
    // examReviewFormData,
    // setExamReviewFormData,
    // showComment,
    // setShowComment,
    // getSectionInputsData,
    // getStateValue,
    // setStateValue,
    getDisableFlag,
  } = useFillHraContext();
  const location = useLocation();
  const assessmentId = location?.state?.assessmentId
    ? location?.state?.assessmentId
    : sessionStorage.getItem("assessmentId");
  const tabId = location?.state?.tabId
    ? location?.state?.tabId
    : sessionStorage.getItem("tabId");
  const roleId = sessionStorage.getItem("roleId");
  const { storedPlan, storedYear, setSideBarSectionDisable } = useUserContext();
  const [examReviewFormData, setExamReviewFormData] = useState({});
  const [showComment, setShowComment] = useState([]);
  const [disableFlag, setDisableFlag] = useState(false);
  const [loading, setLoading] = useState(undefined);
  const sectionId = props.currSection || 13;
  const clientPlan = localStorage.getItem("clientPlan") || storedPlan;
  const clientYear = localStorage.getItem("clientYear") || storedYear;
  const inputsJsonData = getInputsData(clientPlan, clientYear);

  useEffect(() => {
    async function getDbData() {
      setLoading(true);
      setSideBarSectionDisable(true);
      // const dbData = await props.getSectionDbData(sectionId);
      const secondApiResponse = await getApi(
        `/hra/get-assessment?id=${assessmentId}`,
      ); //await props.getSectionDbData(sectionId);
      const dbData = secondApiResponse[`data`];
      const mashedData =
        dbData?.data?.exam?.data &&
        Object.keys(dbData?.data?.exam?.data).length > 0
          ? getExamReviewData(dbData?.data?.exam?.data, clientYear)
          : {};

      const commentVisibility = calcCommentVisibility(mashedData);
      let mashedCalcData = {
        ...mashedData,
        ...commentVisibility,
      };
      setExamReviewFormData(mashedCalcData);
      setLoading(false);
      setSideBarSectionDisable(false);
    }

    getDbData();
    setDisableFlag(getDisableFlag());
  }, []);

  const handleTextChange = (name, value) => {
    const updatedFormData = { ...examReviewFormData };
    updatedFormData[name] = value;

    setExamReviewFormData(updatedFormData);
  };

  const handleRadioChange = (name, value) => {
    const updatedFormData = { ...examReviewFormData };
    updatedFormData[name] = value;

    setExamReviewFormData(updatedFormData);
  };

  const handleDateChange = (name, value) => {
    const updatedFormData = { ...examReviewFormData };
    updatedFormData[name] = value;

    setExamReviewFormData(updatedFormData);
  };

  const handleSelectChange = (name, value) => {
    const updatedFormData = { ...examReviewFormData };
    updatedFormData[name] = value;

    setExamReviewFormData(updatedFormData);
  };

  const handleOptionsTreeChange = (name, value, isMulti) => {
    const updatedFormData = { ...examReviewFormData };
    let newSelection = value;
    // let oldSelection = getStateValue(13, name);
    let oldSelection = updatedFormData[name];
    let deletedValue =
      isMulti === true
        ? lodash?.difference(oldSelection, newSelection)[0] || ""
        : oldSelection || "";

    if (name === "exam_noGpcogMmseRecall" && !value?.includes("poor")) {
      updatedFormData.exam_noGpcogMmseRecallPoorSubDesc = "";
    }

    if (name === "exam_psychMood" && !value?.includes("abnormal")) {
      updatedFormData.exam_psychMoodSub = "";
    }

    if (name === "ros_speech" && !value?.includes("abnormal")) {
      updatedFormData.ros_speechSub = "";
    }

    if (name === "ros_fingerToNose" && !value?.includes("abnormal")) {
      updatedFormData.ros_fingerToNoseAbnormalSub = "";
    }

    if (name === "ros_heelToToe" && !value?.includes("abnormal")) {
      updatedFormData.ros_heelToToeAbnormalSub = "";
    }

    if (name === "ros_thumbToFinger" && !value?.includes("abnormal")) {
      updatedFormData.ros_thumbToFingerAbnormalSub = "";
    }

    if (name === "exam_diabFoot" && !value?.includes("abnormal")) {
      updatedFormData.exam_diabetecFootExamAbnormalSubDesc = "";
    }

    if ((value === "yes" || value === "no") && oldSelection === "yes") {
      const deleteRegex = `${name}Sub`;

      updatedFormData[name] = value;
      let subTreeDeletedData = deleteSubTree(updatedFormData, deleteRegex);
      setExamReviewFormData(subTreeDeletedData);
      return null;
    } else if (deletedValue !== "") {
      updatedFormData[name] = value;
      const deleteRegex = getSubTree(name, deletedValue);

      if (deleteRegex != "") {
        let subTreeDeletedData = deleteSubTree(updatedFormData, deleteRegex);
        setExamReviewFormData(subTreeDeletedData);
        return null;
      } else {
        updatedFormData[name] = value;
        setExamReviewFormData(updatedFormData);
      }

      return null;
    } else {
      updatedFormData[name] = value;
      setExamReviewFormData(updatedFormData);
    }

    if (deletedValue && deletedValue !== "") {
      let subTreeDeletedData = handleDeleteSubTree(
        updatedFormData,
        name,
        deletedValue,
      );
      subTreeDeletedData[name] = newSelection;
      setExamReviewFormData(subTreeDeletedData);
      return null;
    } else {
      updatedFormData[name] = newSelection;
      setExamReviewFormData(updatedFormData);
    }

    updatedFormData[name] = newSelection;
    setExamReviewFormData(updatedFormData);
  };

  const handleButtonClick = (name) => {
    return null;
  };

  const handleCheckboxChange = (name, value, checked) => {
    const updatedFormData = { ...examReviewFormData };
    const oldData = updatedFormData?.[name] || [];
    let newData = [];
    if (oldData.length < 1) {
      newData.push(value);
    } else {
      if (checked) {
        newData = [...oldData];
        newData.push(value);
      } else {
        newData = oldData.filter((ele) => ele !== value);
      }
    }

    updatedFormData[name] = newData;
    setExamReviewFormData(updatedFormData);
  };

  useImperativeHandle(_ref, () => ({
    getPayloadData: () => {
      return setExamReviewData(examReviewFormData, clientYear);
    },
  }));
  useEffect(() => {
    props.setAutoSaveExamReviewData({ ...examReviewFormData });
  }, [examReviewFormData]);

  const handleDeleteSubTree = (objectData, name, deletedValue) => {
    let deleteRegex = "";
    if (deletedValue === "yes" || deletedValue === "no") {
      deleteRegex = `${name}Sub`;
    } else {
      deleteRegex = getSubTree(name, deletedValue);
    }
    if (deleteRegex !== "") {
      let updatedObjectData = deleteSubTree(objectData, deleteRegex);
      return updatedObjectData;
    }
    return objectData;
  };

  const getFieldValues = useCallback(
    (name) => {
      return examReviewFormData[name];
    },
    [examReviewFormData],
  );

  const setFieldValues = useCallback((name, value, type = "") => {
    setExamReviewFormData((prevData) => {
      let tempData = { ...prevData };
      if (type === "datatable") {
        tempData = {
          ...tempData,
          ...value,
        };
      }
      tempData = {
        ...tempData,
        [name]: value,
      };

      return { ...tempData };
    });
  }, []);

  return (
    <LoadingOverlay
      styles={{
        // wrapper: { marginLeft: "50px", marginTop: "50px" },
        // overlay: {},
        content: { marginLeft: "40vw", marginTop: "50px" },
        // spinner: { marginLeft: "50px", marginTop: "50px" },
        spinner: (base) => ({
          ...base,
          marginLeft: "50px",
          marginTop: "50px",
        }),
      }}
      style={{ marginTop: "20px" }}
      active={loading}
      spinner={<img src={focusCaresLoader} alt="Loader" />}
      // text="Loading your content..."
    >
      <div
        style={{
          margin: "10px 20px",
        }}
      >
        <Grid container spacing={2}>
          {inputsJsonData?.questions?.map((inputProps) => {
            const { questionId, name, xs, type, isSingleButton } = inputProps;
            const inputValue = examReviewFormData[name] || "";
            const commentValue = `${
              examReviewFormData?.[`${name}Comment`]
                ? `${examReviewFormData[`${name}Comment`]}`
                : ""
            }`;
            const quesSlug = `${sectionId}_${questionId}`;
            if (
              type === "text" ||
              type === "number" ||
              type === "email" ||
              type === "password"
            ) {
              return (
                <Grid
                  key={quesSlug}
                  xs={xs}
                  /* sm={inputProps.sm}*/
                  item
                >
                  <DFText
                    inputProps={inputProps}
                    value={inputValue}
                    handleTextChange={handleTextChange}
                    showComment={showComment}
                    setShowComment={setShowComment}
                    quesSlug={`${questionId}`}
                    sectionId={sectionId}
                    commentValue={commentValue}
                    disableFlag={disableFlag || (roleId == 5 && tabId == 3)}
                    needMemo={true}
                    getFieldValues={getFieldValues}
                    setFieldValues={setFieldValues}
                  />
                </Grid>
              );
            } else if (type === "radio") {
              return (
                <Grid key={quesSlug} xs={xs} item>
                  <DFRadio
                    inputProps={inputProps}
                    value={inputValue}
                    handleRadioChange={handleRadioChange}
                    handleTextChange={handleTextChange} // added for comment box event handling
                    showComment={showComment}
                    setShowComment={setShowComment}
                    quesSlug={`${questionId}`}
                    sectionId={sectionId}
                    commentValue={commentValue}
                    disableFlag={disableFlag || (roleId == 5 && tabId == 3)}
                    needMemo={true}
                    getFieldValues={getFieldValues}
                    setFieldValues={setFieldValues}
                  />
                </Grid>
              );
            } else if (type === "date") {
              const dateValue = examReviewFormData[name] || {};
              return (
                <Grid
                  key={quesSlug}
                  xs={xs}
                  /* sm={sm} */
                  item
                >
                  <DFDate
                    inputProps={inputProps}
                    value={dateValue}
                    handleDateChange={handleDateChange}
                    handleTextChange={handleTextChange} // added for comment box event handling
                    showComment={showComment}
                    setShowComment={setShowComment}
                    quesSlug={`${questionId}`}
                    sectionId={sectionId}
                    commentValue={commentValue}
                    disableFlag={disableFlag || (roleId == 5 && tabId == 3)}
                    needMemo={true}
                    getFieldValues={getFieldValues}
                    setFieldValues={setFieldValues}
                    // dtPickProps={{
                    //   disableFuture:true,
                    //   disablePast:true,
                    //   minDate:minDOB,
                    //   maxDate:maxDOB,
                    //   views:["year", "month", "day"]
                    // }}
                  />
                </Grid>
              );
            } else if (type === "select") {
              return (
                <Grid key={quesSlug} xs={xs} item>
                  <DFSelect
                    inputProps={inputProps}
                    value={inputValue}
                    handleSelectChange={handleSelectChange}
                    handleTextChange={handleTextChange} // added for comment box event handling
                    showComment={showComment}
                    setShowComment={setShowComment}
                    quesSlug={`${questionId}`}
                    sectionId={sectionId}
                    commentValue={commentValue || (roleId == 5 && tabId == 3)}
                    disableFlag={
                      disableFlag ||
                      (roleId == 5 && tabId == 3) ||
                      roleId == 6 ||
                      roleId == 8 ||
                      roleId == 7 ||
                      roleId == 9
                    }
                    needMemo={true}
                    getFieldValues={getFieldValues}
                    setFieldValues={setFieldValues}
                  />
                </Grid>
              );
            } else if (type === "options_tree") {
              return (
                <DFOptionsTree
                  inputProps={inputProps}
                  // formData={examReviewFormData}
                  formData={examReviewFormData}
                  handleOptionsTreeChange={handleOptionsTreeChange}
                  handleTextChange={handleTextChange}
                  handleRadioChange={handleRadioChange}
                  handleDateChange={handleDateChange}
                  handleSelectChange={handleSelectChange}
                  handleButtonClick={handleButtonClick}
                  handleCheckboxChange={handleCheckboxChange}
                  showComment={showComment}
                  setShowComment={setShowComment}
                  // setFormData={setExamReviewFormData}
                  setFormData={setExamReviewFormData}
                  quesSlug={`${questionId}`}
                  sectionId={sectionId}
                  // commentValue={commentValue}
                  disableFlag={disableFlag || (roleId == 5 && tabId == 3)}
                  needMemo={true}
                  getFieldValues={getFieldValues}
                  setFieldValues={setFieldValues}
                />
              );
            } else if (type === "label") {
              return (
                <Grid key={quesSlug} xs={xs} item>
                  <DFLabel
                    inputProps={inputProps}
                    handleTextChange={handleTextChange} // added for comment box event handling
                    showComment={showComment}
                    setShowComment={setShowComment}
                    quesSlug={`${questionId}`}
                    sectionId={sectionId}
                    commentValue={commentValue}
                    disableFlag={disableFlag || (roleId == 5 && tabId == 3)}
                    needMemo={true}
                    getFieldValues={getFieldValues}
                    setFieldValues={setFieldValues}
                  />
                </Grid>
              );
            } else if (type === "button") {
              return (
                <Grid key={quesSlug} xs={isSingleButton ? 12 : 0} item>
                  <DFButton
                    inputProps={{
                      ...inputProps,
                      disabled: roleId == 5 && tabId == 3,
                    }}
                    handleButtonClick={handleButtonClick}
                    // disableFlag={disableFlag} - check & implement
                  />
                </Grid>
              );
            } else if (type === "heading") {
              return (
                <Grid key={quesSlug} xs={12} item>
                  <DFHeading inputProps={inputProps} />
                </Grid>
              );
            } else if (type === "checkbox") {
              return (
                <Grid key={quesSlug} xs={xs} item>
                  <DFCheckbox
                    inputProps={inputProps}
                    showComment={showComment}
                    handleCheckboxChange={handleCheckboxChange}
                    value={inputValue}
                    handleTextChange={handleTextChange}
                    setShowComment={setShowComment}
                    quesSlug={`${questionId}`}
                    sectionId={sectionId}
                    commentValue={commentValue}
                    disableFlag={disableFlag || (roleId == 5 && tabId == 3)}
                    needMemo={true}
                    getFieldValues={getFieldValues}
                    setFieldValues={setFieldValues}
                  />
                </Grid>
              );
            } else if (type === "image") {
              return (
                <Grid key={quesSlug} xs={xs} item>
                  <img
                    src={footImage}
                    alt="footImage"
                    style={{ border: "1px solid black" }}
                  />
                </Grid>
              );
            } else if (type === "divider") {
              return <DFRuler />;
            }

            return null;
          })}
        </Grid>
      </div>
    </LoadingOverlay>
  );
});

export default ExamReview;
