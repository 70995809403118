import React, { useEffect, useState } from "react";
import _ from "lodash";
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { zcs } from "zcs";
import {
  emailRegex,
  maxDOB,
  minDOB,
  populateYears,
  genderOptions,
  maritalOptions,
  getCityStateFromZip,
  preferredContactMethodOptions,
} from "../../../utils/appConstants";
import { getStateName, stateArray } from "../../../utils/getUSStateName";
import { changeDateToUtcIsoString } from "../../utils";
import { addNewMembers } from "../../../api/membersApi";
import { getAllClients } from "../../../api/clientsApi";
import Loader from "../../Loader/Loader";

import "./AddMembers.css";
import dayjs from "dayjs";

const ZCS = zcs();

export default function ViewMembers({ member }) {
  const [loading, setLoading] = useState(true);
  const [memberAge, setMemberAge] = useState();
  const [clientOptions, setClientOptions] = useState([]);
  const [programOptions, setProgramOptions] = useState([]);
  const [planOptions, setPlanOptions] = useState([]);
  const [yearOptions, setYearOptions] = useState([]);
  const [allMappingData, setAllMappingData] = useState([]);
  const [planValue, setPlanValue] = useState();
  const [programValue, setProgramValue] = useState();
  const [clientValue, setClientValue] = useState();
  const [yearValue, setYearValue] = useState();
  const { control, handleSubmit, reset } = useForm(
    {
      mode: "all",
    },
    {
      defaultValues: {
        age: 0,
        // state: "",
      },
    },
  );

  const getMemberApiData = async () => {
    try {
      const response = await getAllClients();
      if (response?.data?.data) {
        setAllMappingData(response.data.data);

        const initialPlan = member?.assessment?.demographics?.data?.plan;
        const selectedClient = response.data.data.find(
          (client) => client.plan === initialPlan,
        );

        if (selectedClient) {
          setProgramOptions(selectedClient.program || []);

          const initialProgram = selectedClient.program[0]?.name || "";
          setProgramValue(initialProgram);

          const yearList = selectedClient.program.find(
            (program) => program.name === initialProgram,
          );
          setYearOptions((yearList?.yearList || []).reverse());
        }
      }
    } catch (error) {
      console.error("Error fetching member data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!_.isEmpty(planValue)) {
      let selectedClientBasedObjectValue = _.find(allMappingData, {
        plan: planValue,
      });
      setProgramOptions(selectedClientBasedObjectValue?.program || []);
    } else {
      setProgramOptions([]);
    }
    getMemberApiData();
  }, [planValue]);

  useEffect(() => {
    getMemberApiData();
    return () => {};
  }, []);

  const stateOptions = Object.keys(stateArray).map((stateCode) => {
    return getStateName(stateCode).toUpperCase();
    // return {
    //   shortCode:stateCode,
    //   fullName:getStateName(stateCode).toUpperCase()
    // }
  });
  const dialogBtn = {
    width: "131px",
    height: "32px",
    flexShrink: "0",
    borderRadius: "5px",
    background: "var(--mainTheme850)",
    marginLeft: "10px",
    color: "white",
    "&:hover": {
      boxShadow: 8,
      background: "var(--mainTheme)",
    },
  };

  const memberDOB = member?.assessment?.demographics?.data?.dob;
  const memberGender =
    member?.assessment?.demographics?.data?.genderAtBirth || "";
  const memberMarital = member?.assessment?.demographics?.data?.mStatus || "";
  const mPrefMethodOfContact =
    member?.assessment?.demographics?.data?.preferredContactMethod || "";

  const memberAddressOne =
    member?.assessment?.demographics?.data?.address || "";
  const memberAddressTwo =
    member?.assessment?.demographics?.data?.addressTwo || "";
  const mCounty = member?.assessment?.demographics?.data?.mCounty || "";
  const memberZip = member?.assessment?.demographics?.data?.mZip || "";
  const memberCity = member?.assessment?.demographics?.data?.mCity || "";
  const memberState = member?.assessment?.demographics?.data?.mState || "";
  const memberAltNo = member?.assessment?.demographics?.data?.aContact || "";
  const memberCellNo = member?.assessment?.demographics?.data?.secondary || "";
  const memberEmerName = member?.assessment?.demographics?.data?.ename || "";
  const memberEmerNumber = member?.assessment?.demographics?.data?.eNo || "";
  const memberEmail = member?.assessment?.demographics?.data?.email || "";
  //pcp
  const memberpcpcounty =
    member?.assessment?.demographics?.data?.pcpcounty || "";
  const memberPAdd = member?.assessment?.demographics?.data?.pAddOne || "";
  const memberpCity = member?.assessment?.demographics?.data?.pCity || "";
  const memberpFax = member?.assessment?.demographics?.data?.pFax || "";
  const memberpId = member?.assessment?.demographics?.data?.pId || "";
  const memberpName = member?.assessment?.demographics?.data?.pName || "";
  const nameParts = memberpName.split(" ");
  const pcpfirstName = nameParts[0] || "";
  const pcplastName = nameParts[nameParts.length - 1] || "";
  const memberpOfcName = member?.assessment?.demographics?.data?.pOfcName || "";
  const memberpPhone = member?.assessment?.demographics?.data?.pPhone || "";
  const memberpState = member?.assessment?.demographics?.data?.pState || "";
  const memberpZip = member?.assessment?.demographics?.data?.rpZip || "";
  //insurance
  const memberMemID = member?.assessment?.demographics?.data?.memberId || "";
  const memberIdTwo = member?.assessment?.demographics?.data?.memberIdTw || "";
  const memberMedcaidID =
    member?.assessment?.demographics?.data?.medicaidId || "";
  const memberMedcairID =
    member?.assessment?.demographics?.data?.medicareId || "";
  const uniqueId = member?.assessment?.demographics?.data?.uniqueId || "";
  const hicn = member?.assessment?.demographics?.data?.hicn || "";
  const plan = member?.assessment?.demographics?.data?.plan || "";
  const program = member?.assessment?.demographics?.data?.program || "";
  const mlob = member?.assessment?.demographics?.data?.lob || "";
  const mlanguage = member?.assessment?.demographics?.data?.language || "";
  const mAssessment =
    member?.assessment?.demographics?.data?.memberAssesment || "";
  const mEligible =
    member?.assessment?.demographics?.data?.membersEligible || "";
  const memYear = member?.year;
  const memberData = member?.assessment?.demographics?.data || {};
  const memEffDate = memberData.effectiveDate
    ? dayjs(memberData.effectiveDate).format("YYYY-MM-DD")
    : null;
  const memTermDate = member.termDate
    ? dayjs(member.termDate).format("YYYY-MM-DD")
    : null;

  useEffect(() => {
    if (memberDOB) {
      const birthDate = new Date(memberDOB);
      const today = new Date();

      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDifference = today.getMonth() - birthDate.getMonth();

      if (
        monthDifference < 0 ||
        (monthDifference === 0 && today.getDate() < birthDate.getDate())
      ) {
        age--;
      }

      setMemberAge(age);
    }
  }, [memberDOB]);

  return (
    <>
      <form>
        <div className="add-form-content">
          <div className="form-content-left">
            <div className="demographics-section">
              <div className="demo-header">Demographics</div>
              <div className="demo-content">
                <Grid container>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <TextField
                      type="text"
                      required
                      label="Member First Name"
                      className="inputAddMember"
                      fullWidth
                      defaultValue={
                        member?.assessment?.demographics?.data?.firstName || ""
                      }
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <TextField
                      type="text"
                      // required
                      label="Member Middle Name"
                      className="inputAddMember"
                      fullWidth
                      defaultValue={
                        member?.assessment?.demographics?.data?.middleName || ""
                      }
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <TextField
                      type="text"
                      required
                      label="Member Last Name"
                      className="inputAddMember"
                      fullWidth
                      defaultValue={
                        member?.assessment?.demographics?.data?.lastName || ""
                      }
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <FormControl required fullWidth>
                      <Controller
                        name={"dob"}
                        control={control}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <DesktopDatePicker
                            label="DOB"
                            className="inputAddMember"
                            // value={value}
                            value={
                              memberDOB && memberDOB !== ""
                                ? dayjs(memberDOB)
                                : null
                            }
                            // onChange={onChange}
                            disableFuture
                            minDate={minDOB}
                            maxDate={maxDOB}
                            views={["year", "month", "day"]}
                            slotProps={{
                              textField: {
                                required: true,
                                readOnly: true,
                                error: !!error,
                              },
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>

                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      type="number"
                      label="Age"
                      className="inputAddMember"
                      readOnly
                      fullWidth
                      defaultValue={memberAge}
                      value={memberAge !== undefined ? memberAge : ""}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <FormControl fullWidth>
                      <InputLabel>Gender (Female, Male, Unknown)</InputLabel>
                      <Controller
                        name="gender"
                        control={control}
                        defaultValue={memberGender}
                        render={({ field }) => (
                          <Select
                            {...field}
                            label="Select Field"
                            className="inputAddMember"
                            variant="outlined"
                            disabled
                          >
                            {genderOptions?.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <FormControl required fullWidth>
                      <InputLabel>Member Marital Status</InputLabel>
                      <Controller
                        control={control}
                        name={"mStatus"}
                        defaultValue={memberMarital}
                        render={({ field }) => (
                          <Select
                            {...field}
                            label="Select Field"
                            className="inputAddMember"
                            variant="outlined"
                            disabled
                          >
                            {maritalOptions?.map((option) => {
                              return (
                                <MenuItem key={option.id} value={option.id}>
                                  {option.value}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <FormControl required fullWidth>
                      <InputLabel>Member Marital Status</InputLabel>
                      <Controller
                        control={control}
                        name={"preferredContactMethod"}
                        defaultValue={mPrefMethodOfContact}
                        render={({ field }) => (
                          <Select
                            {...field}
                            label="Select Field"
                            className="inputAddMember"
                            variant="outlined"
                            disabled
                          >
                            {preferredContactMethodOptions?.map((option) => {
                              return (
                                <MenuItem key={option.id} value={option.id}>
                                  {option.value}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <TextField
                      type="text"
                      label="Language"
                      className="inputAddMember"
                      fullWidth
                      defaultValue={mlanguage}
                      //   value={memberAddressTwo}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>

                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <TextField
                      type="text"
                      required
                      //   value={memberAddressOne}
                      defaultValue={memberAddressOne}
                      label="Member Address 1"
                      className="inputAddMember"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <TextField
                      type="text"
                      required
                      label="Member Address 2"
                      className="inputAddMember"
                      fullWidth
                      defaultValue={memberAddressTwo}
                      //   value={memberAddressTwo}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>

                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <TextField
                      type="text"
                      required
                      label="Member County"
                      className="inputAddMember"
                      fullWidth
                      defaultValue={mCounty}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <TextField
                      type="number"
                      required
                      label="Member Zip Code"
                      className="inputAddMember"
                      fullWidth
                      defaultValue={memberZip}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <TextField
                      InputLabelProps={{
                        shrink: true,
                        // shrink: !!watch("zipCode"),
                      }}
                      type="text"
                      required
                      label="Member City"
                      className="inputAddMember"
                      fullWidth
                      defaultValue={memberCity}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <TextField
                      InputLabelProps={{
                        shrink: true,
                      }}
                      type="text"
                      required
                      label="Member State"
                      className="inputAddMember"
                      fullWidth
                      defaultValue={memberState}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <TextField
                      type="text"
                      required
                      label="Member Phone Number"
                      className="inputAddMember"
                      fullWidth
                      //   defaultValue={member}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <TextField
                      type="text"
                      required
                      label="Alternate Number 1"
                      className="inputAddMember"
                      fullWidth
                      defaultValue={memberAltNo}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>

                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <TextField
                      type="text"
                      required
                      label="Alternate Number 2"
                      className="inputAddMember"
                      fullWidth
                      defaultValue={memberCellNo}
                      //   value={memberAddressTwo}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <TextField
                      type="text"
                      required
                      label="Alternate Number 3"
                      className="inputAddMember"
                      fullWidth
                      defaultValue={memberAltNo}
                      //   value={memberAddressTwo}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <TextField
                      type="email"
                      // required
                      label="Member Email Address"
                      className="inputAddMember"
                      fullWidth
                      defaultValue={memberEmail}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <TextField
                      type="text"
                      required
                      label="Emergency Contact - Name"
                      className="inputAddMember"
                      fullWidth
                      defaultValue={memberEmerName}
                      //   value={memberAddressTwo}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <TextField
                      type="text"
                      required
                      label="Emergency Contact - Phone Number"
                      className="inputAddMember"
                      fullWidth
                      defaultValue={memberEmerNumber}
                      //   value={memberAddressTwo}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                </Grid>
              </div>
            </div>

            <div className="pcp-details-section">
              <div className="pcp-details-header">
                Primary Care Physician Details
              </div>
              <div className="pcp-details-content">
                <Grid container>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={4}
                    xl={4}
                  >
                    <TextField
                      type="text"
                      required
                      label="Member PCP First Name"
                      className="inputAddMember"
                      fullWidth
                      defaultValue={pcpfirstName}
                      //   value={memberAddressTwo}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={4}
                    xl={4}
                  >
                    <TextField
                      type="text"
                      required
                      label="Member PCP Last Name"
                      className="inputAddMember"
                      fullWidth
                      defaultValue={pcplastName}
                      //   value={memberAddressTwo}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <TextField
                      type="text"
                      required
                      label="Member PCP Phone Number"
                      className="inputAddMember"
                      fullWidth
                      defaultValue={memberpPhone}
                      //   value={memberAddressTwo}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <TextField
                      type="text"
                      required
                      label="Member PCP Address"
                      className="inputAddMember"
                      fullWidth
                      defaultValue={memberPAdd}
                      //   value={memberAddressTwo}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <TextField
                      type="text"
                      required
                      label="Member PCP County"
                      className="inputAddMember"
                      fullWidth
                      defaultValue={memberpcpcounty}
                      //   value={memberAddressTwo}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <TextField
                      type="number"
                      required
                      label="Member PCP Zip Code"
                      className="inputAddMember"
                      fullWidth
                      defaultValue={memberpZip}
                      //   value={memberAddressTwo}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <TextField
                      InputLabelProps={{
                        shrink: true,
                      }}
                      type="text"
                      required
                      label="Member PCP City"
                      className="inputAddMember"
                      fullWidth
                      defaultValue={memberpCity}
                      //   value={memberAddressTwo}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <TextField
                      InputLabelProps={{
                        shrink: true,
                      }}
                      type="text"
                      required
                      label="Member PCP State"
                      className="inputAddMember"
                      fullWidth
                      defaultValue={memberpState}
                      //   value={memberAddressTwo}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <TextField
                      type="text"
                      // required
                      label="Member PCP Fax Number"
                      className="inputAddMember"
                      fullWidth
                      defaultValue={memberpFax}
                      //   value={memberAddressTwo}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <TextField
                      type="text"
                      required
                      label="Member PCP Office ID"
                      className="inputAddMember"
                      fullWidth
                      defaultValue={memberpId}
                      //   value={memberAddressTwo}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <TextField
                      type="text"
                      // required
                      label="Member PCP Office Name"
                      className="inputAddMember"
                      fullWidth
                      defaultValue={memberpOfcName}
                      //   value={memberAddressTwo}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
          <div className="form-content-right">
            <div className="insurance-details-section">
              <div className="insurance-header">Insurance Details</div>
              <div className="insurance-content">
                <Grid container>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <TextField
                      type="text"
                      required
                      label="Member ID"
                      className="inputAddMember"
                      fullWidth
                      defaultValue={memberMemID}
                      //   value={memberAddressTwo}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <TextField
                      type="text"
                      // required
                      label="Member Identification Number Two"
                      className="inputAddMember"
                      fullWidth
                      defaultValue={memberIdTwo}
                      //   value={memberAddressTwo}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <TextField
                      type="text"
                      // required
                      label="Medicare ID"
                      className="inputAddMember"
                      fullWidth
                      defaultValue={memberMedcairID}
                      //   value={memberAddressTwo}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <TextField
                      type="text"
                      // required
                      label="Medicaid ID"
                      className="inputAddMember"
                      fullWidth
                      defaultValue={memberMedcaidID}
                      //   value={memberAddressTwo}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <TextField
                      type="text"
                      // required
                      label="Unique ID"
                      className="inputAddMember"
                      fullWidth
                      defaultValue={uniqueId}
                      //   value={memberAddressTwo}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <TextField
                      type="number"
                      label="HICN"
                      className="inputAddMember"
                      fullWidth
                      defaultValue={hicn}
                      //   value={memberAddressTwo}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <FormControl required fullWidth>
                      <InputLabel>Member Plan</InputLabel>
                      <Controller
                        name="plan"
                        control={control}
                        defaultValue={plan}
                        render={({ field }) => (
                          <Select
                            {...field}
                            label="Select Field"
                            className="inputAddMember"
                            variant="outlined"
                            disabled
                          >
                            <MenuItem value="">Select Plan</MenuItem>
                            {allMappingData?.map((option) => {
                              return (
                                <MenuItem
                                  key={option?._id}
                                  value={option?.plan}
                                >
                                  {_.toUpper(option?.plan)}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sx={{ padding: "10px" }} xs={12}>
                    <FormControl required fullWidth>
                      <InputLabel>Member Program</InputLabel>
                      <Controller
                        name="program"
                        control={control}
                        defaultValue={program}
                        render={({ field }) => (
                          <Select
                            {...field}
                            label="Select Field"
                            className="inputAddMember"
                            variant="outlined"
                            disabled
                          >
                            <MenuItem value="">Select Program</MenuItem>
                            {programOptions?.map((option) => (
                              <MenuItem key={option?._id} value={option?.name}>
                                {option?.name}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <TextField
                      type="text"
                      label="LOB"
                      className="inputAddMember"
                      fullWidth
                      defaultValue={mlob}
                      //   value={memberAddressTwo}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <TextField
                      type="text"
                      label="Member Assesment"
                      className="inputAddMember"
                      fullWidth
                      defaultValue={mAssessment}
                      //   value={memberAddressTwo}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{ padding: "10px" }}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <TextField
                      type="text"
                      label="Member Eligible"
                      className="inputAddMember"
                      fullWidth
                      defaultValue={mEligible}
                      //   value={memberAddressTwo}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>

                  <Grid item sx={{ padding: "10px" }} xs={12}>
                    <FormControl fullWidth required>
                      <InputLabel>Year List</InputLabel>
                      <Controller
                        name="year"
                        control={control}
                        rules={{ required: "Year is Required" }}
                        defaultValue={memYear}
                        render={({ field }) => (
                          <Select
                            {...field}
                            label="Select Field"
                            className="inputAddMember"
                            variant="outlined"
                            disabled
                          >
                            <MenuItem value="">Select Year</MenuItem>
                            {yearOptions?.map((option) => (
                              <MenuItem key={option} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item sx={{ padding: "10px" }} xs={12}>
                    <FormControl required fullWidth>
                      <Controller
                        name={"effectiveDate"}
                        control={control}
                        render={({ field: { value } }) => (
                          <DesktopDatePicker
                            label="Member Enrollment Date"
                            className="inputAddMember"
                            value={value ? dayjs(value) : null}
                            onChange={() => {}}
                            disablePast
                            views={["year", "month", "day"]}
                            disabled
                            slotProps={{
                              textField: {
                                required: true,
                                readOnly: true,
                              },
                            }}
                          />
                        )}
                        rules={{
                          required: {
                            value: true,
                            message: "Effective Date is Required",
                          },
                        }}
                        defaultValue={memEffDate}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item sx={{ padding: "10px" }} xs={12}>
                    <FormControl required fullWidth>
                      <Controller
                        name={"termDate"}
                        control={control}
                        render={({ field: { value } }) => (
                          <DesktopDatePicker
                            label="Member Termed Date"
                            className="inputAddMember"
                            value={value ? dayjs(value) : null}
                            onChange={() => {}}
                            disablePast
                            views={["year", "month", "day"]}
                            disabled
                            slotProps={{
                              textField: {
                                required: true,
                                readOnly: true,
                              },
                            }}
                          />
                        )}
                        rules={{
                          required: {
                            value: true,
                            message: "Term Date is Required",
                          },
                        }}
                        defaultValue={memTermDate}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        </div>
      </form>
      {loading && <Loader />}
    </>
  );
}
