// src/ProfilePhotoUpload.js
import { Fade } from "@mui/material";
import React, { useState, useEffect } from "react";
//import './ProfilePhotoUpload.css'; // Import the CSS file

const ProfilePhotoUpload = ({ profilePic, setProfilePic, disabled }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState("");

  //   const handleFileChange = (event) => {
  //     const file = event.target.files[0];
  //     if (file) {
  //       setSelectedFile(file);
  //       const fileUrl = URL.createObjectURL(file);
  //       setPreviewUrl(fileUrl);
  //       setProfilePic(fileUrl);
  //     }
  //   };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        //setPreviewImage(reader.result);
        setPreviewUrl(reader.result);
        setProfilePic(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setPreviewUrl(null);
    }
  };

  useEffect(() => {
    console.log("profilePic", profilePic);
    setPreviewUrl(profilePic);
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle the upload process here, e.g., send the file to a server
    console.log(selectedFile);
  };

  return (
    <div>
      <form
        onSubmit={handleSubmit}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#8790f5",
          width: "250px",
        }}
      >
        <input
          type="file"
          accept="image/*"
          disabled={disabled}
          onChange={handleFileChange}
          style={{ display: "none" }}
          id="file-input"
        />
        <label
          htmlFor="file-input"
          className="upload-circle"
          style={{
            width: "200px",
            height: "200px",
            borderRadius: "50%",
            border: "2px dashed #ccc",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            overflow: "hidden",
            // backgroundColor:'red',
            //backgroundImage:url('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTMSgQGyTWI52ORnXhYGpUEVfJR3aQa8kadLQ&s'),
            marginBottom: "10px",
            marginTop: "10px",
          }}
        >
          {previewUrl ? (
            <img
              src={previewUrl}
              alt="Profile Preview"
              className="profile-image"
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          ) : (
            <div
              className="placeholder"
              style={{
                width: "100%",
                height: " 100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "16px",
                color: "#aaa",
              }}
            >
              <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQtH87ezXJb8GjssZrlGGI9kbAL8QxJ5MUEDQ&s"
                alt="Profile Preview"
                className="profile-image"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
              {/* <span>Upload Image</span> */}
            </div>
          )}
        </label>
      </form>
      <Fade in={!disabled} unmountOnExit>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <button
            type="button"
            onClick={() => {
              setSelectedFile(null);
              setPreviewUrl("");
            }}
            style={{ marginInline: "10px" }}
          >
            Cancel
          </button>
          <button
            disabled={!Boolean(selectedFile)}
            type="button"
            onClick={(e) => {
              handleSubmit(e);
            }}
          >
            Upload
          </button>
        </div>
      </Fade>
    </div>
  );
};

export default ProfilePhotoUpload;
