import React, { useState, useEffect } from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Typography, Box, Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTimePicker, TimePicker } from "@mui/x-date-pickers";
import Radio from "@mui/material/Radio";
import { styled } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import dayjs from "dayjs";
import { postApi, getApi } from "../../../../utils/axiosApiWrapper";
import ReviewReferralForm from "./ReviewReferralForm";
import ClinicianReviewReferral from "./ClinicianReviewReferral";
import toast from "react-hot-toast";
import utc from "dayjs/plugin/utc";
import { emailRegex } from "../../../../utils/appConstants";

dayjs.extend(utc);

const MemberCriticalReferralForm = ({
  responseData,
  selectedID,
  patientName,
  selectedSpecialist,
  assessId,
  selectedUniquMemberId,
  patientMemberId,
  memberName,
  clinicianName,
  qaMemberName,
  referralId,
  fetchReferralMemberData,
  fetchReferralMemberListData,
  memberDob,
  nurseDob,
  isReSubmit,
}) => {
  const CustomRadio = styled(Radio)(({ theme }) => ({
    color: "var(--mainTheme900)", // Unchecked color
    "&.Mui-checked": {
      color: "var(--mainTheme900)", // Checked color
    },
  }));

  const [openReviewDialog, setOpenReviewDialog] = useState(false);

  const [criticalIncident, setCriticalIncident] = useState({
    Harm: false,
    Medication: false,
    Death: false,
    Law: false,
    Accident: false,
    Hospice: false,
    Other: false,
  });
  const [name, setName] = useState("");
  const [dob, setDob] = useState(null);
  const [idNumber, setIdNumber] = useState("");
  const [telephoneNumber, setTelephoneNumber] = useState("");
  const [reportDate, setReportDate] = useState(null);
  const [providerName, setProviderName] = useState("");
  const [providerAgency, setProviderAgency] = useState("");
  const [providerAgencyContact, setProviderAgencyContact] = useState("");
  const [telNumber, setTelNumber] = useState("");
  const [email, setEmail] = useState("");
  const [dateOfIncident, setDateOfIncident] = useState(null);
  const [timeOfIncident, setTimeOfIncident] = useState(null);
  const [locationOfIncident, setLocationOfIncident] = useState("");
  const [nameOfStaff, setNameOfStaff] = useState("");
  const [nameOfAgencies, setNameOfAgencies] = useState("");
  const [describeTheIncident, setDescribeTheIncident] = useState("");
  const [describeAction, setDescribeAction] = useState("");
  const [describePlan, setDescribePlan] = useState("");
  const [ciReportComment, setCiReportComment] = useState("");
  const [notificationsComment, setNotificationsComment] = useState("");

  const [agenciesNotified, setAgenciesNotified] = useState({
    Police: false,
    EMS: false,
    Fire: false,
    Guardian: false,
    Other: false,
  });

  const [incident, setIncident] = useState({
    dateOfIncident: "",
    timeOfIncident: "",
    locationOfIncident: "",
    nameOfStaff: "",
    nameOfAgencies: "",
    describeTheIncident: "",
    describeAction: "",
    agenciesNotified: "",
  });

  const [patientId, setPatientId] = useState("");
  const [assignedClinician, setAssignedClinician] = useState("");
  //const [patientName, setPatientName] = useState("");
  const [qaName, setQaName] = useState("");
  const [openPopup, setOpenPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [referralData, setReferralData] = useState(null);
  const [emailError, setEmailError] = useState("");
  const [fieldErrors, setFieldErrors] = useState({});

  const handleOpenReviewDialog = async (
    // assessId,
    ID,
    Assigned_Clinician,
    PatientName,
    QaName,
  ) => {
    setPatientId(ID);
    setAssignedClinician(Assigned_Clinician);
    // setPatientName(PatientName);
    setQaName(QaName);
    // sessionStorage.setItem("assessmentId", assessId);
    SaveCriticalReferralFormData();
    try {
      // const assessId = sessionStorage.getItem("assessmentId", assessmentId);
      const response = await getApi(
        `/referral-form/query?id=${
          assessId || selectedUniquMemberId
        }&referralId=${referralId}`,
      );

      if (response.status === 200) {
        setReferralData(response?.data?.data || []);
      } else {
        console.error("Error: Failed to fetch PDF data.");

        // toast.error(response?.response?.data?.message || "Error while fetching data");
      }
      setOpenReviewDialog(true);
    } catch (error) {
      console.error("Error fetching referral report:", error);
    }
  };

  const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    setEmail(emailValue);

    // Validate email
    if (!emailRegex.test(emailValue)) {
      setEmailError("Please enter a valid email address.");
    } else {
      setEmailError("");
    }
  };

  const assessmentId = sessionStorage.getItem("assessmentId");

  const handleDateChange = (date) => {
    setDob(date);
  };

  const handleDateOfIncidentChange = (date) => {
    setDateOfIncident(date);
  };
  const handleTimeOfIncidentChange = (date) => {
    setTimeOfIncident(date);
  };
  const handleReportDateChange = (date) => {
    setReportDate(date);
  };

  //   critical: {
  //     ciReport: {
  //       harm: "1",
  //       medication: "1",
  //       recipient: "1",
  //       lawEnforcement: "1",
  //       accident: "1",
  //       hospice: "1",
  //       other: "1",
  //       comment: "text",
  //     },
  //     incident: {
  //       date: "2015-03-26",
  //       time: "10:58:51",
  //       location: "text",
  //       witness: "text",
  //       agencies: "text",
  //       circumstances: "text",
  //       response: "text",
  //       follow: "text",
  //     },
  //     notifications: {
  //       police: "1",
  //       ems: "1",
  //       fire: "1",
  //       guardian: "1",
  //       other: "1",
  //       otherSubAnswer: {
  //         comment: "text",
  //       },
  //     },
  //     memberTelephone: "text",
  //     dateOfReport: "2015-03-26T10:58:51",
  //     provider: "text",
  //     responsibleReport: "text",
  //     agencyContact: "text",
  //     pTelephone: "text",
  //     pEmail: "text",
  //   },
  // };

  const handleCloseReviewDialog = () => {
    setOpenReviewDialog(false);
  };

  const SaveCriticalReferralFormData = async () => {
    try {
      const payload = {
        id: assessId || selectedUniquMemberId,
        referralId: referralId, //optional
        critical: {
          ciReport: {
            harm:
              criticalIncident?.Harm == true
                ? "1"
                : criticalIncident?.Harm == false
                ? "2"
                : "",
            medication:
              criticalIncident?.Medication == true
                ? "1"
                : criticalIncident?.Medication == false
                ? "2"
                : "",
            recipient:
              criticalIncident?.Death == true
                ? "1"
                : criticalIncident?.Death == false
                ? "2"
                : "",
            lawEnforcement:
              criticalIncident?.Law == true
                ? "1"
                : criticalIncident?.Law == false
                ? "2"
                : "",
            accident:
              criticalIncident?.Accident == true
                ? "1"
                : criticalIncident?.Accident == false
                ? "2"
                : "",
            hospice:
              criticalIncident?.Hospice == true
                ? "1"
                : criticalIncident?.Hospice == false
                ? "2"
                : "",
            other:
              criticalIncident?.Other == true
                ? "1"
                : criticalIncident?.Other == false
                ? "2"
                : "",
            comment: ciReportComment,
          },
          incident: {
            date: dateOfIncident,
            time: timeOfIncident,
            location: locationOfIncident,
            witness: nameOfStaff,
            agencies: nameOfAgencies,
            circumstances: describeTheIncident,
            response: describeAction,
            follow: describePlan,
          },
          notifications: {
            police:
              agenciesNotified?.Police == true
                ? "1"
                : agenciesNotified?.Police == false
                ? "2"
                : "",
            ems:
              agenciesNotified?.EMS == true
                ? "1"
                : agenciesNotified?.EMS == false
                ? "2"
                : "",
            fire:
              agenciesNotified?.Fire == true
                ? "1"
                : agenciesNotified?.Fire == false
                ? "2"
                : "",
            guardian:
              agenciesNotified?.Guardian == true
                ? "1"
                : agenciesNotified?.Guardian == false
                ? "2"
                : "",
            other:
              agenciesNotified?.Other == true
                ? "1"
                : agenciesNotified?.Other == false
                ? "2"
                : "",
            comment: notificationsComment,
          },
          memberTelephone: telephoneNumber,
          dateOfReport: reportDate,
          provider: providerName,
          responsibleReport: providerAgency,
          agencyContact: providerAgencyContact,
          pTelephone: telNumber,
          pEmail: email,
        },
      };
      const response = await postApi("/referral-form/", payload);
      toast.success(response.data.message, {});
    } catch (error) {
      toast.error("Error while submitting Referral form", {});
    }
  };

  const roleId = sessionStorage.getItem("roleId");

  const fetchUrgentData = async () => {
    try {
      const response = await getApi(
        `/referral-form/?id=${
          assessId || selectedUniquMemberId
        }&referralId=${referralId}`,
      );

      if (roleId === "5") {
        setName(patientName);
      } else {
        setName(memberName);
      }
      if (roleId === "5") {
        setDob(nurseDob);
      } else {
        setDob(memberDob);
      }

      if (roleId === "5") {
        setIdNumber(selectedID);
      } else {
        setIdNumber(patientMemberId);
      }
      const data = response?.data?.data?.[0];

      setTelephoneNumber(data.memberTelephone);

      setReportDate(dayjs(data.reportDate));
      setProviderName(data.provider);
      setProviderAgency(data.responsibleReport);
      setProviderAgencyContact(data.agencyContact);
      setTelNumber(data.pTelephone);
      setEmail(data.pEmail);
      setCriticalIncident({
        Harm: data.ciReport.harm === "1",
        Medication: data.ciReport.medication === "1",
        Death: data.ciReport.recipient === "1",
        Law: data.ciReport.lawEnforcement === "1",
        Accident: data.ciReport.accident === "1",
        Hospice: data.ciReport.hospice === "1",
        Other: data.ciReport.other === "1",
      });
      setCiReportComment(data.ciReport.comment || "");

      // setIncident({
      setDateOfIncident(dayjs(data.incident.date));
      setTimeOfIncident(dayjs(data.incident.time));
      setLocationOfIncident(data.incident.location);
      setNameOfStaff(data.incident.witness);
      setNameOfAgencies(data.incident.agencies);
      setDescribeTheIncident(data.incident.circumstances);
      setDescribeAction(data.incident.response);
      setDescribePlan(data.incident.follow);
      // });
      setAgenciesNotified({
        Police: data.notifications.police === "1",
        EMS: data.notifications.ems === "1",
        Fire: data.notifications.fire === "1",
        Guardian: data.notifications.guardian === "1",
        Other: data.notifications.other === "1",
      });
      setNotificationsComment(data.notifications.comment || "");
      // setDiseaseOtherDescribe(
      //   data.urgent.diseaseCategory.otherSubAnswer.comment,
      // );

      // setName(sessionStorage.getItem("Name"));
      // setDob(sessionStorage.getItem("dob"));
      // setIdNumber(sessionStorage.getItem("memberId"));
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  useEffect(() => {
    // if (open) {
    fetchUrgentData();
    // }
  }, []);

  const validateValues = (name, value) => {
    let tempVal = "";
    if (name === "telephoneNumber" || name === "telNumber") {
      const keepRegex = /[^\d-]/g;
      tempVal = value?.replace(keepRegex, "");

      const digitCount = (tempVal.match(/\d/g) || []).length;

      if (digitCount > 10 || tempVal.length > 12) {
        return;
      }

      if (digitCount < 10) {
        setFieldErrors((prev) => ({
          ...prev,
          [name]: "Minimum length is 10 digits",
        }));
      } else {
        setFieldErrors((prev) => ({
          ...prev,
          [name]: "",
        }));
      }
    }
  };

  return (
    //1
    <Box>
      {/* <Box>
        <Typography
          variant="h5"
          style={{
            backgroundColor: "var(--mainTheme500)",
            color: "#fff",
            padding: "8px",
            borderRadius: "6px",
          }}
          marginTop={2}
        >
          Reason for Referral
        </Typography>
      </Box>
      <Box>
        <Typography
          variant="h5"
          style={{
            backgroundColor: "var(--mainTheme500)",
            color: "#fff",
            padding: "8px",
            borderRadius: "6px",
          }}
          marginTop={2}
        >
          Critical Incident Report
        </Typography>
      </Box> */}
      <Typography
        marginTop={4}
        style={{ fontSize: "18px", fontWeight: "bold" }}
      >
        Examples of incidents requiring a Critical Incident Report follows.
        Please check all categories which describe the incident.
      </Typography>
      <FormGroup>
        <Grid container spacing={2}>
          <Grid item sm={4}>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) => {
                    if (e?.target.checked) {
                      setCriticalIncident({ ...criticalIncident, Harm: true });
                    } else {
                      setCriticalIncident({
                        ...criticalIncident,
                        Harm: false,
                      });
                    }
                  }}
                  checked={criticalIncident.Harm}
                />
              }
              label="Harm to self or others"
            />
          </Grid>
          <Grid item sm={4}>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) => {
                    if (e?.target.checked) {
                      setCriticalIncident({
                        ...criticalIncident,
                        Medication: true,
                      });
                    } else {
                      setCriticalIncident({
                        ...criticalIncident,
                        Medication: false,
                      });
                    }
                  }}
                  checked={criticalIncident.Medication}
                />
              }
              label="Medication error requiring medical intervention"
            />
          </Grid>
          <Grid item sm={4}>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) => {
                    if (e?.target.checked) {
                      setCriticalIncident({ ...criticalIncident, Death: true });
                    } else {
                      setCriticalIncident({
                        ...criticalIncident,
                        Death: false,
                      });
                    }
                  }}
                  checked={criticalIncident.Death}
                />
              }
              label="Death of recipient"
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item sm={4}>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) => {
                    if (e?.target.checked) {
                      setCriticalIncident({ ...criticalIncident, Law: true });
                    } else {
                      setCriticalIncident({
                        ...criticalIncident,
                        Law: false,
                      });
                    }
                  }}
                  checked={criticalIncident.Law}
                />
              }
              label="Law enforcement response"
            />
          </Grid>
          <Grid item sm={4}>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) => {
                    if (e?.target.checked) {
                      setCriticalIncident({
                        ...criticalIncident,
                        Accident: true,
                      });
                    } else {
                      setCriticalIncident({
                        ...criticalIncident,
                        Accident: false,
                      });
                    }
                  }}
                  checked={criticalIncident.Accident}
                />
              }
              label="Accident/incident with medical intervention"
            />
          </Grid>
          <Grid item sm={4}>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) => {
                    if (e?.target.checked) {
                      setCriticalIncident({
                        ...criticalIncident,
                        Hospice: true,
                      });
                    } else {
                      setCriticalIncident({
                        ...criticalIncident,
                        Hospice: false,
                      });
                    }
                  }}
                  checked={criticalIncident.Hospice}
                />
              }
              label="Hospice"
            />
          </Grid>
          <Grid item sm={4}>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) => {
                    if (e?.target.checked) {
                      setCriticalIncident({ ...criticalIncident, Other: true });
                    } else {
                      setCriticalIncident({
                        ...criticalIncident,
                        Other: false,
                      });
                    }
                  }}
                  checked={criticalIncident.Other}
                />
              }
              label="Other"
            />
          </Grid>
        </Grid>
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            gap: "12px",
            marginTop: "24px",
          }}
        >
          <Typography style={{ fontSize: "18px", fontWeight: "bold" }}>
            Comment:
          </Typography>
          <TextField
            label=""
            value={ciReportComment}
            onChange={(e) => {
              setCiReportComment(e?.target?.value);
            }}
            style={{ border: "1px solid var(--mainTheme500)", width: "60%" }}
            variant="outlined"
          />
        </Box>
      </FormGroup>

      <Box>
        <Typography
          variant="h5"
          style={{
            backgroundColor: "var(--mainTheme500)",
            color: "#fff",
            padding: "8px",
            borderRadius: "6px",
          }}
          marginTop={4}
        >
          Members Information
        </Typography>
      </Box>
      <Box style={{ paddingTop: "16px" }}>
        <Typography style={{ fontSize: "18px", fontWeight: "bold" }}>
          Name:
        </Typography>
        <TextField
          label=""
          style={{ border: "1px solid var(--mainTheme500)", width: "45vw" }}
          variant="outlined"
          value={name}
          disabled
          onChange={(e) => {
            setName(e?.target?.value);
          }}
        />
      </Box>
      <Divider
        sx={{
          marginTop: "32px",
          marginBottom: "16px",
          borderColor: "#0000004d",
          borderBottomWidth: "thin",
          width: "100%",
        }}
      />
      <Box style={{ paddingTop: "16px", width: "45vw" }}>
        <Typography style={{ fontSize: "18px", fontWeight: "bold" }}>
          Date of Birth:
        </Typography>
        <LocalizationProvider
          sx={{ border: "1px solid var(--mainTheme500)", width: "43vw" }}
          dateAdapter={AdapterDayjs}
        >
          <DemoContainer
            sx={{ border: "1px solid var(--mainTheme500)", width: "40%" }}
            components={["DatePicker"]}
          >
            <DatePicker
              value={dayjs(new Date(dob))}
              variant="outlined"
              disabled
              onChange={(date) => {
                handleDateChange(date);
              }}
              label=""
            />
          </DemoContainer>
        </LocalizationProvider>
      </Box>
      <Divider
        sx={{
          marginTop: "32px",
          marginBottom: "16px",
          borderColor: "#0000004d",
          borderBottomWidth: "thin",
          width: "100%",
        }}
      />
      <Box style={{ paddingTop: "16px" }}>
        <Typography style={{ fontSize: "18px", fontWeight: "bold" }}>
          ID Number:
        </Typography>
        <TextField
          label=""
          type="number"
          style={{ border: "1px solid var(--mainTheme500)", width: "45vw" }}
          variant="outlined"
          disabled
          value={idNumber}
          onChange={(e) => {
            setIdNumber(e?.target?.value);
          }}
        />
      </Box>
      <Divider
        sx={{
          marginTop: "32px",
          marginBottom: "16px",
          borderColor: "#0000004d",
          borderBottomWidth: "thin",
          width: "100%",
        }}
      />
      <Box style={{ paddingTop: "16px" }}>
        <Typography style={{ fontSize: "18px", fontWeight: "bold" }}>
          Member Telephone number:
        </Typography>
        <TextField
          label=""
          type="text"
          style={{ border: "1px solid var(--mainTheme500)", width: "45vw" }}
          variant="outlined"
          value={telephoneNumber}
          onChange={(e) => {
            const { value } = e.target;
            const sanitizedValue = value.replace(/\s/g, "");
            const keepRegex = /[^\d-]/g;
            let tempVal = sanitizedValue.replace(keepRegex, "");

            const digitCount = (tempVal.match(/\d/g) || []).length;

            if (digitCount > 10 || tempVal.length > 12) {
              return;
            }

            setTelephoneNumber(tempVal);
            validateValues("telephoneNumber", tempVal);
          }}
        />
        {fieldErrors?.telephoneNumber && (
          <Typography style={{ color: "red" }}>
            {fieldErrors.telephoneNumber}
          </Typography>
        )}
      </Box>
      <Divider
        sx={{
          marginTop: "32px",
          marginBottom: "16px",
          borderColor: "#0000004d",
          borderBottomWidth: "thin",
          width: "100%",
        }}
      />
      <Box>
        <Typography
          variant="h5"
          style={{
            backgroundColor: "var(--mainTheme500)",
            color: "#fff",
            padding: "8px",
            borderRadius: "6px",
          }}
          marginTop={4}
        >
          Contact Information
        </Typography>
      </Box>
      <Box style={{ paddingTop: "16px", width: "45vw" }}>
        <Typography style={{ fontSize: "18px", fontWeight: "bold" }}>
          Date of this report:
        </Typography>
        <LocalizationProvider
          sx={{ border: "1px solid var(--mainTheme500)", width: "43vw" }}
          dateAdapter={AdapterDayjs}
        >
          <DemoContainer
            sx={{ border: "1px solid var(--mainTheme500)", width: "40%" }}
            components={["DatePicker"]}
          >
            <DateTimePicker
              value={reportDate}
              variant="outlined"
              onChange={(date) => {
                handleReportDateChange(date);
              }}
              label=""
            />
          </DemoContainer>
        </LocalizationProvider>
      </Box>
      <Divider
        sx={{
          marginTop: "32px",
          marginBottom: "16px",
          borderColor: "#0000004d",
          borderBottomWidth: "thin",
          width: "100%",
        }}
      />
      <Box style={{ paddingTop: "16px" }}>
        <Typography style={{ fontSize: "18px", fontWeight: "bold" }}>
          Name of incident reporter (PROVIDER):
        </Typography>
        <TextField
          label=""
          style={{ border: "1px solid var(--mainTheme500)", width: "45vw" }}
          variant="outlined"
          value={providerName}
          onChange={(e) => {
            setProviderName(e?.target?.value);
          }}
        />
      </Box>
      <Divider
        sx={{
          marginTop: "32px",
          marginBottom: "16px",
          borderColor: "#0000004d",
          borderBottomWidth: "thin",
          width: "100%",
        }}
      />
      <Box style={{ paddingTop: "16px" }}>
        <Typography style={{ fontSize: "18px", fontWeight: "bold" }}>
          Provider agency responsible for report:
        </Typography>
        <TextField
          label=""
          style={{ border: "1px solid var(--mainTheme500)", width: "45vw" }}
          variant="outlined"
          value={providerAgency}
          onChange={(e) => {
            setProviderAgency(e?.target?.value);
          }}
        />
      </Box>
      <Divider
        sx={{
          marginTop: "32px",
          marginBottom: "16px",
          borderColor: "#0000004d",
          borderBottomWidth: "thin",
          width: "100%",
        }}
      />
      <Box style={{ paddingTop: "16px" }}>
        <Typography style={{ fontSize: "18px", fontWeight: "bold" }}>
          Provider agency contact:
        </Typography>
        <TextField
          label=""
          style={{ border: "1px solid var(--mainTheme500)", width: "45vw" }}
          variant="outlined"
          value={providerAgencyContact}
          onChange={(e) => {
            setProviderAgencyContact(e?.target?.value);
          }}
        />
      </Box>
      <Divider
        sx={{
          marginTop: "32px",
          marginBottom: "16px",
          borderColor: "#0000004d",
          borderBottomWidth: "thin",
          width: "100%",
        }}
      />
      <Box style={{ paddingTop: "16px" }}>
        <Typography style={{ fontSize: "18px", fontWeight: "bold" }}>
          Telephone number:
        </Typography>
        <TextField
          label=""
          type="number"
          style={{ border: "1px solid var(--mainTheme500)", width: "45vw" }}
          variant="outlined"
          value={telNumber}
          // onChange={(e) => {
          //   setTelNumber(e?.target?.value);
          // }}
          onChange={(e) => {
            const { value } = e.target;
            const sanitizedValue = value.replace(/\s/g, "");
            const keepRegex = /[^\d-]/g;
            let tempVal = sanitizedValue.replace(keepRegex, "");

            const digitCount = (tempVal.match(/\d/g) || []).length;

            if (digitCount > 10 || tempVal.length > 12) {
              return;
            }

            setTelNumber(tempVal);
            validateValues("telNumber", tempVal);
          }}
        />
        {fieldErrors?.telNumber && (
          <Typography style={{ color: "red" }}>
            {fieldErrors.telNumber}
          </Typography>
        )}
      </Box>
      <Divider
        sx={{
          marginTop: "32px",
          marginBottom: "16px",
          borderColor: "#0000004d",
          borderBottomWidth: "thin",
          width: "100%",
        }}
      />
      <Box style={{ paddingTop: "16px" }}>
        <Typography style={{ fontSize: "18px", fontWeight: "bold" }}>
          Email:
        </Typography>
        <TextField
          label=""
          style={{ border: "1px solid var(--mainTheme500)", width: "45vw" }}
          variant="outlined"
          value={email}
          onChange={handleEmailChange}
        />
      </Box>
      {emailError && (
        <Typography style={{ color: "red", marginTop: "8px" }}>
          {emailError}
        </Typography>
      )}
      <Divider
        sx={{
          marginTop: "32px",
          marginBottom: "16px",
          borderColor: "#0000004d",
          borderBottomWidth: "thin",
          width: "100%",
        }}
      />
      <Box>
        <Typography
          variant="h5"
          style={{
            backgroundColor: "var(--mainTheme500)",
            color: "#fff",
            padding: "8px",
            borderRadius: "6px",
          }}
          marginTop={2}
        >
          Incident information
        </Typography>
      </Box>
      <Divider
        sx={{
          marginTop: "32px",
          marginBottom: "16px",
          borderColor: "#0000004d",
          borderBottomWidth: "thin",
          width: "100%",
        }}
      />
      <Box>
        <Typography
          variant="h5"
          style={{
            //backgroundColor: "var(--mainTheme500)",
            //color: "#fff",
            padding: "8px",
            fontSize: "22px",
            //borderRadius: "6px",
          }}
          marginTop={2}
        >
          (Please write “Not Applicable” where an item does not pertain to the
          incident.)
        </Typography>
      </Box>
      <Divider
        sx={{
          marginTop: "32px",
          marginBottom: "16px",
          borderColor: "#0000004d",
          borderBottomWidth: "thin",
          width: "100%",
        }}
      />
      <Box style={{ paddingTop: "16px", width: "45vw" }}>
        <Typography style={{ fontSize: "18px", fontWeight: "bold" }}>
          Date of incident:
        </Typography>
        <LocalizationProvider
          sx={{ border: "1px solid var(--mainTheme500)", width: "43vw" }}
          dateAdapter={AdapterDayjs}
        >
          <DemoContainer
            sx={{ border: "1px solid var(--mainTheme500)", width: "40%" }}
            components={["DatePicker"]}
          >
            <DatePicker
              value={dateOfIncident}
              variant="outlined"
              onChange={(date) => {
                handleDateOfIncidentChange(date);
              }}
              label=""
            />
          </DemoContainer>
        </LocalizationProvider>
      </Box>
      <Divider
        sx={{
          marginTop: "32px",
          marginBottom: "16px",
          borderColor: "#0000004d",
          borderBottomWidth: "thin",
          width: "100%",
        }}
      />
      <Box style={{ paddingTop: "16px", width: "45vw" }}>
        <Typography style={{ fontSize: "18px", fontWeight: "bold" }}>
          Time of incident:
        </Typography>
        <LocalizationProvider
          sx={{ border: "1px solid var(--mainTheme500)", width: "43vw" }}
          dateAdapter={AdapterDayjs}
        >
          <DemoContainer
            sx={{ border: "1px solid var(--mainTheme500)", width: "40%" }}
            components={["TimePicker"]}
          >
            <TimePicker
              value={timeOfIncident}
              variant="outlined"
              onChange={(date) => {
                handleTimeOfIncidentChange(date);
              }}
              label=""
            />
          </DemoContainer>
        </LocalizationProvider>
      </Box>
      <Divider
        sx={{
          marginTop: "32px",
          marginBottom: "16px",
          borderColor: "#0000004d",
          borderBottomWidth: "thin",
          width: "100%",
        }}
      />

      <Box style={{ paddingTop: "16px" }}>
        <Typography style={{ fontSize: "18px", fontWeight: "bold" }}>
          Location of incident (include address):
        </Typography>
        <TextField
          label=""
          style={{ border: "1px solid var(--mainTheme500)", width: "45vw" }}
          variant="outlined"
          multiline
          value={locationOfIncident}
          onChange={(e) => {
            setLocationOfIncident(e?.target?.value);
          }}
        />
      </Box>
      <Divider
        sx={{
          marginTop: "32px",
          marginBottom: "16px",
          borderColor: "#0000004d",
          borderBottomWidth: "thin",
          width: "100%",
        }}
      />

      <Box style={{ paddingTop: "16px" }}>
        <Typography style={{ fontSize: "18px", fontWeight: "bold" }}>
          Names of individuals/staff involved in or witness to the incident:
        </Typography>
        <TextField
          label=""
          style={{ border: "1px solid var(--mainTheme500)", width: "45vw" }}
          variant="outlined"
          multiline
          value={nameOfStaff}
          onChange={(e) => {
            setNameOfStaff(e?.target?.value);
          }}
        />
      </Box>
      <Divider
        sx={{
          marginTop: "32px",
          marginBottom: "16px",
          borderColor: "#0000004d",
          borderBottomWidth: "thin",
          width: "100%",
        }}
      />
      <Box style={{ paddingTop: "16px" }}>
        <Typography style={{ fontSize: "18px", fontWeight: "bold" }}>
          Names of other agencies involved in the incident:
        </Typography>
        <TextField
          label=""
          style={{ border: "1px solid var(--mainTheme500)", width: "45vw" }}
          variant="outlined"
          multiline
          value={nameOfAgencies}
          onChange={(e) => {
            setNameOfAgencies(e?.target?.value);
          }}
        />
      </Box>
      <Divider
        sx={{
          marginTop: "32px",
          marginBottom: "16px",
          borderColor: "#0000004d",
          borderBottomWidth: "thin",
          width: "100%",
        }}
      />
      <Box style={{ paddingTop: "16px" }}>
        <Typography style={{ fontSize: "18px", fontWeight: "bold" }}>
          Describe the incident including circumstances or events leading to the
          incident:
        </Typography>
        <TextField
          label=""
          style={{ border: "1px solid var(--mainTheme500)", width: "45vw" }}
          variant="outlined"
          multiline
          value={describeTheIncident}
          onChange={(e) => {
            setDescribeTheIncident(e?.target?.value);
          }}
        />
      </Box>
      <Divider
        sx={{
          marginTop: "32px",
          marginBottom: "16px",
          borderColor: "#0000004d",
          borderBottomWidth: "thin",
          width: "100%",
        }}
      />
      <Box style={{ paddingTop: "16px" }}>
        <Typography style={{ fontSize: "18px", fontWeight: "bold" }}>
          Describe actions taken in response to the incident:
        </Typography>
        <TextField
          label=""
          style={{ border: "1px solid var(--mainTheme500)", width: "45vw" }}
          variant="outlined"
          multiline
          value={describeAction}
          onChange={(e) => {
            setDescribeAction(e?.target?.value);
          }}
        />
      </Box>
      <Divider
        sx={{
          marginTop: "32px",
          marginBottom: "16px",
          borderColor: "#0000004d",
          borderBottomWidth: "thin",
          width: "100%",
        }}
      />
      <Box style={{ paddingTop: "16px" }}>
        <Typography style={{ fontSize: "18px", fontWeight: "bold" }}>
          Describe plans for provider agency follow-up:
        </Typography>
        <TextField
          label=""
          style={{ border: "1px solid var(--mainTheme500)", width: "45vw" }}
          variant="outlined"
          multiline
          value={describePlan}
          onChange={(e) => {
            setDescribePlan(e?.target?.value);
          }}
        />
      </Box>
      <Divider
        sx={{
          marginTop: "32px",
          marginBottom: "16px",
          borderColor: "#0000004d",
          borderBottomWidth: "thin",
          width: "100%",
        }}
      />
      <Box>
        <Typography
          variant="h5"
          style={{
            backgroundColor: "var(--mainTheme500)",
            color: "#fff",
            padding: "8px",
            borderRadius: "6px",
          }}
          marginTop={2}
        >
          Notifications
        </Typography>
      </Box>
      <Box style={{ paddingY: "16px" }}>
        <Typography
          marginTop={4}
          style={{ fontSize: "18px", fontWeight: "bold" }}
        >
          Please check other agencies and individuals you notified regarding
          this incident.
        </Typography>
        <FormGroup>
          <Grid container spacing={2}>
            <Grid item sm={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) => {
                      if (e?.target.checked) {
                        setAgenciesNotified({
                          ...agenciesNotified,
                          Police: true,
                        });
                      } else {
                        setAgenciesNotified({
                          ...agenciesNotified,
                          Police: false,
                        });
                      }
                    }}
                    checked={agenciesNotified.Police}
                  />
                }
                label="Police/law enforcement "
              />
            </Grid>
            <Grid item sm={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) => {
                      if (e?.target.checked) {
                        setAgenciesNotified({ ...agenciesNotified, EMS: true });
                      } else {
                        setAgenciesNotified({
                          ...agenciesNotified,
                          EMS: false,
                        });
                      }
                    }}
                    checked={agenciesNotified.EMS}
                  />
                }
                label="EMS"
              />
            </Grid>
            <Grid item sm={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) => {
                      if (e?.target.checked) {
                        setAgenciesNotified({
                          ...agenciesNotified,
                          Fire: true,
                        });
                      } else {
                        setAgenciesNotified({
                          ...agenciesNotified,
                          Fire: false,
                        });
                      }
                    }}
                    checked={agenciesNotified.Fire}
                  />
                }
                label="Fire Department"
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item sm={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) => {
                      if (e?.target.checked) {
                        setAgenciesNotified({
                          ...agenciesNotified,
                          Guardian: true,
                        });
                      } else {
                        setAgenciesNotified({
                          ...agenciesNotified,
                          Guardian: false,
                        });
                      }
                    }}
                    checked={agenciesNotified.Guardian}
                  />
                }
                label="Guardian/Legal Representative"
              />
            </Grid>
            <Grid item sm={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) => {
                      if (e?.target.checked) {
                        setAgenciesNotified({
                          ...agenciesNotified,
                          Other: true,
                        });
                      } else {
                        setAgenciesNotified({
                          ...agenciesNotified,
                          Other: false,
                        });
                      }
                    }}
                    checked={agenciesNotified.Other}
                  />
                }
                label="Other"
              />
            </Grid>
          </Grid>
        </FormGroup>
      </Box>

      {/* <Divider
        sx={{
          marginTop: "32px",
          marginBottom: "16px",
          borderColor: "#0000004d",
          borderBottomWidth: "thin",
          width: "100%",
        }}
      /> */}

      <Box style={{ position: "fixed", bottom: "35px", right: "200px" }}>
        <Box display="flex">
          <Box margin={1}>
            <Button
              sx={{
                backgroundColor: "var(--mainTheme900)",
                color: "white",
                width: "100%",
                padding: "21px 16px !important",
                "&:hover": {
                  backgroundColor: "var(--mainTheme500)",
                },
              }}
              //  autoFocus
              onClick={handleOpenReviewDialog}
            >
              Review Referral
            </Button>
          </Box>
        </Box>
      </Box>
      <ReviewReferralForm
        open={openReviewDialog}
        onClose={handleCloseReviewDialog}
        selectedID={selectedID}
        selectedSpecialist={selectedSpecialist}
        patientName={patientName}
        referralData={referralData}
        assessId={assessId}
        patientMemberId={patientMemberId}
        memberName={memberName}
        clinicianName={clinicianName}
        qaMemberName={qaMemberName}
        referralId={referralId}
        selectedUniquMemberId={selectedUniquMemberId}
        fetchReferralMemberData={fetchReferralMemberData}
        fetchReferralMemberListData={fetchReferralMemberListData}
        isReSubmit={isReSubmit}
      />
    </Box>
  );
};
export default MemberCriticalReferralForm;
