import React, { useState, useEffect, useContext, useMemo } from "react";
import DataTableComponent from "../../components/Dashboard/DataTable/DataTable";
import QAHeader from "./QaHeader";
import { SelectDropdown } from "../../components/select/select";
import { HraStatusSelectDropdown } from "../../components/select/hraSelectDropDown";
import MuiInput from "../../components/muiinput/MuiInput";
import TooltipEllipsisCell from "../../components/TooltipEllipsisCell/TooltipEllipsisCell";
import "./qalist.css";
import Button from "../../components/button/button";
import { useNavigate } from "react-router-dom";
import { getApi, postApi, putApi } from "../../utils/axiosApiWrapper";
import { useUserContext } from "../../contexts/UserContext";
import Loader from "../../components/Loader/Loader";
import VphpDialog from "../../components/Dashboard/MemberList/vphp/vphp";
import Alert from "../../components/Alert/Alert";
import Toaster from "../../components/toaster/toaster";
import _ from "lodash";
import Labels from "../../resource_files/Labels.resx";
import AppointmentModal from "../../components/AppointmentModal/AppointmentModal";
import { Checkbox } from "@mui/material";
import AlertContext from "../../contexts/AlertContext";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import moment from "moment";
import ReviewReferralForm from "../AdminDashboard/MemberListPage/Referral/ReviewReferralForm";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";

// Extend Day.js with plugins
dayjs.extend(utc);
dayjs.extend(timezone);

const Qalist = () => {
  const [assignedClinicianValues, setAssignedClinicianValues] = useState([]);
  const [assignedCodingValues, setAssignedCodingValues] = useState([]);
  const [assignedQaValues, setAssignedQaValues] = useState([]);
  const [assignedTermDateValues, setAssignedTermDateValues] = useState([]);
  const [assignedScheduleDateValues, setAssignedScheduleDateValues] = useState(
    [],
  );
  const [visitTypeValues, setVisitTypeValues] = useState([]);
  const [scheduleDateValues, setScheduleDateValues] = useState({});
  const [termDateValues, setTermDateValues] = useState([]);
  const [selectedDropdownValue, setSelectedDropdownValue] = useState("");
  const [responseData, setResponseData] = useState([]);
  const [loading, setLoading] = useState(false);
  const {
    clientData,
    isDialogOpen,
    setClientData,
    apiResponseData,
    setApiResponseData,
    selectedTimeZoneUtc,
  } = useUserContext();
  const [hraStatusOptions, setHraStatusOptions] = useState([]);
  const [visitTypeOptions, setVisitTypeOptions] = useState([]);
  const [clinicianData, setClinicianData] = useState([]);
  const { updateAssessmentData, getAssessment } = useUserContext();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRowCompletedCheckboxes, setSelectedRowCompletedCheckboxes] =
    useState({});
  const [
    selectAllCompletedHeaderCheckbox,
    setSelectAllCompletedHeaderCheckbox,
  ] = useState(false);

  const [selectedRowUploadCheckboxes, setSelectedRowUploadCheckboxes] =
    useState({});
  const [selectAllUploadHeaderCheckbox, setSelectAllUploadHeaderCheckbox] =
    useState(false);
  const [selectedRowCheckboxes, setSelectedRowCheckboxes] = useState({});
  const [areCheckboxesSelected, setAreCheckboxesSelected] = useState(false);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState({});
  const [isActivateAlertOpen, setIsActivateAlertOpen] = useState(false);
  const [toasterMessage, setToasterMessage] = useState("");
  const [toasterSeverity, setToasterSeverity] = useState(Labels.SUCCESS);
  const [toasterOpen, setToasterOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState({
    memberId: "",
    assessmentId: "",
    Name: "",
    plan: "",
    _id: "",
  });
  const [hraStatus, setHraStatus] = useState([]);
  const [hraStatusOptionForFilter, setHraStatusOptionForFilter] = useState([]);
  const [isVisitTypeAlertOpen, setIsVisitTypeAlertOpen] = useState(false);
  const [isAssignedClinicianAlertOpen, setIsAssignedClinicianAlertOpen] =
    useState(false);
  const [isAssignedCodingAlertOpen, setIsAssignedCodingAlertOpen] =
    useState(false);
  const [isAssignedQaAlertOpen, setIsAssignedQaAlertOpen] = useState(false);
  const [isAssignedTermDateAlertOpen, setIsAssignedTermDateAlertOpen] =
    useState(false);
  const [isAssignedScheduleDateAlertOpen, setIsAssignedScheduleDateAlertOpen] =
    useState(false);
  const [selectedVisitTypeChange, setSelectedVisitTypeChange] = useState(null);
  const [selectedHraStatusTypeChange, setSelectedHraStatusTypeChange] =
    useState(null);
  const [selectedAssignedClinicianChange, setSelectedAssignedClinicianChange] =
    useState(null);
  const [selectedAssignedCodingChange, setSelectedAssignedCodingChange] =
    useState(null);
  const [selectedAssignedTermDateChange, setSelectedAssignedTermDateChange] =
    useState(null);
  const [
    selectedAssignedScheduleDateChange,
    setSelectedAssignedScheduleDateChange,
  ] = useState(null);
  const [selectedAssignedQaChange, setSelectedAssignedQaChange] =
    useState(null);

  const [visitTypeData, setVisitTypeData] = useState([]);
  const [isCancelAppointmentAlertOpen, setIsCancelAppointmentAlertOpen] =
    useState(false);
  const [clinicianOptions, setClinicianOptions] = useState([]);
  const [coderOptions, setCoderOptions] = useState([]);
  const [qaOptions, setQaOptions] = useState([]);
  const [data, setData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [selectedCoderRowIndex, setSelectedCoderRowIndex] = useState(null);
  const [selectedTermDateRowIndex, setSelectedTermDateRowIndex] =
    useState(null);
  const [selectedScheduleDateRowIndex, setSelectedScheduleDateRowIndex] =
    useState(null);
  const [selectedQaRowIndex, setSelectedQaRowIndex] = useState(null);
  const [usersByProgramRoleId, setUsersByProgramRoleId] = useState([]);
  const alertContext = useContext(AlertContext);
  const navigate = useNavigate();
  const storedPlan = localStorage.getItem("clientPlan") || clientData?.plan;
  const storedYear = localStorage.getItem("clientYear") || clientData?.year;
  const storedScreeningTypes = localStorage.getItem("storedScreeningTypes");

  const handleVphpSelection = (responseData) => {
    setResponseData(responseData);
  };

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("defaultColumnName");
  const [tabId, setTabId] = useState(1);
  const [openReviewDialog, setOpenReviewDialog] = useState(false);
  const [patientId, setPatientId] = useState("");
  const [assignedClinician, setAssignedClinician] = useState("");
  const [patientName, setPatientName] = useState("");
  const [qaName, setQaName] = useState("");
  const [openPopup, setOpenPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [referralData, setReferralData] = useState(null);

  const [referralId, setReferralId] = useState("");

  const handleReviewClick = async (assessmentId) => {
    try {
      const assessId = sessionStorage.getItem("assessmentId", assessmentId);

      const response = await getApi(
        `/referral-form/?id=${assessId}&referralId=${referralId}`,
      );

      const data = response?.data?.data?.[0];
      setReferralId(data?._id);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  const handleOpenReviewDialog = async (
    assessmentId,
    ID,
    Assigned_Clinician,
    PatientName,
    QaName,
    referralId,
  ) => {
    setPatientId(ID);
    setAssignedClinician(Assigned_Clinician);
    setPatientName(PatientName);
    setQaName(QaName);
    setReferralId(referralId);

    sessionStorage.setItem("assessmentId", assessmentId);
    // handleReviewClick();
    try {
      const assessId = sessionStorage.getItem("assessmentId", assessmentId);
      const response = await getApi(
        `/referral-form/query?id=${assessId}&referralId=${referralId}`,
      );

      if (response.status === 200) {
        setReferralData(response?.data?.data || []);
      } else {
        console.error("Error: Failed to fetch PDF data.");

        // toast.error(response?.response?.data?.message || "Error while fetching data");
      }
      setOpenReviewDialog(true);
    } catch (error) {
      console.error("Error fetching referral report:", error);
    }
  };

  const handleCloseReviewDialog = () => {
    setOpenReviewDialog(false);
  };
  const PopupDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiPaper-root": {
      width: "50%", // Change this to the desired width
      maxWidth: "none", // Prevents the dialog from shrinking
    },
  }));

  const ReviewDialog = ({ open, onClose, message }) => (
    <PopupDialog onClose={onClose} open={open}>
      <DialogContent style={{ alignSelf: "center" }}>
        <Typography style={{ fontSize: "18px", fontWeight: "500 " }}>
          {message}
        </Typography>
      </DialogContent>
      <DialogActions style={{ alignSelf: "center" }}>
        <Button onClick={onClose} color="primary">
          OK
        </Button>
      </DialogActions>
    </PopupDialog>
  );

  //"Ready For Qa", "Clinician Review"
  const handleTabChange = (newValue) => {
    let updatedTabId;
    if (newValue === "Ready For QA") {
      updatedTabId = 1;
    } else if (newValue === "Clinician Review") {
      updatedTabId = 2;
    } else if (newValue === "Referral") {
      updatedTabId = 4;
    } else {
      updatedTabId = null;
    }

    setTabId(updatedTabId);
    setSelectedDropdownValue(newValue);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (tabId !== null && storedPlan && storedYear) {
        await fetchMemberData(storedPlan, storedYear, tabId);
      }
    };

    fetchData();
  }, [tabId, storedPlan, storedYear]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  useEffect(() => {
    if (storedPlan && storedYear) {
      fetchMemberData(storedPlan, storedYear);
    }
  }, [storedPlan, storedYear, page, rowsPerPage]);

  const fetchMemberData = async (
    plan,
    date,
    filter,
    searchTerm,
    defaultSortBy,
    defaultOrder,
  ) => {
    setLoading(true);
    try {
      var DaySorting = "";
      if (defaultSortBy == "Days") {
        if (tabId == 1) {
          var DaySorting = "readyForQaDate";
        } else if (tabId == 2) {
          var DaySorting = "qaReviewDate";
        } else {
          var DaySorting = "";
        }
      }

      const columnMappings = {
        ID: "memberId",
        Name: "memberDemographyData.demographics.data.firstName",
        Program: "program",
        LOB: "memberDemographyData.demographics.data.lob",
        City: "memberDemographyData.demographics.data.city",
        Assigned_Clinician: "clinicanUserData.firstName",
        QA_Analyst: "qaUserData.firstName",
        Schedule_Date: "scheduleDate",
        Visit_Type: "visitType",
        Coding_Analyst: "coderUserData.firstName",
        Days: DaySorting,
      };
      const params = {
        page: page + 1,
        limit: searchTerm ? 60 : rowsPerPage,
        year: date,
        planId: plan,
        searchTerm,
        fieldName:
          filter === "First name"
            ? "memberDemographyData.demographics.data.firstName"
            : filter === "Identification#"
            ? "memberId"
            : filter === "Last name"
            ? "memberDemographyData.demographics.data.lastName"
            : null,
        sortValue: defaultOrder,
        sortBy: columnMappings[defaultSortBy]
          ? columnMappings[defaultSortBy]
          : defaultSortBy,
        tabId: tabId,
      };
      if (searchTerm && searchTerm !== "null" && searchTerm !== "") {
        params.searchTerm = searchTerm;
      }
      const response = await getApi("/dashboard/get-members-for-dashboard", {
        params,
      });
      const totalCount = response?.data?.count ?? 0;
      setTotalRowCount(totalCount);
      handleVphpSelection(response?.data ?? []);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    const formattedDate = date?.toISOString()?.split("T")[0]; // Extracts "YYYY-MM-DD" part
    return formattedDate;
  };

  const fetchReferalReport = async (assessmentId) => {
    sessionStorage.setItem("assessmentId", assessmentId);

    try {
      const assessId = sessionStorage.getItem("assessmentId", assessmentId);
      const response = await getApi(
        `/referral-form/generate-pdf?id=${assessId}`,
      );

      if (response.status === 200) {
        const url = `${process.env.REACT_APP_API_BASE_URL}${response.data.path}`;

        const newTab = window.open(url, "_blank");

        if (newTab) {
          newTab.focus();
        }
      } else {
        console.error("Error: Failed to fetch PDF data.");

        // toast.error(response?.response?.data?.message || "Error while fetching data");
      }
    } catch (error) {
      console.error("Error fetching referral report:", error);
    }
  };

  const fetchReferralQaData = async (
    plan,
    date,
    filter,
    searchTerm,
    defaultSortBy,
    defaultOrder,
  ) => {
    setLoading(true);
    try {
      const columnMappings = {
        ID: "memberId",
        Name: "memberDemographyData.demographics.data.firstName",
        Assigned_Specialist: "qaUserData.firstName",
        Submitted_By: "clinicanUserData.firstName",
        Referral_Status:
          "memberDemographyData.referral.referralForm.referralStatus",
        Referral_Type: "memberDemographyData.referral.referralForm.type",
        Date_Of_Referral:
          "memberDemographyData.referral.referralForm.dateOfReport",
        Referral_Sent_To_Qa_Date:
          "memberDemographyData.referral.referralForm.dateOfReport.date",
        //Referral_Sent_To_Plan_Date: "visitType",
      };
      const params = {
        page: page + 1,
        limit: rowsPerPage,
        year: date,
        planId: plan,
        searchTerm,
        fieldName:
          filter === "First name"
            ? "memberDemographyData.demographics.data.firstName"
            : filter === "Identification#"
            ? "memberId"
            : filter === "Last name"
            ? "memberDemographyData.demographics.data.lastName"
            : null,
        sortValue: defaultOrder,
        sortBy: columnMappings[defaultSortBy]
          ? columnMappings[defaultSortBy]
          : defaultSortBy,
        tabId: 4,
      };
      if (searchTerm && searchTerm !== "null" && searchTerm !== "") {
        params.searchTerm = searchTerm;
      }
      const response = await getApi("/dashboard/get-members-for-dashboard", {
        params,
      });

      if (response && response.data) {
        setResponseData(response.data);
        const totalCount = response?.data?.count;
        setTotalRowCount(totalCount);
      } else {
        console.error("API response does not contain expected data", response);
        setResponseData([]);
      }
    } catch (error) {
      console.error("API call failed", error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedDropdownValue === "Referral") {
      fetchReferralQaData(storedPlan, storedYear);
    }
  }, [selectedDropdownValue]);

  //unassigned
  const fetchReferralQaDataUnassigned = async (plan, date) => {
    setLoading(true);
    try {
      const params = {
        page: page + 1,
        limit: rowsPerPage,
        year: date,
        planId: plan,
        tabId: 4,
        unAssigned: 1,
      };

      const response = await getApi("/dashboard/get-members-for-dashboard", {
        params,
      });

      if (response && response.data) {
        setResponseData(response.data);
        const totalCount = response?.data?.count;
        setTotalRowCount(totalCount);
      } else {
        console.error("API response does not contain expected data", response);
        setResponseData([]);
      }
    } catch (error) {
      console.error("API call failed", error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  // Handler to pass to qaliat
  const handleCheckboxChange = async (event) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      await fetchReferralQaDataUnassigned(storedPlan, storedYear);
    } else {
      await fetchReferralQaData(storedPlan, storedYear);
    }
  };

  useEffect(() => {
    if (!_.isNull(alertContext?.filterDialogData)) {
      handleFilter();
    } else {
      if (storedPlan && storedYear) fetchMemberData(storedPlan, storedYear);
    }
  }, [alertContext?.filterDialogData]);

  const handleFilter = async () => {
    try {
      const filterData = alertContext?.filterDialogData;
      const params = {
        page: page + 1,
        limit: rowsPerPage,
        year: storedYear,
        planId: storedPlan,
        tabId: tabId,
      };

      if (!_.isEmpty(filterData?.program)) {
        params.program = filterData?.program;
      }
      if (!_.isEmpty(filterData?.lob)) {
        params.lob = filterData?.lob;
      }
      if (!_.isEmpty(filterData?.visitType)) {
        params.visitType = filterData?.visitType;
      }
      if (!_.isEmpty(filterData?.scheduleDateFrom)) {
        params.scheduleDateFrom = moment(filterData?.scheduleDateFrom).format(
          "YYYY-MM-DD",
        );
      }
      if (!_.isEmpty(filterData?.scheduleDateTo)) {
        params.scheduleDateTo = moment(filterData?.scheduleDateTo).format(
          "YYYY-MM-DD",
        );
      }
      if (
        !_.isEmpty(filterData?.subStatus) ||
        !_.isNull(filterData?.subStatus)
      ) {
        params.subStatus = filterData?.subStatus;
      }
      if (!_.isEmpty(filterData?.nurseId)) {
        params.nurseId = filterData?.nurseId;
      }
      const response = await getApi("/dashboard/getmembersbyfilter", {
        params,
      });
      const totalCount = response?.data?.count ?? 0;
      setTotalRowCount(totalCount);
      handleVphpSelection(response?.data ?? []);
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  const handleSorting = (defaultSortBy, defaultOrder, filter) => {
    const isAsc = order === "asc";
    const newOrder = isAsc ? "desc" : "asc";
    fetchMemberData(
      storedPlan,
      storedYear,
      filter,
      null,
      defaultSortBy,
      newOrder === "asc" ? 1 : -1,
    );
    if (selectedDropdownValue == "Referral") {
      fetchReferralQaData(
        storedPlan,
        storedYear,
        filter,
        null,
        defaultSortBy,
        newOrder === "asc" ? 1 : -1,
      );
    }
    setOrder(newOrder);
    setOrderBy(defaultSortBy);
  };

  const handleSearch = (filter, searchTerm) => {
    if (storedPlan && storedYear) {
      fetchMemberData(storedPlan, storedYear, filter, searchTerm);
    }
    if (selectedDropdownValue == "Referral") {
      fetchReferralQaData(storedPlan, storedYear, filter, searchTerm);
    }
  };

  const fetchHRAStatusOptions = async () => {
    try {
      const response = await getApi("/hra-status");
      const data = await response.data.data;
      setData(data);
      const hraOptions = data.map((status) => status.statusName);
      setHraStatusOptionForFilter(hraOptions);
      const activeHraStatus = [
        "Submitted - Ready for Coding",
        //"Clinician Review - QA",
      ]; //for qa lead
      const hraOptionsObj = hraOptions?.map((option) => {
        if (activeHraStatus.includes(option) && tabId !== 2) {
          return {
            option: option,
            disable: false,
          };
        } else {
          return {
            option: option,
            disable: true,
          };
        }
      });
      setHraStatusOptions(hraOptionsObj);
    } catch (error) {
      return;
    }
  };

  useEffect(() => {
    fetchHRAStatusOptions();
  }, [tabId]);

  //visit type api
  const fetchVisitTypeOptions = async () => {
    try {
      const response = await getApi("/visitTypes/get-visit-types");
      const data = await response.data.data;
      setVisitTypeData(data);
      const visitOptions = data.map((visit) => visit.visitType);
      setVisitTypeOptions(visitOptions);
      //hraVisitTypeUpdate(responseData);
    } catch (error) {
      return;
    }
  };

  useEffect(() => {
    fetchVisitTypeOptions();
  }, []);

  useEffect(() => {
    const isAllSelected = localStorage.getItem("isAllSelected");

    if (storedPlan && storedYear && storedScreeningTypes && isAllSelected) {
      const newValue = {
        plan: storedPlan,
        year: storedYear,
        screeningTypes: storedScreeningTypes,
        isAllSelected: JSON.parse(isAllSelected),
      };

      setClientData(newValue);
    }
  }, []);

  // Function to handle updating visit type (Needs to update for Nurse dashboard)
  const handleVisitTypeUpdate = async () => {
    const { memberId, memberIdTwo, planId, clientId, year, visitType, _id } =
      selectedRowData;

    const visitTypeString = String(visitType);

    const selectedVisitType = visitTypeData.find(
      (visit) => visit.visitType === visitTypeString,
    );

    if (selectedVisitType) {
      const { id: visitTypeId } = selectedVisitType;

      const requestBody = {
        data: {
          _id: _id,
          visitType: visitTypeId,
          // type: "visit",
          // memberId,
          // memberIdTwo,
          // plan: planId,
          // clientId,
          // year,
        },
      };

      try {
        const response = await putApi(
          "/visitTypes/updateVisiteType",
          requestBody,
        );

        if (response.status === 200) {
          setToasterOpen(true);
          setToasterSeverity(Labels.SUCCESS);
          setToasterMessage(response.data.message);
          if (storedPlan && storedYear) {
            fetchMemberData(storedPlan, storedYear);
          }
        } else {
          return;
        }
      } catch (error) {
        return;
      } finally {
        setIsVisitTypeAlertOpen(false);
        setSelectedVisitTypeChange(null);
      }
    } else {
      return;
    }
  };

  const handleVisitTypeCancel = () => {
    setVisitTypeValues((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues[selectedVisitTypeChange.rowIndex] =
        selectedVisitTypeChange.originalValue;
      return updatedValues;
    });

    setIsVisitTypeAlertOpen(false);

    setSelectedVisitTypeChange(null);
  };

  //hra status
  const handleHraStatus = (event, rowIndex) => {
    const { value } = event.target;

    // Check if the selected hraStatus is "Appointment Cancelled"
    if (value === "Appointment Cancelled") {
      setIsCancelAppointmentAlertOpen(true);
    } else {
      setIsActivateAlertOpen(true);
    }

    setHraStatus((prevValues) => {
      const updatedHraStatus = [...prevValues];
      updatedHraStatus[rowIndex] = value;

      // Store memberId and _id from the selected row

      const selectedRowData = responseData?.data[rowIndex];
      const memberId = selectedRowData?.memberId || "";
      const memberIdTwo = selectedRowData?.memberIdTwo || "";
      //const plan = selectedRowData?.assessmentData?.[0]?.plan || "";
      const plan = selectedRowData?.planId;
      const clientId = selectedRowData?.clientId || "";
      const year = selectedRowData?.year || "";
      const assessmentId = selectedRowData?._id || "";
      const _id = selectedRowData?._id || "";
      setSelectedRowData({
        memberId,
        assessmentId,
        memberIdTwo,
        plan,
        clientId,
        year,
        _id,
        selectedHraStatus: value,
      });

      setSelectedHraStatusTypeChange({
        rowIndex,
        originalValue: prevValues[rowIndex],
      });

      return updatedHraStatus;
    });
  };

  const handleCancelAppointmentAlertYesClick = async (cancelComment) => {
    const { _id, selectedHraStatus } = selectedRowData;

    const selectedStatus = data.find(
      (status) => status.statusName === selectedHraStatus,
    );

    if (selectedStatus) {
      const { id: hraStatusId } = selectedStatus;

      const requestBody = {
        data: {
          // memberId,
          // memberIdTwo,
          // plan,
          // clientId,
          // year,
          _id,
          hraStatus: {
            hraStatus: hraStatusId,
            subStatus: "",
            cancelStatus: "",
            cancelText: cancelComment,
          },
        },
      };
      try {
        const response = await putApi(
          "/hra-status/updateHraStatus",
          requestBody,
        );

        if (response.data.code === 200) {
          setToasterOpen(true);
          setToasterSeverity(Labels.SUCCESS);
          setToasterMessage(response.data.message);
          if (storedPlan && storedYear) {
            fetchMemberData(storedPlan, storedYear);
          }
        } else {
          return;
        }
      } catch (error) {
        return;
      }

      setIsCancelAppointmentAlertOpen(false);
    } else {
      return;
    }
  };

  const handleCancelAppointmentAlertExitClick = () => {
    setHraStatus((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues[selectedHraStatusTypeChange.rowIndex] =
        selectedHraStatusTypeChange.originalValue;
      return updatedValues;
    });
    setIsCancelAppointmentAlertOpen(false);
    setSelectedHraStatusTypeChange(null);
  };

  // Visit Type
  const handleVisitTypeChange = (event, rowIndex) => {
    const { value } = event.target;

    setVisitTypeValues((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues[rowIndex] = value.toString();

      const selectedRow = responseData?.data[rowIndex];
      const memberId = selectedRow?.memberId || "";
      const memberIdTwo = selectedRow?.memberIdTwo || "";
      const planId = selectedRow?.planId || "";
      const year = selectedRow?.year || "";
      const clientId = selectedRow?.clientId || "";

      const Name =
        `${selectedRow?.assessmentData?.demographics?.data?.firstName} ${selectedRow?.assessmentData?.demographics?.data?.middleName} ${selectedRow?.assessmentData?.demographics?.data?.lastName}` ||
        "";
      setSelectedRowData({
        ...selectedRowData,
        memberId,
        Name,
        memberIdTwo,
        planId,
        clientId,
        year,
        visitType: value,
      });

      setIsVisitTypeAlertOpen(true);

      setSelectedVisitTypeChange({
        rowIndex,
        originalValue: prevValues[rowIndex],
      });
      return updatedValues;
    });
  };

  useEffect(() => {
    hraVisitTypeUpdate(responseData);
  }, [responseData]);

  useEffect(() => {
    hraDropdownUpdate(responseData);
  }, [responseData]);

  const hraVisitTypeUpdate = (responseData) => {
    if (responseData?.data) {
      const updatedVisitTypeValues = responseData.data.map((item) => {
        const visitTypeId = item.visitType;

        const selectedVisitType = visitTypeData.find(
          (visit) => visit.id === visitTypeId,
        );

        if (selectedVisitType) {
          return selectedVisitType.visitType;
        } else {
          return "";
        }
      });

      setVisitTypeValues(updatedVisitTypeValues);
    }
  };

  const hraDropdownUpdate = (responseData) => {
    if (responseData?.data) {
      const updatedHraStatusValues = responseData.data.map((item) => {
        const hraStatusId = Number(item?.hraStatus?.hraStatus);

        const selectedStatus = data.find((status) => status.id === hraStatusId);

        if (selectedStatus) {
          return selectedStatus.statusName;
        } else {
          return "";
        }
      });
      setHraStatus(updatedHraStatusValues);
    }
  };

  //Scheduled Date
  const handleAssignedScheduleDateTypeUpdate = async () => {
    if (selectedScheduleDateRowIndex !== null) {
      const selectedRowData = responseData.data[selectedScheduleDateRowIndex];
      const memberObjIds = [selectedRowData._id];
      const scheduleDate =
        assignedScheduleDateValues[selectedScheduleDateRowIndex];

      try {
        const requestBody = {
          memberObjIds,
          scheduleDate,
        };

        if (scheduleDate && scheduleDate.trim() !== "") {
          const formattedDate = new Date(scheduleDate)
            .toISOString()
            .split("T")[0];
          requestBody.scheduleDate = formattedDate;
        }

        const response = await putApi(
          "/assignClinician/updateScheduleandTermDate",
          requestBody,
        );

        setIsAssignedScheduleDateAlertOpen(false);

        setToasterMessage(response.data.message);
        setToasterSeverity(Labels.SUCCESS);
        setToasterOpen(true);
        if (storedPlan && storedYear) {
          fetchMemberData(storedPlan, storedYear);
        }
      } catch (error) {
        return;
      } finally {
        setLoading(false);
      }
    }
  };

  const scheduleDateUpdate = (responseData) => {
    if (responseData?.data) {
      const updatedScheduleDateValues = responseData.data.map((item) => {
        // const scheduleDate = item.scheduleDate || "";
        const scheduleDate = item.finalDate || "";
        if (scheduleDate && scheduleDate.trim() !== "") {
          const formattedDate = new Date(scheduleDate)
            .toISOString()
            .split("T")[0];
          return formattedDate;
        } else {
          return "";
        }
      });

      setAssignedScheduleDateValues(updatedScheduleDateValues);
    }
  };

  useEffect(() => {
    scheduleDateUpdate(responseData);
  }, [responseData]);

  //Term Date
  const handleScheduleDateChange = (event, rowIndex) => {
    const { value } = event.target;

    setAssignedScheduleDateValues((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues[rowIndex] = value.toString();

      if (rowIndex !== undefined) {
        setSelectedScheduleDateRowIndex(rowIndex);

        const selectedRowData = responseData?.data[rowIndex];
        const memberId = selectedRowData?.memberId || "";
        const Name =
          `${selectedRowData?.assessmentData?.demographics?.data?.firstName} ${selectedRowData?.assessmentData?.demographics?.data?.middleName} ${selectedRowData?.assessmentData?.demographics?.data?.lastName}` ||
          "";
        const scheduleDate = updatedValues[rowIndex] || "";

        setSelectedRowData({
          memberId,
          Name,
          scheduleDate,
        });

        setIsAssignedScheduleDateAlertOpen(true);

        setSelectedAssignedScheduleDateChange({
          rowIndex,
          originalValue: prevValues[rowIndex],
        });

        if (scheduleDate && scheduleDate.trim() !== "") {
          updatedValues[rowIndex] = scheduleDate;
        } else {
          return;
        }

        return updatedValues;
      } else {
        return prevValues;
      }
    });
  };

  const handleAssignedScheduleDateTypeCancel = () => {
    setAssignedScheduleDateValues((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues[selectedAssignedScheduleDateChange.rowIndex] =
        selectedAssignedScheduleDateChange.originalValue;
      return updatedValues;
    });

    setIsAssignedScheduleDateAlertOpen(false);
    setSelectedAssignedScheduleDateChange(null);
  };

  const termDateUpdate = (responseData) => {
    if (responseData?.data) {
      const updatedTermDateValues = responseData.data.map((item) => {
        const termDate = item.termDate || "";

        if (termDate && termDate.trim() !== "") {
          const formattedDate = new Date(termDate).toISOString().split("T")[0];
          return formattedDate;
        } else {
          return "";
        }
      });

      setAssignedTermDateValues(updatedTermDateValues);
    }
  };

  useEffect(() => {
    termDateUpdate(responseData);
  }, [responseData]);

  //Term Date
  const handleTermDateChange = (event, rowIndex) => {
    const { value } = event.target;

    setAssignedTermDateValues((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues[rowIndex] = value.toString();

      if (rowIndex !== undefined) {
        setSelectedTermDateRowIndex(rowIndex);

        const selectedRowData = responseData?.data[rowIndex];
        const memberId = selectedRowData?.memberId || "";
        const Name =
          `${selectedRowData?.assessmentData?.demographics?.data?.firstName} ${selectedRowData?.assessmentData?.demographics?.data?.middleName} ${selectedRowData?.assessmentData?.demographics?.data?.lastName}` ||
          "";
        const termDate = updatedValues[rowIndex] || "";

        setSelectedRowData({
          memberId,
          Name,
          termDate,
        });

        setIsAssignedTermDateAlertOpen(true);

        setSelectedAssignedTermDateChange({
          rowIndex,
          originalValue: prevValues[rowIndex],
        });

        if (termDate && termDate.trim() !== "") {
          updatedValues[rowIndex] = termDate;
        } else {
          return;
        }

        return updatedValues;
      } else {
        return prevValues;
      }
    });
  };

  const handleCheckboxSelection = (isChecked) => {
    setAreCheckboxesSelected(isChecked);
  };

  const onCloseToaster = () => {
    setToasterOpen(false);
    setToasterSeverity(Labels.SUCCESS);
    setToasterMessage("");
    setToasterMessage("");
  };

  const handleFillHRAClick = async (
    assessmentId,
    memberIdForQueryForm,
    finalDate,
    evaluationDate,
    patientName,
    screenIha,
    memberType,
  ) => {
    sessionStorage.setItem("assessmentId", assessmentId);
    sessionStorage.setItem("screenIha", screenIha);
    sessionStorage.setItem("patientName", patientName);
    try {
      setIsLoading(true);
      if (responseData.data.length > 0) {
        updateAssessmentData(responseData);
        //  const assessmentId = responseData.data[0].uniquMemberId;

        if (assessmentId) {
          const secondApiResponse = await getApi(
            `/hra/get-assessment?id=${assessmentId}`,
          );
          sessionStorage.setItem("finalDate", finalDate);
          sessionStorage.setItem("evaluationDate", evaluationDate);

          getAssessment(secondApiResponse, memberIdForQueryForm);
          // navigate(`/AdminDashboard/FillHRAForm`);
          navigate(`/QADashboard/FillHRAForm`, {
            state: { patientName, screenIha, tabId },
          });
        } else {
          return;
        }
      } else {
        return;
      }
    } catch (error) {
      return;
    } finally {
      setIsLoading(false);
    }
  };

  const handleRowCheckboxChange = (checked, rowIndex) => {
    setSelectedRowCheckboxes((prevValues) => ({
      ...prevValues,
      [rowIndex]: checked,
    }));

    const allRowCheckboxesChecked = Object.values({
      ...selectedRowCheckboxes,
      [rowIndex]: checked,
    }).every((isChecked) => isChecked);

    setSelectAllCompletedHeaderCheckbox(allRowCheckboxesChecked);
    setSelectedCheckboxes((prevValues) => ({
      ...prevValues,
      [rowIndex]: checked,
    }));
  };

  const handleAlertYesClick = async () => {
    const { _id, selectedHraStatus } = selectedRowData;

    const selectedStatus = data.find(
      (status) => status.statusName === selectedHraStatus,
    );

    if (selectedStatus) {
      const { id: hraStatusId } = selectedStatus;

      const requestBody = {
        data: {
          // memberId,
          // memberIdTwo,
          // plan,
          // clientId,
          // year,
          _id,
          hraStatus: {
            hraStatus: hraStatusId,
            subStatus: "",
            cancelStatus: "",
            cancelText: "",
          },
        },
      };

      try {
        const response = await putApi(
          "/hra-status/updateHraStatus",
          requestBody,
        );

        if (response.status === 200) {
          setToasterOpen(true);
          setToasterSeverity(Labels.SUCCESS);
          setToasterMessage(response.data.message);
          if (storedPlan && storedYear) {
            fetchMemberData(storedPlan, storedYear);
          }
        } else {
          return;
        }
      } catch (error) {
        return;
      } finally {
        setIsActivateAlertOpen(false);
        setSelectedHraStatusTypeChange(null);
      }
    } else {
      return;
    }
  };

  const handleHraStatusCancel = () => {
    setHraStatus((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues[selectedHraStatusTypeChange.rowIndex] =
        selectedHraStatusTypeChange.originalValue;
      return updatedValues;
    });

    setIsActivateAlertOpen(false);

    setSelectedHraStatusTypeChange(null);
  };
  const roleId = sessionStorage.getItem("roleId");
  const [tableHeaders, setTableHeaders] = useState([
    { field: "Action", headerName: "Action", hidesortable: true },
    { field: "Program", headerName: "Program" },
    { field: "LOB", headerName: "LOB" },
    // { field: "Plan", headerName: "Plan" },
    {
      field: "ID",
      headerName: "ID#",
      type: "number",
    },
    {
      field: "Name",
      headerName: "Name",
    },
    {
      field: "City",
      headerName: "Contact",
      renderCell: (params) => (
        <TooltipEllipsisCell value={params.value} maxWidth={200} />
      ),
    },
    { field: "Assigned_Clinician", headerName: "Assigned_Clinician" },
    { field: "Evaluation_Date", headerName: "Evaluation_Date/Time" },
    { field: "Visit_Type", headerName: "Visit_Type" },
    { field: "HRA_Status", headerName: "HRA_Status" },

    //roleId === 8 ? { field: "QA Analyst", headerName: "QA Analyst" } : null, // this is in qa lead role not in qa and as a dropdown
    { field: "Sub_Status", headerName: "Sub_Status" },
    { field: "Days", headerName: "Days" },
  ]);
  useEffect(() => {
    if (roleId == 6) {
      //qa lead
      setTableHeaders([
        { field: "Action", headerName: "Action", hidesortable: true },
        { field: "Program", headerName: "Program" },
        { field: "LOB", headerName: "LOB" },
        // { field: "Plan", headerName: "Plan" },
        {
          field: "ID",
          headerName: "ID#",
          type: "number",
        },
        {
          field: "Name",
          headerName: "Name",
        },
        {
          field: "City",
          headerName: "Contact",
          renderCell: (params) => (
            <TooltipEllipsisCell value={params.value} maxWidth={200} />
          ),
        },
        { field: "Assigned_Clinician", headerName: "Assigned_Clinician" },
        { field: "Evaluation_Date", headerName: "Evaluation_Date/Time" },
        { field: "Visit_Type", headerName: "Visit_Type" },
        { field: "QA_Analyst", headerName: "QA_Analyst" },
        { field: "HRA_Status", headerName: "HRA_Status" },

        //roleId === 8 ? { field: "QA Analyst", headerName: "QA Analyst" } : null, // this is in qa lead role not in qa and as a dropdown
        { field: "Sub_Status", headerName: "Sub_Status" },
        { field: "Days", headerName: "Days" },
      ]);
    }
  }, [roleId]);

  const [selectAllHeaderCheckbox, setSelectAllHeaderCheckbox] = useState(false);
  const handleHeaderCheckboxChange = (event) => {
    const checked = event.target.checked;
    setSelectAllHeaderCheckbox(checked);

    setSelectedRowCheckboxes((prevValues) => {
      const updatedCheckboxes = {};
      for (const rowIndex in prevValues) {
        updatedCheckboxes[rowIndex] = checked;
      }
      return updatedCheckboxes;
    });
  };

  const handleDropdownChange = (newValue) => {
    setSelectedDropdownValue(newValue);
    handleTabChange(newValue);
    // let dynamicHeaders = [
    //   { field: "Action", headerName: "Action", hidesortable: true },
    //   { field: "Program", headerName: "Program" },
    //   { field: "LOB", headerName: "LOB" },
    //   // { field: "Plan", headerName: "Plan" },
    //   { field: "ID", headerName: "ID", type: "number" },
    //   { field: "Name", headerName: "Name" },
    //   {
    //     field: "City",
    //     headerName: "City",
    //     renderCell: (params) => (
    //       <TooltipEllipsisCell value={params.value} maxWidth={200} />
    //     ),
    //   },
    //   { field: "Assigned_Clinician", headerName: "Assigned_Clinician" },

    //   { field: "Evaluation_Date", headerName: "Evaluation_Date/Time" },
    //   { field: "Visit_Type", headerName: "Visit_Type" },
    //   { field: "HRA_Status", headerName: "HRA_Status" },
    //   //  roleId === 8 ? { field: "QA Analyst", headerName: "QA Analyst" } : null, // this is in qa lead role not in qa and as a dropdown
    //   { field: "Sub_Status", headerName: "Sub_Status" },
    //   { field: "Days", headerName: "Days" },
    // ];

    if (roleId == 6 && newValue == "Referral") {
      setTableHeaders([
        {
          field: "checkboxHeader",
          headerName: (
            <input
              type="checkbox"
              style={{ width: "20px", height: "18px" }}
              checked={selectAllHeaderCheckbox}
              onChange={handleHeaderCheckboxChange}
            />
          ),
        },
        {
          field: "ID",
          headerName: "ID#",
          type: "number",
        },
        {
          field: "Name",
          headerName: "Name",
        },
        { field: "Submitted_By", headerName: "Submitted_By" },
        { field: "Assigned_Specialist", headerName: "Assigned_Specialist" },
        { field: "Referral_Status", headerName: "Referral_Status" },
        { field: "Referral_Type", headerName: "Referral_Type" },
        { field: "Date_Of_Referral", headerName: "Date_Of_Referral" },
        {
          field: "Referral_Sent_To_QA_Date",
          headerName: "Referral_Sent_To_QA_Date",
        },
        {
          field: "Referral_Sent_To_Plan_Date",
          headerName: "Referral_Sent_To_Plan_Date",
        },
        { field: "View_Pdf", headerName: "Action" },
        { field: "Review", headerName: "Review" },
        //{ field: "Download", headerName: "Download" },
      ]);
      return;
    }
    if (roleId == 7 && newValue == "Referral") {
      setTableHeaders([
        {
          field: "ID",
          headerName: "ID#",
          type: "number",
        },
        {
          field: "Name",
          headerName: "Name",
        },
        { field: "Submitted_By", headerName: "Submitted_By" },
        //{ field: "Assigned_Specialist", headerName: "Assigned_Specialist" },
        { field: "Referral_Status", headerName: "Referral_Status" },
        { field: "Referral_Type", headerName: "Referral_Type" },
        { field: "Date_Of_Referral", headerName: "Date_Of_Referral" },
        {
          field: "Referral_Sent_To_QA_Date",
          headerName: "Referral_Sent_To_QA_Date",
        },
        {
          field: "Referral_Sent_To_Plan_Date",
          headerName: "Referral_Sent_To_Plan_Date",
        },
        { field: "View_Pdf", headerName: "Action" },
        { field: "Review", headerName: "Review" },
        { field: "Download", headerName: "Download" },
      ]);
      return;
    }
    if (roleId == 6) {
      setTableHeaders([
        { field: "Action", headerName: "Action", hidesortable: true },
        { field: "Program", headerName: "Program" },
        { field: "LOB", headerName: "LOB" },
        // { field: "Plan", headerName: "Plan" },
        {
          field: "ID",
          headerName: "ID#",
          type: "number",
        },
        {
          field: "Name_Qalead",
          headerName: "Name",
        },
        {
          field: "City",
          headerName: "Contact",
          renderCell: (params) => (
            <TooltipEllipsisCell value={params.value} maxWidth={200} />
          ),
        },
        { field: "Assigned_Clinician", headerName: "Assigned_Clinician" },
        { field: "Evaluation_Date", headerName: "Evaluation_Date/Time" },
        { field: "Visit_Type", headerName: "Visit_Type" },
        { field: "QA_Analyst", headerName: "QA_Analyst" },
        { field: "HRA_Status", headerName: "HRA_Status" },

        //roleId === 8 ? { field: "QA Analyst", headerName: "QA Analyst" } : null, // this is in qa lead role not in qa and as a dropdown
        { field: "Sub_Status", headerName: "Sub_Status" },
        { field: "Days", headerName: "Days" },
      ]);
    } else {
      setTableHeaders([
        { field: "Action", headerName: "Action", hidesortable: true },
        { field: "Program", headerName: "Program" },
        { field: "LOB", headerName: "LOB" },
        // { field: "Plan", headerName: "Plan" },
        {
          field: "ID",
          headerName: "ID#",
          type: "number",
        },
        {
          field: "Name",
          headerName: "Name",
        },
        {
          field: "City",
          headerName: "Contact",
          renderCell: (params) => (
            <TooltipEllipsisCell value={params.value} maxWidth={200} />
          ),
        },
        { field: "Assigned_Clinician", headerName: "Assigned_Clinician" },
        { field: "Evaluation_Date", headerName: "Evaluation_Date/Time" },
        { field: "Visit_Type", headerName: "Visit_Type" },
        // { field: "QA_Analyst", headerName: "QA_Analyst" },
        { field: "HRA_Status", headerName: "HRA_Status" },

        //roleId === 8 ? { field: "QA Analyst", headerName: "QA Analyst" } : null, // this is in qa lead role not in qa and as a dropdown
        { field: "Sub_Status", headerName: "Sub_Status" },
        { field: "Days", headerName: "Days" },
      ]);
    }
  };
  const onUnassignedBtnChange = (value) => {
    if (value == "Unassigned") {
      setTabId(3);
    } else if (value == "NotUnassigned") {
      setTabId(1);
    }
  };
  useEffect(() => {
    if (tabId !== null && storedPlan && storedYear) {
      fetchMemberData(storedPlan, storedYear, tabId);
    }
  }, [tabId]);

  // Assigned Coding Analyst
  const handleAssignedCodingTypeUpdate = async () => {
    if (selectedCoderRowIndex !== null) {
      const selectedRowData = responseData.data[selectedCoderRowIndex];
      const memberObjId = selectedRowData._id;
      const clientId = selectedRowData.clientId;

      const selectedCoder = coderOptions.find(
        (coder) =>
          `${coder.firstName} ${coder.lastName}` ===
          assignedCodingValues[selectedCoderRowIndex],
      );

      if (selectedCoder) {
        const selectedUserId = selectedCoder.userid;

        try {
          const response = await putApi("/members/updateAssigneeBasedOnRole", {
            members: [
              {
                memberObjId,
                clientId,
                userRoleId: "9",
                userId: selectedUserId,
              },
            ],
          });

          if (response.status === 200) {
            setToasterOpen(true);
            setToasterSeverity(Labels.SUCCESS);
            setToasterMessage("Coding Analyst Updated Successfully");
            if (storedPlan && storedYear) {
              fetchMemberData(storedPlan, storedYear);
            }
          }
        } catch (error) {
          return;
        } finally {
          setIsAssignedCodingAlertOpen(false);
        }
      }
    }
  };

  const updateAssignedCodingValues = (responseData) => {
    if (responseData?.data) {
      const updatedCodingValues = responseData.data.map((item) => {
        const coderId = item.coderId || "";

        const selectedCoder = coderOptions.find(
          (coder) => coder.userid === coderId,
        );

        if (selectedCoder) {
          const fullName = `${selectedCoder.firstName} ${selectedCoder.lastName}`;
          return fullName;
        } else {
          return "";
        }
      });
      setAssignedCodingValues(updatedCodingValues);
    }
  };
  useEffect(() => {
    updateAssignedCodingValues(responseData);
  }, [responseData, coderOptions]);

  const renderCodingAnalystField = (newValue, index, data1) => {
    if (["Coding", "Clinician Review"].includes(newValue)) {
      return <p>{data1.Coding_Analyst || ""}</p>;
    } else {
      return <div>test of coding analyst</div>;
    }
  };

  const updateAssignedQaValues = (responseData) => {
    if (responseData?.data) {
      const updatedQaValues = responseData.data.map((item) => {
        const qaId = item.qaId || "";

        const selectedQa = qaOptions.find((qa) => qa.userid === qaId);

        if (selectedQa) {
          const fullName = `${selectedQa.firstName} ${selectedQa.lastName}`; // `${selectedQa.firstName} ${selectedQa.lastName}`;
          return fullName;
        } else {
          return "";
        }
      });

      setAssignedQaValues(updatedQaValues);
    }
  };
  const fetchData = async () => {
    try {
      if (apiResponseData) {
        const clinicianUsers =
          apiResponseData.find((obj) => obj.clinician)?.clinician || [];
        const coderUsers =
          apiResponseData.find((obj) => obj.coder)?.coder || [];
        const qaUsers = apiResponseData.find((obj) => obj.qa)?.qa || [];
        setClinicianOptions(clinicianUsers);
        setCoderOptions(coderUsers);
        setQaOptions(qaUsers);
      } else {
        setClinicianOptions([]);
        setCoderOptions([]);
        setQaOptions([]);
      }

      const planId = localStorage.getItem("selectedClientId") || "";
      const response = await postApi("/users/get-users-by-program-roleId", {
        planId: planId,
      });

      if (response.status === 404) {
        return;
      }

      if (response.data && response.data.data) {
        const clinicianUsers =
          response.data.data.find((obj) => obj.clinician)?.clinician || [];
        const coderUsers =
          response.data.data.find((obj) => obj.coder)?.coder || [];
        const qaUsers = response.data.data.find((obj) => obj.qa)?.qa || [];

        setClinicianOptions(clinicianUsers);
        setCoderOptions(coderUsers);
        setQaOptions(qaUsers);
      } else {
        setClinicianOptions([]);
        setCoderOptions([]);
        setQaOptions([]);
      }
    } catch (error) {
      setClinicianOptions([]);
      setCoderOptions([]);
      setQaOptions([]);
    }
  };

  useEffect(() => {
    fetchData();
  }, [
    apiResponseData,
    clientData?.plan,
    setClinicianOptions,
    setCoderOptions,
    setQaOptions,
  ]);
  useEffect(() => {
    updateAssignedQaValues(responseData);
  }, [responseData, qaOptions]);
  const handleAssignedQaChange = async (event, rowIndex) => {
    const { value } = event.target;

    setAssignedQaValues((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues[rowIndex] = value.toString();

      if (rowIndex !== undefined) {
        setSelectedQaRowIndex(rowIndex);

        const selectedRowData = responseData?.data[rowIndex];
        const memberId = selectedRowData?.memberId || "";
        const Name =
          `${selectedRowData?.memberDemographyData?.demographics?.data?.firstName} ${selectedRowData?.memberDemographyData?.demographics?.data?.middleName} ${selectedRowData?.memberDemographyData?.demographics?.data?.lastName}` ||
          "";

        setSelectedRowData({
          memberId,
          Name,
        });

        setIsAssignedQaAlertOpen(true);

        setSelectedAssignedQaChange({
          rowIndex,
          originalValue: prevValues[rowIndex],
        });

        return updatedValues;
      } else {
        return prevValues;
      }
    });
  };
  const handleAssignedQaTypeUpdate = async () => {
    if (selectedQaRowIndex !== null) {
      const selectedRowData = responseData.data[selectedQaRowIndex];
      const memberObjId = selectedRowData._id;
      const clientId = selectedRowData.clientId;

      const selectedQa = qaOptions.find(
        (qa) =>
          `${qa.firstName} ${qa.lastName}` ===
          assignedQaValues[selectedQaRowIndex],
      );

      if (selectedQa) {
        const selectedUserId = selectedQa.userid;
        const planId = localStorage.getItem("selectedClientId") || "";

        try {
          const response = await putApi(
            "/assignClinician/updateAssigneeBasedOnRole",
            {
              members: [
                {
                  memberObjId,
                  clientId,
                  userRoleId: "7",
                  userid: selectedUserId,
                  planId,
                },
              ],
            },
          );

          if (response.status === 200) {
            setToasterOpen(true);
            setToasterSeverity(Labels.SUCCESS);
            setToasterMessage("Qa Analyst Updated Successfully");
            if (storedPlan && storedYear) {
              fetchMemberData(storedPlan, storedYear);
            }
          }
        } catch (error) {
          return;
        } finally {
          setIsAssignedQaAlertOpen(false);
        }
      }
    }
  };
  useEffect(() => {
    if (tabId == 1 || tabId == 2 || tabId == 3) {
      sessionStorage.setItem("showQueryFormBtn", true);
    } else {
      sessionStorage.setItem("showQueryFormBtn", false);
    }
  }, [tabId]);
  // Assigned Qa Analyst
  const handleAssignedQaTypeCancel = () => {
    setAssignedQaValues((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues[selectedAssignedQaChange.rowIndex] =
        selectedAssignedQaChange.originalValue;
      return updatedValues;
    });

    setIsAssignedQaAlertOpen(false);
    setSelectedAssignedQaChange(null);
  };
  const renderQaAnalystField = (newValue, index, data1) => {
    return (
      <SelectDropdown
        label="QA Analyst"
        value={data1?.QA_Analyst || ""}
        onChange={(event) => handleAssignedQaChange(event, index)}
        options={qaOptions.map((qa) => `${qa.firstName} ${qa.lastName}`)}
      />
    );
  };

  const renderAssignedAnalystField = (newValue, index, data1) => {
    return (
      <SelectDropdown
        label="Assigned Specialist"
        value={data1?.QA_Analyst || ""}
        onChange={(event) => handleAssignedQaChange(event, index)}
        options={qaOptions.map((qa) => `${qa.firstName} ${qa.lastName}`)}
      />
    );
  };
  const calculateDaysFromGivenDate = (item) => {
    let givenDate; //new Date(givenDateString);
    const currentDate = new Date();
    if (tabId == 2 || tabId == 3 || tabId == 4 || tabId == 1) {
      //completed
      // givenDate = new Date(item?.scheduleDate);
      if (item?.hraStatus?.hraStatus == 10) {
        givenDate = item?.readyForUploadDate
          ? new Date(item?.readyForUploadDate)
          : null;
      } else if (item?.hraStatus?.hraStatus == 7) {
        givenDate = item?.readyForCoderDate
          ? new Date(item?.readyForCoderDate)
          : null;
      } else if (item?.hraStatus?.hraStatus == 6) {
        givenDate = item?.readyForQaDate
          ? new Date(item?.readyForQaDate)
          : null;
      } else if (item?.hraStatus?.hraStatus == 8) {
        givenDate = item?.qaReviewDate ? new Date(item?.qaReviewDate) : null;
      } else if (item?.hraStatus?.hraStatus == 9) {
        givenDate = item?.coderReviewDate
          ? new Date(item?.coderReviewDate)
          : null;
      } else if (item?.hraStatus?.hraStatus == 11) {
        givenDate = item?.completedDate ? new Date(item?.completedDate) : null;
      } else if (item?.hraStatus?.hraStatus == 2) {
        givenDate = item?.inProgressDate
          ? new Date(item?.inProgressDate)
          : null;
      } else if (item?.hraStatus?.hraStatus == 3) {
        givenDate = item?.appointmentCancelledDate
          ? new Date(item?.appointmentCancelledDate)
          : null;
      } else if (item?.hraStatus?.hraStatus == 12) {
        givenDate = item?.assessmentCalcelledDate
          ? new Date(item?.assessmentCalcelledDate)
          : null;
      } else if (item?.hraStatus?.hraStatus == 4) {
        givenDate = item?.patientRefusalDate
          ? new Date(item?.patientRefusalDate)
          : null;
      } else if (item?.hraStatus?.hraStatus == 17) {
        givenDate = item?.otherDate ? new Date(item?.otherDate) : null;
      } else if (item?.hraStatus?.hraStatus == 16) {
        givenDate = item?.hospiceDate ? new Date(item?.hospiceDate) : null;
      } else if (item?.hraStatus?.hraStatus == 18) {
        givenDate = item?.termExpiredDate
          ? new Date(item?.termExpiredDate)
          : null;
      } else if (item?.hraStatus?.hraStatus == 19) {
        givenDate = item?.noShowDate ? new Date(item?.noShowDate) : null;
      } else if (item?.hraStatus?.hraStatus == 15) {
        givenDate = item?.deceasedDate ? new Date(item?.deceasedDate) : null;
      }
    }
    if (givenDate) {
      const differenceInMilliseconds = currentDate - givenDate;
      const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

      return Math.floor(differenceInDays); // Return the integer part
    } else {
      return "";
    }
  };
  const getSubStatus = (item) => {
    if (item?.qaReviewCount == 1) {
      return "1st Review";
    } else if (item?.qaReviewCount == 2) {
      return "2nd Review";
    } else if (item?.qaReviewCount == 3) {
      return "3rd Review";
    } else if (item?.qaReviewCount >= 4) {
      return `${item?.qaReviewCount}th Review`;
    } else if (item?.qaReviewCount == 0 || !Boolean(item?.qaReviewCount)) {
      return "New";
    }
  };
  const formatEvaluationDate = (_date) => {
    if (_date) {
      const date = new Date(_date);
      // date.setHours(date.getHours() + 5);
      // date.setMinutes(date.getMinutes() + 30);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      // Get the time parts in 12-hour format
      const hours24 = date.getHours();
      const amPm = hours24 >= 12 ? "PM" : "AM";
      const hours = hours24 % 12 || 12;
      const minutes = date.getMinutes();

      // Format the date and time as strings
      // const dateStr = `${year}-${month.toString().padStart(2, "0")}-${day
      //   .toString()
      //   .padStart(2, "0")}`;
      const dateStr = `${month.toString().padStart(2, "0")}/${day
        .toString()
        .padStart(2, "0")}/${year}`;
      const timeStr = `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")} ${amPm}`;
      return `${dateStr}  ${timeStr}`;
    } else {
      return "";
    }
  };
  function calculateAge(dob) {
    if (dob != "undefined" && Boolean(dob)) {
      //const dateOfBirth = excelSerialToDate(JSON.parse(JSON.stringify(dob)));
      // const dateOfBirth = dob;
      const dateOfBirth = JSON.parse(JSON?.stringify(dob));
      var birthDateObj = new Date(dateOfBirth);
      // dateOfBirth?.includes("-")
      //   ? new Date(dateOfBirth)
      //   : new Date(excelSerialToDate(dateOfBirth));
      var currentDate = new Date();
      var timeDifference = currentDate?.getTime() - birthDateObj?.getTime();
      var age = Math?.floor(timeDifference / (1000 * 60 * 60 * 24 * 365.25));
      return age;
    } else {
      return 1000;
    }
  }

  const mapReferralType = (type) => {
    switch (type) {
      case "1":
        return "Urgent";
      case "2":
        return "Non-Urgent";
      case "3":
        return "Critical Incident Report";
      default:
        return ""; // Handle other cases or empty values
    }
  };

  const mapReferralStatus = (status) => {
    switch (status) {
      case "1":
        return "New";
      case "2":
        return "Under Review";
      case "3":
        return "Pending Revision";
      case "4":
        return "Completed";
      default:
        return "";
    }
  };

  const [referralStatus, setReferralStatus] = useState([
    "New",
    "Under Review",
    "Pending Revision",
    "Completed",
  ]);
  const [isReferralActivateAlertOpen, setIsReferralActivateAlertOpen] =
    useState(false);
  const [
    selectedReferralStatusTypeChange,
    setSelectedReferralStatusTypeChange,
  ] = useState(null);

  const [previousValue, setPreviousValue] = useState(null);
  const [currentRowIndex, setCurrentRowIndex] = useState(null);

  const handleReferralStatus = (event, rowIndex) => {
    const { value } = event.target;
    //setPreviousReferralStatus(referralStatus[rowIndex]); // Store the current value

    // setIsReferralActivateAlertOpen(true);
    // setSelectedReferralStatusTypeChange({
    //   rowIndex,
    //   originalValue: referralStatus[rowIndex],
    // });
    setPreviousValue(referralStatus[rowIndex]);
    setCurrentRowIndex(rowIndex);

    setReferralStatus((prevValues) => {
      const updatedReferralStatus = [...prevValues];
      updatedReferralStatus[rowIndex] = value;

      // Store memberId and _id from the selected row
      const selectedRowData = responseData?.data[rowIndex];

      const assessmentId = selectedRowData?._id || "";
      const _id = selectedRowData?._id || "";

      const referralForm =
        selectedRowData?.memberDemographyData?.referral?.referralForm || [];
      const lastReferralFormEntry =
        referralForm.length > 0 ? referralForm[referralForm.length - 1] : null;

      const referralId = lastReferralFormEntry?._id;

      const uniqueId = selectedRowData?.uniquMemberId;

      setSelectedRowData({
        assessmentId,

        _id,
        referralId,
        uniqueId,
        selectedReferralStatus: value,
      });
      setIsReferralActivateAlertOpen(true);
      setSelectedReferralStatusTypeChange({
        rowIndex,
        originalValue: prevValues[rowIndex],
      });

      return updatedReferralStatus;
    });
  };

  const handleReferralAlertYesClick = async () => {
    const { _id, referralId, uniqueId } = selectedRowData;

    const requestBody = {
      //data: {
      id: uniqueId,
      referralId: referralId,
      status: "4",
      //},
    };

    try {
      const response = await putApi(
        "/referral-form/update-status",
        requestBody,
      );
      if (response.status === 200) {
        setToasterOpen(true);
        setToasterSeverity(Labels.SUCCESS);
        setToasterMessage(response.data.message);
        fetchReferralQaData(storedPlan, storedYear);
      } else {
        return;
      }
    } catch (error) {
      return;
    } finally {
      setIsReferralActivateAlertOpen(false);
      //setSelectedHraStatusTypeChange(null);
    }
  };

  const handleReferralStatusCancel = () => {
    // Revert to the previous value
    setReferralStatus((prevValues) => {
      const updatedValues = [...prevValues];
      if (currentRowIndex == null) {
        updatedValues[currentRowIndex] = previousValue;
      }
      return updatedValues;
    });

    // Close the alert and reset state
    setIsReferralActivateAlertOpen(false);
    setPreviousValue(null);
    setCurrentRowIndex(null);
    setSelectedReferralStatusTypeChange(null);
  };

  const dataMemberListReferral =
    (Array.isArray(responseData?.data) ? responseData?.data : []).map(
      (item, index) => {
        const demographics = item?.memberDemographyData?.demographics?.data;
        const firstName = demographics?.firstName || "";
        const middleName = demographics?.middleName || "";
        const lastName = demographics?.lastName || "";

        const clinicianFirstName = item?.clinicanUserData?.firstName || "";
        const clinicianLastName = item?.clinicanUserData?.lastName || "";

        // const name = demographics?.name;

        // Extract the last referral form entry
        const referralForm =
          item?.memberDemographyData?.referral?.referralForm || [];
        const lastReferralFormEntry =
          referralForm.length > 0
            ? referralForm[referralForm.length - 1]
            : null;
        const dateOfReferral = formatDate(lastReferralFormEntry?.dateOfReport); // Format the date
        const referralDateSentQaDate = formatDate(lastReferralFormEntry?.date);
        const referralType = mapReferralType(lastReferralFormEntry?.type);

        const referralStatus = mapReferralStatus(
          lastReferralFormEntry?.referralStatus,
        );

        // const qaName =
        //   item?.qaUserData?.length > 0
        //     ? `${item?.qaUserData[0]?.firstName} ${item?.qaUserData[0]?.lastName}`
        //     : "";

        const qaFirstName = item?.qaUserData?.firstName || "";
        const qaLastName = item?.qaUserData?.lastName || "";
        const qaName = `${qaFirstName} ${qaLastName}`;

        const referralId = lastReferralFormEntry?._id;

        return {
          ID: item?.memberId,
          Name:
            sessionStorage.getItem("roleId") === "6"
              ? demographics?.name
              : `${firstName} ${middleName} ${lastName}`,
          //Name_Qalead: item?.demographics?.name,
          Submitted_By: `${clinicianFirstName} ${clinicianLastName}`,
          Referral_Status: referralStatus,
          Date_Of_Referral: dateOfReferral,
          Referral_Sent_To_QA_Date: referralDateSentQaDate,
          Referral_Type: referralType,
          Referral_Status: referralStatus,
          QA_Analyst: assignedQaValues[index] || "",
          View_Pdf: (
            <Button
              type="submit"
              width="70px"
              height="28px"
              cursor="pointer"
              color="white"
              background="var(--mainTheme)"
              fontSize="12px"
              border="none"
              borderRadius="100px"
              fontFamily="Inter,sans-serif"
              id="memberlist_View_Pdf_button"
              onClick={() => {
                fetchReferalReport(item?.uniquMemberId);
              }}
              // onClick={fetchReferalReport}
            >
              View PDF
            </Button>
          ),
          Review: (
            <Button
              type="submit"
              width="70px"
              height="28px"
              cursor="pointer"
              color="white"
              background="var(--mainTheme)"
              // background={
              //   screenIha
              //     ? "var(--colorDarkBlue)"
              //     : calculateAge(dateOfBirth) >= 18
              //     ? "var(--mainTheme)"
              //     : "#ed8e22"
              // }
              fontSize="12px"
              border="none"
              borderRadius="100px"
              fontFamily="Inter,sans-serif"
              onClick={() => {
                if (referralStatus == "Completed") {
                  console.log(referralStatus, "referralStatus");
                  setPopupMessage("Referral completed");
                  setOpenPopup(true);
                } else if (referralStatus == "Under Review") {
                  setPopupMessage("Latest review is added by current user.");
                  setOpenPopup(true);
                } else {
                  handleOpenReviewDialog(
                    item?.uniquMemberId,
                    item?.memberId,
                    `${clinicianFirstName} ${clinicianLastName}`,

                    sessionStorage.getItem("roleId") === "6"
                      ? demographics?.name
                      : `${firstName} ${middleName} ${lastName}`,
                    `${qaFirstName} ${qaLastName}`,
                    referralId,
                  );
                }
              }}
              //id="memberlist_View_Pdf_button"
            >
              Referral
            </Button>
          ),
        };
      },
    ) || [];
  const dataMemberList =
    (Array.isArray(responseData.data) ? responseData.data : []).map(
      (item, index) => {
        const demographics = item.memberDemographyData?.demographics?.data;
        const firstName = demographics?.firstName || "";
        const middleName = demographics?.middleName || "";
        const lastName = demographics?.lastName || "";
        const address = demographics?.address || "";
        const primary = demographics?.primary || "";
        const lob = item.memberDemographyData?.lob;
        const city = demographics?.city || "";
        const _id = item?._id || "";
        const screenIha = demographics?.screenIha === "2" ? true : false || "";
        const memberType = item?.memberType;
        const dateOfBirth = demographics?.dob;
        const pedsFormPatientName = demographics?.name;
        return {
          Edit: (
            <Button
              type="submit"
              width="70px"
              height="28px"
              cursor="pointer"
              color="white"
              background="var(--mainTheme)"
              fontSize="12px"
              border="none"
              borderRadius="100px"
              fontFamily="Inter,sans-serif"
            >
              Edit
            </Button>
          ),
          Plan: item.planId,
          Program: item.program,
          LOB: lob, //item.assessmentData?.lob || "",
          ID: item.memberId,
          Name:
            pedsFormPatientName?.length > 0
              ? pedsFormPatientName
              : `${firstName} ${middleName} ${lastName}`,
          City: `${city}`,
          Scheduler_Name: item.schedulerName,
          Schedule_Date: item.scheduleDate || "",
          Visit_Type: visitTypeValues[index] || "",
          Coding_Analyst: assignedCodingValues[index] || "",
          QA_Analyst: assignedQaValues[index] || "",
          //   Assigned_Clinician: assignedClinicianValues[index] || "",
          Assigned_Clinician: `${item?.clinicanUserData?.firstName ?? ""} ${
            item?.clinicanUserData?.lastName ?? ""
          }`,
          //   Term_date: item.termDate || "",
          HRA_Status: hraStatus[index] || "",
          _id: _id,

          Action: (
            <Button
              type="submit"
              width="70px"
              height="28px"
              cursor="pointer"
              color="white"
              //background="var(--mainTheme)"
              background={
                screenIha
                  ? "var(--colorDarkBlue)"
                  : calculateAge(dateOfBirth) >= 18
                  ? "var(--mainTheme)"
                  : "#ed8e22"
              }
              fontSize="12px"
              border="none"
              borderRadius="100px"
              fontFamily="Inter,sans-serif"
              onClick={() => {
                //   handleFillHRAClick(item.assessmentData?._id, item._id)
                sessionStorage.setItem(
                  "selectedClinicianName",
                  `${item?.clinicanUserData?.firstName ?? ""} ${
                    item?.clinicanUserData?.lastName ?? ""
                  }`,
                );
                if (screenIha) {
                  handleFillHRAClick(
                    item?.uniquMemberId,
                    item._id,
                    item?.finalDate,
                    item?.evaluationDate,
                    `${firstName} ${middleName} ${lastName}`,
                    "Screen Only",
                  );
                } else {
                  sessionStorage.setItem("memberType", memberType);
                  sessionStorage.setItem("dob", dateOfBirth);
                  handleFillHRAClick(
                    item?.uniquMemberId,
                    item._id,
                    item?.finalDate,
                    item?.evaluationDate,
                    pedsFormPatientName?.length > 0
                      ? pedsFormPatientName
                      : `${firstName} ${middleName} ${lastName}`,
                    undefined,
                    memberType,
                  );
                }
              }}
            >
              {screenIha
                ? "Screen Only"
                : calculateAge(dateOfBirth) >= 18
                ? "View HRA"
                : "Fill PEDS"}
            </Button>
          ),
          Days: calculateDaysFromGivenDate(item),
          Sub_Status: getSubStatus(item),
          Evaluation_Date: formatEvaluationDate(
            item?.finalDate || item?.evaluationDate,
          ),
          View_Pdf: (
            <Button
              type="submit"
              width="70px"
              height="28px"
              cursor="pointer"
              color="white"
              background="var(--mainTheme)"
              // background={
              //   screenIha
              //     ? "var(--colorDarkBlue)"
              //     : calculateAge(dateOfBirth) >= 18
              //     ? "var(--mainTheme)"
              //     : "#ed8e22"
              // }
              fontSize="12px"
              border="none"
              borderRadius="100px"
              fontFamily="Inter,sans-serif"

              // id="memberlist_View_Pdf_button"
            >
              View PDF
            </Button>
          ),
          Review: (
            <Button
              type="submit"
              width="70px"
              height="28px"
              cursor="pointer"
              color="white"
              background="var(--mainTheme)"
              // background={
              //   screenIha
              //     ? "var(--colorDarkBlue)"
              //     : calculateAge(dateOfBirth) >= 18
              //     ? "var(--mainTheme)"
              //     : "#ed8e22"
              // }
              fontSize="12px"
              border="none"
              borderRadius="100px"
              fontFamily="Inter,sans-serif"
              onClick={() => {
                handleOpenReviewDialog(item?.uniquMemberId);
              }}
              //id="memberlist_View_Pdf_button"
            >
              Review
            </Button>
          ),
        };
      },
    ) || [];
  const dataMemberListWithDropdown = dataMemberList.filter((data) => {
    return true;
  });

  hraDropdownUpdate(dataMemberListWithDropdown);
  hraVisitTypeUpdate(dataMemberListWithDropdown);
  const mappingData = useMemo(() => {
    if (selectedDropdownValue == "Referral") {
      return dataMemberListReferral?.map((data1, index) => {
        return {
          ...data1,

          Assigned_Specialist: <p>{data1?.Assigned_Specialist}</p>,

          Referral_Status: (
            <SelectDropdown
              label="Referral Status"
              value={data1?.Referral_Status}
              disabledOptions={true}
              enableCompletedOnly={true}
              onChange={(event) => handleReferralStatus(event, index)}
              options={["New", "Under Review", "Pending Revision", "Completed"]}
            />
          ),
          // Name_Qalead: data1?.Name_Qalead,

          Date_Of_Referral: data1?.Date_Of_Referral,
          Referral_Sent_To_QA_Date: data1?.Referral_Sent_To_QA_Date,
          Referral_Type: data1?.Referral_Type,
          Term_date: (
            // <MuiInput
            //   type="date"
            //   value={assignedTermDateValues[index] || ""}
            //   onChange={(event) => handleTermDateChange(event, index)}
            // />
            <p>{data1?.Term_date}</p>
          ),
          Download: (
            <Checkbox
              type="checkbox"
              // value={scheduleDateValues[index] || ""}
              //onChange={(event) => handleScheduleDateChange(event, index)}
            />
          ),
          Assigned_Specialist: renderAssignedAnalystField(
            selectedDropdownValue,
            index,
            data1,
          ),
        };
      });
    } else {
      return dataMemberListWithDropdown?.map((data1, index) => {
        return {
          ...data1,
          Assigned_Clinician: <p>{data1.Assigned_Clinician || ""}</p>,

          Coding_Analyst: renderCodingAnalystField(
            selectedDropdownValue,
            index,
            data1,
          ),
          QA_Analyst: renderQaAnalystField(selectedDropdownValue, index, data1),
          Visit_Type: <p>{data1.Visit_Type}</p>,
          Schedule_Date: (
            <p>
              {dayjs(assignedScheduleDateValues[index])
                .tz(selectedTimeZoneUtc)
                .format("YYYY-MM-DD HH:mm:ss A")}
            </p>
          ),
          Term_date: (
            <MuiInput
              type="date"
              value={
                assignedTermDateValues[index]
                  ? dayjs(
                      dayjs(assignedTermDateValues[index] || "")
                        .tz(selectedTimeZoneUtc)
                        .format("lll"),
                    )
                  : null
              }
              onChange={(event) => handleTermDateChange(event, index)}
            />
          ),
          HRA_Status: (
            <HraStatusSelectDropdown
              label="HRA status"
              value={data1.HRA_Status || ""}
              onChange={(event) => handleHraStatus(event, index)}
              options={hraStatusOptions}
            />
          ),
          City: <TooltipEllipsisCell value={data1.City} maxWidth={100} />,
          Scheduler_Name: (
            <TooltipEllipsisCell value={data1.Scheduler_Name} maxWidth={100} />
          ),
          Offline: (
            <Checkbox
              type="checkbox"
              // value={scheduleDateValues[index] || ""}
              //onChange={(event) => handleScheduleDateChange(event, index)}
            />
          ),
          Download: (
            <Checkbox
              type="checkbox"
              // value={scheduleDateValues[index] || ""}
              //onChange={(event) => handleScheduleDateChange(event, index)}
            />
          ),
          Days: data1?.Days,
          Sub_Status: data1?.Sub_Status, //getSubStatus(item),
          Evaluation_Date: !_.isEmpty(data1?.Evaluation_Date)
            ? dayjs(data1?.Evaluation_Date)
                .tz(selectedTimeZoneUtc)
                .format("YYYY-MM-DD hh:mm:ss A")
            : "",
        };
      });
    }
  }, [selectedTimeZoneUtc, dataMemberListWithDropdown]);

  return (
    <div className="right-content">
      <div>
        {/* <DashboardComponent> */}
        <QAHeader
          onDropdownChange={handleDropdownChange}
          onUnassignedBtnChange={onUnassignedBtnChange}
          onVphpSelection={handleVphpSelection}
          hraStatusOptions={hraStatusOptionForFilter}
          visitTypeOptions={visitTypeOptions}
          clinicianData={clinicianData}
          selectedCheckboxes={selectedCheckboxes}
          setSelectedCheckboxes={setSelectedCheckboxes}
          onSearch={handleSearch}
          onCheckboxChange={handleCheckboxChange}
          areCheckboxesSelected={areCheckboxesSelected}
        />
        {/* </DashboardComponent> */}
      </div>

      {isDialogOpen === true ? (
        <VphpDialog
          onSelection={handleVphpSelection}
          fetchMemberData={fetchMemberData}
          setLoading={setLoading}
          routedFrom="qalist"
        />
      ) : (
        <div className="container">
          {
            // clientData &&
            // clientData.isAllSelected === true
            (clientData?.isAllSelected === true ||
              localStorage.getItem("isAllSelected") === "true") &&
            (clientData?.plan !== null || storedPlan) &&
            (clientData?.screeningTypes !== null || storedScreeningTypes) &&
            (clientData?.year !== null || storedYear) ? (
              <div className="datatable-memberlist">
                {loading ? (
                  <Loader />
                ) : (
                  <DataTableComponent
                    samp="a"
                    data={mappingData}
                    handleSorting={handleSorting}
                    //showCheckboxHeader={showCheckboxHeader}
                    header={tableHeaders}
                    selectedDropdownValue={selectedDropdownValue}
                    selectAllCompletedHeaderCheckboxState={
                      selectAllCompletedHeaderCheckbox
                    }
                    selectAllUploadHeaderCheckboxState={
                      selectAllUploadHeaderCheckbox
                    }
                    selectedRowCompletedCheckboxes={
                      selectedRowCompletedCheckboxes
                    }
                    selectedRowUploadCheckboxes={selectedRowUploadCheckboxes}
                    selectedRowCheckboxes={selectedRowCheckboxes}
                    // handleReadyForUploadHeaderCheckboxChange={
                    //   handleReadyForUploadHeaderCheckboxChange
                    // }
                    // handleCompletedHeaderCheckboxChange={
                    //   handleCompletedHeaderCheckboxChange
                    // }
                    //handleHeaderCheckboxChange={handleHeaderCheckboxChange}
                    handleRowCheckboxChange={handleRowCheckboxChange}
                    handleCheckboxSelection={handleCheckboxSelection}
                    selectedCheckboxes={selectedCheckboxes}
                    setSelectedCheckboxes={setSelectedCheckboxes}
                    rowsPerPageOptions={[10, 20, 30]}
                    component="div"
                    count={totalRowCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    selectedHeader={orderBy}
                    selectedSorting={order}
                    handleHeaderCheckboxChange={handleHeaderCheckboxChange}
                    selectAllHeaderCheckboxState={selectAllHeaderCheckbox}
                  />
                )}
              </div>
            ) : (
              <VphpDialog
                onSelection={handleVphpSelection}
                routedFrom="NurseList"
                fetchMemberData={fetchMemberData}
                setLoading={setLoading}
              />
            )
          }
          {hraStatus && isActivateAlertOpen && (
            <Alert
              open={isActivateAlertOpen}
              handleClose={() => setIsActivateAlertOpen(false)}
              title="Do you want to change HRA Status"
              okText="Yes"
              cancelText="No"
              onAddClick={handleAlertYesClick}
              onNoClick={handleHraStatusCancel}
            />
          )}
          {visitTypeValues && isVisitTypeAlertOpen && (
            <Alert
              open={isVisitTypeAlertOpen}
              handleClose={() => setIsVisitTypeAlertOpen(false)}
              title={`Do you want to change Visit Type of ${selectedRowData.Name} ${selectedRowData.memberId}?`}
              okText="Yes"
              cancelText="No"
              onAddClick={handleVisitTypeUpdate}
              onNoClick={handleVisitTypeCancel}
            />
          )}
          {/* {assignedClinicianValues && isAssignedClinicianAlertOpen && (
            <Alert
              open={isAssignedClinicianAlertOpen}
              handleClose={() => setIsAssignedClinicianAlertOpen(false)}
              title={`Do you want to assign ${assignedClinicianValues[selectedRowIndex]} to ${selectedRowData.Name} ${selectedRowData.memberId}?`}
              okText="Yes"
              cancelText="No"
              onAddClick={handleAssignedClinicianTypeUpdate}
              onNoClick={handleAssignedClinicianTypeCancel}
            />
          )} */}
          {/* {assignedCodingValues && isAssignedCodingAlertOpen && (
            <Alert
              open={isAssignedCodingAlertOpen}
              handleClose={() => setIsAssignedCodingAlertOpen(false)}
              title={`Do you want to assign ${assignedCodingValues[selectedCoderRowIndex]} to ${selectedRowData.Name} ${selectedRowData.memberId}?`}
              okText="Yes"
              cancelText="No"
              onAddClick={handleAssignedCodingTypeUpdate}
              onNoClick={handleAssignedCodingTypeCancel}
            />
          )} */}
          {assignedQaValues && isAssignedQaAlertOpen && (
            <Alert
              open={isAssignedQaAlertOpen}
              handleClose={() => setIsAssignedQaAlertOpen(false)}
              title={`Do you want to assign ${assignedQaValues[selectedQaRowIndex]} to ${selectedRowData.Name} ${selectedRowData.memberId}?`}
              okText="Yes"
              cancelText="No"
              onAddClick={handleAssignedQaTypeUpdate}
              onNoClick={handleAssignedQaTypeCancel}
            />
          )}
          {/* {assignedTermDateValues && isAssignedTermDateAlertOpen && (
            <Alert
              open={isAssignedTermDateAlertOpen}
              handleClose={() => setIsAssignedTermDateAlertOpen(false)}
              title={`Are you sure you want to change the term date for ${selectedRowData.Name}?`}
              okText="Yes"
              cancelText="No"
              onAddClick={handleAssignedTermDateTypeUpdate}
              onNoClick={handleAssignedTermDateTypeCancel}
            />
          )} */}
          {assignedScheduleDateValues && isAssignedScheduleDateAlertOpen && (
            <Alert
              open={isAssignedScheduleDateAlertOpen}
              handleClose={() => setIsAssignedScheduleDateAlertOpen(false)}
              title={`Do you want to schedule the selected date?`}
              okText="Yes"
              cancelText="No"
              onAddClick={handleAssignedScheduleDateTypeUpdate}
              onNoClick={handleAssignedScheduleDateTypeCancel}
            />
          )}
          {isCancelAppointmentAlertOpen && (
            <AppointmentModal
              open={isCancelAppointmentAlertOpen}
              handleClose={() => setIsCancelAppointmentAlertOpen(false)}
              onSave={(cancelComment) =>
                handleCancelAppointmentAlertYesClick(cancelComment)
              }
              onExit={handleCancelAppointmentAlertExitClick}
            />
          )}
          {referralStatus && isReferralActivateAlertOpen && (
            <Alert
              open={isReferralActivateAlertOpen}
              handleClose={() => setIsReferralActivateAlertOpen(false)}
              title="Do you want to change Referral Status"
              okText="Yes"
              cancelText="No"
              onAddClick={handleReferralAlertYesClick}
              onNoClick={handleReferralStatusCancel}
            />
          )}
          <Toaster
            open={toasterOpen}
            onClose={onCloseToaster}
            severity={toasterSeverity}
            message={toasterMessage}
          />
        </div>
      )}
      <ReviewReferralForm
        open={openReviewDialog}
        onClose={handleCloseReviewDialog}
        patientId={patientId}
        assignedClinician={assignedClinician}
        patientName={patientName}
        qaName={qaName}
        referralData={referralData}
        referralId={referralId}
      />
      <ReviewDialog
        open={openPopup}
        onClose={() => setOpenPopup(false)}
        message={popupMessage}
      />
      {isLoading && <Loader />}
    </div>
  );
};

export default Qalist;
