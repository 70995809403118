import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import UrgentReferralForm from "./UrgentReferralForm";
import NonUrgentReferralForm from "./NonUrgentReferralForm";
import CriticalReferralForm from "./CriticalReferralForm";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  // '& .MuiDialogActions-root': {
  //   padding: theme.spacing(1),
  // },
  "& .MuiPaper-root": {
    width: "80%", // Change this to the desired width
    maxWidth: "none", // Prevents the dialog from shrinking
  },
}));

const BootstrapDialogTitle = ({ children, onClose, ...other }) => {
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#fff",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const CustomRadio = styled(Radio)(({ theme }) => ({
  color: "var(--unselectedColor)", // Unchecked color
  "&.Mui-checked": {
    color: "var(--selectedColor)", // Checked color
  },
}));

export default function ReferralForm({
  open,
  onClose,
  formData,
  showCriticalOnly,
  initialType,
  referralId,
  memberNurseId,
  refSchId,
  uniqueMemberId,
  schedulerName,
  dateOfBirth,
  schedulerMemberId,
}) {
  const [selectedValue, setSelectedValue] = useState("URGENT");
  const [selectedType, setSelectedType] = useState(initialType); // Default to urgent (type 1)

  useEffect(() => {
    if (initialType) {
      setSelectedType(initialType);
    }
  }, [initialType]);

  // useEffect(() => {
  //   // Logic to determine which form was submitted and set the appropriate radio button
  //   if (formData) {
  //     if (formData.type === "URGENT") {
  //       setSelectedValue("URGENT");
  //     } else if (formData.type === "NON-URGENT") {
  //       setSelectedValue("NON-URGENT");
  //     } else if (formData.type === "Critical Incident Report") {
  //       setSelectedValue("Critical Incident Report");
  //     }
  //   }
  // }, [formData]);

  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleTypeChange = (type) => {
    setSelectedType(type);
  };

  return (
    <BootstrapDialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <BootstrapDialogTitle
        id="customized-dialog-title"
        sx={{
          backgroundColor: "var(--mainTheme900)",
          color: "white",
          textAlign: "center",
          fontSize: "22px",
          fontWeight: "600",
        }}
        onClose={onClose}
      >
        Case Management Referral Form
        <Typography
          sx={{
            fontSize: "20px",
            textAlign: "left",
            marginTop: "28px",
          }}
          gutterBottom
        >
          Please check which Category the referral fits in.
        </Typography>
        <FormControl sx={{ display: "flex" }} component="fieldset">
          <RadioGroup
            row
            sx={{ display: "flex", gap: "100px" }}
            //value={selectedValue || }
            value={selectedType}
            onChange={(e) => setSelectedType(e.target.value)}
          >
            {!showCriticalOnly && (
              <>
                <FormControlLabel
                  //value="URGENT"
                  value="1"
                  control={<CustomRadio />}
                  label="URGENT"
                />
                <FormControlLabel
                  // value="NON-URGENT"
                  value="2"
                  control={<CustomRadio />}
                  label="NON-URGENT"
                />
              </>
            )}
            <FormControlLabel
              value="3"
              //value="Critical Incident Report"
              control={<CustomRadio />}
              label="Critical Incident Report"
            />
          </RadioGroup>
        </FormControl>
      </BootstrapDialogTitle>
      <DialogContent>
        {!showCriticalOnly && selectedType === "1" && (
          <UrgentReferralForm
            open={open}
            onClose={onClose}
            referralId={referralId}
            memberNurseId={memberNurseId}
          />
        )}
        {!showCriticalOnly && selectedType === "2" && (
          <NonUrgentReferralForm
            onClose={onClose}
            referralId={referralId}
            memberNurseId={memberNurseId}
          />
        )}
        {(showCriticalOnly || selectedType === "3") && (
          <CriticalReferralForm
            onClose={onClose}
            referralId={referralId}
            memberNurseId={memberNurseId}
            refSchId={refSchId}
            uniqueMemberId={uniqueMemberId}
            schedulerName={schedulerName}
            dateOfBirth={dateOfBirth}
            schedulerMemberId={schedulerMemberId}
          />
        )}
      </DialogContent>
      {/* <DialogActions>
        <Button
          sx={{
        backgroundColor: "var(--mainTheme900)",
        color: "white",
        width: "100px",
        '&:hover': {
          backgroundColor: "var(--mainTheme500)", 
        },
      }}
      //  autoFocus
        onClick={onClose}>
          Submit
        </Button>
        <Button 
        sx={{
          backgroundColor: "var(--mainTheme900)",
          color: "white",
          width: "100px",
          '&:hover': {
            backgroundColor: "var(--mainTheme500)",
          },
        }}
        // autoFocus
         onClick={onClose}>
          Cancel
        </Button>
      </DialogActions> */}
    </BootstrapDialog>
  );
}
