import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc"; // Import UTC plugin for Day.js
import timezone from "dayjs/plugin/timezone";
import _ from "lodash";
import { useUserContext } from "../../../../contexts/UserContext";
export default function DateTimeDayJsDisplay({
  DateTimeFormat,
  selectedTimeZone,
}) {
  const [currentDateTime, setCurrentDateTime] = useState("");
  const timeZoneSelection = [
    {
      id: "PDT",
      value: "America/Los_Angeles",
    },
    {
      id: "PST",
      value: "America/Los_Angeles",
    },
    {
      id: "MST",
      value: "America/Denver",
    },
    {
      id: "MDT",
      value: "America/Denver",
    },
    {
      id: "CST",
      value: "America/Chicago",
    },
    {
      id: "CDT",
      value: "America/Chicago",
    },
    {
      id: "EST",
      value: "America/New_York",
    },
    {
      id: "EDT",
      value: "America/New_York",
    },
    {
      id: "GMT",
      value: "GMT",
    },
    {
      id: "IST",
      value: "Asia/Kolkata",
    },
  ];
  const { setSelectedTimeZoneUtc } = useUserContext();
  // Extend Day.js with plugins
  dayjs.extend(utc);
  dayjs.extend(timezone);

  useEffect(() => {
    const mappingZone = _.find(timeZoneSelection, {
      id: selectedTimeZone?.abbr,
    });
    const intervalId = setInterval(() => {
      // Update the state
      setCurrentDateTime(
        dayjs().tz(selectedTimeZone?.utc[0]).format("YYYY-MM-DD HH:mm:ss A"),
      );
    }, 1000);
    // Clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, [selectedTimeZone]);

  useEffect(() => {
    setSelectedTimeZoneUtc(selectedTimeZone?.utc[0]);
    localStorage.setItem("selectedTimeZone", JSON.stringify(selectedTimeZone));
  }, [selectedTimeZone]);
  return <div style={{ width: "175px" }}>{currentDateTime}</div>;
}
