import { useEffect, useState } from "react";
import { getAdminDashboardData } from "../../../api/graphsApi";

export const useAdminData = (param) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (!param?.planId || !param?.year) return;
    const fetchAdminData = async () => {
      try {
        setLoading(true);
        const response = await getAdminDashboardData(param);
        const DashData = response?.data?.data;
        if (typeof DashData === "object") {
          //Schedulers Data
          const schHeader = {
            callsCompleted: DashData["Total Call made for the year"],
            scheduled: DashData["Total Scheduled"],
            refused:
              DashData["Grouped By Call Result"].find(
                (result) => result.result === "Refusal",
              )?.count || 0,
            utc: DashData["Total UTC"],
          };

          const schCallCompleted = DashData[
            "Top Schedulers By Call Attempt"
          ].map((sch) => ({
            name: `${sch.firstName}`,
            //name: `${sch.firstName} ${sch.lastName}`,
            count: sch.totalCallLogCount,
          }));

          const colorAssignments = {
            Scheduled: "rgba(0, 255, 0, 0.8)", //"#4caf50", // Positive (Green)
            "No VM Setup": "#808080", // Neutral (Gray)
            "Call Back Requested": "#FFFF00", //"#ffeb3b", // Attention (Bright Yellow)
            "Left Voicemail": "#ff9800", // Caution (Orange)
            "Appointment Cancelled": "rgba(58, 0, 204, 0.5)", // Negative (Purple)
            "Appointment Cancellation": "#FF0000", //"#f44336", // Negative (Red)
            "Dead Air": "#616161", // Neutral (Dark Gray)
            "F2F Call Back": "#009688", // Positive (Teal)
            Hospice: "#80deea", // Soft, Calm (Light Blue)
            Ineligible: "#673ab7", // Negative (Deep Purple)
            "Line Busy": "#FFC0CB", //"#e91e63", // Caution (Pink)
            "Mailbox Full": "#9c27b0", // Neutral (Purple)
            "No Show": "#ff5722", // Negative (Dark Orange)
            Rescheduled: "#03a9f4", // Positive (Bright Blue)
            Refusal: "rgba(58, 0, 204, 1)",
          };

          const schCallResult = DashData["Grouped By Call Result"]
            .filter((result) => result.result)
            .map((result, index) => ({
              name: result.result || "Unknown",
              count: result.count,
              color: colorAssignments[result.result],
            }));

          const scheduled =
            DashData["Grouped By Call Result"].find(
              (result) => result.result === "Scheduled",
            )?.count || 0;
          const rescheduled =
            DashData["Grouped By Call Result"].find(
              (result) => result.result === "Rescheduled",
            )?.count || 0;
          const totalCallsMade = DashData["Total Call made for the year"];
          const schedulingSuccessRate =
            ((scheduled + rescheduled) / totalCallsMade) * 100;

          const refusals =
            DashData["Grouped By Call Result"].find(
              (result) => result.result === "Refusal",
            )?.count || 0;
          const refusalRate = (refusals / totalCallsMade) * 100;

          const schSchedulingRate = [
            {
              name: "scheduled",
              count: scheduled,
              color: "rgba(0, 255, 0, 0.8)", //'#ABCE56',
            },
            {
              name: "Rescheduled",
              count: rescheduled,
              color: "#ffeb3b", //'#FFCE56',
            },
            {
              name: "Refused",
              count: refusals,
              color: "#FF0000", //"#f44336"
            },
          ];

          const callBack =
            DashData["Grouped By Call Result"].find(
              (result) => result.result === "Call Back Requested",
            )?.count || 0;
          const noShow =
            DashData["Grouped By Call Result"].find(
              (result) => result.result === "No Show",
            )?.count || 0;
          const connectionRate =
            ((scheduled + rescheduled + callBack + refusals + noShow) /
              DashData["Total Targets Received"]) *
            100;

          const schConnectionRate = [
            {
              name: "Scheduled",
              count: scheduled,
              color: "rgba(0, 255, 0, 0.8)", //"#4caf50"
            },
            {
              name: "Rescheduled",
              count: rescheduled,
              color: "#03a9f4",
            },
            {
              name: "Call Backs Requested",
              count: DashData["Total Call Backs"],
              color: "#ffeb3b",
            },
            {
              name: "Refused",
              count: DashData["Total Refused"],
              color: "rgba(58, 0, 204, 1)",
            },
            {
              name: "No Shows",
              count: DashData["YTD No Show"],
              color: "#ff5722",
            },
          ];

          const schSuccRefData = [
            {
              name: "Success",
              count: Math.ceil(schedulingSuccessRate),
              color: "#304d6d",
            },
            {
              name: "Refusal",
              count: Math.ceil(refusalRate),
              color: "#82a0bc",
            },
          ];

          const schCancelNoshData = [
            {
              name: "Cancellation",
              count: DashData["YTD Cancellation"],
              color: "#FF0000", //"#304d6d"
            },
            {
              name: "No Shows",
              count: DashData["YTD No Show"],
              color: "#FFC0CB", //"#82a0bc"
            },
          ];

          //QAs Data
          const qaHeader = {
            total: DashData["Total Targets Received"],
            notStarted: DashData["YTD Not Started"],
            ineligible:
              DashData[
                "Total Ineligible (Deceased, Hospice, Termed, Ineligible)"
              ],
            completed: DashData["YTD Assessment Completed"],
          };

          const qaByAssigned = DashData["Top QA By QAAssigned"].map((qa) => ({
            name: `${qa.firstName}`, // ${qa.lastName}`,
            count: qa.totalmember,
          }));

          const qaByReview = DashData["Top QA By QAReviewCount"].map((qa) => ({
            name: `${qa.firstName}`, // ${qa.lastName}`,
            count: qa.totalQaReviewCount,
          }));

          const qaAssignedNotStarted = [
            {
              name: "Started",
              count:
                DashData["Total Targets Received"] -
                DashData["YTD Not Started"],
              color: "#ABCE56",
            },
            {
              name: "Not Started",
              count: DashData["YTD Not Started"],
              color: "#FFCE56",
            },
          ];

          //Coders Data
          const coderHeader = {
            total: DashData["Total Targets Received"],
            completed: DashData["YTD Assessment Completed"],
            ineligible:
              DashData[
                "Total Ineligible (Deceased, Hospice, Termed, Ineligible)"
              ],
            uploaded: DashData["YTD Assessment Uploaded"],
          };

          const coderAssessData = [
            {
              name: "Completed",
              count: DashData["YTD Assessment Completed"],
              color: "#ABCE56",
            },
            {
              name: "Uploaded",
              count: DashData["YTD Assessment Uploaded"],
              color: "#FFCE56",
            },
          ];

          const codersByAssigned = DashData["Top Coder By CoderAssigned"].map(
            (coder) => ({
              name: `${coder.firstName}`, // ${coder.lastName}`,
              count: coder.totalmember,
            }),
          );

          const codersByReview = DashData["Top Coder By CoderReviewCount"].map(
            (coder) => ({
              name: `${coder.firstName}`, // ${coder.lastName}`,
              count: coder.totalcoderReviewCount,
            }),
          );

          setData((prev) => ({
            ...prev,
            schHeader,
            schCallCompleted,
            schCallResult,
            schConnectionRate,
            connectionRate,
            schSchedulingRate,
            schSuccRefData,
            schCancelNoshData,
            qaHeader,
            qaByAssigned,
            qaByReview,
            qaAssignedNotStarted,
            coderHeader,
            coderAssessData,
            codersByAssigned,
            codersByReview,
          }));
        }
      } catch (error) {
        console.error("Error fetching Admin DashData:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchAdminData();
  }, [param]);
  return { data, loading };
};
