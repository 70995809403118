import Button from "@mui/material/Button";
import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import MonthlyAvailabilityForm from "../../ViewClinicians/MonthlyAvail.form";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { getApi, postApi } from "../../../../utils/axiosApiWrapper";
import moment from "moment";
import { MdEdit, MdOutlineSave } from "react-icons/md";
//import EditClinicianD from "../../ViewClinicians/EditClinician/EditClinician";
import EditClinicianDialog from "../../../Dialog/EditClinicianDialog/EditClinicianDialog";
//import {ProfilePhotoUpload} from "../../ViewClinicians/uploadProfilePic";
import ProfilePhotoUpload from "../../ViewClinicians/uploadProfilePic";
import { Card, CardHeader } from "@mui/material";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Typography,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Link,
  Fade,
  IconButton,
} from "@mui/material";

const ClinicianProfile = ({ open, handleClose }) => {
  const [fillMonthlyAvailForm, setFillMonthlyAvailForm] = useState(false);
  const [rateList, setRateList] = useState([]);
  const [clinicianData, setClinicianData] = useState({});
  const [editProfile, setEditProfile] = useState(false);
  const [selectedPrograms, setSelectedPrograms] = useState([]);
  const [planData, setPlanData] = useState([]);
  //     userName: "",
  //   password: "",
  //   firstName: "",
  //   lastName: "",
  //   speciality: "",
  //   dateOfBirth: "",
  //   gender: "",
  //   address: "",
  //   city: "",
  //   state: [],
  //   zipCode: "",
  //   county: "",
  //   clientName: "",
  //   phoneNumber: "",
  //   homeNumber: "",
  //   email: "",
  //   npi: "",
  //   licenseNumber: "",
  //   validStates: [],
  //   licenseExpiryDate: "",
  //   pedApproved: "",
  //   status: 1,
  //   evaluation: null,
  //   evaluationReason: null,
  //   emerContactName: "",
  //   emerContactAddress: "",
  //   emerContactPhone: "",
  //   emerContactRelation: "",
  //   timeZone: [],
  //   notes: "",
  //   zipCodeExclusion: "",
  //   payRates: "",
  //   // lastUpdated: "",
  //   monthlyAvailability: "",
  //   maxMiles: "",
  //   planProgramId: "1_1",
  //   languages: [],
  //   licenseType: [],
  //   clientsSupported: [],
  //   prefContactMethod: [],

  const tableCellStyle = {
    fontWeight: "bold",
    backgroundColor: "var(--mainTheme100)",
    border: "1px solid var(--mainTheme)",
    textAlign: "center",
    whiteSpace: "nowrap",
  };

  const tableHeadStyle = {
    fontWeight: "bold",
    backgroundColor: "var(--mainTheme)",
    border: "1px solid var(--mainTheme)",
    textAlign: "ceanter",
    whiteSpace: "nowrap",
  };
  const [selectedTab, setSelectedTab] = useState("Demographics");
  const handleTabSwitch = (value) => {
    setSelectedTab(value);
  };

  const getVisitTypeRate = (field) => {
    const _payRate = clinicianData?.payRates?.filter(
      (rate) => rate?.visitType == field,
    );
    return _payRate?.[0]?.rate;
  };
  const handleRateChange = (value, field) => {
    const rateObj = {
      rate: value,
      visitType: field,
    };
    setRateList([...rateList, rateObj]);
  };

  const fetchClinicianDada = async () => {
    // Check if the data is already set
    // if (clinicianData) {
    //   return; // Avoid making the API call if data is already available
    // }

    try {
      const response = await getApi(`/clinicians/mydetails`);
      console.log("response", response);

      // Store the data to avoid re-fetching it
      setClinicianData(response?.data?.data);
    } catch (error) {
      console.error("Error fetching clinician data:", error);
    }
  };
  useEffect(() => {
    if (open) {
      // Only fetch if the dialog is open
      fetchClinicianDada();
    }
  }, [open]);

  const formattedData = clinicianData?.userClients?.flatMap((userClient) => {
    return userClient?.programs?.map((programId) => ({
      clientId: userClient.plan,
      programId: programId,
    }));
  });

  useEffect(() => {
    const fetchData = () => {
      setSelectedPrograms(formattedData || []);
    };

    fetchData();
  }, [selectedTab]);

  const [isDataFetched, setIsDataFetched] = React.useState(false); // Track if data has been fetched successfully

  const fetchClientData = async () => {
    try {
      const response = await getApi("/clients/list");
      const apiData = response?.data?.data;
      //setApiData(apiData);

      if (apiData && apiData.length > 0) {
        const clientProgramData = {};

        apiData.forEach((client) => {
          client?.program.forEach((program) => {
            const clientProgramKey = `${client._id}_${program.id}`;
            if (!clientProgramData[clientProgramKey]) {
              clientProgramData[clientProgramKey] = {
                programId: program.id.toString(),
                program: program?.name,
                clientId: client?._id,
                plan: client?.plan,
              };
            }
          });
        });

        setPlanData(Object.values(clientProgramData));
        setIsDataFetched(true);
        const programArray = Object.values(clientProgramData);
        programArray.forEach((programs) => {
          const { clientId, programId, program, plan } = programs;
        });
      }
    } catch (error) {
      return;
    } finally {
      setIsDataFetched(false); // Reset loading state regardless of success or failure
    }
  };

  const uniquePlans = [...new Set(planData.map((item) => item.plan))];
  const uniquePrograms = [...new Set(planData.map((item) => item.program))];

  useEffect(() => {
    // Check if the selectedTab is valid, no existing plan data, and not currently loading
    if (selectedTab && (!planData || planData.length === 0) && !isDataFetched) {
      fetchClientData();
    }
  }, [selectedTab, planData, isDataFetched]);
  // console.log("clinicianData?.dateOfBirth", clinicianData?.dateOfBirth);
  return (
    <>
      {editProfile ? (
        <EditClinicianDialog
          title="Edit Clinician"
          componentToShow="clinician"
          open={editProfile}
          onClose={() => {
            setEditProfile(false);
          }}
          editMode={true}
          selectedClinicianData={clinicianData}
          handleCloseProfilePage={handleClose}
          // setSelectedClinicianData={setSelectedClinicianData}
          //fetchClinicianData={fetchClinicianData}
        />
      ) : (
        <Dialog
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          open={open}
          onClose={handleClose}
          maxWidth="lg"
          sx={{
            "& .MuiPaper-root": {
              width: "100%",
            },
          }}
        >
          <DialogTitle
            sx={{
              background: "var(--mainTheme)",
              fontSize: "20px",
              color: "white",
              fontFamily: "Inter,sans-serif",
              height: "40px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            id="alert-dialog-title"
          >
            <Box display="flex" justifyContent="space-between" width="100%">
              <Box display="flex" justifyContent="flex-start" width={0.5}>
                Your Profile
              </Box>
              <Box display="flex" justifyContent="flex-end" width={0.5}>
                <IconButton
                  onClick={() => {
                    setEditProfile(true);
                  }}
                >
                  <MdEdit
                    size={20}
                    color="white"
                    onClick={(e) => setEditProfile(true)}
                    style={{
                      marginTop: "1px",
                      marginRight: "5px",
                      marginLeft: "5px",
                      cursor: "pointer",
                    }}
                  />
                </IconButton>
              </Box>
            </Box>
          </DialogTitle>

          <DialogContent>
            <Box marginBlock={2}>
              <Tabs
                className="custom-tabs"
                value={selectedTab}
                onChange={(e, newValue) => handleTabSwitch(newValue)}
                aria-label="wrapped label tabs example"
              >
                {[
                  "Demographics",
                  "License Information",
                  "Pay Rates",
                  "Monthly Availability Form",
                  "Clients",
                  "Profile Picture",
                ].map((option, index) => (
                  <Tab key={index} value={option} label={option} />
                ))}
              </Tabs>
            </Box>
            <Fade in={selectedTab == "Demographics"} unmountOnExit>
              <Box>
                <Box
                  marginBlock={2}
                  sx={{
                    background: "var(--mainTheme)",
                    fontSize: "20px",
                    color: "white",
                    fontFamily: "Inter,sans-serif",
                    height: "20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "left",
                    width: "100%",
                  }}
                >
                  <Typography>Demographics</Typography>
                </Box>
                {/* firstName */}
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography style={{ width: "15%" }}>
                        First Name:
                      </Typography>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={clinicianData?.firstName}
                        onChange={() => {
                          console.log("hello");
                        }}
                        style={{
                          width: "100%",
                          padding: "8px",
                          marginTop: "5px",
                        }}
                        required
                      />
                    </Box>
                  </Grid>
                </Grid>
                {/* lastName */}
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography style={{ width: "15%" }}>
                        Last Name:
                      </Typography>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={clinicianData?.lastName}
                        onChange={() => {
                          console.log("hello");
                        }}
                        style={{
                          width: "100%",
                          padding: "8px",
                          marginTop: "5px",
                        }}
                        required
                      />
                    </Box>
                  </Grid>
                </Grid>
                {/* speciality */}
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography style={{ width: "15%" }}>
                        Speciality:
                      </Typography>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={clinicianData?.speciality}
                        onChange={() => {
                          console.log("hello");
                        }}
                        style={{
                          width: "100%",
                          padding: "8px",
                          marginTop: "5px",
                        }}
                        required
                      />
                    </Box>
                  </Grid>
                </Grid>
                {/* dob */}
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography style={{ width: "15%" }}>Dob:</Typography>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        //value={moment(clinicianData?.dateOfBirth).format('MM/DD/YYYY')}
                        value={moment(
                          new Date(clinicianData?.dateOfBirth),
                        ).format("MM/DD/YYYY")}
                        onChange={() => {
                          console.log("hello");
                        }}
                        style={{
                          width: "100%",
                          padding: "8px",
                          marginTop: "5px",
                        }}
                        required
                      />
                    </Box>
                  </Grid>
                </Grid>

                {/* gender */}
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography style={{ width: "15%" }}>Gender:</Typography>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={clinicianData?.gender}
                        onChange={() => {
                          console.log("hello");
                        }}
                        style={{
                          width: "100%",
                          padding: "8px",
                          marginTop: "5px",
                        }}
                        required
                      />
                    </Box>
                  </Grid>
                </Grid>
                {/* address */}
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography style={{ width: "15%" }}>
                        Street Address:
                      </Typography>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={clinicianData?.address}
                        onChange={() => {
                          console.log("hello");
                        }}
                        style={{
                          width: "100%",
                          padding: "8px",
                          marginTop: "5px",
                        }}
                        required
                      />
                    </Box>
                  </Grid>
                </Grid>
                {/* city */}
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography style={{ width: "15%" }}>City:</Typography>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={clinicianData?.city}
                        onChange={() => {
                          console.log("hello");
                        }}
                        style={{
                          width: "100%",
                          padding: "8px",
                          marginTop: "5px",
                        }}
                        required
                      />
                    </Box>
                  </Grid>
                </Grid>

                {/* state */}
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography style={{ width: "15%" }}>State:</Typography>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={clinicianData?.state}
                        onChange={() => {
                          console.log("hello");
                        }}
                        style={{
                          width: "100%",
                          padding: "8px",
                          marginTop: "5px",
                        }}
                        required
                      />
                    </Box>
                  </Grid>
                </Grid>
                {/* zipCode */}
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography style={{ width: "15%" }}>ZipCode:</Typography>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={clinicianData?.zipCode}
                        onChange={() => {
                          console.log("hello");
                        }}
                        style={{
                          width: "100%",
                          padding: "8px",
                          marginTop: "5px",
                        }}
                        required
                      />
                    </Box>
                  </Grid>
                </Grid>
                {/* county */}
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography style={{ width: "15%" }}>County:</Typography>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={clinicianData?.county}
                        onChange={() => {
                          console.log("hello");
                        }}
                        style={{
                          width: "100%",
                          padding: "8px",
                          marginTop: "5px",
                        }}
                        required
                      />
                    </Box>
                  </Grid>
                </Grid>

                {/* phone Num */}
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography style={{ width: "15%" }}>
                        Mobile Number:
                      </Typography>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={clinicianData?.phoneNumber}
                        onChange={() => {
                          console.log("hello");
                        }}
                        style={{
                          width: "100%",
                          padding: "8px",
                          marginTop: "5px",
                        }}
                        required
                      />
                    </Box>
                  </Grid>
                </Grid>
                {/* Home NUm */}
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography style={{ width: "15%" }}>
                        Home Number:
                      </Typography>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={clinicianData?.homeNumber}
                        onChange={() => {
                          console.log("hello");
                        }}
                        style={{
                          width: "100%",
                          padding: "8px",
                          marginTop: "5px",
                        }}
                        required
                      />
                    </Box>
                  </Grid>
                </Grid>

                {/* email */}
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography style={{ width: "15%" }}>Email:</Typography>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={clinicianData?.email}
                        onChange={() => {
                          console.log("hello");
                        }}
                        style={{
                          width: "100%",
                          padding: "8px",
                          marginTop: "5px",
                        }}
                        required
                      />
                    </Box>
                  </Grid>
                </Grid>

                {/* languages */}

                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography style={{ width: "15%" }}>
                        Languages spoken:
                      </Typography>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={clinicianData?.languages?.join(",")}
                        onChange={() => {
                          console.log("hello");
                        }}
                        style={{
                          width: "100%",
                          padding: "8px",
                          marginTop: "5px",
                        }}
                        required
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Fade>
            {/* License Information Section */}
            <Fade in={selectedTab == "License Information"} unmountOnExit>
              <Box>
                <Box
                  marginBlock={2}
                  sx={{
                    background: "var(--mainTheme)",
                    fontSize: "20px",
                    color: "white",
                    fontFamily: "Inter,sans-serif",
                    height: "20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "left",
                    width: "100%",
                  }}
                >
                  <Typography>License Info</Typography>
                </Box>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography style={{ width: "15%" }}>
                        License Type:
                      </Typography>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={
                          clinicianData?.licenseType?.length > 0
                            ? clinicianData?.licenseType?.join(",")
                            : ""
                        }
                        onChange={() => {
                          console.log("hello");
                        }}
                        style={{
                          width: "100%",
                          padding: "8px",
                          marginTop: "5px",
                        }}
                        required
                      />
                    </Box>
                  </Grid>
                </Grid>

                {/* npi */}
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography style={{ width: "15%" }}>
                        NPI Number:
                      </Typography>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={clinicianData?.npi}
                        onChange={() => {
                          console.log("hello");
                        }}
                        style={{
                          width: "100%",
                          padding: "8px",
                          marginTop: "5px",
                        }}
                        required
                      />
                    </Box>
                  </Grid>
                </Grid>
                {/* license Number */}
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography style={{ width: "15%" }}>
                        License Number:
                      </Typography>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={clinicianData?.licenseNumber}
                        onChange={() => {
                          console.log("hello");
                        }}
                        style={{
                          width: "100%",
                          padding: "8px",
                          marginTop: "5px",
                        }}
                        required
                      />
                    </Box>
                  </Grid>
                </Grid>
                {/* validStates */}
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography style={{ width: "15%" }}>
                        State(s) it is valid in
                      </Typography>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={clinicianData?.validStates?.join(",")}
                        onChange={() => {
                          console.log("hello");
                        }}
                        style={{
                          width: "100%",
                          padding: "8px",
                          marginTop: "5px",
                        }}
                        required
                      />
                    </Box>
                  </Grid>
                </Grid>

                {/* licenseExpiryDate */}
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography style={{ width: "15%" }}>
                        License Expiry Date:
                      </Typography>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={moment(
                          new Date(clinicianData?.licenseExpiryDate),
                        ).format("mm/DD/YYYY")}
                        onChange={() => {
                          console.log("hello");
                        }}
                        style={{
                          width: "100%",
                          padding: "8px",
                          marginTop: "5px",
                        }}
                        required
                      />
                    </Box>
                  </Grid>
                </Grid>
                {/* client supported */}
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography style={{ width: "15%" }}>
                        Client Supported:
                      </Typography>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={clinicianData?.clientsSupported?.join(",")}
                        onChange={() => {
                          console.log("hello");
                        }}
                        style={{
                          width: "100%",
                          padding: "8px",
                          marginTop: "5px",
                        }}
                        required
                      />
                    </Box>
                  </Grid>
                </Grid>

                {/* peds Approved */}
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography style={{ width: "15%" }}>
                        PEDS approved:
                      </Typography>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={clinicianData?.pedApproved}
                        onChange={() => {
                          console.log("hello");
                        }}
                        style={{
                          width: "100%",
                          padding: "8px",
                          marginTop: "5px",
                        }}
                        required
                      />
                    </Box>
                  </Grid>
                </Grid>

                {/* Status */}
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography style={{ width: "15%" }}>Status:</Typography>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={
                          clinicianData?.status == 1 ? "Active" : "InActive"
                        }
                        onChange={() => {
                          console.log("hello");
                        }}
                        style={{
                          width: "100%",
                          padding: "8px",
                          marginTop: "5px",
                        }}
                        required
                      />
                    </Box>
                  </Grid>
                </Grid>

                {/* evaluation */}
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography style={{ width: "15%" }}>
                        Evaluation:
                      </Typography>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={clinicianData?.evaluation}
                        onChange={() => {
                          console.log("hello");
                        }}
                        style={{
                          width: "100%",
                          padding: "8px",
                          marginTop: "5px",
                        }}
                        required
                      />
                    </Box>
                  </Grid>
                </Grid>

                {/* evaluation Reason */}
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography style={{ width: "15%" }}>
                        Evaluation Reason:
                      </Typography>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={clinicianData?.evaluationReason}
                        onChange={() => {
                          console.log("hello");
                        }}
                        style={{
                          width: "100%",
                          padding: "8px",
                          marginTop: "5px",
                        }}
                        required
                      />
                    </Box>
                  </Grid>
                </Grid>

                {/* emergency contact name */}
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography style={{ width: "15%" }}>
                        Emergency Contact Name:
                      </Typography>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={clinicianData?.emerContactName}
                        onChange={() => {
                          console.log("hello");
                        }}
                        style={{
                          width: "100%",
                          padding: "8px",
                          marginTop: "5px",
                        }}
                        required
                      />
                    </Box>
                  </Grid>
                </Grid>

                {/* emerContactAddress */}
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography style={{ width: "15%" }}>
                        Emergency Contact Address:
                      </Typography>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={clinicianData?.emerContactAddress}
                        onChange={() => {
                          console.log("hello");
                        }}
                        style={{
                          width: "100%",
                          padding: "8px",
                          marginTop: "5px",
                        }}
                        required
                      />
                    </Box>
                  </Grid>
                </Grid>

                {/* emergency Contact Phone Number */}
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography style={{ width: "15%" }}>
                        Emergency Contact Phone:
                      </Typography>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={clinicianData?.emerContactPhone}
                        onChange={() => {
                          console.log("hello");
                        }}
                        style={{
                          width: "100%",
                          padding: "8px",
                          marginTop: "5px",
                        }}
                        required
                      />
                    </Box>
                  </Grid>
                </Grid>

                {/* emerContactRelation */}
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography style={{ width: "15%" }}>
                        Emergency Contact Relation:
                      </Typography>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={clinicianData?.emerContactRelation}
                        onChange={() => {
                          console.log("hello");
                        }}
                        style={{
                          width: "100%",
                          padding: "8px",
                          marginTop: "5px",
                        }}
                        required
                      />
                    </Box>
                  </Grid>
                </Grid>
                {/* timeZone */}
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography style={{ width: "15%" }}>
                        TimeZone:
                      </Typography>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={clinicianData?.timeZone?.join(",")}
                        onChange={() => {
                          console.log("hello");
                        }}
                        style={{
                          width: "100%",
                          padding: "8px",
                          marginTop: "5px",
                        }}
                        required
                      />
                    </Box>
                  </Grid>
                </Grid>
                {/* Preferred Method of Contact */}
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography style={{ width: "15%" }}>
                        Preferred Method of Contact:
                      </Typography>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={clinicianData?.prefContactMethod?.join(",")}
                        onChange={() => {
                          console.log("hello");
                        }}
                        style={{
                          width: "100%",
                          padding: "8px",
                          marginTop: "5px",
                        }}
                        required
                      />
                    </Box>
                  </Grid>
                </Grid>

                {/* notes */}
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography style={{ width: "15%" }}>Notes:</Typography>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={clinicianData?.notes}
                        onChange={() => {
                          console.log("hello");
                        }}
                        style={{
                          width: "100%",
                          padding: "8px",
                          marginTop: "5px",
                        }}
                        required
                      />
                    </Box>
                  </Grid>
                </Grid>
                {/* for  zipcode exclusion */}
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography style={{ width: "15%" }}>
                        Zip Code Exclusion
                      </Typography>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={clinicianData?.zipCodeExclusion}
                        onChange={() => {
                          console.log("hello");
                        }}
                        style={{
                          width: "100%",
                          padding: "8px",
                          marginTop: "5px",
                        }}
                        required
                      />
                    </Box>
                  </Grid>
                </Grid>
                {/* last update */}
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography style={{ width: "15%" }}>
                        Last Updated:
                      </Typography>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={moment(
                          new Date(clinicianData?.updatedAt),
                        ).format("MM/DD/YYYY hh:mm a")}
                        onChange={() => {
                          console.log("hello");
                        }}
                        style={{
                          width: "100%",
                          padding: "8px",
                          marginTop: "5px",
                        }}
                        required
                      />
                    </Box>
                  </Grid>
                </Grid>
                {/* for max miles */}
                {/* <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography style={{ width: "15%" }}>Max Miles:</Typography>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value="100"
                    onChange={() => {
                      console.log("hello");
                    }}
                    style={{ width: "100%", padding: "8px", marginTop: "5px" }}
                    required
                  />
                </Box>
              </Grid>
            </Grid> */}
              </Box>
            </Fade>
            {/* Pay Rates */}
            <Fade in={selectedTab == "Pay Rates"} unmountOnExit>
              <Box>
                <Box
                  marginBlock={2}
                  sx={{
                    background: "var(--mainTheme)",
                    fontSize: "20px",
                    color: "white",
                    fontFamily: "Inter,sans-serif",
                    height: "20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "left",
                    width: "100%",
                  }}
                >
                  <Typography>Pay Rates</Typography>
                </Box>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginTop: "15px",
                    marginLeft: "10px",
                    width: "50%",
                    //margin: "auto",
                  }}
                >
                  <Table
                    sx={{
                      tableLayout: "auto",
                      margin: "10px",
                      overflow: "hidden",
                    }}
                    size="small"
                  >
                    <TableHead style={tableHeadStyle}>
                      <TableRow>
                        <TableCell style={tableCellStyle}>Visit Type</TableCell>
                        <TableCell style={tableCellStyle}>Rate</TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {[
                        { name: "F2F ($ per hour)", field: "F2F" },
                        { name: "Virtual ($ per hour)", field: "Virtual" },
                        { name: "No Show ($ per hour)", field: "NoShow" },
                        { name: "Distance ($ per miles)", field: "Distance" },
                      ]?.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell style={tableCellStyle}>
                            <Typography variant="body2">{row.name}</Typography>
                          </TableCell>

                          <TableCell style={tableCellStyle}>
                            {/* <TextField
                             
                              variant="outlined"
                              
                              sx={{
                                
                                border:
                                  "1.5px solid var(--mainTheme) !important",
                                
                                "& .MuiFormLabel-root": {
                                  backgroundColor: "white",
                                },
                                "& .MuiInputLabel-root": {
                                  backgroundColor: "white",
                                },
                              }}
                              fullWidth
                              size="small"
                              
                            /> */}
                            <input
                              type="text"
                              id="name"
                              name="name"
                              // onChange={(e) =>
                              //   handleRateChange(e?.target?.value, row.field)
                              // }
                              value={getVisitTypeRate(row.field)}
                              style={{
                                width: "100%",
                                padding: "8px",
                                marginTop: "5px",
                              }}
                              required
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
                {/* <Box display="flex" justifyContent="flex-end">
              <Button
                sx={{
                  width: "100px",
                  height: "32px",
                  flexShrink: "0",
                  borderRadius: "5px",
                  background: "var(--mainTheme)",
                  color: "white",
                  "&:hover": {
                    boxShadow: 8,
                    //color: "var(--mainTheme)",
                    background: "var(--mainTheme)",
                    color: "white",
                  },
                }}
                onClick={() => {
                  setFillMonthlyAvailForm(false);
                }}
                color="primary"
              >
                Save
              </Button>
            </Box> */}
              </Box>
            </Fade>
            {/* for Monthly Availability */}
            <Fade in={selectedTab == "Monthly Availability Form"} unmountOnExit>
              <Box>
                <Box
                  marginBlock={2}
                  sx={{
                    background: "var(--mainTheme)",
                    fontSize: "20px",
                    color: "white",
                    fontFamily: "Inter,sans-serif",
                    height: "20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "left",
                    width: "100%",
                  }}
                >
                  <Typography>Monthly Availability Form</Typography>
                </Box>

                <Link
                  component="button"
                  variant="body2"
                  onClick={() => {
                    //handleChange("monthlyAvailability", "");
                    setFillMonthlyAvailForm(true);
                  }}
                >
                  Monthly Availability Form
                </Link>
              </Box>
            </Fade>
            <Fade in={selectedTab == "Clients"} unmountOnExit>
              <Box>
                <Box
                  marginBlock={2}
                  sx={{
                    background: "var(--mainTheme)",
                    fontSize: "20px",
                    color: "white",
                    fontFamily: "Inter,sans-serif",
                    height: "20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "left",
                    width: "100%",
                  }}
                >
                  <Typography>Your Clients</Typography>
                </Box>
                <Box width="50%" height="200px" style={{ overflowY: "scroll" }}>
                  {selectedPrograms
                    ?.map((selectedProgram) => {
                      return planData
                        ?.filter(
                          (item) =>
                            item.clientId === selectedProgram.clientId &&
                            item.programId === selectedProgram.programId,
                        )
                        .map((programItem) => programItem);
                    })
                    ?.flat()
                    ?.reduce((accumulator, programItem) => {
                      const existingPlan = accumulator.find(
                        (plan) => plan.plan === programItem.plan,
                      );

                      if (!existingPlan) {
                        accumulator.push({
                          plan: programItem.plan,
                          programs: [programItem.program],
                        });
                      } else {
                        existingPlan.programs.push(programItem.program);
                      }

                      return accumulator;
                    }, [])
                    ?.sort((a, b) => a.plan.localeCompare(b.plan))
                    ?.map((uniquePlan) => (
                      <div key={uniquePlan.plan}>
                        <Button
                          style={{
                            backgroundColor: "#1076BC",
                            color: "white",
                            width: "100%",
                            marginTop: "3px",
                          }}
                        >
                          {uniquePlan.plan}
                        </Button>
                        {uniquePlan.programs
                          ?.sort((a, b) => a.localeCompare(b))
                          ?.map((program) => (
                            <div
                              key={`${uniquePlan.plan}-${program}`}
                              style={{
                                backgroundColor: "#ccc",
                                color: "#000000de",
                                width: "100%",
                                marginTop: "3px",
                                textAlign: "center",
                                padding: "3px",
                              }}
                            >
                              {program}
                            </div>
                          ))}
                      </div>
                    ))}
                </Box>
              </Box>
            </Fade>
            {/* for profile picture */}
            <Fade in={selectedTab == "Profile Picture"} unmountOnExit>
              <Box>
                <CardHeader
                  title={"Your Profile Picture"}
                  action={
                    <div
                      style={{
                        width: "100% !important",
                        margin: "0 !important",
                        padding: "0 !important",
                      }}
                    >
                      {/* <IconButton
                      style={{
                        float: "right",
                        color: "#FFFFFF",
                      }}
                      aria-label="Section minimise"
                      size="small"
                      onClick={toggleAvailabilitySection}
                    >
                      {isShowAvailability ? (
                        <KeyboardControlKeyIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )}
                    </IconButton> */}
                    </div>
                  }
                  sx={{
                    backgroundColor: `var(--mainTheme)`,
                    color: "white",
                    padding: "10px",
                    textAlign: "left !important",
                    fontWeight: 500,
                    marginBottom: "5px",
                    width: "1100px",
                  }}
                  titleTypographyProps={{ variant: "outline" }}
                />
                <ProfilePhotoUpload
                  profilePic={clinicianData?.profilePicture}
                  disabled={true}
                  //setProfilePic={setProfilePic}
                />
              </Box>
            </Fade>
            <Dialog
              sx={{
                "& .MuiPaper-root": {
                  width: "100%",
                },
              }}
              maxWidth="lg"
              open={fillMonthlyAvailForm}
              onClose={() => {
                setFillMonthlyAvailForm(false);
              }}
            >
              <DialogTitle>Availability and Travel Preferences</DialogTitle>
              <DialogContent>
                <MonthlyAvailabilityForm
                  monthlyAvailForm={clinicianData?.monthlyAvailability}
                  readOnly={true}
                  lastUpdated={moment(
                    new Date(clinicianData?.updatedAt),
                  ).format("MM/DD/YYYY hh:mm a")}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  sx={{
                    width: "100px",
                    height: "32px",
                    flexShrink: "0",
                    borderRadius: "5px",
                    background: "var(--mainTheme)",
                    color: "white",
                    "&:hover": {
                      boxShadow: 8,
                      background: "var(--mainTheme)",
                      color: "white",
                    },
                  }}
                  onClick={() => {
                    setFillMonthlyAvailForm(false);
                  }}
                  color="primary"
                >
                  Close
                </Button>

                {/* <Button
              sx={{
                width: "100px",
                height: "32px",
                flexShrink: "0",
                borderRadius: "5px",
                background: "var(--mainTheme)",
                color: "white",
                "&:hover": {
                  boxShadow: 8,
                  //color: "var(--mainTheme)",
                  background: "var(--mainTheme)",
                  color: "white",
                },
              }}
              onClick={() => {
                setFillMonthlyAvailForm(false);
              }}
              color="primary"
            >
              Save
            </Button> */}
              </DialogActions>
            </Dialog>
          </DialogContent>

          <DialogActions>
            <Button
              sx={{
                width: "100px",
                height: "32px",
                flexShrink: "0",
                borderRadius: "5px",
                background: "var(--mainTheme)",
                color: "white",
                "&:hover": {
                  boxShadow: 8,
                  background: "var(--mainTheme)",
                  color: "white",
                },
              }}
              onClick={() => {
                handleClose();
              }}
              color="primary"
            >
              Close
            </Button>

            {/* <Button
              sx={{
                width: "100px",
                height: "32px",
                flexShrink: "0",
                borderRadius: "5px",
                background: "var(--mainTheme)",
                color: "white",
                "&:hover": {
                  boxShadow: 8,
                  //color: "var(--mainTheme)",
                  background: "var(--mainTheme)",
                  color: "white",
                },
              }}
              onClick={() => {
                setFillMonthlyAvailForm(false);
              }}
              color="primary"
            >
              Save
            </Button> */}
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
export default ClinicianProfile;
