import { useEffect, useState, useContext } from "react";
import toast from "react-hot-toast";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useImperativeHandle, forwardRef } from "react";
import DownloadForOfflineRoundedIcon from "@mui/icons-material/DownloadForOfflineRounded";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Logo from "../../../../../assets/focuscares_logo.png";
import Button from "@mui/material/Button";
import { Box } from "@mui/material";

import {
  generateRTTReport,
  getRTTByAssessmentId,
  updateRTTReport,
} from "../../../../../api/realTimeScriptsApi"; // Ensure this import is correct
import { SaveOutlined } from "@mui/icons-material";
import CachedIcon from "@mui/icons-material/Cached";
import MicNoneIcon from "@mui/icons-material/MicNone";
import { TextField } from "@mui/material";
import { motion } from "framer-motion";
import { useSpeech } from "../../../../../contexts/SpeechContext";
import Loader from "../../../../Loader/Loader";
import StopIcon from "@mui/icons-material/Stop";
import IconButton from "@mui/material/IconButton";
import CustomAudioPlayer from "./CustomAudioPlayer";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import AudioFileIcon from "@mui/icons-material/AudioFile";
import { postApi } from "../../../../../utils/axiosApiWrapper";
import Audio from "./Audio";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  accordion: {
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 0px 10px",
    borderRadius: "20px",
    marginBottom: "10px",
    // transition: "transform 0.2s ease-in-out",
    // "&:hover": {
    //   transform: "scale(1.05)",
    // },
  },
});

const FillHRASummary = ({ apiData, setApiData, uploadedAudioUrl }) => {
  const [accordExpand, setAccordExpand] = useState("panel_Chief");
  const [recText, setRecText] = useState("");

  //const [apiData, setApiData] = useState([]);
  const [selectedSection, setSelectedSection] = useState("");
  const [expandedSection, setExpandedSection] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isListening, setIsListening] = useState(false);
  const [isPlayerVisible, setIsPlayerVisible] = useState(false); // State to toggle the player
  // const [azureBlobUrl, setAzureBlobUrl] = useState("");
  const assessmentId = sessionStorage?.getItem("assessmentId");

  const { setAzureBlobUrl } = useSpeech();
  const classes = useStyles();

  const {
    isRecognise,
    textRecogRef,
    startRecogniser,
    stopRecogniser,
    setRecognisedText,
  } = useSpeech();

  const commands = [
    {
      command: "Replace * with *",
      callback: async (word1, word2) => {
        const sectionToModify = selectedSection || expandedSection; // Use selected section or manually expanded one
        if (sectionToModify) {
          const updatedData = apiData.map((section) => {
            if (section.category === sectionToModify) {
              const updatedContent = section.content.replace(
                new RegExp(`\\b${word1}\\b`, "gi"),
                (match) => {
                  const firstChar = match.charAt(0);
                  const replacement =
                    word2.charAt(0).toUpperCase() + word2.slice(1);
                  return firstChar === firstChar.toUpperCase()
                    ? replacement
                    : replacement.toLowerCase();
                },
              );
              return { ...section, content: updatedContent };
            }
            return section;
          });

          setApiData(updatedData);
          toast.success(
            `Replaced "${word1}" with "${word2}" in section "${sectionToModify}"`,
          );
          resetTranscript();
          // Automatically update the RTT report after replacing the text
          try {
            await updateRTTReport({
              assessmentId,
              rttData: updatedData,
            });
            toast.success("RTT data updated successfully after replacement.");
          } catch (error) {
            console.error("Failed to update RTT report", error);
            toast.error("Failed to update RTT report.");
          }
        } else {
          toast.error("No section selected or expanded.");
          resetTranscript();
        }
      },
    },
    {
      command: "Select * section",
      callback: (sectionName) => {
        const cleanedSectionName = sectionName.trim().toLowerCase();
        const matchedSection = apiData.find(
          (section) => section.category.toLowerCase() === cleanedSectionName,
        );

        if (matchedSection) {
          setSelectedSection(matchedSection.category);
          setExpandedSection(matchedSection.category);
          toast.success(`Section "${matchedSection.category}" selected.`);
        } else {
          toast.error("Section not found.");
        }
        resetTranscript();
      },
    },
    //clear the section
    {
      command: "Clear * section",
      callback: async (sectionName) => {
        const cleanedSectionName = sectionName.trim().toLowerCase();
        const matchedSection = apiData.find(
          (section) => section.category.toLowerCase() === cleanedSectionName,
        );

        if (matchedSection) {
          const updatedData = apiData.map((section) =>
            section.category.toLowerCase() === cleanedSectionName
              ? { ...section, content: "" }
              : section,
          );

          setApiData(updatedData);

          // try {
          //   await updateRTTReport({
          //     assessmentId,
          //     rttData: updatedData,
          //   });
          //   toast.success("RTT data updated automatically after clearing.");
          // } catch (error) {
          //   console.error("Failed to update RTT report after clearing", error);
          //   toast.error("Failed to update RTT report.");
          // }

          toast.success(`Cleared content from "${sectionName}" section.`);
        } else {
          toast.error("Section not found.");
        }

        resetTranscript();
      },
    },

    //add text
    {
      command: "Add text to * section *",
      callback: async (sectionName, newText) => {
        const cleanedSectionName = sectionName.trim().toLowerCase();
        const matchedSection = apiData.find(
          (section) => section.category.toLowerCase() === cleanedSectionName,
        );

        if (matchedSection) {
          const updatedData = apiData.map((section) => {
            if (section.category.toLowerCase() === cleanedSectionName) {
              return { ...section, content: `${section.content} ${newText}` };
            }
            return section;
          });

          // Update state with the new data
          setApiData(updatedData);

          try {
            // Call the update API with the latest data
            await updateRTTReport({
              assessmentId,
              rttData: updatedData,
            });
            toast.success("RTT data updated successfully after adding text.");
          } catch (error) {
            console.error(
              "Failed to update RTT report successfully after adding text",
              error,
            );
            toast.error("Failed to update RTT report.");
          }

          toast.success(
            `Added new text to section "${matchedSection.category}".`,
          );
        } else {
          toast.error("Section not found.");
        }

        resetTranscript();
      },
    },
  ];

  const { transcript, resetTranscript } = useSpeechRecognition({ commands });

  useEffect(() => {
    if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
      toast.error("Your browser does not support speech recognition.");
      return;
    }

    //SpeechRecognition.startListening({ continuous: true });

    // return () => {
    //   SpeechRecognition.stopListening();
    // };
  }, [assessmentId]);

  //autosave

  //   useEffect(() => {
  //   // If apiData has been updated, automatically update RTT report
  //   if (apiData.length > 0) {
  //     const updateData = async () => {
  //       try {
  //         await updateRTTReport({
  //           assessmentId,
  //           rttData: apiData,
  //         });
  //         toast.success("RTT data updated automatically.");
  //       } catch (error) {
  //         console.error("Failed to update RTT report automatically", error);
  //         toast.error("Failed to update RTT report.");
  //       }
  //     };

  //     updateData();
  //   }
  // }, [apiData, assessmentId]); // This will trigger whenever apiData changes

  const fetchData = async () => {
    setLoading(true);
    try {
      const data = await getRTTByAssessmentId(assessmentId); // Replace with your actual API call
      const audioUrl = data?.data?.data?.audioUrls?.[0];
      // Set the Azure Blob URL in context
      setAzureBlobUrl(audioUrl);

      const rttData = data?.data?.data?.rttData;

      if (Array.isArray(rttData)) {
        setApiData(rttData); // Store the rttData in your state
      } else {
        throw new Error("Invalid API response format");
      }
    } catch (error) {
      //toast.error("Failed to fetch data.");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [assessmentId]);

  const handleStartListening = () => {
    SpeechRecognition.startListening({ continuous: true });
  };

  const handleStopListening = () => {
    SpeechRecognition.stopListening();
  };

  if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
    return <div>Your browser does not support speech recognition.</div>;
  }

  //vignesh
  const handleTextChange = (e) => {
    const { name, value } = e.target;
    const oldData = [...apiData];
    let newData = oldData.map((section) => {
      if (section?.category === name) {
        section.content = value;
      }
      return section;
    });

    setApiData(newData);
  };

  const handleSectionReset = (name) => {
    const oldData = [...apiData];
    let newData = oldData.map((section) => {
      if (section?.category === name) {
        section.content = "";
      }
      return section;
    });

    setApiData(newData);
  };

  const handleSectionTranscribe = (name) => {
    const oldData = [...apiData];
    const newData = oldData.map((section) => {
      let tempData = { ...section };
      const { category, content } = tempData;
      if (category === name) {
        const newText = textRecogRef?.current
          ? textRecogRef?.current.join("")
          : recText;
        tempData = {
          ...tempData,
          content: content + newText,
        };
        textRecogRef.current = [];
        resetTranscript();
      }
      return tempData;
    });

    setApiData(newData);
  };

  const handleSectionSave = () => {
    updateRTTReport({
      assessmentId,
      rttData: apiData,
    }).then((response) => toast.success("RTT data updated succesfully"));
  };

  // useEffect(() => {
  //   setLoading(true);
  //   toast.promise(getRTTByAssessmentId(assessmentId), {
  //     loading: "Getting RTT data",
  //     success: (data) => {
  //       const rttSectionsData = data?.data?.data?.[0]?.rttData || [];
  //       if (rttSectionsData && rttSectionsData.length > 0) {
  //         setApiData(rttSectionsData);
  //         return `${data?.data?.message}`;
  //       }
  //       return "RTT Data is empty or not generated";
  //     },
  //     error: (data) => {
  //       return data?.data?.message ? (
  //         <b>{`${data.data.message}`}</b>
  //       ) : (
  //         <b>error when retrieving RTT Data</b>
  //       );
  //     },
  //   });
  //   setLoading(false);
  // }, []);

  const miniButtonStyle = {
    margin: "8px",
    padding: "5px",
    borderRadius: "5px",
    background: "var(--mainTheme250)",
    color: "#514E4E",
    display: "flex",
    alignItems: "center",
  };

  const micButtonStyle = {
    margin: "8px",
    padding: "5px",
    borderRadius: "5px !important",
    background: "var(--mainTheme250)",
    color: "#514E4E",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  };

  const divStyle =
    apiData && apiData.length < 1
      ? {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "80vh",
        }
      : {};

  const handleToggleListening = () => {
    if (isListening) {
      SpeechRecognition.stopListening();
      // setIsListening(false);
      resetTranscript();
    } else {
      SpeechRecognition.startListening({ continuous: true });
      // setIsListening(true);
    }
    setIsListening(!isListening); //new
  };
  const handleTogglePlayer = () => {
    setIsPlayerVisible(!isPlayerVisible); // Toggle the player visibility
  };

  return (
    <div style={{ marginTop: "-4%" }}>
      <div>
        {transcript && (
          <div
            style={{
              position: "absolute",
              // top: "-10%",
              right: "35%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              color: "white",
              padding: "5px 10px",
              borderRadius: "5px",
              width: "22%",
            }}
          >
            {transcript}
          </div>
        )}
        {apiData?.length > 0 && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginRight: "100px",
              bottom: "78px",
              top: "17%",

              //  position:"relative"
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                bottom: "56px",
                top: "17%",
                // borderRadius: "52px",
                width: "16%",
                // padding: "0px 20px",
                // border: "1px solid transparent",
                // backgroundColor: "deeff1",
                // boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
              }}
            >
              <div style={micButtonStyle}>
                <IconButton
                  onClick={handleToggleListening}
                  sx={{
                    borderRadius: "0px",
                    fontSize: "28px",
                  }}
                  aria-label={
                    isListening ? "stop listening" : "start listening"
                  }
                >
                  {isListening ? (
                    <StopIcon style={{ fontSize: "inherit" }} />
                  ) : (
                    <MicNoneIcon style={{ fontSize: "inherit" }} />
                  )}
                </IconButton>
              </div>
              <Box margin={1}>
                <Button
                  sx={{
                    backgroundColor: "var(--mainTheme900)",
                    color: "white",
                    width: "100px",
                    padding: "19px 8px !important",
                    "&:hover": {
                      backgroundColor: "var(--mainTheme500)",
                    },
                  }}
                  onClick={() => {
                    resetTranscript();
                  }}
                >
                  Reset
                </Button>
              </Box>
            </div>
          </div>
        )}
      </div>

      {loading ? (
        <div>
          <Loader />
        </div>
      ) : (
        <div
          style={{
            position: "relative",
            padding: "20px 10px",
            border: "1px solid transparent",
            backgroundColor: apiData?.length > 0 ? "#cae6f9" : "transparent",
            marginTop: "14px",
            height: "495px",
            overflowY: "scroll",
            marginRight: "10px",
          }}
        >
          {apiData.length > 0 ? (
            apiData.map((section, index) => (
              <Accordion
                key={index}
                expanded={expandedSection === section.category}
                onChange={() => {
                  const newExpandedSection =
                    expandedSection === section.category
                      ? false
                      : section.category;
                  setExpandedSection(newExpandedSection);
                  if (newExpandedSection) {
                    setSelectedSection(newExpandedSection);
                  }
                }}
                style={{
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 0px 10px",
                  borderRadius: "20px",
                  marginBottom: "10px",
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${index}a-content`}
                  id={`panel${index}a-header`}
                  style={{
                    margin: "0px",
                    minHeight: "50px",
                    "&.MuiAccordionSummary-content.Mui-expanded": {
                      margin: "5px 0px 10px 0",
                    },
                  }}
                >
                  <Typography variant="h6">{section.category}</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ padding: "0px 14px 3px" }}>
                  <TextField
                    name={section.category}
                    onChange={(event) => handleTextChange(event)}
                    fullWidth
                    multiline
                    value={section.content || ""}
                    variant="outlined"
                    style={{
                      display: "flex",
                      width: "100%",
                      height: "100px",
                      overflowY: "scroll",
                    }}
                    sx={{
                      "& .MuiFormLabel-root": {
                        color: "var(--mainTheme)",
                      },
                      border: "1.5px solid var(--mainTheme)",
                      borderRadius: "5px",
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "var(--mainTheme)",
                      },
                      "&:focus-visible": {
                        outline: "none",
                        borderColor: "var(--mainTheme)",
                      },
                    }}
                  />
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <div
                      style={miniButtonStyle}
                      onClick={() => handleSectionReset(section.category)}
                    >
                      <CachedIcon />
                    </div>
                    <div
                      style={miniButtonStyle}
                      onClick={
                        isRecognise
                          ? () => {
                              stopRecogniser();
                              setRecognisedText(setRecText);
                              handleSectionTranscribe(section.category);
                            }
                          : () => {
                              startRecogniser();
                            }
                      }
                    >
                      {isRecognise ? <StopIcon /> : <MicNoneIcon />}
                    </div>
                    <div
                      style={miniButtonStyle}
                      onClick={() => handleSectionSave(section.category)}
                    >
                      <SaveOutlined />
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            ))
          ) : (
            <motion.div
              animate={{ y: 0, opacity: 1 }}
              initial={{ y: 200, opacity: 0 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.8 }}
            >
              <Typography style={{ margin: "100px" }} variant="h5">
                There is no summary yet.
              </Typography>
            </motion.div>
          )}
        </div>
      )}

      <div
      // style={{display: "inline-block"}}
      >
        {/* Icon to toggle the audio player */}
        {/* <div
          style={{
            border: "1px solid transparent",
            borderRadius: "42px",
            width: "100px",
            backgroundColor: "#aad8dd",
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
            marginLeft: "90px",
          }}
        >
          <PlayCircleFilledIcon
            onClick={handleTogglePlayer}
            style={{ fontSize: "40px", cursor: "pointer" }}
          />
          <AudioFileIcon
            onClick={handleTogglePlayer}
            style={{ fontSize: "40px", cursor: "pointer" }}
          />
        </div> */}
        {/* Conditionally render the audio player based on isPlayerVisible state */}
        {/* {isPlayerVisible && uploadedAudioUrl && <CustomAudioPlayer audioUrl={uploadedAudioUrl}  />} */}
        {/* <Audio /> */}
      </div>
    </div>
  );
};

export default FillHRASummary;
