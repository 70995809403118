import {
  Button,
  Card,
  CardHeader,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Box,
  Typography,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  Tooltip,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardControlKeyIcon from "@mui/icons-material/KeyboardControlKey";
import { FormControl, InputLabel, Stack } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CloseIcon from "@mui/icons-material/Close";
import React, {
  Suspense,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";

import {
  CardSections,
  HeaderCard,
} from "../../../../components/Dashboard/DynamicFormMUI/customFields";

import "../../../../components/Dashboard/DynamicFormMUI/Tables.css";

import { putApi } from "../../../../utils/axiosApiWrapper";
import Toaster from "../../../toaster/toaster";
import toast from "react-hot-toast";
import QueryForm from "./QueryForm";
import Alert from "../../../Alert/Alert";
// import PatientSummary from "./Sections/PatientSummary/PatientSummary";
import usePedsForm from "./peds-form-hooks";

import PedsAssessmentInfoComp from "./PedsFormSection/Peds.AssessmentInfo";
import PedsDemographicsComp from "./PedsFormSection/Peds.demographic";
import PedsPatientSummaryComp from "./PedsFormSection/Peds.Patientsummary";
import Labels from "../../../../resource_files/Labels.resx";
import { SaveOutlined } from "@mui/icons-material";
import { QueryFormIcon } from "../../../../assets/Icons/FillHra/QueryFormIcon";
import { styled } from "@mui/styles";
import { useUserContext } from "../../../../contexts/UserContext";
import TableComponentReferral from "../ReferralFillHra";
const PedsFormHome = (assessmentId) => {
  const {
    sections,
    handleCardClick,
    sectionRefs,
    demographicsJson,
    demographicsFormState,
    setDemographicsFormState,
    demographicsJsonObj,
    currSection,
    setCurrSection,
    currentSectionId,
    setCurrentSectionId,
    storedYear,
    prevSelectedSection,
    setPrevSelectedSection,
    onSaveAssesmentInfoData,
    onSavepedsDemographicsData,
    onSavePatientSummaryData,
  } = usePedsForm(assessmentId);
  const {
    getAssessmentData,
    _memberIdForQueryForm,
    getAssessment,
    autoSavePedsDemographicsData,
    setAutoSavePedsDemographicsData,
    autoSavePedsAssessInfo,
    setAutoSavePedsAssessInfo,
    autoSavePedsPatientSumm,
    setAutoSavePedsPatientSumm,
    autoSavepedsallergiesRowData,
    setAutoSavePedsAllergiesRowData,
    autoSavefirstLevelCommentObj,
    setAutoSavePedsFirstLevelCommentObj,
    autoSavecounterMedicationsRowData,
    setAutoSavePedsCounterMedicationsRowData,
    autoSavesecondLevelTextFieldAnswer,
    setAutoSavePedsSecondLevelTextFieldAnswer,
    autoSavecounterMedicationsRowDataQues15,
    setAutoSavePedsCounterMedicationsRowDataQues15,
    autoSavesecondLevelCommentObj,
    setAutoSavePedsSecondLevelCommentObj,
  } = useUserContext();
  const [toasterMessage, setToasterMessage] = useState("");
  const [toasterSeverity, setToasterSeverity] = useState(Labels.SUCCESS);
  const [toasterOpen, setToasterOpen] = useState(false);

  const onCloseToaster = () => {
    setToasterOpen(false);
    setToasterSeverity(Labels.SUCCESS);
    setToasterMessage("");
    setToasterMessage("");
  };

  const assessmentInfoRef = useRef();
  const demographicsRef = useRef();
  const patientSummaryRef = useRef();

  const data = [
    { year: "2024" },
    // ,
    //  document: "https://example.com/document1.pdf" },
  ];
  useEffect(() => {
    if ((userRoleId == 5 && tabId != 3) || userRoleId == 1 || userRoleId == 2) {
      if (prevSelectedSection == 0) {
        onSaveSection(0);
      }
      if (prevSelectedSection == 1) {
        onSaveSection(1);
        localStorage.removeItem("allergiesRowData");
        localStorage.removeItem("answeredJson");
        localStorage.removeItem("counterMedicationRowData");
        localStorage.removeItem("counterMedicationsRowDataQues15");
        localStorage.removeItem("secondLevelComment");
        localStorage.removeItem("secondLevelCommentObj"); //secondLevelTextFieldAnswer,selected2ndLevelSubQuestion
        localStorage.removeItem("secondLevelTextFieldAnswer");
        localStorage.removeItem("selected2ndLevelSubQuestion");
        localStorage.removeItem("firstLevelCommentObj");
        localStorage.removeItem("selectedDescribeFirstLevel");
        localStorage.removeItem("selectedIdx");
        localStorage.removeItem("selectedIdxDescribeFirstLevel");
        localStorage.removeItem("selectedSubQuestion");
      }
      if (prevSelectedSection == 2) {
        //patientSummaryRef?.current?.onSaveData();
        onSaveSection(2);
      }
    }
  }, [currSection, prevSelectedSection]);
  useEffect(() => {
    return () => {
      setPrevSelectedSection(-1);
      setCurrSection(0);
      localStorage.removeItem("allergiesRowData");
      localStorage.removeItem("answeredJson");
      localStorage.removeItem("counterMedicationRowData");
      localStorage.removeItem("counterMedicationsRowDataQues15");
      localStorage.removeItem("secondLevelComment");
      localStorage.removeItem("secondLevelCommentObj"); //secondLevelTextFieldAnswer,selected2ndLevelSubQuestion
      localStorage.removeItem("secondLevelTextFieldAnswer");
      localStorage.removeItem("selected2ndLevelSubQuestion");
    };
  }, []);
  const onSaveData = async () => {
    console.log("demographicsRef?.current", demographicsRef?.current);
    assessmentInfoRef?.current?.onSaveData();
    demographicsRef?.current?.onSaveData();
    patientSummaryRef?.current?.onSaveData();
  };
  const onSaveSection = async (index) => {
    if (index == 0) {
      onSavepedsDemographicsData(autoSavePedsDemographicsData);
    }
    if (index == 1) {
      onSaveAssesmentInfoData(autoSavePedsAssessInfo);
    }
    if (index == 2) {
      onSavePatientSummaryData(autoSavePedsPatientSumm);
    }
  };
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({});

  const location = useLocation();

  const tabId = location?.state?.tabId
    ? location?.state?.tabId
    : sessionStorage.getItem("tabId");
  const patientName = location?.state?.patientName
    ? location?.state?.patientName
    : sessionStorage.getItem("patientName");
  const hraStatus = location?.state?.hraStatus
    ? location?.state?.hraStatus
    : sessionStorage.getItem("hraStatus");

  const refStatusForFillHra = location?.state?.refStatusForFillHra;
  const referralDateSentQaDate = location?.state?.referralDateSentQaDate;

  const showQueryFormBtn = sessionStorage.getItem("showQueryFormBtn");
  const userRoleId = sessionStorage.getItem("roleId");
  const isAdm = userRoleId === "1" || userRoleId === "2";
  const isClinician = userRoleId === "5";
  const isSaveBtn =
    hraStatus === "In Progress" ||
    hraStatus === "Scheduled" ||
    hraStatus === "Unscheduled";
  const isQfBtn =
    hraStatus === "Clinician Review - QA" ||
    hraStatus === "Clinician Review - Coding" ||
    hraStatus === "Submitted - Ready for Coding" ||
    hraStatus === "Submitted - Ready for QA";

  //Handle Open Query Form
  const handleOpenQueryForm = () => setOpen(true);

  //Managing State for open & close speed dial
  const [openSpeedDial, setOpenSpeedDial] = React.useState(false);
  const handleOpenSpeedDial = () => setOpenSpeedDial(true);
  const handleCloseSpeedDial = () => setOpenSpeedDial(false);
  const roleId = sessionStorage.getItem("roleId");
  //Speed dial Options
  const actions = [
    {
      icon: <SaveOutlined />,
      name: "Save Form Data",
      handler: onSaveData,
      visibility:
        roleId == 1 || roleId == 2 || (roleId == 5 && tabId != 3)
          ? true
          : false,
      // (isAdm && isSaveBtn) ||
      // (isClinician && tabId !== 3) ||
      // (roleId == 5 && tabId != 3),
    },
    {
      icon: <QueryFormIcon />,
      name: "Open Query Form",
      handler: handleOpenQueryForm,
      visibility: showQueryFormBtn === "true" || (isAdm && isQfBtn),
    },
  ];

  //Custom style for Speed Dial
  const StyledSpeedDial = styled(SpeedDial)({
    position: "fixed",
    bottom: 16,
    right: 16,
    "& .MuiSpeedDial-fab": {
      backgroundColor: `var(--mainTheme900)`,
      "&:hover": {
        backgroundColor: `var(--mainTheme900)`,
      },
    },
  });

  //Filter Visible action alone
  const visibleActions = actions.filter((action) => action.visibility);

  const actionLength = visibleActions.length ? visibleActions.length : 0;

  return (
    <>
      {actionLength === 1 && (
        <Tooltip title={visibleActions[0].name} arrow>
          <StyledSpeedDial
            ariaLabel={visibleActions[0].name}
            icon={visibleActions[0].icon}
            onClick={visibleActions[0].handler}
            transitionDuration={0}
          />
        </Tooltip>
      )}
      {actionLength === 2 && (
        <StyledSpeedDial
          ariaLabel="Save and Open Query Form"
          icon={<SpeedDialIcon />}
          onClose={handleCloseSpeedDial}
          onOpen={handleOpenSpeedDial}
          open={openSpeedDial}
          transitionDuration={0}
        >
          {visibleActions.map((action) =>
            action.visibility ? (
              <SpeedDialAction
                sx={{
                  bgcolor: `var(--mainTheme900)`,
                  color: "white",
                  "&:hover": {
                    bgcolor: `var(--mainTheme900)`,
                  },
                }}
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.name}
                onClick={action.handler}
              />
            ) : (
              <></>
            ),
          )}
        </StyledSpeedDial>
      )}

      <QueryForm
        open={open}
        setOpen={setOpen}
        memberId={_memberIdForQueryForm}
        formData={formData}
        _patientName={patientName}
      />

      <Grid item xs={10} marginTop="80px" marginBottom={1}>
        <HeaderCard fromPedsForm={true} />
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        alignItems="flex-start"
        spacing={2}
        style={{ fontFamily: "Inter !important" }}
        className={"fillHraForm"}
      >
        <Grid item xs={2} style={{ paddingY: "3px", paddingLeft: "30px" }}>
          <CardSections
            routedFrom="PedsForm"
            handleMiniSave={() => {}}
            handleScreeningsSave={() => {}}
            handlePatientSave={() => {}}
            sections={sections}
            currSection={currSection}
            handleCardClick={handleCardClick}
            handleEditForm={() => {}}
            handleFieldConfigCheck={() => {}}
            saveSectionFormData={onSaveData}
          />
        </Grid>
        <Grid
          item
          xs={10}
          style={{
            paddingInline: "10px",
            paddingY: "25px",
            textAlign: "center !important",
          }}
        >
          {/* <Card
            style={{
              height: "auto",
              minHeight: "10vh",
              maxHeight: "450px",
              overflowY: "auto",
              marginLeft: "10px",
              border: "1px solid var(--mainTheme)",
              marginBottom: "20px",
            }}
          >
            <span
              style={{
                width: "100% !important",
                margin: "0 !important",
                padding: "0 !important",
              }}
            >
              <CardHeader
                title="Prior Assessment"
                sx={{
                  backgroundColor: `var(--mainTheme)`,
                  color: "white",
                  padding: "15px",
                  textAlign: "center !important",
                  textTransform: "uppercase",
                  fontWeight: 700,
                  height: "20px",
                }}
                titleTypographyProps={{ variant: "outline" }}
              />
            </span>
            <Box marginInline="10vw" marginBlock="20px">
              <TableContainer
                component={Paper}
                sx={{ border: "1px solid var(--mainTheme)" }}
              >
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          borderBottom: "1px solid var(--mainTheme) !important",
                          borderRight: "1px solid var(--mainTheme) !important",
                          width: "50%",
                        }}
                        align="center"
                      >
                        <Typography fontWeight="bolder">Date/Time</Typography>
                      </TableCell>

                      <TableCell
                        sx={{
                          borderBottom: "1px solid var(--mainTheme) !important",
                          width: "50%",
                        }}
                        align="center"
                      >
                        <Typography fontWeight="bolder">Document</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow
                      key="prior-assessment"
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        sx={{
                          borderRight: "1px solid var(--mainTheme) !important",
                        }}
                        align="center"
                      >
                        06/21/2024
                      </TableCell>
                      <TableCell align="center">
                        <a href="#">Prior Assessment 2023</a>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Card> */}

          <Card
            style={{
              height: "auto",
              minHeight: "76vh",
              maxHeight: "450px",
              overflowY: "auto",
              marginLeft: "10px",
            }}
          >
            <span
              style={{
                width: "100% !important",
                margin: "0 !important",
                padding: "0 !important",
              }}
            >
              <TableComponentReferral
                data={data}
                refStatusForFillHra={refStatusForFillHra}
                referralDateSentQaDate={referralDateSentQaDate}
              />

              <CardHeader
                title="Prior Assessment"
                sx={{
                  backgroundColor: `var(--mainTheme)`,
                  color: "white",
                  padding: "15px",
                  textAlign: "center !important",
                  textTransform: "uppercase",
                  fontWeight: 700,
                  height: "20px",
                }}
                titleTypographyProps={{ variant: "outline" }}
              />
            </span>
            <Box marginInline="10vw" marginBlock="20px">
              <TableContainer
                component={Paper}
                sx={{ border: "1px solid var(--mainTheme)" }}
              >
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          borderBottom: "1px solid var(--mainTheme) !important",
                          borderRight: "1px solid var(--mainTheme) !important",
                          width: "50%",
                        }}
                        align="center"
                      >
                        <Typography fontWeight="bolder">Date/Time</Typography>
                      </TableCell>

                      <TableCell
                        sx={{
                          borderBottom: "1px solid var(--mainTheme) !important",
                          width: "50%",
                        }}
                        align="center"
                      >
                        <Typography fontWeight="bolder">Document</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow
                      key="prior-assessment"
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        sx={{
                          borderRight: "1px solid var(--mainTheme) !important",
                        }}
                        align="center"
                      >
                        06/21/2024
                      </TableCell>
                      <TableCell align="center">
                        <a href="#">Prior Assessment 2023</a>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            {sections?.map((section, idx) => (
              <div key={idx} id={section.sectionId}>
                <div
                  ref={sectionRefs[idx]}
                  className="section"
                  style={{ marginBottom: "20px" }}
                >
                  <span
                    style={{
                      width: "100% !important",
                      margin: "0 !important",
                      padding: "0 !important",
                    }}
                  >
                    <CardHeader
                      title={section.sectionName}
                      action={
                        <a
                          style={{
                            textDecoration: "none",

                            color: "rgba(0, 0, 0, 0.87)",
                          }}
                          sx={{
                            textDecoration: "none",
                            color: "var(--mainTheme)",
                          }}
                          href={`#${section.sectionId}`}
                        >
                          <div
                            style={{
                              width: "100% !important",
                              margin: "0 !important",
                              padding: "0 !important",
                            }}
                          >
                            <IconButton
                              style={{
                                float: "right",
                                color: "#FFFFFF",
                                paddingBottom: "20px",
                              }}
                              aria-label="Section minimise"
                              size="small"
                              onClick={() => {
                                if (section?.sectionId !== currentSectionId) {
                                  setCurrentSectionId(section?.sectionId);
                                  setCurrSection(section?.sectionId);
                                } else {
                                  // setCurrentSectionId(undefined);
                                  // setCurrSection(undefined);
                                  setCurrentSectionId(section?.sectionId + 1);
                                  setCurrSection(section?.sectionId + 1);
                                }
                              }}
                            >
                              {currentSectionId == section?.sectionId ? (
                                <KeyboardControlKeyIcon />
                              ) : (
                                <ExpandMoreIcon />
                              )}
                            </IconButton>
                          </div>
                        </a>
                      }
                      sx={{
                        backgroundColor: `var(--mainTheme)`,
                        color: "white",
                        padding: "15px",
                        textAlign: "center !important",
                        textTransform: "uppercase",
                        fontWeight: 700,
                        height: "20px",
                      }}
                      titleTypographyProps={{ variant: "outline" }}
                    />
                  </span>
                  {section.sectionId == 0 && currentSectionId == 0 ? (
                    <PedsDemographicsComp
                      assessmentId={assessmentId}
                      setAutoSavePedsDemographicsData={
                        setAutoSavePedsDemographicsData
                      }
                      ref={demographicsRef}
                    />
                  ) : section?.sectionId == 1 && currentSectionId == 1 ? (
                    <PedsAssessmentInfoComp
                      assessmentId={assessmentId}
                      setAutoSavePedsAssessInfo={setAutoSavePedsAssessInfo}
                      setAutoSavePedsFirstLevelCommentObj={
                        setAutoSavePedsFirstLevelCommentObj
                      }
                      setAutoSavePedsAllergiesRowData={
                        setAutoSavePedsAllergiesRowData
                      }
                      setAutoSavePedsCounterMedicationsRowData={
                        setAutoSavePedsCounterMedicationsRowData
                      }
                      setAutoSavePedsSecondLevelTextFieldAnswer={
                        setAutoSavePedsSecondLevelTextFieldAnswer
                      }
                      setAutoSavePedsCounterMedicationsRowDataQues15={
                        setAutoSavePedsCounterMedicationsRowDataQues15
                      }
                      setAutoSavePedsSecondLevelCommentObj={
                        setAutoSavePedsSecondLevelCommentObj
                      }
                      ref={assessmentInfoRef}
                    />
                  ) : section?.sectionId == 2 && currentSectionId == 2 ? (
                    <PedsPatientSummaryComp
                      assessmentId={assessmentId}
                      ref={patientSummaryRef}
                      setAutoSavePedsPatientSumm={setAutoSavePedsPatientSumm}
                      autoSavePedsPatientSumm={autoSavePedsPatientSumm}
                    />
                  ) : null}
                </div>
              </div>
            ))}
          </Card>
        </Grid>
      </Grid>
      <Toaster
        open={toasterOpen}
        onClose={onCloseToaster}
        severity={toasterSeverity}
        message={toasterMessage}
      />
    </>
  );
};

export default PedsFormHome;
