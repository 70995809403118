import MuiInput from "../../../muiinput/MuiInput";
import Button from "@mui/material/Button";
import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import "./AddClinician.css";
import { zcs } from "zcs";
import {
  languageOptions,
  stateNames,
} from "../../../Dashboard/ReactDND/userCategories";
import ProfilePhotoUpload from "../uploadProfilePic";
import moment from "moment";
import { Card, CardHeader, IconButton, Typography } from "@mui/material";
import Toaster from "../../../toaster/toaster";
import Labels from "../../../../resource_files/Labels.resx";
import ValidationMessage from "../../../../resource_files/ValidationMessage.resx";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardControlKeyIcon from "@mui/icons-material/KeyboardControlKey";
import { getApi, postApi } from "../../../../utils/axiosApiWrapper";
import { getStateName } from "../../../../utils/getUSStateName";
import { capitalize } from "lodash";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import Box from "@mui/material/Box";
import MonthlyAvailabilityForm from "../MonthlyAvail.form";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Paper,
  Link,
} from "@mui/material";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Fade,
} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
const ZCS = zcs();

const initialClinicianData = {
  userName: "",
  password: "",
  firstName: "",
  lastName: "",
  speciality: "",
  dateOfBirth: "",
  gender: "",
  address: "",
  city: "",
  state: [],
  zipCode: "",
  county: "",
  clientName: "",
  phoneNumber: "",
  homeNumber: "",
  email: "",
  npi: "",
  licenseNumber: "",
  validStates: [],
  licenseExpiryDate: "",
  pedApproved: "",
  status: 1,
  evaluation: null,
  evaluationReason: null,
  emerContactName: "",
  emerContactAddress: "",
  emerContactPhone: "",
  emerContactRelation: "",
  timeZone: [],
  notes: "",
  zipCodeExclusion: "",
  payRates: [],
  // lastUpdated: "",
  monthlyAvailability: [],
  maxMiles: "",
  planProgramId: "1_1",
  languages: [],
  licenseType: [],
  clientsSupported: [],
  prefContactMethod: [],
};

export default function AddClinician({ onClose, fetchClinicianData, index }) {
  const [isShowDemographic, setIsShowDemographic] = useState(false);
  const [timeZoneOptions, setTimeZoneOptions] = useState([]);
  const [isShowLicense, setIsShowLicense] = useState(false);
  const [isShowAvailability, setIsShowAvailability] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [nameError, setNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [countyError, setCountyError] = useState("");
  const [cityError, setCityError] = useState("");
  const [stateError, setStateError] = useState("");
  const [emerContactNameError, setEmerContactNameError] = useState("");
  const [emerContactRelationError, setEmerContactRelationError] = useState("");
  const [zipCodeError, setZipCodeError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [homeNumberError, setHomeNumberError] = useState("");
  const [npiError, setNpiError] = useState("");
  const [zipCodeExclusionError, setZipCodeExclusionError] = useState("");
  const [clinicianData, setClinicianData] = useState(initialClinicianData);
  const [toasterMessage, setToasterMessage] = useState("");
  const [toasterSeverity, setToasterSeverity] = useState(Labels.SUCCESS);
  const [toasterOpen, setToasterOpen] = useState(false);
  const [, setApiData] = useState([]);
  const [userClients, setUserClients] = useState([]);
  const [planData, setPlanData] = useState([]);
  const [selectedPlans, setSelectedPlans] = useState([]);
  const [selectedClientId] = useState([]);
  const [selectedProgramData] = useState(null);
  const [selectedPrograms, setSelectedPrograms] = useState([]);
  const [, setSelectedPlanProgramsFlat] = useState([]);
  const [selectedClientPrograms, setSelectedClientPrograms] = useState([]);
  const [, setErrors] = useState({});
  const [emerContactPhoneError, setemerContactPhoneError] = useState("");
  const [dateOfBirthError, setDateOfBirthError] = useState("");
  const [expiryDateError, setExpiryDateError] = useState("");
  const [rateList, setRateList] = useState([]);
  const [rateListObj, setRateListObj] = useState({});
  const [monthlyAvailability, setMonthlyAvailability] = useState([]);
  const [profilePic, setProfilePic] = useState("");
  const handleRateChange = (value, field) => {
    const rateObj = {
      rate: value,
      visitType: field,
    };
    // setRateList([...rateList, rateObj]);
    setRateListObj({ ...rateListObj, [field]: value });
  };
  useEffect(() => {
    const _rateList = Object.keys(rateListObj).map((itm) => {
      const obj = {
        rate: rateListObj[itm],
        visitType: itm,
      };
      return obj;
    });
    setRateList(_rateList);
    setClinicianData((prevClinicianData) => ({
      ...prevClinicianData,
      payRates: _rateList,
    }));
  }, [rateListObj]);

  useEffect(() => {
    console.log("monthlyAvailability", monthlyAvailability);
    setClinicianData((prevClinicianData) => ({
      ...prevClinicianData,
      monthlyAvailability: monthlyAvailability,
    }));
  }, [monthlyAvailability]);

  useEffect(() => {
    // Fetch time zone options using Axios
    const timezoneHandler = async () => {
      try {
        await getApi(`/settings/getTimezones`, {}).then((response) => {
          setTimeZoneOptions(response?.data?.data);
        });
      } catch (error) {}
    };

    timezoneHandler();
  }, []);

  const abbrOptions = timeZoneOptions.map((option) => option.abbr);

  const timeZones = {
    options: abbrOptions,
  };

  const toggleDemographicSection = () => {
    setIsShowDemographic(!isShowDemographic);
  };
  const ValidationError = ({ error }) => {
    return error ? (
      <h3
        className="password"
        style={{
          color: "#d32f2f",
          lineHeight: "14px",
          width: "400px",
          marginTop: "-21px",
        }}
      >
        {error}
      </h3>
    ) : null;
  };

  const toggleLicenseSection = () => {
    setIsShowLicense(!isShowLicense);
  };

  const toggleAvailabilitySection = () => {
    setIsShowAvailability(!isShowAvailability);
  };
  useEffect(() => {
    const selectedPlanProgramsFlat = selectedPlans.flatMap((plan) => {
      const clientProgramIds = planData
        .filter((item) => item.plan === plan)
        .map(
          (programItem) => `${programItem.clientId}_${programItem.programId}`,
        );

      return clientProgramIds;
    });

    const flatString = selectedPlanProgramsFlat.join(",");

    setSelectedPlanProgramsFlat(flatString);
  }, [selectedPlans, planData]);

  let prevSelectedPrograms = [];

  const handleProgramClick = (clientId, programId) => {
    const clientProgramId = `${clientId}_${programId}`;

    setSelectedPrograms((prevSelectedPrograms) => {
      if (!Array.isArray(prevSelectedPrograms)) {
        prevSelectedPrograms = [];
      }

      const programIndex = prevSelectedPrograms.findIndex(
        (program) =>
          program.clientId === clientId && program.programId === programId,
      );

      if (programIndex !== -1) {
        const newSelectedPrograms = [...prevSelectedPrograms];
        newSelectedPrograms.splice(programIndex, 1);
        return newSelectedPrograms;
      } else {
        return [...prevSelectedPrograms, { clientId, programId }];
      }
    });

    setSelectedClientPrograms((prevSelectedClientPrograms) => {
      if (!Array.isArray(prevSelectedClientPrograms)) {
        prevSelectedClientPrograms = [];
      }

      if (
        prevSelectedPrograms.length > 0 &&
        prevSelectedPrograms.some(
          (program) =>
            program.clientId === clientId && program.programId === programId,
        )
      ) {
        return prevSelectedClientPrograms.filter(
          (cp) => cp !== clientProgramId,
        );
      } else {
        return [...prevSelectedClientPrograms, clientProgramId];
      }
    });
  };

  const handlePlanClick = (plan) => {
    const programsInSelectedPlan = planData
      .filter((item) => item.plan === plan)
      .map((programItem) => ({
        clientId: programItem.clientId,
        programId: programItem.programId,
      }));

    const isAllProgramsSelected = programsInSelectedPlan.every((program) =>
      selectedPrograms.some(
        (selectedProgram) =>
          selectedProgram.clientId === program.clientId &&
          selectedProgram.programId === program.programId,
      ),
    );

    setSelectedPrograms((prevSelectedPrograms) => {
      if (isAllProgramsSelected) {
        return prevSelectedPrograms.filter(
          (program) =>
            !programsInSelectedPlan.some(
              (programInPlan) =>
                programInPlan.clientId === program.clientId &&
                programInPlan.programId === program.programId,
            ),
        );
      } else {
        return [
          ...prevSelectedPrograms,
          ...programsInSelectedPlan.filter(
            (programInPlan) =>
              !prevSelectedPrograms.some(
                (program) =>
                  program.clientId === programInPlan.clientId &&
                  program.programId === programInPlan.programId,
              ),
          ),
        ];
      }
    });

    setSelectedPlans((prevSelectedPlans) => {
      if (prevSelectedPlans.includes(plan)) {
        return prevSelectedPlans.filter((p) => p !== plan);
      } else {
        return [...prevSelectedPlans, plan];
      }
    });

    setSelectedClientPrograms((prevSelectedClientPrograms) => {
      if (isAllProgramsSelected) {
        return prevSelectedClientPrograms.filter(
          (cp) =>
            !programsInSelectedPlan.some(
              (program) => cp === `${program.clientId}_${program.programId}`,
            ),
        );
      } else {
        return [
          ...prevSelectedClientPrograms,
          ...programsInSelectedPlan.map(
            (program) => `${program.clientId}_${program.programId}`,
          ),
        ];
      }
    });
  };

  const fetchClientData = async () => {
    try {
      const response = await getApi("/clients/list");
      const apiData = response.data.data;
      setApiData(apiData);

      if (apiData && apiData.length > 0) {
        const clientProgramData = {};

        apiData.forEach((client) => {
          client.program.forEach((program) => {
            const clientProgramKey = `${client._id}_${program.id}`;
            if (!clientProgramData[clientProgramKey]) {
              clientProgramData[clientProgramKey] = {
                programId: program.id.toString(),
                program: program.name,
                clientId: client._id,
                plan: client.plan,
              };
            }
          });
        });

        setPlanData(Object.values(clientProgramData));

        const programArray = Object.values(clientProgramData);
        programArray.forEach((programs) => {
          const { clientId, programId, program, plan } = programs;
        });
      }
    } catch (error) {
      return;
    }
  };

  const uniquePlans = [...new Set(planData.map((item) => item.plan))];

  const headingStyle = {
    padding: "10px",
    backgroundColor: "var(--mainTheme)",
    textAlign: "center",
    color: "white",
    marginBottom: "auto",
  };

  useEffect(() => {
    fetchClientData();
  }, []);

  const additionalConditions = [
    !nameError,
    !passwordError,
    !zipCodeExclusionError,
    !emailError,
    !nameError,
    !lastNameError,
    !countyError,
    !cityError,
    !stateError,
    !emerContactNameError,
    !emerContactRelationError,
    !zipCodeError,
    !phoneNumberError,
    !homeNumberError,
    !npiError,
  ];

  const excludedKeys = [
    "planProgramId",
    "status",
    "evaluation",
    "evaluationReason",
  ];

  const isFormValid = Object.keys(clinicianData)
    .filter((key) => !excludedKeys.includes(key))
    .every((key) => clinicianData[key] !== "");

  const handleAdd = async () => {
    if (clinicianData.email !== undefined) {
      const uniquePlans = new Set();
      const userClientsData = [];

      selectedPrograms.forEach((selectedProgram) => {
        const plan = selectedProgram.clientId;
        const programId = selectedProgram.programId;

        if (!uniquePlans.has(plan)) {
          uniquePlans.add(plan);
          const userClient = {
            plan,
            programs: [programId.toString()],
          };
          userClientsData.push(userClient);
        } else {
          const existingUserClient = userClientsData.find(
            (client) => client.plan === plan,
          );
          if (existingUserClient) {
            existingUserClient.programs.push(programId.toString());
          }
        }
      });

      setUserClients(userClientsData);

      const updatedClinicianData = {
        ...clinicianData,
        // planProgramId: plansProgramIds.toString(),/users/register
        userClients: userClientsData,
        payRates: rateList,
        status: clinicianData?.status + "",
        monthlyAvailability:
          clinicianData?.monthlyAvailability || monthlyAvailability,
        evaluation: "Re Hire",
        profilePicture: profilePic,
      };

      console.log("updatedClinicianData", updatedClinicianData);
      console.log("rateList", rateList);

      const response = await postApi("/clinicians/add", updatedClinicianData);
      // const response = await postApi("/users/register", updatedClinicianData);

      if (response.status === 201) {
        setToasterOpen(true);
        setToasterSeverity(Labels.SUCCESS);
        setToasterMessage(response.data.message);
        setClinicianData(initialClinicianData);

        setTimeout(() => {
          onClose();
        }, 2000);
      } else {
        setToasterSeverity(Labels.ERROR);
        //setToasterMessage(response.response.data.message);
        setToasterMessage(
          `Please Enter All Required Field ${response.response.data.message}`,
        );
        setToasterOpen(true);
      }
    }
  };

  const dialogBtn = {
    width: "131px",
    height: "32px",
    flexShrink: "0",
    borderRadius: "20px",
    background: "var(--mainTheme)",
    marginLeft: "10px",
    color: "white",
    "&:hover": {
      boxShadow: 8,
      color: "var(--mainTheme)",
    },
  };
  const getLocationInfoByZip = (zipCode) => {
    const parsedZipCode = parseInt(zipCode, 10);
    const locationInfo = ZCS.getByZip(parsedZipCode);
    return locationInfo;
  };

  useEffect(() => {
    if (clinicianData.zipCode) {
      try {
        if (
          !/^(0|\d+)$/.test(clinicianData.zipCode) &&
          !/^\d{5}$/.test(clinicianData.zipCode)
        ) {
          setZipCodeError(ValidationMessage.Zipcode_Format);

          return;
        }
        const locationInfo = getLocationInfoByZip(clinicianData.zipCode);
        if (locationInfo?.city && locationInfo?.state) {
          const stateFullName = getStateName(locationInfo?.state);
          setClinicianData((prevClinicianData) => ({
            ...prevClinicianData,
            city: locationInfo.city,
            state: stateFullName.toUpperCase(),
          }));
          setZipCodeError("");
        } else {
          setZipCodeError("Invalid zip code");
        }
      } catch (error) {
        if (
          !/^(0|[1-9][0-9]*)$/.test(clinicianData.zipCode) &&
          !/^\d{5}$/.test(clinicianData.zipCode)
        ) {
          setZipCodeError(ValidationMessage.Zipcode_Format);
        } else {
          setZipCodeError("Error fetching location information");
        }
      }
    }
  }, [clinicianData.zipCode]);

  useEffect(() => {
    const isFNPSelected = clinicianData.licenseType.includes("FNP");

    setClinicianData((prevClinicianData) => ({
      ...prevClinicianData,
      pedApproved: isFNPSelected ? "Yes" : "No",
    }));
  }, [clinicianData.licenseType]);

  useEffect(() => {
    if (clinicianData.firstName && clinicianData.lastName) {
      const userName = `${clinicianData.firstName}${capitalize(
        clinicianData.lastName,
      )}`;

      setClinicianData((prevData) => ({
        ...prevData,
        userName: userName,
      }));
    }
  }, [clinicianData.firstName, clinicianData.lastName]);

  useEffect(() => {
    if (selectedClientPrograms && planData) {
      const result = selectedClientPrograms.map((selected) => {
        const [clientId, programId] = selected.split("_");
        const matchedPlan = planData.find(
          (item) =>
            item.clientId === clientId &&
            Number(item.programId) === Number(programId),
        );
        return matchedPlan ? matchedPlan.plan : null;
      });

      const unique = [...new Set(result)];

      unique.sort();

      setClinicianData((prevData) => ({
        ...prevData,
        clientName: unique.join(",").toString(),
      }));
    }
  }, [selectedClientPrograms, planData, clinicianData.clientName]);

  const handleInputChange = (fieldName, inputValue) => {
    if (fieldName === "zipCodeExclusion") {
      if (!inputValue || /^\s*$/.test(inputValue)) {
        setZipCodeExclusionError(ValidationMessage.ZipCodeExclusion_Required);
      } else if (!/^(\d{5}(,\d{5})*)$/.test(inputValue)) {
        setZipCodeExclusionError(ValidationMessage.ZipCodeExclusion_Format);
      } else {
        setZipCodeExclusionError("");

        setClinicianData({
          ...clinicianData,
          [fieldName]: inputValue,
        });
      }
    } else if (fieldName === "zipCode") {
      if (!inputValue) {
        setZipCodeError(ValidationMessage.Zipcode_Required);
      } else {
        setZipCodeError("");

        setClinicianData((prevClinicianData) => ({
          ...prevClinicianData,
          zipCode: inputValue,
        }));
      }
    } else if (fieldName === "phoneNumber") {
      if (!inputValue) {
        setPhoneNumberError(ValidationMessage.PhoneNumber_Required);
      } else if (!/^(\d{3}-\d{3}-\d{4}|\d{10})$/.test(inputValue)) {
        setPhoneNumberError(ValidationMessage.PhoneNumber_Format);
      } else if (inputValue.replace(/[^\d]/g, "").length > 13) {
        setPhoneNumberError(ValidationMessage.PhoneNumber_Format);
      } else {
        setPhoneNumberError("");
      }
    } else if (fieldName === "homeNumber") {
      if (!inputValue) {
        setHomeNumberError(ValidationMessage.HomeNumber_Required);
      } else if (!/^(\d{3}-\d{3}-\d{4}|\d{10})$/.test(inputValue)) {
        setHomeNumberError(ValidationMessage.HomeNumber_Format);
      } else if (inputValue.replace(/[^\d]/g, "").length > 13) {
        setHomeNumberError(ValidationMessage.HomeNumber_Format);
      } else {
        setHomeNumberError("");
      }
    } else if (fieldName === "emerContactPhone") {
      if (!inputValue || /^\s*$/.test(inputValue)) {
        setemerContactPhoneError(ValidationMessage.EmerContactNumber_Required);
      } else {
        const inputValueString = String(inputValue);

        if (!/^(\d{3}-\d{3}-\d{4}|\d{10})$/.test(inputValueString)) {
          setemerContactPhoneError(ValidationMessage.EmerContactNumber_Format);
        } else if (inputValueString.replace(/[^\d]/g, "").length > 13) {
          setemerContactPhoneError(ValidationMessage.EmerContactNumber_Format);
        } else {
          setemerContactPhoneError("");
        }
      }
    } else if (fieldName === "email") {
      if (!inputValue) {
        setEmailError(ValidationMessage.Email_Required);
      } else if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(inputValue)) {
        setEmailError(ValidationMessage.Email_Format_Invalid);
      } else {
        setEmailError("");
      }
    } else if (fieldName === "password") {
      // Password validation logic
      if (!inputValue) {
        setPasswordError(ValidationMessage.Password_Required);
      } else if (inputValue.length < 8) {
        setPasswordError(ValidationMessage.Password_Length);
      } else if (
        !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_\-+=~])[A-Za-z\d!@#$%^&*()_\-+=~]{8,}$/.test(
          inputValue,
        )
      ) {
        setPasswordError(ValidationMessage.Password_Format);
      } else {
        setPasswordError("");
      }
    } else if (
      fieldName === "firstName" ||
      fieldName === "lastName" ||
      fieldName === "county" ||
      fieldName === "state" ||
      fieldName === "city" ||
      fieldName === "emerContactName" ||
      fieldName === "emerContactRelation"
    ) {
      if (fieldName === "firstName") {
        if (!inputValue) {
          setNameError(ValidationMessage.Firstname_Required);
        } else if (
          !/^[a-zA-Z]+(?:[.'-][a-zA-Z]+)*$|^(Jr\.|Sr\.)$/.test(inputValue)
        ) {
          setNameError("Invalid First Name");
        } else {
          setNameError("");
        }
      } else if (fieldName === "lastName") {
        if (!inputValue) {
          setLastNameError(ValidationMessage.Lastname_Required);
        } else if (
          !/^[a-zA-Z]+(?:[.'-][a-zA-Z]+)*$|^(Jr\.|Sr\.)$/.test(inputValue)
        ) {
          setLastNameError("Invalid Last Name");
        } else {
          setLastNameError("");
        }
      } else if (fieldName === "county") {
        if (!inputValue) {
          setCountyError(ValidationMessage.County_Required);
        } else if (!/^[a-zA-Z ]+$/.test(inputValue)) {
          setCountyError(ValidationMessage.County_Format);
        } else {
          setCountyError("");
        }
      } else if (fieldName === "state") {
        if (!inputValue) {
          setStateError(ValidationMessage.State_Required);
        } else if (!/^[a-zA-Z ]+$/.test(inputValue)) {
          setStateError(ValidationMessage.State_Format);
        } else {
          setStateError("");
        }
      } else if (fieldName === "city") {
        if (!inputValue) {
          setCityError(ValidationMessage.City_Required);
        } else if (!/^[a-zA-Z ]+$/.test(inputValue)) {
          setCityError(ValidationMessage.City_Format);
        } else {
          setCityError("");
        }
      } else if (fieldName === "emerContactName") {
        if (!inputValue) {
          setEmerContactNameError(ValidationMessage.EmerContactName_Required);
        } else if (!/^[a-zA-Z ]+$/.test(inputValue)) {
          setEmerContactNameError(ValidationMessage.EmerContactName_Format);
        } else {
          setEmerContactNameError("");
        }
      } else if (fieldName === "emerContactRelation") {
        if (!inputValue) {
          setEmerContactRelationError(
            ValidationMessage.EmerContactRelation_Required,
          );
        } else if (!/^[a-zA-Z ]+$/.test(inputValue)) {
          setEmerContactRelationError(
            ValidationMessage.EmerContactRelation_Format,
          );
        } else {
          setEmerContactRelationError("");
        }
      }
    } else if (fieldName === "npi") {
      if (!inputValue || /^\s*$/.test(inputValue)) {
        setNpiError(ValidationMessage.Npi_Required);
      } else if (!/^\d{10}$/.test(inputValue)) {
        setNpiError(ValidationMessage.Npi_Length);
      }
      // else if (!/^1\d$/.test(inputValue)) {
      //   setNpiError(ValidationMessage.Npi_Format);
      // }
      else {
        setNpiError("");
      }
    }

    handleChange(fieldName, inputValue);
  };
  const [fillMonthlyAvailForm, setFillMonthlyAvailForm] = useState(false);
  const handleChange = (field, value) => {
    if (
      field === "languages" ||
      field === "licenseType" ||
      field === "clientsSupported" ||
      field === "prefContactMethod" ||
      field === "validStates" ||
      field === "timeZone" ||
      field === "state"
    ) {
      const sortedValues = Array.isArray(value) ? [...value].sort() : [value];
      setClinicianData({
        ...clinicianData,
        [field]: sortedValues,
      });
      if (field === "state") {
        setClinicianData({
          ...clinicianData,
          [field]: sortedValues,
        });
      }
    } else if (field === "status") {
      // Access the first element of the array and convert to 1 or 0
      const statusValue = value[0] === "Active" ? 1 : 0;
      setClinicianData({
        ...clinicianData,
        [field]: statusValue,
      });
    } else if (field === "evaluation") {
      // Access the first element of the array and convert to 1 or 0
      const evaluationValue = value[0] === "Re Hire" ? 1 : 0;
      setClinicianData({
        ...clinicianData,
        [field]: evaluationValue,
      });
    } else if (field === "dateOfBirth") {
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      const selectedDate = new Date(value);
      selectedDate.setHours(0, 0, 0, 0);

      const minimumDate = new Date(1900, 0, 1);
      const selectedYear = selectedDate.getFullYear();
      if (
        selectedDate > currentDate ||
        selectedDate.getTime() === currentDate.getTime() ||
        selectedDate < minimumDate ||
        selectedYear < 1000 ||
        selectedYear > new Date().getFullYear() ||
        selectedDate == "Invalid Date"
      ) {
        setDateOfBirthError("Please enter valid date");
      } else {
        setDateOfBirthError("");
        setClinicianData({
          ...clinicianData,
          [field]: value,
        });
      }
    } else if (field === "licenseExpiryDate") {
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      const selectedDate = new Date(value);
      selectedDate.setHours(0, 0, 0, 0);
      const selectedYear = selectedDate.getFullYear();
      const minimumDate = currentDate; //new Date(1900, 0, 1);

      if (
        selectedDate < currentDate ||
        selectedDate.getTime() === currentDate.getTime() ||
        selectedDate < minimumDate ||
        selectedYear < 1000 ||
        selectedDate == "Invalid Date"
      ) {
        setExpiryDateError("Please enter valid date");
      } else {
        setExpiryDateError("");
        setClinicianData({
          ...clinicianData,
          [field]: value,
        });
      }
    } else if (field === "userName") {
      //remove @ from username
      let t = value[0];
      if (t.includes("@")) {
        t = t.replace(/@/g, "");
      }
      setClinicianData({
        ...clinicianData,
        [field]: t,
      });
    } else if (field === "monthlyAvailability") {
      console.log("field", field); //monthlyAvailability
      setFillMonthlyAvailForm(true);
    } else {
      setClinicianData({
        ...clinicianData,
        [field]: value.toString(),
      });
    }
  };

  const onCloseToaster = () => {
    setToasterOpen(false);
    setToasterSeverity(Labels.SUCCESS);
    setToasterMessage("");
  };

  const handleReset = () => {
    setClinicianData(initialClinicianData);
    setSelectedPrograms([]);
    setErrors({});
  };

  const sortByFirstAndSecondLetter = (a, b) => {
    const firstLetterA = a[0] ? a[0].toLowerCase() : "";
    const firstLetterB = b[0] ? b[0].toLowerCase() : "";

    if (firstLetterA !== firstLetterB) {
      return firstLetterA.localeCompare(firstLetterB);
    }

    const secondLetterA = a[1] ? a[1].toLowerCase() : "";
    const secondLetterB = b[1] ? b[1].toLowerCase() : "";

    return secondLetterA.localeCompare(secondLetterB);
  };

  const tableCellStyle = {
    fontWeight: "bold",
    backgroundColor: "var(--mainTheme100)",
    border: "1px solid var(--mainTheme)",
    textAlign: "center",
    whiteSpace: "nowrap",
  };

  const tableHeadStyle = {
    fontWeight: "bold",
    backgroundColor: "var(--mainTheme)",
    border: "1px solid var(--mainTheme)",
    textAlign: "ceanter",
    whiteSpace: "nowrap",
  };

  const [selectedTab, setSelectedTab] = useState("Demographics");
  const handleTabSwitch = (value) => {
    setSelectedTab(value);
  };
  const getVisitTypeRate = (field) => {
    const _payRate = rateList?.filter((rate) => rate?.visitType == field);
    return _payRate[0]?.rate;
  };

  return (
    <div className="addClincian">
      <div className="" key={index}>
        <Card
          style={{
            height: "auto",
            // minHeight: "76vh",
            maxHeight: "450px",
            overflowY: "auto",
            maxWidth: "100%",
            margin: "0 auto",
            paddingRight: "15px",
          }}
        >
          <Box marginBlock={2}>
            <Tabs
              className="custom-tabs"
              value={selectedTab}
              onChange={(e, newValue) => handleTabSwitch(newValue)}
              aria-label="wrapped label tabs example"
            >
              {[
                "Demographics",
                "License Information",
                "Pay Rates",
                "Monthly Availability Form",
                "Clients",
                "Upload Profile Picture",
              ].map((option, index) => (
                <Tab key={index} value={option} label={option} />
              ))}
            </Tabs>
          </Box>
          <Fade in={selectedTab == "Demographics"} unmountOnExit>
            <Box>
              <CardHeader
                title={"Demographic Information"}
                action={
                  <div
                    style={{
                      width: "100% !important",
                      margin: "0 !important",
                      padding: "0 !important",
                    }}
                  >
                    {/* <IconButton
                      style={{
                        float: "left",
                        color: "#FFFFFF",
                      }}
                      aria-label="Section minimise"
                      size="small"
                      onClick={toggleDemographicSection}
                    >
                      {isShowDemographic ? (
                        <KeyboardControlKeyIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )}
                    </IconButton> */}
                  </div>
                }
                sx={{
                  backgroundColor: `var(--mainTheme)`,
                  color: "white",
                  padding: "10px",
                  textAlign: "left !important",
                  fontWeight: 500,
                  marginBottom: "5px",
                  width: "1100px",
                }}
                titleTypographyProps={{ variant: "outline" }}
              />

              {/* {isShowDemographic && ( */}
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <MuiInput
                    label="First Name"
                    className="inputAddMembers"
                    type="input"
                    value={clinicianData.firstName || ""}
                    onChange={(event) =>
                      handleInputChange(
                        "firstName",
                        event.target.value.toString(),
                      )
                    }
                    required={true}
                  />

                  <ValidationError error={nameError} />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <MuiInput
                    label="Last Name"
                    className="inputAddMembers"
                    type="input"
                    value={clinicianData.lastName || ""}
                    onChange={(event) =>
                      handleInputChange(
                        "lastName",
                        event.target.value.toString(),
                      )
                    }
                    required={true}
                  />

                  <ValidationError error={lastNameError} />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <MuiInput
                    label="User Name"
                    className="inputAddMembers"
                    type="input"
                    value={clinicianData.userName}
                    onChange={(event) =>
                      handleChange("userName", event.target.value)
                    }
                    required={true}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <MuiInput
                    label="Password"
                    className="inputAddMembers"
                    type="input"
                    value={clinicianData.password}
                    onChange={(event) =>
                      handleInputChange(
                        "password",
                        event.target.value.toString(),
                      )
                    }
                    required={true}
                  />

                  <ValidationError error={passwordError} />
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <MuiInput
                    label="Speciality"
                    className="inputAddMembers"
                    type="input"
                    value={clinicianData.speciality}
                    onChange={(event) =>
                      handleChange("speciality", event.target.value)
                    }
                    required={true}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  {/* <MuiInput
                  label="Date Of Birth"
                  className="inputAddMembers"
                  type="date"
                  value={clinicianData.dateOfBirth}
                  onChange={(event) =>
                    handleChange("dateOfBirth", event.target.value.toString())
                  }
                  required={true}
                /> */}
                  <Box
                    sx={{ "& > :not(style)": { width: "100%", marginX: 1 } }}
                    className="inputAddMembers"
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        components={["DatePicker"]}
                        sx={{ width: "100%" }}
                      >
                        <DesktopDatePicker
                          sx={{ width: "100%" }}
                          // className="dateFieldBorder"
                          variant="outlined"
                          label="Date Of Birth"
                          views={["year", "month", "day"]}
                          disableFuture
                          value={dayjs(clinicianData?.dateOfBirth)}
                          onChange={(newValue) => {
                            handleChange("dateOfBirth", newValue);
                          }}
                          slotProps={{
                            textField: {
                              disabled: true,
                            },
                          }}
                          // renderInput={(params) => <TextField {...params} error={dateOfBirthError.length>0} helperText={dateOfBirthError} />}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                    <Box marginTop={1}>
                      <ValidationError error={dateOfBirthError} />
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <MuiInput
                    label="Gender"
                    className="inputAddMembers"
                    options={["Female", "Male"]}
                    type="select"
                    value={clinicianData.gender}
                    onChange={(event) =>
                      handleChange("gender", event.target.value)
                    }
                    required={true}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <MuiInput
                    label="Street Address"
                    className="inputAddMembers"
                    type="input"
                    value={clinicianData.address}
                    onChange={(event) =>
                      handleChange("address", event.target.value)
                    }
                    required={true}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <MuiInput
                    label="City"
                    className="inputAddMembers"
                    type="input"
                    value={clinicianData.city}
                    onChange={(event) =>
                      handleInputChange("city", event.target.value.toString())
                    }
                    required={true}
                  />

                  <ValidationError error={cityError} />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <MuiInput
                    label="State"
                    // options={stateNames.options.toLocaleString()}
                    options={stateNames.options.map((state) =>
                      state.toUpperCase(),
                    )}
                    className="inputAddMember"
                    type="select"
                    value={clinicianData.state}
                    onChange={(event) =>
                      handleChange("state", event.target.value.toString())
                    }
                    required={true}
                  />
                  <ValidationError error={stateError} />
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <MuiInput
                    label="Zip Code"
                    className="inputAddMembers"
                    type="input"
                    value={clinicianData.zipCode}
                    onChange={(event) =>
                      handleInputChange(
                        "zipCode",
                        event.target.value.toString(),
                      )
                    }
                    required={true}
                  />
                  <ValidationError error={zipCodeError} />
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <MuiInput
                    label="County"
                    className="inputAddMembers"
                    type="input"
                    value={clinicianData.county}
                    onChange={(event) =>
                      handleInputChange("county", event.target.value.toString())
                    }
                    required={true}
                  />

                  <ValidationError error={countyError} />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <MuiInput
                    label="Home Phone"
                    className="inputAddMembers"
                    type="input"
                    value={clinicianData.homeNumber}
                    onChange={(event) =>
                      handleInputChange(
                        "homeNumber",
                        event.target.value.toString(),
                      )
                    }
                    required={true}
                  />
                  <ValidationError error={homeNumberError} />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <MuiInput
                    label="Phone Number"
                    className="inputAddMembers"
                    type="input"
                    value={clinicianData.phoneNumber}
                    onChange={(event) =>
                      handleInputChange(
                        "phoneNumber",
                        event.target.value.toString(),
                      )
                    }
                    required={true}
                  />
                  <ValidationError error={phoneNumberError} />
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <MuiInput
                    label="Email"
                    className="inputAddMembers"
                    type="input"
                    value={clinicianData.email}
                    onChange={(event) =>
                      handleInputChange("email", event.target.value.toString())
                    }
                    required={true}
                  />

                  <ValidationError error={emailError} />
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <MuiInput
                    label="Languages Spoken"
                    // options={["English", "Spanish", "French"]}
                    options={languageOptions.options}
                    className="inputAddMembers"
                    type="select"
                    isMulti={true}
                    value={clinicianData.languages}
                    onChange={(event) =>
                      handleChange("languages", event.target.value)
                    }
                    required={true}
                  />
                </Grid>
              </Grid>
            </Box>
            {/* )} */}
          </Fade>

          <Fade in={selectedTab == "License Information"} unmountOnExit>
            <Box>
              <CardHeader
                title={"License Information"}
                action={
                  <div
                    style={{
                      width: "100% !important",
                      margin: "0 !important",
                      padding: "0 !important",
                    }}
                  >
                    {/* <IconButton
                      style={{
                        float: "right",
                        color: "#FFFFFF",
                      }}
                      aria-label="Section minimise"
                      size="small"
                      onClick={toggleLicenseSection}
                    >
                      {isShowLicense ? (
                        <KeyboardControlKeyIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )}
                    </IconButton> */}
                  </div>
                }
                sx={{
                  backgroundColor: `var(--mainTheme)`,
                  color: "white",
                  padding: "10px",
                  textAlign: "left !important",
                  fontWeight: 500,
                  marginBottom: "5px",
                  width: "1100px",
                }}
                titleTypographyProps={{ variant: "outline" }}
              />

              {/* {isShowLicense && ( */}
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <MuiInput
                    label="License Type"
                    options={[
                      "ANP",
                      "Case Manager",
                      "CNA",
                      "FNP",
                      "GNP",
                      "LPN",
                      "LVN",
                      "MA",
                      "MD",
                      "MSW PA",
                      "Psych",
                      "RN",
                      "Screening Technologist",
                      "SW",
                    ]}
                    className="inputAddMembers"
                    type="select"
                    isMulti={true}
                    value={clinicianData.licenseType}
                    onChange={(event) =>
                      handleChange("licenseType", event.target.value)
                    }
                    required={true}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <MuiInput
                    label="NPI Number"
                    className="inputAddMembers"
                    type="input"
                    value={clinicianData.npi}
                    // onChange={(event) => handleChange("npi", event.target.value)}
                    onChange={(event) =>
                      handleInputChange("npi", event.target.value)
                    }
                    required={true}
                  />
                  <ValidationError error={npiError} />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <MuiInput
                    label="License Number"
                    className="inputAddMembers"
                    type="input"
                    value={clinicianData.licenseNumber}
                    onChange={(event) =>
                      handleChange("licenseNumber", event.target.value)
                    }
                    required={true}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <MuiInput
                    label="State(s) it is valid in"
                    // options={["English", "Spanish", "French"]}
                    options={stateNames.options}
                    className="inputAddMembers"
                    type="select"
                    isMulti={true}
                    value={clinicianData.validStates}
                    onChange={(event) =>
                      handleChange("validStates", event.target.value)
                    }
                    required={true}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <Box
                    sx={{ "& > :not(style)": { width: "100%", marginX: 1 } }}
                    className="inputAddMembers"
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        components={["DatePicker"]}
                        sx={{ width: "100%" }}
                      >
                        <DesktopDatePicker
                          sx={{ width: "100%" }}
                          // className="dateFieldBorder"
                          variant="outlined"
                          minDate={dayjs(new Date())}
                          label="Expiration date"
                          views={["year", "month", "day"]}
                          value={dayjs(clinicianData.licenseExpiryDate)}
                          onChange={(newValue) =>
                            handleChange("licenseExpiryDate", newValue)
                          }
                          slotProps={{
                            textField: {
                              disabled: true,
                            },
                          }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                    <Box marginTop={1}>
                      <ValidationError error={expiryDateError} />
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <MuiInput
                    label="Clients Supported"
                    options={["Molina", "Test"]}
                    className="inputAddMembers"
                    type="select"
                    isMulti={true}
                    value={clinicianData.clientsSupported}
                    onChange={(event) =>
                      handleChange("clientsSupported", event.target.value)
                    }
                    required={true}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <MuiInput
                    label="PEDS approved"
                    className="inputAddMembers"
                    type="input"
                    value={clinicianData.pedApproved}
                    // onChange={(event) =>
                    //   handleChange("pedApproved", event.target.value)
                    // }
                    required={true}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <MuiInput
                    label="Status"
                    options={["Active", "Inactive"]}
                    className="inputAddMembers"
                    type="select"
                    value={clinicianData.status === 1 ? "Active" : "Inactive"}
                    onChange={(event) =>
                      handleChange("status", event.target.value)
                    }
                    required={true}
                  />
                </Grid>
                {clinicianData.status === 0 || true ? (
                  <>
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                      <MuiInput
                        label="Evaluation"
                        options={["Re Hire", "No Rehire"]}
                        className="inputAddMembers"
                        type="select"
                        value={
                          clinicianData.evaluation == 1
                            ? "Re Hire"
                            : "No Rehire"
                        }
                        onChange={(event) =>
                          handleChange("evaluation", event.target.value)
                        }
                        required={true}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                      <MuiInput
                        label="Evaluation Reason"
                        type="input"
                        className="inputAddMembers"
                        multiline
                        value={clinicianData.evaluationReason}
                        onChange={(event) =>
                          handleChange("evaluationReason", event.target.value)
                        }
                        required={true}
                      />
                    </Grid>
                  </>
                ) : (
                  <></>
                )}
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <MuiInput
                    label="Emergency Contact Name"
                    className="inputAddMembers"
                    type="input"
                    value={clinicianData.emerContactName}
                    onChange={(event) =>
                      handleInputChange(
                        "emerContactName",
                        event.target.value.toString(),
                      )
                    }
                    required={true}
                  />

                  <ValidationError error={emerContactNameError} />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <MuiInput
                    label="Emergency Contact Relation"
                    className="inputAddMembers"
                    type="Select"
                    options={[
                      "Aunt",
                      "Brother",
                      "Cousin",
                      "Father",
                      "Friend",
                      "Grandparent",
                      "Mother",
                      "Spouse",
                      "Sister",
                      "Uncle",
                    ]}
                    value={clinicianData.emerContactRelation}
                    onChange={(event) =>
                      handleInputChange(
                        "emerContactRelation",
                        event.target.value.toString(),
                      )
                    }
                    required={true}
                  />

                  <ValidationError error={emerContactRelationError} />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <MuiInput
                    label=" Emergency Contact address"
                    className="inputAddMembers"
                    type="input"
                    value={clinicianData.emerContactAddress}
                    onChange={(event) =>
                      handleChange("emerContactAddress", event.target.value)
                    }
                    required={true}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <MuiInput
                    label=" Emergency Contact Number"
                    className="inputAddMembers"
                    type="input"
                    value={clinicianData.emerContactPhone}
                    onChange={(event) =>
                      handleInputChange("emerContactPhone", event.target.value)
                    }
                    required={true}
                  />
                  <ValidationError error={emerContactPhoneError} />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <MuiInput
                    label="Time Zone"
                    // options={timeZoneOptions.options}
                    options={timeZones.options}
                    className="inputAddMembers"
                    type="select"
                    isMulti={true}
                    value={clinicianData.timeZone}
                    onChange={(event) =>
                      handleChange("timeZone", event.target.value)
                    }
                    required={true}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <MuiInput
                    label="Preferred Method of Contact"
                    options={["Phone", "Email", "Text"]}
                    className="inputAddMembers"
                    type="select"
                    isMulti={true}
                    value={clinicianData.prefContactMethod}
                    onChange={(event) =>
                      handleChange("prefContactMethod", event.target.value)
                    }
                    required={true}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <MuiInput
                    label="Notes"
                    className="inputAddMembers"
                    type="input"
                    value={clinicianData.notes}
                    onChange={(event) =>
                      handleChange("notes", event.target.value)
                    }
                    multiline
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <MuiInput
                    label="Zip Code Exclusion (Zip Codes Clinician will NOT travel to)"
                    className="inputAddMembers"
                    type="input"
                    value={clinicianData.zipCodeExclusion}
                    onChange={(event) =>
                      handleInputChange(
                        "zipCodeExclusion",
                        event.target.value.toString(),
                      )
                    }
                    required={true}
                  />
                  <ValidationError error={zipCodeExclusionError} />
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <Box
                    sx={{ "& > :not(style)": { width: "100%", marginX: 1 } }}
                    className="inputAddMembers"
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        components={["DatePicker"]}
                        sx={{ width: "100%" }}
                      >
                        <DesktopDatePicker
                          sx={{ width: "100%" }}
                          variant="outlined"
                          label="Last Updated"
                          views={["year", "month", "day"]}
                          value={dayjs(clinicianData?.lastUpdated)}
                          onChange={(event) =>
                            handleChange("lastUpdated", event.target.value)
                          }
                          slotProps={{
                            textField: {
                              disabled: true,
                            },
                          }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </Box>
                </Grid>
              </Grid>
              {/* )} */}
            </Box>
          </Fade>

          <Fade in={selectedTab == "Pay Rates"} unmountOnExit>
            <Box>
              <CardHeader
                title={"Pay Rates"}
                action={
                  <div
                    style={{
                      width: "100% !important",
                      margin: "0 !important",
                      padding: "0 !important",
                    }}
                  >
                    {/* <IconButton
                      style={{
                        float: "right",
                        color: "#FFFFFF",
                      }}
                      aria-label="Section minimise"
                      size="small"
                      onClick={toggleLicenseSection}
                    >
                      {isShowLicense ? (
                        <KeyboardControlKeyIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )}
                    </IconButton> */}
                  </div>
                }
                sx={{
                  backgroundColor: `var(--mainTheme)`,
                  color: "white",
                  padding: "10px",
                  textAlign: "left !important",
                  fontWeight: 500,
                  marginBottom: "5px",
                  width: "1100px",
                }}
                titleTypographyProps={{ variant: "outline" }}
              />
              <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "15px",
                    marginLeft: "10px",
                    //width: "30vw",
                    margin: "auto",
                  }}
                >
                  <Table
                    sx={{
                      tableLayout: "auto",
                      margin: "10px",
                      overflow: "hidden",
                    }}
                    size="small"
                  >
                    <TableHead style={tableHeadStyle}>
                      <TableRow>
                        <TableCell style={tableCellStyle}>Visit Type</TableCell>
                        <TableCell style={tableCellStyle}>Rate</TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {[
                        { name: "F2F ($ per hour)", field: "F2F" },
                        { name: "Virtual ($ per hour)", field: "Virtual" },
                        { name: "No Show ($ per hour)", field: "NoShow" },
                        { name: "Distance ($ per miles)", field: "Distance" },
                      ]?.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell style={tableCellStyle}>
                            <Typography variant="body2">{row.name}</Typography>
                          </TableCell>

                          <TableCell style={tableCellStyle}>
                            <TextField
                              onChange={(e) =>
                                handleRateChange(e?.target?.value, row.field)
                              }
                              // value = {getVisitTypeRate(row.field)}
                              value={rateListObj[row.field]}
                              //disabled={disableFlag || _disableFlag}
                              variant="outlined"
                              // label= '$ per hour'
                              sx={{
                                // height: "24px",
                                border:
                                  "1.5px solid var(--mainTheme) !important",
                                //  : " 1.5px solid rgba(255, 45, 49, 0.7) !important",
                                "& .MuiFormLabel-root": {
                                  backgroundColor: "white",
                                },
                                "& .MuiInputLabel-root": {
                                  backgroundColor: "white",
                                },
                              }}
                              fullWidth
                              size="small"
                              //variant="standard"
                              // value={row.reaction || ""}
                              //inputProps={{ readOnly: disableFlag }}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              </Grid>
            </Box>
          </Fade>

          <Fade in={selectedTab == "Monthly Availability Form"} unmountOnExit>
            <Box>
              <CardHeader
                title={"Availability and Travel Preferences"}
                action={
                  <div
                    style={{
                      width: "100% !important",
                      margin: "0 !important",
                      padding: "0 !important",
                    }}
                  >
                    {/* <IconButton
                      style={{
                        float: "right",
                        color: "#FFFFFF",
                      }}
                      aria-label="Section minimise"
                      size="small"
                      onClick={toggleAvailabilitySection}
                    >
                      {isShowAvailability ? (
                        <KeyboardControlKeyIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )}
                    </IconButton> */}
                  </div>
                }
                sx={{
                  backgroundColor: `var(--mainTheme)`,
                  color: "white",
                  padding: "10px",
                  textAlign: "left !important",
                  fontWeight: 500,
                  marginBottom: "5px",
                  width: "1100px",
                }}
                titleTypographyProps={{ variant: "outline" }}
              />

              {/* {isShowAvailability && ( */}
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <MuiInput
                    label="# Miles Clinician will travel"
                    className="inputAddMembers"
                    type="input"
                    value={clinicianData.maxMiles}
                    onChange={(event) =>
                      handleChange("maxMiles", event.target.value)
                    }
                    required={true}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={4}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Link
                    component="button"
                    variant="body2"
                    onClick={() => {
                      handleChange("monthlyAvailability", "");
                    }}
                  >
                    Fill Monthly Availability Form
                  </Link>
                </Grid>
              </Grid>
              {/* )} */}
            </Box>
          </Fade>

          <Fade in={selectedTab == "Upload Profile Picture"} unmountOnExit>
            <Box>
              <CardHeader
                title={"Upload Profile Picture"}
                action={
                  <div
                    style={{
                      width: "100% !important",
                      margin: "0 !important",
                      padding: "0 !important",
                    }}
                  >
                    {/* <IconButton
                      style={{
                        float: "right",
                        color: "#FFFFFF",
                      }}
                      aria-label="Section minimise"
                      size="small"
                      onClick={toggleAvailabilitySection}
                    >
                      {isShowAvailability ? (
                        <KeyboardControlKeyIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )}
                    </IconButton> */}
                  </div>
                }
                sx={{
                  backgroundColor: `var(--mainTheme)`,
                  color: "white",
                  padding: "10px",
                  textAlign: "left !important",
                  fontWeight: 500,
                  marginBottom: "5px",
                  width: "1100px",
                }}
                titleTypographyProps={{ variant: "outline" }}
              />
              <ProfilePhotoUpload
                profilePic={profilePic}
                setProfilePic={setProfilePic}
              />
            </Box>
          </Fade>
          <Dialog
            maxWidth="lg"
            sx={{
              "& .MuiPaper-root": {
                width: "100%",
              },
            }}
            open={fillMonthlyAvailForm}
            onClose={() => {
              setFillMonthlyAvailForm(false);
            }}
          >
            <DialogTitle>Availability and Travel Preferences</DialogTitle>
            <DialogContent>
              <MonthlyAvailabilityForm
                monthlyAvailForm={monthlyAvailability}
                setMonthlyAvailability={setMonthlyAvailability}
                fromAddClinician={true}
                lastUpdated={moment(new Date()).format("MM/DD/YYYY hh:mm a")}
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setFillMonthlyAvailForm(false);
                }}
                color="primary"
                sx={{
                  width: "100px",
                  height: "32px",
                  flexShrink: "0",
                  borderRadius: "5px",
                  background: "var(--mainTheme)",
                  color: "white",
                  "&:hover": {
                    boxShadow: 8,
                    background: "var(--mainTheme)",
                    color: "white",
                  },
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>

          <Fade in={selectedTab == "Clients"} unmountOnExit>
            <Box>
              <CardHeader
                title={"Clients"}
                action={
                  <div
                    style={{
                      width: "100% !important",
                      margin: "0 !important",
                      padding: "0 !important",
                    }}
                  >
                    {/* <IconButton
                      style={{
                        float: "right",
                        color: "#FFFFFF",
                      }}
                      aria-label="Section minimise"
                      size="small"
                      onClick={toggleLicenseSection}
                    >
                      {isShowLicense ? (
                        <KeyboardControlKeyIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )}
                    </IconButton> */}
                  </div>
                }
                sx={{
                  backgroundColor: `var(--mainTheme)`,
                  color: "white",
                  padding: "10px",
                  textAlign: "left !important",
                  fontWeight: 500,
                  marginBottom: "5px",
                  width: "1100px",
                }}
                titleTypographyProps={{ variant: "outline" }}
              />
              <Grid container style={{ marginTop: "5px" }}>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <MuiInput
                    label="Client Name"
                    className="inputAddMembers"
                    type="input"
                    value={clinicianData.clientName}
                    // onChange={(event) =>
                    //   handleChange("clientName", event.target.value.toString())
                    // }
                    required={true}
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid container xs={12}>
                <Grid item xs={3} style={{ marginLeft: "15px" }}>
                  <h4 style={headingStyle}>Clients List</h4>
                  <div
                    style={{
                      maxHeight: "200px",
                      overflowY: "auto",
                    }}
                  >
                    {uniquePlans
                      .sort(sortByFirstAndSecondLetter)
                      .map((plan) => (
                        <div key={plan}>
                          <Button
                            key={plan}
                            style={{
                              backgroundColor: "var(--mainTheme)",
                              color: "white",
                              width: "100%",
                              marginTop: "3px",
                            }}
                            onClick={() => handlePlanClick(plan)}
                          >
                            {plan}
                          </Button>
                          {planData
                            .filter((item) => item.plan === plan)
                            .sort((a, b) => a.program.localeCompare(b.program))
                            .map((programItem) => (
                              <div
                                key={`${programItem.clientId}-${programItem.programId}`}
                              >
                                <Button
                                  style={{
                                    backgroundColor: "#ccc",
                                    color: "#000000de",
                                    width: "100%",
                                    marginTop: "3px",
                                  }}
                                  onClick={() =>
                                    handleProgramClick(
                                      programItem.clientId,
                                      programItem.programId,
                                    )
                                  }
                                >
                                  {programItem.program}
                                </Button>
                              </div>
                            ))}
                        </div>
                      ))}
                  </div>
                </Grid>

                <Grid item xs={3} style={{ marginLeft: "15px" }}>
                  <h4 style={headingStyle}>Selected clients</h4>

                  <div style={{ marginBottom: "10px" }}>
                    {selectedClientId && (
                      <div
                        style={{
                          maxHeight: "200px",
                          overflowY: "auto",
                        }}
                      >
                        {selectedProgramData ? (
                          <div>
                            <Button
                              style={{
                                backgroundColor: "var(--mainTheme)",
                                color: "white",
                                width: "100%",
                                marginBottom: "3px",
                              }}
                            >
                              {selectedProgramData.plan}
                            </Button>

                            <div>
                              <Button
                                style={{
                                  backgroundColor: "#ccc",
                                  color: "#000000de",
                                  width: "100%",
                                  marginTop: "3px",
                                }}
                              >
                                {selectedProgramData.program}
                              </Button>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}

                        {selectedPrograms
                          .map((selectedProgram) => {
                            return planData
                              .filter(
                                (item) =>
                                  item.clientId === selectedProgram.clientId &&
                                  item.programId === selectedProgram.programId,
                              )
                              .map((programItem) => programItem);
                          })
                          .flat()
                          .reduce((accumulator, programItem) => {
                            const existingPlan = accumulator.find(
                              (plan) => plan.plan === programItem.plan,
                            );

                            if (!existingPlan) {
                              accumulator.push({
                                plan: programItem.plan,
                                programs: [programItem.program],
                              });
                            } else {
                              existingPlan.programs.push(programItem.program);
                            }

                            return accumulator;
                          }, [])
                          .sort((a, b) => a.plan.localeCompare(b.plan))
                          .map((uniquePlan) => (
                            <div key={uniquePlan.plan}>
                              <Button
                                style={{
                                  backgroundColor: "#1076BC",
                                  color: "white",
                                  width: "100%",
                                  marginTop: "3px",
                                }}
                              >
                                {uniquePlan.plan}
                              </Button>
                              {uniquePlan.programs
                                .sort((a, b) => a.localeCompare(b))
                                .map((program) => (
                                  <div
                                    key={`${uniquePlan.plan}-${program}`}
                                    style={{
                                      backgroundColor: "#ccc",
                                      color: "#000000de",
                                      width: "100%",
                                      marginTop: "3px",
                                      textAlign: "center",
                                      padding: "3px",
                                    }}
                                  >
                                    {program}
                                  </div>
                                ))}
                            </div>
                          ))}
                      </div>
                    )}
                  </div>
                  {/* </CardContent>
                  </Card> */}
                </Grid>
              </Grid>
            </Box>
          </Fade>
        </Card>
      </div>
      <div className="btnsAddMember">
        <div style={{ marginRight: "auto" }}>
          <Button sx={dialogBtn} disabled={true} className="disabledButton">
            Unlock
          </Button>
        </div>

        <Button sx={dialogBtn} onClick={handleReset}>
          Reset
        </Button>

        <Button
          color="primary"
          sx={{
            width: "100px",
            height: "32px",
            flexShrink: "0",
            borderRadius: "5px",
            background: "var(--mainTheme)",
            marginInline: "10px",
            color: "white",
            "&:hover": {
              boxShadow: 8,
              background: "var(--mainTheme)",
              color: "white",
            },
          }}
          // className={`dialogBtn ${
          //   !additionalConditions.every((condition) => condition) ||
          //   !isFormValid
          //     ? "disabledButton"
          //     : ""
          // }`}
          // disabled={
          //   !additionalConditions.every((condition) => condition) ||
          //   !isFormValid
          // }
          //disabled = {!additionalConditions.every((condition) => condition)}
          onClick={handleAdd}
        >
          Add
        </Button>
      </div>
      <Toaster
        open={toasterOpen}
        onClose={onCloseToaster}
        severity={toasterSeverity}
        message={toasterMessage}
      />
    </div>
  );
}
