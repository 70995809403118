import {
  Grid,
  Stack,
  InputLabel,
  TextField,
  Typography,
  Box,
  ToggleButton,
  ToggleButtonGroup,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Paper,
} from "@mui/material";
import React, { useEffect, useState } from "react";
const DebounceTextField = React.memo(({ onChange, value, ...props }) => {
  const [_value, _setValue] = useState("");

  useEffect(() => {
    _setValue(value);
  }, []);

  useEffect(() => {
    const id = setTimeout(() => {
      //const id = setInterval(() => {
      if (_value != value) {
        onChange(_value);
      }
    }, 1500);
    return () => {
      //clearInterval(id);
      clearTimeout(id);
    };
  }, [_value]);

  return (
    <TextField
      {...props}
      //key={`textfield${question?.questionId}${question?.name}`}
      variant="outlined"
      fullWidth
      value={
        // answeredJson[question?.questionId]?.[question?.name]
        _value
      }
      sx={{
        border: "1.5px solid var(--mainTheme) !important",
        borderRadius: "5px",
        "& .MuiFormLabel-root": {
          color: "var(--mainTheme)",
        },
        "& .MuiFormLabel-root.Mui-focused": {
          color: "var(--mainTheme)",
        },
        marginTop: "10px",
        marginLeft: "10px",
        marginBottom: "10px",
      }}
      // onChange={(e) => {
      //   updateAnsweredJsonForTextField(
      //     question,
      //     //idx,
      //     question?.questionId,
      //     e.target.value,
      //   );
      // }}
      onChange={(e) => {
        _setValue(e.target.value);
      }}
    />
  );
});

export default DebounceTextField;
