import InfoIcon from "@mui/icons-material/Info";
import { FormControl, FormLabel, Grid, Stack, Tooltip } from "@mui/material";
import lodash from "lodash";
import PropTypes from "prop-types";
import React from "react";

import {
  DFCommentIcon,
  DFCommentIconMemo,
  DFCommentTextBox,
  DFCommentTextBoxMemo,
  isCommentVisible,
  isCommentVisibleMemo,
} from "./DFComment";

import "./DynamicForms.css";

export const DFLabel = ({
  inputProps,
  handleTextChange,
  showComment,
  setShowComment,
  quesSlug,
  sectionId,
  commentValue,
  disableFlag,
  handleRadioChange,
  value,
  needMemo,
  getFieldValues,
  setFieldValues,
}) => {
  const { isCommentEnabled, name, label, labelType } = inputProps;

  const getTooltipText = (name) => {
    if (name === "home_directions") {
      return "Members should wear their regular footwear and can use a walking aid, if needed. Observe the patient’s postural stability, gait, stride length, and sway. Begin by having the patient sit back in a standard armchair and identify a line 3 meters or 10 feet away, on the floor.";
    } else if (name === "self_educGuidelines") {
      return (
        <ol>
          <li>Maximum Drinking Limits</li>
          For healthy men up to age 65:
          <ul>
            <li>No more than 4 drinks in a day AND</li>
            <li>No more than 14 drinks in a week</li>
          </ul>
          For healthy women ( and healthy men over age 65):
          <ul>
            <li>No more than 3 drinks in a day AND</li>
            <li>No more than 7 drinks in a week</li>
          </ul>
          <li>Lower limits or abstinence as medically indicated</li>
          For example:
          <ul>
            <li>Take medications that interact with alcohol</li>
            <li>Have a health condition exacerbated by alcohol</li>
            <li>Are pregnant (advise abstinence)</li>
          </ul>
          <li>
            Express openness to talking about alcohol use and any concerns it
            may raise
          </li>
          <li>Rescreen annually</li>
        </ol>
      );
    }
  };

  const topMarginLabels = [
    "self_alcUseCurrentSubHowManyDrinksSubScoreWhiteSpace",
    "self_alcUseFormerSubHowManyDrinksSubScoreWhiteSpace",
  ];

  let needTopMargin = topMarginLabels.includes(name);

  return (
    <>
      <Stack direction="row">
        <Grid item xs={isCommentEnabled ? 11 : 12}>
          <FormControl
            className="inputStyle"
            component="fieldset"
            variant="outlined"
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: `${needTopMargin ? "30px" : "10px"}`,
              }}
            >
              <FormLabel
                className="inputStyle"
                sx={{
                  marginLeft: "3px",
                  fontWeight: "600",
                  color: `${
                    labelType && labelType === "error"
                      ? "tomato"
                      : "var(--mainTheme)"
                  }`,
                }}
              >
                {label}

                {(name === "home_directions" ||
                  name === "self_educGuidelines") && (
                  <Tooltip
                    title={
                      <div
                        style={
                          name === "home_directions"
                            ? { fontSize: "16px", width: "250px" }
                            : { fontSize: "12px", maxWidth: "none" }
                        }
                      >
                        {getTooltipText(name)}
                      </div>
                    }
                    placement="top"
                    arrow
                  >
                    <InfoIcon
                      variant="outlined"
                      style={{ cursor: "pointer" }}
                    />
                  </Tooltip>
                )}
              </FormLabel>
            </div>
          </FormControl>
        </Grid>

        {isCommentEnabled === true &&
          (needMemo ? (
            <DFCommentIconMemo
              getFieldValues={getFieldValues}
              setFieldValues={setFieldValues}
              name={name}
              value={commentValue}
              disableFlag={disableFlag}
            />
          ) : (
            <DFCommentIcon
              showComment={showComment}
              setShowComment={setShowComment}
              sectionId={sectionId}
              quesSlug={quesSlug}
              value={commentValue}
              disableFlag={disableFlag}
            />
          ))}
      </Stack>
      {isCommentEnabled &&
        (needMemo
          ? isCommentVisibleMemo(getFieldValues, name)
          : isCommentVisible(
              showComment,
              sectionId,
              quesSlug,
              commentValue,
            )) && (
          <DFCommentTextBox
            label={label}
            handleTextChange={handleTextChange}
            name={name}
            value={commentValue}
            disableFlag={disableFlag}
          />
        )}
    </>
  );
};

DFLabel.propTypes = {
  inputProps: PropTypes.object.isRequired,
  // formData: PropTypes.object.isRequired,
  // value: PropTypes.string.isRequired,
  handleTextChange: PropTypes.func.isRequired,
  quesSlug: PropTypes.string.isRequired,
  sectionId: PropTypes.number.isRequired,
  showComment: PropTypes.array.isRequired,
  setShowComment: PropTypes.func.isRequired,
  // setFormData: PropTypes.func.isRequired,
  disableFlag: PropTypes.bool,
  commentValue: PropTypes.string,
};

const dontReRender = (oldProps, newProps) => {
  /*
   
  returns false - if there is a change between oldProps & newProps
  returns true - if there is no change between oldProps & newProps

  console.log(
    JSON.stringify(oldProps),
    JSON.stringify(newProps),
    lodash.isEqual(JSON.stringify(oldProps), JSON.stringify(newProps)),
    "check memo"
  );

  */

  return lodash.isEqual(JSON.stringify(oldProps), JSON.stringify(newProps));
};

export const DFLabelMemo = React.memo(
  ({
    inputProps,
    commentValue,
    handleTextChange,
    getFieldValues,
    setFieldValues,
    disableFlag,
  }) => {
    const { isCommentEnabled, name, label } = inputProps;
    const text =
      "Members should wear their regular footwear and can use a walking aid, if needed. Observe the patient’s postural stability, gait, stride length, and sway. Begin by having the patient sit back in a standard armchair and identify a line 3 meters or 10 feet away, on the floor.";
    return (
      <>
        <Stack direction="row">
          <Grid item xs={isCommentEnabled ? 11 : 12}>
            <FormControl
              className="inputStyle"
              component="fieldset"
              variant="outlined"
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "10px",
                }}
              >
                <FormLabel
                  className="inputStyle"
                  style={{ marginLeft: "3px", fontWeight: "600" }}
                >
                  {label}
                  {name === "home_directions" && (
                    <Tooltip
                      title={
                        <div style={{ fontSize: "16px", width: "250px" }}>
                          {text}
                        </div>
                      }
                      placement="top"
                      arrow
                    >
                      <InfoIcon
                        variant="outlined"
                        style={{ cursor: "pointer" }}
                      />
                    </Tooltip>
                  )}
                </FormLabel>
              </div>
            </FormControl>
          </Grid>

          {isCommentEnabled === true && (
            <DFCommentIconMemo
              name={name}
              value={commentValue}
              getFieldValues={getFieldValues}
              setFieldValues={setFieldValues}
              disableFlag={disableFlag}
            />
          )}
        </Stack>
        {isCommentEnabled && isCommentVisibleMemo(getFieldValues, name) && (
          <DFCommentTextBoxMemo
            label={label}
            handleTextChange={handleTextChange}
            name={name}
            value={commentValue}
            disableFlag={disableFlag}
          />
        )}
      </>
    );
  },
  dontReRender,
);

DFLabelMemo.propTypes = {
  inputProps: PropTypes.object.isRequired,
  commentValue: PropTypes.string,
  handleTextChange: PropTypes.func.isRequired,
  getFieldValues: PropTypes.func.isRequired,
  setFieldValues: PropTypes.func.isRequired,
  disableFlag: PropTypes.bool,
};
