const json2023 = {
  sectionId: 13,
  sectionName: "Exam Review",
  isEditable: false,
  questions: [
    {
      id: 9,
      name: "constitutional",
      type: "heading",
      label: "Constitutional",
      xs: 12,
      isCommentEnabled: false,
      questionId: 1,
    },
    {
      id: 6,
      name: "exam_genAppear",
      type: "options_tree",
      label: "General appearance:",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              name: "exam_genAppearAbnormalSubDesc",
              value: "",
              type: "text",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
            },
          ],
          isCommentEnabled: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
        },
      ],
      questionId: 2,
    },
    {
      id: 9,
      name: "head_and_face",
      type: "heading",
      label: "Head and Face",
      xs: 12,
      isCommentEnabled: false,
      questionId: 3,
    },
    {
      id: 6,
      name: "exam_headFaceExam",
      type: "options_tree",
      label: "Examination of head and face:",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              name: "exam_headFaceExamAbnormalSubDesc",
              value: "",
              type: "text",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
            },
          ],
          isCommentEnabled: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
        },
      ],
      questionId: 4,
    },
    {
      id: 6,
      name: "exam_headFacePalp",
      type: "options_tree",
      label: "Palpation of the face and sinuses:",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              name: "exam_headFacePalpAbnormalSubDesc",
              value: "",
              type: "text",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
            },
          ],
          isCommentEnabled: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
        },
      ],
      questionId: 5,
    },
    {
      id: 9,
      name: "eyes",
      type: "heading",
      label: "Eyes",
      xs: 12,
      isCommentEnabled: false,
      questionId: 6,
    },
    {
      id: 6,
      name: "exam_eyesInsp",
      type: "options_tree",
      label: "Inspection of conjunctiva and lids:",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              name: "exam_eyesInspAbnormalSubDesc",
              value: "",
              type: "text",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
            },
          ],
          isCommentEnabled: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
        },
      ],
      questionId: 7,
    },
    {
      id: 6,
      name: "exam_eyesExam",
      type: "options_tree",
      label: "Examination of pupils and irises:",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              name: "exam_eyesExamAbnormalSubDesc",
              value: "",
              type: "text",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
            },
          ],
          isCommentEnabled: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
        },
      ],
      questionId: 8,
    },
    {
      id: 9,
      name: "ears,_nose,_mouth_and_throat",
      type: "heading",
      label: "Ears, Nose, Mouth and Throat",
      xs: 12,
      isCommentEnabled: false,
      questionId: 9,
    },
    {
      id: 6,
      name: "exam_entExtInsp",
      type: "options_tree",
      label: "External Inspection of ears and nose:",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              name: "exam_entExtInspAbnormalSubDesc",
              value: "",
              type: "text",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
            },
          ],
          isCommentEnabled: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
        },
      ],
      questionId: 10,
    },
    {
      id: 6,
      name: "exam_entOtosExam",
      type: "options_tree",
      label: "Otoscopic examination:",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              name: "exam_entOtosExamAbnormalSubDesc",
              value: "",
              type: "text",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
            },
          ],
          isCommentEnabled: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
        },
      ],
      questionId: 11,
    },
    {
      id: 6,
      name: "exam_entAssessHear",
      type: "options_tree",
      label: "Assessment of hearing:",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              name: "exam_entAssessHearAbnormalSubDesc",
              value: "",
              type: "text",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
            },
          ],
          isCommentEnabled: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
        },
      ],
      questionId: 12,
    },
    {
      id: 6,
      name: "exam_entNasalInsp",
      type: "options_tree",
      label: "Inspection of nasal mucosa, septum and trubinates:",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              name: "exam_entNasalInspAbnormalSubDesc",
              value: "",
              type: "text",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
            },
          ],
          isCommentEnabled: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
        },
      ],
      questionId: 13,
    },
    {
      id: 6,
      name: "exam_entInspLips",
      type: "options_tree",
      label: "Inspection of lips, teeth and gums:",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              name: "exam_entInspLipsAbnormalSubDesc",
              value: "",
              type: "text",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
            },
          ],
          isCommentEnabled: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
        },
      ],
      questionId: 14,
    },
    {
      id: 6,
      name: "exam_entOropExam",
      type: "options_tree",
      label: "Examination of oropharynx:",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              name: "exam_entOropExamAbnormalSubDesc",
              value: "",
              type: "text",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
            },
          ],
          isCommentEnabled: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
        },
      ],
      questionId: 15,
    },
    {
      id: 9,
      name: "neck",
      type: "heading",
      label: "Neck",
      xs: 12,
      isCommentEnabled: false,
      questionId: 16,
    },
    {
      id: 6,
      name: "exam_neckExam",
      type: "options_tree",
      label: "Examination of neck:",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              name: "exam_neckExamAbnormalSubDesc",
              value: "",
              type: "text",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
            },
          ],
          isCommentEnabled: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
        },
      ],
      questionId: 17,
    },
    {
      id: 6,
      name: "exam_neckExamThyroid",
      type: "options_tree",
      label: "Examination of thyroid:",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              name: "exam_neckExamThyroidAbnormalSubDesc",
              value: "",
              type: "text",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
            },
          ],
          isCommentEnabled: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
        },
      ],
      questionId: 18,
    },
    {
      id: 9,
      name: "pulmonary",
      type: "heading",
      label: "Pulmonary",
      xs: 12,
      isCommentEnabled: false,
      questionId: 19,
    },
    {
      id: 6,
      name: "exam_pulmAssessResp",
      type: "options_tree",
      label: "Assessment of respiratory effort:",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              name: "exam_pulmAssessRespAbnormalSubDesc",
              value: "",
              type: "text",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
            },
          ],
          isCommentEnabled: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
        },
      ],
      questionId: 20,
    },
    {
      id: 6,
      name: "exam_pulmAuscLungs",
      type: "options_tree",
      label: "Auscultation of lungs:",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              name: "exam_pulmAuscLungsAbnormalSubDesc",
              value: "",
              type: "text",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
            },
          ],
          isCommentEnabled: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
        },
      ],
      questionId: 21,
    },
    {
      id: 9,
      name: "cardiovascular",
      type: "heading",
      label: "Cardiovascular",
      xs: 12,
      isCommentEnabled: false,
      questionId: 22,
    },
    {
      id: 6,
      name: "exam_cardioAusHeart",
      type: "options_tree",
      label: "Auscultation of heart:",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              name: "exam_cardioAusHeartAbnormalSubDesc",
              value: "",
              type: "text",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
            },
          ],
          isCommentEnabled: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
        },
      ],
      questionId: 23,
    },
    {
      id: 6,
      name: "exam_cardioPalpAus",
      type: "options_tree",
      label: "Palpation and auscultation of Carotid Arteries:",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              name: "exam_cardioPalpAusAbnormalSubDesc",
              value: "",
              type: "text",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
            },
          ],
          isCommentEnabled: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
        },
      ],
      questionId: 24,
    },
    {
      id: 6,
      name: "exam_cardioPedalPulse",
      type: "options_tree",
      label: "Pedal Pulses:",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              name: "exam_cardioPedalPulseAbnormalSubDesc",
              value: "",
              type: "text",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
            },
          ],
          isCommentEnabled: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
        },
      ],
      questionId: 25,
    },
    {
      id: 6,
      name: "exam_cardioExamEdema",
      type: "options_tree",
      label: "Examination of Edema / Varicosities:",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              name: "exam_cardioExamEdemaAbnormalSubDesc",
              value: "",
              type: "text",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
            },
          ],
          isCommentEnabled: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
        },
      ],
      questionId: 26,
    },
    {
      id: 6,
      name: "exam_cardioExamRadial",
      type: "options_tree",
      label: "Examination of Radial Pulses:",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              name: "exam_cardioExamRadialAbnormalSubDesc",
              value: "",
              type: "text",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
            },
          ],
          isCommentEnabled: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
        },
      ],
      questionId: 27,
    },
    {
      id: 9,
      name: "lymphatic",
      type: "heading",
      label: "Lymphatic",
      xs: 12,
      isCommentEnabled: false,
      questionId: 28,
    },
    {
      id: 6,
      name: "exam_lympPalpCervi",
      type: "options_tree",
      label: "Palpation of cervical nodes (neck)",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              name: "exam_lympPalpCerviAbnormalSubDesc",
              value: "",
              type: "text",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
            },
          ],
          isCommentEnabled: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
        },
      ],
      questionId: 29,
    },
    {
      id: 6,
      name: "exam_lympPalpPreauric",
      type: "options_tree",
      label: "Palpation of preauricular nodes (in front of the ears)",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              name: "exam_lympPalpPreauricAbnormalSubDesc",
              value: "",
              type: "text",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
            },
          ],
          isCommentEnabled: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
        },
      ],
      questionId: 30,
    },
    {
      id: 6,
      name: "exam_lympPalpSubmandi",
      type: "options_tree",
      label: "Palpation of Submandibular nodes (under jaw line/chin)",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              name: "exam_lympPalpSubmandiAbnormalSubDesc",
              value: "",
              type: "text",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
            },
          ],
          isCommentEnabled: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
        },
      ],
      questionId: 31,
    },
    {
      id: 9,
      name: "musculoskeletal",
      type: "heading",
      label: "MUSCULOSKELETAL",
      xs: 12,
      isCommentEnabled: false,
      questionId: 32,
    },
    {
      id: 6,
      name: "exam_muscSkelExamGait",
      type: "options_tree",
      label: "Examination of gait and station:",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              name: "exam_muscSkelExamGaitAbnormalSubDesc",
              value: "",
              type: "text",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
            },
          ],
          isCommentEnabled: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
        },
      ],
      questionId: 33,
    },
    {
      id: 6,
      name: "exam_muscSkelInspDigit",
      type: "options_tree",
      label: "Inspection/palpation of digits and nails:",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              name: "exam_muscSkelInspDigitAbnormalSubDesc",
              value: "",
              type: "text",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
            },
          ],
          isCommentEnabled: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
        },
      ],
      questionId: 34,
    },
    {
      id: 6,
      name: "exam_muscSkelInspJoint",
      type: "options_tree",
      label: "Inspection/palpation of joints, bones and muscles:",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              name: "exam_muscSkelInspJointAbnormalSubDesc",
              value: "",
              type: "text",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
            },
          ],
          isCommentEnabled: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
        },
      ],
      questionId: 35,
    },
    {
      id: 6,
      name: "exam_muscSkelAssessRange",
      type: "options_tree",
      label: "Assessment of range of motion:",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              name: "exam_muscSkelAssessRangeAbnormalSubDesc",
              value: "",
              type: "text",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
            },
          ],
          isCommentEnabled: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
        },
      ],
      questionId: 36,
    },
    {
      id: 6,
      name: "exam_muscSkelAssessStable",
      type: "options_tree",
      label: "Assessment of stability:",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              name: "exam_muscSkelAssessStableAbnormalSubDesc",
              value: "",
              type: "text",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
            },
          ],
          isCommentEnabled: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
        },
      ],
      questionId: 37,
    },
    {
      id: 6,
      name: "exam_muscSkelAssessMuscle",
      type: "options_tree",
      label: "Assessment of Muscle strength/tone:",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              name: "exam_muscSkelAssessMuscleAbnormalSubDesc",
              value: "",
              type: "text",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
            },
          ],
          isCommentEnabled: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
        },
      ],
      questionId: 38,
    },
    {
      id: 9,
      name: "skin",
      type: "heading",
      label: "Skin",
      xs: 12,
      isCommentEnabled: false,
      questionId: 39,
    },
    {
      id: 6,
      name: "exam_skinInspSkin",
      type: "options_tree",
      label: "Inspection of skin and subcutaneous tissue:",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              name: "exam_skinInspSkinAbnormalSubDesc",
              value: "",
              type: "text",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
            },
          ],
          isCommentEnabled: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
        },
      ],
      questionId: 40,
    },
    {
      id: 9,
      name: "neurologic",
      type: "heading",
      label: "Neurologic",
      xs: 12,
      isCommentEnabled: false,
      questionId: 41,
    },
    {
      id: 1,
      name: "exam_neuroCranialTest",
      type: "text",
      label: "Indicate specific cranial nerve tested",
      xs: 12,
      defaultValue: "",
      isCommentEnabled: false,
      questionId: 42,
    },
    {
      id: 1,
      name: "exam_neuroCranialTestIndicate",
      type: "text",
      label: "Indicate cranial nerve deficits found",
      xs: 12,
      defaultValue: "",
      isCommentEnabled: false,
      questionId: 43,
    },
    {
      id: 6,
      name: "exam_neuroRomberg",
      type: "options_tree",
      label: "Romberg Test",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              name: "exam_neuroRombergAbnormalSubDesc",
              value: "",
              type: "text",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
            },
          ],
          isCommentEnabled: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
        },
      ],
      questionId: 44,
    },
    {
      id: 6,
      name: "exam_neuroReflex",
      type: "options_tree",
      label: "Examination of reflexes:",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              name: "exam_neuroReflexAbnormalSubDesc",
              value: "",
              type: "text",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
            },
          ],
          isCommentEnabled: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
        },
      ],
      questionId: 45,
    },
    {
      id: 6,
      name: "exam_neuroSense",
      type: "options_tree",
      label: "Examination of sensation:",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              name: "exam_neuroSenseAbnormalSubDesc",
              value: "",
              type: "text",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
            },
          ],
          isCommentEnabled: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
        },
      ],
      questionId: 46,
    },
    {
      id: 6,
      name: "exam_neuroCoordinate",
      type: "options_tree",
      label: "Coordination:",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              name: "exam_neuroCoordinateAbnormalSubDesc",
              value: "",
              type: "text",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
            },
          ],
          isCommentEnabled: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
        },
      ],
      questionId: 47,
    },
    {
      id: 9,
      name: "diabetes",
      type: "heading",
      label: "Diabetes",
      xs: 12,
      isCommentEnabled: false,
      questionId: 48,
    },
    {
      id: 6,
      name: "exam_diabFoot",
      type: "options_tree",
      label: "Foot Exam",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "exam_diabFootAbnormalSubWhichFoot",
              type: "options_tree",
              label: "Which Foot",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Right Foot",
                  value: "rFoot",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Left Foot",
                  value: "lFoot",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Bi Lateral",
                  value: "bilateral",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 1,
            },
          ],
          isCommentEnabled: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
        },
      ],
      questionId: 49,
    },
    {
      id: 9,
      name: "psychiatric",
      type: "heading",
      label: "Psychiatric",
      xs: 12,
      isCommentEnabled: false,
      questionId: 50,
    },
    {
      id: 6,
      name: "exam_psychJudge",
      type: "options_tree",
      label: "Description of patient's judgement / insight:",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              name: "exam_psychJudgeAbnormalSubDesc",
              value: "",
              type: "text",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
            },
          ],
          isCommentEnabled: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
        },
      ],
      questionId: 51,
    },
    {
      id: 6,
      name: "exam_psychOrient",
      type: "options_tree",
      label: "Orientation of person, place and time:",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              name: "exam_psychOrientAbnormalSubDesc",
              value: "",
              type: "text",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
            },
          ],
          isCommentEnabled: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
        },
      ],
      questionId: 52,
    },
    {
      id: 6,
      name: "exam_psychRemote",
      type: "options_tree",
      label: "Recent and remote memory:",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              name: "exam_psychRemoteAbnormalSubDesc",
              value: "",
              type: "text",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
            },
          ],
          isCommentEnabled: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
        },
      ],
      questionId: 53,
    },
    {
      id: 6,
      name: "exam_psychMood",
      type: "options_tree",
      label: "Mood and affect:",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              name: "exam_psychMoodAbnormalSubDesc",
              value: "",
              type: "text",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
            },
          ],
          isCommentEnabled: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
        },
      ],
      questionId: 54,
    },
  ],
};

const json2024 = {
  sectionId: 13,
  sectionName: "Exam Review",
  isEditable: false,
  questions: [
    {
      id: 9,
      name: "constitutional",
      type: "heading",
      label: "General Appearance",
      xs: 12,
      isCommentEnabled: false,
      questionId: 1,
    },
    {
      id: 6,
      name: "exam_genAppear",
      type: "options_tree",
      label: "General appearance:",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              name: "exam_genAppearAbnormalSubDesc",
              value: "",
              type: "text",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
            },
          ],
          isCommentEnabled: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
        },
      ],
      questionId: 2,
    },
    {
      id: 9,
      name: "head_and_face",
      type: "heading",
      label: "Head and Face",
      xs: 12,
      isCommentEnabled: false,
      questionId: 3,
    },
    {
      id: 6,
      name: "exam_headFaceExam",
      type: "options_tree",
      label: "Examination of head and face:",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              name: "exam_headFaceExamAbnormalSubDesc",
              value: "",
              type: "text",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
            },
          ],
          isCommentEnabled: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
        },
      ],
      questionId: 4,
    },
    {
      id: 6,
      name: "exam_headFacePalp",
      type: "options_tree",
      label: "Palpation of the face and sinuses:",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              name: "exam_headFacePalpAbnormalSubDesc",
              value: "",
              type: "text",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
            },
          ],
          isCommentEnabled: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
        },
      ],
      questionId: 5,
    },
    {
      id: 9,
      name: "eyes",
      type: "heading",
      label: "Eyes",
      xs: 12,
      isCommentEnabled: false,
      questionId: 6,
    },
    {
      id: 6,
      name: "exam_eyesInsp",
      type: "options_tree",
      label: "Inspection of conjunctiva and lids:",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              name: "exam_eyesInspAbnormalSubDesc",
              value: "",
              type: "text",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
            },
          ],
          isCommentEnabled: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
        },
      ],
      questionId: 7,
    },
    {
      id: 6,
      name: "exam_eyesExam",
      type: "options_tree",
      label: "Examination of pupils and irises:",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              name: "exam_eyesExamAbnormalSubDesc",
              value: "",
              type: "text",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
            },
          ],
          isCommentEnabled: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
        },
      ],
      questionId: 8,
    },
    {
      id: 9,
      name: "ears,_nose,_mouth_and_throat",
      type: "heading",
      label: "Ears, Nose, Mouth and Throat",
      xs: 12,
      isCommentEnabled: false,
      questionId: 9,
    },
    {
      id: 6,
      name: "exam_entExtInsp",
      type: "options_tree",
      label: "External Inspection of ears and nose:",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              name: "exam_entExtInspAbnormalSubDesc",
              value: "",
              type: "text",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
            },
          ],
          isCommentEnabled: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
        },
      ],
      questionId: 10,
    },
    {
      id: 6,
      name: "exam_entOtosExam",
      type: "options_tree",
      label: "Otoscopic examination:",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              name: "exam_entOtosExamAbnormalSubDesc",
              value: "",
              type: "text",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
            },
          ],
          isCommentEnabled: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
        },
      ],
      questionId: 11,
    },
    {
      id: 6,
      name: "exam_entAssessHear",
      type: "options_tree",
      label: "Assessment of hearing:",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              name: "exam_entAssessHearAbnormalSubDesc",
              value: "",
              type: "text",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
            },
          ],
          isCommentEnabled: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
        },
      ],
      questionId: 12,
    },
    {
      id: 6,
      name: "exam_entNasalInsp",
      type: "options_tree",
      label: "Inspection of nasal mucosa, septum and trubinates:",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              name: "exam_entNasalInspAbnormalSubDesc",
              value: "",
              type: "text",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
            },
          ],
          isCommentEnabled: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
        },
      ],
      questionId: 13,
    },
    {
      id: 6,
      name: "exam_entInspLips",
      type: "options_tree",
      label: "Inspection of lips, teeth and gums:",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              name: "exam_entInspLipsAbnormalSubDesc",
              value: "",
              type: "text",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
            },
          ],
          isCommentEnabled: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
        },
      ],
      questionId: 14,
    },
    {
      id: 6,
      name: "exam_entOropExam",
      type: "options_tree",
      label: "Examination of oropharynx:",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              name: "exam_entOropExamAbnormalSubDesc",
              value: "",
              type: "text",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
            },
          ],
          isCommentEnabled: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
        },
      ],
      questionId: 15,
    },
    {
      id: 9,
      name: "neck",
      type: "heading",
      label: "Neck",
      xs: 12,
      isCommentEnabled: false,
      questionId: 16,
    },
    {
      id: 6,
      name: "exam_neckExam",
      type: "options_tree",
      label: "Examination of neck:",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              name: "exam_neckExamAbnormalSubDesc",
              value: "",
              type: "text",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
            },
          ],
          isCommentEnabled: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
        },
      ],
      questionId: 17,
    },
    {
      id: 6,
      name: "exam_neckExamThyroid",
      type: "options_tree",
      label: "Examination of thyroid:",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              name: "exam_neckExamThyroidAbnormalSubDesc",
              value: "",
              type: "text",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
            },
          ],
          isCommentEnabled: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
        },
      ],
      questionId: 18,
    },
    {
      id: 9,
      name: "pulmonary",
      type: "heading",
      label: "Pulmonary",
      xs: 12,
      isCommentEnabled: false,
      questionId: 19,
    },
    {
      id: 6,
      name: "exam_pulmAssessResp",
      type: "options_tree",
      label: "Assessment of respiratory effort:",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              name: "exam_pulmAssessRespAbnormalSubDesc",
              value: "",
              type: "text",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
            },
          ],
          isCommentEnabled: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
        },
      ],
      questionId: 20,
    },
    {
      id: 6,
      name: "exam_pulmAuscLungs",
      type: "options_tree",
      label: "Auscultation of lungs:",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              name: "exam_pulmAuscLungsAbnormalSubDesc",
              value: "",
              type: "text",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
            },
          ],
          isCommentEnabled: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
        },
      ],
      questionId: 21,
    },
    {
      id: 9,
      name: "cardiovascular",
      type: "heading",
      label: "Cardiovascular",
      xs: 12,
      isCommentEnabled: false,
      questionId: 22,
    },
    {
      id: 6,
      name: "exam_cardioAusHeart",
      type: "options_tree",
      label: "Auscultation of heart:",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              name: "exam_cardioAusHeartAbnormalSubDesc",
              value: "",
              type: "text",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
            },
          ],
          isCommentEnabled: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
        },
      ],
      questionId: 23,
    },
    {
      id: 6,
      name: "exam_cardioPalpAus",
      type: "options_tree",
      label: "Palpation and auscultation of Carotid Arteries:",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              name: "exam_cardioPalpAusAbnormalSubDesc",
              value: "",
              type: "text",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
            },
          ],
          isCommentEnabled: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
        },
      ],
      questionId: 24,
    },
    {
      id: 6,
      name: "exam_cardioPedalPulse",
      type: "options_tree",
      label: "Pedal Pulses:",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              name: "exam_cardioPedalPulseAbnormalSubDesc",
              value: "",
              type: "text",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
            },
          ],
          isCommentEnabled: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
        },
      ],
      questionId: 25,
    },
    {
      id: 6,
      name: "exam_cardioExamEdema",
      type: "options_tree",
      label: "Examination of Edema / Varicosities:",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              name: "exam_cardioExamEdemaAbnormalSubDesc",
              value: "",
              type: "text",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
            },
          ],
          isCommentEnabled: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
        },
      ],
      questionId: 26,
    },
    {
      id: 6,
      name: "exam_cardioExamRadial",
      type: "options_tree",
      label: "Examination of Radial Pulses:",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              name: "exam_cardioExamRadialAbnormalSubDesc",
              value: "",
              type: "text",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
            },
          ],
          isCommentEnabled: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
        },
      ],
      questionId: 27,
    },
    {
      id: 9,
      name: "lymphatic",
      type: "heading",
      label: "Lymphatic",
      xs: 12,
      isCommentEnabled: false,
      questionId: 28,
    },
    {
      id: 6,
      name: "exam_lympPalpCervi",
      type: "options_tree",
      label: "Palpation of cervical nodes (neck)",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              name: "exam_lympPalpCerviAbnormalSubDesc",
              value: "",
              type: "text",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
            },
          ],
          isCommentEnabled: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
        },
      ],
      questionId: 29,
    },
    {
      id: 6,
      name: "exam_lympPalpPreauric",
      type: "options_tree",
      label: "Palpation of preauricular nodes (in front of the ears)",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              name: "exam_lympPalpPreauricAbnormalSubDesc",
              value: "",
              type: "text",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
            },
          ],
          isCommentEnabled: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
        },
      ],
      questionId: 30,
    },
    {
      id: 6,
      name: "exam_lympPalpSubmandi",
      type: "options_tree",
      label: "Palpation of Submandibular nodes (under jaw line/chin)",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              name: "exam_lympPalpSubmandiAbnormalSubDesc",
              value: "",
              type: "text",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
            },
          ],
          isCommentEnabled: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
        },
      ],
      questionId: 31,
    },
    {
      id: 9,
      name: "musculoskeletal",
      type: "heading",
      label: "MUSCULOSKELETAL",
      xs: 12,
      isCommentEnabled: false,
      questionId: 32,
    },
    {
      id: 6,
      name: "exam_muscSkelExamGait",
      type: "options_tree",
      label: "Examination of gait and station:",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              name: "exam_muscSkelExamGaitAbnormalSubDesc",
              value: "",
              type: "text",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
            },
          ],
          isCommentEnabled: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
        },
      ],
      questionId: 33,
    },
    {
      id: 6,
      name: "exam_muscSkelInspDigit",
      type: "options_tree",
      label: "Inspection/palpation of digits and nails:",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              name: "exam_muscSkelInspDigitAbnormalSubDesc",
              value: "",
              type: "text",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
            },
          ],
          isCommentEnabled: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
        },
      ],
      questionId: 34,
    },
    {
      id: 6,
      name: "exam_muscSkelInspJoint",
      type: "options_tree",
      label: "Inspection/palpation of joints, bones and muscles:",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              name: "exam_muscSkelInspJointAbnormalSubDesc",
              value: "",
              type: "text",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
            },
          ],
          isCommentEnabled: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
        },
      ],
      questionId: 35,
    },
    {
      id: 6,
      name: "exam_muscSkelAssessRange",
      type: "options_tree",
      label: "Assessment of range of motion:",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              name: "exam_muscSkelAssessRangeAbnormalSubDesc",
              value: "",
              type: "text",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
            },
          ],
          isCommentEnabled: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
        },
      ],
      questionId: 36,
    },
    {
      id: 6,
      name: "exam_muscSkelAssessStable",
      type: "options_tree",
      label: "Assessment of stability:",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              name: "exam_muscSkelAssessStableAbnormalSubDesc",
              value: "",
              type: "text",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
            },
          ],
          isCommentEnabled: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
        },
      ],
      questionId: 37,
    },
    {
      id: 6,
      name: "exam_muscSkelAssessMuscle",
      type: "options_tree",
      label: "Assessment of Muscle strength/tone:",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              name: "exam_muscSkelAssessMuscleAbnormalSubDesc",
              value: "",
              type: "text",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
            },
          ],
          isCommentEnabled: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
        },
      ],
      questionId: 38,
    },
    {
      id: 9,
      name: "skin",
      type: "heading",
      label: "Skin",
      xs: 12,
      isCommentEnabled: false,
      questionId: 39,
    },
    {
      id: 6,
      name: "exam_skinInspSkin",
      type: "options_tree",
      label: "Inspection of skin and subcutaneous tissue:",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              name: "exam_skinInspSkinAbnormalSubDesc",
              value: "",
              type: "text",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
            },
          ],
          isCommentEnabled: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
        },
      ],
      questionId: 40,
    },
    {
      id: 9,
      name: "neurologic",
      type: "heading",
      label: "Neurologic",
      xs: 12,
      isCommentEnabled: false,
      questionId: 41,
    },
    {
      id: 6,
      name: "exam_noGpcogMmsePatientOrientPerson",
      type: "options_tree",
      label: "Patient oriented to person?",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              name: "exam_noGpcogMmsePatientOrientPersonNoSubDesc",
              value: "",
              type: "text",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
            },
          ],
          isCommentEnabled: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 42,
    },
    {
      id: 6,
      name: "exam_noGpcogMmsePatientOrientPlace",
      type: "options_tree",
      label: "Patient oriented to place?",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              name: "exam_noGpcogMmsePatientOrientPlaceNoSubDesc",
              value: "",
              type: "text",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
            },
          ],
          isCommentEnabled: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 43,
    },
    {
      id: 6,
      name: "exam_noGpcogMmsePatientOrientTime",
      type: "options_tree",
      label: "Patient oriented to time?",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              name: "exam_noGpcogMmsePatientOrientTimeNoSubDesc",
              value: "",
              type: "text",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
            },
          ],
          isCommentEnabled: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 44,
    },
    {
      id: 6,
      name: "exam_noGpcogMmseRecall",
      type: "options_tree",
      label: "Recall",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Good",
          value: "good",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              name: "exam_noGpcogMmseRecallPoorSubDesc",
              value: "",
              type: "text",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
            },
          ],
          isCommentEnabled: false,
          label: "Poor",
          value: "poor",
          type: "toggle_button",
        },
      ],
      questionId: 45,
    },
    {
      id: 6,
      name: "exam_noGpcogMmsePatientDescribeNews",
      type: "options_tree",
      label: "Patient describes recent news event",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Partially",
          value: "partially",
          type: "toggle_button",
        },
        {
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 46,
    },
    {
      id: 6,
      name: "exam_psychMood",
      type: "options_tree",
      label: "Affect",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              label: "If abnormal,",
              value: "exam_psychMoodSub",
              type: "options_tree",
              xs: 12,
              subQuestions: [
                {
                  subQuestionId: 1,
                  label: "Paranoia",
                  type: "toggle_button",
                  value: "paranoia",
                  hasSubQuestions: false,
                  subQuestions: [{}],
                },
                {
                  subQuestionId: 2,
                  label: "Delusional",
                  type: "toggle_button",
                  value: "delusional",
                  hasSubQuestions: false,
                  subQuestions: [{}],
                },
                {
                  subQuestionId: 3,
                  label: "Disorganised Thought",
                  type: "toggle_button",
                  value: "disorganizedThought",
                  hasSubQuestions: false,
                  subQuestions: [{}],
                },
                {
                  subQuestionId: 4,
                  label: "Flat",
                  type: "toggle_button",
                  value: "flat",
                  hasSubQuestions: false,
                  subQuestions: [{}],
                },
                {
                  subQuestionId: 5,
                  label: "Manic",
                  type: "toggle_button",
                  value: "manic",
                  hasSubQuestions: false,
                  subQuestions: [{}],
                },
                {
                  subQuestionId: 6,
                  label: "Depressed",
                  type: "toggle_button",
                  value: "depressed",
                  hasSubQuestions: false,
                  subQuestions: [{}],
                },
                {
                  subQuestionId: 7,
                  label: "Other",
                  type: "toggle_button",
                  value: "other",
                  hasSubQuestions: false,
                  subQuestions: [{}],
                },
              ],
              isCommentEnabled: true,
              name: "exam_psychMoodSub",
            },
          ],
          isCommentEnabled: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
        },
      ],
      questionId: 47,
    },
    {
      id: 6,
      name: "ros_speech",
      type: "options_tree",
      label: "Speech",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              label: "If abnormal,",
              value: "ros_speechSub",
              type: "options_tree",
              xs: 12,
              subQuestions: [
                {
                  subQuestionId: 1,
                  label: "Slurred",
                  type: "toggle_button",
                  value: "slurred",
                  hasSubQuestions: false,
                  subQuestions: [{}],
                },
                {
                  subQuestionId: 2,
                  label: "Aphasic",
                  type: "toggle_button",
                  value: "aphasic",
                  hasSubQuestions: false,
                  subQuestions: [{}],
                },
                {
                  subQuestionId: 3,
                  label: "Apraxia",
                  type: "toggle_button",
                  value: "apraxia",
                  hasSubQuestions: false,
                  subQuestions: [{}],
                },
              ],
              isCommentEnabled: true,
              name: "ros_speechSub",
            },
          ],
          isCommentEnabled: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
        },
      ],
      questionId: 48,
    },
    {
      id: 6,
      name: "ros_fingerToNose",
      type: "options_tree",
      label: "Finger to Nose",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "ros_fingerToNoseAbnormalSub",
              type: "options_tree",
              label: "If Abnormal",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Left",
                  value: "left",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Right",
                  value: "right",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Both",
                  value: "both",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 1,
            },
          ],
          isCommentEnabled: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
        },
      ],
      questionId: 49,
    },
    {
      id: 6,
      name: "ros_heelToToe",
      type: "options_tree",
      label: "Heel (Shin) to Toe",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "ros_heelToToeAbnormalSub",
              type: "options_tree",
              label: "If abnormal",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Left",
                  value: "left",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Right",
                  value: "right",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Both",
                  value: "both",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 1,
            },
          ],
          isCommentEnabled: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
        },
      ],
      questionId: 50,
    },
    {
      id: 6,
      name: "ros_thumbToFinger",
      type: "options_tree",
      label: "Thumb to Finger Tips",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "ros_thumbToFingerAbnormalSub",
              type: "options_tree",
              label: "If Abnormal  ",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Left",
                  value: "left",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Right",
                  value: "right",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Both",
                  value: "both",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 1,
            },
          ],
          isCommentEnabled: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
        },
      ],
      questionId: 51,
    },
    {
      id: 6,
      name: "ros_sitToStand",
      type: "select",
      label: "Sitting to Standing",
      xs: 12,
      isCommentEnabled: true,
      options: [
        ["Needs Assistance", "1"],
        ["Normal", "2"],
        ["Unable", "3"],
      ],
      questionId: 52,
    },
    {
      id: 6,
      name: "ros_extremeMove",
      type: "select",
      label: "Facial /Extremity Movement",
      xs: 12,
      isCommentEnabled: true,
      options: [
        ["Benign (Essential Tremor)", "1"],
        ["Chorea Movement", "2"],
        ["Cog wheeling", "3"],
        ["Intention Tremor", "4"],
        ["Motor Tic", "5"],
        ["Non-Intention (Pill rolling) Tremor", "6"],
        ["Normal", "7"],
        ["Rigidity", "8"],
        ["Spasticity", "9"],
        ["Vocal Tic", "10"],
      ],
      questionId: 53,
    },
    {
      id: 1,
      name: "exam_neuroCranialTest",
      type: "text",
      label: "Indicate specific cranial nerve tested",
      xs: 12,
      defaultValue: "",
      isCommentEnabled: false,
      questionId: 54,
    },
    {
      id: 1,
      name: "exam_neuroCranialTestIndicate",
      type: "text",
      label: "Indicate cranial nerve deficits found",
      xs: 12,
      defaultValue: "",
      isCommentEnabled: false,
      questionId: 55,
    },
    {
      id: 6,
      name: "exam_neuroRomberg",
      type: "options_tree",
      label: "Romberg Test",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              name: "exam_neuroRombergAbnormalSubDesc",
              value: "",
              type: "text",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
            },
          ],
          isCommentEnabled: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
        },
      ],
      questionId: 56,
    },
    {
      id: 6,
      name: "exam_neuroReflex",
      type: "options_tree",
      label: "Examination of reflexes:",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              name: "exam_neuroReflexAbnormalSubDesc",
              value: "",
              type: "text",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
            },
          ],
          isCommentEnabled: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
        },
      ],
      questionId: 57,
    },
    {
      id: 6,
      name: "exam_neuroSense",
      type: "options_tree",
      label: "Examination of sensation:",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              name: "exam_neuroSenseAbnormalSubDesc",
              value: "",
              type: "text",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
            },
          ],
          isCommentEnabled: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
        },
      ],
      questionId: 58,
    },
    {
      id: 6,
      name: "exam_neuroCoordinate",
      type: "options_tree",
      label: "Coordination:",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              name: "exam_neuroCoordinateAbnormalSubDesc",
              value: "",
              type: "text",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
            },
          ],
          isCommentEnabled: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
        },
      ],
      questionId: 59,
    },
    {
      id: 9,
      name: "diabetes",
      type: "heading",
      label: "Diabetec Foot Examination",
      xs: 12,
      isCommentEnabled: false,
      questionId: 60,
    },
    {
      id: 6,
      name: "exam_diabFoot",
      type: "options_tree",
      label: "Diabetec Foot Examination",
      xs: 9,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "NA",
          value: "na",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          type: "toggle_button",
        },
        {
          subQuestionId: 3,
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              name: "exam_diabetecFootExamAbnormalSubDesc",
              value: "",
              type: "text",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
            },
          ],
          isCommentEnabled: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
        },
      ],
      questionId: 61,
    },
    {
      id: 12,
      name: "image",
      label: "Image",
      placeholder: "",
      editToggle: false,
      defaultValue: "",
      type: "image",
      src: "",
      alt: "",
      xs: 3,
      required: "no",
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 19,
    },
    {
      id: 9,
      name: "psychiatric",
      type: "heading",
      label: "Psychiatric",
      xs: 12,
      isCommentEnabled: false,
      questionId: 62,
    },
    {
      id: 6,
      name: "exam_psychJudge",
      type: "options_tree",
      label: "Description of patient's judgement / insight, mood:",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              name: "exam_psychJudgeAbnormalSubDesc",
              value: "",
              type: "text",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
            },
          ],
          isCommentEnabled: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
        },
      ],
      questionId: 63,
    },
  ],
};

export const getInputsData = (client = "VPHP", year = "2023") => {
  let formLayoutData = {};
  if (year === "2023") {
    formLayoutData = {
      ...formLayoutData,
      ...json2023,
    };
  }
  if (year === "2024") {
    formLayoutData = {
      ...formLayoutData,
      ...json2024,
    };
  }

  return formLayoutData;
};
