import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Typography,
  Box,
  Divider,
  Grid,
  Tooltip,
  Fade,
  TextField,
} from "@mui/material";
import Question from "./question";
import { postApi, getApi } from "../../utils/axiosApiWrapper";
import { Checkbox, FormControlLabel } from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import MuiInput from "../muiinput/MuiInput";
import { Radio, RadioGroup } from "@mui/material";
import dayjs from "dayjs";
import { useUserContext } from "../../contexts/UserContext";
const customStyles = {
  root: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#01695f",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#01695f",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#01695f",
    },
  },
};

export const ScreeningQuestion = ({ open, setOpen }) => {
  //const [open, setOpen] = useState(false);
  const [checkedItems5thQues, setCheckedItems5thQues] = useState({});
  const [checkedItems8thQues, setCheckedItems8thQues] = useState({}); //
  const [answer1stQuestion, setAnswer1stQuestion] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedYesandAgree, setSelectedYesandAgree] = useState("");
  const [thirdQuestionAns, setThirdQuestionAns] = useState();
  const [fourthQuestionAns, setFourthQuestionAns] = useState();
  const [sixthQuestionAns, setSixthQuestionAns] = useState();
  const [clinicianName, setClinicianName] = useState("");
  const [validated, setValidated] = useState(false);
  const [answeredQuestion, setAnsweredQuestion] = useState([...Question]);

  const { selectedTimeZoneUtc } = useUserContext();
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e, answeringQues) => {
    const updatedQuestionWithAnswer = answeredQuestion?.map((ques) => {
      if (ques?.questionId == answeringQues.questionId) {
        if (ques?.type == "input") {
          ques.options[0].text = e.target.value;
          ques.options[0].selected = true;
          ques.answered = true;
          return ques;
        } else if (ques.type == "radio") {
          ques.options = ques.options.map((opt) => {
            if (e.target.value == opt.text) {
              return {
                text: opt.text,
                optionId: opt.optionId,
                selected: true,
              };
            } else {
              return opt;
            }
          });
          const optionSelected = ques?.options?.filter((opt) => opt?.selected);
          if (optionSelected?.length > 0) {
            ques.answered = true;
          } else {
            ques.answered = false;
          }
          return ques;
        } else if (ques.type == "checkbox") {
          ques.options = ques.options.map((opt) => {
            if (e.target.value == opt.text) {
              return {
                text: opt.text,
                optionId: opt.optionId,
                selected: e.target.checked,
              };
            } else {
              return opt;
            }
          });
          const optionSelected = ques?.options?.filter((opt) => opt?.selected);
          if (optionSelected?.length > 0) {
            ques.answered = true;
          } else {
            ques.answered = false;
          }
          return ques;
        }
      } else {
        return ques;
      }
    });
    setAnsweredQuestion([...updatedQuestionWithAnswer]);
  };
  const handleDateChange = (date, answeringQues) => {
    const updatedQuestionWithAnswer = answeredQuestion?.map((ques) => {
      if (ques?.questionId == answeringQues?.questionId) {
        if (ques?.type == "date") {
          ques.options[0].text = date; //e.target.value;
          ques.options[0].selected = true;
          ques.answered = true;
          return ques;
        }
      } else {
        return ques;
      }
    });
    setAnsweredQuestion([...updatedQuestionWithAnswer]);
  };
  useEffect(() => {
    const unAnsweredQues = answeredQuestion?.filter((ques) => {
      return ques?.answered == false || ques?.answered == undefined;
    });
    if (unAnsweredQues?.length > 0) {
      setValidated(false);
    }
    if (unAnsweredQues?.length == 0) {
      setValidated(true);
    }
  }, [answeredQuestion]);
  const onSubmit = async () => {
    const answeredJson = answeredQuestion?.map((ansQ) => {
      return {
        questionId: ansQ.questionId,
        options: ansQ?.options
          ?.filter((opt) => opt?.selected == true)
          ?.map((opt) => {
            return {
              value: opt.text,
              optionId: opt.optionId,
            };
          }),
      };
    });
    const d = new Date();
    let year = d.getFullYear();
    const response = await postApi("/covidForm/covidForm", {
      covidFormAns: { answeredJson: answeredJson },
      year: year,
    });
    if (response.status == 200) {
      sessionStorage.setItem("covidFlag", 0);
    }
  };

  const [clientName, setClientName] = useState([]);
  const userClients = JSON.parse(sessionStorage.getItem("userClient"));

  useEffect(() => {
    const userClientId = userClients?.map((itm) => itm?.plan);
    const fetchClientData = async () => {
      try {
        const response = await getApi("/clients/list");
        if (response.status === 200) {
          const clientList = response.data.data;
          const filterClient = clientList?.filter((client) =>
            userClientId.includes(client?._id),
          );
          const clientName = [];
          filterClient?.forEach((itm, idx) => {
            //setClientName([...clientName, itm.plan]);  string.charAt(0).toUpperCase() + string.slice(1)
            clientName.push({
              text:
                itm?.plan == "vphp"
                  ? itm?.plan.toUpperCase()
                  : itm?.plan.charAt(0).toUpperCase() + itm?.plan.slice(1),
              optionId: idx,
            });
          });
          // Question?.forEach((ques) => {
          //   if (ques?.questionId == "C-ilxqp") {
          //     ques.options = clientName;
          //     console.log("first:", clientName)
          //   }
          // });
          // setAnsweredQuestion(Question);
        }
      } catch (error) {
        return;
      }
    };

    fetchClientData();
  }, []);
  return (
    <div>
      <Dialog onClose={() => setOpen(false)} open={open} maxWidth={800}>
        <DialogContent>
          <Box
            style={{
              backgroundColor: "#01695f",
              width: "100%",
              //   position: "fixed",
              //   zIndex: 1000,
            }}
          >
            <Box display="flex" justifyContent="center">
              <Typography color="whitesmoke" variant="h5" fontWeight="bolder">
                COVID-19 Screening Questions
              </Typography>
            </Box>
            <Divider
              orientation="horizontal"
              style={{
                width: "100%",
                color: "whitesmoke",
                backgroundColor: "whitesmoke",
              }}
            />
            <Box padding={1} display="flex" justifyContent="center">
              <Typography color="whitesmoke" variant="body1">
                Providers will Complete the following COVID Screening
                Questionnaire daily before proceeding with their first in-home
                assessment.
                <br />
                If yes to any of the questions OR temperature is over 100.4
                contact supervisor and do not conduct any in-person visits.
              </Typography>
            </Box>
          </Box>
          <Box
            id="content"
            padding={1}
            style={{
              maxHeight: "60vh",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            {answeredQuestion?.map((question, idx) => (
              <Box>
                <Typography variant="body1" color="#01695f">
                  {idx + 1}.{question?.question}
                  <span style={{ color: "red" }}>*</span>
                </Typography>
                <Fade in={question?.type == "input"} unmountOnExit>
                  <Box paddingTop={1} width={0.4} display="flex">
                    <TextField
                      sx={{
                        height: "48px",
                        border: "1.5px solid var(--mainTheme) !important",
                        borderRadius: "5px",
                        "& .MuiFormLabel-root": {
                          backgroundColor: "white",
                        },
                        "& .MuiInputLabel-root": {
                          backgroundColor: "white",
                        },
                      }}
                      variant="outlined"
                      label="Enter Your Answer"
                      type="input"
                      //className="inputAddMembers"
                      value={question?.options[0]?.text}
                      onChange={(event) => handleChange(event, question)}
                      // InputProps={{
                      //   classes: {
                      //     root: customStyles.root,
                      //   },
                      // }}
                      // required={true}
                      //style={{ width: "50vw" }}
                    />
                  </Box>
                </Fade>
                <Fade in={question?.type == "radio"} unmountOnExit>
                  <RadioGroup
                    aria-label="Yes or Agree"
                    name="yesOrAgree"
                    value={
                      question?.options?.filter((opt) => opt.selected)[0]?.text
                    }
                    onChange={(event) => handleChange(event, question)}
                  >
                    <Box
                      width={0.4}
                      display="flex"
                      justifyContent="space-between"
                    >
                      {question?.options?.map((option) => (
                        <FormControlLabel
                          value={option.text}
                          control={<Radio />}
                          label={
                            <Typography variant="body2" color="#01695f">
                              {option.text}
                            </Typography>
                          }
                        />
                      ))}
                    </Box>
                  </RadioGroup>
                </Fade>
                <Fade in={question?.type == "checkbox"} unmountOnExit>
                  <Box>
                    <Grid container spacing={1}>
                      {question?.options
                        ?.filter((option) => option.text !== "None")
                        .sort((a, b) => a.text.localeCompare(b.text))
                        .concat(
                          question.options.filter(
                            (option) => option.text === "None",
                          ),
                        )
                        .map((itm, index) => (
                          <Grid item xs={4} key={index}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  value={itm.text}
                                  checked={itm?.selected ?? false}
                                  onChange={(event) =>
                                    handleChange(event, question)
                                  }
                                />
                              }
                              label={
                                <Typography variant="body2" color="#01695f">
                                  {itm.text}
                                </Typography>
                              }
                            />
                          </Grid>
                        ))}
                    </Grid>
                  </Box>
                </Fade>
                <Fade in={question?.type == "date"} unmountOnExit>
                  <Box>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DatePicker"]}>
                        <DateTimePicker
                          variant="outlined"
                          onChange={(date) => {
                            handleDateChange(date, question);
                            setSelectedDate(date);
                          }}
                          value={dayjs(
                            dayjs(selectedDate)
                              ?.tz(selectedTimeZoneUtc)
                              .format("MM-DD-YYYY hh:mm:ss A"),
                          )}
                          label=""
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </Box>
                </Fade>
                <Divider
                  orientation="horizontal"
                  style={{
                    marginBlock: "10px",
                    // width: "100vw",
                    backgroundColor: "#01695f",
                  }}
                />
              </Box>
            ))}
            <Box display="flex" justifyContent="center" alignItems="center">
              {validated ? (
                <Button
                  onClick={() => {
                    onSubmit();
                    handleClose();
                  }}
                  style={{ backgroundColor: "#01695f", color: "whitesmoke" }}
                  disabled={!validated}
                  variant="contained"
                >
                  Submit
                </Button>
              ) : (
                <Tooltip placement="top" title="Answer All Question" arrow>
                  <span>
                    <Button
                      style={{
                        backgroundColor: "#01695f",
                        color: "whitesmoke",
                      }}
                      // onClick={handleClose}
                      disabled={!validated}
                      variant="contained"
                    >
                      Submit
                    </Button>
                  </span>
                </Tooltip>
              )}
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ScreeningQuestion;
