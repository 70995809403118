import {
  Grid,
  Stack,
  InputLabel,
  TextField,
  Typography,
  Box,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  MenuItem,
  Select,
  Checkbox,
  FormGroup,
} from "@mui/material";
import lodash from "lodash";
import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useCallback,
} from "react";
import dayjs from "dayjs";
import focusCaresLoader from "../../../../../loader/focuscares_loader.gif";
import LoadingOverlay from "react-loading-overlay";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import usePedsForm from "../peds-form-hooks";
import { putApi, getApi } from "../../../../../utils/axiosApiWrapper";
import Labels from "../../../../../resource_files/Labels.resx";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";
const PedsDemographicsComp = forwardRef((props, ref) => {
  const { assessmentId } = props.assessmentId;
  const location = useLocation();
  const tabId = location?.state?.tabId
    ? location?.state?.tabId
    : sessionStorage.getItem("tabId");
  const roleId = sessionStorage.getItem("roleId");
  console.log(roleId, "roleId");
  console.log(tabId, "tabId");
  //const {setAutoSavePedsDemographicsData} = props?.setAutoSavePedsDemographicsData;
  const { demographicsJsonObj, storedYear } = usePedsForm(assessmentId);
  const [demographicsFormState, setDemographicsFormState] = useState();
  const [fieldError, setFieldError] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const handleTextBoxChange = (event, question) => {
    // setDemographicsFormState({
    //   ...demographicsFormState,
    //   [question.questionField]: event.target.value,
    // });
    const mobregex = /[^\d-]$/; //new RegExp(/^([+]\d{2})?\d{10}$/);
    const emailRegex = new RegExp(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
    if (
      [
        "alternateNo",
        "gPhoneNumber",
        "gCellNumber",
        "pPhoneNumber",
        "ePhoneNumber",
        // "emergencyContact",
        "phoneNumber",
        "cellNumber",
        "alternateContactNumber",
      ].includes(question?.questionField)
    ) {
      const tempVal = event?.target?.value?.replace(mobregex, "");
      setDemographicsFormState({
        ...demographicsFormState,
        [question.questionField]: tempVal, //event.target.value,
      });
      if (tempVal?.length < 10 && tempVal?.length > 0) {
        setFieldError({
          ...fieldError,
          [question.questionField]: "Minimum length is 10 chars",
        });
      } else {
        setFieldError({ ...fieldError, [question.questionField]: "" });
      }
    } else if (["gEmail", "email"].includes(question?.questionField)) {
      setDemographicsFormState({
        ...demographicsFormState,
        [question.questionField]: event.target.value, //event.target.value,
      });
      if (
        !emailRegex.test(event.target.value) &&
        event.target.value?.length > 0
      ) {
        setFieldError({
          ...fieldError,
          [question.questionField]: "Enter Valid Email",
        });
      } else {
        setFieldError({ ...fieldError, [question.questionField]: "" });
      }
    } else if (["mZip", "zip", "pcpZip"].includes(question?.questionField)) {
      const keepRegex = /[^\d-]$/;
      const tempVal = event?.target?.value?.replace(keepRegex, "");
      setDemographicsFormState({
        ...demographicsFormState,
        [question.questionField]: tempVal, //event.target.value,
      });
      if (tempVal?.length < 5 && tempVal?.length > 0) {
        setFieldError({
          ...fieldError,
          [question.questionField]: "Minimum length is 5 chars",
        });
      } else {
        setFieldError({ ...fieldError, [question.questionField]: "" });
      }
    } else {
      setDemographicsFormState({
        ...demographicsFormState,
        [question.questionField]: event.target.value, //event.target.value,
      });
    }
  };

  const handlePaste = useCallback((e, name, value) => {
    if (
      name === "gPhoneNumber" ||
      name === "gCellNumber" ||
      name === "pPhoneNumber" ||
      name === "ePhoneNumber" ||
      name === "phoneNumber" ||
      name === "cellNumber" ||
      name === "alternateContactNumber" ||
      name === "emergencyContact" ||
      name === "faxNumber"
    ) {
      e.preventDefault();
      let pasteData = value;
      // Remove all spaces and keep only digits and hyphens
      let cleanedData = pasteData.replace(/[^0-9-]/g, "");
      // Limit the length to 12 characters
      cleanedData = cleanedData.slice(0, 12);
      // Set the cleaned data to the input field
      setDemographicsFormState((prevData) => {
        return {
          ...prevData,
          [name]: cleanedData,
        };
      });
    }
  }, []);

  const handleDateChange = (question, value) => {
    setDemographicsFormState({
      ...demographicsFormState,
      [question.questionField]: dayjs(value), //_event.target.value,
    });
    const age = calculateAge(dayjs(value));
    setDemographicsFormState({ ...demographicsFormState, age: age });
  };
  const handleSelectChange = (_event, question) => {
    setDemographicsFormState({
      ...demographicsFormState,
      [question.questionField]: _event?.target?.value + "",
    });
  };

  const onSaveData = async () => {
    const payload = {
      plan: demographicsFormState.plan ? demographicsFormState.plan : "",
      program: demographicsFormState.program
        ? demographicsFormState.program
        : "",
      lob: demographicsFormState.lob ? demographicsFormState.lob : "",
      region: demographicsFormState.region ? demographicsFormState.region : "",
      aligned: demographicsFormState.aligned
        ? demographicsFormState.aligned
        : "",
      name: demographicsFormState.name ? demographicsFormState.name : "",
      gender: demographicsFormState.gender ? demographicsFormState.gender : "",
      addressOne: demographicsFormState.addressOne
        ? demographicsFormState.addressOne
        : "",
      addressTwo: demographicsFormState.addressTwo
        ? demographicsFormState.addressTwo
        : "",
      addressThree: demographicsFormState.addressThree
        ? demographicsFormState.addressThree
        : "",
      city: demographicsFormState.city ? demographicsFormState.city : "",
      state: demographicsFormState.state ? demographicsFormState.state : "",
      zip: demographicsFormState.zip ? demographicsFormState.zip : "",
      mAddressOne: demographicsFormState.mAddressOne
        ? demographicsFormState.mAddressOne
        : "",
      mAddressTwo: demographicsFormState.mAddressTwo
        ? demographicsFormState.mAddressTwo
        : "",
      mAddressThree: demographicsFormState.mAddressThree
        ? demographicsFormState.mAddressThree
        : "",
      mCity: demographicsFormState.mCity ? demographicsFormState.mCity : "",
      mState: demographicsFormState.mState ? demographicsFormState.mState : "",
      mZip: demographicsFormState.mZip ? demographicsFormState.mZip : "",
      dob: demographicsFormState.dob ? demographicsFormState.dob : "",
      age: demographicsFormState.age ? demographicsFormState.age : "",
      maritalStatus: demographicsFormState.maritalStatus
        ? demographicsFormState.maritalStatus
        : "",
      memberIdentificationNo: demographicsFormState.memberIdentificationNo
        ? demographicsFormState.memberIdentificationNo
        : "",

      hicn: demographicsFormState.hicn ? demographicsFormState.hicn : "",
      email: demographicsFormState.email ? demographicsFormState.email : "",
      phoneNumber: demographicsFormState.phoneNumber
        ? demographicsFormState.phoneNumber
        : "",
      cellNumber: demographicsFormState.cellNumber
        ? demographicsFormState.cellNumber
        : "",
      alternateContactNumber: demographicsFormState.alternateContactNumber
        ? demographicsFormState.alternateContactNumber
        : "",
      emergencyContact: demographicsFormState.emergencyContact
        ? demographicsFormState.emergencyContact
        : "",
      ePhoneNumber: demographicsFormState.ePhoneNumber
        ? demographicsFormState.ePhoneNumber
        : "",
      primaryCarePhysician: demographicsFormState.primaryCarePhysician
        ? demographicsFormState.primaryCarePhysician
        : "",
      pPhoneNumber: demographicsFormState.pPhoneNumber
        ? demographicsFormState.pPhoneNumber
        : "",
      guardianName: demographicsFormState.guardianName
        ? demographicsFormState.guardianName
        : "",
      relationshipToChild: demographicsFormState.relationshipToChild
        ? demographicsFormState.relationshipToChild
        : "",
      gPhoneNumber: demographicsFormState.gPhoneNumber
        ? demographicsFormState.gPhoneNumber
        : "",
      gCellNumber: demographicsFormState.gCellNumber
        ? demographicsFormState.gCellNumber
        : "",
      gEmail: demographicsFormState.gEmail ? demographicsFormState.gEmail : "",
      pcpAddress: demographicsFormState.pcpAddress
        ? demographicsFormState.pcpAddress
        : "",
      pcpCity: demographicsFormState.pcpCity
        ? demographicsFormState.pcpCity
        : "",
      pcpState: demographicsFormState.pcpState
        ? demographicsFormState.pcpState
        : "",
      pcpZip: demographicsFormState.pcpZip ? demographicsFormState.pcpZip : "",
      pcpCountry: demographicsFormState.pcpCountry
        ? demographicsFormState.pcpCountry
        : "",
      faxNumber: demographicsFormState.faxNumber
        ? demographicsFormState.faxNumber
        : "",
      pcpNpi: demographicsFormState.pcpNpi ? demographicsFormState.pcpNpi : "",
      officeId: demographicsFormState.officeId
        ? demographicsFormState.officeId
        : "",
      officeName: demographicsFormState.officeName
        ? demographicsFormState.officeName
        : "",
    };
    let payload2024 = {};
    if (storedYear == "2024") {
      payload2024 = {
        ...payload,

        currentGender: {
          gender: demographicsFormState.currentGenderIdentity
            ? demographicsFormState.currentGenderIdentity
            : "", //"",
          comment: demographicsFormState.currentGenderComment
            ? demographicsFormState.currentGenderComment
            : "",
        }, //physicianComment
        uniqueIdentificationNo: demographicsFormState.uniqueIdentificationNo
          ? demographicsFormState.uniqueIdentificationNo
          : "",
        physicianComment: demographicsFormState.physicianComment
          ? demographicsFormState.physicianComment
          : "",
        medicaidId: demographicsFormState.medicaidId
          ? demographicsFormState.medicaidId
          : "",
        medicareId: demographicsFormState.medicareId
          ? demographicsFormState.medicareId
          : "",
        pFlag: demographicsFormState.pFlag ? demographicsFormState.pFlag : "",
      };
    }

    try {
      const _payload = {
        id: assessmentId, //"658421885b5cf5633f5bbece", //assessmentId,officeName
        data: {
          name: "Child Demographics",
          //data: { ...demographicsFormState },
          data: storedYear == "2024" ? { ...payload2024 } : { ...payload },
          //  data: { ...demographicsFormState, maritalStatus: "1", gender: "1" }, //payload, //assessmentInfoPayloadObj,
        },
        year: storedYear,
      };
      const response = await putApi("/hra/assessment", _payload);

      if (response.status === 200) {
        toast.success("Assessment Updated Successfully", {});
      } else {
        toast.error("Please Enter Valid Data", {});
      }
    } catch (error) {}
  };
  useImperativeHandle(ref, () => ({
    onSaveData: () => {
      onSaveData();
    },
  }));
  useEffect(() => {
    const id = setInterval(() => {
      if ((roleId == 5 && tabId != 3) || roleId == 1 || roleId == 2) {
        onSaveData();
      }
    }, 60000);
    return () => {
      clearInterval(id);
    };
  }, [demographicsFormState]);
  const calculateAge = (birthDate) => {
    // Create Date objects for birth date and today's date
    const birthDateObj = new Date(birthDate);
    const today = new Date();

    // Calculate the difference in years
    let age = today.getFullYear() - birthDateObj.getFullYear();

    // Check if the birth date hasn't occurred yet this year
    const todayMonth = today.getMonth();
    const todayDay = today.getDate();
    const birthMonth = birthDateObj.getMonth();
    const birthDay = birthDateObj.getDate();

    if (
      todayMonth < birthMonth ||
      (todayMonth === birthMonth && todayDay < birthDay)
    ) {
      age--;
    }

    return age + "";
  };
  const getDemographicsData = async () => {
    setIsLoading(true);
    try {
      const response = await getApi(`/hra/get-assessment?id=${assessmentId}`);
      const data = response.data.data.demographics.data;
      const age = calculateAge(data?.dob);
      const currentGenderComment = data?.currentGender?.comment;
      const currentGenderIdentity = data?.currentGender?.gender;
      const aligned = data?.aligned;
      const alternateContactNumber = data?.alternateContactNumber;
      setDemographicsFormState({
        ...data,
        age: age,
        aligned: aligned == "2" ? "N" : aligned == "1" ? "Y" : aligned,
        currentGenderComment: currentGenderComment,
        currentGenderIdentity: currentGenderIdentity,
        alternateContactNumber: alternateContactNumber,
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      return;
    }
  };
  console.log(demographicsFormState, "xxx");

  useEffect(() => {
    getDemographicsData();
  }, []);

  useEffect(() => {
    // if(Boolean(setAutoSavePedsDemographicsData)){
    props?.setAutoSavePedsDemographicsData(demographicsFormState);
    // }
  }, [demographicsFormState]);

  return (
    <>
      <LoadingOverlay
        styles={{
          content: { marginLeft: "40vw", marginTop: "50px" },
          spinner: (base) => ({
            ...base,
            marginLeft: "50px",
            marginTop: "50px",
          }),
        }}
        style={{ marginTop: "20px" }}
        active={isLoading}
        spinner={<img src={focusCaresLoader} alt="Loader" />}
      >
        <Box padding={2}>
          <Grid container spacing={2}>
            {demographicsJsonObj?.map((question) => (
              <Grid
                item
                sm={
                  [
                    "plan",
                    "program",
                    "name",
                    "gender",
                    "emergencyContact",
                    "ePhoneNumber",
                    "primaryCarePhysician",
                    "pPhoneNumber",
                    "guardianName",
                    "relationshipToChild",
                    "physicianComment",
                    "pcpFlag",
                  ]?.includes(question?.questionField)
                    ? 6
                    : [
                        "addressOne",
                        "addressTwo",
                        "addressThree",
                        "mAddressOne",
                        "mAddressTwo",
                        "mAddressThree",
                      ]?.includes(question?.questionField)
                    ? 2
                    : [
                        "city",
                        "state",
                        "zip",
                        "mCity",
                        "mState",
                        "mZip",
                        "pcpCity",
                        "pcpZip",
                        "pcpCountry",
                      ]?.includes(question?.questionField)
                    ? 2
                    : ["pcpAddress", "officeName"]?.includes(
                        question?.questionField,
                      )
                    ? 4.5
                    : ["pcpState"]?.includes(question?.questionField)
                    ? 1.5
                    : ["pcpfaxnumber", "pcpnpi", "officeId"]?.includes(
                        question?.questionField,
                      )
                    ? 2.5
                    : ["currentGenderComment"]?.includes(
                        question?.questionField,
                      )
                    ? 8
                    : [
                        "memberIdentificationNo",
                        "uniqueIdentificationNo",
                        "medicareId",
                        "medicaidId",
                      ]?.includes(question?.questionField)
                    ? 3
                    : 4
                }
                key={question.questionField}
              >
                {question.questionType == "Text" ? (
                  <Box>
                    <Typography
                      style={{
                        color: "var(--mainTheme)",
                        fontWeight: 600,
                        //fontWeight: "bolder",
                      }}
                      variant="body2"
                    >
                      {question.questionLabel}{" "}
                      {question?.questionField == "name" && (
                        <span style={{ color: "#ff0000" }}>*</span>
                      )}
                    </Typography>

                    <TextField
                      fullWidth
                      variant="outlined"
                      disabled={
                        (roleId == 5 && tabId == 3) ||
                        (roleId == 8 && tabId == 4) ||
                        (roleId == 8 && tabId == 4) ||
                        roleId == 6 ||
                        roleId == 8 ||
                        roleId == 7 ||
                        roleId == 9 ||
                        question?.questionField == "age" ||
                        question?.questionField == "memberIdentificationNo" ||
                        question?.questionField == "medicaidId" ||
                        question?.questionField == "uniqueIdentificationNo" ||
                        question?.questionField == "medicareId" ||
                        question?.questionField == "name"
                      }
                      sx={{
                        // height: "35px",

                        borderRadius: "5px",
                        border: "1.5px solid var(--mainTheme) !important",
                        "& .MuiFormLabel-root": {
                          backgroundColor: "white",
                        },
                        "& .MuiInputLabel-root": {
                          backgroundColor: "white",
                        },
                        "& .MuiOutlinedInput-input": {
                          paddingY: "4px",
                          paddingX: "4px",
                        },
                      }}
                      inputProps={{
                        maxLength: ["mZip", "zip", "pcpZip"].includes(
                          question?.questionField,
                        )
                          ? 10
                          : [
                              "alternateNo",
                              "gPhoneNumber",
                              "gCellNumber",
                              "pPhoneNumber",
                              "ePhoneNumber",
                              // "emergencyContact",
                              "phoneNumber",
                              "cellNumber",
                              "alternateContactNumber",
                            ]?.includes(question?.questionField)
                          ? 12
                          : undefined,
                        minLength: ["mZip", "zip", "pcpZip"].includes(
                          question?.questionField,
                        )
                          ? 5
                          : [
                              "alternateNo",
                              "gPhoneNumber",
                              "gCellNumber",
                              "pPhoneNumber",
                              "ePhoneNumber",
                              "emergencyContact",
                              "phoneNumber",
                              "cellNumber",
                              "alternateContactNumber",
                            ]?.includes(question?.questionField)
                          ? 10
                          : undefined,
                      }}
                      //label={question.questionLabel}
                      type={question.questionType == "Text" ? "text" : "number"}
                      value={
                        demographicsFormState?.[question?.questionField] || ""
                      }
                      onChange={(event) => {
                        handleTextBoxChange(event, question);
                      }}
                      onPaste={(event) =>
                        handlePaste(
                          event,
                          question?.questionField,
                          event.clipboardData.getData("text"),
                        )
                      }
                      error={true}
                    />
                    {fieldError[question.questionField]?.length > 0 && (
                      <p style={{ margin: "0px", color: "tomato" }}>
                        {fieldError[question.questionField]}
                      </p>
                    )}
                  </Box>
                ) : question.questionType == "Select" ? (
                  <Box>
                    <Typography
                      style={{
                        color: "var(--mainTheme)",
                        fontWeight: 600,
                      }}
                      variant="body2"
                    >
                      {question.questionLabel}
                    </Typography>
                    <Select
                      placeholder="---Please Select---"
                      id={question.questionField}
                      key={question.questionField}
                      variant="outlined"
                      style={{ borderRadius: "5px" }}
                      sx={{
                        // height: "45px",
                        border: "1.5px solid var(--mainTheme) !important",

                        "& .MuiFormLabel-root": {
                          backgroundColor: "white",
                          color: "rgba(0,0,0,0.38)!important",
                        },
                        "& .MuiInputLabel-root": {
                          backgroundColor: "white",
                          color: "rgba(0,0,0,0.38)!important",
                        },
                        "& .MuiSelect-select": {
                          paddingY: "4px",
                        },
                      }}
                      disabled={
                        (roleId == 5 && tabId == 3) ||
                        (roleId == 8 && tabId == 4) ||
                        (roleId == 8 && tabId == 4) ||
                        roleId == 6 ||
                        roleId == 8 ||
                        roleId == 7 ||
                        roleId == 9
                      }
                      value={
                        demographicsFormState?.[question?.questionField] ||
                        "---Please Select---"
                        //question?.questionField ==   "currentGenderIdentity" ? demographicsFormState?.currentGender?.gender  :  demographicsFormState?.[question?.questionField] || ""
                      }
                      onChange={(event) => {
                        handleSelectChange(event, question);
                      }}
                      fullWidth
                    >
                      <MenuItem
                        key="select"
                        disabled
                        value="---Please Select---"
                      >
                        ---Please Select---
                      </MenuItem>
                      {question?.questionOption.map((item, index) => (
                        <MenuItem key={item} value={index + 1}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                ) : question?.questionType == "DateField" ? (
                  <Box>
                    <Typography
                      style={{
                        color: "var(--mainTheme)",
                        fontWeight: 600,
                      }}
                      variant="body2"
                    >
                      {question.questionLabel}
                    </Typography>
                    <Box
                      sx={{
                        "& > :not(style)": { width: "100%" },
                        paddingTop: "0px !important",
                        "& .MuiStack-root": {
                          paddingTop: "2px !important",
                        },
                      }}
                      className="inputAddMembers"
                    >
                      <LocalizationProvider
                        disabled={
                          (roleId == 5 && tabId == 3) ||
                          (roleId == 8 && tabId == 4) ||
                          (roleId == 8 && tabId == 4) ||
                          roleId == 6 ||
                          roleId == 8 ||
                          roleId == 7 ||
                          roleId == 9
                        }
                        dateAdapter={AdapterDayjs}
                        style={{
                          overflowY: "hidden !important",
                          "& .MuiStack-root": {
                            paddingTop: "2px !important",
                          },
                        }}
                        sx={{
                          overflowY: "hidden !important",
                          paddingTop: "0px !important",
                        }}
                      >
                        <DemoContainer
                          components={["DatePicker"]}
                          sx={{
                            width: "100%",
                            overflowY: "hidden !important",
                          }}
                          style={{
                            overflowY: "hidden !important",
                          }}
                        >
                          <DesktopDatePicker
                            disabled={
                              (roleId == 5 && tabId == 3) ||
                              (roleId == 8 && tabId == 4) ||
                              (roleId == 8 && tabId == 4) ||
                              roleId == 6 ||
                              roleId == 8 ||
                              roleId == 7 ||
                              roleId == 9
                            }
                            sx={{
                              width: "100%",
                              //height: "30px !important",
                              "& .MuiInputBase-root": {
                                // backgroundColor: "white",
                                // color: "rgba(0,0,0,0.38)!important",
                                //height: "35px !important",
                                // minHeight: "35px !important",
                                paddingY: "0px",
                              },
                              "& .Mui-disabled": {
                                // height: "35px !important",
                                // minHeight: "35px !important",
                                paddingY: "0px",
                              },
                            }}
                            //style={{ height: "30px !important" }}
                            className="dateFieldBorder"
                            variant="outlined"
                            maxDate={dayjs(new Date())}
                            views={["year", "month", "day"]}
                            //value={dayjs(new Date())}
                            value={dayjs(
                              demographicsFormState?.[question?.questionField],
                            )}
                            onChange={(newValue) =>
                              handleDateChange(question, newValue)
                            }
                            slotProps={{
                              textField: {
                                disabled: true,
                                height: "30px",
                                minHeight: "30px",
                                sx: {
                                  "& .MuiInputBase-root": {
                                    // backgroundColor: "white",
                                    // color: "rgba(0,0,0,0.38)!important",
                                    height: "32px !important",
                                    minHeight: "32px !important",
                                  },
                                  "& Mui-disabled": {
                                    height: "32px !important",
                                    minHeight: "32px !important",
                                  },
                                },
                              },
                            }}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </Box>
                  </Box>
                ) : question?.questionType == "Radio" ? (
                  <Box>
                    <Typography
                      style={{
                        color: "var(--mainTheme)",
                        fontWeight: 600,
                        marginLeft: "10px",
                      }}
                      variant="body2"
                    >
                      {" "}
                      {question?.questionLabel}
                    </Typography>
                    <Grid
                      item
                      xs={12}
                      //  component="fieldset"
                      // style={{
                      //   borderWidth: 0.5,
                      //   borderColor: "var(--mainTheme850)",
                      //   borderStyle: "solid",
                      //   borderRadius: "5px",
                      // }}
                    >
                      <FormControl
                        className="inputStyle"
                        fullWidth
                        // disabled={disableFlag==="previewHRA"}
                        disabled={
                          (roleId == 5 && tabId == 3) ||
                          (roleId == 8 && tabId == 4) ||
                          (roleId == 8 && tabId == 4) ||
                          roleId == 6 ||
                          roleId == 8 ||
                          roleId == 7 ||
                          roleId == 9
                        }
                      >
                        <RadioGroup
                          disabled={
                            (roleId == 5 && tabId == 3) ||
                            (roleId == 8 && tabId == 4)
                          }
                          className="inputStyle"
                          variant="outlined"
                          sx={{ marginLeft: "10px" }}
                          row
                          name="Gender" //{inputProps.name}
                          value={
                            demographicsFormState?.[question?.questionField] ||
                            ""
                          }
                          onChange={(event) => {
                            handleSelectChange(event, question);
                          }}
                        >
                          {question?.questionOption.map((item, index) => (
                            <FormControlLabel
                              key={item}
                              value={index + 1}
                              control={<Radio />}
                              label={item}
                              // value={option[1]}
                            />
                          ))}
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Box>
                ) : question?.questionType == "Checkbox" ? (
                  <>
                    <Box>
                      <Typography>
                        Were updates made to the PCP details?
                      </Typography>
                    </Box>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled={
                              (roleId == 5 && tabId == 3) ||
                              (roleId == 8 && tabId == 4) ||
                              roleId == 6 ||
                              roleId == 8 ||
                              roleId == 7 ||
                              roleId == 9
                            }
                            checked={
                              demographicsFormState?.[question.questionField] ==
                              "1"
                                ? true
                                : false
                            }
                            onChange={(e) => {
                              setDemographicsFormState({
                                ...demographicsFormState,
                                [question.questionField]: e.target.checked
                                  ? "1"
                                  : "2", //event.target.value,
                              });
                            }}
                          />
                        }
                        label="Yes"
                      />
                    </FormGroup>
                  </>
                ) : null}
              </Grid>
            ))}
          </Grid>
        </Box>
      </LoadingOverlay>
    </>
  );
});

export default PedsDemographicsComp;
