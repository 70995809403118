const inputsJson2023 = [
  {
    sectionId: 1,
    sectionName: "Demographics",
  },
  {
    sectionId: 2,
    sectionName: "Previously Documented Conditions",
  },
  {
    sectionId: 3,
    sectionName: "Covid Screening",
  },

  {
    sectionId: 4,
    sectionName: "Screenings Needed",
  },

  {
    sectionId: 5,
    sectionName: "Patient Summary",
  },
];

const inputsJson2024 = [
  {
    sectionId: 1,
    sectionName: "Demographics",
  },
  {
    sectionId: 2,
    sectionName: "Previously Documented Conditions",
  },
  {
    sectionId: 3,
    sectionName: "Covid Screening",
  },

  {
    sectionId: 4,
    sectionName: "Screenings Needed",
  },

  {
    sectionId: 5,
    sectionName: "Patient Summary",
  },
];

export const getInputsData = (clientYear) => {
  let temp = [];
  if (clientYear === "2023") {
    temp = [...temp, ...inputsJson2023];
  } else if (clientYear === "2024") {
    temp = [...temp, ...inputsJson2024];
  }

  return temp.length > 0 ? temp : [{}];
};

export const getInputsDataByID = (section_id) => {
  const temp = localStorage.getItem("inputsJsonData")
    ? JSON.parse(localStorage.getItem("inputsJsonData"))
    : inputsJson2023;

  let data = [];

  temp &&
    temp.forEach((section) => {
      if (section.sectionId === section_id) {
        data = [section];
      }
    });

  return data;
};

export const saveFormData = (formData) => {
  localStorage.setItem("formLayoutData", JSON.stringify(formData));
  return true;
};

export const optionsData = [
  {
    questionId: 1,
    name: "trial_options",
    label: "Trial for Options",
    type: "options_tree",
    options: [
      ["Asian", "asian", false],
      ["American", "american", false],
      [
        "Other",
        "other",
        true,
        [
          {
            subQuestionId: 1,
            name: "plan",
            type: "text",
            placeholder: "Plan",
            defaultValue: "",
            label: "Plan",
            variant: "outlined",
            fullWidth: true,
            required: false,
            xs: 6,
            sm: 6,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
          },
        ],
      ],
    ],
    subQuestions: [
      {
        subQuestionId: 1,
        name: "plan",
        type: "text",
        placeholder: "Plan",
        defaultValue: "",
        label: "Plan",
        variant: "outlined",
        fullWidth: true,
        required: false,
        xs: 6,
        sm: 6,
        /*isEnabledClients: [
        ["VPHP", true],
        ["OHP", true],
        ["SWHR", false],
        ["ALLIANT", false],
        ["HA", false],
        ["SHP", false],
      ],
      isEnabledPlans: [
        ["Medicare", true],
        ["MedicAid", true],
      ],
      isAgeGroup: [
        ["Above 65", true],
        ["Below 65", true],
      ],*/
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
      },
      {
        subQuestionId: 2,
        name: "age",
        type: "number",
        placeholder: "Age",
        label: "Age",
        variant: "outlined",
        fullWidth: true,
        required: true,
        xs: 2,
        sm: 2,
        /*isEnabledClients: [
        ["VPHP", true],
        ["OHP", true],
        ["SWHR", false],
        ["ALLIANT", false],
        ["HA", false],
        ["SHP", false],
      ],
      isEnabledPlans: [
        ["Medicare", true],
        ["MedicAid", true],
      ],
      isAgeGroup: [
        ["Above 65", true],
        ["Below 65", true],
      ],*/
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
      },
      {
        subQuestionId: 3,
        name: "gender",
        label: "Gender",
        placeholder: "",
        defaultValue: "male",
        type: "radio",
        options: [
          ["Male", "male"],
          ["Female", "female"],
          ["Other", "other"],
        ],
        xs: 4,
        sm: 4,
        /*isEnabledClients: [
        ["VPHP", true],
        ["OHP", true],
        ["SWHR", false],
        ["ALLIANT", false],
        ["HA", false],
        ["SHP", false],
      ],
      isEnabledPlans: [
        ["Medicare", true],
        ["MedicAid", true],
      ],
      isAgeGroup: [
        ["Above 65", true],
        ["Below 65", true],
      ],*/
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
      },
      {
        subQuestionId: 1,
        name: "options_tree",
        label: "Options Tree",
        type: "options_tree",
        subquestions: [
          {
            subQuestionId: 1,
            name: "firstname",
            type: "text",
            placeholder: "Enter First Name",
            label: "First Name",
            variant: "outlined",
            fullWidth: true,
            required: true,
            xs: 4,
            sm: 4,
            isEnabledClients: [
              ["VPHP", true],
              ["OHP", true],
              ["SWHR", false],
              ["ALLIANT", false],
              ["HA", false],
              ["SHP", false],
            ],
            isEnabledPlans: [
              ["Medicare", true],
              ["MedicAid", true],
            ],
            isAgeGroup: [
              ["Above 65", true],
              ["Below 65", true],
            ],
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
          },
          {
            subQuestionId: 2,
            name: "age",
            type: "number",
            placeholder: "Age",
            label: "Age",
            variant: "outlined",
            fullWidth: true,
            required: true,
            xs: 2,
            sm: 2,
            isEnabledClients: [
              ["VPHP", true],
              ["OHP", true],
              ["SWHR", false],
              ["ALLIANT", false],
              ["HA", false],
              ["SHP", false],
            ],
            isEnabledPlans: [
              ["Medicare", true],
              ["MedicAid", true],
            ],
            isAgeGroup: [
              ["Above 65", true],
              ["Below 65", true],
            ],
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
          },
          {
            subQuestionId: 3,
            name: "gender",
            label: "Gender",
            placeholder: "",
            defaultValue: "male",
            type: "radio",
            options: [
              ["Male", "male"],
              ["Female", "female"],
              ["Other", "other"],
            ],
            xs: 6,
            sm: 6,
            isEnabledClients: [
              ["VPHP", true],
              ["OHP", true],
              ["SWHR", false],
              ["ALLIANT", false],
              ["HA", false],
              ["SHP", false],
            ],
            isEnabledPlans: [
              ["Medicare", true],
              ["MedicAid", true],
            ],
            isAgeGroup: [
              ["Above 65", true],
              ["Below 65", true],
            ],
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
          },
        ],
        required: true,
        xs: 4,
        sm: 4,
        fullWidth: true,
        /*isEnabledClients: [
        ["VPHP", true],
        ["OHP", true],
        ["SWHR", false],
        ["ALLIANT", false],
        ["HA", false],
        ["SHP", false],
      ],
      isEnabledPlans: [
        ["Medicare", true],
        ["MedicAid", true],
      ],
      isAgeGroup: [
        ["Above 65", true],
        ["Below 65", true],
      ],*/
        isCommentEnabled: false,
        isConfigured: true,
        isModified: false,
      },
    ],
    required: true,
    xs: 4,
    sm: 4,
    fullWidth: true,
    isEnabledClients: [
      ["VPHP", true],
      ["OHP", true],
      ["SWHR", false],
      ["ALLIANT", false],
      ["HA", false],
      ["SHP", false],
    ],
    isEnabledPlans: [
      ["Medicare", true],
      ["MedicAid", true],
    ],
    isAgeGroup: [
      ["Above 65", true],
      ["Below 65", true],
    ],
    isCommentEnabled: false,
    isConfigured: true,
    isModified: false,
  },
];
