//import MuiInput from "../../../muiinput/MuiInput";
import Button from "@mui/material/Button";
//import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
//import "./AddClinician.css";
import React, { useState, useEffect } from "react";
import ReactBigCalendar from "./availability.calander";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Paper,
  Typography,
  Fade,
  Box,
} from "@mui/material";

const MonthlyAvailabilityForm = ({
  setMonthlyAvailability,
  monthlyAvailForm,
  fromAddClinician,
  readOnly,
  lastUpdated,
}) => {
  const shifts = [
    { date: "2024-09-22", shiftHour: "9 AM - 5 PM" },
    { date: "2024-09-23", shiftHour: "10 AM - 6 PM" },
    { date: "2024-09-24", shiftHour: "11 AM - 7 PM" },
  ];

  const fName = sessionStorage.getItem("firstName");
  const lName = sessionStorage.getItem("lastName");

  const tableCellStyle = {
    fontWeight: "bold",
    backgroundColor: "var(--mainTheme100)",
    border: "1px solid var(--mainTheme)",
    textAlign: "left",
    whiteSpace: "nowrap",
  };

  const tableHeadStyle = {
    fontWeight: "bold",
    backgroundColor: "var(--mainTheme)",
    border: "1px solid var(--mainTheme)",
    textAlign: "ceanter",
    whiteSpace: "nowrap",
  };
  const [events, setEvents] = useState([]);

  console.log("events", events);
  const weekdays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  // useEffect(()=>{
  //   setMonthlyAvailability(events)
  // },[events]);
  console.log("monthlyAvailForm", monthlyAvailForm);
  useEffect(() => {
    setEvents(monthlyAvailForm);
  }, [monthlyAvailForm]);
  const getFormattedDate = (date) => {
    //const dayNumber = dateObj.getDay(); // Get the day as a number (0-6)

    const dateObj = new Date(date);
    const dayNumber = dateObj.getDay();
    const dayName = weekdays[dayNumber];
    const year = dateObj.getFullYear();
    const month = dateObj.getMonth() + 1;
    const day = dateObj.getDate();
    return `${month < 10 ? "0" + month : month}/${
      day < 10 ? "0" + day : day
    }/${year} ${dayName}`;
  };

  const getAvailableTime = (startDate, endDate) => {
    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);

    let startingHours = startDateObj.getHours(); // Get the hours (0-23)
    const startingMinutes = startDateObj.getMinutes(); // Get the minutes (0-59)
    //const seconds = dateObj.getSeconds();     // Get the seconds (0-59)

    let endingHours = endDateObj.getHours(); // Get the hours (0-23)
    const endingMinutes = endDateObj.getMinutes();

    // Determine AM or PM
    const startingampm = startingHours >= 12 ? "PM" : "AM";
    const endingampm = endingHours >= 12 ? "PM" : "AM";

    // Convert hours to 12-hour format
    startingHours = startingHours % 12;
    startingHours = startingHours ? startingHours : 12;

    endingHours = endingHours % 12;
    endingHours = endingHours ? endingHours : 12;

    return `${startingHours}:${
      startingMinutes < 10 ? "0" + startingMinutes : startingMinutes
    } ${startingampm} To ${endingHours}:${
      endingMinutes < 10 ? "0" + endingMinutes : endingMinutes
    } ${endingampm}`;
    // the hour '0' should be '12'
  };
  console.log("monthlyAvailForm", monthlyAvailForm);
  return (
    <>
      <Fade in={!readOnly} unmountOnExit>
        <Box>
          <ReactBigCalendar
            eventsData={events}
            setEventsData={setEvents}
            setMonthlyAvailability={setMonthlyAvailability}
          />
        </Box>
      </Fade>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "15px",
          marginLeft: "10px",
          width: "100%",
          margin: "auto",
        }}
      >
        <Table
          sx={{
            tableLayout: "auto",
            margin: "10px",
            overflow: "hidden",
          }}
          size="small"
        >
          <TableHead style={tableHeadStyle}>
            <TableRow
              sx={{ backgroundColor: "yellow !important" }}
              style={{ backgroundColor: "yellow !important" }}
            >
              <TableCell
                style={{
                  // ...tableCellStyle,
                  fontWeight: "bold",
                  backgroundColor: "yellow !important",
                }}
              >
                Practitioner's Name
              </TableCell>
              <TableCell
                style={{
                  //...tableCellStyle,
                  backgroundColor: "yellow !important",
                }}
              ></TableCell>
              <TableCell
                style={{
                  //  ...tableCellStyle,
                  fontWeight: "bold",
                  backgroundColor: "yellow !important",
                }}
              ></TableCell>
              <TableCell
                style={{
                  //...tableCellStyle,
                  backgroundColor: "yellow !important",
                }}
              ></TableCell>
              <TableCell
                style={{
                  // ...tableCellStyle,
                  fontWeight: "bold",
                  backgroundColor: "yellow !important",
                }}
              >
                {`${fName} ${lName}`}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell
                style={{
                  //...tableCellStyle,
                  fontWeight: "bold",
                  borderBottom: "1px solid var(--mainTheme)",
                  backgroundColor: "var(--mainTheme100)",
                }}
              >
                Date Submitted:
              </TableCell>
              <TableCell
                style={{
                  //...tableCellStyle,
                  borderBottom: "1px solid var(--mainTheme)",
                  backgroundColor: "var(--mainTheme100)",
                }}
              ></TableCell>
              <TableCell
                style={{
                  //...tableCellStyle,
                  borderBottom: "1px solid var(--mainTheme)",
                  backgroundColor: "var(--mainTheme100)",
                }}
              ></TableCell>
              <TableCell
                style={{
                  //  ...tableCellStyle,
                  borderBottom: "1px solid var(--mainTheme)",
                  backgroundColor: "var(--mainTheme100)",
                }}
              ></TableCell>
              <TableCell style={tableCellStyle}>{lastUpdated}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={tableCellStyle}>Date</TableCell>

              <TableCell style={tableCellStyle}>Available Time</TableCell>
              <TableCell style={tableCellStyle}>Max Load Case</TableCell>
              <TableCell style={tableCellStyle}>Miles</TableCell>
              <TableCell style={tableCellStyle}>Comments</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {events
              ?.filter((e) => e?.start)
              ?.map((row, index) => (
                <TableRow key={index}>
                  <TableCell style={tableCellStyle}>
                    <Typography variant="body2">
                      {row?.start ? getFormattedDate(new Date(row?.start)) : ""}
                    </Typography>
                  </TableCell>

                  <TableCell style={tableCellStyle}>
                    <Typography variant="body2">
                      {row?.end
                        ? getAvailableTime(
                            new Date(row?.start),
                            new Date(row?.end),
                          )
                        : ""}
                    </Typography>
                  </TableCell>

                  <TableCell style={tableCellStyle}>
                    {row?.maxLoad && (
                      <TextField
                        variant="outlined"
                        disabled={readOnly}
                        sx={{
                          border: "1.5px solid var(--mainTheme) !important",
                          //  : " 1.5px solid rgba(255, 45, 49, 0.7) !important",
                          "& .MuiFormLabel-root": {
                            backgroundColor: "white",
                          },
                          "& .MuiInputLabel-root": {
                            backgroundColor: "white",
                          },
                        }}
                        fullWidth
                        size="small"
                        value={row?.maxLoad ? row?.maxLoad : ""}
                      />
                    )}
                  </TableCell>
                  <TableCell style={tableCellStyle}>
                    {row?.miles && (
                      <TextField
                        variant="outlined"
                        disabled={readOnly}
                        value={row?.miles}
                        sx={{
                          border: "1.5px solid var(--mainTheme) !important",

                          "& .MuiFormLabel-root": {
                            backgroundColor: "white",
                          },
                          "& .MuiInputLabel-root": {
                            backgroundColor: "white",
                          },
                        }}
                        fullWidth
                        size="small"
                      />
                    )}
                  </TableCell>

                  <TableCell style={tableCellStyle}>
                    {row?.comment && (
                      <TextField
                        disabled={readOnly}
                        variant="outlined"
                        value={row?.comment}
                        sx={{
                          border: "1.5px solid var(--mainTheme) !important",

                          "& .MuiFormLabel-root": {
                            backgroundColor: "white",
                          },
                          "& .MuiInputLabel-root": {
                            backgroundColor: "white",
                          },
                        }}
                        fullWidth
                        size="small"
                      />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            <TableRow
              sx={{ backgroundColor: "yellow !important" }}
              style={{ backgroundColor: "yellow !important" }}
            >
              <TableCell
                style={{
                  ...tableCellStyle,
                  backgroundColor: "yellow !important",
                  color: "red",
                  fontWeight: "bold",
                }}
              >
                Counties of coverage (Required):
              </TableCell>
              <TableCell
                style={{
                  ...tableCellStyle,
                  backgroundColor: "yellow !important",
                }}
              ></TableCell>
              <TableCell
                style={{
                  ...tableCellStyle,
                  backgroundColor: "yellow !important",
                }}
              ></TableCell>
              <TableCell
                style={{
                  ...tableCellStyle,
                  backgroundColor: "yellow !important",
                }}
              ></TableCell>
              <TableCell
                style={{
                  ...tableCellStyle,
                  backgroundColor: "yellow !important",
                }}
              ></TableCell>
            </TableRow>

            <TableRow
              sx={{ backgroundColor: "yellow !important" }}
              style={{ backgroundColor: "yellow !important" }}
            >
              <TableCell
                style={{
                  fontWeight: "bold",
                  backgroundColor: "var(--mainTheme100)",
                  border: "1px solid var(--mainTheme)",
                  textAlign: "left",
                  whiteSpace: "wrap",
                  backgroundColor: "yellow !important",
                  color: "red",
                  fontWeight: "bold",
                }}
              >
                Special Instructions:
                <br />
                <br /> <br /> (e.g. zip code restrictions, Please note you can
                take only 1 break daily and it can be a 15 minutes, 30 minutes,
                45 minutes, or 60 minutes break)
              </TableCell>
              <TableCell
                style={{
                  ...tableCellStyle,
                  backgroundColor: "yellow !important",
                }}
              ></TableCell>
              <TableCell
                style={{
                  ...tableCellStyle,
                  backgroundColor: "yellow !important",
                }}
              ></TableCell>
              <TableCell
                style={{
                  ...tableCellStyle,
                  backgroundColor: "yellow !important",
                }}
              ></TableCell>
              <TableCell
                style={{
                  ...tableCellStyle,
                  backgroundColor: "yellow !important",
                }}
              ></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </>
  );
};

export default MonthlyAvailabilityForm;
