import React, {
  createContext,
  useState,
  useContext,
  useRef,
  useEffect,
} from "react";

import * as speechsdk from "microsoft-cognitiveservices-speech-sdk";

import { BlobServiceClient } from "@azure/storage-blob";

import { postApi } from "../utils/axiosApiWrapper";

import { generateRTTReport } from "../api/realTimeScriptsApi";

import toast from "react-hot-toast";

const SpeechContext = createContext();

export function SpeechProvider({ children }) {
  // const [showTextArea, setShowTextArea] = useState(false);

  // const [transcript, setTranscript] = useState("");

  const [isTranscribe, setIsTranscribe] = useState(false);

  const [isStarting, setIsStarting] = useState(false); // New lock state

  const [isTranscribePause, setIsTranscribePause] = useState(false);

  const [isRecognisePause, setIsRecognisePause] = useState(false);

  const [isRecognise, setIsRecognise] = useState(false);

  const [recText, setRecText] = useState("");

  const [recording, setRecording] = useState(false);

  const [audioBlob, setAudioBlob] = useState(null);

  const [mediaRecorder, setMediaRecorder] = useState(null);

  const [transcript, setTranscript] = useState("");
  const [azureBlobUrl, setAzureBlobUrl] = useState("");
  const assessmentId = sessionStorage?.getItem("assessmentId");

  const textTransRef = useRef([]);

  const textRecogRef = useRef([]);

  const transcriberRef = useRef();

  const recognizerRef = useRef();

  const speechConfig = speechsdk.SpeechConfig.fromSubscription(
    process.env.REACT_APP_SPEECH_KEY,

    process.env.REACT_APP_SPEECH_REGION,
  );

  speechConfig.enableDictation();

  speechConfig.speechRecognitionLanguage =
    process.env.REACT_APP_SPEECH_LANGUAGE;

  speechConfig.setProperty(
    speechsdk.PropertyId.SpeechServiceConnection_EndSilenceTimeoutMs,

    "2000",
  );

  speechConfig.setProperty(
    speechsdk.PropertyId.SpeechServiceResponse_PostProcessingOption,

    "TrueText",
  );

  let audioConfig = speechsdk.AudioConfig.fromDefaultMicrophoneInput();

  const transcriberInit = () => {
    try {
      let conversationTranscriber = new speechsdk.ConversationTranscriber(
        speechConfig,

        audioConfig,
      );

      transcriberRef.current = conversationTranscriber;
    } catch (error) {
      console.log(error);
    }
  };

  const recognizerInit = () => {
    try {
      const recognizer = new speechsdk.SpeechRecognizer(
        speechConfig,

        audioConfig,
      );

      recognizerRef.current = recognizer;
    } catch (error) {
      console.log(error);
    }
  };

  async function initializeSdk() {
    try {
      transcriberInit();

      recognizerInit();
    } catch (error) {
      console.log(error);
    }
  }

  // console.log(textTransRef,"textTransRef 2")

  if (transcriberRef.current !== undefined) {
    transcriberRef.current.transcribing = function (s, e) {
      if (e.result.text !== undefined && e.result.text !== "") {
        textTransRef?.current?.push(
          e.result.speakerId.replace(/Guest/gi, "Speaker") +
            ":" +
            e.result.text +
            "\n \n",
        );
      }
    };

    transcriberRef.current.transcribed = function (s, e) {
      if (e.result.text !== undefined && e.result.text !== "") {
        textTransRef?.current?.push(
          e.result.speakerId.replace(/Guest/gi, "Speaker") +
            ":" +
            e.result.text +
            "\n \n",
        );
      }
    };
  }

  if (recognizerRef.current !== undefined) {
    recognizerRef.current.recognizing = function (s, e) {
      if (e.result.text !== undefined && e.result.text !== "") {
        textRecogRef.current.push(e.result.text);
      }
    };

    recognizerRef.current.recognized = function (s, e) {
      if (e.result.text !== undefined && e.result.text !== "") {
        textRecogRef.current.push(e.result.text);
      }
    };
  }

  const setTranscribedText = (setState) => {
    const textArrayData = textTransRef?.current || [];

    if (textArrayData.length > 0) {
      setState(textArrayData.join(""));
    }
  };

  const setRecognisedText = (setState) => {
    const textArrayData = textRecogRef?.current || [];

    if (textArrayData.length > 0) {
      setState(textArrayData.join(""));
    }
  };

  const [blobUrlAzure, setBlobUrlAzure] = useState(null);

  const [sasTokenAzure, setSasTokenAzure] = useState(null);

  const [azureRequestUrl, setAzureRequestUrl] = useState(null);

  // console.log("SAS token and Blob URL:", blobUrlAzure, sasTokenAzure);

  const generateSasReport = async () => {
    try {
      const response = await postApi("/realTimeScripts/generate-sas");

      setSasTokenAzure(response?.data?.sasToken);

      const { blobUrl, sasToken } = response?.data;

      // Store blobUrl and sasToken in state

      setBlobUrlAzure(blobUrl);

      return sasToken;
    } catch (error) {
      console.error("Error generating SAS token:", error);
    }
  };
  // Azure Storage setup

  // const sasToken = `${sasTokenAzure}`;

  // console.log(sasTokenAzure, "sasTokenAzure");

  // const sasToken =
  //   "sp=racwd&st=2024-09-26T06:29:08Z&se=2024-09-26T14:29:08Z&spr=https&sv=2022-11-02&sr=c&sig=LiOB%2BlncNCLAomDr0UDWTVNFi4FYzFaoj%2B62YtxnOcY%3D"; // Replace with your SAS token

  // const sasToken = "sv=2024-08-04&spr=https&st=2024-09-26T05%3A56%3A56Z&se=2024-09-26T06%3A56%3A56Z&sr=c&sp=racwd&sig=qqFNpkepR%2F%2F7OeGfTgHCap%2FcRlkMP8ILaUagVzS8wi8%3D";

  const containerName = "audiofiles";

  // const blobServiceClient = new BlobServiceClient(
  //   `https://focuscaresrtttesting.blob.core.windows.net?${sasToken}`,
  // );
  // console.log(blobServiceClient, "blobServiceClient");
  // console.log(sasToken, "sasToken");
  // Function to start recording

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

      const recorder = new MediaRecorder(stream);

      setMediaRecorder(recorder);

      const audioChunks = [];

      recorder.ondataavailable = (event) => {
        audioChunks.push(event.data);
      };

      recorder.onstop = async () => {
        const audioBlob = new Blob(audioChunks, { type: "audio/wav" });

        setAudioBlob(audioBlob);
      };

      recorder.start();

      setRecording(true);
    } catch (error) {
      console.error("Error accessing microphone:", error);
    }
  };

  // Function to stop recording

  const stopRecording = async () => {
    // if (mediaRecorder) {

    mediaRecorder.stop();

    setRecording(false);

    // Stop the microphone stream to release the device

    const stream = mediaRecorder.stream;

    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
    }

    // }
  };

  const startTranscriber = async () => {
    await transcriberRef.current.startTranscribingAsync(() => {
      setIsTranscribe(true);

      setIsTranscribePause(false);
    });
    await startRecording();
  };

  const stopTranscriber = async () => {
    // console.log("stopTranscriber")

    try {
      // Stop transcribing

      await transcriberRef.current.stopTranscribingAsync(() => {
        setIsTranscribe(false);

        setIsTranscribePause(false);
      });

      await stopRecording();
    } catch (error) {
      console.error("Error stopping transcriber or recorder:", error);
    }
  };

  const pauseTranscriber = async () => {
    // console.log("pauseTranscriber")

    await transcriberRef.current.stopTranscribingAsync(() => {
      setIsTranscribePause(true);
    });

    await stopRecording();
  };

  const resumeTranscriber = async () => {
    // console.log("resumeTranscriber")

    await transcriberRef.current.startTranscribingAsync(() => {
      setIsTranscribePause(false);
    });

    await stopRecording();
  };

  // Function to upload audio blob to Azure

  const uploadAudioToAzure = async (sasToken) => {
    // if (audioBlob) {
    try {
      // const sasToken = `${sasTokenAzure}`;
      // console.log(sasToken, "sasToken");
      const blobServiceClient = new BlobServiceClient(
        `https://focuscaresrtttesting.blob.core.windows.net?${sasToken}`,
      );
      const containerClient =
        blobServiceClient.getContainerClient(containerName);

      const blobName = `audio_${new Date().toISOString()}.wav`;

      const blockBlobClient = containerClient.getBlockBlobClient(blobName);

      await blockBlobClient.uploadData(audioBlob, {
        blobHTTPHeaders: { blobContentType: "audio/wav" },
      });

      const requestUrl = blockBlobClient.url;
      setAzureRequestUrl(requestUrl);
    } catch (error) {
      console.error("Error uploading audio:", error);

      alert("Error uploading audio");
    }
    // }
  };

  const startRecogniser = async () => {
    // console.log("startRecogniser")

    await recognizerRef.current.startContinuousRecognitionAsync();

    setIsRecognise(true);
  };

  const stopRecogniser = async () => {
    // console.log("stopRecogniser")

    await recognizerRef.current.stopContinuousRecognitionAsync();

    initializeSdk(); // DONT REMOVE THIS - SDK might be unresponsive sometimes due to network issues. so reinitialzing when stopping.

    setIsRecognise(false);
  };

  useEffect(() => {
    initializeSdk();
  }, []);

  return (
    <SpeechContext.Provider
      value={{
        textTransRef,

        textRecogRef,

        transcriberRef,

        recognizerRef,

        isTranscribe,

        isTranscribePause,

        isRecognise,

        isRecognisePause,

        recText,

        setTranscribedText,

        setRecognisedText,

        startTranscriber,

        stopTranscriber,

        pauseTranscriber,

        resumeTranscriber,

        startRecogniser,

        stopRecogniser,

        setRecText,

        uploadAudioToAzure,

        generateSasReport,

        azureRequestUrl,
        setAzureRequestUrl,
        azureBlobUrl, // Expose the Azure Blob URL
        setAzureBlobUrl, // Expose the setter function
      }}
    >
      {children}
    </SpeechContext.Provider>
  );
}

export function useSpeech() {
  return useContext(SpeechContext);
}
