import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import InputAdornment from "@mui/material/InputAdornment";
import Tooltip from "@mui/material/Tooltip";
import TextField from "@mui/material/TextField";
import { SelectDropdown } from "../../components/select/select";
import { Button, Zoom } from "@mui/material";
import { QaFilterDialogs } from "../../components/Dashboard/Dialog/Dialog";
import AlertContext from "../../contexts/AlertContext";
import "./qalist.css";
import { useContext, useState, useEffect } from "react";
import { useUserContext } from "../../contexts/UserContext";
import { styled } from "@mui/material/styles";
import React from "react";
import { makeStyles } from "@mui/styles";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import Toaster from "../../components/toaster/toaster";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { isEmpty, trim } from "lodash";
import FilterModal from "./Graphs/FilterModal/FilterModal";
import {
  getClientByID,
  getClientsData,
  getHraStatus,
  getSchedulersData,
} from "../../api/filterApi";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import { postApi } from "../../utils/axiosApiWrapper";
import Loader from "../../components/Loader/Loader";
const useStyles = makeStyles((theme) => ({
  verticalDivider: {
    height: "100%",

    margin: 1,
    width: "5px",
    backgroundColor: "#1976d2",
  },

  card: {
    paddingX: "20px",
    textAlign: "center",
    width: "100%",
  },
  root: {
    border: "1px solid",
    padding: "10px",
    boxShadow: "5px 10px red",
  },
}));
const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 33,
  height: 20,
  padding: 0,
  display: "flex",
  marginLeft: 28,
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor:
          theme.palette.mode === "dark" ? "#177ddc" : "var(--mainTheme)",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 15,
    height: 16,
    marginLeft: 2,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

const QAHeader = ({
  onDropdownChange,
  hraStatusOptions,
  onSearch,
  visitTypeOptions,
  clinicianData,
  selectedCheckboxes,
  onUnassignedBtnChange,

  onCheckboxChange,
  areCheckboxesSelected,
  tabId,
  setGraphFilter,
  graphFilter,
  onFilterChange,
  handleOpenFilter,
  handleCloseFilter,
  setIsAnalyticsActive,
  isAnalyticsActive,
  selectedRowIndex,
  selectedMultipleRowId,
}) => {
  const { clientData, handleBadgeClick } = useUserContext();
  const [open, setOpen] = useState(false);
  const roleId = sessionStorage.getItem("roleId");
  const memberListOptions = ["Ready For QA", "Clinician Review", "Referral"];

  const filterDropdown = ["First name", "Identification#", "Last name"];
  const alertContext = useContext(AlertContext);
  const [selectedDropdownValue, setSelectedDropdownValue] =
    useState("Ready For QA");
  const [selectedFilter, setSelectedFilter] = useState(filterDropdown[0]);
  const [isFirstNameSelected, setIsFirstNameSelected] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [unassignedChecked, setUnassignedChecked] = useState(false);
  const [toasterMessage, setToasterMessage] = useState("");
  const [toasterSeverity, setToasterSeverity] = useState("");
  const [toasterOpen, setToasterOpen] = useState(false);
  const handleDropdownChange = (event) => {
    const newValue = event.target.value;
    setSelectedDropdownValue(newValue);
    onDropdownChange(newValue);
  };

  const toggleOpen = () => {
    if (isAnalyticsActive) {
      setGraphFilter(true);
    } else alertContext.setFilterDialog(true);
  };
  const storedPlan = localStorage.getItem("clientPlan");
  const storedYear = localStorage.getItem("clientYear");

  const storedYearList = JSON.parse(
    localStorage.getItem("extractedYearOptions"),
  );

  useEffect(() => {
    setSelectedFilter("Identification#");
    onSearch("Identification#", "");
  }, []);

  const handleSearch = (e) => {
    if (e.key == "Enter") {
      if (searchTerm.length > 2) {
        if (selectedFilter === "First name") {
          onSearch(selectedFilter, trim(searchTerm));
        } else if (selectedFilter === "Last name") {
          onSearch(selectedFilter, trim(searchTerm));
        } else if (selectedFilter === "Identification#") {
          onSearch(selectedFilter, trim(searchTerm));
        }
      } else {
        setToasterOpen(true);
        setToasterSeverity("error");
        setToasterMessage("Minimum 3 characters required to search!");
      }
    }
    if (e.key === "Backspace" && searchTerm.length < 4) {
      if (!isEmpty(searchTerm) && searchTerm.length != 1) {
        if (selectedFilter === "First name") {
          onSearch(selectedFilter, searchTerm);
        } else if (selectedFilter === "Last name") {
          onSearch(selectedFilter, searchTerm);
        } else if (selectedFilter === "Identification#") {
          onSearch(selectedFilter, searchTerm);
        }
      } else if (searchTerm.length == 1 || isEmpty(searchTerm.length)) {
        setSelectedFilter(filterDropdown[0]);
        setSearchTerm("");
        onSearch(selectedFilter, "");
      }
    }
  };
  const handleSearchOnIconClick = () => {
    if (searchTerm.length > 2) {
      onSearch(selectedFilter, searchTerm);
    } else {
      setToasterOpen(true);
      setToasterSeverity("error");
      setToasterMessage("Minimum 3 characters required to search!");
    }
  };

  const handleResetSearch = async () => {
    //setLoading(true);
    setSelectedFilter(filterDropdown[0]);
    setSearchTerm("");
    onSearch(selectedFilter, "");
  };

  const handleFilterDropdownChange = (event) => {
    const { value } = event.target;
    setSelectedFilter(value);
    setIsFirstNameSelected(value === "First name");
  };
  const handleUnassignedSwitchChange = () => {
    const newUnassignedCheckedState = !unassignedChecked;
    setUnassignedChecked(newUnassignedCheckedState);
    if (newUnassignedCheckedState == true) {
      onUnassignedBtnChange("Unassigned");
    } else {
      onUnassignedBtnChange("NotUnassigned");
    }
  };

  const [hra, setHra] = useState([]);
  const [lob, setLob] = useState([]);
  const [prog, setProg] = useState([]);
  const [users, setUsers] = useState([]);

  const [filters, setFilters] = useState({
    program: "",
    lob: "",
    hrastatus: "",
    fromDate: null,
    toDate: null,
  });

  const [qa, setQa] = useState("");

  const storedPlanId = localStorage.getItem("clientPlan");

  useEffect(() => {
    const getFilterData = async () => {
      try {
        const cliDt = await getClientsData();
        const currPlanId =
          cliDt.data.data.find((item) => item.plan === storedPlanId)?._id ||
          null;
        const hraResponse = await getHraStatus();
        const progResponse = await getClientByID(currPlanId.trim());
        const schedResponse = await getSchedulersData({
          planId: currPlanId.trim(),
          roleId: "7",
        });

        const hraData = hraResponse?.data?.data || [];

        if (Array.isArray(hraData)) {
          setHra(
            hraData
              .filter((stat) => stat.id && stat.statusName) // Filter out empty values
              .map((stat) => ({ id: stat.id, name: stat.statusName })),
          );
        }

        // Check and set Program and LOB data
        const progData = progResponse?.data?.data || {};
        if (progData && Array.isArray(progData.program)) {
          setProg(
            progData.program
              .filter((p) => p._id && p.name) // Filter out empty values
              .map((p) => ({ id: p._id, name: p.name })),
          );
          setLob(
            progData.program.flatMap((program) =>
              program.lob
                .filter((lob) => lob._id && lob.name) // Filter out empty values
                .map((lob) => ({ id: lob._id, name: lob.name })),
            ),
          );
        }

        // Check and set Scheduler data
        const schData = schedResponse?.data?.data || [];
        if (Array.isArray(schData)) {
          setUsers(
            schData
              .filter((user) => user.userid && user.firstName && user.lastName) // Filter out empty values
              .map((user) => ({
                id: user.userid,
                name: `${user.firstName} ${user.lastName}`,
              })),
          );
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    //if (storedPlanId) getFilterData();
    if (storedPlanId) {
      getFilterData();
    }
  }, [storedPlanId]);

  const [loading, setLoading] = useState(false);

  const handleDownloadReferrals = async (selectedRowIndex) => {
    const payload = {
      ids: selectedMultipleRowId?.map((itm) => itm?.ReferralId),
    };
    setLoading(true);
    try {
      const response = await postApi(
        "/referral-form/generate-pdfs-zip",
        payload,
      );

      const zipPath = response.data?.zipPath;
      if (zipPath) {
        const baseUrl = process.env.REACT_APP_API_BASE_URL;
        const fullZipUrl = `${baseUrl}${zipPath.replace(
          "/home/devadmin/hra-developers/hra-node/public",
          "",
        )}`;

        const link = document.createElement("a");
        link.href = fullZipUrl;
        link.setAttribute("download", "referral_pdfs.zip"); // Set a default filename for download
        document.body.appendChild(link);
        link.click();
        link.remove();
      } else {
        console.error("zipPath not found in the API response");
      }
    } catch (error) {
      console.error("Error downloading referrals", error);
    } finally {
      setLoading(false); // Stop the loader when API call is complete
    }
  };

  return (
    <>
      <div className="member-list">
        <QaFilterDialogs
          hraStatusOptions={hraStatusOptions}
          visitTypeOptions={visitTypeOptions}
          clinicianData={clinicianData}
          selectedDropdownValue={selectedDropdownValue}
        />
        <div className="member-list-left">
          {(clientData?.isAllSelected === true ||
            localStorage.getItem("isAllSelected") === "true") &&
          (clientData?.plan !== null || storedPlan) &&
          (clientData?.year !== null || storedYearList) ? (
            <span
              style={{
                width: "60%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Tooltip
                TransitionComponent={Zoom}
                title={`Selected: ${clientData?.plan || storedPlan}-${
                  clientData?.year || storedYear
                }`}
                placement="top"
                arrow
                enterDelay={150}
                leaveDelay={100}
              >
                <Box
                  className="filterBoxML"
                  onClick={handleBadgeClick}
                  style={{ borderRadius: "50px" }}
                >
                  <p>{`${clientData?.plan || storedPlan}-${
                    clientData?.year || storedYear
                  }`}</p>
                </Box>
              </Tooltip>
              <Tooltip
                title={
                  isAnalyticsActive
                    ? "Close Analytics View"
                    : "Open Analytics View"
                } // Dynamic tooltip text
                arrow
                enterDelay={100} // Delay before the tooltip appears
              >
                <BarChartOutlinedIcon
                  onClick={() => setIsAnalyticsActive(!isAnalyticsActive)}
                  sx={{
                    borderRadius: "8px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "35px",
                    height: "35px",
                    cursor: "pointer",
                    background: isAnalyticsActive
                      ? "var(--mainTheme)"
                      : "var(--mainTheme200)",
                    color: isAnalyticsActive ? "#fff" : "#4e4e4e",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    padding: "5px",
                  }}
                />
              </Tooltip>
            </span>
          ) : (
            <></>
          )}
        </div>
        <div className="member-list-right">
          <Box className="filterBoxFilterML" onClick={toggleOpen}>
            <p>Filter</p>
            <FilterAltIcon sx={{ height: "26px", width: "26px" }} />
          </Box>
          {graphFilter && (
            <FilterModal
              open={handleOpenFilter}
              onClose={handleCloseFilter}
              qa={qa}
              setQa={setQa}
              onFilterChange={onFilterChange}
              filters={filters}
              setFilters={setFilters}
              setGraphFilter={setGraphFilter}
              hra={hra}
              users={users}
              lob={lob}
              prog={prog}
            />
          )}
          {!isAnalyticsActive && (
            <>
              <SelectDropdown
                sx={{
                  borderRadius: 20,
                  width: "154px",
                  height: "40px",
                  fontSize: "16px",
                  fontWeight: 500,
                  marginRight: 1,
                  marginLeft: 2,
                  "&:hover": {
                    backgroundColor: "var(--mainTheme)",
                  },
                }}
                title="Identification"
                options={filterDropdown}
                className="dropdownMemberList"
                valueClass="dropdownValueMemberList"
                onChange={handleFilterDropdownChange}
              />
              <Box
                className="searchBoxML"
                sx={{ background: "var(--mainTheme200)" }}
              >
                <TextField
                  onKeyDown={(e) => handleSearch(e)}
                  placeholder="Search"
                  InputProps={{
                    disableUnderline: true,
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon
                          style={{ marginLeft: "5px", cursor: "pointer" }}
                          onClick={(e) => handleSearchOnIconClick()}
                        />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        {searchTerm && (
                          <CancelOutlinedIcon
                            style={{ marginRight: "5px", cursor: "pointer" }}
                            onClick={handleResetSearch}
                          />
                        )}
                      </InputAdornment>
                    ),
                  }}
                  variant="standard"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </Box>
            </>
          )}
        </div>
      </div>

      {!isAnalyticsActive &&
      (clientData?.isAllSelected === true ||
        localStorage.getItem("isAllSelected") === "true") &&
      (clientData?.plan !== null || storedPlan) &&
      (clientData?.year !== null || storedYearList) ? (
        <div
          style={{
            marginTop: "22px",
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <Box
            sx={{
              // width: "100%",
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <Tabs
              className="custom-tabs"
              value={selectedDropdownValue}
              onChange={(e, newValue) =>
                handleDropdownChange({ target: { value: newValue } })
              }
              aria-label="wrapped label tabs example"
            >
              {memberListOptions.map((option, index) => (
                <Tab key={index} value={option} label={option} />
              ))}
            </Tabs>
          </Box>
          {roleId == 6 && selectedDropdownValue == "Ready For QA" ? (
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              marginLeft={1}
            >
              <AntSwitch
                defaultChecked={unassignedChecked}
                onChange={handleUnassignedSwitchChange}
                inputProps={{ "aria-label": "ant design" }}
              />

              <Typography style={{ marginLeft: "5px" }}>UnAssigned</Typography>
            </Stack>
          ) : null}

          {roleId == 6 && selectedDropdownValue == "Referral" ? (
            <Box>
              <FormControlLabel
                control={<Checkbox onChange={onCheckboxChange} />}
                label="Unassigned"
              />
            </Box>
          ) : null}

          {selectedDropdownValue == "Referral" && roleId == 6 ? (
            <Box>
              <Button
                ml="15px"
                fontSize="15px"
                cursor="pointer"
                height="unset"
                background="#1076bc"
                mt="5px"
                padding="12px"
                disabled={!areCheckboxesSelected}
                onClick={() => handleDownloadReferrals(selectedRowIndex)}
                sx={{
                  background: "var(--mainTheme)",

                  color: "#fff !important",
                  cursor: "pointer",
                  marginLeft: "15px",
                  height: "unset",
                  marginTop: "5px",
                  padding: "12px",
                  borderRadius: "16px",
                  "&:hover": {
                    backgroundColor: "#1076BC !important",
                  },
                }}
              >
                Download Referrals
              </Button>

              {loading && <Loader />}
            </Box>
          ) : null}
        </div>
      ) : (
        <></>
      )}
      <Toaster
        open={toasterOpen}
        onClose={() => setToasterOpen(false)}
        severity={toasterSeverity}
        message={toasterMessage}
      />
    </>
  );
};

export default QAHeader;
