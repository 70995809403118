import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  InputLabel,
  Grid,
  Stack,
} from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import lodash from "lodash";

import {
  DFCommentIcon,
  DFCommentIconMemo,
  DFCommentTextBox,
  DFCommentTextBoxMemo,
  isCommentVisible,
  isCommentVisibleMemo,
} from "./DFComment";

import "./DynamicForms.css";

export const DFCheckbox = ({
  inputProps,
  showComment,
  handleCheckboxChange,
  value,
  handleTextChange,
  setShowComment,
  quesSlug,
  sectionId,
  commentValue,
  disableFlag,
  rosFormData,
  allergiesFormData,
  needMemo,
  getFieldValues,
  setFieldValues,
}) => {
  const { isCommentEnabled, name, label, variant } = inputProps;
  const inputValue = value && value?.length > 0 ? value : [];

  if (
    (sectionId == 10 && name === "gait_Recommendations") ||
    inputProps.name === "respiratory_Recommendations" ||
    inputProps.name === "integument_Recommendations" ||
    inputProps.name === "musko_Recommendations" ||
    inputProps.name === "hema_Recommendations" ||
    inputProps.name === "ros_cardiovascularRecommendations" ||
    inputProps.name === "ros_gastrorecommendations" ||
    inputProps.name === "ros_genitoRecommendations" ||
    inputProps.name === "ros_endocrineIsEndocrineRecommendations" ||
    inputProps.name === "ros_diagnosisOfCancerRecommendations" ||
    inputProps.name === "ros_eyeENTNeckRecommendations" ||
    inputProps.name === "ros_neuroRecommendations"
  ) {
    return (
      <Grid key={quesSlug} item>
        <Grid key={quesSlug} container alignItems="center">
          <Grid item xs={12}>
            <FormControl
              className="inputStyle"
              component="fieldset"
              variant="outlined"
              style={{
                width: "100%",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <FormLabel
                  className="inputStyle"
                  style={{ marginLeft: "15px" }}
                >
                  {label}
                </FormLabel>
              </div>
              {inputProps.options.map((option, index) => {
                return (
                  <div
                    key={index}
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div style={{ width: "100%" }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            className="inputStyle"
                            variant="outlined"
                            name={name}
                            disabled={disableFlag}
                            checked={inputValue?.includes(option[1])}
                            onChange={(event) =>
                              handleCheckboxChange(
                                event.target.name,
                                option[1],
                                event.target.checked,
                              )
                            }
                            {...(disableFlag && { disabled: true })}
                          />
                        }
                        label={option[0]}
                      />
                      {isCommentEnabled &&
                        (needMemo
                          ? isCommentVisibleMemo(getFieldValues, name)
                          : isCommentVisible(
                              showComment,
                              sectionId,
                              `${quesSlug}_${index}`,
                              rosFormData[`${name}${option[1]}Comment`],
                            )) && (
                          <DFCommentTextBox
                            label={label}
                            handleTextChange={handleTextChange}
                            name={`${name}${option[1]}`}
                            value={rosFormData[`${name}${option[1]}Comment`]}
                            disableFlag={disableFlag}
                            routedFrom={"checkbox"}
                          />
                        )}
                    </div>
                    {inputProps.isCommentEnabled === true &&
                      (needMemo ? (
                        <DFCommentIconMemo
                          getFieldValues={getFieldValues}
                          setFieldValues={setFieldValues}
                          name={name}
                          value={commentValue}
                          disableFlag={disableFlag}
                        />
                      ) : (
                        <DFCommentIcon
                          showComment={showComment}
                          setShowComment={setShowComment}
                          sectionId={sectionId}
                          quesSlug={`${quesSlug}_${index}`}
                          disableFlag={disableFlag}
                        />
                      ))}
                  </div>
                );
              })}
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    );
  }
  if (
    sectionId == 9 &&
    (name === "allergies_Recommendations2" ||
      name === "allergies_Recommendations")
  ) {
    return (
      <Grid key={quesSlug} item>
        <Grid key={quesSlug} container alignItems="center">
          <Grid item xs={12}>
            <FormControl
              className="inputStyle"
              component="fieldset"
              variant="outlined"
              style={{
                width: "100%",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <FormLabel
                  className="inputStyle"
                  style={{ marginLeft: "15px" }}
                >
                  {label}
                </FormLabel>
              </div>
              {inputProps.options.map((option, index) => {
                return (
                  <div
                    key={index}
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div style={{ width: "100%" }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            className="inputStyle"
                            variant="outlined"
                            name={name}
                            disabled={disableFlag}
                            checked={inputValue?.includes(option[1])}
                            onChange={(event) =>
                              handleCheckboxChange(
                                event.target.name,
                                option[1],
                                event.target.checked,
                              )
                            }
                            {...(disableFlag && { disabled: true })}
                          />
                        }
                        label={option[0]}
                      />
                      {isCommentEnabled &&
                        (needMemo
                          ? isCommentVisibleMemo(
                              getFieldValues,
                              `${name}${option[1]}`,
                            )
                          : isCommentVisible(
                              showComment,
                              sectionId,
                              `${quesSlug}_${index}`,
                              allergiesFormData[`${name}${option[1]}Comment`],
                            )) && (
                          <DFCommentTextBox
                            label={label}
                            handleTextChange={handleTextChange}
                            name={`${name}${option[1]}`}
                            value={
                              allergiesFormData[`${name}${option[1]}Comment`]
                            }
                            disableFlag={disableFlag}
                            routedFrom={"checkbox"}
                          />
                        )}
                    </div>
                    {inputProps.isCommentEnabled === true &&
                      (needMemo ? (
                        <DFCommentIconMemo
                          getFieldValues={getFieldValues}
                          setFieldValues={setFieldValues}
                          name={`${name}${option[1]}`}
                          value={commentValue}
                          disableFlag={disableFlag}
                        />
                      ) : (
                        <DFCommentIcon
                          showComment={showComment}
                          setShowComment={setShowComment}
                          sectionId={sectionId}
                          quesSlug={`${quesSlug}_${index}`}
                          disableFlag={disableFlag}
                        />
                      ))}
                  </div>
                );
              })}
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  /*
  to-do

  variant-1
    group of checkbox under single label

  variant-2
    single checkbox with no label
  */
  if (variant === 1) {
    return (
      <>
        <Stack direction="row">
          <Grid
            item
            xs={isCommentEnabled ? 11 : 12}
            component="fieldset"
            style={{
              borderWidth: 0.5,
              borderColor: "var(--mainTheme850)",
              borderStyle: "solid",
              borderRadius: "5px",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <FormLabel className="inputStyle" style={{ marginLeft: "15px" }}>
                {label}
              </FormLabel>
            </div>
            <br />
            <FormGroup className="inputStyle" fullWidth row>
              {inputProps.options.map((option, index) => (
                <div
                  key={quesSlug}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        className="inputStyle"
                        variant="outlined"
                        disabled={disableFlag}
                        name={name}
                        checked={inputValue?.includes(option[1])}
                        onChange={(event) =>
                          handleCheckboxChange(
                            event.target.name,
                            option[1],
                            event.target.checked,
                          )
                        }
                        {...(disableFlag && { disabled: true })}
                      />
                    }
                    label={option[0]}
                  />
                </div>
              ))}
            </FormGroup>
          </Grid>
          {inputProps.isCommentEnabled === true &&
            (needMemo ? (
              <DFCommentIconMemo
                getFieldValues={getFieldValues}
                setFieldValues={setFieldValues}
                name={name}
                value={commentValue}
                disableFlag={disableFlag}
              />
            ) : (
              <DFCommentIcon
                showComment={showComment}
                setShowComment={setShowComment}
                sectionId={sectionId}
                quesSlug={quesSlug}
                value={commentValue}
                disableFlag={disableFlag}
              />
            ))}
        </Stack>
        {inputProps.isCommentEnabled &&
          (needMemo
            ? isCommentVisibleMemo(getFieldValues, name)
            : isCommentVisible(
                showComment,
                sectionId,
                quesSlug,
                commentValue,
              )) && (
            <DFCommentTextBox
              label={label}
              handleTextChange={handleTextChange}
              name={name}
              value={commentValue}
              disableFlag={disableFlag}
            />
          )}
      </>
    );
  } else if (variant === 2) {
    return (
      <>
        <Stack direction="row">
          <Grid item xs={isCommentEnabled ? 11 : 12}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <FormLabel className="inputStyle" style={{ marginLeft: "15px" }}>
                {label}
              </FormLabel>
            </div>
            <br />
            <FormGroup className="inputStyle" fullWidth row>
              {inputProps.options.map((option, index) => (
                <div
                  key={quesSlug}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        className="inputStyle"
                        variant="outlined"
                        name={name}
                        disabled={disableFlag}
                        checked={value?.includes(option[1])}
                        // checked={value === "1"}
                        onChange={(event) =>
                          handleCheckboxChange(
                            event.target.name,
                            option[1],
                            event.target.checked,
                          )
                        }
                        {...(disableFlag && { disabled: true })}
                      />
                    }
                    label={option[0]}
                  />
                </div>
              ))}
            </FormGroup>
          </Grid>
          {inputProps.isCommentEnabled === true &&
            (needMemo ? (
              <DFCommentIconMemo
                getFieldValues={getFieldValues}
                setFieldValues={setFieldValues}
                name={name}
                value={commentValue}
                disableFlag={disableFlag}
              />
            ) : (
              <DFCommentIcon
                showComment={showComment}
                setShowComment={setShowComment}
                sectionId={sectionId}
                quesSlug={quesSlug}
                value={commentValue}
                disableFlag={disableFlag}
              />
            ))}
        </Stack>
        {inputProps.isCommentEnabled &&
          (needMemo
            ? isCommentVisibleMemo(getFieldValues, name)
            : isCommentVisible(
                showComment,
                sectionId,
                quesSlug,
                commentValue,
              )) && (
            <DFCommentTextBox
              label={label}
              handleTextChange={handleTextChange}
              name={name}
              value={commentValue}
              disableFlag={disableFlag}
            />
          )}
      </>
    );
  } else {
    return (
      <>
        <div style={{ display: "flex", alignItems: "center" }}>
          <InputLabel
            sx={{
              color: "var(--mainTheme)",
              fontWeight: "600",
            }}
          >
            {label || ""}
          </InputLabel>
        </div>
        <Stack direction="row">
          <Grid
            item
            // xs={isCommentEnabled ? 11 : 12}
            xs={12}
            // component="fieldset"
            // style={{
            //   borderWidth: 0.5,
            //   borderColor: "var(--mainTheme850)",
            //   borderStyle: "solid",
            //   borderRadius: "5px",
            // }}
          >
            <FormGroup className="inputStyle" fullWidth row>
              {inputProps.options.map((option, index) => (
                <div
                  key={`${quesSlug}_${index}`}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={disableFlag}
                          className="inputStyle"
                          variant="outlined"
                          name={name}
                          checked={inputValue?.includes(option[1])}
                          onChange={(event) =>
                            handleCheckboxChange(
                              event.target.name,
                              option[1],
                              event.target.checked,
                            )
                          }
                          {...(disableFlag && { disabled: true })}
                        />
                      }
                      label={option[0]}
                    />
                  </div>
                  {inputProps.isCommentEnabled === true &&
                    (needMemo ? (
                      <DFCommentIconMemo
                        getFieldValues={getFieldValues}
                        setFieldValues={setFieldValues}
                        name={name}
                        value={commentValue}
                        disableFlag={disableFlag}
                      />
                    ) : (
                      <DFCommentIcon
                        showComment={showComment}
                        setShowComment={setShowComment}
                        sectionId={sectionId}
                        quesSlug={quesSlug}
                        value={commentValue}
                        disableFlag={disableFlag}
                      />
                    ))}
                </div>
              ))}
            </FormGroup>
          </Grid>
        </Stack>
        {inputProps.isCommentEnabled &&
          (needMemo
            ? isCommentVisibleMemo(getFieldValues, name)
            : isCommentVisible(
                showComment,
                sectionId,
                quesSlug,
                commentValue,
              )) && (
            <DFCommentTextBox
              label={label}
              handleTextChange={handleTextChange}
              name={name}
              value={commentValue}
              disableFlag={disableFlag}
            />
          )}
      </>
    );
  }
};

DFCheckbox.propTypes = {
  inputProps: PropTypes.object.isRequired,
  showComment: PropTypes.object.isRequired,
  handleCheckboxChange: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired,
  handleTextChange: PropTypes.func.isRequired,
  setShowComment: PropTypes.func.isRequired,
  quesSlug: PropTypes.any.isRequired,
  sectionId: PropTypes.any.isRequired,
  commentValue: PropTypes.any.isRequired,
  disableFlag: PropTypes.bool,
};

const dontReRender = (oldProps, newProps) => {
  /*
   
  returns false - if there is a change between oldProps & newProps
  returns true - if there is no change between oldProps & newProps

  

  */

  return lodash.isEqual(JSON.stringify(oldProps), JSON.stringify(newProps));
};

export const DFCheckboxMemo = React.memo(
  ({
    inputProps,
    value,
    commentValue,
    quesSlug,
    sectionId,
    rosFormData,
    allergiesFormData,
    handleCheckboxChange,
    handleTextChange,
    getFieldValues,
    setFieldValues,
    disableFlag,
  }) => {
    const { isCommentEnabled, name, label, variant } = inputProps;
    const inputValue = value && value?.length > 0 ? value : [];

    if (
      (sectionId == 10 && name === "gait_Recommendations") ||
      inputProps.name === "respiratory_Recommendations" ||
      inputProps.name === "integument_Recommendations" ||
      inputProps.name === "musko_Recommendations" ||
      inputProps.name === "hema_Recommendations" ||
      inputProps.name === "ros_cardiovascularRecommendations" ||
      inputProps.name === "ros_gastrorecommendations" ||
      inputProps.name === "ros_genitoRecommendations" ||
      inputProps.name === "ros_endocrineIsEndocrineRecommendations" ||
      inputProps.name === "ros_diagnosisOfCancerRecommendations" ||
      inputProps.name === "ros_eyeENTNeckRecommendations"
    ) {
      return (
        <Grid key={quesSlug} item>
          <Grid key={quesSlug} container alignItems="center">
            <Grid item xs={12}>
              <FormControl
                className="inputStyle"
                component="fieldset"
                variant="outlined"
                style={{
                  width: "100%",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <FormLabel
                    className="inputStyle"
                    style={{ marginLeft: "15px" }}
                  >
                    {label}
                  </FormLabel>
                </div>
                {inputProps.options.map((option, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ width: "100%" }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              disabled={disableFlag}
                              className="inputStyle"
                              variant="outlined"
                              name={name}
                              checked={inputValue?.includes(option[1])}
                              onChange={(event) =>
                                handleCheckboxChange(
                                  event.target.name,
                                  option[1],
                                  event.target.checked,
                                )
                              }
                              {...(disableFlag && { disabled: true })}
                            />
                          }
                          label={option[0]}
                        />
                        {isCommentEnabled &&
                          isCommentVisibleMemo(
                            getFieldValues,
                            `${name}${option[1]}`,
                          ) && (
                            <DFCommentTextBox
                              label={label}
                              handleTextChange={handleTextChange}
                              name={`${name}${option[1]}`}
                              value={rosFormData[`${name}${option[1]}Comment`]}
                              disableFlag={disableFlag}
                              routedFrom={"checkbox"}
                            />
                          )}
                      </div>
                      {inputProps.isCommentEnabled === true && (
                        <DFCommentIcon
                          name={name}
                          value={commentValue}
                          getFieldValues={getFieldValues}
                          setFieldValues={setFieldValues}
                          disableFlag={disableFlag}
                        />
                      )}
                    </div>
                  );
                })}
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      );
    }
    if (
      sectionId == 9 &&
      (name === "allergies_Recommendations2" ||
        name === "allergies_Recommendations")
    ) {
      return (
        <Grid key={quesSlug} item>
          <Grid key={quesSlug} container alignItems="center">
            <Grid item xs={12}>
              <FormControl
                className="inputStyle"
                component="fieldset"
                variant="outlined"
                style={{
                  width: "100%",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <FormLabel
                    className="inputStyle"
                    style={{ marginLeft: "15px" }}
                  >
                    {label}
                  </FormLabel>
                </div>
                {inputProps.options.map((option, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ width: "100%" }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              disabled={disableFlag}
                              className="inputStyle"
                              variant="outlined"
                              name={name}
                              checked={inputValue?.includes(option[1])}
                              onChange={(event) =>
                                handleCheckboxChange(
                                  event.target.name,
                                  option[1],
                                  event.target.checked,
                                )
                              }
                              {...(disableFlag && { disabled: true })}
                            />
                          }
                          label={option[0]}
                        />
                        {isCommentEnabled &&
                          isCommentVisibleMemo(
                            getFieldValues,
                            `${name}${option[1]}`,
                          ) && (
                            <DFCommentTextBox
                              label={label}
                              handleTextChange={handleTextChange}
                              name={`${name}${option[1]}`}
                              value={
                                allergiesFormData[`${name}${option[1]}Comment`]
                              }
                              disableFlag={disableFlag}
                              routedFrom={"checkbox"}
                            />
                          )}
                      </div>
                      {inputProps.isCommentEnabled === true && (
                        <DFCommentIcon
                          name={name}
                          value={commentValue}
                          getFieldValues={getFieldValues}
                          setFieldValues={setFieldValues}
                          disableFlag={disableFlag}
                        />
                      )}
                    </div>
                  );
                })}
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      );
    }

    /*
  to-do

  variant-1
    group of checkbox under single label

  variant-2
    single checkbox with no label
  */
    if (variant === 1) {
      return (
        <>
          <Stack direction="row">
            <Grid
              item
              xs={isCommentEnabled ? 11 : 12}
              component="fieldset"
              style={{
                borderWidth: 0.5,
                borderColor: "var(--mainTheme850)",
                borderStyle: "solid",
                borderRadius: "5px",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <FormLabel
                  className="inputStyle"
                  style={{ marginLeft: "15px" }}
                >
                  {label}
                </FormLabel>
              </div>
              <br />
              <FormGroup className="inputStyle" fullWidth row>
                {inputProps.options.map((option, index) => (
                  <div
                    key={quesSlug}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={disableFlag}
                          className="inputStyle"
                          variant="outlined"
                          name={name}
                          checked={inputValue?.includes(option[1])}
                          onChange={(event) =>
                            handleCheckboxChange(
                              event.target.name,
                              option[1],
                              event.target.checked,
                            )
                          }
                          {...(disableFlag && { disabled: true })}
                        />
                      }
                      label={option[0]}
                    />
                  </div>
                ))}
              </FormGroup>
            </Grid>
            {inputProps.isCommentEnabled === true && (
              <DFCommentIcon
                name={name}
                value={commentValue}
                getFieldValues={getFieldValues}
                setFieldValues={setFieldValues}
                disableFlag={disableFlag}
              />
            )}
          </Stack>
          {inputProps.isCommentEnabled &&
            isCommentVisibleMemo(getFieldValues, name) && (
              <DFCommentTextBox
                label={label}
                handleTextChange={handleTextChange}
                name={name}
                value={commentValue}
                disableFlag={disableFlag}
              />
            )}
        </>
      );
    } else if (variant === 2) {
      return (
        <>
          <Stack direction="row">
            <Grid item xs={isCommentEnabled ? 11 : 12}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <FormLabel
                  className="inputStyle"
                  style={{ marginLeft: "15px" }}
                >
                  {label}
                </FormLabel>
              </div>
              <br />
              <FormGroup className="inputStyle" fullWidth row>
                {inputProps.options.map((option, index) => (
                  <div
                    key={quesSlug}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={disableFlag}
                          className="inputStyle"
                          variant="outlined"
                          name={name}
                          checked={value?.includes(option[1])}
                          // checked={value === "1"}
                          onChange={(event) =>
                            handleCheckboxChange(
                              event.target.name,
                              option[1],
                              event.target.checked,
                            )
                          }
                          {...(disableFlag && { disabled: true })}
                        />
                      }
                      label={option[0]}
                    />
                  </div>
                ))}
              </FormGroup>
            </Grid>
            {inputProps.isCommentEnabled === true && (
              <DFCommentIcon
                name={name}
                value={commentValue}
                getFieldValues={getFieldValues}
                setFieldValues={setFieldValues}
                disableFlag={disableFlag}
              />
            )}
          </Stack>
          {inputProps.isCommentEnabled &&
            isCommentVisibleMemo(getFieldValues, name) && (
              <DFCommentTextBox
                label={label}
                handleTextChange={handleTextChange}
                name={name}
                value={commentValue}
                disableFlag={disableFlag}
              />
            )}
        </>
      );
    } else {
      return (
        <>
          <div style={{ display: "flex", alignItems: "center" }}>
            <InputLabel
              sx={{
                color: "var(--mainTheme)",
                fontWeight: "600",
              }}
            >
              {label || ""}
            </InputLabel>
          </div>
          <Stack direction="row">
            <Grid
              item
              // xs={isCommentEnabled ? 11 : 12}
              xs={12}
              // component="fieldset"
              // style={{
              //   borderWidth: 0.5,
              //   borderColor: "var(--mainTheme850)",
              //   borderStyle: "solid",
              //   borderRadius: "5px",
              // }}
            >
              <FormGroup className="inputStyle" fullWidth row>
                {inputProps.options.map((option, index) => (
                  <div
                    key={`${quesSlug}_${index}`}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <div>
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled={disableFlag}
                            className="inputStyle"
                            variant="outlined"
                            name={name}
                            checked={inputValue?.includes(option[1])}
                            onChange={(event) =>
                              handleCheckboxChange(
                                event.target.name,
                                option[1],
                                event.target.checked,
                              )
                            }
                            {...(disableFlag && { disabled: true })}
                          />
                        }
                        label={option[0]}
                      />
                    </div>
                    {inputProps.isCommentEnabled === true && (
                      <DFCommentIcon
                        name={name}
                        value={commentValue}
                        getFieldValues={getFieldValues}
                        setFieldValues={setFieldValues}
                        disableFlag={disableFlag}
                      />
                    )}
                  </div>
                ))}
              </FormGroup>
            </Grid>
          </Stack>
          {inputProps.isCommentEnabled &&
            isCommentVisibleMemo(getFieldValues, name) && (
              <DFCommentTextBox
                label={label}
                handleTextChange={handleTextChange}
                name={name}
                value={commentValue}
                disableFlag={disableFlag}
              />
            )}
        </>
      );
    }
  },
  dontReRender,
);

DFCheckboxMemo.propTypes = {
  inputProps: PropTypes.object.isRequired,
  value: PropTypes.any.isRequired,
  commentValue: PropTypes.any.isRequired,
  quesSlug: PropTypes.any.isRequired,
  sectionId: PropTypes.any.isRequired,
  rosFormData: PropTypes.object,
  allergiesFormData: PropTypes.object,
  handleCheckboxChange: PropTypes.func.isRequired,
  handleTextChange: PropTypes.func.isRequired,
  getFieldValues: PropTypes.func.isRequired,
  setFieldValues: PropTypes.func.isRequired,
  disableFlag: PropTypes.bool,
};
