const json2023 = {
  sectionId: 1,
  sectionName: "Demographics",
  isEditable: false,
  questions: [
    {
      id: 1,
      questionId: 1,
      name: "demograph_plan",
      type: "text",
      label: "Plan",
      xs: 6,
      isCommentEnabled: false,
    },
    {
      id: 1,
      questionId: 2,
      name: "demograph_program",
      type: "text",
      label: "Program",
      xs: 6,
      isCommentEnabled: false,
    },
    {
      questionId: 3,
      name: "demograph_lob",
      type: "text",
      label: "LOB",
      xs: 4,
      isCommentEnabled: false,
    },
    {
      questionId: 4,
      name: "demograph_region",
      type: "text",
      label: "Region",
      xs: 4,
      isCommentEnabled: false,
    },
    {
      questionId: 5,
      name: "demograph_aligned",
      type: "text",
      label: "Aligned",
      xs: 4,
      isCommentEnabled: false,
    },
    {
      questionId: 6,
      name: "demograph_fullName",
      type: "text",
      label: "Name",
      xs: 8,
      disabled: true,
      isCommentEnabled: false,
    },
    {
      questionId: 7,
      name: "demograph_gender",
      label: "Gender",
      type: "radio",
      options: [
        ["Male", "2"],
        ["Female", "1"],
      ],
      xs: 4,
      isCommentEnabled: false,
    },
    {
      questionId: 8,
      name: "demograph_address",
      type: "text",
      label: "Address",
      xs: 4,
      isCommentEnabled: false,
    },
    {
      questionId: 9,
      name: "demograph_addressTwo",
      type: "text",
      label: "Address 2",
      xs: 4,
      isCommentEnabled: false,
    },
    {
      questionId: 10,
      name: "demograph_addressThree",
      type: "text",
      label: "Address 3",
      xs: 4,
      isCommentEnabled: false,
    },
    {
      questionId: 11,
      name: "demograph_city",
      type: "text",
      label: "City",
      xs: 3,
      isCommentEnabled: false,
    },
    {
      questionId: 12,
      name: "demograph_state",
      type: "text",
      label: "State",
      xs: 3,
      isCommentEnabled: false,
    },
    {
      questionId: 13,
      name: "demograph_zip",
      type: "text",
      label: "Zip",
      xs: 3,
      isCommentEnabled: false,
    },
    {
      questionId: 14,
      name: "demograph_county",
      type: "text",
      label: "County",
      xs: 3,
      isCommentEnabled: false,
    },
    {
      questionId: 15,
      name: "demograph_mAddressOne",
      type: "text",
      label: "Mailing Address 1",
      xs: 4,
      isCommentEnabled: false,
    },
    {
      questionId: 16,
      name: "demograph_mAddTwo",
      type: "text",
      label: "Mailing Address 2",
      xs: 4,
      isCommentEnabled: false,
    },
    {
      questionId: 17,
      name: "demograph_mAddressThree",
      type: "text",
      label: "Mailing Address 3",
      xs: 4,
      isCommentEnabled: false,
    },
    {
      questionId: 18,
      name: "demograph_mCity",
      type: "text",
      label: "Mailing City",
      xs: 3,
      isCommentEnabled: false,
    },
    {
      questionId: 19,
      name: "demograph_mState",
      type: "text",
      label: "Mailing State",
      xs: 3,
      isCommentEnabled: false,
    },
    {
      questionId: 20,
      name: "demograph_mZip",
      type: "text",
      label: "Mailing Zip",
      xs: 3,
      isCommentEnabled: false,
    },
    {
      questionId: 21,
      name: "demograph_mCounty",
      type: "text",
      label: "Mailing County",
      xs: 3,
      isCommentEnabled: false,
    },
    {
      questionId: 22,
      name: "demograph_dob",
      id: "date",
      type: "date",
      variant: "1",
      label: "Date of Birth",
      xs: 4,
      disableFutureFlag: true,
    },
    {
      questionId: 23,
      name: "demograph_age",
      type: "text",
      label: "Age(as of date)",
      xs: 4,
      isCommentEnabled: false,
      disabled: true,
    },
    {
      questionId: 24,
      name: "demograph_mStatus",
      label: "Marital Status",
      type: "select",
      options: [
        ["Divorced", "3"],
        ["Married", "2"],
        ["Registered Partnership", "6"],
        ["Seperated", "5"],
        ["Single", "1"],
        ["Widowed", "4"],
      ],
      xs: 4,
      isCommentEnabled: false,
    },
    {
      questionId: 25,
      name: "demograph_memberId",
      type: "text",
      disabled: true,
      label: "Member Identification Number",
      xs: 4,
      isCommentEnabled: false,
    },
    {
      questionId: 26,
      name: "demograph_hicn",
      type: "text",
      label: "HICN",
      disabled: true,
      xs: 4,
      isCommentEnabled: false,
    },
    {
      questionId: 27,
      name: "demograph_email",
      type: "email",
      label: "Email",
      xs: 4,
      isCommentEnabled: false,
    },
    {
      questionId: 28,
      name: "demograph_primary",
      type: "text",
      label: "Phone Number",
      pattern: "/^[d-]{10,12}$/",
      xs: 4,
      isCommentEnabled: false,
    },
    {
      questionId: 29,
      name: "demograph_secondary",
      type: "text",
      label: "Cell Number",
      pattern: "/^[d-]{10,12}$/",
      xs: 4,
      isCommentEnabled: false,
    },
    {
      questionId: 30,
      name: "demograph_aContact",
      type: "number",
      label: "Alternate Contact Number",
      xs: 4,
      isCommentEnabled: false,
    },
    {
      questionId: 31,
      name: "demograph_ename",
      type: "number",
      label: "Emergency Contact",
      xs: 6,
      isCommentEnabled: false,
    },
    {
      questionId: 32,
      name: "demograph_eNo",
      type: "text",
      label: "Emergency Contact Phone Number",
      xs: 6,
      isCommentEnabled: false,
    },
    {
      questionId: 33,
      name: "demograph_pName",
      type: "text",
      label: "Primary Care Physician",
      xs: 6,
      isCommentEnabled: false,
    },
    {
      questionId: 34,
      name: "demograph_pPhone",
      type: "number",
      label: "PCP Phone Number",
      xs: 3,
      isCommentEnabled: false,
    },
    {
      questionId: 35,
      name: "demograph_pFax",
      type: "number",
      label: "PCP Fax Number",
      xs: 3,
      isCommentEnabled: false,
    },
    {
      questionId: 36,
      name: "demograph_pAddOne",
      type: "text",
      label: "PCP Address",
      xs: 4,
      isCommentEnabled: false,
    },
    {
      questionId: 37,
      name: "demograph_pCity",
      type: "text",
      label: "PCP City",
      xs: 2,
      isCommentEnabled: false,
    },
    {
      questionId: 38,
      name: "demograph_pState",
      type: "text",
      label: "PCP State",
      xs: 2,
      isCommentEnabled: false,
    },
    {
      questionId: 39,
      name: "demograph_pZip",
      type: "text",
      label: "PCP Zip",
      xs: 2,
      isCommentEnabled: false,
    },
    {
      questionId: 40,
      name: "demograph_pcpcounty",
      type: "text",
      label: "PCP County",
      xs: 2,
      isCommentEnabled: false,
    },
    {
      questionId: 41,
      name: "demograph_pcpNpi",
      type: "number",
      label: "PCP NPI",
      xs: 4,
      isCommentEnabled: false,
    },
    {
      questionId: 42,
      name: "demograph_pId",
      type: "number",
      label: "Office ID",
      xs: 4,
      isCommentEnabled: false,
    },
    {
      questionId: 43,
      name: "demograph_pOfcName",
      type: "text",
      label: "Office Name",
      xs: 4,
      isCommentEnabled: false,
    },
    {
      questionId: 44,
      name: "horizontalLine",
      label: "Horizontal Line",
      type: "divider",
      xs: 12,
      isCommentEnabled: false,
    },
    {
      questionId: 45,
      label: "1. Race",
      value: "",
      type: "options_tree",
      xs: 12,
      subQuestions: [
        {
          subQuestionId: 1,
          label: "Caucasian",
          type: "toggle_button",
          value: "caucasian",
          hasSubQuestions: false,
          subQuestions: [{}],
        },
        {
          subQuestionId: 2,
          label: "African American",
          type: "toggle_button",
          value: "africanAmerican",
          hasSubQuestions: false,
          subQuestions: [{}],
        },
        {
          subQuestionId: 3,
          label: "Asian",
          type: "toggle_button",
          value: "asian",
          hasSubQuestions: false,
          subQuestions: [{}],
        },
        {
          subQuestionId: 4,
          label: "Latino",
          type: "toggle_button",
          value: "latino",
          hasSubQuestions: false,
          subQuestions: [{}],
        },
        {
          subQuestionId: 5,
          label: "Native American",
          type: "toggle_button",
          value: "nativeAmerican",
          hasSubQuestions: false,
          subQuestions: [{}],
        },
        {
          subQuestionId: 6,
          label: "Native Hawaiian or other Pacific Islander",
          type: "toggle_button",
          value: "nativeHawaiin",
          hasSubQuestions: false,
          subQuestions: [{}],
        },
        {
          subQuestionId: 7,
          label: "Alaskan Native",
          type: "toggle_button",
          value: "alaskanNative",
          hasSubQuestions: false,
          subQuestions: [{}],
        },
        {
          subQuestionId: 8,
          label: "Other",
          type: "toggle_button",
          value: "other",
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              name: "demograph_raceOtherSubDesc",
              type: "text",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
            },
          ],
        },
      ],
      isCommentEnabled: true,
      name: "demograph_race",
    },
    {
      questionId: 46,
      name: "horizontalLine",
      label: "Horizontal Line",
      type: "divider",
      xs: 12,
      isCommentEnabled: false,
    },
    {
      questionId: 47,
      name: "demograph_patientEthnic",
      label: "Patient's Ethnicity",
      value: "",
      type: "options_tree",
      xs: 12,
      subQuestions: [
        {
          subQuestionId: 1,
          label: "Hispanic",
          type: "toggle_button",
          value: "hispanic",
          hasSubQuestions: false,
          subQuestions: [{}],
        },
        {
          subQuestionId: 2,
          label: "Non-Hispanic",
          type: "toggle_button",
          value: "nonHispanic",
          hasSubQuestions: false,
          subQuestions: [{}],
        },
        {
          subQuestionId: 3,
          label: "Other Ethnicity",
          type: "toggle_button",
          value: "otherethnicity",
          hasSubQuestions: false,
          subQuestions: [{}],
        },
        {
          subQuestionId: 4,
          label: "Prefer not to say",
          type: "toggle_button",
          value: "prefernot",
          hasSubQuestions: false,
          subQuestions: [{}],
        },
      ],
      isCommentEnabled: true,
    },
    {
      questionId: 48,
      name: "horizontalLine",
      label: "Horizontal Line",
      type: "divider",
      xs: 12,
      isCommentEnabled: false,
    },
    {
      questionId: 49,
      name: "demograph_prefLang",
      label: "Preferred language",
      value: "",
      type: "options_tree",
      xs: 12,
      subQuestions: [
        {
          subQuestionId: 1,
          label: "English",
          type: "toggle_button",
          value: "english",
          hasSubQuestions: false,
          subQuestions: [{}],
        },
        {
          subQuestionId: 2,
          label: "Other",
          type: "toggle_button",
          value: "other",
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "demograph_prefLangOtherSub",
              type: "options_tree",
              label: "If other,",
              xs: 12,
              isCommentEnabled: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "African languages",
                  value: "african",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Arabic",
                  value: "arabic",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Chinese",
                  value: "chinese",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "French",
                  value: "french",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 5,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "French Creole",
                  value: "frenchCreole",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 6,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "German",
                  value: "german",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 7,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Greek",
                  value: "greek",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 8,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Gujarati",
                  value: "gujarati",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 9,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Hebrew",
                  value: "hebrew",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 10,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Hindi",
                  value: "hindi",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 11,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Hungarian",
                  value: "hungarian",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 12,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Italian",
                  value: "italian",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 13,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Japanese",
                  value: "japanese",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 14,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Korean",
                  value: "korean",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 15,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Persian",
                  value: "persian",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 16,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Polish",
                  value: "polish",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 17,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Portuguese",
                  value: "portuguese",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 18,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Russian",
                  value: "russian",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 19,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Scandinavian Languages",
                  value: "scandinavian",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 20,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Serbo-Croatian",
                  value: "serbo",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 21,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Spanish",
                  value: "spanish",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 22,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Tagalog",
                  value: "tagalog",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 23,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Urdu",
                  value: "urdu",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 24,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Vietnamese",
                  value: "vietnamese",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 25,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Yiddish",
                  value: "yiddish",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 1,
            },
          ],
        },
      ],
      isCommentEnabled: true,
    },
  ],
};

const json2024 = {
  sectionId: 1,
  sectionName: "Demographics",
  isEditable: false,
  questions: [
    {
      id: 1,
      questionId: 1,
      name: "demograph_plan",
      type: "text",
      label: "Plan",
      xs: 6,
      isCommentEnabled: false,
    },
    {
      id: 1,
      questionId: 2,
      name: "demograph_program",
      type: "text",
      label: "Program",
      xs: 6,
      isCommentEnabled: false,
    },
    {
      questionId: 3,
      name: "demograph_lob",
      type: "text",
      label: "LOB",
      xs: 4,
      isCommentEnabled: false,
    },
    {
      questionId: 4,
      name: "demograph_region",
      type: "text",
      label: "Region",
      xs: 4,
      isCommentEnabled: false,
    },
    {
      questionId: 5,
      name: "demograph_aligned",
      type: "text",
      label: "Aligned",
      xs: 4,
      isCommentEnabled: false,
    },
    {
      questionId: 6,
      name: "demograph_fullName",
      type: "text",
      label: "Name",
      xs: 7,
      disabled: true,
      isCommentEnabled: false,
    },
    {
      questionId: 7,
      name: "demograph_genderAtBirth",
      label: "Gender at birth",
      defaultValue: "2",
      type: "radio",
      options: [
        ["Female", "1"],
        ["Male", "2"],
      ],
      xs: 5,
      isCommentEnabled: false,
    },
    {
      id: 5,
      name: "demograph_currentGender",
      label: "What is your current gender identity?",
      defaultValue: "",
      type: "select",
      options: [
        ["Female", "1"],
        ["Male", "2"],
        ["Non Binary", "3"],
        ["Other", "4"],
        ["Transgender", "5"],
      ],
      xs: 6,
      isCommentEnabled: false,
      questionId: 8,
    },
    {
      questionId: 9,
      name: "demograph_currentGenderComment",
      type: "text",
      label: "Current Gender Comment",
      xs: 6,
      isCommentEnabled: false,
    },
    {
      questionId: 10,
      name: "demograph_address",
      type: "text",
      label: "Address",
      xs: 3,
      isCommentEnabled: false,
    },
    {
      questionId: 11,
      name: "demograph_addressTwo",
      type: "text",
      label: "Address 2",
      xs: 3,
      isCommentEnabled: false,
    },
    {
      questionId: 12,
      name: "demograph_addressThree",
      type: "text",
      label: "Address 3",
      xs: 3,
      isCommentEnabled: false,
    },
    {
      questionId: 13,
      name: "demograph_city",
      type: "text",
      label: "City",
      xs: 3,
      isCommentEnabled: false,
    },
    {
      questionId: 14,
      name: "demograph_state",
      type: "text",
      label: "State",
      xs: 2,
      isCommentEnabled: false,
    },
    {
      questionId: 15,
      name: "demograph_zip",
      type: "text",
      label: "Zip",
      xs: 2,
      isCommentEnabled: false,
    },
    {
      questionId: 16,
      name: "demograph_mAddressOne",
      type: "text",
      label: "Mailing Address 1",
      xs: 4,
      isCommentEnabled: false,
    },
    {
      questionId: 17,
      name: "demograph_mAddTwo",
      type: "text",
      label: "Mailing Address 2",
      xs: 4,
      isCommentEnabled: false,
    },
    {
      questionId: 18,
      name: "demograph_mAddressThree",
      type: "text",
      label: "Mailing Address 3",
      xs: 4,
      isCommentEnabled: false,
    },
    {
      questionId: 19,
      name: "demograph_mCity",
      type: "text",
      label: "Mailing City",
      xs: 4,
      isCommentEnabled: false,
    },
    {
      questionId: 20,
      name: "demograph_mState",
      type: "text",
      label: "Mailing State",
      xs: 2,
      isCommentEnabled: false,
    },
    {
      questionId: 21,
      name: "demograph_mZip",
      type: "text",
      label: "Mailing Zip",
      xs: 2,
      isCommentEnabled: false,
    },
    {
      questionId: 22,
      name: "demograph_dob",
      id: "date",
      type: "date",
      variant: "1",
      label: "Date of Birth",
      xs: 4,
      disableFutureFlag: true,
    },
    {
      questionId: 23,
      name: "demograph_age",
      type: "text",
      label: "Age(as of date)",
      xs: 4,
      isCommentEnabled: false,
      disabled: true,
    },
    {
      questionId: 24,
      name: "demograph_mStatus",
      label: "Marital Status",
      type: "select",
      options: [
        ["Divorced", "3"],
        ["Married", "2"],
        ["Registered Partnership", "6"],
        ["Seperated", "5"],
        ["Single", "1"],
        ["Widowed", "4"],
      ],
      xs: 4,
      isCommentEnabled: false,
    },
    {
      questionId: 25,
      name: "demograph_memberId",
      type: "number",
      disabled: true,
      label: "Member Identification Number",
      xs: 3,
      isCommentEnabled: false,
    },
    {
      questionId: 26,
      name: "demograph_uniqueId",
      type: "text",
      disabled: true,
      label: "Unique Identification Number",
      xs: 3,
      isCommentEnabled: false,
    },
    {
      questionId: 27,
      name: "demograph_medicareId",
      type: "text",
      disabled: true,
      label: "Medicare Identification Number",
      xs: 3,
      isCommentEnabled: false,
    },
    {
      questionId: 28,
      name: "demograph_medicaidId",
      type: "text",
      disabled: true,
      label: "Medicaid Identification Number",
      xs: 3,
      isCommentEnabled: false,
    },
    {
      questionId: 29,
      name: "demograph_email",
      type: "email",
      label: "Email",
      xs: 3,
      isCommentEnabled: false,
    },
    {
      questionId: 30,
      name: "demograph_primary",
      type: "text",
      label: "Phone Number",
      pattern: "/^[d-]{10,12}$/",
      xs: 3,
      isCommentEnabled: false,
    },
    {
      questionId: 31,
      name: "demograph_secondary",
      type: "text",
      label: "Cell Number",
      pattern: "/^[d-]{10,12}$/",
      xs: 3,
      isCommentEnabled: false,
    },
    {
      questionId: 32,
      name: "demograph_aContact",
      type: "number",
      label: "Alternate Contact Number",
      xs: 3,
      isCommentEnabled: false,
    },
    {
      questionId: 33,
      name: "demograph_ename",
      type: "number",
      label: "Emergency Contact",
      xs: 6,
      isCommentEnabled: false,
    },
    {
      questionId: 34,
      name: "demograph_eNo",
      type: "text",
      label: "Emergency Contact Phone Number",
      xs: 6,
      isCommentEnabled: false,
    },
    {
      questionId: 35,
      name: "demograph_pName",
      type: "text",
      label: "Primary Care Physician",
      xs: 6,
      isCommentEnabled: false,
    },
    {
      questionId: 36,
      name: "demograph_pPhone",
      type: "number",
      label: "PCP Phone Number",
      xs: 3,
      isCommentEnabled: false,
    },
    {
      questionId: 37,
      name: "demograph_pFax",
      type: "number",
      label: "PCP Fax Number",
      xs: 3,
      isCommentEnabled: false,
    },
    {
      questionId: 38,
      name: "demograph_pAddOne",
      type: "text",
      label: "PCP Address",
      xs: 4,
      isCommentEnabled: false,
    },
    {
      questionId: 39,
      name: "demograph_pCity",
      type: "text",
      label: "PCP City",
      xs: 2,
      isCommentEnabled: false,
    },
    {
      questionId: 40,
      name: "demograph_pState",
      type: "text",
      label: "PCP State",
      xs: 2,
      isCommentEnabled: false,
    },
    {
      questionId: 41,
      name: "demograph_pZip",
      type: "text",
      label: "PCP Zip",
      xs: 2,
      isCommentEnabled: false,
    },
    {
      questionId: 42,
      name: "demograph_pcpcounty",
      type: "text",
      label: "PCP County",
      xs: 2,
      isCommentEnabled: false,
    },
    {
      questionId: 43,
      name: "demograph_pcpNpi",
      type: "number",
      label: "PCP NPI",
      xs: 3,
      isCommentEnabled: false,
    },
    {
      questionId: 44,
      name: "demograph_pId",
      type: "number",
      label: "Office ID",
      xs: 3,
      isCommentEnabled: false,
    },
    {
      questionId: 45,
      name: "demograph_pOfcName",
      type: "text",
      label: "Office Name",
      xs: 6,
      isCommentEnabled: false,
    },
    {
      questionId: 46,
      name: "demograph_pNameComment",
      type: "text",
      label: "Physician Comment",
      xs: 6,
      isCommentEnabled: false,
    },
    {
      questionId: 47,
      name: "demograph_pFlag",
      label: "Were updates made to the PCP details?",
      type: "checkbox",
      options: [["Yes", "yes"]],
      xs: 6,
      isCommentEnabled: false,
    },
    {
      questionId: 48,
      name: "horizontalLine",
      label: "Horizontal Line",
      type: "divider",
      xs: 12,
      isCommentEnabled: false,
    },
    {
      questionId: 49,
      label: "1. Race",
      value: "",
      type: "options_tree",
      xs: 12,
      subQuestions: [
        {
          subQuestionId: 1,
          label: "Caucasian",
          type: "toggle_button",
          value: "caucasian",
          hasSubQuestions: false,
          subQuestions: [{}],
        },
        {
          subQuestionId: 2,
          label: "African American",
          type: "toggle_button",
          value: "africanAmerican",
          hasSubQuestions: false,
          subQuestions: [{}],
        },
        {
          subQuestionId: 3,
          label: "Asian",
          type: "toggle_button",
          value: "asian",
          hasSubQuestions: false,
          subQuestions: [{}],
        },
        {
          subQuestionId: 4,
          label: "Latino",
          type: "toggle_button",
          value: "latino",
          hasSubQuestions: false,
          subQuestions: [{}],
        },
        {
          subQuestionId: 5,
          label: "Native American",
          type: "toggle_button",
          value: "nativeAmerican",
          hasSubQuestions: false,
          subQuestions: [{}],
        },
        {
          subQuestionId: 6,
          label: "Native Hawaiian or other Pacific Islander",
          type: "toggle_button",
          value: "nativeHawaiin",
          hasSubQuestions: false,
          subQuestions: [{}],
        },
        {
          subQuestionId: 7,
          label: "Alaskan Native",
          type: "toggle_button",
          value: "alaskanNative",
          hasSubQuestions: false,
          subQuestions: [{}],
        },
        {
          subQuestionId: 8,
          label: "Other",
          type: "toggle_button",
          value: "other",
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              name: "demograph_raceOtherSubDesc",
              type: "text",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
            },
          ],
        },
      ],
      isCommentEnabled: true,
      name: "demograph_race",
    },
    {
      questionId: 50,
      name: "horizontalLine",
      label: "Horizontal Line",
      type: "divider",
      xs: 12,
      isCommentEnabled: false,
    },
    {
      questionId: 51,
      name: "demograph_patientEthnic",
      label: "Patient's Ethnicity",
      value: "",
      type: "options_tree",
      xs: 12,
      subQuestions: [
        {
          subQuestionId: 1,
          label: "Hispanic",
          type: "toggle_button",
          value: "hispanic",
          hasSubQuestions: false,
          subQuestions: [{}],
        },
        {
          subQuestionId: 2,
          label: "Non-Hispanic",
          type: "toggle_button",
          value: "nonHispanic",
          hasSubQuestions: false,
          subQuestions: [{}],
        },
        {
          subQuestionId: 3,
          label: "Other Ethnicity",
          type: "toggle_button",
          value: "otherethnicity",
          hasSubQuestions: false,
          subQuestions: [{}],
        },
        {
          subQuestionId: 4,
          label: "Prefer not to say",
          type: "toggle_button",
          value: "prefernot",
          hasSubQuestions: false,
          subQuestions: [{}],
        },
      ],
      isCommentEnabled: true,
    },
    {
      questionId: 52,
      name: "horizontalLine",
      label: "Horizontal Line",
      type: "divider",
      xs: 12,
      isCommentEnabled: false,
    },
    {
      questionId: 53,
      name: "demograph_prefLang",
      label: "Preferred language",
      value: "",
      type: "options_tree",
      xs: 12,
      subQuestions: [
        {
          subQuestionId: 1,
          label: "English",
          type: "toggle_button",
          value: "english",
          hasSubQuestions: false,
          subQuestions: [{}],
        },
        {
          subQuestionId: 2,
          label: "Other",
          type: "toggle_button",
          value: "other",
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "demograph_prefLangOtherSub",
              type: "options_tree",
              label: "If other,",
              xs: 12,
              isCommentEnabled: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "African languages",
                  value: "african",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Arabic",
                  value: "arabic",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Chinese",
                  value: "chinese",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "French",
                  value: "french",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 5,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "French Creole",
                  value: "frenchCreole",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 6,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "German",
                  value: "german",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 7,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Greek",
                  value: "greek",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 8,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Gujarati",
                  value: "gujarati",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 9,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Hebrew",
                  value: "hebrew",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 10,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Hindi",
                  value: "hindi",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 11,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Hungarian",
                  value: "hungarian",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 12,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Italian",
                  value: "italian",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 13,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Japanese",
                  value: "japanese",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 14,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Korean",
                  value: "korean",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 15,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Persian",
                  value: "persian",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 16,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Polish",
                  value: "polish",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 17,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Portuguese",
                  value: "portuguese",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 18,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Russian",
                  value: "russian",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 19,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Scandinavian Languages",
                  value: "scandinavian",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 20,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Serbo-Croatian",
                  value: "serbo",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 21,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Spanish",
                  value: "spanish",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 22,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Tagalog",
                  value: "tagalog",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 23,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Urdu",
                  value: "urdu",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 24,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Vietnamese",
                  value: "vietnamese",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 25,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Yiddish",
                  value: "yiddish",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 1,
            },
          ],
        },
      ],
      isCommentEnabled: true,
    },
    {
      id: 6,
      name: "demograph_religious",
      type: "options_tree",
      label:
        "Are there any cultural, religious, or spiritual beliefs that your healthcare providers need to be aware of to provide appropriate care?",
      fullWidth: true,
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 1,
              name: "demograph_religiousSubDesc",
              type: "text",
              label: "Comments",
              xs: 12,
              placeholder: "",
              defaultValue: "",
              isCommentEnabled: false,
              isModified: true,
              subQuestionId: 1,
            },
          ],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 54,
    },
  ],
};
export const getInputsData = (client = "VPHP", year = "2023") => {
  let formLayoutData = {};
  if (year === "2023") {
    formLayoutData = {
      ...formLayoutData,
      ...json2023,
    };
  }
  if (year === "2024") {
    formLayoutData = {
      ...formLayoutData,
      ...json2024,
    };
  }

  return formLayoutData;
};
