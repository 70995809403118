import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import React, { useEffect, useState, useContext } from "react";
import { MdEdit, MdOutlineSave } from "react-icons/md";
import Toaster from "../../../components/toaster/toaster";
import { format } from "date-fns";
import "./DynamicForms.css";
import { useFillHraContext } from "../../../contexts/fillHraFormContext";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { getDemographicsData } from "../MemberList/Fill_HRA/Sections/Demographics/demographicsMapping";
import { useUserContext } from "../../../contexts/UserContext";
import _ from "lodash";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { getDatetimeDayJS } from "../../../utils/appConstants";

// Extend Day.js with plugins
dayjs.extend(utc);
dayjs.extend(timezone);

export const HeaderCard = ({ formData, getSectionDbData, fromPedsForm }) => {
  //const { firstName, lastName, middleName, plan } = formData;
  const [name, setName] = useState("");
  const [dob, setDob] = useState("");

  const [_plan, setPlan] = useState();
  const [evaluationDate, setEvaluationDate] = useState();
  const { selectedTimeZoneUtc, selectedTimeZone } = useUserContext();
  useEffect(() => {
    console.log("hello");
    console.log("selectedTimeZone", selectedTimeZone);
  }, [selectedTimeZone]);
  useEffect(() => {
    const finalDate = sessionStorage.getItem("finalDate");
    const __evaluationDate = sessionStorage.getItem("evaluationDate");
    if (finalDate != "null") {
      let _evaluationDate = new Date(finalDate);
      if (_evaluationDate != "null") {
        setEvaluationDate(new Date(sessionStorage.getItem("finalDate")));
      } else {
        setEvaluationDate(null);
      }
    } else if (__evaluationDate != "null") {
      setEvaluationDate(new Date(sessionStorage.getItem("evaluationDate")));
    } else {
      setEvaluationDate(null);
    }
  }, [selectedTimeZoneUtc, selectedTimeZone]);
  const _getDemographicsData = async () => {
    const name = sessionStorage.getItem("patientName");
    const plan = localStorage.getItem("clientPlan");
    setPlan(plan);
    setName(name);
    if (Boolean(getSectionDbData)) {
      const data = await getSectionDbData(1);

      const _dob = formatDate(data?.dob);
      setDob(_dob);
    } else if (fromPedsForm) {
      const _dob = formatDate(
        JSON.parse(JSON.stringify(sessionStorage.getItem("dob"))),
      );
      setDob(_dob);
    }
  };

  const formatDate = (inputDate) => {
    if (Boolean(inputDate) && inputDate != "undefined") {
      const dateObj = new Date(inputDate);

      const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
      const day = dateObj.getDate().toString().padStart(2, "0");
      const year = dateObj.getFullYear();

      // Format the date as MM/DD/YYYY
      const formattedDate = `${month}/${day}/${year}`;

      return formattedDate;
    } else return "";
  };

  useEffect(() => {
    _getDemographicsData();
  }, []);
  const assignedClinician = sessionStorage.getItem("selectedClinicianName");
  return (
    // <div>
    //   <Card
    //     // style={{
    //     //   width: "100% !important",
    //     //   height: "86px",
    //     //   marginLeft: "0px",
    //     //   marginRight: "10px",
    //     //   display: "flex",

    //     //   flexDirection: "column",
    //     // }}
    //     /* className="Header" */
    //     elevation={5}
    //     className="custom-card"
    //   >
    //     <CardContent>
    //       <Box
    //         // style={{
    //         //   display: "flex",
    //         //   flexFlow: "column noWrap",
    //         //   flexDirection: "row",
    //         //   marginBottom: "12px",
    //         //   justifyContent: "space-between",
    //         // }}
    //       >
    //         <div
    //           // style={{
    //           //   display: "flex",
    //           //   flexDirection: "column",
    //           //   alignItems: "flex-start",
    //           // }}
    //         >
    //           <Grid item xs={4}
    //           //style={{ marginBottom: "16px" }}
    //           >
    //             <Typography
    //               variant="p"
    //               // style={{ fontSize: "16px", fontWeight: 800 }}
    //             >
    //               Health Plan:{" "}
    //               <Typography
    //                 variant="p"
    //                 // style={{ fontWeight: 500, marginLeft: "10px" }}
    //               >
    //                 {_plan}
    //               </Typography>
    //             </Typography>
    //           </Grid>
    //           <Grid item xs={4} style={{ display: "contents" }}>
    //             <Typography
    //               variant="p"
    //               // style={{ fontWeight: 800, fontSize: "16px" }}
    //             >
    //               Assessment Type:
    //               <Typography
    //                 variant="p"
    //                 style={{
    //                   fontWeight: 500,
    //                   marginLeft: "10px",
    //                   fontSize: "16px",
    //                 }}
    //               >
    //                 Health Risk Assesment
    //               </Typography>
    //             </Typography>
    //           </Grid>
    //         </div>
    //         <div
    //           style={{
    //             display: "flex",
    //             flexDirection: "column",
    //             alignItems: "flex-start",
    //           }}
    //         >
    //           <Grid item xs={4} style={{ marginBottom: "16px" }}>
    //             <Typography
    //               variant="p"
    //               style={{
    //                 fontWeight: 800,
    //                 fontSize: "16px",
    //                 marginLeft: "34px",
    //               }}
    //             >
    //               Member Name:{" "}
    //               <Typography
    //                 variant="p"
    //                 style={{
    //                   fontWeight: 500,
    //                   marginLeft: "10px",
    //                   fontSize: "16px",
    //                 }}
    //               >
    //                 {/* {`${firstName} ${middleName} ${lastName}`} */}
    //                 {name}
    //               </Typography>
    //             </Typography>
    //           </Grid>
    //           <Grid item xs={4}>
    //             <Typography
    //               variant="p"
    //               style={{
    //                 fontWeight: 800,
    //                 fontSize: "16px",
    //                 marginLeft: "35px",
    //               }}
    //             >
    //               DOB:
    //               <Typography
    //                 variant="p"
    //                 style={{
    //                   fontWeight: 500,
    //                   marginLeft: "10px",
    //                   fontSize: "16px",
    //                 }}
    //               >
    //                 {dob}
    //               </Typography>
    //             </Typography>
    //           </Grid>
    //         </div>
    //         <div
    //           style={{
    //             display: "flex",
    //             flexDirection: "column",
    //             alignItems: "flex-start",
    //           }}
    //         >
    //           <Grid
    //             item
    //             xs={4}
    //             style={{ marginRight: "29px", marginBottom: "16px" }}
    //           >
    //             <Typography
    //               variant="p"
    //               style={{
    //                 fontWeight: 800,
    //                 fontSize: "16px",
    //                 marginLeft: "82px",
    //               }}
    //             >
    //               Evaluator Name:{" "}
    //               <Typography
    //                 variant="p"
    //                 style={{
    //                   fontWeight: 500,
    //                   marginLeft: "10px",
    //                   fontSize: "16px",
    //                 }}
    //               >
    //                 {assignedClinician}
    //               </Typography>
    //             </Typography>
    //           </Grid>
    //           <Grid item xs={4} style={{ marginLeft: "70px" }}>
    //             <Typography
    //               variant="p"
    //               style={{
    //                 fontWeight: 800,
    //                 fontSize: "16px",
    //                 marginLeft: "10px",
    //               }}
    //             >
    //               Evaluation Date:
    //               <Typography
    //                 variant="p"
    //                 style={{
    //                   fontWeight: 500,
    //                   marginLeft: "10px",
    //                   fontSize: "16px",
    //                 }}
    //               >
    //                 {Boolean(evaluationDate)
    //                   ? dayjs(evaluationDate)
    //                       ?.tz(selectedTimeZoneUtc)
    //                       .format("MM-DD-YYYY hh:mm:ss A")
    //                   : //getDatetimeDayJS(evaluationDate,selectedTimeZone)
    //                     ""}
    //                 {/* 03-16-2024 11:41 AM */}
    //                 {/* {evaluationDate !== null  || Boolean(evaluationDate)? format(new Date(evaluationDate), "MM-dd-yyyy hh:mm aa") : ""}  */}
    //               </Typography>
    //             </Typography>
    //           </Grid>
    //         </div>
    //       </Box>
    //       <Box
    //         style={{
    //           display: "flex",
    //           flexFlow: "column noWrap",
    //           flexDirection: "row",
    //           justifyContent: "space-between",
    //         }}
    //       ></Box>
    //     </CardContent>
    //   </Card>
    // </div>
    <Card elevation={5} className="custom-card">
      <CardContent className="card-content-container">
        <Box className="card-content">
          <Typography variant="p" className="field">
            <span className="label">Health Plan:</span>
            <span className="value">{_plan}</span>
          </Typography>

          <Typography variant="p" className="field">
            <span className="label">Member Name:</span>
            <span className="value">{name}</span>
          </Typography>

          <Typography variant="p" className="field">
            <span className="label">Evaluator Name:</span>
            <span className="value">{assignedClinician}</span>
          </Typography>

          <Typography variant="p" className="field">
            <span className="label">Assessment Type:</span>
            <span className="value">Health Risk Assessment</span>
          </Typography>

          <Typography variant="p" className="field">
            <span className="label">DOB:</span>
            <span className="value">{dob}</span>
          </Typography>

          <Typography variant="p" className="field">
            <span className="label">Evaluation Date:</span>
            <span className="value">
              {Boolean(evaluationDate)
                ? dayjs(evaluationDate)
                    ?.tz(selectedTimeZoneUtc)
                    .format("MM-DD-YYYY hh:mm:ss A")
                : ""}
            </span>
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export const GlobalTextField = ({
  inputProps,
  formData,
  handleChange,
  quesindex,
  section,
  commentIndex,
  setCommentIndex,
  isCommentEnabled,
  isConfigured,
  setFormData,
  setComment,
  updateCommentInFormData,
}) => {
  const handleKeyDown = (e) => {
    if (
      inputProps.type === "number" &&
      (e.key === "e" || e.key === "+" || e.key === "-" || e.key === "E")
    ) {
      e.preventDefault();
    }
  };

  const toggleComment = () => {
    if (commentIndex[`${quesindex}_${section?.sectionId}`] !== undefined) {
      setCommentIndex({
        ...commentIndex,
        [`${quesindex}_${section?.sectionId}`]:
          !commentIndex[`${quesindex}_${section?.sectionId}`],
      });
    } else {
      setCommentIndex({
        ...commentIndex,
        [`${quesindex}_${section?.sectionId}`]: true,
      });
    }
  };

  return (
    <>
      <Stack direction="row">
        <Grid item xs={inputProps.isCommentEnabled === true ? 12 : 11}>
          <TextField
            value={formData[inputProps.name] || ""}
            variant="outlined"
            label="Outlined Input"
            sx={{
              height: "48px",
              border:
                inputProps.isConfigured === true
                  ? "1.5px solid var(--mainTheme) !important"
                  : " 1.5px solid rgba(255, 45, 49, 0.7) !important",
              "& .MuiFormLabel-root": {
                backgroundColor: "white",
              },
              "& .MuiInputLabel-root": {
                backgroundColor: "white",
              },
            }}
            {...{
              ...inputProps,
              value: formData[inputProps.name] || "",
              pattern: formData[inputProps.pattern],
            }}
            onChange={(e) => {
              handleChange(e, inputProps);
            }}
            onKeyDown={handleKeyDown}
            fullWidth
          />
        </Grid>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {inputProps.isCommentEnabled === true && (
            <Grid item xs={1} style={{ textAlign: "right" }}>
              <ChatBubbleIcon
                style={{
                  cursor: "pointer",
                  color: "var(--mainTheme)",
                }}
                onClick={toggleComment}
              />
            </Grid>
          )}
        </div>
      </Stack>

      {commentIndex[`${quesindex}_${section?.sectionId}`] === true ||
      commentIndex[`${quesindex}_${section?.sectionId}`] === undefined ? (
        <></>
      ) : (
        <span>
          <TextField
            {...{
              label: "Comments",

              placeholder: "Comments if any",
            }}
            value={formData[`${inputProps?.name}Comment`]}
            onChange={(event) => {
              setComment(event.target.value);
              updateCommentInFormData(inputProps.name, event.target.value);
            }}
            multiline
            maxRows={4}
            fullWidth
            variant="outlined"
            className="inputStyle"
            // size="small"
            sx={{
              "& .MuiFormLabel-root": {
                color: "var(--mainTheme)",
              },
              "& .MuiFormLabel-root.Mui-focused": {
                color: "var(--mainTheme)",
              },
              marginTop: "20px",
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <ArrowForwardIcon
                    sx={{
                      size: "medium",
                      color: "var(--mainTheme)",
                    }}
                  />
                </InputAdornment>
              ),
            }}
          />
        </span>
      )}
    </>
  );
};

export const CustomCheckboxField = ({
  inputProps,
  formData,
  handleChangeCheckbox,
  quesindex,
  section,
  commentIndex,
  setCommentIndex,
  getCommentBasedOnInputProps,
  setComment,
  updateCommentInFormData,
  showConsentedToVideo,
  showMemberConsentedToCompleteVirtualVisit,
  showMembersIdentityConfirmedWithID,
  disabled,
}) => {
  const isConsentedToVideoCheckbox = inputProps.name === "consentedToVideo";
  const isMemberConsentedToCompleteVirtualVisitCheckbox =
    inputProps.name === "memberConsentedToCompleteVirtualVisit";
  const isMembersIdentityConfirmedWithIDCheckbox =
    inputProps.name === "membersIdentityConfirmedWithID";

  if (isConsentedToVideoCheckbox && !showConsentedToVideo) {
    return null;
  }
  if (
    isMemberConsentedToCompleteVirtualVisitCheckbox &&
    !showMemberConsentedToCompleteVirtualVisit
  ) {
    return null;
  }

  if (
    isMembersIdentityConfirmedWithIDCheckbox &&
    !showMembersIdentityConfirmedWithID
  ) {
    return null;
  }

  const isPreCheckedSubAnswers = formData.recommendationsSubAnswers?.some(
    (subAnswer) => subAnswer[inputProps.name] === "1",
  );

  const inputValue = formData[inputProps.name] || "";
  const isPreChecked = inputValue === "1";

  const toggleComment = () => {
    if (commentIndex[quesindex + section?.sectionId] !== undefined) {
      setCommentIndex({
        ...commentIndex,
        [quesindex + section?.sectionId]:
          !commentIndex[quesindex + section?.sectionId],
      });
    } else {
      setCommentIndex({
        ...commentIndex,
        [quesindex + section?.sectionId]: true,
      });
    }
  };

  const toggleCommentNew = (p1, p2, p3 = "", p4 = "", p5 = "") => {
    let cmntIdxVal = `${p1}_${p2}`;
    cmntIdxVal = cmntIdxVal + `${p3 ? `_${p3}` : ""}`;
    cmntIdxVal = cmntIdxVal + `${p4 ? `_${p4}` : ""}`;
    cmntIdxVal = cmntIdxVal + `${p5 ? `_${p5}` : ""}`;

    if (commentIndex[cmntIdxVal] !== undefined) {
      setCommentIndex({
        ...commentIndex,
        [cmntIdxVal]: !commentIndex[cmntIdxVal],
      });
    } else {
      setCommentIndex({
        ...commentIndex,
        [cmntIdxVal]: true,
      });
    }
  };

  if (
    section?.sectionId == 9 &&
    section.sectionName === "Allergies/Medication" &&
    inputProps.name === "allergies_Recommendations"
  ) {
    const selectedValues = formData?.["allergies_Recommendations"];
    // formData?.[inputProps?.name] || [];
    return (
      <Grid key={inputProps.questionId} container alignItems="center">
        <Grid item xs={11}>
          <FormControl
            className="inputStyle"
            component="fieldset"
            {...inputProps}
            variant="outlined"
            style={{ marginLeft: "20px" }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <FormLabel className="inputStyle" style={{ marginLeft: "15px" }}>
                {inputProps.label}
              </FormLabel>
            </div>
            {inputProps.options.map((option, index) => (
              <div key={index} style={{ display: "grid", direction: "row" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        className="inputStyle"
                        variant="outlined"
                        name={inputProps.name}
                        checked={selectedValues?.includes(option?.[1])}
                        onChange={(e) => handleChangeCheckbox(e, inputProps)}
                        value={option[1]}
                        disabled={disabled}
                      />
                    }
                    // label={option[0]}
                    label={
                      <Typography
                        sx={{
                          color: "var(--mainTheme)",
                          fontWeight: "600 !important",
                        }}
                        variant="body2"
                      >
                        {" "}
                        {option[0]}
                      </Typography>
                    }
                  />
                  {inputProps.isCommentEnabled === true && (
                    <Grid item xs={1} style={{ textAlign: "right" }}>
                      <ChatBubbleIcon
                        style={{
                          cursor: "pointer",
                          color: "var(--mainTheme)",
                        }}
                        onClick={(e) =>
                          toggleCommentNew(
                            section?.sectionId,
                            quesindex,
                            index + 1,
                          )
                        }
                        // onClick={toggleComment}
                      />
                    </Grid>
                  )}
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {commentIndex[
                    `${section?.sectionId}_${quesindex}_${index + 1}`
                  ] === true ||
                  commentIndex[
                    `${section?.sectionId}_${quesindex}_${index + 1}`
                  ] === undefined ? (
                    <></>
                  ) : (
                    <Grid item xs={12}>
                      <span>
                        <TextField
                          label="Comments"
                          // value={getCommentBasedOnInputProps(inputProps.name)}
                          value={getCommentBasedOnInputProps(
                            `${inputProps.name}${option[1]}`,
                          )}
                          onChange={(event) => {
                            setComment(event.target.value);
                            updateCommentInFormData(
                              `${inputProps.name}${option[1]}`,
                              event.target.value,
                            );
                            // updateCommentInFormData(
                            //   inputProps.name,
                            //   event.target.value
                            // );
                          }}
                          placeholder="Comments if any"
                          multiline
                          maxRows={4}
                          fullWidth
                          variant="outlined"
                          className="inputStyle"
                          sx={{
                            "& .MuiFormLabel-root": {
                              color: "var(--mainTheme)",
                            },
                            "& .MuiFormLabel-root.Mui-focused": {
                              color: "var(--mainTheme)",
                            },
                            marginTop: "20px",
                            marginLeft: "10px",
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <ArrowForwardIcon
                                  sx={{
                                    size: "medium",
                                    color: "var(--mainTheme)",
                                  }}
                                />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </span>
                    </Grid>
                  )}
                </div>
              </div>
            ))}
          </FormControl>
        </Grid>
      </Grid>
    );
  }
  if (
    (section?.sectionId == 10 &&
      section.sectionName === "Review Of Systems And Diagnoses" &&
      inputProps.name === "gait_Recommendations") ||
    inputProps.name === "respiratory_Recommendations" ||
    inputProps.name === "integument_Recommendations" ||
    inputProps.name === "musko_Recommendations" ||
    inputProps.name === "hema_Recommendations" ||
    inputProps.name === "ros_diagnosisOfCancerRecommendations" ||
    inputProps.name === "ros_cardiovascularRecommendations" ||
    inputProps.name === "ros_gastrorecommendations" ||
    inputProps.name === "ros_endocrineIsEndocrineRecommendations" ||
    inputProps.name === "ros_genitoRecommendations"
  ) {
    const selectedValues = formData[`${inputProps.name}`]; // formData?.[inputProps?.name] || [];
    return (
      <Grid key={inputProps.questionId} container alignItems="center">
        <Grid item xs={11}>
          <FormControl
            className="inputStyle"
            component="fieldset"
            {...inputProps}
            variant="outlined"
            style={{ marginLeft: "20px" }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <FormLabel className="inputStyle" style={{ marginLeft: "15px" }}>
                {inputProps.label}
              </FormLabel>
            </div>
            {inputProps.options.map((option, index) => (
              <div key={index} style={{ display: "grid", direction: "row" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        className="inputStyle"
                        variant="outlined"
                        name={inputProps.name}
                        checked={selectedValues?.includes(option?.[1])}
                        onChange={(e) => handleChangeCheckbox(e, inputProps)}
                        value={option[1]}
                      />
                    }
                    label={option[0]}
                  />
                  {inputProps.isCommentEnabled === true && (
                    <Grid item xs={1} style={{ textAlign: "right" }}>
                      <ChatBubbleIcon
                        style={{
                          cursor: "pointer",
                          color: "var(--mainTheme)",
                        }}
                        onClick={(e) =>
                          toggleCommentNew(
                            section?.sectionId,
                            quesindex,
                            index + 1,
                          )
                        }
                        // onClick={toggleComment}
                      />
                    </Grid>
                  )}
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {commentIndex[
                    `${section?.sectionId}_${quesindex}_${index + 1}`
                  ] === true ||
                  commentIndex[
                    `${section?.sectionId}_${quesindex}_${index + 1}`
                  ] === undefined ? (
                    <></>
                  ) : (
                    <Grid item xs={12}>
                      <span>
                        <TextField
                          label="Comments"
                          // value={getCommentBasedOnInputProps(inputProps.name)}
                          value={getCommentBasedOnInputProps(
                            `${inputProps.name}${option[1]}`,
                          )}
                          onChange={(event) => {
                            setComment(event.target.value);
                            updateCommentInFormData(
                              `${inputProps.name}${option[1]}`,
                              event.target.value,
                            );
                            // updateCommentInFormData(
                            //   inputProps.name,
                            //   event.target.value
                            // );
                          }}
                          placeholder="Comments if any"
                          multiline
                          maxRows={4}
                          fullWidth
                          variant="outlined"
                          className="inputStyle"
                          sx={{
                            "& .MuiFormLabel-root": {
                              color: "var(--mainTheme)",
                            },
                            "& .MuiFormLabel-root.Mui-focused": {
                              color: "var(--mainTheme)",
                            },
                            marginTop: "20px",
                            marginLeft: "10px",
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <ArrowForwardIcon
                                  sx={{
                                    size: "medium",
                                    color: "var(--mainTheme)",
                                  }}
                                />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </span>
                    </Grid>
                  )}
                </div>
              </div>
            ))}
          </FormControl>
        </Grid>
      </Grid>
    );
  } else {
    const isPreCheckedSubAnswers = formData.recommendationsSubAnswers?.some(
      (subAnswer) => subAnswer[inputProps.name] === "1",
    );

    const inputValue = formData[inputProps.name] || "";
    const isPreChecked = inputValue === "1";

    return (
      <Grid key={inputProps.questionId} container alignItems="center">
        <Grid item xs={11}>
          <FormControl
            className="inputStyle"
            component="fieldset"
            {...inputProps}
            variant="outlined"
            style={{ marginLeft: "20px" }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <FormLabel className="inputStyle" style={{ marginLeft: "15px" }}>
                {inputProps.label}
              </FormLabel>
            </div>

            {inputProps.options.map((option, index) => (
              <div
                key={index}
                style={{ display: "flex", alignItems: "center" }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      className="inputStyle"
                      variant="outlined"
                      name={inputProps.name}
                      checked={
                        inputProps.name === "memberAcknowledgement"
                          ? isPreChecked
                          : inputProps.name === "acceptDisclosure"
                          ? isPreChecked
                          : inputProps.name === "consentedToVideo"
                          ? isPreChecked
                          : inputProps.name ===
                            "memberConsentedToCompleteVirtualVisit"
                          ? isPreChecked
                          : inputProps.name === "membersIdentityConfirmedWithID"
                          ? isPreChecked
                          : inputProps.name === "recommendationsFurther"
                          ? isPreChecked
                          : inputProps.name === "recommendationsScreenings"
                          ? isPreChecked
                          : inputProps.name === "recommendationsOther"
                          ? isPreChecked
                          : inputProps.name === "recommendationsAbdominal"
                          ? isPreChecked
                          : inputProps.name === "recommendationsHepatitis"
                          ? isPreChecked
                          : inputProps.name === "recommendationsOtherss"
                          ? isPreChecked
                          : inputProps.name === "recommendationsDoctor"
                          ? isPreChecked
                          : inputProps.name === "recommendationsSide"
                          ? isPreChecked
                          : inputProps.name === "educatedonImportance"
                          ? isPreChecked
                          : isPreCheckedSubAnswers
                      }
                      onChange={(e) => handleChangeCheckbox(e, inputProps)}
                      value={option[1]}
                      disabled={disabled}
                    />
                  }
                  label={option[0]}
                />
              </div>
            ))}
          </FormControl>
        </Grid>

        {inputProps.isCommentEnabled === true && (
          <Grid item xs={1} style={{ textAlign: "right" }}>
            <ChatBubbleIcon
              style={{
                cursor: "pointer",
                color: "var(--mainTheme)",
              }}
              onClick={toggleComment}
            />
          </Grid>
        )}

        {commentIndex[quesindex + section?.sectionId] === true ||
        commentIndex[quesindex + section?.sectionId] === undefined ? (
          <></>
        ) : (
          <Grid item xs={12}>
            <span>
              <TextField
                label="Comments"
                value={getCommentBasedOnInputProps(inputProps.name)}
                onChange={(event) => {
                  setComment(event.target.value);
                  updateCommentInFormData(inputProps.name, event.target.value);
                }}
                placeholder="Comments if any"
                multiline
                maxRows={4}
                fullWidth
                variant="outlined"
                className="inputStyle"
                sx={{
                  "& .MuiFormLabel-root": {
                    color: "var(--mainTheme)",
                  },
                  "& .MuiFormLabel-root.Mui-focused": {
                    color: "var(--mainTheme)",
                  },
                  marginTop: "20px",
                  marginLeft: "10px",
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <ArrowForwardIcon
                        sx={{
                          size: "medium",
                          color: "var(--mainTheme)",
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </span>
          </Grid>
        )}
      </Grid>
    );
  }
};

export const CustomSelectField = ({
  inputProps,
  formData,
  handleChange,
  handleVisitTypeChange,
  style,
  disabled,
}) => {
  const getPreselectedValue = () => {
    if (inputProps.name === "visitType") {
      const visitTypeData = formData.visitType || "Select";

      if (visitTypeData === "inPerson") {
        return "inPerson";
      } else if (visitTypeData === "virtual") {
        return "virtual";
      }
    }
    return formData[inputProps.name] || "";
  };
  const preselectedValue = getPreselectedValue();
  return (
    <Grid
      key={inputProps.questionId}
      // xs={inputProps.xs}
      // sm={inputProps.sm}
      xs={12}
      sm={6}
      md={4}
      item
      //fullWidth
      style={{
        marginTop: "6px",
        border: "0px !important",
        maxWidth: "100%",
      }}
    >
      <FormControl variant="outlined" fullWidth {...inputProps}>
        {/* <InputLabel
          style={{
            color: "rgba(0,0,0,0.38)!important",
          }}
        >
          {inputProps.label}
        </InputLabel> */}

        <Select
          label={inputProps.label}
          variant="outlined"
          disabled={disabled}
          style={{ borderRadius: "4px" }}
          sx={{
            height: "auto",
            width: "100%",
            border: inputProps.isConfigured
              ? "1.5px solid var(--mainTheme) !important"
              : "1.5px solid rgba(255, 45, 49, 0.7) !important",
            "& .MuiFormLabel-root": {
              backgroundColor: "white",
              color: "rgba(0,0,0,0.38)!important",
              borderRadius: "0px !important",
            },
            "& .MuiInputLabel-root": {
              backgroundColor: "white",
              color: "rgba(0,0,0,0.38)!important",
              borderRadius: "0px !important",
            },
            maxWidth: "100%",
          }}
          name={inputProps.name}
          value={preselectedValue || "Select"}
          onChange={(e) => {
            if (inputProps.name === "visitType") {
              handleVisitTypeChange(e);
            } else {
              handleChange(e, inputProps);
            }
          }}
          fullWidth
        >
          {inputProps.options.map((item, index) => (
            <MenuItem key={index} value={item[1]}>
              {item[0]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  );
};

export const CustomLabelField = ({
  inputProps,
  formData,
  commentIndex,
  setCommentIndex,
  quesindex,
  section,
  getCommentBasedOnInputProps,
  setComment,
  updateCommentInFormData,
}) => {
  const toggleComment = () => {
    if (
      commentIndex[quesindex + section?.section + inputProps.label] !==
      undefined
    ) {
      setCommentIndex({
        ...commentIndex,
        [quesindex + section?.sectionId + inputProps.label]:
          !commentIndex[quesindex + section?.section + inputProps.label],
      });
    } else {
      setCommentIndex({
        ...commentIndex,
        [quesindex + section?.sectionId + inputProps.label]: true,
      });
    }
  };

  return (
    <Grid key={inputProps.questionId} xs={inputProps.xs} item>
      <Stack
        direction="row"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Grid item>
          <FormControl
            className="inputStyle"
            component="fieldset"
            variant="outlined"
            {...inputProps}
            value={formData[inputProps.name] || ""}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "10px",
              }}
            >
              <>
                {inputProps.label == "Assessor's Comments:" ? (
                  <FormLabel
                    className="inputStyle"
                    style={{ marginLeft: "3px" }}
                  >
                    <Typography style={{ fontWeight: 600 }}>
                      {inputProps.label}
                    </Typography>
                  </FormLabel>
                ) : inputProps.label == "Comments :" &&
                  formData.visitType === "virtual" ? (
                  <FormLabel
                    className="inputStyle"
                    style={{ marginLeft: "3px" }}
                  >
                    <Typography style={{ fontWeight: 600 }}>
                      {inputProps.label}
                    </Typography>
                  </FormLabel>
                ) : (
                  <React.Fragment>
                    {formData.visitType === "virtual" && (
                      <FormLabel
                        className="inputStyle"
                        style={{ marginLeft: "3px" }}
                      >
                        {inputProps.label}
                      </FormLabel>
                    )}
                  </React.Fragment>
                )}
              </>
            </div>
          </FormControl>
          {commentIndex[quesindex + section?.sectionId + inputProps.label] ===
            true ||
          commentIndex[quesindex + section?.sectionId + inputProps.label] ===
            undefined ? (
            <></>
          ) : (
            <Grid item xs={12} style={{ width: "900px" }}>
              <span>
                <TextField
                  label="Comments"
                  value={getCommentBasedOnInputProps(inputProps.name)}
                  onChange={(event) => {
                    setComment(event.target.value);
                    updateCommentInFormData(
                      inputProps.name,
                      event.target.value,
                    );
                  }}
                  placeholder="Comments if any"
                  multiline
                  maxRows={4}
                  fullWidth
                  variant="outlined"
                  className="inputStyle"
                  sx={{
                    "& .MuiFormLabel-root": {
                      color: "var(--mainTheme)",
                    },
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "var(--mainTheme)",
                    },
                    marginTop: "20px",
                    marginLeft: "10px",
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <ArrowForwardIcon
                          sx={{
                            size: "medium",
                            color: "var(--mainTheme)",
                          }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </span>
            </Grid>
          )}
        </Grid>

        <div>
          {inputProps.isCommentEnabled === true && (
            <Grid item xs={1} style={{ textAlign: "right" }}>
              <ChatBubbleIcon
                style={{
                  cursor: "pointer",
                  color: "var(--mainTheme)",
                }}
                onClick={toggleComment}
              />
            </Grid>
          )}
        </div>
      </Stack>
    </Grid>
  );
};

export const CustomHeadingField = ({ inputProps, formData }) => {
  return (
    <Stack direction="row">
      <Grid
        item
        xs={12}
        sm={12}
        style={{
          backgroundColor: "#1976d2",
          textTransform: "uppercase",
        }}
      >
        <FormControl
          className="inputStyle"
          component="fieldset"
          variant="outlined"
          {...inputProps}
          value={formData[inputProps.name] || ""}
          sx={{
            "& .MuiFormLabel-root": {
              //color: "#fff",
              backgroundColor: "#1976d2 !important",
            },
            ".css-u4tvz2-MuiFormLabel-root": {
              color: "#fff !important",
            },
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: "5px",
              marginBottom: "5px",
            }}
          >
            <FormLabel
              className="inputStyle"
              style={{
                marginLeft: "3px",
                backgroundColor: "#1976d2 !important",
                fontWeight: 600,
                color: "#fff",
              }}
            >
              {inputProps.label}
            </FormLabel>
          </div>
        </FormControl>
      </Grid>
    </Stack>
  );
};

export const CustomImageField = ({ inputProps }) => {
  return (
    <Grid xs={inputProps.xs}>
      <div
        style={{
          width: "50%",
          marginTop: "2%",
          marginBottom: "1%",
          marginLeft: "1%",
        }}
      >
        <img
          src={inputProps.src}
          alt={inputProps.alt}
          style={{
            width: "100%",
            height: "60px",
          }}
        />
      </div>
    </Grid>
  );
};

export const CustomToggleButtonGroup = ({
  quesindex,
  inputProps,
  formData,
  handleChange,
  setToggleIndex,
  roleId,
  sectionName,
}) => {
  const selectedValue = formData[inputProps.name] || "";

  const handleToggleChange = (e) => {
    if (roleId !== 5) {
      handleChange(e, inputProps, inputProps.name, inputProps.value);
    }

    if (sectionName !== "Screenings Needed") {
      handleChange(e, inputProps, inputProps.name, inputProps.value);
    }

    setToggleIndex(quesindex);
  };

  return (
    <ToggleButtonGroup
      exclusive
      //exclusive={inputProps.isExclusive}
      fullWidth
      key={quesindex}
      aria-label={inputProps.label}
      role="group"
      size="large"
      onChange={handleToggleChange}
      selected={
        inputProps.name === "specialDiet" ? formData.specialDiet : selectedValue
      }
      name={inputProps.name}
      value={selectedValue}
      sx={{
        flexDirection: "row",
        flexWrap: "wrap",
      }}
    >
      {inputProps?.subQuestions?.map((subQuestion) => (
        <ToggleButton
          key={subQuestion.subQuestionId}
          exclusive
          value={subQuestion.value}
          onClick={() => setToggleIndex(quesindex)}
          aria-pressed
          name={inputProps.name}
          role="button"
          aria-label={subQuestion.label}
          sx={{
            minWidth: "235px",
            overflow: "hidden",
            display: "inline-block",
            whiteSpace: "normal",
            minHeight: "58px",
            margin: "12px",
            width: "230px",
            textAlign: "center",
            justifyContent: "center",
          }}
        >
          {subQuestion.label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

// export const CustomToggleButtonGroup = ({
//   quesindex,
//   inputProps,
//   formData,
//   handleChange,
//   setToggleIndex,
// }) => {
//   const selectedValue = formData[inputProps.name] || "";

//   return (
//     <ToggleButtonGroup
//       exclusive
//       fullWidth
//       key={quesindex}
//       aria-label={inputProps.label}
//       role="group"
//       size="large"
//       onChange={(e) => {
//         handleChange(e, inputProps, inputProps.name, inputProps.value);
//         setToggleIndex(quesindex);
//       }}
//       selected={
//         inputProps.name === "specialDiet" ? formData.specialDiet : selectedValue
//       }
//       name={inputProps.name}
//       value={selectedValue}
//       sx={{
//         flexDirection: "row",
//         flexWrap: "wrap",
//       }}
//     >
//       {inputProps?.subQuestions?.map((subQuestion) => (
//         <ToggleButton
//           key={subQuestion.subQuestionId}
//           exclusive
//           value={subQuestion.value}
//           onClick={() => setToggleIndex(quesindex)}
//           aria-pressed
//           name={inputProps.name}
//           role="button"
//           aria-label={subQuestion.label}
//           sx={{
//             minWidth: "235px",
//             overflow: "hidden",
//             display: "inline-block",
//             whiteSpace: "normal",
//             minHeight: "58px",
//             margin: "12px",
//             width: "230px",
//             textAlign: "center",
//             justifyContent: "center",
//           }}
//         >
//           {subQuestion.label}
//         </ToggleButton>
//       ))}
//     </ToggleButtonGroup>
//   );
// };
export const CustomRadioGroupField = ({
  inputProps,
  formData,
  handleChangeRadio,

  // value
}) => {
  const name = inputProps.name.trim();

  const data = formData[name] ? formData[name] : "right";

  return (
    <Stack direction="row">
      <Grid item>
        <FormControl
          className="inputStyle"
          component="fieldset"
          variant="outlined"
        >
          <div
            style={{ display: "flex", flexDirection: "row", marginTop: "10px" }}
          >
            <FormLabel className="inputStyle" style={{ marginLeft: "3px" }}>
              {inputProps.label}
            </FormLabel>
            <RadioGroup
              className="inputStyle"
              variant="outlined"
              sx={{ marginLeft: "10px" }}
              row
              name={inputProps.name}
              //  value={ formData.status_options_____}
              value={
                formData[inputProps.name.trim()]
                  ? formData[inputProps.name.trim()]
                  : inputProps.defaultValue
              }
              onChange={(e) => handleChangeRadio(e, inputProps)}
            >
              {inputProps.options.map((option, index) => (
                <FormControlLabel
                  key={index}
                  control={<Radio />}
                  label={option[0]}
                  value={option[1]}
                />
              ))}
            </RadioGroup>
          </div>
        </FormControl>
      </Grid>
    </Stack>
  );
};
export const CardSections = ({
  toasterOpen,
  setToasterOpen,
  toasterSeverity,
  toasterMessage,
  sections,
  currSection,
  handleCardClick,
  handleEditForm,
  handleFieldConfigCheck,
  routedFrom,
  screenOnlyRoute,
  handleMiniSave,
  handlePreventiveSave,
  handleSavesFData,
  handleAllergiesSave,
  // handleMedicalSave,
  //handlePainSave,
  //handleVitalSave,
  handleSelfSave,
  handleScreeningsSave,
  //handleCovidSave,
  handlePatientSave,
  // handleActivitiesSave,
  handleRosSave,
  saveSectionFormData,
  sideBarSectionDisable,
}) => {
  const currentSection = sections[currSection];
  const {
    // saveSectionFormData,
    getReroute,
  } = useFillHraContext();
  const navigate = useNavigate();

  const userRoleId = sessionStorage.getItem("roleId");

  const isIconEnabled = userRoleId == 1 || userRoleId == 2 || userRoleId == 5;

  return (
    // <Card elevation={0} style={{ height: "auto", minHeight: "70vh" }}>
    //   <CardHeader
    //     title="Sections"
    //     sx={{
    //       backgroundColor: "var(--mainTheme900)",
    //       color: "white",
    //       padding: "6px",
    //       boxShadow: "4px 4px 8px 0px #00000045",
    //       textAlign: "left !important",
    //       fontWeight: 500,
    //       textTransform: "uppercase",
    //       letterSpacing: "1px",
    //     }}
    //     titleTypographyProps={{ variant: "outline" }}
    //     action={
    //       <>
    //         {routedFrom === "FillHra" ||
    //         routedFrom === "PedsForm" ||
    //         screenOnlyRoute === "ScreenOnly" ? (
    //           <> </>
    //         ) : (
    //           <MdEdit
    //             size={20}
    //             color="white"
    //             onClick={(e) => handleEditForm(e)}
    //             style={{
    //               marginTop: "1px",
    //               marginRight: "5px",
    //               marginLeft: "5px",
    //               cursor: "pointer",
    //             }}
    //           />
    //         )}
    //       </>
    //     }
    //   />
    //   <CardContent
    //     style={{
    //       border: "0px",
    //       padding: "0px",
    //       margin:
    //         routedFrom === "PedsForm" || screenOnlyRoute === "ScreenOnly"
    //           ? "0px"
    //           : "1px",
    //       height: "auto",
    //       minHeight: "70vh",
    //       maxHeight: "412px",
    //       overflowY: "auto",
    //       backgroundColor: `var(--mainTheme100)`,
    //     }}
    //   >
    //     <Toaster
    //       open={toasterOpen}
    //       onClose={() => setToasterOpen(false)}
    //       severity={toasterSeverity}
    //       message={toasterMessage}
    //     />
    //     <Grid //right side sections name
    //       container
    //       direction="row"
    //       justifyContent="space-evenly"
    //       alignItems="center"
    //     >
    //       <Grid item xs={12}>
    //         {sections.map((section, sectionIndex) => {
    //           return (
    //             <Paper
    //               sx={{
    //                 backgroundColor:
    //                   currSection === sectionIndex
    //                     ? "var(--mainTheme300)"
    //                     : "0ffffff",
    //                 margin: "7px 5px",
    //                 width: "inherit",
    //                 borderRadius: "5px",
    //                 textAlign: "left",
    //                 "&:hover": {
    //                   backgroundColor: !sideBarSectionDisable
    //                     ? "var(--mainTheme300)"
    //                     : undefined,
    //                 },
    //               }}
    //               key={sectionIndex}
    //             >
    //               <Box
    //                 onClick={
    //                   !sideBarSectionDisable
    //                     ? () => handleCardClick(sectionIndex)
    //                     : undefined
    //                 }
    //               >
    //                 <Tooltip
    //                   TransitionComponent={Zoom}
    //                   title={`${section.sectionName}`}
    //                   placement="top"
    //                   arrow
    //                   enterDelay={150}
    //                   leaveDelay={100}
    //                 >
    //                   <div className="divTextEllipsis">
    //                     <a
    //                       style={{
    //                         textDecoration: "none",

    //                         color: "rgba(0, 0, 0, 0.87)",
    //                       }}
    //                       sx={{
    //                         textDecoration: "none",
    //                         color: "var(--mainTheme)",
    //                       }}
    //                       href={`#${section.sectionId}`}
    //                     >
    //                       <Typography
    //                         size="small"
    //                         sx={{
    //                           cursor: "pointer",
    //                           padding: "5px 6px",
    //                           fontSize: "15px",
    //                           fontFamily: "Inter !important",
    //                         }}
    //                         inputprops={{
    //                           style: { padding: "1px" },
    //                         }}
    //                         noWrap
    //                       >
    //                         {section.sectionName ? section.sectionName : " "}
    //                       </Typography>
    //                     </a>
    //                   </div>
    //                 </Tooltip>
    //               </Box>
    //             </Paper>
    //           );
    //         })}
    //       </Grid>
    //     </Grid>
    //   </CardContent>
    // </Card>
    <Card elevation={0} className="section-card">
      <CardHeader
        title="Sections"
        className="section-card-header"
        action={
          routedFrom !== "FillHra" &&
          routedFrom !== "PedsForm" &&
          screenOnlyRoute !== "ScreenOnly" && (
            <MdEdit
              size={20}
              className="edit-icon"
              onClick={(e) => handleEditForm(e)}
            />
          )
        }
      />
      <CardContent className="section-card-content-container">
        {/* Toaster logic */}
        <Grid
          container
          justifyContent="space-evenly"
          alignItems="center"
          className="section-card-content"
        >
          <Grid item xs={12}>
            {sections.map((section, sectionIndex) => (
              <Paper
                sx={{
                  backgroundColor:
                    currSection === sectionIndex
                      ? "var(--mainTheme300)"
                      : "0ffffff",
                }}
                key={sectionIndex}
                className={`section-paper ${
                  currSection === sectionIndex ? "active-section" : ""
                }`}
                onClick={
                  !sideBarSectionDisable
                    ? () => handleCardClick(sectionIndex)
                    : undefined
                }
              >
                <Tooltip
                  TransitionComponent={Zoom}
                  title={section.sectionName}
                  placement="top"
                  arrow
                  enterDelay={150}
                  leaveDelay={100}
                >
                  <div className="divTextEllipsis">
                    <a href={`#${section.sectionId}`} className="section-link">
                      <Typography noWrap className="section-text">
                        {section.sectionName || " "}
                      </Typography>
                    </a>
                  </div>
                </Tooltip>
              </Paper>
            ))}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

// export const CardSections = React.memo(NonCardSections);
