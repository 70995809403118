// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.audio-player-container {
  max-width: 300px; /* container width */
  padding: 10px;
  border-radius: 10px;
  background-color: #aad8dd;
  position: relative;
  overflow: hidden; /* prevents content from overflowing */
  cursor: move;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-top: -7px;
}

.rhap_container {
  background-color: #e0f0f2; /* light background */
  border-radius: 10px;
  max-width: 100%;
}

.rhap_time,
.rhap_current-time {
  color: #000;
}

.rhap_controls-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-wrap: nowrap; /* keep icons in one line */
}

.rhap_controls-button {
  width: 28px; /* reduce icon size to fit */
  height: 28px;
  margin: 0 4px; /* Adjust margin to ensure fit */
  min-width: 44px !important;
}
.rhap_main-controls-button {
  min-width: 0px !important;
}

.rhap_button-clear {
  min-width: auto;
}

/* Responsive adjustments */
@media (max-width: 400px) {
  .rhap_controls-button {
    width: 25px;
    height: 25px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Dashboard/MemberList/Fill_HRA/FillHraSummary/CustomAudioPlayer.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB,EAAE,oBAAoB;EACtC,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB,kBAAkB;EAClB,gBAAgB,EAAE,sCAAsC;EACxD,YAAY;EACZ,wCAAwC;EACxC,gBAAgB;AAClB;;AAEA;EACE,yBAAyB,EAAE,qBAAqB;EAChD,mBAAmB;EACnB,eAAe;AACjB;;AAEA;;EAEE,WAAW;AACb;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,WAAW;EACX,iBAAiB,EAAE,2BAA2B;AAChD;;AAEA;EACE,WAAW,EAAE,4BAA4B;EACzC,YAAY;EACZ,aAAa,EAAE,gCAAgC;EAC/C,0BAA0B;AAC5B;AACA;EACE,yBAAyB;AAC3B;;AAEA;EACE,eAAe;AACjB;;AAEA,2BAA2B;AAC3B;EACE;IACE,WAAW;IACX,YAAY;EACd;AACF","sourcesContent":[".audio-player-container {\r\n  max-width: 300px; /* container width */\r\n  padding: 10px;\r\n  border-radius: 10px;\r\n  background-color: #aad8dd;\r\n  position: relative;\r\n  overflow: hidden; /* prevents content from overflowing */\r\n  cursor: move;\r\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);\r\n  margin-top: -7px;\r\n}\r\n\r\n.rhap_container {\r\n  background-color: #e0f0f2; /* light background */\r\n  border-radius: 10px;\r\n  max-width: 100%;\r\n}\r\n\r\n.rhap_time,\r\n.rhap_current-time {\r\n  color: #000;\r\n}\r\n\r\n.rhap_controls-section {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  align-items: center;\r\n  width: 100%;\r\n  flex-wrap: nowrap; /* keep icons in one line */\r\n}\r\n\r\n.rhap_controls-button {\r\n  width: 28px; /* reduce icon size to fit */\r\n  height: 28px;\r\n  margin: 0 4px; /* Adjust margin to ensure fit */\r\n  min-width: 44px !important;\r\n}\r\n.rhap_main-controls-button {\r\n  min-width: 0px !important;\r\n}\r\n\r\n.rhap_button-clear {\r\n  min-width: auto;\r\n}\r\n\r\n/* Responsive adjustments */\r\n@media (max-width: 400px) {\r\n  .rhap_controls-button {\r\n    width: 25px;\r\n    height: 25px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
