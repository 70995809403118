import { SaveOutlined } from "@mui/icons-material";
import CachedIcon from "@mui/icons-material/Cached";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MicNoneIcon from "@mui/icons-material/MicNone";
import StopIcon from "@mui/icons-material/Stop";
import { TextField } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { motion } from "framer-motion";
import { useEffect, useState, useImperativeHandle, forwardRef } from "react";
import toast from "react-hot-toast";
import DownloadForOfflineRoundedIcon from "@mui/icons-material/DownloadForOfflineRounded";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Logo from "../../../../../assets/focuscares_logo.png";
/*
this is for Command Control
// import SpeechRecognition, {
//   useSpeechRecognition,
// } from "react-speech-recognition";
// import "regenerator-runtime/runtime";
// import createSpeechServicesPonyfill from "web-speech-cognitive-services";
*/

import {
  getRTTByAssessmentId,
  updateRTTReport,
} from "../../../../../api/realTimeScriptsApi";
import { useSpeech } from "../../../../../contexts/SpeechContext";
import Loader from "../../../../Loader/Loader";
/*
this is for Command Control
// const { SpeechRecognition: AzureSpeechRecognition } =
//   createSpeechServicesPonyfill({
//     credentials: {
//       region: process.env.REACT_APP_SPEECH_REGION,
//       subscriptionKey: process.env.REACT_APP_SPEECH_KEY,
//     },
//     textNormalization: "lexical",
//   });
// SpeechRecognition.applyPolyfill(AzureSpeechRecognition);

// const startRecogniser = () => {
//   SpeechRecognition.startListening({ continuous: true });
// };

// const stopRecogniser = () => {
//   SpeechRecognition.stopListening();
// };

// const commands = [
//   {
//     command: "Clear the * section",
//     callback: (name) => {
//       let word = name;
//       word = word.replace(/ /g, "").toLowerCase();
//       if (
//         word == "ros" ||
//         word == "reviewofsymptoms" ||
//         word == "reviewofsymptom"
//       ) {
//         word = "ros(reviewofsymptoms)";
//       }
//       let selectedObject = data.find((item) => {
//         return item.category?.toLowerCase().replace(/\s/g, "") == word;
//       });
//       const selectedObjectIndex = data.findIndex((item) => {
//         return item.category?.toLowerCase().replace(/\s/g, "") == word;
//       });
//       selectedObject.content = "";
//       let tempArray = [...data];
//       tempArray[selectedObjectIndex] = selectedObject;
//       setData(tempArray);
//       resetTranscript();
//     },
//   },
//   {
//     command: "Edit the *",
//     callback: (section) => {
//       let word = section.replace(/ /g, "").toLowerCase();
//       if (
//         word == "ros" ||
//         word == "reviewofsymptoms" ||
//         word == "reviewofsymptom"
//       ) {
//         word = "ros(reviewofsymptoms)";
//       }

//       if (word !== undefined) {
//         setSelectedSection(word);
//         resetTranscript();
//       }
//     },
//   },
//   {
//     command: "Replace * with *",
//     callback: (word1, word2) => {
//       if (selectedSection.length > 0) {
//         let selectedObject = data.find((item) => {
//           return (
//             item.category?.toLowerCase().replace(/\s/g, "") == selectedSection
//           );
//         });
//         const updatedValue = selectedObject.content.replace(
//           new RegExp("\\b" + word1 + "\\b", "gi"),
//           (match) => {
//             const firstChar = match.charAt(0);
//             const replacementFirstChar = word2.charAt(0);
//             const replacementRest = word2.slice(1);
//             return firstChar === firstChar.toUpperCase()
//               ? replacementFirstChar.toUpperCase() +
//               replacementRest.toLowerCase()
//               : replacementFirstChar.toLowerCase() +
//               replacementRest.toLowerCase();
//           }
//         );
//         updateStateValue(updatedValue);
//         resetTranscript();
//       } else {
//         sectionError();
//       }
//     },
//   },
//   {
//     command: "Add *",
//     callback: (word) => {
//       if (selectedSection.length > 0) {
//         let selectedObject = data.find((item) => {
//           return (
//             item.category?.toLowerCase().replace(/\s/g, "") == selectedSection
//           );
//         });
//         const updatedValue = selectedObject.content.concat(word);
//         updateStateValue(updatedValue);
//         resetTranscript();
//       } else {
//         sectionError();
//       }
//     },
//   },
//   {
//     command: ["New Line", "New Paragraph"],
//     callback: () => {
//       if (selectedSection.length > 0) {
//         let selectionStart =
//           sectionRef.current[selectedSection].selectionStart;
//         let selectionEnd = sectionRef.current[selectedSection].selectionEnd;
//         let value = sectionRef.current[selectedSection].value;
//         let newValue =
//           value.substring(0, selectionStart) +
//           "\n" +
//           value.substring(selectionEnd, value.length);

//         updateStateValue(newValue);
//         resetTranscript();
//       } else {
//         sectionError();
//       }
//     },
//   },

//   {
//     command: ["Delete That", "Scratch That"],
//     callback: () => {
//       if (selectedSection.length > 0) {
//         let selectionStart =
//           sectionRef.current[selectedSection].selectionStart;
//         let selectionEnd = sectionRef.current[selectedSection].selectionEnd;
//         let value = sectionRef.current[selectedSection].value;
//         let newValue =
//           value.substring(0, selectionStart) +
//           value.substring(selectionEnd, value.length);

//         updateStateValue(newValue);
//         resetTranscript();
//       } else {
//         sectionError();
//       }
//     },
//   },
// ];

// const {
//   transcript,
//   resetTranscript,
//   listening,
//   browserSupportsSpeechRecognition,
//   isMicrophoneAvailable,
// } = useSpeechRecognition({
//   // commands,
// });
*/
const FillHRASummary = forwardRef((_, ref) => {
  const [accordExpand, setAccordExpand] = useState("panel_Chief");
  const [loading, setLoading] = useState(false);
  const [apiData, setApiData] = useState([]);
  const [recText, setRecText] = useState("");
  const assessmentId = sessionStorage?.getItem("assessmentId");
  /*
this is for Command Control
  // const {
  //   transcript,
  //   listening,
  //   resetTranscript,
  //   browserSupportsSpeechRecognition,
  // } = useSpeechRecognition();
*/
  const {
    isRecognise,
    textRecogRef,
    startRecogniser,
    stopRecogniser,
    setRecognisedText,
  } = useSpeech();

  const handleAccordChange = (panel) => (event, isExpanded) => {
    setAccordExpand(isExpanded ? panel : false);
  };

  const handleTextChange = (e) => {
    const { name, value } = e.target;
    const oldData = [...apiData];
    let newData = oldData.map((section) => {
      if (section?.category === name) {
        section.content = value;
      }
      return section;
    });

    setApiData(newData);
  };

  const handleSectionReset = (name) => {
    const oldData = [...apiData];
    let newData = oldData.map((section) => {
      if (section?.category === name) {
        section.content = "";
      }
      return section;
    });

    setApiData(newData);
  };

  const handleSectionTranscribe = (name) => {
    const oldData = [...apiData];
    const newData = oldData.map((section) => {
      let tempData = { ...section };
      const { category, content } = tempData;
      if (category === name) {
        const newText = textRecogRef?.current
          ? textRecogRef?.current.join("")
          : recText;
        tempData = {
          ...tempData,
          content: content + newText,
        };
        textRecogRef.current = [];
        // resetTranscript();
      }
      return tempData;
    });

    setApiData(newData);
  };

  const handleSectionSave = () => {
    updateRTTReport({
      assessmentId,
      rttData: apiData,
    }).then((response) => toast.success("RTT data updated succesfully"));
  };

  useImperativeHandle(ref, () => ({
    handleDownloadPdf() {
      const doc = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
      });
      const pageWidth = doc.internal.pageSize.width;
      const pageHeight = doc.internal.pageSize.height;
      let yPosition = 20; // Starting Y position for the first element, reduced for A4 size

      const addFooter = (currentPage) => {
        // Draw horizontal line above the footer
        doc.setDrawColor(0, 0, 0, 3); // Set color to #00000024 with 24% opacity
        doc.setLineWidth(0.2); // Line thickness
        doc.line(10, pageHeight - 20, pageWidth - 10, pageHeight - 20); // Draw the line

        doc.setFontSize(10);
        doc.setFont("helvetica", "normal");
        doc.setTextColor(0, 0, 0); // Set text color to black

        // Add page number
        doc.text(`Page ${currentPage}`, pageWidth - 20, pageHeight - 10, {
          align: "right",
        });

        // Add logo
        doc.addImage(Logo, "PNG", 10, pageHeight - 15, 30, 10); // Adjust position and size as needed
      };

      // Add a title to the PDF
      doc.setFontSize(14); // Reduced font size
      doc.setFont("helvetica", "bold");
      doc.setTextColor(0, 0, 0); // Set text color to black
      doc.text("RTT Summary", pageWidth / 2, yPosition, { align: "center" });
      yPosition += 10; // Reduced spacing after the heading

      apiData.forEach((section, index) => {
        const { category, content } = section;

        // Check if the content needs a new page
        if (yPosition + 50 > pageHeight) {
          // Reserve 50mm for footer space
          addFooter(doc.internal.getNumberOfPages());
          doc.addPage();
          yPosition = 20; // Reset Y position for new page, reduced for A4 size
        }

        // Add a background color and text color for each category
        doc.setFillColor(189, 193, 198); // Background color #bdc1c6
        doc.setTextColor(0, 0, 0); // Text color black
        doc.setFontSize(12);
        doc.setFont("helvetica", "bold");
        doc.rect(10, yPosition, pageWidth - 20, 10, "F"); // Draw a filled rectangle for the category background
        doc.text(category, 15, yPosition + 7); // Add category text
        yPosition += 15;

        // Draw another rectangle for the content
        doc.setFontSize(10);
        doc.setFont("helvetica", "normal");
        doc.setTextColor(60, 60, 60); // Text color for content
        const lines = doc.splitTextToSize(content, pageWidth - 30); // Split content into lines that fit within the page
        doc.rect(10, yPosition, pageWidth - 20, lines.length * 7 + 5); // Adjust rectangle height based on content length
        doc.text(lines, 15, yPosition + 5); // Add content text
        yPosition += lines.length * 7 + 10; // Adjust yPosition based on content length

        // Add some spacing after each section
        yPosition += 5;

        // Add footer on each page
        if (index === apiData.length - 1) {
          addFooter(doc.internal.getNumberOfPages());
        }
      });

      // Add footer to the last page
      addFooter(doc.internal.getNumberOfPages());

      doc.save("RTT_Summary.pdf");
    },
  }));

  useEffect(() => {
    setLoading(true);
    toast.promise(getRTTByAssessmentId(assessmentId), {
      loading: "Getting RTT data",
      success: (data) => {
        const rttSectionsData = data?.data?.data?.[0]?.rttData || [];
        if (rttSectionsData && rttSectionsData.length > 0) {
          setApiData(rttSectionsData);
          return `${data?.data?.message}`;
        }
        return "RTT Data is empty or not generated";
      },
      error: (data) => {
        return data?.data?.message ? (
          <b>{`${data.data.message}`}</b>
        ) : (
          <b>error when retrieving RTT Data</b>
        );
      },
    });
    setLoading(false);
  }, []);

  const miniButtonStyle = {
    margin: "8px",
    padding: "5px",
    borderRadius: "5px",
    background: "var(--mainTheme250)",
    color: "#514E4E",
    display: "flex",
    alignItems: "center",
  };

  const divStyle =
    apiData && apiData.length < 1
      ? {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "80vh",
        }
      : {};

  return (
    <div
      style={{
        ...divStyle,
        backgroundColor: "var(--mainTheme250)",
        padding: "5px",
        margin: "0px 4px 0px 0px",
        borderRadius: "10px",
      }}
    >
      {/* <DownloadForOfflineRoundedIcon onClick={handleDownloadPdf} /> */}

      {loading ? (
        <div>
          <Loader />
        </div>
      ) : apiData && apiData.length > 0 ? (
        apiData?.map((section, sectionIndex) => {
          const { category, content } = section;
          const sectionKey = `panel_${
            category.split(" ")?.[0] || sectionIndex
          }`;
          return (
            <Accordion
              key={sectionKey}
              expanded={accordExpand === sectionKey}
              onChange={handleAccordChange(sectionKey)}
              {...(sectionIndex === 0 && { defaultExpanded: true })}
              style={{ margin: "1px" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`${
                  category.split(" ")?.[0] || sectionIndex
                }-content`}
                id={`${category.split(" ")?.[0] || sectionIndex}-header`}
                style={{
                  margin: "0px",
                  minHeight: "50px",
                  "&.MuiAccordionSummary-content.Mui-expanded": {
                    margin: "5px 0px 10px 0",
                  },
                }}
              >
                <Typography variant="h6">{category}</Typography>
              </AccordionSummary>
              <AccordionDetails style={{ padding: "8px 16px 16px" }}>
                <TextField
                  name={category}
                  onChange={(event) => handleTextChange(event)}
                  fullWidth
                  multiline
                  value={content || ""}
                  variant="outlined"
                  sx={{
                    "& .MuiFormLabel-root": {
                      color: "var(--mainTheme)",
                    },
                    border: "1.5px solid var(--mainTheme)",
                    borderRadius: "5px",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "var(--mainTheme)",
                    },
                    "&:focus-visible": {
                      outline: "none",
                      borderColor: "var(--mainTheme)",
                    },
                  }}
                />
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <div
                    style={miniButtonStyle}
                    onClick={() => {
                      handleSectionReset(category);
                    }}
                  >
                    <CachedIcon />
                  </div>
                  <div
                    style={miniButtonStyle}
                    onClick={
                      isRecognise
                        ? () => {
                            stopRecogniser();
                            setRecognisedText(setRecText);
                            handleSectionTranscribe(category);
                          }
                        : () => {
                            startRecogniser();
                          }
                    }
                  >
                    {isRecognise ? <StopIcon /> : <MicNoneIcon />}
                  </div>
                  <div
                    style={miniButtonStyle}
                    onClick={() => {
                      handleSectionSave(category);
                    }}
                  >
                    <SaveOutlined />
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          );
        })
      ) : (
        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{
            duration: 0.6,
            delay: 0.8,
            ease: [0.17, 0.67, 0.2, 1.01],
          }}
        >
          <h3>There is no summary yet.</h3>
        </motion.div>
      )}
    </div>
  );
});

export default FillHRASummary;
