import {
  TextField,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  FormControl,
  Checkbox,
  Select,
  ToggleButton,
  ToggleButtonGroup,
  IconButton,
  Paper,
  Grid,
  Autocomplete,
  TablePagination,
  Typography,
  TableContainer,
} from "@mui/material";
import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { Link, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { PiInfo } from "react-icons/pi";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Tooltip from "@mui/material/Tooltip";
import { Zoom } from "@mui/material";
import { Box } from "@mui/material";
import diagnosisData1 from "./diagnosisData.json";
import diagnosisData2 from "./diagnosisData2.json";

import Labels from "../../../../../resource_files/Labels.resx";
// import Toaster from "../../../components/toaster/toaster";
import Button from "../../../../button/button";
import MuiInput from "../../../../muiinput/MuiInput";
import { DragNDropContext } from "../../../../../contexts/DragNDropContext";
import { putApi } from "../../../../../utils/axiosApiWrapper";
import { useUserContext } from "../../../../../contexts/UserContext";

import "./DynamicForms.css";
import "./Tables.css";
import { staticData } from "../../../DynamicFormMUI/indexData";

let inputid = 0;

const myTheme = createTheme({
  components: {
    MuiToggleButton: {
      styleOverrides: {
        root: {
          color: "black",
          margin: "5px 15px 0 0",
          borderStyle: "none",
          borderRadius: "0px!important",
          textTransform: "none",
          border: "1px solid var(--mainTheme)",
          width: "100%",
          whiteSpace: "normal",
          display: "flex",
          flexWrap: "wrap",
          padding: "5px",
          "&::before": {
            content: '""',
            textTransform: "uppercase",
          },
          "&.Mui-selected ": {
            backgroundColor: "var(--mainTheme)",
            color: "white",
            border: "1px solid var(--mainTheme) !important",
          },
          "&.Mui-selected:hover": {
            backgroundColor: "var(--mainTheme)",
            color: "white",
            border: "1px solid var(--mainTheme) !important",
          },
        },
      },
    },
  },
});

const myThemes = createTheme({
  components: {
    MuiToggleButton: {
      styleOverrides: {
        root: {
          color: "black",
          borderStyle: "none",
          borderRadius: "1px!important",
          textTransform: "none",
          width: "100px",
          whiteSpace: "normal",
          display: "flex",
          flexWrap: "wrap",
          padding: "5px",
          "&::before": {
            content: '""',
          },
          "&.Mui-selected": {
            backgroundColor: "var(--mainTheme)",
            color: "white",
          },
          "&.Mui-selected:hover": {
            backgroundColor: "var(--mainTheme)",
            color: "white",
          },
        },
      },
    },
  },
});

const CustomActionButtons = ({ onClear, onCancel }) => {
  return (
    <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2, p: 2 }}>
      <Button onClick={onClear} variant="text">
        Clear
      </Button>
      <Button onClick={onCancel} variant="text">
        Cancel
      </Button>
    </Box>
  );
};

export function TableComponentStaticActivities() {
  const tableCellStyle = {
    fontWeight: "bold",
    backgroundColor: "var(--mainTheme100)",
    border: "1px solid var(--mainTheme)",
    // padding: "8px",
    textAlign: "center",
    whiteSpace: "nowrap",
  };

  const tableHeadStyle = {
    fontWeight: "bold",
    backgroundColor: "var(--mainTheme)",
    //border: "1px solid var(--mainTheme)",
    textAlign: "center",
    whiteSpace: "nowrap",
  };

  return (
    <div>
      <p style={{ textWrap: "noWrap", fontWeight: "500", fontSize: "20px" }}>
        Scoring guidelines for individual tasks
      </p>
      <Table
        sx={{
          tableLayout: "auto",
          margin: "10px",
          overflow: "hidden",
          width: "35vw",
          //marginTop: '85%',
        }}
        size="small"
      >
        <TableHead style={tableHeadStyle}>
          <TableRow>
            <TableCell
              style={{
                border: "1px solid var(--mainTheme800)",
                textAlign: "center",
                color: "#ffff",
                backgroundColor: "var(--mainTheme800)",
              }}
            >
              Score
            </TableCell>
            <TableCell
              style={{
                border: "1px solid var(--mainTheme800)",
                textAlign: "center",
                color: "#ffff",
                backgroundColor: "var(--mainTheme800)",
              }}
            >
              Indication
            </TableCell>
          </TableRow>
        </TableHead>
        <TableRow>
          <TableCell style={tableCellStyle}>1</TableCell>
          <TableCell style={tableCellStyle}>Full Function</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={tableCellStyle}>2 - 3</TableCell>
          <TableCell style={tableCellStyle}>Moderate Impairment</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={tableCellStyle}>4 or more</TableCell>
          <TableCell style={tableCellStyle}>
            Severe Functional Impairment
          </TableCell>
        </TableRow>
      </Table>
    </div>
  );
}

export const TableComponentStatic = ({ tableName, data, disabled }) => {
  if (tableName === "BMI Index Chart") {
    return (
      <Grid
      // style={{
      //   textAlign: "center",
      //   justifyContent: "center",
      //   alignItems: "center",
      //   margin: "30px",
      // }}
      >
        <table
          style={{
            margin: "0 auto",
            borderCollapse: "collapse",
            border: "1px solid var(--mainTheme)",
          }}
        >
          <thead>
            <tr>
              <th>BMI Index Chart</th>
            </tr>
          </thead>
          <tbody>
            {data.map((row, rowIndex) => (
              <tr key={`row-${rowIndex}`}>
                <td
                  key={`cell-${rowIndex}`}
                  style={{
                    border: "1px solid var(--mainTheme)",
                    padding: "8px",
                  }}
                >
                  {row}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Grid>
    );
  }

  if (tableName === "BMI Index Chart 2024") {
    return (
      <Grid>
        <table
          style={{
            margin: "0 auto",
            borderCollapse: "collapse",
            border: "1px solid var(--mainTheme)",
          }}
        >
          <thead>
            <tr>
              <th>BMI Index Chart</th>
            </tr>
          </thead>
          <tbody>
            {data.map((row, rowIndex) => (
              <tr key={`row-${rowIndex}`}>
                <td
                  key={`cell-${rowIndex}`}
                  style={{
                    border: "1px solid var(--mainTheme)",
                    padding: "8px",
                  }}
                >
                  {row}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Grid>
    );
  }

  return <div>Table not found</div>;
};

export function DynamicTablePreviously({
  previouslyRowData,
  setPreviouslyRowData,
  memberId,
  disableFlag,
}) {
  const [visibleRows, setVisibleRows] = useState(5);
  const [message, setMessage] = useState("");
  const [isDiagnosisMessage, setIsDiagnosisMessage] = useState(false);
  const observerRef = useRef();
  const [suggestions, setSuggestions] = useState([]);
  const [focusedRowIndex, setFocusedRowIndex] = useState(null);
  const [showSuggestions, setShowSuggestions] = useState(true);
  const [hasScrolled, setHasScrolled] = useState(false);
  const clientYear = localStorage.getItem("clientYear");
  const diagnosisData = useMemo(
    () => (clientYear === "2023" ? diagnosisData1 : diagnosisData2),
    [clientYear],
  );

  const updateDiagnosisDescriptions = useCallback(() => {
    const updatedRowData = previouslyRowData.map((row) => ({
      ...row,
      diagnosis: getDiagnosisDescription(row.diagnosisCode),
    }));
    setPreviouslyRowData(updatedRowData);
  }, [previouslyRowData, setPreviouslyRowData]);

  useEffect(() => {
    updateDiagnosisDescriptions();
  }, []);

  useEffect(() => {
    if (hasScrolled && !showSuggestions) {
      setShowSuggestions(false);
    }
  }, [hasScrolled, showSuggestions]);

  const getDiagnosisDescription = useCallback(
    (diagnosisCode) => {
      const diagnosis = diagnosisData?.find(
        (item) => item.code === diagnosisCode,
      );
      return diagnosis ? diagnosis.description : "";
    },
    [diagnosisData],
  );

  const updateMessages = useCallback(
    (index) => {
      const focusedRow = previouslyRowData[index];
      if (!focusedRow) return;

      const hasDescriptionMissing =
        !focusedRow.diagnosis && focusedRow.diagnosisCode;

      const isDuplicateDiagnosisCode = previouslyRowData.some(
        (row, i) =>
          i !== index && row.diagnosisCode === focusedRow.diagnosisCode,
      );

      if (hasDescriptionMissing) {
        setMessage("Description is not available.");
        setIsDiagnosisMessage(false);
        setShowSuggestions(false);
      } else if (isDuplicateDiagnosisCode) {
        setMessage("Selected Code is already present in the list.");
        setIsDiagnosisMessage(false);
        setShowSuggestions(false);
      } else {
        setMessage("");
        setIsDiagnosisMessage(true);
        setShowSuggestions(true);
      }
    },
    [previouslyRowData],
  );

  const handleAddRow = () => {
    setPreviouslyRowData([...previouslyRowData, {}]);
  };

  const handleRemoveRow = (index) => {
    const updatedRowData = [...previouslyRowData];
    updatedRowData.splice(index, 1);
    setPreviouslyRowData(updatedRowData);
  };

  const handleTableDataChange = useCallback(
    (e, index, label) => {
      const value = e.target.value;
      const updatedRowData = previouslyRowData.map((row, i) =>
        i === index ? { ...row, [label]: value } : row,
      );
      if (label === "diagnosisCode") {
        const diagnosis = diagnosisData.find(
          (item) => item.code === e.target.value,
        );
        updatedRowData[index]["diagnosis"] = diagnosis
          ? diagnosis.description
          : "";
      }

      if (label === "diagnosisCode" && value.length >= 2) {
        const filteredSuggestions = diagnosisData.filter((item) =>
          item.code.startsWith(value),
        );
        setSuggestions(filteredSuggestions);
      } else {
        setSuggestions([]);
      }

      setPreviouslyRowData(updatedRowData);
    },
    [previouslyRowData, setPreviouslyRowData, diagnosisData],
  );

  const handleTableDateChange = useCallback(
    (date, index, label) => {
      const updatedRowData = previouslyRowData.map((row, i) =>
        i === index ? { ...row, [label]: date ? date.toDate() : "" } : row,
      );
      setPreviouslyRowData(updatedRowData);
    },
    [previouslyRowData, setPreviouslyRowData],
  );

  const handleFocus = (index) => {
    setFocusedRowIndex(index);
    const row = previouslyRowData[index];
    if (row && row.diagnosisCode) {
      updateMessages(index);
      if (suggestions.length > 0) {
        setShowSuggestions(true);
      }
    }
  };

  const handleBlur = () => {
    setMessage("");
    setIsDiagnosisMessage(false);
    setHasScrolled(true);
    setFocusedRowIndex(null);
  };

  const handleSuggestionClick = (index, suggestion) => {
    const updatedRowData = previouslyRowData.map((row, i) =>
      i === index
        ? {
            ...row,
            diagnosis: suggestion.description,
            diagnosisCode: suggestion.code,
          }
        : row,
    );
    setPreviouslyRowData(updatedRowData);
    setShowSuggestions(false);
    setHasScrolled(false);
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Enter") {
      updateMessages(index);
    }
  };

  const tableCellStyle = {
    fontWeight: "bold",
    backgroundColor: "var(--mainTheme100)",
    border: "1px solid var(--mainTheme)",
    textAlign: "center",
    whiteSpace: "nowrap",
  };

  const tableHeadStyle = {
    fontWeight: "bold",
    backgroundColor: "var(--mainTheme)",
    border: "1px solid var(--mainTheme)",
    textAlign: "center",
    whiteSpace: "nowrap",
    color: "white",
  };
  const tableCellsStyle = {
    fontWeight: "bold",
    backgroundColor: "var(--mainTheme100)",
    border: "1px solid var(--mainTheme)",
    textAlign: "left",
    whiteSpace: "normal",
    wordWrap: "break-word",
    padding: "1px",
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setVisibleRows((prevVisibleRows) => prevVisibleRows + 5);
          if (focusedRowIndex !== null) {
            handleFocus(focusedRowIndex);
          }
        }
      },
      { threshold: 1 },
    );
    if (observerRef.current) {
      observer.observe(observerRef.current);
    }
    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current);
      }
    };
  }, [focusedRowIndex]);

  const displayData = previouslyRowData.slice(0, visibleRows);
  const roleId = sessionStorage.getItem("roleId");

  return (
    <div style={{ position: "relative", height: "calc(100vh - 150px)" }}>
      <div style={{ height: "100%", overflowY: "auto", paddingBottom: "50px" }}>
        <TableContainer
          style={{
            overflow: "auto",
            padding: "0",
            margin: "0",
            maxHeight: "450px",
          }}
        >
          <Table
            sx={{
              tableLayout: "auto",
              width: "100%",
              overflow: "hidden",
            }}
            size="small"
          >
            <TableHead style={tableHeadStyle}>
              <TableRow>
                <TableCell style={{ ...tableHeadStyle, width: "300px" }}>
                  Diagnosis
                </TableCell>
                <TableCell style={{ ...tableHeadStyle, width: "30px" }}>
                  Prior HCC
                </TableCell>
                <TableCell style={{ ...tableHeadStyle, width: "30px" }}>
                  Diagnosis Code
                </TableCell>
                <TableCell style={{ ...tableHeadStyle, width: "250px" }}>
                  Date of Service
                </TableCell>
                <TableCell style={tableHeadStyle}>
                  <AddIcon
                    // onClick={() => {
                    //   if (disableFlag) return null;
                    //   handleAddRow();
                    // }}
                    onClick={() => {
                      if (disableFlag || roleId == 5) return null;
                      handleAddRow();
                    }}
                    style={{ cursor: "pointer" }}
                  />
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {displayData.map((row, index) => (
                <TableRow key={index}>
                  <TableCell style={tableCellsStyle}>
                    <TextField
                      fullWidth
                      size="small"
                      value={row.diagnosis || ""}
                      InputProps={{
                        readOnly: true,
                      }}
                      sx={{
                        "& .MuiInputBase-input": {
                          padding: "0px",
                          fontSize: "0.95rem",
                          lineHeight: "1em",
                        },
                        "& .MuiOutlinedInput-root": {
                          padding: "0px",
                        },
                      }}
                      multiline
                    />
                  </TableCell>
                  <TableCell style={tableCellStyle}>
                    <TextField
                      onChange={(e) =>
                        handleTableDataChange(e, index, "priorHcc")
                      }
                      fullWidth
                      size="small"
                      value={row.priorHcc || ""}
                      // inputProps={{ readOnly: disableFlag }}
                      inputProps={{
                        readOnly: roleId == 5 ? true : disableFlag,
                      }}
                      sx={{
                        "& .MuiInputBase-input": {
                          padding: "0px",
                          fontSize: "0.95rem",
                          lineHeight: "1em",
                        },
                        "& .MuiOutlinedInput-root": {
                          padding: "0px",
                        },
                      }}
                    />
                  </TableCell>
                  <TableCell style={tableCellStyle}>
                    <TextField
                      onFocus={() => handleFocus(index)}
                      onBlur={handleBlur}
                      onKeyDown={(e) => handleKeyDown(e, index)}
                      onChange={(e) =>
                        handleTableDataChange(e, index, "diagnosisCode")
                      }
                      fullWidth
                      size="small"
                      value={row.diagnosisCode || ""}
                      inputProps={{
                        readOnly: roleId == 5 ? true : disableFlag,
                      }}
                      sx={{
                        "& .MuiInputBase-input": {
                          padding: "0px",
                          fontSize: "0.95rem",
                          lineHeight: "1em",
                        },
                        "& .MuiOutlinedInput-root": {
                          padding: "0px",
                        },
                      }}
                    />
                  </TableCell>

                  <TableCell style={tableCellStyle}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        value={
                          row.dateOfService ? dayjs(row.dateOfService) : null
                        }
                        onChange={(date) =>
                          handleTableDateChange(date, index, "dateOfService")
                        }
                        disabled={roleId == 5 ? true : disableFlag}
                        slots={{
                          actionBar: (props) => (
                            <CustomActionButtons {...props} />
                          ),
                        }}
                        slotProps={{
                          textField: {
                            readOnly: roleId == 5 ? true : disableFlag,
                          },
                        }}
                        sx={{
                          "& .MuiInputBase-input": {
                            padding: "0px",
                            fontSize: "0.95rem",
                            lineHeight: "1em",
                          },
                          "& .MuiOutlinedInput-root": {
                            padding: "0px",
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </TableCell>
                  <TableCell style={tableCellStyle}>
                    <RemoveIcon
                      style={{ color: "var(--mainTheme)", cursor: "pointer" }}
                      // onClick={() => {
                      //   if (disableFlag) return null;
                      //   handleRemoveRow(index);
                      // }}
                      onClick={() => {
                        if (disableFlag || roleId == 5) return null;
                        handleRemoveRow(index);
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell colSpan={5} style={{ textAlign: "center" }}>
                  <div ref={observerRef} id="scroll-anchor"></div>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {showSuggestions && (
        <div
          style={{
            position: "absolute",
            bottom: "0",
            left: "0",
            width: "70%",
            maxHeight: "100px",
            backgroundColor: "white",
            boxShadow: "0 -1px 5px rgba(0, 0, 0, 0.1)",
            textAlign: "left",
            boxSizing: "border-box",
            overflowY: "auto",
            paddingRight: "20px",
            scrollbarWidth: "thin",
            zIndex: 1000,
            scrollbarColor: "#888 #f1f1f1",
          }}
          className="scrollbar-visible"
          onMouseDown={(e) => e.preventDefault()}
        >
          {suggestions.map((suggestion, idx) => (
            <div
              key={idx}
              onClick={(e) => {
                handleSuggestionClick(focusedRowIndex, suggestion);
                e.stopPropagation();
              }}
              style={{
                cursor: "pointer",
                borderBottom: "1px solid #ccc",
              }}
            >
              {suggestion.code} - {suggestion.description}
            </div>
          ))}
          <style>
            {`
        .scrollbar-visible::-webkit-scrollbar {
          width: 12px; 
        }

        .scrollbar-visible::-webkit-scrollbar-track {
          background: #f1f1f1;
        }

        .scrollbar-visible::-webkit-scrollbar-thumb {
          background: #888; 
          border-radius: 10px; 
        }

        .scrollbar-visible::-webkit-scrollbar-thumb:hover {
          background: #555;
        }
      `}
          </style>
        </div>
      )}

      <div
        style={{
          position: "absolute",
          bottom: "0",
          left: "0",
          width: "100%",
          padding: "10px",
          backgroundColor: "white",
          boxShadow: "0 -1px 5px rgba(0, 0, 0, 0.1)",
          textAlign: "left",
          boxSizing: "border-box",
        }}
      >
        {message && (
          <Typography
            variant="body2"
            color={isDiagnosisMessage ? "inherit" : "error"}
            style={{ margin: "0 auto" }}
          >
            {message}
          </Typography>
        )}
      </div>
    </div>
  );
}

export function DynamicTableSuspect({
  suspectRowData,
  setSuspectRowData,
  memberId,
  disableFlag,
}) {
  const [visibleRows, setVisibleRows] = useState(5);
  const [message, setMessage] = useState("");
  const [isDiagnosisMessage, setIsDiagnosisMessage] = useState(false);
  const observerRef = useRef();
  const [suggestions, setSuggestions] = useState([]);
  const [focusedRowIndex, setFocusedRowIndex] = useState(null);
  const [showSuggestions, setShowSuggestions] = useState(true);
  const [hasScrolled, setHasScrolled] = useState(false);
  const clientYear = localStorage.getItem("clientYear");
  const diagnosisData = useMemo(
    () => (clientYear === "2023" ? diagnosisData1 : diagnosisData2),
    [clientYear],
  );

  const getDiagnosisDescription = useCallback((diagnosisCode) => {
    const diagnosis = diagnosisData?.find(
      (item) => item.code === diagnosisCode,
    );
    return diagnosis ? diagnosis.description : "";
  }, []);

  const updateDiagnosisDescriptions = useCallback(() => {
    const updatedRowData = suspectRowData.map((row) => ({
      ...row,
      diagnosis: getDiagnosisDescription(row.diagnosisCode),
    }));
    setSuspectRowData(updatedRowData);
  }, []);

  useEffect(() => {
    updateDiagnosisDescriptions();
  }, []);
  useEffect(() => {
    if (hasScrolled && !showSuggestions) {
      setShowSuggestions(false);
    }
  }, [hasScrolled, showSuggestions]);

  const updateMessages = useCallback(
    (index) => {
      const focusedRow = suspectRowData[index];
      if (!focusedRow) return;

      const hasDescriptionMissing =
        !focusedRow.diagnosis && focusedRow.diagnosisCode;

      const isDuplicateDiagnosisCode = suspectRowData.some(
        (row, i) =>
          i !== index && row.diagnosisCode === focusedRow.diagnosisCode,
      );

      if (hasDescriptionMissing) {
        setMessage("Description is not available.");
        setIsDiagnosisMessage(false);
        setShowSuggestions(false);
      } else if (isDuplicateDiagnosisCode) {
        setMessage("Selected Code is already present in the list.");
        setIsDiagnosisMessage(false);
        setShowSuggestions(false);
      } else {
        setMessage("");
        setIsDiagnosisMessage(true);
        setShowSuggestions(true);
      }
    },
    [suspectRowData],
  );

  const tableCellStyle = {
    fontWeight: "bold",
    backgroundColor: "var(--mainTheme100)",
    border: "1px solid var(--mainTheme)",
    textAlign: "center",
    whiteSpace: "nowrap",
  };
  const tableHeadStyle = {
    fontWeight: "bold",
    backgroundColor: "var(--mainTheme)",
    border: "1px solid var(--mainTheme)",
    textAlign: "center",
    whiteSpace: "nowrap",
    color: "white",
  };

  const handleAddRow = () => {
    setSuspectRowData((prevData) => [...prevData, {}]);
  };

  const handleRemoveRow = useCallback((index) => {
    setSuspectRowData((prevData) => prevData.filter((_, i) => i !== index));
  }, []);

  const handleTableDataChange = useCallback(
    (e, index, label) => {
      const value = e.target.value;
      const updatedRowData = suspectRowData.map((row, i) =>
        i === index ? { ...row, [label]: value } : row,
      );
      if (label === "diagnosisCode") {
        const diagnosis = diagnosisData.find(
          (item) => item.code === e.target.value,
        );
        updatedRowData[index]["diagnosis"] = diagnosis
          ? diagnosis.description
          : "";
      }

      if (label === "diagnosisCode" && value.length >= 2) {
        const filteredSuggestions = diagnosisData.filter((item) =>
          item.code.startsWith(value),
        );
        setSuggestions(filteredSuggestions);
      } else {
        setSuggestions([]);
      }

      setSuspectRowData(updatedRowData);
    },
    [suspectRowData, setSuspectRowData, diagnosisData],
  );

  const handleFocus = (index) => {
    setFocusedRowIndex(index);
    const row = suspectRowData[index];
    if (row && row.diagnosisCode) {
      updateMessages(index);
      if (suggestions.length > 0) {
        setShowSuggestions(true);
      }
    }
  };

  const handleBlur = () => {
    setMessage("");
    setIsDiagnosisMessage(false);
    setHasScrolled(true);
    setFocusedRowIndex(null);
  };

  const handleSuggestionClick = (index, suggestion) => {
    const updatedRowData = suspectRowData.map((row, i) =>
      i === index
        ? {
            ...row,
            diagnosis: suggestion.description,
            diagnosisCode: suggestion.code,
          }
        : row,
    );
    setSuspectRowData(updatedRowData);
    setShowSuggestions(false);
    setHasScrolled(false);
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Enter") {
      updateMessages(index);
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setVisibleRows((prevVisibleRows) => prevVisibleRows + 5);
          if (focusedRowIndex !== null) {
            handleFocus(focusedRowIndex);
          }
        }
      },
      { threshold: 1 },
    );
    if (observerRef.current) {
      observer.observe(observerRef.current);
    }
    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current);
      }
    };
  }, [focusedRowIndex]);

  const displayData = suspectRowData.slice(0, visibleRows);
  const roleId = sessionStorage.getItem("roleId");
  return (
    <div style={{ position: "relative", height: "calc(100vh - 150px)" }}>
      <div style={{ height: "100%", overflowY: "auto", paddingBottom: "50px" }}>
        <TableContainer
          style={{
            overflow: "auto",
            padding: "0",
            margin: "0",
            maxHeight: "450px",
          }}
        >
          <Table
            sx={{
              tableLayout: "auto",
              width: "100%",
              overflow: "hidden",
            }}
            size="small"
          >
            <TableHead style={tableHeadStyle}>
              <TableRow>
                <TableCell style={{ ...tableHeadStyle, width: "300px" }}>
                  Diagnosis
                </TableCell>
                <TableCell style={{ ...tableHeadStyle, width: "30px" }}>
                  Diagnosis Code
                </TableCell>
                <TableCell style={{ ...tableHeadStyle, width: "20px" }}>
                  <AddIcon
                    onClick={() => {
                      if (!disableFlag) handleAddRow();
                    }}
                    style={{ cursor: "pointer" }}
                  />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {displayData.map((row, index) => (
                <TableRow key={index}>
                  <TableCell style={tableCellStyle}>
                    <TextField
                      fullWidth
                      size="small"
                      value={row.diagnosis || ""}
                      InputProps={{ readOnly: true }}
                      sx={{
                        "& .MuiInputBase-input": {
                          padding: "0px",
                          fontSize: "0.95rem",
                          lineHeight: "1em",
                        },
                        "& .MuiOutlinedInput-root": {
                          padding: "0px",
                        },
                      }}
                      multiline
                    />
                  </TableCell>
                  <TableCell style={tableCellStyle}>
                    <TextField
                      onFocus={() => handleFocus(index)}
                      onBlur={handleBlur}
                      onKeyDown={(e) => handleKeyDown(e, index)}
                      onChange={(e) =>
                        handleTableDataChange(e, index, "diagnosisCode")
                      }
                      fullWidth
                      size="small"
                      value={row.diagnosisCode || ""}
                      // inputProps={{
                      //   readOnly: roleId == 5 ? true : disableFlag,
                      // }}
                      inputProps={{ readOnly: disableFlag }}
                      sx={{
                        "& .MuiInputBase-input": {
                          padding: "0px",
                          fontSize: "0.95rem",
                          lineHeight: "1em",
                        },
                        "& .MuiOutlinedInput-root": {
                          padding: "0px",
                        },
                      }}
                    />
                  </TableCell>
                  <TableCell style={tableCellStyle}>
                    <RemoveIcon
                      style={{ color: "var(--mainTheme)", cursor: "pointer" }}
                      onClick={() => {
                        if (!disableFlag) handleRemoveRow(index);
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell colSpan={3} style={{ textAlign: "center" }}>
                  <div ref={observerRef} id="scroll-anchor"></div>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {showSuggestions && (
        <div
          style={{
            position: "absolute",
            bottom: "0",
            left: "0",
            width: "70%",
            maxHeight: "100px",
            backgroundColor: "white",
            boxShadow: "0 -1px 5px rgba(0, 0, 0, 0.1)",
            textAlign: "left",
            boxSizing: "border-box",
            overflowY: "auto",
            paddingRight: "20px",
            scrollbarWidth: "thin",
            zIndex: 1000,
            scrollbarColor: "#888 #f1f1f1",
          }}
          className="scrollbar-visible"
          onMouseDown={(e) => e.preventDefault()}
        >
          {suggestions.map((suggestion, idx) => (
            <div
              key={idx}
              onClick={(e) => {
                handleSuggestionClick(focusedRowIndex, suggestion);
                e.stopPropagation();
              }}
              style={{
                cursor: "pointer",
                borderBottom: "1px solid #ccc",
              }}
            >
              {suggestion.code} - {suggestion.description}
            </div>
          ))}
          <style>
            {`
        .scrollbar-visible::-webkit-scrollbar {
          width: 12px; 
        }

        .scrollbar-visible::-webkit-scrollbar-track {
          background: #f1f1f1;
        }

        .scrollbar-visible::-webkit-scrollbar-thumb {
          background: #888; 
          border-radius: 10px; 
        }

        .scrollbar-visible::-webkit-scrollbar-thumb:hover {
          background: #555;
        }
      `}
          </style>
        </div>
      )}
      <div
        style={{
          position: "absolute",
          bottom: "0",
          left: "0",
          width: "100%",
          padding: "10px",
          backgroundColor: "white",
          boxShadow: "0 -1px 5px rgba(0, 0, 0, 0.1)",
          textAlign: "left",
          boxSizing: "border-box",
        }}
      >
        {message && (
          <Typography
            variant="body2"
            color={isDiagnosisMessage ? "inherit" : "error"}
            style={{ margin: "0 auto" }}
          >
            {message}
          </Typography>
        )}
      </div>
    </div>
  );
}

export function DynamicTableFamilyHistory({
  familyRowData,
  setFamilyRowData,
  memberId,
  disableFlag,
}) {
  const handleTableDataChange = (e, index, label) => {
    const updatedRowData = [...familyRowData];
    updatedRowData[index][label] = e.target.value;
    setFamilyRowData(updatedRowData);
  };

  const handleAddRow = () => {
    const newRow = {
      id: Date.now().toString(),
      familyMember: "",
      medicalCondition: "",
      causeofDeath: "",
    };
    setFamilyRowData([...familyRowData, newRow]);
  };

  const handleRemoveRow = (index) => {
    const updatedRowData = [...familyRowData];
    updatedRowData.splice(index, 1);
    setFamilyRowData(updatedRowData);
  };

  const familyMemberOptions = [
    "Father",
    "Mother",
    "Sibling1",
    "Sibling2",
    "Sibling3",
    "Other",
  ];

  const tableCellStyle = {
    fontWeight: "bold",
    backgroundColor: "var(--mainTheme100)",
    border: "1px solid var(--mainTheme)",
    textAlign: "center",
    whiteSpace: "nowrap",
  };

  const tableHeadStyle = {
    fontWeight: "bold",
    backgroundColor: "var(--mainTheme)",
    border: "1px solid var(--mainTheme)",
    textAlign: "center",
    whiteSpace: "nowrap",
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "15px",
        marginLeft: "10px",
        width: "80%",
        margin: "auto",
      }}
    >
      <Table
        sx={{
          tableLayout: "auto",
          margin: "10px",
          overflow: "hidden",
        }}
        size="small"
      >
        <TableHead style={tableHeadStyle}>
          <TableRow>
            <TableCell style={tableCellStyle}>Family Member</TableCell>
            <TableCell style={tableCellStyle}>Medical Condition</TableCell>
            <TableCell style={tableCellStyle}>Cause of Death</TableCell>
            <TableCell style={tableCellStyle}>
              <AddIcon
                style={{ color: "var(--mainTheme)", cursor: "pointer" }}
                onClick={() => {
                  if (disableFlag) {
                    return null;
                  }
                  handleAddRow();
                }}
              />
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {familyRowData?.map((row, index) => (
            <TableRow key={index}>
              <TableCell style={tableCellStyle}>
                <Select
                  value={row?.familyMember || ""}
                  onChange={(e) =>
                    handleTableDataChange(e, index, "familyMember")
                  }
                  {...(disableFlag && { disabled: true })}
                  fullWidth
                  size="small"
                  variant="standard"
                  displayEmpty
                >
                  <MenuItem value="">
                    <span>Select Family Member</span>
                  </MenuItem>
                  {familyMemberOptions?.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </TableCell>

              <TableCell style={tableCellStyle}>
                <TextField
                  onChange={(e) =>
                    handleTableDataChange(e, index, "medicalCondition")
                  }
                  fullWidth
                  size="small"
                  variant="standard"
                  value={row?.medicalCondition || ""}
                  inputProps={{ readOnly: disableFlag }}
                />
              </TableCell>

              <TableCell style={tableCellStyle}>
                <TextField
                  onChange={(e) =>
                    handleTableDataChange(e, index, "causeofDeath")
                  }
                  fullWidth
                  size="small"
                  variant="standard"
                  value={row?.causeofDeath || ""}
                  inputProps={{ readOnly: disableFlag }}
                />
              </TableCell>
              {familyRowData.length > 1 && (
                <TableCell style={tableCellStyle}>
                  <RemoveIcon
                    style={{ color: "var(--mainTheme)", cursor: "pointer" }}
                    onClick={() => {
                      if (disableFlag) {
                        return null;
                      }
                      handleRemoveRow(index);
                    }}
                  />
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}

export function DynamicTableSpecialists({
  medicalRowData,
  setMedicalRowData,
  // memberId,
  disableFlag,
}) {
  const tableCellStyle = {
    fontWeight: "bold",
    backgroundColor: "var(--mainTheme100)",
    border: "1px solid var(--mainTheme)",
    // padding: "8px",
    textAlign: "center",
    whiteSpace: "nowrap",
  };

  const tableHeadStyle = {
    fontWeight: "bold",
    backgroundColor: "var(--mainTheme)",
    border: "1px solid var(--mainTheme)",
    textAlign: "center",
    whiteSpace: "nowrap",
  };
  const handleAddRow = () => {
    const newRow = {
      medicalSp: "",
      specialist: "",
      for: "",
    };

    setMedicalRowData([...medicalRowData, newRow]);
  };

  const handleRemoveRow = (index) => {
    const updatedRowData = [...medicalRowData];
    updatedRowData.splice(index, 1);
    setMedicalRowData(updatedRowData);
  };

  const handleTableDataChange = (e, index, label) => {
    const updatedRowData = [...medicalRowData];

    updatedRowData[index][label] = e.target.value;

    setMedicalRowData(updatedRowData);
  };

  let medicalSpecOptions = [
    "Allergist / Immunologist",
    "Anesthesiologist",
    "Cardiologist",
    "Dermatologist",
    "ENT",
    "Endocrinologist",
    "Gastroenterologist",
    "Geneticist",
    "Hematologist",
    "Infectious Disease Specialist",
    "Nephrologist",
    "Neurologist",
    "Obstetrician/Gynecologist",
    "Oncologist",
    "Ophthalmologist",
    "Podiatrist",
    "Psychiatrist",
    "Psychologist",
    "Pulmonologist",
    "Urologist",
  ];
  const clientYear = localStorage.getItem("clientYear");
  if (clientYear == 2024) {
    medicalSpecOptions = [
      ...medicalSpecOptions,
      "Orthopedist",
      "Rheumatologist",
    ];
  }

  medicalSpecOptions.sort();
  medicalSpecOptions = [...medicalSpecOptions, "Other"];

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "15px",
        marginLeft: "10px",
        width: "80%",
        margin: "auto",
      }}
    >
      <Table
        sx={{
          tableLayout: "auto",

          margin: "10px",
        }}
        size="small"
      >
        <TableHead style={tableHeadStyle}>
          <TableRow>
            <TableCell style={tableCellStyle}>Medical Specialty</TableCell>
            <TableCell style={tableCellStyle}>Specialist</TableCell>
            <TableCell style={tableCellStyle}>For</TableCell>
            <TableCell style={tableCellStyle}>
              <AddIcon
                style={{ color: "var(--mainTheme)", cursor: "pointer" }}
                onClick={() => {
                  if (disableFlag) {
                    return null;
                  }
                  handleAddRow();
                }}
              />
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {medicalRowData.length > 0 &&
            medicalRowData?.map((row, index) => (
              <TableRow key={index}>
                <TableCell style={tableCellStyle}>
                  <Select
                    value={row.medicalSp || ""}
                    onChange={(e) =>
                      handleTableDataChange(e, index, "medicalSp")
                    }
                    {...(disableFlag && { disabled: true })}
                    fullWidth
                    size="small"
                    variant="standard"
                    displayEmpty
                  >
                    <MenuItem value="" disabled>
                      Please Select
                    </MenuItem>
                    {medicalSpecOptions.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>

                <TableCell style={tableCellStyle}>
                  <TextField
                    onChange={(e) =>
                      handleTableDataChange(e, index, "specialist")
                    }
                    fullWidth
                    size="small"
                    variant="standard"
                    value={row.specialist || ""}
                    inputProps={{ readOnly: disableFlag }}
                  />
                </TableCell>

                <TableCell style={tableCellStyle}>
                  <TextField
                    onChange={(e) => handleTableDataChange(e, index, "for")}
                    fullWidth
                    size="small"
                    variant="standard"
                    value={row.for || ""}
                    inputProps={{ readOnly: disableFlag }}
                  />
                </TableCell>

                {medicalRowData.length > 1 && (
                  <TableCell style={tableCellStyle}>
                    <RemoveIcon
                      style={{ color: "var(--mainTheme)", cursor: "pointer" }}
                      onClick={() => {
                        if (disableFlag) {
                          return null;
                        }
                        handleRemoveRow(index);
                      }}
                    />
                  </TableCell>
                )}
              </TableRow>
            ))}
        </TableBody>
      </Table>

      {/* <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            style={{
              cursor: "pointer",
            }}
            // onClick={handleSaveData}
          >
            Save
          </Button>
        </div> */}
    </div>
  );
}

export function DynamicTableAlcoholUsage({
  alcoholRowData,
  setAlcoholRowData,
  memberId,
  disableFlag,
}) {
  const navigate = useNavigate();
  const [toasterMessage, setToasterMessage] = useState("");

  const [toasterSeverity, setToasterSeverity] = useState(Labels.SUCCESS);
  const [toasterOpen, setToasterOpen] = useState(false);

  const handleTableDataChange = (e, index, label) => {
    const updatedRowData = [...alcoholRowData];
    updatedRowData[index][label] = e.target.value;
    setAlcoholRowData(updatedRowData);
  };

  const tableCellStyle = {
    fontWeight: "bold",
    backgroundColor: "var(--mainTheme100)",
    border: "1px solid var(--mainTheme)",
    textAlign: "center",
    whiteSpace: "nowrap",
  };

  const tableHeadStyle = {
    fontWeight: "bold",
    backgroundColor: "var(--mainTheme)",
    border: "1px solid var(--mainTheme)",
    textAlign: "ceanter",
    whiteSpace: "nowrap",
  };

  return (
    <div>
      <Table
        sx={{
          tableLayout: "auto",
          margin: "10px",
          overflow: "hidden",
        }}
        size="small"
      >
        <TableHead style={tableHeadStyle}>
          <TableRow>
            <TableCell style={tableCellStyle}>How Many Drinks</TableCell>
            <TableCell style={tableCellStyle}>How Often</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {alcoholRowData?.map((row, index) => (
            <TableRow key={index}>
              <TableCell style={tableCellStyle}>
                <TextField
                  onChange={(e) =>
                    handleTableDataChange(e, index, "howManyDrinks")
                  }
                  fullWidth
                  size="small"
                  variant="standard"
                  value={row.howManyDrinks || ""}
                  inputProps={{ readOnly: disableFlag }}
                />
              </TableCell>
              <TableCell style={tableCellStyle}>
                <TextField
                  onChange={(e) => handleTableDataChange(e, index, "howOften")}
                  fullWidth
                  size="small"
                  variant="standard"
                  value={row.howOften || ""}
                  inputProps={{ readOnly: disableFlag }}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
export function DynamicTablePreventiveCare({
  preventivesRowData,
  setPreventivesRowData,
  disableFlag,
}) {
  const screeningsWithMethod = [
    "Breast Cancer Screening",
    "COVID-19 Vaccine",
    "Cervical Cancer Screening",
  ];

  const handleTableDataChange = (e, index, label) => {
    const updatedData = [...preventivesRowData];
    updatedData[index][label] = e.target.value;

    if (label === "screen" && !screeningsWithMethod.includes(e.target.value)) {
      updatedData[index]["method"] = "N/A";
    }
    setPreventivesRowData(updatedData);
  };

  const handleCheckboxChange = (index, label) => {
    const updatedData = [...preventivesRowData];
    updatedData[index][label] = updatedData[index][label] === "1" ? "0" : "1";
    setPreventivesRowData(updatedData);
  };

  const tableCellStyle = {
    fontWeight: "bold",
    backgroundColor: "var(--mainTheme100)",
    border: "1px solid var(--mainTheme)",
    textAlign: "center",
    whiteSpace: "nowrap",
  };

  const tableHeadStyle = {
    fontWeight: "bold",
    backgroundColor: "var(--mainTheme)",
    border: "1px solid var(--mainTheme)",
    textAlign: "center",
    whiteSpace: "nowrap",
  };

  const tooltips = [
    "Family hx or at risk start at 40 otherwise 50-74, biennial Mammogram",
    "Family hx start at 45 otherwise 50-75 yrs.: FIT q 4 yrs. or Colonoscopy q 10 yrs. 76-85 yrs.discuss with PCP",
    "Annual",
    "Discuss with PCP",
    "Discuss with PCP",
    "Discuss with PCP",
    "Annual, *If Diabetic Hgb A1c screen every 3-4 months",
    "Annual",
    "Annual",
    "Annual",
    "Annual",
    "21-65 pap q 3 yrs. or q 5 years with hrHPV testing",
    "DXA screening > 65 yrs. or <65 yrs. post menopause",
    "55-65 yrs. discuss exam and PSA testing with PCP",
    "Annual",
  ];

  const defaultScreenings = [
    "Breast Cancer Screening",
    "Colorectal Screening",
    "Influenza Vaccine",
    "COVID-19 Vaccine",
    "Pneumococcal Vaccine",
    "Herpes Zoster Vaccine",
    "Diabetes Screening",
    "Diabetic Foot Exam",
    "Cholesterol Screening",
    "Glaucoma Screening",
    "STIs/HIV Screening",
    "Cervical Cancer Screening",
    "Osteoporosis Screening",
    "Prostate Screening",
    "Fall Risk Screening",
  ];

  const initialPreventivesRowData = preventivesRowData
    ? preventivesRowData.map((item, index) => ({
        ...item,
        method: screeningsWithMethod.includes(defaultScreenings[index])
          ? item.method || ""
          : "N/A",
      }))
    : Array.from({ length: 15 }, (_, index) => ({
        screen: defaultScreenings[index] || "",
        answer: "",
        date: "",
        method: screeningsWithMethod.includes(defaultScreenings[index])
          ? ""
          : "N/A",
        recommendation: "0",
        educationCompleted: "0",
      }));

  return (
    <div>
      <Table
        sx={{
          tableLayout: "auto",
          margin: "10px",
          overflow: "hidden",
        }}
        size="small"
      >
        <TableHead style={tableHeadStyle}>
          <TableRow>
            <TableCell style={tableCellStyle}>Screen</TableCell>
            <TableCell style={tableCellStyle}>Answer</TableCell>
            <TableCell style={tableCellStyle}>Date</TableCell>
            <TableCell style={tableCellStyle}>Method</TableCell>
            <TableCell style={tableCellStyle}>Recommendation</TableCell>
            <TableCell style={tableCellStyle}>Education Completed</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {initialPreventivesRowData?.map((row, index) => (
            <TableRow key={index}>
              <TableCell style={{ ...tableCellStyle, minWidth: "200px" }}>
                <TextField
                  onChange={(e) => handleTableDataChange(e, index, "screen")}
                  fullWidth
                  style={{ fontSize: "12px" }}
                  size="small"
                  variant="standard"
                  value={defaultScreenings[index]}
                  inputProps={{ readOnly: disableFlag || true }}
                />
                <Tooltip
                  TransitionComponent={Zoom}
                  title={tooltips[index]}
                  placement="top"
                  arrow
                  enterDelay={150}
                  leaveDelay={100}
                >
                  <span>
                    <PiInfo style={{ cursor: "pointer" }} />
                  </span>
                </Tooltip>
              </TableCell>

              <TableCell style={tableCellStyle}>
                <Select
                  onChange={(e) => handleTableDataChange(e, index, "answer")}
                  {...(disableFlag && { disabled: true })}
                  fullWidth
                  size="small"
                  variant="standard"
                  value={row.answer || ""}
                  displayEmpty
                >
                  <MenuItem value="" disabled>
                    <span>Select</span>
                  </MenuItem>
                  <MenuItem value={"4"}>Don't Know</MenuItem>
                  <MenuItem value={"2"}>No</MenuItem>
                  <MenuItem value={"3"}>Not Applicable</MenuItem>
                  <MenuItem value={"1"}>Yes</MenuItem>
                </Select>
              </TableCell>

              <TableCell style={tableCellStyle}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    onChange={(e) => handleTableDataChange(e, index, "date")}
                    slotProps={{
                      textField: { readOnly: true },
                    }}
                    slots={{
                      actionBar: (props) => <CustomActionButtons {...props} />,
                    }}
                    fullWidth
                    size="small"
                    variant="standard"
                    value={dayjs(row?.date)}
                  />
                </LocalizationProvider>
                {/* <TextField
                  type="date"
                  onChange={(e) => handleTableDataChange(e, index, "date")}
                  fullWidth
                  size="small"
                  variant="standard"
                  value={row.date || ""}
                /> */}
              </TableCell>

              <TableCell style={tableCellStyle}>
                {screeningsWithMethod.includes(defaultScreenings[index]) ? (
                  <TextField
                    onChange={(e) => handleTableDataChange(e, index, "method")}
                    fullWidth
                    size="small"
                    variant="standard"
                    value={row.method || ""}
                    inputProps={{ readOnly: disableFlag }}
                  />
                ) : (
                  <TextField
                    fullWidth
                    size="small"
                    variant="standard"
                    value={"N/A"}
                    inputProps={{ readOnly: true }}
                  />
                )}
              </TableCell>

              <TableCell style={tableCellStyle}>
                <Checkbox
                  onChange={() => handleCheckboxChange(index, "recommendation")}
                  checked={row.recommendation === "1"}
                  {...(disableFlag && { disabled: true })}
                />
              </TableCell>

              <TableCell style={tableCellStyle}>
                <Checkbox
                  onChange={() =>
                    handleCheckboxChange(index, "educationCompleted")
                  }
                  checked={row.educationCompleted === "1"}
                  {...(disableFlag && { disabled: true })}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
export function DynamicTablePreventiveCare2024({
  preventivesRowData,
  setPreventivesRowData,
  disableFlag,
}) {
  const screeningsWithMethod = [
    "Breast Cancer Screening",
    "Colorectal Screening",
    "Osteoporosis Screening",
  ];

  const handleTableDataChange = (e, index, label) => {
    const updatedData = [...preventivesRowData];
    updatedData[index][label] = e.target.value;

    if (label === "screen" && !screeningsWithMethod.includes(e.target.value)) {
      updatedData[index]["method"] = "N/A";
    }
    setPreventivesRowData(updatedData);
  };

  const handleCheckboxChange = (index, label) => {
    const updatedData = [...preventivesRowData];
    updatedData[index][label] = updatedData[index][label] === "1" ? "0" : "1";
    setPreventivesRowData(updatedData);
  };

  const tableCellStyle = {
    fontWeight: "bold",
    backgroundColor: "var(--mainTheme100)",
    border: "1px solid var(--mainTheme)",
    textAlign: "center",
    whiteSpace: "nowrap",
  };

  const tableHeadStyle = {
    fontWeight: "bold",
    backgroundColor: "var(--mainTheme)",
    border: "1px solid var(--mainTheme)",
    textAlign: "center",
    whiteSpace: "nowrap",
  };

  const tooltips = [
    "Family hx or at risk start at 40 otherwise 50-74, biennial Mammogram",
    "21-65 pap q 3 yrs. or q 5 years with hrHPV testing",
    "Annual",
    "Family hx start at 45, otherwise 50-75 yrs.: FIT q 4 yrs. or Colonoscopy q 10 yrs. 76 - 85 yrs. discuss with PCP. Colonoscopy every 10 years or, Fecal occult blood test home 3-pack FOBT test or FIT fecal immunochemical test every year or, Flexible sigmoidoscopy every 5 years or, Flexible sigmoidoscopy every 10 years with FIT every year or CT Colonography every 5 years (Delaware state mandate also) or Cologuard (DNA stool screening) every three years",
    "Discuss with PCP",
    "Annual",
    "Annual",
    "Annual, *If Diabetic Hgb A1c screen every 3-4 months",
    "Annual",
    "Adults 50 years and older,Adults 19 years and older who have weakened immune systems.",
    "Annual",
    "DXA screening > 65 yrs. or <65 yrs. post menopause",
    "Discuss with PCP",
    "55-69 yrs. discuss exam and PSA testing with PCP",
    "Annual",
  ];

  const defaultScreenings = [
    "Breast Cancer Screening",
    "Cervical Cancer Screening",
    "Cholesterol Screening",
    "Colorectal Screening",
    "COVID-19 Vaccine",
    "Diabetic Eye Exam",
    "Diabetic Foot Exam",
    "Diabetes Screening",
    "Fall Risk Screening",
    "Herpes Zoster Vaccine",
    "Influenza Vaccine",
    "Osteoporosis Screening",
    "Pneumococcal Vaccine",
    "Prostate Screening",
    "STIs/HIV Screening",
  ];

  const initialPreventivesRowData = preventivesRowData
    ? preventivesRowData.map((item, index) => ({
        ...item,
        method: screeningsWithMethod.includes(defaultScreenings[index])
          ? item.method || ""
          : "N/A",
      }))
    : Array.from({ length: 15 }, (_, index) => ({
        screen: defaultScreenings[index] || "",
        answer: "",
        date: "",
        method: screeningsWithMethod.includes(defaultScreenings[index])
          ? ""
          : "N/A",
        recommendation: "0",
      }));

  return (
    <div>
      <Table
        sx={{
          tableLayout: "auto",
          margin: "10px",
          overflow: "hidden",
        }}
        size="small"
      >
        <TableHead style={tableHeadStyle}>
          <TableRow>
            <TableCell style={tableCellStyle}>Screen</TableCell>
            <TableCell style={tableCellStyle}>Answer</TableCell>
            <TableCell style={tableCellStyle}>Date</TableCell>
            <TableCell style={tableCellStyle}>Method</TableCell>
            <TableCell style={tableCellStyle}>Recommendation</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {initialPreventivesRowData?.map((row, index) => (
            <TableRow key={index}>
              <TableCell style={{ ...tableCellStyle, minWidth: "200px" }}>
                <TextField
                  onChange={(e) => handleTableDataChange(e, index, "screen")}
                  fullWidth
                  style={{ fontSize: "12px" }}
                  size="small"
                  variant="standard"
                  value={defaultScreenings[index]}
                  inputProps={{ readOnly: disableFlag || true }}
                />
                <Tooltip
                  TransitionComponent={Zoom}
                  title={tooltips[index]}
                  placement="top"
                  arrow
                  enterDelay={150}
                  leaveDelay={100}
                >
                  <span>
                    <PiInfo style={{ cursor: "pointer" }} />
                  </span>
                </Tooltip>
              </TableCell>

              <TableCell style={tableCellStyle}>
                <Select
                  onChange={(e) => handleTableDataChange(e, index, "answer")}
                  {...(disableFlag && { disabled: true })}
                  fullWidth
                  size="small"
                  variant="standard"
                  value={row.answer || ""}
                  displayEmpty
                >
                  <MenuItem value="" disabled>
                    <span>Select</span>
                  </MenuItem>
                  <MenuItem value={"4"}>Don't Know</MenuItem>
                  <MenuItem value={"2"}>No</MenuItem>
                  <MenuItem value={"3"}>Not Applicable</MenuItem>
                  <MenuItem value={"1"}>Yes</MenuItem>
                </Select>
              </TableCell>

              <TableCell style={tableCellStyle}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    onChange={(e) => handleTableDataChange(e, index, "date")}
                    slotProps={{
                      textField: { readOnly: true },
                    }}
                    slots={{
                      actionBar: (props) => <CustomActionButtons {...props} />,
                    }}
                    fullWidth
                    size="small"
                    variant="standard"
                    value={dayjs(row?.date)}
                    disabled={disableFlag}
                  />
                </LocalizationProvider>
                {/* <TextField
                  onChange={(e) => handleTableDataChange(e, index, "date")}
                  fullWidth
                  size="small"
                  variant="standard"
                  value={row.date || ""}
                /> */}
              </TableCell>

              <TableCell style={tableCellStyle}>
                {screeningsWithMethod.includes(defaultScreenings[index]) ? (
                  <TextField
                    onChange={(e) => handleTableDataChange(e, index, "method")}
                    fullWidth
                    size="small"
                    variant="standard"
                    value={row.method || ""}
                    disabled={disableFlag}
                    inputProps={{ readOnly: disableFlag }}
                  />
                ) : (
                  <TextField
                    fullWidth
                    size="small"
                    variant="standard"
                    value={"N/A"}
                    disabled={disableFlag}
                    inputProps={{ readOnly: true }}
                  />
                )}
              </TableCell>

              <TableCell style={tableCellStyle}>
                <Checkbox
                  onChange={() => handleCheckboxChange(index, "recommendation")}
                  checked={row.recommendation === "1"}
                  {...(disableFlag && { disabled: true })}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}

export function DynamicTableAllergies({
  allergiesRowData,
  setAllergiesRowData,
  memberId,
  disableFlag,
  fromPeds,
}) {
  const tableCellStyle = {
    fontWeight: "bold",
    backgroundColor: "var(--mainTheme100)",
    border: "1px solid var(--mainTheme)",
    // padding: "8px",
    textAlign: "center",
    whiteSpace: "nowrap",
  };
  console.log("disableFlag", disableFlag);
  const tableHeadStyle = {
    fontWeight: "bold",
    backgroundColor: "var(--mainTheme)",
    border: "1px solid var(--mainTheme)",
    textAlign: "center",
    whiteSpace: "nowrap",
  };

  const roleId = sessionStorage.getItem("roleId");
  const tabId = sessionStorage.getItem("tabId");

  const _disableFlag =
    (roleId == 5 && tabId == 3) ||
    (roleId == 8 && tabId == 4) ||
    roleId == 6 ||
    roleId == 8 ||
    roleId == 7 ||
    roleId == 9;

  const handleAddRow = () => {
    if (
      Array.isArray(allergiesRowData) &&
      (fromPeds ? true : allergiesRowData.length < 5)
    ) {
      const uniqueId = Date.now().toString();
      setAllergiesRowData([...allergiesRowData, { id: uniqueId }]);
    } else {
      // s
    }
  };

  const handleRemoveRow = (index) => {
    const updatedRowData = [...allergiesRowData];
    updatedRowData.splice(index, 1);
    setAllergiesRowData(updatedRowData);
  };

  const handleTableDataChange = (e, index, label) => {
    const updatedRowData = [...allergiesRowData];

    updatedRowData[index][label] = e.target.value;

    setAllergiesRowData(updatedRowData);
  };

  if (!allergiesRowData || allergiesRowData.length === 0) {
    setAllergiesRowData([{ id: Date.now().toString() }]);
  }
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "15px",
          marginLeft: "10px",
          width: "70vw",
          margin: "auto",
        }}
      >
        <Table
          sx={{
            tableLayout: "auto",
            margin: "10px",
            overflow: "hidden",
          }}
          size="small"
        >
          <TableHead style={tableHeadStyle}>
            <TableRow>
              <TableCell style={tableCellStyle}>Substance</TableCell>
              <TableCell style={tableCellStyle}>Reaction</TableCell>

              <TableCell style={tableCellStyle}>
                <AddIcon
                  style={{ color: "var(--mainTheme)", cursor: "pointer" }}
                  onClick={() => {
                    if (disableFlag || _disableFlag) {
                      return null;
                    }
                    handleAddRow();
                  }}
                />
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {allergiesRowData?.map((row, index) => (
              <TableRow key={index}>
                <TableCell style={tableCellStyle}>
                  <TextField
                    onChange={(e) =>
                      handleTableDataChange(e, index, "substance")
                    }
                    disabled={disableFlag || _disableFlag}
                    fullWidth
                    size="small"
                    variant="standard"
                    value={row.substance || ""}
                    inputProps={{ readOnly: disableFlag }}
                  />
                </TableCell>

                <TableCell style={tableCellStyle}>
                  <TextField
                    onChange={(e) =>
                      handleTableDataChange(e, index, "reaction")
                    }
                    disabled={disableFlag || _disableFlag}
                    fullWidth
                    size="small"
                    variant="standard"
                    value={row.reaction || ""}
                    inputProps={{ readOnly: disableFlag }}
                  />
                </TableCell>
                {allergiesRowData.length > 1 && (
                  <TableCell style={tableCellStyle}>
                    <RemoveIcon
                      style={{ color: "var(--mainTheme)", cursor: "pointer" }}
                      onClick={() => {
                        if (disableFlag || _disableFlag) {
                          return null;
                        }
                        handleRemoveRow(index);
                      }}
                    />
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <div style={{ display: "flex", width: "100%" }}>
          <div
            style={{
              marginRight: "20px",
              color: "red",
              display: "flex",
              width: "50%",
              justifyContent: "flex-start",
            }}
          >
            Click on - button to delete respective row.
          </div>
          <div
            style={{
              color: "red",
              display: "flex",
              justifyContent: "flex-start",
              width: "50%",
            }}
          >
            Click on + button to add Prescription.
          </div>
        </div>
      </div>
      {/* <div style={{ display: "flex", marginBottom: "15px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            marginRight: "20px",
            color: "red",
          }}
        >
          Click on - button to delete respective row.
        </div>
        <div
          style={{
            color: "red",
            display: "flex",
            justifyContent: "flex-start",
            marginLeft:'250px'
          }}
        >
          {fromPeds
            ? "Click on + button to add  Prescription."
            : "Click on + button to add 5 Prescription maximum."}
        </div>
      </div> */}
    </>
  );
}

export function DynamicMedicationsTable({
  medicationsRowData,
  setMedicationsRowData,
  disableFlag,
  labelNameOptions,
}) {
  const handleAddRow = () => {
    setMedicationsRowData([...medicationsRowData, {}]);
  };

  const handleRemoveRow = (index) => {
    const updatedRowData = [...medicationsRowData];
    updatedRowData.splice(index, 1);
    setMedicationsRowData(updatedRowData);
  };
  const handleTableDataChange = (e, index, label) => {
    const updatedData = [...medicationsRowData];
    updatedData[index][label] = e.target.value;
    setMedicationsRowData(updatedData);
  };
  const handleTableLabelChange = (value, index, label) => {
    const updatedData = [...medicationsRowData];
    updatedData[index][label] = value;
    setMedicationsRowData(updatedData);
  };

  const tableCellStyle = {
    fontWeight: "bold",
    backgroundColor: "var(--mainTheme100)",
    border: "1px solid var(--mainTheme)",
    textAlign: "center",
    whiteSpace: "nowrap",
  };

  const tableHeadStyle = {
    fontWeight: "bold",
    backgroundColor: "var(--mainTheme)",
    border: "1px solid var(--mainTheme)",
    textAlign: "center",
    whiteSpace: "nowrap",
  };

  const handleStatusChange = (index, value) => {
    const updatedData = [...medicationsRowData];

    if (value === "") {
      updatedData[index].status = {
        taking: "",
        notTaking: "",
      };
    } else {
      updatedData[index].status = {
        taking: value === "taking" ? "1" : "2",
        notTaking: value === "notTaking" ? "1" : "2",
      };
    }

    setMedicationsRowData(updatedData);
  };

  const routeOptions = [
    "E = Eye",
    "EA = Ear",
    "IV = Intravenous",
    "IN = Inhalation",
    "M = Intramuscular",
    "N = Nasal",
    "PO = By Mouth",
    "R = Rectal",
    "S = Sublingual",
    "SQ = Subcutaneous",
    "T = Topical",
  ];

  const frequencyOptions = [
    "AC",
    "AC & HS",
    "BID",
    "HS",
    "PC",
    "PRN",
    "QAM",
    "QD",
    "QID",
    "Q Month",
    "QOD",
    "QOW",
    "QPM",
    "TID",
    "QW",
  ];

  if (!medicationsRowData || medicationsRowData.length === 0) {
    setMedicationsRowData([{ id: Date.now().toString() }]);
  }
  const handleTextFieldChange = (value, index) => {
    const newData = [...medicationsRowData];

    newData[index].labelName = value;
    setMedicationsRowData(newData);
  };

  return (
    <div>
      <Table
        sx={{
          tableLayout: "auto",
          margin: "10px",
          overflow: "hidden",
        }}
        size="small"
      >
        <TableHead style={tableHeadStyle}>
          <TableRow>
            <TableCell
              style={{
                ...tableCellStyle,
                maxWidth: "100px",
                whiteSpace: "normal",
              }}
              // style={tableCellStyle}
            >
              <Tooltip
                TransitionComponent={Zoom}
                title="Diagnosis"
                placement="top"
                arrow
                enterDelay={150}
                leaveDelay={100}
              >
                <span>
                  Diagnosis{" "}
                  <PiInfo style={{ cursor: "pointer", fontSize: "15px" }} />
                </span>
              </Tooltip>
            </TableCell>
            <TableCell style={tableCellStyle}>
              <Tooltip
                TransitionComponent={Zoom}
                title="Label Name"
                placement="top"
                arrow
                enterDelay={150}
                leaveDelay={100}
              >
                <span>
                  Label Name{" "}
                  <PiInfo style={{ cursor: "pointer", fontSize: "15px" }} />
                </span>
              </Tooltip>
            </TableCell>
            <TableCell
              style={{
                ...tableCellStyle,
                maxWidth: "100px",
                whiteSpace: "normal",
              }}
            >
              <Tooltip
                TransitionComponent={Zoom}
                title="Dose/Units"
                placement="top"
                arrow
                enterDelay={150}
                leaveDelay={100}
              >
                <span>
                  Dose/Units{" "}
                  <PiInfo style={{ cursor: "pointer", fontSize: "15px" }} />
                </span>
              </Tooltip>
            </TableCell>
            <TableCell style={tableCellStyle}>
              <Tooltip
                TransitionComponent={Zoom}
                title="Route"
                placement="top"
                arrow
                enterDelay={150}
                leaveDelay={100}
              >
                <span>
                  Route{" "}
                  <PiInfo style={{ cursor: "pointer", fontSize: "15px" }} />
                </span>
              </Tooltip>
            </TableCell>
            <TableCell style={tableCellStyle}>
              <Tooltip
                TransitionComponent={Zoom}
                title="Frequency"
                placement="top"
                arrow
                enterDelay={150}
                leaveDelay={100}
              >
                <span>
                  Frequency{" "}
                  <PiInfo style={{ cursor: "pointer", fontSize: "15px" }} />
                </span>
              </Tooltip>
            </TableCell>
            <TableCell
              style={{
                ...tableCellStyle,
                maxWidth: "100px",
                whiteSpace: "normal",
              }}
            >
              <Tooltip
                TransitionComponent={Zoom}
                title="Prescribing Physician"
                placement="top"
                arrow
                enterDelay={150}
                leaveDelay={100}
              >
                <span>
                  Prescribing Physician{" "}
                  <PiInfo style={{ cursor: "pointer", fontSize: "15px" }} />
                </span>
              </Tooltip>
            </TableCell>
            <TableCell style={tableCellStyle}>
              <Tooltip
                TransitionComponent={Zoom}
                title="Status"
                placement="top"
                arrow
                enterDelay={150}
                leaveDelay={100}
              >
                <span>
                  Status{" "}
                  <PiInfo style={{ cursor: "pointer", fontSize: "15px" }} />
                </span>
              </Tooltip>
            </TableCell>
            <TableCell style={tableCellStyle}>
              <AddIcon
                style={{ color: "var(--mainTheme)", cursor: "pointer" }}
                onClick={() => {
                  if (disableFlag) {
                    return null;
                  }
                  handleAddRow();
                }}
              />
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {medicationsRowData?.map((row, index) => (
            <TableRow key={index}>
              <TableCell style={{ ...tableCellStyle, maxWidth: "30px" }}>
                <TextField
                  onChange={(e) => handleTableDataChange(e, index, "diagnosis")}
                  fullWidth
                  size="small"
                  variant="standard"
                  value={row.diagnosis || ""}
                  inputProps={{ readOnly: disableFlag }}
                />
              </TableCell>
              <TableCell
                style={{
                  ...tableCellStyle,
                  maxWidth: "210px",
                  minWidth: "210px",
                }}
              >
                <Autocomplete
                  freeSolo
                  options={labelNameOptions}
                  getOptionLabel={(option) => option}
                  value={row.labelName || ""}
                  onChange={(e, value) =>
                    handleTableLabelChange(value, index, "labelName")
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      size="small"
                      variant="standard"
                      placeholder="Select/Enter"
                      value={row.labelName || ""}
                      onChange={(e) =>
                        handleTextFieldChange(e.target.value, index)
                      }
                      inputProps={{
                        ...params.inputProps,
                        readOnly: disableFlag,
                        disableRipple: true,
                      }}
                    />
                  )}
                  renderOption={(props, option) => (
                    <MenuItem
                      {...props}
                      style={{
                        whiteSpace: "normal",
                        wordBreak: "break-word",
                        display: "block",
                        overflowWrap: "break-word",
                      }}
                    >
                      {option}
                    </MenuItem>
                  )}
                />
              </TableCell>
              <TableCell style={{ ...tableCellStyle, maxWidth: "50px" }}>
                <TextField
                  onChange={(e) =>
                    handleTableDataChange(e, index, "doseOrUnits")
                  }
                  inputProps={{ readOnly: disableFlag }}
                  fullWidth
                  size="small"
                  variant="standard"
                  value={row.doseOrUnits || ""}
                />
              </TableCell>

              <TableCell style={{ ...tableCellStyle, maxWidth: "145px" }}>
                <Select
                  onChange={(e) => handleTableDataChange(e, index, "route")}
                  {...(disableFlag && { disabled: true })}
                  fullWidth
                  size="small"
                  variant="standard"
                  value={row.route || ""}
                  displayEmpty
                >
                  <MenuItem value="" disabled>
                    <span>Select</span>
                  </MenuItem>
                  {routeOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </TableCell>

              <TableCell style={{ ...tableCellStyle, maxWidth: "20px" }}>
                <Select
                  onChange={(e) => handleTableDataChange(e, index, "frequency")}
                  {...(disableFlag && { disabled: true })}
                  fullWidth
                  size="small"
                  variant="standard"
                  value={row.frequency || ""}
                  displayEmpty
                >
                  <MenuItem value="" disabled>
                    <span>Select</span>
                  </MenuItem>
                  {frequencyOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </TableCell>
              <TableCell style={{ ...tableCellStyle, maxWidth: "50px" }}>
                <TextField
                  onChange={(e) =>
                    handleTableDataChange(e, index, "prescribingPhysician")
                  }
                  inputProps={{ readOnly: disableFlag }}
                  fullWidth
                  size="small"
                  variant="standard"
                  value={row.prescribingPhysician || ""}
                />
              </TableCell>

              <TableCell style={tableCellStyle}>
                <ThemeProvider theme={myThemes}>
                  <ToggleButtonGroup
                    value={
                      row?.status?.taking === "1"
                        ? "taking"
                        : row?.status?.notTaking === "1"
                        ? "notTaking"
                        : ""
                    }
                    exclusive
                    {...(disableFlag && { disabled: true })}
                    onChange={(e, value) => handleStatusChange(index, value)}
                  >
                    <ToggleButton value="taking">Taking</ToggleButton>
                    <ToggleButton value="notTaking">Not Taking</ToggleButton>
                  </ToggleButtonGroup>
                </ThemeProvider>
              </TableCell>
              {medicationsRowData.length > 1 && (
                <TableCell style={tableCellStyle}>
                  <RemoveIcon
                    style={{ color: "var(--mainTheme)", cursor: "pointer" }}
                    onClick={() => {
                      if (disableFlag) {
                        return null;
                      }
                      handleRemoveRow(index);
                    }}
                  />
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
export function DynamicMedicationsTable2024({
  medicationsRowData,
  setMedicationsRowData,
  disableFlag,
  labelNameOptions,
}) {
  const handleAddRow = () => {
    setMedicationsRowData([...medicationsRowData, {}]);
  };

  const handleRemoveRow = (index) => {
    const updatedRowData = [...medicationsRowData];
    updatedRowData.splice(index, 1);
    setMedicationsRowData(updatedRowData);
  };
  const handleTableDataChange = (e, index, label) => {
    const updatedData = [...medicationsRowData];
    updatedData[index][label] = e.target.value;
    setMedicationsRowData(updatedData);
  };

  const handleTableLabelChange = (value, index, label) => {
    const updatedData = [...medicationsRowData];
    updatedData[index][label] = value;
    setMedicationsRowData(updatedData);
  };

  const tableCellStyle = {
    fontWeight: "bold",
    backgroundColor: "var(--mainTheme100)",
    border: "1px solid var(--mainTheme)",
    textAlign: "center",
    whiteSpace: "nowrap",
  };

  const tableHeadStyle = {
    fontWeight: "bold",
    backgroundColor: "var(--mainTheme)",
    border: "1px solid var(--mainTheme)",
    textAlign: "center",
    whiteSpace: "nowrap",
  };

  const handleStatusChange = (index, value) => {
    const updatedData = [...medicationsRowData];

    if (value === "") {
      updatedData[index].status = {
        taking: "",
        notTaking: "",
      };
    } else {
      updatedData[index].status = {
        taking: value === "taking" ? "1" : "2",
        notTaking: value === "notTaking" ? "1" : "2",
      };
    }

    setMedicationsRowData(updatedData);
  };

  const routeOptions = [
    "E = Eye",
    "EA = Ear",
    "G = Tube",
    "IV = Intravenous",
    "IN = Inhalation",
    "M = Intramuscular",
    "N = Nasal",
    "PO = By Mouth",
    "R = Rectal",
    "S = Sublingual",
    "SQ = Subcutaneous",
    "T = Topical",
  ];

  const frequencyOptions = [
    "AC",
    "AC & HS",
    "BID",
    "HS",
    "PC",
    "PRN",
    "QAM",
    "QD",
    "QID",
    "Q Month",
    "QOD",
    "QOW",
    "QPM",
    "TID",
    "QW",
  ];

  if (!medicationsRowData || medicationsRowData.length === 0) {
    setMedicationsRowData([{ id: Date.now().toString() }]);
  }
  const handleTextFieldChange = (value, index) => {
    const newData = [...medicationsRowData];

    newData[index].labelName = value;
    setMedicationsRowData(newData);
  };

  return (
    <div>
      <Table
        sx={{
          tableLayout: "auto",
          margin: "10px",
          overflow: "hidden",
        }}
        size="small"
      >
        <TableHead style={tableHeadStyle}>
          <TableRow>
            <TableCell style={tableCellStyle}>
              <Tooltip
                TransitionComponent={Zoom}
                title="Diagnosis"
                placement="top"
                arrow
                enterDelay={150}
                leaveDelay={100}
              >
                <span>
                  Diagnosis{" "}
                  <PiInfo style={{ cursor: "pointer", fontSize: "15px" }} />
                </span>
              </Tooltip>
            </TableCell>
            <TableCell style={tableCellStyle}>
              <Tooltip
                TransitionComponent={Zoom}
                title="Label Name"
                placement="top"
                arrow
                enterDelay={150}
                leaveDelay={100}
              >
                <span>
                  Label Name{" "}
                  <PiInfo style={{ cursor: "pointer", fontSize: "15px" }} />
                </span>
              </Tooltip>
            </TableCell>
            <TableCell style={tableCellStyle}>
              <Tooltip
                TransitionComponent={Zoom}
                title="Dose/Units"
                placement="top"
                arrow
                enterDelay={150}
                leaveDelay={100}
              >
                <span>
                  Dose/Units{" "}
                  <PiInfo style={{ cursor: "pointer", fontSize: "15px" }} />
                </span>
              </Tooltip>
            </TableCell>
            <TableCell style={tableCellStyle}>
              <Tooltip
                TransitionComponent={Zoom}
                title="Route"
                placement="top"
                arrow
                enterDelay={150}
                leaveDelay={100}
              >
                <span>
                  Route{" "}
                  <PiInfo style={{ cursor: "pointer", fontSize: "15px" }} />
                </span>
              </Tooltip>
            </TableCell>
            <TableCell style={tableCellStyle}>
              <Tooltip
                TransitionComponent={Zoom}
                title="Frequency"
                placement="top"
                arrow
                enterDelay={150}
                leaveDelay={100}
              >
                <span>
                  Frequency{" "}
                  <PiInfo style={{ cursor: "pointer", fontSize: "15px" }} />
                </span>
              </Tooltip>
            </TableCell>

            <TableCell style={tableCellStyle}>
              <Tooltip
                TransitionComponent={Zoom}
                title="Status"
                placement="top"
                arrow
                enterDelay={150}
                leaveDelay={100}
              >
                <span>
                  Status{" "}
                  <PiInfo style={{ cursor: "pointer", fontSize: "15px" }} />
                </span>
              </Tooltip>
            </TableCell>
            <TableCell style={tableCellStyle}>
              <AddIcon
                style={{ color: "var(--mainTheme)", cursor: "pointer" }}
                onClick={() => {
                  if (disableFlag) {
                    return null;
                  }
                  handleAddRow();
                }}
              />
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {medicationsRowData?.map((row, index) => (
            <TableRow key={index}>
              <TableCell style={{ ...tableCellStyle, maxWidth: "80px" }}>
                <TextField
                  onChange={(e) => handleTableDataChange(e, index, "diagnosis")}
                  fullWidth
                  size="small"
                  variant="standard"
                  value={row.diagnosis || ""}
                  inputProps={{ readOnly: disableFlag }}
                />
              </TableCell>
              <TableCell
                style={{
                  ...tableCellStyle,
                  maxWidth: "220px",
                  minWidth: "220px",
                }}
              >
                <Tooltip
                  TransitionComponent={Zoom}
                  title={row.labelName || ""}
                  placement="top"
                  arrow
                  enterDelay={150}
                  leaveDelay={100}
                >
                  <Autocomplete
                    freeSolo
                    options={labelNameOptions}
                    getOptionLabel={(option) => option}
                    value={row.labelName || ""}
                    onChange={(e, value) =>
                      handleTableLabelChange(value, index, "labelName")
                    }
                    disabled={disableFlag}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        size="small"
                        variant="standard"
                        placeholder="Select/Enter"
                        value={row.labelName || ""}
                        onChange={(e) =>
                          handleTextFieldChange(e.target.value, index)
                        }
                        disabled={disableFlag}
                        inputProps={{
                          ...params.inputProps,
                          readOnly: disableFlag,
                          disableRipple: true,
                        }}
                      />
                    )}
                    renderOption={(props, option) => (
                      <MenuItem
                        {...props}
                        style={{
                          whiteSpace: "normal",
                          wordBreak: "break-word",
                          display: "block",
                          overflowWrap: "break-word",
                        }}
                      >
                        {option}
                      </MenuItem>
                    )}
                  />
                </Tooltip>
              </TableCell>
              <TableCell style={{ ...tableCellStyle, maxWidth: "120px" }}>
                <TextField
                  onChange={(e) =>
                    handleTableDataChange(e, index, "doseOrUnits")
                  }
                  inputProps={{ readOnly: disableFlag }}
                  fullWidth
                  size="small"
                  variant="standard"
                  value={row.doseOrUnits || ""}
                />
              </TableCell>

              <TableCell style={{ ...tableCellStyle, maxWidth: "150px" }}>
                <Select
                  onChange={(e) => handleTableDataChange(e, index, "route")}
                  {...(disableFlag && { disabled: true })}
                  fullWidth
                  size="small"
                  variant="standard"
                  value={row.route || ""}
                  displayEmpty
                >
                  <MenuItem value="" disabled>
                    <span>Select</span>
                  </MenuItem>
                  {routeOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </TableCell>

              <TableCell style={{ ...tableCellStyle, maxWidth: "50px" }}>
                <Select
                  onChange={(e) => handleTableDataChange(e, index, "frequency")}
                  {...(disableFlag && { disabled: true })}
                  fullWidth
                  size="small"
                  variant="standard"
                  value={row.frequency || ""}
                  displayEmpty
                >
                  <MenuItem value="" disabled>
                    <span>Select</span>
                  </MenuItem>
                  {frequencyOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </TableCell>

              <TableCell style={tableCellStyle}>
                <ThemeProvider theme={myThemes}>
                  <ToggleButtonGroup
                    value={
                      row?.status?.taking === "1"
                        ? "taking"
                        : row?.status?.notTaking === "1"
                        ? "notTaking"
                        : ""
                    }
                    exclusive
                    {...(disableFlag && { disabled: true })}
                    onChange={(e, value) => handleStatusChange(index, value)}
                  >
                    <ToggleButton value="taking">Taking</ToggleButton>
                    <ToggleButton value="notTaking">Not Taking</ToggleButton>
                  </ToggleButtonGroup>
                </ThemeProvider>
              </TableCell>
              {medicationsRowData.length > 1 && (
                <TableCell style={tableCellStyle}>
                  <RemoveIcon
                    style={{ color: "var(--mainTheme)", cursor: "pointer" }}
                    onClick={() => {
                      if (disableFlag) {
                        return null;
                      }
                      handleRemoveRow(index);
                    }}
                  />
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}

export function DynamicTableCounterMedications({
  counterMedicationsRowData,
  setCounterMedicationsRowData,
  memberId,
  disableFlag,
}) {
  const tableCellStyle = {
    fontWeight: "bold",
    backgroundColor: "var(--mainTheme100)",
    border: "1px solid var(--mainTheme)",
    textAlign: "center",
    whiteSpace: "nowrap",
  };

  const tableHeadStyle = {
    fontWeight: "bold",
    backgroundColor: "var(--mainTheme)",
    border: "1px solid var(--mainTheme)",
    textAlign: "center",
    whiteSpace: "nowrap",
  };

  const handleAddRow = () => {
    if (
      Array.isArray(counterMedicationsRowData) &&
      counterMedicationsRowData.length < 5
    ) {
      setCounterMedicationsRowData([...counterMedicationsRowData, {}]);
    } else {
      // console.log("You can add a maximum of 5 rows.");
    }
  };

  const handleRemoveRow = (index) => {
    const updatedRowData = [...counterMedicationsRowData];
    updatedRowData.splice(index, 1);
    setCounterMedicationsRowData(updatedRowData);
  };

  const handleTableDataChange = (e, index, label) => {
    const updatedRowData = [...counterMedicationsRowData];
    updatedRowData[index][label] = e.target.value;
    setCounterMedicationsRowData(updatedRowData);
  };
  const handleTableDateChange = (newDate, index, label) => {
    if (newDate && dayjs(newDate).isValid()) {
      const updatedRowData = [...counterMedicationsRowData];
      updatedRowData[index][label] = dayjs(newDate).format();

      setCounterMedicationsRowData(updatedRowData);
    }
  };

  const routeOptions = [
    "PO = By Mouth",
    "SQ = Subcutaneous",
    "M = Intramuscular",
    "IV = Intravenous",
    "N = Nasal",
    "R = Rectal",
    "S = Sublingual",
    // "E = Eye",
    // "T = Topical",
    // "EA = Ear",
    // "IN = Inhalation",
  ];

  if (!counterMedicationsRowData || counterMedicationsRowData.length === 0) {
    setCounterMedicationsRowData([{ id: Date.now().toString() }]);
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "15px",
        marginLeft: "10px",
        width: "80%",
        margin: "auto",
      }}
    >
      <Table
        sx={{ tableLayout: "auto", margin: "10px", overflow: "hidden" }}
        size="small"
      >
        <TableHead style={tableHeadStyle}>
          <TableRow>
            <TableCell style={tableCellStyle}>Date</TableCell>
            <TableCell style={tableCellStyle}>Description</TableCell>
            <TableCell style={tableCellStyle}>Dose/Units</TableCell>
            <TableCell style={tableCellStyle}>Route</TableCell>
            <TableCell style={tableCellStyle}>Frequency</TableCell>
            <TableCell style={tableCellStyle}>
              <AddIcon
                style={{ color: "var(--mainTheme)", cursor: "pointer" }}
                onClick={
                  // handleAddRow
                  () => {
                    if (disableFlag) {
                      return null;
                    }
                    handleAddRow();
                  }
                }
              />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {counterMedicationsRowData?.map((row, index) => (
            <TableRow key={index}>
              <TableCell style={tableCellStyle}>
                {/* <TextField
                    onChange={(e) => handleTableDataChange(e, index, "date")}
                    fullWidth
                    size="small"
                    variant="standard"
                    value={row.date || ""}
                    type="date"
                    
                    
                  /> */}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker", "DatePicker"]}>
                    <DatePicker
                      // value={dayjs(row.date) || ""}
                      value={row.date ? dayjs(row.date) : null}
                      // onChange={(e) => handleTableDataChange(e, index, "date")}
                      onChange={(newDate) =>
                        handleTableDateChange(newDate, index, "date")
                      }
                      {...(disableFlag && { disabled: true })}
                      slotProps={{
                        textField: {
                          readOnly: true,
                        },
                      }}
                      slots={{
                        actionBar: (props) => (
                          <CustomActionButtons {...props} />
                        ),
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </TableCell>
              <TableCell style={tableCellStyle}>
                <TextField
                  onChange={(e) =>
                    handleTableDataChange(e, index, "description")
                  }
                  fullWidth
                  size="small"
                  variant="standard"
                  value={row.description || ""}
                  inputProps={{ readOnly: disableFlag }}
                />
              </TableCell>
              <TableCell style={tableCellStyle}>
                <TextField
                  onChange={(e) =>
                    handleTableDataChange(e, index, "doseOrUnits")
                  }
                  fullWidth
                  size="small"
                  variant="standard"
                  value={row.doseOrUnits || ""}
                  inputProps={{ readOnly: disableFlag }}
                />
              </TableCell>
              <TableCell style={tableCellStyle}>
                {/* <TextField
                    onChange={(e) => handleTableDataChange(e, index, "route")}
                    fullWidth
                    size="small"
                    variant="standard"
                    value={row.route || ""}
                  /> */}
                <Select
                  value={row.route || ""}
                  onChange={(e) => handleTableDataChange(e, index, "route")}
                  {...(disableFlag && { disabled: true })}
                  fullWidth
                  displayEmpty
                  size="small"
                  variant="standard"
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                    style: { maxHeight: 300 },
                  }}
                >
                  <MenuItem value="" disabled>
                    <span>Select</span>
                  </MenuItem>
                  {routeOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </TableCell>
              <TableCell style={tableCellStyle}>
                <TextField
                  onChange={(e) => handleTableDataChange(e, index, "frequency")}
                  inputProps={{ readOnly: disableFlag }}
                  fullWidth
                  size="small"
                  variant="standard"
                  value={row.frequency || ""}
                />
              </TableCell>
              {counterMedicationsRowData.length > 1 && (
                <TableCell style={tableCellStyle}>
                  <RemoveIcon
                    style={{ color: "var(--mainTheme)", cursor: "pointer" }}
                    onClick={() => {
                      if (disableFlag) {
                        return null;
                      }
                      handleRemoveRow(index);
                    }}
                  />
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ marginRight: "20px" }}>
          Click on - button to delete respective row.
        </div>
        <div>Click on + button to add 5 Prescription maximum.</div>
      </div>
    </div>
  );
}

function MiniTable({
  rowData,
  setRowData,
  labelText,
  pointsLabel,
  options,
  description,
  inputType = "select",
  disabled = false,
  cellStyles = {},
  disableFlag,
}) {
  const handleTableDataChange = (e, points) => {
    setRowData((prevData) => ({
      ...prevData,
      [points]: e.target.value,
    }));
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "15px",
        marginBottom: "10px",
        marginLeft: "10px",
        width: "100%",
        margin: "auto",
      }}
    >
      <table
        style={{
          width: "100%",
          borderCollapse: "collapse",
          marginBottom: "10px",
          fontFamily: "Inter, sans-serif",
        }}
      >
        <tbody>
          <tr>
            <td
              style={{
                padding: "8px",
                width: "20%",
                ...cellStyles.label,
              }}
            >
              {`${labelText} :`}
            </td>
            <td
              style={{
                padding: "8px",
                width: "5%",
                ...cellStyles.points,
              }}
            >
              {inputType === "select" ? (
                <select
                  value={rowData.points || ""}
                  onChange={(e) => handleTableDataChange(e, "points")}
                  disabled={disableFlag}
                  style={{ width: "100%" }}
                >
                  {options.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              ) : (
                <input
                  type="number"
                  id="pointsInput"
                  value={rowData.points || ""}
                  onChange={(e) => handleTableDataChange(e, "points")}
                  disabled={disableFlag || disabled}
                  style={{ width: "100%" }}
                />
              )}
              <label htmlFor="pointsInput">{pointsLabel}</label>
            </td>
            <td
              style={{
                padding: "8px",
                width: "50%",
                textAlign: "left",
                ...cellStyles.description,
              }}
            >
              {description}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export function DynamicWordRecall({
  wordRecallRowData,
  setWordRecallRowData,
  disableFlag,
}) {
  const options = ["-", "0", "1", "2", "3"];
  const wordRecallCellStyles = {
    label: { width: "12%", padding: "8px" },
    points: { width: "5%", padding: "8px" },
    description: { width: "75%", padding: "8px" },
  };

  return (
    <MiniTable
      rowData={wordRecallRowData}
      setRowData={setWordRecallRowData}
      labelText="Word Recall"
      pointsLabel="Points"
      options={options}
      cellStyles={wordRecallCellStyles}
      description={`1 point for each word spontaneously recalled without cueing.`}
      disableFlag={disableFlag}
    />
  );
}

export function DynamicClockDraw({
  clockDrawRowData,
  setClockDrawRowData,
  disableFlag,
}) {
  const options = ["-", "0", "2"];
  const clockDrawCellStyles = {
    label: { width: "12%", padding: "8px" },
    points: { width: "5%", padding: "8px" },
    description: { width: "75%", padding: "8px" },
  };

  return (
    <MiniTable
      rowData={clockDrawRowData}
      setRowData={setClockDrawRowData}
      labelText="Clock Draw"
      pointsLabel="Points"
      options={options}
      cellStyles={clockDrawCellStyles}
      description={`Normal clock = 2 points. A normal clock has all numbers placed in the correct positions  with no missing or duplicate numbers. Hands are pointing to the 11 sequence and approximately correct position (e.g., 12, 3, 6 and 9 are in anchor Inability or refusal to draw a clock (abnormal) = 0 points and 2 (11:10). Hand length is not scored).`}
      disableFlag={disableFlag}
    />
  );
}

export function DynamicTotalScore({ totalScoreRowData, setTotalScoreRowData }) {
  const totalScoreStyles = {
    label: { width: "12%", padding: "8px" },
    points: { width: "5%", padding: "8px" },
    description: { width: "75%", padding: "8px" },
  };
  return (
    <MiniTable
      rowData={totalScoreRowData}
      setRowData={setTotalScoreRowData}
      labelText="Total Score"
      pointsLabel="Points"
      inputType="number"
      disabled={true}
      cellStyles={totalScoreStyles}
      description={`Total score = Word Recall score + Clock Draw score. A cut point of < 3 on the Mini-Cog™ has been validated for dementia screening, but many individuals with clinically meaningful cognitive impairment will score higher. When greater sensitivity is desired, a cut point of < 4 is recommended as it may indicate a need for further evaluation of cognitive status.`}
    />
  );
}

const depressionSeverity = [
  [
    "1 - 4",
    "Minimal Depression",
    "ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeveritySubMini",
  ],
  [
    "5 - 9",
    "Mild Depression",
    "ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeveritySubMild",
  ],
  [
    "10 - 14",
    "Moderate Depression",
    "ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeveritySubModerate",
  ],
  [
    "15 - 19",
    "Moderately Severe Depression",
    "ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeverityModerateSevere",
  ],
  [
    "20 - 27",
    "Severe Depression",
    "ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeveritySevere",
  ],
];

export function DynamicDepressionSeverity({
  tableName,
  tableData,
  setFieldValues,
}) {
  const handleCheckChange = (e) => {
    const { name, checked } = e.target;
    const updatedData = { ...tableData };
    depressionSeverity.forEach((ele) => {
      const deleteItem = ele[2];
      updatedData[deleteItem] = "";
    });
    let newData = checked ? "1" : "";
    updatedData[name] = newData;

    setFieldValues(tableName, updatedData, "datatable");
  };
  return (
    <Grid>
      <table
        style={{
          margin: "0 auto",
          borderCollapse: "collapse",
          border: "1px solid var(--mainTheme)",
        }}
      >
        <thead>
          <tr>
            <th>Score</th>
            <th>Depression Severity</th>
          </tr>
        </thead>
        <tbody>
          {depressionSeverity.map((tableRow) => {
            const [score, depText, depName] = tableRow;
            const checked = tableData?.[depName] === "1" ? true : false;
            return (
              <tr key={`${depName}`}>
                <td
                  style={{
                    border: "1px solid var(--mainTheme)",
                    padding: "8px",
                  }}
                >
                  {score}
                </td>
                <td
                  style={{
                    border: "1px solid var(--mainTheme)",
                    padding: "8px",
                  }}
                >
                  <input
                    type="checkbox"
                    name={depName}
                    checked={checked}
                    onChange={handleCheckChange}
                  />
                  {depText}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Grid>
  );
}

const depressionSeverityStatic = [
  ["0 - 4", "Non-Minimal", "None"],
  ["5 - 9", "Mild", "Watchful waiting: repeat PHQ-9 at follow-up"],
  [
    "10 - 14",
    "Moderate",
    "Treatment plan, consider counseling, follow-up and/or pharmacotherapy",
  ],
  [
    "15 - 19",
    "Moderately Severe",
    "Active treatment with pharmacotherapy and/or psychotherapy",
  ],
  [
    "20 - 27",
    "Severe",
    "Immediate initiation of pharmochotherapy and, if severe impairment or poor response to therapy, expedited referral to a mental health specialist",
  ],
];

export function DynamicDepressionSeverityStatic({ xs }) {
  return (
    <Grid item xs={xs}>
      <table
        style={{
          // margin: "0 auto",
          borderCollapse: "collapse",
          border: "1px solid var(--mainTheme)",
        }}
      >
        <thead>
          <tr>
            <th>PHQ-9 Score</th>
            <th>Depression Severity</th>
            <th>Proposed Treatment</th>
          </tr>
        </thead>
        <tbody>
          {depressionSeverityStatic.map((tableRow) => {
            const [score, depText, propTreat] = tableRow;
            return (
              <tr key={`${score}`}>
                <td
                  style={{
                    border: "1px solid var(--mainTheme)",
                    padding: "8px",
                  }}
                >
                  {score}
                </td>
                <td
                  style={{
                    border: "1px solid var(--mainTheme)",
                    padding: "8px",
                  }}
                >
                  {depText}
                </td>
                <td
                  style={{
                    border: "1px solid var(--mainTheme)",
                    padding: "8px",
                  }}
                >
                  {propTreat}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Grid>
  );
}

export function StaticTimedUpGoDatatable({
  tableName,
  tableData,
  setFieldValues,
  disableFlag,
}) {
  const tableCellStyle = {
    border: "1px solid var(--mainTheme)",
    padding: "8px",
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const updatedData = { ...tableData, [name]: value };
    setFieldValues(tableName, updatedData, "datatable");
  };

  return (
    <table
      style={{
        margin: "10px 20px",
        borderCollapse: "collapse",
        border: "1px solid var(--mainTheme)",
        width: "100%",
      }}
    >
      <tbody>
        <tr key={`row-1`}>
          <td key={`row-1-cell-1`} style={tableCellStyle}>
            <p>1. Instruct the patient: When I say “Go”, I want you to:</p>
            <p style={{ marginLeft: "15px" }}>• Stand up from the chair.</p>
            <p style={{ marginLeft: "15px" }}>
              • Walk to the line on the floor at your normal pace.
            </p>
            <p style={{ marginLeft: "15px" }}>• Turn.</p>
            <p style={{ marginLeft: "15px" }}>
              • Walk back to the chair at your normal pace.
            </p>
            <p style={{ marginLeft: "15px" }}>• Sit down again.</p>
          </td>
          <td key={`row-1-cell-2`} style={tableCellStyle}>
            <p>2. On the word “Go,” begin timing.</p>
            <p>3. Stop timing after patient sits back down.</p>
            <p>4. Record time</p>
            <label
              htmlFor="home_timeInSeconds"
              style={{ display: "block", marginBottom: "5px" }}
            >
              Time in Seconds:
            </label>
            <input
              type="text"
              name="home_timeInSeconds"
              onFocus={(e) => {
                e.target.style.border = "1px solid var(--mainTheme)";
                e.target.style.outline = "none";
              }}
              onBlur={(e) => {
                e.target.style.border = "1px solid var(--mainTheme)";
              }}
              disabled={disableFlag}
              value={tableData.home_timeInSeconds || ""}
              onChange={handleInputChange}
              style={{
                width: "100%",
                padding: "8px",
                margin: "10px 0",
                border: "1px solid var(--mainTheme)",
              }}
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export function TableComponentStagesOfKidneyDisease() {
  const table = staticData.find(
    (item) => item.table_name === "Stages Of Kidney Disease",
  );

  if (!table) {
    return null;
  }

  const columnData = table.column_data[0];

  const transformedData = [];
  for (let i = 0; i < columnData.rows.length; i += 2) {
    transformedData.push(columnData.rows.slice(i, i + 2));
  }

  return (
    <Grid
    // style={{
    //   textAlign: "center",
    //   justifyContent: "center",
    //   alignItems: "center",
    //   margin: "30px",
    // }}
    >
      <Table
        sx={{
          tableLayout: "auto",
          margin: "10px",
          overflow: "hidden",
          width: "50vw",
          marginLeft: "80px",
        }}
        size="small"
      >
        <TableHead>
          <TableRow>
            <TableCell
              colSpan={2}
              style={{
                fontWeight: "bold",
                backgroundColor: "var(--mainTheme)",
                color: "#fff",
                textAlign: "center",
                padding: "8px",
              }}
            >
              Stages of Kidney Disease
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {transformedData.map((row, rowIndex) => (
            <TableRow key={`row-${rowIndex}`}>
              {row.map((cell, cellIndex) => (
                <TableCell
                  key={`cell-${rowIndex}-${cellIndex}`}
                  style={{
                    border: "1px solid var(--mainTheme)",
                    padding: "8px",
                    textAlign: "center",
                  }}
                >
                  {cell}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Grid>
  );
}
