import React, { useState } from "react";
import Sidebar from "../../components/Dashboard/Sidebar/Sidebar";
import Header from "../../components/Dashboard/Header/Header";
import { AlertProvider } from "../../contexts/AlertContext";
import { Routes, Route } from "react-router-dom";
import NurseCalendar from "./Calendar";
import NurseDashboard from "./NurseDashboard";
import AddNewNurse from "./AddNewNurse/AddNewNurse";
import { InputProvider } from "../../contexts/InputContext";
import Footer from "../../components/Footer";
import FillHRAForm from "../AdminDashboard/MemberListPage/FillHraForm";
import { NurseFilterDialogs } from "../../components/Dashboard/Dialog/Dialog";
import AddMembersPage from "../AdminDashboard/AddMembersPage/AddMembersPage";
export default function Index() {
  const [sidebarCollapse, setSidebarCollapse] = useState(false);
  const pathName = window.location.pathname;
  const roleId = sessionStorage.getItem("roleId");
  return (
    <AlertProvider>
      <div className="body">
        {!pathName.includes("/FillHRAForm") ? (
          <div className={!sidebarCollapse ? "sidebar" : "sidebar inactive"}>
            <Sidebar roleId={roleId} />
          </div>
        ) : null}
        <div
          style={
            pathName.includes("/FillHRAForm")
              ? { width: "100%", height: "100vh", marginInline: "0px" }
              : undefined
          }
          className={!sidebarCollapse ? "body-content" : "body-content active"}
        >
          <Header sidebarToggle={setSidebarCollapse} />
          <Routes>
            <Route path="/Nurselist" element={<NurseDashboard />} />
            <Route path="/Calendar" element={<NurseCalendar />} />
            <Route
              path="/AddNewMember"
              element={
                <InputProvider>
                  <AddMembersPage />
                </InputProvider>
              }
            />
            <Route
              path={`/FillHRAForm`}
              element={
                // <RedirectToLogin moveToLogin={hasLoggedIn}>
                <FillHRAForm />
                // </RedirectToLogin>
              }
            />
          </Routes>
          <Footer />
        </div>
      </div>
    </AlertProvider>
  );
}
