import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import InputAdornment from "@mui/material/InputAdornment";
import Tooltip from "@mui/material/Tooltip";
import TextField from "@mui/material/TextField";
import { SelectDropdown } from "../../components/select/select";
import { Zoom } from "@mui/material";
import CustomizedDialogs from "../../components/Dashboard/Dialog/Dialog";
import AlertContext from "../../contexts/AlertContext";
import "./coderList.css";
import { useContext, useState, useEffect } from "react";
import { useUserContext } from "../../contexts/UserContext";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import React from "react";
import { makeStyles } from "@mui/styles";
import Toaster from "../../components/toaster/toaster";
import { CoderFilterDialogs } from "../../components/Dashboard/Dialog/Dialog";
const useStyles = makeStyles((theme) => ({
  verticalDivider: {
    height: "100%",

    margin: 1,
    width: "5px",
    backgroundColor: "#1976d2",
  },

  card: {
    paddingX: "20px",
    textAlign: "center",
    width: "100%",
  },
  root: {
    border: "1px solid",
    padding: "10px",
    boxShadow: "5px 10px red",
  },
}));

const LeadCoderListHeader = ({
  onDropdownChange,
  hraStatusOptions,
  onSearch,
  visitTypeOptions,
  clinicianData,
  selectedCheckboxes,
}) => {
  const { clientData, handleBadgeClick } = useUserContext();
  const [open, setOpen] = useState(false);
  const roleId = sessionStorage.getItem("roleId");
  const memberListOptions =
    Number(roleId) == 8 // coder lead
      ? [
          "Ready For Coding",
          "Clinician Review",
          "Ready For Upload",
          "Completed",
        ]
      : ["Ready For Coding", "Clinician Review"]; //coder

  const filterDropdown = ["First name", "Identification#", "Last name"];
  const alertContext = useContext(AlertContext);
  const [selectedDropdownValue, setSelectedDropdownValue] =
    useState("Ready For Coding");
  const [selectedFilter, setSelectedFilter] = useState(filterDropdown[1]);
  const [isFirstNameSelected, setIsFirstNameSelected] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [toasterMessage, setToasterMessage] = useState("");
  const [toasterSeverity, setToasterSeverity] = useState("");
  const [toasterOpen, setToasterOpen] = useState(false);

  const handleDropdownChange = (event) => {
    const newValue = event.target.value;
    setSelectedDropdownValue(newValue);
    onDropdownChange(newValue);
  };

  const toggleOpen = () => {
    alertContext.setFilterDialog(true);
  };
  const storedPlan = localStorage.getItem("clientPlan");
  const storedYear = localStorage.getItem("clientYear");

  const storedYearList = JSON.parse(
    localStorage.getItem("extractedYearOptions"),
  );

  useEffect(() => {
    setSelectedFilter("Identification#");
    onSearch("Identification#", "");
  }, []);

  const handleSearch = (e) => {
    if (e.key == "Enter") {
      if (searchTerm.length > 2) {
        if (selectedFilter === "First name") {
          onSearch(selectedFilter, searchTerm);
        } else if (selectedFilter === "Last name") {
          onSearch(selectedFilter, searchTerm);
        } else if (selectedFilter === "Identification#") {
          onSearch(selectedFilter, searchTerm);
        }
      } else {
        setToasterOpen(true);
        setToasterSeverity("error");
        setToasterMessage("Minimum 3 characters required to search!");
      }
    }
  };
  const handleSearchOnIconClick = () => {
    if (searchTerm.length > 2) {
      onSearch(selectedFilter, searchTerm);
    } else {
      setToasterOpen(true);
      setToasterSeverity("error");
      setToasterMessage("Minimum 3 characters required to search!");
    }
  };

  const handleResetSearch = async () => {
    //setLoading(true);
    setSelectedFilter(filterDropdown[1]);
    setSearchTerm("");
    onSearch(selectedFilter, "");
  };

  const handleFilterDropdownChange = (event) => {
    const { value } = event.target;
    setSelectedFilter(value);
    setIsFirstNameSelected(value === "First name");
  };

  return (
    <>
      <div className="codermember-list">
        <CoderFilterDialogs
          hraStatusOptions={hraStatusOptions}
          visitTypeOptions={visitTypeOptions}
          clinicianData={clinicianData}
        />

        <div className="codermember-list-left">
          {(clientData?.isAllSelected === true ||
            localStorage.getItem("isAllSelected") === "true") &&
          (clientData?.plan !== null || storedPlan) &&
          (clientData?.year !== null || storedYearList) ? (
            <Tooltip
              TransitionComponent={Zoom}
              title={`Selected: ${clientData?.plan || storedPlan}-${
                clientData?.year || storedYear
              }`}
              placement="top"
              arrow
              enterDelay={150}
              leaveDelay={100}
            >
              <Box
                className="filterBoxML"
                onClick={handleBadgeClick}
                style={{ borderRadius: "50px" }}
              >
                <p>{`${clientData?.plan || storedPlan}-${
                  clientData?.year || storedYear
                }`}</p>
              </Box>
            </Tooltip>
          ) : (
            <></>
          )}
        </div>
        <div className="codermember-list-right">
          <Box className="filterBoxFilterML" onClick={toggleOpen}>
            <p>Filter</p>
            <FilterAltIcon sx={{ height: "26px", width: "26px" }} />
          </Box>
          <SelectDropdown
            sx={{
              borderRadius: 20,
              width: "154px",
              height: "40px",
              fontSize: "16px",
              fontWeight: 500,
              marginRight: 1,
              marginLeft: 2,
              "&:hover": {
                backgroundColor: "var(--mainTheme)",
              },
            }}
            title="Identification"
            options={filterDropdown}
            className="dropdownMemberList"
            valueClass="dropdownValueMemberList"
            onChange={handleFilterDropdownChange}
          />
          <Box
            className="searchBoxML"
            sx={{ background: "var(--mainTheme200)" }}
          >
            <TextField
              onKeyDown={(e) => handleSearch(e)}
              placeholder="Search"
              InputProps={{
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon
                      style={{ marginLeft: "5px", cursor: "pointer" }}
                      onClick={(e) => handleSearchOnIconClick()}
                    />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    {searchTerm && (
                      <CancelOutlinedIcon
                        style={{ marginRight: "5px", cursor: "pointer" }}
                        onClick={handleResetSearch}
                      />
                    )}
                  </InputAdornment>
                ),
              }}
              variant="standard"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </Box>
        </div>
      </div>

      {(clientData?.isAllSelected === true ||
        localStorage.getItem("isAllSelected") === "true") &&
      (clientData?.plan !== null || storedPlan) &&
      (clientData?.year !== null || storedYearList) ? (
        <div style={{ marginTop: "22px" }}>
          <Box sx={{ width: "100%" }}>
            <Tabs
              className="custom-tabs"
              value={selectedDropdownValue}
              onChange={(e, newValue) =>
                handleDropdownChange({ target: { value: newValue } })
              }
              aria-label="wrapped label tabs example"
            >
              {memberListOptions.map((option, index) => (
                <Tab key={index} value={option} label={option} />
              ))}
            </Tabs>
          </Box>
        </div>
      ) : (
        <></>
      )}
      <Toaster
        open={toasterOpen}
        onClose={() => setToasterOpen(false)}
        severity={toasterSeverity}
        message={toasterMessage}
      />
    </>
  );
};

export default LeadCoderListHeader;
