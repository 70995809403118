import React, { useState, useContext, useEffect } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  Grid,
  FormControl,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { DragNDropContext } from "../../../../../../contexts/DragNDropContext";

export const PadEligibility = ({
  disableFlag,
  screeningsFormData,
  setscreeningsFormData,
}) => {
  const { handleButtonAction, padEligibilityDialog, setPadEligibilityDialog } =
    useContext(DragNDropContext);

  const [padTestingEligibility, setPadTestingEligibility] = useState({
    isButtocks: "",
    buttocksSubAnswers: {
      stopAndRest: "",
    },
    diabets: "",
    smoke: "",
    cholesterol: "",
  });

  const [showText, setShowText] = useState(false);

  const screenIha = sessionStorage.getItem("screenIha");

  useEffect(() => {
    if (screeningsFormData && screeningsFormData?.padTestingEligibility) {
      setPadTestingEligibility({
        ...screeningsFormData?.padTestingEligibility,
        isButtocks:
          screeningsFormData?.padTestingEligibility?.isButtocks === "1"
            ? "yes"
            : screeningsFormData?.padTestingEligibility?.isButtocks === "2"
            ? "no"
            : "",
        buttocksSubAnswers: {
          stopAndRest:
            screeningsFormData.padTestingEligibility.buttocksSubAnswers
              ?.stopAndRest === "1"
              ? "yes"
              : screeningsFormData.padTestingEligibility.buttocksSubAnswers
                  ?.stopAndRest === "2"
              ? "no"
              : "",
        },
        diabets:
          screeningsFormData.padTestingEligibility.diabets === "1"
            ? "yes"
            : screeningsFormData.padTestingEligibility.diabets === "2"
            ? "no"
            : "",
        smoke:
          screeningsFormData.padTestingEligibility.smoke === "1"
            ? "yes"
            : screeningsFormData.padTestingEligibility.smoke === "2"
            ? "no"
            : "",
        cholesterol:
          screeningsFormData.padTestingEligibility.cholesterol === "1"
            ? "yes"
            : screeningsFormData.padTestingEligibility.cholesterol === "2"
            ? "no"
            : "",
      });
    }
  }, [screeningsFormData]);

  const handleSubmit = () => {
    const updatedFormData = {
      ...screeningsFormData,
      padTestingEligibility: {
        ...padTestingEligibility,
        isButtocks:
          padTestingEligibility.isButtocks === "yes"
            ? "1"
            : padTestingEligibility.isButtocks === "no"
            ? "2"
            : "",
        buttocksSubAnswers: {
          stopAndRest:
            padTestingEligibility.buttocksSubAnswers.stopAndRest === "yes"
              ? "1"
              : padTestingEligibility.buttocksSubAnswers.stopAndRest === "no"
              ? "2"
              : "",
        },
        diabets:
          padTestingEligibility.diabets === "yes"
            ? "1"
            : padTestingEligibility.diabets === "no"
            ? "2"
            : "",
        smoke:
          padTestingEligibility.smoke === "yes"
            ? "1"
            : padTestingEligibility.smoke === "no"
            ? "2"
            : "",
        cholesterol:
          padTestingEligibility.cholesterol === "yes"
            ? "1"
            : padTestingEligibility.cholesterol === "no"
            ? "2"
            : "",
      },
    };
    let yesCount = 0;

    if (
      padTestingEligibility.isButtocks === "yes" &&
      padTestingEligibility.buttocksSubAnswers?.stopAndRest === "yes"
    ) {
      yesCount += 1;
    }

    yesCount +=
      (padTestingEligibility.diabets === "yes" ? 1 : 0) +
      (padTestingEligibility.smoke === "yes" ? 1 : 0) +
      (padTestingEligibility.cholesterol === "yes" ? 1 : 0);

    updatedFormData.padVal = yesCount >= 2 ? ["yes"] : ["no"];

    setscreeningsFormData(updatedFormData);
    setShowText(true);

    setTimeout(() => {
      setShowText(false);
      setPadEligibilityDialog(false);
    }, 2000);
  };

  const allFieldsSelected = () => {
    return (
      padTestingEligibility.isButtocks !== "" &&
      (padTestingEligibility.isButtocks === "no" ||
        padTestingEligibility.buttocksSubAnswers.stopAndRest !== "") &&
      padTestingEligibility.diabets !== "" &&
      padTestingEligibility.smoke !== "" &&
      padTestingEligibility.cholesterol !== ""
    );
  };

  const allFieldsSelectedDisable = () => {
    return (
      padTestingEligibility.isButtocks !== "" &&
      (padTestingEligibility.isButtocks === "no" ||
        padTestingEligibility.buttocksSubAnswers.stopAndRest !== "") &&
      padTestingEligibility.diabets !== "" &&
      padTestingEligibility.smoke !== "" &&
      padTestingEligibility.cholesterol !== ""
    );
  };

  const roleId = sessionStorage.getItem("roleId");
  const getPadValue = () => {
    if (
      (screeningsFormData?.padVal?.includes("no") ||
        // screeningsFormData?.padVal?.includes("") ||
        screeningsFormData?.padVal?.includes("yes")) &&
      roleId == "5"
    ) {
      return true;
    }
  };

  const resetOnExit = () => {
    setPadTestingEligibility({
      ...screeningsFormData?.padTestingEligibility,
      isButtocks:
        screeningsFormData?.padTestingEligibility?.isButtocks === "1"
          ? "yes"
          : screeningsFormData?.padTestingEligibility?.isButtocks === "2"
          ? "no"
          : "",
      buttocksSubAnswers: {
        stopAndRest:
          screeningsFormData.padTestingEligibility.buttocksSubAnswers
            ?.stopAndRest === "1"
            ? "yes"
            : screeningsFormData.padTestingEligibility.buttocksSubAnswers
                ?.stopAndRest === "2"
            ? "no"
            : "",
      },
      diabets:
        screeningsFormData.padTestingEligibility.diabets === "1"
          ? "yes"
          : screeningsFormData.padTestingEligibility.diabets === "2"
          ? "no"
          : "",
      smoke:
        screeningsFormData.padTestingEligibility.smoke === "1"
          ? "yes"
          : screeningsFormData.padTestingEligibility.smoke === "2"
          ? "no"
          : "",
      cholesterol:
        screeningsFormData.padTestingEligibility.cholesterol === "1"
          ? "yes"
          : screeningsFormData.padTestingEligibility.cholesterol === "2"
          ? "no"
          : "",
    });
  };
  // const getPadValueForScreening = () => {
  //   if (
  //     screeningsFormData?.padVal?.includes("no") ||
  //     //screeningsFormData?.padVal?.includes("") ||
  //     screeningsFormData?.padVal?.includes("yes")
  //   ) {
  //     return true;
  //   }
  // };
  return (
    <Grid>
      <Button
        onClick={() => handleButtonAction("padTestingEligibility")}
        aria-pressed
        disableRipple
        sx={{
          marginTop: "20px",
          minHeight: "70px",
          margin: "12px",
          width: "230px",
          textAlign: "center",
          justifyContent: "center",
          borderRadius: "5px",
          borderStyle: "none",
          textTransform: "none",
          border: `1px solid var(--mainTheme) !important`,
          padding: "5px",
          fontSize: "0.85rem",
          backgroundColor: padEligibilityDialog ? "var(--mainTheme)" : "white",
          color: padEligibilityDialog ? "white" : "black",
          "&:disabled": {
            color: "black",
            fontWeight: "600",
            fontSize: "0.85rem",
          },
          "&:hover": {
            backgroundColor: "var(--mainTheme400)",
            color: padEligibilityDialog ? "white" : "black",
          },
        }}
        disabled={
          // screenIha == "Screen Only"
          //   ? getPadValueForScreening()
          //   :
          disableFlag || getPadValue()
        }
      >
        Pad Test Eligibility
      </Button>

      {padEligibilityDialog && (
        <Dialog
          open={padEligibilityDialog}
          onClose={() => setPadEligibilityDialog(false)}
          fullWidth
          maxWidth="md"
          PaperProps={{
            style: { maxWidth: "700px", width: "100%", margin: "20px" },
          }}
        >
          <DialogTitle
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "var(--mainTheme)",
              color: "white",
              fontWeight: 600,
              height: "40px",
            }}
          >
            PAD Testing Eligibility
          </DialogTitle>

          <DialogContent
            style={{
              padding: "20px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <FormControl
              component="fieldset"
              fullWidth
              style={{
                marginBottom: "20px",
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <span style={{ flex: "1 1 50%", maxWidth: "50%" }}>
                Do you have pain in your buttocks, legs or feet when walking?
              </span>
              <RadioGroup
                aria-label="walkingPain"
                name="walkingPain"
                value={padTestingEligibility.isButtocks}
                onChange={(event) =>
                  setPadTestingEligibility({
                    ...padTestingEligibility,
                    isButtocks: event.target.value,
                    buttocksSubAnswers: {
                      ...padTestingEligibility.buttocksSubAnswers,
                      stopAndRest:
                        event.target.value === "yes"
                          ? padTestingEligibility.buttocksSubAnswers.stopAndRest
                          : null,
                    },
                  })
                }
                style={{
                  flex: "1 1 50%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  marginLeft: "auto",
                }}
              >
                <FormControlLabel
                  value="yes"
                  control={<Radio />}
                  label="Yes"
                  labelPlacement="end"
                  // sx={{ marginRight: "30px" }}
                  sx={{
                    marginRight: "30px",
                    "& .MuiFormControlLabel-label.Mui-disabled": {
                      color: "black",
                    },
                  }}
                  disabled={allFieldsSelectedDisable()}
                />
                <FormControlLabel
                  value="no"
                  control={<Radio />}
                  label="No"
                  labelPlacement="end"
                  sx={{
                    marginRight: "30px",
                    "& .MuiFormControlLabel-label.Mui-disabled": {
                      color: "black",
                    },
                  }}
                  // sx={{ marginLeft: "30px" }}
                  disabled={allFieldsSelectedDisable()}
                />
              </RadioGroup>
            </FormControl>
            {/* <hr style={{ width: "100%", margin: "10px 0" }} /> */}

            {padTestingEligibility.isButtocks === "yes" && (
              <Grid item xs={12}>
                <FormControl
                  component="fieldset"
                  fullWidth
                  style={{
                    // marginBottom: "20px",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: "60px",
                    }}
                  >
                    <span style={{ marginRight: "20px" }}>
                      Does it go away when you stop and rest?
                    </span>{" "}
                    <RadioGroup
                      aria-label="restingRelief"
                      name="restingRelief"
                      value={
                        padTestingEligibility.buttocksSubAnswers.stopAndRest
                      }
                      onChange={(event) =>
                        setPadTestingEligibility({
                          ...padTestingEligibility,
                          buttocksSubAnswers: {
                            ...padTestingEligibility.buttocksSubAnswers,
                            stopAndRest: event.target.value,
                          },
                        })
                      }
                      style={{ flexDirection: "column" }}
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio />}
                        label="Yes"
                        labelPlacement="end"
                        disabled={allFieldsSelectedDisable()}
                        sx={{
                          "& .MuiFormControlLabel-label.Mui-disabled": {
                            color: "black",
                          },
                        }}
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio />}
                        label="No"
                        labelPlacement="end"
                        disabled={allFieldsSelectedDisable()}
                        sx={{
                          "& .MuiFormControlLabel-label.Mui-disabled": {
                            color: "black",
                          },
                        }}
                      />
                    </RadioGroup>
                  </div>
                </FormControl>
              </Grid>
            )}
            <hr style={{ width: "100%", margin: "10px 0" }} />

            <FormControl
              component="fieldset"
              fullWidth
              style={{
                marginBottom: "20px",
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <span style={{ flex: "1 1 50%", maxWidth: "50%" }}>
                Do you have diabetes?
              </span>
              <RadioGroup
                aria-label="diabetes"
                name="diabetes"
                value={padTestingEligibility.diabets}
                onChange={(event) =>
                  setPadTestingEligibility({
                    ...padTestingEligibility,
                    diabets: event.target.value,
                  })
                }
                style={{
                  flex: "1 1 50%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
              >
                <FormControlLabel
                  value="yes"
                  control={<Radio />}
                  label="Yes"
                  labelPlacement="end"
                  // sx={{ marginRight: "30px" }}
                  sx={{
                    marginRight: "30px",
                    "& .MuiFormControlLabel-label.Mui-disabled": {
                      color: "black",
                    },
                  }}
                  disabled={allFieldsSelectedDisable()}
                />
                <FormControlLabel
                  value="no"
                  control={<Radio />}
                  label="No"
                  labelPlacement="end"
                  // sx={{ marginLeft: "30px" }}
                  sx={{
                    marginRight: "30px",
                    "& .MuiFormControlLabel-label.Mui-disabled": {
                      color: "black",
                    },
                  }}
                  disabled={allFieldsSelectedDisable()}
                />
              </RadioGroup>
            </FormControl>
            <hr style={{ width: "100%", margin: "10px 0" }} />

            <FormControl
              component="fieldset"
              fullWidth
              style={{
                marginBottom: "20px",
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <span style={{ flex: "1 1 50%", maxWidth: "50%" }}>
                Do you smoke?
              </span>
              <RadioGroup
                aria-label="smoke"
                name="smoke"
                value={padTestingEligibility.smoke}
                onChange={(event) =>
                  setPadTestingEligibility({
                    ...padTestingEligibility,
                    smoke: event.target.value,
                  })
                }
                style={{
                  flex: "1 1 50%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
              >
                <FormControlLabel
                  value="yes"
                  control={<Radio />}
                  label="Yes"
                  labelPlacement="end"
                  // sx={{ marginRight: "30px" }}
                  sx={{
                    marginRight: "30px",
                    "& .MuiFormControlLabel-label.Mui-disabled": {
                      color: "black",
                    },
                  }}
                  disabled={allFieldsSelectedDisable()}
                />
                <FormControlLabel
                  value="no"
                  control={<Radio />}
                  label="No"
                  labelPlacement="end"
                  // sx={{ marginLeft: "30px" }}
                  sx={{
                    marginRight: "30px",
                    "& .MuiFormControlLabel-label.Mui-disabled": {
                      color: "black",
                    },
                  }}
                  disabled={allFieldsSelectedDisable()}
                />
              </RadioGroup>
            </FormControl>
            <hr style={{ width: "100%", margin: "10px 0" }} />

            <FormControl
              component="fieldset"
              fullWidth
              style={{
                marginBottom: "20px",
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <span style={{ flex: "1 1 50%", maxWidth: "50%" }}>
                Do you have high cholesterol?
              </span>
              <RadioGroup
                aria-label="cholesterol"
                name="cholesterol"
                value={padTestingEligibility.cholesterol}
                onChange={(event) =>
                  setPadTestingEligibility({
                    ...padTestingEligibility,
                    cholesterol: event.target.value,
                  })
                }
                style={{
                  flex: "1 1 50%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
              >
                <FormControlLabel
                  value="yes"
                  control={<Radio />}
                  label="Yes"
                  labelPlacement="end"
                  // sx={{ marginRight: "30px" }}
                  sx={{
                    marginRight: "30px",
                    "& .MuiFormControlLabel-label.Mui-disabled": {
                      color: "black",
                    },
                  }}
                  disabled={allFieldsSelectedDisable()}
                />
                <FormControlLabel
                  value="no"
                  control={<Radio />}
                  label="No"
                  labelPlacement="end"
                  // sx={{ marginLeft: "30px" }}
                  sx={{
                    marginRight: "30px",
                    "& .MuiFormControlLabel-label.Mui-disabled": {
                      color: "black",
                    },
                  }}
                  disabled={allFieldsSelectedDisable()}
                />
              </RadioGroup>
            </FormControl>
            <hr style={{ width: "100%", margin: "10px 0" }} />
          </DialogContent>

          <DialogActions
            style={{
              padding: "20px",
              justifyContent: "center",
              margin: 0,
              display: "flex",
              width: "100%",
            }}
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: "var(--mainTheme)",
                color: "white",
                marginRight: "10px",
                "&:disabled": {
                  backgroundColor: "var(--mainTheme400)",
                },
              }}
              onClick={() => {
                handleSubmit();
                // setPadEligibilityDialog(false);
              }}
              disabled={!allFieldsSelected()}
            >
              Submit
            </Button>

            <Button
              variant="contained"
              onClick={() => {
                setPadEligibilityDialog(false);
                resetOnExit();
              }}
              sx={{ color: "white", borderColor: "var(--mainTheme)" }}
            >
              Exit
            </Button>
          </DialogActions>

          {showText && (
            <div style={{ marginBottom: "20px" }}>
              <div style={{ textAlign: "center" }}>
                {screeningsFormData &&
                screeningsFormData.padVal &&
                screeningsFormData.padVal[0] === "yes" ? (
                  <span style={{ color: "green" }}>
                    The member is a candidate for PAD test
                  </span>
                ) : (
                  <span style={{ color: "red" }}>
                    The member is not a candidate for PAD test
                  </span>
                )}
              </div>
            </div>
          )}
        </Dialog>
      )}
    </Grid>
  );
};
