const json2023 = {
  sectionId: 5,
  sectionName: "Activities of Daily Living",
  isEditable: false,
  questions: [
    {
      id: 7,
      name: "difficultyWithActivities",
      value: "difficultyWithActivities",
      type: "label",
      label: "Do you have any difficulty with the following activities?",
      editToggle: false,
      xs: 12,
      required: "no",
      placeholder: "",
      defaultValue: "",
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 1,
    },
    {
      id: 6,
      name: "activities_gettingInOrOutOfBed",
      value: "gettingInOrOutOfBed",
      type: "options_tree",
      label: "A. Getting in or out of bed",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 1,
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          label: "No",
          value: "no",
          name: "no",
          isConfigured: true,
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Need Some Help",
          value: "needSomeHelp",
          name: "needSomeHelp",
          type: "toggle_button",
        },
        {
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Need Total Help",
          value: "needTotalHelp",
          name: "needTotalHelp",
          type: "toggle_button",
        },
      ],
      questionId: 2,
      editToggle: false,
    },
    {
      id: 6,
      name: "activities_gettingInOrOutOfChairs",
      value: "gettingInOrOutOfChairs",
      type: "options_tree",
      label: "B. Getting in or out of chairs",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 1,
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          label: "No",
          value: "no",
          name: "no",
          isConfigured: true,
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Need Some Help",
          value: "needSomeHelp",
          name: "needSomeHelp",
          type: "toggle_button",
        },
        {
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Need Total Help",
          value: "needTotalHelp",
          name: "needTotalHelp",
          type: "toggle_button",
        },
      ],
      questionId: 3,
      editToggle: false,
    },
    {
      id: 6,
      name: "activities_toileting",
      vale: "toileting",
      type: "options_tree",
      label: "C. Toileting",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 1,
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          label: "No",
          value: "no",
          name: "no",
          isConfigured: true,
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Need Some Help",
          value: "needSomeHelp",
          name: "needSomeHelp",
          type: "toggle_button",
        },
        {
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Need Total Help",
          value: "needTotalHelp",
          name: "needTotalHelp",
          type: "toggle_button",
        },
      ],
      questionId: 4,
      editToggle: false,
    },
    {
      id: 6,
      name: "activities_bathing",
      value: "bathing",
      type: "options_tree",
      label: "D. Bathing",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 1,
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          label: "No",
          value: "no",
          name: "no",
          isConfigured: true,
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Need Some Help",
          value: "needSomeHelp",
          name: "needSomeHelp",
          type: "toggle_button",
        },
        {
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Need Total Help",
          value: "needTotalHelp",
          name: "needTotalHelp",
          type: "toggle_button",
        },
      ],
      questionId: 5,
      editToggle: false,
    },
    {
      id: 6,
      name: "activities_dressing",
      value: "dressing",
      type: "options_tree",
      label: "E. Dressing",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 1,
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          label: "No",
          value: "no",
          name: "no",
          isConfigured: true,
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Need Some Help",
          value: "needSomeHelp",
          name: "needSomeHelp",
          type: "toggle_button",
        },
        {
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Need Total Help",
          value: "needTotalHelp",
          name: "needTotalHelp",
          type: "toggle_button",
        },
      ],
      questionId: 6,
      editToggle: false,
    },
    {
      id: 6,
      name: "activities_eating",
      value: "eating",
      type: "options_tree",
      label: "F. Eating",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 1,
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          label: "No",
          value: "no",
          name: "no",
          isConfigured: true,
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Need Some Help",
          value: "needSomeHelp",
          name: "needSomeHelp",
          type: "toggle_button",
        },
        {
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Need Total Help",
          value: "needTotalHelp",
          name: "needTotalHelp",
          type: "toggle_button",
        },
      ],
      questionId: 7,
      editToggle: false,
    },
    {
      id: 6,
      name: "activities_walking",
      value: "walking",
      type: "options_tree",
      label: "G. Walking",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 1,
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          label: "No",
          value: "no",
          name: "no",
          isConfigured: true,
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "activities_walkingSubNeedSomeHelpSubAnswers",
              value: "needSomeHelpSubAnswers",
              type: "options_tree",
              label: "How far can you walk",
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 3,
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  label: "Household only",
                  value: "householdOnly",
                  name: "householdOnly",
                  isConfigured: true,
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Less than one block",
                  value: "lessThanOneBlock",
                  name: "lessThanOneBlock",
                  type: "toggle_button",
                  depth: 3,
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "One block",
                  value: "oneBlock",
                  name: "oneBlock",
                  type: "toggle_button",
                  depth: 3,
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Two or more blocks",
                  value: "twoOrMore",
                  name: "twoOrMore",
                  type: "toggle_button",
                  depth: 3,
                },
                {
                  subQuestionId: 5,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Non-ambulatory",
                  value: "nonAmbulatory",
                  name: "nonAmbulatory",
                  type: "toggle_button",
                  depth: 3,
                },
              ],
              subQuestionId: 1,
              editToggle: false,
            },
          ],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Need Some Help",
          value: "needSomeHelp",
          name: "needSomeHelp",
          type: "toggle_button",
          depth: 1,
        },
        {
          subQuestionId: 3,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "activities_walkingSubNeededTotalHelpSubAnswers",
              value: "neededTotalHelpSubAnswer",
              type: "options_tree",
              label: "How far can you walk",
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 3,
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  label: "Household only",
                  value: "householdOnly",
                  name: "householdOnly",
                  isConfigured: true,
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Less than one block",
                  value: "lessThanOneBlock",
                  name: "lessThanOneBlock",
                  type: "toggle_button",
                  depth: 3,
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "One block",
                  value: "oneBlock",
                  name: "oneBlock",
                  type: "toggle_button",
                  depth: 3,
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Two or more blocks",
                  value: "twoOrMore",
                  name: "twoOrMore",
                  type: "toggle_button",
                  depth: 3,
                },
                {
                  subQuestionId: 5,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Non-ambulatory",
                  value: "nonAmbulatory",
                  name: "nonAmbulatory",
                  type: "toggle_button",
                  depth: 3,
                },
              ],
              subQuestionId: 1,
              editToggle: false,
            },
          ],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Need Total Help",
          value: "neededTotalHelp",
          name: "neededTotalHelp",
          type: "toggle_button",
          depth: 1,
        },
      ],
      questionId: 8,
      editToggle: false,
    },
    {
      id: 6,
      name: "activities_goingUpOrDownStairs",
      value: "goingUpOrDownStairs",
      type: "options_tree",
      label: "H. Going up or down stairs",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 1,
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          label: "No",
          value: "no",
          name: "no",
          isConfigured: true,
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "activities_goingUpOrDownStairsSubNeedSomeHelpSubAnswers",
              value: "needSomeHelpSubAnswers",
              type: "options_tree",
              label: "How many stairs can you climb",
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 3,
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  label: "None",
                  value: "none",
                  isConfigured: true,
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Three to five",
                  value: "threeToFive",
                  name: "threeToFive",
                  type: "toggle_button",
                  depth: 3,
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Six to ten",
                  value: "sixToTen",
                  name: "sixToTen",
                  type: "toggle_button",
                  depth: 3,
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "More than ten",
                  value: "moreThanTen",
                  name: "moreThanTen",
                  type: "toggle_button",
                  depth: 3,
                },
              ],
              subQuestionId: 1,
              editToggle: false,
            },
          ],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Need Some help",
          value: "needSomeHelp",
          name: "needSomeHelp",
          type: "toggle_button",
          depth: 1,
        },
        {
          subQuestionId: 3,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "activities_goingUpOrDownStairsSubNeededTotalHelpSubAnswers",
              value: "neededTotalHelpSubAnswer",
              type: "options_tree",
              label: "How many stairs can you climb",
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 3,
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  label: "None",
                  value: "none",
                  name: "none",
                  isConfigured: true,
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Three to five",
                  value: "threeToFive",
                  name: "threeToFive",
                  type: "toggle_button",
                  depth: 3,
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Six to ten",
                  value: "sixToTen",
                  name: "sixToTen",
                  type: "toggle_button",
                  depth: 3,
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "More than ten",
                  value: "moreThanTen",
                  name: "moreThanTen",
                  type: "toggle_button",
                  depth: 3,
                },
              ],
              subQuestionId: 1,
              editToggle: false,
            },
          ],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Need Total help",
          value: "neededTotalHelp",
          name: "neededTotalHelp",
          type: "toggle_button",
          depth: 1,
        },
      ],
      questionId: 9,
      editToggle: false,
    },
  ],
};

const json2024 = {
  sectionId: 5,
  sectionName: "Activities of Daily Living",
  isEditable: false,
  questions: [
    {
      id: 1,
      name: "activities_independenceActivities",
      type: "label",
      label: "Katz Index of Independence in Activities of Daily Living",
      editToggle: false,
      xs: 12,
      required: "no",
      placeholder: "",
      defaultValue: "",
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 1,
    },
    {
      id: 2,
      name: "activities_evidencedBased",
      type: "label",
      label: "Evidenced-based functional status instrument",
      editToggle: false,
      xs: 9.6,
      required: "no",
      placeholder: "",
      defaultValue: "",
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      questionId: 2,
    },
    {
      id: 3,
      name: "activities_activitiesTable",
      label: "Activities",
      editToggle: false,
      type: "datatable",
      tablelist: [
        [
          {
            id: 13,
            name: "activities_bathing",
            label: "Activities",
            columns: [
              {
                label: "Activities",
                type: "heading",
              },
              {
                label: "Independence (1 Point)",
                type: "heading",
              },
              {
                label: "Dependence (2-5 Points)",
                type: "heading",
              },
            ],
            isReadOnly: false,
          },
          true,
        ],
      ],
      xs: 12,
      required: "no",
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 3,
    },

    {
      id: 6,
      name: "",
      label: "",
      editToggle: false,
      type: "label",

      xs: 6,
      required: "no",
      xs: 12,
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 6,
    },
  ],
};
export const getInputsData = (client = "VPHP", year = "2023") => {
  let formLayoutData = {};
  if (year === "2023") {
    formLayoutData = {
      ...formLayoutData,
      ...json2023,
    };
  }
  if (year === "2024") {
    formLayoutData = {
      ...formLayoutData,
      ...json2024,
    };
  }

  return formLayoutData;
};
