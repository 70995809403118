import React, {
  createContext,
  useState,
  useContext,
  useRef,
  useEffect,
} from "react";
import * as speechsdk from "microsoft-cognitiveservices-speech-sdk";

const SpeechContext = createContext();

export function SpeechProvider({ children }) {
  // const [showTextArea, setShowTextArea] = useState(false);
  // const [transcript, setTranscript] = useState("");
  const [isTranscribe, setIsTranscribe] = useState(false);
  const [isTranscribePause, setIsTranscribePause] = useState(false);
  const [isRecognisePause, setIsRecognisePause] = useState(false);
  const [isRecognise, setIsRecognise] = useState(false);
  const [recText, setRecText] = useState("");
  const textTransRef = useRef([]);
  const textRecogRef = useRef([]);
  const transcriberRef = useRef();
  const recognizerRef = useRef();

  const speechConfig = speechsdk.SpeechConfig.fromSubscription(
    process.env.REACT_APP_SPEECH_KEY,
    process.env.REACT_APP_SPEECH_REGION,
  );
  speechConfig.enableDictation();
  speechConfig.speechRecognitionLanguage =
    process.env.REACT_APP_SPEECH_LANGUAGE;
  speechConfig.setProperty(
    speechsdk.PropertyId.SpeechServiceConnection_EndSilenceTimeoutMs,
    "2000",
  );
  speechConfig.setProperty(
    speechsdk.PropertyId.SpeechServiceResponse_PostProcessingOption,
    "TrueText",
  );

  let audioConfig = speechsdk.AudioConfig.fromDefaultMicrophoneInput();

  const transcriberInit = () => {
    try {
      let conversationTranscriber = new speechsdk.ConversationTranscriber(
        speechConfig,
        audioConfig,
      );

      transcriberRef.current = conversationTranscriber;
    } catch (error) {
      console.log(error);
    }
  };

  const recognizerInit = () => {
    try {
      const recognizer = new speechsdk.SpeechRecognizer(
        speechConfig,
        audioConfig,
      );
      recognizerRef.current = recognizer;
    } catch (error) {
      console.log(error);
    }
  };

  async function initializeSdk() {
    try {
      transcriberInit();
      recognizerInit();
    } catch (error) {
      console.log(error);
    }
  }

  if (transcriberRef.current !== undefined) {
    transcriberRef.current.transcribing = function (s, e) {
      if (e.result.text !== undefined && e.result.text !== "") {
        textTransRef.current.push(
          e.result.speakerId.replace(/Guest/gi, "Speaker") +
            ":" +
            e.result.text +
            "\n \n",
        );
      }
    };

    transcriberRef.current.transcribed = function (s, e) {
      if (e.result.text !== undefined && e.result.text !== "") {
        textTransRef.current.push(
          e.result.speakerId.replace(/Guest/gi, "Speaker") +
            ":" +
            e.result.text +
            "\n \n",
        );
      }
    };
  }

  if (recognizerRef.current !== undefined) {
    recognizerRef.current.recognizing = function (s, e) {
      if (e.result.text !== undefined && e.result.text !== "") {
        textRecogRef.current.push(e.result.text);
      }
    };
    recognizerRef.current.recognized = function (s, e) {
      if (e.result.text !== undefined && e.result.text !== "") {
        textRecogRef.current.push(e.result.text);
      }
    };
  }

  const setTranscribedText = (setState) => {
    const textArrayData = textTransRef?.current || [];
    if (textArrayData.length > 0) {
      setState(textArrayData.join(""));
    }
  };

  const setRecognisedText = (setState) => {
    const textArrayData = textRecogRef?.current || [];
    if (textArrayData.length > 0) {
      setState(textArrayData.join(""));
    }
  };

  // const setRecognisedText = () => {
  //   const textArrayData = textRecogRef?.current || [];
  //   if (textArrayData.length > 0) {
  //     setRecText(textArrayData.join(""));
  //   }
  // };

  const startTranscriber = async () => {
    await transcriberRef.current.startTranscribingAsync(() => {
      setIsTranscribe(true);
      setIsTranscribePause(false);
    });
  };

  const stopTranscriber = async () => {
    await transcriberRef.current.stopTranscribingAsync(() => {
      setIsTranscribe(false);
      setIsTranscribePause(false);
    });
  };

  const pauseTranscriber = async () => {
    await transcriberRef.current.stopTranscribingAsync(() => {
      setIsTranscribePause(true);
    });
  };

  const resumeTranscriber = async () => {
    await transcriberRef.current.startTranscribingAsync(() => {
      setIsTranscribePause(false);
    });
  };

  const startRecogniser = async () => {
    await recognizerRef.current.startContinuousRecognitionAsync();
    setIsRecognise(true);
  };

  const stopRecogniser = async () => {
    await recognizerRef.current.stopContinuousRecognitionAsync();
    initializeSdk(); // DONT REMOVE THIS - SDK might be unresponsive sometimes due to network issues. so reinitialzing when stopping.
    setIsRecognise(false);
  };

  useEffect(() => {
    initializeSdk();
  }, []);

  return (
    <SpeechContext.Provider
      value={{
        textTransRef,
        textRecogRef,
        transcriberRef,
        recognizerRef,
        isTranscribe,
        isTranscribePause,
        isRecognise,
        isRecognisePause,
        recText,
        setTranscribedText,
        setRecognisedText,
        startTranscriber,
        stopTranscriber,
        pauseTranscriber,
        resumeTranscriber,
        startRecogniser,
        stopRecogniser,
        setRecText,
      }}
    >
      {children}
    </SpeechContext.Provider>
  );
}

export function useSpeech() {
  return useContext(SpeechContext);
}
