import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { PiBellBold, PiHouseBold, PiPaletteBold } from "react-icons/pi";
import { useLocation, useNavigate } from "react-router-dom";
import { useSession } from "../../../contexts/SessionContext";
import { useUserContext } from "../../../contexts/UserContext";
import Labels from "../../../resource_files/Labels.resx";
import { getApi, putApi } from "../../../utils/axiosApiWrapper";
import ThemePlatte from "../../ThemePlatte";
import Toaster from "../../toaster/toaster";
import DateTimeDisplay from "./DateTimeDisplay/DateTimeDisplay";
import "./Header.scss";
import { Box } from "@mui/material";
import UserDropMenu from "./UserDropDownMenu/UserDropMenu";
import _ from "lodash";
import DateTimeDayJsDisplay from "./DateTimeDisplay/DateTimeDayJsDisplay";
import toast from "react-hot-toast";
import EyeLogo from "../../../assets/logo_eye.png";
import FocusCareLogoPng from "../../../assets/focuscares_logo.png";
const HeaderTitle = {
  MemberList: "Admin Dashboard",
  AddNewMember: "Add New Member",
  ViewClinicians: "View Clinicians",
  ViewClients: "View Clients",
  ViewAdministrators: "View Administrators",
  ImportFiles: "Import Files",
  Reports: "Reports",
  Scheduler: "Scheduler",
  SchedulerList: "Scheduler Dashboard",
  PreviewHRAForm: "Preview Form",
  CreateHRAForm: "Create Form",
  FillHRAForm: "Fill HRA",
  Nurselist: "Nurse Dashboard",
  QAList: "QA Dashboard",
  Coderlist: "Coder Dashboard",
};

export default function Header({ headerUserName }) {
  const {
    setCurrentColor,
    selectedTimeZone,
    setSelectedTimeZone,
    clientData,
    setClientData,
    user,
    handleBadgeClick,
    currSection,
    setCurrSection,
    // toasterMessage,
    // setToasterMessage,
    // toasterSeverity,
    // setToasterSeverity,
    // toasterOpen,
    // setToasterOpen,
    saveSectionFormData,
    sectionId,
    onSavepedsDemographicsData,
    onSavePedsAssesmentInfoData,
    onSavePatientSummaryData,
  } = useUserContext();
  const location = useLocation();
  const tabId = location?.state?.tabId
    ? location?.state?.tabId
    : sessionStorage.getItem("tabId");
  const [title, setTitle] = useState();
  const [themeOpen, setThemeOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const [timeZoneOptions, setTimeZoneOptions] = useState([]);
  const [toasterMessage, setToasterMessage] = useState("");
  const [toasterSeverity, setToasterSeverity] = useState(Labels.SUCCESS);
  const [toasterOpen, setToasterOpen] = useState(false);
  const navigate = useNavigate();
  const { setLogoutSuccess } = useSession();

  const screenOnlyForm = sessionStorage.getItem("screenIha");

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onCloseToaster = () => {
    setToasterOpen(false);
    setToasterSeverity(Labels.SUCCESS);
    setToasterMessage("");
  };

  const LogoutClick = () => {
    // if (tabId == 1 && user.roleId == 5) {
    //   updateHraStatusToInProgress();
    // }
    if (currSection == 3 && screenOnlyForm == "Screen Only") {
      saveSectionFormData(14).then((resp) => {
        const statusCode = resp?.status;
        if (statusCode === 200) {
          const data = resp?.data || {};
          if (data && Object.keys(data).length > 0) {
            const toastMsg = data.message;
            toast.remove();
            if (toastMsg === "Assessment updated successfully.") {
              if (
                (user.roleId == 5 && tabId != 3) ||
                user.roleId == 1 ||
                user.roleId == 2
              ) {
                toast.success(toastMsg, {});
              }
            } else {
              toast.error(toastMsg, {});
            }
          }
        }
      });
    } else if (currSection == 4 && screenOnlyForm == "Screen Only") {
      saveSectionFormData(17).then((resp) => {
        const statusCode = resp?.status;
        if (statusCode === 200) {
          const data = resp?.data || {};
          if (data && Object.keys(data).length > 0) {
            const toastMsg = data.message;
            toast.remove();
            if (toastMsg === "Assessment updated successfully.") {
              if (
                (user.roleId == 5 && tabId != 3) ||
                user.roleId == 1 ||
                user.roleId == 2
              ) {
                toast.success(toastMsg, {});
              }
            } else {
              toast.error(toastMsg, {});
            }
          }
        }
      });
    } else if (calculateAge() < 18 && currSection == 0) {
      onSavepedsDemographicsData();
    } else if (calculateAge() < 18 && currSection == 1) {
      onSavePedsAssesmentInfoData();
    } else if (calculateAge() < 18 && currSection == 2) {
      onSavePatientSummaryData();
    } else {
      saveSectionFormData(currSection + 1).then((resp) => {
        const statusCode = resp?.status;
        if (statusCode === 200) {
          const data = resp?.data || {};
          if (data && Object.keys(data).length > 0) {
            const toastMsg = data.message;
            toast.remove();
            if (toastMsg === "Assessment updated successfully.") {
              if (
                (user.roleId == 5 && tabId != 3) ||
                user.roleId == 1 ||
                user.roleId == 2
              ) {
                toast.success(toastMsg, {});
              }
            } else {
              toast.error(toastMsg, {});
            }
          }
        }
      });
    }
    getApi(`/users/logout`)
      .then((response) => {
        setLogoutSuccess(true);
        localStorage.clear();
        sessionStorage.clear();
        navigate(`/`);
      })
      .catch((error) => {
        setToasterSeverity(Labels.ERROR);
        setToasterOpen(true);
      });
  };

  const { _memberIdForQueryForm } = useUserContext();
  const updateHraStatusToInProgress = async (cancelComment) => {
    const requestBody = {
      data: {
        _id: _memberIdForQueryForm,
        hraStatus: {
          hraStatus: 2,
          subStatus: "",
          cancelStatus: "",
        },
      },
    };
    try {
      const response = await putApi("/hra-status/updateHraStatus", requestBody);

      if (response.data.code === 200) {
        if (
          (user.roleId == 5 && tabId != 3) ||
          user.roleId == 1 ||
          user.roleId == 2
        ) {
          setToasterOpen(true);
          setToasterSeverity(Labels.SUCCESS);
          setToasterMessage("Assessment Updated Successfully");
        }
      } else {
        return;
      }
    } catch (error) {
      return;
    }
  };
  function excelSerialToDate(dob) {
    const baseDate = new Date("1899-12-30");
    const milliseconds = (dob - 1) * 24 * 60 * 60 * 1000;
    const date = new Date(baseDate.getTime() + milliseconds);
    return date;
  }
  function calculateAge() {
    const dob = sessionStorage.getItem("dob");
    if (dob != "undefined") {
      const dateOfBirth = JSON.parse(JSON?.stringify(dob));
      var birthDateObj = dateOfBirth?.includes("-")
        ? new Date(dateOfBirth)
        : new Date(excelSerialToDate(dateOfBirth));
      var currentDate = new Date();
      var timeDifference = currentDate?.getTime() - birthDateObj?.getTime();
      var age = Math?.floor(timeDifference / (1000 * 60 * 60 * 24 * 365.25));
      return age;
    } else {
      return 1000;
    }
  }
  const handleHomeClick = () => {
    if (currSection == 3 && screenOnlyForm == "Screen Only") {
      saveSectionFormData(14).then((resp) => {
        const statusCode = resp?.status;
        if (statusCode === 200) {
          const data = resp?.data || {};
          if (data && Object.keys(data).length > 0) {
            const toastMsg = data.message;
            toast.remove();
            if (toastMsg === "Assessment updated successfully.") {
              if (
                (user.roleId == 5 && tabId != 3) ||
                user.roleId == 1 ||
                user.roleId == 2
              ) {
                toast.success(toastMsg, {});
              }
            } else {
              toast.error(toastMsg, {});
            }
          }
        }
      });
    } else if (currSection == 4 && screenOnlyForm == "Screen Only") {
      saveSectionFormData(17).then((resp) => {
        const statusCode = resp?.status;
        if (statusCode === 200) {
          const data = resp?.data || {};
          if (data && Object.keys(data).length > 0) {
            const toastMsg = data.message;
            toast.remove();
            if (toastMsg === "Assessment updated successfully.") {
              if (
                (user.roleId == 5 && tabId != 3) ||
                user.roleId == 1 ||
                user.roleId == 2
              ) {
                toast.success(toastMsg, {});
              }
            } else {
              toast.error(toastMsg, {});
            }
          }
        }
      });
    } else if (calculateAge() < 18 && currSection == 0) {
      onSavepedsDemographicsData();
    } else if (calculateAge() < 18 && currSection == 1) {
      onSavePedsAssesmentInfoData();
    } else if (calculateAge() < 18 && currSection == 2) {
      onSavePatientSummaryData();
    } else {
      saveSectionFormData(currSection + 1).then((resp) => {
        const statusCode = resp?.status;
        if (statusCode === 200) {
          const data = resp?.data || {};
          if (data && Object.keys(data).length > 0) {
            const toastMsg = data.message;
            toast.remove();
            if (toastMsg === "Assessment updated successfully.") {
              if (
                (user.roleId == 5 && tabId != 3) ||
                user.roleId == 1 ||
                user.roleId == 2
              ) {
                toast.success(toastMsg, {});
              }
            } else {
              toast.error(toastMsg, {});
            }
          }
        }
      });
    }
    const homePage = sessionStorage.getItem("homePage");
    navigate(homePage);
    if (tabId == 1 && user.roleId == 5) {
      updateHraStatusToInProgress();
    }
    setCurrSection(0);
  };

  const titleHandler = (path) => {
    let docTitle = HeaderTitle[path] ? HeaderTitle[path] : "Dashboard";
    // to-do - check with the actual devs of these dashboards for the data differentiation
    if (path === "Scheduler" && user.roleId === 3) {
      docTitle = "Scheduler Lead Dashboard";
    } else if (path === "QAList" && user.roleId === 6) {
      docTitle = "QA Lead Dashboard";
    } else if (path === "Coderlist" && user.roleId === 8) {
      docTitle = "Coder Lead Dashboard";
    }
    document.title = docTitle;
    setTitle(docTitle);
  };

  useEffect(() => {
    // Fetch time zone options using Axios
    const timezoneHandler = async () => {
      try {
        await getApi(`/settings/getTimezones`, {}).then((response) => {
          setTimeZoneOptions(response?.data?.data);
        });
      } catch (error) {}
    };

    timezoneHandler();
  }, []);
  /* Empty dependency array means - the code will run whenever the component is mounted,
   similar to componentDidMount lifecycle method */

  useEffect(() => {
    const storedThemeColor = localStorage.getItem("themeColor");
    setCurrentColor(storedThemeColor || "#1076BC");
    const root = document.documentElement;
    root.style.setProperty("--mainTheme", storedThemeColor || "#1076BC");
    const storedClientData = sessionStorage.getItem("clientData");
    if (storedClientData) {
      setClientData(JSON.parse(storedClientData));
    }
    const splitURLToText = window.location.pathname.split("/");
    titleHandler(splitURLToText.pop());
  }, []);
  const splitURLToText = window.location.pathname.split("/");
  useEffect(() => {
    const splitURLToText = window.location.pathname.split("/");
    titleHandler(splitURLToText.pop());
    if (
      !(
        splitURLToText.includes("CreateHRAForm") ||
        splitURLToText.includes("PreviewHRAForm")
      )
    ) {
      setClientData(null);
      sessionStorage.removeItem("clientData");
    }
  }, [location]);

  useEffect(() => {
    localStorage.setItem("selectedTimeZone", JSON.stringify(selectedTimeZone));
  }, [selectedTimeZone]);
  const pathName = window.location.pathname;
  return (
    <div
      style={
        pathName.includes("/FillHRAForm")
          ? { width: "100vw", marginInline: "0px" }
          : undefined
      }
      className="header"
    >
      <div className="left-section">
        <span>
          {clientData &&
          clientData.isSelected === true &&
          clientData.clientName !== null &&
          clientData.ageGroup !== null ? (
            <span>
              <div className="logoImageContainer">
                <img
                  src={FocusCareLogoPng}
                  className="logoBrandImage"
                  alt="brand logo"
                />
              </div>
              <h5 className="title">{title}</h5>
              <span
                className="plan"
                // style={{
                //   fontSize: "16px",
                //   fontFamily: "Inter, sans-serif",
                //   margin: 20,
                //   fontWeight: 500,
                //   backgroundColor: `var(--mainTheme400)`,
                //   boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                //   padding: "10px",
                //   borderRadius: "8px",
                // }}
              >
                {clientData.clientName}-{clientData.ageGroup}
              </span>
            </span>
          ) : (
            <Box display="flex">
              {user.roleId != 5 && user.roleId != 1 && user.roleId != 2 && (
                <div
                  className="logoImageContainer"
                  //style={{ marginRight: "10px" }}
                >
                  <img
                    //style={{ height: "50px" }}
                    src={FocusCareLogoPng}
                    className="logoBrandImage"
                    alt="brand logo"
                  />
                </div>
              )}
              <h5
                id={title}
                className="title"
                // style={{
                //   display: "flex",
                //   alignItems: "center",
                //   justifyContent: "center",
                // }}
              >
                {title}
              </h5>
            </Box>
          )}
        </span>

        <span>
          {splitURLToText.includes("Scheduler") &&
          clientData &&
          clientData.isAllSelected === true &&
          clientData.plan !== null &&
          clientData.screeningTypes !== null &&
          clientData.year !== null ? (
            <span
              className="plan"
              // style={{
              //   fontSize: "16px",
              //   fontFamily: "Inter, sans-serif",
              //   margin: 20,
              //   fontWeight: 500,
              //   backgroundColor: `var(--mainTheme400)`,
              //   boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              //   padding: "10px",
              //   borderRadius: "8px",
              //   cursor: "pointer",
              // }}
              onClick={handleBadgeClick}
            >
              {clientData.plan}-{clientData.year}
            </span>
          ) : (
            <></>
          )}
        </span>
      </div>

      <div className="right-section">
        <div className="date-time">
          <div className="date-container">
            <span
              className="icon-btn"
              onClick={handleClick}
              // onClick="undefined"
              //style={{ height: "40px", width: "40px" }}
              id="TimezoneSelectionIcon"
            >
              <svg>
                <line
                  x1="10"
                  y1="0"
                  x2="10"
                  y2="4"
                  style={{
                    stroke: "#2b2b2b",
                    strokeWidth: 1.5,
                  }}
                />
                <line
                  x1="22"
                  y1="0"
                  x2="22"
                  y2="4"
                  style={{
                    stroke: "#2b2b2b",
                    strokeWidth: 1.5,
                  }}
                />
                <line
                  x1="0"
                  y1="8"
                  x2="35"
                  y2="8"
                  style={{
                    stroke: "#2b2b2b",
                    strokeWidth: 1.5,
                  }}
                />
                <rect
                  x="1"
                  y="3"
                  width="30"
                  height="30"
                  style={{
                    // fill: "#2b2b2b",
                    stroke: "#2b2b2b",
                    strokeWidth: 1.5,
                    fillOpacity: 0,
                    // strokeOpacity: 1,
                  }}
                  rx="2"
                />
                <text
                  x="4"
                  y="23"
                  style={{
                    // fill: "#ffffff",
                    fillOpacity: 1,
                    // fontFamily: "Inter sans-serif",
                    fontSize: "0.7em",
                    letterSpacing:
                      selectedTimeZone.abbr === "IST"
                        ? "2px"
                        : selectedTimeZone.abbr === "GMT"
                        ? "0.2px"
                        : "1px",
                    fontWeight: "bold",
                    width: "100%",
                  }}
                >
                  {selectedTimeZone.abbr}
                </text>
              </svg>
            </span>
            <Menu
              id="time-zone-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              sx={{
                left: "-2px",
                "& .MuiMenu-paper": {
                  borderRadius: "0px 0px 5px 5px",
                },
                "& .MuiMenuItem-root": {
                  backgroundColor: `var(--mainTheme50) !important`,
                  padding: "10px",
                  paddingBottom: "5px",
                  fontSize: "14px",
                },
                "& .MuiMenuItem-root.Mui-selected": {
                  backgroundColor: `var(--mainTheme450) !important`,
                },
                "& .MuiMenuItem-root:hover": {
                  backgroundColor: `var(--mainTheme250) !important`,
                  color: "#000000",
                },
              }}
            >
              {timeZoneOptions && timeZoneOptions.length > 0
                ? timeZoneOptions.map((option, index) => {
                    return (
                      <MenuItem
                        key={option.id}
                        id={`isTimezone${index}`}
                        selected={option.abbr === selectedTimeZone.abbr}
                        onClick={() => {
                          setSelectedTimeZone(() => option);
                          handleClose();
                        }}
                      >
                        {option.abbr === "GMT"
                          ? "(UTC)"
                          : _.split(option?.text, " ", 2)[0].replace(
                              "UTC",
                              "",
                            )}{" "}
                        {option.value}
                      </MenuItem>
                    );
                  })
                : null}
            </Menu>
            <DateTimeDayJsDisplay
              DateTimeFormat={"MM-DD-YYYY HH:mm:ss"}
              selectedTimeZone={selectedTimeZone}
            />
          </div>
        </div>
        <div className="icon-btns">
          <div className="icons home" id="home" onClick={handleHomeClick}>
            <span className="icon-btn">
              <PiHouseBold />
            </span>
          </div>
          {/* <div className="icons notification">
            <span className="icon-btn">
              <PiBellBold />
            </span>
          </div> */}
          <div className="icons theme-picker">
            <span
              className="icon-btn"
              onClick={(e) => setThemeOpen(!themeOpen)}
            >
              <PiPaletteBold />
            </span>
            {themeOpen && (
              <div className="color-lens">
                <ThemePlatte />
              </div>
            )}
          </div>
        </div>
        <UserDropMenu
          userName={
            sessionStorage.getItem("lastName")
              ? sessionStorage.getItem("lastName")
              : "John Doe"
          }
          handleLogout={LogoutClick}
        />
      </div>
      <Toaster
        open={toasterOpen}
        onClose={onCloseToaster}
        severity={toasterSeverity}
        message={toasterMessage}
      />
    </div>
  );
}

Header.propTypes = {
  headerUserName: PropTypes.string,
};
