const json2023 = {
  sectionId: 10,
  sectionName: "Review Of Systems And Diagnoses",
  isEditable: false,
  questions: [
    {
      id: 9,
      name: "eyes",
      type: "heading",
      label: "Eyes",
      xs: 12,
      isCommentEnabled: false,
      questionId: 1,
    },
    {
      id: 6,
      name: "ros_eyes",
      type: "options_tree",
      label:
        "Eye Problems (Glaucoma, Cataracts, Macular Degeneration, Blindness, Retinal Detachment, Other)",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "ros_eyesDiag",
              type: "options_tree",
              label: "Diagnoses",
              isMulti: true,
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_eyesDiagCataractSubWhEye",
                      type: "options_tree",
                      label: "Cataracts Which Eye ",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Right Eye",
                          value: "rightEye",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Left Eye",
                          value: "leftEye",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Both",
                          value: "both",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "ros_eyesDiagCataractSubDesc",
                      type: "options_tree",
                      label: "Describe",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                    },
                    {
                      id: 6,
                      name: "ros_eyesDiagCataractSubSupp",
                      type: "options_tree",
                      label: "Supported by",
                      isMulti: true,
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History",
                          value: "history",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_eyesDiagCataractSubSuppSympSub",
                              type: "options_tree",
                              label: "Symptoms",
                              isMulti: true,
                              xs: 12,
                              isCommentEnabled: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Pain",
                                  value: "pain",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Dry Eye",
                                  value: "dryEye",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Tearing",
                                  value: "tearing",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 4,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Problem Seeing at night",
                                  value: "problemSeeing",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 5,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Floaters",
                                  value: "floaters",
                                  type: "toggle_button",
                                },
                              ],
                              subQuestionId: 1,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Symptoms",
                          value: "symptoms",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Physical Findings",
                          value: "physicalFindings",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Medications",
                          value: "medications",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Test results",
                          value: "testResults",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Image Studies",
                          value: "imageStudies",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Biopsy",
                          value: "biopsy",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 8,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "DME",
                          value: "dme",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 9,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "ros_eyesDiagCataractSubSuppOtherSubDesc",
                              type: "text",
                              label: "Describe",
                              xs: 4,
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              subQuestionId: 1,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 3,
                    },
                    {
                      id: 6,
                      name: "ros_eyesDiagCataractSubDiabetes",
                      type: "options_tree",
                      label: "Secondary to Diabetes",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Yes",
                          value: "yes",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 4,
                    },
                  ],
                  isCommentEnabled: false,
                  isExclusive: false,
                  label: "Cataracts",
                  value: "cataracts",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_eyesDiagGlaucomaSubWhEye",
                      type: "options_tree",
                      label: "Glaucoma Which Eye ",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Right Eye ",
                          value: "rightEye",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Left Eye ",
                          value: "leftEye",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Both ",
                          value: "both",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "ros_eyesDiagGlaucomaSubDesc",
                      type: "options_tree",
                      label: "Describe ",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Active ",
                          value: "active",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of ",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Rule out ",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                    },
                    {
                      id: 6,
                      name: "ros_eyesDiagGlaucomaSubSupp",
                      type: "options_tree",
                      label: "Supported by ",
                      isMulti: true,
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History ",
                          value: "history",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_eyesDiagGlaucomaSubSuppSympSub",
                              type: "options_tree",
                              isMulti: true,
                              label: "Symptoms",
                              xs: 12,
                              isCommentEnabled: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Pain",
                                  value: "pain",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Dry eye",
                                  value: "dryEye",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Tearing",
                                  value: "tearing",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 4,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Problem seeing at night",
                                  value: "problemSeeing",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 5,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Floaters",
                                  value: "floaters",
                                  type: "toggle_button",
                                },
                              ],
                              subQuestionId: 1,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Symptoms ",
                          value: "symptoms",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Physical Findings ",
                          value: "physicalFindings",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Medications ",
                          value: "medications",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Test results ",
                          value: "testResults",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Image studies ",
                          value: "imageStudies",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Biopsy ",
                          value: "biopsy",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 8,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "DME ",
                          value: "dme",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 9,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "ros_eyesDiagGlaucomaSubSuppOtherSubDesc",
                              type: "text",
                              label: "Describe",
                              xs: 4,
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              subQuestionId: 1,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "other ",
                          value: "other",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 3,
                    },
                    {
                      id: 6,
                      name: "ros_eyesDiagGlaucomaSubDiabetes",
                      type: "options_tree",
                      label: "Secondary to Diabetes ",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_eyesDiagGlaucomaSubDiabetesSub",
                              type: "options_tree",
                              label: "Type",
                              xs: 12,
                              isCommentEnabled: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  label: "Open (Wide) Angle",
                                  value: "openWideAngle",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Closed (Narrow) Angle",
                                  value: "closedNarrowAngle",
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Yes ",
                          value: "yes",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 4,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Glaucoma",
                  value: "glaucoma",
                  type: "toggle_button",
                  isExclusive: false,
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_eyesDiagHyperopiaSubWhEye",
                      type: "options_tree",
                      label: "Hyperopia Which Eye ",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Right Eye",
                          value: "rightEye",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Left Eye",
                          value: "leftEye",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Both",
                          value: "both",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "ros_eyesDiagHyperopiaSubDesc",
                      type: "options_tree",
                      label: "Describe",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Rule out  ",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                    },
                    {
                      id: 6,
                      name: "ros_eyesDiagHyperopiaSubSupp",
                      type: "options_tree",
                      label: "Supported by",
                      isMulti: true,
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History",
                          value: "history",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_eyesDiagHyperopiaSubSuppSympSub",
                              type: "options_tree",
                              label: "Symptoms",
                              isMulti: true,
                              xs: 12,
                              isCommentEnabled: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Pain",
                                  value: "pain",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Dry eye",
                                  value: "dryEye",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Tearing",
                                  value: "tearing",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 4,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Problem seeing at night",
                                  value: "problemSeeing",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 5,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Floaters",
                                  value: "floaters",
                                  type: "toggle_button",
                                },
                              ],
                              subQuestionId: 1,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Symptoms",
                          value: "symptoms",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Physical Findings",
                          value: "physicalFindings",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Medications",
                          value: "medications",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Test results  ",
                          value: "testResults",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Image studies  ",
                          value: "imageStudies",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Biopsy  ",
                          value: "biopsy",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 8,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "DME",
                          value: "dme",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 9,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "ros_eyesDiagHyperopiaSubSuppOtherSubDesc",
                              type: "text",
                              label: "Describe",
                              xs: 4,
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              subQuestionId: 1,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Other  ",
                          value: "other",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 3,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Hyperopia",
                  value: "hyperopia",
                  isExclusive: false,
                  type: "toggle_button",
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_eyesDiagLegalBlindSubWhEye",
                      type: "options_tree",
                      label: "Legally Blind Which Eye ",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Right Eye",
                          value: "rightEye",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Left Eye",
                          value: "leftEye",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Both",
                          value: "both",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "ros_eyesDiagLegalBlindSubDesc",
                      type: "options_tree",
                      label: "Describe   ",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Active   ",
                          value: "active",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of   ",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Rule out   ",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                    },
                    {
                      id: 6,
                      name: "ros_eyesDiagLegalBlindSubSupp",
                      type: "options_tree",
                      label: "Supported by",
                      isMulti: true,
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History   ",
                          value: "history",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_eyesDiagLegalBlindSubSuppSympSub",
                              type: "options_tree",
                              label: "Symptoms",
                              isMulti: true,
                              xs: 12,
                              isCommentEnabled: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Pain",
                                  value: "pain",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Dry eye",
                                  value: "dryEye",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Tearing",
                                  value: "tearing",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 4,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Problem seeing at night",
                                  value: "problemSeeing",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 5,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Floaters",
                                  value: "floaters",
                                  type: "toggle_button",
                                },
                              ],
                              subQuestionId: 1,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Symptoms",
                          value: "symptoms",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Physical Findings   ",
                          value: "physicalFindings",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Medications   ",
                          value: "medications",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Test results   ",
                          value: "testResults",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Image studies   ",
                          value: "imageStudies",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Biopsy   ",
                          value: "biopsy",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 8,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "DME   ",
                          value: "dme",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 9,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "ros_eyesDiagLegalBlindSubSuppOtherSubDesc",
                              type: "text",
                              label: "Describe",
                              xs: 4,
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              subQuestionId: 1,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 3,
                    },
                    {
                      id: 1,
                      name: "ros_eyesDiagLegalBlindSubDescText",
                      type: "text",
                      label: "Describe",
                      xs: 12,
                      isCommentEnabled: false,
                      subQuestionId: 4,
                    },
                  ],
                  isCommentEnabled: false,
                  isExclusive: false,
                  label: "Legally Blind",
                  value: "legallyBlind",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 5,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_eyesDiagMascDegenSubWhEye",
                      type: "options_tree",
                      label: "Macular Degeneration Which Eye ",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Right Eye",
                          value: "rightEye",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Left Eye",
                          value: "leftEye",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Both",
                          value: "both",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "ros_eyesDiagMascDegenSubDesc",
                      type: "options_tree",
                      label: " Describe",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                    },
                    {
                      id: 6,
                      name: "ros_eyesDiagMascDegenSubSupp",
                      type: "options_tree",
                      label: "Supported by",
                      isMulti: true,
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History ",
                          value: "history",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_eyesDiagMascDegenSubSuppSympSub",
                              type: "options_tree",
                              label: "Symptoms",
                              isMulti: true,
                              xs: 12,
                              isCommentEnabled: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Pain",
                                  value: "pain",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Dry eye",
                                  value: "dryEye",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Tearing",
                                  value: "tearing",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 4,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Problem seeing at night",
                                  value: "problemSeeing",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 5,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Floaters",
                                  value: "floaters",
                                  type: "toggle_button",
                                },
                              ],
                              subQuestionId: 1,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Symptoms",
                          value: "symptoms",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Physical Findings",
                          value: "physicalFindings",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Medications",
                          value: "medications",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Test results",
                          value: "testResults",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: " Image studies",
                          value: "imageStudies",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: " Biopsy",
                          value: "biopsy",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 8,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: " DME",
                          value: "dme",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 9,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "ros_eyesDiagMascDegenSubSuppOtherSubDesc",
                              type: "text",
                              label: "Describe",
                              xs: 4,
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              subQuestionId: 1,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 3,
                    },
                    {
                      id: 6,
                      name: "ros_eyesDiagMascDegenSubDesc2",
                      type: "options_tree",
                      label: "Describe",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Wet",
                          value: "wet",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Dry",
                          value: "dry",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 4,
                    },
                  ],
                  isCommentEnabled: false,
                  isExclusive: false,
                  label: "Macular Degeneration",
                  value: "mascularDegeneration",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 6,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_eyesDiagMyopiaSubWhEye",
                      type: "options_tree",
                      label: "Myopia Which Eye",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Right Eye",
                          value: "rightEye",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Left Eye",
                          value: "leftEye",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Both",
                          value: "both",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "ros_eyesDiagMyopiaSubDesc",
                      type: "options_tree",
                      label: "Describe",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                    },
                    {
                      id: 6,
                      name: "ros_eyesDiagMyopiaSubSupp",
                      type: "options_tree",
                      label: "Supported by",
                      isMulti: true,
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History",
                          value: "history",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_eyesDiagMyopiaSubSuppSympSub",
                              type: "options_tree",
                              label: "Symptoms",
                              isMulti: true,
                              xs: 12,
                              isCommentEnabled: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Pain",
                                  value: "pain",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Dry eye",
                                  value: "dryEye",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Tearing",
                                  value: "tearing",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 4,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Problem seeing at night",
                                  value: "problemSeeing",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 5,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Floaters",
                                  value: "floaters",
                                  type: "toggle_button",
                                },
                              ],
                              subQuestionId: 1,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Symptoms",
                          value: "symptoms",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Physical Findings",
                          value: "physicalFindings",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Medications",
                          value: "medications",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Test results",
                          value: "testResults",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Image studies",
                          value: "imageStudies",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Biopsy",
                          value: "biopsy",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 8,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "DME",
                          value: "dme",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 9,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "ros_eyesDiagMyopiaSubSuppOtherSubDesc",
                              type: "text",
                              label: "Describe",
                              xs: 4,
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              subQuestionId: 1,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 3,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Myopia",
                  value: "myopia",
                  isExclusive: false,
                  type: "toggle_button",
                },
                {
                  subQuestionId: 7,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_eyesDiagRetinalSubWhEye",
                      type: "options_tree",
                      label: "Retinal Disease Which Eye ",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Right Eye",
                          value: "rightEye",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Left Eye",
                          value: "leftEye",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Both",
                          value: "both",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "ros_eyesDiagRetinalSubDesc",
                      type: "options_tree",
                      label: "Describe",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                    },
                    {
                      id: 6,
                      name: "ros_eyesDiagRetinalSubSupp",
                      type: "options_tree",
                      label: "Supported by",
                      isMulti: true,
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History",
                          value: "history",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_eyesDiagRetinalSubSuppSympSub",
                              type: "options_tree",
                              label: "Symptoms",
                              isMulti: true,
                              xs: 12,
                              isCommentEnabled: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Pain",
                                  value: "pain",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Dry eye",
                                  value: "dryEye",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Tearing",
                                  value: "tearing",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 4,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Problem seeing at night",
                                  value: "problemSeeing",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 5,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Floaters",
                                  value: "floaters",
                                  type: "toggle_button",
                                },
                              ],
                              subQuestionId: 1,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Symptoms",
                          value: "symptoms",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Physical Findings",
                          value: "physicalFindings",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Medications",
                          value: "medications",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Test results",
                          value: "testResults",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Image studies",
                          value: "imageStudies",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Biopsy",
                          value: "biopsy",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 8,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "DME",
                          value: "dme",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 9,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "ros_eyesDiagRetinalSubSuppOtherSubDesc",
                              type: "text",
                              label: "Describe",
                              xs: 4,
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              subQuestionId: 1,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 3,
                    },
                    {
                      id: 6,
                      name: "ros_eyesDiagRetinalSubDiabetes",
                      type: "options_tree",
                      label: "Secondary to Diabetes",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_eyesDiagRetinalSubDiabetesSubIsVitHemm",
                              type: "options_tree",
                              label: "Vitreous Hemorrhage",
                              xs: 12,
                              isCommentEnabled: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Yes",
                                  value: "yes",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "No",
                                  value: "no",
                                  type: "toggle_button",
                                },
                              ],
                              subQuestionId: 1,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Yes",
                          value: "yes",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 4,
                    },
                  ],
                  isCommentEnabled: false,
                  isExclusive: false,
                  label: "Retinal Disease",
                  value: "retinalDisease",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 8,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_eyesDiagOtherSubWhEye",
                      type: "options_tree",
                      label: "Others Which Eye",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Right Eye",
                          value: "rightEye",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Left Eye",
                          value: "leftEye",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Both",
                          value: "both",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "ros_eyesDiagOtherSubDesc",
                      type: "options_tree",
                      label: "Describe",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                    },
                    {
                      id: 6,
                      name: "ros_eyesDiagOtherSubSupp",
                      type: "options_tree",
                      label: "Supported by",
                      isMulti: true,
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History",
                          value: "history",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_eyesDiagOtherSubSuppSympSub",
                              type: "options_tree",
                              label: "Symptoms",
                              isMulti: true,
                              xs: 12,
                              isCommentEnabled: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Pain",
                                  value: "pain",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Dry eye",
                                  value: "dryEye",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Tearing",
                                  value: "tearing",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 4,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Problem seeing at night",
                                  value: "problemSeeing",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 5,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Floaters",
                                  value: "floaters",
                                  type: "toggle_button",
                                },
                              ],
                              subQuestionId: 1,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Symptoms",
                          value: "symptoms",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Physical Findings",
                          value: "physicalFindings",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Medications",
                          value: "medications",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Test results",
                          value: "testResults",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Image studies",
                          value: "imageStudies",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Biopsy",
                          value: "biopsy",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 8,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "DME",
                          value: "dme",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 9,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "ros_eyesDiagOtherSubSuppOtherSubDesc",
                              type: "text",
                              label: "Describe",
                              xs: 4,
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              subQuestionId: 1,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 3,
                    },
                    {
                      id: 6,
                      name: "ros_eyesDiagOtherSubOther",
                      type: "text",
                      label: "Other",
                      xs: 12,
                      isCommentEnabled: false,
                      hasSubQuestions: false,
                      subQuestions: [],
                      subQuestionId: 5,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Others",
                  value: "others",
                  isExclusive: false,
                  type: "toggle_button",
                },
              ],
              subQuestionId: 1,
            },
          ],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          name: "yes",
          type: "toggle_button",
        },
        {
          id: 6,
          type: "toggle_button",
          label: "No",
          xs: 12,
          isCommentEnabled: true,
          hasSubQuestions: false,
          subQuestions: [{}],
          subQuestionId: 2,
          value: "no",
          name: "no",
        },
      ],
      questionId: 2,
    },
    {
      id: 9,
      name: "ears",
      type: "heading",
      label: "Ears",
      xs: 12,
      isCommentEnabled: false,
      questionId: 3,
    },
    {
      id: 6,
      name: "ros_ears",
      type: "options_tree",
      label: "Ear Problems (Hard of hearing, Deaf, Vertigo, Ear Infections)",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "ros_earsDiagnoses",
              type: "options_tree",
              label: "Diagnoses",
              isMulti: true,
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_earsDiagDiffHearSubDesc",
                      type: "options_tree",
                      label: "Difficulty with Hearing Describe",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "ros_earsDiagDiffHearSubSupp",
                      type: "options_tree",
                      label: "Supported by",
                      isMulti: true,
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History",
                          value: "history",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Symptoms",
                          value: "symptoms",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Test results",
                          value: "testResults",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Hearing aids",
                          value: "hearingAids",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Medication",
                          value: "medication",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Reading lips",
                          value: "readingLips",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "ros_earsDiagDiffHearSubSuppOtherSubDesc",
                              type: "text",
                              label: "Describe",
                              xs: 4,
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              subQuestionId: 1,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Difficulty with Hearing",
                  value: "difficultyHearing",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_earsDiagLegalDeafSubDesc",
                      type: "options_tree",
                      label: "Legally Deaf Describe",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "ros_earsDiagLegalDeafSubSupp",
                      type: "options_tree",
                      label: "Supported by",
                      isMulti: true,
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History",
                          value: "history",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Symptoms",
                          value: "symptoms",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Physical Findings",
                          value: "physicalFindings",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Medications",
                          value: "medications",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Test results",
                          value: "testResults",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Image studies",
                          value: "imageStudies",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Biopsy",
                          value: "biopsy",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 8,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "DME",
                          value: "dme",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 9,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "ros_earsDiagLegalDeafSubSuppOtherSubDesc",
                              type: "text",
                              label: "Describe",
                              xs: 4,
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              subQuestionId: 1,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Legally Deaf",
                  value: "legallyDeaf",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_earsDiagTinnitusSubDesc",
                      type: "options_tree",
                      label: "Tinnitus Describe",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "ros_earsDiagTinnitusSubSupp",
                      type: "options_tree",
                      label: "Supported By",
                      isMulti: true,
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History",
                          value: "history",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Symptoms",
                          value: "symptoms",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Physical Findings",
                          value: "physicalFindings",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Medications",
                          value: "medications",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Test results",
                          value: "testResults",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Image studies",
                          value: "imageStudies",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Biopsy",
                          value: "biopsy",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 8,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "DME",
                          value: "dme",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 9,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "ros_earsDiagTinnitusSubSuppOtherSubDesc",
                              type: "text",
                              label: "Describe",
                              xs: 4,
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              subQuestionId: 1,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Tinnitus",
                  value: "tinnitus",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_earsDiagVertigoSubDesc",
                      type: "options_tree",
                      label: "Vertigo Describe",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "ros_earsDiagVertigoSubSupp",
                      type: "options_tree",
                      label: "Supported  By",
                      isMulti: true,
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History",
                          value: "history",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Symptoms",
                          value: "symptoms",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Physical Findings",
                          value: "physicalFindings",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Medications",
                          value: "medications",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Test results",
                          value: "testResults",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Image studies",
                          value: "imageStudies",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Biopsy",
                          value: "biopsy",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 8,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "DME",
                          value: "dme",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 9,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "ros_earsDiagVertigoSubSuppOtherSubDesc",
                              type: "text",
                              label: "Describe",
                              xs: 4,
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              subQuestionId: 1,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                    },
                    {
                      id: 6,
                      name: "ros_earsDiagVertigoSubLoseBal",
                      type: "options_tree",
                      label: "Do you lose your balance",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Yes",
                          value: "yes",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 3,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Vertigo",
                  value: "vertigo",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 5,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_earsDiagOtherSubDesc",
                      type: "options_tree",
                      label: "Other Describe",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "ros_earsDiagOtherSubSupp",
                      type: "options_tree",
                      label: "Supported by",
                      isMulti: true,
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History",
                          value: "history",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Symptoms",
                          value: "symptoms",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Physical Findings",
                          value: "physicalFindings",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Medications",
                          value: "medications",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Test results",
                          value: "testResults",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Image studies",
                          value: "imageStudies",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Biopsy",
                          value: "biopsy",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 8,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "DME",
                          value: "dme",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 9,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "ros_earsDiagOtherSubSuppOtherSubDesc",
                              type: "text",
                              label: "Other",
                              xs: 4,
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              subQuestionId: 1,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                    },
                    {
                      id: 6,
                      name: "ros_earsDiagOtherSubOther",
                      type: "text",
                      label: "Other",
                      xs: 12,
                      isCommentEnabled: false,
                      hasSubQuestions: false,
                      subQuestions: [],
                      subQuestionId: 3,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Other",
                  value: "other",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 1,
            },
          ],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
        },
        {
          id: 6,
          type: "toggle_button",
          label: "No",
          xs: 12,
          isCommentEnabled: true,
          hasSubQuestions: false,
          subQuestions: [{}],
          subQuestionId: 2,
          value: "no",
        },
      ],
      questionId: 4,
    },
    {
      id: 9,
      name: "nose",
      type: "heading",
      label: "Nose",
      xs: 12,
      isCommentEnabled: false,
      questionId: 5,
    },
    {
      id: 6,
      name: "ros_nose",
      type: "options_tree",
      label: "Nose Problems (Nose Bleeds, Sinus infections, Other)",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "ros_noseDiagnoses",
              type: "options_tree",
              label: "Diagnoses   ",
              isMulti: true,
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_noseDiagAllergySubDesc",
                      type: "options_tree",
                      label: "Allergic Rhinitis Describe",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "ros_noseDiagAllergySubSupp",
                      type: "options_tree",
                      label: " Supported by",
                      isMulti: true,
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History",
                          value: "history",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Symptoms",
                          value: "symptoms",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Physical Findings",
                          value: "physicalFindings",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Medications",
                          value: "medications",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Test results",
                          value: "testResults",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Image studies",
                          value: "imageStudies",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Biopsy",
                          value: "biopsy",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 8,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "DME",
                          value: "dme",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 9,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "ros_noseDiagAllergySubSuppOtherSubDesc",
                              type: "text",
                              label: "Describe",
                              xs: 4,
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              subQuestionId: 1,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Allergic Rhinitis",
                  value: "allergicRhinitis",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_noseDiagChronicNasalSubDesc",
                      type: "options_tree",
                      label: "Chronic Post Nasal Drip Describe",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "ros_noseDiagChronicNasalSubSupp",
                      type: "options_tree",
                      label: " Supported by",
                      isMulti: true,
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History",
                          value: "history",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Symptoms",
                          value: "symptoms",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Physical Findings",
                          value: "physicalFindings",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Medications",
                          value: "medications",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Test results",
                          value: "testResults",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Image studies",
                          value: "imageStudies",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Biopsy",
                          value: "biopsy",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 8,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "DME",
                          value: "dme",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 9,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "ros_noseDiagChronicNasalSubSuppOtherSubDesc",
                              type: "text",
                              label: "Describe",
                              xs: 4,
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              subQuestionId: 1,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Chronic Post Nasal Drip",
                  value: "chronicNasalDrip",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_noseDiagNoseBleedSub",
                      type: "options_tree",
                      label: "Nose Bleeds Describe",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Nose Bleeds",
                  value: "noseBleeds",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_noseDiagSeasonAllergySubDesc",
                      type: "options_tree",
                      label: "Seasonal Allergies Describe",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "ros_noseDiagSeasonAllergySubSupp",
                      type: "options_tree",
                      label: " Supported by",
                      isMulti: true,
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History",
                          value: "history",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Symptoms",
                          value: "symptoms",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Physical Findings",
                          value: "physicalFindings",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Medications",
                          value: "medications",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Test results",
                          value: "testResults",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Image studies",
                          value: "imageStudies",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Biopsy",
                          value: "biopsy",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 8,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "DME",
                          value: "dme",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 9,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "ros_noseDiagSeasonAllergySubSuppOtherSubDesc",
                              type: "text",
                              label: "Describe",
                              xs: 4,
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              subQuestionId: 1,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Seasonal Allergies",
                  value: "seasonalAllergies",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 5,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_noseDiagSinusInfectSubDesc",
                      type: "options_tree",
                      label: "Sinus infections Describe",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "ros_noseDiagSinusInfectSubSupp",
                      type: "options_tree",
                      label: " Supported by",
                      isMulti: true,
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History",
                          value: "history",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Symptoms",
                          value: "symptoms",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Physical findings",
                          value: "physicalFindings",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Medications",
                          value: "medications",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Test results",
                          value: "testResults",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Image studies",
                          value: "imageStudies",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Biopsy",
                          value: "biopsy",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 8,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "DME",
                          value: "dme",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 9,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "ros_noseDiagSinusInfectSubSuppOtherSubDesc",
                              type: "text",
                              label: "Describe",
                              xs: 4,
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              subQuestionId: 1,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                    },
                    {
                      id: 6,
                      name: "ros_noseDiagSinusInfectSubExu",
                      type: "options_tree",
                      label: "Exudate",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Clear",
                          value: "clear",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Purulent",
                          value: "purulent",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Sinus infections",
                  value: "sinusInfections",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 6,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_noseDiagSinustisSubDesc",
                      type: "options_tree",
                      label: "Sinusitis Describe",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "ros_noseDiagSinustisSubSupp",
                      type: "options_tree",
                      label: " Supported by",
                      isMulti: true,
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History",
                          value: "history",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Symptoms",
                          value: "symptoms",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Physical Findings",
                          value: "physicalFindings",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Medications",
                          value: "medications",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Test results",
                          value: "testResults",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Image studies",
                          value: "imageStudies",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Biopsy",
                          value: "biopsy",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 8,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "DME",
                          value: "dme",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 9,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "ros_noseDiagSinustisSubSuppOtherSubDesc",
                              type: "text",
                              label: "Describe",
                              xs: 4,
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              subQuestionId: 1,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Sinusitis",
                  value: "sinusitis",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 7,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_noseDiagOtherSubDesc",
                      type: "options_tree",
                      label: "Other Describe",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "ros_noseDiagOtherSubSupp",
                      type: "options_tree",
                      label: " Supported by ",
                      isMulti: true,
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History",
                          value: "history",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Symptoms",
                          value: "symptoms",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Physical Findings",
                          value: "physicalFindings",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Medications",
                          value: "medications",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Test results",
                          value: "testResults",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Image studies",
                          value: "imageStudies",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Biopsy",
                          value: "biopsy",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 8,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "DME",
                          value: "dme",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 9,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "ros_noseDiagOtherSubSuppOtherSubDesc",
                              type: "text",
                              label: "Describe",
                              xs: 4,
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              subQuestionId: 1,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                    },
                    {
                      id: 1,
                      name: "ros_noseDiagOtherSubOther",
                      type: "text",
                      label: "Other",
                      xs: 12,
                      placeholder: "",
                      defaultValue: "",
                      isCommentEnabled: false,
                      subQuestionId: 3,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Other",
                  value: "other",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 1,
            },
          ],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
        },
        {
          id: 6,
          type: "toggle_button",
          label: "No",
          xs: 12,
          isCommentEnabled: true,
          hasSubQuestions: false,
          subQuestions: [{}],
          subQuestionId: 2,
          value: "no",
        },
      ],
      questionId: 6,
    },
    {
      id: 9,
      name: "mouth_&_throat",
      type: "heading",
      label: "MOUTH AND THROAT",
      xs: 12,
      isCommentEnabled: false,
      questionId: 7,
    },
    {
      id: 6,
      name: "ros_mouth",
      type: "options_tree",
      label:
        "Mouth and Throat Problems (Difficulty Chewing, Difficulty Swallowing, Bleeding Gums, Other )",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "ros_mouthDiag",
              type: "options_tree",
              label: "Diagnoses",
              isMulti: true,
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_mouthDiagBleedGumsSub",
                      type: "options_tree",
                      label: "Bleeding Gums Describe",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Bleeding Gums",
                  value: "bleedingGums",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_mouthDiagDiffChewSubDesc",
                      type: "options_tree",
                      label: "Difficulty Chewing Describe",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "ros_mouthDiagDiffChewSubBcsPain",
                      type: "options_tree",
                      label: "Because of pain",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_mouthDiagDiffChewSubBcsPainSub",
                              type: "options_tree",
                              label:
                                "Because you wear partial or complete dentures",
                              xs: 12,
                              isCommentEnabled: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Yes",
                                  value: "yes",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "No",
                                  value: "no",
                                  type: "toggle_button",
                                },
                              ],
                              subQuestionId: 1,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Yes",
                          value: "yes",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Difficulty Chewing",
                  value: "difficultyChewing",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_mouthDiagDiffSwallowSubDesc",
                      type: "options_tree",
                      label: "Difficulty Swallowing Describe",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "ros_mouthDiagDiffSwallowSubHadStroke",
                      type: "options_tree",
                      label: "Have you had a stroke",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_mouthDiagDiffSwallowSubHadStrokeSpclDietSub",
                              type: "options_tree",
                              label: "Do you eat a special diet",
                              xs: 12,
                              isCommentEnabled: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Yes",
                                  value: "yes",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "No",
                                  value: "no",
                                  type: "toggle_button",
                                },
                              ],
                              subQuestionId: 1,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Yes",
                          value: "yes",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Difficulty Swallowing",
                  value: "difficultySwallowing",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_mouthDiagOtherSubDesc",
                      type: "options_tree",
                      label: "Other Describe ",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "ros_mouthDiagOtherSubSupp",
                      type: "options_tree",
                      label: "Supported by",
                      isMulti: true,
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History",
                          value: "history",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Symptoms",
                          value: "symptoms",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Physical Findings",
                          value: "physicalFindings",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Medications",
                          value: "medications",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Test results",
                          value: "testResults",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Image studies",
                          value: "imageStudies",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Biopsy",
                          value: "biopsy",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 8,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "DME",
                          value: "dme",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 9,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "ros_mouthDiagOtherSubSuppOtherSubDesc",
                              type: "text",
                              label: "Describe",
                              xs: 4,
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              subQuestionId: 1,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                    },
                    {
                      id: 1,
                      name: "ros_mouthDiagOtherSubOther",
                      type: "text",
                      label: "Other",
                      xs: 12,
                      placeholder: "",
                      defaultValue: "",
                      isCommentEnabled: false,
                      subQuestionId: 3,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Other",
                  value: "other",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 1,
            },
          ],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 8,
    },
    {
      id: 9,
      name: "neck",
      type: "heading",
      label: "Neck",
      xs: 12,
      isCommentEnabled: false,
      questionId: 9,
    },
    {
      id: 6,
      name: "ros_neck",
      type: "options_tree",
      label: "Neck Problems (parotid Disease, Carotid Stenosis, Other)",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "ros_neckDiag",
              type: "options_tree",
              label: "Diagnoses",
              isMulti: true,
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_neckDiagCarotidSubDesc",
                      type: "options_tree",
                      label: "Carotid Stenosis Describe",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "ros_neckDiagCarotidSubSupp",
                      type: "options_tree",
                      label: "Supported by",
                      isMulti: true,
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Bruits",
                          value: "bruits",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of TIAs",
                          value: "historyOfTia",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Laboratory studies",
                          value: "laboratoryStudies",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 1,
                              name: "ros_neckDiagCarotidSubSuppOtherSubDesc",
                              type: "text",
                              label: "Describe",
                              xs: 4,
                              isCommentEnabled: false,
                              subQuestionId: 1,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                    },
                    {
                      id: 6,
                      name: "ros_neckDiagCarotidSubCsDesc",
                      type: "options_tree",
                      label: "Describe",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Right",
                          value: "right",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Left",
                          value: "left",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Bilateral",
                          value: "bilateral",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 3,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Carotid Stenosis",
                  value: "carotidStenosis",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_neckDiagParotidSubDesc",
                      type: "options_tree",
                      label: "Parotid Disease Describe",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "ros_neckDiagParotidSubSupp",
                      type: "options_tree",
                      label: "   Supported by",
                      isMulti: true,
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Physical findings",
                          value: "physicalFindings",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History",
                          value: "history",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "ros_neckDiagParotidSubSuppOtherSubDesc",
                              type: "text",
                              label: "Describe",
                              xs: 4,
                              isCommentEnabled: false,
                              subQuestionId: 1,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Parotid Disease",
                  value: "parotidDisease",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_neckDiagOtherSubDesc",
                      type: "options_tree",
                      label: "   Other Describe   ",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "ros_neckDiagOtherSubSupp",
                      type: "options_tree",
                      label: "    Supported By ",
                      isMulti: true,
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History",
                          value: "history",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Symptoms",
                          value: "symptoms",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Physical Findings",
                          value: "physicalFindings",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Medications",
                          value: "medications",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Test results",
                          value: "testResults",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Image studies",
                          value: "imageStudies",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Biopsy",
                          value: "biopsy",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 8,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "DME",
                          value: "dme",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 9,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "ros_neckDiagOtherSubSuppOtherSubDesc",
                              type: "text",
                              label: "Describe",
                              xs: 4,
                              isCommentEnabled: false,
                              subQuestionId: 1,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                    },
                    {
                      id: 1,
                      name: "ros_neckDiagOtherSubOther",
                      type: "text",
                      label: "Other",
                      xs: 4,
                      isCommentEnabled: false,
                      subQuestionId: 3,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Other",
                  value: "other",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 1,
            },
          ],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
        },
        {
          id: 6,
          type: "toggle_button",
          label: "No",
          xs: 12,
          isCommentEnabled: true,
          hasSubQuestions: false,
          subQuestions: [{}],
          subQuestionId: 2,
          value: "no",
        },
      ],
      questionId: 10,
    },
    {
      id: 10,
      name: "ros_eyeENTNeckRecommendations",
      label: "Recommendations",
      type: "checkbox",
      options: [
        ["Hearing evaluation.", "hearingEvaluation"],
        ["Dental exam.", "dentalExam"],
        ["Eye exam.", "eyeExam"],
        ["Swallowing evaluation", "swallowingEvaluation"],
        ["Take medications as prescribed", "takeMedications"],
        ["Other", "other"],
      ],
      xs: 12,
      isCommentEnabled: true,
      questionId: 11,
    },
    {
      id: 9,
      name: "respiratory",
      type: "heading",
      label: "RESPIRATORY",
      editToggle: false,
      xs: 12,
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 12,
    },
    {
      id: 6,
      name: "ros_respiratory",
      type: "options_tree",
      label:
        "Respiratory Problems (COPD, Emphysema, Asthma, Chronic Bronchitis Pneumonia, Other)",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 1,
          subQuestionId: 1,
          hasSubQuestions: true,
          editToggle: false,
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          isConfigured: true,
          type: "toggle_button",
          subQuestions: [
            {
              id: 6,
              name: "ros_respiratoryDiagnoses",
              value: "diagnoses",
              type: "options_tree",
              label: "Diagnoses",
              isMulti: true,
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 1,
                  subQuestionId: 1,
                  name: "ros_respiratoryAcutePulmonarySubAnswer",
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  label: " Acute Pulmonary Embolism ",
                  value: "acutePulmonary",
                  isConfigured: true,
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Acute Pulmonary Embolism",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_respiratoryAcutePulmonarySubAnswerDescribe",
                      value: "describe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active (in past 6 months)",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule Out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_respiratoryAcutePulmonarySubAnswerSupportedBy",
                      value: "supportedBy",
                      type: "options_tree",
                      label: "Supported by    ",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: " Hospitalization for Pulmonary Embolism ",
                          value: "hospitalization",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: " CT Angiogram ",
                          value: "ctAngiogram",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: " Venous Doppler ",
                          value: "venousDoppler",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: " D-dimer ",
                          value: "dDimer",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: " VQ scan ",
                          value: "vqScan",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: " Use of anticoagulation ",
                          value: "useOfAnticoagulation",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: " Shortness of breath ",
                          value: "shortnessOfBreath",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 8,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: " Wheezing ",
                          value: "wheezing",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 9,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: " Chronic cough ",
                          value: "chronicCough",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 10,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          name: "ros_respiratoryAcutePulmonarySubOther",
                          value: "other",
                          type: "toggle_button",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              subQuestionId: 1,
                              name: "ros_respiratoryAcutePulmonarySubOtherSubDesc",
                              value: "describe",
                              type: "text",
                              placeholder: "",
                              label: "Describe",
                              variant: "outlined",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              sm: 3,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: false,
                            },
                          ],
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: " Acute Upper Respiratory Infection ",
                  name: "ros_respiratoryAcuteUpperSubAnswer",
                  value: "acuteUpper",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Acute Upper Respiratory Infection",
                      fullWidth: true,
                      required: false,
                      xs: 18,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_respiratoryAcuteUpperSubAnswerDescribe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_respiratoryAcuteUpperSubAnswerSupportedBy",
                      type: "options_tree",
                      label: "Supported by    ",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Fever",
                          value: "fever",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Chills",
                          value: "chills",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Cough",
                          value: "cough",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Decreased breathe sounds ",
                          value: "decreasedBreathe",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rales",
                          value: "rales",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Wheezing",
                          value: "wheezing",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Chest X-ray",
                          value: "chestXRay",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 8,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Shortness of breath",
                          value: "shortnessOfBreath",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 9,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: " Chronic cough ",
                          value: "chronicCough",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 10,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          name: "ros_respiratoryAcuteUpperSubOther",
                          type: "toggle_button",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              subQuestionId: 1,
                              name: "ros_respiratoryAcuteUpperSubOtherSubDesc",
                              value: "describe",
                              type: "text",
                              placeholder: "",
                              label: "Describe",
                              variant: "outlined",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              sm: 3,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: false,
                            },
                          ],
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: " Asthma ",
                  value: "asthma",
                  name: "ros_respiratoryAsthmaSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Asthma",
                      fullWidth: true,
                      required: false,
                      xs: 18,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_respiratoryAsthmaSubAnswerDescribe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_respiratoryAsthmaSubAnswerSupportedBy",
                      type: "options_tree",
                      label: " Supported by",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Wheezing",
                          value: "wheezing",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Chronic Cough",
                          value: "chronicCough",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Cyanosis",
                          value: "cyanosis",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Use of Bronchodilator",
                          value: "useOfBronchodilator",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: " Use of Inhaled or oral steroids ",
                          value: "useOfInhaled",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: " Use of ventilator ",
                          value: "useOfVentilator",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Shortness of breath",
                          value: "shortnessOfBreath",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 8,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          name: "ros_respiratoryAsthmaSubOther",
                          type: "toggle_button",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              subQuestionId: 1,
                              name: "ros_respiratoryAsthmaSubOtherSubDesc",
                              value: "describe",
                              type: "text",
                              placeholder: "",
                              label: "Describe",
                              variant: "outlined",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              sm: 3,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: false,
                            },
                          ],
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_respiratoryAsthmaPatientController",
                      type: "options_tree",
                      value: "patientController",
                      label: "Is patient on controller medications",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Yes",
                          value: "yes",
                          name: "yes",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No",
                          value: "no",
                          name: "no",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 3,
                      editToggle: false,
                    },
                    {
                      id: 7,
                      name: "ros_respiratoryAsthmaRescueMedications",
                      type: "options_tree",
                      value: "rescueMedications",
                      label: "Does patient use rescue medications",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Yes",
                          value: "yes",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 4,
                      editToggle: false,
                    },
                    {
                      id: 8,
                      name: "ros_respiratoryAsthmaCurrentExacerbation",
                      type: "options_tree",
                      value: "currentExacerbation",
                      label: "Does patient have current exacerbation",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Yes",
                          value: "yes",
                          isConfigured: true,
                          type: "toggle_button",
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              name: "ros_respiratoryAsthmaPatientControllerSubasthmaCurrentExacerbation",
                              type: "options_tree",
                              label: "Supported by",
                              editToggle: false,
                              xs: 12,
                              required: false,
                              value: "supported_by",
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                              depth: 4,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 5,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  name: "wheezing",
                                  label: "Wheezing",
                                  subQuestions: [],
                                  editToggle: false,
                                  value: "wheezing",
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 5,
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  name: "shortness_breathe",
                                  label: "Shortness of breath",
                                  value: "shortnessOfBreath",
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 5,
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  name: "rescue_medications",
                                  label: "Use of rescue medications",
                                  value: "useOfRescue",
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 5,
                                  subQuestionId: 4,
                                  hasSubQuestions: false,
                                  name: "use_ventilator",
                                  label: "Use of ventilator",
                                  value: "useOfVentilator",
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 5,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: " Chronic Pulmonary Embolism ",
                  value: "chronicPulmonaryEmbolism",
                  name: "ros_respiratoryChronicPulmonaryEmbolismSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Chronic Pulmonary Embolism",
                      fullWidth: true,
                      required: false,
                      xs: 18,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_respiratoryChronicPulmonaryEmbolismSubAnswerDescribe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_respiratoryChronicPulmonaryEmbolismSubAnswerSupportedBy",
                      type: "options_tree",
                      label: " Supported by",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: " History of Pulmonary Embolism ",
                          value: "historyOfPul",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: " Insertion of Vena Cava Filter ",
                          value: "insersionOfVena",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: " Anticoagulation beyond six months ",
                          value: "anticoagulation",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: " Shortness of breath ",
                          value: "shortnessOfBreath",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Wheezing",
                          value: "wheezing",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Chronic cough",
                          value: "chronicCough",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          name: "ros_respiratoryChronicPulmonaryEmbolismSubOther",
                          type: "toggle_button",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              subQuestionId: 1,
                              name: "ros_respiratoryChronicPulmonaryEmbolismSubOtherSubDesc",
                              value: "describe",
                              type: "text",
                              placeholder: "",
                              label: "Describe",
                              variant: "outlined",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              sm: 3,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: false,
                            },
                          ],
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 5,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: " Chronic Respiratory Failure ",
                  value: "chronicRespiratoryFailure",
                  name: "ros_respiratoryChronicRespiratoryFailureSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Chronic Respiratory Failure",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_respiratoryChronicRespiratoryFailureSubAnswerDescribe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_respiratoryChronicRespiratoryFailureSubAnswerSupportedBy",
                      type: "options_tree",
                      label: " Supported by",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label:
                            " History of hospitalization with Respiratory Failure ",
                          value: "historyOfHospitalization",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: " Chronic use of O2 at >2L/min ",
                          value: "chronicUseOfO2",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: " CO2 Retention ",
                          value: "carbonDioxideRetention",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Use of ventilator",
                          value: "useOfVentilator",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: " Shortness of breath ",
                          value: "shortnessOfBreath",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Wheezing",
                          value: "wheezing",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Chronic cough",
                          value: "chronicCough",
                          type: "toggle_button",
                        },

                        {
                          subQuestionId: 8,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          name: "ros_respiratoryChronicRespiratoryFailureSubOther",
                          type: "toggle_button",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              subQuestionId: 1,
                              name: "ros_respiratoryChronicRespiratoryFailureSubOtherSubDesc",
                              value: "describe",
                              type: "text",
                              placeholder: "",
                              label: "Describe",
                              variant: "outlined",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              sm: 3,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: false,
                            },
                          ],
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 6,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: " Chronic Sputum Production ",
                  value: "chronicSputumFailure",
                  name: "ros_respiratoryChronicSputumFailureSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Chronic Sputum Production",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_respiratoryChronicSputumFailureSubAnswerDescribe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_respiratoryChronicSputumFailureSubAnswerSupportedBy",
                      type: "options_tree",
                      label: " Supported by",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: " History ",
                          value: "history",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Symptoms",
                          value: "symptoms",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Physical Findings",
                          value: "physicalFindings",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Medications",
                          value: "medications",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Test results",
                          value: "testResults",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Image studies",
                          value: "imageStudies",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Biopsy",
                          value: "biopsy",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 8,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "DME",
                          value: "dme",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 9,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Shortness of breath",
                          value: "shortnessOfBreath",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 10,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Wheezing",
                          value: "wheezing",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 11,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Chronic cough",
                          value: "chronicCough",
                          type: "toggle_button",
                        },

                        {
                          subQuestionId: 12,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          name: "ros_respiratoryChronicSputumFailureSubOther",
                          type: "toggle_button",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              subQuestionId: 1,
                              name: "ros_respiratoryChronicSputumFailureSubOtherSubDesc",
                              value: "describe",
                              type: "text",
                              placeholder: "",
                              label: "Describe",
                              variant: "outlined",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              sm: 3,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: false,
                            },
                          ],
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 7,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: " COPD ",
                  value: "copd",
                  name: "ros_respiratoryCopdSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "COPD",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_respiratoryCopdSubAnswerDescribe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_respiratoryCopdSubAnswerSupportedBy",
                      type: "options_tree",
                      label: " Supported by",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "  Use of accessary muscles  ",
                          value: "xuseOfAccessaryMuscles",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Barrel chest",
                          value: "barrelChest",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "XR results",
                          value: "xrResults",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Wheezing",
                          value: "wheezing",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Clubbing",
                          value: "clubbing",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: " Decreased or prolonged breath sounds ",
                          value: "decreasedOrProlongedSounds",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: " Dyspnea on exertion ",
                          value: "dyspneaOnExertion",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 8,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "O2 use",
                          value: "oxygenUse",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 9,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: " Bronchodilator medication ",
                          value: "bronchodilatorMedication",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 10,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Shortness of breath",
                          value: "shortnessOfBreath",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 11,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Chronic cough",
                          value: "chronicCough",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 12,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          name: "ros_respiratoryCopdSubOther",
                          type: "toggle_button",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              subQuestionId: 1,
                              name: "ros_respiratoryCopdSubOtherSubDesc",
                              value: "describe",
                              type: "text",
                              placeholder: "",
                              label: "Describe",
                              variant: "outlined",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              sm: 3,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: false,
                            },
                          ],
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_respiratoryPatientChronicBronchitis",
                      type: "options_tree",
                      label:
                        "Has patient been told they have Chronic Bronchitis",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Yes",
                          value: "yes",
                          name: "yes",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No",
                          value: "no",
                          name: "no",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 3,
                      editToggle: false,
                    },
                    {
                      id: 7,
                      name: "ros_respiratoryPatientHaveEmphysema",
                      type: "options_tree",
                      label: "Has patient been told they have Emphysema",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Yes",
                          value: "yes",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 4,
                      editToggle: false,
                    },
                    {
                      id: 8,
                      name: "ros_respiratoryPatientBronchodilator",
                      type: "options_tree",
                      label: "Is patient on Bronchodilator",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Yes",
                          value: "yes",
                          isConfigured: true,
                          type: "toggle_button",
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              name: "ros_respiratoryCopdSubPatientBronchodilator",
                              type: "options_tree",
                              label: "Route is",
                              editToggle: false,
                              xs: 12,
                              required: false,
                              value: "routeIs",
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 1,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  name: "inhaled",
                                  label: "Inhaled",
                                  value: "inhaled",
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 1,
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  name: "nebulizer",
                                  label: "Nebulizer",
                                  value: "nebulizer",
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 5,
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  name: "oral",
                                  label: "Oral",
                                  value: "oral",
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 5,
                      editToggle: false,
                    },
                    {
                      id: 9,
                      name: "ros_respiratoryPatientSteroids",
                      type: "options_tree",
                      label: "Is patient on Steroids",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Yes",
                          value: "yes",
                          isConfigured: true,
                          type: "toggle_button",
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "ros_respiratoryCopdSubPatientSteroids",
                              type: "options_tree",
                              label: "Route is",
                              editToggle: false,
                              xs: 12,
                              required: false,
                              value: "supported_bty",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                              depth: 4,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 5,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  name: "inhaled",
                                  label: "Inhaled",
                                  subQuestions: [],
                                  editToggle: false,
                                  value: "inhaled",
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 5,
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  name: "nebulizer",
                                  label: "Nebulizer",
                                  value: "nebulizer",
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 5,
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  name: "oral",
                                  label: "Oral",
                                  value: "oral",
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 6,
                      editToggle: false,
                    },
                    {
                      id: 10,
                      name: "ros_respiratoryPatientExacerbation",
                      type: "options_tree",
                      label: "Does patient have current exacerbation",
                      fullWidth: true,
                      required: true,
                      value: "patientExacerbation",
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Yes",
                          value: "yes",
                          isConfigured: true,
                          type: "toggle_button",
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "ros_respiratoryCopdSubPatientExacerbation",
                              type: "options_tree",
                              label: "Supported by",
                              editToggle: false,
                              xs: 12,
                              required: false,
                              value: "",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                              depth: 4,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 5,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  label: "use of antibiotics",
                                  subQuestions: [],
                                  editToggle: false,
                                  value: "useOfAntibiotics",
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 5,
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  label: "Fever",
                                  value: "fever",
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 5,
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  label: "Increased sputum production",
                                  value: "increasedSputumProduction",
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 5,
                                  subQuestionId: 4,
                                  hasSubQuestions: false,
                                  label: "Adventitious sounds on lung exam",
                                  value: "adventitiousSoundsOnLung",
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 7,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 8,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: " Cystic Fibrosis ",
                  value: "cysticFibrosis",
                  name: "ros_respiratoryCysticFibrosisSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Cystic Fibrosis",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_respiratoryCysticFibrosisSubAnswerDescribe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_respiratoryCysticFibrosisSubAnswerSupportedBy",
                      type: "options_tree",
                      label: " Supported by",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: " History ",
                          value: "history",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Symptoms",
                          value: "symptoms",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Physical Findings",
                          value: "physicalFindings",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Medications",
                          value: "medications",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Test results",
                          value: "testResults",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Image studies",
                          value: "imageStudies",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Biopsy",
                          value: "biopsy",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 8,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "DME",
                          value: "dme",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 9,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Shortness of breath",
                          value: "shortnessOfBreath",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 10,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Wheezing",
                          value: "wheezing",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 11,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Chronic cough",
                          value: "chronicCough",
                          type: "toggle_button",
                        },

                        {
                          subQuestionId: 12,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          name: "ros_respiratoryCysticFibrosisSubOther",
                          type: "toggle_button",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              subQuestionId: 1,
                              name: "ros_respiratoryCysticFibrosisSubDesc",
                              value: "describe",
                              type: "text",
                              placeholder: "",
                              label: "Describe",
                              variant: "outlined",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              sm: 3,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: false,
                            },
                          ],
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 9,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: " Hypoventilation secondary to Obesity ",
                  value: "hypoventilation",
                  name: "ros_respiratoryHypoventilationSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Hypoventilation secondary to Obesity",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_respiratoryHypoventilationSubAnswerDescribe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule Out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_respiratoryHypoventilationSubAnswerSupportedBy",
                      type: "options_tree",
                      label: " Supported by",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Morbid Obesity ",
                          value: "morbidObesity",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Use of O2",
                          value: "useOfOxygen",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "CO2 Retention",
                          value: "carbonDioxideRetention",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Shortness of breath",
                          value: "shortnessOfBreath",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Wheezing",
                          value: "wheezing",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Chronic cough",
                          value: "chronicCough",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          name: "ros_respiratoryHypoventilationSubOther",
                          type: "toggle_button",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              subQuestionId: 1,
                              name: "ros_respiratoryHypoventilationSubDesc",
                              value: "describe",
                              type: "text",
                              placeholder: "",
                              label: "Describe",
                              variant: "outlined",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              sm: 3,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: false,
                            },
                          ],
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 10,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: " Hypoxemia ",
                  value: "hypoxemia",
                  name: "ros_respiratoryHypoxemiaSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Hypoxemia",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_respiratoryHypoxemiaSubAnswerDescribe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_respiratoryHypoxemiaSubAnswerSupportedBy",
                      type: "options_tree",
                      label: " Supported by",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: " O2 saturation of <90% on room air ",
                          value: "oxygenSaturation",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: " Shortness of breath ",
                          value: "shortnessOfBreath",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Wheezing",
                          value: "wheezing",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Chronic cough",
                          value: "chronicCough",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          name: "ros_respiratoryHypoxemiaSubOther",
                          type: "toggle_button",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              subQuestionId: 1,
                              name: "ros_respiratoryHypoxemiaSubDesc",
                              value: "describe",
                              type: "text",
                              placeholder: "",
                              label: "Describe",
                              variant: "outlined",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              sm: 3,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: false,
                            },
                          ],
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 11,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: " Pneumonia ",
                  value: "pneumonia",
                  name: "ros_respiratoryPneumoniaSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Pneumonia",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_respiratoryPneumoniaSubAnswerDescribe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule Out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_respiratoryPneumoniaSubAnswerSupportedBy",
                      type: "options_tree",
                      label: " Supported by",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Hospitalization",
                          value: "hospitalization",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Physical findings",
                          value: "physicalFindings",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Image studies",
                          value: "imageStudies",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Lab studies",
                          value: "labStudies",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Shortness of breath",
                          value: "shortnessOfBreath",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Wheezing",
                          value: "wheezing",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 8,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Chronic cough",
                          value: "chronicCough",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 9,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          name: "ros_respiratoryPneumoniaSubOther",
                          type: "toggle_button",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              subQuestionId: 1,
                              name: "ros_respiratoryPneumoniaSubDesc",
                              value: "describe",
                              type: "text",
                              placeholder: "",
                              label: "Describe",
                              variant: "outlined",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              sm: 3,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: false,
                            },
                          ],
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_respiratoryPneumoniaSubAnswerEtiology",
                      type: "options_tree",
                      label: "Etiology",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Viral",
                          value: "viral",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Pneumococcal",
                          value: "pneumococcal",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Staph",
                          value: "staph",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other Bacterial",
                          value: "otherBacterial",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Aspiration",
                          value: "aspiration",
                          name: "aspiration",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 3,
                      editToggle: false,
                    },
                    {
                      id: 7,
                      name: "ros_respiratoryHistoryOfLungAbscess",
                      type: "options_tree",
                      label: "History / finding of Lung abscess",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Yes",
                          value: "yes",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 4,
                      editToggle: false,
                    },
                    {
                      id: 8,
                      name: "ros_respiratoryHistoryOfEmpyema",
                      type: "options_tree",
                      label: "History / finding of Empyema",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Yes",
                          value: "yes",
                          isConfigured: true,
                          type: "toggle_button",
                          hasSubQuestions: false,
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 5,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 12,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: " Pulmonary Fibrosis ",
                  value: "pulmonaryFibrosis",
                  name: "ros_respiratoryPulmonaryFibrosisSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Pulmonary Fibrosis",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_respiratoryPulmonaryFibrosisSubAnswerDescribe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_respiratoryPulmonaryFibrosisSubAnswerSupportedBy",
                      type: "options_tree",
                      label: " Supported by",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "X-ray or CT results",
                          value: "xRay",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "PFT",
                          value: "pft",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Biopsy",
                          value: "biopsy",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Medications",
                          value: "medications",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Shortness of breath",
                          value: "shortnessOfBreath",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Wheezing",
                          value: "wheezing",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 8,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Chronic cough",
                          value: "chronicCough",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 9,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          name: "ros_respiratoryPulmonaryFibrosisSubOther",
                          type: "toggle_button",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              subQuestionId: 1,
                              name: "ros_respiratoryPulmonaryFibrosisSubDesc",
                              value: "describe",
                              type: "text",
                              placeholder: "",
                              label: "Describe",
                              variant: "outlined",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              sm: 3,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: false,
                            },
                          ],
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 13,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: " Respirator Dependence/ Tracheostomy Status ",
                  value: "respiratorDependence",
                  name: "ros_respiratoryRespiratorDependenceSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Respirator Dependence/ Tracheostomy Status",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_respiratoryRespiratorDependenceSubAnswerDescribe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_respiratoryRespiratorDependenceSubAnswerSupportedBy",
                      type: "options_tree",
                      label: " Supported by",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "History",
                          value: "history",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Symptoms",
                          value: "symptoms",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Physical Findings",
                          value: "physicalFindings",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Medications",
                          value: "medications",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Test results",
                          value: "testResults",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Image studies",
                          value: "imageStudies",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Biopsy",
                          value: "biopsy",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 8,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "DME",
                          value: "dme",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 9,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Shortness of breath",
                          value: "shortnessOfBreath",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 10,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Wheezing",
                          value: "wheezing",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 11,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Chronic cough",
                          value: "chronicCough",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 12,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          name: "ros_respiratoryRespiratorDependenceSubOther",
                          type: "toggle_button",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              subQuestionId: 1,
                              name: "ros_respiratoryRespiratorDependenceSubDesc",
                              value: "describe",
                              type: "text",
                              placeholder: "",
                              label: "Describe",
                              variant: "outlined",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              sm: 3,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: false,
                            },
                          ],
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 14,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: " Respiratory Arrest ",
                  value: "respiratoryArrest",
                  name: "ros_respiratoryRespiratoryArrestSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Respiratory Arrest",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_respiratoryRespiratoryArrestSubAnswerDescribe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active(in past 3 months)",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_respiratoryRespiratoryArrestSubAnswerSupportedBy",
                      type: "options_tree",
                      label: " Supported by",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label:
                            "History of hospitalization with Respiratory arrest",
                          value: "historyOfHospitalization",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Use of ventilator",
                          value: "useOfVentilator",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "CO2 Retention",
                          value: "carbonDioxideRetention",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Shortness of breath",
                          value: "shortnessOfBreath",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Wheezing",
                          value: "wheezing",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Chronic cough",
                          value: "chronicCough",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          name: "ros_respiratoryRespiratoryArrestSubOther",
                          value: "other",
                          type: "toggle_button",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              subQuestionId: 1,
                              name: "ros_respiratoryRespiratoryArrestSubDesc",
                              value: "describe",
                              type: "text",
                              placeholder: "",
                              label: "Describe",
                              variant: "outlined",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              sm: 3,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: false,
                            },
                          ],
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 15,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: " Sarcoidosis ",
                  value: "sarcoidosis",
                  name: "ros_respiratorySarcoidosisSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Sarcoidosis",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_respiratorySarcoidosisSubAnswerDescribe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_respiratorySarcoidosisSubAnswerSpecify",
                      type: "options_tree",
                      label: " Specify",
                      value: "specify",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Lung",
                          value: "lung",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Lymph Nodes",
                          value: "lymphNodes",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Skin",
                          value: "skin",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Cranial Nerves",
                          value: "cranialNerves",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Unknown",
                          value: "unKnown",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_respiratorySarcoidosisSubAnswerSupportedBy",
                      type: "options_tree",
                      label: " Supported by",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Diagnostic results",
                          value: "diagnosticResults",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Cutaneous lesions",
                          value: "cutaneousLesions",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Shortness of breath",
                          value: "shortnessOfBreath",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Wheezing",
                          value: "wheezing",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Chronic cough",
                          value: "chronicCough",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          name: "ros_respiratorySarcoidosisSubOther",
                          type: "toggle_button",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              subQuestionId: 1,
                              name: "ros_respiratorySarcoidosisSubDesc",
                              value: "describe",
                              type: "text",
                              placeholder: "",
                              label: "Describe",
                              variant: "outlined",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              sm: 3,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: false,
                            },
                          ],
                        },
                      ],
                      subQuestionId: 3,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 16,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: " Sleep Apnea ",
                  value: "sleepApnea",
                  name: "ros_respiratorySleepApneaSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Sleep Apnea",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_respiratorySleepApneaSubAnswerDescribe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_respiratorySleepApneaSubAnswerSupportedBy",
                      type: "options_tree",
                      label: " Supported by",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Use of CPAP",
                          value: "useOfCpap",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Positive sleep studies",
                          value: "positiveSleepStudies",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of sleepiness during the day",
                          value: "historyOfSleepiness",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Heavy snoring / restlessness during sleep",
                          value: "heavySnoring",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Shortness of breath",
                          value: "shortnessOfBreath",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Wheezing",
                          value: "wheezing",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Chronic cough",
                          value: "chronicCough",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 8,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          name: "ros_respiratorySleepApneaSubOther",
                          type: "toggle_button",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              subQuestionId: 1,
                              name: "ros_respiratorySleepApneaSubDesc",
                              value: "describe",
                              type: "text",
                              placeholder: "",
                              label: "Describe",
                              variant: "outlined",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              sm: 3,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: false,
                            },
                          ],
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 17,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: " Other ",
                  value: "other",
                  name: "ros_respiratoryOtherSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Other",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_respiratoryRespOtherSubAnswerDescribe",
                      value: "describe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_respiratoryRespOtherSubAnswerSupportedBy",
                      type: "options_tree",
                      value: "supportedBy",
                      label: " Supported by",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "History",
                          value: "history",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Symptoms",
                          value: "symptoms",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Physical Findings",
                          value: "physicalFindings",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Medications",
                          value: "medications",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Test results",
                          value: "testResults",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Image studies",
                          value: "imageStudies",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Biopsy",
                          value: "biopsy",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 8,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "DME",
                          value: "dme",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 9,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Shortness of breath",
                          value: "shortnessOfBreath",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 10,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Wheezing",
                          value: "wheezing",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 11,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Chronic cough",
                          value: "chronicCough",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 12,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          name: "ros_respiratoryOtherSubOther",
                          type: "toggle_button",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              subQuestionId: 1,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              subQuestionId: 2,
                              name: "ros_respiratoryOtherSubDesc",
                              value: "describe",
                              type: "text",
                              placeholder: "",
                              label: "Describe",
                              variant: "outlined",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              sm: 3,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: false,
                            },
                          ],
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                    },
                    {
                      id: 4,
                      name: "ros_respiratoryOtherSubAnswerOther",
                      depth: 3,
                      type: "text",
                      label: "Other",
                      value: "other",
                      editToggle: false,
                      xs: 12,
                      required: false,
                      placeholder: "",
                      defaultValue: "",
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      subQuestionId: 3,
                      hasSubQuestions: false,
                    },
                  ],
                },
              ],
              subQuestionId: 1,
              editToggle: false,
            },
          ],
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 13,
      editToggle: false,
    },
    {
      id: 10,
      name: "respiratory_Recommendations",
      value: "takeMedications",
      label: "Recommendations",
      placeholder: "",
      editToggle: false,
      defaultValue: "",
      type: "checkbox",
      options: [
        ["Take medications as prescribed", "takeMedications"],
        ["Other", "other"],
      ],
      xs: 12,
      required: false,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      questionId: 14,
    },
    {
      id: 9,
      name: "cardiovascular",
      type: "heading",
      label: "CARDIOVASCULAR",
      editToggle: false,
      xs: 12,
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 14,
      depth: 1,
    },
    {
      id: 6,
      name: "ros_cardio",
      type: "options_tree",
      label:
        "Cardiovascular (Hypertension, Angina, Ischemic Heart Disease(CAD), Myocardial Infarction, Other)",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 2,
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "ros_cardioSubDiagnoses",
              type: "options_tree",
              label: " Diagnoses         ",
              isMulti: true,
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 4,
                  subQuestionId: 1,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Abnormal Cardiac Rhythm",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },

                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesabnormalCardiacRhythmDescribe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "active",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                          name: "historyOf",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                          name: "ruleOut",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                      depth: 5,
                    },
                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesabnormalCardiacRhythmSup",
                      type: "options_tree",
                      label: "Supported by    ",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "ECG",
                          value: "ecg",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "ecg",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Use of rate controlling drug",
                          value: "useofratecontrollingdrug",
                          type: "toggle_button",
                          name: "useofratecontrollingdrug",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Use of anticoagulation",
                          value: "useOfAnticoagulation",
                          type: "toggle_button",
                          name: "useOfAnticoagulation",
                          depth: 6,
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Electrophysiology procedure/cardioversion",
                          value: "electrophysiologyProcedureCard",
                          type: "toggle_button",
                          name: "electrophysiologyProcedureCard",
                          depth: 6,
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Chest pain",
                          value: "chestPain",
                          type: "toggle_button",
                          name: "chestPain",
                          depth: 6,
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Light headedness",
                          value: "lightHeadedness",
                          type: "toggle_button",
                          name: "lightHeadedness",
                          depth: 6,
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Implanted pacemaker",
                          value: "implantedPacemaker",
                          type: "toggle_button",
                          name: "implantedPacemaker",
                          depth: 6,
                        },
                        {
                          subQuestionId: 8,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Implanted defibrillator",
                          value: "implantedDefibrillator",
                          type: "toggle_button",
                          name: "implantedDefibrillator",
                          depth: 6,
                        },
                        {
                          subQuestionId: 9,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Shortness of breath",
                          value: "shortnessOfBreath",
                          type: "toggle_button",
                          name: "shortnessOfBreath",
                          depth: 6,
                        },
                        {
                          subQuestionId: 10,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Wheezing",
                          value: "wheezing",
                          type: "toggle_button",
                          name: "wheezing",
                          depth: 6,
                        },
                        {
                          subQuestionId: 11,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Chronic cough",
                          value: "chronicCough",
                          type: "toggle_button",
                          name: "chronicCough",
                          depth: 6,
                        },

                        {
                          subQuestionId: 12,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 1,
                              name: "ros_cardioSubDiagnosesabnormalCardiacRhythmSupotherSubAnswer",
                              type: "text",
                              label: "Describe",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                          name: "other",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                      depth: 5,
                    },
                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesabnormalCardiacRhythmCrDes",
                      type: "options_tree",
                      label: "Describe   ",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Bradycardia",
                          value: "bradycardia",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "bradycardia",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Tachycardia",
                          value: "tachycardia",
                          type: "toggle_button",
                          name: "tachycardia",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_cardioSubDiagnosesabnormalCardiacRhythmCrDesregularlyIrregularsubAnsAtrial",
                              type: "options_tree",
                              label: "Does patient have Atrial Fibrillation",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Yes",
                                  value: "yes",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "No",
                                  value: "no",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 1,
                              editToggle: false,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Regularly irregular",
                          value: "regularlyIrregular",
                          type: "toggle_button",
                          name: "regularlyIrregular",
                          depth: 6,
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Irregularly Irregular",
                          value: "irregularlyIrregular",
                          type: "toggle_button",
                          name: "irregularlyIrregular",
                          depth: 6,
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Premature contractures",
                          value: "prematureContractures",
                          type: "toggle_button",
                          name: "prematureContractures",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 3,
                      editToggle: false,
                      depth: 5,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  label: "Abnormal Cardiac Rhythm",
                  value: "abnormalCardiacRhythm",
                  isConfigured: true,
                  type: "toggle_button",
                  name: "abnormalCardiacRhythm",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Aneurysm",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesAneurysmDes",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "active",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                          name: "history",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                          name: "ruleOut",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                      depth: 5,
                    },
                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesAneurysmSup",
                      type: "options_tree",
                      label: "Supported by     ",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Physical exam",
                          value: "physicalExam",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "physicalExam",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Ultra sound,last study date & size",
                          value: "ultrasoundlaststudydate&size",
                          type: "toggle_button",
                          name: "ussdSize",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Image studies",
                          value: "imageStudies",
                          type: "toggle_button",
                          name: "imageStudies",
                          depth: 6,
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Chest pain",
                          value: "chestPain",
                          type: "toggle_button",
                          name: "chestPain",
                          depth: 6,
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Light headedness",
                          value: "lightHeadedness",
                          type: "toggle_button",
                          name: "lightHeadedness",
                          depth: 6,
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Shortness of breath",
                          value: "shortnessofbreath",
                          type: "toggle_button",
                          name: "shortnessOfBreath",
                          depth: 6,
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Wheezing",
                          value: "wheezing",
                          type: "toggle_button",
                          name: "wheezing",
                          depth: 6,
                        },
                        {
                          subQuestionId: 8,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Chronic cough",
                          value: "chronicCough",
                          type: "toggle_button",
                          name: "chronicCough",
                          depth: 6,
                        },
                        {
                          subQuestionId: 9,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 1,
                              name: "ros_cardioSubDiagnosesAneurysmSupotherSubAnswer",
                              type: "text",
                              label: "Describe",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                          name: "other",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                      depth: 5,
                    },
                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesAneurysmCrDes",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Abdominal",
                          value: "abdominal",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "abdominal",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Thoracic",
                          value: "thoracic",
                          type: "toggle_button",
                          name: "thoracic",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Peripheral",
                          value: "peripheral",
                          type: "toggle_button",
                          name: "peripheral",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 3,
                      editToggle: false,
                      depth: 5,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Aneurysm",
                  value: "aneursym",
                  type: "toggle_button",
                  name: "aneursym",
                  depth: 4,
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Angina",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesAnginaDes",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "active",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                          name: "historyOf",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                          name: "ruleOut",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                      depth: 5,
                    },
                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesAnginaSup",
                      type: "options_tree",
                      label: " Supported by          ",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Medications",
                          value: "medications",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "medications",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History characterizing chest pain",
                          value: "history",
                          type: "toggle_button",
                          name: "history",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Stress test",
                          value: "stressTest",
                          type: "toggle_button",
                          name: "stressTest",
                          depth: 6,
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Chest pain",
                          value: "chestPain",
                          type: "toggle_button",
                          name: "chestPain",
                          depth: 6,
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Light headedness",
                          value: "lightHeadedness",
                          type: "toggle_button",
                          name: "lightHeadedness",
                          depth: 6,
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Shortness of breath",
                          value: "shortnessOfBreath",
                          type: "toggle_button",
                          name: "shortnessOfBreath",
                          depth: 6,
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Wheezing",
                          value: "wheezing",
                          type: "toggle_button",
                          name: "wheezing",
                          depth: 6,
                        },
                        {
                          subQuestionId: 8,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Chronic cough",
                          value: "chronicCough",
                          type: "toggle_button",
                          name: "chronicCough",
                          depth: 6,
                        },
                        {
                          subQuestionId: 9,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 1,
                              name: "ros_cardioSubDiagnosesAnginaSupotherSubAnswer",
                              type: "text",
                              label: "Describe",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                          name: "other",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                      depth: 5,
                    },
                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesAnginaCrDes",
                      type: "options_tree",
                      label: "  Describe        ",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: " Stable",
                          value: "stable",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "stable",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Unstable",
                          value: "unstable",
                          type: "toggle_button",
                          name: "unstable",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 3,
                      editToggle: false,
                      depth: 5,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Angina",
                  value: "angina",
                  type: "toggle_button",
                  name: "angina",
                  depth: 4,
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Atrial Fibrillation",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesAtrialFibrillationDes",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "active",
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                          name: "historyOf",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                          name: "ruleOut",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 1,
                      depth: 5,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesAtrialFibrillationType",
                      type: "options_tree",
                      label: "Type",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "paroxysmal",
                          label: "Paroxysmal",
                          value: "paroxysmal",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Chronic",
                          value: "chronic",
                          type: "toggle_button",
                          name: "chronic",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Unknown",
                          value: "unknown",
                          type: "toggle_button",
                          name: "unknown",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 2,
                      depth: 5,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesAtrialFibrillationSup",
                      type: "options_tree",
                      label: "Supported by ",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "medications",
                          label: "Medications",
                          value: "medications",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "ECG",
                          value: "ecg",
                          type: "toggle_button",
                          name: "ecg",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Symptoms",
                          value: "symptoms",
                          type: "toggle_button",
                          name: "symptoms",
                          depth: 6,
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History",
                          value: "history",
                          type: "toggle_button",
                          name: "history",
                          depth: 6,
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Electric cardioversion",
                          value: "electricCardioversion",
                          type: "toggle_button",
                          name: "electricCardioversion",
                          depth: 6,
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Chest pain",
                          value: "chestPain",
                          type: "toggle_button",
                          name: "chestPain",
                          depth: 6,
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Light headedness",
                          value: "lightHeadedness",
                          type: "toggle_button",
                          name: "lightHeadedness",
                          depth: 6,
                        },
                        {
                          subQuestionId: 8,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Shortness of  breath",
                          value: "shortnessOfBreath",
                          type: "toggle_button",
                          name: "shortnessOfBreath",
                          depth: 6,
                        },
                        {
                          subQuestionId: 9,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Wheezing",
                          value: "wheezing",
                          type: "toggle_button",
                          name: "wheezing",
                          depth: 6,
                        },
                        {
                          subQuestionId: 10,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Chronic cough",
                          value: "chronicCough",
                          type: "toggle_button",
                          name: "chronicCough",
                          depth: 6,
                        },
                        {
                          subQuestionId: 11,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 1,
                              name: "ros_cardioSubDiagnosesAtrialFibrillationSupotherSubAnswer",
                              type: "text",
                              label: "Describe",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                          name: "other",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 3,
                      depth: 5,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesAtrialFibrillationIspatientTaking",
                      type: "options_tree",
                      label: "Is patient taking",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "anticoagulant",
                          label: "Anticoagulant",
                          value: "anticoagulant",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rate controlling medication",
                          value: "rateControllingMedication",
                          type: "toggle_button",
                          name: "rateControllingMedication",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 1,
                              name: "ros_cardioSubDiagnosesAtrialFibrillationPatientTakingOtherSubAnswers",
                              type: "text",
                              label: "Describe",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                          name: "other",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 4,
                      depth: 5,
                      editToggle: false,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Atrial Fibrillation",
                  value: "atrialFibrillation",
                  type: "toggle_button",
                  name: "atrialFibrillation",
                  depth: 4,
                },
                {
                  subQuestionId: 5,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Cardio-Respiratory Failure/Shock",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesCardiorespiratoryShockDes",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "active",
                          label: "Active(in the past 6 months)",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of ",
                          value: "historyOf",
                          type: "toggle_button",
                          name: "historyOf",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                          name: "ruleOut",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 1,
                      depth: 5,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesCardiorespiratoryShockSup",
                      type: "options_tree",
                      label: "Supported by",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "history",
                          label: "History",
                          value: "history",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Implanted Defibrillator",
                          value: "implantedDefibrillator",
                          type: "toggle_button",
                          name: "implantedDefibrillator",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Chest pain",
                          value: "chestPain",
                          type: "toggle_button",
                          name: "chestPain",
                          depth: 6,
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Light headedness",
                          value: "lightHeadedness",
                          type: "toggle_button",
                          name: "lightHeadedness",
                          depth: 6,
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Shortness of breath",
                          value: "shortnessOfBreath",
                          type: "toggle_button",
                          name: "shortnessOfBreath",
                          depth: 6,
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Wheezing",
                          value: "wheezing",
                          type: "toggle_button",
                          name: "wheezing",
                          depth: 6,
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Chronic cough",
                          value: "chronicCough",
                          type: "toggle_button",
                          name: "chronicCough",
                          depth: 6,
                        },
                        {
                          subQuestionId: 8,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 1,
                              name: "ros_cardioSubDiagnosesCardiorespiratoryShockSupotherSubAnswer",
                              type: "text",
                              label: "Describe",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                          name: "other",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 2,
                      depth: 5,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesCardiorespiratoryShockCardicArrest",
                      type: "options_tree",
                      label: "Cardiac Arrest",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "yes",
                          label: "Yes",
                          value: "yes",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                          name: "no",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 3,
                      depth: 5,
                      editToggle: false,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Cardio-Respiratory Failure/Shock",
                  value: "cardioRespiratoryFailureShock",
                  type: "toggle_button",
                  name: "cardioRespiratoryFailureShock",
                  depth: 4,
                },
                {
                  subQuestionId: 6,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Cardiomyopathy",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesCardiomyopathyDes",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "active",
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                          name: "historyOf",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                          name: "ruleOut",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 1,
                      depth: 5,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesCardiomyopathyType",
                      type: "options_tree",
                      label: "Type",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "dilated",
                          label: "Dilated",
                          value: "dilated",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Hypertrophic",
                          value: "hypertrophic",
                          type: "toggle_button",
                          name: "hypertrophic",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Restrictive",
                          value: "restrictive",
                          type: "toggle_button",
                          name: "restrictive",
                          depth: 6,
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Unknown",
                          value: "unknown",
                          type: "toggle_button",
                          name: "unknown",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 2,
                      depth: 5,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesCardiomyopathySup",
                      type: "options_tree",
                      label: "Supported by",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "echo",
                          label: "Echo",
                          value: "echo",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Cardiac Cath",
                          value: "cardiacCath",
                          type: "toggle_button",
                          name: "cardiacCath",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Chest pain",
                          value: "chestPain",
                          type: "toggle_button",
                          name: "chestPain",
                          depth: 6,
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Light headedness",
                          value: "lightHeadedness",
                          type: "toggle_button",
                          name: "lightHeadedness",
                          depth: 6,
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Shortness of breath",
                          value: "shortnessOfBreath",
                          type: "toggle_button",
                          name: "shortnessOfBreath",
                          depth: 6,
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Wheezing",
                          value: "wheezing",
                          type: "toggle_button",
                          name: "wheezing",
                          depth: 6,
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Chronic cough",
                          value: "chronicCough",
                          type: "toggle_button",
                          name: "chronicCough",
                          depth: 6,
                        },
                        {
                          subQuestionId: 8,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 1,
                              name: "ros_cardioSubDiagnosesCardiomyopathySupotherSubAnswer",
                              type: "text",
                              label: "Describe",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                          name: "other",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 3,
                      depth: 5,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesCardiompathysecondaryToHypertension",
                      type: "options_tree",
                      label: "Secondary to Hypertension",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "yes",
                          label: "Yes",
                          value: "yes",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                          name: "no",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 4,
                      depth: 5,
                      editToggle: false,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Cardiomyopathy",
                  value: "cardiomyopathy",
                  type: "toggle_button",
                  name: "cardiomyopathy",
                  depth: 4,
                },
                {
                  subQuestionId: 7,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Congestive Heart Failure",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesCongestiveHeartFailureSpecify",
                      type: "options_tree",
                      label: "Specify",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "acute",
                          label: "Acute",
                          value: "acute",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Chronic",
                          value: "chronic",
                          type: "toggle_button",
                          name: "chronic",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Acute on Chronic",
                          value: "acuteOnChronic",
                          type: "toggle_button",
                          name: "acuteOnChronic",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 1,
                      depth: 5,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesCongestiveHeartFailureDes",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "active",
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                          name: "historyOf",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                          name: "ruleOut",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 2,
                      depth: 5,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesCongestiveHeartFailureSup",
                      type: "options_tree",
                      label: "Supported by",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "ejectionFraction",
                          label: "Ejection fraction",
                          value: "ejectionFraction",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Cardiomegaly",
                          value: "cardiomegaly",
                          type: "toggle_button",
                          name: "cardiomegaly",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Orthopnea",
                          value: "orthopnea",
                          type: "toggle_button",
                          name: "orthopnea",
                          depth: 6,
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "DOE",
                          value: "doe",
                          type: "toggle_button",
                          name: "doe",
                          depth: 6,
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "PND",
                          value: "pnd",
                          type: "toggle_button",
                          name: "pnd",
                          depth: 6,
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "S3",
                          value: "s3",
                          type: "toggle_button",
                          name: "s3",
                          depth: 6,
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Medications",
                          value: "medications",
                          type: "toggle_button",
                          name: "medications",
                          depth: 6,
                        },
                        {
                          subQuestionId: 8,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Peripheral edema",
                          value: "peripheralEdema",
                          type: "toggle_button",
                          name: "peripheralEdema",
                          depth: 6,
                        },
                        {
                          subQuestionId: 9,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Chest pain",
                          value: "chestPain",
                          type: "toggle_button",
                          name: "chestPain",
                          depth: 6,
                        },
                        {
                          subQuestionId: 10,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Light headedness",
                          value: "lightHeadedness",
                          type: "toggle_button",
                          name: "lightHeadedness",
                          depth: 6,
                        },
                        {
                          subQuestionId: 11,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Shortness of breath",
                          value: "shortnessOfBreath",
                          type: "toggle_button",
                          name: "shortnessOfBreath",
                          depth: 6,
                        },
                        {
                          subQuestionId: 12,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Wheezing",
                          value: "wheezing",
                          type: "toggle_button",
                          name: "wheezing",
                          depth: 6,
                        },
                        {
                          subQuestionId: 13,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Chronic cough",
                          value: "chronicCough",
                          type: "toggle_button",
                          name: "chronicCough",
                          depth: 6,
                        },
                        {
                          subQuestionId: 14,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 1,
                              name: "ros_cardioSubDiagnosesCongestiveHeartFailureSupotherSubAnswer",
                              type: "text",
                              label: "Describe",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                          name: "other",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 3,
                      depth: 5,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesCongestiveHeartFailureCrDes",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "diastolic",
                          label: "Diastolic",
                          value: "diastolic",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Systolic",
                          value: "systolic",
                          type: "toggle_button",
                          name: "systolic",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Unknown",
                          value: "unknown",
                          type: "toggle_button",
                          name: "unknown",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 4,
                      depth: 5,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesCongestiveHeartFailuresecondaryToHypertension",
                      type: "options_tree",
                      label: "Secondary to Hypertension",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "yes",
                          label: "Yes",
                          value: "yes",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                          name: "no",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 5,
                      depth: 5,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientACE",
                      type: "options_tree",
                      label: "Is patient on an ACE or ARB",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "yes",
                          label: "Yes",
                          value: "yes",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                          name: "no",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 6,
                      depth: 5,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesCongestiveHeartFailureIsPatientBlocker",
                      type: "options_tree",
                      label: "Is patient on a Beta Blocker",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "yes",
                          label: "Yes",
                          value: "yes",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                          name: "no",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 7,
                      depth: 5,
                      editToggle: false,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Congestive Heart Failure",
                  value: "congestiveHeartFailure",
                  type: "toggle_button",
                  name: "congestiveHeartFailure",
                  depth: 4,
                },
                {
                  subQuestionId: 8,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Deep Vein Thrombosis",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesDeepVeinThrombosisCrDes",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "acute",
                          label: "Acute",
                          value: "acute",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Chronic",
                          value: "chronic",
                          type: "toggle_button",
                          name: "chronic",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 1,
                      depth: 5,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesDeepVeinThrombosisDes",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "active",
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                          name: "historyOf",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                          name: "ruleOut",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 2,
                      depth: 5,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesDeepVeinThrombosisSup",
                      type: "options_tree",
                      label: "Supported by",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "physicalFindings",
                          label: "Physical findings",
                          value: "physicalFindings",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Use of anticoagulation",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 6,
                              name: "ros_cardioSubDiagnosesDeepVeinThrombosisSupanticoagulationSub",
                              type: "options_tree",
                              label: "Describe",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Prophylactic",
                                  value: "prophylactic",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Therapeutic",
                                  value: "therapeutic",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 1,
                              editToggle: false,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Use of anticoagulation",
                          value: "useOfAnticoagulation",
                          type: "toggle_button",
                          name: "useOfAnticoagulation",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Vascular studies",
                          value: "vascularStudies",
                          type: "toggle_button",
                          name: "vascularStudies",
                          depth: 6,
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Vena Cava filter",
                          value: "venaCavaFilter",
                          type: "toggle_button",
                          name: "venaCavaFilter",
                          depth: 6,
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Edema",
                          value: "edema",
                          type: "toggle_button",
                          name: "edema",
                          depth: 6,
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Chest pain",
                          value: "chestPain",
                          type: "toggle_button",
                          name: "chestPain",
                          depth: 6,
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Light headedness",
                          value: "lightHeadedness",
                          type: "toggle_button",
                          name: "lightHeadedness",
                          depth: 6,
                        },
                        {
                          subQuestionId: 8,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Shortness of breath",
                          value: "shortnessOfBreath",
                          type: "toggle_button",
                          name: "shortnessOfBreath",
                          depth: 6,
                        },
                        {
                          subQuestionId: 9,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Wheezing",
                          value: "wheezing",
                          type: "toggle_button",
                          name: "wheezing",
                          depth: 6,
                        },
                        {
                          subQuestionId: 10,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Chronic cough",
                          value: "chronicCough",
                          type: "toggle_button",
                          name: "chronicCough",
                          depth: 6,
                        },
                        {
                          subQuestionId: 11,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 1,
                              name: "ros_cardioSubDiagnosesDeepVeinThrombosisSupotherSubAnswer",
                              type: "text",
                              label: "Describe",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                          name: "other",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 3,
                      depth: 5,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesDeepVeinThrombosisPersistent",
                      type: "options_tree",
                      label: "Persistent for three months or more",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "yes",
                          label: "Yes",
                          value: "yes",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                          name: "no",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 4,
                      depth: 5,
                      editToggle: false,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Deep Vein Thrombosis",
                  value: "deepVeinThrombosis",
                  type: "toggle_button",
                  name: "deepVeinThrombosis",
                  depth: 4,
                },
                {
                  subQuestionId: 9,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Hyperlipidemia",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesHyperlipidemiaDes",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "active",
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                          name: "historyOf",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                          name: "ruleOut",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 1,
                      depth: 5,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesHyperlipidemiaSup",
                      type: "options_tree",
                      label: "Supported by",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "labResults",
                          label: "Lab results",
                          value: "labResults",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Medication",
                          value: "medication",
                          type: "toggle_button",
                          name: "medication",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Chest pain",
                          value: "chestPain",
                          type: "toggle_button",
                          name: "chestPain",
                          depth: 6,
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Light headedness",
                          value: "lightHeadedness",
                          type: "toggle_button",
                          name: "lightHeadedness",
                          depth: 6,
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Shortness of breath",
                          value: "shortnessOfBreath",
                          type: "toggle_button",
                          name: "shortnessOfBreath",
                          depth: 6,
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Wheezing",
                          value: "wheezing",
                          type: "toggle_button",
                          name: "wheezing",
                          depth: 6,
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Chronic cough",
                          value: "chronicCough",
                          type: "toggle_button",
                          name: "chronicCough",
                          depth: 6,
                        },
                        {
                          subQuestionId: 8,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 1,
                              name: "ros_cardioSubDiagnosesHyperlipidemiaSupotherSubAnswer",
                              type: "text",
                              label: "Describe",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                          name: "other",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 2,
                      depth: 5,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesHyperlipidemiapatientOnStatin",
                      type: "options_tree",
                      label: "Is patient on Statin",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "yes",
                          label: "Yes",
                          value: "yes",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                          name: "no",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 3,
                      depth: 5,
                      editToggle: false,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Hyperlipidemia",
                  value: "hyperlipidemia",
                  type: "toggle_button",
                  name: "hyperlipidemia",
                  depth: 4,
                },
                {
                  subQuestionId: 10,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Hypertension",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesHypertensionDes",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "active",
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                          name: "historyOf",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                          name: "ruleOut",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 1,
                      depth: 5,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesHypertensionSup",
                      type: "options_tree",
                      label: "Supported by",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "physicalExam",
                          label: "Physical Exam",
                          value: "physicalExam",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Medications",
                          value: "medications",
                          type: "toggle_button",
                          name: "medications",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Symptoms",
                          value: "symptoms",
                          type: "toggle_button",
                          name: "symptoms",
                          depth: 6,
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Chest pain",
                          value: "chestPain",
                          type: "toggle_button",
                          name: "chestPain",
                          depth: 6,
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Light headedness",
                          value: "lightHeadedness",
                          type: "toggle_button",
                          name: "lightHeadedness",
                          depth: 6,
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Shortness of breath",
                          value: "shortnessOfBreath",
                          type: "toggle_button",
                          name: "shortnessOfBreath",
                          depth: 6,
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Wheezing",
                          value: "wheezing",
                          type: "toggle_button",
                          name: "wheezing",
                          depth: 6,
                        },
                        {
                          subQuestionId: 8,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Chronic cough",
                          value: "chronicCough",
                          type: "toggle_button",
                          name: "chronicCough",
                          depth: 6,
                        },
                        {
                          subQuestionId: 9,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 1,
                              name: "ros_cardioSubDiagnosesHypertensionSupotherSubAnswer",
                              type: "text",
                              label: "Describe",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                          name: "other",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 2,
                      depth: 5,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesHypertensionadequatelyControlled",
                      type: "options_tree",
                      label: "Adequately controlled",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "yes",
                          label: "Yes",
                          value: "yes",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                          name: "no",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Unknown",
                          value: "unknown",
                          type: "toggle_button",
                          name: "unknown",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 3,
                      depth: 5,
                      editToggle: false,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Hypertension",
                  value: "hypertension",
                  type: "toggle_button",
                  name: "hypertension",
                  depth: 4,
                },
                {
                  subQuestionId: 11,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Ischemic Heart Disease(CAD)",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesIschemicHeartDiseaseDes",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "active",
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of ",
                          value: "historyOf",
                          type: "toggle_button",
                          name: "historyOf",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                          name: "ruleOut",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 1,
                      depth: 5,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesIschemicHeartDiseaseSup",
                      type: "options_tree",
                      label: "Supported by",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "cardiacCath",
                          label: "Cardiac Cath",
                          value: "cardiacCath",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of coronary stent",
                          value: "historyOfCoronaryStent",
                          type: "toggle_button",
                          name: "historyOfCoronaryStent",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Diagnosis of angina",
                          value: "diagnosisOfAngina",
                          type: "toggle_button",
                          name: "diagnosisOfAngina",
                          depth: 6,
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Medications",
                          value: "medications",
                          type: "toggle_button",
                          name: "medications",
                          depth: 6,
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of CABG",
                          value: "historyOfCabg",
                          type: "toggle_button",
                          name: "historyOfCabg",
                          depth: 6,
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "ECG",
                          value: "ecg",
                          type: "toggle_button",
                          name: "ecg",
                          depth: 6,
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Chest pain",
                          value: "chestPain",
                          type: "toggle_button",
                          name: "chestPain",
                          depth: 6,
                        },
                        {
                          subQuestionId: 8,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Light headedness",
                          value: "lightHeadedness",
                          type: "toggle_button",
                          name: "lightHeadedness",
                          depth: 6,
                        },
                        {
                          subQuestionId: 9,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Shortness of breath",
                          value: "shortnessOfBreath",
                          type: "toggle_button",
                          name: "shortnessOfBreath",
                          depth: 6,
                        },
                        {
                          subQuestionId: 10,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Wheezing",
                          value: "wheezing",
                          type: "toggle_button",
                          name: "wheezing",
                          depth: 6,
                        },
                        {
                          subQuestionId: 11,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Chronic cough",
                          value: "chronicCough",
                          type: "toggle_button",
                          name: "chronicCough",
                          depth: 6,
                        },
                        {
                          subQuestionId: 12,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 1,
                              name: "ros_cardioSubDiagnosesIschemicHeartDiseaseSupotherSubAnswer",
                              type: "text",
                              label: "Describe",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                          name: "other",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 2,
                      depth: 5,
                      editToggle: false,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Ischemic Heart Disease(CAD)",
                  value: "ischemicHeartDisease",
                  type: "toggle_button",
                  name: "ischemicHeartDisease",
                  depth: 4,
                },
                {
                  subQuestionId: 12,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Myocardial Infarction",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesMyocardialDes",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "active",
                          label: "Active (in past 28 days)",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                          name: "historyOf",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                          name: "ruleOut",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 1,
                      depth: 5,
                      editToggle: false,
                    },
                    {
                      id: 4,
                      name: "ros_cardioSubDiagnosesMyocardialDate",
                      label: "Date",
                      placeholder: "",
                      type: "text",
                      editToggle: false,
                      xs: 4,
                      defaultValue: "",
                      fullWidth: true,
                      required: "no",
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      subQuestionId: 2,
                      depth: 5,
                    },
                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesMyocardialSup",
                      type: "options_tree",
                      label: "Supported by",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "ecgChanges",
                          label: "ECG Changes",
                          value: "ecgChanges",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Lab results",
                          value: "labResults",
                          type: "toggle_button",
                          name: "labResults",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of Hospitalization/Procedure for MI",
                          value: "historyOfHospitalizationProced",
                          type: "toggle_button",
                          name: "historyOfHospitalizationProced",
                          depth: 6,
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Medications",
                          value: "medications",
                          type: "toggle_button",
                          name: "medications",
                          depth: 6,
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Chest pain",
                          value: "chestPain",
                          type: "toggle_button",
                          name: "chestPain",
                          depth: 6,
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Light headedness",
                          value: "lightHeadedness",
                          type: "toggle_button",
                          name: "lightHeadedness",
                          depth: 6,
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Shortness of breath",
                          value: "shortnessOfBreath",
                          type: "toggle_button",
                          name: "shortnessOfBreath",
                          depth: 6,
                        },
                        {
                          subQuestionId: 8,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Wheezing",
                          value: "wheezing",
                          type: "toggle_button",
                          name: "wheezing",
                          depth: 6,
                        },
                        {
                          subQuestionId: 9,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Chronic cough",
                          value: "chronicCough",
                          type: "toggle_button",
                          name: "chronicCough",
                          depth: 6,
                        },
                        {
                          subQuestionId: 10,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 1,
                              name: "ros_cardioSubDiagnosesMyocardialSupotherSubAnswer",
                              type: "text",
                              label: "Describe",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                          name: "other",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 3,
                      depth: 5,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesMyocardialBetaBlocker",
                      type: "options_tree",
                      label: "Is patient taking a Beta Blocker",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "yes",
                          label: "Yes",
                          value: "yes",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                          name: "no",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 4,
                      depth: 5,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesMyocardialIspateint",
                      type: "options_tree",
                      label: "Is patient taking",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "aspirin",
                          label: "Aspirin",
                          value: "aspirin",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Plavix",
                          value: "plavix",
                          type: "toggle_button",
                          name: "plavix",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Nitrate",
                          value: "nitrate",
                          type: "toggle_button",
                          name: "nitrate",
                          depth: 6,
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 1,
                              name: "ros_cardioSubDiagnosesMyocardialIspatientotherSubAnswer",
                              type: "text",
                              label: "Describe",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                          name: "other",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 5,
                      depth: 5,
                      editToggle: false,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Myocardial Infarction",
                  value: "myocardialInfarction",
                  type: "toggle_button",
                  name: "myocardialInfarction",
                  depth: 4,
                },
                {
                  subQuestionId: 13,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Peripheral Vascular Disease",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesPeripheralVascularDiseaseDes",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "active",
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of ",
                          value: "historyOf",
                          type: "toggle_button",
                          name: "historyOf",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                          name: "ruleOut",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 1,
                      depth: 5,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesPeripheralVascularDiseaseSup",
                      type: "options_tree",
                      label: "Supported by",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "vascularStudies",
                          label: "Vascular studies",
                          value: "vascularStudies",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Claudication",
                          value: "claudication",
                          type: "toggle_button",
                          name: "claudication",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Extemity Ulcers",
                          value: "extemityUlcers",
                          type: "toggle_button",
                          name: "extemityUlcers",
                          depth: 6,
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Diminished or absent pulses",
                          value: "diminishedOrAbsentPulses",
                          type: "toggle_button",
                          name: "diminishedOrAbsentPulses",
                          depth: 6,
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Amputation",
                          value: "amputation",
                          type: "toggle_button",
                          name: "amputation",
                          depth: 6,
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Chest pain",
                          value: "chestPain",
                          type: "toggle_button",
                          name: "chestPain",
                          depth: 6,
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Light headedness",
                          value: "lightHeadedness",
                          type: "toggle_button",
                          name: "lightHeadedness",
                          depth: 6,
                        },
                        {
                          subQuestionId: 8,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Shortness of breath",
                          value: "shortnessOfBreath",
                          type: "toggle_button",
                          name: "shortnessOfBreath",
                          depth: 6,
                        },
                        {
                          subQuestionId: 9,
                          hasSubQuestions: false,
                          subQuestions: [{}],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Wheezing",
                          value: "wheezing",
                          type: "toggle_button",
                          name: "wheezing",
                          depth: 6,
                        },
                        {
                          subQuestionId: 10,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Chronic cough",
                          value: "chronicCough",
                          type: "toggle_button",
                          name: "chronicCough",
                          depth: 6,
                        },
                        {
                          subQuestionId: 11,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 1,
                              name: "ros_cardioSubDiagnosesPeripheralVascularDiseaseSupotherSubAnswer",
                              type: "text",
                              label: "Describe",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                          name: "other",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 2,
                      depth: 5,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_cardiohistoryDiabetesisHistoryDiabetes",
                      type: "options_tree",
                      label: "History Diabetes",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "yes",
                          label: "Yes",
                          value: "yes",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                          name: "no",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 3,
                      depth: 5,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_cardiopvdDescribe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "ulceration",
                          label: "Ulceration",
                          value: "ulceration",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Gangrene",
                          value: "gangrene",
                          type: "toggle_button",
                          name: "gangrene",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 4,
                      depth: 5,
                      editToggle: false,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Peripheral Vascular Disease",
                  value: "peripheralVascularDisease",
                  type: "toggle_button",
                  name: "peripheralVascularDisease",
                  depth: 4,
                },
                {
                  subQuestionId: 14,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Pulmonary Hypertension",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesPulmonaryHypertensionDes",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "active",
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                          name: "historyOf",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                          name: "ruleOut",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 1,
                      depth: 5,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesPulmonaryHypertensionSup",
                      type: "options_tree",
                      label: "Supported by",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "cardiacCath",
                          label: "Cardiac Cath",
                          value: "cardiacCath",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Use of O2",
                          value: "useOfO2",
                          type: "toggle_button",
                          name: "useOfO2",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Edema",
                          value: "edema",
                          type: "toggle_button",
                          name: "edema",
                          depth: 6,
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Medication",
                          value: "medication",
                          type: "toggle_button",
                          name: "medication",
                          depth: 6,
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Chest pain",
                          value: "chestPain",
                          type: "toggle_button",
                          name: "chestPain",
                          depth: 6,
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Light headedness",
                          value: "lightHeadedness",
                          type: "toggle_button",
                          name: "lightHeadedness",
                          depth: 6,
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Shortness of breath",
                          value: "shortnessOfBreath",
                          type: "toggle_button",
                          name: "shortnessOfBreath",
                          depth: 6,
                        },
                        {
                          subQuestionId: 8,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Wheezing",
                          value: "wheezing",
                          type: "toggle_button",
                          name: "wheezing",
                          depth: 6,
                        },
                        {
                          subQuestionId: 9,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Chronic cough",
                          value: "chronicCough",
                          type: "toggle_button",
                          name: "chronicCough",
                          depth: 6,
                        },
                        {
                          subQuestionId: 10,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 1,
                              name: "ros_cardioSubDiagnosesPulmonaryHypertensionSupotherSubAnswer",
                              type: "text",
                              label: "Describe",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                          name: "other",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 2,
                      depth: 5,
                      editToggle: false,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Pulmonary Hypertension",
                  value: "pulmonaryHypertension",
                  type: "toggle_button",
                  name: "pulmonaryHypertension",
                  depth: 4,
                },
                {
                  subQuestionId: 15,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Valvular Disease",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesvalvulardiseaseDes",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "active",
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                          name: "historyOf",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                          name: "ruleOut",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 1,
                      depth: 5,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesvalvulardiseaseSup",
                      type: "options_tree",
                      label: "Supported by",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "history",
                          label: "History ",
                          value: "history",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Symptoms",
                          value: "symptoms",
                          type: "toggle_button",
                          name: "symptoms",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [{}],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Physical Findings",
                          value: "physicalFindings",
                          type: "toggle_button",
                          name: "physicalFindings",
                          depth: 6,
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Medications",
                          value: "medications",
                          type: "toggle_button",
                          name: "medications",
                          depth: 6,
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Test results",
                          value: "testResults",
                          type: "toggle_button",
                          name: "testResults",
                          depth: 6,
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Image studies",
                          value: "imageStudies",
                          type: "toggle_button",
                          name: "imageStudies",
                          depth: 6,
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Biopsy",
                          value: "biopsy",
                          type: "toggle_button",
                          name: "biopsy",
                          depth: 6,
                        },
                        {
                          subQuestionId: 8,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "DME",
                          value: "dme",
                          type: "toggle_button",
                          name: "dme",
                          depth: 6,
                        },
                        {
                          subQuestionId: 9,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Chest pain",
                          value: "chestPain",
                          type: "toggle_button",
                          name: "chestPain",
                          depth: 6,
                        },
                        {
                          subQuestionId: 10,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Light headedness",
                          value: "lightHeadedness",
                          type: "toggle_button",
                          name: "lightHeadedness",
                          depth: 6,
                        },
                        {
                          subQuestionId: 11,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Shortness of breath",
                          value: "shortnessOfBreath",
                          type: "toggle_button",
                          name: "shortnessOfBreath",
                          depth: 6,
                        },
                        {
                          subQuestionId: 12,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Wheezing",
                          value: "wheezing",
                          type: "toggle_button",
                          name: "wheezing",
                          depth: 6,
                        },
                        {
                          subQuestionId: 13,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Chronic cough",
                          value: "chronicCough",
                          type: "toggle_button",
                          name: "chronicCough",
                          depth: 6,
                        },
                        {
                          subQuestionId: 14,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 1,
                              name: "ros_cardioSubDiagnosesvalvulardiseaseSupotherSubAnswer",
                              type: "text",
                              label: "Describe",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                          name: "other",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 2,
                      depth: 5,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesvalvulardiseaseCrDes",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "mitralStenosis",
                          label: "Mitral Stenosis",
                          value: "mitralStenosis",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Aortic Stenosis/Sclerosis",
                          value: "aorticStenosisSclerosis",
                          type: "toggle_button",
                          name: "aorticStenosisSclerosis",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Tricuspid Stenosis",
                          value: "tricuspidStenosis",
                          type: "toggle_button",
                          name: "tricuspidStenosis",
                          depth: 6,
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Pulmonary Insufficiency",
                          value: "pulmonaryInsufficiency",
                          type: "toggle_button",
                          name: "pulmonaryInsufficiency",
                          depth: 6,
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Pulmonary Stenosis",
                          value: "pulmonaryStenosis",
                          type: "toggle_button",
                          name: "pulmonaryStenosis",
                          depth: 6,
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Arotic Insufficiency",
                          value: "aroticInsufficiency",
                          type: "toggle_button",
                          name: "aroticInsufficiency",
                          depth: 6,
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Mitral Insufficiency/Prolapse",
                          value: "mitralInsufficiencyProlapse",
                          type: "toggle_button",
                          name: "mitralInsufficiencyProlapse",
                          depth: 6,
                        },
                        {
                          subQuestionId: 8,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Tricuspid Insufficiency",
                          value: "tricuspidInsufficiency",
                          type: "toggle_button",
                          name: "tricuspidInsufficiency",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 3,
                      depth: 5,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesvalvulardiseaseValveReplacement",
                      type: "options_tree",
                      label: "Valve replacement",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "yes",
                          label: "Yes",
                          value: "yes",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                          name: "no",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 4,
                      depth: 5,
                      editToggle: false,
                    },
                    {
                      id: 1,
                      name: "ros_cardioSubDiagnosesvalvulardiseaseValueTypeOfReplacement",
                      type: "text",
                      label: "Which valve, type of replacement",
                      editToggle: false,
                      xs: 4,
                      required: "no",
                      placeholder: "",
                      defaultValue: "",
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      subQuestionId: 5,
                      depth: 5,
                    },
                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesvalvulardiseasePatientAnticoagulation",
                      type: "options_tree",
                      label: "Is patient on anticoagulation",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "yes",
                          label: "Yes",
                          value: "yes",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                          name: "no",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 6,
                      depth: 5,
                      editToggle: false,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Valvular Disease",
                  value: "valvularDisease",
                  type: "toggle_button",
                  name: "valvularDisease",
                  depth: 4,
                },
                {
                  subQuestionId: 16,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Other",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesOtherDes",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "active",
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                          name: "historyOf",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                          name: "ruleOut",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 1,
                      depth: 5,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesOtherSup",
                      type: "options_tree",
                      label: "Supported by",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "history",
                          label: "History",
                          value: "history",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Symptoms",
                          value: "symptoms",
                          type: "toggle_button",
                          name: "symptoms",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Physical Findings",
                          value: "physicalFindings",
                          type: "toggle_button",
                          name: "physicalFindings",
                          depth: 6,
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Medications",
                          value: "medications",
                          type: "toggle_button",
                          name: "medications",
                          depth: 6,
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Test results",
                          value: "testResults",
                          type: "toggle_button",
                          name: "testResults",
                          depth: 6,
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Image studies",
                          value: "imageStudies",
                          type: "toggle_button",
                          name: "imageStudies",
                          depth: 6,
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Biopsy",
                          value: "biopsy",
                          type: "toggle_button",
                          name: "biopsy",
                          depth: 6,
                        },
                        {
                          subQuestionId: 8,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "DME",
                          value: "dme",
                          type: "toggle_button",
                          name: "dme",
                          depth: 6,
                        },
                        {
                          subQuestionId: 9,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Chest pain",
                          value: "chestPain",
                          type: "toggle_button",
                          name: "chestPain",
                          depth: 6,
                        },
                        {
                          subQuestionId: 10,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Light headedness",
                          value: "lightHeadedness",
                          type: "toggle_button",
                          name: "lightHeadedness",
                          depth: 6,
                        },
                        {
                          subQuestionId: 11,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Shortness of breath",
                          value: "shortnessOfBreath",
                          type: "toggle_button",
                          name: "shortnessOfBreath",
                          depth: 6,
                        },
                        {
                          subQuestionId: 12,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Wheezing",
                          value: "wheezing",
                          type: "toggle_button",
                          name: "wheezing",
                          depth: 6,
                        },
                        {
                          subQuestionId: 13,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Chronic cough",
                          value: "chronicCough",
                          type: "toggle_button",
                          name: "chronicCough",
                          depth: 6,
                        },
                        {
                          subQuestionId: 14,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 1,
                              name: "ros_cardioSubDiagnosesOtherSupotherSubAnswer",
                              type: "text",
                              label: "Describe",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                          name: "other",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 2,
                      depth: 5,
                      editToggle: false,
                    },
                    {
                      id: 1,
                      name: "ros_cardioSubDiagnosesOtherOtherSub",
                      type: "text",
                      label: "Other",
                      editToggle: false,
                      xs: 4,
                      required: "no",
                      placeholder: "",
                      defaultValue: "",
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      subQuestionId: 3,
                      depth: 5,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Other",
                  value: "other",
                  type: "toggle_button",
                  name: "other",
                  depth: 4,
                },
              ],
              subQuestionId: 1,
              editToggle: false,
              depth: 3,
            },
          ],
          editToggle: false,
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          isConfigured: true,
          type: "toggle_button",
          name: "yes",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "No",
          value: "no",
          type: "toggle_button",
          name: "no",
          depth: 2,
        },
      ],
      questionId: 15,
      editToggle: false,
      depth: 1,
    },
    {
      id: 10,
      name: "ros_cardiovascularRecommendations",
      label: "Recommendations",
      placeholder: "",
      editToggle: false,
      defaultValue: "",
      type: "checkbox",
      options: [
        ["Blood pressure checks.", "bloodPressureChecks"],
        ["Heart healthy diet.", "heartHealthyDiet"],
        ["Exercise 30  minutes a day.", "exerciseThirtyMinADay"],
        ["Take medications as prescribed.", "takeMedicationsAsPescribed"],
        ["Other.", "other"],
      ],
      xs: 12,
      required: false,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      questionId: 16,
      depth: 1,
    },
    {
      id: 9,
      name: "gastrointestinal",
      type: "heading",
      label: "GASTROINTESTINAL",
      editToggle: false,
      xs: 12,
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 17,
    },
    {
      id: 6,
      name: "ros_gastro",
      type: "options_tree",
      label:
        "Gastrointestinal Problems (Ulcer, Reflux, Hiatal Hernia, Colitis, Other)",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 1,
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "ros_gastroSubDiagnoses",
              type: "options_tree",
              label: "Diagnoses",
              isMulti: true,
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 1,
                  subQuestionId: 1,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Bowel Obstruction",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },

                    {
                      id: 6,
                      name: "ros_gastroSubDiagnosesdesbowelObstruction",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_gastroSubDiagnosesbowelObstructionSup",
                      type: "options_tree",
                      label: "    Supported by     ",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Hospitalization",
                          value: "hospitalization",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Image studies",
                          value: "imageStudies",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Physical Findings",
                          value: "physicalFindings",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Abdominal Pain",
                          value: "abdominalPain",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Nausea and vomiting",
                          value: "nauseandvomiting",
                          type: "toggle_button",
                        },

                        {
                          subQuestionId: 6,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 1,
                              name: "ros_gastroSubDiagnosesbowelObstructionSupotherSubAnswerVal",
                              type: "text",
                              label: "Describe",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                          name: "other",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  label: "Bowel Obstruction",
                  value: "bowelobstruction",
                  isConfigured: true,
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Cachexia",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_gastroSubDiagnosescachexiades",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_gastroSubDiagnosescachexiaSup",
                      type: "options_tree",
                      label: "Supported by       ",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Albumin <3.5g/dl",
                          value: "albumin<3.5g/dl",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Muscle Wasting",
                          value: "muscleWasting",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of severe weight loss",
                          value: "historyofsevereweightloss",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Abdominal pain",
                          value: "abdominalpain",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Nausea and vomiting",
                          value: "nauseaandvomiting",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 1,
                              name: "ros_gastroSubDiagnosescachexiaSupotherSubAnswerVal",
                              type: "text",
                              label: "Describe",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                          name: "other",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Cachexia",
                  value: "cachexia",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Celiac Disease",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_gastroSubDiagnosesdesceliacDisease",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active ",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_gastroSubDiagnosesceliacDiseaseSup",
                      type: "options_tree",
                      label: " Supported  by ",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Weight Loss",
                          value: "weightLoss",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Anemia",
                          value: "anemia",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Change in bowel movements",
                          value: "changeinbowelmovements",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Osteoporosis",
                          value: "osteoporosis",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Diet",
                          value: "diet",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Abdominal Pain",
                          value: "abdominalPain",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Nausea and vomiting",
                          value: "nauseaandvomiting",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 8,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 1,
                              name: "ros_gastroSubDiagnosesceliacDiseaseSupotherSubAnswerVal",
                              type: "text",
                              label: "Describe",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                          name: "other",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_gastroSubDiagnosesceliacDiseaseGlueten",
                      type: "options_tree",
                      label: "On a gluten free diet",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Yes",
                          value: "yes",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 3,
                      editToggle: false,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Celiac Disease",
                  value: "celiacdisease",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Cirrhosis",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_gastroSubDiagnosesdescirrhosis",
                      type: "options_tree",
                      label: "Describe ",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_gastroSubDiagnosescirrhosisSup",
                      type: "options_tree",
                      label: "    Supported by   ",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Symptoms",
                          value: "symptoms",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Physical findings",
                          value: "physicalFindings",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Lab studies",
                          value: "labStudies",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "MRI",
                          value: "mri",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Biopsy",
                          value: "biopsy",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Abdominal pain",
                          value: "abdominalPain",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Nausea and vomiting",
                          value: "nauseaandvomiting",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 8,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 1,
                              name: "ros_gastroSubDiagnosescirrhosisSupotherSubAnswerVal",
                              type: "text",
                              label: "Describe",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                          name: "other",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_gastroSubDiagnosescirrhosisEndStage",
                      type: "options_tree",
                      label: "End stage liver disease",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_gastroSubDiagnosescirrhosisEndStageSubAnswer",
                              type: "options_tree",
                              label: "Supported by history / finding of",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  name: "ascites",
                                  label: "Ascites",
                                  value: "ascites",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Varices",
                                  value: "varices",
                                  type: "toggle_button",
                                  name: "varices",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Encephalopathy",
                                  value: "encephalopathy",
                                  type: "toggle_button",
                                  name: "encephalopathy",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 4,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Hepatorenal Syndrome",
                                  value: "hepatorenalSyndrome",
                                  type: "toggle_button",
                                  name: "hepatorenalSyndrome",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 5,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Other",
                                  value: "other",
                                  type: "toggle_button",
                                  name: "other",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 1,
                              depth: 7,
                              editToggle: false,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Yes",
                          value: "yes",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "yes",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                          name: "no",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 3,
                      editToggle: false,
                      depth: 5,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Cirrhosis",
                  value: "cirrhosis",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 5,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Colon Polyps",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_gastroSubDiagnosescolonPolypsDes",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_gastroSubDiagnosescolonSup",
                      type: "options_tree",
                      label: "Supported by                  ",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "History",
                          value: "history",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Symptoms",
                          value: "symptoms",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Physical Findings",
                          value: "physicalFindings",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Medications",
                          value: "medications",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Test results",
                          value: "testResults",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Image studies",
                          value: "imageStudies",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Biopsy",
                          value: "biopsy",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 8,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "DME",
                          value: "dme",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 9,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Abdominal pain",
                          value: "abdominalPain",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 10,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Nausea and vomiting",
                          value: "nauseaandvomiting",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 11,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 1,
                              name: "ros_gastroSubDiagnosescolonOtherSubAnswerVal",
                              type: "text",
                              label: "Describe",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                          name: "other",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_gastroSubDiagnosescolonCrDes",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Benign",
                          value: "bengir",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Carcinoma in situ",
                          value: "carcinomainsitu",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Familial polyposis",
                          value: "fmilialpolyposis",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 3,
                      editToggle: false,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Colon Polyps",
                  value: "colonpolyps",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 6,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Diverticulitis",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_gastroSubDiagnosesdesdiverticulitis",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_gastroSubDiagnosesdiverticulitisSup",
                      type: "options_tree",
                      label: "   Supported  by     ",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Colonoscopy",
                          value: "colonoscopy",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Image studies",
                          value: "imageStudies",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Symptoms",
                          value: "symptoms",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Diet",
                          value: "diet",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Abdominal Pain",
                          value: "abdominalPain",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Nausea and vomiting",
                          value: "nauseaandvomiting",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 11,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 1,
                              name: "ros_gastroSubDiagnosesdiverticulitisSupotherSubAnswerVal",
                              type: "text",
                              label: "Describe",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                          name: "other",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_gastroSubDiagnosesdiverticulitisabscessVal",
                      type: "options_tree",
                      label: "Abscess",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Yes",
                          value: "yes",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 3,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_gastroSubDiagnosesdiverticulitisperforationVal",
                      type: "options_tree",
                      label: "Perforation",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Yes",
                          value: "yes",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 4,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_gastroSubDiagnosesdiverticulitishighFiberDietVal",
                      type: "options_tree",
                      label: "On a high fiber diet",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Yes",
                          value: "yes",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 5,
                      editToggle: false,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Diverticulitis",
                  value: "diverticultis",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 7,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Gall Bladder Disease",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_gastroSubDiagnosesdesgallBladderDisease",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "active",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                          name: "historyOf",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                          name: "ruleOut",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_gastroSubDiagnosesgallBladderDiseaseSup",
                      type: "options_tree",
                      label: " Supported by",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Symptoms",
                          value: "symptoms",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Passing of stones",
                          value: "passingofstones",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "ERCP",
                          value: "ercp",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "HIDA Scan",
                          value: "hidascan",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "MRI",
                          value: "mri",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Treatment history",
                          value: "treatmentHistory",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Abdominal Pain",
                          value: "abdominalPain",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 8,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Nausea and vomiting",
                          value: "nauseaandvomiting",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 9,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },

                            {
                              id: 1,
                              name: "ros_gastroSubDiagnosesgallBladderSupOtherSubAnswerDescribe",
                              type: "text",
                              label: "Describe",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Gall Bladder Disease",
                  value: "gallbladderdisease",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 8,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Gastroparesis",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_gastroSubDiagnosesgastroparesisDescribe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_gastroSubDiagnosesgastroparesisSupportedBy",
                      type: "options_tree",
                      label: "    Supported by    ",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Symptoms",
                          value: "symptoms",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Gastric emptying test",
                          value: "gastricemptyingtest",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Medications",
                          value: "medications",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History",
                          value: "history",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Abdominal pain",
                          value: "abdominalpain",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Nausea and vomiting",
                          value: "nauseaandvomiting",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 1,
                              name: "ros_gastroSubDiagnosesgastroparesisSupportedByOtherSubAnswerDescribe",
                              type: "text",
                              label: "Describe",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Gastroparesis",
                  value: "gastroparesis",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 9,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "GERD",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_gastroSubDiagnosesgerdDescribe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_gastroSubDiagnosesgerdSupportedBy",
                      type: "options_tree",
                      label: " Supported  by ",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Heartburn / Dyspepsia",
                          value: "heartburn",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Regurgitation",
                          value: "regurgitation",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Medications",
                          value: "medications",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Abdominal Pain",
                          value: "abdominalpain",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Nausea and vomiting",
                          value: "nauseaandvomiting",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 1,
                              name: "ros_gastroSubDiagnosesgerdSupportedByOtherSubAnswerDescribe",
                              type: "text",
                              label: "Describe",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "GERD",
                  value: "gerd",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 10,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "GI Bleed",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_gastroSubDiagnosesglBleedDescribe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_gastroSubDiagnosesglBleedSupportedBy",
                      type: "options_tree",
                      label: "   Supported  by   ",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Symptoms",
                          value: "symptoms",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Blood in stool",
                          value: "bloodinstool",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Abdominal pain",
                          value: "abdominalpain",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Nausea and vomiting",
                          value: "nauseaandvomiting",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 1,
                              name: "ros_gastroSubDiagnosesglBleedSupportedByOtherSubAnswerDescribe",
                              type: "text",
                              label: "Describe",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "GI Bleed",
                  value: "gibleed",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 11,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Hepatitis",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_gastroSubDiagnoseshepatitisDescribe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_gastroSubDiagnoseshepatitisSupportedBy",
                      type: "options_tree",
                      label: "   Supported by                ",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Symptoms",
                          value: "symptoms",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Physical findings",
                          value: "physicalfindings",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Lab studies",
                          value: "labstudies",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Jaundice",
                          value: "jaundice",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Abdominal pain",
                          value: "abdominalpain",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Nausea and vomiting",
                          value: "nauseaandvomiting",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 1,
                              name: "ros_gastroSubDiagnoseshepatitisSupportedByOtherSubAnswerDescribe",
                              type: "text",
                              label: "Describe",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_gastroSubDiagnoseshepatitisType",
                      type: "options_tree",
                      label: "   Type   ",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "A",
                          value: "a",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "B",
                          value: "b",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "C",
                          value: "c",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 3,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_gastroSubDiagnoseshepatitisDess",
                      type: "options_tree",
                      label: "  Describe  ",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Acute",
                          value: "acute",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Chronic",
                          value: "chronic",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 4,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_gastroSubDiagnoseshepatitisCirrhosis",
                      type: "options_tree",
                      label: "Cirrhosis",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Yes",
                          value: "yes",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 5,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_gastroSubDiagnoseshepatitisHepatocellularCarcinoma",
                      type: "options_tree",
                      label: "Hepatocellular Carcinoma",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Yes",
                          value: "yes",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 6,
                      editToggle: false,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Hepatitis",
                  value: "hepatitis",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 12,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Inflammatory Bowel Disease",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_gastroSubDiagnosesinflammatoryBowelDiseaseDescribe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupportedBy",
                      type: "options_tree",
                      label: "    Supported  by    ",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Colonoscopy",
                          value: "colonoscopy",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Symptoms",
                          value: "symptoms",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Physical findings",
                          value: "physicalfindings",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Medications",
                          value: "medications",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Abdominal pain",
                          value: "abdominalpain",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Nausea and vomiting",
                          value: "nauseaandvomiting",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 1,
                              name: "ros_gastroSubDiagnosesinflammatoryBowelDiseaseSupportedByOtherSubAnswerDescribe",
                              type: "text",
                              label: "Describe",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_gastroSubDiagnosesinflammatoryBowelDiseaseIBDDescribe",
                      type: "options_tree",
                      label: "  Describe  ",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "UIcerative Colitis",
                          value: "uicerativecolitis",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Crohn's Disease",
                          value: "croh'nsdisease",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 3,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_gastroSubDiagnosesinflammatoryBowelDiseaseSpecificDiet",
                      type: "options_tree",
                      label: "On a specific diet",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Yes",
                          value: "yes",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 4,
                      editToggle: false,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Inflammatory Bowel Disease",
                  value: "inflammatoryboweldisease",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 13,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Pancreatitis",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_gastroSubDiagnosespancreatitisDescribe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_gastroSubDiagnosespancreatitisSupportedBy",
                      type: "options_tree",
                      label: "   Supported  by ",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Symptoms",
                          value: "symptoms",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Lab studies",
                          value: "labstudies",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "ERCP",
                          value: "ercp",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Alcoholism",
                          value: "alcoholism",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Use of certain medications",
                          value: "useofcertainmedications",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "MRI",
                          value: "mri",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Family history",
                          value: "familyhistory",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 8,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Abdominal pain",
                          value: "abdominalpain",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 9,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Nausea and vomiting",
                          value: "nauseaandvomiting",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 10,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 1,
                              name: "ros_gastroSubDiagnosespancreatitisSupportedByOtherSubAnswerDescribe",
                              type: "text",
                              label: "Describe",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_gastroSubDiagnosespancreatitisRecurrentEpisodes",
                      type: "options_tree",
                      label: "Recurrent episodes",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Yes - Chronic",
                          value: "yes",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 3,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_gastroSubDiagnosespancreatitisSpecificDiet",
                      type: "options_tree",
                      label: "on a specific diet   ",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Yes",
                          value: "yes",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 4,
                      editToggle: false,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Pancreatitis",
                  value: "pancreatitis",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 14,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Ulcer Disease",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_gastroSubDiagnosesulcerDiseaseDescribe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_gastroSubDiagnosesulcerDiseaseSupportedBy",
                      type: "options_tree",
                      label: " Supported by                ",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Endoscopic findings",
                          value: "endoscopicfindings",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Culture",
                          value: "culture",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Symptoms",
                          value: "symptoms",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Medications",
                          value: "medications",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Abdominal Pain",
                          value: "abdominalpain",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Nausea and vomiting",
                          value: "nauseaandvomiting",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 1,
                              name: "ros_gastroSubDiagnosesulcerDiseaseSupportedByOtherSubAnswerDescribe",
                              type: "text",
                              label: "Describe",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_gastroSubDiagnosesulcerDisease",
                      type: "options_tree",
                      label: "    Describe  ",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Esophageal",
                          value: "esophageal",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Gastric",
                          value: "gastric",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Duodenal",
                          value: "duodenal",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "ros_gastroSubDiagnosesulcerDiseaseOtherSubAnswerDescribe",
                              type: "text",
                              label: "Describe",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 3,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_gastroSubDiagnosesulcerDiseaseHeliobactria",
                      type: "options_tree",
                      label: "Positive culture for Heliobactria Pylori",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Yes",
                          value: "yes",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 4,
                      editToggle: false,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Ulcer Disease",
                  value: "ulcerdisease",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 15,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Other",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_gastroSubDiagnosesotherDescribe",
                      type: "options_tree",
                      label: "Describe        ",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_gastroSubDiagnosesotherSupportedBy",
                      type: "options_tree",
                      label: "Supported by                  ",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "History",
                          value: "history",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Symptoms",
                          value: "symptoms",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Physical findings",
                          value: "physicalfindings",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Medications",
                          value: "medications",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Test results",
                          value: "testresults",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Image studies",
                          value: "imagestudies",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Biopsy",
                          value: "biopsy",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 8,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "DME",
                          value: "dme",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 9,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Abdominal pain",
                          value: "abdominalpain",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 10,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Nausea and vomiting",
                          value: "nauseaandvomiting",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 11,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 1,
                              name: "ros_gastroSubDiagnosesotherSupportedByOtherSubAnswerDescribe",
                              type: "text",
                              label: "Describe  ",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                    },
                    {
                      id: 1,
                      name: "ros_gastroSubDiagnosesotherOther",
                      type: "text",
                      label: "Other ",
                      editToggle: false,
                      xs: 4,
                      required: "no",
                      placeholder: "",
                      defaultValue: "",
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      subQuestionId: 3,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Other",
                  value: "other",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 1,
              editToggle: false,
            },
          ],
          editToggle: false,
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          isConfigured: true,
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 18,
      editToggle: false,
    },
    {
      id: 10,
      name: "ros_gastrorecommendations",
      label: "Recommendations",
      placeholder: "",
      editToggle: false,
      defaultValue: "",
      type: "checkbox",
      options: [
        ["Take medications as prescribed", "takemedicationsasprescribed"],
        ["Other", "other"],
      ],
      xs: 12,
      required: false,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      questionId: 19,
    },
    {
      id: 6,
      name: "ros_bowelMove",
      type: "options_tree",
      label: "Bowel Movements",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [{}],
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "ros_bowelMoveAbnormSub",
              type: "options_tree",
              label: "If Abnormal",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_bowelMoveAbnormSubConstSubIfConst",
                      type: "options_tree",
                      label: "If Constipation",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Acute",
                          value: "acute",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Chronic",
                          value: "chronic",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Constipation",
                  value: "constipation",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_bowelMoveAbnormSubDiarrSub",
                      type: "options_tree",
                      label: "If Diarrhea",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Acute",
                          value: "acute",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Chronic",
                          value: "chronic",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "ros_bowelMoveAbnormSubDiarrSubIshis",
                      type: "options_tree",
                      label:
                        "If Diarrhea, history of C Difficile (Rrefer to C Difficile in the Infectious Disease Section for further documentation)",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Yes",
                          value: "yes",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Diarrhea",
                  value: "diarrhea",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Bowel Incontinence",
                  value: "bowelIncontinence",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 1,
            },
          ],
          isCommentEnabled: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
        },
      ],
      questionId: 20,
    },
    {
      id: 6,
      name: "ros_abdominal",
      type: "options_tree",
      label: "Abdominal Openings",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "ros_abdominalIsyes",
              type: "options_tree",
              label: "Describe",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Ileostomy",
                  value: "ileostomy",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Colostomy",
                  value: "colostomy",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Urostomy",
                  value: "urostomy",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "PEG",
                  value: "peg",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 5,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Cystostomy",
                  value: "crytostomy",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 1,
            },
          ],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 21,
    },
    {
      id: 6,
      name: "ros_rectal",
      type: "options_tree",
      label: "Rectal Problems",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "ros_rectalIsrecIsyesfem",
              type: "options_tree",
              label: "If Yes (Female)",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Hemorrhoids",
                  value: "hemorrhoids",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Fissure",
                  value: "fissure",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Mass",
                  value: "mass",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 1,
            },
            {
              id: 6,
              name: "ros_rectalIsrecIsyesmal",
              type: "options_tree",
              label:
                "If Yes (Male) - (if BPH is checked refer to BPH in the Genitourinary Section for further documentation. If Prostate mass is checked refer to Cancer of the prostate in the Cancer Section for further documentation)",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Hemorrhoids",
                  value: "hemorrhoids",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Fissure",
                  value: "fissure",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Mass",
                  value: "mass",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "BPH",
                  value: "bph",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 5,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Prostate Mass",
                  value: "prostateMass",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 2,
            },
          ],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 22,
    },
    {
      id: 6,
      name: "ros_lastBowel",
      type: "options_tree",
      label: "Last Bowel Movement",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Today",
          value: "today",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "1-3 days ago",
          value: "oneToThreeDaysAgo",
          type: "toggle_button",
        },
        {
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: ">3 days ago",
          value: "greaterThanThreeDaysAgo",
          type: "toggle_button",
        },
      ],
      questionId: 23,
    },
    {
      id: 9,
      name: "neuroPsych",
      type: "heading",
      label: "NEURO-PSYCH",
      editToggle: false,
      xs: 3,
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 24,
      depth: 1,
    },
    {
      id: 6,
      name: "ros_neuroPsych",
      type: "options_tree",
      label:
        "Neuro / Psych Problems (Stroke, Parkinson's Disease, Seizures Paraplegia, Depression, Other)",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "ros_neuroPsychSubDiag",
              type: "options_tree",
              label: "Diagnoses",
              isMulti: true,
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagAlcoholSubDesc",
                      type: "options_tree",
                      label: "Alcohol Dependence Describe",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagAlcoholSubType",
                      type: "options_tree",
                      label: "Type",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Episodic",
                          value: "episodic",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Continuous",
                          value: "continuous",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Remission",
                          value: "remission",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                    },
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagAlcoholSubSupp",
                      type: "options_tree",
                      label: "Supported by",
                      isMulti: true,
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Drinking history",
                          value: "drinkingHistory",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Hospitalizations",
                          value: "hospitalizations",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Physical findings",
                          value: "physicalFindings",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Lab results",
                          value: "labResults",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "ros_neuroPsychSubDiagAlcoholSubSuppOtherSubDesc",
                              type: "text",
                              label: "Describe",
                              xs: 4,
                              isCommentEnabled: false,
                              subQuestionId: 1,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 3,
                    },
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagAlcoholSubDelirium",
                      type: "options_tree",
                      label: "History of Delirium Tremens",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Yes",
                          value: "yes",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 4,
                    },
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagAlcoholSubPsych",
                      type: "options_tree",
                      label: "History of Psychosis",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Yes",
                          value: "yes",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 5,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Alcohol Dependence",
                  value: "alcoholDependence",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagAmyotropSubDesc",
                      type: "options_tree",
                      label: "Amyotrophic Lateral Sclerosis Describe",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagAmyotropSubSupp",
                      type: "options_tree",
                      label: "   Supported  by   ",
                      isMulti: true,
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Progressive Weakness",
                          value: "progressiveWeekness",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Slurring of speech",
                          value: "slurringOfSpeech",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Abnormal gait",
                          value: "abnormalGait",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Difficulty swallowing",
                          value: "difficultySwallowing",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "EMG",
                          value: "emg",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "ros_neuroPsychSubDiagAmyotropSubSuppOtherSubDesc",
                              type: "text",
                              label: "Describe",
                              xs: 4,
                              isCommentEnabled: false,
                              subQuestionId: 1,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Amyotrophic Lateral Sclerosis",
                  value: "amyotrophicLateral",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagAnxietySubDesc",
                      type: "options_tree",
                      label: "Anxiety Describe",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagAnxietySubType",
                      type: "options_tree",
                      label: "Type",
                      isMulti: true,
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Generalized Anxiety Disorder",
                          value: "generalizedAnxietyDisorder",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Panic Disorder",
                          value: "panicDisorder",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Obsessive-Compulsive Disorder",
                          value: "obsessiveCompulsiveDisorder",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Post traumatic stress disorder",
                          value: "postTraumaticStressDisorder",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Social Phobia",
                          value: "socialPhobia",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "ros_neuroPsychSubDiagAnxietySubTypeOtherSubDesc",
                              type: "text",
                              label: "Describe",
                              xs: 4,
                              isCommentEnabled: false,
                              subQuestionId: 1,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                    },
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagAnxietySubSupp",
                      type: "options_tree",
                      label: "Supported by",
                      isMulti: true,
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Symptoms",
                          value: "symptoms",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "GAD 7",
                          value: "gadSeven",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Antianxiety medication",
                          value: "antianxietyMedication",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "ros_neuroPsychSubDiagAnxietySubSuppOtherSubDesc",
                              type: "text",
                              label: "Describe",
                              xs: 4,
                              isCommentEnabled: false,
                              subQuestionId: 1,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 3,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Anxiety",
                  value: "anxiety",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagBipolarSubDesc",
                      type: "options_tree",
                      label: "Bipolar Disorder Describe",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagBipolarSubType",
                      type: "options_tree",
                      label: "Type ",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Manic Depression",
                          value: "manicDepression",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Bipolar Depression",
                          value: "bipolarDepression",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Mixed",
                          value: "mixed",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Unknown",
                          value: "unknown",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                    },
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagBipolarSubSupp",
                      type: "options_tree",
                      isMulti: true,
                      label: "Supported By",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of Mood swings",
                          value: "historyOfMoodSwings",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Medication",
                          value: "medication",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "ros_neuroPsychSubDiagBipolarSubSuppOtherSubDesc",
                              type: "text",
                              label: "Describe",
                              xs: 4,
                              isCommentEnabled: false,
                              subQuestionId: 1,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 3,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Bipolar Disorder",
                  value: "bipolarDisorder",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 5,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagCerebHemmSubDesc",
                      type: "options_tree",
                      label: "Cerebral Hemorrhage Describe",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagCerebHemmSubSupp",
                      type: "options_tree",
                      label: "Supported by",
                      isMulti: true,
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Hospitalization",
                          value: "hospitalization",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Image study",
                          value: "imageStudy",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_neuroPsychSubDiagCerebHemmSubSuppPhysFindSub",
                              type: "options_tree",
                              label: "Physical findings",
                              isMulti: "true",
                              xs: 12,
                              isCommentEnabled: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "None",
                                  value: "none",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Right arm paralysis",
                                  value: "rightArmParalysis",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Left arm paralysis",
                                  value: "leftArmParalysis",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 4,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Right leg paralysis",
                                  value: "rightLegParalysis",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 5,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Left leg paralysis",
                                  value: "leftLegParalysis",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 6,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Right hemiparesis",
                                  value: "rightHemiparesis",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 7,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Left hemiparesis",
                                  value: "leftHemiparesis",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 8,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Aphasia",
                                  value: "aphasia",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 9,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Apraxia",
                                  value: "apraxia",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 10,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Cranial nerve paralysis",
                                  value: "cranialNerveParalysis",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 11,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Paraplegia",
                                  value: "paraplegia",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 12,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Quadriplegia",
                                  value: "quadriplegia",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 13,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Coma",
                                  value: "coma",
                                  type: "toggle_button",
                                },
                              ],
                              subQuestionId: 2,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Physical findings",
                          value: "physicalFindings",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_neuroPsychSubDiagCerebHemmSubSuppSenseFindSub",
                              type: "options_tree",
                              label: "Sensory findings",
                              isMulti: "true",
                              xs: 12,
                              isCommentEnabled: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "None",
                                  value: "none",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Numbness right arm",
                                  value: "numbnessRightArm",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Numbness right leg",
                                  value: "numbnessRightLeg",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 4,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Numbness left arm",
                                  value: "numbnessLeftArm",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 5,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Numbness left leg",
                                  value: "numbnessLeftLeg",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 6,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Facial numbness",
                                  value: "facialNumbness",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 7,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Paresthesias",
                                  value: "paresthesias",
                                  type: "toggle_button",
                                },
                              ],
                              subQuestionId: 2,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Sensory findings",
                          value: "sensoryFindings",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "ros_neuroPsychSubDiagCerebHemmSubSuppOtherSubDesc",
                              type: "text",
                              label: "Describe",
                              xs: 4,
                              isCommentEnabled: false,
                              subQuestionId: 1,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Cerebral Hemorrhage",
                  value: "cerebralHemorrhage",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 6,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagCerebPalsySubDesc",
                      type: "options_tree",
                      label: "Cerebral Palsy Describe",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagCerebPalsySubSupp",
                      type: "options_tree",
                      label: " Supported by ",
                      isMulti: true,
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Physical exam",
                          value: "physicalExam",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History",
                          value: "history",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: " Laboratory testing ",
                          value: "laboratoryTesting",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "ros_neuroPsychSubDiagCerebPalsySubSuppOtherSubDesc",
                              type: "text",
                              label: "Describe",
                              xs: 4,
                              isCommentEnabled: false,
                              subQuestionId: 1,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Cerebral Palsy",
                  value: "cerebralPalsy",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 7,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagDelusionSubDesc",
                      type: "options_tree",
                      label: "Delusional Disease Describe",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagDelusionSubSupp",
                      type: "options_tree",
                      label: "Supported by                    ",
                      isMulti: true,
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Affect",
                          value: "affect",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Specific symptoms for 6 months or more",
                          value: "specificSymptoms",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Medication",
                          value: "medication",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Hospitalization",
                          value: "hospitalization",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "ros_neuroPsychSubDiagDelusionSubSuppOtherSubDesc",
                              type: "text",
                              label: "Describe",
                              xs: 4,
                              isCommentEnabled: false,
                              subQuestionId: 1,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Delusional Disease",
                  value: "delusionalDisease",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 8,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagDementiaSubDesc",
                      type: "options_tree",
                      label: "Dementia Describe",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagDementiaSubSupp",
                      type: "options_tree",
                      label: "Supported by",
                      isMulti: true,
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Behavioral changes",
                          value: "behavioralChanges",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Mental testing",
                          value: "mentalTesting",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "MRI",
                          value: "mri",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Functional Changes",
                          value: "functionalChanges",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "ros_neuroPsychSubDiagDementiaSubSuppOtherSubDesc",
                              type: "text",
                              label: "Describe",
                              xs: 4,
                              isCommentEnabled: false,
                              subQuestionId: 1,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                    },
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagDementiaSubType",
                      type: "options_tree",
                      label: "Type of Dementia",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_neuroPsychSubDiagDementiaSubTypeVascSubType",
                              type: "options_tree",
                              label: "Supported by",
                              xs: 12,
                              isCommentEnabled: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "History of Strokes",
                                  value: "historyOfStrokes",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label:
                                    "Risk factors (Atrial Fibrillation, Diabetes, Hypertension, Hypercholesterolemia, Smoking)",
                                  value: "riskFactors",
                                  type: "toggle_button",
                                },
                              ],
                              subQuestionId: 3,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Vascular",
                          value: "vascular",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Alzheimer's disease",
                          value: "alzheimersDisease",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Etiology Unknown",
                          value: "etiologyUnknown",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 3,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Dementia",
                  value: "dementia",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 9,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagDepressSubDesc",
                      type: "options_tree",
                      label: "Depression Describe",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagDepressSubSupp",
                      type: "options_tree",
                      label: "Supported by   ",
                      isMulti: true,
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Symptoms",
                          value: "symptoms",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "PHQ 2/9",
                          value: "phqTwoByNine",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Use of antidepressant medication",
                          value: "antidepressantMedication",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "ros_neuroPsychSubDiagDepressSubSuppOtherSubDesc",
                              type: "text",
                              label: "Describe",
                              xs: 4,
                              isCommentEnabled: false,
                              subQuestionId: 1,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                    },
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagDepressSubMajor",
                      type: "options_tree",
                      label: "Major",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_neuroPsychSubDiagDepressSubMajorSub",
                              type: "options_tree",
                              label: "Major - Supported by",
                              xs: 12,
                              isCommentEnabled: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "PHQ 9",
                                  value: "phqnine",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Hospitalization",
                                  value: "hospitalization",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label:
                                    " Chronic use of antidepressant medication beyond 6 months ",
                                  value: "chronicUseOfAntipressant",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 4,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Use Of ECT",
                                  value: "useOfEct",
                                  type: "toggle_button",
                                },
                              ],
                              subQuestionId: 1,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Yes",
                          value: "yes",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Unknown",
                          value: "unknown",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 3,
                    },
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagDepressSubEpisode",
                      type: "options_tree",
                      label: " Episodes   ",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Single",
                          value: "single",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Recurrent",
                          value: "recurrent",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "In Remission",
                          value: "inRemission",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Unknown",
                          value: "unknown",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 4,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Depression",
                  value: "depression",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 10,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagDrugsSubDesc",
                      type: "options_tree",
                      label: "Drug Dependence Describe",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagDrugsSubType",
                      type: "options_tree",
                      label: "Type",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Episodic",
                          value: "episodic",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Continuous",
                          value: "continuous",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Remission",
                          value: "remission",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                    },
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagDrugsSubSupp",
                      type: "options_tree",
                      label: "Supported by",
                      isMulti: true,
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Use of recreational drugs",
                          value: "recreationalDrugs",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Chronic use of pain medication",
                          value: "chronicUseOfPainMedication",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of hospitalization",
                          value: "historyOfHospitalization",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History outpatient treatment",
                          value: "historyOutpatientTreatment",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Withdrawal symptoms",
                          value: "withdrawalSymptoms",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Abnormal effect",
                          value: "abnormalAffect",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "ros_neuroPsychSubDiagDrugsSubSuppOtherSubDesc",
                              type: "text",
                              label: "Describe",
                              xs: 4,
                              isCommentEnabled: false,
                              subQuestionId: 1,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 3,
                    },
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagDrugsSubHistoryPsych",
                      type: "options_tree",
                      label: "History of Psychosis",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Yes",
                          value: "yes",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 4,
                    },
                    {
                      id: 1,
                      name: "ros_neuroPsychSubDiagDrugsSubWhatDrugs",
                      type: "text",
                      label: "Which drugs",
                      xs: 4,
                      isCommentEnabled: false,
                      subQuestionId: 5,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Drug Dependence",
                  value: "drugDependence",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 11,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagFibroSubDesc",
                      type: "options_tree",
                      label: "Fibromyalgia Describe",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagFibroSubSupp",
                      type: "options_tree",
                      label: " Supported by               ",
                      isMulti: true,
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Symptoms",
                          value: "symptoms",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Physical findings",
                          value: "physicalFindings",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History",
                          value: "history",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Medications",
                          value: "medications",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "ros_neuroPsychSubDiagFibroSubSuppOtherSubDesc",
                              type: "text",
                              label: "Describe",
                              xs: 4,
                              isCommentEnabled: false,
                              subQuestionId: 1,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Fibromyalgia",
                  value: "fibromyalgia",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 12,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagGuillSubDesc",
                      type: "options_tree",
                      label: "Guillain-Barre Disease Describe",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagGuillSubSupp",
                      type: "options_tree",
                      label: "Supported by                     ",
                      isMulti: true,
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Physical findings",
                          value: "physicalFindings",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "EMG/Nerve Conduction studies",
                          value: "emg",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Hospitalization",
                          value: "hospitalization",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "ros_neuroPsychSubDiagGuillSubSuppOtherSubDesc",
                              type: "text",
                              label: "Describe",
                              xs: 4,
                              isCommentEnabled: false,
                              subQuestionId: 1,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Guillain-Barre Disease",
                  value: "guillainBarreDisease",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 13,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagHemiSubDesc",
                      type: "options_tree",
                      label: "Hemiparesis Describe",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagHemiSubHemiDesc",
                      type: "options_tree",
                      label: "Describe",
                      isMulti: true,
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Left sided",
                          value: "leftSided",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Right sided",
                          value: "rightSided",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                    },
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagHemiSubSupp",
                      type: "options_tree",
                      label: "Supported by",
                      isMulti: true,
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Physical findings",
                          value: "physicalFindings",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History",
                          value: "history",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "ros_neuroPsychSubDiagHemiSubSuppOtherSubDesc",
                              type: "text",
                              label: "Describe",
                              xs: 4,
                              isCommentEnabled: false,
                              subQuestionId: 1,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 3,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Hemiparesis",
                  value: "hemiparesis",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 14,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagHuntingSubDesc",
                      type: "options_tree",
                      label: "Huntington's Chorea Describe",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagHuntingSubSupp",
                      type: "options_tree",
                      label: "   Supported by                 ",
                      isMulti: true,
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Family history",
                          value: "familyHistory",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Chorea Movement",
                          value: "choreaMovement",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Physical findings",
                          value: "physicalFindings",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "ros_neuroPsychSubDiagHuntingSubSuppOtherSubDesc",
                              type: "text",
                              label: "Describe",
                              xs: 4,
                              isCommentEnabled: false,
                              subQuestionId: 1,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Huntington's Chorea",
                  value: "huntingtonsChorea",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 15,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagInsomniaSubDesc",
                      type: "options_tree",
                      label: "Insomnia Describe",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagInsomniaSubSupp",
                      type: "options_tree",
                      label: "Supported by",
                      isMulti: true,
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Medications",
                          value: "medication",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Symptoms",
                          value: "symptoms",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History",
                          value: "history",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "ros_neuroPsychSubDiagInsomniaSubSuppOtherSubDesc",
                              type: "text",
                              label: "Describe",
                              xs: 4,
                              isCommentEnabled: false,
                              subQuestionId: 1,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Insomnia",
                  value: "insomnia",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 16,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagIntelSubDesc",
                      type: "options_tree",
                      label:
                        "Intellectual and or Developmental Disability Describe",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagIntelSubSupp",
                      type: "options_tree",
                      label: "Supported by",
                      isMulti: true,
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History",
                          value: "history",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Symptoms",
                          value: "symptoms",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Physical findings",
                          value: "physicalFindings",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Medications",
                          value: "medications",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Test results",
                          value: "testResults",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Image studies",
                          value: "imageStudies",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Biopsy",
                          value: "biopsy",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 8,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "DME",
                          value: "dme",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 9,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "ros_neuroPsychSubDiagIntelSubSuppOtherSubDesc",
                              type: "text",
                              label: "Describe",
                              xs: 4,
                              isCommentEnabled: false,
                              subQuestionId: 1,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                    },
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagIntelSubIntelDesc",
                      type: "options_tree",
                      label: "Describe",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Down's Syndrome",
                          value: "downsSyndrome",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Psychomotor Retardation",
                          value: "phychomotorRetardation",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "ros_neuroPsychSubDiagIntelSubIntelDescOtherSubDesc",
                              type: "text",
                              label: "Describe",
                              xs: 4,
                              isCommentEnabled: false,
                              subQuestionId: 1,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 3,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Intellectual and or Developmental Disability",
                  value: "developmentalDisability",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 17,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagMigraineSubDesc",
                      type: "options_tree",
                      label: "Migraine Headaches Describe",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagMigraineSubSupp",
                      type: "options_tree",
                      label: "Supported by",
                      isMulti: true,
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History",
                          value: "history",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Symptoms",
                          value: "symptoms",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Medications",
                          value: "medication",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "ros_neuroPsychSubDiagMigraineSubSuppOtherSubDesc",
                              type: "text",
                              label: "Describe",
                              xs: 4,
                              isCommentEnabled: false,
                              subQuestionId: 1,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Migraine Headaches",
                  value: "migraineHeadaches",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 18,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagSclerosisSubDesc",
                      type: "options_tree",
                      label: "Multiple Sclerosis Describe",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagSclerosisSubSupp",
                      type: "options_tree",
                      label: "Supported by",
                      isMulti: true,
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Physical findings",
                          value: "physicalFindings",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Laboratory testing",
                          value: "laboratoryTesting",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Medications",
                          value: "medications",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Bowel or bladder dysfunction",
                          value: "bowel",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Hospitalization",
                          value: "hospitalization",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "ros_neuroPsychSubDiagSclerosisSubSuppOtherSubDesc",
                              type: "text",
                              label: "Describe",
                              xs: 4,
                              isCommentEnabled: false,
                              subQuestionId: 1,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Multiple Sclerosis",
                  value: "multipleSclerosis",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 19,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagDystrophySubDesc",
                      type: "options_tree",
                      label: "Muscular Dystrophy Describe",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagDystrophySubSupp",
                      type: "options_tree",
                      label: "Supported by",
                      isMulti: true,
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Physical findings",
                          value: "physicalFindings",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "EMG's",
                          value: "emgs",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of progressive muscle weakness",
                          value: "historyOfProgressiveMuscle",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Family history",
                          value: "familyHistory",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "ros_neuroPsychSubDiagDystrophySubSuppOtherSubDesc",
                              type: "text",
                              label: "Describe",
                              xs: 4,
                              isCommentEnabled: false,
                              subQuestionId: 1,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Muscular Dystrophy",
                  value: "muscularDystrophy",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 20,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagGravisSubDesc",
                      type: "options_tree",
                      label: "Myasthenia Gravis Describe",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagGravisSubSupp",
                      type: "options_tree",
                      label: "Supported by",
                      isMulti: true,
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Ptosis",
                          value: "ptosis",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Double vision",
                          value: "doubleVision",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Difficulty chewing",
                          value: "difficultyChewing",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Difficulty swallowing",
                          value: "difficultySwallowing",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Tensilon test",
                          value: "tensilonTest",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Medication",
                          value: "medication",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "ros_neuroPsychSubDiagGravisSubSuppOtherSubDesc",
                              type: "text",
                              label: "Describe",
                              xs: 4,
                              isCommentEnabled: false,
                              subQuestionId: 1,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Myasthenia Gravis",
                  value: "myastheniaGravis",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 21,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagParkinsonSubDesc",
                      type: "options_tree",
                      label: "Parkinson's Disease Describe",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagParkinsonSubSupp",
                      type: "options_tree",
                      label: "Supported by",
                      isMulti: true,
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Physical findings",
                          value: "physicalFindings",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Gait",
                          value: "gait",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Dementia",
                          value: "dementia",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Affect",
                          value: "affect",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "ros_neuroPsychSubDiagParkinsonSubSuppOtherSubDesc",
                              type: "text",
                              label: "Describe",
                              xs: 4,
                              isCommentEnabled: false,
                              subQuestionId: 1,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Parkinson's Disease",
                  value: "parkinsonsDisease",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 22,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagPeriNeuroSubDesc",
                      type: "options_tree",
                      label: "Peripheral Neuropathy Describe",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagPeriNeuroSubSupp",
                      type: "options_tree",
                      label: "Supported by",
                      isMulti: true,
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Physical findings",
                          value: "physicalFindings",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "EMG/Nerve conduction studies",
                          value: "emg",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Biopsy",
                          value: "biopsy",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "ros_neuroPsychSubDiagPeriNeuroSubSuppOtherSubDesc",
                              type: "text",
                              label: "Describe",
                              xs: 4,
                              isCommentEnabled: false,
                              subQuestionId: 1,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                    },
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagPeriNeuroSubDiabetes",
                      type: "options_tree",
                      label: "Secondary to Diabetes",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Yes",
                          value: "yes",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 3,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Peripheral Neuropathy",
                  value: "peripheralNeuropathy",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 23,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagRestlessLegSubDesc",
                      type: "options_tree",
                      label: "Restless leg syndrome Describe",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagRestlessLegSubSupp",
                      type: "options_tree",
                      label: "Supported by",
                      isMulti: true,
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Symptoms",
                          value: "symptoms",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Medication",
                          value: "medication",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History",
                          value: "history",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "ros_neuroPsychSubDiagRestlessLegSubSuppOtherSubDesc",
                              type: "text",
                              label: "Describe",
                              xs: 4,
                              isCommentEnabled: false,
                              subQuestionId: 1,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Restless leg syndrome",
                  value: "restlessLegSyndrome",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 24,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagSchizoSubDesc",
                      type: "options_tree",
                      label: "Schizophrenia Describe",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagSchizoSubSupp",
                      type: "options_tree",
                      label: "Supported by                       ",
                      isMulti: true,
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Affect",
                          value: "affect",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Specific symptoms for 6 months or more",
                          value: "specificSymptomsForSixMonths",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Medication",
                          value: "medication",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Hospitalization",
                          value: "hospitalization",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Psychosis",
                          value: "psychosis",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "ros_neuroPsychSubDiagSchizoSubSuppOtherSubDesc",
                              type: "text",
                              label: "Describe",
                              xs: 4,
                              isCommentEnabled: false,
                              subQuestionId: 1,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Schizophrenia",
                  value: "schizophrenia",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 25,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagSeizureSubDesc",
                      type: "options_tree",
                      label: "Seizures Describe",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagSeizureSubType",
                      type: "options_tree",
                      label: "Type",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Seizure Disorder",
                          value: "seizureDisorder",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Seizure Unspecified",
                          value: "seizureUnspecified",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                    },
                    {
                      id: 1,
                      name: "ros_neuroPsychSubDiagSeizureSubTreatment",
                      type: "text",
                      label: "Treatment",
                      xs: 4,
                      isCommentEnabled: false,
                      subQuestionId: 3,
                    },
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagSeizureSubSupp",
                      type: "options_tree",
                      label: "Supported by                        ",
                      isMulti: true,
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of recurrent seizures",
                          value: "historyOfRecurrentSeizures",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Medications",
                          value: "medications",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Laboratory testing",
                          value: "laboratoryTesting",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "ros_neuroPsychSubDiagSeizureSubSuppOtherSubDesc",
                              type: "text",
                              label: "Describe",
                              xs: 4,
                              isCommentEnabled: false,
                              subQuestionId: 1,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 4,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Seizures",
                  value: "seizures",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 26,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagSpinalCordSubDesc",
                      type: "options_tree",
                      label: "Spinal Cord Injury Describe",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Active (within  8 months)",
                          value: "active",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagSpinalCordSubSupp",
                      type: "options_tree",
                      label: "Supported by",
                      isMulti: true,
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_neuroPsychSubDiagSpinalCordSubSuppParesisSub",
                              type: "options_tree",
                              label: "Paresis or paralysis - Describe",
                              isMulti: true,
                              xs: 12,
                              isCommentEnabled: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "None",
                                  value: "none",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Paraplegia",
                                  value: "paraplegia",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Quadriplegia",
                                  value: "quadriplegia",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 4,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Anterior Cord Syndrome",
                                  value: "anteriorCordSyndrome",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 5,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Posterior Cord Syndrome",
                                  value: "posteriorCordSyndrome",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 6,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Central Cord Syndrome",
                                  value: "centralCordSyndrome",
                                  type: "toggle_button",
                                },
                              ],
                              subQuestionId: 1,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Paresis or paralysis",
                          value: "paresis",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_neuroPsychSubDiagSpinalCordSubSuppLossSenseSub",
                              type: "options_tree",
                              label: "Loss of sensation - Describe",
                              isMulti: true,
                              xs: 12,
                              isCommentEnabled: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "None",
                                  value: "none",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Paraplegia",
                                  value: "paraplegia",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Quadriplegia",
                                  value: "quadriplegia",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 4,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Anterior Cord Syndrome",
                                  value: "anteriorCordSyndrome",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 5,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Posterior Cord Syndrome",
                                  value: "posteriorCordSyndrome",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 6,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Central Cord Syndrome",
                                  value: "centralCordSyndrome",
                                  type: "toggle_button",
                                },
                              ],
                              subQuestionId: 1,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Loss of sensation",
                          value: "lossOfSensation",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Bowel or bladder dysfunction",
                          value: "bowel",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Hospitalization",
                          value: "hospitalization",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "ros_neuroPsychSubDiagSpinalCordSubSuppOtherSubDesc",
                              type: "text",
                              label: "Describe",
                              xs: 4,
                              isCommentEnabled: false,
                              subQuestionId: 1,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                    },
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagSpinalCordSubSecTo",
                      type: "options_tree",
                      label: "Secondary to",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Fracture",
                          value: "fracture",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Dislocation",
                          value: "dislocation",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Compressive Lesion",
                          value: "compressiveLesion",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 3,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Spinal Cord Injury",
                  value: "spinalCordInjury",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 27,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagStrokeSubDesc",
                      type: "options_tree",
                      label: "Stroke Describe",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagStrokeSubSupp",
                      type: "options_tree",
                      label: "Secondary to",
                      isMulti: true,
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Hospitalization",
                          value: "hospitalization",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Image Study",
                          value: "imageStudy",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_neuroPsychSubDiagStrokeSubSuppPhysFindSub",
                              type: "options_tree",
                              label: "Physical findings",
                              isMulti: true,
                              xs: 12,
                              isCommentEnabled: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "None",
                                  value: "none",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Right arm paralysis",
                                  value: "rightArmParalysis",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Left arm paralysis",
                                  value: "leftArmParalysis",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 4,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Right leg paralysis",
                                  value: "rightLegParalysis",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 5,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Left leg paralysis",
                                  value: "leftLegParalysis",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 6,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Right hemiparesis",
                                  value: "rightHemiparesis",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 7,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Left hemiparesis",
                                  value: "leftHemiparesis",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 8,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Aphasia",
                                  value: "aphasia",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 9,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Apraxia",
                                  value: "apraxia",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 10,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Cranial nerve paralysis",
                                  value: "cranialNerveParalysis",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 11,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Functional Quadriplegia",
                                  value: "functionalQuadriplegia",
                                  type: "toggle_button",
                                },
                              ],
                              subQuestionId: 2,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Physical findings",
                          value: "physicalFindings",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_neuroPsychSubDiagStrokeSubSuppSenseFindSub",
                              type: "options_tree",
                              label: "Sensory Findings",
                              isMulti: true,
                              xs: 12,
                              isCommentEnabled: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "None",
                                  value: "none",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Numbness right arm",
                                  value: "numbnessRightArm",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Numbness right leg",
                                  value: "numbnessRightLeg",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 4,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Numbness left arm",
                                  value: "numbnessLeftArm",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 5,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Numbness left leg",
                                  value: "numbnessLeftLeg",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 6,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Facial numbness",
                                  value: "facialNumbness",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 7,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Paresthesias",
                                  value: "paresthesias",
                                  type: "toggle_button",
                                },
                              ],
                              subQuestionId: 2,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Sensory findings",
                          value: "sensoryFindings",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "ros_neuroPsychSubDiagStrokeSubSuppOtherSubDesc",
                              type: "text",
                              label: "Describe",
                              xs: 4,
                              isCommentEnabled: false,
                              subQuestionId: 1,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Stroke",
                  value: "stroke",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 28,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagSubduralSubDesc",
                      type: "options_tree",
                      label: "Subdural Hematoma Describe",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagSubduralSubSupp",
                      type: "options_tree",
                      label: "Supported by",
                      isMulti: true,
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Hospitalization",
                          value: "hospitalization",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Image study",
                          value: "imageStudy",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_neuroPsychSubDiagSubduralSubSuppPhysFindSub",
                              type: "options_tree",
                              label: "Physical findings",
                              isMulti: true,
                              xs: 12,
                              isCommentEnabled: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "None",
                                  value: "none",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Right arm paralysis",
                                  value: "rightArmParalysis",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Left arm paralysis",
                                  value: "leftArmParalysis",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 4,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Right leg paralysis",
                                  value: "rightLegParalysis",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 5,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Left leg paralysis",
                                  value: "leftLegParalysis",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 6,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Right hemiparesis",
                                  value: "rightHemiparesis",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 7,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Left hemiparesis",
                                  value: "leftHemiparesis",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 8,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Aphasia",
                                  value: "aphasia",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 9,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Apraxia",
                                  value: "apraxia",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 10,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Cranial nerve paralysis",
                                  value: "cranialNerveParalysis",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 11,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Paraplegia",
                                  value: "paraplegia",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 11,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Quadriplegia",
                                  value: "quadriplegia",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 11,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Coma",
                                  value: "coma",
                                  type: "toggle_button",
                                },
                              ],
                              subQuestionId: 2,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Physical findings",
                          value: "physicalFindings",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_neuroPsychSubDiagSubduralSubSuppSenseFindSub",
                              type: "options_tree",
                              label: "Sensory findings",
                              isMulti: true,
                              xs: 12,
                              isCommentEnabled: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "None",
                                  value: "none",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Numbness Right Arm",
                                  value: "numbnessRightArm",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Numbness right leg",
                                  value: "numbnessRightLeg",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 4,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Numbness left arm",
                                  value: "numbnessLeftArm",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 5,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Numbness left leg",
                                  value: "numbnessLeftLeg",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 6,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Facial numbness",
                                  value: "facialNumbness",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 7,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Paresthesias",
                                  value: "paresthesias",
                                  type: "toggle_button",
                                },
                              ],
                              subQuestionId: 2,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Sensory findings",
                          value: "sensoryFindings",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "ros_neuroPsychSubDiagSubduralSubSuppOtherSubDesc",
                              type: "text",
                              label: "Describe",
                              xs: 4,
                              isCommentEnabled: false,
                              subQuestionId: 1,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Subdural Hematoma",
                  value: "subduralHematoma",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 29,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagTiaSubDesc",
                      type: "options_tree",
                      label: "TIA Describe",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagTiaSubSupp",
                      type: "options_tree",
                      label: "Supported by",
                      isMulti: true,
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History",
                          value: "history",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Physical exam",
                          value: "physicalExam",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Image studies",
                          value: "imageStudies",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "ros_neuroPsychSubDiagTiaSubSuppOtherSubDesc",
                              type: "text",
                              label: "Describe",
                              xs: 4,
                              isCommentEnabled: false,
                              subQuestionId: 1,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "TIA",
                  value: "tia",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 30,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagTraumaBrainSubDesc",
                      type: "options_tree",
                      label: "Traumatic Brain Injury Describe",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagTraumaBrainSubSupp",
                      type: "options_tree",
                      label: "Supported by",
                      isMulti: true,
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Hospitalization",
                          value: "hospitalization",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Image studies",
                          value: "imageStudy",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_neuroPsychSubDiagTraumaBrainSubSuppPhysFindSub",
                              type: "options_tree",
                              label: "Physical Findings",
                              isMulti: true,
                              xs: 12,
                              isCommentEnabled: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "None",
                                  value: "none",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Coma",
                                  value: "coma",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Quadriplegia",
                                  value: "quadriplegia",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 4,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Paraplegia",
                                  value: "paraplegia",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 5,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Hemiplegia",
                                  value: "hemiplegia",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 6,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Monoplegia",
                                  value: "monoplegia",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 5,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "PT/OT",
                                  value: "pt",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 6,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "DME",
                                  value: "dme",
                                  type: "toggle_button",
                                },
                              ],
                              subQuestionId: 2,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Physical findings",
                          value: "physicalFindings",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "ros_neuroPsychSubDiagTraumaBrainSubSuppOtherSubDesc",
                              type: "text",
                              label: "Describe",
                              xs: 4,
                              isCommentEnabled: false,
                              subQuestionId: 1,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Traumatic Brain Injury",
                  value: "traumaticBrainInjury",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 31,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagOtherSubDesc",
                      type: "options_tree",
                      label: "Other Describe",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagOtherSubSupp",
                      type: "options_tree",
                      label: "Supported by",
                      isMulti: true,
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History",
                          value: "history",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Symptoms",
                          value: "symptoms",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Physical findings",
                          value: "physicalFindings",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Medications",
                          value: "medications",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Test results",
                          value: "testResults",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Image studies",
                          value: "imageStudies",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Biopsy",
                          value: "biopsy",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 8,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "DME",
                          value: "dme",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 9,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "ros_neuroPsychSubDiagOtherSubSuppOtherSubDesc",
                              type: "text",
                              label: "Describe",
                              xs: 4,
                              isCommentEnabled: false,
                              subQuestionId: 1,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                    },
                    {
                      id: 1,
                      name: "ros_neuroPsychSubDiagOtherSubOther",
                      type: "text",
                      label: "Other",
                      xs: 4,
                      isCommentEnabled: false,
                      subQuestionId: 3,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Other",
                  value: "other",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 1,
            },
          ],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 25,
    },
    {
      id: 6,
      name: "ros_nervousAnxious",
      type: "options_tree",
      label:
        "Are you nervous, anxious, feel on the edge or often feel stressed?",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 1,
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          isConfigured: true,
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 26,
      editToggle: false,
    },
    {
      id: 6,
      name: "ros_worryMuch",
      type: "options_tree",
      label: "Do you worry too much about different things?",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 1,
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          isConfigured: true,
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 27,
      editToggle: false,
    },
    {
      id: 6,
      name: "ros_feelAfraid",
      type: "options_tree",
      label: "Do you feel afraid that something bad might happen?",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 1,
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          isConfigured: true,
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 28,
      editToggle: false,
    },
    {
      id: 6,
      name: "ros_neuroPsychHistoryAudio",
      type: "options_tree",
      label: "History of auditory hallucinations",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 1,
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          isConfigured: true,
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 29,
      editToggle: false,
    },
    {
      id: 6,
      name: "ros_neuroPsychHistoryVisual",
      type: "options_tree",
      label: "History of visual hallucinations",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 1,
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          isConfigured: true,
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 30,
      editToggle: false,
    },
    {
      id: 6,
      name: "ros_neuroPsychBehaviour",
      type: "options_tree",
      label: "History of psychotic behavior",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 1,
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          isConfigured: true,
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 31,
      editToggle: false,
    },
    {
      id: 6,
      name: "ros_neuroPsychDelirium",
      type: "options_tree",
      label: "History of episodes of delirium",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 1,
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          isConfigured: true,
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 32,
      editToggle: false,
    },
    {
      id: 6,
      name: "ros_neuroPsychHearVoices",
      type: "options_tree",
      label: "Do you hear voices or see things that other people do not",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 1,
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          isConfigured: true,
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 33,
      editToggle: false,
    },
    {
      id: 6,
      name: "ros_neuroPsychHighLows",
      type: "options_tree",
      label: "Do you have highs and lows",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 1,
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          isConfigured: true,
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 34,
      editToggle: false,
    },
    {
      id: 6,
      name: "ros_neuroPsychEverFeel",
      type: "options_tree",
      label: "Do you ever feel like someone is out to get you",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 1,
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          isConfigured: true,
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 35,
      editToggle: false,
    },
    {
      id: 6,
      name: "ros_meetWith",
      type: "options_tree",
      label: "How often do you go out to meet with family or friends",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Often",
          value: "often",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Sometimes",
          value: "sometimes",
          type: "toggle_button",
        },
        {
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Never",
          value: "never",
          type: "toggle_button",
        },
      ],
      questionId: 36,
    },
    {
      id: 1,
      name: "ros_gpcogScoIsgpcogs",
      type: "text",
      label: "GPCOG Score",
      xs: 4,
      isCommentEnabled: false,
      questionId: 37,
    },
    {
      id: 1,
      name: "ros_gpcogScoIsmmsesc",
      type: "text",
      label: "or MMSE Score",
      xs: 4,
      isCommentEnabled: false,
      questionId: 38,
    },
    {
      id: 6,
      name: "if_gpcog_or_mmse_is_not_done,is",
      type: "label",
      label: "If GPCOG or MMSE is not done,is",
      xs: 12,
      isCommentEnabled: false,
      hasSubQuestions: false,
      subQuestions: [],
      questionId: 39,
    },
    {
      id: 6,
      name: "ros_noGpcogMmsePatientOrientPerson",
      type: "options_tree",
      label: "Patient oriented to person",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 40,
    },
    {
      id: 6,
      name: "ros_noGpcogMmsePatientOrientPlace",
      type: "options_tree",
      label: "Patient oriented to place",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 41,
    },
    {
      id: 6,
      name: "ros_noGpcogMmsePatientOrientTime",
      type: "options_tree",
      label: "Patient oriented to time",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 42,
    },
    {
      id: 6,
      name: "ros_noGpcogMmseRecall",
      type: "options_tree",
      label: "Recall",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Good",
          value: "good",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Poor",
          value: "poor",
          type: "toggle_button",
        },
      ],
      questionId: 43,
    },
    {
      id: 6,
      name: "ros_noGpcogMmsePatientDescribeNews",
      type: "options_tree",
      label: "Patient describes recent news event",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Partially",
          value: "partially",
          type: "toggle_button",
        },
        {
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 44,
    },
    {
      id: 6,
      name: "ros_affect",
      type: "options_tree",
      label: "Affect",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 1,
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          isConfigured: true,
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              label: "If abnormal,",
              value: "ros_affectAbnormalSub",
              type: "options_tree",
              xs: 12,
              subQuestions: [
                {
                  depth: 1,
                  subQuestionId: 1,
                  label: "Paranoia",
                  type: "toggle_button",
                  isConfigured: true,
                  isModified: false,
                  value: "paranoia",
                  hasSubQuestions: false,
                  subQuestions: [
                    {
                      editToggle: false,
                    },
                  ],
                },
                {
                  depth: 1,
                  subQuestionId: 2,
                  label: "Delusional",
                  type: "toggle_button",
                  isConfigured: true,
                  isModified: false,
                  value: "delusional",
                  hasSubQuestions: false,
                  subQuestions: [
                    {
                      editToggle: false,
                    },
                  ],
                },
                {
                  depth: 1,
                  subQuestionId: 3,
                  label: "Disorganised Thought",
                  type: "toggle_button",
                  isConfigured: true,
                  isModified: false,
                  value: "disorganizedThought",
                  hasSubQuestions: false,
                  subQuestions: [
                    {
                      editToggle: false,
                    },
                  ],
                },
                {
                  depth: 1,
                  subQuestionId: 4,
                  label: "Flat",
                  type: "toggle_button",
                  isConfigured: true,
                  isModified: false,
                  value: "flat",
                  hasSubQuestions: false,
                  subQuestions: [
                    {
                      editToggle: false,
                    },
                  ],
                },
                {
                  depth: 1,
                  subQuestionId: 5,
                  label: "Maniac",
                  type: "toggle_button",
                  isConfigured: true,
                  isModified: false,
                  value: "manic",
                  hasSubQuestions: false,
                  subQuestions: [
                    {
                      editToggle: false,
                    },
                  ],
                },
                {
                  depth: 1,
                  subQuestionId: 6,
                  label: "Depressed",
                  type: "toggle_button",
                  isConfigured: true,
                  isModified: false,
                  value: "depressed",
                  hasSubQuestions: false,
                  subQuestions: [
                    {
                      editToggle: false,
                    },
                  ],
                },
                {
                  depth: 1,
                  subQuestionId: 7,
                  label: "Other",
                  type: "toggle_button",
                  isConfigured: true,
                  isModified: false,
                  value: "other",
                  hasSubQuestions: false,
                  subQuestions: [
                    {
                      editToggle: false,
                    },
                  ],
                },
              ],
              isCommentEnabled: true,
              isConfigured: true,
              isModified: false,
              fullWidth: true,
              required: true,
              name: "ros_affectAbnormalSubDesc",
            },
          ],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
        },
      ],
      questionId: 45,
      editToggle: false,
    },
    {
      id: 7,
      name: "over_the_past_2_weeks,_how_often_have_you_been_bothered_by_any_of_the_following_problems?",
      type: "label",
      label:
        "Over the past 2 weeks, how often have you been bothered by any of the following problems?",
      editToggle: false,
      xs: 12,
      required: "no",
      placeholder: "",
      defaultValue: "",
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 46,
    },
    {
      id: 6,
      name: "ros_over2WeeksLittleIntrst",
      type: "options_tree",
      label: "Little interest or pleasure in doing things",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 1,
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          label: "Not at all",
          value: "notAtAll",
          isConfigured: true,
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Several Days",
          value: "severalDays",
          type: "toggle_button",
        },
        {
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "More than half the days",
          value: "moreThanHalfTheDays",
          type: "toggle_button",
        },
        {
          subQuestionId: 4,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Nearly every day",
          value: "nearlyEveryDay",
          type: "toggle_button",
        },
      ],
      questionId: 47,
      editToggle: false,
    },
    {
      id: 6,
      name: "ros_over2WeeksFeelDepressed",
      type: "options_tree",
      label: "Feeling down, depressed or hopeless",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 1,
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          label: "Not at all",
          value: "notAtAll",
          isConfigured: true,
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Several Days",
          value: "severalDays",
          type: "toggle_button",
        },
        {
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "More than half the days",
          value: "moreThanHalfTheDays",
          type: "toggle_button",
        },
        {
          subQuestionId: 4,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Nearly every day",
          value: "nearlyEveryDay",
          type: "toggle_button",
        },
      ],
      questionId: 48,
      editToggle: false,
    },
    {
      id: 6,
      name: "ros_over2WeeksPhq",
      type: "options_tree",
      label: "PHQ 2 Score",
      fullWidth: true,
      required: true,
      disabled: true,
      xs: 12,
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 1,
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          label: "<3",
          value: "lessThanThree",
          isConfigured: true,
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 9,
              name: "phq9Heading",
              type: "heading",
              label: "Depression Screening PHQ9",
              editToggle: false,
              xs: 3,
              isCommentEnabled: false,
              isConfigured: true,
              isModified: false,
              questionId: 201,
              depth: 1,
            },
            {
              id: 6,
              name: "ros_over2WeeksPhq2MoreThreeSubDepScreenHaveLittleIntrst",
              type: "options_tree",
              label: "Having little interest or pleasure in doing things?",
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 1,
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  label: "Not at all",
                  value: "notAtAll",
                  isConfigured: true,
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Several Days",
                  value: "severalDays",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "More than half the days",
                  value: "moreThanHalfTheDays",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Nearly Every day",
                  value: "nearlyEveryDay",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 1,
              editToggle: false,
            },
            {
              id: 6,
              name: "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelDepress",
              type: "options_tree",
              label: "Feeling down, depressed or hopeless at times?",
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 1,
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  label: "Not at all",
                  value: "notAtAll",
                  isConfigured: true,
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Several Days",
                  value: "severalDays",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "More than half the days",
                  value: "moreThanHalfTheDays",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Nearly Every Day",
                  value: "nearlyEveryDay",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 2,
              editToggle: false,
            },
            {
              id: 6,
              name: "ros_over2WeeksPhq2MoreThreeSubDepScreenTrouFall",
              type: "options_tree",
              label:
                "Do you have trouble falling or staying asleep, sleeping too much?",
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 1,
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  label: "Not at all",
                  value: "notAtAll",
                  isConfigured: true,
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Several Days",
                  value: "severalDays",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "More than half the days",
                  value: "moreThanHalfTheDays",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Nearly Every day",
                  value: "nearlyEveryDay",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 3,
              editToggle: false,
            },
            {
              id: 6,
              name: "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelTired",
              type: "options_tree",
              label: "Do you feeling tired or having little energy?",
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 1,
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  label: "Not at all",
                  value: "notAtAll",
                  isConfigured: true,
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Several Days",
                  value: "severalDays",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "More than half the days",
                  value: "moreThanHalfTheDays",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Nearly Every Day",
                  value: "nearlyEveryDay",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 9,
              editToggle: false,
            },
            {
              id: 6,
              name: "ros_over2WeeksPhq2MoreThreeSubDepScreenPoorAppetite",
              type: "options_tree",
              label: "Do you have a poor appetite or overeating?",
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 1,
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  label: "Not at all",
                  value: "notAtAll",
                  isConfigured: true,
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Several Days",
                  value: "severalDays",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "More than half the days",
                  value: "moreThanHalfTheDays",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Nearly every day",
                  value: "nearlyEveryDay",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 4,
              editToggle: false,
            },
            {
              id: 6,
              name: "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelBad",
              type: "options_tree",
              label:
                "Feeling bad about yourself or that you are a failure or have let yourself or your family down?",
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 1,
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  label: "Not at all",
                  value: "notAtAll",
                  isConfigured: true,
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Several Days",
                  value: "severalDays",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "More than half the days",
                  value: "moreThanHalfTheDays",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Nearly everyday",
                  value: "nearlyEveryDay",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 5,
              editToggle: false,
            },
            {
              id: 6,
              name: "ros_over2WeeksPhq2MoreThreeSubDepScreenTroubleConc",
              type: "options_tree",
              label:
                "Trouble concentrating on things, such as reading the newspaper or watching TV?",
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 1,
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  label: "Not at all",
                  value: "notAtAll",
                  isConfigured: true,
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Several Days",
                  value: "severalDays",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "More than half the days",
                  value: "moreThanHalfTheDays",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Nearly everyday",
                  value: "nearlyEveryDay",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 6,
              editToggle: false,
            },
            {
              id: 6,
              name: "ros_over2WeeksPhq2MoreThreeSubDepScreenMoveOrSpeak",
              type: "options_tree",
              label:
                "Moving or speaking so slowly that other people have noticed. Or opposite-being fidgety or restless that you have been moving around a lot more than usual?",
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 1,
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  label: "Not at all",
                  value: "notAtAll",
                  isConfigured: true,
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Several Days",
                  value: "severalDays",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "More than half the days",
                  value: "moreThanHalfTheDays",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Nearly Every Day",
                  value: "nearlyEveryDay",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 7,
              editToggle: false,
            },
            {
              id: 6,
              name: "ros_over2WeeksPhq2MoreThreeSubDepScreenHurtYourself",
              type: "options_tree",
              label:
                "Thoughts that you would be better off dead, or hurting yourself?",
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 1,
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  label: "Not at all",
                  value: "notAtAll",
                  isConfigured: true,
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Several Days",
                  value: "severalDays",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "More than half the days",
                  value: "moreThanHalfTheDays",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Nearly Every Day",
                  value: "nearlyEveryDay",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 8,
              editToggle: false,
            },
            {
              id: 2,
              name: "ros_over2WeeksPhq9",
              type: "text",
              label: "PHQ 9 Score",
              editToggle: false,
              disabled: true,
              xs: 3,
              required: "no",
              isCommentEnabled: false,
              helperText:
                "If Score is Greater than 15, recommend additional treatment",
              isConfigured: true,
              isModified: true,
              subQuestionId: 10,
            },
            {
              id: 1,
              name: "ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeverityComment",
              type: "text",
              label: "Comment",
              editToggle: false,
              xs: 3,
              required: "no",
              placeholder: " ",
              defaultValue: "",
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 11,
            },
            {
              id: 11,
              name: "ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeverity",
              label: "Stages of Depression",
              editToggle: false,
              type: "datatable",
              tablelist: [],
              xs: 12,
              required: "no",
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 3,
              depth: 5,
            },
          ],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          isVisible: `ros_neuroPsychSubDiag`,
          label: "3 or more",
          value: "threeOrMore",
          type: "toggle_button",
        },
      ],
      questionId: 49,
      editToggle: false,
    },
    {
      id: 6,
      name: "ros_speech",
      type: "options_tree",
      label: "Speech",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 1,
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          isConfigured: true,
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Slurred",
          value: "slurred",
          type: "toggle_button",
        },
        {
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Aphasic",
          value: "aphasic",
          type: "toggle_button",
        },
        {
          subQuestionId: 4,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Apraxia",
          value: "apraxia",
          type: "toggle_button",
        },
      ],
      questionId: 50,
      editToggle: false,
    },
    {
      id: 6,
      name: "ros_fingerToNose",
      type: "options_tree",
      label: "Finger to Nose",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 1,
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          isConfigured: true,
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "ros_fingerToNoseAbnormalSub",
              type: "options_tree",
              label: "If Abnormal",
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 3,
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  label: "Left",
                  value: "left",
                  isConfigured: true,
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Right",
                  value: "right",
                  type: "toggle_button",
                  depth: 3,
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Both",
                  value: "both",
                  type: "toggle_button",
                  depth: 3,
                },
              ],
              subQuestionId: 1,
              editToggle: false,
            },
          ],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
          depth: 1,
        },
      ],
      questionId: 51,
      editToggle: false,
    },
    {
      id: 6,
      name: "ros_heelToToe",
      type: "options_tree",
      label: "Heel (Shin) to Toe",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 1,
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          isConfigured: true,
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "ros_heelToToeAbnormalSub",
              type: "options_tree",
              label: "If Abnormal",
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 3,
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  label: "Left",
                  value: "left",
                  isConfigured: true,
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Right",
                  value: "right",
                  type: "toggle_button",
                  depth: 3,
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Both",
                  value: "both",
                  type: "toggle_button",
                  depth: 3,
                },
              ],
              subQuestionId: 1,
              editToggle: false,
            },
          ],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
          depth: 1,
        },
      ],
      questionId: 52,
      editToggle: false,
    },
    {
      id: 6,
      name: "ros_thumbToFinger",
      type: "options_tree",
      label: "Thumb to Finger Tips",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 1,
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          isConfigured: true,
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "ros_thumbToFingerAbnormalSub",
              type: "options_tree",
              label: "If Abnormal  ",
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 3,
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  label: "Left",
                  value: "left",
                  isConfigured: true,
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Right",
                  value: "right",
                  type: "toggle_button",
                  depth: 3,
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Both",
                  value: "both",
                  type: "toggle_button",
                  depth: 3,
                },
              ],
              subQuestionId: 1,
              editToggle: false,
            },
          ],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
          depth: 1,
        },
      ],
      questionId: 53,
      editToggle: false,
    },
    {
      id: 6,
      name: "ros_sitToStand",
      type: "options_tree",
      label: "Sitting to Standing",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 1,
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          isConfigured: true,
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Needs Assistance",
          value: "needsAssistance",
          type: "toggle_button",
        },
        {
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Unable",
          value: "unable",
          type: "toggle_button",
        },
      ],
      questionId: 54,
      editToggle: false,
    },
    {
      id: 6,
      name: "ros_extremeMove",
      type: "options_tree",
      label: "Facial /Extremity Movement",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 1,
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          label: "Motor Tic",
          value: "motorTic",
          isConfigured: true,
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Vocal Tic",
          value: "vocalTic",
          type: "toggle_button",
        },
        {
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Benign (Essential Tremor)",
          value: "benign",
          type: "toggle_button",
        },
        {
          subQuestionId: 4,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Intention Tremor",
          value: "intentionTremor",
          type: "toggle_button",
        },
        {
          subQuestionId: 5,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Non-Intention (Pill rolling) Tremor",
          value: "nonIntention",
          type: "toggle_button",
        },
        {
          subQuestionId: 6,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Rigidity",
          value: "rigidity",
          type: "toggle_button",
        },
        {
          subQuestionId: 7,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Spasticity",
          value: "spasticity",
          type: "toggle_button",
        },
        {
          subQuestionId: 8,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Chorea Movement",
          value: "choreaMovement",
          type: "toggle_button",
        },
        {
          subQuestionId: 9,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Cog Wheeling",
          value: "cogWheeling",
          type: "toggle_button",
        },
        {
          subQuestionId: 10,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Normal",
          value: "normal",
          type: "toggle_button",
        },
      ],
      questionId: 55,
      editToggle: false,
    },
    {
      id: 6,
      name: "ros_gait",
      type: "options_tree",
      label: "Gait",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 1,
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          isConfigured: true,
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Limp",
          value: "limp",
          type: "toggle_button",
        },
        {
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Wide based",
          value: "wideBased",
          type: "toggle_button",
        },
        {
          subQuestionId: 4,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Abductor lurch",
          value: "abductorLurch",
          type: "toggle_button",
        },
        {
          subQuestionId: 5,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Paretic",
          value: "paretic",
          type: "toggle_button",
        },
        {
          subQuestionId: 6,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Shuffling",
          value: "shuffling",
          type: "toggle_button",
        },
        {
          subQuestionId: 7,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Ataxic",
          value: "ataxic",
          type: "toggle_button",
        },
        {
          subQuestionId: 8,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Other (Findings may also apply to Musculoskeletal diagnoses)",
          value: "other",
          type: "toggle_button",
        },
      ],
      questionId: 56,
      editToggle: false,
    },
    {
      id: 10,
      name: "gait_Recommendations",
      value: "takeMedicationsAsPescribed",
      label: "Recommendations",
      variant: 2,
      placeholder: "",
      editToggle: false,
      defaultValue: "",
      type: "checkbox",
      options: [
        ["Take medications as prescribed", "takeMedicationsAsPescribed"],
        ["Other", "other"],
      ],
      xs: 12,
      required: false,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      questionId: 57,
    },
    {
      id: 9,
      name: "genitourinary",
      type: "heading",
      label: "GENITOURINARY",
      editToggle: false,
      xs: 3,
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 58,
      depth: 1,
    },
    {
      id: 6,
      name: "ros_genito",
      type: "options_tree",
      label:
        "Genitourinary Problems (Overactive Bladder, Urinary Incontinence Stress Incontinence, Benign Prostatic Hypertrophy, Others)",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 2,
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "ros_genitoSubDiagnoses",
              type: "options_tree",
              label: "Diagnoses",
              isMulti: true,
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 4,
                  subQuestionId: 1,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Acute Renal Failure",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },

                    {
                      id: 6,
                      name: "ros_genitoSubDiagnosesacuteRenalFailuredes",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "active",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of ",
                          value: "historyOf",
                          type: "toggle_button",
                          name: "historyOf",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                          name: "ruleOut",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                      depth: 5,
                    },
                    {
                      id: 6,
                      name: "ros_genitoSubDiagnosesacuteRenalFailureSup",
                      type: "options_tree",
                      label: "Supported by",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Lab tests",
                          value: "labTests",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "labTests",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Calculated GFR",
                          value: "calculatedGfr",
                          type: "toggle_button",
                          name: "calculatedGfr",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Hospitalization",
                          value: "hospitalization",
                          type: "toggle_button",
                          name: "hospitalization",
                          depth: 6,
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 1,
                              name: "ros_genitoSubDiagnosesacuteRenalFailureSupotherSubVal",
                              type: "text",
                              label: "Describe",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                          name: "other",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                      depth: 5,
                    },
                    {
                      id: 1,
                      name: "ros_genitoSubDiagnosesacuteRenalFailureEtiology",
                      type: "text",
                      label: "Etiology",
                      editToggle: false,
                      xs: 4,
                      required: "no",
                      placeholder: "",
                      defaultValue: "",
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      subQuestionId: 3,
                      depth: 5,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  label: "Acute Renal Failure",
                  value: "acuteRenalFailure",
                  isConfigured: true,
                  type: "toggle_button",
                  name: "acuteRenalFailure",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "BPH",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_genitoSubDiagnosesbphDescribe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "active",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                          name: "historyOf",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                          name: "ruleOut",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                      depth: 5,
                    },
                    {
                      id: 6,
                      name: "ros_genitoSubDiagnosesbphSupportedBy",
                      type: "options_tree",
                      label: "Supported by",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Physical exam",
                          value: "physicalExam",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "physicalExam",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [{}],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Symptoms",
                          value: "symptoms",
                          type: "toggle_button",
                          name: "symptoms",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Lab test",
                          value: "labTest",
                          type: "toggle_button",
                          name: "labTest",
                          depth: 6,
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Biopsy",
                          value: "biopsy",
                          type: "toggle_button",
                          name: "biopsy",
                          depth: 6,
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Medication",
                          value: "medication",
                          type: "toggle_button",
                          name: "medication",
                          depth: 6,
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Hospitalization",
                          value: "hospitalization",
                          type: "toggle_button",
                          name: "hospitalization",
                          depth: 6,
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 1,
                              name: "ros_genitoSubDiagnosesbphSupportedByOtherSubAnswer",
                              type: "text",
                              label: "Describe",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                          name: "other",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                      depth: 5,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "BPH",
                  value: "bphgenito",
                  type: "toggle_button",
                  name: "bphgenito",
                  depth: 4,
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Chronic Kidney Disease",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_genitoSubDiagnoseschronicKidneyDiseaseDescribe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "active",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                          name: "historyOf",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                          name: "ruleOut",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                      depth: 5,
                    },
                    {
                      id: 6,
                      name: "ros_genitoSubDiagnoseschronicKidneyDiseaseSupportedBy",
                      type: "options_tree",
                      label: "Supported by",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Lab tests",
                          value: "labTests",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "labTests",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Calculated GFR X 3",
                          value: "calculatedGfrX3",
                          type: "toggle_button",
                          name: "calculatedGfrX3",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 1,
                              name: "ros_genitoSubDiagnoseschronicKidneyDiseaseSupportedByOtherSubAnswer",
                              type: "text",
                              label: "Describe",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                          name: "other",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                      depth: 5,
                    },
                    {
                      id: 11,
                      name: "stagesOfKidneyDisease",
                      label: "Stages of Kidney Disease",
                      editToggle: false,
                      type: "datatable",
                      tablelist: [
                        [
                          {
                            id: 1,
                            label: "Allergies",
                            name: "HRAForm_Allergies",
                            columns: [
                              {
                                label: "Substance",
                                type: "text",
                              },
                              {
                                label: "Reaction",
                                type: "text",
                              },
                            ],
                            isReadOnly: false,
                          },
                          false,
                        ],
                        [
                          {
                            id: 2,
                            label: "BMI Index Chart",
                            name: "HRAForm_BMI_Index_Chart",
                            columns: [
                              {
                                label: "BMI",
                                type: "text",
                              },
                            ],
                            isReadOnly: true,
                          },
                          false,
                        ],
                        [
                          {
                            id: 3,
                            label: "Counter Medications",
                            name: "HRAForm_Counter_Medications",
                            columns: [
                              {
                                label: "Date",
                                type: "date",
                              },
                              {
                                label: "Description",
                                type: "text",
                              },
                              {
                                label: "Dose Per Units",
                                type: "text",
                              },
                              {
                                label: "Route",
                                type: "select",
                              },
                              {
                                label: "Frequency",
                                type: "text",
                              },
                            ],
                            isReadOnly: false,
                          },
                          false,
                        ],
                        [
                          {
                            id: 4,
                            label: "Medical History Specialists",
                            name: "HRAForm_Medical_History_Speciality",
                            columns: [
                              {
                                label: "Member Specialist",
                                type: "select",
                              },
                              {
                                label: "Speciality",
                                type: "text",
                              },
                              {
                                label: "For",
                                type: "text",
                              },
                            ],
                            isReadOnly: false,
                          },
                          false,
                        ],
                        [
                          {
                            id: 5,
                            label: "Medications",
                            name: "HRAForm_Medications",
                            columns: [
                              {
                                label: "Diagnoses",
                                type: "text",
                              },
                              {
                                label: "label Name",
                                type: "select",
                              },
                              {
                                label: "Dose Per Units",
                                type: "text",
                              },
                              {
                                label: "Route",
                                type: "select",
                              },
                              {
                                label: "Frequency",
                                type: "select",
                              },
                              {
                                label: "Prescribing Physician",
                                type: "text",
                              },
                              {
                                label: "Status",
                                type: "togglebutton",
                              },
                            ],
                            isReadOnly: false,
                          },
                          false,
                        ],
                        [
                          {
                            id: 6,
                            label: "Mini Cog Versions",
                            name: "HRAForm_Minicog_Version",
                            columns: [
                              {
                                label: "Version 1",
                                type: "text",
                              },
                              {
                                label: "Version 2",
                                type: "text",
                              },
                              {
                                label: "Version 3",
                                type: "text",
                              },
                              {
                                label: "Version 4",
                                type: "text",
                              },
                              {
                                label: "Version 5",
                                type: "text",
                              },
                              {
                                label: "Version 6",
                                type: "text",
                              },
                            ],
                            isReadOnly: true,
                          },
                          false,
                        ],
                        [
                          {
                            id: 7,
                            label: "Preventive Care",
                            name: "HRAForm_Preventive_Care",
                            columns: [
                              {
                                label: "Screen",
                                type: "text",
                              },
                              {
                                label: "Answer",
                                type: "select",
                              },
                              {
                                label: "Date",
                                type: "text",
                              },
                              {
                                label: "Method",
                                type: "text",
                              },
                              {
                                label: "Recommendation",
                                type: "checkbox",
                              },
                              {
                                label: "Education Completed",
                                type: "checkbox",
                              },
                            ],
                            isReadOnly: true,
                          },
                          false,
                        ],
                        [
                          {
                            id: 8,
                            label: "Previously Documented Conditions",
                            name: "HRAForm_Previously_Documented_Conditions",
                            columns: [
                              {
                                label: "Diagnosis",
                                type: "text",
                              },
                              {
                                label: "Prior HCC",
                                type: "text",
                              },
                              {
                                label: "Diagnosis Code",
                                type: "text",
                              },
                              {
                                label: "Date of Service",
                                type: "date",
                              },
                            ],
                            isReadOnly: false,
                          },
                          false,
                        ],
                        [
                          {
                            id: 9,
                            label: "Prior Assessments",
                            name: "HRAForm_Prior_Assessments",
                            columns: [
                              {
                                label: "Date Time",
                                type: "date",
                              },
                              {
                                label: "Document",
                                type: "button",
                              },
                            ],
                            isReadOnly: true,
                          },
                          false,
                        ],
                        [
                          {
                            id: 10,
                            label: "Stages of Kidney Disease",
                            name: "HRAForm_Stage_Of_Kidney_Disease",
                            columns: [
                              {
                                label: "Stages",
                                type: "text",
                              },
                            ],
                            isReadOnly: true,
                          },
                          true,
                        ],
                        [
                          {
                            id: 11,
                            label: "Alcohol Usage",
                            name: "HRAForm_Alcohol_Usage",
                            columns: [
                              {
                                label: "How many drinks",
                                type: "select",
                              },
                              {
                                label: "How often",
                                type: "select",
                              },
                            ],
                            isReadOnly: true,
                          },
                          false,
                        ],
                        [
                          {
                            id: 12,
                            name: "Family History",
                            columns: [
                              {
                                label: "Family Member",
                                type: "select",
                              },
                              {
                                label: "Medical Condition",
                                type: "text",
                              },
                              {
                                label: "Cause of Death",
                                type: "text",
                              },
                            ],
                            isReadOnly: false,
                          },
                          false,
                        ],
                        [
                          {
                            id: 13,
                            label: "Depression Severity",
                            name: "Depression_Severity",
                            columns: [
                              {
                                label: "Score",
                                type: "number",
                              },
                              {
                                label: "Depression Severity",
                                type: "checkbox",
                              },
                            ],
                            isReadOnly: true,
                          },
                          false,
                        ],
                        [
                          {
                            id: 14,
                            label: "Word Recall",
                            name: "Word_Recall",
                            columns: [
                              {
                                label: "Word Recall",
                                type: "text",
                              },
                              {
                                label: "Points",
                                type: "select",
                              },
                              {
                                label: "Description",
                                type: "text",
                              },
                            ],
                            isReadOnly: true,
                          },
                          false,
                        ],
                      ],
                      xs: 12,
                      required: "no",
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      subQuestionId: 3,
                      depth: 5,
                    },
                    {
                      id: 6,
                      name: "ros_genitoSubDiagnoseswhatStage",
                      type: "options_tree",
                      label: "What stage",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [{}],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Stage 1",
                          value: "stage1",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "stage1",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Stage 2",
                          value: "stage2",
                          type: "toggle_button",
                          name: "stage2",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Stage 3a",
                          value: "stage3A",
                          type: "toggle_button",
                          name: "stage3A",
                          depth: 6,
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Stage 3b",
                          value: "stage3B",
                          type: "toggle_button",
                          name: "stage3B",
                          depth: 6,
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Stage 4",
                          value: "stage4",
                          type: "toggle_button",
                          name: "stage4",
                          depth: 6,
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Stage 5",
                          value: "stage5",
                          type: "toggle_button",
                          name: "stage5",
                          depth: 6,
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Unknown",
                          value: "unknown",
                          type: "toggle_button",
                          name: "unknown",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 4,
                      editToggle: false,
                      depth: 5,
                    },
                    {
                      id: 6,
                      name: "ros_genitoSubDiagnoseschronicKidneyDiseaseSecondaryToDiabetes",
                      type: "options_tree",
                      label: "Secondary to Diabetes",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Yes",
                          value: "yes",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "yes",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                          name: "no",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Unknown",
                          value: "unknown",
                          type: "toggle_button",
                          name: "unknown",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 5,
                      editToggle: false,
                      depth: 5,
                    },
                    {
                      id: 6,
                      name: "ros_genitoSubDiagnoseschronicKidneyDiseaseSecondaryToHypertension",
                      type: "options_tree",
                      label: "Secondary to Hypertension",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Yes",
                          value: "yes",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "yes",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                          name: "no",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Unknown",
                          value: "unknown",
                          type: "toggle_button",
                          name: "unknown",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 6,
                      editToggle: false,
                      depth: 5,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Chronic Kidney Disease",
                  value: "chronicKidneyDisease",
                  type: "toggle_button",
                  name: "chronicKidneyDisease",
                  depth: 4,
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "ESRD",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_genitoSubDiagnosesesrdDescribe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "active",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                          name: "historyOf",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                          name: "ruleOut",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                      depth: 5,
                    },
                    {
                      id: 6,
                      name: "ros_genitoSubDiagnosesesrdSupportedBy",
                      type: "options_tree",
                      label: "Supported by",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Lab tests",
                          value: "labTests",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "labTests",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [{}],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Calculated GFR X 3",
                          value: "calculatedGfrX3",
                          type: "toggle_button",
                          name: "calculatedGfrX3",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Symptoms",
                          value: "symptoms",
                          type: "toggle_button",
                          name: "symptoms",
                          depth: 6,
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 1,
                              name: "ros_genitoSubDiagnosesesrdSupportedByOtherSubAnswer",
                              type: "text",
                              label: "Describe",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                          name: "other",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                      depth: 5,
                    },
                    {
                      id: 6,
                      name: "ros_genitoSubDiagnosesesrdPatientOnDialysis",
                      type: "options_tree",
                      label: "Patient on dialysis",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_genitoSubDiagnosesesrdPatientOnDialysisType",
                              type: "options_tree",
                              label: "Type",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Hemodialysis",
                                  value: "hemodialysis",
                                  isConfigured: true,
                                  type: "toggle_button",
                                  name: "hemodialysis",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Peritoneal dialysis",
                                  value: "peritonealDialysis",
                                  type: "toggle_button",
                                  name: "peritonealDialysis",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 1,
                              editToggle: false,
                              depth: 7,
                            },
                            {
                              id: 6,
                              name: "ros_genitoSubDiagnosesesrdPatientOnDialysisLocation",
                              type: "options_tree",
                              label: "Location",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "In Home",
                                  value: "inHome",
                                  isConfigured: true,
                                  type: "toggle_button",
                                  name: "inHome",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Dialysis Center",
                                  value: "dialysisCenter",
                                  type: "toggle_button",
                                  name: "dialysisCenter",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 2,
                              editToggle: false,
                              depth: 7,
                            },
                            {
                              id: 1,
                              name: "ros_genitoSubDiagnosesesrddialysisSchedule",
                              type: "text",
                              label: "Dialysis schedule",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 3,
                              depth: 7,
                            },
                            {
                              id: 1,
                              name: "ros_genitoSubDiagnosesesrdtypeAndLocationOfAccessDevice",
                              type: "text",
                              label: "Type and location of access device",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 4,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Yes",
                          value: "yes",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "yes",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                          name: "no",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 3,
                      editToggle: false,
                      depth: 5,
                    },
                    {
                      id: 6,
                      name: "ros_genitoSubDiagnosesesrdSpecialDietIsspecialDiet",
                      type: "options_tree",
                      label: "On a special diet",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Yes",
                          value: "yes",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "yes",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                          name: "no",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 4,
                      editToggle: false,
                      depth: 5,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "ESRD",
                  value: "esrd",
                  type: "toggle_button",
                  name: "esrd",
                  depth: 4,
                },
                {
                  subQuestionId: 5,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Erectile Dysfunction",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_genitoSubDiagnoseserectileDysfunctionDescribe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "active",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of ",
                          value: "historyOf",
                          type: "toggle_button",
                          name: "historyOf",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                          name: "ruleOut",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                      depth: 5,
                    },
                    {
                      id: 6,
                      name: "ros_genitoSubDiagnoseserectileDysfunctionSupportedBy",
                      type: "options_tree",
                      label: "Supported by",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "History",
                          value: "history",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "history",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Symptoms",
                          value: "symptoms",
                          type: "toggle_button",
                          name: "symptoms",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Physical Findings",
                          value: "physicalFindings",
                          type: "toggle_button",
                          name: "physicalFindings",
                          depth: 6,
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Medications",
                          value: "medications",
                          type: "toggle_button",
                          name: "medications",
                          depth: 6,
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Test results",
                          value: "testResults",
                          type: "toggle_button",
                          name: "testResults",
                          depth: 6,
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 1,
                              name: "ros_genitoSubDiagnoseserectileDysfunctionSupportedByOtherSubAnswer",
                              type: "text",
                              label: "Describe",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                          name: "other",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                      depth: 5,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Erectile Dysfunction",
                  value: "erectileDysfunction",
                  type: "toggle_button",
                  name: "erectileDysfunction",
                  depth: 4,
                },
                {
                  subQuestionId: 6,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Frequent UTI",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_genitoSubDiagnosesfrequentUtiDescribe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "active",
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                          name: "historyOf",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                          name: "ruleOut",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 1,
                      depth: 5,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_genitoSubDiagnosesfrequentUtiSupportedBy",
                      type: "options_tree",
                      label: "Supported by",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "symptoms",
                          label: "Symptoms",
                          value: "symptoms",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [{}],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Cultures",
                          value: "cultures",
                          type: "toggle_button",
                          name: "cultures",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Laboratory results",
                          value: "laboratoryResults",
                          type: "toggle_button",
                          name: "laboratoryResults",
                          depth: 6,
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 1,
                              name: "ros_genitoSubDiagnosesfrequentUtiSupportedByOtherSubAnswer",
                              type: "text",
                              label: "Describe",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                          name: "other",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 2,
                      depth: 5,
                      editToggle: false,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Frequent UTI",
                  value: "frequentUti",
                  type: "toggle_button",
                  name: "frequentUti",
                  depth: 4,
                },
                {
                  subQuestionId: 7,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Gynecological",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_genitoSubDiagnosesgynecologicalDescribe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "active",
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [{}],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                          name: "historyOf",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                          name: "ruleOut",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 1,
                      depth: 5,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_genitoSubDiagnosesgynecologicalSupportedBy",
                      type: "options_tree",
                      label: "Supported by",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "symptoms",
                          label: "Symptoms",
                          value: "symptoms",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History",
                          value: "history",
                          type: "toggle_button",
                          name: "history",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Diagnostic results",
                          value: "diagnosticResults",
                          type: "toggle_button",
                          name: "diagnosticResults",
                          depth: 6,
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Medications",
                          value: "medications",
                          type: "toggle_button",
                          name: "medications",
                          depth: 6,
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Vaginal Bleeding",
                          value: "vaginalBleeding",
                          type: "toggle_button",
                          name: "vaginalBleeding",
                          depth: 6,
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 1,
                              name: "ros_genitoSubDiagnosesgynecologicalSupportedByOtherSubAnswer",
                              type: "text",
                              label: "Describe",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                          name: "other",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 2,
                      depth: 5,
                      editToggle: false,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Gynecological",
                  value: "gynecological",
                  type: "toggle_button",
                  name: "gynecological",
                  depth: 4,
                },
                {
                  subQuestionId: 8,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Kidney Stones",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_genitoSubDiagnoseskidneyStonesDescribe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "active",
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                          name: "historyOf",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                          name: "ruleOut",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 1,
                      depth: 5,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_genitoSubDiagnoseskidneyStonesSupportedBy",
                      type: "options_tree",
                      label: "Supported by",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "history",
                          label: "History",
                          value: "history",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Symptoms",
                          value: "symptoms",
                          type: "toggle_button",
                          name: "symptoms",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Physical Findings",
                          value: "physicalFindings",
                          type: "toggle_button",
                          name: "physicalFindings",
                          depth: 6,
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Medications",
                          value: "medications",
                          type: "toggle_button",
                          name: "medications",
                          depth: 6,
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Test results",
                          value: "testResults",
                          type: "toggle_button",
                          name: "testResults",
                          depth: 6,
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Image studies",
                          value: "imageStudies",
                          type: "toggle_button",
                          name: "imageStudies",
                          depth: 6,
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 1,
                              name: "ros_genitoSubDiagnoseskidneyStonesSupportedByOtherSubAnswer",
                              type: "text",
                              label: "Describe",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                          name: "other",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 2,
                      depth: 5,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_genitoSubDiagnoseskidneyStonesType",
                      type: "options_tree",
                      label: "Type",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "urate",
                          label: "Urate",
                          value: "urate",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Calcium Oxalate",
                          value: "calciumOxalate",
                          type: "toggle_button",
                          name: "calciumOxalate",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Magnesium",
                          value: "magnesium",
                          type: "toggle_button",
                          name: "magnesium",
                          depth: 6,
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                          name: "other",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 3,
                      depth: 5,
                      editToggle: false,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Kidney Stones",
                  value: "kidneyStonesgenito",
                  type: "toggle_button",
                  name: "kidneyStonesgenito",
                  depth: 4,
                },
                {
                  subQuestionId: 9,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Nephritis or Nephrosis",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_genitoSubDiagnosesnephritisOrNephrosisDescribe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "active",
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                          name: "historyOf",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                          name: "ruleOut",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 1,
                      depth: 5,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_genitoSubDiagnosesnephritisOrNephrosisSupportedBy",
                      type: "options_tree",
                      label: "Supported by",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "medicalHistory",
                          label: "Medical history",
                          value: "medicalHistory",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Lab tests",
                          value: "labTests",
                          type: "toggle_button",
                          name: "labTests",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Imaging studies",
                          value: "imagingStudies",
                          type: "toggle_button",
                          name: "imagingStudies",
                          depth: 6,
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Biopsy",
                          value: "biopsy",
                          type: "toggle_button",
                          name: "biopsy",
                          depth: 6,
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Medications",
                          value: "medications",
                          type: "toggle_button",
                          name: "medications",
                          depth: 6,
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 1,
                              name: "ros_genitoSubDiagnosesnephritisOrNephrosisSupportedByOtherSubAnswer",
                              type: "text",
                              label: "Describe",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                          name: "other",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 2,
                      depth: 5,
                      editToggle: false,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Nephritis or Nephrosis",
                  value: "nephritisOrNephrosis",
                  type: "toggle_button",
                  name: "nephritisOrNephrosis",
                  depth: 4,
                },
                {
                  subQuestionId: 10,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Urinary Incontinence",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_genitoSubDiagnosesurinaryIncontinenceDescribe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "active",
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                          name: "historyOf",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                          name: "ruleOut",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 1,
                      depth: 5,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_genitoSubDiagnosesurinaryIncontinenceSupportedBy",
                      type: "options_tree",
                      label: "Supported by",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "history",
                          label: "History",
                          value: "history",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Symptoms",
                          value: "symptoms",
                          type: "toggle_button",
                          name: "symptoms",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [{}],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Physical Findings",
                          value: "physicalFindings",
                          type: "toggle_button",
                          name: "physicalFindings",
                          depth: 6,
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [{}],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Medications",
                          value: "medications",
                          type: "toggle_button",
                          name: "medications",
                          depth: 6,
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [{}],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Test results",
                          value: "testResults",
                          type: "toggle_button",
                          name: "testResults",
                          depth: 6,
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "DME",
                          value: "dme",
                          type: "toggle_button",
                          name: "dme",
                          depth: 6,
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 1,
                              name: "ros_genitoSubDiagnosesurinaryIncontinenceSupportedByOtherSubAnswer",
                              type: "text",
                              label: "Describe",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                          name: "other",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 2,
                      depth: 5,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_genitoStress",
                      type: "options_tree",
                      label: "Related to stress",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_genitoStressSub",
                              type: "options_tree",
                              label: "Related to ",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 6,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Dribbling",
                                  value: "dribbling",
                                  isConfigured: true,
                                  type: "toggle_button",
                                  name: "dribbling",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Urgency",
                                  value: "urgency",
                                  type: "toggle_button",
                                  name: "urgency",
                                  depth: 6,
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Other",
                                  value: "other",
                                  type: "toggle_button",
                                  name: "other",
                                  depth: 6,
                                },
                              ],
                              subQuestionId: 1,
                              editToggle: false,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "yes",
                          label: "Yes",
                          value: "yes",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                          name: "no",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 3,
                      depth: 5,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_genitoSubDiagnosesurinaryIncontinenceUiDescribe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "daily",
                          label: "Daily",
                          value: "daily",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Few times a week",
                          value: "fewTimesAWeek",
                          type: "toggle_button",
                          name: "fewTimesAWeek",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Less than once a week",
                          value: "lessThanOnceAWeek",
                          type: "toggle_button",
                          name: "lessThanOnceAWeek",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 4,
                      depth: 5,
                      editToggle: false,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Urinary Incontinence",
                  value: "urinaryIncontinence",
                  type: "toggle_button",
                  name: "urinaryIncontinence",
                  depth: 4,
                },
                {
                  subQuestionId: 11,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Other",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_genitoSubDiagnosesotherDescribe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "active",
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of ",
                          value: "historyOf",
                          type: "toggle_button",
                          name: "historyOf",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                          name: "ruleOut",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 1,
                      depth: 5,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_genitoSubDiagnosesotherSupportedBy",
                      type: "options_tree",
                      label: "Supported by",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "history",
                          label: "History",
                          value: "history",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Symptoms ",
                          value: "symptoms",
                          type: "toggle_button",
                          name: "symptoms",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Physical Findings",
                          value: "physicalFindings",
                          type: "toggle_button",
                          name: "physicalFindings",
                          depth: 6,
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Medications",
                          value: "medications",
                          type: "toggle_button",
                          name: "medications",
                          depth: 6,
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Test results",
                          value: "testResults",
                          type: "toggle_button",
                          name: "testResults",
                          depth: 6,
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Image studies",
                          value: "imageStudies",
                          type: "toggle_button",
                          name: "imageStudies",
                          depth: 6,
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Biopsy",
                          value: "biopsy",
                          type: "toggle_button",
                          name: "biopsy",
                          depth: 6,
                        },
                        {
                          subQuestionId: 8,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "DME",
                          value: "dme",
                          type: "toggle_button",
                          name: "dme",
                          depth: 6,
                        },
                        {
                          subQuestionId: 9,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 1,
                              name: "ros_genitoSubDiagnosesotherSupportedByOtherSubAnswer",
                              type: "text",
                              label: "Describe",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                          name: "other",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 2,
                      depth: 5,
                      editToggle: false,
                    },
                    {
                      id: 1,
                      name: "ros_genitoSubDiagnosesotherOther",
                      type: "text",
                      label: "Other",
                      editToggle: false,
                      xs: 4,
                      required: "no",
                      placeholder: "",
                      defaultValue: "",
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      subQuestionId: 3,
                      depth: 5,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Other",
                  value: "other",
                  type: "toggle_button",
                  name: "other",
                  depth: 4,
                },
              ],
              subQuestionId: 1,
              editToggle: false,
              depth: 3,
            },
          ],
          editToggle: false,
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          isConfigured: true,
          type: "toggle_button",
          name: "yes",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "No",
          value: "no",
          type: "toggle_button",
          name: "no",
          depth: 2,
        },
      ],
      questionId: 59,
      editToggle: false,
      depth: 1,
    },
    {
      id: 10,
      name: "ros_genitoRecommendations",
      label: "Recommendations",
      placeholder: "",
      editToggle: false,
      defaultValue: "",
      type: "checkbox",
      color: "primary",
      options: [
        ["Take medications as prescribed", "takeMedicationsAsPrescribedGenito"],
        ["Other", "otherGenito"],
      ],
      xs: 12,
      required: "no",
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      questionId: 60,
      depth: 1,
    },
    {
      id: 10,
      name: "musculoskeletal",
      type: "heading",
      label: "MUSCULOSKELETAL",
      editToggle: false,
      xs: 12,
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 61,
    },
    {
      id: 8,
      name: "ros_musculoskeletal",
      type: "options_tree",
      label:
        "Musculoskeletal Problems (Spinal Stenosis, Rheumatoid Arthritis, Gout, Osteoporosis, Others)",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 1,
          subQuestionId: 1,
          hasSubQuestions: true,
          editToggle: false,
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          isConfigured: true,
          type: "toggle_button",
          subQuestions: [
            {
              id: 6,
              name: "ros_musculoskeletalDiagnoses",
              value: "diagnoses",
              type: "options_tree",
              isMulti: true,
              label: "Diagnoses",
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 1,
                  subQuestionId: 1,
                  name: "ros_musculoskeletalCollagenSubAnswer",
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  label: "Collagen(Connective Tissue Disease)",
                  value: "collagen",
                  isConfigured: true,
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Collagen (Connective) Tissue Disease",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_musculoskeletalCollagenSubAnswerDescribe",
                      value: "describe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_musculoskeletalCollagenSubAnswerSupportedBy",
                      value: "supportedBy",
                      isMulti: true,
                      type: "options_tree",
                      label: "Supported by    ",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Physical Findings",
                          value: "physicalFindings",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Lab Tests",
                          value: "labTests",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Biopsy",
                          value: "biopsy",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Image studies",
                          value: "imageStudies",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          name: "ros_musculoskeletalCollagenSubOther",
                          type: "toggle_button",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              subQuestionId: 1,
                              hasSubQuestions: false,
                              editToggle: false,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: false,
                              label: "Describe",
                              value: "describe",
                              name: "ros_musculoskeletalCollagenSubOtherSubDesc",
                              type: "text",
                              xs: 12,
                            },
                          ],
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                    },
                    {
                      id: 7,
                      name: "ros_musculoskeletalCollagenSubAnswercollagenDescribe",
                      value: "collagenDescribe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "SLE(Lupus)",
                          value: "sleLupus",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Scleroderma",
                          value: "scleroderma",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Dermatomyositis",
                          value: "dermatomyositis",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 3,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Degenerative Disc Disease",
                  name: "ros_musculoskeletalDegenerativeDiscDiseaseSubAnswer",
                  value: "degenerativeDiscDisease",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Degenerative Disc Disease",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerDescribe",
                      type: "options_tree",
                      value: "describe",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerSupportedBy",
                      value: "supportedBy",
                      isMulti: true,
                      type: "options_tree",
                      label: "Supported by    ",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Symptoms",
                          value: "symptoms",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          depth: 1,
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Physical Findings",
                          value: "physicalFindings",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Image studies",
                          value: "imageStudies",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Medications",
                          value: "medications",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          name: "ros_musculoskeletalDegenerativeDiscDiseaseSubOther",
                          value: "other",
                          type: "toggle_button",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              subQuestionId: 1,
                              hasSubQuestions: false,
                              editToggle: false,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: false,
                              label: "Describe",
                              value: "describe",
                              name: "ros_musculoskeletalDegenerativeDiscDiseaseSubOtherSubDesc",
                              type: "text",
                              xs: 12,
                            },
                          ],
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerNormalBladder",
                      value: "normalBladder",
                      type: "options_tree",
                      label: "Normal bladder and bowel function",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Yes",
                          value: "yes",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          depth: 1,
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "No",
                          value: "no",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 3,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerSiteOfDisease",
                      value: "siteOfDisease",
                      type: "options_tree",
                      label: "Site of disease",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Cervical",
                          value: "cervical",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          depth: 1,
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Thoracic",
                          value: "thoracic",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          depth: 1,
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Lumbar",
                          value: "lumbar",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          depth: 1,
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Lumbosacral",
                          value: "lumbosacral",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          depth: 1,
                          subQuestionId: 5,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Other",
                          value: "other",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "ros_musculoskeletalDegenerativeDiscDiseaseSubSiteOfDiseaseSubOther",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              depth: 2,
                              subQuestionId: 1,
                              hasSubQuestions: true,
                              editToggle: false,
                              isCommentEnabled: false,
                              label: "Describe",
                              value: "describe",
                              name: "ros_musculoskeletalDegenerativeDiscDiseaseSubSiteOfDiseaseSubOtherSubDesc",
                              isConfigured: true,
                              type: "text",
                              xs: 12,
                            },
                          ],
                        },
                      ],
                      subQuestionId: 4,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Extremity Fracture",
                  value: "extremityFracture",
                  name: "ros_musculoskeletalExtremityFractureSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Extremity Fracture",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_musculoskeletalExtremityFractureSubAnswerDescribe",
                      value: "describe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          subQuestions: [
                            {
                              depth: 2,
                              hasSubQuestions: true,
                              editToggle: false,
                              subQuestionId: 1,
                              isCommentEnabled: true,
                              label: "Type",
                              value: "activeTypeSubAnswer",
                              name: "ros_musculoskeletalExtremityFractureActiveTypeSubAnswer",
                              isConfigured: true,
                              type: "options_tree",
                              subQuestions: [
                                {
                                  depth: 2,
                                  subQuestionId: 1,
                                  hasSubQuestions: true,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Traumatic",
                                  value: "traumatic",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Pathological",
                                  value: "pathological",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                          subQuestions: [
                            {
                              depth: 2,
                              editToggle: false,
                              isCommentEnabled: true,
                              label: "Type",
                              value: "historyOfSubAnswer",
                              name: "ros_musculoskeletalExtremityFractureHistoryOfSubAnswer",
                              isConfigured: true,
                              type: "options_tree",
                              xs: 12,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 2,
                                  subQuestionId: 1,
                                  hasSubQuestions: true,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Traumatic",
                                  value: "traumatic",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Pathological",
                                  value: "pathological",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_musculoskeletalExtremityFractureSubAnswerSupportedBy",
                      type: "options_tree",
                      value: "supportedBy",
                      label: "Supported by",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "History",
                          value: "history",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Symptoms",
                          value: "symptoms",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Physical Findings",
                          value: "physicalFindings",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Medications",
                          value: "medications",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Test results",
                          value: "testResults",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Image studies",
                          value: "imageStudies",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Biopsy",
                          value: "biopsy",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 8,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "DME",
                          value: "dme",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 9,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          name: "ros_musculoskeletalExtremityFractureSubOther",
                          type: "toggle_button",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              depth: 1,
                              subQuestionId: 1,
                              editToggle: false,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: false,
                              label: "Describe",
                              xs: 12,
                              name: "ros_musculoskeletalExtremityFractureSubOtherSubDesc",
                              value: "describe",
                              type: "text",
                            },
                          ],
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_musculoskeletalExtremityFractureSubAnswerExtremity",
                      type: "options_tree",
                      value: "extremity",
                      label: "Extremity",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      isMulti: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Shoulder ",
                          value: "shoulder",
                          isConfigured: true,
                          type: "toggle_button",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Shoulder",
                              fullWidth: true,
                              required: false,
                              xs: 18,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 6,
                              name: "ros_musculoskeletalExtremityFractureShoulderSubAnswer",
                              value: "shoulderSubAnswer",
                              type: "options_tree",
                              label: "Laterality",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 1,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Right",
                                  value: "right",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 1,
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Left",
                                  value: "left",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          depth: 1,
                          subQuestionId: 2,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Arm",
                          value: "arm",
                          isConfigured: true,
                          type: "toggle_button",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Arm",
                              fullWidth: true,
                              required: false,
                              xs: 18,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 6,
                              name: "ros_musculoskeletalExtremityFractureArmSubAnswer",
                              value: "armSubAnswer",
                              type: "options_tree",
                              label: "Laterality",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 1,
                                  subQuestionId: 1,
                                  name: "ros_",
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Right",
                                  value: "right",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 1,
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Left",
                                  value: "left",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          depth: 1,
                          subQuestionId: 3,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Forearm",
                          value: "forearm",
                          isConfigured: true,
                          type: "toggle_button",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Forearm",
                              fullWidth: true,
                              required: false,
                              xs: 18,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 6,
                              name: "ros_musculoskeletalExtremityFractureForearmSubAnswer",
                              value: "forearmSubAnswer",
                              type: "options_tree",
                              label: "Laterality",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 1,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Right",
                                  value: "right",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 1,
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Left",
                                  value: "left",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          depth: 1,
                          subQuestionId: 4,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Wrist",
                          value: "wrist",
                          isConfigured: true,
                          type: "toggle_button",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Wrist",
                              fullWidth: true,
                              required: false,
                              xs: 18,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 6,
                              name: "ros_musculoskeletalExtremityFractureWristSubAnswer",
                              value: "wristSubAnswer",
                              type: "options_tree",
                              label: "Laterality",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 1,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Right",
                                  value: "right",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 1,
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Left",
                                  value: "left",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          depth: 1,
                          subQuestionId: 5,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Hand",
                          value: "hand",
                          isConfigured: true,
                          type: "toggle_button",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Hand",
                              fullWidth: true,
                              required: false,
                              xs: 18,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 6,
                              name: "ros_musculoskeletalHandSubAnswer",
                              value: "extremityFractureHandSubAnswer",
                              type: "options_tree",
                              label: "Laterality",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 1,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Right",
                                  value: "right",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 1,
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Left",
                                  value: "left",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          depth: 1,
                          subQuestionId: 6,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Femoral Shaft",
                          value: "femoralShaft",
                          isConfigured: true,
                          type: "toggle_button",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Femoral Shaft",
                              fullWidth: true,
                              required: false,
                              xs: 18,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 6,
                              name: "ros_musculoskeletalFemoralShaftSubAnswer",
                              value: "extremityFractureFemoralShaftSubAnswer",
                              type: "options_tree",
                              label: "Laterality",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 1,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Right",
                                  value: "right",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 1,
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Left",
                                  value: "left",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          depth: 1,
                          subQuestionId: 7,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Tibia",
                          value: "tibia",
                          isConfigured: true,
                          type: "toggle_button",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Tibia",
                              fullWidth: true,
                              required: false,
                              xs: 18,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 6,
                              name: "ros_musculoskeletalExtremityFractureTibiaSubAnswer",
                              value: "tibiaSubAnswer",
                              type: "options_tree",
                              label: "Laterality",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 1,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Right",
                                  value: "right",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 1,
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Left",
                                  value: "left",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          depth: 1,
                          subQuestionId: 8,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Fibula",
                          value: "fibula",
                          isConfigured: true,
                          type: "toggle_button",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Fibula",
                              fullWidth: true,
                              required: false,
                              xs: 18,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 6,
                              name: "ros_musculoskeletalExtremityFractureFibulaSubAnswer",
                              value: "fibulaSubAnswer",
                              type: "options_tree",
                              label: "Laterality",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 1,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Right",
                                  value: "right",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 1,
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Left",
                                  value: "left",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          depth: 1,
                          subQuestionId: 9,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Ankle",
                          value: "ankle",
                          isConfigured: true,
                          type: "toggle_button",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Ankle",
                              fullWidth: true,
                              required: false,
                              xs: 18,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 6,
                              name: "ros_musculoskeletalExtremityFractureAnkleSubAnswer",
                              value: "ankleSubAnswer",
                              type: "options_tree",
                              label: "Laterality",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 1,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Right",
                                  value: "right",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 1,
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Left",
                                  value: "left",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          depth: 1,
                          subQuestionId: 10,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Foot",
                          value: "foot",
                          isConfigured: true,
                          type: "toggle_button",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Foot",
                              fullWidth: true,
                              required: false,
                              xs: 18,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 6,
                              name: "ros_musculoskeletalExtremityFractureFootSubAnswer",
                              value: "footSubAnswer",
                              type: "options_tree",
                              label: "Laterality",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 1,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Right",
                                  value: "right",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 1,
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Left",
                                  value: "left",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          depth: 1,
                          subQuestionId: 11,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Hip",
                          value: "hip",
                          isConfigured: true,
                          type: "toggle_button",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Hip",
                              fullWidth: true,
                              required: false,
                              xs: 18,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 6,
                              name: "ros_musculoskeletalExtremityFractureHipSubAnswer",
                              value: "hipSubAnswer",
                              type: "options_tree",
                              label: "Laterality",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 1,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Right",
                                  value: "right",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 1,
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Left",
                                  value: "left",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                        },
                      ],
                      subQuestionId: 3,
                      editToggle: false,
                    },
                    {
                      id: 3,
                      name: "ros_musculoskeletalExtremityFractureCurrentWithinTwelveWeeksSubAnswer",
                      type: "options_tree",
                      value: "currentWithinTwelveWeeks",
                      label: "Current (within 12 weeks)",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Yes",
                          value: "yes",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 4,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Gout",
                  value: "gout",
                  name: "ros_musculoskeletalGoutSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Gout",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_musculoskeletalGoutSubAnswerDescribe",
                      value: "describe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_musculoskeletalGoutSubAnswerSupportedBy",
                      value: "supportedBy",
                      isMulti: true,
                      type: "options_tree",
                      label: "Supported by    ",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "History of attacks in Foot",
                          value: "historyOfAttacks",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Lab tests",
                          value: "labTests",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Medications",
                          value: "medications",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          name: "ros_musculoskeletalGoutSubOther",
                          type: "options_tree",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              depth: 2,
                              subQuestionId: 1,
                              hasSubQuestions: true,
                              editToggle: false,
                              isCommentEnabled: false,
                              label: "Describe",
                              value: "describe",
                              name: "ros_musculoskeletalGoutSubOtherSubDesc",
                              isConfigured: true,
                              type: "text",
                              xs: 12,
                            },
                          ],
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 5,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Hallux Valgus",
                  value: "halluxValgus",
                  name: "ros_musculoskeletalHalluxValgusSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Hallux Valgus",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_musculoskeletalHalluxValgusSubAnswerDescribe",
                      type: "options_tree",
                      value: "describe",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 7,
                      name: "ros_musculoskeletalHalluxValgusSubAnswerSupportedBy",
                      type: "options_tree",
                      value: "supportedBy",
                      isMulti: true,
                      label: "Supported by    ",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "History",
                          value: "history",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Symptoms",
                          value: "symptoms",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Physical Findings",
                          value: "physicalFindings",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Medications",
                          value: "medications",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Test results",
                          value: "testResults",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Image studies",
                          value: "imageStudies",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Biopsy",
                          value: "biopsy",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 8,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "DME",
                          value: "dme",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 9,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          name: "ros_musculoskeletalHalluxValgusSubOther",
                          type: "toggle_button",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              depth: 2,
                              subQuestionId: 1,
                              hasSubQuestions: true,
                              editToggle: false,
                              isCommentEnabled: false,
                              label: "Describe",
                              value: "describe",
                              name: "ros_musculoskeletalHalluxValgusSubOtherSubDesc",
                              isConfigured: true,
                              type: "text",
                              xs: 12,
                            },
                          ],
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 6,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Hammer Toes",
                  value: "hammerToes",
                  name: "ros_musculoskeletalHammerToesSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Hammer Toes",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_musculoskeletalHammerToesSubAnswerDescribe",
                      value: "describe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_musculoskeletalHammerToesSubAnswerSupportedBy",
                      type: "options_tree",
                      value: "supportedBy",
                      label: " Supported by",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: " History ",
                          value: "history",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Symptoms",
                          value: "symptoms",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Physical Findings",
                          value: "physicalFindings",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Medications",
                          value: "medications",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Test results",
                          value: "testResults",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Image Studies",
                          value: "imageStudies",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Biopsy",
                          value: "biopsy",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 8,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "DME",
                          value: "dme",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 9,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                          name: "ros_musculoskeletalHammerToesSubOther",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              depth: 2,
                              subQuestionId: 1,
                              hasSubQuestions: true,
                              editToggle: false,
                              isCommentEnabled: false,
                              label: "Describe",
                              value: "describe",
                              name: "ros_musculoskeletalHammerToesSubOtherSubDesc",
                              isConfigured: true,
                              type: "text",
                              xs: 12,
                            },
                          ],
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 7,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Osteoarthritis",
                  value: "osteoarthritis",
                  name: "ros_musculoskeletalOsteoarthritisSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Osteoarthritis",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_musculoskeletalOsteoarthritisSubAnswerDescribe",
                      type: "options_tree",
                      value: "describe",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_musculoskeletalOsteoarthritisSubAnswerSupportedBy",
                      type: "options_tree",
                      label: " Supported by",
                      isMulti: true,
                      value: "supportedBy",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Symptoms",
                          value: "symptoms",
                          isConfigured: true,
                          type: "options_tree",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Symptoms",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 6,
                              name: "ros_musculoskeletalOsteoarthritisSubAnswerSymptomsSubAnswer",
                              type: "options_tree",
                              value: "symptomsSubAnswer",
                              label: "Describe",
                              isMulti: true,
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  value: "jointSwelling",
                                  type: "toggle_button",
                                  label: "Joint swelling",
                                  fullWidth: true,
                                  required: true,
                                  xs: 12,
                                  isCommentEnabled: true,
                                  isConfigured: true,
                                  isModified: true,
                                  hasSubQuestions: true,
                                },
                                {
                                  value: "jointStiffness",
                                  type: "toggle_button",
                                  label: "Joint stiffness",
                                  fullWidth: true,
                                  required: true,
                                  xs: 12,
                                  isCommentEnabled: true,
                                  isConfigured: true,
                                  isModified: true,
                                  hasSubQuestions: true,
                                },
                                {
                                  value: "pain",
                                  type: "toggle_button",
                                  label: "Pain",
                                  fullWidth: true,
                                  required: true,
                                  xs: 12,
                                  isCommentEnabled: true,
                                  isConfigured: true,
                                  isModified: true,
                                  hasSubQuestions: true,
                                },
                                {
                                  value: "limitedRom",
                                  type: "toggle_button",
                                  label: "Limited ROM",
                                  fullWidth: true,
                                  required: true,
                                  xs: 12,
                                  isCommentEnabled: true,
                                  isConfigured: true,
                                  isModified: true,
                                  hasSubQuestions: true,
                                },
                              ],
                            },
                          ],
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Physical Findings",
                          value: "physicalFindings",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Image Studies",
                          value: "imageStudies",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          name: "ros_musculoskeletalOsteoarthritisSubOther",
                          type: "options_tree",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              value: "describe",
                              type: "text",
                              label: "Describe",
                              name: "ros_musculoskeletalOsteoarthritisSubOtherSubDesc",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                          ],
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                    },
                    {
                      id: 4,
                      name: "ros_musculoskeletalOsteoarthritisSubAnswerWhichJoints",
                      depth: 3,
                      type: "text",
                      label: "Which Joints",
                      value: "whichJoints",
                      editToggle: false,
                      xs: 12,
                      required: false,
                      placeholder: "",
                      defaultValue: "",
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      subQuestionId: 3,
                      hasSubQuestions: false,
                    },
                  ],
                },
                {
                  subQuestionId: 8,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Osteomyelitis",
                  value: "osteomyelitis",
                  name: "ros_musculoskeletalOsteomyelitisSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Osteomyelitis",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_musculoskeletalOsteomyelitisSubAnswerDescribe",
                      value: "describe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_musculoskeletalOsteomyelitisSubAnswerSupportedBy",
                      type: "options_tree",
                      value: "supportedBy",
                      label: " Supported by",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: " Hospitalization ",
                          value: "hospitalization",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Image Studies",
                          value: "imageStudies",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Cultures",
                          value: "cultures",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Medications",
                          value: "medications",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "options_tree",
                          name: "ros_musculoskeletalOsteomyelitisSubOther",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              value: "describe",
                              type: "text",
                              label: "Describe",
                              name: "ros_musculoskeletalOsteomyelitisSubOtherSubDesc",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                          ],
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 9,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Osteoporosis",
                  value: "osteoporosis",
                  name: "ros_musculoskeletalOsteoporosisSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Osteoporosis",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_musculoskeletalOsteoporosisSubAnswerDescribe",
                      type: "options_tree",
                      value: "describe",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_musculoskeletalOsteoporosisSubAnswerSupportedBy",
                      type: "options_tree",
                      value: "supportedBy",
                      label: " Supported by",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: " Dexa Scan ",
                          value: "dexaScan",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Medications",
                          value: "medications",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Imaging studies",
                          value: "imagingStudies",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Symptoms",
                          value: "symptoms",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Fracture history",
                          value: "fractureHistory",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                          name: "ros_musculoskeletalOsteoporosisSubOther",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              value: "describe",
                              type: "text",
                              label: "Describe",
                              name: "ros_musculoskeletalOsteoporosisSubOtherSubDesc",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                          ],
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 10,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Pyogenic Arthritis",
                  value: "pyogenicArthritis",
                  name: "ros_musculoskeletalPyogenicArthritisSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Pyogenic Arthritis",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_musculoskeletalPyogenicArthritisSubAnswerDescribe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_musculoskeletalPyogenicArthritisSubAnswerSupportedBy",
                      type: "options_tree",
                      label: " Supported by",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Hospitalization",
                          value: "hospitalization",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Image studies",
                          value: "imageStudies",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Cultures",
                          value: "cultures",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Medications",
                          value: "medications",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          name: "ros_musculoskeletalPyogenicArthritisSubOther",
                          type: "options_tree",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              name: "ros_musculoskeletalPyogenicArthritisSubOtherSubDesc",
                              type: "text",
                              label: "Describe",
                              value: "decsribe",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                          ],
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                    },
                    {
                      id: 4,
                      name: "ros_musculoskeletalPyogenicArthritisSubAnswerWhichJoints",
                      depth: 3,
                      type: "text",
                      label: "Which Joint/s",
                      value: "whichJoints",
                      editToggle: false,
                      xs: 12,
                      required: false,
                      placeholder: "",
                      defaultValue: "",
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      subQuestionId: 3,
                      hasSubQuestions: false,
                    },
                  ],
                },
                {
                  subQuestionId: 11,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Rheumatoid Arthritis",
                  value: "rheumatoidArthritis",
                  name: "ros_musculoskeletalRheumatoidArthritisSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Rheumatoid Arthritis",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_musculoskeletalRheumatoidArthritisSubAnswerDescribe",
                      type: "options_tree",
                      value: "describe",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_musculoskeletalRheumatoidArthritisSubAnswerSupportedBy",
                      type: "options_tree",
                      isMulti: true,
                      value: "supportedBy",
                      label: " Supported by",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Symptoms",
                          value: "symptoms",
                          isConfigured: true,
                          type: "toggle_button",
                          subQuestions: [
                            {
                              id: 5,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Symptoms",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 6,
                              name: "ros_musculoskeletalRheumatoidArthritisSubAnswerSymptoms",
                              type: "options_tree",
                              value: "isSymptomsSubAnswer",
                              isMulti: true,
                              label: "Describe",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  value: "jointSwelling",
                                  type: "toggle_button",
                                  label: "Joint swelling",
                                  fullWidth: true,
                                  required: true,
                                  xs: 12,
                                  isCommentEnabled: true,
                                  isConfigured: true,
                                  isModified: true,
                                  hasSubQuestions: true,
                                },
                                {
                                  value: "jointStiffness",
                                  type: "toggle_button",
                                  label: "Joint stiffness",
                                  fullWidth: true,
                                  required: true,
                                  xs: 12,
                                  isCommentEnabled: true,
                                  isConfigured: true,
                                  isModified: true,
                                  hasSubQuestions: true,
                                },
                                {
                                  value: "pain",
                                  type: "toggle_button",
                                  label: "Pain",
                                  fullWidth: true,
                                  required: true,
                                  xs: 12,
                                  isCommentEnabled: true,
                                  isConfigured: true,
                                  isModified: true,
                                  hasSubQuestions: true,
                                },
                                {
                                  value: "limitedRom",
                                  type: "toggle_button",
                                  label: "Limited ROM",
                                  fullWidth: true,
                                  required: true,
                                  xs: 12,
                                  isCommentEnabled: true,
                                  isConfigured: true,
                                  isModified: true,
                                  hasSubQuestions: true,
                                },
                              ],
                            },
                          ],
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Physical findings",
                          value: "physicalFindings",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Medications",
                          value: "medications",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Lab tests",
                          value: "labTests",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Image studies",
                          value: "imageStudies",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          name: "ros_musculoskeletalRheumatoidArthritisSubOther",
                          type: "options_tree",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              value: "describe",
                              type: "text",
                              label: "Describe",
                              name: "ros_musculoskeletalRheumatoidArthritisSubDesc",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                          ],
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                    },
                    {
                      id: 4,
                      name: "ros_musculoskeletalRheumatoidArthritisSubAnswerWhichJoints",
                      depth: 3,
                      type: "text",
                      label: "Which Joints",
                      value: "whichJoints",
                      editToggle: false,
                      xs: 12,
                      required: false,
                      placeholder: "",
                      defaultValue: "",
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      subQuestionId: 3,
                      hasSubQuestions: false,
                    },
                  ],
                },
                {
                  subQuestionId: 12,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Spinal Stenosis",
                  value: "spinalStenosis",
                  name: "ros_musculoskeletalSpinalStenosisSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Spinal Stenosis",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_musculoskeletalSpinalStenosisSubAnswerDescribe",
                      type: "options_tree",
                      value: "describe",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_musculoskeletalSpinalStenosisSubAnswerSupportedBy",
                      type: "options_tree",
                      value: "supportedBy",
                      isMulti: true,
                      label: " Supported by",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Symptoms",
                          value: "symptoms",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Physical Findings",
                          value: "physicalFindings",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Image studies",
                          value: "imageStudies",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Medications",
                          value: "medications",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "options_tree",
                          name: "ros_musculoskeletalSpinalStenosisSubOther",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              name: "ros_musculoskeletalSpinalStenosisSubOtherSubDesc",
                              type: "text",
                              value: "describe",
                              label: "Describe",
                              subQuestionId: 1,
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                          ],
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_musculoskeletalSpinalStenosisSubAnswerNormalBladder",
                      type: "options_tree",
                      value: "normalBladder",
                      label: "Normal bladder and bowel function",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      subQuestionId: 3,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Yes",
                          value: "yes",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          depth: 1,
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "No",
                          value: "no",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                      ],
                    },
                  ],
                },
                {
                  subQuestionId: 13,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Systemic Lupus Erythematosus",
                  value: "systemicLupus",
                  name: "ros_musculoskeletalSystemicLupusSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Systemic Lupus Erythematosus",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_musculoskeletalSystemicLupusSubAnswerDescribe",
                      value: "describe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_musculoskeletalSystemicLupusSubAnswerSupportedBy",
                      type: "options_tree",
                      isMulti: true,
                      value: "supportedBy",
                      label: " Supported by",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Labs",
                          value: "labs",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Medications",
                          value: "medications",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History",
                          value: "history",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "options_tree",
                          name: "ros_musculoskeletalSystemicLupusSubOther",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              name: "ros_musculoskeletalSystemicLupusSubOtherSubDesc",
                              type: "text",
                              value: "describe",
                              subQuestionId: 1,
                              label: "Describe",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                          ],
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 14,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Other",
                  value: "other",
                  name: "ros_musculoskeletalOtherSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Other",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_musculoskeletalMuskoOtherSubAnswerDescribe",
                      value: "describe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_musculoskeletalMuskoOtherSubAnswerSupportedBy",
                      value: "supportedBy",
                      isMulti: true,
                      type: "options_tree",
                      label: " Supported by",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "History",
                          value: "history",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Symptoms",
                          value: "symptoms",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Physical Findings",
                          value: "physicalFindings",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Medications",
                          value: "medications",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Test results",
                          value: "testResults",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Image studies",
                          value: "imageStudies",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Biopsy",
                          value: "biopsy",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 8,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "DME",
                          value: "dme",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 9,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "options_tree",
                          name: "ros_musculoskeletalMuskoSubOther",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              name: "ros_musculoskeletalMuskoSubOtherSubDesc",
                              type: "text",
                              value: "describe",
                              label: "Describe",
                              subQuestionId: 1,
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                          ],
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                    },
                    {
                      id: 4,
                      name: "ros_musculoskeletalMuskoOtherSubAnswerother",
                      depth: 3,
                      type: "text",
                      label: "Other",
                      value: "other",
                      editToggle: false,
                      xs: 12,
                      required: false,
                      placeholder: "",
                      defaultValue: "",
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      subQuestionId: 3,
                      hasSubQuestions: false,
                    },
                  ],
                },
              ],
              subQuestionId: 1,
              editToggle: false,
            },
            {
              id: 9,
              questionId: 2,
              hasSubQuestions: true,
              editToggle: false,
              //depth: 3,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: false,
              name: "ros_musculoskeletalMuskoHadAnAmputation",
              label: "Have you had an amputation?",
              value: "hadAnAmputation",
              type: "options_tree",
              subQuestions: [
                {
                  subQuestionId: 1,
                  //depth: 1,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: true,
                  isConfigured: true,
                  isModified: false,
                  label: "Yes",
                  value: "yes",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 4,
                      name: "ros_musculoskeletalHadAnAmputationSubAnswer",
                      depth: 1,
                      type: "text",
                      label: "Describe",
                      value: "describe",
                      editToggle: false,
                      xs: 12,
                      required: false,
                      placeholder: "",
                      defaultValue: "",
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      subQuestionId: 1,
                      hasSubQuestions: false,
                    },
                  ],
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "No",
                  //depth: 1,
                  value: "no",
                  type: "toggle_button",
                },
              ],
            },
          ],
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 62,
      editToggle: false,
    },
    {
      id: 10,
      name: "musko_Recommendations",
      value: "takeMedicationsAsPescribed",
      label: "Recommendations",
      placeholder: "",
      editToggle: false,
      defaultValue: "",
      type: "checkbox",
      options: [
        ["Discuss PT/OT evaluation with PCP", "discussPtOt"],
        ["Take medications as prescribed", "takeMedicationsAsPescribed"],
        ["Other", "other"],
      ],
      xs: 12,
      required: false,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      questionId: 63,
    },
    {
      id: 10,
      name: "integument",
      type: "heading",
      label: "INTEGUMENT",
      editToggle: false,
      xs: 12,
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 64,
    },
    {
      id: 7,
      name: "ros_integument",
      type: "options_tree",
      label:
        "Integument Problems (Eczema, Psoriasis, Dermatitis, Urticaria, Other)",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 1,
          subQuestionId: 1,
          hasSubQuestions: true,
          editToggle: false,
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          isConfigured: true,
          type: "toggle_button",
          subQuestions: [
            {
              id: 6,
              name: "ros_integumentDiagnoses",
              value: "diagnoses",
              type: "options_tree",
              label: "Diagnoses",
              isMulti: true,
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 1,
                  subQuestionId: 1,
                  name: "ros_integumentBasilCellCarcinomaSubAnswer",
                  value: "basilCellCarcinoma",
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  label: " Basil Cell Carcinoma ",
                  isConfigured: true,
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Basil Cell Carcinoma",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_integumentBasilCellCarcinomaSubAnswerDescribe",
                      value: "describe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_integumentBasilCellCarcinomaSubAnswerSupportedBy",
                      value: "supportedBy",
                      type: "options_tree",
                      isMulti: true,
                      label: "Supported by    ",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: " History ",
                          value: "history",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: " Symptoms ",
                          value: "symptoms",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: " Physical Findings ",
                          value: "physicalFindings",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: " Medications ",
                          value: "medications",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: " Test results ",
                          value: "testResults",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: " Image studies ",
                          value: "imageStudies",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: " Biopsy ",
                          value: "biopsy",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 8,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "DME",
                          value: "dme",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 9,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          name: "ros_integumentBasilCellCarcinomaSubOther",
                          type: "toggle_button",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              subQuestionId: 1,
                              name: "ros_integumentBasilCellCarcinomaSubOtherSubDesc",
                              value: "describe",
                              type: "text",
                              placeholder: "",
                              label: "Describe",
                              variant: "outlined",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              sm: 3,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: false,
                            },
                          ],
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Dermatitis",
                  name: "ros_integumentDermatitisSubAnswer",
                  value: "dermatitis",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Dermatitis",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_integumentDermatitisSubAnswerDescribe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_integumentDermatitisSubAnswerSupportedBy",
                      type: "options_tree",
                      label: "Supported by    ",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "History",
                          value: "history",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Symptoms",
                          value: "symptoms",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Physical Findings",
                          value: "physicalFindings",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Medications",
                          value: "medications",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Test results",
                          value: "testResults",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Image studies",
                          value: "imageStudies",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Biopsy",
                          value: "biopsy",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 8,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "DME",
                          value: "dme",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 9,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          name: "ros_integumentDermatitisSubOther",
                          type: "toggle_button",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              subQuestionId: 1,
                              name: "ros_integumentDermatitisSubOtherSubDesc",
                              value: "describe",
                              type: "text",
                              placeholder: "",
                              label: "Describe",
                              variant: "outlined",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              sm: 3,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: false,
                            },
                          ],
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_integumentDermatitisSubAnswerWhatType",
                      type: "options_tree",
                      value: "whatType",
                      label: "What type",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Contact",
                          value: "contact",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Stasis",
                          value: "stasis",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Drug induced",
                          value: "drugInduced",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Disease Induced",
                          value: "diseaseInduced",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Unspecified",
                          value: "unspecified",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 3,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Eczema",
                  value: "eczema",
                  name: "ros_integumentEczemaSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Eczema",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_integumentEczemaSubAnswerDescribe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_integumentEczemaSubAnswerSupportedBy",
                      type: "options_tree",
                      label: "Supported by    ",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "History",
                          value: "history",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Symptoms",
                          value: "symptoms",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Physical Findings",
                          value: "physicalFindings",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Medications",
                          value: "medications",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Test results",
                          value: "testResults",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Image studies",
                          value: "imageStudies",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Biopsy",
                          value: "biopsy",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 8,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "DME",
                          value: "dme",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 9,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                          name: "ros_integumentEczemaSubOther",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              subQuestionId: 1,
                              name: "ros_integumentEczemaSubOtherSubDesc",
                              value: "describe",
                              type: "text",
                              placeholder: "",
                              label: "Describe",
                              variant: "outlined",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              sm: 3,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: false,
                            },
                          ],
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Onychomycosis",
                  value: "onychomycosis",
                  name: "ros_integumentOnychomycosisSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Onychomycosis",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_integumentOnychomycosisSubAnswerDescribe",
                      type: "options_tree",
                      label: "Describe",
                      value: "describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_integumentOnychomycosisSubAnswerSupportedBy",
                      type: "options_tree",
                      label: "Supported by    ",
                      isMulti: true,
                      value: "supportedBy",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "History",
                          value: "history",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Symptoms",
                          value: "symptoms",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Physical Findings",
                          value: "physicalFindings",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Medications",
                          value: "medications",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Test results",
                          value: "testResults",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Image studies",
                          value: "imageStudies",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Biopsy",
                          value: "biopsy",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 8,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "DME",
                          value: "dme",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 9,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              subQuestionId: 1,
                              name: "ros_integumentOnychomycosisSubOtherSubDesc",
                              value: "describe",
                              type: "text",
                              placeholder: "",
                              label: "Describe",
                              variant: "outlined",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              sm: 3,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: false,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          name: "ros_integumentOnychomycosisSubOther",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 5,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Psoriasis",
                  value: "psoriasis",
                  name: "ros_integumentPsoriasisSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Psoriasis",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_integumentPsoriasisSubAnswerDescribe",
                      type: "options_tree",
                      value: "describe",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 7,
                      name: "ros_integumentPsoriasisSubAnswerSupportedBy",
                      type: "options_tree",
                      label: "Supported by    ",
                      isMulti: true,
                      value: "supportedBy",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "History",
                          value: "history",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Symptoms",
                          value: "symptoms",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Physical Findings",
                          value: "physicalFindings",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Medications",
                          value: "medications",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Test results",
                          value: "testResults",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Image studies",
                          value: "imageStudies",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Biopsy",
                          value: "biopsy",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 8,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "DME",
                          value: "dme",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 9,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                          name: "ros_integumentPsoriasisSubOther",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              subQuestionId: 1,
                              name: "ros_integumentPsoriasisSubOtherSubDesc",
                              value: "describe",
                              type: "text",
                              placeholder: "",
                              label: "Describe",
                              variant: "outlined",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              sm: 3,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: false,
                            },
                          ],
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                    },
                    {
                      id: 8,
                      name: "ros_integumentPsoriasisSubAnswerPsoriaticArthritis",
                      type: "options_tree",
                      value: "psoriaticArthritis",
                      label: "History of Psoriatic Arthritis",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Yes",
                          value: "yes",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 3,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 6,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Skin ulcer",
                  value: "skinUlcer",
                  name: "ros_integumentSkinUlcerSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Skin ulcer",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_integumentSkinUlcerSubAnswerDescribe",
                      type: "options_tree",
                      label: "Describe",
                      value: "describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_integumentSkinUlcerSubAnswerSupportedBy",
                      type: "options_tree",
                      isMulti: true,
                      label: " Supported by",
                      value: "supportedBy",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: " History ",
                          value: "history",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Symptoms",
                          value: "symptoms",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Physical Findings",
                          value: "physicalFindings",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Medications",
                          value: "medications",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Test results",
                          value: "testResults",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Image studies",
                          value: "imageStudies",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Biopsy",
                          value: "biopsy",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 8,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "DME",
                          value: "dme",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 9,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          name: "ros_integumentSkinUlcerSubOther",
                          type: "toggle_button",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              subQuestionId: 1,
                              name: "ros_integumentSkinUlcerSubOtherSubDesc",
                              value: "describe",
                              type: "text",
                              placeholder: "",
                              label: "Describe",
                              variant: "outlined",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              sm: 3,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: false,
                            },
                          ],
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                    },
                    {
                      id: 7,
                      name: "ros_integumentSkinUlcerSubAnswerEtiology",
                      type: "options_tree",
                      label: "Etiology",
                      value: "etiology",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Pressure ",
                          value: "pressure",
                          name: "ros_integumentSkinUlcerSubEtiology",
                          isConfigured: true,
                          type: "toggle_button",
                          subQuestions: [
                            {
                              name: "ros_integumentSkinUlcerSubEtiologySubDesc",
                              depth: 4,
                              type: "text",
                              label: "Stage and Location",
                              value: "stageAndLocation",
                              editToggle: false,
                              xs: 12,
                              required: false,
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                              hasSubQuestions: false,
                            },
                          ],
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Venous Statis",
                          value: "venousStatis",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Peripheral Vascular Disease",
                          value: "peripheralVascular",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Disease Induced",
                          value: "diseaseInduced",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Diabetic Vasculitis",
                          value: "diabeticVasculitis",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Diabitic Neuropathy",
                          value: "diabeticNeuropathy",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 3,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 7,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Tinea Pedis",
                  value: "tineaPedis",
                  name: "ros_integumentTineaPedis",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: " Tinea Pedis",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_integumentTineaPedisSubAnswerDescribe",
                      type: "options_tree",
                      value: "describe",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_integumentTineaPedisSubAnswerSupportedBy",
                      type: "options_tree",
                      value: "supportedBy",
                      isMulti: true,
                      label: " Supported by",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: " History ",
                          value: "history",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Symptoms",
                          value: "symptoms",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Physical Findings",
                          value: "physicalFindings",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Medications",
                          value: "medications",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Test results",
                          value: "testResults",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Image studies",
                          value: "imageStudies",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Biopsy",
                          value: "biopsy",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 8,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "DME",
                          value: "dme",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 9,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          name: "ros_integumentTineaPedisSubOther",
                          type: "toggle_button",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              subQuestionId: 1,
                              name: "ros_integumentTineaPedisSubOtherSubDesc",
                              value: "describe",
                              type: "text",
                              placeholder: "",
                              label: "Describe",
                              variant: "outlined",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              sm: 3,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: false,
                            },
                          ],
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 8,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Urticarial Disease",
                  value: "urticarialDisease",
                  name: "ros_integumentUrticarialDiseaseSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Urticarial Disease",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_integumentUrticarialDiseaseSubAnswerDescribe",
                      type: "options_tree",
                      value: "describe",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_integumentUrticarialDiseaseSubAnswerSupportedBy",
                      type: "options_tree",
                      value: "supportedBy",
                      isMulti: true,
                      label: " Supported by",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: " History ",
                          value: "history",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Symptoms",
                          value: "symptoms",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Physical Findings",
                          value: "physicalFindings",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Medications",
                          value: "medications",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Test results",
                          value: "testResults",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Image studies",
                          value: "imageStudies",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Biopsy",
                          value: "biopsy",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 8,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "DME",
                          value: "dme",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 9,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          name: "ros_integumentUrticarialDiseaseSubOther",
                          type: "toggle_button",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              subQuestionId: 1,
                              name: "ros_integumentUrticarialDiseaseSubOtherSubDesc",
                              value: "describe",
                              type: "text",
                              placeholder: "",
                              label: "Describe",
                              variant: "outlined",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              sm: 3,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: false,
                            },
                          ],
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                    },
                    {
                      id: 7,
                      name: "ros_integumentUrticarialDiseaseSubAnswerType",
                      type: "options_tree",
                      value: "type",
                      label: "Type",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Acute ",
                          value: "acute",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Chronic",
                          value: "chronic",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 3,
                      editToggle: false,
                    },
                    {
                      id: 4,
                      name: "ros_integumentUrticarialDiseaseSubAnswerEtiology",
                      depth: 3,
                      type: "text",
                      label: "Etiology",
                      value: "etiology",
                      editToggle: false,
                      xs: 12,
                      required: false,
                      placeholder: "",
                      defaultValue: "",
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      subQuestionId: 4,
                      hasSubQuestions: false,
                    },
                  ],
                },
                {
                  subQuestionId: 9,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Wound",
                  value: "wound",
                  name: "ros_integumentWoundSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Wound",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_integumentWoundSubAnswerDescribe",
                      type: "options_tree",
                      label: "Describe",
                      value: "describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_integumentWoundSubAnswerSupportedBy",
                      type: "options_tree",
                      value: "supportedBy",
                      label: " Supported by",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: " History ",
                          value: "history",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Symptoms",
                          value: "symptoms",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Physical Findings",
                          value: "physicalFindings",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Medications",
                          value: "medications",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Test results",
                          value: "testResults",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Image studies",
                          value: "imageStudies",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Biopsy",
                          value: "biopsy",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 8,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "DME",
                          value: "dme",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 9,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                          name: "ros_integumentWoundSubOther",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              subQuestionId: 1,
                              name: "ros_integumentWoundSubOtherSubDesc",
                              value: "describe",
                              type: "text",
                              placeholder: "",
                              label: "Describe",
                              variant: "outlined",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              sm: 3,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: false,
                            },
                          ],
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                    },
                    {
                      id: 7,
                      name: "ros_integumentWoundSubAnswerEtiology",
                      type: "options_tree",
                      label: "Etiology",
                      value: "etiology",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Surgical",
                          value: "surgical",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Traumatic",
                          value: "traumatic",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Burn",
                          value: "burn",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 3,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 10,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Other",
                  value: "other",
                  name: "ros_integumentOtherSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Other",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_integumentOthersSubAnswerDescribe",
                      value: "describe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_integumentOthersSubAnswerSupportedBy",
                      type: "options_tree",
                      label: " Supported by",
                      isMulti: true,
                      value: "supportedBy",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: " History ",
                          value: "history",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Symptoms",
                          value: "symptoms",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Physical Findings",
                          value: "physicalFindings",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Medications",
                          value: "medications",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Test results",
                          value: "testResults",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Image studies",
                          value: "imageStudies",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Biopsy",
                          value: "biopsy",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 8,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "DME",
                          value: "dme",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 9,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                          name: "ros_integumentIntegumentOtherSubOther",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              subQuestionId: 1,
                              name: "ros_integumentIntegumentOtherSubOtherSubDesc",
                              value: "describe",
                              type: "text",
                              placeholder: "",
                              label: "Describe",
                              variant: "outlined",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              sm: 3,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: false,
                            },
                          ],
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                    },
                    {
                      id: 4,
                      name: "ros_integumentOthersSubAnswerOther",
                      depth: 3,
                      type: "text",
                      label: "Other",
                      value: "other",
                      editToggle: false,
                      xs: 12,
                      required: false,
                      placeholder: "",
                      defaultValue: "",
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      subQuestionId: 3,
                      hasSubQuestions: false,
                    },
                  ],
                },
              ],
              subQuestionId: 1,
              editToggle: false,
            },
          ],
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 65,
      editToggle: false,
    },
    {
      id: 10,
      name: "integument_Recommendations",
      value: "takeMedicationsAsPescribed",
      label: "Recommendations",
      placeholder: "",
      editToggle: false,
      defaultValue: "",
      type: "checkbox",
      options: [
        ["Take medications as prescribed", "takeMedicationsAsPescribed"],
        ["Other", "other"],
      ],
      xs: 12,
      required: false,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      questionId: 66,
    },
    {
      id: 9,
      name: "endocrine",
      type: "heading",
      label: "ENDOCRINE",
      editToggle: false,
      xs: 12,
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 67,
      depth: 1,
    },
    {
      id: 6,
      name: "ros_endocrineIsEndocrine",
      type: "options_tree",
      label: "Endocrine Problems",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 2,
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "ros_endocrineIsEndocrineDiagnose",
              type: "options_tree",
              label: "Diagnoses",
              isMulti: true,
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 4,
                  subQuestionId: 1,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Chronic Kidney Disease secondary to Diabetes",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_endocrineIsEndocrineSubchronicKidneySubdescribeSub",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "active",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                          name: "historyOf",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                          name: "ruleOut",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                      depth: 5,
                    },
                    {
                      id: 6,
                      name: "ros_endocrineIsEndocrineSubchronicKidneySubsupportedBySub",
                      type: "options_tree",
                      label: "Supported by",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Decreased GFR",
                          value: "decreasedGfr",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "decreasedGfr",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Albuminuria",
                          value: "albuminuria",
                          type: "toggle_button",
                          name: "albuminuria",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Elevated BUN/Creatinine",
                          value: "elevatedBunCreatinine",
                          type: "toggle_button",
                          name: "elevatedBunCreatinine",
                          depth: 6,
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Dialysis",
                          value: "dialysis",
                          type: "toggle_button",
                          name: "dialysis",
                          depth: 6,
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 1,
                              name: "ros_endocrineIsEndocrineSubchronicKidneySubsupportedBySubotherSubdescribe",
                              type: "text",
                              label: "Describe",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                          name: "other",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                      depth: 5,
                    },
                    {
                      id: 6,
                      name: "ros_endocrineIsEndocrinechronicKidneySubpatientOnAceSubisPatientOnAce",
                      type: "options_tree",
                      label: "Patient on ACE or ARB",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Yes",
                          value: "yes",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "yes",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                          name: "no",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 3,
                      editToggle: false,
                      depth: 5,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  label: "Chronic Kidney Disease secondary to Diabetes",
                  value: "ckdstDiabetes",
                  isConfigured: true,
                  type: "toggle_button",
                  name: "ckdstDiabetes",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Coronary Artery Disease and Diabetes",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_endocrineIsEndocrineSubcoronaryArterySubdescribeSub",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "active",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                          name: "historyOf",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                          name: "ruleOut",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                      depth: 5,
                    },
                    {
                      id: 6,
                      name: "ros_endocrineIsEndocrineSubcoronaryArterySubsupportedBySub",
                      type: "options_tree",
                      label: "Supported by",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "History",
                          value: "history",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "history",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Symptoms",
                          value: "symptoms",
                          type: "toggle_button",
                          name: "symptoms",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Physical Findings",
                          value: "physicalFindings",
                          type: "toggle_button",
                          name: "physicalFindings",
                          depth: 6,
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Medications",
                          value: "medications",
                          type: "toggle_button",
                          name: "medications",
                          depth: 6,
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Test results",
                          value: "testResults",
                          type: "toggle_button",
                          name: "testResults",
                          depth: 6,
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Image studies",
                          value: "imageStudies",
                          type: "toggle_button",
                          name: "imageStudies",
                          depth: 6,
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Biopsy",
                          value: "biopsy",
                          type: "toggle_button",
                          name: "biopsy",
                          depth: 6,
                        },
                        {
                          subQuestionId: 8,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "DME",
                          value: "dme",
                          type: "toggle_button",
                          name: "dme",
                          depth: 6,
                        },
                        {
                          subQuestionId: 9,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 1,
                              name: "ros_endocrineIsEndocrineSubcoronaryArterySubsupportedBySubotherSubdescribe",
                              type: "text",
                              label: "Describe",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                          name: "other",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                      depth: 5,
                    },
                    {
                      id: 6,
                      name: "ros_endocrineIsEndocrinecoronaryArterySubpatientOnAStatinSubisPatientOnAStatin",
                      type: "options_tree",
                      label: "Is patient on an statin",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Yes",
                          value: "yes",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "yes",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                          name: "no",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 3,
                      editToggle: false,
                      depth: 5,
                    },
                    {
                      id: 6,
                      name: "ros_endocrineIsEndocrinecoronaryArterySubpatientOnAAspirinSubisPatientOnAAspirin",
                      type: "options_tree",
                      label: "Is patient on an aspirin",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Yes",
                          value: "yes",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "yes",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                          name: "no",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 4,
                      editToggle: false,
                      depth: 5,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Coronary Artery Disease and Diabetes",
                  value: "coronaryArteryDiseaseAndDiabet",
                  type: "toggle_button",
                  name: "coronaryArteryDiseaseAndDiabet",
                  depth: 4,
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Cushing's Disease",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_endocrineIsEndocrineSubcushingsDiseaseSubdescribeSub",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "active",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                          name: "historyOf",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                          name: "ruleOut",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                      depth: 5,
                    },
                    {
                      id: 6,
                      name: "ros_endocrineIsEndocrineSubcushingsDiseaseSubsupportedBySub",
                      type: "options_tree",
                      label: "Supported by",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Physical exam",
                          value: "physicalExam",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "physicalExam",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Lab tests",
                          value: "labTests",
                          type: "toggle_button",
                          name: "labTests",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Suppression Test",
                          value: "suppressionTest",
                          type: "toggle_button",
                          name: "suppressionTest",
                          depth: 6,
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 1,
                              name: "ros_endocrineIsEndocrineSubcushingsDiseaseSubsupportedBySubotherSubdescribe",
                              type: "text",
                              label: "Describe",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                          name: "other",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                      depth: 5,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Cushing's Disease",
                  value: "cushingsDisease",
                  type: "toggle_button",
                  name: "cushingsDisease",
                  depth: 4,
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Diabetes",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_endocrineIsEndocrineSubdiabetesSubdescribeSub",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "active",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of ",
                          value: "historyOf",
                          type: "toggle_button",
                          name: "historyOf",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                          name: "ruleOut",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                      depth: 5,
                    },
                    {
                      id: 6,
                      name: "ros_endocrineIsEndocrineSubdiabetesSubsupportedBySub",
                      type: "options_tree",
                      label: "Supported by",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Symptoms",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 6,
                              name: "ros_endocrineIsEndocrineSubdiabetesSubsupportedBySubsymptomsSub",
                              type: "options_tree",
                              label: "Describe",
                              fullWidth: true,
                              isMulti: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Increased Thirst",
                                  value: "increasedThirst",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Shakiness",
                                  value: "shakiness",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Sweating",
                                  value: "sweating",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 4,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Blurred vision",
                                  value: "blurredVision",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 1,
                              editToggle: false,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Symptoms",
                          value: "symptoms",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "symptoms",
                        },

                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Physical findings",
                          value: "physicalFindings",
                          type: "toggle_button",
                          name: "physicalFindings",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Lab tests",
                          value: "labTests",
                          type: "toggle_button",
                          name: "labTests",
                          depth: 6,
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Medications",
                          value: "medications",
                          type: "toggle_button",
                          name: "medications",
                          depth: 6,
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 1,
                              name: "ros_endocrineIsEndocrineSubdiabetesSubsupportedByotherdescribe",
                              type: "text",
                              label: "Describe",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                          name: "other",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                      depth: 5,
                    },
                    {
                      id: 6,
                      name: "ros_endocrineIsEndocrineSubdiabetesSubtype",
                      type: "options_tree",
                      label: "Type",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Type 1",
                          value: "type1",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "type1",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Type 1.5",
                          value: "type15",
                          type: "toggle_button",
                          name: "type15",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Type 2",
                          value: "type2",
                          type: "toggle_button",
                          name: "type2",
                          depth: 6,
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Gestational",
                          value: "gestational",
                          type: "toggle_button",
                          name: "gestational",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 3,
                      editToggle: false,
                      depth: 5,
                    },
                    {
                      id: 1,
                      name: "ros_endocrineIsEndocrineSubdiabetesSubrecentHbAonecValue",
                      type: "text",
                      label: "Most recent Hb A1C, value",
                      editToggle: false,
                      xs: 4,
                      required: "no",
                      placeholder: "",
                      defaultValue: "",
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      subQuestionId: 4,
                      depth: 5,
                    },
                    {
                      id: 4,
                      name: "ros_endocrineIsEndocrineSubdiabetesSubandDate",
                      label: "And Date",
                      placeholder: "",
                      variant: "1",
                      type: "text",
                      editToggle: false,
                      xs: 4,
                      defaultValue: "2024-03-01",
                      value: "2024-03-01",
                      fullWidth: true,
                      required: "no",
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      subQuestionId: 5,
                      depth: 5,
                    },
                    {
                      id: 6,
                      name: "ros_endocrineIsEndocrineSubdiabetesSubdieticianSubisDietician",
                      type: "options_tree",
                      label:
                        "Met with a nurse or dietician for diabetic education",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Yes",
                          value: "yes",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "yes",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                          name: "no",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 6,
                      editToggle: false,
                      depth: 5,
                    },
                    {
                      id: 6,
                      name: "ros_endocrineIsEndocrineSubdiabeticEducatorSubisDiabeticEducator",
                      type: "options_tree",
                      label: "Met with a diabetic educator",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_endocrineIsEndocrineSubdiabetesSubdiabeticEducatorSub",
                              type: "options_tree",
                              label: "Treatment includes",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Diet",
                                  value: "diet",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Oral Hypoglycemic Agent",
                                  value: "oralHypoglycemic",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Insulin",
                                  value: "insulin",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 4,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Exercise",
                                  value: "exercise",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 5,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Weight Loss",
                                  value: "weightloss",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 1,
                              editToggle: false,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Yes",
                          value: "yes",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "yes",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                          name: "no",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 7,
                      editToggle: false,
                      depth: 5,
                    },
                    {
                      id: 6,
                      name: "ros_endocrineIsEndocrineSubdiabetesSubbloodSugarSubisbloodSugar",
                      type: "options_tree",
                      label: "Do you test your blood sugar",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Yes",
                          value: "yes",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "yes",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                          name: "no",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 8,
                      editToggle: false,
                      depth: 5,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Diabetes",
                  value: "diabetes",
                  type: "toggle_button",
                  name: "diabetes",
                  depth: 4,
                },
                {
                  subQuestionId: 5,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Diabetic Retinopathy",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_endocrineIsEndocrineSubdiabetesRetinopathySubdescribeSub",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "active",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                          name: "historyOf",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                          name: "ruleOut",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                      depth: 5,
                    },
                    {
                      id: 6,
                      name: "ros_endocrineIsEndocrineSubdiabeticRetinopathySubtypeSub",
                      type: "options_tree",
                      label: "Type",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Proliferative",
                          value: "proliferative",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "proliferative",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Non -proliferative",
                          value: "nonProliferative",
                          type: "toggle_button",
                          name: "nonProliferative",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Unknown",
                          value: "unknown",
                          type: "toggle_button",
                          name: "unknown",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                      depth: 5,
                    },
                    {
                      id: 6,
                      name: "ros_endocrineIsEndocrineSubdiabeticRetinopathySubspecifySub",
                      type: "options_tree",
                      label: "Specify",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Right eye",
                          value: "rightEye",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "rightEye",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Left eye",
                          value: "leftEye",
                          type: "toggle_button",
                          name: "leftEye",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Bilateral",
                          value: "bilateral",
                          type: "toggle_button",
                          name: "bilateral",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 3,
                      editToggle: false,
                      depth: 5,
                    },
                    {
                      id: 6,
                      name: "ros_endocrineIsEndocrineSubdiabeticRetinopathySubsupportedBySub",
                      type: "options_tree",
                      label: "Supported by",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Funduscopic exam",
                          value: "funduscopicExam",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "funduscopicExam",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Vision loss",
                          value: "visionLoss",
                          type: "toggle_button",
                          name: "visionLoss",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Laser Therapy",
                          value: "laserTherapy",
                          type: "toggle_button",
                          name: "laserTherapy",
                          depth: 6,
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Retinal injections",
                          value: "retinalInjections",
                          type: "toggle_button",
                          name: "retinalInjections",
                          depth: 6,
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Surgical procedure",
                          value: "surgicalProcedure",
                          type: "toggle_button",
                          name: "surgicalProcedure",
                          depth: 6,
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 1,
                              name: "ros_endocrineIsEndocrineSubdiabeticRetinopathySubsupportedBySubotherSubdescribe",
                              type: "text",
                              label: "Describe",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                          name: "other",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 4,
                      editToggle: false,
                      depth: 5,
                    },
                    {
                      id: 6,
                      name: "ros_endocrineIsEndocrineSubdiabeticRetinopathySubophthalmologistSub",
                      type: "options_tree",
                      label: "Patient sees Ophthalmologist",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Occasionally",
                          value: "occasionally",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "occasionally",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Once a year",
                          value: "onceAYear",
                          type: "toggle_button",
                          name: "onceAYear",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Twice a year",
                          value: "twiceAYear",
                          type: "toggle_button",
                          name: "twiceAYear",
                          depth: 6,
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: ">Twice a year",
                          value: "gtwiceAYear",
                          type: "toggle_button",
                          name: "gtwiceAYear",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 5,
                      editToggle: false,
                      depth: 5,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Diabetic Retinopathy",
                  value: "diabeticRetinopathy",
                  type: "toggle_button",
                  name: "diabeticRetinopathy",
                  depth: 4,
                },
                {
                  subQuestionId: 6,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Secondary Hyperparathyroidism",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubdescribeSub",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Active",
                          value: "active",
                          type: "toggle_button",
                          name: "active",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                          name: "historyOf",
                          depth: 6,
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                          name: "ruleOut",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                      depth: 5,
                    },
                    {
                      id: 6,
                      name: "ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubsupportedBySub",
                      type: "options_tree",
                      label: "Supported by",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "History Chronic Kidney Disease",
                          value: "historyChronicKidneyDisease",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "historyChronicKidneyDisease",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History Celiac Disease",
                          value: "historyCeliacDisease",
                          type: "toggle_button",
                          name: "historyCeliacDisease",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History VitaminD Deficiency",
                          value: "vitaminDDeficiency",
                          type: "toggle_button",
                          name: "vitaminDDeficiency",
                          depth: 6,
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Malabsorption",
                          value: "malabsorption",
                          type: "toggle_button",
                          name: "malabsorption",
                          depth: 6,
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Bariatric Surgery",
                          value: "bariatricSurgery",
                          type: "toggle_button",
                          name: "bariatricSurgery",
                          depth: 6,
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Lab tests",
                          value: "labTests",
                          type: "toggle_button",
                          name: "labTests",
                          depth: 6,
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of Kidney stones",
                          value: "histroyOfKidneyStones",
                          type: "toggle_button",
                          name: "histroyOfKidneyStones",
                          depth: 6,
                        },
                        {
                          subQuestionId: 8,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of Fractures",
                          value: "historyOfFractures",
                          type: "toggle_button",
                          name: "historyOfFractures",
                          depth: 6,
                        },
                        {
                          subQuestionId: 9,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Imaging studies",
                          value: "imagingStudies",
                          type: "toggle_button",
                          name: "imagingStudies",
                          depth: 6,
                        },
                        {
                          subQuestionId: 10,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Fatigue",
                          value: "fatigue",
                          type: "toggle_button",
                          name: "fatigue",
                          depth: 6,
                        },
                        {
                          subQuestionId: 11,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 1,
                              name: "ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubsupportedBySubotherdescribe",
                              type: "text",
                              label: "Describe",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                          name: "other",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                      depth: 5,
                    },
                    {
                      id: 6,
                      name: "ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubkidneyDiseaseSubisKidneyDisease",
                      type: "options_tree",
                      label: "Due to kidney disease?",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Yes",
                          value: "yes",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "yes",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                          name: "no",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Unknown",
                          value: "unknown",
                          type: "toggle_button",
                          name: "unknown",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 3,
                      editToggle: false,
                      depth: 5,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Secondary Hyperparathyroidism",
                  value: "secondaryHyperparathyroidism",
                  type: "toggle_button",
                  name: "secondaryHyperparathyroidism",
                  depth: 4,
                },
                {
                  subQuestionId: 7,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Hypertension and Diabetes",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_endocrineIsEndocrineSubhypertensionDiabetesSubdescribeSub",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "active",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of ",
                          value: "historyOf",
                          type: "toggle_button",
                          name: "historyOf",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                          name: "ruleOut",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                      depth: 5,
                    },
                    {
                      id: 6,
                      name: "ros_endocrineIsEndocrineSubhypertensionDiabetesSubsupportedBySub",
                      type: "options_tree",
                      label: "Supported by",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "History",
                          value: "history",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "history",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Symptoms",
                          value: "symptoms",
                          type: "toggle_button",
                          name: "symptoms",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Phyiscal Findings",
                          value: "phyiscalFindings",
                          type: "toggle_button",
                          name: "phyiscalFindings",
                          depth: 6,
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Medications",
                          value: "medications",
                          type: "toggle_button",
                          name: "medications",
                          depth: 6,
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Test results",
                          value: "testResults",
                          type: "toggle_button",
                          name: "testResults",
                          depth: 6,
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Image studies",
                          value: "imageStudies",
                          type: "toggle_button",
                          name: "imageStudies",
                          depth: 6,
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Biopsy",
                          value: "biopsy",
                          type: "toggle_button",
                          name: "biopsy",
                          depth: 6,
                        },
                        {
                          subQuestionId: 8,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "DME",
                          value: "dme",
                          type: "toggle_button",
                          name: "dme",
                          depth: 6,
                        },
                        {
                          subQuestionId: 9,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 1,
                              name: "ros_endocrineIsEndocrineSubhypertensionDiabetesSubsupportedBySubotherSubAnswer",
                              type: "text",
                              label: "Describe",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                          name: "other",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                      depth: 5,
                    },
                    {
                      id: 6,
                      name: "ros_endocrineIsEndocrineSubhypertensionDiabetesSubisPatientOnAce",
                      type: "options_tree",
                      label: "Is patient on Ace or ARB",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Yes",
                          value: "yes",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "yes",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                          name: "no",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 3,
                      editToggle: false,
                      depth: 5,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Hypertension and Diabetes",
                  value: "hypertensionAndDiabetes",
                  type: "toggle_button",
                  name: "hypertensionAndDiabetes",
                  depth: 4,
                },
                {
                  subQuestionId: 8,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Hyperthyroidism",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_endocrineIsEndocrineSubhyperthyroidismSubdescribeSub",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "active",
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                          name: "historyOf",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                          name: "ruleOut",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 1,
                      depth: 5,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_endocrineIsEndocrineSubhyperthyroidismSubsupportedBySub",
                      type: "options_tree",
                      label: "Supported by",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "rapidHeartBeat",
                          label: "Rapid Heart Beat",
                          value: "rapidHeartBeat",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Nervousness",
                          value: "nervousness",
                          type: "toggle_button",
                          name: "nervousness",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Weight Loss",
                          value: "weightLoss",
                          type: "toggle_button",
                          name: "weightLoss",
                          depth: 6,
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Heat intolerance",
                          value: "heatIntolerance",
                          type: "toggle_button",
                          name: "heatIntolerance",
                          depth: 6,
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Tremor",
                          value: "tremor",
                          type: "toggle_button",
                          name: "tremor",
                          depth: 6,
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Lab Data",
                          value: "labData",
                          type: "toggle_button",
                          name: "labData",
                          depth: 6,
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of treatment for Hyperthyroidism",
                          value: "historyOfTreatmentForHyperthyr",
                          type: "toggle_button",
                          name: "historyOfTreatmentForHyperthyr",
                          depth: 6,
                        },
                        {
                          subQuestionId: 8,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Hyper Reflexes",
                          value: "hyperReflexes",
                          type: "toggle_button",
                          name: "hyperReflexes",
                          depth: 6,
                        },
                        {
                          subQuestionId: 9,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 1,
                              name: "ros_endocrineIsEndocrineSubhyperthyroidismSubsupportedBySubotherSubdescribe",
                              type: "text",
                              label: "Describe",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                          name: "other",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 2,
                      depth: 5,
                      editToggle: false,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Hyperthyroidism",
                  value: "hyperthyroidism",
                  type: "toggle_button",
                  name: "hyperthyroidism",
                  depth: 4,
                },
                {
                  subQuestionId: 9,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Hypothyroidism",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_endocrineIsEndocrineSubhypothyroidismSubdescribeSub",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "active",
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                          name: "historyOf",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                          name: "ruleOut",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 1,
                      depth: 5,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_endocrineIsEndocrineSubhypothyroidismSubsupportedBySub",
                      type: "options_tree",
                      label: "Supported by",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "weightGain",
                          label: "Weight Gain",
                          value: "weightGain",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Fatigue",
                          value: "fatigue",
                          type: "toggle_button",
                          name: "fatigue",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Hair Changes",
                          value: "hairChanges",
                          type: "toggle_button",
                          name: "hairChanges",
                          depth: 6,
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Depression",
                          value: "depression",
                          type: "toggle_button",
                          name: "depression",
                          depth: 6,
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Treatment for hypothyroidism",
                          value: "treatmentForHypothyroidism",
                          type: "toggle_button",
                          name: "treatmentForHypothyroidism",
                          depth: 6,
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Lab data ",
                          value: "labData",
                          type: "toggle_button",
                          name: "labData",
                          depth: 6,
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 1,
                              name: "ros_endocrineIsEndocrineSubhypothyroidismSubsupportedBySubotherSubdescribe",
                              type: "text",
                              label: "Describe",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                          name: "other",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 2,
                      depth: 5,
                      editToggle: false,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Hypothyroidism",
                  value: "hypothyroidism",
                  type: "toggle_button",
                  name: "hypothyroidism",
                  depth: 4,
                },
                {
                  subQuestionId: 10,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Peripheral Neuropathy secondary to Diabetes",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathyDescribe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "active",
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                          name: "historyOf",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                          name: "ruleOut",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 1,
                      depth: 5,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathySup",
                      type: "options_tree",
                      label: "Supported by",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "physicalExam",
                          label: "Physical exam",
                          value: "physicalExam",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Medication",
                          value: "medication",
                          type: "toggle_button",
                          name: "medication",
                          depth: 6,
                        },
                        // {
                        //   subQuestionId: 3,
                        //   hasSubQuestions: false,
                        //   subQuestions: [],
                        //   editToggle: false,
                        //   isCommentEnabled: false,
                        //   isConfigured: true,
                        //   isModified: false,
                        //   label: "Symptoms",
                        //   value: "symptoms",
                        //   type: "toggle_button",
                        //   name: "symptoms",
                        //   depth: 6,
                        // },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Symptoms",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 6,
                              name: "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathySupSymptomsSub",
                              type: "options_tree",
                              label: "Describe",
                              isMulti: true,
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Numbness",
                                  value: "numbness",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Pain",
                                  value: "pain",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Burning",
                                  value: "burning",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 4,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Decreased sensation to legs or feet",
                                  value: "decreasedSensation",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 1,
                              editToggle: false,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Symptoms",
                          value: "symptoms",
                          type: "toggle_button",
                          name: "symptoms",
                          depth: 6,
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 1,
                              name: "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathySupOtherSubAnswer",
                              type: "text",
                              label: "Describe",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                          name: "other",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 2,
                      depth: 5,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatrist",
                      type: "options_tree",
                      label: "Patient sees Podiatrist",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatristSub",
                              type: "options_tree",
                              label: "How often",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 6,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Once a year",
                                  value: "onceAYear",
                                  isConfigured: true,
                                  type: "toggle_button",
                                  name: "onceAYear",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Twice a year",
                                  value: "twiceAYear",
                                  type: "toggle_button",
                                  name: "twiceAYear",
                                  depth: 6,
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Quarterly",
                                  value: "quarterly",
                                  type: "toggle_button",
                                  name: "quarterly",
                                  depth: 6,
                                },
                              ],
                              subQuestionId: 1,
                              editToggle: false,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "yes",
                          label: "Yes",
                          value: "yes",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                          name: "no",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 3,
                      depth: 5,
                      editToggle: false,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Peripheral Neuropathy secondary to Diabetes",
                  value: "peripheralNeuropathySecondaryT",
                  type: "toggle_button",
                  name: "peripheralNeuropathySecondaryT",
                  depth: 4,
                },
                {
                  subQuestionId: 11,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label:
                        "Peripheral Vascular Disease secondary to Diabetes",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_endocrineIsEndocrineDiagnoseperipheralVascularDescribe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "active",
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of ",
                          value: "historyOf",
                          type: "toggle_button",
                          name: "historyOf",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                          name: "ruleOut",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 1,
                      depth: 5,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_endocrineIsEndocrineDiagnoseperipheralVascularSup",
                      type: "options_tree",
                      label: "Supported by",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "physicalExam",
                          label: "Physical exam",
                          value: "physicalExam",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Vascular studies",
                          value: "vascularStudies",
                          type: "toggle_button",
                          name: "vascularStudies",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Skin lesions",
                          value: "skinLesions",
                          type: "toggle_button",
                          name: "skinLesions",
                          depth: 6,
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [{}],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Foot deformity",
                          value: "footDeformity",
                          type: "toggle_button",
                          name: "footDeformity",
                          depth: 6,
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Surgical procedures",
                          value: "surgicalProcedures",
                          type: "toggle_button",
                          name: "surgicalProcedures",
                          depth: 6,
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Intermittent claudication",
                          value: "intermittentClaudication",
                          type: "toggle_button",
                          name: "intermittentClaudication",
                          depth: 6,
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 1,
                              name: "ros_endocrineIsEndocrineDiagnoseperipheralVascularSupOtherSubAnswer",
                              type: "text",
                              label: "Describe",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                          name: "other",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 2,
                      depth: 5,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatrist",
                      type: "options_tree",
                      label: "Patient sees Podiatrist",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatristSub",
                              type: "options_tree",
                              label: "How often",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 6,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Once a year",
                                  value: "onceAYear",
                                  isConfigured: true,
                                  type: "toggle_button",
                                  name: "onceAYear",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Twice a year",
                                  value: "twiceAYear",
                                  type: "toggle_button",
                                  name: "twiceAYear",
                                  depth: 6,
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Quarterly",
                                  value: "quarterly",
                                  type: "toggle_button",
                                  name: "quarterly",
                                  depth: 6,
                                },
                              ],
                              subQuestionId: 1,
                              editToggle: false,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "yes",
                          label: "Yes",
                          value: "yes",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                          name: "no",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 3,
                      depth: 5,
                      editToggle: false,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Peripheral Vascular Disease secondary to Diabetes",
                  value: "pvdstDiabetes",
                  type: "toggle_button",
                  name: "pvdstDiabetes",
                  depth: 4,
                },
                {
                  subQuestionId: 12,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Hyperparathyroidism",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_endocrineIsEndocrineDiagnosehyperparathyroidismDescribe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "active",
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                          name: "historyOf",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                          name: "ruleOut",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 1,
                      depth: 5,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_endocrineIsEndocrineDiagnosehyperparathyroidismSup",
                      type: "options_tree",
                      label: "Supported by",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "labTests",
                          label: "Lab tests",
                          value: "labTests",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of kidney stones",
                          value: "historyOfKidneyStones",
                          type: "toggle_button",
                          name: "historyOfKidneyStones",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of Fractures",
                          value: "historyOfFractures",
                          type: "toggle_button",
                          name: "historyOfFractures",
                          depth: 6,
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Imaging studies",
                          value: "imagingStudies",
                          type: "toggle_button",
                          name: "imagingStudies",
                          depth: 6,
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Fatigue",
                          value: "fatigue",
                          type: "toggle_button",
                          name: "fatigue",
                          depth: 6,
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 1,
                              name: "ros_endocrineIsEndocrineDiagnosehyperparathyroidismSupOtherSubAnswer",
                              type: "text",
                              label: "Describe",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                          name: "other",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 2,
                      depth: 5,
                      editToggle: false,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Hyperparathyroidism",
                  value: "hyperparathyroidism",
                  type: "toggle_button",
                  name: "hyperparathyroidism",
                  depth: 4,
                },
                {
                  subQuestionId: 13,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Other",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_endocrineIsEndocrineDiagnoseotherDescribe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "active",
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                          name: "historyOf",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [{}],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                          name: "ruleOut",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 1,
                      depth: 5,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_endocrineIsEndocrineDiagnoseotherSup",
                      type: "options_tree",
                      label: "Supported by",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "history",
                          label: "History",
                          value: "history",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Symptoms",
                          value: "symptoms",
                          type: "toggle_button",
                          name: "symptoms",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Physical Findings",
                          value: "physicalFindings",
                          type: "toggle_button",
                          name: "physicalFindings",
                          depth: 6,
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Medications",
                          value: "medications",
                          type: "toggle_button",
                          name: "medications",
                          depth: 6,
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Test results",
                          value: "testResults",
                          type: "toggle_button",
                          name: "testResults",
                          depth: 6,
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Image studies",
                          value: "imageStudies",
                          type: "toggle_button",
                          name: "imageStudies",
                          depth: 6,
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Biopsy",
                          value: "biopsy",
                          type: "toggle_button",
                          name: "biopsy",
                          depth: 6,
                        },
                        {
                          subQuestionId: 8,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "DME",
                          value: "dme",
                          type: "toggle_button",
                          name: "dme",
                          depth: 6,
                        },
                        {
                          subQuestionId: 9,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 1,
                              name: "ros_endocrineIsEndocrineDiagnoseotherSupOtherSubAnswer",
                              type: "text",
                              label: "Describe",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                          name: "other",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 2,
                      depth: 5,
                      editToggle: false,
                    },
                    {
                      id: 1,
                      name: "ros_endocrineIsEndocrineDiagnoseotherOther",
                      type: "text",
                      label: "Other",
                      editToggle: false,
                      xs: 4,
                      required: "no",
                      placeholder: "",
                      defaultValue: "",
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      subQuestionId: 3,
                      depth: 5,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Other",
                  value: "other",
                  type: "toggle_button",
                  name: "other",
                  depth: 4,
                },
              ],
              subQuestionId: 1,
              editToggle: false,
              depth: 3,
            },
          ],
          editToggle: false,
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          isConfigured: true,
          type: "toggle_button",
          name: "yes",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "No",
          value: "no",
          type: "toggle_button",
          name: "no",
          depth: 2,
        },
      ],
      questionId: 68,
      editToggle: false,
      depth: 1,
    },

    {
      id: 10,
      name: "ros_endocrineIsEndocrineRecommendations",
      label: "Recommendations",
      placeholder: "",
      editToggle: false,
      defaultValue: "",
      type: "checkbox",
      options: [
        ["Take medications as prescribed", "takeMedicationsAsPescribedEndo"],
        ["Check Blood Sugar", "checkBloodSugarEndo"],
        ["Other", "otherEndo"],
      ],
      xs: 12,
      required: false,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      questionId: 69,
      depth: 1,
    },
    {
      id: 10,
      name: "hematology",
      type: "heading",
      label: "HEMATOLOGY / IMMUNOLOGY / INFECTIOUS DISEASE",
      editToggle: false,
      xs: 12,
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 70,
    },
    {
      id: 8,
      name: "ros_hematology",
      type: "options_tree",
      label:
        "Hematology / Immunology / Infection Disease Problems (Anemia, easy bruising or abnormal bleeding Thrombocytopenia , Other)",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 1,
          questionId: 3,
          hasSubQuestions: true,
          editToggle: false,
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          isConfigured: true,
          type: "toggle_button",
          subQuestions: [
            {
              id: 6,
              name: "ros_hematologyDiagnoses",
              value: "diagnoses",
              type: "options_tree",
              label: "Diagnoses",
              fullWidth: true,
              isMulti: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 1,
                  subQuestionId: 1,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  label: "AIDS",
                  value: "aids",
                  name: "ros_hematologyAidsSubAnswer",
                  isConfigured: true,
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "AIDS",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_hematologyAidsSubAnswerDescribe",
                      value: "describe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_hematologyAidsSubAnswerSupportedBy",
                      value: "supportedBy",
                      type: "options_tree",
                      isMulti: true,
                      label: "Supported by    ",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Symptoms",
                          value: "symptoms",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Physical findings",
                          value: "physicalFindings",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of opportunistic infections",
                          value: "historyOfOpportunistic",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Medications",
                          value: "medications",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                          name: "ros_hematologyAidsSubOther",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              subQuestionId: 1,
                              hasSubQuestions: false,
                              editToggle: false,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: false,
                              label: "Describe",
                              name: "ros_hematologyAidsSubOtherSubDesc",
                              value: "describe",
                              type: "text",
                              xs: 12,
                            },
                          ],
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                    },
                    {
                      id: 7,
                      name: "ros_hematologyAidsSubAnswerPatientUnderTreatment",
                      value: "patientUnderTreatment",
                      type: "options_tree",
                      label: "Is patient currently under treatment",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          name: "ros_hematologyAidsSubPatientUnderTreatment",
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Yes",
                          value: "yes",
                          isConfigured: true,
                          type: "toggle_button",
                          subQuestions: [
                            {
                              subQuestionId: 1,
                              hasSubQuestions: false,
                              editToggle: false,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: false,
                              xs: 12,
                              label: "Where",
                              name: "ros_hematologyAidsSubPatientUnderTreatmentSubWhere",
                              value: "where",
                              type: "text",
                              depth: 3,
                            },
                          ],
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 3,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Anemia",
                  name: "ros_hematologyAnemiaSubAnswer",
                  value: "anemia",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Anemia",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_hematologyAnemiaSubAnswerDescribe",
                      value: "describe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_hematologyAnemiaSubAnswerSupportedBy",
                      value: "supportedBy",
                      type: "options_tree",
                      label: "Supported by    ",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Lab tests",
                          value: "labTests",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          depth: 1,
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Symptoms",
                          value: "symptoms",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of blood transfusion",
                          value: "historyOfBloodTransfusion",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          name: "ros_hematologyAnemiaSubother",
                          type: "toggle_button",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              subQuestionId: 1,
                              hasSubQuestions: false,
                              editToggle: false,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: false,
                              label: "Describe",
                              value: "describe",
                              name: "ros_hematologyAnemiaSubotherSubDesc",
                              type: "text",
                              xs: 12,
                            },
                          ],
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_hematologyAnemiaSubAnswerEtiology",
                      value: "etiology",
                      type: "options_tree",
                      label: "Etiology",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Iron deficiency",
                          value: "ironDeficiency",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          depth: 1,
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Pernicious",
                          value: "pernicious",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          depth: 1,
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Kidney Disease",
                          value: "kidneyDisease",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          depth: 1,
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Hemolysis",
                          value: "hemolysis",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          depth: 1,
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Aplastic",
                          value: "aplastic",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          depth: 1,
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Chemotherapy",
                          value: "chemotherapy",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          depth: 1,
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Blood loss",
                          value: "bloodLoss",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          depth: 1,
                          subQuestionId: 8,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Chronic Disease",
                          value: "chronicDisease",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          depth: 1,
                          subQuestionId: 9,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Folate Deficiency",
                          value: "folateDeficiency",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          depth: 1,
                          subQuestionId: 10,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Other",
                          value: "other",
                          name: "ros_hematologyAnemiaSubEtiologySubOther",
                          isConfigured: true,
                          type: "toggle_button",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              subQuestionId: 1,
                              hasSubQuestions: false,
                              editToggle: false,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: false,
                              name: "ros_hematologyAnemiaSubEtiologySubOtherSubDesc",
                              label: "Describe",
                              value: "describe",
                              type: "text",
                              xs: 12,
                            },
                          ],
                        },
                      ],
                      subQuestionId: 3,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_hematologyAnemiaSubAnswerYesPatientOn",
                      value: "yesPatientOn",
                      type: "options_tree",
                      label: "If yes, Patient on",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Iron",
                          value: "iron",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          depth: 1,
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "B 12",
                          value: "bTwelve",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          depth: 1,
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Folic Acid",
                          value: "folicAcid",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          depth: 1,
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Blood Transfusions",
                          value: "bloodTransfusions",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          depth: 1,
                          subQuestionId: 5,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Other",
                          value: "other",
                          name: "ros_hematologyAnemiaSubYesPatientOn",
                          isConfigured: true,
                          type: "toggle_button",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              depth: 2,
                              subQuestionId: 1,
                              hasSubQuestions: false,
                              subQuestions: [],
                              editToggle: false,
                              isCommentEnabled: false,
                              label: "Describe",
                              value: "describe",
                              isConfigured: true,
                              name: "ros_hematologyAnemiaSubYesPatientOnSubDesc",
                              type: "text",
                              xs: 12,
                            },
                          ],
                        },
                      ],
                      subQuestionId: 4,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "C. Difficile",
                  value: "cDifficile",
                  name: "ros_hematologyCDifficileSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "C. Difficile",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_hematologyCDifficileSubAnswerDescribe",
                      value: "describe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          subQuestions: [],
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                          subQuestions: [],
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_hematologyCDifficileSubAnswerSupportedBy",
                      type: "options_tree",
                      value: "supportedBy",
                      label: "Supported by",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Positive lab test",
                          value: "positiveLabTest",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Symptoms",
                          value: "symptoms",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Hospitalization",
                          value: "hospitalization",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Medication",
                          value: "medication",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                          name: "ros_hematologyCDifficileSubOther",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              depth: 1,
                              subQuestionId: 1,
                              editToggle: false,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: false,
                              label: "Describe",
                              value: "describe",
                              name: "ros_hematologyCDifficileSubOtherSubDesc",
                              type: "text",
                              xs: 12,
                            },
                          ],
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Community Acquired MRSA Infection",
                  value: "communityAcquired",
                  name: "ros_hematologyCommunityAcquiredSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Community Acquired MRSA Infection",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_hematologyCommunityAcquiredSubAnswerDescribe",
                      value: "describe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_hematologyCommunityAcquiredSubAnswerSupportedBy",
                      value: "supportedBy",
                      type: "options_tree",
                      label: "Supported by    ",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Cultures",
                          value: "cultures",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Hospitalization",
                          value: "hospitalization",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Medications",
                          value: "medications",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Physical findings",
                          value: "physicalFindings",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          name: "ros_hematologyCommunityAcquiredSubOther",
                          type: "options_tree",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              depth: 1,
                              subQuestionId: 1,
                              editToggle: false,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: false,
                              label: "Describe",
                              value: "describe",
                              name: "ros_hematologyCommunityAcquiredSubOtherSubDesc",
                              type: "text",
                              xs: 12,
                            },
                          ],
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 5,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "HIV",
                  value: "hiv",
                  name: "ros_hematologyHivSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "HIV",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_hematologyHivSubAnswerDescribe",
                      type: "options_tree",
                      value: "describe",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 7,
                      name: "ros_hematologyHivSubAnswerSupportedBy",
                      type: "options_tree",
                      value: "supportedBy",
                      isMulti: true,
                      label: "Supported by    ",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Lab tests",
                          value: "labTests",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Symptoms",
                          value: "symptoms",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Medications",
                          value: "medications",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                          name: "ros_hematologyHivSubOther",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              subQuestionId: 1,
                              hasSubQuestions: false,
                              editToggle: false,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: false,
                              label: "Describe",
                              value: "describe",
                              name: "ros_hematologyHivSubOtherSubDesc",
                              type: "text",
                              xs: 12,
                            },
                          ],
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                    },
                    {
                      id: 4,
                      name: "ros_hematologyHivSubAnswerViralLoad",
                      depth: 3,
                      type: "text",
                      label: "Viral load",
                      value: "viralLoad",
                      editToggle: false,
                      xs: 12,
                      required: false,
                      placeholder: "",
                      defaultValue: "",
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      subQuestionId: 3,
                      hasSubQuestions: false,
                    },
                    {
                      id: 5,
                      name: "ros_hematologyHivSubAnswerCFour",
                      depth: 3,
                      type: "text",
                      label: "C4",
                      value: "cFour",
                      editToggle: false,
                      xs: 12,
                      required: false,
                      placeholder: "",
                      defaultValue: "",
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      subQuestionId: 4,
                      hasSubQuestions: false,
                    },
                    {
                      subQuestionId: 5,
                      hasSubQuestions: true,
                      editToggle: false,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: false,
                      label: "Patient currently symptomatic",
                      value: "patientSymptomatic",
                      name: "ros_hematologyHivSubAnswerPatientSymptomatic",
                      type: "options_tree",
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Yes",
                          value: "yes",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                        },
                      ],
                    },
                    {
                      subQuestionId: 6,
                      hasSubQuestions: true,
                      editToggle: false,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: false,
                      name: "ros_hematologyHivSubAnswerActiveTreatment",
                      label: "Is patient currently under active treatment",
                      value: "activeTreatment",
                      type: "options_tree",
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Yes",
                          value: "yes",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                        },
                      ],
                    },
                  ],
                },
                {
                  subQuestionId: 6,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Herpes Zoster",
                  value: "herpesZoster",
                  name: "ros_hematologyHerpesZosterSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Herpes Zoster",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_hematologyHerpesZosterSubAnswerDescribe",
                      value: "describe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_hematologyHerpesZosterSubAnswerSupportedBy",
                      type: "options_tree",
                      value: "supportedBy",
                      isMulti: true,
                      label: " Supported by",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: " Rash ",
                          value: "rash",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Symptoms",
                          value: "symptoms",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Medications",
                          value: "medications",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "options_tree",
                          name: "ros_hematologyHerpesZosterSubOther",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              subQuestionId: 1,
                              hasSubQuestions: false,
                              editToggle: false,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: false,
                              label: "Decribe",
                              value: "describe",
                              name: "ros_hematologyHerpesZosterSubOtherSubDesc",
                              type: "text",
                              xs: 12,
                            },
                          ],
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 7,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Hospital Acquired MRSA Infection",
                  value: "mrsaInfection",
                  name: "ros_hematologyMrsaInfectionSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Hospital Acquired MRSA Infection",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_hematologyMrsaInfectionSubAnswerDescribe",
                      value: "describe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_hematologyMrsaInfectionSubAnswerSupportedBy",
                      value: "supportedBy",
                      isMulti: true,
                      type: "options_tree",
                      label: "Supported by    ",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Cultures",
                          value: "cultures",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Hospitalization",
                          value: "hospitalization",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Medications",
                          value: "medications",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Physical findings",
                          value: "physicalFindings",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          name: "ros_hematologyMrsaInfectionSubOther",
                          type: "options_tree",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              subQuestionId: 1,
                              hasSubQuestions: false,
                              editToggle: false,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: false,
                              label: "Other",
                              name: "ros_hematologyMrsaInfectionSubOtherSubDesc",
                              value: "describe",
                              type: "text",
                              xs: 12,
                            },
                          ],
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 8,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Immune Deficiency",
                  value: "immuneDeficiency",
                  name: "ros_hematologyImmuneDeficiencySubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Immune Deficiency",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_hematologyImmuneDeficiencySubAnswerDescribe",
                      type: "options_tree",
                      value: "describe",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 7,
                      name: "ros_hematologyImmuneDeficiencySubAnswerSupportedBy",
                      type: "options_tree",
                      value: "supportedBy",
                      label: "Supported by    ",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "History",
                          value: "history",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Symptoms",
                          value: "symptoms",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Physical Findings",
                          value: "physicalFindings",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Medications",
                          value: "medications",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Test results",
                          value: "testResults",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Image studies",
                          value: "imageStudies",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          name: "ros_hematologyImmuneDeficiencySubOther",
                          type: "toggle_button",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              subQuestionId: 1,
                              hasSubQuestions: false,
                              editToggle: false,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: false,
                              label: "Describe",
                              value: "describe",
                              name: "ros_hematologyImmuneDeficiencySubOtherSubDesc",
                              type: "text",
                              xs: 12,
                            },
                          ],
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                    },
                    {
                      id: 4,
                      name: "ros_hematologyImmuneDeficiencySubAnswerEtiology",
                      depth: 3,
                      type: "text",
                      label: "Etiology",
                      value: "etiology",
                      editToggle: false,
                      xs: 12,
                      required: false,
                      placeholder: "",
                      defaultValue: "",
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      subQuestionId: 3,
                      hasSubQuestions: false,
                    },
                    {
                      subQuestionId: 4,
                      hasSubQuestions: true,
                      editToggle: false,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: false,
                      name: "ros_hematologyImmuneDeficiencySubAnswerHistoryOfOpportunistic",
                      label: "History of Opportunistic Infection",
                      value: "historyOfOpportunistic",
                      type: "options_tree",
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Yes",
                          value: "yes",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                        },
                      ],
                    },
                  ],
                },
                {
                  subQuestionId: 9,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Sepsis",
                  value: "sepsis",
                  name: "ros_hematologySepsisSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Sepsis",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_hematologySepsisSubAnswerDescribe",
                      value: "describe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_hematologySepsisSubAnswerSupportedBy",
                      value: "supportedBy",
                      isMulti: true,
                      type: "options_tree",
                      label: "Supported by    ",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Blood Cultures",
                          value: "bloodCultures",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other cultures",
                          value: "otherCultures",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Symptoms",
                          value: "symptoms",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Hospitalization",
                          value: "hospitalization",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Unstable vital signs",
                          value: "unstableVitalSigns",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          name: "ros_hematologySepsisSubOther",
                          type: "options_tree",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              subQuestionId: 1,
                              hasSubQuestions: false,
                              editToggle: false,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: false,
                              label: "Describe",
                              name: "ros_hematologySepsisSubOtherSubDesc",
                              value: "describe",
                              type: "text",
                              xs: 12,
                            },
                          ],
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 10,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Sickle Cell Disease",
                  value: "sickleCellDisease",
                  name: "ros_hematologySickleCellDiseaseSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Sickle Cell Disease",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_hematologySickleCellDiseaseSubAnswerDescribe",
                      value: "describe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_hematologySickleCellDiseaseSubAnswerSupportedBy",
                      value: "supportedBy",
                      type: "options_tree",
                      label: "Supported by    ",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Family history",
                          value: "familyHistory",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Symptoms",
                          value: "symptoms",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of infections",
                          value: "historyOfInfections",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Hospitalizations",
                          value: "hospitalizations",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "options_tree",
                          name: "ros_hematologySickleCellDiseaseSubOther",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              subQuestionId: 1,
                              hasSubQuestions: false,
                              editToggle: false,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: false,
                              label: "Describe",
                              name: "ros_hematologySickleCellDiseaseSubDesc",
                              value: "describe",
                              type: "text",
                              xs: 12,
                            },
                          ],
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 11,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Sickle Cell Trait",
                  value: "sickleCellTrait",
                  name: "ros_hematologySickleCellTraitSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Sickle Cell Trait",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_hematologySickleCellTraitSubAnswerDescribe",
                      value: "describe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_hematologySickleCellTraitSubAnswerSupportedBy",
                      value: "supportedBy",
                      type: "options_tree",
                      isMulti: true,
                      label: "Supported by    ",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Family history",
                          value: "familyHistory",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Lab test",
                          value: "labTest",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "options_tree",
                          name: "ros_hematologySickleCellTraitSubOther",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              subQuestionId: 1,
                              hasSubQuestions: false,
                              editToggle: false,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: false,
                              label: "Describe",
                              value: "describe",
                              name: "ros_hematologySickleCellTraitSubOtherSubDesc",
                              type: "text",
                              xs: 12,
                            },
                          ],
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 12,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Thalassemia",
                  value: "thalassemia",
                  name: "ros_hematologyThalassemiaSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Thalassemia",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_hematologyThalassemiaSubAnswerDescribe",
                      value: "describe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_hematologyThalassemiaSubAnswerSupportedBy",
                      value: "supportedBy",
                      type: "options_tree",
                      label: "Supported by    ",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Family history",
                          value: "familyHistory",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Symptoms",
                          value: "symptoms",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Lab tests",
                          value: "labTests",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of infections",
                          value: "historyOfInfections",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "options_tree",
                          name: "ros_hematologyThalassemiaSubOther",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              subQuestionId: 1,
                              hasSubQuestions: false,
                              editToggle: false,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: false,
                              label: "Describe",
                              xs: 12,
                              name: "ros_hematologyThalassemiaSubOtherSubDesc",
                              value: "describe",
                              type: "text",
                            },
                          ],
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 13,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Thrombocytopenia",
                  value: "thrombocytopenia",
                  name: "ros_hematologyThrombocytopeniaSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Thrombocytopenia",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },

                    {
                      id: 6,
                      name: "ros_hematologyThrombocytopeniaSubAnswerDescribe",
                      value: "describe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_hematologyThrombocytopeniaSubAnswerSupportedBy",
                      value: "supportedBy",
                      type: "options_tree",
                      isMulti: true,
                      label: "Supported by    ",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Lab tests",
                          value: "labTests",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Physical findings",
                          value: "physicalFindings",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History",
                          value: "history",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 4,
                              name: "ros_hematologyThrombocytopeniaSubOtherSubDesc",
                              depth: 3,
                              type: "text",
                              label: "Describe",
                              value: "describe",
                              editToggle: false,
                              required: false,
                              placeholder: "",
                              defaultValue: "",
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                              hasSubQuestions: false,
                            },
                          ],
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                    },
                    {
                      id: 4,
                      name: "ros_hematologyThrombocytopeniaSubAnswerEtiology",
                      depth: 3,
                      type: "text",
                      label: "Etiology",
                      value: "etiology",
                      editToggle: false,
                      xs: 12,
                      required: false,
                      placeholder: "",
                      defaultValue: "",
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      subQuestionId: 3,
                      hasSubQuestions: false,
                    },
                  ],
                },
                {
                  subQuestionId: 14,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Tuberculosis",
                  value: "tuberculosis",
                  name: "ros_hematologyTuberculosisSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Tuberculosis",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_hematologyTuberculosisSubAnswerDescribe",
                      value: "describe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of Active TB",
                          value: "historyOfActiveTb",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "TB infection(positive PPD)",
                          value: "tbInfection",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out Active TB",
                          value: "ruleOutActiveTb",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_hematologyTuberculosisSubAnswerSupportedBy",
                      value: "supportedBy",
                      type: "options_tree",
                      isMulti: true,
                      label: "Supported by    ",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "History",
                          value: "history",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Medications",
                          value: "medications",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Imagining study",
                          value: "imaginingStudy",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Skin test",
                          value: "skinTest",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Symptoms",
                          value: "symptoms",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Positive culture",
                          value: "positiveCulture",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          name: "ros_hematologyTuberculosisSubOther",
                          type: "options_tree",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              subQuestionId: 1,
                              hasSubQuestions: false,
                              editToggle: false,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: false,
                              xs: 12,
                              label: "describe",
                              value: "describe",
                              name: "ros_hematologyTuberculosisSubOtherSubDesc",
                              type: "text",
                            },
                          ],
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_hematologyTuberculosisSubAnswerGivenBCG",
                      value: "givenBCG",
                      type: "options_tree",
                      label: "Has patient been given BCG",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Yes",
                          value: "yes",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Unknown",
                          value: "unknown",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 3,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_hematologyTuberculosisSubAnswerActiveTuberculosis",
                      value: "activeTuberculosis",
                      type: "options_tree",
                      label: "Has patient been treated for active Tuberculosis",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Yes",
                          value: "yes",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Unknown",
                          value: "unknown",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 4,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_hematologyTuberculosisSubAnswerTbInfection",
                      value: "tbInfection",
                      type: "options_tree",
                      label: "Has patient been treated for TB Infection",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Yes",
                          value: "yes",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Unknown",
                          value: "unknown",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 5,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 15,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Vitamin D Deficiency",
                  value: "vitaminDDeficiency",
                  name: "ros_hematologyVitaminDDeficiencySubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Vitamin D Deficiency",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_hematologyVitaminDDeficiencySubAnswerDescribe",
                      value: "describe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_hematologyVitaminDDeficiencySubAnswerSupportedBy",
                      value: "supportedBy",
                      isMulti: true,
                      type: "options_tree",
                      label: "Supported by    ",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Labs",
                          value: "labs",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Medications",
                          value: "medications",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History",
                          value: "history",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          name: "ros_hematologyVitaminDDeficiencySubOther",
                          type: "options_tree",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              subQuestionId: 1,
                              hasSubQuestions: false,
                              editToggle: false,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: false,
                              label: "describe",
                              value: "describe",
                              xs: 12,
                              name: "ros_hematologyVitaminDDeficiencySubOtherSubDesc",
                              type: "text",
                            },
                          ],
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 16,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Other",
                  value: "other",
                  name: "ros_hematologyOtherSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Other",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_hematologyHemaOtherSubAnswerDescribe",
                      value: "describe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Rule out",
                          value: "ruleOut",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_hematologyHemaOtherSubAnswerSupportedBy",
                      value: "supportedBy",
                      type: "options_tree",
                      label: " Supported by",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "History",
                          value: "history",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Symptoms",
                          value: "symptoms",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Physical Findings",
                          value: "physicalFindings",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Medications",
                          value: "medications",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Test results",
                          value: "testResults",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Image studies",
                          value: "imageStudies",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Biopsy",
                          value: "biopsy",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 8,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "DME",
                          value: "dme",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 9,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          name: "ros_hematologyHemaOtherSubOther",
                          type: "options_tree",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              name: "ros_hematologyHemaOtherSubOtherSubDesc",
                              type: "text",
                              value: "describe",
                              label: "Describe",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: false,
                              subQuestionId: 1,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                          ],
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                    },
                    {
                      id: 4,
                      name: "ros_hematologyHemaOtherSubAnswerOther",
                      depth: 3,
                      type: "text",
                      label: "Other",
                      value: "other",
                      editToggle: false,
                      xs: 12,
                      required: false,
                      placeholder: "",
                      defaultValue: "",
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      subQuestionId: 3,
                      hasSubQuestions: false,
                    },
                  ],
                },
              ],
              subQuestionId: 1,
              editToggle: false,
            },
          ],
        },
        {
          questionId: 4,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 71,
      editToggle: false,
    },
    {
      id: 10,
      name: "hema_Recommendations",
      value: "takeMedicationsAsPescribed",
      label: "Recommendations",
      placeholder: "",
      editToggle: false,
      defaultValue: "",
      type: "checkbox",
      options: [
        ["Take medications as prescribed", "takeMedicationsAsPescribed"],
        ["Report abnormal bruising or bleeding", "reportAbnormalBruising"],
        ["Follow up with doctor for lab work", "followUpWithDoctor"],
        ["Other", "other"],
      ],
      xs: 12,
      required: false,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      questionId: 72,
    },
    {
      id: 9,
      name: "cancer",
      type: "heading",
      label: "CANCER",
      editToggle: false,
      xs: 12,
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 73,
    },
    {
      id: 6,
      name: "ros_diagnosisOfCancer",
      type: "options_tree",
      label: "Diagnosis of Cancer",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 2,
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "ros_diagnosisOfCancerSubDesc",
              type: "options_tree",
              label: "Describe",
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 4,
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  name: "active",
                  label: "Active",
                  value: "active",
                  isConfigured: true,
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "History of",
                  value: "historyOf",
                  type: "toggle_button",
                  name: "historyOf",
                  depth: 4,
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Rule out",
                  value: "ruleOut",
                  type: "toggle_button",
                  name: "ruleOut",
                  depth: 4,
                },
              ],
              subQuestionId: 1,
              depth: 3,
              editToggle: false,
            },
            {
              id: 6,
              name: "ros_diagnosisOfCancerSubSup",
              type: "options_tree",
              label: "Supported by",
              isMulti: true,
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 4,
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  name: "physicalFindings",
                  label: "Physical findings",
                  value: "physicalFindings",
                  isConfigured: true,
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Hospitalization",
                  value: "hospitalization",
                  type: "toggle_button",
                  name: "hospitalization",
                  depth: 4,
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Treatments",
                  value: "treatments",
                  type: "toggle_button",
                  name: "treatments",
                  depth: 4,
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Lab tests",
                  value: "labTests",
                  type: "toggle_button",
                  name: "labTests",
                  depth: 4,
                },
                {
                  subQuestionId: 5,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Imaging studies",
                  value: "imagingStudies",
                  type: "toggle_button",
                  name: "imagingStudies",
                  depth: 4,
                },
                {
                  subQuestionId: 6,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Surgery",
                  value: "surgery",
                  type: "toggle_button",
                  name: "surgery",
                  depth: 4,
                },
                {
                  subQuestionId: 7,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Biopsy",
                  value: "biopsy",
                  type: "toggle_button",
                  name: "biopsy",
                  depth: 4,
                },

                {
                  subQuestionId: 8,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Other",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 1,
                      name: "ros_diagnosisOfCancerSubSupOtherValSubAnswer",
                      type: "text",
                      label: "Describe",
                      editToggle: false,
                      xs: 4,
                      required: "no",
                      placeholder: "",
                      defaultValue: "",
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      subQuestionId: 1,
                      depth: 5,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Other",
                  value: "other",
                  type: "toggle_button",
                  name: "other",
                  depth: 4,
                },
              ],
              subQuestionId: 2,
              depth: 3,
              editToggle: false,
            },
            {
              id: 6,
              name: "ros_diagnosisOfCancerSubType",
              type: "options_tree",
              label: "Type",
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 4,
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  name: "brain",
                  label: "Brain",
                  value: "brain",
                  isConfigured: true,
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Head",
                  value: "head",
                  type: "toggle_button",
                  name: "head",
                  depth: 4,
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Neck",
                  value: "neck",
                  type: "toggle_button",
                  name: "neck",
                  depth: 4,
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Breast",
                  value: "breast",
                  type: "toggle_button",
                  name: "breast",
                  depth: 4,
                },
                {
                  subQuestionId: 5,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Lung",
                  value: "lung",
                  type: "toggle_button",
                  name: "lung",
                  depth: 4,
                },
                {
                  subQuestionId: 6,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Esophagus",
                  value: "esophagus",
                  type: "toggle_button",
                  name: "esophagus",
                  depth: 4,
                },
                {
                  subQuestionId: 7,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Stomach",
                  value: "stomach",
                  type: "toggle_button",
                  name: "stomach",
                  depth: 4,
                },
                {
                  subQuestionId: 8,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Liver",
                  value: "liver",
                  type: "toggle_button",
                  name: "liver",
                  depth: 4,
                },
                {
                  subQuestionId: 9,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Pancreas",
                  value: "pancreas",
                  type: "toggle_button",
                  name: "pancreas",
                  depth: 4,
                },
                {
                  subQuestionId: 10,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Colon",
                  value: "colon",
                  type: "toggle_button",
                  name: "colon",
                  depth: 4,
                },
                {
                  subQuestionId: 11,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Rectum",
                  value: "rectum",
                  type: "toggle_button",
                  name: "rectum",
                  depth: 4,
                },
                {
                  subQuestionId: 12,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Kidney",
                  value: "kidney",
                  type: "toggle_button",
                  name: "kidney",
                  depth: 4,
                },
                {
                  subQuestionId: 13,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Bladder",
                  value: "bladder",
                  type: "toggle_button",
                  name: "bladder",
                  depth: 4,
                },
                {
                  subQuestionId: 14,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Ovaries",
                  value: "ovaries",
                  type: "toggle_button",
                  name: "ovaries",
                  depth: 4,
                },
                {
                  subQuestionId: 15,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Uterus",
                  value: "uterus",
                  type: "toggle_button",
                  name: "uterus",
                  depth: 4,
                },
                {
                  subQuestionId: 16,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Prostate",
                  value: "prostate",
                  type: "toggle_button",
                  name: "prostate",
                  depth: 4,
                },
                {
                  subQuestionId: 17,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Bone",
                  value: "bone",
                  type: "toggle_button",
                  name: "bone",
                  depth: 4,
                },
                {
                  subQuestionId: 18,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Blood",
                  value: "blood",
                  type: "toggle_button",
                  name: "blood",
                  depth: 4,
                },
                {
                  subQuestionId: 19,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Lymph Nodes",
                  value: "lymphNodes",
                  type: "toggle_button",
                  name: "lymphNodes",
                  depth: 4,
                },
                {
                  subQuestionId: 20,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Skin",
                  value: "skin",
                  type: "toggle_button",
                  name: "skin",
                  depth: 4,
                },
                {
                  subQuestionId: 21,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 1,
                      name: "ros_diagnosisOfCancerSubTypeOtherValSubAnswer",
                      type: "text",
                      label: "Describe",
                      editToggle: false,
                      xs: 4,
                      required: "no",
                      placeholder: "",
                      defaultValue: "",
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      subQuestionId: 1,
                      depth: 7,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Other",
                  value: "other",
                  type: "toggle_button",
                  name: "other",
                  depth: 6,
                },
              ],
              subQuestionId: 3,
              depth: 3,
              editToggle: false,
            },
            {
              id: 1,
              name: "ros_diagnosisOfCancerspecialTypes",
              type: "text",
              label: "Specific type/s",
              editToggle: false,
              xs: 4,
              required: "no",
              placeholder: "",
              defaultValue: "",
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 4,
              depth: 3,
            },
            {
              id: 1,
              name: "ros_diagnosisOfCancerstageSpecificToTheCancer",
              type: "text",
              label: "Stage or Classification specific to the cancer",
              editToggle: false,
              xs: 5,
              required: "no",
              placeholder: "",
              defaultValue: "",
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 5,
              depth: 3,
            },
            {
              id: 6,
              name: "ros_diagnosisOfCancerros_activeTreatment",
              type: "options_tree",
              label: "Active treatment ",
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 4,
                  subQuestionId: 1,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_diagnosisOfCancerSubActiveTreat",
                      type: "options_tree",
                      label: "Active treatment    ",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "chemotherapy",
                          label: "Chemotherapy",
                          value: "chemotherapy",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Radiation",
                          value: "radiation",
                          type: "toggle_button",
                          name: "radiation",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Stem Cell",
                          value: "stemCell",
                          type: "toggle_button",
                          name: "stemCell",
                          depth: 6,
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Bone Marrow",
                          value: "boneMarrow",
                          type: "toggle_button",
                          name: "boneMarrow",
                          depth: 6,
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Surgery",
                          value: "surgery",
                          type: "toggle_button",
                          name: "surgery",
                          depth: 6,
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Immune System",
                          value: "immuneSystem",
                          type: "toggle_button",
                          name: "immuneSystem",
                          depth: 6,
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "ros_diagnosisOfCancerSubActiveTreatOtherValSubAnswer",
                              type: "text",
                              label: "Describe",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                          name: "other",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                      depth: 5,
                    },
                    {
                      id: 6,
                      name: "ros_diagnosisOfCancerSubSideEff",
                      type: "options_tree",
                      label: "Side effects",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "nausea",
                          label: "Nausea",
                          value: "nausea",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Vomiting",
                          value: "vomiting",
                          type: "toggle_button",
                          name: "vomiting",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Diarrhea",
                          value: "diarrhea",
                          type: "toggle_button",
                          name: "diarrhea",
                          depth: 6,
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Anemia",
                          value: "anemia",
                          type: "toggle_button",
                          name: "anemia",
                          depth: 6,
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Neutropenia",
                          value: "neutropenia",
                          type: "toggle_button",
                          name: "neutropenia",
                          depth: 6,
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Thrombocytopenia",
                          value: "thrombocytopenia",
                          type: "toggle_button",
                          name: "thrombocytopenia",
                          depth: 6,
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Weakness",
                          value: "weakness",
                          type: "toggle_button",
                          name: "weakness",
                          depth: 6,
                        },
                        {
                          subQuestionId: 8,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Loss of appetite",
                          value: "lossOfAppetite",
                          type: "toggle_button",
                          name: "lossOfAppetite",
                          depth: 6,
                        },
                        {
                          subQuestionId: 9,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "ros_diagnosisOfCancerSubActiveTreatSideEffOtherValSubAnswer",
                              type: "text",
                              label: "Describe",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                          name: "other",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                      depth: 5,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  name: "yes",
                  label: "Yes",
                  value: "yes",
                  isConfigured: true,
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "No",
                  value: "no",
                  type: "toggle_button",
                  name: "no",
                  depth: 4,
                },
              ],
              subQuestionId: 6,
              depth: 3,
              editToggle: false,
            },
            {
              id: 6,
              name: "ros_diagnosisOfCancerros_Metastasis",
              type: "options_tree",
              label: "Is there a current finding of Metastasis?",
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 4,
                  subQuestionId: 1,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 1,
                      name: "ros_diagnosisOfCancerSubMetastasisLocVal",
                      type: "text",
                      label: "Location",
                      editToggle: false,
                      xs: 4,
                      required: "no",
                      placeholder: "",
                      defaultValue: "",
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      subQuestionId: 1,
                      depth: 5,
                    },
                    {
                      id: 6,
                      name: "ros_diagnosisOfCancerSubMetastasisCachexiaVal",
                      type: "options_tree",
                      label: "History / finding of Cachexia",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Yes",
                          value: "yes",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "yes",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                          name: "no",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                      depth: 5,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  name: "yes",
                  label: "Yes",
                  value: "yes",
                  isConfigured: true,
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "No",
                  value: "no",
                  type: "toggle_button",
                  name: "no",
                  depth: 4,
                },
              ],
              subQuestionId: 7,
              depth: 3,
              editToggle: false,
            },
            {
              id: 6,
              name: "ros_diagnosisOfCancerros_SeeASpec",
              type: "options_tree",
              label: "Do you see a specialist?",
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 4,
                  subQuestionId: 1,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 1,
                      name: "ros_diagnosisOfCancerSubSeeASpecProVal",
                      type: "text",
                      label: "Provider",
                      editToggle: false,
                      xs: 4,
                      required: "no",
                      placeholder: "",
                      defaultValue: "",
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      subQuestionId: 1,
                      depth: 5,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  name: "yes",
                  label: "Yes",
                  value: "yes",
                  isConfigured: true,
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "No",
                  value: "no",
                  type: "toggle_button",
                  name: "no",
                  depth: 4,
                },
              ],
              subQuestionId: 8,
              depth: 3,
              editToggle: false,
            },
          ],
          editToggle: false,
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          isConfigured: true,
          type: "toggle_button",
          name: "yes",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "No",
          value: "no",
          type: "toggle_button",
          name: "no",
          depth: 2,
        },
      ],
      questionId: 74,
      editToggle: false,
      depth: 1,
    },
    {
      id: 10,
      name: "ros_diagnosisOfCancerRecommendations",
      label: "Recommendations",
      placeholder: "",
      editToggle: false,
      defaultValue: "",
      type: "checkbox",
      options: [
        ["Take medications as prescribed", "takemedicationsasprescribedCancer"],
        ["Other", "otherCancer"],
      ],
      xs: 12,
      required: false,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      questionId: 75,
    },
  ],
};

const json2024 = {
  sectionId: 10,
  sectionName: "Assessment Of Diagnoses",
  isEditable: false,
  questions: [
    {
      id: 9,
      name: "eyes",
      type: "heading",
      label: "EYES",
      xs: 12,
      isCommentEnabled: false,
      questionId: 1,
    },
    {
      id: 6,
      name: "ros_eyes",
      type: "options_tree",
      label:
        "Eye Problems (Glaucoma, Cataracts, Macular Degeneration, Blindness, Retinal Detachment, Other)",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "ros_eyesDiag",
              type: "options_tree",
              label: "Diagnoses",
              isMulti: true,
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_eyesDiagCataractSubWhEye",
                      type: "options_tree",
                      label: "Cataracts Which Eye",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Right Eye",
                          value: "rightEye",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Left Eye",
                          value: "leftEye",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Both",
                          value: "both",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "ros_eyesDiagCataractSubDesc",
                      type: "options_tree",
                      label: "Describe",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_eyesDiagCataractSubDescActiveSubTreat",
                              type: "options_tree",
                              isMulti: true,
                              label: "Treatment",
                              xs: 12,
                              isCommentEnabled: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label: "Other Treatment",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      subQuestionId: 1,
                                      name: "ros_eyesDiagCataractSubDescActiveSubTreatOtherSubDescText",
                                      type: "text",
                                      label: "Other Treatment",
                                      xs: 12,
                                      isCommentEnabled: false,
                                    },
                                  ],
                                  isCommentEnabled: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 4,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Glasses",
                                  value: "glasses",
                                  type: "toggle_button",
                                },
                              ],
                              subQuestionId: 2,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                    },
                  ],
                  isCommentEnabled: false,
                  isExclusive: false,
                  label: "Cataracts",
                  value: "cataracts",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_eyesDiagGlaucomaSubWhEye",
                      type: "options_tree",
                      label: "Glaucoma Which Eye ",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Right Eye ",
                          value: "rightEye",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Left Eye ",
                          value: "leftEye",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Both",
                          value: "both",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "ros_eyesDiagGlaucomaSubDesc",
                      type: "options_tree",
                      label: "Describe ",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_eyesDiagGlaucomaSubDescActiveSubTreat",
                              type: "options_tree",
                              isMulti: true,
                              label: "Treatment",
                              xs: 12,
                              isCommentEnabled: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label: "Other Treatment",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      subQuestionId: 1,
                                      name: "ros_eyesDiagGlaucomaSubDescActiveSubTreatOtherSubDescText",
                                      type: "text",
                                      label: "Other Treatment",
                                      xs: 12,
                                      isCommentEnabled: false,
                                    },
                                  ],
                                  isCommentEnabled: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 4,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Glasses",
                                  value: "glasses",
                                  type: "toggle_button",
                                },
                              ],
                              subQuestionId: 2,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Glaucoma",
                  value: "glaucoma",
                  type: "toggle_button",
                  isExclusive: false,
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_eyesDiagHyperopiaSubWhEye",
                      type: "options_tree",
                      label: "Hyperopia Which Eye ",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Right Eye",
                          value: "rightEye",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Left Eye",
                          value: "leftEye",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Both",
                          value: "both",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "ros_eyesDiagHyperopiaSubDesc",
                      type: "options_tree",
                      label: "Describe",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_eyesDiagHyperopiaSubDescActiveSubTreat",
                              type: "options_tree",
                              isMulti: true,
                              label: "Treatment",
                              xs: 12,
                              isCommentEnabled: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label: "Other Treatment",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      subQuestionId: 1,
                                      name: "ros_eyesDiagHyperopiaSubDescActiveSubTreatOtherSubDescText",
                                      type: "text",
                                      label: "Other Treatment",
                                      xs: 12,
                                      isCommentEnabled: false,
                                    },
                                  ],
                                  isCommentEnabled: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 4,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Glasses",
                                  value: "glasses",
                                  type: "toggle_button",
                                },
                              ],
                              subQuestionId: 2,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Hyperopia",
                  value: "hyperopia",
                  isExclusive: false,
                  type: "toggle_button",
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_eyesDiagLegalBlindSubWhEye",
                      type: "options_tree",
                      label: "Legally Blind Which Eye ",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Right Eye",
                          value: "rightEye",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Left Eye",
                          value: "leftEye",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Both",
                          value: "both",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "ros_eyesDiagLegalBlindSubDesc",
                      type: "options_tree",
                      label: "Describe",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_eyesDiagLegalBlindSubDescActiveSubTreat",
                              type: "options_tree",
                              isMulti: true,
                              label: "Treatment",
                              xs: 12,
                              isCommentEnabled: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label: "Other Treatment",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      subQuestionId: 1,
                                      name: "ros_eyesDiagLegalBlindSubDescActiveSubTreatOtherSubDescText",
                                      type: "text",
                                      label: "Other Treatment",
                                      xs: 12,
                                      isCommentEnabled: false,
                                    },
                                  ],
                                  isCommentEnabled: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 4,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Glasses",
                                  value: "glasses",
                                  type: "toggle_button",
                                },
                              ],
                              subQuestionId: 2,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                    },
                    {
                      subQuestionId: 3,
                      name: "ros_eyesDiagLegalBlindSubDescText",
                      type: "text",
                      label: "Describe",
                      xs: 12,
                      isCommentEnabled: false,
                    },
                  ],
                  isCommentEnabled: false,
                  isExclusive: false,
                  label: "Legally Blind",
                  value: "legallyBlind",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 5,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_eyesDiagMascDegenSubWhEye",
                      type: "options_tree",
                      label: "Macular Degeneration Which Eye ",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Right Eye",
                          value: "rightEye",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Left Eye",
                          value: "leftEye",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Both",
                          value: "both",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "ros_eyesDiagMascDegenSubDesc",
                      type: "options_tree",
                      label: " Describe",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_eyesDiagMascDegenSubDescActiveSubTreat",
                              type: "options_tree",
                              isMulti: true,
                              label: "Treatment",
                              xs: 12,
                              isCommentEnabled: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label: "Other Treatment",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      subQuestionId: 1,
                                      name: "ros_eyesDiagMascDegenSubDescActiveSubTreatOtherSubDescText",
                                      type: "text",
                                      label: "Other Treatment",
                                      xs: 12,
                                      isCommentEnabled: false,
                                    },
                                  ],
                                  isCommentEnabled: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 4,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Glasses",
                                  value: "glasses",
                                  type: "toggle_button",
                                },
                              ],
                              subQuestionId: 2,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                    },
                    {
                      id: 6,
                      name: "ros_eyesDiagMascDegenSubDesc2",
                      type: "options_tree",
                      label: "Describe",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Wet",
                          value: "wet",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Dry",
                          value: "dry",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 4,
                    },
                  ],
                  isCommentEnabled: false,
                  isExclusive: false,
                  label: "Macular Degeneration",
                  value: "mascularDegeneration",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 6,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_eyesDiagMyopiaSubWhEye",
                      type: "options_tree",
                      label: "Myopia Which Eye ",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Right Eye",
                          value: "rightEye",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Left Eye",
                          value: "leftEye",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Both",
                          value: "both",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "ros_eyesDiagMyopiaSubDesc",
                      type: "options_tree",
                      label: "Describe",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_eyesDiagMyopiaSubDescActiveSubTreat",
                              type: "options_tree",
                              isMulti: true,
                              label: "Treatment",
                              xs: 12,
                              isCommentEnabled: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label: "Other Treatment",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      subQuestionId: 1,
                                      name: "ros_eyesDiagMyopiaSubDescActiveSubTreatOtherSubDescText",
                                      type: "text",
                                      label: "Other Treatment",
                                      xs: 12,
                                      isCommentEnabled: false,
                                    },
                                  ],
                                  isCommentEnabled: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 4,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Glasses",
                                  value: "glasses",
                                  type: "toggle_button",
                                },
                              ],
                              subQuestionId: 2,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Myopia",
                  value: "myopia",
                  isExclusive: false,
                  type: "toggle_button",
                },
                {
                  subQuestionId: 7,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_eyesDiagRetinalSubWhEye",
                      type: "options_tree",
                      label: "Retinal Disease Which Eye ",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Right Eye",
                          value: "rightEye",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Left Eye",
                          value: "leftEye",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Both",
                          value: "both",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "ros_eyesDiagRetinalSubDesc",
                      type: "options_tree",
                      label: "Describe     ",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_eyesDiagRetinalSubDescActiveSubTreat",
                              type: "options_tree",
                              isMulti: true,
                              label: "Treatment",
                              xs: 12,
                              isCommentEnabled: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label: "Other Treatment",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      subQuestionId: 1,
                                      name: "ros_eyesDiagRetinalSubDescActiveSubTreatOtherSubDescText",
                                      type: "text",
                                      label: "Other Treatment",
                                      xs: 12,
                                      isCommentEnabled: false,
                                    },
                                  ],
                                  isCommentEnabled: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 4,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Glasses",
                                  value: "glasses",
                                  type: "toggle_button",
                                },
                              ],
                              subQuestionId: 2,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                    },
                  ],
                  isCommentEnabled: false,
                  isExclusive: false,
                  label: "Retinal Disease",
                  value: "retinalDisease",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 8,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_eyesDiagOtherSubWhEye",
                      type: "options_tree",
                      label: "Others Which Eye",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Right Eye",
                          value: "rightEye",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Left Eye",
                          value: "leftEye",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Both",
                          value: "both",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "ros_eyesDiagOtherSubDesc",
                      type: "options_tree",
                      label: "    Describe",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_eyesDiagOtherSubDescActiveSubTreat",
                              type: "options_tree",
                              isMulti: true,
                              label: "Treatment",
                              xs: 12,
                              isCommentEnabled: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label: "Other Treatment",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      subQuestionId: 1,
                                      name: "ros_eyesDiagOtherSubDescActiveSubTreatOtherSubDescText",
                                      type: "text",
                                      label: "Other Treatment",
                                      xs: 12,
                                      isCommentEnabled: false,
                                    },
                                  ],
                                  isCommentEnabled: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 4,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Glasses",
                                  value: "glasses",
                                  type: "toggle_button",
                                },
                              ],
                              subQuestionId: 2,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                    },
                    {
                      id: 6,
                      name: "ros_eyesDiagOtherSubOther",
                      type: "text",
                      label: "Other",
                      xs: 12,
                      isCommentEnabled: false,
                      hasSubQuestions: false,
                      subQuestions: [],
                      subQuestionId: 5,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Others",
                  value: "others",
                  isExclusive: false,
                  type: "toggle_button",
                },
              ],
              subQuestionId: 1,
            },
            {
              subQuestionId: 2,
              label: "Symptoms",
              value: "",
              type: "options_tree",
              isMulti: true,
              xs: 12,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  label: "Pain",
                  type: "toggle_button",
                  value: "pain",
                  hasSubQuestions: false,
                  subQuestions: [{}],
                },
                {
                  subQuestionId: 2,
                  label: "Dry Eye",
                  type: "toggle_button",
                  value: "dryEye",
                  hasSubQuestions: false,
                  subQuestions: [{}],
                },
                {
                  subQuestionId: 3,
                  label: "Tearing",
                  type: "toggle_button",
                  value: "tearing",
                  hasSubQuestions: false,
                  subQuestions: [{}],
                },
                {
                  subQuestionId: 4,
                  label: "Problem seeing at night",
                  type: "toggle_button",
                  value: "problemSeeing",
                  hasSubQuestions: false,
                  subQuestions: [{}],
                },
                {
                  subQuestionId: 5,
                  label: "Floaters",
                  type: "toggle_button",
                  value: "floaters",
                  hasSubQuestions: false,
                  subQuestions: [{}],
                },
                {
                  subQuestionId: 6,
                  label: "Other",
                  type: "toggle_button",
                  value: "other",
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Other",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      subQuestionId: 1,
                      name: "ros_eyesSympOtherSubDesc",
                      type: "text",
                      label: "Other",
                      xs: 12,
                      isCommentEnabled: false,
                    },
                  ],
                },
                {
                  subQuestionId: 7,
                  label: "None",
                  type: "toggle_button",
                  value: "none",
                  hasSubQuestions: false,
                  subQuestions: [{}],
                },
              ],
              isCommentEnabled: true,
              name: "ros_eyesSymp",
            },
          ],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          name: "yes",
          type: "toggle_button",
        },
        {
          id: 6,
          type: "toggle_button",
          label: "No",
          xs: 12,
          isCommentEnabled: true,
          hasSubQuestions: false,
          subQuestions: [{}],
          subQuestionId: 2,
          value: "no",
          name: "no",
        },
      ],
      questionId: 2,
    },
    {
      id: 9,
      name: "ears",
      type: "heading",
      label: "EARS",
      xs: 12,
      isCommentEnabled: false,
      questionId: 3,
    },
    {
      id: 6,
      name: "ros_ears",
      type: "options_tree",
      label: "Ear Problems (Hard of hearing, Deaf, Vertigo, Ear Infections)",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "ros_earsDiag",
              type: "options_tree",
              label: "Diagnoses",
              isMulti: true,
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_earsDiagDiffHearSubDesc",
                      subQuestionId: 1,
                      type: "options_tree",
                      label: "Difficulty with Hearing Describe",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_earsDiagDiffHearSubDescActiveSubWhich",
                              type: "options_tree",
                              label: "Which Ear",
                              xs: 12,
                              subQuestionId: 1,
                              isCommentEnabled: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Right",
                                  value: "right",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Left",
                                  value: "left",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Both",
                                  value: "both",
                                  type: "toggle_button",
                                },
                              ],
                            },
                            {
                              id: 6,
                              name: "ros_earsDiagDiffHearSubDescActiveSubHearAid",
                              type: "options_tree",
                              label: "Hearing Aides",
                              xs: 12,
                              subQuestionId: 1,
                              isCommentEnabled: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Does not wear hearing aides",
                                  value: "doesNotWear",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "ros_earsDiagDiffHearSubDescActiveSubHearAidSubWhich",
                                      type: "options_tree",
                                      label: "Which Ear",
                                      xs: 12,
                                      subQuestionId: 1,
                                      isCommentEnabled: true,
                                      hasSubQuestions: true,
                                      subQuestions: [
                                        {
                                          subQuestionId: 1,
                                          hasSubQuestions: false,
                                          subQuestions: [],
                                          isCommentEnabled: false,
                                          label: "Right",
                                          value: "right",
                                          type: "toggle_button",
                                        },
                                        {
                                          subQuestionId: 2,
                                          hasSubQuestions: false,
                                          subQuestions: [],
                                          isCommentEnabled: false,
                                          label: "Left",
                                          value: "left",
                                          type: "toggle_button",
                                        },
                                        {
                                          subQuestionId: 3,
                                          hasSubQuestions: false,
                                          subQuestions: [],
                                          isCommentEnabled: false,
                                          label: "Both",
                                          value: "both",
                                          type: "toggle_button",
                                        },
                                      ],
                                    },
                                  ],
                                  isCommentEnabled: false,
                                  label: "Wears Hearing Aides",
                                  value: "wears",
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Difficulty with Hearing",
                  value: "difficultyHearing",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_earsDiagLegalDeafSubDesc",
                      subQuestionId: 1,
                      type: "options_tree",
                      label: "Legally Deaf Describe",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_earsDiagLegalDeafSubDescActiveSubWhich",
                              type: "options_tree",
                              label: "Which Ear",
                              xs: 12,
                              subQuestionId: 1,
                              isCommentEnabled: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Right",
                                  value: "right",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Left",
                                  value: "left",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Both",
                                  value: "both",
                                  type: "toggle_button",
                                },
                              ],
                            },
                            {
                              id: 6,
                              name: "ros_earsDiagLegalDeafSubDescActiveSubHearAid",
                              type: "options_tree",
                              label: "Hearing Aides",
                              xs: 12,
                              subQuestionId: 1,
                              isCommentEnabled: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Does not wear hearing aides",
                                  value: "doesNotWear",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "ros_earsDiagLegalDeafSubDescActiveSubHearAidSubWhich",
                                      type: "options_tree",
                                      label: "Which Ear",
                                      xs: 12,
                                      subQuestionId: 1,
                                      isCommentEnabled: true,
                                      hasSubQuestions: true,
                                      subQuestions: [
                                        {
                                          subQuestionId: 1,
                                          hasSubQuestions: false,
                                          subQuestions: [],
                                          isCommentEnabled: false,
                                          label: "Right",
                                          value: "right",
                                          type: "toggle_button",
                                        },
                                        {
                                          subQuestionId: 2,
                                          hasSubQuestions: false,
                                          subQuestions: [],
                                          isCommentEnabled: false,
                                          label: "Left",
                                          value: "left",
                                          type: "toggle_button",
                                        },
                                        {
                                          subQuestionId: 3,
                                          hasSubQuestions: false,
                                          subQuestions: [],
                                          isCommentEnabled: false,
                                          label: "Both",
                                          value: "both",
                                          type: "toggle_button",
                                        },
                                      ],
                                    },
                                  ],
                                  isCommentEnabled: false,
                                  label: "Wears Hearing Aides",
                                  value: "wears",
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Legally Deaf",
                  value: "legallyDeaf",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_earsDiagTinnitusSubDesc",
                      subQuestionId: 1,
                      type: "options_tree",
                      label: "Tinnitus Describe",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              subQuestionId: 1,
                              label: "Symptoms",
                              value: "",
                              type: "options_tree",
                              isMulti: true,
                              xs: 12,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  subQuestionId: 1,
                                  label: "Dizziness",
                                  type: "toggle_button",
                                  value: "dizziness",
                                  hasSubQuestions: false,
                                  subQuestions: [{}],
                                },
                                {
                                  subQuestionId: 2,
                                  label: "Room Spinning",
                                  type: "toggle_button",
                                  value: "roomSpinning",
                                  hasSubQuestions: false,
                                  subQuestions: [{}],
                                },
                                {
                                  subQuestionId: 3,
                                  label: "Lose Balance",
                                  type: "toggle_button",
                                  value: "loseBalance",
                                  hasSubQuestions: false,
                                  subQuestions: [{}],
                                },
                                {
                                  subQuestionId: 4,
                                  label: "Nausea",
                                  type: "toggle_button",
                                  value: "nausea",
                                  hasSubQuestions: false,
                                  subQuestions: [{}],
                                },
                                {
                                  subQuestionId: 5,
                                  label: "Ears Ringing",
                                  type: "toggle_button",
                                  value: "earsRinging",
                                  hasSubQuestions: false,
                                  subQuestions: [{}],
                                },
                                {
                                  subQuestionId: 6,
                                  label: "Other",
                                  type: "toggle_button",
                                  value: "other",
                                  hasSubQuestions: true,
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label: "Other",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      subQuestionId: 1,
                                      name: "ros_earsDiagTinnitusSubDescActiveSubSympOtherSubDesc",
                                      type: "text",
                                      label: "Other",
                                      xs: 12,
                                      isCommentEnabled: false,
                                    },
                                  ],
                                },
                                {
                                  subQuestionId: 7,
                                  label: "None",
                                  type: "toggle_button",
                                  value: "none",
                                  hasSubQuestions: false,
                                  subQuestions: [{}],
                                },
                              ],
                              isCommentEnabled: true,
                              name: "ros_earsDiagTinnitusSubDescActiveSubSymp",
                            },
                            {
                              id: 6,
                              name: "ros_earsDiagTinnitusSubDescActiveSubTreat",
                              type: "options_tree",
                              isMulti: true,
                              label: "Treatment",
                              xs: 12,
                              isCommentEnabled: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  subQuestions: [
                                    {
                                      subQuestionId: 1,
                                      name: "ros_earsDiagTinnitusSubDescActiveSubTreatOtherSubDesc",
                                      type: "text",
                                      label: "Other Treatment",
                                      xs: 12,
                                      isCommentEnabled: false,
                                    },
                                  ],
                                  isCommentEnabled: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  type: "toggle_button",
                                },
                              ],
                              subQuestionId: 2,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Tinnitus",
                  value: "tinnitus",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_earsDiagVertigoSubDesc",
                      type: "options_tree",
                      label: "Vertigo Describe",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              subQuestionId: 1,
                              label: "Symptoms",
                              value: "",
                              type: "options_tree",
                              isMulti: true,
                              xs: 12,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  subQuestionId: 1,
                                  label: "Dizziness",
                                  type: "toggle_button",
                                  value: "dizziness",
                                  hasSubQuestions: false,
                                  subQuestions: [{}],
                                },
                                {
                                  subQuestionId: 2,
                                  label: "Room Spinning",
                                  type: "toggle_button",
                                  value: "roomSpinning",
                                  hasSubQuestions: false,
                                  subQuestions: [{}],
                                },
                                {
                                  subQuestionId: 3,
                                  label: "Lose Balance",
                                  type: "toggle_button",
                                  value: "loseBalance",
                                  hasSubQuestions: false,
                                  subQuestions: [{}],
                                },
                                {
                                  subQuestionId: 4,
                                  label: "Nausea",
                                  type: "toggle_button",
                                  value: "nausea",
                                  hasSubQuestions: false,
                                  subQuestions: [{}],
                                },
                                {
                                  subQuestionId: 5,
                                  label: "Ears Ringing",
                                  type: "toggle_button",
                                  value: "earsRinging",
                                  hasSubQuestions: false,
                                  subQuestions: [{}],
                                },
                                {
                                  subQuestionId: 6,
                                  label: "Other",
                                  type: "toggle_button",
                                  value: "other",
                                  hasSubQuestions: true,
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label: "Other",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      subQuestionId: 1,
                                      name: "ros_earsDiagVertigoSubDescActiveSubSympOtherSubDesc",
                                      type: "text",
                                      label: "Other",
                                      xs: 12,
                                      isCommentEnabled: false,
                                    },
                                  ],
                                },
                                {
                                  subQuestionId: 7,
                                  label: "None",
                                  type: "toggle_button",
                                  value: "none",
                                  hasSubQuestions: false,
                                  subQuestions: [{}],
                                },
                              ],
                              isCommentEnabled: true,
                              name: "ros_earsDiagVertigoSubDescActiveSubSymp",
                            },
                            {
                              id: 6,
                              name: "ros_earsDiagVertigoSubDescActiveSubTreat",
                              type: "options_tree",
                              isMulti: true,
                              label: "Treatment",
                              xs: 12,
                              isCommentEnabled: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label: "Other Treatment",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      subQuestionId: 1,
                                      name: "ros_earsDiagVertigoSubDescActiveSubTreatOtherSubDesc",
                                      type: "text",
                                      label: "Other Treatment",
                                      xs: 12,
                                      isCommentEnabled: false,
                                    },
                                  ],
                                  isCommentEnabled: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  type: "toggle_button",
                                },
                              ],
                              subQuestionId: 2,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "ros_earsDiagVertigoSubLoseBal",
                      type: "options_tree",
                      label: "Do you lose your balance",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Yes",
                          value: "yes",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Vertigo",
                  value: "vertigo",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 5,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_earsDiagOtherSubDesc",
                      type: "options_tree",
                      label: "    Describe",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_earsDiagOtherSubDescActiveSubTreat",
                              type: "options_tree",
                              isMulti: true,
                              label: "Treatment",
                              xs: 12,
                              isCommentEnabled: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label: "Other Treatment",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      subQuestionId: 1,
                                      name: "ros_earsDiagOtherSubDescActiveSubTreatOtherSubDesc",
                                      type: "text",
                                      label: "Other Treatment",
                                      xs: 12,
                                      isCommentEnabled: false,
                                    },
                                  ],
                                  isCommentEnabled: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  type: "toggle_button",
                                },
                              ],
                              subQuestionId: 1,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                    },
                    {
                      id: 6,
                      name: "ros_earsDiagOtherSubOther",
                      type: "text",
                      label: "Other",
                      xs: 12,
                      isCommentEnabled: false,
                      hasSubQuestions: false,
                      subQuestions: [],
                      subQuestionId: 5,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Other",
                  value: "other",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 1,
            },
          ],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
        },
        {
          id: 6,
          type: "toggle_button",
          label: "No",
          xs: 12,
          isCommentEnabled: true,
          hasSubQuestions: false,
          subQuestions: [{}],
          subQuestionId: 2,
          value: "no",
        },
      ],
      questionId: 4,
    },
    {
      id: 9,
      name: "nose",
      type: "heading",
      label: "NOSE",
      xs: 12,
      isCommentEnabled: false,
      questionId: 5,
    },
    {
      id: 6,
      name: "ros_nose",
      type: "options_tree",
      label: "Nose Problems (Nose Bleeds, Sinus infections, Other)",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "ros_noseDiag",
              type: "options_tree",
              isMulti: true,
              label: "Diagnoses",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_noseDiagAllergySubDesc",
                      type: "options_tree",
                      label: "Allergic Rhinitis Describe",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_noseDiagAllergySubDescActiveSubTreat",
                              type: "options_tree",
                              isMulti: true,
                              label: "Treatment",
                              xs: 12,
                              isCommentEnabled: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label: "Other Treatment",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      subQuestionId: 1,
                                      name: "ros_noseDiagAllergySubDescActiveSubTreatOtherSubDesc",
                                      type: "text",
                                      label: "Other Treatment",
                                      xs: 12,
                                      isCommentEnabled: false,
                                    },
                                  ],
                                  isCommentEnabled: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  type: "toggle_button",
                                },
                              ],
                              subQuestionId: 2,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Allergic Rhinitis",
                  value: "allergicRhinitis",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_noseDiagChronicNasalSubDesc",
                      type: "options_tree",
                      label: "Chronic Post Nasal Drip Describe",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_noseDiagChronicNasalSubDescActiveSubTreat",
                              type: "options_tree",
                              isMulti: true,
                              label: "Treatment",
                              xs: 12,
                              isCommentEnabled: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label: "Other Treatment",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      subQuestionId: 1,
                                      name: "ros_noseDiagChronicNasalSubDescActiveSubTreatOtherSubDesc",
                                      type: "text",
                                      label: "Other Treatment",
                                      xs: 12,
                                      isCommentEnabled: false,
                                    },
                                  ],
                                  isCommentEnabled: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  type: "toggle_button",
                                },
                              ],
                              subQuestionId: 2,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Chronic Post Nasal Drip",
                  value: "chronicNasalDrip",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_noseDiagNoseBleedSubDesc",
                      type: "options_tree",
                      label: "Nose Bleeds Describe",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_noseDiagNoseBleedSubDescActiveSubTreat",
                              type: "options_tree",
                              isMulti: true,
                              label: "Treatment",
                              xs: 12,
                              isCommentEnabled: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label: "Other Treatment",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      subQuestionId: 1,
                                      name: "ros_noseDiagNoseBleedSubDescActiveSubTreatOtherSubDesc",
                                      type: "text",
                                      label: "Other Treatment",
                                      xs: 12,
                                      isCommentEnabled: false,
                                    },
                                  ],
                                  isCommentEnabled: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  type: "toggle_button",
                                },
                              ],
                              subQuestionId: 2,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Nose Bleeds",
                  value: "noseBleeds",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_noseDiagSeasonAllergySubDesc",
                      type: "options_tree",
                      label: "Seasonal Allergies Describe",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_noseDiagSeasonAllergySubDescActiveSubTreat",
                              type: "options_tree",
                              isMulti: true,
                              label: "Treatment",
                              xs: 12,
                              isCommentEnabled: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label: "Other Treatment",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      subQuestionId: 1,
                                      name: "ros_noseDiagSeasonAllergySubDescActiveSubTreatOtherSubDesc",
                                      type: "text",
                                      label: "Other Treatment",
                                      xs: 12,
                                      isCommentEnabled: false,
                                    },
                                  ],
                                  isCommentEnabled: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  type: "toggle_button",
                                },
                              ],
                              subQuestionId: 2,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Seasonal Allergies",
                  value: "seasonalAllergies",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 5,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_noseDiagSinusInfectSubDesc",
                      type: "options_tree",
                      label: "Sinus infections Describe",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_noseDiagSinusInfectSubDescActiveSubTreat",
                              type: "options_tree",
                              isMulti: true,
                              label: "Treatment",
                              xs: 12,
                              isCommentEnabled: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label: "Other Treatment",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      subQuestionId: 1,
                                      name: "ros_noseDiagSinusInfectSubDescActiveSubTreatOtherSubDesc",
                                      type: "text",
                                      label: "Other Treatment",
                                      xs: 12,
                                      isCommentEnabled: false,
                                    },
                                  ],
                                  isCommentEnabled: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  type: "toggle_button",
                                },
                              ],
                              subQuestionId: 2,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "ros_noseDiagSinusInfectSubExu",
                      type: "options_tree",
                      label: "Exudate",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Clear",
                          value: "clear",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Purulent",
                          value: "purulent",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Sinus infections",
                  value: "sinusInfections",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 6,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_noseDiagSinustisSubDesc",
                      type: "options_tree",
                      label: "Sinusitis Describe",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_noseDiagSinustisSubDescActiveSubTreat",
                              type: "options_tree",
                              isMulti: true,
                              label: "Treatment",
                              xs: 12,
                              isCommentEnabled: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label: "Other Treatment",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      subQuestionId: 1,
                                      name: "ros_noseDiagSinustisSubDescActiveSubTreatOtherSubDesc",
                                      type: "text",
                                      label: "Other Treatment",
                                      xs: 12,
                                      isCommentEnabled: false,
                                    },
                                  ],
                                  isCommentEnabled: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  type: "toggle_button",
                                },
                              ],
                              subQuestionId: 2,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Sinusitis",
                  value: "sinusitis",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 7,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_noseDiagOtherSubDesc",
                      type: "options_tree",
                      label: "Other Describe",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_noseDiagOtherSubDescActiveSubTreat",
                              type: "options_tree",
                              isMulti: true,
                              label: "Treatment",
                              xs: 12,
                              isCommentEnabled: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label: "Other Treatment",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      subQuestionId: 1,
                                      name: "ros_noseDiagOtherSubDescActiveSubTreatOtherSubDesc",
                                      type: "text",
                                      label: "Other Treatment",
                                      xs: 12,
                                      isCommentEnabled: false,
                                    },
                                  ],
                                  isCommentEnabled: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  type: "toggle_button",
                                },
                              ],
                              subQuestionId: 2,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 1,
                      name: "ros_noseDiagOtherSubOther",
                      type: "text",
                      label: "Other",
                      xs: 4,
                      placeholder: "",
                      defaultValue: "",
                      isCommentEnabled: false,
                      subQuestionId: 3,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Other",
                  value: "other",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 1,
            },
          ],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
        },
        {
          id: 6,
          type: "toggle_button",
          label: "No",
          xs: 12,
          isCommentEnabled: true,
          hasSubQuestions: false,
          subQuestions: [{}],
          subQuestionId: 2,
          value: "no",
        },
      ],
      questionId: 6,
    },
    {
      id: 9,
      name: "mouth_&_throat",
      type: "heading",
      label: "MOUTH AND THROAT",
      xs: 12,
      isCommentEnabled: false,
      questionId: 7,
    },
    {
      id: 6,
      name: "ros_mouth",
      type: "options_tree",
      label:
        "Mouth and Throat Problems (Difficulty Chewing, Difficulty Swallowing, Bleeding Gums, Other )",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "ros_mouthDiag",
              type: "options_tree",
              isMulti: true,
              label: "Diagnoses",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_mouthDiagBleedGumsSubDesc",
                      type: "options_tree",
                      label: "Bleeding Gums Describe",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Bleeding Gums",
                  value: "bleedingGums",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_mouthDiagDiffChewSubDesc",
                      type: "options_tree",
                      label: "Difficulty Chewing Describe",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "ros_mouthDiagDiffChewSubBcsPain",
                      type: "options_tree",
                      label: "Because of pain",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_mouthDiagDiffChewSubBcsPainSubDentures",
                              type: "options_tree",
                              label:
                                "Because you wear partial or complete dentures",
                              xs: 12,
                              isCommentEnabled: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Yes",
                                  value: "yes",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "No",
                                  value: "no",
                                  type: "toggle_button",
                                },
                              ],
                              subQuestionId: 1,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Yes",
                          value: "yes",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Difficulty Chewing",
                  value: "difficultyChewing",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_mouthDiagDiffSwallowSubDesc",
                      type: "options_tree",
                      label: "Difficulty Swallowing Describe",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "ros_mouthDiagDiffSwallowSubHadStroke",
                      type: "options_tree",
                      label: "Have you had a stroke",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_mouthDiagDiffSwallowSubHadStrokeSubSpclDiet",
                              type: "options_tree",
                              label: "Do you eat a special diet",
                              xs: 12,
                              isCommentEnabled: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Yes",
                                  value: "yes",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "No",
                                  value: "no",
                                  type: "toggle_button",
                                },
                              ],
                              subQuestionId: 1,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Yes",
                          value: "yes",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Difficulty Swallowing",
                  value: "difficultySwallowing",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_mouthDiagOtherSubDesc",
                      type: "options_tree",
                      label: "Other Describe ",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "ros_mouthDiagOtherSubSupp",
                      type: "options_tree",
                      label: "Supported by",
                      isMulti: true,
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History",
                          value: "history",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Symptoms",
                          value: "symptoms",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Physical Findings",
                          value: "physicalFindings",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Medications",
                          value: "medications",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Test results",
                          value: "testResults",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Image studies",
                          value: "imageStudies",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Biopsy",
                          value: "biopsy",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 8,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "DME",
                          value: "dme",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 9,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 1,
                              name: "ros_mouthDiagOtherSubSuppOtherSubDesc",
                              type: "text",
                              label: "Describe",
                              xs: 4,
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              subQuestionId: 1,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                    },
                    {
                      id: 1,
                      name: "ros_mouthDiagOtherSubOther",
                      type: "text",
                      label: "Other",
                      xs: 4,
                      placeholder: "",
                      defaultValue: "",
                      isCommentEnabled: false,
                      subQuestionId: 3,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Other",
                  value: "other",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 1,
            },
          ],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 8,
    },
    {
      id: 9,
      name: "neck",
      type: "heading",
      label: "NECK",
      xs: 12,
      isCommentEnabled: false,
      questionId: 9,
    },
    {
      id: 6,
      name: "ros_neck",
      type: "options_tree",
      label: "Neck Problems (parotid Disease, Carotid Stenosis, Other)",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "ros_neckDiag",
              type: "options_tree",
              label: "Diagnoses",
              isMulti: true,
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_neckDiagCarotidSubDesc",
                      type: "options_tree",
                      label: "Carotid Stenosis Describe",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_neckDiagCarotidSubDescActiveSubTreat",
                              type: "options_tree",
                              isMulti: true,
                              label: "Treatment",
                              xs: 12,
                              isCommentEnabled: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label: "Other Treatment",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      subQuestionId: 1,
                                      name: "ros_neckDiagCarotidSubDescActiveSubTreatOtherSubDesc",
                                      type: "text",
                                      label: "Other Treatment",
                                      xs: 12,
                                      isCommentEnabled: false,
                                    },
                                  ],
                                  isCommentEnabled: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  type: "toggle_button",
                                },
                              ],
                              subQuestionId: 2,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "ros_neckDiagCarotidSubCsDesc",
                      type: "options_tree",
                      label: "Describe",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Right",
                          value: "right",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Left",
                          value: "left",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Bilateral",
                          value: "bilateral",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 3,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Carotid Stenosis",
                  value: "carotidStenosis",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_neckDiagParotidSubDesc",
                      type: "options_tree",
                      label: "Parotid Disease Describe",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_neckDiagParotidSubDescActiveSubTreat",
                              type: "options_tree",
                              isMulti: true,
                              label: "Treatment",
                              xs: 12,
                              isCommentEnabled: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label: "Other Treatment",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      subQuestionId: 1,
                                      name: "ros_neckDiagParotidSubDescActiveSubTreatOtherSubDesc",
                                      type: "text",
                                      label: "Other Treatment",
                                      xs: 12,
                                      isCommentEnabled: false,
                                    },
                                  ],
                                  isCommentEnabled: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  type: "toggle_button",
                                },
                              ],
                              subQuestionId: 2,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Parotid Disease",
                  value: "parotidDisease",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_neckDiagOtherSubDesc",
                      type: "options_tree",
                      label: "Other Describe",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_neckDiagOtherSubDescActiveSubTreat",
                              type: "options_tree",
                              isMulti: true,
                              label: "Treatment",
                              xs: 12,
                              isCommentEnabled: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label: "Other Treatment",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      subQuestionId: 1,
                                      name: "ros_neckDiagOtherSubDescActiveSubTreatOtherSubDesc",
                                      type: "text",
                                      label: "Other Treatment",
                                      xs: 12,
                                      isCommentEnabled: false,
                                    },
                                  ],
                                  isCommentEnabled: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  type: "toggle_button",
                                },
                              ],
                              subQuestionId: 2,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 1,
                      name: "ros_neckDiagOtherSubOther",
                      type: "text",
                      label: "Other",
                      xs: 4,
                      placeholder: "",
                      defaultValue: "",
                      isCommentEnabled: false,
                      subQuestionId: 3,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Other",
                  value: "other",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 1,
            },
          ],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
        },
        {
          id: 6,
          type: "toggle_button",
          label: "No",
          xs: 12,
          isCommentEnabled: true,
          hasSubQuestions: false,
          subQuestions: [{}],
          subQuestionId: 2,
          value: "no",
        },
      ],
      questionId: 10,
    },
    {
      id: 10,
      name: "ros_eyeENTNeckRecommendations",
      label: "Recommendations",
      type: "checkbox",
      options: [
        ["Hearing evaluation.", "hearingEvaluation"],
        ["Dental exam.", "dentalExam"],
        ["Eye exam.", "eyeExam"],
        ["Swallowing evaluation.", "swallowingEvaluation"],
        ["Take medications as prescribed.", "takeMedications"],
        ["Case management referral for follow up.", "caseManagement"],
        ["Follow up with Clinician/Specialist.", "followUpClinician"],
        ["Member to use Durable Medical Equipment (DME).", "DME"],
        ["Other.", "other"],
      ],
      xs: 12,
      isCommentEnabled: true,
      questionId: 11,
    },
    {
      id: 9,
      name: "respiratory",
      type: "heading",
      label: "RESPIRATORY",
      editToggle: false,
      xs: 12,
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 12,
    },
    {
      id: 6,
      name: "ros_respiratory",
      type: "options_tree",
      label:
        "Respiratory Problems (COPD, Emphysema, Asthma, Chronic Bronchitis Pneumonia, Other)",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 1,
          subQuestionId: 1,
          hasSubQuestions: true,
          editToggle: false,
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          isConfigured: true,
          type: "toggle_button",
          subQuestions: [
            {
              id: 6,
              name: "ros_respiratoryDiagnoses",
              value: "diagnoses",
              type: "options_tree",
              label: "Diagnoses",
              isMulti: true,
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 2,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: " Acute Upper Respiratory Infection ",
                  name: "ros_respiratoryAcuteUpperSubAnswer",
                  value: "acuteUpper",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Acute Upper Respiratory Infection",
                      fullWidth: true,
                      required: false,
                      xs: 18,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_respiratoryAcuteUpperSubAnswerDescribe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          subQuestions: [
                            {
                              depth: 2,
                              hasSubQuestions: true,
                              editToggle: false,
                              subQuestionId: 1,
                              isCommentEnabled: true,
                              label: "Treatment",
                              isMulti: true,
                              value: "treatment",
                              name: "ros_respiratoryAcuteUpperActiveTreatmentSubAnswer",
                              isConfigured: true,
                              type: "options_tree",
                              subQuestions: [
                                {
                                  depth: 2,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  name: "ros_respiratoryAcuteUpperOtherTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label: "Other Treatment",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      depth: 1,
                                      subQuestionId: 1,
                                      editToggle: false,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: false,
                                      label: "Other Treatment",
                                      xs: 12,
                                      name: "ros_respiratoryAcuteUpperSubOtherTreatmentSubDesc",
                                      value: "describe",
                                      type: "text",
                                    },
                                  ],
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                        },

                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: " Asthma ",
                  value: "asthma",
                  name: "ros_respiratoryAsthmaSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Asthma",
                      fullWidth: true,
                      required: false,
                      xs: 18,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_respiratoryAsthmaSubAnswerDescribe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          subQuestions: [
                            {
                              depth: 2,
                              hasSubQuestions: true,
                              editToggle: false,
                              subQuestionId: 1,
                              isCommentEnabled: true,
                              label: "Treatment",
                              value: "activeTreatmentSubAnswer",
                              isMulti: true,
                              name: "ros_respiratoryAsthmaActiveTreatmentSubAnswer",
                              isConfigured: true,
                              type: "options_tree",
                              subQuestions: [
                                {
                                  depth: 2,
                                  subQuestionId: 1,
                                  hasSubQuestions: true,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label: "Medication",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      depth: 2,
                                      hasSubQuestions: true,
                                      editToggle: false,
                                      subQuestionId: 1,
                                      isCommentEnabled: true,
                                      label: "Select",
                                      isMulti: true,
                                      value: "medicationSubAnswer",
                                      name: "ros_respiratoryAsthmaMedicationSubAnswer",
                                      isConfigured: true,
                                      type: "options_tree",
                                      isMulti: true,
                                      subQuestions: [
                                        {
                                          depth: 2,
                                          subQuestionId: 1,
                                          hasSubQuestions: false,
                                          editToggle: false,
                                          isCommentEnabled: false,
                                          label: "Inhaled or Oral Steroids",
                                          value: "inhaledOrOralSteroids",
                                          isConfigured: true,
                                          type: "toggle_button",
                                        },
                                        {
                                          depth: 2,
                                          subQuestionId: 2,
                                          hasSubQuestions: false,
                                          editToggle: false,
                                          isCommentEnabled: false,
                                          label:
                                            "Use Rescue Inhaler more than 2 times a week",
                                          value: "rescueInhaler",
                                          isConfigured: true,
                                          type: "toggle_button",
                                        },
                                      ],
                                    },
                                  ],
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  name: "ros_respiratoryAsthmaSubOtherTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label: "Other Treatment",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      depth: 1,
                                      subQuestionId: 1,
                                      editToggle: false,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: false,
                                      label: "Other Treatment",
                                      xs: 12,
                                      name: "ros_respiratoryAsthmaSubOtherTreatmentSubDesc",
                                      value: "describe",
                                      type: "text",
                                    },
                                  ],
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 8,
                      name: "ros_respiratoryAsthmaCurrentExacerbation",
                      type: "options_tree",
                      value: "currentExacerbation",
                      label: "Does patient have current exacerbation",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Yes",
                          value: "yes",
                          isConfigured: true,
                          type: "toggle_button",
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              name: "ros_respiratoryAsthmaPatientControllerSubasthmaCurrentExacerbation",
                              type: "options_tree",
                              label: "Supported By",
                              editToggle: false,
                              xs: 12,
                              required: false,
                              value: "supported_by",
                              isCommentEnabled: true,
                              isMulti: true,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                              depth: 4,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 5,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  name: "wheezing",
                                  label: "Wheezing",
                                  subQuestions: [],
                                  editToggle: false,
                                  value: "wheezing",
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 5,
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  name: "shortness_breathe",
                                  label: "Shortness of breath",
                                  value: "shortnessOfBreath",
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 5,
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  name: "rescue_medications",
                                  label: "Use of rescue medications",
                                  value: "useOfRescue",
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 5,
                                  subQuestionId: 4,
                                  hasSubQuestions: false,
                                  name: "use_ventilator",
                                  label: "Use of Ventilator",
                                  value: "useOfVentilator",
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 5,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 5,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: " Chronic Respiratory Failure ",
                  value: "chronicRespiratoryFailure",
                  name: "ros_respiratoryChronicRespiratoryFailureSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Chronic Respiratory Failure",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_respiratoryChronicRespiratoryFailureSubAnswerDescribe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          subQuestions: [
                            {
                              depth: 2,
                              hasSubQuestions: true,
                              editToggle: false,
                              subQuestionId: 1,
                              isCommentEnabled: true,
                              label: "Treatment",
                              isMulti: true,
                              value: "activeTreatmentSubAnswer",
                              name: "ros_respiratoryChronicRespiratoryFailureTreatmentSubAnswer",
                              isConfigured: true,
                              isMulti: true,
                              type: "options_tree",
                              subQuestions: [
                                {
                                  depth: 2,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  name: "ros_respiratoryChronicRespiratoryFailureSubOtherTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label: "Other Treatment",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      depth: 1,
                                      subQuestionId: 1,
                                      editToggle: false,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: false,
                                      label: "Other Treatment",
                                      xs: 12,
                                      name: "ros_respiratoryChronicRespiratoryFailureSubOtherTreatmentSubDesc",
                                      value: "describe",
                                      type: "text",
                                    },
                                  ],
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 4,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Chronic use of o2 > 2L",
                                  value: "chronicUseOfo2",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 5,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Use of Ventilator",
                                  value: "useOfVentilator",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 6,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: " Chronic Sputum Production ",
                  value: "chronicSputumFailure",
                  name: "ros_respiratoryChronicSputumFailureSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Chronic Sputum Production",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_respiratoryChronicSputumFailureSubAnswerDescribe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          subQuestions: [
                            {
                              depth: 2,
                              hasSubQuestions: true,
                              editToggle: false,
                              subQuestionId: 1,
                              isCommentEnabled: true,
                              label: "Treatment",
                              isMulti: true,
                              value: "activeTreatmentSubAnswer",
                              name: "ros_respiratoryChronicSputumFailureActiveTreatmentSubAnswer",
                              isConfigured: true,
                              type: "options_tree",
                              subQuestions: [
                                {
                                  depth: 2,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  name: "ros_respiratoryAcuteUpperSubOtherTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label: "Other Treatment",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      depth: 1,
                                      subQuestionId: 1,
                                      editToggle: false,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: false,
                                      label: "Other Treatment",
                                      xs: 12,
                                      name: "ros_respiratoryChronicSputumFailureSubOtherTreatmentSubDesc",
                                      value: "describe",
                                      type: "text",
                                    },
                                  ],
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 7,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: " COPD ",
                  value: "copd",
                  name: "ros_respiratoryCopdSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "COPD",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_respiratoryCopdSubAnswerDescribe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          subQuestions: [
                            {
                              depth: 2,
                              hasSubQuestions: true,
                              editToggle: false,
                              subQuestionId: 1,
                              isCommentEnabled: true,
                              label: "Type",
                              value: "type",
                              name: "ros_respiratoryCopdTypeSubAnswer",
                              isConfigured: true,
                              type: "options_tree",

                              subQuestions: [
                                {
                                  depth: 2,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Chronic Bronchitis",
                                  value: "chronicBronchitis",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Emphysema",
                                  value: "emphysema",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "UnKnown",
                                  value: "unknown",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                            {
                              depth: 2,
                              hasSubQuestions: true,
                              editToggle: false,
                              subQuestionId: 2,
                              isCommentEnabled: true,
                              label: "Treatment",
                              isMulti: true,
                              value: "medicationSubAnswer",
                              name: "ros_respiratoryCopdActiveTreatmentSubAnswer",
                              isConfigured: true,
                              type: "options_tree",
                              subQuestions: [
                                {
                                  depth: 2,
                                  subQuestionId: 1,
                                  hasSubQuestions: true,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label: "Medication",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      depth: 2,
                                      hasSubQuestions: true,
                                      editToggle: false,
                                      subQuestionId: 1,
                                      isCommentEnabled: true,
                                      label: "Select",
                                      isMulti: true,
                                      value: "select",
                                      name: "ros_respiratoryCopdMedicationSubAnswer",
                                      isConfigured: true,
                                      type: "options_tree",
                                      isMulti: true,
                                      subQuestions: [
                                        {
                                          depth: 2,
                                          subQuestionId: 1,
                                          hasSubQuestions: false,
                                          editToggle: false,
                                          isCommentEnabled: false,
                                          label: "Inhaled or Oral Steroids",
                                          value: "inhaledOrOralSteroids",
                                          isConfigured: true,
                                          type: "toggle_button",
                                        },
                                        {
                                          depth: 2,
                                          subQuestionId: 2,
                                          hasSubQuestions: false,
                                          editToggle: false,
                                          isCommentEnabled: false,
                                          label:
                                            "Use Rescue Inhaler more than 2 times a week",
                                          value: "rescueInhaler",
                                          isConfigured: true,
                                          type: "toggle_button",
                                        },
                                      ],
                                    },
                                    {
                                      id: 10,
                                      name: "ros_respiratoryPatientExacerbation",
                                      type: "options_tree",
                                      label:
                                        "Does patient have current exacerbation",
                                      fullWidth: true,
                                      required: true,
                                      value: "patientExacerbation",
                                      xs: 12,
                                      isCommentEnabled: true,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                      subQuestions: [
                                        {
                                          depth: 1,
                                          subQuestionId: 1,
                                          editToggle: false,
                                          isCommentEnabled: false,
                                          label: "Yes",
                                          value: "yes",
                                          isConfigured: true,
                                          type: "toggle_button",
                                          hasSubQuestions: true,
                                          subQuestions: [
                                            {
                                              id: 1,
                                              name: "ros_respiratoryCopdSubPatientExacerbation",
                                              type: "options_tree",
                                              label: "Supported by",
                                              editToggle: false,
                                              xs: 12,
                                              required: false,
                                              value: "",
                                              placeholder: "",
                                              isMulti: true,
                                              defaultValue: "",
                                              isCommentEnabled: true,
                                              isConfigured: true,
                                              isModified: true,
                                              subQuestionId: 1,
                                              depth: 4,
                                              hasSubQuestions: true,
                                              subQuestions: [
                                                {
                                                  depth: 5,
                                                  subQuestionId: 1,
                                                  hasSubQuestions: false,
                                                  label: "Wheezing",
                                                  subQuestions: [],
                                                  editToggle: false,
                                                  value: "wheezing",
                                                  isCommentEnabled: false,
                                                  isConfigured: true,
                                                  type: "toggle_button",
                                                },
                                                {
                                                  depth: 5,
                                                  subQuestionId: 2,
                                                  hasSubQuestions: false,
                                                  label: "Shortness of breath",
                                                  value: "shortnessOfBreath",
                                                  subQuestions: [],
                                                  editToggle: false,
                                                  isCommentEnabled: false,
                                                  isConfigured: true,
                                                  type: "toggle_button",
                                                },
                                                {
                                                  depth: 5,
                                                  subQuestionId: 3,
                                                  hasSubQuestions: false,
                                                  label:
                                                    "Use Of Rescue Medications",
                                                  value:
                                                    "useOfRescueMedications",
                                                  subQuestions: [],
                                                  editToggle: false,
                                                  isCommentEnabled: false,
                                                  isConfigured: true,
                                                  type: "toggle_button",
                                                },
                                                {
                                                  depth: 5,
                                                  subQuestionId: 4,
                                                  hasSubQuestions: false,
                                                  label: "Use of ventilator",
                                                  value: "useOfVentilator",
                                                  subQuestions: [],
                                                  editToggle: false,
                                                  isCommentEnabled: false,
                                                  isConfigured: true,
                                                  type: "toggle_button",
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                        {
                                          subQuestionId: 2,
                                          hasSubQuestions: false,
                                          subQuestions: [],
                                          editToggle: false,
                                          isCommentEnabled: false,
                                          isConfigured: true,
                                          isModified: false,
                                          label: "No",
                                          value: "no",
                                          type: "toggle_button",
                                        },
                                      ],
                                      subQuestionId: 7,
                                      editToggle: false,
                                    },
                                  ],
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  name: "ros_respiratoryCopdSubOtherTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label: "Other Treatment",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      depth: 1,
                                      subQuestionId: 1,
                                      editToggle: false,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: false,
                                      label: "Other Treatment",
                                      xs: 12,
                                      name: "ros_respiratoryCopdSubOtherTreatmentSubDesc",
                                      value: "describe",
                                      type: "text",
                                    },
                                  ],
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 4,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "O2 Use",
                                  value: "o2Use",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 8,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: " Cystic Fibrosis ",
                  value: "cysticFibrosis",
                  name: "ros_respiratoryCysticFibrosisSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Cystic Fibrosis",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_respiratoryCysticFibrosisSubAnswerDescribe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          subQuestions: [
                            {
                              depth: 2,
                              hasSubQuestions: true,
                              editToggle: false,
                              subQuestionId: 1,
                              isCommentEnabled: true,
                              label: "Treatment",
                              isMulti: true,
                              isMulti: true,
                              value: "activeTreatmentSubAnswer",
                              name: "ros_respiratoryCysticFibrosisActiveTreatmentSubAnswer",
                              isConfigured: true,
                              type: "options_tree",
                              subQuestions: [
                                {
                                  depth: 2,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  name: "ros_respiratoryCysticFibrosisSubOtherTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label: "Other Treatment",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      depth: 1,
                                      subQuestionId: 1,
                                      editToggle: false,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: false,
                                      label: "Other Treatment",
                                      xs: 12,
                                      name: "ros_respiratoryCysticFibrosisSubOtherTreatmentSubDesc",
                                      value: "describe",
                                      type: "text",
                                    },
                                  ],
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 9,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: " Hypoventilation secondary to Obesity ",
                  value: "hypoventilation",
                  name: "ros_respiratoryHypoventilationSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Hypoventilation secondary to Obesity",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_respiratoryHypoventilationSubAnswerDescribe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          subQuestions: [
                            {
                              depth: 2,
                              hasSubQuestions: true,
                              editToggle: false,
                              subQuestionId: 1,
                              isCommentEnabled: true,
                              label: "Select",
                              isMulti: true,
                              value: "activeSelectSubAnswer",
                              name: "ros_respiratoryHypoventilationActiveSelectSubAnswer",
                              isConfigured: true,
                              type: "options_tree",
                              subQuestions: [
                                {
                                  depth: 2,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "O2 Use",
                                  value: "o2Use",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },

                                {
                                  depth: 2,
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Morbid Obesity",
                                  value: "morbidObesity",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                            {
                              depth: 2,
                              hasSubQuestions: true,
                              editToggle: false,
                              subQuestionId: 2,
                              isCommentEnabled: true,
                              label: "Treatment",
                              isMulti: true,
                              value: "activeTreatmentSubAnswer",
                              name: "ros_respiratoryHypoventilationActiveTreatmentSubAnswer",
                              isConfigured: true,
                              type: "options_tree",
                              subQuestions: [
                                {
                                  depth: 2,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  name: "ros_respiratoryHypoventilationSubOtherTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label: "Other Treatment",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      depth: 1,
                                      subQuestionId: 1,
                                      editToggle: false,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: false,
                                      label: "Other Treatment",
                                      xs: 12,
                                      name: "ros_respiratoryHypoventilationSubOtherTreatmentSubDesc",
                                      value: "describe",
                                      type: "text",
                                    },
                                  ],
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 10,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: " Hypoxemia ",
                  value: "hypoxemia",
                  name: "ros_respiratoryHypoxemiaSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Hypoxemia",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_respiratoryHypoxemiaSubAnswerDescribe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          subQuestions: [
                            {
                              depth: 2,
                              hasSubQuestions: true,
                              editToggle: false,
                              subQuestionId: 1,
                              isCommentEnabled: true,
                              label: "Select",
                              isMulti: true,
                              value: "activeSelectSubAnswer",
                              name: "ros_respiratoryHypoxemiaActiveSelectSubAnswer",
                              isConfigured: true,
                              type: "options_tree",
                              subQuestions: [
                                {
                                  depth: 2,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "O2 Saturation of <90% on room air",
                                  value: "o2Saturation",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                            {
                              depth: 2,
                              hasSubQuestions: true,
                              editToggle: false,
                              subQuestionId: 2,
                              isCommentEnabled: true,
                              label: "Treatment",
                              isMulti: true,
                              value: "activeTreatmentSubAnswer",
                              name: "ros_respiratoryHypoxemiaActiveTreatmentSubAnswer",
                              isConfigured: true,
                              type: "options_tree",
                              subQuestions: [
                                {
                                  depth: 2,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  name: "ros_respiratoryHypoxemiaSubOtherTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label: "Other Treatment",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      depth: 1,
                                      subQuestionId: 1,
                                      editToggle: false,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: false,
                                      label: "Other Treatment",
                                      xs: 12,
                                      name: "ros_respiratoryHypoxemiaSubOtherTreatmentSubDesc",
                                      value: "describe",
                                      type: "text",
                                    },
                                  ],
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 11,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: " Pneumonia ",
                  value: "pneumonia",
                  name: "ros_respiratoryPneumoniaSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Pneumonia",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_respiratoryPneumoniaSubAnswerDescribe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_respiratoryPneumoniaSubAnswerEtiology",
                              type: "options_tree",
                              label: "Etiology",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 1,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Viral",
                                  value: "viral",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Pneumococcal",
                                  value: "pneumococcal",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Staph",
                                  value: "staph",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 4,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Other Bacterial",
                                  value: "otherBacterial",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 5,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Aspiration",
                                  value: "aspiration",
                                  name: "aspiration",
                                  type: "toggle_button",
                                },
                              ],
                              subQuestionId: 3,
                              editToggle: false,
                            },
                            {
                              id: 7,
                              name: "ros_respiratoryHistoryOfLungAbscess",
                              type: "options_tree",
                              label: "Finding of Lung abscess",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 1,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Yes",
                                  value: "yes",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "No",
                                  value: "no",
                                  type: "toggle_button",
                                },
                              ],
                              subQuestionId: 4,
                              editToggle: false,
                            },
                            {
                              id: 8,
                              name: "ros_respiratoryHistoryOfEmpyema",
                              type: "options_tree",
                              label: "Finding of Empyema",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 1,
                                  subQuestionId: 1,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Yes",
                                  value: "yes",
                                  isConfigured: true,
                                  type: "toggle_button",
                                  hasSubQuestions: false,
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "No",
                                  value: "no",
                                  type: "toggle_button",
                                },
                              ],
                              subQuestionId: 5,
                              editToggle: false,
                            },
                            {
                              depth: 2,
                              hasSubQuestions: true,
                              editToggle: false,
                              subQuestionId: 1,
                              isCommentEnabled: true,
                              label: "Treatment",
                              isMulti: true,
                              value: "activeTreatmentSubAnswer",
                              name: "ros_respiratoryPneumoniaActiveTreatmentSubAnswer",
                              isConfigured: true,
                              type: "options_tree",
                              subQuestions: [
                                {
                                  depth: 2,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  name: "ros_respiratoryPneumoniaSubOtherTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label: "Other Treatment",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      depth: 1,
                                      subQuestionId: 1,
                                      editToggle: false,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: false,
                                      label: "Other Treatment",
                                      xs: 12,
                                      name: "ros_respiratoryPneumoniaSubOtherTreatmentSubDesc",
                                      value: "describe",
                                      type: "text",
                                    },
                                  ],
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 12,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: " Pulmonary Fibrosis ",
                  value: "pulmonaryFibrosis",
                  name: "ros_respiratoryPulmonaryFibrosisSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Pulmonary Fibrosis",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_respiratoryPulmonaryFibrosisSubAnswerDescribe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          subQuestions: [
                            {
                              depth: 2,
                              hasSubQuestions: true,
                              editToggle: false,
                              subQuestionId: 1,
                              isCommentEnabled: true,
                              label: "Treatment",
                              isMulti: true,
                              isMulti: true,
                              value: "activeTreatmentSubAnswer",
                              name: "ros_respiratoryPulmonaryFibrosisActiveTreatmentSubAnswer",
                              isConfigured: true,
                              type: "options_tree",
                              subQuestions: [
                                {
                                  depth: 2,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  name: "ros_respiratoryPulmonaryFibrosisSubOtherTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label: "Other Treatment",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      depth: 1,
                                      subQuestionId: 1,
                                      editToggle: false,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: false,
                                      label: "Other Treatment",
                                      xs: 12,
                                      name: "ros_respiratoryPulmonaryFibrosisSubOtherTreatmentSubDesc",
                                      value: "describe",
                                      type: "text",
                                    },
                                  ],
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 13,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: " Pulmonary Embolism ",
                  value: "pulmonaryEmbolism",
                  name: "ros_respiratoryPulmonaryEmbolismSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Pulmonary Embolism",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_respiratoryPulmonaryEmbolismSubAnswerDescribe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          subQuestions: [
                            {
                              depth: 2,
                              hasSubQuestions: true,
                              editToggle: false,
                              subQuestionId: 1,
                              isCommentEnabled: true,
                              label: "Select",
                              value: "activeSelectSubAnswer",
                              name: "ros_respiratoryPulmonaryEmbolismActiveSelectSubAnswer",
                              isConfigured: true,
                              type: "options_tree",
                              subQuestions: [
                                {
                                  depth: 2,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Acute",
                                  value: "acute",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },

                                {
                                  depth: 2,
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Chronic",
                                  value: "chronic",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                            {
                              depth: 2,
                              hasSubQuestions: true,
                              editToggle: false,
                              subQuestionId: 2,
                              isCommentEnabled: true,
                              label: "Treatment",
                              isMulti: true,
                              isMulti: true,
                              value: "activeTreatmentSubAnswer",
                              name: "ros_respiratoryPulmonaryEmbolismActiveTreatmentSubAnswer",
                              isConfigured: true,
                              type: "options_tree",
                              subQuestions: [
                                {
                                  depth: 2,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  name: "ros_respiratoryPulmonaryEmbolismSubOtherTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label: "Other Treatment",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      depth: 1,
                                      subQuestionId: 1,
                                      editToggle: false,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: false,
                                      label: "Other Treatment",
                                      xs: 12,
                                      name: "ros_respiratoryPulmonaryEmbolismSubOtherTreatmentSubDesc",
                                      value: "describe",
                                      type: "text",
                                    },
                                  ],
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                          subQuestions: [
                            {
                              depth: 2,
                              hasSubQuestions: true,
                              editToggle: false,
                              subQuestionId: 1,
                              isCommentEnabled: true,
                              label: "Select",
                              value: "activeSelectSubAnswer",
                              name: "ros_respiratoryPulmonaryEmbolismHistoryOfSelectSubAnswer",
                              isConfigured: true,
                              type: "options_tree",
                              subQuestions: [
                                {
                                  depth: 2,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Acute (Within the last 3 months)",
                                  value: "acute",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },

                                {
                                  depth: 2,
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label:
                                    "Chronic (Persistent for more than 3 months)",
                                  value: "chronic",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                            {
                              depth: 2,
                              hasSubQuestions: true,
                              editToggle: false,
                              subQuestionId: 1,
                              isCommentEnabled: true,
                              label: "Treatment",
                              isMulti: true,
                              isMulti: true,
                              value: "activeTreatmentSubAnswer",
                              name: "ros_respiratoryPulmonaryEmbolismHistoryOfTreatmentSubAnswer",
                              isConfigured: true,
                              type: "options_tree",
                              subQuestions: [
                                {
                                  depth: 2,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  editToggle: false,
                                  isCommentEnabled: true,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  name: "ros_respiratoryPulmonaryEmbolismHistoryOfSubOtherTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label: "Other Treatment",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      depth: 1,
                                      subQuestionId: 1,
                                      editToggle: false,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: false,
                                      label: "Other Treatment",
                                      xs: 12,
                                      name: "ros_respiratoryPulmonaryEmbolismHistoryOfSubOtherTreatmentSubDesc",
                                      value: "describe",
                                      type: "text",
                                    },
                                  ],
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 14,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: " Respirator Dependence",
                  value: "respiratorDependence",
                  name: "ros_respiratoryRespiratorDependenceSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Respirator Dependence/ Tracheostomy Status",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_respiratoryRespiratorDependenceSubAnswerDescribe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          subQuestions: [
                            {
                              depth: 2,
                              hasSubQuestions: true,
                              editToggle: false,
                              subQuestionId: 1,
                              isCommentEnabled: true,
                              label: "Treatment",
                              isMulti: true,
                              value: "activeTreatmentSubAnswer",
                              name: "ros_respiratoryRespiratorDependenceActiveTreatmentSubAnswer",
                              isConfigured: true,
                              type: "options_tree",
                              subQuestions: [
                                {
                                  depth: 2,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  name: "ros_respiratorDependenceSubOtherTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label: "Other Treatment",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      depth: 1,
                                      subQuestionId: 1,
                                      editToggle: false,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: false,
                                      label: "Other Treatment",
                                      xs: 12,
                                      name: "ros_respiratoryRespiratorDependenceSubOtherTreatmentSubDesc",
                                      value: "describe",
                                      type: "text",
                                    },
                                  ],
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 4,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Tracheostomy Present",
                                  value: "tracheostomyPresent",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 15,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: " Respiratory Arrest ",
                  value: "respiratoryArrest",
                  name: "ros_respiratoryRespiratoryArrestSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Respiratory Arrest",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_respiratoryRespiratoryArrestSubAnswerDescribe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active(in past 3 months)",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          subQuestions: [
                            {
                              depth: 2,
                              hasSubQuestions: true,
                              editToggle: false,
                              subQuestionId: 1,
                              isCommentEnabled: true,
                              label: "Treatment",
                              isMulti: true,
                              value: "activeTreatmentSubAnswer",
                              name: "ros_respiratoryRespiratoryArrestActiveTreatmentSubAnswer",
                              isConfigured: true,
                              type: "options_tree",
                              subQuestions: [
                                {
                                  depth: 2,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  name: "ros_respiratoryRespiratoryArrestSubOtherTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label: "Other Treatment",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      depth: 1,
                                      subQuestionId: 1,
                                      editToggle: false,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: false,
                                      label: "Other Treatment",
                                      xs: 12,
                                      name: "ros_respiratoryRespiratoryArrestSubOtherTreatmentSubDesc",
                                      value: "describe",
                                      type: "text",
                                    },
                                  ],
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 4,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Ventilator Use",
                                  value: "useOfVentilator",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 16,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: " Sarcoidosis ",
                  value: "sarcoidosis",
                  name: "ros_respiratorySarcoidosisSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Sarcoidosis",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_respiratorySarcoidosisSubAnswerDescribe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_respiratorySarcoidosisSubAnswerSpecify",
                      type: "options_tree",
                      label: " Specify",
                      value: "specify",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Lung",
                          value: "lung",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Lymph Nodes",
                          value: "lymphNodes",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Skin",
                          value: "skin",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Cranial Nerves",
                          value: "cranialNerves",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Unknown",
                          value: "unKnown",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                    },
                    {
                      depth: 2,
                      hasSubQuestions: true,
                      editToggle: false,
                      subQuestionId: 3,
                      isCommentEnabled: true,
                      label: "Treatment",
                      isMulti: true,
                      value: "activeTreatmentSubAnswer",
                      name: "ros_respiratorySarcoidosisActiveTreatmentSubAnswer",
                      isConfigured: true,
                      type: "options_tree",
                      subQuestions: [
                        {
                          depth: 2,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Medication",
                          value: "medication",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          depth: 2,
                          subQuestionId: 2,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Other Treatment",
                          value: "otherTreatment",
                          name: "ros_respiratorySarcoidosisSubOtherTreatment",
                          isConfigured: true,
                          type: "toggle_button",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other Treatment",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              depth: 1,
                              subQuestionId: 1,
                              editToggle: false,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: false,
                              label: "Other Treatment",
                              xs: 12,
                              name: "ros_respiratorySarcoidosisSubOtherTreatmentSubDesc",
                              value: "describe",
                              type: "text",
                            },
                          ],
                        },
                        {
                          depth: 2,
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "No Treatment",
                          value: "noTreatment",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                      ],
                    },
                  ],
                },
                {
                  subQuestionId: 17,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: " Sleep Apnea ",
                  value: "sleepApnea",
                  name: "ros_respiratorySleepApneaSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Sleep Apnea",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_respiratorySleepApneaSubAnswerDescribe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          subQuestions: [
                            {
                              depth: 2,
                              hasSubQuestions: true,
                              editToggle: false,
                              subQuestionId: 1,
                              isCommentEnabled: true,
                              label: "Snoring",
                              value: "activeSelectSubAnswer",
                              name: "ros_respiratorySleepApneaActiveSelectSubAnswer",
                              isConfigured: true,
                              type: "options_tree",
                              subQuestions: [
                                {
                                  depth: 2,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Yes",
                                  value: "yes",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },

                                {
                                  depth: 2,
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "No",
                                  value: "no",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                            {
                              depth: 2,
                              hasSubQuestions: true,
                              editToggle: false,
                              subQuestionId: 2,
                              isCommentEnabled: true,
                              label: "Treatment",
                              isMulti: true,
                              value: "activeTreatmentSubAnswer",
                              name: "ros_respiratorySleepApneaActiveTreatmentSubAnswer",
                              isConfigured: true,
                              type: "options_tree",
                              subQuestions: [
                                {
                                  depth: 2,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  name: "ros_respiratorySleepApneaSubOtherTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label: "Other Treatment",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      depth: 1,
                                      subQuestionId: 1,
                                      editToggle: false,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: false,
                                      label: "Other Treatment",
                                      xs: 12,
                                      name: "ros_respiratorySleepApneaSubOtherTreatmentSubDesc",
                                      value: "describe",
                                      type: "text",
                                    },
                                  ],
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 4,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "CPAP",
                                  value: "cpap",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 18,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Lung Transplant",
                  value: "lungTransplant",
                  name: "ros_respiratoryLungTransplantSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Lung Transplant",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      depth: 1,
                      subQuestionId: 1,
                      editToggle: false,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: false,
                      label: "Date of Transplant (Month, Day, Year)",
                      xs: 12,
                      name: "ros_respiratoryLungTransplantSubOtherTreatmentSubDesc",
                      value: "dateOfTransplant",
                      type: "text",
                    },
                  ],
                },
                {
                  subQuestionId: 19,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: " Other ",
                  value: "other",
                  name: "ros_respiratoryOtherSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Other",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_respiratoryRespOtherSubAnswerDescribe",
                      value: "describe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_respiratoryRespOtherSubAnswerSupportedBy",
                      type: "options_tree",
                      value: "supportedBy",
                      label: " Supported by",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "History",
                          value: "history",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Symptoms",
                          value: "symptoms",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Physical Findings",
                          value: "physicalFindings",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Medications",
                          value: "medications",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Test results",
                          value: "testResults",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Image studies",
                          value: "imageStudies",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Biopsy",
                          value: "biopsy",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 8,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "DME",
                          value: "dme",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 9,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Shortness of breath",
                          value: "shortnessOfBreath",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 10,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Wheezing",
                          value: "wheezing",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 11,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Chronic cough",
                          value: "chronicCough",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 12,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          name: "ros_respiratoryOtherSubOther",
                          type: "toggle_button",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              subQuestionId: 1,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              subQuestionId: 2,
                              name: "ros_respiratoryOtherSubDesc",
                              value: "describe",
                              type: "text",
                              placeholder: "",
                              label: "Describe",
                              variant: "outlined",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              sm: 3,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: false,
                            },
                          ],
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                    },
                    {
                      id: 4,
                      name: "ros_respiratoryOtherSubAnswerOther",
                      depth: 3,
                      type: "text",
                      label: "Other",
                      value: "other",
                      editToggle: false,
                      xs: 12,
                      required: false,
                      placeholder: "",
                      defaultValue: "",
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      subQuestionId: 3,
                      hasSubQuestions: false,
                    },
                  ],
                },
              ],
              subQuestionId: 1,
              editToggle: false,
            },
            {
              id: 9,
              questionId: 2,
              hasSubQuestions: true,
              editToggle: false,
              depth: 3,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: false,
              name: "ros_respiratorySymptoms",
              label: "Symptoms",
              isMulti: true,
              value: "symptoms",
              type: "options_tree",
              subQuestions: [
                {
                  subQuestionId: 1,
                  depth: 1,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: true,
                  isConfigured: true,
                  isModified: false,
                  label: "Shortness of breath",
                  value: "sob",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Chronic Cough",
                  value: "chronicCough",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Wheezing",
                  value: "wheezing",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Other",
                  value: "other",
                  name: "ros_respiratorySymptomsSubOther",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Other",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      depth: 1,
                      subQuestionId: 1,
                      editToggle: false,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: false,
                      label: "Other",
                      xs: 12,
                      name: "ros_respiratorySymptomsSubOtherSubDesc",
                      value: "describe",
                      type: "text",
                    },
                  ],
                },
                {
                  subQuestionId: 5,
                  hasSubQuestions: false,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "None",
                  value: "none",
                  type: "toggle_button",
                },
              ],
            },
          ],
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 13,
      editToggle: false,
    },
    {
      id: 10,
      name: "respiratory_Recommendations",
      value: "takeMedications",
      label: "Recommendations",
      placeholder: "",
      editToggle: false,
      defaultValue: "",
      type: "checkbox",
      options: [
        ["Take medications as prescribed.", "takeMedications"],
        ["Case management referral for follow up.", "caseManagement"],
        ["Follow up with Clinician/Specialist.", "followUpClinician"],
        ["Member to use Durable Medical Equipment (DME).", "DME"],
        ["Other.", "other"],
      ],
      xs: 12,
      required: false,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      questionId: 14,
    },
    {
      id: 9,
      name: "cardiovascular",
      type: "heading",
      label: "CARDIOVASCULAR",
      editToggle: false,
      xs: 12,
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 15,
      depth: 1,
    },
    {
      id: 6,
      name: "ros_cardio",
      type: "options_tree",
      label:
        "Cardiovascular (Hypertension, Angina, Ischemic Heart Disease(CAD), Myocardial Infarction, Other)",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 2,
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "ros_cardioSubDiagnoses",
              type: "options_tree",
              label: " Diagnoses         ",
              isMulti: true,
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 4,
                  subQuestionId: 1,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Abnormal Cardiac Rhythm",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesabnormalCardiacRhythmDescribe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_cardioSubDiagnosesabnormalCardiacRhythmDescribeactiveSubAnswer",
                              type: "options_tree",
                              isMulti: true,
                              label: "Treatment",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  subQuestions: [
                                    {
                                      id: 1,
                                      name: "ros_cardioSubDiagnosesabnormalCardiacRhythmDescribeactiveSubAnswerOtherTreatmentSubAnswer",
                                      type: "text",
                                      label: "Other Treatment",
                                      editToggle: false,
                                      xs: 4,
                                      required: "no",
                                      placeholder: "",
                                      defaultValue: "",
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      subQuestionId: 1,
                                      depth: 9,
                                    },
                                  ],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 4,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Implanted Defibrillator",
                                  value: "implantedDefibrillator",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 5,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Pacemaker",
                                  value: "pacemaker",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 1,
                              editToggle: false,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "active",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                          name: "historyOf",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                      depth: 5,
                    },
                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesabnormalCardiacRhythmCrDes",
                      type: "options_tree",
                      label: "Describe   ",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_cardioSubDiagnosesabnormalCardiacRhythmSelectOne",
                              type: "options_tree",
                              label: "Select",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Sick Sinus Syndrome(SSS)",
                                  value: "sickSinusSyndromeSss",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Unknown",
                                  value: "unknown",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 1,
                              editToggle: false,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Bradycardia",
                          value: "bradycardia",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "bradycardia",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_cardioSubDiagnosesabnormalCardiacRhythmSelectTwo",
                              type: "options_tree",
                              label: "Select",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Supraventricular Tachycardia (SVT)",
                                  value: "supraventricularTachycardiaSvt",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Unknown",
                                  value: "unknown",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 1,
                              editToggle: false,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Tachycardia",
                          value: "tachycardia",
                          type: "toggle_button",
                          name: "tachycardia",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 3,
                      editToggle: false,
                      depth: 5,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  label: "Abnormal Cardiac Rhythm",
                  value: "abnormalCardiacRhythm",
                  isConfigured: true,
                  type: "toggle_button",
                  name: "abnormalCardiacRhythm",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Aneursym",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesAneurysmDes",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "active",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                          name: "history",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                      depth: 5,
                    },

                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesAneurysmCrDes",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Abdominal",
                          value: "abdominal",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "abdominal",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Thoracic",
                          value: "thoracic",
                          type: "toggle_button",
                          name: "thoracic",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Peripheral",
                          value: "peripheral",
                          type: "toggle_button",
                          name: "peripheral",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                      depth: 5,
                    },
                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesAneurysmType",
                      type: "options_tree",
                      label: "Type",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Ruptured",
                          value: "ruptured",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "ruptured",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Unruptured",
                          value: "unruptured",
                          type: "toggle_button",
                          name: "unruptured",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 3,
                      editToggle: false,
                      depth: 5,
                    },
                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesAneurysmTreatment",
                      type: "options_tree",
                      isMulti: true,
                      label: "Treatment",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Medication",
                          value: "medication",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "medication",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "ros_cardioSubDiagnosesAneurysmTreatOtherTreatmentSubAnswer",
                              type: "text",
                              label: "Other Treatment",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other Treatment",
                          value: "otherTreatment",
                          type: "toggle_button",
                          name: "otherTreatment",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No Treatment",
                          value: "noTreatment",
                          type: "toggle_button",
                          name: "noTreatment",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 4,
                      editToggle: false,
                      depth: 5,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Aneursym",
                  value: "aneursym",
                  type: "toggle_button",
                  name: "aneursym",
                  depth: 4,
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Angina",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesAnginaDes",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_cardioSubDiagnosesAnginaDescribeactiveSubAnswerDes",
                              type: "options_tree",
                              label: "Describe",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Stable",
                                  value: "stable",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },

                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Unstable",
                                  value: "Unstable",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 1,
                              editToggle: false,
                              depth: 7,
                            },

                            {
                              id: 6,
                              name: "ros_cardioSubDiagnosesAnginaDescribeactiveSubAnswerTreatment",
                              type: "options_tree",
                              isMulti: true,
                              label: "Treatment",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  subQuestions: [
                                    {
                                      id: 1,
                                      name: "ros_cardioSubDiagnosesAnginaDescribeactiveSubAnswerTreatmentotherSub",
                                      type: "text",
                                      label: "Other Treatment",
                                      editToggle: false,
                                      xs: 4,
                                      required: "no",
                                      placeholder: "",
                                      defaultValue: "",
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      subQuestionId: 1,
                                      depth: 9,
                                    },
                                  ],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 2,
                              editToggle: false,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "active",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                          name: "historyOf",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                      depth: 5,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Angina",
                  value: "angina",
                  type: "toggle_button",
                  name: "angina",
                  depth: 4,
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Atrial Flutter",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesAtrialFlutterDes",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_cardioSubDiagnosesAtrialFlutterDesActiveSubTreat",
                              type: "options_tree",
                              isMulti: true,
                              label: "Treatment",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  subQuestions: [
                                    {
                                      id: 1,
                                      name: "ros_cardioSubDiagnosesAtrialFlutterDesActiveSubTreatotherSub",
                                      type: "text",
                                      label: "Other Treatment",
                                      editToggle: false,
                                      xs: 4,
                                      required: "no",
                                      placeholder: "",
                                      defaultValue: "",
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      subQuestionId: 1,
                                      depth: 9,
                                    },
                                  ],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 4,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Implanted Defibrillator",
                                  value: "implantedDefibrillator",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 5,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Pacemaker",
                                  value: "pacemaker",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 1,
                              editToggle: false,
                              depth: 7,
                            },

                            {
                              id: 6,
                              name: "ros_cardioSubDiagnosesAtrialFlutterDesActiveSubisPatient",
                              type: "options_tree",
                              label: "Is patient taking",
                              isMulti: true,
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Anticoagulant",
                                  value: "anticoagulant",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },

                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Rate Controlling Medication",
                                  value: "rateControllingMedication",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: true,
                                  subQuestions: [
                                    {
                                      id: 1,
                                      name: "ros_cardioSubDiagnosesAtrialFlutterDesActiveSubisPatientOtherSub",
                                      type: "text",
                                      label: "Describe",
                                      editToggle: false,
                                      xs: 4,
                                      required: "no",
                                      placeholder: "",
                                      defaultValue: "",
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      subQuestionId: 1,
                                      depth: 9,
                                    },
                                  ],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Other",
                                  value: "other",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 2,
                              editToggle: false,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "active",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                          name: "historyOf",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 1,
                      depth: 5,
                      editToggle: false,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Atrial Flutter",
                  value: "atrialFlutter",
                  type: "toggle_button",
                  name: "atrialFlutter",
                  depth: 4,
                },
                {
                  subQuestionId: 5,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Atrial Fibrillation",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesAtrialFibrillationDes",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerType",
                              type: "options_tree",
                              label: "Type",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Paroxysmal",
                                  value: "paroxysmal",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },

                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Chronic",
                                  value: "chronic",
                                  type: "toggle_button",
                                  depth: 8,
                                },

                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Unknown",
                                  value: "unknown",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 1,
                              editToggle: false,
                              depth: 7,
                            },
                            {
                              id: 6,
                              name: "ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerTreat",
                              type: "options_tree",
                              isMulti: true,
                              label: "Treatment",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  subQuestions: [
                                    {
                                      id: 1,
                                      name: "ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerTreatOtherTrSub",
                                      type: "text",
                                      label: "Other Treatment",
                                      editToggle: false,
                                      xs: 4,
                                      required: "no",
                                      placeholder: "",
                                      defaultValue: "",
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      subQuestionId: 1,
                                      depth: 9,
                                    },
                                  ],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 4,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Implanted Defibrillator",
                                  value: "implantedDefibrillator",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 5,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Pacemaker",
                                  value: "pacemaker",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 2,
                              editToggle: false,
                              depth: 7,
                            },

                            {
                              id: 6,
                              name: "ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerPatient",
                              type: "options_tree",
                              label: "Is patient taking",
                              isMulti: true,
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Anticoagulant",
                                  value: "anticoagulant",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },

                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Rate Controlling Medication",
                                  value: "rateControllingMedication",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: true,
                                  subQuestions: [
                                    {
                                      id: 1,
                                      name: "ros_cardioSubDiagnosesAtrialFibrillationDescribeactiveSubAnswerPatientOtherSub",
                                      type: "text",
                                      label: "Describe",
                                      editToggle: false,
                                      xs: 4,
                                      required: "no",
                                      placeholder: "",
                                      defaultValue: "",
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      subQuestionId: 1,
                                      depth: 9,
                                    },
                                  ],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Other",
                                  value: "other",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 3,
                              editToggle: false,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "active",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                          name: "historyOf",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 1,
                      depth: 5,
                      editToggle: false,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Atrial Fibrillation",
                  value: "atrialFibrillation",
                  type: "toggle_button",
                  name: "atrialFibrillation",
                  depth: 4,
                },
                {
                  subQuestionId: 6,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Cardio-Respiratory Failure/Shock",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesCardiorespiratoryShockDes",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_cardioSubDiagnosesCardiorespiratoryShockDescribeactiveSubAnswerTreat",
                              type: "options_tree",
                              isMulti: true,
                              label: "Treatment",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  subQuestions: [
                                    {
                                      id: 1,
                                      name: "ros_cardioSubDiagnosesCardioresTreatOtherTrSub",
                                      type: "text",
                                      label: "Other Treatment",
                                      editToggle: false,
                                      xs: 4,
                                      required: "no",
                                      placeholder: "",
                                      defaultValue: "",
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      subQuestionId: 1,
                                      depth: 9,
                                    },
                                  ],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 4,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Implanted Defibrillator",
                                  value: "implantedDefibrillator",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 5,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Pacemaker",
                                  value: "pacemaker",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 1,
                              editToggle: false,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active(in the past 6 months)",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "active",
                        },

                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of ",
                          value: "historyOf",
                          type: "toggle_button",
                          name: "historyOf",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 1,
                      depth: 5,
                      editToggle: false,
                    },

                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesCardiorespiratoryShockCardicArrest",
                      type: "options_tree",
                      label: "Cardiac Arrest",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "yes",
                          label: "Yes",
                          value: "yes",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                          name: "no",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 3,
                      depth: 5,
                      editToggle: false,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Cardio-Respiratory Failure/Shock",
                  value: "cardioRespiratoryFailureShock",
                  type: "toggle_button",
                  name: "cardioRespiratoryFailureShock",
                  depth: 4,
                },
                {
                  subQuestionId: 7,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Cardiomyopathy",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesCardiomyopathyDes",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_cardioSubDiagnosesCardiomyopathyType",
                              type: "options_tree",
                              label: "Type",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Dilated",
                                  value: "dilated",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },

                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Hypertrophic",
                                  value: "hypertrophic",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Restrictive",
                                  value: "restrictive",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Unknown",
                                  value: "unknown",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 1,
                              editToggle: false,
                              depth: 7,
                            },
                            {
                              id: 6,
                              name: "ros_cardioSubDiagnosesCardiomyopathyDescribeactiveSubAnswerSeconHyper",
                              type: "options_tree",
                              label: "Secondary to Hypertension",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Yes",
                                  value: "yes",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },

                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "No",
                                  value: "no",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 2,
                              editToggle: false,
                              depth: 7,
                            },

                            {
                              id: 6,
                              name: "ros_cardioSubDiagnosesCardiomyopathyDescribeactiveSubAnswerTreat",
                              type: "options_tree",
                              isMulti: true,
                              label: "Treatment",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  subQuestions: [
                                    {
                                      id: 1,
                                      name: "ros_cardioSubDiagnosesCardiompTreatOtherTrSub",
                                      type: "text",
                                      label: "Other Treatment",
                                      editToggle: false,
                                      xs: 4,
                                      required: "no",
                                      placeholder: "",
                                      defaultValue: "",
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      subQuestionId: 1,
                                      depth: 9,
                                    },
                                  ],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 3,
                              editToggle: false,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "active",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                          name: "historyOf",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 1,
                      depth: 5,
                      editToggle: false,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Cardiomyopathy",
                  value: "cardiomyopathy",
                  type: "toggle_button",
                  name: "cardiomyopathy",
                  depth: 4,
                },
                {
                  subQuestionId: 8,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Congestive Heart Failure",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesCongestiveHeartFailureSpecify",
                      type: "options_tree",
                      label: "Specify",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "acute",
                          label: "Acute",
                          value: "acute",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Chronic",
                          value: "chronic",
                          type: "toggle_button",
                          name: "chronic",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Acute on Chronic",
                          value: "acuteOnChronic",
                          type: "toggle_button",
                          name: "acuteOnChronic",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 1,
                      depth: 5,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesCongestiveHeartFailureDes",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubDes",
                              type: "options_tree",
                              label: "Describe",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Diastolic",
                                  value: "diastolic",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },

                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Systolic",
                                  value: "systolic",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "UnKnown",
                                  value: "unKnown",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 1,
                              editToggle: false,
                              depth: 7,
                            },
                            {
                              id: 6,
                              name: "ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubCard",
                              type: "options_tree",
                              label: "cardiomegaly",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Yes",
                                  value: "yes",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },

                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "No",
                                  value: "no",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Unknown",
                                  value: "unknown",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 2,
                              editToggle: false,
                              depth: 7,
                            },
                            {
                              id: 6,
                              name: "ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubPeri",
                              type: "options_tree",
                              label: "Peripheral Edema",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Yes",
                                  value: "yes",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },

                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "No",
                                  value: "no",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Unknown",
                                  value: "unknown",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 3,
                              editToggle: false,
                              depth: 7,
                            },

                            {
                              id: 6,
                              name: "ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubTre",
                              type: "options_tree",
                              isMulti: true,
                              label: "Treatment",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  subQuestions: [
                                    {
                                      id: 1,
                                      name: "ros_cardioSubDiagnosesCongestiveHeartFailureDesActiveSubTreOtherSub",
                                      type: "text",
                                      label: "Other Treatment",
                                      editToggle: false,
                                      xs: 4,
                                      required: "no",
                                      placeholder: "",
                                      defaultValue: "",
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      subQuestionId: 1,
                                      depth: 9,
                                    },
                                  ],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 3,
                              editToggle: false,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "active",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                          name: "historyOf",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 2,
                      depth: 5,
                      editToggle: false,
                    },

                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesCongestiveHeartFailuresecondaryToHypertension",
                      type: "options_tree",
                      label: "Secondary to Hypertension",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "yes",
                          label: "Yes",
                          value: "yes",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                          name: "no",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 5,
                      depth: 5,
                      editToggle: false,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Congestive Heart Failure",
                  value: "congestiveHeartFailure",
                  type: "toggle_button",
                  name: "congestiveHeartFailure",
                  depth: 4,
                },
                {
                  subQuestionId: 9,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Deep Vein Thrombosis",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesDeepVeinThrombosisDes",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "active",
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                          name: "historyOf",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 2,
                      depth: 5,
                      editToggle: false,
                    },

                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesDeepVeinThrombosisTreat",
                      type: "options_tree",
                      isMulti: true,
                      label: "Treatment",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_cardioSubDiagnosesDeepVeinThrombosisTreatMedicationSubAnti",
                              type: "options_tree",
                              label: "Use of anticoagulation",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Prophylactic",
                                  value: "prophylactic",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Therapeutic",
                                  value: "therapeutic",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 1,
                              editToggle: false,
                              depth: 7,
                            },
                            {
                              id: 6,
                              name: "ros_cardioSubDiagnosesDeepVeinThrombosisTreatMedicationSubLoc",
                              type: "options_tree",
                              label: "Location",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Right Leg",
                                  value: "rightLeg",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Left Leg",
                                  value: "leftLeg",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [{}],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Bilateral Lower Extremities",
                                  value: "bilateralLower",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 4,
                                  hasSubQuestions: false,
                                  subQuestions: [{}],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Right Arm",
                                  value: "rightArm",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 5,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Left Arm",
                                  value: "leftArm",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 6,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Bilateral Upper Extremities",
                                  value: "bitlateralUpper",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 2,
                              editToggle: false,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Medication",
                          value: "medication",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "medication",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "ros_cardioSubDiagnosesDeepVienTreatotherSubAnswer",
                              type: "text",
                              label: "Other Treatment",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other Treatment",
                          value: "otherTreatment",
                          type: "toggle_button",
                          name: "otherTreatment",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No Treatment",
                          value: "noTreatment",
                          type: "toggle_button",
                          name: "noTreatment",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 3,
                      editToggle: false,
                      depth: 5,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Deep Vein Thrombosis",
                  value: "deepVeinThrombosis",
                  type: "toggle_button",
                  name: "deepVeinThrombosis",
                  depth: 4,
                },
                {
                  subQuestionId: 10,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Hyperlipidemia",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesHyperlipidemiaDes",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_cardioSubDiagnosesHyperlipidemiaDesActiveSubTre",
                              type: "options_tree",
                              isMulti: true,
                              label: "Treatment",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Diet Controlled",
                                  value: "dietControlled",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: true,
                                  subQuestions: [
                                    {
                                      id: 1,
                                      name: "ros_cardioSubDiagnosesHyperlipidemiaDesActiveSubTreOtherSub",
                                      type: "text",
                                      label: "Other Treatment",
                                      editToggle: false,
                                      xs: 4,
                                      required: "no",
                                      placeholder: "",
                                      defaultValue: "",
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      subQuestionId: 1,
                                      depth: 9,
                                    },
                                  ],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 4,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 1,
                              editToggle: false,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "active",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                          name: "historyOf",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 1,
                      depth: 5,
                      editToggle: false,
                    },

                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesHyperlipidemiapatientOnStatin",
                      type: "options_tree",
                      label: "Is patient on Statin",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "yes",
                          label: "Yes",
                          value: "yes",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                          name: "no",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 3,
                      depth: 5,
                      editToggle: false,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Hyperlipidemia",
                  value: "hyperlipidemia",
                  type: "toggle_button",
                  name: "hyperlipidemia",
                  depth: 4,
                },
                {
                  subQuestionId: 11,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Hypertension",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesHypertensionDes",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_cardioSubDiagnosesHypertensionDesActiveSubTre",
                              type: "options_tree",
                              isMulti: true,
                              label: "Treatment",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Diet Controlled",
                                  value: "dietControlled",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: true,
                                  subQuestions: [
                                    {
                                      id: 1,
                                      name: "ros_cardioSubDiagnosesHypertensionDesActiveSubTreOtherSub",
                                      type: "text",
                                      label: "Other Treatment",
                                      editToggle: false,
                                      xs: 4,
                                      required: "no",
                                      placeholder: "",
                                      defaultValue: "",
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      subQuestionId: 1,
                                      depth: 9,
                                    },
                                  ],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 4,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 1,
                              editToggle: false,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "active",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                          name: "historyOf",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 1,
                      depth: 5,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesHypertensionadequatelyControlled",
                      type: "options_tree",
                      label: "Adequately controlled",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "yes",
                          label: "Yes",
                          value: "yes",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                          name: "no",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Unknown",
                          value: "unknown",
                          type: "toggle_button",
                          name: "unknown",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 2,
                      depth: 5,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesHypertensionSymp",
                      type: "options_tree",
                      label: "Symptoms(related to HTN)",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "headAches",
                          label: "Headaches",
                          value: "headAches",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Light Headedness",
                          value: "lightheadedness",
                          type: "toggle_button",
                          name: "lightheadedness",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Blurred Vision",
                          value: "blurredVision",
                          type: "toggle_button",
                          name: "blurredVision",
                          depth: 6,
                        },

                        {
                          subQuestionId: 4,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "ros_cardioSubDiagnosesHypertensionSympotherSubAnswer",
                              type: "text",
                              label: "Other",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                          name: "other",
                          depth: 6,
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "None",
                          value: "none",
                          type: "toggle_button",
                          name: "none",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 3,
                      depth: 5,
                      editToggle: false,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Hypertension",
                  value: "hypertension",
                  type: "toggle_button",
                  name: "hypertension",
                  depth: 4,
                },
                {
                  subQuestionId: 12,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Ischemic Heart Disease(CAD)",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesIschemicHeartDiseaseDes",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_cardioSubDiagnosesIschemicHeartDiseaseDesActiveSubTre",
                              type: "options_tree",
                              isMulti: true,
                              label: "Treatment",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },

                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  subQuestions: [
                                    {
                                      id: 1,
                                      name: "ros_cardioSubDiagnosesIschemicHeartDiseaseDesActiveSubTreOtherSub",
                                      type: "text",
                                      label: "Other Treatment",
                                      editToggle: false,
                                      xs: 4,
                                      required: "no",
                                      placeholder: "",
                                      defaultValue: "",
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      subQuestionId: 1,
                                      depth: 9,
                                    },
                                  ],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  type: "toggle_button",
                                  depth: 8,
                                },

                                {
                                  subQuestionId: 4,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Coronary Stent",
                                  value: "coronaryStent",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 5,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Cabg",
                                  value: "cabg",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 6,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Use Of Nitrates",
                                  value: "useOfNitrates",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 1,
                              editToggle: false,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "active",
                        },

                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of ",
                          value: "historyOf",
                          type: "toggle_button",
                          name: "historyOf",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 1,
                      depth: 5,
                      editToggle: false,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Ischemic Heart Disease(CAD)",
                  value: "ischemicHeartDisease",
                  type: "toggle_button",
                  name: "ischemicHeartDisease",
                  depth: 4,
                },
                {
                  subQuestionId: 13,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Myocardial Infarction",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesMyocardialDes",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                          name: "historyOf",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 1,
                      depth: 5,
                      editToggle: false,
                    },
                    {
                      id: 4,
                      name: "ros_cardioSubDiagnosesMyocardialDate",
                      label: "Date",
                      placeholder: "",
                      type: "text",
                      editToggle: false,
                      xs: 4,
                      defaultValue: "",
                      fullWidth: true,
                      required: "no",
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      subQuestionId: 2,
                      depth: 5,
                    },

                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesMyocardialBetaBlocker",
                      type: "options_tree",
                      label: "Is patient taking a Beta Blocker",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "yes",
                          label: "Yes",
                          value: "yes",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                          name: "no",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 4,
                      depth: 5,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesMyocardialIspateint",
                      type: "options_tree",
                      label: "Is patient taking",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "aspirin",
                          label: "Aspirin",
                          value: "aspirin",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Plavix",
                          value: "plavix",
                          type: "toggle_button",
                          name: "plavix",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Nitrate",
                          value: "nitrate",
                          type: "toggle_button",
                          name: "nitrate",
                          depth: 6,
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "ros_cardioSubDiagnosesMyocardialIspatientotherSubAnswer",
                              type: "text",
                              label: "Describe",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                          name: "other",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 5,
                      depth: 5,
                      editToggle: false,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Myocardial Infarction",
                  value: "myocardialInfarction",
                  type: "toggle_button",
                  name: "myocardialInfarction",
                  depth: 4,
                },
                {
                  subQuestionId: 14,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Peripheral Vascular Disease",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesPeripheralVascularDiseaseDes",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_cardioSubDiagnosesPeripheralVascularDiseaseDesActiveSubSymp",
                              type: "options_tree",
                              isMulti: true,
                              label: "Symptoms",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Claudication",
                                  value: "claudication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },

                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Diminished",
                                  value: "diminished",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Extremity Ulcers",
                                  value: "extremityUlcers",
                                  type: "toggle_button",
                                  depth: 8,
                                },

                                {
                                  subQuestionId: 4,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Ulceration",
                                  value: "ulceration",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 5,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Gangrene",
                                  value: "gangrene",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 1,
                              editToggle: false,
                              depth: 7,
                            },

                            {
                              id: 6,
                              name: "ros_cardioSubDiagnosesPeripheralVascularDiseaseDesActiveSubTre",
                              type: "options_tree",
                              isMulti: true,
                              label: "Treatment",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  subQuestions: [
                                    {
                                      id: 1,
                                      name: "ros_cardioSubDiagnosesPeripheralVascularDiseaseDesActiveSubTreOtherSub",
                                      type: "text",
                                      label: "Other Treatment",
                                      editToggle: false,
                                      xs: 4,
                                      required: "no",
                                      placeholder: "",
                                      defaultValue: "",
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      subQuestionId: 1,
                                      depth: 9,
                                    },
                                  ],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 2,
                              editToggle: false,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "active",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of ",
                          value: "historyOf",
                          type: "toggle_button",
                          name: "historyOf",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 1,
                      depth: 5,
                      editToggle: false,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Peripheral Vascular Disease",
                  value: "peripheralVascularDisease",
                  type: "toggle_button",
                  name: "peripheralVascularDisease",
                  depth: 4,
                },
                {
                  subQuestionId: 15,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Pulmonary Hypertension",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesPulmonaryHypertensionDes",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_cardioSubDiagnosesPulmonaryHypertensionDesActiveSubDes",
                              type: "options_tree",
                              label: "Describe",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Primary",
                                  value: "primary",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },

                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Secondary",
                                  value: "secondary",
                                  type: "toggle_button",
                                  depth: 8,
                                },

                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Unknown",
                                  value: "unknown",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 1,
                              editToggle: false,
                              depth: 7,
                            },

                            {
                              id: 6,
                              name: "ros_cardioSubDiagnosesPulmonaryHypertensionDesActiveSubTre",
                              type: "options_tree",
                              label: "Treatment",
                              isMulti: true,
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },

                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  subQuestions: [
                                    {
                                      id: 1,
                                      name: "ros_cardioSubDiagnosesPulmonaryHypertensionDesActiveSubTreOtherSub",
                                      type: "text",
                                      label: "Other Treatment",
                                      editToggle: false,
                                      xs: 4,
                                      required: "no",
                                      placeholder: "",
                                      defaultValue: "",
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      subQuestionId: 1,
                                      depth: 9,
                                    },
                                  ],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 2,
                              editToggle: false,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "active",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                          name: "historyOf",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 1,
                      depth: 5,
                      editToggle: false,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Pulmonary Hypertension",
                  value: "pulmonaryHypertension",
                  type: "toggle_button",
                  name: "pulmonaryHypertension",
                  depth: 4,
                },
                {
                  subQuestionId: 16,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Valvular Disease",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesvalvulardiseaseDes",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "active",
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                          name: "historyOf",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 1,
                      depth: 5,
                      editToggle: false,
                    },

                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesvalvulardiseaseCrDes",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "mitralStenosis",
                          label: "Mitral Stenosis",
                          value: "mitralStenosis",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Aortic Stenosis/Sclerosis",
                          value: "aorticStenosisSclerosis",
                          type: "toggle_button",
                          name: "aorticStenosisSclerosis",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Tricuspid Stenosis",
                          value: "tricuspidStenosis",
                          type: "toggle_button",
                          name: "tricuspidStenosis",
                          depth: 6,
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Pulmonary Insufficiency",
                          value: "pulmonaryInsufficiency",
                          type: "toggle_button",
                          name: "pulmonaryInsufficiency",
                          depth: 6,
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Pulmonary Stenosis",
                          value: "pulmonaryStenosis",
                          type: "toggle_button",
                          name: "pulmonaryStenosis",
                          depth: 6,
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Arotic Insufficiency",
                          value: "aroticInsufficiency",
                          type: "toggle_button",
                          name: "aroticInsufficiency",
                          depth: 6,
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Mitral Insufficiency/Prolapse",
                          value: "mitralInsufficiencyProlapse",
                          type: "toggle_button",
                          name: "mitralInsufficiencyProlapse",
                          depth: 6,
                        },
                        {
                          subQuestionId: 8,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Tricuspid Insufficiency",
                          value: "tricuspidInsufficiency",
                          type: "toggle_button",
                          name: "tricuspidInsufficiency",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 3,
                      depth: 5,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesvalvulardiseaseValveReplacement",
                      type: "options_tree",
                      label: "Valve replacement",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "yes",
                          label: "Yes",
                          value: "yes",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                          name: "no",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 4,
                      depth: 5,
                      editToggle: false,
                    },
                    {
                      id: 1,
                      name: "ros_cardioSubDiagnosesvalvulardiseaseValueTypeOfReplacement",
                      type: "text",
                      label: "Which valve, type of replacement",
                      editToggle: false,
                      xs: 4,
                      required: "no",
                      placeholder: "",
                      defaultValue: "",
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      subQuestionId: 5,
                      depth: 5,
                    },
                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesvalvulardiseaseTreatment",
                      type: "options_tree",
                      isMulti: true,
                      label: "Treatment",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Medication",
                          value: "medication",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "medication",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "ros_cardioSubDiagnosesvalvulardiseaseTreatmentOtherTreatmentSubAnswers",
                              type: "text",
                              label: "Other Treatment",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other Treatment",
                          value: "otherTreatment",
                          type: "toggle_button",
                          name: "otherTreatment",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No Treatment",
                          value: "notreatment",
                          type: "toggle_button",
                          name: "notreatment",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 6,
                      editToggle: false,
                      depth: 5,
                    },
                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesvalvulardiseasePatientAnticoagulation",
                      type: "options_tree",
                      label: "Is patient on anticoagulation",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "yes",
                          label: "Yes",
                          value: "yes",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                          name: "no",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 7,
                      depth: 5,
                      editToggle: false,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Valvular Disease",
                  value: "valvularDisease",
                  type: "toggle_button",
                  name: "valvularDisease",
                  depth: 4,
                },
                {
                  subQuestionId: 17,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Heart transplant",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 1,
                      name: "ros_cardioSubDiagnosesHeartTransplant",
                      type: "text",
                      label: "Date of Transplant (Month, Day, Year)",
                      editToggle: false,
                      xs: 4,
                      required: "no",
                      placeholder: "",
                      defaultValue: "",
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      subQuestionId: 1,
                      depth: 5,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Heart transplant",
                  value: "heartTransplant",
                  type: "toggle_button",
                  name: "heartTransplant",
                  depth: 4,
                },
                {
                  subQuestionId: 18,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Other",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_cardioSubDiagnosesOtherDes",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "active",
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                          name: "historyOf",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 1,
                      depth: 5,
                      editToggle: false,
                    },

                    {
                      id: 1,
                      name: "ros_cardioSubDiagnosesOtherOtherSub",
                      type: "text",
                      label: "Other",
                      editToggle: false,
                      xs: 4,
                      required: "no",
                      placeholder: "",
                      defaultValue: "",
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      subQuestionId: 2,
                      depth: 5,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Other",
                  value: "other",
                  type: "toggle_button",
                  name: "other",
                  depth: 4,
                },
              ],
              subQuestionId: 1,
              editToggle: false,
              depth: 3,
            },

            {
              id: 6,
              name: "ros_cardioSubDiagnosesSymp",
              type: "options_tree",
              label: "Symptoms",
              fullWidth: true,
              isMulti: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 6,
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  name: "chestPain",
                  label: "Chest pain",
                  value: "chestPain",
                  isConfigured: true,
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Peripheral Edema",
                  value: "peripheralEdema",
                  type: "toggle_button",
                  name: "peripheralEdema",
                  depth: 6,
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Shortness of breath",
                  value: "ShortnessOfBreathe",
                  type: "toggle_button",
                  name: "ShortnessOfBreathe",
                  depth: 6,
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Heart palpitations",
                  value: "heartPalpitations",
                  type: "toggle_button",
                  name: "heartPalpitations",
                  depth: 6,
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Claudication",
                  value: "claudication",
                  type: "toggle_button",
                  name: "claudication",
                  depth: 6,
                },
                {
                  subQuestionId: 5,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Extremity Discoloration",
                  value: "extremityDiscoloration",
                  type: "toggle_button",
                  name: "extremityDiscoloration",
                  depth: 6,
                },
                {
                  subQuestionId: 6,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Unexplained/Sudden weight gain",
                  value: "unexplained/suddenWeight",
                  type: "toggle_button",
                  name: "unexplained/suddenWeight",
                  depth: 6,
                },
                {
                  subQuestionId: 7,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Dizziness",
                  value: "dizziness",
                  type: "toggle_button",
                  name: "dizziness",
                  depth: 6,
                },
                {
                  subQuestionId: 8,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 1,
                      name: "ros_cardioSubDiagnosesSympOtherSubAnswer",
                      type: "text",
                      label: "Other",
                      editToggle: false,
                      xs: 4,
                      required: "no",
                      placeholder: "",
                      defaultValue: "",
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      subQuestionId: 1,
                      depth: 7,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Other",
                  value: "other",
                  type: "toggle_button",
                  name: "other",
                  depth: 6,
                },

                {
                  subQuestionId: 9,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "None",
                  value: "none",
                  type: "toggle_button",
                  name: "none",
                  depth: 6,
                },
              ],
              subQuestionId: 2,
              depth: 5,
              editToggle: false,
            },
          ],
          editToggle: false,
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          isConfigured: true,
          type: "toggle_button",
          name: "yes",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "No",
          value: "no",
          type: "toggle_button",
          name: "no",
          depth: 2,
        },
      ],
      questionId: 16,
      editToggle: false,
      depth: 1,
    },
    {
      id: 10,
      name: "ros_cardiovascularRecommendations",
      label: "Recommendations",
      placeholder: "",
      editToggle: false,
      defaultValue: "",
      type: "checkbox",
      options: [
        ["Blood pressure checks.", "bloodPressureChecks"],
        ["Heart healthy diet.", "heartHealthyDiet"],
        ["Exercise thirty minutes a day.", "exerciseThirtyMinADay"],
        ["Take medications as prescribed.", "takeMedicationsAsPescribed"],
        ["Case management referral for followup.", "casemangereferralfollowup"],
        ["Followup with Clinician/Specialist.", "FollowupClinicianSpecialist"],
        [
          "Member to use Durable Medical Equipment(DME).",
          "memberTouseDurableEquip",
        ],
        ["Other.", "other"],
      ],
      xs: 12,
      required: false,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      questionId: 17,
      depth: 1,
    },
    {
      id: 9,
      name: "gastrointestinal",
      type: "heading",
      label: "GASTROINTESTINAL",
      editToggle: false,
      xs: 12,
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 18,
    },
    {
      id: 6,
      name: "ros_gastro",
      type: "options_tree",
      label:
        "Gastrointestinal Problems (Ulcer, Reflux, Hiatal Hernia, Colitis, Other)",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 1,
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "ros_gastroSubDiagnoses",
              type: "options_tree",
              label: "Diagnoses",
              isMulti: true,
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 1,
                  subQuestionId: 1,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Bowel Obstruction",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },

                    {
                      id: 6,
                      name: "ros_gastroSubDiagnosesdesbowelObstruction",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_gastroSubDiagnosesdesbowelObstructionActiveSubTre",
                              type: "options_tree",
                              label: "Treatment",
                              isMulti: true,
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },

                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  subQuestions: [
                                    {
                                      id: 1,
                                      name: "ros_gastroSubDiagnosesdesbowelObstructionActiveSubTreOtherSub",
                                      type: "text",
                                      label: "Other Treatment",
                                      editToggle: false,
                                      xs: 4,
                                      required: "no",
                                      placeholder: "",
                                      defaultValue: "",
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      subQuestionId: 1,
                                      depth: 9,
                                    },
                                  ],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 2,
                              editToggle: false,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  label: "Bowel Obstruction",
                  value: "bowelobstruction",
                  isConfigured: true,
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Cachexia",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_gastroSubDiagnosescachexiades",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_gastroSubDiagnosesdescachexiaActiveSubTre",
                              type: "options_tree",
                              label: "Treatment",
                              isMulti: true,
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },

                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  subQuestions: [
                                    {
                                      id: 1,
                                      name: "ros_gastroSubDiagnosesdescachexiaActiveSubTreOtherSub",
                                      type: "text",
                                      label: "Other Treatment",
                                      editToggle: false,
                                      xs: 4,
                                      required: "no",
                                      placeholder: "",
                                      defaultValue: "",
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      subQuestionId: 1,
                                      depth: 9,
                                    },
                                  ],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 2,
                              editToggle: false,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Cachexia",
                  value: "cachexia",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Celiac Disease",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_gastroSubDiagnosesdesceliacDisease",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_gastroSubDiagnosesdesceliacDiseaseActiveSubAnemia",
                              type: "options_tree",
                              label: "Anemia?",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Yes",
                                  value: "yes",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },

                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "No",
                                  value: "no",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 1,
                              editToggle: false,
                              depth: 7,
                            },
                            {
                              id: 6,
                              name: "ros_gastroSubDiagnosesdesceliacDiseaseActiveSubTre",
                              type: "options_tree",
                              label: "Treatment",
                              isMulti: true,
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },

                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  subQuestions: [
                                    {
                                      id: 1,
                                      name: "ros_gastroSubDiagnosesdesceliacDiseaseActiveSubTreOtherSub",
                                      type: "text",
                                      label: "Other Treatment",
                                      editToggle: false,
                                      xs: 4,
                                      required: "no",
                                      placeholder: "",
                                      defaultValue: "",
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      subQuestionId: 1,
                                      depth: 9,
                                    },
                                  ],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 4,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Gluten Free Diet",
                                  value: "glutenFreeDiet",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 2,
                              editToggle: false,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active ",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Celiac Disease",
                  value: "celiacdisease",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Cirrhosis",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_gastroSubDiagnosesdescirrhosis",
                      type: "options_tree",
                      label: "Describe ",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_gastroSubDiagnosesdescirrhosisActiveSubSelect",
                              type: "options_tree",
                              isMulti: true,
                              label: "Select",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Alcohol Related",
                                  value: "alcoholRelated",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },

                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Ascites",
                                  value: "ascites",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "End stage liver disease",
                                  value: "endStageLiverDisease",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 4,
                                  hasSubQuestions: true,
                                  subQuestions: [
                                    {
                                      id: 1,
                                      name: "ros_gastroSubDiagnosesdescirrhosisActiveSubSelectOtherSubAns",
                                      type: "text",
                                      label: "Other",
                                      editToggle: false,
                                      xs: 4,
                                      required: "no",
                                      placeholder: "",
                                      defaultValue: "",
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      subQuestionId: 1,
                                      depth: 9,
                                    },
                                  ],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Other",
                                  value: "other",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 5,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "None",
                                  value: "none",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 1,
                              editToggle: false,
                              depth: 7,
                            },
                            {
                              id: 6,
                              name: "ros_gastroSubDiagnosesdescirrhosisActiveSubTre",
                              type: "options_tree",
                              label: "Treatment",
                              isMulti: true,
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },

                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  subQuestions: [
                                    {
                                      id: 1,
                                      name: "ros_gastroSubDiagnosesdescirrhosisSubAnswerActiveSubTreOtherSub",
                                      type: "text",
                                      label: "Other Treatment",
                                      editToggle: false,
                                      xs: 4,
                                      required: "no",
                                      placeholder: "",
                                      defaultValue: "",
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      subQuestionId: 1,
                                      depth: 9,
                                    },
                                  ],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 2,
                              editToggle: false,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Cirrhosis",
                  value: "cirrhosis",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 5,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Colon Polyps",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_gastroSubDiagnosescolonPolypsDes",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_gastroSubDiagnosesdescolonPolypsActiveSubTre",
                              type: "options_tree",
                              isMulti: true,
                              label: "Treatment",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },

                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  subQuestions: [
                                    {
                                      id: 1,
                                      name: "ros_gastroSubDiagnosesdescolonPolypsActiveSubTreOtherSub",
                                      type: "text",
                                      label: "Other Treatment",
                                      editToggle: false,
                                      xs: 4,
                                      required: "no",
                                      placeholder: "",
                                      defaultValue: "",
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      subQuestionId: 1,
                                      depth: 9,
                                    },
                                  ],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 1,
                              editToggle: false,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },

                    {
                      id: 6,
                      name: "ros_gastroSubDiagnosescolonCrDes",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Benign",
                          value: "bengir",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Carcinoma in situ",
                          value: "carcinomainsitu",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Fmilial Polyposis",
                          value: "fmilialpolyposis",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Colon Polyps",
                  value: "colonpolyps",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 6,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Diverticultis",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_gastroSubDiagnosesdesdiverticulitis",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_gastroSubDiagnosesdesdiverticulitisActiveSubSelect",
                              type: "options_tree",
                              label: "Select",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Abscess",
                                  value: "abscess",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },

                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Perforation",
                                  value: "perforation",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Bleeding",
                                  value: "bleeding",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 1,
                              editToggle: false,
                              depth: 7,
                            },
                            {
                              id: 6,
                              name: "ros_gastroSubDiagnosesdesdiverticulitisActiveSubTre",
                              type: "options_tree",
                              label: "Treatment",
                              isMulti: true,
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },

                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  subQuestions: [
                                    {
                                      id: 1,
                                      name: "ros_gastroSubDiagnosesdesdiverticulitisActiveSubTreOtherSub",
                                      type: "text",
                                      label: "Other Treatment",
                                      editToggle: false,
                                      xs: 4,
                                      required: "no",
                                      placeholder: "",
                                      defaultValue: "",
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      subQuestionId: 1,
                                      depth: 9,
                                    },
                                  ],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 2,
                              editToggle: false,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Diverticulitis",
                  value: "diverticultis",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 7,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Gall Bladder Disease",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_gastroSubDiagnosesdesgallBladderDisease",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_gastroSubDiagnosesdesgallBladderActiveSubTre",
                              type: "options_tree",
                              label: "Treatment",
                              isMulti: true,
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },

                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  subQuestions: [
                                    {
                                      id: 1,
                                      name: "ros_gastroSubDiagnosesdesgallBladderActiveSubTreOtherSub",
                                      type: "text",
                                      label: "Other Treatment",
                                      editToggle: false,
                                      xs: 4,
                                      required: "no",
                                      placeholder: "",
                                      defaultValue: "",
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      subQuestionId: 1,
                                      depth: 9,
                                    },
                                  ],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 1,
                              editToggle: false,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "active",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                          name: "historyOf",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Gall Bladder Disease",
                  value: "gallbladderdisease",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 8,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Gastroparesis",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },

                    {
                      id: 6,
                      name: "ros_gastroSubDiagnosesgastroparesisDescribe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_gastroSubDiagnosesdesgastroparesisActiveSubTre",
                              type: "options_tree",
                              label: "Treatment",
                              isMulti: true,
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },

                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  subQuestions: [
                                    {
                                      id: 1,
                                      name: "ros_gastroSubDiagnosesdesgastroparesisActiveSubTreOtherSub",
                                      type: "text",
                                      label: "Other Treatment",
                                      editToggle: false,
                                      xs: 4,
                                      required: "no",
                                      placeholder: "",
                                      defaultValue: "",
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      subQuestionId: 1,
                                      depth: 9,
                                    },
                                  ],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 1,
                              editToggle: false,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Gastroparesis",
                  value: "gastroparesis",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 9,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "GERD",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_gastroSubDiagnosesgerdDescribe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_gastroSubDiagnosesdesgerdActiveSubTre",
                              type: "options_tree",
                              label: "Treatment",
                              isMulti: true,
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },

                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  subQuestions: [
                                    {
                                      id: 1,
                                      name: "ros_gastroSubDiagnosesdesgerdActiveSubTreOtherSub",
                                      type: "text",
                                      label: "Other Treatment",
                                      editToggle: false,
                                      xs: 4,
                                      required: "no",
                                      placeholder: "",
                                      defaultValue: "",
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      subQuestionId: 1,
                                      depth: 9,
                                    },
                                  ],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 1,
                              editToggle: false,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "GERD",
                  value: "gerd",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 10,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "GI Bleed",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_gastroSubDiagnosesglBleedDescribe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_gastroSubDiagnosesdesglBleedActiveSubBlood",
                              type: "options_tree",
                              label: "Blood in stool?",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Yes",
                                  value: "yes",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },

                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "No",
                                  value: "no",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 1,
                              editToggle: false,
                              depth: 7,
                            },
                            {
                              id: 6,
                              name: "ros_gastroSubDiagnosesdesglBleedActiveSubTre",
                              type: "options_tree",
                              label: "Treatment",
                              isMulti: true,
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },

                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  subQuestions: [
                                    {
                                      id: 1,
                                      name: "ros_gastroSubDiagnosesdesglBleedActiveSubTreOtherSub",
                                      type: "text",
                                      label: "Other Treatment",
                                      editToggle: false,
                                      xs: 4,
                                      required: "no",
                                      placeholder: "",
                                      defaultValue: "",
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      subQuestionId: 1,
                                      depth: 9,
                                    },
                                  ],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 2,
                              editToggle: false,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "GI Bleed",
                  value: "gibleed",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 11,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Hepatitis",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_gastroSubDiagnoseshepatitisDescribe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_gastroSubDiagnoseshepatitisType",
                              type: "options_tree",
                              label: "Type",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: true,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "A",
                                  value: "a",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },

                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "B",
                                  value: "b",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "C",
                                  value: "c",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 1,
                              editToggle: false,
                              depth: 7,
                            },
                            {
                              id: 6,
                              name: "ros_gastroSubDiagnoseshepatitisDess",
                              type: "options_tree",
                              label: "Describe",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: true,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Acute",
                                  value: "acute",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },

                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Chronic",
                                  value: "chronic",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 2,
                              editToggle: false,
                              depth: 7,
                            },
                            {
                              id: 6,
                              name: "ros_gastroSubDiagnosesdeshepatitisActiveSubJaundice",
                              type: "options_tree",
                              label: "Jaundice?",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: true,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Yes",
                                  value: "yes",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },

                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "No",
                                  value: "no",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 3,
                              editToggle: false,
                              depth: 7,
                            },
                            {
                              id: 6,
                              name: "ros_gastroSubDiagnoseshepatitisCirrhosis",
                              type: "options_tree",
                              label: "Cirrhosis",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 6,
                                  subQuestionId: 1,
                                  hasSubQuestions: true,
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "ros_gastroCiroalcoholRelated",
                                      type: "options_tree",
                                      label: "Alcohol related?",
                                      fullWidth: true,
                                      required: true,
                                      xs: 12,
                                      isCommentEnabled: true,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                      subQuestions: [
                                        {
                                          depth: 8,
                                          subQuestionId: 1,
                                          hasSubQuestions: false,
                                          subQuestions: [],
                                          editToggle: false,
                                          isCommentEnabled: false,
                                          label: "Yes",
                                          value: "yes",
                                          isConfigured: true,
                                          type: "toggle_button",
                                        },
                                        {
                                          subQuestionId: 2,
                                          hasSubQuestions: false,
                                          subQuestions: [],
                                          editToggle: false,
                                          isCommentEnabled: false,
                                          isConfigured: true,
                                          isModified: false,
                                          label: "No",
                                          value: "no",
                                          type: "toggle_button",
                                          depth: 8,
                                        },
                                      ],
                                      subQuestionId: 1,
                                      depth: 7,
                                      editToggle: false,
                                    },
                                  ],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Yes",
                                  value: "yes",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },

                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "No",
                                  value: "no",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 4,
                              editToggle: false,
                              depth: 7,
                            },
                            {
                              id: 6,
                              name: "ros_gastroSubDiagnoseshepatitisHepatocellularCarcinoma",
                              type: "options_tree",
                              label: "Hepatocellular Carcinoma",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Yes",
                                  value: "yes",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },

                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "No",
                                  value: "no",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 5,
                              editToggle: false,
                              depth: 7,
                            },
                            {
                              id: 6,
                              name: "ros_gastroSubDiagnosesdeshepatitisActiveSubTre",
                              type: "options_tree",
                              label: "Treatment",
                              isMulti: true,
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },

                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  subQuestions: [
                                    {
                                      id: 1,
                                      name: "ros_gastroSubDiagnosesdeshepatitisActiveSubTreOtherSub",
                                      type: "text",
                                      label: "Other Treatment",
                                      editToggle: false,
                                      xs: 4,
                                      required: "no",
                                      placeholder: "",
                                      defaultValue: "",
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      subQuestionId: 1,
                                      depth: 9,
                                    },
                                  ],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 6,
                              editToggle: false,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Hepatitis",
                  value: "hepatitis",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 12,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Inflammatory Bowel Disease",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_gastroSubDiagnosesinflammatoryBowelDiseaseDescribe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_gastroSubDiagnosesinflammatoryBowelDiseaseIBDDescribe",
                              type: "options_tree",
                              label: "Describe",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Ulcerative colitis",
                                  value: "ulcerative",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },

                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Crohn's Disease",
                                  value: "crohnsdisease",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Other",
                                  value: "other",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 1,
                              editToggle: false,
                              depth: 7,
                            },
                            {
                              id: 6,
                              name: "ros_gastroSubDiagnosesdesinflammatoryBowelDiseaseActiveSubTre",
                              type: "options_tree",
                              label: "Treatment",
                              isMulti: true,
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },

                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  subQuestions: [
                                    {
                                      id: 1,
                                      name: "ros_gastroSubDiagnosesdesinflammatoryBowelDiseaseActiveSubTreOtherSub",
                                      type: "text",
                                      label: "Other Treatment",
                                      editToggle: false,
                                      xs: 4,
                                      required: "no",
                                      placeholder: "",
                                      defaultValue: "",
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      subQuestionId: 1,
                                      depth: 9,
                                    },
                                  ],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 2,
                              editToggle: false,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Inflammatory Bowel Disease",
                  value: "inflammatoryboweldisease",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 13,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Pancreatitis",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_gastroSubDiagnosespancreatitisDescribe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_gastroSubDiagnosespancreatitisRecurrentEpisodes",
                              type: "options_tree",
                              label: "Recurrent Episodes",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Yes - Chronic",
                                  value: "yes",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },

                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "No",
                                  value: "no",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 1,
                              editToggle: false,
                              depth: 7,
                            },
                            {
                              id: 6,
                              name: "ros_gastroSubDiagnosesdespancreatitisActiveSubAlcohol",
                              type: "options_tree",
                              label: "Alcohol Related",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Yes",
                                  value: "yes",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },

                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "No",
                                  value: "no",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 2,
                              editToggle: false,
                              depth: 7,
                            },
                            {
                              id: 6,
                              name: "ros_gastroSubDiagnosesdespancreatitisActiveSubTre",
                              type: "options_tree",
                              label: "Treatment",
                              fullWidth: true,
                              isMulti: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },

                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  subQuestions: [
                                    {
                                      id: 1,
                                      name: "ros_gastroSubDiagnosesdespancreatitisActiveSubTreOtherSub",
                                      type: "text",
                                      label: "Other Treatment",
                                      editToggle: false,
                                      xs: 4,
                                      required: "no",
                                      placeholder: "",
                                      defaultValue: "",
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      subQuestionId: 1,
                                      depth: 9,
                                    },
                                  ],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 3,
                              editToggle: false,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Pancreatitis",
                  value: "pancreatitis",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 14,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Ulcer Disease",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_gastroSubDiagnosesulcerDiseaseDescribe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_gastroSubDiagnosesdesulcerDiseaseActiveSubDes",
                              type: "options_tree",
                              label: "Describe",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Esophageal",
                                  value: "esophageal",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },

                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Gastric",
                                  value: "gastric",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Duodenal",
                                  value: "duodenal",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 4,
                                  hasSubQuestions: true,
                                  subQuestions: [
                                    {
                                      id: 1,
                                      name: "ros_gastroSubDiagnosesulcerDiseaseOtherSubAnswerDescribe",
                                      type: "text",
                                      label: "Describe",
                                      editToggle: false,
                                      xs: 4,
                                      required: "no",
                                      placeholder: "",
                                      defaultValue: "",
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      subQuestionId: 1,
                                      depth: 9,
                                    },
                                  ],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Describe",
                                  value: "describe",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 1,
                              editToggle: false,
                              depth: 7,
                            },
                            {
                              id: 6,
                              name: "ros_gastroSubDiagnosesulcerDiseaseHeliobactria",
                              type: "options_tree",
                              label: "Positive culture for Heliobactria Pylori",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Yes",
                                  value: "yes",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },

                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "No",
                                  value: "no",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 2,
                              editToggle: false,
                              depth: 7,
                            },
                            {
                              id: 6,
                              name: "ros_gastroSubDiagnosesdesulcerDiseaseActiveSubTre",
                              type: "options_tree",
                              label: "Treatment",
                              isMulti: true,
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },

                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  subQuestions: [
                                    {
                                      id: 1,
                                      name: "ros_gastroSubDiagnosesdesulcerDiseaseActiveSubTreOtherSub",
                                      type: "text",
                                      label: "Other Treatment",
                                      editToggle: false,
                                      xs: 4,
                                      required: "no",
                                      placeholder: "",
                                      defaultValue: "",
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      subQuestionId: 1,
                                      depth: 9,
                                    },
                                  ],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 3,
                              editToggle: false,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Ulcer Disease",
                  value: "ulcerdisease",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 15,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Liver Transplant",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 1,
                      name: "ros_gastroDateOfTransplant",
                      type: "text",
                      label: "Date of Transplant (Month, Day, Year)",
                      editToggle: false,
                      xs: 4,
                      required: "no",
                      placeholder: "",
                      defaultValue: "",
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      subQuestionId: 1,
                      depth: 5,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Liver Transplant",
                  value: "liverTransplant",
                  type: "toggle_button",
                  name: "liverTransplant",
                  depth: 4,
                },

                {
                  subQuestionId: 16,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Other",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_gastroSubDiagnosesotherDescribe",
                      type: "options_tree",
                      label: "Describe        ",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_gastroSubDiagnosesotherSupportedBy",
                      type: "options_tree",
                      label: "Supported by                  ",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "History",
                          value: "history",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Symptoms",
                          value: "symptoms",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Physical Findings",
                          value: "physicalfindings",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Medications",
                          value: "medications",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Test results",
                          value: "testresults",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Image studies",
                          value: "imagestudies",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Biopsy",
                          value: "biopsy",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 8,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "DME",
                          value: "dme",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 9,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Abdominal pain",
                          value: "abdominalpain",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 10,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Nausea and vomiting",
                          value: "nauseaandvomiting",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 11,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "ros_gastroSubDiagnosesotherSupportedByOtherSubAnswerDescribe",
                              type: "text",
                              label: "Describe  ",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                    },
                    {
                      id: 1,
                      name: "ros_gastroSubDiagnosesotherOther",
                      type: "text",
                      label: "Describe   ",
                      editToggle: false,
                      xs: 4,
                      required: "no",
                      placeholder: "",
                      defaultValue: "",
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      subQuestionId: 3,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Other",
                  value: "other",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 1,
              editToggle: false,
            },
            {
              id: 6,
              name: "ros_gastroSubSymptoms",
              type: "options_tree",
              label: "Symptoms",
              fullWidth: true,
              isMulti: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 6,
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  name: "heartburn/dyspepsia",
                  label: "Heartburn / Dyspepsia",
                  value: "heartburn/dyspepsia",
                  isConfigured: true,
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Nausea/ Vomiting",
                  value: "nausea/vomiting",
                  type: "toggle_button",
                  name: "nausea/vomiting",
                  depth: 6,
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Blood in Stool",
                  value: "bloodinStool",
                  type: "toggle_button",
                  name: "bloodinStool",
                  depth: 6,
                },

                {
                  subQuestionId: 4,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Abdominal Pain",
                  value: "abdominalPain",
                  type: "toggle_button",
                  name: "abdominalPain",
                  depth: 6,
                },

                {
                  subQuestionId: 5,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 1,
                      name: "ros_gastroSubSymptomsOtherSub",
                      type: "text",
                      label: "Other",
                      editToggle: false,
                      xs: 4,
                      required: "no",
                      placeholder: "",
                      defaultValue: "",
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      subQuestionId: 1,
                      depth: 7,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Other",
                  value: "other",
                  type: "toggle_button",
                  name: "other",
                  depth: 6,
                },

                {
                  subQuestionId: 6,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "None",
                  value: "none",
                  type: "toggle_button",
                  name: "none",
                  depth: 6,
                },
              ],
              subQuestionId: 2,
              depth: 5,
              editToggle: false,
            },
          ],
          editToggle: false,
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          isConfigured: true,
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 19,
      editToggle: false,
    },
    {
      id: 6,
      name: "ros_bowel",
      type: "options_tree",
      label: "Bowel Movements",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [{}],
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          type: "toggle_button",
        },
        {
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "ros_bowelConstiSubIfConsti",
              type: "options_tree",
              label: "If Constipation",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Acute",
                  value: "acute",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Chronic",
                  value: "chronic",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 1,
            },
          ],
          isCommentEnabled: false,
          label: "Constipation",
          value: "constipation",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "ros_bowelDiarrSubIfDiarr",
              type: "options_tree",
              label: "If Diarrhea",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Acute",
                  value: "acute",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Chronic",
                  value: "chronic",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 1,
            },
            {
              id: 6,
              name: "ros_bowelDiarrSubIsHistoryOf",
              type: "options_tree",
              label:
                "If Diarrhea, history of C Difficile (Rrefer to C Difficile in the Infectious Disease Section for further documentation)",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Yes",
                  value: "yes",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "No",
                  value: "no",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 2,
            },
          ],
          isCommentEnabled: false,
          label: "Diarrhea",
          value: "diarrhea",
          type: "toggle_button",
        },
        {
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Bowel Incontinence",
          value: "bowelIncontinence",
          type: "toggle_button",
        },
      ],
      questionId: 20,
    },
    {
      id: 6,
      name: "ros_abdominal",
      type: "options_tree",
      label: "Abdominal Openings",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Ileostomy",
          value: "ileostomy",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Colostomy",
          value: "colostomy",
          type: "toggle_button",
        },
        {
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Urostomy",
          value: "urostomy",
          type: "toggle_button",
        },
        {
          subQuestionId: 4,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "PEG",
          value: "peg",
          type: "toggle_button",
        },
        {
          subQuestionId: 5,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Cystostomy",
          value: "crytostomy",
          type: "toggle_button",
        },
        {
          subQuestionId: 5,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "None",
          value: "none",
          type: "toggle_button",
        },
      ],
      questionId: 21,
    },
    {
      id: 6,
      name: "ros_rectal",
      type: "options_tree",
      label: "Rectal Problems",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 1,
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "ros_rectalIsrecIsyesfem",
              type: "options_tree",
              label: "If Yes (Female)",
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 3,
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  label: "Hemorrhoids",
                  value: "hemorrhoids",
                  isConfigured: true,
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Fissure",
                  value: "fissure",
                  type: "toggle_button",
                  depth: 3,
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Mass",
                  value: "mass",
                  type: "toggle_button",
                  depth: 3,
                },
              ],
              subQuestionId: 1,
              editToggle: false,
              depth: 2,
            },
            {
              id: 6,
              name: "ros_rectalIsrecIsyesmal",
              type: "options_tree",
              label:
                "If Yes (Male) - (if BPH is checked refer to BPH in the Genitourinary Section for further documentation. If Prostate mass is checked refer to Cancer of the prostate in the Cancer Section for further documentation)",
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 3,
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  label: "Hemorrhoids",
                  value: "hemorrhoids",
                  isConfigured: true,
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Fissure",
                  value: "fissure",
                  type: "toggle_button",
                  depth: 3,
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Mass",
                  value: "mass",
                  type: "toggle_button",
                  depth: 3,
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "BPH",
                  value: "bph",
                  type: "toggle_button",
                  depth: 3,
                },
                {
                  subQuestionId: 5,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Prostate Mass",
                  value: "prostateMass",
                  type: "toggle_button",
                  depth: 3,
                },
              ],
              subQuestionId: 2,
              depth: 2,
              editToggle: false,
            },
          ],
          editToggle: false,
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          isConfigured: true,
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "No",
          value: "no",
          type: "toggle_button",
          depth: 1,
        },
      ],
      questionId: 22,
      editToggle: false,
    },
    {
      id: 6,
      name: "ros_lastBowel",
      type: "options_tree",
      label: "Last Bowel Movement",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 3,
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          label: "Today",
          value: "today",
          isConfigured: true,
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "1-3 days ago",
          value: "oneToThreeDaysAgo",
          type: "toggle_button",
        },
        {
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: ">3 days ago",
          value: "greaterThanThreeDaysAgo",
          type: "toggle_button",
        },
      ],
      questionId: 23,
      editToggle: false,
    },
    {
      id: 10,
      name: "ros_gastrorecommendations",
      label: "Recommendations",
      placeholder: "",
      editToggle: false,
      defaultValue: "",
      type: "checkbox",
      options: [
        ["Take medications as prescribed.", "takemedicationsasprescribed"],
        ["Case management referral for follow up.", "caseManagement"],
        ["Follow up with Clinician/Specialist.", "followup"],
        ["Member to use Durable Medical Equipment (DME).", "useDme"],
        ["Other.", "other"],
      ],
      xs: 12,
      required: false,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      questionId: 24,
    },
    {
      id: 9,
      name: "neuroPsych",
      type: "heading",
      label: "NEURO-PSYCH",
      editToggle: false,
      xs: 3,
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 25,
      depth: 1,
    },
    {
      id: 6,
      name: "ros_neuroPsych",
      type: "options_tree",
      label:
        "Neuro / Psych Problems (Stroke, Parkinson's Disease, Seizures Paraplegia, Depression, Other)",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "ros_neuroPsychSubDiag",
              type: "options_tree",
              label: "Diagnoses",
              isMulti: true,
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: true,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Alcohol Dependence",
                  value: "alcoholDependence",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Amyotrophic Lateral Sclerosis",
                  value: "amyotrophicLateral",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Anxiety",
                  value: "anxiety",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 51,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Attention Deficit Disorder",
                  value: "attentionDeficit",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 52,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Autism",
                  value: "autism",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Bipolar Disorder",
                  value: "bipolarDisorder",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 5,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Cerebral Hemorrhage",
                  value: "cerebralHemorrhage",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 6,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Cerebral Palsy",
                  value: "cerebralPalsy",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 7,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Delusional Disease",
                  value: "delusionalDisease",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 8,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Dementia",
                  value: "dementia",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 9,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Depression",
                  value: "depression",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 10,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Drug Dependence",
                  value: "drugDependence",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 11,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Fibromyalgia",
                  value: "fibromyalgia",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 12,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Guillain-Barre Disease",
                  value: "guillainBarreDisease",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 13,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Hemiparesis",
                  value: "hemiparesis",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 14,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Huntington's Chorea",
                  value: "huntingtonsChorea",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 15,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Insomnia",
                  value: "insomnia",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 16,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Intellectual and or Developmental Disability",
                  value: "developmentalDisability",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 17,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Migraine Headaches",
                  value: "migraineHeadaches",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 18,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Multiple Sclerosis",
                  value: "multipleSclerosis",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 19,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Muscular Dystrophy",
                  value: "muscularDystrophy",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 20,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Myasthenia Gravis",
                  value: "myastheniaGravis",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 21,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Parkinson's Disease",
                  value: "parkinsonsDisease",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 22,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Peripheral Neuropathy (Non-Diabetic)",
                  value: "peripheralNeuropathy",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 23,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Restless leg syndrome",
                  value: "restlessLegSyndrome",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 24,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Schizophrenia",
                  value: "schizophrenia",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 25,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Seizures",
                  value: "seizures",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 26,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Spinal Cord Injury",
                  value: "spinalCordInjury",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 27,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Stroke",
                  value: "stroke",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 28,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Subdural Hematoma",
                  value: "subduralHematoma",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 29,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "TIA",
                  value: "tia",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 30,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Traumatic Brain Injury",
                  value: "traumaticBrainInjury",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 31,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Other",
                  value: "other",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 1,
            },
          ],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 26,
    },
    {
      id: 6,
      name: "ros_nervousAnxious",
      type: "options_tree",
      label:
        "Are you nervous, anxious, feel on the edge or often feel stressed?",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 27,
    },
    {
      id: 6,
      name: "ros_worryMuch",
      type: "options_tree",
      label: "Do you worry too much about different things?",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 28,
    },
    {
      id: 6,
      name: "ros_feelAfraid",
      type: "options_tree",
      label: "Do you feel afraid that something bad might happen?",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 29,
    },
    {
      id: 7,
      name: "over_the_past_2_weeks,_how_often_have_you_been_bothered_by_any_of_the_following_problems?",
      type: "label",
      label:
        "Over the past 2 weeks, how often have you been bothered by any of the following problems?",
      xs: 12,
      isCommentEnabled: false,
      questionId: 30,
    },
    {
      id: 6,
      name: "ros_over2WeeksLittleIntrst",
      type: "options_tree",
      label: "Little interest or pleasure in doing things",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 1,
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          label: "Not at all",
          value: "notAtAll",
          isConfigured: true,
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Several Days",
          value: "severalDays",
          type: "toggle_button",
        },
        {
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "More than half the days",
          value: "moreThanHalfTheDays",
          type: "toggle_button",
        },
        {
          subQuestionId: 4,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Nearly every day",
          value: "nearlyEveryDay",
          type: "toggle_button",
        },
      ],
      questionId: 31,
      editToggle: false,
    },
    {
      id: 6,
      name: "ros_over2WeeksFeelDepressed",
      type: "options_tree",
      label: "Feeling down, depressed or hopeless",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 1,
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          label: "Not at all",
          value: "notAtAll",
          isConfigured: true,
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Several Days",
          value: "severalDays",
          type: "toggle_button",
        },
        {
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "More than half the days",
          value: "moreThanHalfTheDays",
          type: "toggle_button",
        },
        {
          subQuestionId: 4,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Nearly every day",
          value: "nearlyEveryDay",
          type: "toggle_button",
        },
      ],
      questionId: 32,
      editToggle: false,
    },
    {
      id: 6,
      name: "ros_over2WeeksPhq",
      type: "options_tree",
      label: "PHQ 2 Score",
      fullWidth: true,
      required: true,
      disabled: true,
      xs: 12,
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 1,
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          label: "<3",
          value: "lessThanThree",
          isConfigured: true,
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 9,
              name: "phq9Heading",
              type: "heading",
              label: "Depression Screening PHQ9",
              editToggle: false,
              xs: 3,
              isCommentEnabled: false,
              isConfigured: true,
              isModified: false,
              questionId: 201,
              depth: 1,
            },
            {
              id: 6,
              name: "ros_over2WeeksPhq2MoreThreeSubDepScreenHaveLittleIntrst",
              type: "options_tree",
              label: "Having little interest or pleasure in doing things?",
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 1,
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  label: "Not at all",
                  value: "notAtAll",
                  isConfigured: true,
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Several Days",
                  value: "several",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "More than half the days",
                  value: "moreThanHalfTheDays",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Nearly Every day",
                  value: "nearlyEveryDay",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 1,
              editToggle: false,
            },
            {
              id: 6,
              name: "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelDepress",
              type: "options_tree",
              label: "Feeling down, depressed or hopeless at times?",
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 1,
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  label: "Not at all",
                  value: "notAtAll",
                  isConfigured: true,
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Several Days",
                  value: "several",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "More than half the days",
                  value: "moreThanHalfTheDays",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Nearly Every Day",
                  value: "nearlyEveryDay",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 2,
              editToggle: false,
            },
            {
              id: 6,
              name: "ros_over2WeeksPhq2MoreThreeSubDepScreenTrouFall",
              type: "options_tree",
              label:
                "Do you have trouble falling or staying asleep, sleeping too much?",
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 1,
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  label: "Not at all",
                  value: "notAtAll",
                  isConfigured: true,
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Several Days",
                  value: "several",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "More than half the days",
                  value: "moreThanHalfTheDays",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Nearly Every day",
                  value: "nearlyEveryDay",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 3,
              editToggle: false,
            },
            {
              id: 6,
              name: "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelTired",
              type: "options_tree",
              label: "Do you feeling tired or having little energy?",
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 1,
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  label: "Not at all",
                  value: "notAtAll",
                  isConfigured: true,
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Several Days",
                  value: "several",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "More than half the days",
                  value: "moreThanHalfTheDays",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Nearly Every Day",
                  value: "nearlyEveryDay",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 9,
              editToggle: false,
            },
            {
              id: 6,
              name: "ros_over2WeeksPhq2MoreThreeSubDepScreenPoorAppetite",
              type: "options_tree",
              label: "Do you have a poor appetite or overeating?",
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 1,
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  label: "Not at all",
                  value: "notAtAll",
                  isConfigured: true,
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Several Days",
                  value: "several",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "More than half the days",
                  value: "moreThanHalfTheDays",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Nearly every day",
                  value: "nearlyEveryDay",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 4,
              editToggle: false,
            },
            {
              id: 6,
              name: "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelBad",
              type: "options_tree",
              label:
                "Feeling bad about yourself or that you are a failure or have let yourself or your family down?",
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 1,
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  label: "Not at all",
                  value: "notAtAll",
                  isConfigured: true,
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Several Days",
                  value: "several",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "More than half the days",
                  value: "moreThanHalfTheDays",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Nearly everyday",
                  value: "nearlyEveryDay",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 5,
              editToggle: false,
            },
            {
              id: 6,
              name: "ros_over2WeeksPhq2MoreThreeSubDepScreenTroubleConc",
              type: "options_tree",
              label:
                "Trouble concentrating on things, such as reading the newspaper or watching TV?",
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 1,
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  label: "Not at all",
                  value: "notAtAll",
                  isConfigured: true,
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Several Days",
                  value: "several",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "More than half the days",
                  value: "moreThanHalfTheDays",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Nearly everyday",
                  value: "nearlyEveryDay",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 6,
              editToggle: false,
            },
            {
              id: 6,
              name: "ros_over2WeeksPhq2MoreThreeSubDepScreenMoveOrSpeak",
              type: "options_tree",
              label:
                "Moving or speaking so slowly that other people have noticed. Or opposite-being fidgety or restless that you have been moving around a lot more than usual?",
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 1,
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  label: "Not at all",
                  value: "notAtAll",
                  isConfigured: true,
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Several Days",
                  value: "several",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "More than half the days",
                  value: "moreThanHalfTheDays",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Nearly Every Day",
                  value: "nearlyEveryDay",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 7,
              editToggle: false,
            },
            {
              id: 6,
              name: "ros_over2WeeksPhq2MoreThreeSubDepScreenHurtYourself",
              type: "options_tree",
              label:
                "Thoughts that you would be better off dead, or hurting yourself?",
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 1,
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  label: "Not at all",
                  value: "notAtAll",
                  isConfigured: true,
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Several Days",
                  value: "several",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "More than half the days",
                  value: "moreThanHalfTheDays",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Nearly Every Day",
                  value: "nearlyEveryDay",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 8,
              editToggle: false,
            },
            {
              id: 6,
              name: "ros_over2WeeksPhq2MoreThreeSubDepScreenDiffProb",
              type: "options_tree",
              label:
                "How difficult have these problems made it for you to do your work, take care of things at home, or get along with other people?",
              fullWidth: true,
              required: true,
              isVisible: `ros_over2WeeksPhq9`,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 1,
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  label: "Not difficult at all",
                  value: "notDifficultAtAll",
                  isConfigured: true,
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Somewhat Difficult",
                  value: "someWhatDifficult",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Very Difficult",
                  value: "veryDifficult",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Extremely Difficult",
                  value: "extremelyDifficult",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 5,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Other",
                  value: "other",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 6,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Not Applicable",
                  value: "notApplicable",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 8,
              editToggle: false,
            },
            {
              id: 11,
              name: "ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeverityStatic",
              label: "Stages of Depression",
              editToggle: false,
              type: "datatable",
              tablelist: [],
              xs: 12,
              required: "no",
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 3,
              depth: 5,
            },
            {
              id: 2,
              name: "ros_over2WeeksPhq9",
              type: "text",
              label: "PHQ 9 Score",
              editToggle: false,
              disabled: true,
              xs: 6,
              required: "no",
              placeholder: " ",
              defaultValue: "",
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 10,
            },
            {
              id: 1,
              name: "ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeverityComment",
              type: "text",
              label: "Comment",
              editToggle: false,
              xs: 6,
              required: "no",
              placeholder: " ",
              defaultValue: "",
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 11,
            },
          ],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "3 or more",
          value: "threeOrMore",
          type: "toggle_button",
        },
      ],
      questionId: 33,
      editToggle: false,
    },
    //
    {
      id: 6,
      name: "",
      value: "",
      type: "label",
      label: "Alcohol Dependence",
      fullWidth: true,
      required: false,
      xs: 18,
      isCommentEnabled: false,
      isConfigured: true,
      isModified: true,
      hasSubQuestions: true,
      isVisible: "ros_neuroPsychSubDiag=alcoholDependence",
    },
    {
      id: 6,
      name: "ros_neuroPsychSubDiagAlcoholSubDesc",
      type: "options_tree",
      label: "Describe",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "ros_neuroPsychSubDiagAlcoholSubDescActiveSubTreat",
              type: "options_tree",
              isMulti: true,
              label: "Treatment",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Medication",
                  value: "medication",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Other Treatment",
                      fullWidth: true,
                      required: false,
                      xs: 18,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      subQuestionId: 1,
                      name: "ros_neuroPsychSubDiagAlcoholSubDescActiveSubTreatOtherSubDescText",
                      type: "text",
                      label: "Other Treatment",
                      xs: 12,
                      isCommentEnabled: false,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Other Treatment",
                  value: "otherTreatment",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "No Treatment",
                  value: "noTreatment",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 2,
            },
          ],
          isCommentEnabled: false,
          label: "Active",
          value: "active",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "History of",
          value: "historyOf",
          type: "toggle_button",
        },
      ],
      isVisible: "ros_neuroPsychSubDiag=alcoholDependence",
      questionId: 601,
      //depth: 2,
    },
    {
      id: 6,
      name: "ros_neuroPsychSubDiagAlcoholSubType",
      type: "options_tree",
      label: "Type",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Episodic",
          value: "episodic",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Continuous",
          value: "continuous",
          type: "toggle_button",
        },
        {
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Remission",
          value: "remission",
          type: "toggle_button",
        },
      ],
      isVisible: "ros_neuroPsychSubDiag=alcoholDependence",
      questionId: 602,
    },
    {
      id: 6,
      name: "ros_neuroPsychSubDiagAlcoholSubSupp",
      type: "options_tree",
      label: "Supported by",
      isMulti: true,
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Drinking History",
          value: "drinkingHistory",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Hospitalizations",
          value: "hospitalizations",
          type: "toggle_button",
        },
        {
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Lab Results",
          value: "labResults",
          type: "toggle_button",
        },
        {
          subQuestionId: 4,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Delirium Tremors",
          value: "deleriumTremors",
          type: "toggle_button",
        },
        {
          subQuestionId: 5,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Psychosis",
          value: "psychosis",
          type: "toggle_button",
        },
        {
          subQuestionId: 6,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "",
              value: "",
              type: "label",
              label: "Other",
              fullWidth: true,
              required: false,
              xs: 18,
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
            },
            {
              id: 1,
              name: "ros_neuroPsychSubDiagAlcoholSubSuppOtherSubDesc",
              type: "text",
              label: "Describe",
              xs: 4,
              isCommentEnabled: false,
              subQuestionId: 1,
            },
          ],
          isCommentEnabled: false,
          label: "Other",
          value: "other",
          type: "toggle_button",
        },
      ],
      isVisible: "ros_neuroPsychSubDiag=alcoholDependence",
      questionId: 603,
    },

    //
    {
      id: 6,
      name: "",
      value: "",
      type: "label",
      label: "Amyotrophic Lateral Sclerosis",
      fullWidth: true,
      required: false,
      xs: 18,
      isCommentEnabled: false,
      isConfigured: true,
      isModified: true,
      hasSubQuestions: true,
      isVisible: "ros_neuroPsychSubDiag=amyotrophicLateral",
    },
    {
      id: 6,
      name: "ros_neuroPsychSubDiagAmyotropSubDesc",
      type: "options_tree",
      label: "Describe",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "ros_neuroPsychSubDiagAmyotropSubDescActiveSubSelect",
              type: "options_tree",
              label: "Select",
              isMulti: true,
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Progressive Weakness",
                  value: "progressiveWeekness",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Slurring of speech",
                  value: "slurringOfSpeech",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Abnormal gait",
                  value: "abnormalGait",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Difficulty swallowing",
                  value: "difficultySwallowing",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 5,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "EMG",
                  value: "emg",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 6,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Other",
                      fullWidth: true,
                      required: false,
                      xs: 18,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 1,
                      name: "ros_neuroPsychSubDiagAmyotropSubDescActiveSubSelectOtherSubDesc",
                      type: "text",
                      label: "Describe",
                      xs: 4,
                      isCommentEnabled: false,
                      subQuestionId: 1,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Other",
                  value: "other",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 1,
            },
            {
              id: 6,
              name: "ros_neuroPsychSubDiagAmyotropSubDescActiveSubTreat",
              type: "options_tree",
              isMulti: true,
              label: "Treatment",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Medication",
                  value: "medication",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Other Treatment",
                      fullWidth: true,
                      required: false,
                      xs: 18,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      subQuestionId: 1,
                      name: "ros_neuroPsychSubDiagAmyotropSubDescActiveSubTreatOtherSubDescText",
                      type: "text",
                      label: "Other Treatment",
                      xs: 12,
                      isCommentEnabled: false,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Other Treatment",
                  value: "otherTreatment",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "No Treatment",
                  value: "noTreatment",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 2,
            },
          ],
          isCommentEnabled: false,
          label: "Active",
          value: "active",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "History of",
          value: "historyOf",
          type: "toggle_button",
        },
      ],
      isVisible: "ros_neuroPsychSubDiag=amyotrophicLateral",
      questionId: 604,
    },
    //
    {
      id: 6,
      name: "",
      value: "",
      type: "label",
      label: "Anxiety",
      fullWidth: true,
      required: false,
      xs: 18,
      isCommentEnabled: false,
      isConfigured: true,
      isModified: true,
      hasSubQuestions: true,
      isVisible: "ros_neuroPsychSubDiag=anxiety",
    },
    {
      id: 6,
      name: "ros_neuroPsychSubDiagAnxietySubDesc",
      type: "options_tree",
      label: "Describe",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "ros_neuroPsychSubDiagAnxietySubDescActiveSubType",
              type: "options_tree",
              label: "Type",
              isMulti: true,
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Generalized Anxiety Disorder",
                  value: "generalizedAnxietyDisorder",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Panic Disorder",
                  value: "panicDisorder",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Obsessive-Compulsive Disorder",
                  value: "obsessiveCompulsiveDisorder",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Post traumatic stress disorder",
                  value: "postTraumaticStressDisorder",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 5,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Social Phobia",
                  value: "socialPhobia",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 6,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Other",
                      fullWidth: true,
                      required: false,
                      xs: 18,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 1,
                      name: "ros_neuroPsychSubDiagAnxietySubDescActiveSubTypeOtherSubDesc",
                      type: "text",
                      label: "Describe",
                      xs: 4,
                      isCommentEnabled: false,
                      subQuestionId: 1,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Other",
                  value: "other",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 1,
            },
            {
              id: 6,
              name: "ros_neuroPsychSubDiagAnxietySubDescActiveSubTreat",
              type: "options_tree",
              isMulti: true,
              label: "Treatment",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Medication",
                  value: "medication",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Other Treatment",
                      fullWidth: true,
                      required: false,
                      xs: 18,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      subQuestionId: 1,
                      name: "ros_neuroPsychSubDiagAnxietySubDescActiveSubTreatOtherSubDescText",
                      type: "text",
                      label: "Other Treatment",
                      xs: 12,
                      isCommentEnabled: false,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Other Treatment",
                  value: "otherTreatment",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "No Treatment",
                  value: "noTreatment",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 2,
            },
          ],
          isCommentEnabled: false,
          label: "Active",
          value: "active",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "History of",
          value: "historyOf",
          type: "toggle_button",
        },
      ],
      isVisible: "ros_neuroPsychSubDiag=anxiety",
      questionId: 605,
    },
    //
    {
      id: 6,
      name: "",
      value: "",
      type: "label",
      label: "Attention Deficit Disorder",
      fullWidth: true,
      required: false,
      xs: 18,
      isCommentEnabled: false,
      isConfigured: true,
      isModified: true,
      hasSubQuestions: true,
      isVisible: "ros_neuroPsychSubDiag=attentionDeficit",
    },
    {
      id: 6,
      name: "ros_neuroPsychSubDiagAttDefOrderSubDesc",
      type: "options_tree",
      label: "Describe",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "ros_neuroPsychSubDiagAttDefOrderSubDescActiveSubType",
              type: "options_tree",
              label: "Type",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Inattentive",
                  value: "inattentive",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Hyperactive",
                  value: "hyperactive",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Combined",
                  value: "combined",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Other",
                  value: "other",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 5,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Unspecified",
                  value: "unspecified",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 1,
            },
            {
              id: 6,
              name: "ros_neuroPsychSubDiagAttDefOrderSubDescActiveSubTreat",
              type: "options_tree",
              isMulti: true,
              label: "Treatment",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Medication",
                  value: "medication",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Other Treatment",
                      fullWidth: true,
                      required: false,
                      xs: 18,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      subQuestionId: 1,
                      name: "ros_neuroPsychSubDiagAttDefOrderSubDescActiveSubTreatOtherSubDescText",
                      type: "text",
                      label: "Other Treatment",
                      xs: 12,
                      isCommentEnabled: false,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Other Treatment",
                  value: "otherTreatment",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "No Treatment",
                  value: "noTreatment",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 2,
            },
          ],
          isCommentEnabled: false,
          label: "Active",
          value: "active",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "History of",
          value: "historyOf",
          type: "toggle_button",
        },
      ],
      isVisible: "ros_neuroPsychSubDiag=attentionDeficit",
      questionId: 606,
    },
    //
    {
      id: 6,
      name: "",
      value: "",
      type: "label",
      label: "Autism",
      fullWidth: true,
      required: false,
      xs: 18,
      isCommentEnabled: false,
      isConfigured: true,
      isModified: true,
      hasSubQuestions: true,
      isVisible: "ros_neuroPsychSubDiag=autism",
    },
    {
      id: 6,
      name: "ros_neuroPsychSubDiagAutismSubDesc",
      type: "options_tree",
      label: "Describe",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "ros_neuroPsychSubDiagAutismSubDescActiveSubTreat",
              type: "options_tree",
              isMulti: true,
              label: "Treatment",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Medication",
                  value: "medication",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Other Treatment",
                      fullWidth: true,
                      required: false,
                      xs: 18,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      subQuestionId: 1,
                      name: "ros_neuroPsychSubDiagAutismSubDescActiveSubTreatOtherSubDescText",
                      type: "text",
                      label: "Other Treatment",
                      xs: 12,
                      isCommentEnabled: false,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Other Treatment",
                  value: "otherTreatment",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "No Treatment",
                  value: "noTreatment",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 2,
            },
          ],
          isCommentEnabled: false,
          label: "Active",
          value: "active",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "History of",
          value: "historyOf",
          type: "toggle_button",
        },
      ],
      isVisible: "ros_neuroPsychSubDiag=autism",
      questionId: 607,
    },
    //
    {
      id: 6,
      name: "",
      value: "",
      type: "label",
      label: "Bipolar Disorder",
      fullWidth: true,
      required: false,
      xs: 18,
      isCommentEnabled: false,
      isConfigured: true,
      isModified: true,
      hasSubQuestions: true,
      isVisible: "ros_neuroPsychSubDiag=autism",
    },
    {
      id: 6,
      name: "ros_neuroPsychSubDiagBipolarSubDesc",
      type: "options_tree",
      label: "Describe",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "ros_neuroPsychSubDiagBipolarSubDescActiveSubType",
              type: "options_tree",
              label: "Type",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Manic Depression",
                  value: "manicDepression",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Bipolar Depression",
                  value: "bipolarDepression",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Mixed",
                  value: "mixed",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Unknown",
                  value: "unknown",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 2,
            },
            {
              id: 6,
              name: "ros_neuroPsychSubDiagBipolarSubDescActiveSubMoodSwing",
              type: "options_tree",
              label: "Mood Swings",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Yes",
                  value: "yes",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "No",
                  value: "no",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 2,
            },
            {
              id: 6,
              name: "ros_neuroPsychSubDiagBipolarSubDescActiveSubTreat",
              type: "options_tree",
              isMulti: true,
              label: "Treatment",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Medication",
                  value: "medication",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Other Treatment",
                      fullWidth: true,
                      required: false,
                      xs: 18,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      subQuestionId: 1,
                      name: "ros_neuroPsychSubDiagBipolarSubDescActiveSubTreatOtherSubDescText",
                      type: "text",
                      label: "Other Treatment",
                      xs: 12,
                      isCommentEnabled: false,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Other Treatment",
                  value: "otherTreatment",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "No Treatment",
                  value: "noTreatment",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 3,
            },
          ],
          isCommentEnabled: false,
          label: "Active",
          value: "active",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "History of",
          value: "historyOf",
          type: "toggle_button",
        },
      ],
      isVisible: "ros_neuroPsychSubDiag=bipolarDisorder",
      questionId: 608,
    },
    //
    {
      id: 6,
      name: "",
      value: "",
      type: "label",
      label: "Cerebral Hemorrhage",
      fullWidth: true,
      required: false,
      xs: 18,
      isCommentEnabled: false,
      isConfigured: true,
      isModified: true,
      hasSubQuestions: true,
      isVisible: "ros_neuroPsychSubDiag=cerebralHemorrhage",
    },
    {
      id: 6,
      name: "ros_neuroPsychSubDiagCerebHemmDesc",
      type: "options_tree",
      label: "Describe",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Active",
          value: "active",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "History of",
          value: "historyOf",
          type: "toggle_button",
        },
      ],
      isVisible: "ros_neuroPsychSubDiag=cerebralHemorrhage",
      questionId: 609,
    },
    {
      id: 6,
      name: "ros_neuroPsychSubDiagCerebHemmSelect",
      type: "options_tree",
      label: "Select",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 8,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "ros_neuroPsychSubDiagCerebHemmSelectOngoSubPhys",
              type: "options_tree",
              label: "Physical Findings",
              isMulti: true,
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 8,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Aphasia",
                  value: "aphasia",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 9,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Apraxia",
                  value: "apraxia",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 10,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Cranial Nerve Deficits",
                  value: "cranialNerveParalysis",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Leg Paralysis",
                      fullWidth: true,
                      required: false,
                      xs: 18,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagCerebHemmSelectOngoSubPhysLegSubLeg",
                      type: "options_tree",
                      label: "Leg Paralysis",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_neuroPsychSubDiagCerebHemmSelectOngoSubPhysLegSubLegLeftSubLeft",
                              type: "options_tree",
                              label: "Left Sided",
                              xs: 12,
                              isCommentEnabled: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Dominant",
                                  value: "dominant",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Non Dominant",
                                  value: "nonDominant",
                                  type: "toggle_button",
                                },
                              ],
                              subQuestionId: 2,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Left Sided",
                          value: "leftSided",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_neuroPsychSubDiagCerebHemmSelectOngoSubPhysLegSubLegRightSubRight",
                              type: "options_tree",
                              label: "Right Sided",
                              xs: 12,
                              isCommentEnabled: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Dominant",
                                  value: "dominant",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Non Dominant",
                                  value: "nonDominant",
                                  type: "toggle_button",
                                },
                              ],
                              subQuestionId: 2,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Right Sided",
                          value: "rightSided",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 3,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Leg Paralysis",
                  value: "legParalysis",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Arm Paralysis",
                      fullWidth: true,
                      required: false,
                      xs: 18,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagCerebHemmSelectOngoSubPhysArmSubArm",
                      type: "options_tree",
                      label: "Arm Paralysis",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_neuroPsychSubDiagCerebHemmSelectOngoSubPhysArmSubArmLeftSubLeft",
                              type: "options_tree",
                              label: "Left Sided",
                              xs: 12,
                              isCommentEnabled: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Dominant",
                                  value: "dominant",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Non Dominant",
                                  value: "nonDominant",
                                  type: "toggle_button",
                                },
                              ],
                              subQuestionId: 2,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Left Sided",
                          value: "leftSided",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_neuroPsychSubDiagCerebHemmSelectOngoSubPhysArmSubArmRightSubRight",
                              type: "options_tree",
                              label: "Right Sided",
                              xs: 12,
                              isCommentEnabled: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Dominant",
                                  value: "dominant",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Non Dominant",
                                  value: "nonDominant",
                                  type: "toggle_button",
                                },
                              ],
                              subQuestionId: 2,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Right Sided",
                          value: "rightSided",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 3,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Arm Paralysis",
                  value: "armParalysis",
                  type: "toggle_button",
                },

                {
                  subQuestionId: 6,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Hemiparesis",
                      fullWidth: true,
                      required: false,
                      xs: 18,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },

                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagCerebHemmSelectOngoSubPhysHemiSubHemi",
                      type: "options_tree",
                      label: "Hemiparesis",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_neuroPsychSubDiagCerebHemmSelectOngoSubPhysHemiSubHemiLeftSubLeft",
                              type: "options_tree",
                              label: "Left Sided",
                              xs: 12,
                              isCommentEnabled: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Dominant",
                                  value: "dominant",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Non Dominant",
                                  value: "nonDominant",
                                  type: "toggle_button",
                                },
                              ],
                              subQuestionId: 2,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Left Sided",
                          value: "leftSided",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_neuroPsychSubDiagCerebHemmSelectOngoSubPhysHemiSubHemiRightSubRight",
                              type: "options_tree",
                              label: "Right Sided",
                              xs: 12,
                              isCommentEnabled: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Dominant",
                                  value: "dominant",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Non Dominant",
                                  value: "nonDominant",
                                  type: "toggle_button",
                                },
                              ],
                              subQuestionId: 2,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Right Sided",
                          value: "rightSided",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 3,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Hemiparesis",
                  value: "hemiparesis",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 11,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Functional Quadriplegia",
                  value: "functionalQuadriplegia",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 5,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Other",
                      fullWidth: true,
                      required: false,
                      xs: 18,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 1,
                      name: "ros_neuroPsychSubDiagCerebHemmSelectOngoSubPhysOtherSubDesc",
                      type: "text",
                      label: "Describe",
                      xs: 4,
                      isCommentEnabled: false,
                      subQuestionId: 1,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Other",
                  value: "other",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 2,
            },
          ],
          isCommentEnabled: false,
          label: "Ongoing Deficits",
          value: "ongoingDeficits",
          type: "toggle_button",
        },
        {
          subQuestionId: 9,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "None",
          value: "none",
          type: "toggle_button",
        },
      ],
      isVisible: "ros_neuroPsychSubDiag=cerebralHemorrhage",
      questionId: 610,
    },
    {
      id: 6,
      name: "ros_neuroPsychSubDiagCerebHemmTreat",
      type: "options_tree",
      isMulti: true,
      label: "Treatment",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Medication",
          value: "medication",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "",
              value: "",
              type: "label",
              label: "Other Treatment",
              fullWidth: true,
              required: false,
              xs: 18,
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
            },
            {
              subQuestionId: 1,
              name: "ros_neuroPsychSubDiagCerebHemmTreatOtherSubDescText",
              type: "text",
              label: "Other Treatment",
              xs: 12,
              isCommentEnabled: false,
            },
          ],
          isCommentEnabled: false,
          label: "Other Treatment",
          value: "otherTreatment",
          type: "toggle_button",
        },
        {
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "No Treatment",
          value: "noTreatment",
          type: "toggle_button",
        },
      ],
      isVisible: "ros_neuroPsychSubDiag=cerebralHemorrhage",
      questionId: 611,
    },
    //
    {
      id: 6,
      name: "",
      value: "",
      type: "label",
      label: "Cerebral Palsy",
      fullWidth: true,
      required: false,
      xs: 18,
      isCommentEnabled: false,
      isConfigured: true,
      isModified: true,
      hasSubQuestions: true,
      isVisible: "ros_neuroPsychSubDiag=cerebralPalsy",
    },
    {
      id: 6,
      name: "ros_neuroPsychSubDiagCerebPalsySubDesc",
      type: "options_tree",
      label: "Describe",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "ros_neuroPsychSubDiagCerebPalsySubDescActiveSubSelect",
              type: "options_tree",
              label: "Select",
              isMulti: true,
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Weakness",
                  value: "weakness",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Difficulty swallowing",
                  value: "difficultySwallowing",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Mobility Issues",
                  value: "mobilityIssue",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 6,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Other",
                  value: "other",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 1,
            },
            {
              id: 6,
              name: "ros_neuroPsychSubDiagCerebPalsySubDescActiveSubTreat",
              type: "options_tree",
              isMulti: true,
              label: "Treatment",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Medication",
                  value: "medication",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Other Treatment",
                      fullWidth: true,
                      required: false,
                      xs: 18,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      subQuestionId: 1,
                      name: "ros_neuroPsychSubDiagCerebPalsySubDescActiveSubTreatOtherSubDescText",
                      type: "text",
                      label: "Other Treatment",
                      xs: 12,
                      isCommentEnabled: false,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Other Treatment",
                  value: "otherTreatment",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "No Treatment",
                  value: "noTreatment",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 2,
            },
          ],
          isCommentEnabled: false,
          label: "Active",
          value: "active",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "History of",
          value: "historyOf",
          type: "toggle_button",
        },
      ],
      isVisible: "ros_neuroPsychSubDiag=cerebralPalsy",
      questionId: 612,
    },
    //
    {
      id: 6,
      name: "",
      value: "",
      type: "label",
      label: "Delusional Disease",
      fullWidth: true,
      required: false,
      xs: 18,
      isCommentEnabled: false,
      isConfigured: true,
      isModified: true,
      hasSubQuestions: true,
      isVisible: "ros_neuroPsychSubDiag=delusionalDisease",
    },
    {
      id: 6,
      name: "ros_neuroPsychSubDiagDelusionSubDesc",
      type: "options_tree",
      label: "Describe",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "ros_neuroPsychSubDiagDelusionSubDescActiveSubTreat",
              type: "options_tree",
              isMulti: true,
              label: "Treatment",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Medication",
                  value: "medication",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Other Treatment",
                      fullWidth: true,
                      required: false,
                      xs: 18,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      isVisible: "ros_neuroPsychSubDiag=alcoholDependence",
                    },
                    {
                      subQuestionId: 1,
                      name: "ros_neuroPsychSubDiagDelusionSubDescActiveSubTreatOtherSubDescText",
                      type: "text",
                      label: "Other Treatment",
                      xs: 12,
                      isCommentEnabled: false,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Other Treatment",
                  value: "otherTreatment",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "No Treatment",
                  value: "noTreatment",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 2,
            },
          ],
          isCommentEnabled: false,
          label: "Active",
          value: "active",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "History of",
          value: "historyOf",
          type: "toggle_button",
        },
      ],
      isVisible: "ros_neuroPsychSubDiag=delusionalDisease",
      questionId: 613,
    },
    //
    {
      id: 6,
      name: "",
      value: "",
      type: "label",
      label: "Dementia",
      fullWidth: true,
      required: false,
      xs: 18,
      isCommentEnabled: false,
      isConfigured: true,
      isModified: true,
      hasSubQuestions: true,
      isVisible: "ros_neuroPsychSubDiag=dementia",
    },
    {
      id: 6,
      name: "ros_neuroPsychSubDiagDementiaSubDesc",
      type: "options_tree",
      label: "Describe",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "ros_neuroPsychSubDiagDementiaSubDescActiveSubType",
              type: "options_tree",
              label: "Type of Dementia",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagDementiaSubDescActiveSubTypeVascSubType",
                      type: "options_tree",
                      label: "Supported by",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "History of Strokes",
                          value: "historyOfStrokes",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label:
                            "Risk factors (Atrial Fibrillation, Diabetes, Hypertension, Hypercholesterolemia, Smoking)",
                          value: "riskFactors",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 3,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Vascular",
                  value: "vascular",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Alzheimer's disease",
                  value: "alzheimersDisease",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Etiology Unknown",
                  value: "etiologyUnknown",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 3,
            },
            {
              id: 6,
              name: "ros_neuroPsychSubDiagDementiaSubDescActiveSubSelect",
              type: "options_tree",
              label: "Select",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Behavioral changes",
                  value: "behavioralDisturbances",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Functional Changes",
                  value: "functionalChanges",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 2,
            },
            {
              id: 6,
              name: "ros_neuroPsychSubDiagDementiaSubDescActiveSubTreat",
              type: "options_tree",
              isMulti: true,
              label: "Treatment",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Medication",
                  value: "medication",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Other Treatment",
                      fullWidth: true,
                      required: false,
                      xs: 18,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      isVisible: "ros_neuroPsychSubDiag=alcoholDependence",
                    },
                    {
                      subQuestionId: 1,
                      name: "ros_neuroPsychSubDiagDementiaSubDescActiveSubTreatOtherSubDescText",
                      type: "text",
                      label: "Other Treatment",
                      xs: 12,
                      isCommentEnabled: false,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Other Treatment",
                  value: "otherTreatment",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "No Treatment",
                  value: "noTreatment",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 2,
            },
          ],
          isCommentEnabled: false,
          label: "Active",
          value: "active",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "History of",
          value: "historyOf",
          type: "toggle_button",
        },
      ],
      isVisible: "ros_neuroPsychSubDiag=dementia",
      questionId: 614,
    },
    //
    {
      id: 6,
      name: "",
      value: "",
      type: "label",
      label: "Depression",
      fullWidth: true,
      required: false,
      xs: 18,
      isCommentEnabled: false,
      isConfigured: true,
      isModified: true,
      hasSubQuestions: true,
      isVisible: "ros_neuroPsychSubDiag=depression",
    },
    {
      id: 6,
      name: "ros_neuroPsychSubDiagDepressDesc",
      type: "options_tree",
      label: "Describe",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "ros_neuroPsychSubDiagDepressDescActiveSubType",
              type: "options_tree",
              label: "Type of Depression",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      subQuestionId: 1,
                      name: "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubCause",
                      type: "text",
                      label: "Cause",
                      xs: 12,
                      isCommentEnabled: false,
                    },
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubEpi",
                      type: "options_tree",
                      label: "Episodes",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Single Episode",
                          value: "single",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Recurrent",
                          value: "recurrent",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubDepSev",
                      type: "options_tree",
                      label: "Severity",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Unspecified",
                          value: "unspecified",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Mild",
                          value: "mild",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Moderate",
                          value: "moderate",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Severe Without Psychotic Features",
                          value: "severeWithoutPsychotic",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Severe With Psychotic Features",
                          value: "severeWithPsychotic",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubUnderCtrl",
                      type: "options_tree",
                      label: "Is Major Depression under good control?",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Yes",
                          value: "yes",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "NA",
                          value: "na",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubSympFree",
                      type: "options_tree",
                      label:
                        "Have you been symptom free for less than 2 months?",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Yes",
                          value: "yes",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "NA",
                          value: "na",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubSelect",
                      type: "options_tree",
                      label: "Select",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "In Remission Unspecified",
                          value: "inRemission",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Partial Remission",
                          value: "partialRemission",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label:
                            "Full Remission (During the past 2 months, no significant  signs or symptoms of the disturbance were present)",
                          value: "fullRemission",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "NA",
                          value: "NA",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Major",
                  value: "major",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Chronic",
                  value: "chronic",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 1,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagDepressDescActiveSubTypeManicSubDepSev",
                      type: "options_tree",
                      label: "Severity",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Mild",
                          value: "mild",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Moderate",
                          value: "moderate",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Moderately Severe",
                          value: "moderatelySevere",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Severe",
                          value: "severe",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Unknown",
                          value: "unknown",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Manic",
                  value: "manic",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagDepressDescActiveSubTypeUnspecSubDepSev",
                      type: "options_tree",
                      label: "Severity",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Mild",
                          value: "mild",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Moderate",
                          value: "moderate",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Moderately Severe",
                          value: "moderatelySevere",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Severe",
                          value: "severe",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Unknown",
                          value: "unknown",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Depression Unspecified",
                  value: "depressionUnspecified",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 1,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagDepressDescActiveSubTypeOtherSubDepSev",
                      type: "options_tree",
                      label: "Severity",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Mild",
                          value: "mild",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Moderate",
                          value: "moderate",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Moderately Severe",
                          value: "moderatelySevere",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Severe",
                          value: "severe",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Unknown",
                          value: "unknown",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Other",
                  value: "other",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Unknown",
                  value: "unknown",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 1,
            },
            {
              id: 6,
              name: "ros_neuroPsychSubDiagDepressDescActiveSubTreat",
              type: "options_tree",
              isMulti: true,
              label: "Treatment",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Medication",
                  value: "medication",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Other Treatment",
                      fullWidth: true,
                      required: false,
                      xs: 18,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      isVisible: "ros_neuroPsychSubDiag=alcoholDependence",
                    },
                    {
                      subQuestionId: 1,
                      name: "ros_neuroPsychSubDiagDepressDescActiveSubTreatOtherSubDescText",
                      type: "text",
                      label: "Other Treatment",
                      xs: 12,
                      isCommentEnabled: false,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Other Treatment",
                  value: "otherTreatment",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "No Treatment",
                  value: "noTreatment",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 2,
            },
          ],
          isCommentEnabled: false,
          label: "Active",
          value: "active",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "History of",
          value: "historyOf",
          type: "toggle_button",
        },
      ],
      isVisible: "ros_neuroPsychSubDiag=depression",
      questionId: 615,
    },
    //
    {
      id: 6,
      name: "",
      value: "",
      type: "label",
      label: "Drug Dependence ",
      fullWidth: true,
      required: false,
      xs: 18,
      isCommentEnabled: false,
      isConfigured: true,
      isModified: true,
      hasSubQuestions: true,
      isVisible: "ros_neuroPsychSubDiag=drugDependence",
    },
    {
      id: 6,
      name: "ros_neuroPsychSubDiagDrugsSubDesc",
      type: "options_tree",
      label: "Describe",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "ros_neuroPsychSubDiagDrugsSubDescActiveSubType",
              type: "options_tree",
              label: "Type",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Episodic",
                  value: "episodic",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Continuous",
                  value: "continuous",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Remission",
                  value: "remission",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 2,
            },
            {
              id: 1,
              name: "ros_neuroPsychSubDiagDrugsSubDescActiveSubWhatDrugs",
              type: "text",
              label: "What drugs",
              xs: 4,
              isCommentEnabled: false,
              subQuestionId: 5,
            },
            {
              id: 6,
              name: "ros_neuroPsychSubDiagDrugsSubDescActiveSubPsych",
              type: "options_tree",
              label: "History of Psychosis",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Yes",
                  value: "yes",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "No",
                  value: "no",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 4,
            },
            {
              id: 6,
              name: "ros_neuroPsychSubDiagDrugsSubDescActiveSubTreat",
              type: "options_tree",
              isMulti: true,
              label: "Treatment",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Medication",
                  value: "medication",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      subQuestionId: 1,
                      name: "ros_neuroPsychSubDiagDrugsSubDescActiveSubTreatOtherSubDescText",
                      type: "text",
                      label: "Other Treatment",
                      xs: 12,
                      isCommentEnabled: false,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Other Treatment",
                  value: "otherTreatment",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "No Treatment",
                  value: "noTreatment",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 2,
            },
          ],
          isCommentEnabled: false,
          label: "Active",
          value: "active",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "ros_neuroPsychSubDiagDrugsSubDescHistorySubType",
              type: "options_tree",
              label: "Type",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Episodic",
                  value: "episodic",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Continuous",
                  value: "continuous",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Remission",
                  value: "remission",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 2,
            },
            {
              id: 1,
              name: "ros_neuroPsychSubDiagDrugsSubDescHistorySubWhatDrugs",
              type: "text",
              label: "What drugs",
              xs: 4,
              isCommentEnabled: false,
              subQuestionId: 5,
            },
            {
              id: 6,
              name: "ros_neuroPsychSubDiagDrugsSubDescHistorySubPsych",
              type: "options_tree",
              label: "History of Psychosis",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Yes",
                  value: "yes",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "No",
                  value: "no",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 4,
            },
            {
              id: 6,
              name: "ros_neuroPsychSubDiagDrugsSubDescHistorySubTreat",
              type: "options_tree",
              isMulti: true,
              label: "Treatment",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Medication",
                  value: "medication",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Other Treatment",
                      fullWidth: true,
                      required: false,
                      xs: 18,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      subQuestionId: 1,
                      name: "ros_neuroPsychSubDiagDrugsSubDescHistorySubTreatOtherSubDescText",
                      type: "text",
                      label: "Other Treatment",
                      xs: 12,
                      isCommentEnabled: false,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Other Treatment",
                  value: "otherTreatment",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "No Treatment",
                  value: "noTreatment",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 2,
            },
          ],
          isCommentEnabled: false,
          label: "History of",
          value: "historyOf",
          type: "toggle_button",
        },
      ],
      isVisible: "ros_neuroPsychSubDiag=drugDependence",
      questionId: 616,
    },
    //
    {
      id: 6,
      name: "",
      value: "",
      type: "label",
      label: "Fibromyalgia",
      fullWidth: true,
      required: false,
      xs: 18,
      isCommentEnabled: false,
      isConfigured: true,
      isModified: true,
      hasSubQuestions: true,
      isVisible: "ros_neuroPsychSubDiag=fibromyalgia",
    },
    {
      id: 6,
      name: "ros_neuroPsychSubDiagFibroSubDesc",
      type: "options_tree",
      label: " Describe",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "ros_neuroPsychSubDiagFibroSubDescActiveSubTreat",
              type: "options_tree",
              isMulti: true,
              label: "Treatment",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Medication",
                  value: "medication",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Other Treatment",
                      fullWidth: true,
                      required: false,
                      xs: 18,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      isVisible: "ros_neuroPsychSubDiag=alcoholDependence",
                    },
                    {
                      subQuestionId: 1,
                      name: "ros_neuroPsychSubDiagFibroSubDescActiveSubTreatOtherSubDescText",
                      type: "text",
                      label: "Other Treatment",
                      xs: 12,
                      isCommentEnabled: false,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Other Treatment",
                  value: "otherTreatment",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "No Treatment",
                  value: "noTreatment",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 2,
            },
          ],
          isCommentEnabled: false,
          label: "Active",
          value: "active",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "History of",
          value: "historyOf",
          type: "toggle_button",
        },
      ],
      isVisible: "ros_neuroPsychSubDiag=fibromyalgia",
      questionId: 617,
    },
    //
    {
      id: 6,
      name: "",
      value: "",
      type: "label",
      label: "Guillain-Barre Disease ",
      fullWidth: true,
      required: false,
      xs: 18,
      isCommentEnabled: false,
      isConfigured: true,
      isModified: true,
      hasSubQuestions: true,
      isVisible: "ros_neuroPsychSubDiag=guillainBarreDisease",
    },
    {
      id: 6,
      name: "ros_neuroPsychSubDiagGuillSubDesc",
      type: "options_tree",
      label: "Describe",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "ros_neuroPsychSubDiagGuillSubDescActiveSubTreat",
              type: "options_tree",
              isMulti: true,
              label: "Treatment",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Medication",
                  value: "medication",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Other Treatment",
                      fullWidth: true,
                      required: false,
                      xs: 18,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      subQuestionId: 1,
                      name: "ros_neuroPsychSubDiagGuillSubDescActiveSubTreatOtherSubDescText",
                      type: "text",
                      label: "Other Treatment",
                      xs: 12,
                      isCommentEnabled: false,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Other Treatment",
                  value: "otherTreatment",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "No Treatment",
                  value: "noTreatment",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 2,
            },
          ],
          isCommentEnabled: false,
          label: "Active",
          value: "active",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "History of",
          value: "historyOf",
          type: "toggle_button",
        },
      ],
      isVisible: "ros_neuroPsychSubDiag=guillainBarreDisease",
      questionId: 618,
    },
    //
    {
      id: 6,
      name: "",
      value: "",
      type: "label",
      label: "Hemiparesis",
      fullWidth: true,
      required: false,
      xs: 18,
      isCommentEnabled: false,
      isConfigured: true,
      isModified: true,
      hasSubQuestions: true,
      isVisible: "ros_neuroPsychSubDiag=hemiparesis",
    },
    {
      id: 6,
      name: "ros_neuroPsychSubDiagHemiSubDesc",
      type: "options_tree",
      label: " Describe",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "ros_neuroPsychSubDiagHemiSubDescActiveSubLateral",
              type: "options_tree",
              label: "Laterality",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagHemiSubDescActiveSubLateralLeftSubLeft",
                      type: "options_tree",
                      label: "Left Sided",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Dominant",
                          value: "dominant",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Non Dominant",
                          value: "nonDominant",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Left sided",
                  value: "leftSided",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagHemiSubDescActiveSubLateralRightSubRight",
                      type: "options_tree",
                      label: "Right Sided",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Dominant",
                          value: "dominant",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Non Dominant",
                          value: "nonDominant",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Right sided",
                  value: "rightSided",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 2,
            },
            {
              subQuestionId: 1,
              name: "ros_neuroPsychSubDiagHemiSubDescActiveSubCause",
              type: "text",
              label: "Cause",
              xs: 12,
              isCommentEnabled: false,
            },
          ],
          isCommentEnabled: false,
          label: "Active",
          value: "active",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "History of",
          value: "historyOf",
          type: "toggle_button",
        },
      ],
      isVisible: "ros_neuroPsychSubDiag=hemiparesis",
      questionId: 619,
    },
    //
    {
      id: 6,
      name: "",
      value: "",
      type: "label",
      label: "Huntington's Chorea ",
      fullWidth: true,
      required: false,
      xs: 18,
      isCommentEnabled: false,
      isConfigured: true,
      isModified: true,
      hasSubQuestions: true,
      isVisible: "ros_neuroPsychSubDiag=huntingtonsChorea",
    },
    {
      id: 6,
      name: "ros_neuroPsychSubDiagHuntingSubDesc",
      type: "options_tree",
      label: "Describe",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "ros_neuroPsychSubDiagHuntingSubDescActiveSubSelect",
              type: "options_tree",
              label: "Select",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: true,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Chorea Movement",
                  value: "choreaMovement",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Unknown",
                  value: "unknown",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 1,
            },
            {
              id: 6,
              name: "ros_neuroPsychSubDiagHuntingSubDescActiveSubTreat",
              type: "options_tree",
              isMulti: true,
              label: "Treatment",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Medication",
                  value: "medication",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Other Treatment",
                      fullWidth: true,
                      required: false,
                      xs: 18,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      isVisible: "ros_neuroPsychSubDiag=alcoholDependence",
                    },
                    {
                      subQuestionId: 1,
                      name: "ros_neuroPsychSubDiagHuntingSubDescActiveSubTreatOtherSubDescText",
                      type: "text",
                      label: "Other Treatment",
                      xs: 12,
                      isCommentEnabled: false,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Other Treatment",
                  value: "otherTreatment",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "No Treatment",
                  value: "noTreatment",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 2,
            },
          ],
          isCommentEnabled: false,
          label: "Active",
          value: "active",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "History of",
          value: "historyOf",
          type: "toggle_button",
        },
      ],
      isVisible: "ros_neuroPsychSubDiag=huntingtonsChorea",
      questionId: 620,
    },
    //
    {
      id: 6,
      name: "",
      value: "",
      type: "label",
      label: "Insomnia",
      fullWidth: true,
      required: false,
      xs: 18,
      isCommentEnabled: false,
      isConfigured: true,
      isModified: true,
      hasSubQuestions: true,
      isVisible: "ros_neuroPsychSubDiag=insomnia",
    },
    {
      id: 6,
      name: "ros_neuroPsychSubDiagInsomniaSubDesc",
      type: "options_tree",
      label: " Describe",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "ros_neuroPsychSubDiagInsomniaSubDescActiveSubTreat",
              type: "options_tree",
              isMulti: true,
              label: "Treatment",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Medication",
                  value: "medication",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Other Treatment",
                      fullWidth: true,
                      required: false,
                      xs: 18,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      subQuestionId: 1,
                      name: "ros_neuroPsychSubDiagInsomniaSubDescActiveSubTreatOtherSubDescText",
                      type: "text",
                      label: "Other Treatment",
                      xs: 12,
                      isCommentEnabled: false,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Other Treatment",
                  value: "otherTreatment",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "No Treatment",
                  value: "noTreatment",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 2,
            },
          ],
          isCommentEnabled: false,
          label: "Active",
          value: "active",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "History of",
          value: "historyOf",
          type: "toggle_button",
        },
      ],
      isVisible: "ros_neuroPsychSubDiag=insomnia",
      questionId: 621,
    },
    //
    {
      id: 6,
      name: "",
      value: "",
      type: "label",
      label: "Intellectual and or Developmental Disability ",
      fullWidth: true,
      required: false,
      xs: 18,
      isCommentEnabled: false,
      isConfigured: true,
      isModified: true,
      hasSubQuestions: true,
      isVisible: "ros_neuroPsychSubDiag=developmentalDisability",
    },
    {
      id: 6,
      name: "ros_neuroPsychSubDiagIntelSubDesc",
      type: "options_tree",
      label: "Describe",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "ros_neuroPsychSubDiagIntelSubDescActiveSubDesc",
              type: "options_tree",
              label: "Describe",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Down's Syndrome",
                  value: "downsSyndrome",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Psychomotor Retardation",
                  value: "phychomotorRetardation",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 1,
                      name: "ros_neuroPsychSubDiagIntelSubDescActiveSubDescOtherSubDesc",
                      type: "text",
                      label: "Describe",
                      xs: 4,
                      isCommentEnabled: false,
                      subQuestionId: 1,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Other",
                  value: "other",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 3,
            },
            {
              id: 6,
              name: "ros_neuroPsychSubDiagIntelSubDescActiveSubTreat",
              type: "options_tree",
              isMulti: true,
              label: "Treatment",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Medication",
                  value: "medication",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Other Treatment",
                      fullWidth: true,
                      required: false,
                      xs: 18,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      subQuestionId: 1,
                      name: "ros_neuroPsychSubDiagIntelSubDescActiveSubTreatOtherSubDescText",
                      type: "text",
                      label: "Other Treatment",
                      xs: 12,
                      isCommentEnabled: false,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Other Treatment",
                  value: "otherTreatment",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "No Treatment",
                  value: "noTreatment",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 2,
            },
          ],
          isCommentEnabled: false,
          label: "Active",
          value: "active",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "History of",
          value: "historyOf",
          type: "toggle_button",
        },
      ],
      isVisible: "ros_neuroPsychSubDiag=developmentalDisability",
      questionId: 622,
    },
    //
    {
      id: 6,
      name: "",
      value: "",
      type: "label",
      label: "Migraine Headaches ",
      fullWidth: true,
      required: false,
      xs: 18,
      isCommentEnabled: false,
      isConfigured: true,
      isModified: true,
      hasSubQuestions: true,
      isVisible: "ros_neuroPsychSubDiag=migraineHeadaches",
    },
    {
      id: 6,
      name: "ros_neuroPsychSubDiagMigraineSubDesc",
      type: "options_tree",
      label: "Describe",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "ros_neuroPsychSubDiagMigraineSubDescActiveSubSelectAura",
              type: "options_tree",
              label: "Select",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "With Aura",
                  value: "withAura",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Without Aura",
                  value: "withoutAura",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 2,
            },
            {
              id: 6,
              name: "ros_neuroPsychSubDiagMigraineSubDescActiveSubTreat",
              type: "options_tree",
              isMulti: true,
              label: "Treatment",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Medication",
                  value: "medication",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Other Treatment",
                      fullWidth: true,
                      required: false,
                      xs: 18,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      subQuestionId: 1,
                      name: "ros_neuroPsychSubDiagMigraineSubDescActiveSubTreatOtherSubDescText",
                      type: "text",
                      label: "Other Treatment",
                      xs: 12,
                      isCommentEnabled: false,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Other Treatment",
                  value: "otherTreatment",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "No Treatment",
                  value: "noTreatment",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 2,
            },
          ],
          isCommentEnabled: false,
          label: "Active",
          value: "active",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "History of",
          value: "historyOf",
          type: "toggle_button",
        },
      ],
      isVisible: "ros_neuroPsychSubDiag=migraineHeadaches",
      questionId: 623,
    },
    //
    {
      id: 6,
      name: "",
      value: "",
      type: "label",
      label: "Multiple Sclerosis ",
      fullWidth: true,
      required: false,
      xs: 18,
      isCommentEnabled: false,
      isConfigured: true,
      isModified: true,
      hasSubQuestions: true,
      isVisible: "ros_neuroPsychSubDiag=multipleSclerosis",
    },
    {
      id: 6,
      name: "ros_neuroPsychSubDiagSclerosisSubDesc",
      type: "options_tree",
      label: "Describe",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "ros_neuroPsychSubDiagSclerosisSubDescActiveSubSymp",
              type: "options_tree",
              label: "Symptoms",
              isMulti: true,
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 4,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Bowel or bladder dysfunction",
                  value: "bowelDysfunction",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Visual Disturbances",
                  value: "visualDisturbances",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Weakness",
                  value: "weakness",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Numbness",
                  value: "numbness",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 5,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Decreased Coordination",
                  value: "deceasedCoordination",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 6,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Other",
                  value: "other",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 6,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "None",
                  value: "none",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 2,
            },
            {
              id: 6,
              name: "ros_neuroPsychSubDiagSclerosisSubDescActiveSubTreat",
              type: "options_tree",
              isMulti: true,
              label: "Treatment",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Medication",
                  value: "medication",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Other Treatment",
                      fullWidth: true,
                      required: false,
                      xs: 18,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      isVisible: "ros_neuroPsychSubDiag=alcoholDependence",
                    },
                    {
                      subQuestionId: 1,
                      name: "ros_neuroPsychSubDiagSclerosisSubDescActiveSubTreatOtherSubDescText",
                      type: "text",
                      label: "Other Treatment",
                      xs: 12,
                      isCommentEnabled: false,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Other Treatment",
                  value: "otherTreatment",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "No Treatment",
                  value: "noTreatment",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 2,
            },
          ],
          isCommentEnabled: false,
          label: "Active",
          value: "active",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "History of",
          value: "historyOf",
          type: "toggle_button",
        },
      ],
      isVisible: "ros_neuroPsychSubDiag=multipleSclerosis",
      questionId: 624,
    },
    //
    {
      id: 6,
      name: "",
      value: "",
      type: "label",
      label: "Muscular Dystrophy ",
      fullWidth: true,
      required: false,
      xs: 18,
      isCommentEnabled: false,
      isConfigured: true,
      isModified: true,
      hasSubQuestions: true,
      isVisible: "ros_neuroPsychSubDiag=muscularDystrophy",
    },
    {
      id: 6,
      name: "ros_neuroPsychSubDiagDystrophySubDesc",
      type: "options_tree",
      label: "Describe",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "ros_neuroPsychSubDiagDystrophySubDescActiveSubProgMuscWeak",
              type: "options_tree",
              label: "Progressive Muscle Weakness?",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 4,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Yes",
                  value: "yes",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "No",
                  value: "no",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Unknown",
                  value: "unknown",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 2,
            },
            {
              id: 6,
              name: "ros_neuroPsychSubDiagDystrophySubDescActiveSubTreat",
              type: "options_tree",
              isMulti: true,
              label: "Treatment",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Medication",
                  value: "medication",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Other Treatment",
                      fullWidth: true,
                      required: false,
                      xs: 18,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      subQuestionId: 1,
                      name: "ros_neuroPsychSubDiagDystrophySubDescActiveSubTreatOtherSubDescText",
                      type: "text",
                      label: "Other Treatment",
                      xs: 12,
                      isCommentEnabled: false,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Other Treatment",
                  value: "otherTreatment",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "No Treatment",
                  value: "noTreatment",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 2,
            },
          ],
          isCommentEnabled: false,
          label: "Active",
          value: "active",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "History of",
          value: "historyOf",
          type: "toggle_button",
        },
      ],
      isVisible: "ros_neuroPsychSubDiag=muscularDystrophy",
      questionId: 625,
    },
    //
    {
      id: 6,
      name: "",
      value: "",
      type: "label",
      label: "Myasthenia Gravis",
      fullWidth: true,
      required: false,
      xs: 18,
      isCommentEnabled: false,
      isConfigured: true,
      isModified: true,
      hasSubQuestions: true,
      isVisible: "ros_neuroPsychSubDiag=myastheniaGravis",
    },
    {
      id: 6,
      name: "ros_neuroPsychSubDiagGravisSubDesc",
      type: "options_tree",
      label: "Describe",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "ros_neuroPsychSubDiagGravisSubDescActiveSubSelect",
              type: "options_tree",
              label: "Select",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Double vision",
                  value: "doubleVision",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Difficulty chewing",
                  value: "difficultyChewing",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Difficulty swallowing",
                  value: "difficultySwallowing",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 5,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Other",
                  value: "other",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 6,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "None",
                  value: "none",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 2,
            },
            {
              id: 6,
              name: "ros_neuroPsychSubDiagGravisSubDescActiveSubTreat",
              type: "options_tree",
              isMulti: true,
              label: "Treatment",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Medication",
                  value: "medication",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Other Treatment",
                      fullWidth: true,
                      required: false,
                      xs: 18,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      subQuestionId: 1,
                      name: "ros_neuroPsychSubDiagGravisSubDescActiveSubTreatOtherSubDescText",
                      type: "text",
                      label: "Other Treatment",
                      xs: 12,
                      isCommentEnabled: false,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Other Treatment",
                  value: "otherTreatment",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "No Treatment",
                  value: "noTreatment",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 2,
            },
          ],
          isCommentEnabled: false,
          label: "Active",
          value: "active",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "History of",
          value: "historyOf",
          type: "toggle_button",
        },
      ],
      isVisible: "ros_neuroPsychSubDiag=myastheniaGravis",
      questionId: 626,
    },
    //
    {
      id: 6,
      name: "",
      value: "",
      type: "label",
      label: "Parkinson's Disease",
      fullWidth: true,
      required: false,
      xs: 18,
      isCommentEnabled: false,
      isConfigured: true,
      isModified: true,
      hasSubQuestions: true,
      isVisible: "ros_neuroPsychSubDiag=parkinsonsDisease",
    },
    {
      id: 6,
      name: "ros_neuroPsychSubDiagParkinsonSubDesc",
      type: "options_tree",
      label: " Describe",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "ros_neuroPsychSubDiagParkinsonSubDescActiveSubDementia",
              type: "options_tree",
              label: "Dementia?",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 4,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Yes",
                  value: "yes",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "No",
                  value: "no",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Unknown",
                  value: "unknown",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 2,
            },
            {
              id: 6,
              name: "ros_neuroPsychSubDiagParkinsonSubDescActiveSubTreat",
              type: "options_tree",
              isMulti: true,
              label: "Treatment",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Medication",
                  value: "medication",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Other Treatment",
                      fullWidth: true,
                      required: false,
                      xs: 18,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      subQuestionId: 1,
                      name: "ros_neuroPsychSubDiagParkinsonSubDescActiveSubTreatOtherSubDescText",
                      type: "text",
                      label: "Other Treatment",
                      xs: 12,
                      isCommentEnabled: false,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Other Treatment",
                  value: "otherTreatment",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "No Treatment",
                  value: "noTreatment",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 2,
            },
          ],
          isCommentEnabled: false,
          label: "Active",
          value: "active",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "History of",
          value: "historyOf",
          type: "toggle_button",
        },
      ],
      isVisible: "ros_neuroPsychSubDiag=parkinsonsDisease",
      questionId: 627,
    },
    //
    {
      id: 6,
      name: "",
      value: "",
      type: "label",
      label: "Peripheral Neuropathy ",
      fullWidth: true,
      required: false,
      xs: 18,
      isCommentEnabled: false,
      isConfigured: true,
      isModified: true,
      hasSubQuestions: true,
      isVisible: "ros_neuroPsychSubDiag=peripheralNeuropathy",
    },
    {
      id: 6,
      name: "ros_neuroPsychSubDiagPeriNeuroSubDesc",
      type: "options_tree",
      label: "Describe",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "ros_neuroPsychSubDiagPeriNeuroSubDescActiveSubTreat",
              type: "options_tree",
              isMulti: true,
              label: "Treatment",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Medication",
                  value: "medication",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Other Treatment",
                      fullWidth: true,
                      required: false,
                      xs: 18,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      subQuestionId: 1,
                      name: "ros_neuroPsychSubDiagPeriNeuroSubDescActiveSubTreatOtherSubDescText",
                      type: "text",
                      label: "Other Treatment",
                      xs: 12,
                      isCommentEnabled: false,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Other Treatment",
                  value: "otherTreatment",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "No Treatment",
                  value: "noTreatment",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 2,
            },
          ],
          isCommentEnabled: false,
          label: "Active",
          value: "active",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "History of",
          value: "historyOf",
          type: "toggle_button",
        },
      ],
      isVisible: "ros_neuroPsychSubDiag=peripheralNeuropathy",
      questionId: 628,
    },
    //
    {
      id: 6,
      name: "",
      value: "",
      type: "label",
      label: "Restless leg syndrome ",
      fullWidth: true,
      required: false,
      xs: 18,
      isCommentEnabled: false,
      isConfigured: true,
      isModified: true,
      hasSubQuestions: true,
      isVisible: "ros_neuroPsychSubDiag=restlessLegSyndrome",
    },
    {
      id: 6,
      name: "ros_neuroPsychSubDiagRestlessLegSubDesc",
      type: "options_tree",
      label: "Describe",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "ros_neuroPsychSubDiagRestlessLegSubDescActiveSubTreat",
              type: "options_tree",
              isMulti: true,
              label: "Treatment",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Medication",
                  value: "medication",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Other Treatment",
                      fullWidth: true,
                      required: false,
                      xs: 18,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      subQuestionId: 1,
                      name: "ros_neuroPsychSubDiagRestlessLegSubDescActiveSubTreatOtherSubDescText",
                      type: "text",
                      label: "Other Treatment",
                      xs: 12,
                      isCommentEnabled: false,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Other Treatment",
                  value: "otherTreatment",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "No Treatment",
                  value: "noTreatment",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 2,
            },
          ],
          isCommentEnabled: false,
          label: "Active",
          value: "active",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "History of",
          value: "historyOf",
          type: "toggle_button",
        },
      ],
      isVisible: "ros_neuroPsychSubDiag=restlessLegSyndrome",
      questionId: 629,
    },
    //
    {
      id: 6,
      name: "",
      value: "",
      type: "label",
      label: "Schizophrenia",
      fullWidth: true,
      required: false,
      xs: 18,
      isCommentEnabled: false,
      isConfigured: true,
      isModified: true,
      hasSubQuestions: true,
      isVisible: "ros_neuroPsychSubDiag=schizophrenia",
    },
    {
      id: 6,
      name: "ros_neuroPsychSubDiagSchizoSubDesc",
      type: "options_tree",
      label: "Describe",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "ros_neuroPsychSubDiagSchizoSubDescActiveSubActive",
              type: "options_tree",
              label: "Active",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 4,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Psychosis",
                  value: "psychosis",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Paranoia",
                  value: "paranoia",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Other",
                  value: "other",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Unspecified",
                  value: "unspecified",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 2,
            },
            {
              id: 6,
              name: "ros_neuroPsychSubDiagSchizoSubDescActiveSubTreat",
              type: "options_tree",
              isMulti: true,
              label: "Treatment",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Medication",
                  value: "medication",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Other Treatment",
                      fullWidth: true,
                      required: false,
                      xs: 18,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      subQuestionId: 1,
                      name: "ros_neuroPsychSubDiagSchizoSubDescActiveSubTreatOtherSubDescText",
                      type: "text",
                      label: "Other Treatment",
                      xs: 12,
                      isCommentEnabled: false,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Other Treatment",
                  value: "otherTreatment",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "No Treatment",
                  value: "noTreatment",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 2,
            },
          ],
          isCommentEnabled: false,
          label: "Active",
          value: "active",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "History of",
          value: "historyOf",
          type: "toggle_button",
        },
      ],
      isVisible: "ros_neuroPsychSubDiag=schizophrenia",
      questionId: 630,
    },
    //
    {
      id: 6,
      name: "",
      value: "",
      type: "label",
      label: "Seizures",
      fullWidth: true,
      required: false,
      xs: 18,
      isCommentEnabled: false,
      isConfigured: true,
      isModified: true,
      hasSubQuestions: true,
      isVisible: "ros_neuroPsychSubDiag=seizures",
    },
    {
      id: 6,
      name: "ros_neuroPsychSubDiagSeizureSubDesc",
      type: "options_tree",
      label: " Describe",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "ros_neuroPsychSubDiagSeizureSubDescActiveSubType",
              type: "options_tree",
              label: "Type",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Seizure Disorder",
                  value: "seizureDisorder",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Seizure, Unspecified",
                  value: "seizureUnspecified",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 2,
            },
            {
              id: 6,
              name: "ros_neuroPsychSubDiagSeizureSubDescActiveSubTreat",
              type: "options_tree",
              isMulti: true,
              label: "Treatment",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Medication",
                  value: "medication",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Other Treatment",
                      fullWidth: true,
                      required: false,
                      xs: 18,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      subQuestionId: 1,
                      name: "ros_neuroPsychSubDiagSeizureSubDescActiveSubTreatOtherSubDescText",
                      type: "text",
                      label: "Other Treatment",
                      xs: 12,
                      isCommentEnabled: false,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Other Treatment",
                  value: "otherTreatment",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "No Treatment",
                  value: "noTreatment",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 2,
            },
          ],
          isCommentEnabled: false,
          label: "Active",
          value: "active",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "History of",
          value: "historyOf",
          type: "toggle_button",
        },
      ],
      isVisible: "ros_neuroPsychSubDiag=seizures",
      questionId: 631,
    },
    //
    {
      id: 6,
      name: "",
      value: "",
      type: "label",
      label: "Spinal Cord Injury ",
      fullWidth: true,
      required: false,
      xs: 18,
      isCommentEnabled: false,
      isConfigured: true,
      isModified: true,
      hasSubQuestions: true,
      isVisible: "ros_neuroPsychSubDiag=spinalCordInjury",
    },
    {
      id: 6,
      name: "ros_neuroPsychSubDiagSpinalCordSubDesc",
      type: "options_tree",
      label: "Describe",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Active (within  8 months)",
          value: "active",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "History of",
          value: "historyOf",
          type: "toggle_button",
        },
      ],
      isVisible: "ros_neuroPsychSubDiag=spinalCordInjury",
      questionId: 632,
    },
    {
      id: 6,
      name: "ros_neuroPsychSubDiagSpinalCordSubType",
      type: "options_tree",
      label: "Type",
      isMulti: true,
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Paresis or paralysis",
          value: "paresis",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Loss of sensation",
          value: "lossOfSensation",
          type: "toggle_button",
        },
        {
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Bowel or bladder dysfunction",
          value: "bowel",
          type: "toggle_button",
        },
        {
          subQuestionId: 4,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Paraplegia",
          value: "paraplegia",
          type: "toggle_button",
        },
        {
          subQuestionId: 4,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Quadriplegia",
          value: "quadriplegia",
          type: "toggle_button",
        },
        {
          subQuestionId: 4,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Anterior Cord Syndrome",
          value: "anteriorCordsyndrome",
          type: "toggle_button",
        },
        {
          subQuestionId: 4,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Posterior Cord Syndrome",
          value: "posteriorCordsyndrome",
          type: "toggle_button",
        },
        {
          subQuestionId: 4,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Central Cord Syndrome",
          value: "centralCordsyndrome",
          type: "toggle_button",
        },
        {
          subQuestionId: 5,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "None",
          value: "none",
          type: "toggle_button",
        },
      ],
      isVisible: "ros_neuroPsychSubDiag=spinalCordInjury",
      questionId: 633,
    },
    {
      id: 6,
      name: "ros_neuroPsychSubDiagSpinalCordSubSecTo",
      type: "options_tree",
      label: "Secondary to",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Fracture",
          value: "fracture",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Dislocation",
          value: "dislocation",
          type: "toggle_button",
        },
        {
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Compressive Lession",
          value: "compressiveLesion",
          type: "toggle_button",
        },
      ],
      isVisible: "ros_neuroPsychSubDiag=spinalCordInjury",
      questionId: 634,
    },
    //
    {
      id: 6,
      name: "",
      value: "",
      type: "label",
      label: "Stroke",
      fullWidth: true,
      required: false,
      xs: 18,
      isCommentEnabled: false,
      isConfigured: true,
      isModified: true,
      hasSubQuestions: true,
      isVisible: "ros_neuroPsychSubDiag=stroke",
    },
    {
      id: 6,
      name: "ros_neuroPsychSubDiagStrokeDesc",
      type: "options_tree",
      label: "Describe",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Active",
          value: "active",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "History of",
          value: "historyOf",
          type: "toggle_button",
        },
      ],
      isVisible: "ros_neuroPsychSubDiag=stroke",
      questionId: 635,
    },
    {
      id: 1,
      name: "ros_neuroPsychSubDiagStrokeDate",
      type: "text",
      label: "Date",
      xs: 12,
      isCommentEnabled: false,
      isVisible: "ros_neuroPsychSubDiag=stroke",
      questionId: 636,
    },
    {
      id: 6,
      name: "ros_neuroPsychSubDiagStrokeSelect",
      type: "options_tree",
      label: "Select",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 8,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "ros_neuroPsychSubDiagStrokeSelectOngoSubPhys",
              type: "options_tree",
              label: "Physical Findings",
              isMulti: true,
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 8,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Aphasia",
                  value: "aphasia",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 9,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Apraxia",
                  value: "apraxia",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 10,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Cranial Nerve Deficits",
                  value: "cranialNerveParalysis",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Leg Paralysis",
                      fullWidth: true,
                      required: false,
                      xs: 18,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagStrokeSelectOngoSubPhysLegSubLeg",
                      type: "options_tree",
                      label: "Leg Paralysis",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_neuroPsychSubDiagStrokeSelectOngoSubPhysLegSubLegLeftSubLeft",
                              type: "options_tree",
                              label: "Left Sided",
                              xs: 12,
                              isCommentEnabled: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Dominant",
                                  value: "dominant",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Non Dominant",
                                  value: "nonDominant",
                                  type: "toggle_button",
                                },
                              ],
                              subQuestionId: 2,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Left Sided",
                          value: "leftSided",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_neuroPsychSubDiagStrokeSelectOngoSubPhysLegSubLegRightSubRight",
                              type: "options_tree",
                              label: "Right Sided",
                              xs: 12,
                              isCommentEnabled: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Dominant",
                                  value: "dominant",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Non Dominant",
                                  value: "nonDominant",
                                  type: "toggle_button",
                                },
                              ],
                              subQuestionId: 2,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Right Sided",
                          value: "rightSided",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 3,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Leg Paralysis",
                  value: "legParalysis",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Arm Paralysis",
                      fullWidth: true,
                      required: false,
                      xs: 18,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagStrokeSelectOngoSubPhysArmSubArm",
                      type: "options_tree",
                      label: "Arm Paralysis",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_neuroPsychSubDiagStrokeSelectOngoSubPhysArmSubArmLeftSubLeft",
                              type: "options_tree",
                              label: "Left Sided",
                              xs: 12,
                              isCommentEnabled: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Dominant",
                                  value: "dominant",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Non Dominant",
                                  value: "nonDominant",
                                  type: "toggle_button",
                                },
                              ],
                              subQuestionId: 2,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Left Sided",
                          value: "leftSided",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_neuroPsychSubDiagStrokeSelectOngoSubPhysArmSubArmRightSubRight",
                              type: "options_tree",
                              label: "Right Sided",
                              xs: 12,
                              isCommentEnabled: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Dominant",
                                  value: "dominant",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Non Dominant",
                                  value: "nonDominant",
                                  type: "toggle_button",
                                },
                              ],
                              subQuestionId: 2,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Right Sided",
                          value: "rightSided",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 3,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Arm Paralysis",
                  value: "armParalysis",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 6,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Hemiparesis",
                      fullWidth: true,
                      required: false,
                      xs: 18,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagStrokeSelectOngoSubPhysHemiSubHemi",
                      type: "options_tree",
                      label: "Hemiparesis",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_neuroPsychSubDiagStrokeSelectOngoSubPhysHemiSubHemiLeftSubLeft",
                              type: "options_tree",
                              label: "Left Sided",
                              xs: 12,
                              isCommentEnabled: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Dominant",
                                  value: "dominant",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Non Dominant",
                                  value: "nonDominant",
                                  type: "toggle_button",
                                },
                              ],
                              subQuestionId: 2,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Left Sided",
                          value: "leftSided",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_neuroPsychSubDiagStrokeSelectOngoSubPhysHemiSubHemiRightSubRight",
                              type: "options_tree",
                              label: "Right Sided",
                              xs: 12,
                              isCommentEnabled: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Dominant",
                                  value: "dominant",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Non Dominant",
                                  value: "nonDominant",
                                  type: "toggle_button",
                                },
                              ],
                              subQuestionId: 2,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Right Sided",
                          value: "rightSided",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 3,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Hemiparesis",
                  value: "hemiparesis",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 11,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Functional Quadriplegia",
                  value: "functionalQuadriplegia",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 5,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Other",
                      fullWidth: true,
                      required: false,
                      xs: 18,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 1,
                      name: "ros_neuroPsychSubDiagStrokeSelectOngoSubPhysOtherSubDesc",
                      type: "text",
                      label: "Describe",
                      xs: 4,
                      isCommentEnabled: false,
                      subQuestionId: 1,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Other",
                  value: "other",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 2,
            },
          ],
          isCommentEnabled: false,
          label: "Ongoing Deficits",
          value: "ongoingDeficits",
          type: "toggle_button",
        },
        {
          subQuestionId: 9,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "None",
          value: "none",
          type: "toggle_button",
        },
      ],
      isVisible: "ros_neuroPsychSubDiag=stroke",
      questionId: 637,
    },
    //
    {
      id: 6,
      name: "",
      value: "",
      type: "label",
      label: "Subdural Hematoma ",
      fullWidth: true,
      required: false,
      xs: 18,
      isCommentEnabled: false,
      isConfigured: true,
      isModified: true,
      hasSubQuestions: true,
      isVisible: "ros_neuroPsychSubDiag=subduralHematoma",
    },
    {
      id: 6,
      name: "ros_neuroPsychSubDiagSubduralDesc",
      type: "options_tree",
      label: "Describe",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Active",
          value: "active",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "History of",
          value: "historyOf",
          type: "toggle_button",
        },
      ],
      isVisible: "ros_neuroPsychSubDiag=subduralHematoma",
      questionId: 638,
    },
    {
      id: 6,
      name: "ros_neuroPsychSubDiagSubduralSelect",
      type: "options_tree",
      label: "Select",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 8,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "ros_neuroPsychSubDiagSubduralSelectOngoSubPhys",
              type: "options_tree",
              label: "Physical Findings",
              isMulti: true,
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 8,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Aphasia",
                  value: "aphasia",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 9,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Apraxia",
                  value: "apraxia",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 10,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Cranial Nerve Deficits",
                  value: "cranialNerveParalysis",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Leg Paralysis",
                      fullWidth: true,
                      required: false,
                      xs: 18,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagSubduralSelectOngoSubPhysLegSubLeg",
                      type: "options_tree",
                      label: "Leg Paralysis",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_neuroPsychSubDiagSubduralSelectOngoSubPhysLegSubLegLeftSubLeft",
                              type: "options_tree",
                              label: "Left Sided",
                              xs: 12,
                              isCommentEnabled: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Dominant",
                                  value: "dominant",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Non Dominant",
                                  value: "nonDominant",
                                  type: "toggle_button",
                                },
                              ],
                              subQuestionId: 2,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Left Sided",
                          value: "leftSided",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_neuroPsychSubDiagSubduralSelectOngoSubPhysLegSubLegRightSubRight",
                              type: "options_tree",
                              label: "Right Sided",
                              xs: 12,
                              isCommentEnabled: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Dominant",
                                  value: "dominant",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Non Dominant",
                                  value: "nonDominant",
                                  type: "toggle_button",
                                },
                              ],
                              subQuestionId: 2,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Right Sided",
                          value: "rightSided",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 3,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Leg Paralysis",
                  value: "legParalysis",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Arm Paralysis",
                      fullWidth: true,
                      required: false,
                      xs: 18,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagSubduralSelectOngoSubPhysArmSubArm",
                      type: "options_tree",
                      label: "Arm Paralysis",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_neuroPsychSubDiagSubduralSelectOngoSubPhysArmSubArmLeftSubLeft",
                              type: "options_tree",
                              label: "Left Sided",
                              xs: 12,
                              isCommentEnabled: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Dominant",
                                  value: "dominant",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Non Dominant",
                                  value: "nonDominant",
                                  type: "toggle_button",
                                },
                              ],
                              subQuestionId: 2,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Left Sided",
                          value: "leftSided",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Arm Paralysis",
                              fullWidth: true,
                              required: false,
                              xs: 18,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 6,
                              name: "ros_neuroPsychSubDiagSubduralSelectOngoSubPhysArmSubArmRightSubRight",
                              type: "options_tree",
                              label: "Right Sided",
                              xs: 12,
                              isCommentEnabled: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Dominant",
                                  value: "dominant",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Non Dominant",
                                  value: "nonDominant",
                                  type: "toggle_button",
                                },
                              ],
                              subQuestionId: 2,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Right Sided",
                          value: "rightSided",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 3,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Arm Paralysis",
                  value: "armParalysis",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 6,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Hemiparesis",
                      fullWidth: true,
                      required: false,
                      xs: 18,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagSubduralSelectOngoSubPhysHemiSubHemi",
                      type: "options_tree",
                      label: "Hemiparesis",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_neuroPsychSubDiagSubduralSelectOngoSubPhysHemiSubHemiLeftSubLeft",
                              type: "options_tree",
                              label: "Left Sided",
                              xs: 12,
                              isCommentEnabled: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Dominant",
                                  value: "dominant",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Non Dominant",
                                  value: "nonDominant",
                                  type: "toggle_button",
                                },
                              ],
                              subQuestionId: 2,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Left Sided",
                          value: "leftSided",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_neuroPsychSubDiagSubduralSelectOngoSubPhysHemiSubHemiRightSubRight",
                              type: "options_tree",
                              label: "Right Sided",
                              xs: 12,
                              isCommentEnabled: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Dominant",
                                  value: "dominant",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Non Dominant",
                                  value: "nonDominant",
                                  type: "toggle_button",
                                },
                              ],
                              subQuestionId: 2,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Right Sided",
                          value: "rightSided",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 3,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Hemiparesis",
                  value: "hemiparesis",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 11,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Functional Quadriplegia",
                  value: "functionalQuadriplegia",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 5,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Other",
                      fullWidth: true,
                      required: false,
                      xs: 18,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 1,
                      name: "ros_neuroPsychSubDiagSubduralSelectOngoSubPhysOtherSubDesc",
                      type: "text",
                      label: "Describe",
                      xs: 4,
                      isCommentEnabled: false,
                      subQuestionId: 1,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Other",
                  value: "other",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 2,
            },
          ],
          isCommentEnabled: false,
          label: "Ongoing Deficits",
          value: "ongoingDeficits",
          type: "toggle_button",
        },
        {
          subQuestionId: 9,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "None",
          value: "none",
          type: "toggle_button",
        },
      ],
      isVisible: "ros_neuroPsychSubDiag=subduralHematoma",
      questionId: 639,
    },
    //
    {
      id: 6,
      name: "",
      value: "",
      type: "label",
      label: "TIA",
      fullWidth: true,
      required: false,
      xs: 18,
      isCommentEnabled: false,
      isConfigured: true,
      isModified: true,
      hasSubQuestions: true,
      isVisible: "ros_neuroPsychSubDiag=tia",
    },
    {
      id: 6,
      name: "ros_neuroPsychSubDiagTiaSubDesc",
      type: "options_tree",
      label: "Describe",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Active",
          value: "active",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "History of",
          value: "historyOf",
          type: "toggle_button",
        },
      ],
      isVisible: "ros_neuroPsychSubDiag=tia",
      questionId: 640,
    },
    {
      id: 6,
      name: "ros_neuroPsychSubDiagTiaSubTreat",
      type: "options_tree",
      isMulti: true,
      label: "Treatment",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Medication",
          value: "medication",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "",
              value: "",
              type: "label",
              label: "Other Treatment",
              fullWidth: true,
              required: false,
              xs: 18,
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
            },
            {
              subQuestionId: 1,
              name: "ros_neuroPsychSubDiagTiaSubTreatOtherSubDescText",
              type: "text",
              label: "Other Treatment",
              xs: 12,
              isCommentEnabled: false,
            },
          ],
          isCommentEnabled: false,
          label: "Other Treatment",
          value: "otherTreatment",
          type: "toggle_button",
        },
        {
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "No Treatment",
          value: "noTreatment",
          type: "toggle_button",
        },
      ],
      isVisible: "ros_neuroPsychSubDiag=tia",
      questionId: 641,
    },
    //
    {
      id: 6,
      name: "",
      value: "",
      type: "label",
      label: "Traumatic Brain Injury ",
      fullWidth: true,
      required: false,
      xs: 18,
      isCommentEnabled: false,
      isConfigured: true,
      isModified: true,
      hasSubQuestions: true,
      isVisible: "ros_neuroPsychSubDiag=traumaticBrainInjury",
    },
    {
      id: 6,
      name: "ros_neuroPsychSubDiagTraumaBrainDesc",
      type: "options_tree",
      label: "Describe",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Active",
          value: "active",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "History of",
          value: "historyOf",
          type: "toggle_button",
        },
      ],
      isVisible: "ros_neuroPsychSubDiag=traumaticBrainInjury",
      questionId: 642,
    },
    {
      id: 1,
      name: "ros_neuroPsychSubDiagTraumaBrainDate",
      type: "text",
      label: "Date",
      xs: 12,
      isCommentEnabled: false,
      isVisible: "ros_neuroPsychSubDiag=traumaticBrainInjury",
      questionId: 643,
    },
    {
      id: 6,
      name: "ros_neuroPsychSubDiagTraumaBrainSelect",
      type: "options_tree",
      label: "Select",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 8,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "ros_neuroPsychSubDiagTraumaBrainSelectOngoSubPhys",
              type: "options_tree",
              label: "Physical Findings",
              isMulti: true,
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 8,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Aphasia",
                  value: "aphasia",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 9,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Apraxia",
                  value: "apraxia",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 10,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Cranial Nerve Deficits",
                  value: "cranialNerveParalysis",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Leg Paralysis",
                      fullWidth: true,
                      required: false,
                      xs: 18,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagTraumaBrainSelectOngoSubPhysLegSubLeg",
                      type: "options_tree",
                      label: "Leg Paralysis",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_neuroPsychSubDiagTraumaBrainSelectOngoSubPhysLegSubLegLeftSubLeft",
                              type: "options_tree",
                              label: "Left Sided",
                              xs: 12,
                              isCommentEnabled: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Dominant",
                                  value: "dominant",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Non Dominant",
                                  value: "nonDominant",
                                  type: "toggle_button",
                                },
                              ],
                              subQuestionId: 2,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Left Sided",
                          value: "leftSided",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_neuroPsychSubDiagTraumaBrainSelectOngoSubPhysLegSubLegRightSubRight",
                              type: "options_tree",
                              label: "Right Sided",
                              xs: 12,
                              isCommentEnabled: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Dominant",
                                  value: "dominant",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Non Dominant",
                                  value: "nonDominant",
                                  type: "toggle_button",
                                },
                              ],
                              subQuestionId: 2,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Right Sided",
                          value: "rightSided",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 3,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Leg Paralysis",
                  value: "legParalysis",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Arm Paralysis",
                      fullWidth: true,
                      required: false,
                      xs: 18,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagTraumaBrainSelectOngoSubPhysArmSubArm",
                      type: "options_tree",
                      label: "Arm Paralysis",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_neuroPsychSubDiagTraumaBrainSelectOngoSubPhysArmSubArmLeftSubLeft",
                              type: "options_tree",
                              label: "Left Sided",
                              xs: 12,
                              isCommentEnabled: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Dominant",
                                  value: "dominant",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Non Dominant",
                                  value: "nonDominant",
                                  type: "toggle_button",
                                },
                              ],
                              subQuestionId: 2,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Left Sided",
                          value: "leftSided",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_neuroPsychSubDiagTraumaBrainSelectOngoSubPhysArmSubArmRightSubRight",
                              type: "options_tree",
                              label: "Right Sided",
                              xs: 12,
                              isCommentEnabled: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Dominant",
                                  value: "dominant",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Non Dominant",
                                  value: "nonDominant",
                                  type: "toggle_button",
                                },
                              ],
                              subQuestionId: 2,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Right Sided",
                          value: "rightSided",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 3,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Arm Paralysis",
                  value: "armParalysis",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 6,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Hemiparesis",
                      fullWidth: true,
                      required: false,
                      xs: 18,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_neuroPsychSubDiagTraumaBrainSelectOngoSubPhysHemiSubHemi",
                      type: "options_tree",
                      label: "Hemiparesis",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_neuroPsychSubDiagTraumaBrainSelectOngoSubPhysHemiSubHemiLeftSubLeft",
                              type: "options_tree",
                              label: "Left Sided",
                              xs: 12,
                              isCommentEnabled: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Dominant",
                                  value: "dominant",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Non Dominant",
                                  value: "nonDominant",
                                  type: "toggle_button",
                                },
                              ],
                              subQuestionId: 2,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Left Sided",
                          value: "leftSided",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_neuroPsychSubDiagTraumaBrainSelectOngoSubPhysHemiSubHemiRightSubRight",
                              type: "options_tree",
                              label: "Right Sided",
                              xs: 12,
                              isCommentEnabled: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Dominant",
                                  value: "dominant",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  isCommentEnabled: false,
                                  label: "Non Dominant",
                                  value: "nonDominant",
                                  type: "toggle_button",
                                },
                              ],
                              subQuestionId: 2,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Right Sided",
                          value: "rightSided",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 3,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Hemiparesis",
                  value: "hemiparesis",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 11,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Functional Quadriplegia",
                  value: "functionalQuadriplegia",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 5,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Other",
                      fullWidth: true,
                      required: false,
                      xs: 18,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 1,
                      name: "ros_neuroPsychSubDiagTraumaBrainSelectOngoSubPhysOtherSubDesc",
                      type: "text",
                      label: "Describe",
                      xs: 4,
                      isCommentEnabled: false,
                      subQuestionId: 1,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Other",
                  value: "other",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 2,
            },
          ],
          isCommentEnabled: false,
          label: "Ongoing Deficits",
          value: "ongoingDeficits",
          type: "toggle_button",
        },
        {
          subQuestionId: 9,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "None",
          value: "none",
          type: "toggle_button",
        },
      ],
      isVisible: "ros_neuroPsychSubDiag=traumaticBrainInjury",
      questionId: 644,
    },
    {
      id: 6,
      name: "ros_neuroPsychSubDiagTraumaBrainTreat",
      type: "options_tree",
      isMulti: true,
      label: "Treatment",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Medication",
          value: "medication",
          type: "toggle_button",
        },
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "PT/OT",
          value: "PT",
          type: "toggle_button",
        },
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "DME",
          value: "DME",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "",
              value: "",
              type: "label",
              label: "Other Treatment",
              fullWidth: true,
              required: false,
              xs: 18,
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
            },
            {
              subQuestionId: 1,
              name: "ros_neuroPsychSubDiagTraumaBrainTreatOtherSubDescText",
              type: "text",
              label: "Other Treatment",
              xs: 12,
              isCommentEnabled: false,
            },
          ],
          isCommentEnabled: false,
          label: "Other Treatment",
          value: "otherTreatment",
          type: "toggle_button",
        },
        {
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "No Treatment",
          value: "noTreatment",
          type: "toggle_button",
        },
      ],
      isVisible: "ros_neuroPsychSubDiag=traumaticBrainInjury",
      questionId: 645,
    },
    {
      id: 6,
      name: "ros_neuroPsychSubDiagTraumaBrainGait",
      type: "options_tree",
      label: "Gait",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          type: "toggle_button",
        },
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Limp",
          value: "limp",
          type: "toggle_button",
        },
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Wide based",
          value: "wideBased",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Abductor Lurch",
          value: "abductorLurch",
          type: "toggle_button",
        },
        {
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Paretic",
          value: "paretic",
          type: "toggle_button",
        },
        {
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Shuffling",
          value: "shuffling",
          type: "toggle_button",
        },
        {
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Ataxic",
          value: "ataxic",
          type: "toggle_button",
        },
        {
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Other (Findings may also apply to Musculoskeletal diagnoses)",
          value: "other",
          type: "toggle_button",
        },
      ],
      isVisible: "ros_neuroPsychSubDiag=traumaticBrainInjury",
      questionId: 646,
    },
    //
    {
      id: 6,
      name: "",
      value: "",
      type: "label",
      label: "Other",
      fullWidth: true,
      required: false,
      xs: 18,
      isCommentEnabled: false,
      isConfigured: true,
      isModified: true,
      hasSubQuestions: true,
      isVisible: "ros_neuroPsychSubDiag=other",
    },
    {
      id: 6,
      name: "ros_neuroPsychSubDiagOtherSubDesc",
      type: "options_tree",
      label: "Describe",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Active",
          value: "active",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "History of",
          value: "historyOf",
          type: "toggle_button",
        },
      ],
      isVisible: "ros_neuroPsychSubDiag=other",
      questionId: 647,
    },
    {
      id: 6,
      name: "ros_neuroPsychSubDiagOtherSubSupp",
      type: "options_tree",
      label: "Supported by",
      isMulti: true,
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "History",
          value: "history",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Symptoms",
          value: "symptoms",
          type: "toggle_button",
        },
        {
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Physical findings",
          value: "physicalFindings",
          type: "toggle_button",
        },
        {
          subQuestionId: 4,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Medications",
          value: "medications",
          type: "toggle_button",
        },
        {
          subQuestionId: 5,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Test results",
          value: "testResults",
          type: "toggle_button",
        },
        {
          subQuestionId: 6,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Image studies",
          value: "imageStudies",
          type: "toggle_button",
        },
        {
          subQuestionId: 7,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Biopsy",
          value: "biopsy",
          type: "toggle_button",
        },
        {
          subQuestionId: 8,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "DME",
          value: "dme",
          type: "toggle_button",
        },
        {
          subQuestionId: 9,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "",
              value: "",
              type: "label",
              label: "Other",
              fullWidth: true,
              required: false,
              xs: 18,
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
            },
            {
              id: 1,
              name: "ros_neuroPsychSubDiagOtherSubSuppOtherSubDesc",
              type: "text",
              label: "Describe",
              xs: 4,
              isCommentEnabled: false,
              subQuestionId: 1,
            },
          ],
          isCommentEnabled: false,
          label: "Other",
          value: "other",
          type: "toggle_button",
        },
      ],
      isVisible: "ros_neuroPsychSubDiag=other",
      questionId: 648,
    },
    {
      id: 1,
      name: "ros_neuroPsychSubDiagOtherSubOther",
      type: "text",
      label: "Other",
      xs: 4,
      isCommentEnabled: false,
      isVisible: "ros_neuroPsychSubDiag=other",
      questionId: 649,
    },
    {
      id: 10,
      name: "ros_neuroRecommendations",
      label: "Recommendations",
      type: "checkbox",
      options: [
        ["Take medications as prescribed.", "takeMedications"],
        ["Case management referral for follow up.", "caseManagement"],
        ["Follow up with Clinician/Specialist.", "followUpClinician"],
        ["Member to use Durable Medical Equipment (DME).", "DME"],
        ["Other.", "other"],
      ],
      xs: 12,
      isCommentEnabled: true,
      questionId: 34,
    },
    {
      id: 9,
      name: "genitourinary",
      type: "heading",
      label: "GENITOURINARY",
      editToggle: false,
      xs: 3,
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 35,
      depth: 1,
    },
    {
      id: 6,
      name: "ros_genito",
      type: "options_tree",
      label:
        "Genitourinary Problems (Overactive Bladder, Urinary Incontinence Stress Incontinence, Benign Prostatic Hypertrophy, Others)",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 2,
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "ros_genitoSubDiagnoses",
              type: "options_tree",
              label: "Diagnoses",
              isMulti: true,
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 4,
                  subQuestionId: 1,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Acute Renal Failure",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },

                    {
                      id: 6,
                      name: "ros_genitoSubDiagnosesacuteRenalFailuredes",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_genitoSubDiagnosesdesacuteActiveSubTre",
                              type: "options_tree",
                              isMulti: true,
                              label: "Treatment",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },

                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  subQuestions: [
                                    {
                                      id: 1,
                                      name: "ros_genitoSubDiagnosesdesacuteActiveSubTreOtherSub",
                                      type: "text",
                                      label: "Other Treatment",
                                      editToggle: false,
                                      xs: 4,
                                      required: "no",
                                      placeholder: "",
                                      defaultValue: "",
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      subQuestionId: 1,
                                      depth: 9,
                                    },
                                  ],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 1,
                              editToggle: false,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "active",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of ",
                          value: "historyOf",
                          type: "toggle_button",
                          name: "historyOf",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                      depth: 5,
                    },
                    {
                      id: 6,
                      name: "ros_genitoSubDiagnosesacuteRenalFailureSup",
                      type: "options_tree",
                      label: "Findings",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Lab tests",
                          value: "labTests",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "labTests",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Calculated GFR",
                          value: "calculatedGfr",
                          type: "toggle_button",
                          name: "calculatedGfr",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Hospitalization",
                          value: "hospitalization",
                          type: "toggle_button",
                          name: "hospitalization",
                          depth: 6,
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "ros_genitoSubDiagnosesacuteRenalFailureSupotherSubVal",
                              type: "text",
                              label: "Describe",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                          name: "other",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                      depth: 5,
                    },
                    {
                      id: 1,
                      name: "ros_genitoSubDiagnosesacuteRenalFailureEtiology",
                      type: "text",
                      label: "Etiology",
                      editToggle: false,
                      xs: 4,
                      required: "no",
                      placeholder: "",
                      defaultValue: "",
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      subQuestionId: 3,
                      depth: 5,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  label: "Acute Renal Failure",
                  value: "acuteRenalFailure",
                  isConfigured: true,
                  type: "toggle_button",
                  name: "acuteRenalFailure",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "BPH",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_genitoSubDiagnosesbphDescribe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_genitoSubDiagnosesdesbhpActiveSubTre",
                              type: "options_tree",
                              label: "Treatment",
                              fullWidth: true,
                              isMulti: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },

                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  subQuestions: [
                                    {
                                      id: 1,
                                      name: "ros_genitoSubDiagnosesdesbhpActiveSubTreOtherSub",
                                      type: "text",
                                      label: "Other Treatment",
                                      editToggle: false,
                                      xs: 4,
                                      required: "no",
                                      placeholder: "",
                                      defaultValue: "",
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      subQuestionId: 1,
                                      depth: 9,
                                    },
                                  ],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 1,
                              editToggle: false,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "active",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                          name: "historyOf",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                      depth: 5,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "BPH",
                  value: "bphgenito",
                  type: "toggle_button",
                  name: "bphgenito",
                  depth: 4,
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Chronic Kidney Disease",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_genitoSubDiagnoseschronicKidneyDiseaseDescribe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_genitoSubDiagnosesdeschronicActiveSubSec",
                              type: "options_tree",
                              label: "Secondary to Hypertension",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Yes",
                                  value: "yes",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },

                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "No",
                                  value: "no",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Unknown",
                                  value: "unknown",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 1,
                              editToggle: false,
                              depth: 7,
                            },
                            {
                              id: 6,
                              name: "ros_genitoSubDiagnosesdeschronicActiveSubTre",
                              type: "options_tree",
                              label: "Treatment",
                              isMulti: true,
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },

                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  subQuestions: [
                                    {
                                      id: 1,
                                      name: "ros_genitoSubDiagnosesdeschroActiveSubTreOtherSub",
                                      type: "text",
                                      label: "Other Treatment",
                                      editToggle: false,
                                      xs: 4,
                                      required: "no",
                                      placeholder: "",
                                      defaultValue: "",
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      subQuestionId: 1,
                                      depth: 9,
                                    },
                                  ],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 2,
                              editToggle: false,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "active",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                          name: "historyOf",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                      depth: 5,
                    },
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "options_tree",
                      label: "What stage",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 11,
                      name: "stagesOfKidneyDisease",
                      label: "Stages of Kidney Disease",
                      editToggle: false,
                      type: "datatable",
                      tablelist: [
                        [
                          {
                            id: 1,
                            label: "Allergies",
                            name: "HRAForm_Allergies",
                            columns: [
                              {
                                label: "Substance",
                                type: "text",
                              },
                              {
                                label: "Reaction",
                                type: "text",
                              },
                            ],
                            isReadOnly: false,
                          },
                          false,
                        ],
                        [
                          {
                            id: 2,
                            label: "BMI Index Chart",
                            name: "HRAForm_BMI_Index_Chart",
                            columns: [
                              {
                                label: "BMI",
                                type: "text",
                              },
                            ],
                            isReadOnly: true,
                          },
                          false,
                        ],
                        [
                          {
                            id: 3,
                            label: "Counter Medications",
                            name: "HRAForm_Counter_Medications",
                            columns: [
                              {
                                label: "Date",
                                type: "date",
                              },
                              {
                                label: "Description",
                                type: "text",
                              },
                              {
                                label: "Dose Per Units",
                                type: "text",
                              },
                              {
                                label: "Route",
                                type: "select",
                              },
                              {
                                label: "Frequency",
                                type: "text",
                              },
                            ],
                            isReadOnly: false,
                          },
                          false,
                        ],
                        [
                          {
                            id: 4,
                            label: "Medical History Specialists",
                            name: "HRAForm_Medical_History_Speciality",
                            columns: [
                              {
                                label: "Member Specialist",
                                type: "select",
                              },
                              {
                                label: "Speciality",
                                type: "text",
                              },
                              {
                                label: "For",
                                type: "text",
                              },
                            ],
                            isReadOnly: false,
                          },
                          false,
                        ],
                        [
                          {
                            id: 5,
                            label: "Medications",
                            name: "HRAForm_Medications",
                            columns: [
                              {
                                label: "Diagnoses",
                                type: "text",
                              },
                              {
                                label: "label Name",
                                type: "select",
                              },
                              {
                                label: "Dose Per Units",
                                type: "text",
                              },
                              {
                                label: "Route",
                                type: "select",
                              },
                              {
                                label: "Frequency",
                                type: "select",
                              },
                              {
                                label: "Prescribing Physician",
                                type: "text",
                              },
                              {
                                label: "Status",
                                type: "togglebutton",
                              },
                            ],
                            isReadOnly: false,
                          },
                          false,
                        ],
                        [
                          {
                            id: 6,
                            label: "Mini Cog Versions",
                            name: "HRAForm_Minicog_Version",
                            columns: [
                              {
                                label: "Version 1",
                                type: "text",
                              },
                              {
                                label: "Version 2",
                                type: "text",
                              },
                              {
                                label: "Version 3",
                                type: "text",
                              },
                              {
                                label: "Version 4",
                                type: "text",
                              },
                              {
                                label: "Version 5",
                                type: "text",
                              },
                              {
                                label: "Version 6",
                                type: "text",
                              },
                            ],
                            isReadOnly: true,
                          },
                          false,
                        ],
                        [
                          {
                            id: 7,
                            label: "Preventive Care",
                            name: "HRAForm_Preventive_Care",
                            columns: [
                              {
                                label: "Screen",
                                type: "text",
                              },
                              {
                                label: "Answer",
                                type: "select",
                              },
                              {
                                label: "Date",
                                type: "text",
                              },
                              {
                                label: "Method",
                                type: "text",
                              },
                              {
                                label: "Recommendation",
                                type: "checkbox",
                              },
                              {
                                label: "Education Completed",
                                type: "checkbox",
                              },
                            ],
                            isReadOnly: true,
                          },
                          false,
                        ],
                        [
                          {
                            id: 8,
                            label: "Previously Documented Conditions",
                            name: "HRAForm_Previously_Documented_Conditions",
                            columns: [
                              {
                                label: "Diagnosis",
                                type: "text",
                              },
                              {
                                label: "Prior HCC",
                                type: "text",
                              },
                              {
                                label: "Diagnosis Code",
                                type: "text",
                              },
                              {
                                label: "Date of Service",
                                type: "date",
                              },
                            ],
                            isReadOnly: false,
                          },
                          false,
                        ],
                        [
                          {
                            id: 9,
                            label: "Prior Assessments",
                            name: "HRAForm_Prior_Assessments",
                            columns: [
                              {
                                label: "Date Time",
                                type: "date",
                              },
                              {
                                label: "Document",
                                type: "button",
                              },
                            ],
                            isReadOnly: true,
                          },
                          false,
                        ],
                        [
                          {
                            id: 10,
                            label: "Stages of Kidney Disease",
                            name: "Stages of Kidney Disease",
                            columns: [
                              {
                                label: "Stages",
                                type: "text",
                              },
                            ],
                            isReadOnly: true,
                          },
                          true,
                        ],
                        [
                          {
                            id: 11,
                            label: "Alcohol Usage",
                            name: "HRAForm_Alcohol_Usage",
                            columns: [
                              {
                                label: "How many drinks",
                                type: "select",
                              },
                              {
                                label: "How often",
                                type: "select",
                              },
                            ],
                            isReadOnly: true,
                          },
                          false,
                        ],
                        [
                          {
                            id: 12,
                            name: "Family History",
                            columns: [
                              {
                                label: "Family Member",
                                type: "select",
                              },
                              {
                                label: "Medical Condition",
                                type: "text",
                              },
                              {
                                label: "Cause of Death",
                                type: "text",
                              },
                            ],
                            isReadOnly: false,
                          },
                          false,
                        ],
                        [
                          {
                            id: 13,
                            label: "Depression Severity",
                            name: "Depression_Severity",
                            columns: [
                              {
                                label: "Score",
                                type: "number",
                              },
                              {
                                label: "Depression Severity",
                                type: "checkbox",
                              },
                            ],
                            isReadOnly: true,
                          },
                          false,
                        ],
                        [
                          {
                            id: 14,
                            label: "Word Recall",
                            name: "Word_Recall",
                            columns: [
                              {
                                label: "Word Recall",
                                type: "text",
                              },
                              {
                                label: "Points",
                                type: "select",
                              },
                              {
                                label: "Description",
                                type: "text",
                              },
                            ],
                            isReadOnly: true,
                          },
                          false,
                        ],
                      ],
                      xs: 12,
                      required: "no",
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      subQuestionId: 3,
                      depth: 5,
                    },
                    {
                      id: 6,
                      name: "ros_genitoSubDiagnoseswhatStage",
                      type: "options_tree",
                      // label: "What stage",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [{}],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Stage 1",
                          value: "stage1",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "stage1",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Stage 2",
                          value: "stage2",
                          type: "toggle_button",
                          name: "stage2",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Stage 3a",
                          value: "stage3A",
                          type: "toggle_button",
                          name: "stage3A",
                          depth: 6,
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Stage 3b",
                          value: "stage3B",
                          type: "toggle_button",
                          name: "stage3B",
                          depth: 6,
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Stage 4",
                          value: "stage4",
                          type: "toggle_button",
                          name: "stage4",
                          depth: 6,
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Stage 5",
                          value: "stage5",
                          type: "toggle_button",
                          name: "stage5",
                          depth: 6,
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Unknown",
                          value: "unknown",
                          type: "toggle_button",
                          name: "unknown",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 4,
                      editToggle: false,
                      depth: 5,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Chronic Kidney Disease",
                  value: "chronicKidneyDisease",
                  type: "toggle_button",
                  name: "chronicKidneyDisease",
                  depth: 4,
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "ESRD",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_genitoSubDiagnosesesrdDescribe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_genitoSubDiagnosesdesesrdActiveSubTre",
                              type: "options_tree",
                              label: "Treatment",
                              isMulti: true,
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  subQuestionId: 1,
                                  hasSubQuestions: true,
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "ros_genitoSubDiagnosesesrdPatientOnDialysisType",
                                      type: "options_tree",
                                      label: "Type",
                                      fullWidth: true,
                                      required: true,
                                      xs: 12,
                                      isCommentEnabled: true,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                      subQuestions: [
                                        {
                                          depth: 8,
                                          subQuestionId: 1,
                                          hasSubQuestions: false,
                                          subQuestions: [],
                                          editToggle: false,
                                          isCommentEnabled: false,
                                          label: "Hemodialysis",
                                          value: "hemodialysis",
                                          isConfigured: true,
                                          type: "toggle_button",
                                          name: "hemodialysis",
                                        },
                                        {
                                          subQuestionId: 2,
                                          hasSubQuestions: false,
                                          subQuestions: [],
                                          editToggle: false,
                                          isCommentEnabled: false,
                                          isConfigured: true,
                                          isModified: false,
                                          label: "Peritoneal",
                                          value: "peritonealDialysis",
                                          type: "toggle_button",
                                          name: "peritonealDialysis",
                                          depth: 8,
                                        },
                                      ],
                                      subQuestionId: 1,
                                      editToggle: false,
                                      depth: 7,
                                    },
                                    {
                                      id: 6,
                                      name: "ros_genitoSubDiagnosesesrdPatientOnDialysisLocation",
                                      type: "options_tree",
                                      label: "Location",
                                      fullWidth: true,
                                      required: true,
                                      xs: 12,
                                      isCommentEnabled: true,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                      subQuestions: [
                                        {
                                          depth: 8,
                                          subQuestionId: 1,
                                          hasSubQuestions: false,
                                          subQuestions: [],
                                          editToggle: false,
                                          isCommentEnabled: false,
                                          label: "In Home",
                                          value: "inHome",
                                          isConfigured: true,
                                          type: "toggle_button",
                                          name: "inHome",
                                        },
                                        {
                                          subQuestionId: 2,
                                          hasSubQuestions: false,
                                          subQuestions: [],
                                          editToggle: false,
                                          isCommentEnabled: false,
                                          isConfigured: true,
                                          isModified: false,
                                          label: "Dialysis Center",
                                          value: "dialysisCenter",
                                          type: "toggle_button",
                                          name: "dialysisCenter",
                                          depth: 8,
                                        },
                                      ],
                                      subQuestionId: 2,
                                      editToggle: false,
                                      depth: 7,
                                    },
                                    {
                                      id: 1,
                                      name: "ros_genitoSubDiagnosesesrddialysisSchedule",
                                      type: "text",
                                      label: "Dialysis schedule",
                                      editToggle: false,
                                      xs: 4,
                                      required: "no",
                                      placeholder: "",
                                      defaultValue: "",
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      subQuestionId: 3,
                                      depth: 7,
                                    },
                                    {
                                      id: 1,
                                      name: "ros_genitoSubDiagnosesesrdtypeAndLocationOfAccessDevice",
                                      type: "text",
                                      label:
                                        "Type and location of access device",
                                      editToggle: false,
                                      xs: 4,
                                      required: "no",
                                      placeholder: "",
                                      defaultValue: "",
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      subQuestionId: 4,
                                      depth: 7,
                                    },
                                  ],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Dialysis",
                                  value: "dialysis",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Medication",
                                  value: "medication",
                                  type: "toggle_button",
                                  depth: 8,
                                },

                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: true,
                                  subQuestions: [
                                    {
                                      id: 1,
                                      name: "ros_genitoSubDiagnoActiveSubTreOtherSub",
                                      type: "text",
                                      label: "Other Treatment",
                                      editToggle: false,
                                      xs: 4,
                                      required: "no",
                                      placeholder: "",
                                      defaultValue: "",
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      subQuestionId: 1,
                                      depth: 9,
                                    },
                                  ],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 4,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 1,
                              editToggle: false,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "active",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                          name: "historyOf",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                      depth: 5,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "ESRD",
                  value: "esrd",
                  type: "toggle_button",
                  name: "esrd",
                  depth: 4,
                },
                {
                  subQuestionId: 5,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Erectile Dysfunction",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_genitoSubDiagnoseserectileDysfunctionDescribe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_genitoSubDiagnosesdeserectileActiveSubTre",
                              type: "options_tree",
                              label: "Treatment",
                              isMulti: true,
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },

                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  subQuestions: [
                                    {
                                      id: 1,
                                      name: "ros_genitoSubDiagnosesdesErectileTreOtherSub",
                                      type: "text",
                                      label: "Other Treatment",
                                      editToggle: false,
                                      xs: 4,
                                      required: "no",
                                      placeholder: "",
                                      defaultValue: "",
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      subQuestionId: 1,
                                      depth: 9,
                                    },
                                  ],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 1,
                              editToggle: false,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "active",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of ",
                          value: "historyOf",
                          type: "toggle_button",
                          name: "historyOf",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                      depth: 5,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Erectile Dysfunction",
                  value: "erectileDysfunction",
                  type: "toggle_button",
                  name: "erectileDysfunction",
                  depth: 4,
                },
                {
                  subQuestionId: 6,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Frequent UTI",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_genitoSubDiagnosesfrequentUtiDescribe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_genitoSubDiagnosesdesfreqsActiveSubTre",
                              type: "options_tree",
                              label: "Treatment",
                              isMulti: true,
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },

                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  subQuestions: [
                                    {
                                      id: 1,
                                      name: "ros_genitoSubDiagnosesdesfreqActiveSubTreOtherSub",
                                      type: "text",
                                      label: "Other Treatment",
                                      editToggle: false,
                                      xs: 4,
                                      required: "no",
                                      placeholder: "",
                                      defaultValue: "",
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      subQuestionId: 1,
                                      depth: 9,
                                    },
                                  ],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 1,
                              editToggle: false,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "active",
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                          name: "historyOf",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 1,
                      depth: 5,
                      editToggle: false,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Frequent UTI",
                  value: "frequentUti",
                  type: "toggle_button",
                  name: "frequentUti",
                  depth: 4,
                },
                {
                  subQuestionId: 7,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Gynecological",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_genitoSubDiagnosesgynecologicalDescribe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_genitoSubDiagnosesdesgynecoActiveSubTre",
                              type: "options_tree",
                              isMulti: true,
                              label: "Treatment",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },

                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  subQuestions: [
                                    {
                                      id: 1,
                                      name: "ros_genitoSubDiagnosesdesgynecoActiveSubTreOtherSub",
                                      type: "text",
                                      label: "Other Treatment",
                                      editToggle: false,
                                      xs: 4,
                                      required: "no",
                                      placeholder: "",
                                      defaultValue: "",
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      subQuestionId: 1,
                                      depth: 9,
                                    },
                                  ],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 1,
                              editToggle: false,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "active",
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [{}],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                          name: "historyOf",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 1,
                      depth: 5,
                      editToggle: false,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Gynecological",
                  value: "gynecological",
                  type: "toggle_button",
                  name: "gynecological",
                  depth: 4,
                },
                {
                  subQuestionId: 8,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Kidney Stones",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_genitoSubDiagnoseskidneyStonesDescribe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_genitoSubDiagnosesdeskidneyActiveSubTre",
                              type: "options_tree",
                              isMulti: true,
                              label: "Treatment",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },

                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  subQuestions: [
                                    {
                                      id: 1,
                                      name: "ros_genitoSubDiagnosesdeskidneyActiveSubTreOtherSub",
                                      type: "text",
                                      label: "Other Treatment",
                                      editToggle: false,
                                      xs: 4,
                                      required: "no",
                                      placeholder: "",
                                      defaultValue: "",
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      subQuestionId: 1,
                                      depth: 9,
                                    },
                                  ],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 1,
                              editToggle: false,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "active",
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                          name: "historyOf",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 1,
                      depth: 5,
                      editToggle: false,
                    },

                    {
                      id: 6,
                      name: "ros_genitoSubDiagnoseskidneyStonesType",
                      type: "options_tree",
                      label: "Type",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "urate",
                          label: "Urate",
                          value: "urate",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Calcium Oxalate",
                          value: "calciumOxalate",
                          type: "toggle_button",
                          name: "calciumOxalate",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Magnesium",
                          value: "magnesium",
                          type: "toggle_button",
                          name: "magnesium",
                          depth: 6,
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                          name: "other",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 3,
                      depth: 5,
                      editToggle: false,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Kidney Stones",
                  value: "kidneyStonesgenito",
                  type: "toggle_button",
                  name: "kidneyStonesgenito",
                  depth: 4,
                },
                {
                  subQuestionId: 9,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Nephritis or Nephrosis",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_genitoSubDiagnosesnephritisOrNephrosisDescribe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_genitoSubDiagnosesdesNephrosisActiveSubTre",
                              type: "options_tree",
                              isMulti: true,
                              label: "Treatment",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },

                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  subQuestions: [
                                    {
                                      id: 1,
                                      name: "ros_genitoSubDiagnosesdesNephrosisActiveSubTreOtherSub",
                                      type: "text",
                                      label: "Other Treatment",
                                      editToggle: false,
                                      xs: 4,
                                      required: "no",
                                      placeholder: "",
                                      defaultValue: "",
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      subQuestionId: 1,
                                      depth: 9,
                                    },
                                  ],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 1,
                              editToggle: false,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "active",
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                          name: "historyOf",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 1,
                      depth: 5,
                      editToggle: false,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Nephritis or Nephrosis",
                  value: "nephritisOrNephrosis",
                  type: "toggle_button",
                  name: "nephritisOrNephrosis",
                  depth: 4,
                },
                {
                  subQuestionId: 10,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Urinary Incontinence",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_genitoSubDiagnosesurinaryIncontinenceDescribe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_genitoSubDiagnosesdesUrinaryActiveSubTre",
                              type: "options_tree",
                              label: "Treatment",
                              isMulti: true,
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },

                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  subQuestions: [
                                    {
                                      id: 1,
                                      name: "ros_genitoSubDiagnosesdesurinaryActiveSubTreOtherSub",
                                      type: "text",
                                      label: "Other Treatment",
                                      editToggle: false,
                                      xs: 4,
                                      required: "no",
                                      placeholder: "",
                                      defaultValue: "",
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      subQuestionId: 1,
                                      depth: 9,
                                    },
                                  ],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 1,
                              editToggle: false,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "active",
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                          name: "historyOf",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 1,
                      depth: 5,
                      editToggle: false,
                    },

                    {
                      id: 6,
                      name: "ros_genitoStress",
                      type: "options_tree",
                      label: "Related to stress",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_genitoStressSub",
                              type: "options_tree",
                              label: "Describe",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 6,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Dribbling",
                                  value: "dribbling",
                                  isConfigured: true,
                                  type: "toggle_button",
                                  name: "dribbling",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Urgency",
                                  value: "urgency",
                                  type: "toggle_button",
                                  name: "urgency",
                                  depth: 6,
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Other",
                                  value: "other",
                                  type: "toggle_button",
                                  name: "other",
                                  depth: 6,
                                },
                              ],
                              subQuestionId: 1,
                              editToggle: false,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "yes",
                          label: "Yes",
                          value: "yes",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                          name: "no",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 3,
                      depth: 5,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_genitoSubDiagnosesurinaryIncontinenceUiDescribe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "daily",
                          label: "Daily",
                          value: "daily",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Few times a week",
                          value: "fewTimesAWeek",
                          type: "toggle_button",
                          name: "fewTimesAWeek",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Less than once a week",
                          value: "lessThanOnceAWeek",
                          type: "toggle_button",
                          name: "lessThanOnceAWeek",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 4,
                      depth: 5,
                      editToggle: false,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Urinary Incontinence",
                  value: "urinaryIncontinence",
                  type: "toggle_button",
                  name: "urinaryIncontinence",
                  depth: 4,
                },
                {
                  subQuestionId: 11,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Other",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_genitoSubDiagnosesotherDescribe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_genitoSubDiagnosesdesOtherActiveSubTre",
                              type: "options_tree",
                              label: "Treatment",
                              isMulti: true,
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },

                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  subQuestions: [
                                    {
                                      id: 1,
                                      name: "ros_genitoSubDiagnosesdesOtherActiveSubTreOtherSub",
                                      type: "text",
                                      label: "Other Treatment",
                                      editToggle: false,
                                      xs: 4,
                                      required: "no",
                                      placeholder: "",
                                      defaultValue: "",
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      subQuestionId: 1,
                                      depth: 9,
                                    },
                                  ],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 1,
                              editToggle: false,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "active",
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of ",
                          value: "historyOf",
                          type: "toggle_button",
                          name: "historyOf",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 1,
                      depth: 5,
                      editToggle: false,
                    },

                    {
                      id: 1,
                      name: "ros_genitoSubDiagnosesotherOther",
                      type: "text",
                      label: "Other",
                      editToggle: false,
                      xs: 4,
                      required: "no",
                      placeholder: "",
                      defaultValue: "",
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      subQuestionId: 3,
                      depth: 5,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Other",
                  value: "other",
                  type: "toggle_button",
                  name: "other",
                  depth: 4,
                },
              ],
              subQuestionId: 1,
              editToggle: false,
              depth: 3,
            },
            {
              id: 6,
              name: "ros_genitoSubSymptoms",
              type: "options_tree",
              label: "Symptoms",
              fullWidth: true,
              isMulti: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 6,
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  name: "urinaryFrequency",
                  label: "Urinary Frequency",
                  value: "urinaryFrequency",
                  isConfigured: true,
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Pain / Burning with urination",
                  value: "pain/burningwithurination",
                  type: "toggle_button",
                  name: "pain/burningwithurination",
                  depth: 6,
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Abnormal Vaginal Bleeding",
                  value: "abdominalVaginalBleed",
                  type: "toggle_button",
                  name: "abdominalVaginalBleed",
                  depth: 6,
                },

                {
                  subQuestionId: 4,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 1,
                      name: "ros_genitoSubSymptomsOtherSub",
                      type: "text",
                      label: "Other",
                      editToggle: false,
                      xs: 4,
                      required: "no",
                      placeholder: "",
                      defaultValue: "",
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      subQuestionId: 1,
                      depth: 7,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Other",
                  value: "other",
                  type: "toggle_button",
                  name: "other",
                  depth: 6,
                },

                {
                  subQuestionId: 5,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "None",
                  value: "none",
                  type: "toggle_button",
                  name: "none",
                  depth: 6,
                },
              ],
              subQuestionId: 2,
              depth: 5,
              editToggle: false,
            },
          ],
          editToggle: false,
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          isConfigured: true,
          type: "toggle_button",
          name: "yes",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "No",
          value: "no",
          type: "toggle_button",
          name: "no",
          depth: 2,
        },
      ],
      questionId: 36,
      editToggle: false,
      depth: 1,
    },
    {
      id: 10,
      name: "ros_genitoRecommendations",
      label: "Recommendations",
      placeholder: "",
      editToggle: false,
      defaultValue: "",
      type: "checkbox",
      color: "primary",
      options: [
        [
          "Take medications as prescribed.",
          "takeMedicationsAsPrescribedGenito",
        ],
        ["Case management referral for followup.", "casemanagementrefGenito"],
        [
          "Follow up with Clinician/Specialists.",
          "followupwithclinispecGenito",
        ],
        [
          "Member to use Durable Medical Equipment(DME).",
          "memberDurableGenito",
        ],
        ["Other.", "otherGenito"],
      ],
      xs: 12,
      required: "no",
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      questionId: 37,
      depth: 1,
    },
    {
      id: 10,
      name: "musculoskeletal",
      type: "heading",
      label: "MUSCULOSKELETAL",
      editToggle: false,
      xs: 12,
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 38,
    },
    {
      id: 8,
      name: "ros_musculoskeletal",
      type: "options_tree",
      label:
        "Musculoskeletal Problems (Spinal Stenosis, Rheumatoid Arthritis, Gout, Osteoporosis, Others)",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 1,
          subQuestionId: 1,
          hasSubQuestions: true,
          editToggle: false,
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          isConfigured: true,
          type: "toggle_button",
          subQuestions: [
            {
              id: 6,
              name: "ros_musculoskeletalDiagnoses",
              value: "diagnoses",
              type: "options_tree",
              isMulti: true,
              label: "Diagnoses",
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 1,
                  subQuestionId: 1,
                  name: "ros_musculoskeletalDermatomyositisSubAnswer",
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  label: "Dermatomyositis",
                  value: "dermatomyositis",
                  isConfigured: true,
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Dermatomyositis",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_musculoskeletalDermatomyositisSubAnswerDescribe",
                      value: "describe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          subQuestions: [
                            {
                              depth: 2,
                              hasSubQuestions: true,
                              editToggle: false,
                              subQuestionId: 1,
                              isCommentEnabled: true,
                              label: "Treatment",
                              isMulti: true,
                              value: "activeTreatmentSubAnswer",
                              name: "ros_musculoskeletalDermatomyositisActiveTreatmentSubAnswer",
                              isConfigured: true,
                              type: "options_tree",
                              subQuestions: [
                                {
                                  depth: 2,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  name: "ros_musculoskeletalDermatomyositisSubOtherTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label: "Other Treatment",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      depth: 1,
                                      subQuestionId: 1,
                                      editToggle: false,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: false,
                                      label: "Other Treatment",
                                      xs: 12,
                                      name: "ros_musculoskeletalDermatomyositisSubOtherTreatmentSubDesc",
                                      value: "describe",
                                      type: "text",
                                    },
                                  ],
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Degenerative Disc Disease",
                  name: "ros_musculoskeletalDegenerativeDiscDiseaseSubAnswer",
                  value: "degenerativeDiscDisease",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Degenerative Disc Disease",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerDescribe",
                      type: "options_tree",
                      value: "describe",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerSiteOfDisease",
                              value: "siteOfDisease",
                              type: "options_tree",
                              label: "Site of disease",
                              fullWidth: true,
                              required: true,
                              isMulti: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 1,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Cervical",
                                  value: "cervical",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 1,
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Thoracic",
                                  value: "thoracic",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 1,
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Lumbar",
                                  value: "lumbar",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 1,
                                  subQuestionId: 4,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Lumbosacral",
                                  value: "lumbosacral",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 1,
                                  subQuestionId: 5,
                                  hasSubQuestions: true,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Other",
                                  value: "other",
                                  isConfigured: true,
                                  type: "toggle_button",
                                  name: "ros_musculoskeletalDegenerativeDiscDiseaseSubSiteOfDiseaseSubOther",
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label: "Other",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      depth: 2,
                                      subQuestionId: 1,
                                      hasSubQuestions: true,
                                      editToggle: false,
                                      isCommentEnabled: false,
                                      label: "Describe",
                                      value: "describe",
                                      name: "ros_musculoskeletalDegenerativeDiscDiseaseSubSiteOfDiseaseSubOtherSubDesc",
                                      isConfigured: true,
                                      type: "text",
                                      xs: 12,
                                    },
                                  ],
                                },
                              ],
                              subQuestionId: 1,
                              editToggle: false,
                            },
                            {
                              id: 6,
                              name: "ros_musculoskeletalDegenerativeDiscDiseaseSubAnswerNormalBladder",
                              value: "normalBladder",
                              type: "options_tree",
                              label: "Abnormal Bowel and Bladder?",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 1,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Yes",
                                  value: "yes",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 1,
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "No",
                                  value: "no",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                              subQuestionId: 2,
                              editToggle: false,
                            },
                            {
                              depth: 2,
                              hasSubQuestions: true,
                              editToggle: false,
                              subQuestionId: 3,
                              isCommentEnabled: true,
                              label: "Treatment",
                              isMulti: true,
                              value: "activeTreatmentSubAnswer",
                              name: "ros_musculoskeletalDegenerativeDiscDiseaseActiveTreatmentSubAnswer",
                              isConfigured: true,
                              type: "options_tree",
                              subQuestions: [
                                {
                                  depth: 2,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  name: "ros_musculoskeletalDegenerativeDiscDiseaseSubOtherTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label: "Other Treatment",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      depth: 1,
                                      subQuestionId: 1,
                                      editToggle: false,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: false,
                                      label: "Other Treatment",
                                      xs: 12,
                                      name: "ros_musculoskeletalDegenerativeDiscDiseaseSubOtherTreatmentSubDesc",
                                      value: "describe",
                                      type: "text",
                                    },
                                  ],
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Extremity Fracture",
                  value: "extremityFracture",
                  name: "ros_musculoskeletalExtremityFractureSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Extremity Fracture",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_musculoskeletalExtremityFractureSubAnswerDescribe",
                      value: "describe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          subQuestions: [
                            {
                              depth: 2,
                              hasSubQuestions: true,
                              editToggle: false,
                              subQuestionId: 1,
                              isCommentEnabled: true,
                              label: "Type",
                              value: "activeTypeSubAnswer",
                              name: "ros_musculoskeletalExtremityFractureActiveTypeSubAnswer",
                              isConfigured: true,
                              type: "options_tree",
                              subQuestions: [
                                {
                                  depth: 2,
                                  subQuestionId: 1,
                                  hasSubQuestions: true,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Traumatic",
                                  value: "traumatic",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Pathological",
                                  value: "pathological",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                            {
                              depth: 2,
                              hasSubQuestions: true,
                              editToggle: false,
                              subQuestionId: 2,
                              isCommentEnabled: true,
                              label: "Treatment",
                              isMulti: true,
                              value: "activeTreatmentSubAnswer",
                              name: "ros_musculoskeletalExtremityFractureActiveTreatmentSubAnswer",
                              isConfigured: true,
                              type: "options_tree",
                              subQuestions: [
                                {
                                  depth: 2,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  name: "ros_musculoskeletalExtremityFractureSubOtherTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label: "Other Treatment",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      depth: 1,
                                      subQuestionId: 1,
                                      editToggle: false,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: false,
                                      label: "Other Treatment",
                                      xs: 12,
                                      name: "ros_musculoskeletalExtremityFractureSubOtherTreatmentSubDesc",
                                      value: "describe",
                                      type: "text",
                                    },
                                  ],
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                          subQuestions: [
                            {
                              depth: 2,
                              editToggle: false,
                              isCommentEnabled: true,
                              label: "Type",
                              value: "historyOfSubAnswer",
                              name: "ros_musculoskeletalExtremityFractureHistoryOfSubAnswer",
                              isConfigured: true,
                              type: "options_tree",
                              xs: 12,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 2,
                                  subQuestionId: 1,
                                  hasSubQuestions: true,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Traumatic",
                                  value: "traumatic",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Pathological",
                                  value: "pathological",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_musculoskeletalExtremityFractureSubAnswerExtremity",
                      type: "options_tree",
                      value: "extremity",
                      label: "Extremity",
                      fullWidth: true,
                      required: true,
                      isMulti: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Shoulder ",
                          value: "shoulder",
                          isConfigured: true,
                          type: "toggle_button",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Shoulder",
                              fullWidth: true,
                              required: false,
                              xs: 18,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 6,
                              name: "ros_musculoskeletalExtremityFractureShoulderSubAnswer",
                              value: "shoulderSubAnswer",
                              type: "options_tree",
                              label: "Laterality",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 2,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 1,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Left",
                                  value: "left",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 1,
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Right",
                                  value: "right",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          depth: 1,
                          subQuestionId: 2,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Arm",
                          value: "arm",
                          isConfigured: true,
                          type: "toggle_button",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Arm",
                              fullWidth: true,
                              required: false,
                              xs: 18,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 6,
                              name: "ros_musculoskeletalExtremityFractureArmSubAnswer",
                              value: "armSubAnswer",
                              type: "options_tree",
                              label: "Laterality",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              subQuestionId: 2,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 1,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Left",
                                  value: "left",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 1,
                                  subQuestionId: 2,
                                  name: "ros_",
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Right",
                                  value: "right",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          depth: 1,
                          subQuestionId: 3,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Forearm",
                          value: "forearm",
                          isConfigured: true,
                          type: "toggle_button",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Forearm",
                              fullWidth: true,
                              required: false,
                              xs: 18,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 6,
                              name: "ros_musculoskeletalExtremityFractureForearmSubAnswer",
                              value: "forearmSubAnswer",
                              type: "options_tree",
                              label: "Laterality",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              subQuestionId: 2,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 1,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Left",
                                  value: "left",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 1,
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Right",
                                  value: "right",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          depth: 1,
                          subQuestionId: 4,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Wrist",
                          value: "wrist",
                          isConfigured: true,
                          type: "toggle_button",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Wrist",
                              fullWidth: true,
                              required: false,
                              xs: 18,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 6,
                              name: "ros_musculoskeletalExtremityFractureWristSubAnswer",
                              value: "wristSubAnswer",
                              type: "options_tree",
                              label: "Laterality",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              subQuestionId: 2,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 1,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Left",
                                  value: "left",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 1,
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Right",
                                  value: "right",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          depth: 1,
                          subQuestionId: 5,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Hand",
                          value: "hand",
                          isConfigured: true,
                          type: "toggle_button",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Hand",
                              fullWidth: true,
                              required: false,
                              xs: 18,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 6,
                              name: "ros_musculoskeletalHandSubAnswer",
                              value: "extremityFractureHandSubAnswer",
                              type: "options_tree",
                              label: "Laterality",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              subQuestionId: 2,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 1,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Left",
                                  value: "left",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 1,
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Right",
                                  value: "right",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          depth: 1,
                          subQuestionId: 6,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Femoral Shaft",
                          value: "femoralShaft",
                          isConfigured: true,
                          type: "toggle_button",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Femoral Shaft",
                              fullWidth: true,
                              required: false,
                              xs: 18,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 6,
                              name: "ros_musculoskeletalFemoralShaftSubAnswer",
                              value: "extremityFractureFemoralShaftSubAnswer",
                              type: "options_tree",
                              label: "Laterality",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              subQuestionId: 2,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 1,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Left",
                                  value: "left",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 1,
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Right",
                                  value: "right",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          depth: 1,
                          subQuestionId: 7,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Tibia",
                          value: "tibia",
                          isConfigured: true,
                          type: "toggle_button",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Tibia",
                              fullWidth: true,
                              required: false,
                              xs: 18,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 6,
                              name: "ros_musculoskeletalExtremityFractureTibiaSubAnswer",
                              value: "tibiaSubAnswer",
                              type: "options_tree",
                              label: "Laterality",
                              fullWidth: true,
                              subQuestionId: 2,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 1,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Left",
                                  value: "left",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 1,
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Right",
                                  value: "right",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          depth: 1,
                          subQuestionId: 8,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Fibula",
                          value: "fibula",
                          isConfigured: true,
                          type: "toggle_button",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Fibula",
                              fullWidth: true,
                              required: false,
                              xs: 18,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 6,
                              name: "ros_musculoskeletalExtremityFractureFibulaSubAnswer",
                              value: "fibulaSubAnswer",
                              type: "options_tree",
                              label: "Laterality",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              subQuestionId: 2,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 1,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Left",
                                  value: "left",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 1,
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Right",
                                  value: "right",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          depth: 1,
                          subQuestionId: 9,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Ankle",
                          value: "ankle",
                          isConfigured: true,
                          type: "toggle_button",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Ankle",
                              fullWidth: true,
                              required: false,
                              xs: 18,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 6,
                              name: "ros_musculoskeletalExtremityFractureAnkleSubAnswer",
                              value: "ankleSubAnswer",
                              type: "options_tree",
                              subQuestionId: 2,
                              label: "Laterality",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 1,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Left",
                                  value: "left",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 1,
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Right",
                                  value: "right",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          depth: 1,
                          subQuestionId: 10,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Foot",
                          value: "foot",
                          isConfigured: true,
                          type: "toggle_button",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Foot",
                              fullWidth: true,
                              required: false,
                              xs: 18,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 6,
                              name: "ros_musculoskeletalExtremityFractureFootSubAnswer",
                              value: "footSubAnswer",
                              type: "options_tree",
                              subQuestionId: 2,
                              label: "Laterality",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 1,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Left",
                                  value: "left",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 1,
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Right",
                                  value: "right",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          depth: 1,
                          subQuestionId: 11,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Hip",
                          value: "hip",
                          isConfigured: true,
                          type: "toggle_button",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Hip",
                              fullWidth: true,
                              required: false,
                              xs: 18,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              id: 6,
                              name: "ros_musculoskeletalExtremityFractureHipSubAnswer",
                              value: "hipSubAnswer",
                              type: "options_tree",
                              subQuestionId: 2,
                              label: "Laterality",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 1,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Left",
                                  value: "left",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 1,
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Right",
                                  value: "right",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                        },
                      ],
                      subQuestionId: 3,
                      editToggle: false,
                    },
                    {
                      id: 3,
                      name: "ros_musculoskeletalExtremityFractureCurrentWithinTwelveWeeksSubAnswer",
                      type: "options_tree",
                      value: "currentWithinTwelveWeeks",
                      label: "Current (within 12 weeks)",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Yes",
                          value: "yes",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 4,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Gout",
                  value: "gout",
                  name: "ros_goutSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Gout",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_musculoskeletalGoutSubAnswerDescribe",
                      value: "describe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          subQuestions: [
                            {
                              depth: 2,
                              hasSubQuestions: true,
                              editToggle: false,
                              subQuestionId: 1,
                              isCommentEnabled: true,
                              label: "Treatment",
                              isMulti: true,
                              value: "activeTreatmentSubAnswer",
                              name: "ros_musculoskeletalGoutActiveTreatmentSubAnswer",
                              isConfigured: true,
                              type: "options_tree",
                              subQuestions: [
                                {
                                  depth: 2,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  name: "ros_musculoskeletalGoutSubOtherTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label: "Other Treatment",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      depth: 1,
                                      subQuestionId: 1,
                                      editToggle: false,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: false,
                                      label: "Other Treatment",
                                      xs: 12,
                                      name: "ros_musculoskeletalGoutSubOtherTreatmentSubDesc",
                                      value: "describe",
                                      type: "text",
                                    },
                                  ],
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 5,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Hallux Valgus",
                  value: "halluxValgus",
                  name: "ros_musculoskeletalHalluxValgusSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Hallux Valgus",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_musculoskeletalHalluxValgusSubAnswerDescribe",
                      type: "options_tree",
                      value: "describe",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          subQuestions: [
                            {
                              depth: 2,
                              hasSubQuestions: true,
                              editToggle: false,
                              subQuestionId: 1,
                              isCommentEnabled: true,
                              label: "Treatment",
                              isMulti: true,
                              value: "activeTreatmentSubAnswer",
                              name: "ros_musculoskeletalHalluxValgusTreatmentSubAnswer",
                              isConfigured: true,
                              type: "options_tree",
                              subQuestions: [
                                {
                                  depth: 2,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  name: "ros_musculoskeletalHalluxValgusSubOtherTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label: "Other Treatment",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      depth: 1,
                                      subQuestionId: 1,
                                      editToggle: false,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: false,
                                      label: "Other Treatment",
                                      xs: 12,
                                      name: "ros_musculoskeletalHalluxValgusSubOtherTreatmentSubDesc",
                                      value: "describe",
                                      type: "text",
                                    },
                                  ],
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 6,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Hammer Toes",
                  value: "hammerToes",
                  name: "ros_musculoskeletalHammerToesSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Hammer Toes",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_musculoskeletalHammerToesSubAnswerDescribe",
                      value: "describe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: true,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          subQuestions: [
                            {
                              depth: 2,
                              hasSubQuestions: true,
                              editToggle: false,
                              subQuestionId: 1,
                              isCommentEnabled: true,
                              label: "Select",
                              value: "activeTreatmentSubAnswer",
                              name: "ros_musculoskeletalHammerToesActiveSelectTreatmentSubAnswer",
                              isConfigured: true,
                              type: "options_tree",
                              subQuestions: [
                                {
                                  depth: 2,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Right Foot",
                                  value: "rightFoot",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Left Foot",
                                  value: "leftFoot",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Bilateral",
                                  value: "bilateral",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                            {
                              depth: 2,
                              hasSubQuestions: true,
                              editToggle: false,
                              subQuestionId: 2,
                              isCommentEnabled: true,
                              label: "Treatment",
                              isMulti: true,
                              value: "activeTreatmentSubAnswer",
                              name: "ros_musculoskeletalHammerToesActiveTreatmentSubAnswer",
                              isConfigured: true,
                              type: "options_tree",
                              subQuestions: [
                                {
                                  depth: 2,
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 3,
                                  hasSubQuestions: true,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  name: "ros_musculoskeletalHammerToesSubOtherTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label: "Other Treatment",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      depth: 1,
                                      subQuestionId: 1,
                                      editToggle: false,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: false,
                                      label: "Other Treatment",
                                      xs: 12,
                                      name: "ros_musculoskeletalHammerToesSubOtherTreatmentSubDesc",
                                      value: "describe",
                                      type: "text",
                                    },
                                  ],
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 7,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Osteoarthritis",
                  value: "osteoarthritis",
                  name: "ros_musculoskeletalOsteoarthritisSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Osteoarthritis",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_musculoskeletalOsteoarthritisSubAnswerDescribe",
                      type: "options_tree",
                      value: "describe",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          subQuestions: [
                            {
                              depth: 2,
                              hasSubQuestions: true,
                              editToggle: false,
                              subQuestionId: 1,
                              isCommentEnabled: true,
                              label: "Treatment",
                              isMulti: true,
                              value: "activeTreatmentSubAnswer",
                              name: "ros_musculoskeletalOsteoarthritisActiveTreatmentSubAnswer",
                              isConfigured: true,
                              type: "options_tree",
                              subQuestions: [
                                {
                                  depth: 2,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  name: "ros_musculoskeletalOsteoarthritisSubOtherTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label: "Other Treatment",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      depth: 1,
                                      subQuestionId: 1,
                                      editToggle: false,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: false,
                                      label: "Other Treatment",
                                      xs: 12,
                                      name: "ros_musculoskeletalOsteoarthritisSubOtherTreatmentSubDesc",
                                      value: "describe",
                                      type: "text",
                                    },
                                  ],
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 4,
                      name: "ros_musculoskeletalOsteoarthritisSubAnswerWhichJoints",
                      depth: 3,
                      type: "text",
                      label: "Which Joints",
                      value: "whichJoints",
                      editToggle: false,
                      xs: 12,
                      required: false,
                      placeholder: "",
                      defaultValue: "",
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      subQuestionId: 3,
                      hasSubQuestions: false,
                    },
                  ],
                },
                {
                  subQuestionId: 8,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Osteomyelitis",
                  value: "osteomyelitis",
                  name: "ros_musculoskeletalOsteomyelitisSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Osteomyelitis",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_musculoskeletalOsteomyelitisSubAnswerDescribe",
                      value: "describe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: true,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          subQuestions: [
                            {
                              depth: 1,
                              subQuestionId: 1,
                              editToggle: false,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: false,
                              label: "Location",
                              xs: 12,
                              name: "ros_musculoskeletalOsteomyelitisSubLocation",
                              value: "location",
                              type: "text",
                            },
                            {
                              depth: 2,
                              hasSubQuestions: true,
                              editToggle: false,
                              subQuestionId: 2,
                              isCommentEnabled: true,
                              label: "Treatment",
                              isMulti: true,
                              value: "activeTreatmentSubAnswer",
                              name: "ros_musculoskeletalOsteomyelitisActiveTreatmentSubAnswer",
                              isConfigured: true,
                              type: "options_tree",
                              subQuestions: [
                                {
                                  depth: 2,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  name: "ros_musculoskeletalOsteomyelitisSubOtherTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label: "Other Treatment",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      depth: 1,
                                      subQuestionId: 1,
                                      editToggle: false,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: false,
                                      label: "Other Treatment",
                                      xs: 12,
                                      name: "ros_musculoskeletalOsteomyelitisSubOtherTreatmentSubDesc",
                                      value: "describe",
                                      type: "text",
                                    },
                                  ],
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 9,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Osteoporosis",
                  value: "osteoporosis",
                  name: "ros_musculoskeletalOsteoporosisSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Osteoporosis",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_musculoskeletalOsteoporosisSubAnswerDescribe",
                      type: "options_tree",
                      value: "describe",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          subQuestions: [
                            {
                              depth: 2,
                              hasSubQuestions: true,
                              editToggle: false,
                              subQuestionId: 2,
                              isCommentEnabled: true,
                              label: "Treatment",
                              isMulti: true,
                              value: "activeTreatmentSubAnswer",
                              name: "ros_musculoskeletalOsteoporosisActiveTreatmentSubAnswer",
                              isConfigured: true,
                              type: "options_tree",
                              subQuestions: [
                                {
                                  depth: 2,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  name: "ros_musculoskeletalOsteoporosisSubOtherTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label: "Other Treatment",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      depth: 1,
                                      subQuestionId: 1,
                                      editToggle: false,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: false,
                                      label: "Other Treatment",
                                      xs: 12,
                                      name: "ros_musculoskeletalOsteoporosisSubOtherTreatmentSubDesc",
                                      value: "describe",
                                      type: "text",
                                    },
                                  ],
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 10,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Pyogenic Arthritis",
                  value: "pyogenicArthritis",
                  name: "ros_musculoskeletalPyogenicArthritisSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Pyogenic Arthritis",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_musculoskeletalPyogenicArthritisSubAnswerDescribe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          subQuestions: [
                            {
                              depth: 2,
                              hasSubQuestions: true,
                              editToggle: false,
                              subQuestionId: 2,
                              isCommentEnabled: true,
                              label: "Treatment",
                              isMulti: true,
                              value: "activeTreatmentSubAnswer",
                              name: "ros_musculoskeletalPyogenicArthritisActiveTreatmentSubAnswer",
                              isConfigured: true,
                              type: "options_tree",
                              subQuestions: [
                                {
                                  depth: 2,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  name: "ros_musculoskeletalPyogenicArthritisSubOtherTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label: "Other Treatment",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      depth: 1,
                                      subQuestionId: 1,
                                      editToggle: false,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: false,
                                      label: "Other Treatment",
                                      xs: 12,
                                      name: "ros_musculoskeletalPyogenicArthritisSubOtherTreatmentSubDesc",
                                      value: "describe",
                                      type: "text",
                                    },
                                  ],
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 4,
                      name: "ros_musculoskeletalPyogenicArthritisSubAnswerWhichJoints",
                      depth: 3,
                      type: "text",
                      label: "Which Joint/s",
                      value: "whichJoints",
                      editToggle: false,
                      xs: 12,
                      required: false,
                      placeholder: "",
                      defaultValue: "",
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      subQuestionId: 3,
                      hasSubQuestions: false,
                    },
                  ],
                },
                {
                  subQuestionId: 11,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Rheumatoid Arthritis",
                  value: "rheumatoidArthritis",
                  name: "ros_musculoskeletalRheumatoidArthritisSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Rheumatoid Arthritis",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_musculoskeletalRheumatoidArthritisSubAnswerDescribe",
                      type: "options_tree",
                      value: "describe",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          subQuestions: [
                            {
                              depth: 2,
                              hasSubQuestions: true,
                              editToggle: false,
                              subQuestionId: 2,
                              isCommentEnabled: true,
                              label: "Treatment",
                              isMulti: true,
                              value: "activeTreatmentSubAnswer",
                              name: "ros_musculoskeletalRheumatoidArthritisActiveTreatmentSubAnswer",
                              isConfigured: true,
                              type: "options_tree",
                              subQuestions: [
                                {
                                  depth: 2,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  name: "ros_musculoskeletalRheumatoidArthritisSubOtherTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label: "Other Treatment",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      depth: 1,
                                      subQuestionId: 1,
                                      editToggle: false,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: false,
                                      label: "Other Treatment",
                                      xs: 12,
                                      name: "ros_musculoskeletalRheumatoidArthritisSubOtherTreatmentSubDesc",
                                      value: "describe",
                                      type: "text",
                                    },
                                  ],
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 4,
                      name: "ros_musculoskeletalRheumatoidArthritisSubAnswerWhichJoints",
                      depth: 3,
                      type: "text",
                      label: "Which Joints",
                      value: "whichJoints",
                      editToggle: false,
                      xs: 12,
                      required: false,
                      placeholder: "",
                      defaultValue: "",
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      subQuestionId: 3,
                      hasSubQuestions: false,
                    },
                  ],
                },
                {
                  subQuestionId: 12,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Scleroderma",
                  value: "scleroderma",
                  name: "ros_musculoskeletalSclerodermaSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Scleroderma",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_musculoskeletalSclerodermaSubAnswerDescribe",
                      value: "describe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          subQuestions: [
                            {
                              depth: 2,
                              hasSubQuestions: true,
                              editToggle: false,
                              subQuestionId: 1,
                              isCommentEnabled: true,
                              label: "Treatment",
                              isMulti: true,
                              value: "activeTreatmentSubAnswer",
                              name: "ros_musculoskeletalSclerodermaActiveTreatmentSubAnswer",
                              isConfigured: true,
                              type: "options_tree",
                              subQuestions: [
                                {
                                  depth: 2,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  name: "ros_musculoskeletalSclerodermaSubOtherTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label: "Other Treatment",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      depth: 1,
                                      subQuestionId: 1,
                                      editToggle: false,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: false,
                                      label: "Other Treatment",
                                      xs: 12,
                                      name: "ros_musculoskeletalSclerodermaSubOtherTreatmentSubDesc",
                                      value: "describe",
                                      type: "text",
                                    },
                                  ],
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 13,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Spinal Stenosis",
                  value: "spinalStenosis",
                  name: "ros_musculoskeletalSpinalStenosisSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Spinal Stenosis",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_musculoskeletalSpinalStenosisSubAnswerDescribe",
                      type: "options_tree",
                      value: "describe",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_musculoskeletalSpinalStenosisSubAnswerSiteOfDisease",
                              value: "siteOfDisease",
                              type: "options_tree",
                              label: "Site of disease",
                              fullWidth: true,
                              required: true,
                              isMulti: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 1,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Cervical",
                                  value: "cervical",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 1,
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Thoracic",
                                  value: "thoracic",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 1,
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Lumbar",
                                  value: "lumbar",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 1,
                                  subQuestionId: 4,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Lumbosacral",
                                  value: "lumbosacral",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 1,
                                  subQuestionId: 5,
                                  hasSubQuestions: true,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Other",
                                  value: "other",
                                  isConfigured: true,
                                  type: "toggle_button",
                                  name: "ros_musculoskeletalSpinalStenosisSubSiteOfDiseaseSubOther",
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label: "Other",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      depth: 2,
                                      subQuestionId: 1,
                                      hasSubQuestions: true,
                                      editToggle: false,
                                      isCommentEnabled: false,
                                      label: "Describe",
                                      value: "describe",
                                      name: "ros_musculoskeletalSpinalStenosisSubSiteOfDiseaseSubOtherSubDesc",
                                      isConfigured: true,
                                      type: "text",
                                      xs: 12,
                                    },
                                  ],
                                },
                              ],
                              subQuestionId: 1,
                              editToggle: false,
                            },
                            {
                              id: 6,
                              name: "ros_musculoskeletalSpinalStenosisSubAnswerNormalBladder",
                              value: "normalBladder",
                              type: "options_tree",
                              label: "Abnormal Bowel and Bladder?",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 1,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Yes",
                                  value: "yes",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 1,
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "No",
                                  value: "no",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                              subQuestionId: 2,
                              editToggle: false,
                            },

                            {
                              depth: 2,
                              hasSubQuestions: true,
                              editToggle: false,
                              subQuestionId: 3,
                              isCommentEnabled: true,
                              label: "Treatment",
                              isMulti: true,
                              value: "activeTreatmentSubAnswer",
                              name: "ros_musculoskeletalSpinalStenosisActiveTreatmentSubAnswer",
                              isConfigured: true,
                              type: "options_tree",
                              subQuestions: [
                                {
                                  depth: 2,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  name: "ros_musculoskeletalSpinalStenosisSubOtherTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label: "Other Treatment",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      depth: 1,
                                      subQuestionId: 1,
                                      editToggle: false,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: false,
                                      label: "Other Treatment",
                                      xs: 12,
                                      name: "ros_musculoskeletalSpinalStenosisSubOtherTreatmentSubDesc",
                                      value: "describe",
                                      type: "text",
                                    },
                                  ],
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 14,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Systemic Lupus Erythematosus",
                  value: "systemicLupus",
                  name: "ros_musculoskeletalSystemicLupusSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Systemic Lupus Erythematosus",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_musculoskeletalSystemicLupusSubAnswerDescribe",
                      value: "describe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      depth: 2,
                      hasSubQuestions: true,
                      editToggle: false,
                      subQuestionId: 3,
                      isCommentEnabled: true,
                      label: "Treatment",
                      isMulti: true,
                      value: "activeTreatmentSubAnswer",
                      name: "ros_musculoskeletalSystemicLupusActiveTreatmentSubAnswer",
                      isConfigured: true,
                      type: "options_tree",
                      subQuestions: [
                        {
                          depth: 2,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Medication",
                          value: "medication",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          depth: 2,
                          subQuestionId: 2,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Other Treatment",
                          value: "otherTreatment",
                          name: "ros_musculoskeletalSystemicLupusSubOtherTreatment",
                          isConfigured: true,
                          type: "toggle_button",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other Treatment",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              depth: 1,
                              subQuestionId: 1,
                              editToggle: false,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: false,
                              label: "Other Treatment",
                              xs: 12,
                              name: "ros_musculoskeletalSystemicLupusSubOtherTreatmentSubDesc",
                              value: "describe",
                              type: "text",
                            },
                          ],
                        },
                        {
                          depth: 2,
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "No Treatment",
                          value: "noTreatment",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                      ],
                    },
                  ],
                },
                {
                  subQuestionId: 15,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Other",
                  value: "other",
                  name: "ros_musculoskeletalOtherSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Other",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_musculoskeletalMuskoOtherSubAnswerDescribe",
                      value: "describe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          subQuestions: [
                            {
                              depth: 2,
                              hasSubQuestions: true,
                              editToggle: false,
                              subQuestionId: 3,
                              isCommentEnabled: true,
                              label: "Treatment",
                              isMulti: true,
                              value: "activeTreatmentSubAnswer",
                              name: "ros_musculoskeletalMuskoOtherActiveTreatmentSubAnswer",
                              isConfigured: true,
                              type: "options_tree",
                              subQuestions: [
                                {
                                  depth: 2,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  name: "ros_musculoskeletalMuskoOtherSubOtherTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label: "Other Treatment",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      depth: 1,
                                      subQuestionId: 1,
                                      editToggle: false,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: false,
                                      label: "Other Treatment",
                                      xs: 12,
                                      name: "ros_musculoskeletalMuskoOtherSubOtherTreatmentSubDesc",
                                      value: "describe",
                                      type: "text",
                                    },
                                  ],
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 4,
                      name: "ros_musculoskeletalMuskoOtherSubAnswerother",
                      depth: 3,
                      type: "text",
                      label: "Other",
                      value: "other",
                      editToggle: false,
                      xs: 12,
                      required: false,
                      placeholder: "",
                      defaultValue: "",
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      subQuestionId: 3,
                      hasSubQuestions: false,
                    },
                  ],
                },
              ],
              subQuestionId: 1,
              editToggle: false,
            },
            {
              id: 9,
              subQuestionId: 2,
              hasSubQuestions: true,
              editToggle: false,
              depth: 3,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: false,
              name: "ros_musculoskeletalMuskoSymptoms",
              label: "Symptoms",
              isMulti: true,
              value: "symptoms",
              type: "options_tree",
              subQuestions: [
                {
                  subQuestionId: 1,
                  depth: 1,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: true,
                  isConfigured: true,
                  isModified: false,
                  label: "Joint Swelling",
                  value: "jointSwelling",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Joint Stiffness",
                  value: "jointStiffness",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Pain",
                  value: "pain",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: false,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Limited ROM",
                  value: "limitedRom",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 5,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Other",
                  value: "other",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Other",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      depth: 1,
                      subQuestionId: 1,
                      editToggle: false,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: false,
                      label: "Other",
                      xs: 12,
                      name: "ros_musculoskeletalMuskoSymptomsSubOtherSubDesc",
                      value: "describe",
                      type: "text",
                    },
                  ],
                },
                {
                  subQuestionId: 6,
                  hasSubQuestions: false,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "None",
                  value: "none",
                  type: "toggle_button",
                },
              ],
            },
            {
              id: 9,
              subQuestionId: 3,
              hasSubQuestions: true,
              editToggle: false,
              depth: 3,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: false,
              name: "ros_musculoskeletalMuskoHadAnAmputation",
              label: "Have you had an amputation?",
              value: "hadAnAmputation",
              type: "options_tree",
              subQuestions: [
                {
                  subQuestionId: 1,
                  depth: 1,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: true,
                  isConfigured: true,
                  isModified: false,
                  label: "Yes",
                  value: "yes",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 4,
                      name: "ros_musculoskeletalHadAnAmputationSubAnswer",
                      depth: 3,
                      type: "text",
                      label: "Describe",
                      value: "describe",
                      editToggle: false,
                      xs: 12,
                      required: false,
                      placeholder: "",
                      defaultValue: "",
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      subQuestionId: 1,
                      hasSubQuestions: false,
                    },
                  ],
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "No",
                  value: "no",
                  type: "toggle_button",
                },
              ],
            },
          ],
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 39,
      editToggle: false,
    },
    {
      id: 10,
      name: "musko_Recommendations",
      value: "takeMedicationsAsPescribed",
      label: "Recommendations",
      placeholder: "",
      editToggle: false,
      defaultValue: "",
      type: "checkbox",
      options: [
        ["Discuss PT/OT evaluation with PCP.", "discussPtOt"],
        ["Take medications as prescribed.", "takeMedicationsAsPescribed"],
        ["Case management referral for follow up.", "caseManagement"],
        ["Follow up with Clinician/Specialist.", "followUpClinician"],
        ["Member to use Durable Medical Equipment (DME).", "DME"],
        ["Other.", "other"],
      ],
      xs: 12,
      required: false,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      questionId: 40,
    },
    {
      id: 10,
      name: "integument",
      type: "heading",
      label: "INTEGUMENT",
      editToggle: false,
      xs: 12,
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 41,
    },
    {
      id: 7,
      name: "ros_integument",
      type: "options_tree",
      label:
        "Integument Problems (Eczema, Psoriasis, Dermatitis, Urticaria, Other)",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 1,
          subQuestionId: 1,
          hasSubQuestions: true,
          editToggle: false,
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          isConfigured: true,
          type: "toggle_button",
          subQuestions: [
            {
              id: 6,
              name: "ros_integumentDiagnoses",
              value: "diagnoses",
              type: "options_tree",
              label: "Diagnoses",
              isMulti: true,
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 1,
                  subQuestionId: 1,
                  name: "ros_integumentBasilCellCarcinomaSubAnswer",
                  value: "basilCellCarcinoma",
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  label: " Basil Cell Carcinoma ",
                  isConfigured: true,
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Basil Cell Carcinoma",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_integumentBasilCellCarcinomaSubAnswerDescribe",
                      value: "describe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          subQuestions: [
                            {
                              depth: 1,
                              subQuestionId: 1,
                              editToggle: false,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: false,
                              label: "Location",
                              xs: 12,
                              name: "ros_integumentBasilCellCarcinomaSubLocation",
                              value: "location",
                              type: "text",
                            },
                            {
                              depth: 2,
                              hasSubQuestions: true,
                              editToggle: false,
                              subQuestionId: 2,
                              isCommentEnabled: true,
                              label: "Treatment",
                              isMulti: true,
                              value: "activeTreatmentSubAnswer",
                              name: "ros_integumentBasilCellCarcinomaActiveTreatmentSubAnswer",
                              isConfigured: true,
                              type: "options_tree",
                              subQuestions: [
                                {
                                  depth: 2,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  name: "ros_integumentBasilCellCarcinomaSubOtherTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label: "Other Treatment",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      depth: 1,
                                      subQuestionId: 1,
                                      editToggle: false,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: false,
                                      label: "Other Treatment",
                                      xs: 12,
                                      name: "ros_integumentBasilCellCarcinomaSubOtherTreatmentSubDesc",
                                      value: "describe",
                                      type: "text",
                                    },
                                  ],
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Dermatitis",
                  name: "ros_integumentDermatitisSubAnswer",
                  value: "dermatitis",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Dermatitis",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_integumentDermatitisSubAnswerDescribe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          subQuestions: [
                            {
                              depth: 2,
                              subQuestionId: 1,
                              editToggle: false,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: false,
                              label: "Location",
                              xs: 12,
                              name: "ros_integumentDermatitisSubLocation",
                              value: "location",
                              type: "text",
                            },
                            {
                              depth: 2,
                              hasSubQuestions: true,
                              editToggle: false,
                              subQuestionId: 2,
                              isCommentEnabled: true,
                              label: "Treatment",
                              isMulti: true,
                              value: "activeTreatmentSubAnswer",
                              name: "ros_integumentDermatitisActiveTreatmentSubAnswer",
                              isConfigured: true,
                              type: "options_tree",
                              subQuestions: [
                                {
                                  depth: 2,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  name: "ros_integumentDermatitisSubOtherTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label: "Other Treatment",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      depth: 1,
                                      subQuestionId: 1,
                                      editToggle: false,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: false,
                                      label: "Other Treatment",
                                      xs: 12,
                                      name: "ros_integumentDermatitisSubOtherTreatmentSubDesc",
                                      value: "describe",
                                      type: "text",
                                    },
                                  ],
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },

                    {
                      id: 6,
                      name: "ros_integumentDermatitisSubAnswerWhatType",
                      type: "options_tree",
                      value: "whatType",
                      label: "What type",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Contact",
                          value: "contact",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Stasis",
                          value: "stasis",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Drug induced",
                          value: "drugInduced",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Disease Induced",
                          value: "diseaseInduced",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Unspecified",
                          value: "unspecified",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 3,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Eczema",
                  value: "eczema",
                  name: "ros_integumentEczemaSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Eczema",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_integumentEczemaSubAnswerDescribe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          subQuestions: [
                            {
                              depth: 1,
                              subQuestionId: 1,
                              editToggle: false,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: false,
                              label: "Location",
                              xs: 12,
                              name: "ros_integumentEczemaSubLocation",
                              value: "location",
                              type: "text",
                            },
                            {
                              depth: 2,
                              hasSubQuestions: true,
                              editToggle: false,
                              subQuestionId: 2,
                              isCommentEnabled: true,
                              label: "Treatment",
                              isMulti: true,
                              value: "activeTreatmentSubAnswer",
                              name: "ros_integumentEczemaActiveTreatmentSubAnswer",
                              isConfigured: true,
                              type: "options_tree",
                              subQuestions: [
                                {
                                  depth: 2,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  name: "ros_integumentEczemaSubOtherTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label: "Other Treatment",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      depth: 1,
                                      subQuestionId: 1,
                                      editToggle: false,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: false,
                                      label: "Other Treatment",
                                      xs: 12,
                                      name: "ros_integumentEczemaSubOtherTreatmentSubDesc",
                                      value: "describe",
                                      type: "text",
                                    },
                                  ],
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Onychomycosis",
                  value: "onychomycosis",
                  name: "ros_integumentOnychomycosisSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Onychomycosis",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_integumentOnychomycosisSubAnswerDescribe",
                      type: "options_tree",
                      label: "Describe",
                      value: "describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          subQuestions: [
                            {
                              depth: 1,
                              subQuestionId: 1,
                              editToggle: false,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: false,
                              label: "Location",
                              xs: 12,
                              name: "ros_integumentOnychomycosisSubLocation",
                              value: "location",
                              type: "text",
                            },
                            {
                              depth: 2,
                              hasSubQuestions: true,
                              editToggle: false,
                              subQuestionId: 2,
                              isCommentEnabled: true,
                              label: "Treatment",
                              isMulti: true,
                              value: "activeTreatmentSubAnswer",
                              name: "ros_integumentOnychomycosisActiveTreatmentSubAnswer",
                              isConfigured: true,
                              type: "options_tree",
                              subQuestions: [
                                {
                                  depth: 2,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  name: "ros_integumentOnychomycosisSubOtherTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label: "Other Treatment",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      depth: 1,
                                      subQuestionId: 1,
                                      editToggle: false,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: false,
                                      label: "Other Treatment",
                                      xs: 12,
                                      name: "ros_integumentOnychomycosisSubOtherTreatmentSubDesc",
                                      value: "describe",
                                      type: "text",
                                    },
                                  ],
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 5,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Psoriasis",
                  value: "psoriasis",
                  name: "ros_integumentPsoriasisSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Psoriasis",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_integumentPsoriasisSubAnswerDescribe",
                      type: "options_tree",
                      value: "describe",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          subQuestions: [
                            {
                              depth: 1,
                              subQuestionId: 1,
                              editToggle: false,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: false,
                              label: "Location",
                              xs: 12,
                              name: "ros_integumentPsoriasisSubLocation",
                              value: "location",
                              type: "text",
                            },
                            {
                              id: 8,
                              name: "ros_integumentPsoriasisSubAnswerPsoriaticArthritis",
                              type: "options_tree",
                              value: "psoriaticArthritis",
                              label: "Psoriatic Arthritis",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 1,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Yes",
                                  value: "yes",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "No",
                                  value: "no",
                                  type: "toggle_button",
                                },
                              ],
                              subQuestionId: 2,
                              editToggle: false,
                            },
                            {
                              depth: 2,
                              hasSubQuestions: true,
                              editToggle: false,
                              subQuestionId: 3,
                              isCommentEnabled: true,
                              label: "Treatment",
                              isMulti: true,
                              value: "activeTreatmentSubAnswer",
                              name: "ros_integumentPsoriasisActiveTreatmentSubAnswer",
                              isConfigured: true,
                              type: "options_tree",
                              subQuestions: [
                                {
                                  depth: 2,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  name: "ros_integumentPsoriasisSubOtherTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label: "Other Treatment",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      depth: 1,
                                      subQuestionId: 1,
                                      editToggle: false,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: false,
                                      label: "Other Treatment",
                                      xs: 12,
                                      name: "ros_integumentPsoriasisSubOtherTreatmentSubDesc",
                                      value: "describe",
                                      type: "text",
                                    },
                                  ],
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 6,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Skin ulcer",
                  value: "skinUlcer",
                  name: "ros_integumentSkinUlcerSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Skin ulcer",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_integumentSkinUlcerSubAnswerDescribe",
                      type: "options_tree",
                      label: "Describe",
                      value: "describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          subQuestions: [
                            {
                              depth: 1,
                              subQuestionId: 1,
                              editToggle: false,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: false,
                              label: "Location",
                              xs: 12,
                              name: "ros_integumentSkinUlcerSubLocation",
                              value: "location",
                              type: "text",
                            },
                            {
                              depth: 2,
                              hasSubQuestions: true,
                              editToggle: false,
                              subQuestionId: 2,
                              isCommentEnabled: true,
                              label: "Treatment",
                              isMulti: true,
                              value: "activeTreatmentSubAnswer",
                              name: "ros_integumentSkinUlcerActiveTreatmentSubAnswer",
                              isConfigured: true,
                              type: "options_tree",
                              subQuestions: [
                                {
                                  depth: 2,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  name: "ros_integumentSkinUlcerSubOtherTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label: "Other Treatment",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      depth: 1,
                                      subQuestionId: 1,
                                      editToggle: false,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: false,
                                      label: "Other Treatment",
                                      xs: 12,
                                      name: "ros_integumentSkinUlcerSubOtherTreatmentSubDesc",
                                      value: "describe",
                                      type: "text",
                                    },
                                  ],
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 7,
                      name: "ros_integumentSkinUlcerSubAnswerEtiology",
                      type: "options_tree",
                      label: "Etiology",
                      value: "etiology",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Pressure ",
                          value: "pressure",
                          name: "ros_integumentSkinUlcerSubEtiology",
                          isConfigured: true,
                          type: "toggle_button",
                          subQuestions: [
                            {
                              name: "ros_integumentSkinUlcerSubEtiologySubDesc",
                              depth: 4,
                              type: "text",
                              label: "Stage and Location",
                              value: "stageAndLocation",
                              editToggle: false,
                              xs: 12,
                              required: false,
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                              hasSubQuestions: false,
                            },
                          ],
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Venous Stasis",
                          value: "venousStatis",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Peripheral Vascular Disease",
                          value: "peripheralVascular",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Disease Induced",
                          value: "diseaseInduced",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Diabetic Vasculitis",
                          value: "diabeticVasculitis",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Diabetic Neuropathy",
                          value: "diabeticNeuropathy",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 3,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 7,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: " Tinea Pedis",
                  value: "tineaPedis",
                  name: "ros_integumentTineaPedis",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: " Tinea Pedis",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_integumentTineaPedisSubAnswerDescribe",
                      type: "options_tree",
                      value: "describe",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          subQuestions: [
                            {
                              depth: 2,
                              hasSubQuestions: true,
                              editToggle: false,
                              subQuestionId: 2,
                              isCommentEnabled: true,
                              label: "Select",
                              value: "activeTreatmentSubAnswer",
                              name: "ros_integumentTineaPedisSelectTreatmentSubAnswer",
                              isConfigured: true,
                              type: "options_tree",
                              subQuestions: [
                                {
                                  depth: 2,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Right Foot",
                                  value: "rightFoot",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },

                                {
                                  depth: 2,
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Left Foot",
                                  value: "leftFoot",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Bilateral",
                                  value: "bilateral",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                            {
                              depth: 2,
                              hasSubQuestions: true,
                              editToggle: false,
                              subQuestionId: 3,
                              isCommentEnabled: true,
                              label: "Treatment",
                              isMulti: true,
                              value: "activeTreatmentSubAnswer",
                              name: "ros_integumentTineaPedisActiveTreatmentSubAnswer",
                              isConfigured: true,
                              type: "options_tree",
                              subQuestions: [
                                {
                                  depth: 2,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  name: "ros_integumentTineaPedisSubOtherTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label: "Other Treatment",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      depth: 1,
                                      subQuestionId: 1,
                                      editToggle: false,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: false,
                                      label: "Other Treatment",
                                      xs: 12,
                                      name: "ros_integumentTineaPedisSubOtherTreatmentSubDesc",
                                      value: "describe",
                                      type: "text",
                                    },
                                  ],
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 8,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Urticarial Disease",
                  value: "urticarialDisease",
                  name: "ros_integumentUrticarialDiseaseSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Urticarial Disease",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_integumentUrticarialDiseaseSubAnswerDescribe",
                      type: "options_tree",
                      value: "describe",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          subQuestions: [
                            {
                              depth: 1,
                              subQuestionId: 1,
                              editToggle: false,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: false,
                              label: "Location",
                              xs: 12,
                              name: "ros_integumentUrticarialDiseaseSubLocation",
                              value: "location",
                              type: "text",
                            },
                            {
                              depth: 2,
                              hasSubQuestions: true,
                              editToggle: false,
                              subQuestionId: 2,
                              isCommentEnabled: true,
                              label: "Treatment",
                              isMulti: true,
                              value: "activeTreatmentSubAnswer",
                              name: "ros_integumentUrticarialDiseaseActiveTreatmentSubAnswer",
                              isConfigured: true,
                              type: "options_tree",
                              subQuestions: [
                                {
                                  depth: 2,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  name: "ros_integumentUrticarialDiseaseSubOtherTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label: "Other Treatment",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      depth: 1,
                                      subQuestionId: 1,
                                      editToggle: false,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: false,
                                      label: "Other Treatment",
                                      xs: 12,
                                      name: "ros_integumentUrticarialDiseaseSubOtherTreatmentSubDesc",
                                      value: "describe",
                                      type: "text",
                                    },
                                  ],
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 7,
                      name: "ros_integumentUrticarialDiseaseSubAnswerType",
                      type: "options_tree",
                      value: "type",
                      label: "Type",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Acute ",
                          value: "acute",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Chronic",
                          value: "chronic",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 3,
                      editToggle: false,
                    },
                    {
                      id: 4,
                      name: "ros_integumentUrticarialDiseaseSubAnswerEtiology",
                      depth: 3,
                      type: "text",
                      label: "Etiology",
                      value: "etiology",
                      editToggle: false,
                      xs: 12,
                      required: false,
                      placeholder: "",
                      defaultValue: "",
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      subQuestionId: 4,
                      hasSubQuestions: false,
                    },
                  ],
                },
                {
                  subQuestionId: 9,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Wound",
                  value: "wound",
                  name: "ros_integumentWoundSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Wound",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_integumentWoundSubAnswerDescribe",
                      type: "options_tree",
                      label: "Describe",
                      value: "describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          subQuestions: [
                            {
                              depth: 1,
                              subQuestionId: 1,
                              editToggle: false,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: false,
                              label: "Location",
                              xs: 12,
                              name: "ros_integumentWoundSubLocation",
                              value: "location",
                              type: "text",
                            },
                            {
                              depth: 2,
                              hasSubQuestions: true,
                              editToggle: false,
                              subQuestionId: 2,
                              isCommentEnabled: true,
                              label: "Treatment",
                              isMulti: true,
                              value: "activeTreatmentSubAnswer",
                              name: "ros_integumentWoundActiveTreatmentSubAnswer",
                              isConfigured: true,
                              type: "options_tree",
                              subQuestions: [
                                {
                                  depth: 2,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  name: "ros_integumentWoundSubOtherTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label: "Other Treatment",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      depth: 1,
                                      subQuestionId: 1,
                                      editToggle: false,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: false,
                                      label: "Other Treatment",
                                      xs: 12,
                                      name: "ros_integumentWoundSubOtherTreatmentSubDesc",
                                      value: "describe",
                                      type: "text",
                                    },
                                  ],
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 7,
                      name: "ros_integumentWoundSubAnswerEtiology",
                      type: "options_tree",
                      label: "Etiology",
                      value: "etiology",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Surgical",
                          value: "surgical",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Traumatic",
                          value: "traumatic",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Burn",
                          value: "burn",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 3,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 10,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Other",
                  value: "other",
                  name: "ros_integumentOtherSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Other",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_integumentOthersSubAnswerDescribe",
                      value: "describe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          subQuestions: [
                            {
                              depth: 1,
                              subQuestionId: 1,
                              editToggle: false,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: false,
                              label: "Location",
                              xs: 12,
                              name: "ros_integumentOthersSubLocation",
                              value: "location",
                              type: "text",
                            },
                            {
                              depth: 2,
                              hasSubQuestions: true,
                              editToggle: false,
                              subQuestionId: 2,
                              isCommentEnabled: true,
                              label: "Treatment",
                              isMulti: true,
                              value: "activeTreatmentSubAnswer",
                              name: "ros_integumentOthersActiveTreatmentSubAnswer",
                              isConfigured: true,
                              type: "options_tree",
                              subQuestions: [
                                {
                                  depth: 2,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  name: "ros_integumentOthersSubOtherTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label: "Other Treatment",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      depth: 1,
                                      subQuestionId: 1,
                                      editToggle: false,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: false,
                                      label: "Other Treatment",
                                      xs: 12,
                                      name: "ros_integumentOthersSubOtherTreatmentSubDesc",
                                      value: "describe",
                                      type: "text",
                                    },
                                  ],
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 4,
                      name: "ros_integumentOthersSubAnswerOther",
                      depth: 3,
                      type: "text",
                      label: "Other",
                      value: "other",
                      editToggle: false,
                      xs: 12,
                      required: false,
                      placeholder: "",
                      defaultValue: "",
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      subQuestionId: 3,
                      hasSubQuestions: false,
                    },
                  ],
                },
              ],
              subQuestionId: 1,
              editToggle: false,
            },
          ],
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 42,
      editToggle: false,
    },
    {
      id: 11,
      name: "integument_Recommendations",
      value: "takeMedicationsAsPescribed",
      label: "Recommendations",
      placeholder: "",
      editToggle: false,
      defaultValue: "",
      type: "checkbox",
      options: [
        ["Take medications as prescribed.", "takeMedicationsAsPescribed"],
        ["Case management referral for follow up.", "caseManagement"],
        ["Follow up with Clinician/Specialist.", "followUpClinician"],
        ["Member to use Durable Medical Equipment (DME).", "DME"],
        ["Other.", "other"],
      ],
      xs: 12,
      required: false,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      questionId: 43,
    },
    {
      id: 9,
      name: "endocrine",
      type: "heading",
      label: "ENDOCRINE",
      editToggle: false,
      xs: 12,
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 44,
      depth: 1,
    },

    {
      id: 6,
      name: "ros_endocrineIsEndocrine",
      type: "options_tree",
      label: "Endocrine Problems",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 2,
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "ros_endocrineIsEndocrineDiagnose",
              type: "options_tree",
              label: "Diagnoses",
              isMulti: true,
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Cushing's Disease",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      depth: 5,
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "ros_endocrineIsEndocrineSubcushingsDiseaseSubdescribeSub",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_endocrineIsEndocrineDiagnosedescushingsActiveSubSymp",
                              type: "options_tree",
                              label: "Symptoms",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isMulti: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Increased Thirst",
                                  value: "increasedThirst",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Shakiness",
                                  value: "shakiness",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Sweating",
                                  value: "sweating",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 4,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Blurred Vision",
                                  value: "blurredVision",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 5,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Weight Gain",
                                  value: "weightGain",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 6,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Fatigue",
                                  value: "fatigue",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 7,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Hair Changes",
                                  value: "hairChanges",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 8,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Weight Loss",
                                  value: "weightLoss",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 9,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "None",
                                  value: "none",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 1,
                              editToggle: false,
                              depth: 7,
                            },
                            {
                              id: 6,
                              name: "ros_endocrineIsEndocrineDiagnosedescushingsActiveSubTre",
                              type: "options_tree",
                              isMulti: true,
                              label: "Treatment",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  subQuestions: [
                                    {
                                      id: 1,
                                      name: "ros_endocrineIsEndocrineDiagnosedescushingsActiveSubTreOtherSub",
                                      type: "text",
                                      label: "Other Treatment",
                                      editToggle: false,
                                      xs: 4,
                                      required: "no",
                                      placeholder: "",
                                      defaultValue: "",
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      subQuestionId: 1,
                                      depth: 9,
                                    },
                                  ],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 2,
                              editToggle: false,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                          name: "historyOf",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                      depth: 5,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Cushing's Disease",
                  value: "cushingsDisease",
                  type: "toggle_button",
                  name: "cushingsDisease",
                  depth: 4,
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Diabetes",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      depth: 5,
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "ros_endocrineIsEndocrineSubdiabetesSubdescribeSub",
                      type: "options_tree",
                      label: "Diabetes",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubType",
                              type: "options_tree",
                              label: "Type",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Type 1",
                                  value: "type1",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },

                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Type 1.5",
                                  value: "typeOnepointFive",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Type 2",
                                  value: "type2",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 4,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Gestational",
                                  value: "gestational",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 1,
                              editToggle: false,
                              depth: 7,
                            },
                            {
                              id: 6,
                              name: "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTre",
                              type: "options_tree",
                              isMulti: true,
                              label: "Treatment",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: true,
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label: "Medication",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                      depth: 9,
                                      subQuestionId: 1,
                                    },
                                    {
                                      id: 6,
                                      name: "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubSelect",
                                      type: "options_tree",
                                      label: "Select",
                                      fullWidth: true,
                                      isMulti: true,
                                      required: true,
                                      xs: 12,
                                      isCommentEnabled: true,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                      subQuestions: [
                                        {
                                          depth: 9,
                                          subQuestionId: 1,
                                          hasSubQuestions: false,
                                          subQuestions: [],
                                          editToggle: false,
                                          isCommentEnabled: false,
                                          label: "Oral Hypoglycemic Agent",
                                          value: "oralHypoglycemicAgent",
                                          isConfigured: true,
                                          type: "toggle_button",
                                        },

                                        {
                                          subQuestionId: 2,
                                          hasSubQuestions: false,
                                          subQuestions: [],
                                          editToggle: false,
                                          isCommentEnabled: false,
                                          isConfigured: true,
                                          isModified: false,
                                          label: "Insulin",
                                          value: "insulin",
                                          type: "toggle_button",
                                          depth: 9,
                                        },
                                        {
                                          subQuestionId: 2,
                                          hasSubQuestions: true,
                                          subQuestions: [
                                            {
                                              id: 6,
                                              name: "",
                                              value: "",
                                              type: "label",
                                              label: "Other",
                                              fullWidth: true,
                                              required: false,
                                              xs: 12,
                                              isCommentEnabled: false,
                                              isConfigured: true,
                                              isModified: true,
                                              hasSubQuestions: true,
                                              depth: 9,
                                              subQuestionId: 1,
                                            },
                                            {
                                              id: 1,
                                              name: "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubSelectOtherDes",
                                              type: "text",
                                              label: "Describe",
                                              editToggle: false,
                                              xs: 4,
                                              required: "no",
                                              placeholder: "",
                                              defaultValue: "",
                                              isCommentEnabled: false,
                                              isConfigured: true,
                                              isModified: true,
                                              subQuestionId: 1,
                                              depth: 9,
                                            },
                                          ],
                                          editToggle: false,
                                          isCommentEnabled: false,
                                          isConfigured: true,
                                          isModified: false,
                                          label: "Other",
                                          value: "other",
                                          type: "toggle_button",
                                          depth: 8,
                                        },
                                      ],
                                      subQuestionId: 1,
                                      editToggle: false,
                                      depth: 10,
                                    },
                                  ],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  subQuestions: [
                                    {
                                      id: 1,
                                      name: "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTreOtherSub",
                                      type: "text",
                                      label: "Other Treatment",
                                      editToggle: false,
                                      xs: 4,
                                      required: "no",
                                      placeholder: "",
                                      defaultValue: "",
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      subQuestionId: 1,
                                      depth: 9,
                                    },
                                  ],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 4,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Exercise",
                                  value: "exercise",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 5,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Diet",
                                  value: "diet",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 2,
                              editToggle: false,
                              depth: 7,
                            },
                            {
                              id: 1,
                              name: "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubA1c",
                              type: "text",
                              label: "Most recent Hb A1C, value",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 3,
                              depth: 7,
                            },
                            {
                              id: 1,
                              name: "ros_endocrineIsEndocrineSubdiabetesSubandDate",
                              type: "text",
                              label: "And Date",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 4,
                              depth: 7,
                            },
                            {
                              id: 6,
                              name: "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEgfr",
                              type: "options_tree",
                              label:
                                "Have you had a eGFR and urine albumin -creatinine ratio done in the last year?",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Yes",
                                  value: "yes",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  subQuestions: [
                                    {
                                      id: 10,
                                      name: "ros_endocrineIsEndocrineNoEdu",
                                      label: "",
                                      placeholder: "",
                                      editToggle: false,
                                      defaultValue: "",
                                      type: "checkbox",
                                      options: [
                                        [
                                          "Education provided to followup with PCP/specialist regarding eGFR urine albumin-creatinine ratio",
                                          "educationProvidNo",
                                        ],
                                      ],
                                      xs: 12,
                                      required: false,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: false,
                                      questionId: 1,
                                      depth: 1,
                                    },
                                  ],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "No",
                                  value: "no",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: true,
                                  subQuestions: [
                                    {
                                      id: 10,
                                      name: "ros_endocrineIsEndocrineUnknownEdu",
                                      label: "",
                                      placeholder: "",
                                      editToggle: false,
                                      defaultValue: "",
                                      type: "checkbox",
                                      options: [
                                        [
                                          "Education provided to followup with PCP/specialist regarding eGFR urine albumin-creatinine ratio",
                                          "educationProvidUnknown",
                                        ],
                                      ],
                                      xs: 12,
                                      required: false,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: false,
                                      questionId: 1,
                                      depth: 1,
                                    },
                                  ],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Unknown",
                                  value: "unknown",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 5,
                              editToggle: false,
                              depth: 7,
                            },
                            {
                              id: 6,
                              name: "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubMet",
                              type: "options_tree",
                              label:
                                "Met with a nurse, dietician, or diabetic educator?",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Yes",
                                  value: "yes",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "No",
                                  value: "no",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 6,
                              editToggle: false,
                              depth: 7,
                            },
                            {
                              id: 6,
                              name: "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubTest",
                              type: "options_tree",
                              label: "Do you test your blood sugar",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Yes",
                                  value: "yes",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "No",
                                  value: "no",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 7,
                              editToggle: false,
                              depth: 7,
                            },
                            {
                              id: 6,
                              name: "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCompl",
                              type: "options_tree",
                              label: "Complications",
                              fullWidth: true,
                              required: true,
                              isMulti: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: true,
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label: "Diabetic Cataracts",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                      depth: 9,
                                      subQuestionId: 1,
                                    },
                                    {
                                      id: 6,
                                      name: "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCatarcts",
                                      type: "options_tree",
                                      label: "Which Eye",
                                      fullWidth: true,
                                      required: true,
                                      xs: 12,
                                      isCommentEnabled: true,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                      subQuestions: [
                                        {
                                          depth: 10,
                                          subQuestionId: 1,
                                          hasSubQuestions: false,
                                          subQuestions: [],
                                          editToggle: false,
                                          isCommentEnabled: false,
                                          label: "Right Eye",
                                          value: "rightEye",
                                          isConfigured: true,
                                          type: "toggle_button",
                                        },
                                        {
                                          subQuestionId: 2,
                                          hasSubQuestions: false,
                                          subQuestions: [],
                                          editToggle: false,
                                          isCommentEnabled: false,
                                          isConfigured: true,
                                          isModified: false,
                                          label: "Left Eye",
                                          value: "leftEye",
                                          type: "toggle_button",
                                          depth: 10,
                                        },
                                        {
                                          subQuestionId: 3,
                                          hasSubQuestions: false,
                                          subQuestions: [],
                                          editToggle: false,
                                          isCommentEnabled: false,
                                          isConfigured: true,
                                          isModified: false,
                                          label: "Both",
                                          value: "both",
                                          type: "toggle_button",
                                          depth: 10,
                                        },
                                      ],
                                      depth: 9,
                                      subQuestionId: 2,
                                    },
                                    {
                                      id: 6,
                                      name: "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubDescribe",
                                      type: "options_tree",
                                      label: "Describe",
                                      fullWidth: true,
                                      required: true,
                                      xs: 12,
                                      isCommentEnabled: true,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                      subQuestions: [
                                        {
                                          depth: 10,
                                          subQuestionId: 1,
                                          hasSubQuestions: true,
                                          subQuestions: [
                                            {
                                              id: 6,
                                              name: "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCatarctsTre",
                                              type: "options_tree",
                                              isMulti: true,
                                              label: "Treatment",
                                              fullWidth: true,
                                              required: true,
                                              xs: 12,
                                              isCommentEnabled: true,
                                              isConfigured: true,
                                              isModified: true,
                                              hasSubQuestions: true,
                                              subQuestions: [
                                                {
                                                  depth: 12,
                                                  subQuestionId: 1,
                                                  hasSubQuestions: false,
                                                  subQuestions: [],
                                                  editToggle: false,
                                                  isCommentEnabled: false,
                                                  label: "Medication",
                                                  value: "medication",
                                                  isConfigured: true,
                                                  type: "toggle_button",
                                                },
                                                {
                                                  subQuestionId: 2,
                                                  hasSubQuestions: true,
                                                  subQuestions: [
                                                    {
                                                      id: 1,
                                                      name: "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubCatarctsTreOtherSub",
                                                      type: "text",
                                                      label: "Other Treatment",
                                                      editToggle: false,
                                                      xs: 4,
                                                      required: "no",
                                                      placeholder: "",
                                                      defaultValue: "",
                                                      isCommentEnabled: false,
                                                      isConfigured: true,
                                                      isModified: true,
                                                      subQuestionId: 1,
                                                      depth: 13,
                                                    },
                                                  ],
                                                  editToggle: false,
                                                  isCommentEnabled: false,
                                                  isConfigured: true,
                                                  isModified: false,
                                                  label: "Other Treatment",
                                                  value: "otherTreatment",
                                                  type: "toggle_button",
                                                  depth: 12,
                                                },
                                                {
                                                  subQuestionId: 3,
                                                  hasSubQuestions: false,
                                                  subQuestions: [],
                                                  editToggle: false,
                                                  isCommentEnabled: false,
                                                  isConfigured: true,
                                                  isModified: false,
                                                  label: "No Treatment",
                                                  value: "noTreatment",
                                                  type: "toggle_button",
                                                  depth: 12,
                                                },
                                              ],
                                              subQuestionId: 1,
                                              editToggle: false,
                                              depth: 11,
                                            },
                                          ],
                                          editToggle: false,
                                          isCommentEnabled: false,
                                          label: "Active",
                                          value: "active",
                                          isConfigured: true,
                                          type: "toggle_button",
                                        },
                                        {
                                          subQuestionId: 2,
                                          hasSubQuestions: false,
                                          subQuestions: [],
                                          editToggle: false,
                                          isCommentEnabled: false,
                                          isConfigured: true,
                                          isModified: false,
                                          label: "History of",
                                          value: "historyOf",
                                          type: "toggle_button",
                                          depth: 10,
                                        },
                                      ],
                                      depth: 9,
                                      subQuestionId: 3,
                                    },
                                  ],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Diabetic Cataracts",
                                  value: "diabeticCataracts",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label: "Diabetic Macular Edema",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                      depth: 9,
                                      subQuestionId: 1,
                                    },
                                    {
                                      id: 6,
                                      name: "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEdema",
                                      type: "options_tree",
                                      label: "Which Eye",
                                      fullWidth: true,
                                      required: true,
                                      xs: 12,
                                      isCommentEnabled: true,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                      subQuestions: [
                                        {
                                          depth: 10,
                                          subQuestionId: 1,
                                          hasSubQuestions: false,
                                          subQuestions: [],
                                          editToggle: false,
                                          isCommentEnabled: false,
                                          label: "Right Eye",
                                          value: "rightEye",
                                          isConfigured: true,
                                          type: "toggle_button",
                                        },
                                        {
                                          subQuestionId: 2,
                                          hasSubQuestions: false,
                                          subQuestions: [],
                                          editToggle: false,
                                          isCommentEnabled: false,
                                          isConfigured: true,
                                          isModified: false,
                                          label: "Left Eye",
                                          value: "leftEye",
                                          type: "toggle_button",
                                          depth: 10,
                                        },
                                        {
                                          subQuestionId: 3,
                                          hasSubQuestions: false,
                                          subQuestions: [],
                                          editToggle: false,
                                          isCommentEnabled: false,
                                          isConfigured: true,
                                          isModified: false,
                                          label: "Both",
                                          value: "both",
                                          type: "toggle_button",
                                          depth: 10,
                                        },
                                      ],
                                      depth: 9,
                                      subQuestionId: 2,
                                    },
                                    {
                                      id: 6,
                                      name: "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubEdemaDescribe",
                                      type: "options_tree",
                                      label: "Describe",
                                      fullWidth: true,
                                      required: true,
                                      xs: 12,
                                      isCommentEnabled: true,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                      subQuestions: [
                                        {
                                          depth: 10,
                                          subQuestionId: 1,
                                          hasSubQuestions: true,
                                          subQuestions: [
                                            {
                                              id: 6,
                                              name: "ros_endocrineIsEndocrineDiagnosedesDiabetesEdemaActiveSubTre",
                                              type: "options_tree",
                                              label: "Treatment",
                                              isMulti: true,
                                              fullWidth: true,
                                              required: true,
                                              xs: 12,
                                              isCommentEnabled: true,
                                              isConfigured: true,
                                              isModified: true,
                                              hasSubQuestions: true,
                                              subQuestions: [
                                                {
                                                  depth: 12,
                                                  subQuestionId: 1,
                                                  hasSubQuestions: false,
                                                  subQuestions: [],
                                                  editToggle: false,
                                                  isCommentEnabled: false,
                                                  label: "Medication",
                                                  value: "medication",
                                                  isConfigured: true,
                                                  type: "toggle_button",
                                                },
                                                {
                                                  depth: 12,
                                                  subQuestionId: 2,
                                                  hasSubQuestions: false,
                                                  subQuestions: [],
                                                  editToggle: false,
                                                  isCommentEnabled: false,
                                                  label: "Laser Therapy",
                                                  value: "laserTherapy",
                                                  isConfigured: true,
                                                  type: "toggle_button",
                                                },
                                                {
                                                  depth: 12,
                                                  subQuestionId: 3,
                                                  hasSubQuestions: false,
                                                  subQuestions: [],
                                                  editToggle: false,
                                                  isCommentEnabled: false,
                                                  label: "Eye Injections",
                                                  value: "eyeInjections",
                                                  isConfigured: true,
                                                  type: "toggle_button",
                                                },
                                                {
                                                  subQuestionId: 4,
                                                  hasSubQuestions: true,
                                                  subQuestions: [
                                                    {
                                                      id: 1,
                                                      name: "ros_endocrineIsEndocrineDiagnosedesDiabetesEdemaActiveSubTreOtherSub",
                                                      type: "text",
                                                      label: "Other Treatment",
                                                      editToggle: false,
                                                      xs: 4,
                                                      required: "no",
                                                      placeholder: "",
                                                      defaultValue: "",
                                                      isCommentEnabled: false,
                                                      isConfigured: true,
                                                      isModified: true,
                                                      subQuestionId: 1,
                                                      depth: 13,
                                                    },
                                                  ],
                                                  editToggle: false,
                                                  isCommentEnabled: false,
                                                  isConfigured: true,
                                                  isModified: false,
                                                  label: "Other Treatment",
                                                  value: "otherTreatment",
                                                  type: "toggle_button",
                                                  depth: 12,
                                                },
                                                {
                                                  subQuestionId: 5,
                                                  hasSubQuestions: false,
                                                  subQuestions: [],
                                                  editToggle: false,
                                                  isCommentEnabled: false,
                                                  isConfigured: true,
                                                  isModified: false,
                                                  label: "No Treatment",
                                                  value: "noTreatment",
                                                  type: "toggle_button",
                                                  depth: 12,
                                                },
                                              ],
                                              subQuestionId: 1,
                                              editToggle: false,
                                              depth: 11,
                                            },
                                            {
                                              id: 6,
                                              name: "ros_endocrineIsEndocrineDiagnosedesDiaEdemaPatientOphtha",
                                              type: "options_tree",
                                              label:
                                                "Patient sees Ophthalmologist",
                                              fullWidth: true,
                                              required: true,
                                              xs: 12,
                                              isCommentEnabled: true,
                                              isConfigured: true,
                                              isModified: true,
                                              hasSubQuestions: true,
                                              subQuestions: [
                                                {
                                                  depth: 12,
                                                  subQuestionId: 1,
                                                  hasSubQuestions: false,
                                                  subQuestions: [],
                                                  editToggle: false,
                                                  isCommentEnabled: false,
                                                  label: "Ocasionally",
                                                  value: "ocasionally",
                                                  isConfigured: true,
                                                  type: "toggle_button",
                                                },
                                                {
                                                  depth: 12,
                                                  subQuestionId: 2,
                                                  hasSubQuestions: false,
                                                  subQuestions: [],
                                                  editToggle: false,
                                                  isCommentEnabled: false,
                                                  label: "Once a year",
                                                  value: "onceAYear",
                                                  isConfigured: true,
                                                  type: "toggle_button",
                                                },
                                                {
                                                  depth: 12,
                                                  subQuestionId: 3,
                                                  hasSubQuestions: false,
                                                  subQuestions: [],
                                                  editToggle: false,
                                                  isCommentEnabled: false,
                                                  label: "Twice a year",
                                                  value: "twiceAyear",
                                                  isConfigured: true,
                                                  type: "toggle_button",
                                                },
                                                {
                                                  subQuestionId: 4,
                                                  hasSubQuestions: false,
                                                  subQuestions: [],
                                                  editToggle: false,
                                                  isCommentEnabled: false,
                                                  isConfigured: true,
                                                  isModified: false,
                                                  label: "> Twice a year",
                                                  value: "greaterThan2Ayear",
                                                  type: "toggle_button",
                                                  depth: 12,
                                                },
                                              ],
                                              subQuestionId: 2,
                                              editToggle: false,
                                              depth: 11,
                                            },
                                          ],
                                          editToggle: false,
                                          isCommentEnabled: false,
                                          label: "Active",
                                          value: "active",
                                          isConfigured: true,
                                          type: "toggle_button",
                                        },
                                        {
                                          subQuestionId: 2,
                                          hasSubQuestions: false,
                                          subQuestions: [],
                                          editToggle: false,
                                          isCommentEnabled: false,
                                          isConfigured: true,
                                          isModified: false,
                                          label: "History of",
                                          value: "historyOf",
                                          type: "toggle_button",
                                          depth: 10,
                                        },
                                      ],
                                      depth: 9,
                                      subQuestionId: 3,
                                    },
                                  ],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Diabetic Macular Edema",
                                  value: "diabeticMacularEdema",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: true,
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label: "Diabetic Retinopathy",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      id: 6,
                                      name: "ros_endocrineIsEndocrineSubdiabetesRetinopathySubdescribeSub",
                                      type: "options_tree",
                                      label: "Describe",
                                      fullWidth: true,
                                      required: true,
                                      xs: 12,
                                      isCommentEnabled: true,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                      subQuestions: [
                                        {
                                          depth: 6,
                                          subQuestionId: 1,
                                          hasSubQuestions: false,
                                          subQuestions: [],
                                          editToggle: false,
                                          isCommentEnabled: false,
                                          label: "Active",
                                          value: "active",
                                          isConfigured: true,
                                          type: "toggle_button",
                                          name: "active",
                                        },
                                        {
                                          subQuestionId: 2,
                                          hasSubQuestions: false,
                                          subQuestions: [],
                                          editToggle: false,
                                          isCommentEnabled: false,
                                          isConfigured: true,
                                          isModified: false,
                                          label: "History of",
                                          value: "historyOf",
                                          type: "toggle_button",
                                          name: "historyOf",
                                          depth: 6,
                                        },
                                      ],
                                      subQuestionId: 1,
                                      editToggle: false,
                                      depth: 5,
                                    },

                                    {
                                      id: 6,
                                      name: "ros_endocrineIsEndocrineSubdiabeticRetinopathySubwhichEyeSub",
                                      type: "options_tree",
                                      label: "Which Eye",
                                      fullWidth: true,
                                      required: true,
                                      xs: 12,
                                      isCommentEnabled: true,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                      subQuestions: [
                                        {
                                          depth: 6,
                                          subQuestionId: 1,
                                          hasSubQuestions: false,
                                          subQuestions: [],
                                          editToggle: false,
                                          isCommentEnabled: false,
                                          label: "Right Eye",
                                          value: "rightEye",
                                          isConfigured: true,
                                          type: "toggle_button",
                                          name: "rightEye",
                                        },
                                        {
                                          subQuestionId: 2,
                                          hasSubQuestions: false,
                                          subQuestions: [],
                                          editToggle: false,
                                          isCommentEnabled: false,
                                          isConfigured: true,
                                          isModified: false,
                                          label: "Left Eye",
                                          value: "leftEye",
                                          type: "toggle_button",
                                          name: "leftEye",
                                          depth: 6,
                                        },
                                        {
                                          subQuestionId: 3,
                                          hasSubQuestions: false,
                                          subQuestions: [],
                                          editToggle: false,
                                          isCommentEnabled: false,
                                          isConfigured: true,
                                          isModified: false,
                                          label: "Bilateral",
                                          value: "bilateral",
                                          type: "toggle_button",
                                          name: "bilateral",
                                          depth: 6,
                                        },
                                      ],
                                      subQuestionId: 2,
                                      editToggle: false,
                                      depth: 5,
                                    },
                                    {
                                      id: 6,
                                      name: "ros_endocrineIsEndocrineSubdiabeticRetinopathySubtypeSub",
                                      type: "options_tree",
                                      label: "Type",
                                      fullWidth: true,
                                      required: true,
                                      xs: 12,
                                      isCommentEnabled: true,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                      subQuestions: [
                                        {
                                          depth: 6,
                                          subQuestionId: 1,
                                          hasSubQuestions: false,
                                          subQuestions: [],
                                          editToggle: false,
                                          isCommentEnabled: false,
                                          label: "Proliferative",
                                          value: "proliferative",
                                          isConfigured: true,
                                          type: "toggle_button",
                                          name: "proliferative",
                                        },
                                        {
                                          subQuestionId: 2,
                                          hasSubQuestions: false,
                                          subQuestions: [],
                                          editToggle: false,
                                          isCommentEnabled: false,
                                          isConfigured: true,
                                          isModified: false,
                                          label: "Non -proliferative",
                                          value: "nonProliferative",
                                          type: "toggle_button",
                                          name: "nonProliferative",
                                          depth: 6,
                                        },
                                        {
                                          subQuestionId: 3,
                                          hasSubQuestions: false,
                                          subQuestions: [],
                                          editToggle: false,
                                          isCommentEnabled: false,
                                          isConfigured: true,
                                          isModified: false,
                                          label: "Unknown",
                                          value: "unknown",
                                          type: "toggle_button",
                                          name: "unknown",
                                          depth: 6,
                                        },
                                      ],
                                      subQuestionId: 3,
                                      editToggle: false,
                                      depth: 5,
                                    },
                                    {
                                      id: 6,
                                      name: "ros_endocrineIsEndocrineSubdiabeticRetinopathyTreat",
                                      type: "options_tree",
                                      label: "Treatment",
                                      isMulti: true,
                                      fullWidth: true,
                                      required: true,
                                      xs: 12,
                                      isCommentEnabled: true,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                      subQuestions: [
                                        {
                                          depth: 6,
                                          subQuestionId: 1,
                                          hasSubQuestions: false,
                                          subQuestions: [],
                                          editToggle: false,
                                          isCommentEnabled: false,
                                          label: "Medication",
                                          value: "medication",
                                          isConfigured: true,
                                          type: "toggle_button",
                                          name: "medication",
                                        },

                                        {
                                          subQuestionId: 2,
                                          hasSubQuestions: false,
                                          subQuestions: [],
                                          editToggle: false,
                                          isCommentEnabled: false,
                                          isConfigured: true,
                                          isModified: false,
                                          label: "Laser Therapy",
                                          value: "laserTherapy",
                                          type: "toggle_button",
                                          name: "laserTherapy",
                                          depth: 6,
                                        },
                                        {
                                          subQuestionId: 3,
                                          hasSubQuestions: false,
                                          subQuestions: [],
                                          editToggle: false,
                                          isCommentEnabled: false,
                                          isConfigured: true,
                                          isModified: false,
                                          label: "Retinal injections",
                                          value: "retinalInjections",
                                          type: "toggle_button",
                                          name: "retinalInjections",
                                          depth: 6,
                                        },

                                        {
                                          subQuestionId: 4,
                                          hasSubQuestions: true,
                                          subQuestions: [
                                            {
                                              id: 6,
                                              name: "",
                                              value: "",
                                              type: "label",
                                              label: "Other",
                                              fullWidth: true,
                                              required: false,
                                              xs: 12,
                                              isCommentEnabled: false,
                                              isConfigured: true,
                                              isModified: true,
                                              hasSubQuestions: true,
                                            },
                                            {
                                              id: 1,
                                              name: "ros_endocrineIsEndocrineSubdiabeticRetinopathyTreatOtherSub",
                                              type: "text",
                                              label: "Describe",
                                              editToggle: false,
                                              xs: 4,
                                              required: "no",
                                              placeholder: "",
                                              defaultValue: "",
                                              isCommentEnabled: false,
                                              isConfigured: true,
                                              isModified: true,
                                              subQuestionId: 1,
                                              depth: 7,
                                            },
                                          ],
                                          editToggle: false,
                                          isCommentEnabled: false,
                                          isConfigured: true,
                                          isModified: false,
                                          label: "Other Treatment",
                                          value: "other",
                                          type: "toggle_button",
                                          name: "other",
                                          depth: 6,
                                        },
                                        {
                                          subQuestionId: 5,
                                          hasSubQuestions: false,
                                          subQuestions: [],
                                          editToggle: false,
                                          isCommentEnabled: false,
                                          isConfigured: true,
                                          isModified: false,
                                          label: "No Treatment",
                                          value: "noTreatment",
                                          type: "toggle_button",
                                          name: "noTreatment",
                                          depth: 6,
                                        },
                                      ],
                                      subQuestionId: 4,
                                      editToggle: false,
                                      depth: 5,
                                    },
                                    {
                                      id: 6,
                                      name: "ros_endocrineIsEndocrineSubdiabeticRetinopathySubophthalmologistSub",
                                      type: "options_tree",
                                      label: "Patient sees Ophthalmologist",
                                      fullWidth: true,
                                      required: true,
                                      xs: 12,
                                      isCommentEnabled: true,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                      subQuestions: [
                                        {
                                          depth: 6,
                                          subQuestionId: 1,
                                          hasSubQuestions: false,
                                          subQuestions: [],
                                          editToggle: false,
                                          isCommentEnabled: false,
                                          label: "Occasionally",
                                          value: "occasionally",
                                          isConfigured: true,
                                          type: "toggle_button",
                                          name: "occasionally",
                                        },
                                        {
                                          subQuestionId: 2,
                                          hasSubQuestions: false,
                                          subQuestions: [],
                                          editToggle: false,
                                          isCommentEnabled: false,
                                          isConfigured: true,
                                          isModified: false,
                                          label: "Once a year",
                                          value: "onceAYear",
                                          type: "toggle_button",
                                          name: "onceAYear",
                                          depth: 6,
                                        },
                                        {
                                          subQuestionId: 3,
                                          hasSubQuestions: false,
                                          subQuestions: [],
                                          editToggle: false,
                                          isCommentEnabled: false,
                                          isConfigured: true,
                                          isModified: false,
                                          label: "Twice a year",
                                          value: "twiceAYear",
                                          type: "toggle_button",
                                          name: "twiceAYear",
                                          depth: 6,
                                        },
                                        {
                                          subQuestionId: 4,
                                          hasSubQuestions: false,
                                          subQuestions: [],
                                          editToggle: false,
                                          isCommentEnabled: false,
                                          isConfigured: true,
                                          isModified: false,
                                          label: ">Twice a year",
                                          value: "gtwiceAYear",
                                          type: "toggle_button",
                                          name: "gtwiceAYear",
                                          depth: 6,
                                        },
                                      ],
                                      subQuestionId: 5,
                                      editToggle: false,
                                      depth: 5,
                                    },
                                  ],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Diabetic Retinopathy",
                                  value: "diabeticRetinopathy",
                                  type: "toggle_button",
                                  name: "diabeticRetinopathy",
                                  depth: 4,
                                },
                                {
                                  depth: 4,
                                  subQuestionId: 4,
                                  hasSubQuestions: true,
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label:
                                        "Peripheral Neuropathy Secondary to Diabetes",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      id: 6,
                                      name: "ros_endocrineIsEndocrineSubneuroSubdescribeSub",
                                      type: "options_tree",
                                      label: "Describe",
                                      fullWidth: true,
                                      required: true,
                                      xs: 12,
                                      isCommentEnabled: true,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                      subQuestions: [
                                        {
                                          depth: 6,
                                          subQuestionId: 1,
                                          hasSubQuestions: true,
                                          subQuestions: [
                                            {
                                              id: 6,
                                              name: "ros_endocrineIsEndocrineSubNeuroSubSymp",
                                              type: "options_tree",
                                              label: "Symptoms",
                                              isMulti: true,
                                              fullWidth: true,
                                              required: true,
                                              xs: 12,
                                              isCommentEnabled: true,
                                              isConfigured: true,
                                              isModified: true,
                                              hasSubQuestions: true,
                                              subQuestions: [
                                                {
                                                  depth: 6,
                                                  subQuestionId: 1,
                                                  hasSubQuestions: true,
                                                  subQuestions: [
                                                    {
                                                      id: 6,
                                                      name: "",
                                                      value: "",
                                                      type: "label",
                                                      label: "Numbness",
                                                      fullWidth: true,
                                                      required: false,
                                                      xs: 12,
                                                      isCommentEnabled: false,
                                                      isConfigured: true,
                                                      isModified: true,
                                                      hasSubQuestions: true,
                                                      depth: 9,
                                                      subQuestionId: 1,
                                                    },
                                                    {
                                                      id: 6,
                                                      name: "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveNumbLoc",
                                                      type: "options_tree",
                                                      label: "Location",
                                                      fullWidth: true,
                                                      required: true,
                                                      xs: 12,
                                                      isCommentEnabled: true,
                                                      isConfigured: true,
                                                      isModified: true,
                                                      hasSubQuestions: true,
                                                      subQuestions: [
                                                        {
                                                          depth: 9,
                                                          subQuestionId: 1,
                                                          hasSubQuestions: false,
                                                          subQuestions: [],
                                                          editToggle: false,
                                                          isCommentEnabled: false,
                                                          label:
                                                            "Upper Extremities",
                                                          value:
                                                            "upperExtremities",
                                                          isConfigured: true,
                                                          type: "toggle_button",
                                                        },

                                                        {
                                                          subQuestionId: 2,
                                                          hasSubQuestions: false,
                                                          subQuestions: [],
                                                          editToggle: false,
                                                          isCommentEnabled: false,
                                                          isConfigured: true,
                                                          isModified: false,
                                                          label:
                                                            "Lower Extremities",
                                                          value:
                                                            "lowerExtremities",
                                                          type: "toggle_button",
                                                          depth: 9,
                                                        },
                                                      ],
                                                      subQuestionId: 1,
                                                      editToggle: false,
                                                      depth: 10,
                                                    },
                                                  ],
                                                  editToggle: false,
                                                  isCommentEnabled: false,
                                                  label: "Numbness",
                                                  value: "numbess",
                                                  isConfigured: true,
                                                  type: "toggle_button",
                                                  name: "numbess",
                                                },

                                                {
                                                  depth: 6,
                                                  subQuestionId: 2,
                                                  hasSubQuestions: true,
                                                  subQuestions: [
                                                    {
                                                      id: 6,
                                                      name: "",
                                                      value: "",
                                                      type: "label",
                                                      label: "Pain",
                                                      fullWidth: true,
                                                      required: false,
                                                      xs: 12,
                                                      isCommentEnabled: false,
                                                      isConfigured: true,
                                                      isModified: true,
                                                      hasSubQuestions: true,
                                                      depth: 9,
                                                      subQuestionId: 1,
                                                    },
                                                    {
                                                      id: 6,
                                                      name: "ros_endocrineIsEndocrineDiagnosedesdiabetesActivePainLoc",
                                                      type: "options_tree",
                                                      label: "Location",
                                                      fullWidth: true,
                                                      required: true,
                                                      xs: 12,
                                                      isCommentEnabled: true,
                                                      isConfigured: true,
                                                      isModified: true,
                                                      hasSubQuestions: true,
                                                      subQuestions: [
                                                        {
                                                          depth: 9,
                                                          subQuestionId: 1,
                                                          hasSubQuestions: false,
                                                          subQuestions: [],
                                                          editToggle: false,
                                                          isCommentEnabled: false,
                                                          label:
                                                            "Upper Extremities",
                                                          value:
                                                            "upperExtremities",
                                                          isConfigured: true,
                                                          type: "toggle_button",
                                                        },

                                                        {
                                                          subQuestionId: 2,
                                                          hasSubQuestions: false,
                                                          subQuestions: [],
                                                          editToggle: false,
                                                          isCommentEnabled: false,
                                                          isConfigured: true,
                                                          isModified: false,
                                                          label:
                                                            "Lower Extremities",
                                                          value:
                                                            "lowerExtremities",
                                                          type: "toggle_button",
                                                          depth: 9,
                                                        },
                                                      ],
                                                      subQuestionId: 1,
                                                      editToggle: false,
                                                      depth: 10,
                                                    },
                                                  ],
                                                  editToggle: false,
                                                  isCommentEnabled: false,
                                                  label: "Pain",
                                                  value: "pain",
                                                  isConfigured: true,
                                                  type: "toggle_button",
                                                  name: "pain",
                                                },
                                                {
                                                  depth: 6,
                                                  subQuestionId: 3,
                                                  hasSubQuestions: true,
                                                  subQuestions: [
                                                    {
                                                      id: 6,
                                                      name: "",
                                                      value: "",
                                                      type: "label",
                                                      label: "Burning",
                                                      fullWidth: true,
                                                      required: false,
                                                      xs: 12,
                                                      isCommentEnabled: false,
                                                      isConfigured: true,
                                                      isModified: true,
                                                      hasSubQuestions: true,
                                                      depth: 9,
                                                      subQuestionId: 1,
                                                    },
                                                    {
                                                      id: 6,
                                                      name: "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveBurLoc",
                                                      type: "options_tree",
                                                      label: "Location",
                                                      fullWidth: true,
                                                      required: true,
                                                      xs: 12,
                                                      isCommentEnabled: true,
                                                      isConfigured: true,
                                                      isModified: true,
                                                      hasSubQuestions: true,
                                                      subQuestions: [
                                                        {
                                                          depth: 9,
                                                          subQuestionId: 1,
                                                          hasSubQuestions: false,
                                                          subQuestions: [],
                                                          editToggle: false,
                                                          isCommentEnabled: false,
                                                          label:
                                                            "Upper Extremities",
                                                          value:
                                                            "upperExtremities",
                                                          isConfigured: true,
                                                          type: "toggle_button",
                                                        },

                                                        {
                                                          subQuestionId: 2,
                                                          hasSubQuestions: false,
                                                          subQuestions: [],
                                                          editToggle: false,
                                                          isCommentEnabled: false,
                                                          isConfigured: true,
                                                          isModified: false,
                                                          label:
                                                            "Lower Extremities",
                                                          value:
                                                            "lowerExtremities",
                                                          type: "toggle_button",
                                                          depth: 9,
                                                        },
                                                      ],
                                                      subQuestionId: 1,
                                                      editToggle: false,
                                                      depth: 10,
                                                    },
                                                  ],
                                                  editToggle: false,
                                                  isCommentEnabled: false,
                                                  label: "Burning",
                                                  value: "burning",
                                                  isConfigured: true,
                                                  type: "toggle_button",
                                                  name: "burning",
                                                },
                                                {
                                                  depth: 6,
                                                  subQuestionId: 4,
                                                  hasSubQuestions: true,
                                                  subQuestions: [
                                                    {
                                                      id: 6,
                                                      name: "",
                                                      value: "",
                                                      type: "label",
                                                      label:
                                                        "Decreased Sensation",
                                                      fullWidth: true,
                                                      required: false,
                                                      xs: 12,
                                                      isCommentEnabled: false,
                                                      isConfigured: true,
                                                      isModified: true,
                                                      hasSubQuestions: true,
                                                      depth: 9,
                                                      subQuestionId: 1,
                                                    },
                                                    {
                                                      id: 6,
                                                      name: "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveDecrLoc",
                                                      type: "options_tree",
                                                      label: "Location",
                                                      fullWidth: true,
                                                      required: true,
                                                      xs: 12,
                                                      isCommentEnabled: true,
                                                      isConfigured: true,
                                                      isModified: true,
                                                      hasSubQuestions: true,
                                                      subQuestions: [
                                                        {
                                                          depth: 9,
                                                          subQuestionId: 1,
                                                          hasSubQuestions: false,
                                                          subQuestions: [],
                                                          editToggle: false,
                                                          isCommentEnabled: false,
                                                          label:
                                                            "Upper Extremities",
                                                          value:
                                                            "upperExtremities",
                                                          isConfigured: true,
                                                          type: "toggle_button",
                                                        },

                                                        {
                                                          subQuestionId: 2,
                                                          hasSubQuestions: false,
                                                          subQuestions: [],
                                                          editToggle: false,
                                                          isCommentEnabled: false,
                                                          isConfigured: true,
                                                          isModified: false,
                                                          label:
                                                            "Lower Extremities",
                                                          value:
                                                            "lowerExtremities",
                                                          type: "toggle_button",
                                                          depth: 9,
                                                        },
                                                      ],
                                                      subQuestionId: 1,
                                                      editToggle: false,
                                                      depth: 10,
                                                    },
                                                  ],
                                                  editToggle: false,
                                                  isCommentEnabled: false,
                                                  label: "Decreased Sensation",
                                                  value: "decreasedSensation",
                                                  isConfigured: true,
                                                  type: "toggle_button",
                                                  name: "decreasedSensation",
                                                },
                                                {
                                                  depth: 6,
                                                  subQuestionId: 5,
                                                  hasSubQuestions: false,
                                                  subQuestions: [],
                                                  editToggle: false,
                                                  isCommentEnabled: false,
                                                  label: "None",
                                                  value: "none",
                                                  isConfigured: true,
                                                  type: "toggle_button",
                                                  name: "none",
                                                },
                                              ],
                                              subQuestionId: 1,
                                              editToggle: false,
                                              depth: 5,
                                            },
                                            {
                                              id: 6,
                                              name: "ros_endocrineIsEndocrineSubNeuroSubTreat",
                                              type: "options_tree",
                                              label: "Treatment",
                                              isMulti: true,
                                              fullWidth: true,
                                              required: true,
                                              xs: 12,
                                              isCommentEnabled: true,
                                              isConfigured: true,
                                              isModified: true,
                                              hasSubQuestions: true,
                                              subQuestions: [
                                                {
                                                  depth: 6,
                                                  subQuestionId: 1,
                                                  hasSubQuestions: false,
                                                  subQuestions: [],
                                                  editToggle: false,
                                                  isCommentEnabled: false,
                                                  label: "Medication",
                                                  value: "medication",
                                                  isConfigured: true,
                                                  type: "toggle_button",
                                                  name: "medication",
                                                },

                                                {
                                                  subQuestionId: 2,
                                                  hasSubQuestions: true,
                                                  subQuestions: [
                                                    {
                                                      id: 1,
                                                      name: "ros_endocrineIsEndocrineSubNeuroSubTreatBySubotherSubdescribe",
                                                      type: "text",
                                                      label: "Other treatment",
                                                      editToggle: false,
                                                      xs: 4,
                                                      required: "no",
                                                      placeholder: "",
                                                      defaultValue: "",
                                                      isCommentEnabled: false,
                                                      isConfigured: true,
                                                      isModified: true,
                                                      subQuestionId: 1,
                                                      depth: 7,
                                                    },
                                                  ],
                                                  editToggle: false,
                                                  isCommentEnabled: false,
                                                  isConfigured: true,
                                                  isModified: false,
                                                  label: "Other Treatment",
                                                  value: "otherTreatment",
                                                  type: "toggle_button",
                                                  name: "other",
                                                  depth: 6,
                                                },
                                                {
                                                  depth: 6,
                                                  subQuestionId: 1,
                                                  hasSubQuestions: false,
                                                  subQuestions: [],
                                                  editToggle: false,
                                                  isCommentEnabled: false,
                                                  label: "No treatment",
                                                  value: "noTreatment",
                                                  isConfigured: true,
                                                  type: "toggle_button",
                                                  name: "noTreatment",
                                                },
                                              ],
                                              subQuestionId: 2,
                                              editToggle: false,
                                              depth: 5,
                                            },
                                            {
                                              id: 6,
                                              name: "ros_endocrineIsEndocrineDiagnoseperipheralNeuropathypatientSeesPodiatrist",
                                              type: "options_tree",
                                              label: "Patient sees Podiatrist",
                                              fullWidth: true,
                                              required: true,
                                              xs: 12,
                                              isCommentEnabled: true,
                                              isConfigured: true,
                                              isModified: true,
                                              hasSubQuestions: true,
                                              subQuestions: [
                                                {
                                                  subQuestionId: 1,
                                                  hasSubQuestions: true,
                                                  subQuestions: [
                                                    {
                                                      id: 6,
                                                      name: "ros_endocrineIsEndocrineNeuroSubPatientOften",
                                                      type: "options_tree",
                                                      label: "How often",
                                                      fullWidth: true,
                                                      required: true,
                                                      xs: 12,
                                                      isCommentEnabled: true,
                                                      isConfigured: true,
                                                      isModified: true,
                                                      hasSubQuestions: true,
                                                      subQuestions: [
                                                        {
                                                          depth: 6,
                                                          subQuestionId: 1,
                                                          hasSubQuestions: false,
                                                          subQuestions: [],
                                                          editToggle: false,
                                                          isCommentEnabled: false,
                                                          label: "Once a year",
                                                          value: "onceAYear",
                                                          isConfigured: true,
                                                          type: "toggle_button",
                                                          name: "onceAYear",
                                                        },
                                                        {
                                                          subQuestionId: 2,
                                                          hasSubQuestions: false,
                                                          subQuestions: [],
                                                          editToggle: false,
                                                          isCommentEnabled: false,
                                                          isConfigured: true,
                                                          isModified: false,
                                                          label: "Twice a year",
                                                          value: "twiceAYear",
                                                          type: "toggle_button",
                                                          name: "twiceAYear",
                                                          depth: 6,
                                                        },
                                                        {
                                                          subQuestionId: 3,
                                                          hasSubQuestions: false,
                                                          subQuestions: [],
                                                          editToggle: false,
                                                          isCommentEnabled: false,
                                                          isConfigured: true,
                                                          isModified: false,
                                                          label: "Quarterly",
                                                          value: "quarterly",
                                                          type: "toggle_button",
                                                          name: "quarterly",
                                                          depth: 6,
                                                        },
                                                      ],
                                                      subQuestionId: 1,
                                                      editToggle: false,
                                                      depth: 7,
                                                    },
                                                  ],
                                                  editToggle: false,
                                                  isCommentEnabled: false,
                                                  isConfigured: true,
                                                  isModified: false,
                                                  label: "Yes",
                                                  value: "yes",
                                                  type: "toggle_button",
                                                  name: "yes",
                                                  depth: 6,
                                                },
                                                {
                                                  depth: 6,
                                                  subQuestionId: 1,
                                                  hasSubQuestions: false,
                                                  subQuestions: [],
                                                  editToggle: false,
                                                  isCommentEnabled: false,
                                                  label: "No",
                                                  value: "no",
                                                  isConfigured: true,
                                                  type: "toggle_button",
                                                  name: "no",
                                                },
                                              ],
                                              subQuestionId: 3,
                                              editToggle: false,
                                              depth: 5,
                                            },
                                          ],
                                          editToggle: false,
                                          isCommentEnabled: false,
                                          label: "Active",
                                          value: "active",
                                          isConfigured: true,
                                          type: "toggle_button",
                                          name: "active",
                                        },
                                        {
                                          subQuestionId: 2,
                                          hasSubQuestions: false,
                                          subQuestions: [],
                                          editToggle: false,
                                          isCommentEnabled: false,
                                          isConfigured: true,
                                          isModified: false,
                                          label: "History of",
                                          value: "historyOf",
                                          type: "toggle_button",
                                          name: "historyOf",
                                          depth: 6,
                                        },
                                      ],
                                      subQuestionId: 1,
                                      editToggle: false,
                                      depth: 5,
                                    },
                                  ],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label:
                                    "Peripheral Neuropathy secondary to Diabetes",
                                  value: "peripheralNeuropathy",
                                  isConfigured: true,
                                  type: "toggle_button",
                                  name: "peripheralNeuropathy",
                                },

                                {
                                  depth: 4,
                                  subQuestionId: 5,
                                  hasSubQuestions: true,
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label:
                                        "Peripheral Vascular Disease Secondary to Diabetes",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      id: 6,
                                      name: "ros_endocrineIsEndocrineDiagnoseperipheralVascularDescribe",
                                      type: "options_tree",
                                      label: "Describe",
                                      fullWidth: true,
                                      required: true,
                                      xs: 12,
                                      isCommentEnabled: true,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                      subQuestions: [
                                        {
                                          depth: 6,
                                          subQuestionId: 1,
                                          hasSubQuestions: true,
                                          subQuestions: [
                                            {
                                              id: 6,
                                              name: "ros_endocrineIsEndocrineSubVascularSubPhysical",
                                              type: "options_tree",
                                              label: "Physical Findings",
                                              isMulti: true,
                                              fullWidth: true,
                                              required: true,
                                              xs: 12,
                                              isCommentEnabled: true,
                                              isConfigured: true,
                                              isModified: true,
                                              hasSubQuestions: true,
                                              subQuestions: [
                                                {
                                                  depth: 6,
                                                  subQuestionId: 1,
                                                  hasSubQuestions: false,
                                                  subQuestions: [],
                                                  editToggle: false,
                                                  isCommentEnabled: false,
                                                  label: "Skin Changes",
                                                  value: "skinChanges",
                                                  isConfigured: true,
                                                  type: "toggle_button",
                                                  name: "skinChanges",
                                                },

                                                {
                                                  depth: 6,
                                                  subQuestionId: 2,
                                                  hasSubQuestions: false,
                                                  subQuestions: [],
                                                  editToggle: false,
                                                  isCommentEnabled: false,
                                                  label: "Foot Deformity",
                                                  value: "footdeformity",
                                                  isConfigured: true,
                                                  type: "toggle_button",
                                                  name: "footdeformity",
                                                },
                                                {
                                                  depth: 6,
                                                  subQuestionId: 3,
                                                  hasSubQuestions: false,
                                                  subQuestions: [],
                                                  editToggle: false,
                                                  isCommentEnabled: false,
                                                  label:
                                                    "Intermittent Claudication",
                                                  value: "intermittent",
                                                  isConfigured: true,
                                                  type: "toggle_button",
                                                  name: "intermittent",
                                                },
                                                {
                                                  depth: 6,
                                                  subQuestionId: 4,
                                                  hasSubQuestions: false,
                                                  subQuestions: [],
                                                  editToggle: false,
                                                  isCommentEnabled: false,
                                                  label: "Other",
                                                  value: "other",
                                                  isConfigured: true,
                                                  type: "toggle_button",
                                                  name: "other",
                                                },
                                                {
                                                  depth: 6,
                                                  subQuestionId: 5,
                                                  hasSubQuestions: false,
                                                  subQuestions: [],
                                                  editToggle: false,
                                                  isCommentEnabled: false,
                                                  label: "None",
                                                  value: "none",
                                                  isConfigured: true,
                                                  type: "toggle_button",
                                                  name: "none",
                                                },
                                              ],
                                              subQuestionId: 1,
                                              editToggle: false,
                                              depth: 5,
                                            },
                                            {
                                              id: 6,
                                              name: "ros_endocrineIsEndocrineSubVascularSubTreat",
                                              type: "options_tree",
                                              label: "Treatment",
                                              isMulti: true,
                                              fullWidth: true,
                                              required: true,
                                              xs: 12,
                                              isCommentEnabled: true,
                                              isConfigured: true,
                                              isModified: true,
                                              hasSubQuestions: true,
                                              subQuestions: [
                                                {
                                                  depth: 6,
                                                  subQuestionId: 1,
                                                  hasSubQuestions: false,
                                                  subQuestions: [],
                                                  editToggle: false,
                                                  isCommentEnabled: false,
                                                  label: "Medication",
                                                  value: "medication",
                                                  isConfigured: true,
                                                  type: "toggle_button",
                                                  name: "medication",
                                                },

                                                {
                                                  subQuestionId: 2,
                                                  hasSubQuestions: true,
                                                  subQuestions: [
                                                    {
                                                      id: 1,
                                                      name: "ros_endocrineIsEndocrineSubvascularTreatmentBySubotherSubdescribe",
                                                      type: "text",
                                                      label: "Other treatment",
                                                      editToggle: false,
                                                      xs: 4,
                                                      required: "no",
                                                      placeholder: "",
                                                      defaultValue: "",
                                                      isCommentEnabled: false,
                                                      isConfigured: true,
                                                      isModified: true,
                                                      subQuestionId: 1,
                                                      depth: 7,
                                                    },
                                                  ],
                                                  editToggle: false,
                                                  isCommentEnabled: false,
                                                  isConfigured: true,
                                                  isModified: false,
                                                  label: "Other Treatment",
                                                  value: "otherTreatment",
                                                  type: "toggle_button",
                                                  name: "other",
                                                  depth: 6,
                                                },
                                                {
                                                  depth: 6,
                                                  subQuestionId: 1,
                                                  hasSubQuestions: false,
                                                  subQuestions: [],
                                                  editToggle: false,
                                                  isCommentEnabled: false,
                                                  label: "No treatment",
                                                  value: "noTreatment",
                                                  isConfigured: true,
                                                  type: "toggle_button",
                                                  name: "noTreatment",
                                                },
                                              ],
                                              subQuestionId: 2,
                                              editToggle: false,
                                              depth: 5,
                                            },
                                            {
                                              id: 6,
                                              name: "ros_endocrineIsEndocrineDiagnoseperipheralVascularpatientSeesPodiatrist",
                                              type: "options_tree",
                                              label: "Patient sees Podiatrist",
                                              fullWidth: true,
                                              required: true,
                                              xs: 12,
                                              isCommentEnabled: true,
                                              isConfigured: true,
                                              isModified: true,
                                              hasSubQuestions: true,
                                              subQuestions: [
                                                {
                                                  subQuestionId: 1,
                                                  hasSubQuestions: true,
                                                  subQuestions: [
                                                    {
                                                      id: 6,
                                                      name: "ros_endocrineIsEndocrineSubPeripheralVasSubPatientOften",
                                                      type: "options_tree",
                                                      label: "How often",
                                                      fullWidth: true,
                                                      required: true,
                                                      xs: 12,
                                                      isCommentEnabled: true,
                                                      isConfigured: true,
                                                      isModified: true,
                                                      hasSubQuestions: true,
                                                      subQuestions: [
                                                        {
                                                          depth: 6,
                                                          subQuestionId: 1,
                                                          hasSubQuestions: false,
                                                          subQuestions: [],
                                                          editToggle: false,
                                                          isCommentEnabled: false,
                                                          label: "Once a year",
                                                          value: "onceAYear",
                                                          isConfigured: true,
                                                          type: "toggle_button",
                                                          name: "onceAYear",
                                                        },
                                                        {
                                                          subQuestionId: 2,
                                                          hasSubQuestions: false,
                                                          subQuestions: [],
                                                          editToggle: false,
                                                          isCommentEnabled: false,
                                                          isConfigured: true,
                                                          isModified: false,
                                                          label: "Twice a year",
                                                          value: "twiceAYear",
                                                          type: "toggle_button",
                                                          name: "twiceAYear",
                                                          depth: 6,
                                                        },
                                                        {
                                                          subQuestionId: 3,
                                                          hasSubQuestions: false,
                                                          subQuestions: [],
                                                          editToggle: false,
                                                          isCommentEnabled: false,
                                                          isConfigured: true,
                                                          isModified: false,
                                                          label: "Quarterly",
                                                          value: "quarterly",
                                                          type: "toggle_button",
                                                          name: "quarterly",
                                                          depth: 6,
                                                        },
                                                      ],
                                                      subQuestionId: 1,
                                                      editToggle: false,
                                                      depth: 7,
                                                    },
                                                  ],
                                                  editToggle: false,
                                                  isCommentEnabled: false,
                                                  isConfigured: true,
                                                  isModified: false,
                                                  label: "Yes",
                                                  value: "yes",
                                                  type: "toggle_button",
                                                  name: "yes",
                                                  depth: 6,
                                                },
                                                {
                                                  depth: 6,
                                                  subQuestionId: 1,
                                                  hasSubQuestions: false,
                                                  subQuestions: [],
                                                  editToggle: false,
                                                  isCommentEnabled: false,
                                                  label: "No",
                                                  value: "no",
                                                  isConfigured: true,
                                                  type: "toggle_button",
                                                  name: "no",
                                                },
                                              ],
                                              subQuestionId: 3,
                                              editToggle: false,
                                              depth: 5,
                                            },
                                          ],
                                          editToggle: false,
                                          isCommentEnabled: false,
                                          label: "Active",
                                          value: "active",
                                          isConfigured: true,
                                          type: "toggle_button",
                                          name: "active",
                                        },
                                        {
                                          subQuestionId: 2,
                                          hasSubQuestions: false,
                                          subQuestions: [],
                                          editToggle: false,
                                          isCommentEnabled: false,
                                          isConfigured: true,
                                          isModified: false,
                                          label: "History of",
                                          value: "historyOf",
                                          type: "toggle_button",
                                          name: "historyOf",
                                          depth: 6,
                                        },
                                      ],
                                      subQuestionId: 1,
                                      editToggle: false,
                                      depth: 5,
                                    },
                                  ],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label:
                                    "Peripheral Vascular Disease secondary to Diabetes",
                                  value: "peripheralVascular",
                                  isConfigured: true,
                                  type: "toggle_button",
                                  name: "peripheralVascular",
                                },

                                {
                                  depth: 4,
                                  subQuestionId: 6,
                                  hasSubQuestions: true,
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label:
                                        "Autonomic Neuropathy Secondary to Diabetes",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      id: 6,
                                      name: "ros_endocrineIsEndocrineSubautonomicSubdescribeSub",
                                      type: "options_tree",
                                      label: "Describe",
                                      fullWidth: true,
                                      required: true,
                                      xs: 12,
                                      isCommentEnabled: true,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                      subQuestions: [
                                        {
                                          depth: 6,
                                          subQuestionId: 1,
                                          hasSubQuestions: true,
                                          subQuestions: [
                                            {
                                              id: 6,
                                              name: "ros_endocrineIsEndocrineSubAutonomicSubTreat",
                                              type: "options_tree",
                                              label: "Treatment",
                                              isMulti: true,
                                              fullWidth: true,
                                              required: true,
                                              xs: 12,
                                              isCommentEnabled: true,
                                              isConfigured: true,
                                              isModified: true,
                                              hasSubQuestions: true,
                                              subQuestions: [
                                                {
                                                  depth: 6,
                                                  subQuestionId: 1,
                                                  hasSubQuestions: false,
                                                  subQuestions: [],
                                                  editToggle: false,
                                                  isCommentEnabled: false,
                                                  label: "Medication",
                                                  value: "medication",
                                                  isConfigured: true,
                                                  type: "toggle_button",
                                                  name: "medication",
                                                },

                                                {
                                                  subQuestionId: 2,
                                                  hasSubQuestions: true,
                                                  subQuestions: [
                                                    {
                                                      id: 1,
                                                      name: "ros_endocrineIsEndocrineSubautonomicTreatmentBySubotherSubdescribe",
                                                      type: "text",
                                                      label: "Other treatment",
                                                      editToggle: false,
                                                      xs: 4,
                                                      required: "no",
                                                      placeholder: "",
                                                      defaultValue: "",
                                                      isCommentEnabled: false,
                                                      isConfigured: true,
                                                      isModified: true,
                                                      subQuestionId: 1,
                                                      depth: 7,
                                                    },
                                                  ],
                                                  editToggle: false,
                                                  isCommentEnabled: false,
                                                  isConfigured: true,
                                                  isModified: false,
                                                  label: "Other Treatment",
                                                  value: "otherTreatment",
                                                  type: "toggle_button",
                                                  name: "other",
                                                  depth: 6,
                                                },
                                                {
                                                  depth: 6,
                                                  subQuestionId: 1,
                                                  hasSubQuestions: false,
                                                  subQuestions: [],
                                                  editToggle: false,
                                                  isCommentEnabled: false,
                                                  label: "No treatment",
                                                  value: "noTreatment",
                                                  isConfigured: true,
                                                  type: "toggle_button",
                                                  name: "noTreatment",
                                                },
                                              ],
                                              subQuestionId: 1,
                                              editToggle: false,
                                              depth: 5,
                                            },
                                          ],
                                          editToggle: false,
                                          isCommentEnabled: false,
                                          label: "Active",
                                          value: "active",
                                          isConfigured: true,
                                          type: "toggle_button",
                                          name: "active",
                                        },
                                        {
                                          subQuestionId: 2,
                                          hasSubQuestions: false,
                                          subQuestions: [],
                                          editToggle: false,
                                          isCommentEnabled: false,
                                          isConfigured: true,
                                          isModified: false,
                                          label: "History of",
                                          value: "historyOf",
                                          type: "toggle_button",
                                          name: "historyOf",
                                          depth: 6,
                                        },
                                      ],
                                      subQuestionId: 1,
                                      editToggle: false,
                                      depth: 5,
                                    },
                                  ],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label:
                                    "Autonomic Neuropathy Secondary to Diabetes",
                                  value: "autonomic",
                                  isConfigured: true,
                                  type: "toggle_button",
                                  name: "autonomic",
                                },

                                {
                                  depth: 4,
                                  subQuestionId: 7,
                                  hasSubQuestions: true,
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label:
                                        "Chronic Kidney Disease secondary to Diabetes",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      id: 6,
                                      name: "ros_endocrineIsEndocrineSubchronicKidneySubdescribeSub",
                                      type: "options_tree",
                                      label: "Describe",
                                      fullWidth: true,
                                      required: true,
                                      xs: 12,
                                      isCommentEnabled: true,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                      subQuestions: [
                                        {
                                          depth: 6,
                                          subQuestionId: 1,
                                          hasSubQuestions: true,
                                          subQuestions: [
                                            {
                                              id: 6,
                                              name: "ros_endocrineIsEndocrineSubchronicKidneySubStage",
                                              type: "options_tree",
                                              label: "What stage",
                                              fullWidth: true,
                                              required: true,
                                              xs: 12,
                                              isCommentEnabled: true,
                                              isConfigured: true,
                                              isModified: true,
                                              hasSubQuestions: true,
                                              subQuestions: [
                                                {
                                                  depth: 6,
                                                  subQuestionId: 1,
                                                  hasSubQuestions: false,
                                                  subQuestions: [],
                                                  editToggle: false,
                                                  isCommentEnabled: false,
                                                  label: "Stage 1",
                                                  value: "stage1",
                                                  isConfigured: true,
                                                  type: "toggle_button",
                                                  name: "stage1",
                                                },
                                                {
                                                  subQuestionId: 2,
                                                  hasSubQuestions: false,
                                                  subQuestions: [],
                                                  editToggle: false,
                                                  isCommentEnabled: false,
                                                  isConfigured: true,
                                                  isModified: false,
                                                  label: "Stage 2",
                                                  value: "stage2",
                                                  type: "toggle_button",
                                                  name: "stage2",
                                                  depth: 6,
                                                },
                                                {
                                                  subQuestionId: 3,
                                                  hasSubQuestions: false,
                                                  subQuestions: [],
                                                  editToggle: false,
                                                  isCommentEnabled: false,
                                                  isConfigured: true,
                                                  isModified: false,
                                                  label: "Stage 3a",
                                                  value: "stage3a",
                                                  type: "toggle_button",
                                                  name: "stage3a",
                                                  depth: 6,
                                                },
                                                {
                                                  subQuestionId: 4,
                                                  hasSubQuestions: false,
                                                  subQuestions: [],
                                                  editToggle: false,
                                                  isCommentEnabled: false,
                                                  isConfigured: true,
                                                  isModified: false,
                                                  label: "Stage 3b",
                                                  value: "stage3b",
                                                  type: "toggle_button",
                                                  name: "stage3b",
                                                  depth: 6,
                                                },
                                                {
                                                  subQuestionId: 5,
                                                  hasSubQuestions: false,
                                                  subQuestions: [],
                                                  editToggle: false,
                                                  isCommentEnabled: false,
                                                  isConfigured: true,
                                                  isModified: false,
                                                  label: "Stage 4",
                                                  value: "stage4",
                                                  type: "toggle_button",
                                                  name: "stage4",
                                                  depth: 7,
                                                },
                                                {
                                                  subQuestionId: 6,
                                                  hasSubQuestions: false,
                                                  subQuestions: [],
                                                  editToggle: false,
                                                  isCommentEnabled: false,
                                                  isConfigured: true,
                                                  isModified: false,
                                                  label: "Stage 5",
                                                  value: "stage5",
                                                  type: "toggle_button",
                                                  name: "stage5",
                                                  depth: 7,
                                                },
                                                {
                                                  subQuestionId: 7,
                                                  hasSubQuestions: false,
                                                  subQuestions: [],
                                                  editToggle: false,
                                                  isCommentEnabled: false,
                                                  isConfigured: true,
                                                  isModified: false,
                                                  label: "Unknown",
                                                  value: "unknown",
                                                  type: "toggle_button",
                                                  name: "unknown",
                                                  depth: 7,
                                                },
                                              ],
                                              subQuestionId: 1,
                                              editToggle: false,
                                              depth: 5,
                                            },
                                            {
                                              id: 6,
                                              name: "ros_endocrineIsEndocrineSubchronicKidneySubTreat",
                                              type: "options_tree",
                                              label: "Treatment",
                                              isMulti: true,
                                              fullWidth: true,
                                              required: true,
                                              xs: 12,
                                              isCommentEnabled: true,
                                              isConfigured: true,
                                              isModified: true,
                                              hasSubQuestions: true,
                                              subQuestions: [
                                                {
                                                  depth: 6,
                                                  subQuestionId: 1,
                                                  hasSubQuestions: false,
                                                  subQuestions: [],
                                                  editToggle: false,
                                                  isCommentEnabled: false,
                                                  label: "Medication",
                                                  value: "medication",
                                                  isConfigured: true,
                                                  type: "toggle_button",
                                                  name: "medication",
                                                },

                                                {
                                                  subQuestionId: 2,
                                                  hasSubQuestions: true,
                                                  subQuestions: [
                                                    {
                                                      id: 1,
                                                      name: "ros_endocrineIsEndocrineSubchronicKidneySubsupportedBySubotherSubdescribe",
                                                      type: "text",
                                                      label: "Other treatment",
                                                      editToggle: false,
                                                      xs: 4,
                                                      required: "no",
                                                      placeholder: "",
                                                      defaultValue: "",
                                                      isCommentEnabled: false,
                                                      isConfigured: true,
                                                      isModified: true,
                                                      subQuestionId: 1,
                                                      depth: 7,
                                                    },
                                                  ],
                                                  editToggle: false,
                                                  isCommentEnabled: false,
                                                  isConfigured: true,
                                                  isModified: false,
                                                  label: "Other Treatment",
                                                  value: "otherTreatment",
                                                  type: "toggle_button",
                                                  name: "other",
                                                  depth: 6,
                                                },
                                                {
                                                  depth: 6,
                                                  subQuestionId: 1,
                                                  hasSubQuestions: false,
                                                  subQuestions: [],
                                                  editToggle: false,
                                                  isCommentEnabled: false,
                                                  label: "No treatment",
                                                  value: "noTreatment",
                                                  isConfigured: true,
                                                  type: "toggle_button",
                                                  name: "noTreatment",
                                                },
                                              ],
                                              subQuestionId: 2,
                                              editToggle: false,
                                              depth: 5,
                                            },
                                          ],
                                          editToggle: false,
                                          isCommentEnabled: false,
                                          label: "Active",
                                          value: "active",
                                          isConfigured: true,
                                          type: "toggle_button",
                                          name: "active",
                                        },
                                        {
                                          subQuestionId: 2,
                                          hasSubQuestions: false,
                                          subQuestions: [],
                                          editToggle: false,
                                          isCommentEnabled: false,
                                          isConfigured: true,
                                          isModified: false,
                                          label: "History of",
                                          value: "historyOf",
                                          type: "toggle_button",
                                          name: "historyOf",
                                          depth: 6,
                                        },
                                      ],
                                      subQuestionId: 1,
                                      editToggle: false,
                                      depth: 5,
                                    },
                                  ],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label:
                                    "Chronic Kidney Disease secondary to Diabetes",
                                  value: "ckdstDiabetes",
                                  isConfigured: true,
                                  type: "toggle_button",
                                  name: "ckdstDiabetes",
                                },
                                {
                                  subQuestionId: 8,
                                  hasSubQuestions: true,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Other complication",
                                  value: "otherComplication",
                                  type: "toggle_button",
                                  depth: 8,
                                },

                                {
                                  subQuestionId: 9,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "None",
                                  value: "none",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 8,
                              editToggle: false,
                              depth: 7,
                            },
                            {
                              id: 6,
                              name: "ros_endocrineIsEndocrineDiagnosedesdiabetesActiveSubSymp",
                              type: "options_tree",
                              label: "Symptoms",
                              isMulti: true,
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Increased Thirst",
                                  value: "increasedThirst",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Shakiness",
                                  value: "shakiness",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Sweating",
                                  value: "sweating",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 4,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Blurred Vision",
                                  value: "blurredVision",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 5,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Weight Gain",
                                  value: "weightGain",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 6,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Fatigue",
                                  value: "fatigue",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 7,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Hair Changes",
                                  value: "hairChanges",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 8,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Weight Loss",
                                  value: "weightLoss",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 9,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "None",
                                  value: "none",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 9,
                              editToggle: false,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of ",
                          value: "historyOf",
                          type: "toggle_button",
                          name: "historyOf",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                      depth: 5,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Diabetes",
                  value: "diabetes",
                  type: "toggle_button",
                  name: "diabetes",
                  depth: 4,
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Secondary Hyperparathyroidism",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      depth: 5,
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "ros_endocrineIsEndocrineSubsecondaryHyperparathyroidismSubdescribeSub",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubEti",
                              type: "options_tree",
                              label: "Etiology",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Kidney Disease",
                                  value: "kidneyDisease",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Vitamin D Deficiency",
                                  value: "vitaminDef",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Celiac Disease",
                                  value: "celiacDis",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 4,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Malabsorption",
                                  value: "malabsorption",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 5,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Bariatric Surgery",
                                  value: "bariatricSurgery",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 6,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Fractures",
                                  value: "fractures",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 7,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Other",
                                  value: "other",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 8,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Unknown",
                                  value: "unknown",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 1,
                              editToggle: false,
                              depth: 7,
                            },
                            {
                              id: 6,
                              name: "ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubSymp",
                              type: "options_tree",
                              label: "Symptoms",
                              isMulti: true,
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Increased Thirst",
                                  value: "increasedThirst",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Shakiness",
                                  value: "shakiness",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Sweating",
                                  value: "sweating",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 4,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Blurred Vision",
                                  value: "blurredVision",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 5,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Weight Gain",
                                  value: "weightGain",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 6,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Fatigue",
                                  value: "fatigue",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 7,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Hair Changes",
                                  value: "hairChanges",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 8,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Weight Loss",
                                  value: "weightLoss",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 9,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "None",
                                  value: "none",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 2,
                              editToggle: false,
                              depth: 7,
                            },
                            {
                              id: 6,
                              name: "ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubTre",
                              type: "options_tree",
                              label: "Treatment",
                              fullWidth: true,
                              isMulti: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  subQuestions: [
                                    {
                                      id: 1,
                                      name: "ros_endocrineIsEndocrineDiagnosedessecHyperActiveSubTreOtherSub",
                                      type: "text",
                                      label: "Other Treatment",
                                      editToggle: false,
                                      xs: 4,
                                      required: "no",
                                      placeholder: "",
                                      defaultValue: "",
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      subQuestionId: 1,
                                      depth: 9,
                                    },
                                  ],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 3,
                              editToggle: false,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Active",
                          value: "active",
                          type: "toggle_button",
                          name: "active",
                          depth: 6,
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                          name: "historyOf",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                      depth: 5,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Secondary Hyperparathyroidism",
                  value: "secondaryHyperparathyroidism",
                  type: "toggle_button",
                  name: "secondaryHyperparathyroidism",
                  depth: 4,
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Hyperthyroidism",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      depth: 5,
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "ros_endocrineIsEndocrineSubhyperthyroidismSubdescribeSub",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubSymp",
                              type: "options_tree",
                              label: "Symptoms",
                              isMulti: true,
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Rapid Heart Beat",
                                  value: "rapidHeartBeat",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Nervousness",
                                  value: "nervousness",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Weight Loss",
                                  value: "weightLoss",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 4,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Heart Intolerance",
                                  value: "heartIntolerance",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 5,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "None",
                                  value: "none",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 1,
                              editToggle: false,
                              depth: 7,
                            },
                            {
                              id: 6,
                              name: "ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubTre",
                              type: "options_tree",
                              label: "Treatment",
                              isMulti: true,
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  subQuestions: [
                                    {
                                      id: 1,
                                      name: "ros_endocrineIsEndocrineDiagnosedeshyperthyroActiveSubTreOtherSub",
                                      type: "text",
                                      label: "Other Treatment",
                                      editToggle: false,
                                      xs: 4,
                                      required: "no",
                                      placeholder: "",
                                      defaultValue: "",
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      subQuestionId: 1,
                                      depth: 9,
                                    },
                                  ],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 2,
                              editToggle: false,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "active",
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                          name: "historyOf",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 2,
                      depth: 5,
                      editToggle: false,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Hyperthyroidism",
                  value: "hyperthyroidism",
                  type: "toggle_button",
                  name: "hyperthyroidism",
                  depth: 4,
                },
                {
                  subQuestionId: 5,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Hypothyroidism",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      depth: 5,
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "ros_endocrineIsEndocrineSubhypothyroidismSubdescribeSub",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_endocrineIsEndocrineDiagnosedeshypothyroidismActiveSubSymp",
                              type: "options_tree",
                              label: "Symptoms",
                              isMulti: true,
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Weight Gain",
                                  value: "weightGain",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Fatigue",
                                  value: "fatigue",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Hair Loss",
                                  value: "hairLoss",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 4,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "None",
                                  value: "none",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 1,
                              editToggle: false,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "active",
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                          name: "historyOf",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 2,
                      depth: 5,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_endocrineIsEndocrineSubhypothyroidismSubTreatment",
                      type: "options_tree",
                      isMulti: true,
                      label: "Treatment",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Medication",
                          value: "medication",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "medication",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "ros_endocrineIsEndocrineSubhypothyroidismSubTreatmentOtherSubAns",
                              type: "text",
                              label: "Other Treatment",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other Treatment",
                          value: "otherTreatment",
                          type: "toggle_button",
                          name: "otherTreatment",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No Treatment",
                          value: "notreatment",
                          type: "toggle_button",
                          name: "notreatment",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 3,
                      editToggle: false,
                      depth: 5,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Hypothyroidism",
                  value: "hypothyroidism",
                  type: "toggle_button",
                  name: "hypothyroidism",
                  depth: 4,
                },
                {
                  subQuestionId: 6,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Hyperparathyroidism",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      depth: 5,
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "ros_endocrineIsEndocrineDiagnosehyperparathyroidismDescribe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubSymp",
                              type: "options_tree",
                              label: "Symptoms",
                              isMulti: true,
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Fractures",
                                  value: "fractures",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Fatigue",
                                  value: "fatigue",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Other",
                                  value: "other",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 4,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "None",
                                  value: "none",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 1,
                              editToggle: false,
                              depth: 7,
                            },
                            {
                              id: 6,
                              name: "ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubTre",
                              type: "options_tree",
                              label: "Treatment",
                              isMulti: true,
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 8,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  subQuestions: [
                                    {
                                      id: 1,
                                      name: "ros_endocrineIsEndocrineDiagnosedeshyperparaActiveSubTreOtherSub",
                                      type: "text",
                                      label: "Other Treatment",
                                      editToggle: false,
                                      xs: 4,
                                      required: "no",
                                      placeholder: "",
                                      defaultValue: "",
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      subQuestionId: 1,
                                      depth: 9,
                                    },
                                  ],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  type: "toggle_button",
                                  depth: 8,
                                },
                              ],
                              subQuestionId: 2,
                              editToggle: false,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "active",
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                          name: "historyOf",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 2,
                      depth: 5,
                      editToggle: false,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Hyperparathyroidism",
                  value: "hyperparathyroidism",
                  type: "toggle_button",
                  name: "hyperparathyroidism",
                  depth: 4,
                },
                {
                  subQuestionId: 7,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Other",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      depth: 5,
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "ros_endocrineIsEndocrineDiagnoseotherDescribe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "active",
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                          name: "historyOf",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 2,
                      depth: 5,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_endocrineIsEndocrineDiagnoseotherSup",
                      type: "options_tree",
                      label: "Supported by",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "history",
                          label: "History",
                          value: "history",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Symptoms",
                          value: "symptoms",
                          type: "toggle_button",
                          name: "symptoms",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Physical Findings",
                          value: "physicalFindings",
                          type: "toggle_button",
                          name: "physicalFindings",
                          depth: 6,
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Medications",
                          value: "medications",
                          type: "toggle_button",
                          name: "medications",
                          depth: 6,
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Test results",
                          value: "testResults",
                          type: "toggle_button",
                          name: "testResults",
                          depth: 6,
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Image studies",
                          value: "imageStudies",
                          type: "toggle_button",
                          name: "imageStudies",
                          depth: 6,
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Biopsy",
                          value: "biopsy",
                          type: "toggle_button",
                          name: "biopsy",
                          depth: 6,
                        },
                        {
                          subQuestionId: 8,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "DME",
                          value: "dme",
                          type: "toggle_button",
                          name: "dme",
                          depth: 6,
                        },
                        {
                          subQuestionId: 9,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "ros_endocrineIsEndocrineDiagnoseotherSupOtherSubAnswer",
                              type: "text",
                              label: "Describe",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                          name: "other",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 3,
                      depth: 5,
                      editToggle: false,
                    },
                    {
                      id: 1,
                      name: "ros_endocrineIsEndocrineDiagnoseotherOther",
                      type: "text",
                      label: "Other",
                      editToggle: false,
                      xs: 4,
                      required: "no",
                      placeholder: "",
                      defaultValue: "",
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      subQuestionId: 4,
                      depth: 5,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Other",
                  value: "other",
                  type: "toggle_button",
                  name: "other",
                  depth: 4,
                },
              ],
              subQuestionId: 1,
              editToggle: false,
              depth: 3,
            },
          ],
          editToggle: false,
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          isConfigured: true,
          type: "toggle_button",
          name: "yes",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "No",
          value: "no",
          type: "toggle_button",
          name: "no",
          depth: 2,
        },
      ],
      questionId: 45,
      editToggle: false,
      depth: 1,
    },

    {
      id: 10,
      name: "ros_endocrineIsEndocrineRecommendations",
      label: "Recommendations",
      placeholder: "",
      editToggle: false,
      defaultValue: "",
      type: "checkbox",
      options: [
        ["Take medications as prescribed.", "takeMedicationsAsPescribedEndo"],
        ["Check Blood Sugar.", "checkBloodSugarEndo"],
        ["Case management referral for followup.", "casemangfollowEndo"],
        ["Follow up with Clinician/specialist.", "followupwithclinispecEndo"],
        [
          "Member to use Durable Medical Equipment(DME).",
          "durablemedicalequipEndo",
        ],
        [
          "eGFR and albumin-creatinine ratio,discuss with provider.",
          "albuminRatioEndo",
        ],
        ["Other.", "otherEndo"],
      ],
      xs: 12,
      required: false,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      questionId: 46,
      depth: 1,
    },
    {
      id: 10,
      name: "hematology",
      type: "heading",
      label: "HEMATOLOGY / IMMUNOLOGY / INFECTIOUS DISEASE",
      editToggle: false,
      xs: 12,
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 47,
    },
    {
      id: 8,
      name: "ros_hematology",
      type: "options_tree",
      label:
        "Hematology / Immunology / Infection Disease Problems (Anemia, easy bruising or abnormal bleeding Thrombocytopenia , Other)",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 1,
          questionId: 3,
          hasSubQuestions: true,
          editToggle: false,
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          isConfigured: true,
          type: "toggle_button",
          subQuestions: [
            {
              id: 6,
              name: "ros_hematologyDiagnoses",
              value: "diagnoses",
              type: "options_tree",
              label: "Diagnoses",
              fullWidth: true,
              isMulti: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 1,
                  subQuestionId: 1,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  label: "AIDS",
                  value: "aids",
                  name: "ros_hematologyAidsSubAnswer",
                  isConfigured: true,
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "AIDS",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_hematologyAidsSubAnswerDescribe",
                      value: "describe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          subQuestions: [
                            {
                              depth: 2,
                              hasSubQuestions: true,
                              editToggle: false,
                              subQuestionId: 1,
                              isCommentEnabled: true,
                              label: "Treatment",
                              isMulti: true,
                              value: "activeTreatmentSubAnswer",
                              name: "ros_hematologyAidsActiveTreatmentSubAnswer",
                              isConfigured: true,
                              type: "options_tree",
                              subQuestions: [
                                {
                                  depth: 2,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  name: "ros_hematologyAidsSubOtherTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label: "Other Treatment",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      depth: 1,
                                      subQuestionId: 1,
                                      editToggle: false,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: false,
                                      label: "Other Treatment",
                                      xs: 12,
                                      name: "ros_hematologyAidsSubOtherTreatmentSubDesc",
                                      value: "describe",
                                      type: "text",
                                    },
                                  ],
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Anemia",
                  name: "ros_hematologyAnemiaSubAnswer",
                  value: "anemia",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Anemia",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_hematologyAnemiaSubAnswerDescribe",
                      value: "describe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_hematologyAnemiaSubAnswerEtiology",
                              value: "etiology",
                              type: "options_tree",
                              label: "Etiology",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 1,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Iron deficiency",
                                  value: "ironDeficiency",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 1,
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Pernicious",
                                  value: "pernicious",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 1,
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Kidney Disease",
                                  value: "kidneyDisease",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 1,
                                  subQuestionId: 4,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Hemolysis",
                                  value: "hemolysis",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 1,
                                  subQuestionId: 5,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Aplastic",
                                  value: "aplastic",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 1,
                                  subQuestionId: 6,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Chemotherapy",
                                  value: "chemotherapy",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 1,
                                  subQuestionId: 7,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Blood loss",
                                  value: "bloodLoss",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 1,
                                  subQuestionId: 8,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Chronic disease",
                                  value: "chronicDisease",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 1,
                                  subQuestionId: 9,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Folate Deficiency",
                                  value: "folateDeficiency",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 1,
                                  subQuestionId: 10,
                                  hasSubQuestions: true,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Other",
                                  value: "other",
                                  name: "ros_hematologyAnemiaSubEtiologySubOther",
                                  isConfigured: true,
                                  type: "toggle_button",
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label: "Other",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      subQuestionId: 1,
                                      hasSubQuestions: false,
                                      editToggle: false,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: false,
                                      name: "ros_hematologyAnemiaSubEtiologySubOtherSubDesc",
                                      label: "Describe",
                                      value: "describe",
                                      type: "text",
                                      xs: 12,
                                    },
                                  ],
                                },
                              ],
                              subQuestionId: 3,
                              editToggle: false,
                            },
                            {
                              depth: 2,
                              hasSubQuestions: true,
                              editToggle: false,
                              subQuestionId: 2,
                              isCommentEnabled: true,
                              label: "Treatment",
                              isMulti: true,
                              value: "activeTreatmentSubAnswer",
                              name: "ros_hematologyAnemiaActiveTreatmentSubAnswer",
                              isConfigured: true,
                              type: "options_tree",
                              subQuestions: [
                                {
                                  depth: 2,
                                  subQuestionId: 1,
                                  hasSubQuestions: true,
                                  editToggle: false,
                                  isCommentEnabled: true,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label: "Medication",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      id: 6,
                                      name: "ros_hematologyAnemiaSubAnswerYesPatientOn",
                                      value: "yesPatientOn",
                                      type: "options_tree",
                                      label: "If yes, Patient on",
                                      fullWidth: true,
                                      required: true,
                                      xs: 12,
                                      isCommentEnabled: true,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                      subQuestions: [
                                        {
                                          depth: 1,
                                          subQuestionId: 1,
                                          hasSubQuestions: false,
                                          subQuestions: [],
                                          editToggle: false,
                                          isCommentEnabled: false,
                                          label: "Iron",
                                          value: "iron",
                                          isConfigured: true,
                                          type: "toggle_button",
                                        },
                                        {
                                          depth: 1,
                                          subQuestionId: 2,
                                          hasSubQuestions: false,
                                          subQuestions: [],
                                          editToggle: false,
                                          isCommentEnabled: false,
                                          label: "B 12",
                                          value: "bTwelve",
                                          isConfigured: true,
                                          type: "toggle_button",
                                        },
                                        {
                                          depth: 1,
                                          subQuestionId: 3,
                                          hasSubQuestions: false,
                                          subQuestions: [],
                                          editToggle: false,
                                          isCommentEnabled: false,
                                          label: "Folic Acid",
                                          value: "folicAcid",
                                          isConfigured: true,
                                          type: "toggle_button",
                                        },
                                        {
                                          depth: 1,
                                          subQuestionId: 4,
                                          hasSubQuestions: false,
                                          subQuestions: [],
                                          editToggle: false,
                                          isCommentEnabled: false,
                                          label: "Blood Transfusions",
                                          value: "bloodTransfusion",
                                          isConfigured: true,
                                          type: "toggle_button",
                                        },
                                        {
                                          depth: 1,
                                          subQuestionId: 5,
                                          hasSubQuestions: true,
                                          editToggle: false,
                                          isCommentEnabled: false,
                                          label: "Other",
                                          value: "other",
                                          name: "ros_hematologyAnemiaSubYesPatientOn",
                                          isConfigured: true,
                                          type: "toggle_button",
                                          subQuestions: [
                                            {
                                              id: 6,
                                              name: "",
                                              value: "",
                                              type: "label",
                                              label: "Other",
                                              fullWidth: true,
                                              required: false,
                                              xs: 12,
                                              isCommentEnabled: false,
                                              isConfigured: true,
                                              isModified: true,
                                              hasSubQuestions: true,
                                            },
                                            {
                                              depth: 2,
                                              subQuestionId: 1,
                                              hasSubQuestions: false,
                                              subQuestions: [],
                                              editToggle: false,
                                              isCommentEnabled: false,
                                              label: "Describe",
                                              value: "describe",
                                              isConfigured: true,
                                              name: "ros_hematologyAnemiaSubYesPatientOnSubDesc",
                                              type: "text",
                                              xs: 12,
                                            },
                                          ],
                                        },
                                      ],
                                      subQuestionId: 1,
                                      editToggle: false,
                                    },
                                  ],
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  name: "ros_hematologyAnemiaSubOtherTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label: "Other Treatment",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      depth: 1,
                                      subQuestionId: 1,
                                      editToggle: false,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: false,
                                      label: "Other Treatment",
                                      xs: 12,
                                      name: "ros_hematologyAnemiaSubOtherTreatmentSubDesc",
                                      value: "describe",
                                      type: "text",
                                    },
                                  ],
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 4,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Blood Transfusion",
                                  value: "bloodTransfusion",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "C. Difficile",
                  value: "cDifficile",
                  name: "ros_hematologyCDifficileSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "C. Difficile",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_hematologyCDifficileSubAnswerDescribe",
                      value: "describe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: true,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          subQuestions: [
                            {
                              depth: 2,
                              hasSubQuestions: true,
                              editToggle: false,
                              subQuestionId: 1,
                              isCommentEnabled: true,
                              label: "Treatment",
                              isMulti: true,
                              value: "activeTreatmentSubAnswer",
                              name: "ros_hematologyCDifficileActiveTreatmentSubAnswer",
                              isConfigured: true,
                              type: "options_tree",
                              subQuestions: [
                                {
                                  depth: 2,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  name: "ros_hematologyCDifficileSubOtherTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label: "Other Treatment",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      depth: 1,
                                      subQuestionId: 1,
                                      editToggle: false,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: false,
                                      label: "Other Treatment",
                                      xs: 12,
                                      name: "ros_hematologyCDifficileSubOtherTreatmentSubDesc",
                                      value: "describe",
                                      type: "text",
                                    },
                                  ],
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                          subQuestions: [],
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Community Acquired MRSA Infection",
                  value: "communityAcquired",
                  name: "ros_hematologyCommunityAcquiredSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Community Acquired MRSA Infection",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_hematologyCommunityAcquiredSubAnswerDescribe",
                      value: "describe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          subQuestions: [
                            {
                              depth: 2,
                              hasSubQuestions: true,
                              editToggle: false,
                              subQuestionId: 1,
                              isCommentEnabled: true,
                              label: "Treatment",
                              isMulti: true,
                              value: "activeTreatmentSubAnswer",
                              name: "ros_hematologyCommunityAcquiredActiveTreatmentSubAnswer",
                              isConfigured: true,
                              type: "options_tree",
                              subQuestions: [
                                {
                                  depth: 2,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  name: "ros_hematologyCommunityAcquiredSubOtherTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label: "Other Treatment",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      depth: 1,
                                      subQuestionId: 1,
                                      editToggle: false,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: false,
                                      label: "Other Treatment",
                                      xs: 12,
                                      name: "ros_hematologyCommunityAcquiredSubOtherTreatmentSubDesc",
                                      value: "describe",
                                      type: "text",
                                    },
                                  ],
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 5,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "HIV",
                  value: "hiv",
                  name: "ros_hematologyHivSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "HIV",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_hematologyHivSubAnswerDescribe",
                      type: "options_tree",
                      value: "describe",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          subQuestions: [
                            {
                              depth: 2,
                              hasSubQuestions: true,
                              editToggle: false,
                              subQuestionId: 1,
                              isCommentEnabled: true,
                              label: "Treatment",
                              isMulti: true,
                              value: "activeTreatmentSubAnswer",
                              name: "ros_hematologyHivActiveTreatmentSubAnswer",
                              isConfigured: true,
                              type: "options_tree",
                              subQuestions: [
                                {
                                  depth: 2,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  name: "ros_hematologyHivSubOtherTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label: "Other Treatment",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      depth: 1,
                                      subQuestionId: 1,
                                      editToggle: false,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: false,
                                      label: "Other Treatment",
                                      xs: 12,
                                      name: "ros_hematologyHivSubOtherTreatmentSubDesc",
                                      value: "describe",
                                      type: "text",
                                    },
                                  ],
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 4,
                      name: "ros_hematologyHivSubAnswerViralLoad",
                      depth: 3,
                      type: "text",
                      label: "Viral load",
                      value: "viralLoad",
                      editToggle: false,
                      xs: 12,
                      required: false,
                      placeholder: "",
                      defaultValue: "",
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      subQuestionId: 3,
                      hasSubQuestions: false,
                    },
                    {
                      id: 5,
                      name: "ros_hematologyHivSubAnswerCFour",
                      depth: 3,
                      type: "text",
                      label: "C4",
                      value: "cFour",
                      editToggle: false,
                      xs: 12,
                      required: false,
                      placeholder: "",
                      defaultValue: "",
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      subQuestionId: 4,
                      hasSubQuestions: false,
                    },
                    {
                      subQuestionId: 5,
                      hasSubQuestions: true,
                      editToggle: false,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: false,
                      label: "Patient currently symptomatic",
                      value: "patientSymptomatic",
                      name: "ros_hematologyHivSubAnswerPatientSymptomatic",
                      type: "options_tree",
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Yes",
                          value: "yes",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                        },
                      ],
                    },
                    {
                      subQuestionId: 6,
                      hasSubQuestions: true,
                      editToggle: false,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: false,
                      name: "ros_hematologyHivSubAnswerActiveTreatment",
                      label: "Is patient currently under active treatment",
                      value: "activeTreatment",
                      type: "options_tree",
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Yes",
                          value: "yes",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                        },
                      ],
                    },
                  ],
                },
                {
                  subQuestionId: 6,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Herpes Infection",
                  value: "herpesZoster",
                  name: "ros_hematologyHerpesZosterSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Herpes Infection",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_hematologyHerpesZosterSubAnswerDescribe",
                      value: "describe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          subQuestions: [
                            {
                              depth: 2,
                              hasSubQuestions: true,
                              editToggle: false,
                              subQuestionId: 1,
                              isCommentEnabled: true,
                              label: "Type",
                              value: "activeTreatmentSubAnswer",
                              name: "ros_hematologyHerpesZosterTypeSubAnswer",
                              isConfigured: true,
                              type: "options_tree",
                              subQuestions: [
                                {
                                  depth: 2,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Herpes Zoster",
                                  value: "herpesZoster",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Herpes Simplex Virus 1",
                                  value: "herpesSimplex1",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Herpes Simplex Virus 2",
                                  value: "herpesSimplex2",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 4,
                                  hasSubQuestions: true,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Other",
                                  value: "other",
                                  name: "ros_hematologyHerpesZosterSubOther",
                                  isConfigured: true,
                                  type: "toggle_button",
                                  subQuestions: [
                                    {
                                      depth: 1,
                                      subQuestionId: 1,
                                      editToggle: false,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: false,
                                      label: "Other",
                                      xs: 12,
                                      name: "ros_hematologyHerpesZosterActiveSubOtherSubDesc",
                                      value: "describe",
                                      type: "text",
                                    },
                                  ],
                                },
                              ],
                            },
                            {
                              depth: 2,
                              hasSubQuestions: true,
                              editToggle: false,
                              subQuestionId: 2,
                              isCommentEnabled: true,
                              label: "Treatment",
                              isMulti: true,
                              value: "activeTreatmentSubAnswer",
                              name: "ros_hematologyHerpesZosterActiveTreatmentSubAnswer",
                              isConfigured: true,
                              type: "options_tree",
                              subQuestions: [
                                {
                                  depth: 2,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  name: "ros_hematologyHerpesZosterSubOtherTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label: "Other Treatment",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      depth: 1,
                                      subQuestionId: 1,
                                      editToggle: false,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: false,
                                      label: "Other Treatment",
                                      xs: 12,
                                      name: "ros_hematologyHerpesZosterSubOtherTreatmentSubDesc",
                                      value: "describe",
                                      type: "text",
                                    },
                                  ],
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                          subQuestions: [
                            {
                              depth: 2,
                              hasSubQuestions: true,
                              editToggle: false,
                              subQuestionId: 1,
                              isCommentEnabled: true,
                              label: "Type",
                              value: "historyOfTreatmentSubAnswer",
                              name: "ros_hematologyHerpesZosterHistoryOfTypeSubAnswer",
                              isConfigured: true,
                              type: "options_tree",
                              subQuestions: [
                                {
                                  depth: 2,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Herpes Zoster",
                                  value: "herpesZoster",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Herpes Simplex Virus 1",
                                  value: "herpesSimplex1",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Herpes Simplex Virus 2",
                                  value: "herpesSimplex2",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 4,
                                  hasSubQuestions: true,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Other",
                                  value: "other",
                                  name: "ros_hematologyHerpesZosterSubOther",
                                  isConfigured: true,
                                  type: "toggle_button",
                                  subQuestions: [
                                    {
                                      depth: 1,
                                      subQuestionId: 1,
                                      editToggle: false,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: false,
                                      label: "Other",
                                      xs: 12,
                                      name: "ros_hematologyHerpesZosterHistoryOfSubOtherSubDesc",
                                      value: "describe",
                                      type: "text",
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 7,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Hospital Acquired MRSA Infection",
                  value: "mrsaInfection",
                  name: "ros_hematologyMrsaInfectionSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Hospital Acquired MRSA Infection",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_hematologyMrsaInfectionSubAnswerDescribe",
                      value: "describe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      depth: 2,
                      hasSubQuestions: true,
                      editToggle: false,
                      subQuestionId: 2,
                      isCommentEnabled: true,
                      label: "Treatment",
                      isMulti: true,
                      value: "activeTreatmentSubAnswer",
                      name: "ros_hematologyMrsaInfectionActiveTreatmentSubAnswer",
                      isConfigured: true,
                      type: "options_tree",
                      subQuestions: [
                        {
                          depth: 2,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Medication",
                          value: "medication",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          depth: 2,
                          subQuestionId: 2,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Other Treatment",
                          value: "otherTreatment",
                          name: "ros_hematologyMrsaInfectionSubOtherTreatment",
                          isConfigured: true,
                          type: "toggle_button",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other Treatment",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              depth: 1,
                              subQuestionId: 1,
                              editToggle: false,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: false,
                              label: "Other Treatment",
                              xs: 12,
                              name: "ros_hematologyMrsaInfectionSubOtherTreatmentSubDesc",
                              value: "describe",
                              type: "text",
                            },
                          ],
                        },
                        {
                          depth: 2,
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "No Treatment",
                          value: "noTreatment",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                      ],
                    },
                  ],
                },
                {
                  subQuestionId: 8,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Immune Deficiency",
                  value: "immuneDeficiency",
                  name: "ros_hematologyImmuneDeficiencySubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Immune Deficiency",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_hematologyImmuneDeficiencySubAnswerDescribe",
                      type: "options_tree",
                      value: "describe",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          subQuestions: [
                            {
                              depth: 2,
                              hasSubQuestions: true,
                              editToggle: false,
                              subQuestionId: 1,
                              isCommentEnabled: true,
                              label: "Treatment",
                              isMulti: true,
                              value: "activeTreatmentSubAnswer",
                              name: "ros_hematologyImmuneDeficiencyActiveTreatmentSubAnswer",
                              isConfigured: true,
                              type: "options_tree",
                              subQuestions: [
                                {
                                  depth: 2,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  name: "ros_hematologyImmuneDeficiencySubOtherTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label: "Other Treatment",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      depth: 1,
                                      subQuestionId: 1,
                                      editToggle: false,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: false,
                                      label: "Other Treatment",
                                      xs: 12,
                                      name: "ros_hematologyImmuneDeficiencySubOtherTreatmentSubDesc",
                                      value: "describe",
                                      type: "text",
                                    },
                                  ],
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 4,
                      name: "ros_hematologyImmuneDeficiencySubAnswerEtiology",
                      depth: 3,
                      type: "text",
                      label: "Etiology",
                      value: "etiology",
                      editToggle: false,
                      xs: 12,
                      required: false,
                      placeholder: "",
                      defaultValue: "",
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      subQuestionId: 2,
                      hasSubQuestions: false,
                    },
                  ],
                },
                {
                  subQuestionId: 9,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Sepsis",
                  value: "sepsis",
                  name: "ros_hematologySepsisSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Sepsis",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_hematologySepsisSubAnswerDescribe",
                      value: "describe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          subQuestions: [
                            {
                              depth: 2,
                              hasSubQuestions: true,
                              editToggle: false,
                              subQuestionId: 1,
                              isCommentEnabled: true,
                              label: "Treatment",
                              isMulti: true,
                              value: "activeTreatmentSubAnswer",
                              name: "ros_hematologySepsisActiveTreatmentSubAnswer",
                              isConfigured: true,
                              type: "options_tree",
                              subQuestions: [
                                {
                                  depth: 2,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  name: "ros_hematologySepsisSubOtherTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label: "Other Treatment",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      depth: 1,
                                      subQuestionId: 1,
                                      editToggle: false,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: false,
                                      label: "Other Treatment",
                                      xs: 12,
                                      name: "ros_hematologySepsisSubOtherTreatmentSubDesc",
                                      value: "describe",
                                      type: "text",
                                    },
                                  ],
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 10,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Sickle Cell Disease",
                  value: "sickleCellDisease",
                  name: "ros_hematologySickleCellDiseaseSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Sickle Cell Disease",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_hematologySickleCellDiseaseSubAnswerDescribe",
                      value: "describe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          subQuestions: [
                            {
                              depth: 2,
                              hasSubQuestions: true,
                              editToggle: false,
                              subQuestionId: 2,
                              isCommentEnabled: true,
                              label: "Family History?",
                              value: "activeTreatmentSubAnswer",
                              name: "ros_hematologySickleCellDiseaseFamilyTreatmentSubAnswer",
                              isConfigured: true,
                              type: "options_tree",
                              subQuestions: [
                                {
                                  depth: 1,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Yes",
                                  value: "yes",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  // depth: 2,
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "No",
                                  value: "no",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  // depth: 2,
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "UnKnown",
                                  value: "unknown",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                            {
                              depth: 2,
                              hasSubQuestions: true,
                              editToggle: false,
                              subQuestionId: 3,
                              isCommentEnabled: true,
                              label: "Treatment",
                              isMulti: true,
                              value: "activeTreatmentSubAnswer",
                              name: "ros_hematologySickleCellDiseaseActiveTreatmentSubAnswer",
                              isConfigured: true,
                              type: "options_tree",
                              subQuestions: [
                                {
                                  depth: 2,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  name: "ros_hematologySickleCellDiseaseSubOtherTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label: "Other Treatment",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      depth: 1,
                                      subQuestionId: 1,
                                      editToggle: false,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: false,
                                      label: "Other Treatment",
                                      xs: 12,
                                      name: "ros_hematologySickleCellDiseaseSubOtherTreatmentSubDesc",
                                      value: "describe",
                                      type: "text",
                                    },
                                  ],
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 11,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Sickle Cell Trait",
                  value: "sickleCellTrait",
                  name: "ros_hematologySickleCellTraitSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Sickle Cell Trait",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_hematologySickleCellTraitSubAnswerDescribe",
                      value: "describe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          subQuestions: [
                            {
                              depth: 2,
                              hasSubQuestions: true,
                              editToggle: false,
                              subQuestionId: 1,
                              isCommentEnabled: true,
                              label: "Treatment",
                              isMulti: true,
                              value: "activeTreatmentSubAnswer",
                              name: "ros_hematologySickleCellTraitActiveTreatmentSubAnswer",
                              isConfigured: true,
                              type: "options_tree",
                              subQuestions: [
                                {
                                  depth: 2,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  name: "ros_hematologySickleCellTraitSubOtherTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label: "Other Treatment",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      depth: 1,
                                      subQuestionId: 1,
                                      editToggle: false,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: false,
                                      label: "Other Treatment",
                                      xs: 12,
                                      name: "ros_hematologySickleCellTraitSubOtherTreatmentSubDesc",
                                      value: "describe",
                                      type: "text",
                                    },
                                  ],
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 12,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Thalassemia",
                  value: "thalassemia",
                  name: "ros_hematologyThalassemiaSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Thalassemia",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_hematologyThalassemiaSubAnswerDescribe",
                      value: "describe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          subQuestions: [
                            {
                              depth: 2,
                              hasSubQuestions: true,
                              editToggle: false,
                              subQuestionId: 1,
                              isCommentEnabled: true,
                              label: "Treatment",
                              isMulti: true,
                              value: "activeTreatmentSubAnswer",
                              name: "ros_hematologyThalassemiaActiveTreatmentSubAnswer",
                              isConfigured: true,
                              type: "options_tree",
                              subQuestions: [
                                {
                                  depth: 2,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  name: "ros_hematologyThalassemiaSubOtherTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label: "Other Treatment",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      depth: 1,
                                      subQuestionId: 1,
                                      editToggle: false,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: false,
                                      label: "Other Treatment",
                                      xs: 12,
                                      name: "ros_hematologyThalassemiaSubOtherTreatmentSubDesc",
                                      value: "describe",
                                      type: "text",
                                    },
                                  ],
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 13,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Thrombocytopenia",
                  value: "thrombocytopenia",
                  name: "ros_hematologyThrombocytopeniaSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Thrombocytopenia",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },

                    {
                      id: 6,
                      name: "ros_hematologyThrombocytopeniaSubAnswerDescribe",
                      value: "describe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          subQuestions: [
                            {
                              depth: 2,
                              hasSubQuestions: true,
                              editToggle: false,
                              subQuestionId: 1,
                              isCommentEnabled: true,
                              label: "Treatment",
                              isMulti: true,
                              value: "activeTreatmentSubAnswer",
                              name: "ros_hematologyThrombocytopeniaActiveTreatmentSubAnswer",
                              isConfigured: true,
                              type: "options_tree",
                              subQuestions: [
                                {
                                  depth: 2,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  name: "ros_hematologyThrombocytopeniaSubOtherTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label: "Other Treatment",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      depth: 1,
                                      subQuestionId: 1,
                                      editToggle: false,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: false,
                                      label: "Other Treatment",
                                      xs: 12,
                                      name: "ros_hematologyThrombocytopeniaSubOtherTreatmentSubDesc",
                                      value: "describe",
                                      type: "text",
                                    },
                                  ],
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 4,
                      name: "ros_hematologyThrombocytopeniaSubAnswerEtiology",
                      depth: 3,
                      type: "text",
                      label: "Etiology",
                      value: "etiology",
                      editToggle: false,
                      xs: 12,
                      required: false,
                      placeholder: "",
                      defaultValue: "",
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      subQuestionId: 3,
                      hasSubQuestions: false,
                    },
                  ],
                },
                {
                  subQuestionId: 14,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Tuberculosis",
                  value: "tuberculosis",
                  name: "ros_hematologyTuberculosisSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Tuberculosis",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_hematologyTuberculosisSubAnswerDescribe",
                      value: "describe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          subQuestions: [
                            {
                              id: 6,
                              name: "ros_hematologyTuberculosisSubAnswerGivenBCG",
                              value: "givenBCG",
                              type: "options_tree",
                              label: "Has patient been given BCG",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 1,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Yes",
                                  value: "yes",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "No",
                                  value: "no",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Unknown",
                                  value: "unknown",
                                  type: "toggle_button",
                                },
                              ],
                              subQuestionId: 1,
                              editToggle: false,
                            },
                            {
                              id: 6,
                              name: "ros_hematologyTuberculosisSubAnswerActiveTuberculosis",
                              value: "activeTuberculosis",
                              type: "options_tree",
                              label:
                                "Has patient been treated for active Tuberculosis",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 1,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Yes",
                                  value: "yes",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "No",
                                  value: "no",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Unknown",
                                  value: "unknown",
                                  type: "toggle_button",
                                },
                              ],
                              subQuestionId: 2,
                              editToggle: false,
                            },

                            {
                              id: 6,
                              name: "ros_hematologyTuberculosisSubAnswerTbInfection",
                              value: "tbInfection",
                              type: "options_tree",
                              label:
                                "Has patient been treated for TB Infection",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: true,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                              subQuestions: [
                                {
                                  depth: 1,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Yes",
                                  value: "yes",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 2,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "No",
                                  value: "no",
                                  type: "toggle_button",
                                },
                                {
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  subQuestions: [],
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  isConfigured: true,
                                  isModified: false,
                                  label: "Unknown",
                                  value: "unknown",
                                  type: "toggle_button",
                                },
                              ],
                              subQuestionId: 3,
                              editToggle: false,
                            },
                            {
                              depth: 2,
                              hasSubQuestions: true,
                              editToggle: false,
                              subQuestionId: 4,
                              isCommentEnabled: true,
                              label: "Treatment",
                              isMulti: true,
                              value: "activeTreatmentSubAnswer",
                              name: "ros_hematologyTuberculosisActiveTreatmentSubAnswer",
                              isConfigured: true,
                              type: "options_tree",
                              subQuestions: [
                                {
                                  depth: 2,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  name: "ros_hematologyTuberculosisSubOtherTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label: "Other Treatment",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      depth: 1,
                                      subQuestionId: 1,
                                      editToggle: false,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: false,
                                      label: "Other Treatment",
                                      xs: 12,
                                      name: "ros_hematologyTuberculosisSubOtherTreatmentSubDesc",
                                      value: "describe",
                                      type: "text",
                                    },
                                  ],
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of Active TB",
                          value: "historyOfActiveTb",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "TB infection(positive PPD)",
                          value: "tbInfection",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 15,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Vitamin D Deficiency",
                  value: "vitaminDDeficiency",
                  name: "ros_hematologyVitaminDDeficiencySubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Vitamin D Deficiency",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_hematologyVitaminDDeficiencySubAnswerDescribe",
                      value: "describe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                          subQuestions: [
                            {
                              depth: 2,
                              hasSubQuestions: true,
                              editToggle: false,
                              subQuestionId: 1,
                              isCommentEnabled: true,
                              label: "Treatment",
                              isMulti: true,
                              value: "activeTreatmentSubAnswer",
                              name: "ros_hematologyVitaminDDeficiencyActiveTreatmentSubAnswer",
                              isConfigured: true,
                              type: "options_tree",
                              subQuestions: [
                                {
                                  depth: 2,
                                  subQuestionId: 1,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Medication",
                                  value: "medication",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 2,
                                  hasSubQuestions: true,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "Other Treatment",
                                  value: "otherTreatment",
                                  name: "ros_hematologyVitaminDDeficiencySubOtherTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                  subQuestions: [
                                    {
                                      id: 6,
                                      name: "",
                                      value: "",
                                      type: "label",
                                      label: "Other Treatment",
                                      fullWidth: true,
                                      required: false,
                                      xs: 12,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: true,
                                      hasSubQuestions: true,
                                    },
                                    {
                                      depth: 1,
                                      subQuestionId: 1,
                                      editToggle: false,
                                      isCommentEnabled: false,
                                      isConfigured: true,
                                      isModified: false,
                                      label: "Other Treatment",
                                      xs: 12,
                                      name: "ros_hematologyVitaminDDeficiencySubOtherTreatmentSubDesc",
                                      value: "describe",
                                      type: "text",
                                    },
                                  ],
                                },
                                {
                                  depth: 2,
                                  subQuestionId: 3,
                                  hasSubQuestions: false,
                                  editToggle: false,
                                  isCommentEnabled: false,
                                  label: "No Treatment",
                                  value: "noTreatment",
                                  isConfigured: true,
                                  type: "toggle_button",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                  ],
                },
                {
                  subQuestionId: 16,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Bone Marrow Transplant",
                  value: "boneMarrowTransplant",
                  name: "ros_hematologyBoneMarrowTransplantSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Bone Marrow Transplant",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      depth: 1,
                      subQuestionId: 1,
                      editToggle: false,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: false,
                      label: "Date of Transplant (Month, Day, Year)",
                      xs: 12,
                      name: "ros_hematologyBoneMarrowTransplantSubOtherTreatmentSubDesc",
                      value: "dateOfTransplant",
                      type: "text",
                    },
                  ],
                },
                {
                  subQuestionId: 17,
                  hasSubQuestions: true,
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Other",
                  value: "other",
                  name: "ros_hematologyOtherSubAnswer",
                  type: "toggle_button",
                  subQuestions: [
                    {
                      id: 6,
                      name: "",
                      value: "",
                      type: "label",
                      label: "Other",
                      fullWidth: true,
                      required: false,
                      xs: 12,
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                    },
                    {
                      id: 6,
                      name: "ros_hematologyHemaOtherSubAnswerDescribe",
                      value: "describe",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Active",
                          value: "active",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "History of",
                          value: "historyOf",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                    {
                      id: 6,
                      name: "ros_hematologyHemaOtherSubAnswerSupportedBy",
                      value: "supportedBy",
                      type: "options_tree",
                      label: " Supported by",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "History",
                          value: "history",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Symptoms",
                          value: "symptoms",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Physical Findings",
                          value: "physicalFindings",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Medications",
                          value: "medications",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Test Results",
                          value: "testResults",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Image studies",
                          value: "imageStudies",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Biopsy",
                          value: "biopsy",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 8,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "DME",
                          value: "dme",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 9,
                          hasSubQuestions: true,
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          name: "ros_hematologyHemaOtherSubOther",
                          type: "options_tree",
                          subQuestions: [
                            {
                              id: 6,
                              name: "",
                              value: "",
                              type: "label",
                              label: "Other",
                              fullWidth: true,
                              required: false,
                              xs: 12,
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                            {
                              name: "ros_hematologyHemaOtherSubOtherSubDesc",
                              type: "text",
                              value: "describe",
                              label: "Describe",
                              fullWidth: true,
                              required: true,
                              xs: 12,
                              isCommentEnabled: false,
                              subQuestionId: 1,
                              isConfigured: true,
                              isModified: true,
                              hasSubQuestions: true,
                            },
                          ],
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                    },
                    {
                      id: 4,
                      name: "ros_hematologyHemaOtherSubAnswerOther",
                      depth: 3,
                      type: "text",
                      label: "Other",
                      value: "other",
                      editToggle: false,
                      xs: 12,
                      required: false,
                      placeholder: "",
                      defaultValue: "",
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      subQuestionId: 3,
                      hasSubQuestions: false,
                    },
                  ],
                },
              ],
              subQuestionId: 1,
              editToggle: false,
            },
          ],
        },
        {
          questionId: 4,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 48,
      editToggle: false,
    },
    {
      id: 10,
      name: "hema_Recommendations",
      value: "takeMedicationsAsPescribed",
      label: "Recommendations",
      placeholder: "",
      editToggle: false,
      defaultValue: "",
      type: "checkbox",
      options: [
        ["Take medications as prescribed.", "takeMedicationsAsPescribed"],
        ["Report abnormal bruising or bleeding.", "reportAbnormalBruising"],
        ["Follow up with doctor for lab work.", "followUpWithDoctor"],
        ["Case management referral for follow up.", "caseManagement"],
        ["Follow up with Clinician/Specialist.", "followUpClinician"],
        ["Member to use Durable Medical Equipment (DME).", "DME"],
        ["Other.", "other"],
      ],
      xs: 12,
      required: false,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      questionId: 49,
    },
    {
      id: 9,
      name: "cancer",
      type: "heading",
      label: "CANCER",
      editToggle: false,
      xs: 12,
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 50,
    },
    {
      id: 6,
      name: "ros_diagnosisOfCancer",
      type: "options_tree",
      label: "Diagnosis of Cancer",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 2,
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "ros_diagnosisOfCancerSubDesc",
              type: "options_tree",
              label: "Describe",
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 4,
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  name: "active",
                  label: "Active",
                  value: "active",
                  isConfigured: true,
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "History of",
                  value: "historyOf",
                  type: "toggle_button",
                  name: "historyOf",
                  depth: 4,
                },
              ],
              subQuestionId: 1,
              depth: 3,
              editToggle: false,
            },

            {
              id: 6,
              name: "ros_diagnosisOfCancerSubType",
              type: "options_tree",
              label: "Type",
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 4,
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  name: "brain",
                  label: "Brain",
                  value: "brain",
                  isConfigured: true,
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Head",
                  value: "head",
                  type: "toggle_button",
                  name: "head",
                  depth: 4,
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Neck",
                  value: "neck",
                  type: "toggle_button",
                  name: "neck",
                  depth: 4,
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_diagnosisOfCancertitl5Years",
                      type: "options_tree",
                      label: "Treatment in the last 5 years?",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 1,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Yes",
                          value: "yes",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Breast",
                  value: "breast",
                  type: "toggle_button",
                  name: "breast",
                  depth: 3,
                },
                {
                  subQuestionId: 5,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Lung",
                  value: "lung",
                  type: "toggle_button",
                  name: "lung",
                  depth: 4,
                },
                {
                  subQuestionId: 6,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Esophagus",
                  value: "esophagus",
                  type: "toggle_button",
                  name: "esophagus",
                  depth: 4,
                },
                {
                  subQuestionId: 7,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Stomach",
                  value: "stomach",
                  type: "toggle_button",
                  name: "stomach",
                  depth: 4,
                },
                {
                  subQuestionId: 8,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Liver",
                  value: "liver",
                  type: "toggle_button",
                  name: "liver",
                  depth: 4,
                },
                {
                  subQuestionId: 9,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Pancreas",
                  value: "pancreas",
                  type: "toggle_button",
                  name: "pancreas",
                  depth: 4,
                },
                {
                  subQuestionId: 10,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Colon",
                  value: "colon",
                  type: "toggle_button",
                  name: "colon",
                  depth: 4,
                },
                {
                  subQuestionId: 11,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Rectum",
                  value: "rectum",
                  type: "toggle_button",
                  name: "rectum",
                  depth: 4,
                },
                {
                  subQuestionId: 12,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Kidney",
                  value: "kidney",
                  type: "toggle_button",
                  name: "kidney",
                  depth: 4,
                },
                {
                  subQuestionId: 13,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Bladder",
                  value: "bladder",
                  type: "toggle_button",
                  name: "bladder",
                  depth: 4,
                },
                {
                  subQuestionId: 14,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Ovaries",
                  value: "ovaries",
                  type: "toggle_button",
                  name: "ovaries",
                  depth: 4,
                },
                {
                  subQuestionId: 15,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Uterus",
                  value: "uterus",
                  type: "toggle_button",
                  name: "uterus",
                  depth: 4,
                },
                {
                  subQuestionId: 16,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Prostate",
                  value: "prostate",
                  type: "toggle_button",
                  name: "prostate",
                  depth: 4,
                },
                {
                  subQuestionId: 17,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Bone",
                  value: "bone",
                  type: "toggle_button",
                  name: "bone",
                  depth: 4,
                },
                {
                  subQuestionId: 18,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Blood",
                  value: "blood",
                  type: "toggle_button",
                  name: "blood",
                  depth: 4,
                },
                {
                  subQuestionId: 19,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Lymph Nodes",
                  value: "lymphNodes",
                  type: "toggle_button",
                  name: "lymphNodes",
                  depth: 4,
                },
                {
                  subQuestionId: 20,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Skin",
                  value: "skin",
                  type: "toggle_button",
                  name: "skin",
                  depth: 4,
                },

                {
                  subQuestionId: 21,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 1,
                      name: "ros_diagnosisOfCancerSubTypeOtherValSubAnswer",
                      type: "text",
                      label: "Describe",
                      editToggle: false,
                      xs: 4,
                      required: "no",
                      placeholder: "",
                      defaultValue: "",
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      subQuestionId: 1,
                      depth: 7,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Other",
                  value: "other",
                  type: "toggle_button",
                  name: "other",
                  depth: 6,
                },
              ],
              subQuestionId: 3,
              depth: 3,
              editToggle: false,
            },
            {
              id: 1,
              name: "ros_diagnosisOfCancerspecialTypes",
              type: "text",
              label: "Specific type/s",
              editToggle: false,
              xs: 4,
              required: "no",
              placeholder: "",
              defaultValue: "",
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 4,
              depth: 3,
            },
            {
              id: 1,
              name: "ros_diagnosisOfCancerstageSpecificToTheCancer",
              type: "text",
              label: "Stage or Classification specific to the cancer",
              editToggle: false,
              xs: 5,
              required: "no",
              placeholder: "",
              defaultValue: "",
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 5,
              depth: 3,
            },
            {
              id: 6,
              name: "ros_diagnosisOfCancerros_activeTreatment",
              type: "options_tree",
              label: "Active treatment ",
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 4,
                  subQuestionId: 1,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "ros_diagnosisOfCancerSubActiveTreat",
                      type: "options_tree",
                      label: "Active treatment    ",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "chemotherapy",
                          label: "Chemotherapy",
                          value: "chemotherapy",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Radiation",
                          value: "radiation",
                          type: "toggle_button",
                          name: "radiation",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Stem Cell",
                          value: "stemCell",
                          type: "toggle_button",
                          name: "stemCell",
                          depth: 6,
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Bone Marrow",
                          value: "boneMarrow",
                          type: "toggle_button",
                          name: "boneMarrow",
                          depth: 6,
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Surgery",
                          value: "surgery",
                          type: "toggle_button",
                          name: "surgery",
                          depth: 6,
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Immune System",
                          value: "immuneSystem",
                          type: "toggle_button",
                          name: "immuneSystem",
                          depth: 6,
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "ros_diagnosisOfCancerSubActiveTreatOtherValSubAnswer",
                              type: "text",
                              label: "Describe",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                          name: "other",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                      depth: 5,
                    },
                    {
                      id: 6,
                      name: "ros_diagnosisOfCancerSubSideEff",
                      type: "options_tree",
                      label: "Side effects",
                      isMulti: true,
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          name: "nausea",
                          label: "Nausea",
                          value: "nausea",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Vomiting",
                          value: "vomiting",
                          type: "toggle_button",
                          name: "vomiting",
                          depth: 6,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Diarrhea",
                          value: "diarrhea",
                          type: "toggle_button",
                          name: "diarrhea",
                          depth: 6,
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Anemia",
                          value: "anemia",
                          type: "toggle_button",
                          name: "anemia",
                          depth: 6,
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Neutropenia",
                          value: "neutropenia",
                          type: "toggle_button",
                          name: "neutropenia",
                          depth: 6,
                        },
                        {
                          subQuestionId: 6,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Thrombocytopenia",
                          value: "thrombocytopenia",
                          type: "toggle_button",
                          name: "thrombocytopenia",
                          depth: 6,
                        },
                        {
                          subQuestionId: 7,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Weakness",
                          value: "weakness",
                          type: "toggle_button",
                          name: "weakness",
                          depth: 6,
                        },
                        {
                          subQuestionId: 8,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Loss of appetite",
                          value: "lossOfAppetite",
                          type: "toggle_button",
                          name: "lossOfAppetite",
                          depth: 6,
                        },
                        {
                          subQuestionId: 9,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "ros_diagnosisOfCancerSubActiveTreatSideEffOtherValSubAnswer",
                              type: "text",
                              label: "Describe",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                              depth: 7,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                          name: "other",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                      depth: 5,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  name: "yes",
                  label: "Yes",
                  value: "yes",
                  isConfigured: true,
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "No",
                  value: "no",
                  type: "toggle_button",
                  name: "no",
                  depth: 4,
                },
              ],
              subQuestionId: 6,
              depth: 3,
              editToggle: false,
            },
            {
              id: 6,
              name: "ros_diagnosisOfCancerros_Metastasis",
              type: "options_tree",
              label: "Is there a current finding of Metastasis?",
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 4,
                  subQuestionId: 1,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 1,
                      name: "ros_diagnosisOfCancerSubMetastasisLocVal",
                      type: "text",
                      label: "Location",
                      editToggle: false,
                      xs: 4,
                      required: "no",
                      placeholder: "",
                      defaultValue: "",
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      subQuestionId: 1,
                      depth: 5,
                    },
                    {
                      id: 6,
                      name: "ros_diagnosisOfCancerSubMetastasisCachexiaVal",
                      type: "options_tree",
                      label: "History / finding of Cachexia",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 6,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Yes",
                          value: "yes",
                          isConfigured: true,
                          type: "toggle_button",
                          name: "yes",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                          name: "no",
                          depth: 6,
                        },
                      ],
                      subQuestionId: 2,
                      editToggle: false,
                      depth: 5,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  name: "yes",
                  label: "Yes",
                  value: "yes",
                  isConfigured: true,
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "No",
                  value: "no",
                  type: "toggle_button",
                  name: "no",
                  depth: 4,
                },
              ],
              subQuestionId: 7,
              depth: 3,
              editToggle: false,
            },
            {
              id: 6,
              name: "ros_diagnosisOfCancerros_SeeASpec",
              type: "options_tree",
              label: "Do you see a specialist?",
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 4,
                  subQuestionId: 1,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 1,
                      name: "ros_diagnosisOfCancerSubSeeASpecProVal",
                      type: "text",
                      label: "Provider",
                      editToggle: false,
                      xs: 4,
                      required: "no",
                      placeholder: "",
                      defaultValue: "",
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      subQuestionId: 1,
                      depth: 5,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  name: "yes",
                  label: "Yes",
                  value: "yes",
                  isConfigured: true,
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "No",
                  value: "no",
                  type: "toggle_button",
                  name: "no",
                  depth: 4,
                },
              ],
              subQuestionId: 8,
              depth: 3,
              editToggle: false,
            },
          ],
          editToggle: false,
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          isConfigured: true,
          type: "toggle_button",
          name: "yes",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "No",
          value: "no",
          type: "toggle_button",
          name: "no",
          depth: 2,
        },
      ],
      questionId: 51,
      editToggle: false,
      depth: 1,
    },
    {
      id: 10,
      name: "ros_diagnosisOfCancerRecommendations",
      label: "Recommendations",
      placeholder: "",
      editToggle: false,
      defaultValue: "",
      type: "checkbox",
      options: [
        [
          "Take medications as prescribed.",
          "takemedicationsasprescribedCancer",
        ],
        [
          "Case management referral for followup.",
          "CasemanagementfollowupreferralCancer",
        ],
        ["Followup with Clinician/Specialist.", "followupwithclinispecCancer"],
        [
          "Member to use Durable Medical Equipment(DME).",
          "membertousedurablemedicalequipCancer",
        ],
        ["Other.", "otherCancer"],
      ],
      xs: 12,
      required: false,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      questionId: 52,
    },
  ],
};
export const getInputsData = (client = "VPHP", year = "2023") => {
  let formLayoutData = {};
  if (year === "2023") {
    formLayoutData = {
      ...formLayoutData,
      ...json2023,
    };
  }
  if (year === "2024") {
    formLayoutData = {
      ...formLayoutData,
      ...json2024,
    };
  }

  return formLayoutData;
};
