import {
  deleteSubTreeData,
  getObjectData,
  // getOptionsSelected,
  getSubTreeString,
  setObjectData,
  // setOptionsSelected,
} from "../../../../../../utils/helpers.DynamicForm";

/*

============= FLATMAP =============

for get/set the nested level DB-JSON data back & forth using lodash
It's an array of arrays. Inner array has 3 values -> [ flatmap_value,db_json_path,year_list_array ]
<- example ->
refer home safety

======= OPTIONS TREE MAPPER =======

for get/set the options tree selected value by comparing with formdata json
It's an object where key-value pair will be flatmap value-value to be assigned in options tree if it is "1"
it must match your options tree's corresponding toggle button's value field
<- example ->
refer home safety

======= OPTIONS TREE WRAPPER =======

for populate the selected value(s) of an options tree by combining list of flatmap values.
It's an array of arrays. Inner array has 2 elements -> [grouped_string,[list_of_flattened_values_to_be_grouped]]
this grouped string must be assigned to your options tree's name field.

<- example ->
refer home safety

*/

const examReview_flatmap = [
  [
    "exam_genAppearNormalVal",
    "constitutional.generalAppearance.normal",
    ["2023", "2024"],
  ],
  [
    "exam_genAppearAbnormalVal",
    "constitutional.generalAppearance.abnormal",
    ["2023", "2024"],
  ],
  [
    "exam_genAppearAbnormalSubDescVal",
    "constitutional.generalAppearance.abnormalSubAnswers.describe",
    ["2023", "2024"],
  ],
  [
    "exam_genAppearCommentVal",
    "constitutional.generalAppearance.comment",
    ["2023", "2024"],
  ],

  [
    "exam_headFaceExamNormalVal",
    "headAndFace.examination.normal",
    ["2023", "2024"],
  ],
  [
    "exam_headFaceExamAbnormalVal",
    "headAndFace.examination.abnormal",
    ["2023", "2024"],
  ],
  [
    "exam_headFaceExamAbnormalSubDescVal",
    "headAndFace.examination.abnormalSubAnswers.describe",
    ["2023", "2024"],
  ],
  [
    "exam_headFaceExamCommentVal",
    "headAndFace.examination.comment",
    ["2023", "2024"],
  ],
  [
    "exam_headFacePalpNormalVal",
    "headAndFace.palpation.normal",
    ["2023", "2024"],
  ],
  [
    "exam_headFacePalpAbnormalVal",
    "headAndFace.palpation.abnormal",
    ["2023", "2024"],
  ],
  [
    "exam_headFacePalpAbnormalSubDescVal",
    "headAndFace.palpation.abnormalSubAnswers.describe",
    ["2023", "2024"],
  ],
  [
    "exam_headFacePalpCommentVal",
    "headAndFace.palpation.comment",
    ["2023", "2024"],
  ],

  ["exam_eyesInspNormalVal", "eyes.inspection.normal", ["2023", "2024"]],
  ["exam_eyesInspAbnormalVal", "eyes.inspection.abnormal", ["2023", "2024"]],
  [
    "exam_eyesInspAbnormalSubDescVal",
    "eyes.inspection.abnormalSubAnswers.describe",
    ["2023", "2024"],
  ],
  ["exam_eyesInspCommentVal", "eyes.inspection.comment", ["2023", "2024"]],
  ["exam_eyesExamNormalVal", "eyes.examination.normal", ["2023", "2024"]],
  ["exam_eyesExamAbnormalVal", "eyes.examination.abnormal", ["2023", "2024"]],
  [
    "exam_eyesExamAbnormalSubDescVal",
    "eyes.examination.abnormalSubAnswers.describe",
    ["2023", "2024"],
  ],
  ["exam_eyesExamCommentVal", "eyes.examination.comment", ["2023", "2024"]],

  [
    "exam_entExtInspNormalVal",
    "earsNoseMouthThroat.externalInspection.normal",
    ["2023", "2024"],
  ],
  [
    "exam_entExtInspAbnormalVal",
    "earsNoseMouthThroat.externalInspection.abnormal",
    ["2023", "2024"],
  ],
  [
    "exam_entExtInspAbnormalSubDescVal",
    "earsNoseMouthThroat.externalInspection.abnormalSubAnswers.describe",
    ["2023", "2024"],
  ],
  [
    "exam_entExtInspCommentVal",
    "earsNoseMouthThroat.externalInspection.comment",
    ["2023", "2024"],
  ],
  [
    "exam_entOtosExamNormalVal",
    "earsNoseMouthThroat.otoscopicExamination.normal",
    ["2023", "2024"],
  ],
  [
    "exam_entOtosExamAbnormalVal",
    "earsNoseMouthThroat.otoscopicExamination.abnormal",
    ["2023", "2024"],
  ],
  [
    "exam_entOtosExamAbnormalSubDescVal",
    "earsNoseMouthThroat.otoscopicExamination.abnormalSubAnswers.describe",
    ["2023", "2024"],
  ],
  [
    "exam_entOtosExamCommentVal",
    "earsNoseMouthThroat.otoscopicExamination.comment",
    ["2023", "2024"],
  ],
  [
    "exam_entAssessHearNormalVal",
    "earsNoseMouthThroat.assessmentOfHearing.normal",
    ["2023", "2024"],
  ],
  [
    "exam_entAssessHearAbnormalVal",
    "earsNoseMouthThroat.assessmentOfHearing.abnormal",
    ["2023", "2024"],
  ],
  [
    "exam_entAssessHearAbnormalSubDescVal",
    "earsNoseMouthThroat.assessmentOfHearing.abnormalSubAnswers.describe",
    ["2023", "2024"],
  ],
  [
    "exam_entAssessHearCommentVal",
    "earsNoseMouthThroat.assessmentOfHearing.comment",
    ["2023", "2024"],
  ],
  [
    "exam_entNasalInspNormalVal",
    "earsNoseMouthThroat.inspectionOfNasal.normal",
    ["2023", "2024"],
  ],
  [
    "exam_entNasalInspAbnormalVal",
    "earsNoseMouthThroat.inspectionOfNasal.abnormal",
    ["2023", "2024"],
  ],
  [
    "exam_entNasalInspAbnormalSubDescVal",
    "earsNoseMouthThroat.inspectionOfNasal.abnormalSubAnswers.describe",
    ["2023", "2024"],
  ],
  [
    "exam_entNasalInspCommentVal",
    "earsNoseMouthThroat.inspectionOfNasal.comment",
    ["2023", "2024"],
  ],
  [
    "exam_entInspLipsNormalVal",
    "earsNoseMouthThroat.inspectionOfLips.normal",
    ["2023", "2024"],
  ],
  [
    "exam_entInspLipsAbnormalVal",
    "earsNoseMouthThroat.inspectionOfLips.abnormal",
    ["2023", "2024"],
  ],
  [
    "exam_entInspLipsAbnormalSubDescVal",
    "earsNoseMouthThroat.inspectionOfLips.abnormalSubAnswers.describe",
    ["2023", "2024"],
  ],
  [
    "exam_entInspLipsCommentVal",
    "earsNoseMouthThroat.inspectionOfLips.comment",
    ["2023", "2024"],
  ],
  [
    "exam_entOropExamNormalVal",
    "earsNoseMouthThroat.examinationOfOropharynx.normal",
    ["2023", "2024"],
  ],
  [
    "exam_entOropExamAbnormalVal",
    "earsNoseMouthThroat.examinationOfOropharynx.abnormal",
    ["2023", "2024"],
  ],
  [
    "exam_entOropExamAbnormalSubDescVal",
    "earsNoseMouthThroat.examinationOfOropharynx.abnormalSubAnswers.describe",
    ["2023", "2024"],
  ],
  [
    "exam_entOropExamCommentVal",
    "earsNoseMouthThroat.examinationOfOropharynx.comment",
    ["2023", "2024"],
  ],

  ["exam_neckExamNormalVal", "neck.examinationOfNeck.normal", ["2023", "2024"]],
  [
    "exam_neckExamAbnormalVal",
    "neck.examinationOfNeck.abnormal",
    ["2023", "2024"],
  ],
  [
    "exam_neckExamAbnormalSubDescVal",
    "neck.examinationOfNeck.abnormalSubAnswers.describe",
    ["2023", "2024"],
  ],
  [
    "exam_neckExamCommentVal",
    "neck.examinationOfNeck.comment",
    ["2023", "2024"],
  ],
  [
    "exam_neckExamThyroidNormalVal",
    "neck.examinationOfThyroid.normal",
    ["2023", "2024"],
  ],
  [
    "exam_neckExamThyroidAbnormalVal",
    "neck.examinationOfThyroid.abnormal",
    ["2023", "2024"],
  ],
  [
    "exam_neckExamThyroidAbnormalSubDescVal",
    "neck.examinationOfThyroid.abnormalSubAnswers.describe",
    ["2023", "2024"],
  ],
  [
    "exam_neckExamThyroidCommentVal",
    "neck.examinationOfThyroid.comment",
    ["2023", "2024"],
  ],

  [
    "exam_pulmAssessRespNormalVal",
    "pulmonary.assessmentOfRespiratory.normal",
    ["2023", "2024"],
  ],
  [
    "exam_pulmAssessRespAbnormalVal",
    "pulmonary.assessmentOfRespiratory.abnormal",
    ["2023", "2024"],
  ],
  [
    "exam_pulmAssessRespAbnormalSubDescVal",
    "pulmonary.assessmentOfRespiratory.abnormalSubAnswers.describe",
    ["2023", "2024"],
  ],
  [
    "exam_pulmAssessRespCommentVal",
    "pulmonary.assessmentOfRespiratory.comment",
    ["2023", "2024"],
  ],
  [
    "exam_pulmAuscLungsNormalVal",
    "pulmonary.auscultationOfLungs.normal",
    ["2023", "2024"],
  ],
  [
    "exam_pulmAuscLungsAbnormalVal",
    "pulmonary.auscultationOfLungs.abnormal",
    ["2023", "2024"],
  ],
  [
    "exam_pulmAuscLungsAbnormalSubDescVal",
    "pulmonary.auscultationOfLungs.abnormalSubAnswers.describe",
    ["2023", "2024"],
  ],
  [
    "exam_pulmAuscLungsCommentVal",
    "pulmonary.auscultationOfLungs.comment",
    ["2023", "2024"],
  ],

  [
    "exam_cardioAusHeartNormalVal",
    "cardiovascular.auscultationOfHeart.normal",
    ["2023", "2024"],
  ],
  [
    "exam_cardioAusHeartAbnormalVal",
    "cardiovascular.auscultationOfHeart.abnormal",
    ["2023", "2024"],
  ],
  [
    "exam_cardioAusHeartAbnormalSubDescVal",
    "cardiovascular.auscultationOfHeart.abnormalSubAnswers.describe",
    ["2023", "2024"],
  ],
  [
    "exam_cardioAusHeartCommentVal",
    "cardiovascular.auscultationOfHeart.comment",
    ["2023", "2024"],
  ],
  [
    "exam_cardioPalpAusNormalVal",
    "cardiovascular.palpationAndAuscultation.normal",
    ["2023", "2024"],
  ],
  [
    "exam_cardioPalpAusAbnormalVal",
    "cardiovascular.palpationAndAuscultation.abnormal",
    ["2023", "2024"],
  ],
  [
    "exam_cardioPalpAusAbnormalSubDescVal",
    "cardiovascular.palpationAndAuscultation.abnormalSubAnswers.describe",
    ["2023", "2024"],
  ],
  [
    "exam_cardioPalpAusCommentVal",
    "cardiovascular.palpationAndAuscultation.comment",
    ["2023", "2024"],
  ],
  [
    "exam_cardioPedalPulseNormalVal",
    "cardiovascular.pedalPulses.normal",
    ["2023", "2024"],
  ],
  [
    "exam_cardioPedalPulseAbnormalVal",
    "cardiovascular.pedalPulses.abnormal",
    ["2023", "2024"],
  ],
  [
    "exam_cardioPedalPulseAbnormalSubDescVal",
    "cardiovascular.pedalPulses.abnormalSubAnswers.describe",
    ["2023", "2024"],
  ],
  [
    "exam_cardioPedalPulseCommentVal",
    "cardiovascular.pedalPulses.comment",
    ["2023", "2024"],
  ],
  [
    "exam_cardioExamEdemaNormalVal",
    "cardiovascular.examinationOfEdema.normal",
    ["2023", "2024"],
  ],
  [
    "exam_cardioExamEdemaAbnormalVal",
    "cardiovascular.examinationOfEdema.abnormal",
    ["2023", "2024"],
  ],
  [
    "exam_cardioExamEdemaAbnormalSubDescVal",
    "cardiovascular.examinationOfEdema.abnormalSubAnswers.describe",
    ["2023", "2024"],
  ],
  [
    "exam_cardioExamEdemaCommentVal",
    "cardiovascular.examinationOfEdema.comment",
    ["2023", "2024"],
  ],
  [
    "exam_cardioExamRadialNormalVal",
    "cardiovascular.examinationOfRadialPulses.normal",
    ["2023", "2024"],
  ],
  [
    "exam_cardioExamRadialAbnormalVal",
    "cardiovascular.examinationOfRadialPulses.abnormal",
    ["2023", "2024"],
  ],
  [
    "exam_cardioExamRadialAbnormalSubDescVal",
    "cardiovascular.examinationOfRadialPulses.abnormalSubAnswers.describe",
    ["2023", "2024"],
  ],
  [
    "exam_cardioExamRadialCommentVal",
    "cardiovascular.examinationOfRadialPulses.comment",
    ["2023", "2024"],
  ],

  [
    "exam_lympPalpCerviNormalVal",
    "lymphatic.palpationOfCervical.normal",
    ["2023", "2024"],
  ],
  [
    "exam_lympPalpCerviAbnormalVal",
    "lymphatic.palpationOfCervical.abnormal",
    ["2023", "2024"],
  ],
  [
    "exam_lympPalpCerviAbnormalSubDescVal",
    "lymphatic.palpationOfCervical.abnormalSubAnswers.describe",
    ["2023", "2024"],
  ],
  [
    "exam_lympPalpCerviCommentVal",
    "lymphatic.palpationOfCervical.comment",
    ["2023", "2024"],
  ],
  [
    "exam_lympPalpPreauricNormalVal",
    "lymphatic.palpationOfPreauricular.normal",
    ["2023", "2024"],
  ],
  [
    "exam_lympPalpPreauricAbnormalVal",
    "lymphatic.palpationOfPreauricular.abnormal",
    ["2023", "2024"],
  ],
  [
    "exam_lympPalpPreauricAbnormalSubDescVal",
    "lymphatic.palpationOfPreauricular.abnormalSubAnswers.describe",
    ["2023", "2024"],
  ],
  [
    "exam_lympPalpPreauricCommentVal",
    "lymphatic.palpationOfPreauricular.comment",
    ["2023", "2024"],
  ],
  [
    "exam_lympPalpSubmandiNormalVal",
    "lymphatic.palpationOfSubmandibular.normal",
    ["2023", "2024"],
  ],
  [
    "exam_lympPalpSubmandiAbnormalVal",
    "lymphatic.palpationOfSubmandibular.abnormal",
    ["2023", "2024"],
  ],
  [
    "exam_lympPalpSubmandiAbnormalSubDescVal",
    "lymphatic.palpationOfSubmandibular.abnormalSubAnswers.describe",
    ["2023", "2024"],
  ],
  [
    "exam_lympPalpSubmandiCommentVal",
    "lymphatic.palpationOfSubmandibular.comment",
    ["2023", "2024"],
  ],

  [
    "exam_muscSkelExamGaitNormalVal",
    "musculoskeletal.examinationOfGait.normal",
    ["2023", "2024"],
  ],
  [
    "exam_muscSkelExamGaitAbnormalVal",
    "musculoskeletal.examinationOfGait.abnormal",
    ["2023", "2024"],
  ],
  [
    "exam_muscSkelExamGaitAbnormalSubDescVal",
    "musculoskeletal.examinationOfGait.abnormalSubAnswers.describe",
    ["2023", "2024"],
  ],
  [
    "exam_muscSkelExamGaitCommentVal",
    "musculoskeletal.examinationOfGait.comment",
    ["2023", "2024"],
  ],
  [
    "exam_muscSkelInspDigitNormalVal",
    "musculoskeletal.inspectionOfDigits.normal",
    ["2023", "2024"],
  ],
  [
    "exam_muscSkelInspDigitAbnormalVal",
    "musculoskeletal.inspectionOfDigits.abnormal",
    ["2023", "2024"],
  ],
  [
    "exam_muscSkelInspDigitAbnormalSubDescVal",
    "musculoskeletal.inspectionOfDigits.abnormalSubAnswers.describe",
    ["2023", "2024"],
  ],
  [
    "exam_muscSkelInspDigitCommentVal",
    "musculoskeletal.inspectionOfDigits.comment",
    ["2023", "2024"],
  ],
  [
    "exam_muscSkelInspJointNormalVal",
    "musculoskeletal.inspectionOfJoints.normal",
    ["2023", "2024"],
  ],
  [
    "exam_muscSkelInspJointAbnormalVal",
    "musculoskeletal.inspectionOfJoints.abnormal",
    ["2023", "2024"],
  ],
  [
    "exam_muscSkelInspJointAbnormalSubDescVal",
    "musculoskeletal.inspectionOfJoints.abnormalSubAnswers.describe",
    ["2023", "2024"],
  ],
  [
    "exam_muscSkelInspJointCommentVal",
    "musculoskeletal.inspectionOfJoints.comment",
    ["2023", "2024"],
  ],
  [
    "exam_muscSkelAssessRangeNormalVal",
    "musculoskeletal.assessmentOfRange.normal",
    ["2023", "2024"],
  ],
  [
    "exam_muscSkelAssessRangeAbnormalVal",
    "musculoskeletal.assessmentOfRange.abnormal",
    ["2023", "2024"],
  ],
  [
    "exam_muscSkelAssessRangeAbnormalSubDescVal",
    "musculoskeletal.assessmentOfRange.abnormalSubAnswers.describe",
    ["2023", "2024"],
  ],
  [
    "exam_muscSkelAssessRangeCommentVal",
    "musculoskeletal.assessmentOfRange.comment",
    ["2023", "2024"],
  ],
  [
    "exam_muscSkelAssessStableNormalVal",
    "musculoskeletal.assessmentOfStability.normal",
    ["2023", "2024"],
  ],
  [
    "exam_muscSkelAssessStableAbnormalVal",
    "musculoskeletal.assessmentOfStability.abnormal",
    ["2023", "2024"],
  ],
  [
    "exam_muscSkelAssessStableAbnormalSubDescVal",
    "musculoskeletal.assessmentOfStability.abnormalSubAnswers.describe",
    ["2023", "2024"],
  ],
  [
    "exam_muscSkelAssessStableCommentVal",
    "musculoskeletal.assessmentOfStability.comment",
    ["2023", "2024"],
  ],
  [
    "exam_muscSkelAssessMuscleNormalVal",
    "musculoskeletal.assessmentOfMuscle.normal",
    ["2023", "2024"],
  ],
  [
    "exam_muscSkelAssessMuscleAbnormalVal",
    "musculoskeletal.assessmentOfMuscle.abnormal",
    ["2023", "2024"],
  ],
  [
    "exam_muscSkelAssessMuscleAbnormalSubDescVal",
    "musculoskeletal.assessmentOfMuscle.abnormalSubAnswers.describe",
    ["2023", "2024"],
  ],
  [
    "exam_muscSkelAssessMuscleCommentVal",
    "musculoskeletal.assessmentOfMuscle.comment",
    ["2023", "2024"],
  ],
  [
    "exam_skinInspSkinNormalVal",
    "skin.inspectionOfSkin.normal",
    ["2023", "2024"],
  ],
  [
    "exam_skinInspSkinAbnormalVal",
    "skin.inspectionOfSkin.abnormal",
    ["2023", "2024"],
  ],
  [
    "exam_skinInspSkinAbnormalSubDescVal",
    "skin.inspectionOfSkin.abnormalSubAnswers.describe",
    ["2023", "2024"],
  ],
  [
    "exam_skinInspSkinCommentVal",
    "skin.inspectionOfSkin.comment",
    ["2023", "2024"],
  ],

  [
    "exam_neuroCranialTestVal",
    "neurologic.specificCranialNerve",
    ["2023", "2024"],
  ],
  [
    "exam_neuroCranialTestIndicateVal",
    "neurologic.indicateCranialNerveDeficits",
    ["2023", "2024"],
  ],
  [
    "exam_neuroRombergNormalVal",
    "neurologic.rombergTest.normal",
    ["2023", "2024"],
  ],
  [
    "exam_neuroRombergAbnormalVal",
    "neurologic.rombergTest.abnormal",
    ["2023", "2024"],
  ],
  [
    "exam_neuroRombergAbnormalSubDescVal",
    "neurologic.rombergTest.abnormalSubAnswers.describe",
    ["2023", "2024"],
  ],
  [
    "exam_neuroRombergCommentVal",
    "neurologic.rombergTest.comment",
    ["2023", "2024"],
  ],
  [
    "exam_neuroReflexNormalVal",
    "neurologic.examinationOfReflexes.normal",
    ["2023", "2024"],
  ],
  [
    "exam_neuroReflexAbnormalVal",
    "neurologic.examinationOfReflexes.abnormal",
    ["2023", "2024"],
  ],
  [
    "exam_neuroReflexAbnormalSubDescVal",
    "neurologic.examinationOfReflexes.abnormalSubAnswers.describe",
    ["2023", "2024"],
  ],
  [
    "exam_neuroReflexCommentVal",
    "neurologic.examinationOfReflexes.comment",
    ["2023", "2024"],
  ],
  [
    "exam_neuroSenseNormalVal",
    "neurologic.examinationOfSensation.normal",
    ["2023", "2024"],
  ],
  [
    "exam_neuroSenseAbnormalVal",
    "neurologic.examinationOfSensation.abnormal",
    ["2023", "2024"],
  ],
  [
    "exam_neuroSenseAbnormalSubDescVal",
    "neurologic.examinationOfSensation.abnormalSubAnswers.describe",
    ["2023", "2024"],
  ],
  [
    "exam_neuroSenseCommentVal",
    "neurologic.examinationOfSensation.comment",
    ["2023", "2024"],
  ],
  [
    "exam_neuroCoordinateNormalVal",
    "neurologic.coordination.normal",
    ["2023", "2024"],
  ],
  [
    "exam_neuroCoordinateAbnormalVal",
    "neurologic.coordination.abnormal",
    ["2023", "2024"],
  ],
  [
    "exam_neuroCoordinateAbnormalSubDescVal",
    "neurologic.coordination.abnormalSubAnswers.describe",
    ["2023", "2024"],
  ],
  [
    "exam_neuroCoordinateCommentVal",
    "neurologic.coordination.comment",
    ["2023", "2024"],
  ],

  [
    "exam_noGpcogMmsePatientOrientPersonYesVal",
    "neurologic.patientOrientedToPerson.yes",
    ["2024"],
  ],
  [
    "exam_noGpcogMmsePatientOrientPersonNoVal",
    "neurologic.patientOrientedToPerson.no",
    ["2024"],
  ],
  [
    "exam_noGpcogMmsePatientOrientPersonNoSubDescVal",
    "neurologic.patientOrientedToPerson.noSubAnswers.describe",
    ["2024"],
  ],
  [
    "exam_noGpcogMmsePatientOrientPersonCommentVal",
    "neurologic.patientOrientedToPerson.comment",
    ["2024"],
  ],

  [
    "exam_noGpcogMmsePatientOrientPlaceYesVal",
    "neurologic.patientOrientedToPlace.yes",
    ["2024"],
  ],
  [
    "exam_noGpcogMmsePatientOrientPlaceNoVal",
    "neurologic.patientOrientedToPlace.no",
    ["2024"],
  ],
  [
    "exam_noGpcogMmsePatientOrientPlaceNoSubDescVal",
    "neurologic.patientOrientedToPlace.noSubAnswers.describe",
    ["2024"],
  ],
  [
    "exam_noGpcogMmsePatientOrientPlaceCommentVal",
    "neurologic.patientOrientedToPlace.comment",
    ["2024"],
  ],

  [
    "exam_noGpcogMmsePatientOrientTimeYesVal",
    "neurologic.patientOrientedToTime.yes",
    ["2024"],
  ],
  [
    "exam_noGpcogMmsePatientOrientTimeNoVal",
    "neurologic.patientOrientedToTime.no",
    ["2024"],
  ],
  [
    "exam_noGpcogMmsePatientOrientTimeNoSubDescVal",
    "neurologic.patientOrientedToTime.noSubAnswers.describe",
    ["2024"],
  ],
  [
    "exam_noGpcogMmsePatientOrientTimeCommentVal",
    "neurologic.patientOrientedToTime.comment",
    ["2024"],
  ],

  ["exam_noGpcogMmseRecallGoodVal", "neurologic.recall.good", ["2024"]],
  ["exam_noGpcogMmseRecallPoorVal", "neurologic.recall.poor", ["2024"]],
  [
    "exam_noGpcogMmseRecallPoorSubDescVal",
    "neurologic.recall.poorSubAnswers.describe",
    ["2024"],
  ],
  ["exam_noGpcogMmseRecallCommentVal", "neurologic.recall.comment", ["2024"]],

  [
    "exam_noGpcogMmsePatientDescribeNewsYesVal",
    "neurologic.patientDescribesRecentNews.yes",
    ["2024"],
  ],
  [
    "exam_noGpcogMmsePatientDescribeNewsPartiallyVal",
    "neurologic.patientDescribesRecentNews.partially",
    ["2024"],
  ],
  [
    "exam_noGpcogMmsePatientDescribeNewsNoVal",
    "neurologic.patientDescribesRecentNews.no",
    ["2024"],
  ],
  [
    "exam_noGpcogMmsePatientDescribeNewsCommentVal",
    "neurologic.patientDescribesRecentNews.comment",
    ["2024"],
  ],
  ["exam_psychMoodNormalVal", "neurologic.affect.normal", ["2024"]],
  ["exam_psychMoodAbnormalVal", "neurologic.affect.abnormal", ["2024"]],
  [
    "exam_psychMoodSubParanoiaVal",
    "neurologic.affect.abnormalSubAnswers.paranoia",
    ["2024"],
  ],
  [
    "exam_psychMoodSubDelusionalVal",
    "neurologic.affect.abnormalSubAnswers.delusional",
    ["2024"],
  ],
  [
    "exam_psychMoodSubDisorganizedThoughtVal",
    "neurologic.affect.abnormalSubAnswers.disorganizedThought",
    ["2024"],
  ],
  [
    "exam_psychMoodSubFlatVal",
    "neurologic.affect.abnormalSubAnswers.flat",
    ["2024"],
  ],
  [
    "exam_psychMoodSubManicVal",
    "neurologic.affect.abnormalSubAnswers.manic",
    ["2024"],
  ],
  [
    "exam_psychMoodSubDepressedVal",
    "neurologic.affect.abnormalSubAnswers.depressed",
    ["2024"],
  ],
  [
    "exam_psychMoodSubOtherVal",
    "neurologic.affect.abnormalSubAnswers.other",
    ["2024"],
  ],

  ["exam_psychMoodCommentVal", "neurologic.affect.comment", ["2024"]],
  [
    "exam_psychMoodSubCommentVal",
    "neurologic.affect.abnormalSubAnswers.comment",
    ["2024"],
  ],

  ["ros_speechNormalVal", "neurologic.speech.normal", ["2024"]],
  ["ros_speechAbnormalVal", "neurologic.speech.abnormal", ["2024"]],
  [
    "ros_speechSubSlurredVal",
    "neurologic.speech.abnormalSubAnswers.slurred",
    ["2024"],
  ],
  [
    "ros_speechSubAphasicVal",
    "neurologic.speech.abnormalSubAnswers.aphasic",
    ["2024"],
  ],
  [
    "ros_speechSubApraxiaVal",
    "neurologic.speech.abnormalSubAnswers.apraxia",
    ["2024"],
  ],

  ["ros_speechCommentVal", "neurologic.speech.comment", ["2024"]],
  [
    "ros_speechSubCommentVal",
    "neurologic.speech.abnormalSubAnswers.comment",
    ["2024"],
  ],

  ["ros_fingerToNoseNormalVal", "neurologic.fingerToNose.normal", ["2024"]],
  ["ros_fingerToNoseAbnormalVal", "neurologic.fingerToNose.abnormal", ["2024"]],
  [
    "ros_fingerToNoseAbnormalSubLeftVal",
    "neurologic.fingerToNose.abnormalSubAnswers.left",
    ["2024"],
  ],
  [
    "ros_fingerToNoseAbnormalSubRightVal",
    "neurologic.fingerToNose.abnormalSubAnswers.right",
    ["2024"],
  ],
  [
    "ros_fingerToNoseAbnormalSubBothVal",
    "neurologic.fingerToNose.abnormalSubAnswers.both",
    ["2024"],
  ],

  ["ros_fingerToNoseCommentVal", "neurologic.fingerToNose.comment", ["2024"]],
  [
    "ros_fingerToNoseAbnormalSubCommentVal",
    "neurologic.fingerToNose.abnormalSubAnswers.comment",
    ["2024"],
  ],

  ["ros_heelToToeNormalVal", "neurologic.heelToToe.normal", ["2024"]],
  ["ros_heelToToeAbnormalVal", "neurologic.heelToToe.abnormal", ["2024"]],
  [
    "ros_heelToToeAbnormalSubLeftVal",
    "neurologic.heelToToe.abnormalSubAnswers.left",
    ["2024"],
  ],
  [
    "ros_heelToToeAbnormalSubRightVal",
    "neurologic.heelToToe.abnormalSubAnswers.right",
    ["2024"],
  ],
  [
    "ros_heelToToeAbnormalSubBothVal",
    "neurologic.heelToToe.abnormalSubAnswers.both",
    ["2024"],
  ],

  ["ros_heelToToeCommentVal", "neurologic.heelToToe.comment", ["2024"]],
  [
    "ros_heelToToeAbnormalSubCommentVal",
    "neurologic.heelToToe.abnormalSubAnswers.comment",
    ["2024"],
  ],

  [
    "ros_thumbToFingerNormalVal",
    "neurologic.thumbToFingerTips.normal",
    ["2024"],
  ],
  [
    "ros_thumbToFingerAbnormalVal",
    "neurologic.thumbToFingerTips.abnormal",
    ["2024"],
  ],
  [
    "ros_thumbToFingerAbnormalSubLeftVal",
    "neurologic.thumbToFingerTips.abnormalSubAnswers.left",
    ["2024"],
  ],
  [
    "ros_thumbToFingerAbnormalSubRightVal",
    "neurologic.thumbToFingerTips.abnormalSubAnswers.right",
    ["2024"],
  ],
  [
    "ros_thumbToFingerAbnormalSubBothVal",
    "neurologic.thumbToFingerTips.abnormalSubAnswers.both",
    ["2024"],
  ],

  [
    "ros_thumbToFingerCommentVal",
    "neurologic.thumbToFingerTips.comment",
    ["2024"],
  ],
  [
    "ros_thumbToFingerAbnormalSubCommentVal",
    "neurologic.thumbToFingerTips.abnormalSubAnswers.comment",
    ["2024"],
  ],

  [
    "ros_sitToStandVal",
    "neurologic.sittingToStanding.isSittingToStanding",
    ["2024"],
  ],
  [
    "ros_sitToStandCommentVal",
    "neurologic.sittingToStanding.comment",
    ["2024"],
  ],

  [
    "ros_extremeMoveVal",
    "neurologic.facialMovement.isFacialMovement",
    ["2024"],
  ],
  ["ros_extremeMoveCommentVal", "neurologic.facialMovement.comment", ["2024"]],

  ["exam_diabFootNaVal", "diabetes.footExam.na", ["2024"]],
  ["exam_diabFootNormalVal", "diabetes.footExam.normal", ["2024"]],
  ["exam_diabFootAbnormalVal", "diabetes.footExam.abnormal", ["2024"]],

  [
    "exam_diabetecFootExamAbnormalSubDescVal",
    "diabetes.footExam.abnormalSubAnswers.describe",
    ["2024"],
  ],
  ["exam_diabFootCommentVal", "diabetes.footExam.comment", ["2024"]],

  ["exam_diabFootNormalVal", "diabetes.footExam.normal", ["2023"]],
  ["exam_diabFootAbnormalVal", "diabetes.footExam.abnormal", ["2023"]],
  [
    "exam_diabFootAbnormalSubWhichFootRfootVal",
    "diabetes.footExam.abonormalSubAnswers.rFoot",
    ["2023"],
  ],
  [
    "exam_diabFootAbnormalSubWhichFootLfootVal",
    "diabetes.footExam.abonormalSubAnswers.lFoot",
    ["2023"],
  ],
  [
    "exam_diabFootAbnormalSubWhichFootBiLateralVal",
    "diabetes.footExam.abonormalSubAnswers.bilateral",
    ["2023"],
  ],
  [
    "exam_diabFootAbnormalSubWhichFootCommentVal",
    "diabetes.footExam.abonormalSubAnswers.comment",
    ["2023"],
  ],
  ["exam_diabFootCommentVal", "diabetes.footExam.comment", ["2023"]],
  [
    "exam_psychJudgeNormalVal",
    "psychiatric.judgementOrInsight.normal",
    ["2023", "2024"],
  ],
  [
    "exam_psychJudgeAbnormalVal",
    "psychiatric.judgementOrInsight.abnormal",
    ["2023", "2024"],
  ],
  [
    "exam_psychJudgeAbnormalSubDescVal",
    "psychiatric.judgementOrInsight.abnormalSubAnswers.describe",
    ["2023", "2024"],
  ],
  [
    "exam_psychJudgeCommentVal",
    "psychiatric.judgementOrInsight.comment",
    ["2023", "2024"],
  ],
  [
    "exam_psychOrientNormalVal",
    "psychiatric.orientationOfPerson.normal",
    ["2023"],
  ],
  [
    "exam_psychOrientAbnormalVal",
    "psychiatric.orientationOfPerson.abnormal",
    ["2023"],
  ],
  [
    "exam_psychOrientAbnormalSubDescVal",
    "psychiatric.orientationOfPerson.abnormalSubAnswers.describe",
    ["2023"],
  ],
  [
    "exam_psychOrientCommentVal",
    "psychiatric.orientationOfPerson.comment",
    ["2023"],
  ],
  ["exam_psychRemoteNormalVal", "psychiatric.remoteMemory.normal", ["2023"]],
  [
    "exam_psychRemoteAbnormalVal",
    "psychiatric.remoteMemory.abnormal",
    ["2023"],
  ],
  [
    "exam_psychRemoteAbnormalSubDescVal",
    "psychiatric.remoteMemory.abnormalSubAnswers.describe",
    ["2023"],
  ],
  ["exam_psychRemoteCommentVal", "psychiatric.remoteMemory.comment", ["2023"]],
  ["exam_psychMoodNormalVal", "psychiatric.moodAndAffect.normal", ["2023"]],
  ["exam_psychMoodAbnormalVal", "psychiatric.moodAndAffect.abnormal", ["2023"]],
  [
    "exam_psychMoodAbnormalSubDescVal",
    "psychiatric.moodAndAffect.abnormalSubAnswers.describe",
    ["2023"],
  ],
  ["exam_psychMoodCommentVal", "psychiatric.moodAndAffect.comment", ["2023"]],
];

const examReview_optionsTreeMapObject2023 = {
  exam_genAppearNormalVal: "normal",
  exam_genAppearAbnormalVal: "abnormal",

  exam_headFaceExamNormalVal: "normal",
  exam_headFaceExamAbnormalVal: "abnormal",
  exam_headFacePalpNormalVal: "normal",
  exam_headFacePalpAbnormalVal: "abnormal",
  exam_eyesInspNormalVal: "normal",
  exam_eyesInspAbnormalVal: "abnormal",
  exam_eyesExamNormalVal: "normal",
  exam_eyesExamAbnormalVal: "abnormal",
  exam_neckExamNormalVal: "normal",
  exam_neckExamAbnormalVal: "abnormal",
  exam_neckExamThyroidNormalVal: "normal",
  exam_neckExamThyroidAbnormalVal: "abnormal",
  exam_entExtInspNormalVal: "normal",
  exam_entExtInspAbnormalVal: "abnormal",
  exam_entOtosExamNormalVal: "normal",
  exam_entOtosExamAbnormalVal: "abnormal",
  exam_entAssessHearNormalVal: "normal",
  exam_entAssessHearAbnormalVal: "abnormal",
  exam_entNasalInspNormalVal: "normal",
  exam_entNasalInspAbnormalVal: "abnormal",
  exam_entInspLipsNormalVal: "normal",
  exam_entInspLipsAbnormalVal: "abnormal",
  exam_entOropExamNormalVal: "normal",
  exam_entOropExamAbnormalVal: "abnormal",
  exam_pulmAssessRespNormalVal: "normal",
  exam_pulmAssessRespAbnormalVal: "abnormal",
  exam_pulmAuscLungsNormalVal: "normal",
  exam_pulmAuscLungsAbnormalVal: "abnormal",
  exam_cardioAusHeartNormalVal: "normal",
  exam_cardioAusHeartAbnormalVal: "abnormal",
  exam_cardioPalpAusNormalVal: "normal",
  exam_cardioPalpAusAbnormalVal: "abnormal",
  exam_cardioPedalPulseNormalVal: "normal",
  exam_cardioPedalPulseAbnormalVal: "abnormal",
  exam_cardioExamEdemaNormalVal: "normal",
  exam_cardioExamEdemaAbnormalVal: "abnormal",
  exam_cardioExamRadialNormalVal: "normal",
  exam_cardioExamRadialAbnormalVal: "abnormal",
  exam_lympPalpCerviNormalVal: "normal",
  exam_lympPalpCerviAbnormalVal: "abnormal",
  exam_lympPalpPreauricNormalVal: "normal",
  exam_lympPalpPreauricAbnormalVal: "abnormal",
  // exam_lympExamGaitNormalVal: "normal",
  // exam_lympExamGaitAbnormalVal: "abnormal",
  exam_lympPalpSubmandiNormalVal: "normal",
  exam_lympPalpSubmandiAbnormalVal: "abnormal",
  exam_muscSkelExamGaitNormalVal: "normal",
  exam_muscSkelExamGaitAbnormalVal: "abnormal",
  exam_muscSkelInspDigitNormalVal: "normal",
  exam_muscSkelInspDigitAbnormalVal: "abnormal",
  exam_muscSkelInspJointNormalVal: "normal",
  exam_muscSkelInspJointAbnormalVal: "abnormal",
  exam_muscSkelAssessRangeNormalVal: "normal",
  exam_muscSkelAssessRangeAbnormalVal: "abnormal",
  exam_muscSkelAssessStableNormalVal: "normal",
  exam_muscSkelAssessStableAbnormalVal: "abnormal",
  exam_muscSkelAssessMuscleNormalVal: "normal",
  exam_muscSkelAssessMuscleAbnormalVal: "abnormal",
  // exam_skinAssessSkinNormalVal: "normal",
  // exam_skinAssessSkinAbnormalVal: "abnormal",
  exam_skinInspSkinNormalVal: "normal",
  exam_skinInspSkinAbnormalVal: "abnormal",
  exam_psychJudgeNormalVal: "normal",
  exam_psychJudgeAbnormalVal: "abnormal",
  exam_psychOrientNormalVal: "normal",
  exam_psychOrientAbnormalVal: "abnormal",
  exam_psychRemoteNormalVal: "normal",
  exam_psychRemoteAbnormalVal: "abnormal",
  exam_psychMoodNormalVal: "normal",
  exam_psychMoodAbnormalVal: "abnormal",
  exam_neuroRombergNormalVal: "normal",
  exam_neuroRombergAbnormalVal: "abnormal",
  exam_neuroReflexNormalVal: "normal",
  exam_neuroReflexAbnormalVal: "abnormal",
  exam_neuroSenseNormalVal: "normal",
  exam_neuroSenseAbnormalVal: "abnormal",
  exam_neuroCoordinateNormalVal: "normal",
  exam_neuroCoordinateAbnormalVal: "abnormal",

  exam_diabFootNormalVal: "normal",
  exam_diabFootAbnormalVal: "abnormal",
  exam_diabFootAbnormalSubWhichFootRfootVal: "rFoot",
  exam_diabFootAbnormalSubWhichFootLfootVal: "lFoot",
  exam_diabFootAbnormalSubWhichFootBiLateralVal: "bilateral",
};

const examReview_optionsTreeMapObject2024 = {
  exam_genAppearNormalVal: "normal",
  exam_genAppearAbnormalVal: "abnormal",

  exam_headFaceExamNormalVal: "normal",
  exam_headFaceExamAbnormalVal: "abnormal",

  exam_headFacePalpNormalVal: "normal",
  exam_headFacePalpAbnormalVal: "abnormal",

  exam_eyesInspNormalVal: "normal",
  exam_eyesInspAbnormalVal: "abnormal",

  exam_eyesExamNormalVal: "normal",
  exam_eyesExamAbnormalVal: "abnormal",

  exam_entExtInspNormalVal: "normal",
  exam_entExtInspAbnormalVal: "abnormal",

  exam_entOtosExamNormalVal: "normal",
  exam_entOtosExamAbnormalVal: "abnormal",

  exam_entAssessHearNormalVal: "normal",
  exam_entAssessHearAbnormalVal: "abnormal",

  exam_entNasalInspNormalVal: "normal",
  exam_entNasalInspAbnormalVal: "abnormal",

  exam_entInspLipsNormalVal: "normal",
  exam_entInspLipsAbnormalVal: "abnormal",

  exam_entOropExamNormalVal: "normal",
  exam_entOropExamAbnormalVal: "abnormal",

  exam_neckExamNormalVal: "normal",
  exam_neckExamAbnormalVal: "abnormal",

  exam_neckExamThyroidNormalVal: "normal",
  exam_neckExamThyroidAbnormalVal: "abnormal",

  exam_pulmAssessRespNormalVal: "normal",
  exam_pulmAssessRespAbnormalVal: "abnormal",

  exam_pulmAuscLungsNormalVal: "normal",
  exam_pulmAuscLungsAbnormalVal: "abnormal",

  exam_cardioAusHeartNormalVal: "normal",
  exam_cardioAusHeartAbnormalVal: "abnormal",

  exam_cardioPalpAusNormalVal: "normal",
  exam_cardioPalpAusAbnormalVal: "abnormal",

  exam_cardioPedalPulseNormalVal: "normal",
  exam_cardioPedalPulseAbnormalVal: "abnormal",

  exam_cardioExamEdemaNormalVal: "normal",
  exam_cardioExamEdemaAbnormalVal: "abnormal",

  exam_cardioExamRadialNormalVal: "normal",
  exam_cardioExamRadialAbnormalVal: "abnormal",

  exam_lympPalpCerviNormalVal: "normal",
  exam_lympPalpCerviAbnormalVal: "abnormal",

  exam_lympPalpPreauricNormalVal: "normal",
  exam_lympPalpPreauricAbnormalVal: "abnormal",

  // exam_lympExamGaitNormalVal: "normal",
  // exam_lympExamGaitAbnormalVal: "abnormal",
  exam_lympPalpSubmandiNormalVal: "normal",
  exam_lympPalpSubmandiAbnormalVal: "abnormal",

  exam_muscSkelExamGaitNormalVal: "normal",
  exam_muscSkelExamGaitAbnormalVal: "abnormal",

  exam_muscSkelInspDigitNormalVal: "normal",
  exam_muscSkelInspDigitAbnormalVal: "abnormal",

  exam_muscSkelInspJointNormalVal: "normal",
  exam_muscSkelInspJointAbnormalVal: "abnormal",

  exam_muscSkelAssessRangeNormalVal: "normal",
  exam_muscSkelAssessRangeAbnormalVal: "abnormal",

  exam_muscSkelAssessStableNormalVal: "normal",
  exam_muscSkelAssessStableAbnormalVal: "abnormal",

  exam_muscSkelAssessMuscleNormalVal: "normal",
  exam_muscSkelAssessMuscleAbnormalVal: "abnormal",

  // exam_skinAssessSkinNormalVal: "normal",
  // exam_skinAssessSkinAbnormalVal: "abnormal",
  exam_skinInspSkinNormalVal: "normal",
  exam_skinInspSkinAbnormalVal: "abnormal",

  exam_psychJudgeNormalVal: "normal",
  exam_psychJudgeAbnormalVal: "abnormal",

  exam_neuroRombergNormalVal: "normal",
  exam_neuroRombergAbnormalVal: "abnormal",

  exam_noGpcogMmsePatientOrientPersonYesVal: "yes",
  exam_noGpcogMmsePatientOrientPersonNoVal: "no",

  exam_noGpcogMmsePatientOrientPlaceYesVal: "yes",
  exam_noGpcogMmsePatientOrientPlaceNoVal: "no",

  exam_noGpcogMmsePatientOrientTimeYesVal: "yes",
  exam_noGpcogMmsePatientOrientTimeNoVal: "no",

  exam_noGpcogMmseRecallGoodVal: "good",
  exam_noGpcogMmseRecallPoorVal: "poor",

  exam_noGpcogMmsePatientDescribeNewsYesVal: "yes",
  exam_noGpcogMmsePatientDescribeNewsPartiallyVal: "partially",
  exam_noGpcogMmsePatientDescribeNewsNoVal: "no",

  exam_psychMoodNormalVal: "normal",
  exam_psychMoodAbnormalVal: "abnormal",
  exam_psychMoodSubParanoiaVal: "paranoia",
  exam_psychMoodSubDelusionalVal: "delusional",
  exam_psychMoodSubDisorganizedThoughtVal: "disorganizedThought",
  exam_psychMoodSubFlatVal: "flat",
  exam_psychMoodSubManicVal: "manic",
  exam_psychMoodSubDepressedVal: "depressed",
  exam_psychMoodSubOtherVal: "other",

  ros_speechNormalVal: "normal",
  ros_speechAbnormalVal: "abnormal",
  ros_speechSubSlurredVal: "slurred",
  ros_speechSubAphasicVal: "aphasic",
  ros_speechSubApraxiaVal: "apraxia",

  ros_fingerToNoseNormalVal: "normal",
  ros_fingerToNoseAbnormalVal: "abnormal",
  ros_fingerToNoseAbnormalSubLeftVal: "left",
  ros_fingerToNoseAbnormalSubRightVal: "right",
  ros_fingerToNoseAbnormalSubBothVal: "both",

  ros_heelToToeNormalVal: "normal",
  ros_heelToToeAbnormalVal: "abnormal",
  ros_heelToToeAbnormalSubLeftVal: "left",
  ros_heelToToeAbnormalSubRightVal: "right",
  ros_heelToToeAbnormalSubBothVal: "both",

  ros_thumbToFingerNormalVal: "normal",
  ros_thumbToFingerAbnormalVal: "abnormal",
  ros_thumbToFingerAbnormalSubLeftVal: "left",
  ros_thumbToFingerAbnormalSubRightVal: "right",
  ros_thumbToFingerAbnormalSubBothVal: "both",

  exam_diabFootNaVal: "na",
  exam_diabFootNormalVal: "normal",
  exam_diabFootAbnormalVal: "abnormal",

  exam_neuroReflexNormalVal: "normal",
  exam_neuroReflexAbnormalVal: "abnormal",

  exam_neuroSenseNormalVal: "normal",
  exam_neuroSenseAbnormalVal: "abnormal",

  exam_neuroCoordinateNormalVal: "normal",
  exam_neuroCoordinateAbnormalVal: "abnormal",
};

const examReviewOptionsTreeWrappers2023 = [
  ["exam_genAppear", ["exam_genAppearNormalVal", "exam_genAppearAbnormalVal"]],
  [
    "exam_headFaceExam",
    ["exam_headFaceExamNormalVal", "exam_headFaceExamAbnormalVal"],
  ],
  [
    "exam_headFacePalp",
    ["exam_headFacePalpNormalVal", "exam_headFacePalpAbnormalVal"],
  ],
  ["exam_eyesInsp", ["exam_eyesInspNormalVal", "exam_eyesInspAbnormalVal"]],
  ["exam_eyesExam", ["exam_eyesExamNormalVal", "exam_eyesExamAbnormalVal"]],
  [
    "exam_entExtInsp",
    ["exam_entExtInspNormalVal", "exam_entExtInspAbnormalVal"],
  ],
  [
    "exam_entOtosExam",
    ["exam_entOtosExamNormalVal", "exam_entOtosExamAbnormalVal"],
  ],
  [
    "exam_entAssessHear",
    ["exam_entAssessHearNormalVal", "exam_entAssessHearAbnormalVal"],
  ],
  [
    "exam_entNasalInsp",
    ["exam_entNasalInspNormalVal", "exam_entNasalInspAbnormalVal"],
  ],
  [
    "exam_entInspLips",
    ["exam_entInspLipsNormalVal", "exam_entInspLipsAbnormalVal"],
  ],
  [
    "exam_entOropExam",
    ["exam_entOropExamNormalVal", "exam_entOropExamAbnormalVal"],
  ],
  ["exam_neckExam", ["exam_neckExamNormalVal", "exam_neckExamAbnormalVal"]],
  [
    "exam_neckExamThyroid",
    ["exam_neckExamThyroidNormalVal", "exam_neckExamThyroidAbnormalVal"],
  ],
  [
    "exam_pulmAssessResp",
    ["exam_pulmAssessRespNormalVal", "exam_pulmAssessRespAbnormalVal"],
  ],
  [
    "exam_pulmAuscLungs",
    ["exam_pulmAuscLungsNormalVal", "exam_pulmAuscLungsAbnormalVal"],
  ],
  [
    "exam_cardioAusHeart",
    ["exam_cardioAusHeartNormalVal", "exam_cardioAusHeartAbnormalVal"],
  ],
  [
    "exam_cardioPalpAus",
    ["exam_cardioPalpAusNormalVal", "exam_cardioPalpAusAbnormalVal"],
  ],
  [
    "exam_cardioPedalPulse",
    ["exam_cardioPedalPulseNormalVal", "exam_cardioPedalPulseAbnormalVal"],
  ],
  [
    "exam_cardioExamEdema",
    ["exam_cardioExamEdemaNormalVal", "exam_cardioExamEdemaAbnormalVal"],
  ],
  [
    "exam_cardioExamRadial",
    ["exam_cardioExamRadialNormalVal", "exam_cardioExamRadialAbnormalVal"],
  ],

  [
    "exam_lympPalpCervi",
    ["exam_lympPalpCerviNormalVal", "exam_lympPalpCerviAbnormalVal"],
  ],
  [
    "exam_lympPalpPreauric",
    ["exam_lympPalpPreauricNormalVal", "exam_lympPalpPreauricAbnormalVal"],
  ],
  [
    "exam_lympExamGait",
    ["exam_lympExamGaitNormalVal", "exam_lympExamGaitAbnormalVal"],
  ],
  [
    "exam_lympPalpSubmandi",
    ["exam_lympPalpSubmandiNormalVal", "exam_lympPalpSubmandiAbnormalVal"],
  ],

  [
    "exam_muscSkelExamGait",
    ["exam_muscSkelExamGaitNormalVal", "exam_muscSkelExamGaitAbnormalVal"],
  ],
  [
    "exam_muscSkelInspDigit",
    ["exam_muscSkelInspDigitNormalVal", "exam_muscSkelInspDigitAbnormalVal"],
  ],
  [
    "exam_muscSkelInspJoint",
    ["exam_muscSkelInspJointNormalVal", "exam_muscSkelInspJointAbnormalVal"],
  ],
  [
    "exam_muscSkelAssessRange",
    [
      "exam_muscSkelAssessRangeNormalVal",
      "exam_muscSkelAssessRangeAbnormalVal",
    ],
  ],
  [
    "exam_muscSkelAssessStable",
    [
      "exam_muscSkelAssessStableNormalVal",
      "exam_muscSkelAssessStableAbnormalVal",
    ],
  ],
  [
    "exam_muscSkelAssessMuscle",
    [
      "exam_muscSkelAssessMuscleNormalVal",
      "exam_muscSkelAssessMuscleAbnormalVal",
    ],
  ],
  // [
  //   "exam_skinAssessSkin",
  //   ["exam_skinAssessSkinNormalVal", "exam_skinAssessSkinAbnormalVal"],
  // ],
  [
    "exam_skinInspSkin",
    ["exam_skinInspSkinNormalVal", "exam_skinInspSkinAbnormalVal"],
  ],

  [
    "exam_psychJudge",
    ["exam_psychJudgeNormalVal", "exam_psychJudgeAbnormalVal"],
  ],
  [
    "exam_psychOrient",
    ["exam_psychOrientNormalVal", "exam_psychOrientAbnormalVal"],
  ],
  [
    "exam_psychRemote",
    ["exam_psychRemoteNormalVal", "exam_psychRemoteAbnormalVal"],
  ],
  ["exam_psychMood", ["exam_psychMoodNormalVal", "exam_psychMoodAbnormalVal"]],

  [
    "exam_neuroRomberg",
    ["exam_neuroRombergNormalVal", "exam_neuroRombergAbnormalVal"],
  ],
  [
    "exam_neuroReflex",
    ["exam_neuroReflexNormalVal", "exam_neuroReflexAbnormalVal"],
  ],
  [
    "exam_neuroSense",
    ["exam_neuroSenseNormalVal", "exam_neuroSenseAbnormalVal"],
  ],
  [
    "exam_neuroCoordinate",
    ["exam_neuroCoordinateNormalVal", "exam_neuroCoordinateAbnormalVal"],
  ],
  ["exam_diabFoot", ["exam_diabFootNormalVal", "exam_diabFootAbnormalVal"]],

  [
    "exam_diabFootAbnormalSubWhichFoot",
    [
      "exam_diabFootAbnormalSubWhichFootRfootVal",
      "exam_diabFootAbnormalSubWhichFootLfootVal",
      "exam_diabFootAbnormalSubWhichFootBiLateralVal",
    ],
  ],
];

const examReviewOptionsTreeWrappers2024 = [
  ["exam_genAppear", ["exam_genAppearNormalVal", "exam_genAppearAbnormalVal"]],
  [
    "exam_headFaceExam",
    ["exam_headFaceExamNormalVal", "exam_headFaceExamAbnormalVal"],
  ],
  [
    "exam_headFacePalp",
    ["exam_headFacePalpNormalVal", "exam_headFacePalpAbnormalVal"],
  ],
  ["exam_eyesInsp", ["exam_eyesInspNormalVal", "exam_eyesInspAbnormalVal"]],
  ["exam_eyesExam", ["exam_eyesExamNormalVal", "exam_eyesExamAbnormalVal"]],
  [
    "exam_entExtInsp",
    ["exam_entExtInspNormalVal", "exam_entExtInspAbnormalVal"],
  ],
  [
    "exam_entOtosExam",
    ["exam_entOtosExamNormalVal", "exam_entOtosExamAbnormalVal"],
  ],
  [
    "exam_entAssessHear",
    ["exam_entAssessHearNormalVal", "exam_entAssessHearAbnormalVal"],
  ],
  [
    "exam_entNasalInsp",
    ["exam_entNasalInspNormalVal", "exam_entNasalInspAbnormalVal"],
  ],
  [
    "exam_entInspLips",
    ["exam_entInspLipsNormalVal", "exam_entInspLipsAbnormalVal"],
  ],
  [
    "exam_entOropExam",
    ["exam_entOropExamNormalVal", "exam_entOropExamAbnormalVal"],
  ],
  ["exam_neckExam", ["exam_neckExamNormalVal", "exam_neckExamAbnormalVal"]],
  [
    "exam_neckExamThyroid",
    ["exam_neckExamThyroidNormalVal", "exam_neckExamThyroidAbnormalVal"],
  ],
  [
    "exam_pulmAssessResp",
    ["exam_pulmAssessRespNormalVal", "exam_pulmAssessRespAbnormalVal"],
  ],
  [
    "exam_pulmAuscLungs",
    ["exam_pulmAuscLungsNormalVal", "exam_pulmAuscLungsAbnormalVal"],
  ],
  [
    "exam_cardioAusHeart",
    ["exam_cardioAusHeartNormalVal", "exam_cardioAusHeartAbnormalVal"],
  ],
  [
    "exam_cardioPalpAus",
    ["exam_cardioPalpAusNormalVal", "exam_cardioPalpAusAbnormalVal"],
  ],
  [
    "exam_cardioPedalPulse",
    ["exam_cardioPedalPulseNormalVal", "exam_cardioPedalPulseAbnormalVal"],
  ],
  [
    "exam_cardioExamEdema",
    ["exam_cardioExamEdemaNormalVal", "exam_cardioExamEdemaAbnormalVal"],
  ],
  [
    "exam_cardioExamRadial",
    ["exam_cardioExamRadialNormalVal", "exam_cardioExamRadialAbnormalVal"],
  ],

  [
    "exam_lympPalpCervi",
    ["exam_lympPalpCerviNormalVal", "exam_lympPalpCerviAbnormalVal"],
  ],
  [
    "exam_lympPalpPreauric",
    ["exam_lympPalpPreauricNormalVal", "exam_lympPalpPreauricAbnormalVal"],
  ],
  [
    "exam_lympExamGait",
    ["exam_lympExamGaitNormalVal", "exam_lympExamGaitAbnormalVal"],
  ],
  [
    "exam_lympPalpSubmandi",
    ["exam_lympPalpSubmandiNormalVal", "exam_lympPalpSubmandiAbnormalVal"],
  ],

  [
    "exam_muscSkelExamGait",
    ["exam_muscSkelExamGaitNormalVal", "exam_muscSkelExamGaitAbnormalVal"],
  ],
  [
    "exam_muscSkelInspDigit",
    ["exam_muscSkelInspDigitNormalVal", "exam_muscSkelInspDigitAbnormalVal"],
  ],
  [
    "exam_muscSkelInspJoint",
    ["exam_muscSkelInspJointNormalVal", "exam_muscSkelInspJointAbnormalVal"],
  ],
  [
    "exam_muscSkelAssessRange",
    [
      "exam_muscSkelAssessRangeNormalVal",
      "exam_muscSkelAssessRangeAbnormalVal",
    ],
  ],
  [
    "exam_muscSkelAssessStable",
    [
      "exam_muscSkelAssessStableNormalVal",
      "exam_muscSkelAssessStableAbnormalVal",
    ],
  ],
  [
    "exam_muscSkelAssessMuscle",
    [
      "exam_muscSkelAssessMuscleNormalVal",
      "exam_muscSkelAssessMuscleAbnormalVal",
    ],
  ],
  // [
  //   "exam_skinAssessSkin",
  //   ["exam_skinAssessSkinNormalVal", "exam_skinAssessSkinAbnormalVal"],
  // ],
  [
    "exam_skinInspSkin",
    ["exam_skinInspSkinNormalVal", "exam_skinInspSkinAbnormalVal"],
  ],

  [
    "exam_psychJudge",
    ["exam_psychJudgeNormalVal", "exam_psychJudgeAbnormalVal"],
  ],

  [
    "exam_noGpcogMmsePatientOrientPerson",
    [
      "exam_noGpcogMmsePatientOrientPersonYesVal",
      "exam_noGpcogMmsePatientOrientPersonNoVal",
    ],
  ],

  [
    "exam_noGpcogMmsePatientOrientPlace",
    [
      "exam_noGpcogMmsePatientOrientPlaceYesVal",
      "exam_noGpcogMmsePatientOrientPlaceNoVal",
    ],
  ],

  [
    "exam_noGpcogMmsePatientOrientTime",
    [
      "exam_noGpcogMmsePatientOrientTimeYesVal",
      "exam_noGpcogMmsePatientOrientTimeNoVal",
    ],
  ],

  [
    "exam_noGpcogMmseRecall",
    ["exam_noGpcogMmseRecallGoodVal", "exam_noGpcogMmseRecallPoorVal"],
  ],

  [
    "exam_noGpcogMmsePatientDescribeNews",
    [
      "exam_noGpcogMmsePatientDescribeNewsYesVal",
      "exam_noGpcogMmsePatientDescribeNewsPartiallyVal",
      "exam_noGpcogMmsePatientDescribeNewsNoVal",
    ],
  ],

  ["exam_psychMood", ["exam_psychMoodNormalVal", "exam_psychMoodAbnormalVal"]],

  [
    "exam_psychMoodSub",
    [
      "exam_psychMoodSubParanoiaVal",
      "exam_psychMoodSubDelusionalVal",
      "exam_psychMoodSubDisorganizedThoughtVal",
      "exam_psychMoodSubFlatVal",
      "exam_psychMoodSubManicVal",
      "exam_psychMoodSubDepressedVal",
      "exam_psychMoodSubOtherVal",
    ],
  ],

  ["ros_speech", ["ros_speechAbnormalVal", "ros_speechNormalVal"]],

  [
    "ros_speechSub",
    [
      "ros_speechSubSlurredVal",
      "ros_speechSubAphasicVal",
      "ros_speechSubApraxiaVal",
    ],
  ],

  [
    "ros_fingerToNose",
    ["ros_fingerToNoseNormalVal", "ros_fingerToNoseAbnormalVal"],
  ],

  [
    "ros_fingerToNoseAbnormalSub",
    [
      "ros_fingerToNoseAbnormalSubLeftVal",
      "ros_fingerToNoseAbnormalSubRightVal",
      "ros_fingerToNoseAbnormalSubBothVal",
    ],
  ],

  ["ros_heelToToe", ["ros_heelToToeNormalVal", "ros_heelToToeAbnormalVal"]],

  [
    "ros_heelToToeAbnormalSub",
    [
      "ros_heelToToeAbnormalSubLeftVal",
      "ros_heelToToeAbnormalSubRightVal",
      "ros_heelToToeAbnormalSubBothVal",
    ],
  ],

  [
    "ros_thumbToFinger",
    ["ros_thumbToFingerNormalVal", "ros_thumbToFingerAbnormalVal"],
  ],

  [
    "ros_thumbToFingerAbnormalSub",
    [
      "ros_thumbToFingerAbnormalSubLeftVal",
      "ros_thumbToFingerAbnormalSubRightVal",
      "ros_thumbToFingerAbnormalSubBothVal",
    ],
  ],

  [
    "exam_diabFoot",
    [
      "exam_diabFootNaVal",
      "exam_diabFootNormalVal",
      "exam_diabFootAbnormalVal",
    ],
  ],

  [
    "exam_neuroRomberg",
    ["exam_neuroRombergNormalVal", "exam_neuroRombergAbnormalVal"],
  ],
  [
    "exam_neuroReflex",
    ["exam_neuroReflexNormalVal", "exam_neuroReflexAbnormalVal"],
  ],
  [
    "exam_neuroSense",
    ["exam_neuroSenseNormalVal", "exam_neuroSenseAbnormalVal"],
  ],
  [
    "exam_neuroCoordinate",
    ["exam_neuroCoordinateNormalVal", "exam_neuroCoordinateAbnormalVal"],
  ],
];

export const getExamReviewData = (apiData, year) => {
  let nestedChildsData = {};
  let flattenData = {};

  let flatmap = [];

  examReview_flatmap.forEach((quesData) => {
    let yearList = quesData?.[2] || [];
    if (yearList.includes(year)) {
      flatmap.push(quesData);
    }
  });

  let treeWrappers = getTreeWrapperByYear(year);

  flatmap.forEach((questionPair) => {
    nestedChildsData[questionPair[0]] = getObjectData(apiData, questionPair[1]);
  });

  treeWrappers.forEach((pointerLabels) => {
    flattenData[pointerLabels[0]] =
      getWrapper(nestedChildsData, pointerLabels[1], year) || "";
  });

  const exam_genAppearComment = nestedChildsData["exam_genAppearCommentVal"];
  const exam_headFaceExamComment =
    nestedChildsData["exam_headFaceExamCommentVal"];
  const exam_headFacePalpComment =
    nestedChildsData["exam_headFacePalpCommentVal"];
  const exam_eyesInspComment = nestedChildsData["exam_eyesInspCommentVal"];
  const exam_eyesExamComment = nestedChildsData["exam_eyesExamCommentVal"];
  const exam_entExtInspComment = nestedChildsData["exam_entExtInspCommentVal"];
  const exam_entOtosExamComment =
    nestedChildsData["exam_entOtosExamCommentVal"];
  const exam_entAssessHearComment =
    nestedChildsData["exam_entAssessHearCommentVal"];
  const exam_entNasalInspComment =
    nestedChildsData["exam_entNasalInspCommentVal"];
  const exam_entInspLipsComment =
    nestedChildsData["exam_entInspLipsCommentVal"];
  const exam_entOropExamComment =
    nestedChildsData["exam_entOropExamCommentVal"];
  const exam_neckExamComment = nestedChildsData["exam_neckExamCommentVal"];
  const exam_neckExamThyroidComment =
    nestedChildsData["exam_neckExamThyroidCommentVal"];
  const exam_pulmAssessRespComment =
    nestedChildsData["exam_pulmAssessRespCommentVal"];
  const exam_pulmAuscLungsComment =
    nestedChildsData["exam_pulmAuscLungsCommentVal"];
  const exam_cardioAusHeartComment =
    nestedChildsData["exam_cardioAusHeartCommentVal"];
  const exam_cardioPalpAusComment =
    nestedChildsData["exam_cardioPalpAusCommentVal"];
  const exam_cardioPedalPulseComment =
    nestedChildsData["exam_cardioPedalPulseCommentVal"];
  const exam_cardioExamEdemaComment =
    nestedChildsData["exam_cardioExamEdemaCommentVal"];
  const exam_cardioExamRadialComment =
    nestedChildsData["exam_cardioExamRadialCommentVal"];
  const exam_lympPalpCerviComment =
    nestedChildsData["exam_lympPalpCerviCommentVal"];
  const exam_lympPalpPreauricComment =
    nestedChildsData["exam_lympPalpPreauricCommentVal"];
  // const exam_lympExamGaitComment = tempDbData["exam_lympExamGaitCommentVal"];
  const exam_lympPalpSubmandiComment =
    nestedChildsData["exam_lympPalpSubmandiCommentVal"];
  const exam_muscSkelExamGaitComment =
    nestedChildsData["exam_muscSkelExamGaitCommentVal"];
  const exam_muscSkelInspDigitComment =
    nestedChildsData["exam_muscSkelInspDigitCommentVal"];
  const exam_muscSkelInspJointComment =
    nestedChildsData["exam_muscSkelInspJointCommentVal"];
  const exam_muscSkelAssessRangeComment =
    nestedChildsData["exam_muscSkelAssessRangeCommentVal"];
  const exam_muscSkelAssessStableComment =
    nestedChildsData["exam_muscSkelAssessStableCommentVal"];
  const exam_muscSkelAssessMuscleComment =
    nestedChildsData["exam_muscSkelAssessMuscleCommentVal"];
  // const exam_skinAssessSkinComment =
  //   tempDbData["exam_skinAssessSkinCommentVal"];
  const exam_skinInspSkinComment =
    nestedChildsData["exam_skinInspSkinCommentVal"];
  const exam_psychJudgeComment = nestedChildsData["exam_psychJudgeCommentVal"];
  const exam_psychOrientComment =
    nestedChildsData["exam_psychOrientCommentVal"];
  const exam_psychRemoteComment =
    nestedChildsData["exam_psychRemoteCommentVal"];
  const exam_psychMoodComment = nestedChildsData["exam_psychMoodCommentVal"];
  const exam_neuroCranialTest = nestedChildsData["exam_neuroCranialTestVal"];
  const exam_neuroCranialTestIndicate =
    nestedChildsData["exam_neuroCranialTestIndicateVal"];
  const exam_neuroRombergComment =
    nestedChildsData["exam_neuroRombergCommentVal"];
  const exam_neuroReflexComment =
    nestedChildsData["exam_neuroReflexCommentVal"];
  const exam_neuroSenseComment = nestedChildsData["exam_neuroSenseCommentVal"];
  const exam_neuroCoordinateComment =
    nestedChildsData["exam_neuroCoordinateCommentVal"];
  const exam_diabFootAbnormalSubWhichFootComment =
    nestedChildsData["exam_diabFootAbnormalSubWhichFootCommentVal"];
  const exam_diabFootComment = nestedChildsData["exam_diabFootCommentVal"];

  const exam_genAppearAbnormalSubDesc =
    nestedChildsData["exam_genAppearAbnormalSubDescVal"];
  const exam_headFaceExamAbnormalSubDesc =
    nestedChildsData["exam_headFaceExamAbnormalSubDescVal"];
  const exam_headFacePalpAbnormalSubDesc =
    nestedChildsData["exam_headFacePalpAbnormalSubDescVal"];
  const exam_eyesInspAbnormalSubDesc =
    nestedChildsData["exam_eyesInspAbnormalSubDescVal"];
  const exam_eyesExamAbnormalSubDesc =
    nestedChildsData["exam_eyesExamAbnormalSubDescVal"];
  const exam_entExtInspAbnormalSubDesc =
    nestedChildsData["exam_entExtInspAbnormalSubDescVal"];
  const exam_entOtosExamAbnormalSubDesc =
    nestedChildsData["exam_entOtosExamAbnormalSubDescVal"];
  const exam_entAssessHearAbnormalSubDesc =
    nestedChildsData["exam_entAssessHearAbnormalSubDescVal"];
  const exam_entNasalInspAbnormalSubDesc =
    nestedChildsData["exam_entNasalInspAbnormalSubDescVal"];
  const exam_entInspLipsAbnormalSubDesc =
    nestedChildsData["exam_entInspLipsAbnormalSubDescVal"];
  const exam_entOropExamAbnormalSubDesc =
    nestedChildsData["exam_entOropExamAbnormalSubDescVal"];
  const exam_neckExamAbnormalSubDesc =
    nestedChildsData["exam_neckExamAbnormalSubDescVal"];
  const exam_neckExamThyroidAbnormalSubDesc =
    nestedChildsData["exam_neckExamThyroidAbnormalSubDescVal"];
  const exam_pulmAssessRespAbnormalSubDesc =
    nestedChildsData["exam_pulmAssessRespAbnormalSubDescVal"];
  const exam_pulmAuscLungsAbnormalSubDesc =
    nestedChildsData["exam_pulmAuscLungsAbnormalSubDescVal"];
  const exam_cardioAusHeartAbnormalSubDesc =
    nestedChildsData["exam_cardioAusHeartAbnormalSubDescVal"];
  const exam_cardioPalpAusAbnormalSubDesc =
    nestedChildsData["exam_cardioPalpAusAbnormalSubDescVal"];
  const exam_cardioPedalPulseAbnormalSubDesc =
    nestedChildsData["exam_cardioPedalPulseAbnormalSubDescVal"];
  const exam_cardioExamEdemaAbnormalSubDesc =
    nestedChildsData["exam_cardioExamEdemaAbnormalSubDescVal"];
  const exam_cardioExamRadialAbnormalSubDesc =
    nestedChildsData["exam_cardioExamRadialAbnormalSubDescVal"];
  const exam_lympPalpCerviAbnormalSubDesc =
    nestedChildsData["exam_lympPalpCerviAbnormalSubDescVal"];
  const exam_lympPalpPreauricAbnormalSubDesc =
    nestedChildsData["exam_lympPalpPreauricAbnormalSubDescVal"];
  const exam_lympPalpSubmandiAbnormalSubDesc =
    nestedChildsData["exam_lympPalpSubmandiAbnormalSubDescVal"];
  const exam_muscSkelExamGaitAbnormalSubDesc =
    nestedChildsData["exam_muscSkelExamGaitAbnormalSubDescVal"];
  const exam_muscSkelInspDigitAbnormalSubDesc =
    nestedChildsData["exam_muscSkelInspDigitAbnormalSubDescVal"];
  const exam_muscSkelInspJointAbnormalSubDesc =
    nestedChildsData["exam_muscSkelInspJointAbnormalSubDescVal"];
  const exam_muscSkelAssessRangeAbnormalSubDesc =
    nestedChildsData["exam_muscSkelAssessRangeAbnormalSubDescVal"];
  const exam_muscSkelAssessStableAbnormalSubDesc =
    nestedChildsData["exam_muscSkelAssessStableAbnormalSubDescVal"];
  const exam_muscSkelAssessMuscleAbnormalSubDesc =
    nestedChildsData["exam_muscSkelAssessMuscleAbnormalSubDescVal"];
  const exam_skinInspSkinAbnormalSubDesc =
    nestedChildsData["exam_skinInspSkinAbnormalSubDescVal"];
  const exam_psychJudgeAbnormalSubDesc =
    nestedChildsData["exam_psychJudgeAbnormalSubDescVal"];
  const exam_psychOrientAbnormalSubDesc =
    nestedChildsData["exam_psychOrientAbnormalSubDescVal"];
  const exam_psychRemoteAbnormalSubDesc =
    nestedChildsData["exam_psychRemoteAbnormalSubDescVal"];
  const exam_psychMoodAbnormalSubDesc =
    nestedChildsData["exam_psychMoodAbnormalSubDescVal"];
  const exam_neuroRombergAbnormalSubDesc =
    nestedChildsData["exam_neuroRombergAbnormalSubDescVal"];
  const exam_neuroReflexAbnormalSubDesc =
    nestedChildsData["exam_neuroReflexAbnormalSubDescVal"];
  const exam_neuroSenseAbnormalSubDesc =
    nestedChildsData["exam_neuroSenseAbnormalSubDescVal"];
  const exam_neuroCoordinateAbnormalSubDesc =
    nestedChildsData["exam_neuroCoordinateAbnormalSubDescVal"];
  const exam_diabFootAbnormalSubDesc =
    nestedChildsData["exam_diabFootAbnormalSubDescVal"];

  flattenData = {
    ...flattenData,
    exam_genAppearComment: exam_genAppearComment || "",
    exam_headFaceExamComment: exam_headFaceExamComment || "",
    exam_headFacePalpComment: exam_headFacePalpComment || "",
    exam_eyesInspComment: exam_eyesInspComment || "",
    exam_eyesExamComment: exam_eyesExamComment || "",
    exam_entExtInspComment: exam_entExtInspComment || "",
    exam_entOtosExamComment: exam_entOtosExamComment || "",
    exam_entAssessHearComment: exam_entAssessHearComment || "",
    exam_entNasalInspComment: exam_entNasalInspComment || "",
    exam_entInspLipsComment: exam_entInspLipsComment || "",
    exam_entOropExamComment: exam_entOropExamComment || "",
    exam_neckExamComment: exam_neckExamComment || "",
    exam_neckExamThyroidComment: exam_neckExamThyroidComment || "",
    exam_pulmAssessRespComment: exam_pulmAssessRespComment || "",
    exam_pulmAuscLungsComment: exam_pulmAuscLungsComment || "",
    exam_cardioAusHeartComment: exam_cardioAusHeartComment || "",
    exam_cardioPalpAusComment: exam_cardioPalpAusComment || "",
    exam_cardioPedalPulseComment: exam_cardioPedalPulseComment || "",
    exam_cardioExamEdemaComment: exam_cardioExamEdemaComment || "",
    exam_cardioExamRadialComment: exam_cardioExamRadialComment || "",
    exam_lympPalpCerviComment: exam_lympPalpCerviComment || "",
    exam_lympPalpPreauricComment: exam_lympPalpPreauricComment || "",
    // exam_lympExamGaitComment: exam_lympExamGaitComment || "",
    exam_lympPalpSubmandiComment: exam_lympPalpSubmandiComment || "",
    exam_muscSkelExamGaitComment: exam_muscSkelExamGaitComment || "",
    exam_muscSkelInspDigitComment: exam_muscSkelInspDigitComment || "",
    exam_muscSkelInspJointComment: exam_muscSkelInspJointComment || "",
    exam_muscSkelAssessRangeComment: exam_muscSkelAssessRangeComment || "",
    exam_muscSkelAssessStableComment: exam_muscSkelAssessStableComment || "",
    exam_muscSkelAssessMuscleComment: exam_muscSkelAssessMuscleComment || "",
    // exam_skinAssessSkinComment: exam_skinAssessSkinComment || "",
    exam_skinInspSkinComment: exam_skinInspSkinComment || "",
    exam_psychJudgeComment: exam_psychJudgeComment || "",
    exam_psychOrientComment: exam_psychOrientComment || "",
    exam_psychRemoteComment: exam_psychRemoteComment || "",
    exam_psychMoodComment: exam_psychMoodComment || "",
    exam_neuroCranialTest: exam_neuroCranialTest || "",
    exam_neuroCranialTestIndicate: exam_neuroCranialTestIndicate || "",
    exam_neuroRombergComment: exam_neuroRombergComment || "",
    exam_neuroReflexComment: exam_neuroReflexComment || "",
    exam_neuroSenseComment: exam_neuroSenseComment || "",
    exam_neuroCoordinateComment: exam_neuroCoordinateComment || "",
    exam_diabFootAbnormalSubWhichFootComment:
      exam_diabFootAbnormalSubWhichFootComment || "",
    exam_diabFootComment: exam_diabFootComment || "",
    exam_genAppearAbnormalSubDesc: exam_genAppearAbnormalSubDesc || "",
    exam_headFaceExamAbnormalSubDesc: exam_headFaceExamAbnormalSubDesc || "",
    exam_headFacePalpAbnormalSubDesc: exam_headFacePalpAbnormalSubDesc || "",
    exam_eyesInspAbnormalSubDesc: exam_eyesInspAbnormalSubDesc || "",
    exam_eyesExamAbnormalSubDesc: exam_eyesExamAbnormalSubDesc || "",
    exam_entExtInspAbnormalSubDesc: exam_entExtInspAbnormalSubDesc || "",
    exam_entOtosExamAbnormalSubDesc: exam_entOtosExamAbnormalSubDesc || "",
    exam_entAssessHearAbnormalSubDesc: exam_entAssessHearAbnormalSubDesc || "",
    exam_entNasalInspAbnormalSubDesc: exam_entNasalInspAbnormalSubDesc || "",
    exam_entInspLipsAbnormalSubDesc: exam_entInspLipsAbnormalSubDesc || "",
    exam_entOropExamAbnormalSubDesc: exam_entOropExamAbnormalSubDesc || "",
    exam_neckExamAbnormalSubDesc: exam_neckExamAbnormalSubDesc || "",
    exam_neckExamThyroidAbnormalSubDesc:
      exam_neckExamThyroidAbnormalSubDesc || "",
    exam_pulmAssessRespAbnormalSubDesc:
      exam_pulmAssessRespAbnormalSubDesc || "",
    exam_pulmAuscLungsAbnormalSubDesc: exam_pulmAuscLungsAbnormalSubDesc || "",
    exam_cardioAusHeartAbnormalSubDesc:
      exam_cardioAusHeartAbnormalSubDesc || "",
    exam_cardioPalpAusAbnormalSubDesc: exam_cardioPalpAusAbnormalSubDesc || "",
    exam_cardioPedalPulseAbnormalSubDesc:
      exam_cardioPedalPulseAbnormalSubDesc || "",
    exam_cardioExamEdemaAbnormalSubDesc:
      exam_cardioExamEdemaAbnormalSubDesc || "",
    exam_cardioExamRadialAbnormalSubDesc:
      exam_cardioExamRadialAbnormalSubDesc || "",
    exam_lympPalpCerviAbnormalSubDesc: exam_lympPalpCerviAbnormalSubDesc || "",
    exam_lympPalpPreauricAbnormalSubDesc:
      exam_lympPalpPreauricAbnormalSubDesc || "",
    exam_lympPalpSubmandiAbnormalSubDesc:
      exam_lympPalpSubmandiAbnormalSubDesc || "",
    exam_muscSkelExamGaitAbnormalSubDesc:
      exam_muscSkelExamGaitAbnormalSubDesc || "",
    exam_muscSkelInspDigitAbnormalSubDesc:
      exam_muscSkelInspDigitAbnormalSubDesc || "",
    exam_muscSkelInspJointAbnormalSubDesc:
      exam_muscSkelInspJointAbnormalSubDesc || "",
    exam_muscSkelAssessRangeAbnormalSubDesc:
      exam_muscSkelAssessRangeAbnormalSubDesc || "",
    exam_muscSkelAssessStableAbnormalSubDesc:
      exam_muscSkelAssessStableAbnormalSubDesc || "",
    exam_muscSkelAssessMuscleAbnormalSubDesc:
      exam_muscSkelAssessMuscleAbnormalSubDesc || "",
    exam_skinInspSkinAbnormalSubDesc: exam_skinInspSkinAbnormalSubDesc || "",
    exam_psychJudgeAbnormalSubDesc: exam_psychJudgeAbnormalSubDesc || "",
    exam_psychOrientAbnormalSubDesc: exam_psychOrientAbnormalSubDesc || "",
    exam_psychRemoteAbnormalSubDesc: exam_psychRemoteAbnormalSubDesc || "",
    exam_psychMoodAbnormalSubDesc: exam_psychMoodAbnormalSubDesc || "",
    exam_neuroRombergAbnormalSubDesc: exam_neuroRombergAbnormalSubDesc || "",
    exam_neuroReflexAbnormalSubDesc: exam_neuroReflexAbnormalSubDesc || "",
    exam_neuroSenseAbnormalSubDesc: exam_neuroSenseAbnormalSubDesc || "",
    exam_neuroCoordinateAbnormalSubDesc:
      exam_neuroCoordinateAbnormalSubDesc || "",
    exam_diabFootAbnormalSubDesc: exam_diabFootAbnormalSubDesc || "",
  };

  if (year === "2023") {
    const exam_psychOrientAbnormalSubDesc =
      nestedChildsData["exam_psychOrientAbnormalSubDescVal"];

    const exam_psychOrientComment =
      nestedChildsData["exam_psychOrientCommentVal"];

    const exam_psychRemoteAbnormalSubDesc =
      nestedChildsData["exam_psychRemoteAbnormalSubDescVal"];

    const exam_psychRemoteComment =
      nestedChildsData["exam_psychRemoteCommentVal"];

    const exam_psychMoodAbnormalSubDesc =
      nestedChildsData["exam_psychMoodAbnormalSubDescVal"];

    const exam_psychMoodComment = nestedChildsData["exam_psychMoodCommentVal"];

    const exam_diabFootAbnormalSubDesc =
      nestedChildsData["exam_diabFootAbnormalSubDescVal"];

    const exam_diabFootAbnormalSubWhichFootComment =
      nestedChildsData["exam_diabFootAbnormalSubWhichFootCommentVal"];

    const exam_diabFootComment = nestedChildsData["exam_diabFootCommentVal"];

    flattenData = {
      ...flattenData,
      exam_psychOrientAbnormalSubDesc,
      exam_psychOrientComment,
      exam_psychRemoteAbnormalSubDesc,
      exam_psychRemoteComment,
      exam_psychMoodAbnormalSubDesc,
      exam_psychMoodComment,
      exam_diabFootAbnormalSubDesc,
      exam_diabFootAbnormalSubWhichFootComment,
      exam_diabFootComment,
    };
  } else if (year === "2024") {
    const exam_noGpcogMmsePatientOrientPersonComment =
      nestedChildsData["exam_noGpcogMmsePatientOrientPersonCommentVal"];
    const exam_noGpcogMmsePatientOrientPersonNoSubDesc =
      nestedChildsData["exam_noGpcogMmsePatientOrientPersonNoSubDescVal"];
    // const exam_noGpcogMmsePatientOrientPerson =
    // getOptionsSelectedYesNo(
    //   nestedChildsData[
    //     "exam_noGpcogMmsePatientOrientPersonVal"
    //   ],
    // );
    // const exam_noGpcogMmsePatientOrientPlace =
    // getOptionsSelectedYesNo(
    //   nestedChildsData[
    //     "exam_noGpcogMmsePatientOrientPlaceVal"
    //   ],
    // );
    const exam_noGpcogMmsePatientOrientPlaceComment =
      nestedChildsData["exam_noGpcogMmsePatientOrientPlaceCommentVal"];
    const exam_noGpcogMmsePatientOrientPlaceNoSubDesc =
      nestedChildsData["exam_noGpcogMmsePatientOrientPlaceNoSubDescVal"];
    // const exam_noGpcogMmsePatientOrientTime =
    // getOptionsSelectedYesNo(
    //   nestedChildsData[
    //     "exam_noGpcogMmsePatientOrientTimeVal"
    //   ],
    // );
    const exam_noGpcogMmsePatientOrientTimeComment =
      nestedChildsData["exam_noGpcogMmsePatientOrientTimeCommentVal"];
    const exam_noGpcogMmsePatientOrientTimeNoSubDesc =
      nestedChildsData["exam_noGpcogMmsePatientOrientTimeNoSubDescVal"];
    const exam_noGpcogMmseRecallComment =
      nestedChildsData["exam_noGpcogMmseRecallCommentVal"];
    const exam_noGpcogMmseRecallPoorSubDesc =
      nestedChildsData["exam_noGpcogMmseRecallPoorSubDescVal"];
    const exam_noGpcogMmsePatientDescribeNewsComment =
      nestedChildsData["exam_noGpcogMmsePatientDescribeNewsCommentVal"];
    const exam_psychMoodSubComment =
      nestedChildsData["exam_psychMoodSubCommentVal"];
    const exam_psychMoodComment = nestedChildsData["exam_psychMoodCommentVal"];

    const ros_speechComment = nestedChildsData["ros_speechCommentVal"];
    const ros_speechSubComment = nestedChildsData["ros_speechSubCommentVal"];

    const ros_fingerToNoseAbnormalSubComment =
      nestedChildsData["ros_fingerToNoseAbnormalSubCommentVal"];
    const ros_fingerToNoseComment =
      nestedChildsData["ros_fingerToNoseCommentVal"];

    const ros_heelToToeComment = nestedChildsData["ros_heelToToeCommentVal"];
    const ros_heelToToeAbnormalSubComment =
      nestedChildsData["ros_heelToToeAbnormalSubCommentVal"];

    const ros_thumbToFingerComment =
      nestedChildsData["ros_thumbToFingerCommentVal"];
    const ros_thumbToFingerAbnormalSubComment =
      nestedChildsData["ros_thumbToFingerAbnormalSubCommentVal"];

    const ros_sitToStand = nestedChildsData["ros_sitToStandVal"];
    const ros_extremeMove = nestedChildsData["ros_extremeMoveVal"];

    const exam_diabetecFootExamAbnormalSubDesc =
      nestedChildsData["exam_diabetecFootExamAbnormalSubDescVal"];
    const exam_diabFootComment = nestedChildsData["exam_diabFootCommentVal"];
    const ros_sitToStandComment = nestedChildsData["ros_sitToStandCommentVal"];
    const ros_extremeMoveComment =
      nestedChildsData["ros_extremeMoveCommentVal"];

    flattenData = {
      ...flattenData,
      exam_noGpcogMmsePatientOrientPersonComment,
      exam_noGpcogMmsePatientOrientPersonNoSubDesc,
      // exam_noGpcogMmsePatientOrientPerson,
      // exam_noGpcogMmsePatientOrientPlace,
      exam_noGpcogMmsePatientOrientPlaceComment,
      exam_noGpcogMmsePatientOrientPlaceNoSubDesc,
      //exam_noGpcogMmsePatientOrientTime,
      exam_noGpcogMmsePatientOrientTimeComment,
      exam_noGpcogMmsePatientOrientTimeNoSubDesc,
      exam_noGpcogMmseRecallComment,
      exam_noGpcogMmsePatientDescribeNewsComment,
      exam_psychMoodSubComment,
      exam_psychMoodComment,
      ros_speechComment,
      ros_speechSubComment,
      ros_fingerToNoseAbnormalSubComment,
      ros_fingerToNoseComment,
      ros_heelToToeComment,
      ros_heelToToeAbnormalSubComment,
      ros_thumbToFingerComment,
      ros_thumbToFingerAbnormalSubComment,
      ros_sitToStand,
      ros_extremeMove,
      exam_diabetecFootExamAbnormalSubDesc,
      exam_diabFootComment,
      exam_noGpcogMmseRecallPoorSubDesc,
      ros_sitToStandComment,
      ros_extremeMoveComment,
    };
  }

  return flattenData;
};

export const setExamReviewData = (formData, year) => {
  let flattenData = {};

  // let treeMappers = getTreeMapperByYear(year);
  let treeWrappers = getTreeWrapperByYear(year);

  treeWrappers.forEach((pointerLabels) => {
    flattenData = {
      ...flattenData,
      ...setWrapper(formData[pointerLabels[0]], pointerLabels[1], year),
    };
  });

  let payloadData = {};

  const exam_genAppearCommentVal = formData["exam_genAppearComment"];
  const exam_headFaceExamCommentVal = formData["exam_headFaceExamComment"];
  const exam_headFacePalpCommentVal = formData["exam_headFacePalpComment"];
  const exam_eyesInspCommentVal = formData["exam_eyesInspComment"];
  const exam_eyesExamCommentVal = formData["exam_eyesExamComment"];
  const exam_entExtInspCommentVal = formData["exam_entExtInspComment"];
  const exam_entOtosExamCommentVal = formData["exam_entOtosExamComment"];
  const exam_entAssessHearCommentVal = formData["exam_entAssessHearComment"];
  const exam_entNasalInspCommentVal = formData["exam_entNasalInspComment"];
  const exam_entInspLipsCommentVal = formData["exam_entInspLipsComment"];
  const exam_entOropExamCommentVal = formData["exam_entOropExamComment"];
  const exam_neckExamCommentVal = formData["exam_neckExamComment"];
  const exam_neckExamThyroidCommentVal =
    formData["exam_neckExamThyroidComment"];
  const exam_pulmAssessRespCommentVal = formData["exam_pulmAssessRespComment"];
  const exam_pulmAuscLungsCommentVal = formData["exam_pulmAuscLungsComment"];
  const exam_cardioAusHeartCommentVal = formData["exam_cardioAusHeartComment"];
  const exam_cardioPalpAusCommentVal = formData["exam_cardioPalpAusComment"];
  const exam_cardioPedalPulseCommentVal =
    formData["exam_cardioPedalPulseComment"];
  const exam_cardioExamEdemaCommentVal =
    formData["exam_cardioExamEdemaComment"];
  const exam_cardioExamRadialCommentVal =
    formData["exam_cardioExamRadialComment"];
  const exam_lympPalpCerviCommentVal = formData["exam_lympPalpCerviComment"];
  const exam_lympPalpPreauricCommentVal =
    formData["exam_lympPalpPreauricComment"];
  // const exam_lympExamGaitCommentVal = formData["exam_lympExamGaitComment"];
  const exam_lympPalpSubmandiCommentVal =
    formData["exam_lympPalpSubmandiComment"];
  const exam_muscSkelExamGaitCommentVal =
    formData["exam_muscSkelExamGaitComment"];
  const exam_muscSkelInspDigitCommentVal =
    formData["exam_muscSkelInspDigitComment"];
  const exam_muscSkelInspJointCommentVal =
    formData["exam_muscSkelInspJointComment"];
  const exam_muscSkelAssessRangeCommentVal =
    formData["exam_muscSkelAssessRangeComment"];
  const exam_muscSkelAssessStableCommentVal =
    formData["exam_muscSkelAssessStableComment"];
  const exam_muscSkelAssessMuscleCommentVal =
    formData["exam_muscSkelAssessMuscleComment"];
  // const exam_skinAssessSkinCommentVal = formData["exam_skinAssessSkinComment"];
  const exam_skinInspSkinCommentVal = formData["exam_skinInspSkinComment"];
  const exam_psychJudgeCommentVal = formData["exam_psychJudgeComment"];
  const exam_psychOrientCommentVal = formData["exam_psychOrientComment"];
  const exam_psychRemoteCommentVal = formData["exam_psychRemoteComment"];
  const exam_psychMoodCommentVal = formData["exam_psychMoodComment"];
  const exam_neuroCranialTestVal = formData["exam_neuroCranialTest"];
  const exam_neuroCranialTestIndicateVal =
    formData["exam_neuroCranialTestIndicate"];
  const exam_neuroRombergCommentVal = formData["exam_neuroRombergComment"];
  const exam_neuroReflexCommentVal = formData["exam_neuroReflexComment"];
  const exam_neuroSenseCommentVal = formData["exam_neuroSenseComment"];
  const exam_neuroCoordinateCommentVal =
    formData["exam_neuroCoordinateComment"];
  const exam_diabFootAbnormalSubWhichFootCommentVal =
    formData["exam_diabFootAbnormalSubWhichFootComment"];
  const exam_diabFootCommentVal = formData["exam_diabFootComment"];

  const exam_genAppearAbnormalSubDescVal =
    formData["exam_genAppearAbnormalSubDesc"];
  const exam_headFaceExamAbnormalSubDescVal =
    formData["exam_headFaceExamAbnormalSubDesc"];
  const exam_headFacePalpAbnormalSubDescVal =
    formData["exam_headFacePalpAbnormalSubDesc"];
  const exam_eyesInspAbnormalSubDescVal =
    formData["exam_eyesInspAbnormalSubDesc"];
  const exam_eyesExamAbnormalSubDescVal =
    formData["exam_eyesExamAbnormalSubDesc"];
  const exam_entExtInspAbnormalSubDescVal =
    formData["exam_entExtInspAbnormalSubDesc"];
  const exam_entOtosExamAbnormalSubDescVal =
    formData["exam_entOtosExamAbnormalSubDesc"];
  const exam_entAssessHearAbnormalSubDescVal =
    formData["exam_entAssessHearAbnormalSubDesc"];
  const exam_entNasalInspAbnormalSubDescVal =
    formData["exam_entNasalInspAbnormalSubDesc"];
  const exam_entInspLipsAbnormalSubDescVal =
    formData["exam_entInspLipsAbnormalSubDesc"];
  const exam_entOropExamAbnormalSubDescVal =
    formData["exam_entOropExamAbnormalSubDesc"];
  const exam_neckExamAbnormalSubDescVal =
    formData["exam_neckExamAbnormalSubDesc"];
  const exam_neckExamThyroidAbnormalSubDescVal =
    formData["exam_neckExamThyroidAbnormalSubDesc"];
  const exam_pulmAssessRespAbnormalSubDescVal =
    formData["exam_pulmAssessRespAbnormalSubDesc"];
  const exam_pulmAuscLungsAbnormalSubDescVal =
    formData["exam_pulmAuscLungsAbnormalSubDesc"];
  const exam_cardioAusHeartAbnormalSubDescVal =
    formData["exam_cardioAusHeartAbnormalSubDesc"];
  const exam_cardioPalpAusAbnormalSubDescVal =
    formData["exam_cardioPalpAusAbnormalSubDesc"];
  const exam_cardioPedalPulseAbnormalSubDescVal =
    formData["exam_cardioPedalPulseAbnormalSubDesc"];
  const exam_cardioExamEdemaAbnormalSubDescVal =
    formData["exam_cardioExamEdemaAbnormalSubDesc"];
  const exam_cardioExamRadialAbnormalSubDescVal =
    formData["exam_cardioExamRadialAbnormalSubDesc"];
  const exam_lympPalpCerviAbnormalSubDescVal =
    formData["exam_lympPalpCerviAbnormalSubDesc"];
  const exam_lympPalpPreauricAbnormalSubDescVal =
    formData["exam_lympPalpPreauricAbnormalSubDesc"];
  const exam_lympPalpSubmandiAbnormalSubDescVal =
    formData["exam_lympPalpSubmandiAbnormalSubDesc"];
  const exam_muscSkelExamGaitAbnormalSubDescVal =
    formData["exam_muscSkelExamGaitAbnormalSubDesc"];
  const exam_muscSkelInspDigitAbnormalSubDescVal =
    formData["exam_muscSkelInspDigitAbnormalSubDesc"];
  const exam_muscSkelInspJointAbnormalSubDescVal =
    formData["exam_muscSkelInspJointAbnormalSubDesc"];
  const exam_muscSkelAssessRangeAbnormalSubDescVal =
    formData["exam_muscSkelAssessRangeAbnormalSubDesc"];
  const exam_muscSkelAssessStableAbnormalSubDescVal =
    formData["exam_muscSkelAssessStableAbnormalSubDesc"];
  const exam_muscSkelAssessMuscleAbnormalSubDescVal =
    formData["exam_muscSkelAssessMuscleAbnormalSubDesc"];
  const exam_skinInspSkinAbnormalSubDescVal =
    formData["exam_skinInspSkinAbnormalSubDesc"];
  const exam_psychJudgeAbnormalSubDescVal =
    formData["exam_psychJudgeAbnormalSubDesc"];
  const exam_psychOrientAbnormalSubDescVal =
    formData["exam_psychOrientAbnormalSubDesc"];
  const exam_psychRemoteAbnormalSubDescVal =
    formData["exam_psychRemoteAbnormalSubDesc"];
  const exam_psychMoodAbnormalSubDescVal =
    formData["exam_psychMoodAbnormalSubDesc"];
  const exam_neuroRombergAbnormalSubDescVal =
    formData["exam_neuroRombergAbnormalSubDesc"];
  const exam_neuroReflexAbnormalSubDescVal =
    formData["exam_neuroReflexAbnormalSubDesc"];
  const exam_neuroSenseAbnormalSubDescVal =
    formData["exam_neuroSenseAbnormalSubDesc"];
  const exam_neuroCoordinateAbnormalSubDescVal =
    formData["exam_neuroCoordinateAbnormalSubDesc"];
  const exam_diabFootAbnormalSubDescVal =
    formData["exam_diabFootAbnormalSubDesc"];

  flattenData = {
    ...flattenData,
    exam_genAppearCommentVal: exam_genAppearCommentVal || "",
    exam_headFaceExamCommentVal: exam_headFaceExamCommentVal || "",
    exam_headFacePalpCommentVal: exam_headFacePalpCommentVal || "",
    exam_eyesInspCommentVal: exam_eyesInspCommentVal || "",
    exam_eyesExamCommentVal: exam_eyesExamCommentVal || "",
    exam_entExtInspCommentVal: exam_entExtInspCommentVal || "",
    exam_entOtosExamCommentVal: exam_entOtosExamCommentVal || "",
    exam_entAssessHearCommentVal: exam_entAssessHearCommentVal || "",
    exam_entNasalInspCommentVal: exam_entNasalInspCommentVal || "",
    exam_entInspLipsCommentVal: exam_entInspLipsCommentVal || "",
    exam_entOropExamCommentVal: exam_entOropExamCommentVal || "",
    exam_neckExamCommentVal: exam_neckExamCommentVal || "",
    exam_neckExamThyroidCommentVal: exam_neckExamThyroidCommentVal || "",
    exam_pulmAssessRespCommentVal: exam_pulmAssessRespCommentVal || "",
    exam_pulmAuscLungsCommentVal: exam_pulmAuscLungsCommentVal || "",
    exam_cardioAusHeartCommentVal: exam_cardioAusHeartCommentVal || "",
    exam_cardioPalpAusCommentVal: exam_cardioPalpAusCommentVal || "",
    exam_cardioPedalPulseCommentVal: exam_cardioPedalPulseCommentVal || "",
    exam_cardioExamEdemaCommentVal: exam_cardioExamEdemaCommentVal || "",
    exam_cardioExamRadialCommentVal: exam_cardioExamRadialCommentVal || "",
    exam_lympPalpCerviCommentVal: exam_lympPalpCerviCommentVal || "",
    exam_lympPalpPreauricCommentVal: exam_lympPalpPreauricCommentVal || "",
    // exam_lympExamGaitCommentVal : exam_lympExamGaitCommentVal || "",
    exam_lympPalpSubmandiCommentVal: exam_lympPalpSubmandiCommentVal || "",
    exam_muscSkelExamGaitCommentVal: exam_muscSkelExamGaitCommentVal || "",
    exam_muscSkelInspDigitCommentVal: exam_muscSkelInspDigitCommentVal || "",
    exam_muscSkelInspJointCommentVal: exam_muscSkelInspJointCommentVal || "",
    exam_muscSkelAssessRangeCommentVal:
      exam_muscSkelAssessRangeCommentVal || "",
    exam_muscSkelAssessStableCommentVal:
      exam_muscSkelAssessStableCommentVal || "",
    exam_muscSkelAssessMuscleCommentVal:
      exam_muscSkelAssessMuscleCommentVal || "",
    // exam_skinAssessSkinCommentVal:exam_skinAssessSkinCommentVal || "",
    exam_skinInspSkinCommentVal: exam_skinInspSkinCommentVal || "",
    exam_psychJudgeCommentVal: exam_psychJudgeCommentVal || "",
    exam_psychOrientCommentVal: exam_psychOrientCommentVal || "",
    exam_psychRemoteCommentVal: exam_psychRemoteCommentVal || "",
    exam_psychMoodCommentVal: exam_psychMoodCommentVal || "",
    exam_neuroCranialTestVal: exam_neuroCranialTestVal || "",
    exam_neuroCranialTestIndicateVal: exam_neuroCranialTestIndicateVal || "",
    exam_neuroRombergCommentVal: exam_neuroRombergCommentVal || "",
    exam_neuroReflexCommentVal: exam_neuroReflexCommentVal || "",
    exam_neuroSenseCommentVal: exam_neuroSenseCommentVal || "",
    exam_neuroCoordinateCommentVal: exam_neuroCoordinateCommentVal || "",
    exam_diabFootAbnormalSubWhichFootCommentVal:
      exam_diabFootAbnormalSubWhichFootCommentVal || "",
    exam_diabFootCommentVal: exam_diabFootCommentVal || "",
    exam_genAppearAbnormalSubDescVal: exam_genAppearAbnormalSubDescVal || "",
    exam_headFaceExamAbnormalSubDescVal:
      exam_headFaceExamAbnormalSubDescVal || "",
    exam_headFacePalpAbnormalSubDescVal:
      exam_headFacePalpAbnormalSubDescVal || "",
    exam_eyesInspAbnormalSubDescVal: exam_eyesInspAbnormalSubDescVal || "",
    exam_eyesExamAbnormalSubDescVal: exam_eyesExamAbnormalSubDescVal || "",
    exam_entExtInspAbnormalSubDescVal: exam_entExtInspAbnormalSubDescVal || "",
    exam_entOtosExamAbnormalSubDescVal:
      exam_entOtosExamAbnormalSubDescVal || "",
    exam_entAssessHearAbnormalSubDescVal:
      exam_entAssessHearAbnormalSubDescVal || "",
    exam_entNasalInspAbnormalSubDescVal:
      exam_entNasalInspAbnormalSubDescVal || "",
    exam_entInspLipsAbnormalSubDescVal:
      exam_entInspLipsAbnormalSubDescVal || "",
    exam_entOropExamAbnormalSubDescVal:
      exam_entOropExamAbnormalSubDescVal || "",
    exam_neckExamAbnormalSubDescVal: exam_neckExamAbnormalSubDescVal || "",
    exam_neckExamThyroidAbnormalSubDescVal:
      exam_neckExamThyroidAbnormalSubDescVal || "",
    exam_pulmAssessRespAbnormalSubDescVal:
      exam_pulmAssessRespAbnormalSubDescVal || "",
    exam_pulmAuscLungsAbnormalSubDescVal:
      exam_pulmAuscLungsAbnormalSubDescVal || "",
    exam_cardioAusHeartAbnormalSubDescVal:
      exam_cardioAusHeartAbnormalSubDescVal || "",
    exam_cardioPalpAusAbnormalSubDescVal:
      exam_cardioPalpAusAbnormalSubDescVal || "",
    exam_cardioPedalPulseAbnormalSubDescVal:
      exam_cardioPedalPulseAbnormalSubDescVal || "",
    exam_cardioExamEdemaAbnormalSubDescVal:
      exam_cardioExamEdemaAbnormalSubDescVal || "",
    exam_cardioExamRadialAbnormalSubDescVal:
      exam_cardioExamRadialAbnormalSubDescVal || "",
    exam_lympPalpCerviAbnormalSubDescVal:
      exam_lympPalpCerviAbnormalSubDescVal || "",
    exam_lympPalpPreauricAbnormalSubDescVal:
      exam_lympPalpPreauricAbnormalSubDescVal || "",
    exam_lympPalpSubmandiAbnormalSubDescVal:
      exam_lympPalpSubmandiAbnormalSubDescVal || "",
    exam_muscSkelExamGaitAbnormalSubDescVal:
      exam_muscSkelExamGaitAbnormalSubDescVal || "",
    exam_muscSkelInspDigitAbnormalSubDescVal:
      exam_muscSkelInspDigitAbnormalSubDescVal || "",
    exam_muscSkelInspJointAbnormalSubDescVal:
      exam_muscSkelInspJointAbnormalSubDescVal || "",
    exam_muscSkelAssessRangeAbnormalSubDescVal:
      exam_muscSkelAssessRangeAbnormalSubDescVal || "",
    exam_muscSkelAssessStableAbnormalSubDescVal:
      exam_muscSkelAssessStableAbnormalSubDescVal || "",
    exam_muscSkelAssessMuscleAbnormalSubDescVal:
      exam_muscSkelAssessMuscleAbnormalSubDescVal || "",
    exam_skinInspSkinAbnormalSubDescVal:
      exam_skinInspSkinAbnormalSubDescVal || "",
    exam_psychJudgeAbnormalSubDescVal: exam_psychJudgeAbnormalSubDescVal || "",
    exam_psychOrientAbnormalSubDescVal:
      exam_psychOrientAbnormalSubDescVal || "",
    exam_psychRemoteAbnormalSubDescVal:
      exam_psychRemoteAbnormalSubDescVal || "",
    exam_psychMoodAbnormalSubDescVal: exam_psychMoodAbnormalSubDescVal || "",
    exam_neuroRombergAbnormalSubDescVal:
      exam_neuroRombergAbnormalSubDescVal || "",
    exam_neuroReflexAbnormalSubDescVal:
      exam_neuroReflexAbnormalSubDescVal || "",
    exam_neuroSenseAbnormalSubDescVal: exam_neuroSenseAbnormalSubDescVal || "",
    exam_neuroCoordinateAbnormalSubDescVal:
      exam_neuroCoordinateAbnormalSubDescVal || "",
    exam_diabFootAbnormalSubDescVal: exam_diabFootAbnormalSubDescVal || "",
  };

  if (year === "2023") {
    const exam_psychOrientAbnormalSubDescVal =
      formData["exam_psychOrientAbnormalSubDesc"];

    const exam_psychOrientCommentVal = formData["exam_psychOrientComment"];

    const exam_psychRemoteAbnormalSubDescVal =
      formData["exam_psychRemoteAbnormalSubDesc"];

    const exam_psychRemoteCommentVal = formData["exam_psychRemoteComment"];

    const exam_psychMoodAbnormalSubDescVal =
      formData["exam_psychMoodAbnormalSubDesc"];

    const exam_psychMoodCommentVal = formData["exam_psychMoodComment"];

    const exam_diabFootAbnormalSubDescVal =
      formData["exam_diabFootAbnormalSubDesc"];

    const exam_diabFootAbnormalSubWhichFootCommentVal =
      formData["exam_diabFootAbnormalSubWhichFootComment"];

    const exam_diabFootCommentVal = formData["exam_diabFootComment"];

    flattenData = {
      ...flattenData,
      exam_psychOrientAbnormalSubDescVal,
      exam_psychOrientCommentVal,
      exam_psychRemoteAbnormalSubDescVal,
      exam_psychRemoteCommentVal,
      exam_psychMoodAbnormalSubDescVal,
      exam_psychMoodCommentVal,
      exam_diabFootAbnormalSubDescVal,
      exam_diabFootAbnormalSubWhichFootCommentVal,
      exam_diabFootCommentVal,
    };
  } else if (year === "2024") {
    const exam_noGpcogMmsePatientOrientPersonCommentVal =
      formData["exam_noGpcogMmsePatientOrientPersonComment"];
    const exam_noGpcogMmsePatientOrientPersonNoSubDescVal =
      formData["exam_noGpcogMmsePatientOrientPersonNoSubDesc"];
    // const exam_noGpcogMmsePatientOrientPersonVal =
    // setOptionsSelectedYesNo(
    //   formData[
    //     "exam_noGpcogMmsePatientOrientPerson"
    //   ],
    // );
    // const exam_noGpcogMmsePatientOrientPlaceVal =
    // setOptionsSelectedYesNo(
    //   formData[
    //     "exam_noGpcogMmsePatientOrientPlace"
    //   ],
    // );
    const exam_noGpcogMmsePatientOrientPlaceCommentVal =
      formData["exam_noGpcogMmsePatientOrientPlaceComment"];
    const exam_noGpcogMmsePatientOrientPlaceNoSubDescVal =
      formData["exam_noGpcogMmsePatientOrientPlaceNoSubDesc"];
    // const exam_noGpcogMmsePatientOrientTimeVal =
    // setOptionsSelectedYesNo(
    //   formData[
    //     "exam_noGpcogMmsePatientOrientTime"
    //   ],
    // );
    const exam_noGpcogMmseRecallPoorSubDescVal =
      formData["exam_noGpcogMmseRecallPoorSubDesc"];

    const exam_noGpcogMmsePatientOrientTimeCommentVal =
      formData["exam_noGpcogMmsePatientOrientTimeComment"];
    const exam_noGpcogMmsePatientOrientTimeNoSubDescVal =
      formData["exam_noGpcogMmsePatientOrientTimeNoSubDesc"];
    const exam_noGpcogMmseRecallCommentVal =
      formData["exam_noGpcogMmseRecallComment"];
    const exam_noGpcogMmsePatientDescribeNewsCommentVal =
      formData["exam_noGpcogMmsePatientDescribeNewsComment"];
    const exam_psychMoodSubCommentVal = formData["exam_psychMoodSubComment"];
    const exam_psychMoodCommentVal = formData["exam_psychMoodComment"];

    const ros_speechCommentVal = formData["ros_speechComment"];
    const ros_speechSubCommentVal = formData["ros_speechSubComment"];

    const ros_fingerToNoseAbnormalSubCommentVal =
      formData["ros_fingerToNoseAbnormalSubComment"];
    const ros_fingerToNoseCommentVal = formData["ros_fingerToNoseComment"];

    const ros_heelToToeCommentVal = formData["ros_heelToToeComment"];
    const ros_heelToToeAbnormalSubCommentVal =
      formData["ros_heelToToeAbnormalSubComment"];

    const ros_thumbToFingerCommentVal = formData["ros_thumbToFingerComment"];
    const ros_thumbToFingerAbnormalSubCommentVal =
      formData["ros_thumbToFingerAbnormalSubComment"];

    const ros_sitToStandVal = formData["ros_sitToStand"];
    const ros_extremeMoveVal = formData["ros_extremeMove"];

    const exam_diabetecFootExamAbnormalSubDescVal =
      formData["exam_diabetecFootExamAbnormalSubDesc"];
    const exam_diabFootCommentVal = formData["exam_diabFootComment"];
    const ros_sitToStandCommentVal = formData["ros_sitToStandComment"];
    const ros_extremeMoveCommentVal = formData["ros_extremeMoveComment"];

    flattenData = {
      ...flattenData,
      exam_noGpcogMmsePatientOrientPersonCommentVal,
      exam_noGpcogMmsePatientOrientPersonNoSubDescVal,
      // exam_noGpcogMmsePatientOrientPersonVal,
      // exam_noGpcogMmsePatientOrientPlaceVal,
      exam_noGpcogMmsePatientOrientPlaceCommentVal,
      exam_noGpcogMmsePatientOrientPlaceNoSubDescVal,
      //exam_noGpcogMmsePatientOrientTimeVal,
      exam_noGpcogMmsePatientOrientTimeCommentVal,
      exam_noGpcogMmsePatientOrientTimeNoSubDescVal,
      exam_noGpcogMmseRecallCommentVal,
      exam_noGpcogMmsePatientDescribeNewsCommentVal,
      exam_psychMoodSubCommentVal,
      exam_psychMoodCommentVal,
      ros_speechCommentVal,
      ros_speechSubCommentVal,
      ros_fingerToNoseAbnormalSubCommentVal,
      ros_fingerToNoseCommentVal,
      ros_heelToToeCommentVal,
      ros_heelToToeAbnormalSubCommentVal,
      ros_thumbToFingerCommentVal,
      ros_thumbToFingerAbnormalSubCommentVal,
      ros_sitToStandVal,
      ros_extremeMoveVal,
      exam_diabetecFootExamAbnormalSubDescVal,
      exam_diabFootCommentVal,
      exam_noGpcogMmseRecallPoorSubDescVal,
      ros_sitToStandCommentVal,
      ros_extremeMoveCommentVal,
    };
  }

  let flatmap = [];

  examReview_flatmap.forEach((quesData) => {
    let yearList = quesData?.[2] || [];
    if (yearList.includes(year)) {
      flatmap.push(quesData);
    }
  });

  flatmap.forEach((questionPair) => {
    //if (tempData[questionPair[0]] !== "") {
    setObjectData(payloadData, questionPair[1], flattenData[questionPair[0]]);
    //}
  });

  return payloadData;
};

const getTreeMapperByYear = (year) => {
  let temp = {};
  if (year === "2023") {
    temp = { ...examReview_optionsTreeMapObject2023 };
  } else if (year === "2024") {
    temp = { ...examReview_optionsTreeMapObject2024 };
  }

  return temp;
};

const getTreeWrapperByYear = (year) => {
  let temp = [];
  if (year === "2023") {
    temp = [...examReviewOptionsTreeWrappers2023];
  } else if (year === "2024") {
    temp = [...examReviewOptionsTreeWrappers2024];
  }

  return temp;
};

const getWrapper = (tempData, keyArray, year) => {
  let temp = getOptionsSelected(tempData, keyArray, year);
  return temp;
};

const setWrapper = (tempData, keyArray, year) => {
  let temp = setOptionsSelected(tempData, keyArray, year);
  return temp;
};

export const getOptionsSelectedYesNo = (optionValue) => {
  let selectedValue;
  if (optionValue === "1") {
    selectedValue = ["yes"];
  } else if (optionValue === "2") {
    selectedValue = ["no"];
  } else {
    selectedValue = [""];
  }

  return selectedValue;
};

const setOptionsSelectedYesNo = (optionValue) => {
  let selectedValue;
  if (optionValue === "yes") {
    selectedValue = "1";
  } else if (optionValue === "no") {
    selectedValue = "2";
  } else {
    selectedValue = "";
  }

  return selectedValue;
};

const getOptionsSelected = (
  optionObject,
  optionArray,
  year,
  multiSelect = false,
) => {
  const selectedVariable = optionArray?.filter((option) => {
    return optionObject[option] === "1";
  });

  let selectedOption;
  let treeMappers = getTreeMapperByYear(year);

  if (multiSelect) {
    selectedOption = selectedVariable.map((variable) => {
      return treeMappers[variable];
    });
  } else {
    selectedOption = treeMappers[selectedVariable[0]];
  }
  return selectedOption;
};

const setOptionsSelected = (
  optionsSelected,
  optionArray,
  year,
  multiSelect = false,
) => {
  let temp = {};

  let treeMappers = getTreeMapperByYear(year);

  if (multiSelect) {
    optionArray.forEach((option) => {
      temp[option] = optionsSelected.includes(treeMappers[option]) ? "1" : "";
    });
  } else {
    optionArray.forEach((option) => {
      temp[option] = treeMappers[option] === optionsSelected ? "1" : "";
    });
  }
  return temp;
};

export const getSubTree = (optionName, optionValue) => {
  let getSubTreeRegex = getSubTreeString(
    optionName,
    optionValue,
    examReviewOptionsTreeWrappers2023,
    //
    examReview_optionsTreeMapObject2023,
    examReviewOptionsTreeWrappers2024,
    examReview_optionsTreeMapObject2024,
  );
  return getSubTreeRegex;
};

export const deleteSubTree = (formData, subTreeRegex) => {
  let updatedData = deleteSubTreeData(formData, subTreeRegex);
  return updatedData;
};
