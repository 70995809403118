import { useContext, useState } from "react";
import _ from "lodash";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { SelectDropdown } from "../../select/select";
import * as React from "react";
import { useUserContext } from "../../../contexts/UserContext";
import { useForm, Controller } from "react-hook-form";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import "./Dialog.css";
import { getAllClients } from "../../../api/clientsApi";
import AlertContext from "../../../contexts/AlertContext";
import { getApi, postApi } from "../../../utils/axiosApiWrapper";
import MuiInput from "../../muiinput/MuiInput";
import Loader from "../../Loader/Loader";
import { id } from "date-fns/locale";
import Chip from "@mui/material/Chip";
import Toaster from "../../toaster/toaster";
import Labels from "../../../resource_files/Labels.resx";
import { Clear, Refresh } from "@mui/icons-material";

const TextFieldComp = ({ placeholder, styles, inputStyles, ...props }) => {
  return (
    <Box sx={{ "& > :not(style)": { m: 1 } }}>
      <TextField
        sx={{
          width: { sm: 150, md: 250 },
          "& .MuiInputBase-root": {
            height: 50,
          },
        }}
        id="input-with-icon-textfield"
        label={placeholder}
        InputLabelProps={{
          style: styles,
        }}
        InputProps={{
          disableUnderline: true,
          sx: {
            borderRadius: "4px",
            border: "1px solid var(--mainTheme)",
            background: "#FFF",
          },
          startAdornment: <InputAdornment position="start"></InputAdornment>,
        }}
        variant="standard"
        {...props}
      />
    </Box>
  );
};

const storedRoleId = localStorage.getItem("roleId");

const DialogInput = (props) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
      }}
    >
      <InputLabel sx={props.styles} htmlFor="input-with-icon-adornment">
        {props.title}
      </InputLabel>
      {props.children}
    </div>
  );
};
const filterDialogLabel = {
  color: "#000",
  fontFamily: "Inter,sans-serif",
  fontSize: 12,
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  marginLeft: "6px",
};
const inputStyles = {
  borderRadius: "4px",
  border: "1px solid var(--mainTheme)",
  background: "#FFF",
};

const dialogBtn = {
  width: "131px",
  height: "32px",
  flexShrink: "0",
  borderRadius: "20px",
  background: "var(--mainTheme)",
  color: "white",
  "&:hover": {
    boxShadow: 8,
    color: "var(--mainTheme)",
  },
};
export default function CustomizedDialogs({
  hraStatusOptions,
  visitTypeOptions,
  clinicianData,
  tabId,
  selectedDropdownValue,
}) {
  const alertContext = useContext(AlertContext);
  const {
    programTypesOptions,
    lobTypesOptions,
    lobOptionData,
    apiResponseData,
    clinicianDataList,
    hraStatusData,
    visitTypeOptionsData,
  } = useUserContext();
  const [isLoading, setIsLoading] = React.useState(false);
  const formmethod = useForm();
  const [programOptions, setProgramOptions] = useState([]);
  const storedPlanId = localStorage.getItem("selectedClientId") || "";
  const [toasterMessage, setToasterMessage] = useState("");
  const [toasterOpen, setToasterOpen] = useState(false);
  const [toasterSeverity, setToasterSeverity] = useState(Labels.SUCCESS);
  const [allQaList, setAllQaList] = useState([]);
  const {
    register,
    control,
    handleSubmit,
    watch,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = formmethod;
  const storedPlan = localStorage.getItem("clientPlan");
  const storedYear = localStorage.getItem("clientYear");
  const screeningTypeOptions = ["Screening", "HRA"];
  const getMemberApiData = async () => {
    await getAllClients()
      .then((response) => {
        const allMappingData = response?.data?.data.map((data) => data);
        let selectedClientBasedObjectValue = _.find(allMappingData, {
          plan: storedPlan,
        });
        setProgramOptions(selectedClientBasedObjectValue?.program);
      })
      .catch((error) => {
        return;
      });
  };

  const handleClose = () => {
    alertContext.setFilterDialog(false);
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  React.useEffect(() => {
    reset(alertContext?.filterDialogData);
  }, []);

  React.useEffect(() => {
    // Add necessary conditions to avoid unnecessary calls
    if (storedPlanId && storedPlan) {
      // Ensure both storedPlanId and storedPlan are available
      getMemberApiData();
    }
  }, [storedPlanId, storedPlan]);

  const [isFetchingQaList, setIsFetchingQaList] = useState(false);

  const getAllQaList = async () => {
    try {
      setIsFetchingQaList(true); // Set fetching flag to true
      const response = await postApi("/users/get-users-by-roleId", {
        planId: storedPlanId,
        roleId: "6,7",
      });
      const data = response?.data?.data || [];

      if (Array.isArray(data) && data.length > 0) {
        setAllQaList(data); // Set data only if valid
      }
    } catch (error) {
      console.error("Error fetching QA list:", error);
    } finally {
      setIsFetchingQaList(false); // Set fetching flag to false
    }
  };

  const handleApplyFilter = React.useCallback(() => {
    var updatedFormValues = getValues();
    var start_date = updatedFormValues?.scheduleDateFrom;
    var end_date = updatedFormValues?.scheduleDateTo;

    if (!_.isEmpty(updatedFormValues?.lob)) {
      var updatedFormValues = {
        ...updatedFormValues,
        lob: updatedFormValues?.lob.join(","),
      };
    }
    if (!_.isEmpty(updatedFormValues?.hraStatus)) {
      var updatedFormValues = {
        ...updatedFormValues,
        hraStatus: updatedFormValues?.hraStatus.join(","),
      };
    }
    if (!_.isEmpty(updatedFormValues?.visitType)) {
      var updatedFormValues = {
        ...updatedFormValues,
        visitType: updatedFormValues?.visitType.join(","),
      };
    }
    if (!_.isEmpty(updatedFormValues?.program)) {
      var updatedFormValues = {
        ...updatedFormValues,
        program: updatedFormValues?.program.join(","),
      };
    }
    if (!_.isEmpty(updatedFormValues?.nurseId)) {
      var updatedFormValues = {
        ...updatedFormValues,
        nurseId: updatedFormValues?.nurseId.join(","),
      };
    }

    if (!_.isEmpty(updatedFormValues?.qaId)) {
      var updatedFormValues = {
        ...updatedFormValues,
        qaId: updatedFormValues?.qaId.join(","),
      };
    }

    if (
      Boolean(start_date) &&
      Boolean(end_date) &&
      new Date(start_date)?.getTime() >= new Date(end_date)?.getTime()
    ) {
      setToasterOpen(true);
      setToasterSeverity(Labels.ERROR);
      setToasterMessage(
        "Schedule To Date should be greater than Schedule From Date",
      );
      reset({
        scheduleDateFrom: null,
        scheduleDateTo: null,
      });
    } else {
      alertContext.setFilterDialogData(updatedFormValues);
      alertContext.setFilterDialog(false);
    }
  }, []);

  const onCloseToaster = () => {
    setToasterOpen(false);
    setToasterMessage("");
  };

  const handleClear = React.useCallback(() => {
    alertContext.setFilterDialogData(null);
    reset();
    reset({
      lob: [],
      program: [],
      hraStatus: [],
      nurseId: [],
      visitType: [],
      scheduleDateFrom: null,
      scheduleDateTo: null,
      value: "",

      qaId: [],
      dateOfReferralForm: null,
      dateOfReferralTo: null,
      referralType: [],
      referralStatus: [],
    });
  }, []);

  React.useEffect(() => {
    if (
      !_.isEmpty(storedPlanId) &&
      storedPlanId !== "null" &&
      (!allQaList || allQaList.length === 0) &&
      !isFetchingQaList
    ) {
      getAllQaList();
    }
  }, [storedPlanId, allQaList, isFetchingQaList]);

  return (
    <div>
      <Dialog
        open={alertContext.filterDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
      >
        <DialogTitle
          sx={{
            background: "var(--mainTheme)",
            fontSize: "20px",
            color: "white",
            fontFamily: "Inter,sans-serif",
            height: "40px",
            display: "flex",
            alignItems: "center",
          }}
          id="alert-dialog-title"
        >
          Filter
        </DialogTitle>
        <DialogContent sx={{ paddingTop: "20px !important" }}>
          <Grid container spacing={2} sx={{ padding: "16px" }}>
            {selectedDropdownValue == "Referral" ? (
              <>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={4}
                  sx={{ padding: "10px" }}
                >
                  <Controller
                    name={"dateOfReferralForm"}
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DesktopDatePicker
                        label="Date of Referral From :"
                        className="inputAddMember"
                        views={["year", "month", "day"]}
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={4}
                  sx={{ padding: "10px" }}
                >
                  <Controller
                    name={"dateOfReferralTo"}
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DesktopDatePicker
                        label="Date of Referral To :"
                        className="inputAddMember"
                        views={["year", "month", "day"]}
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={4}
                  sx={{ padding: "10px" }}
                >
                  <FormControl fullWidth>
                    <InputLabel>Referral Type:</InputLabel>
                    <Controller
                      name="referralType"
                      control={control}
                      defaultValue={[]}
                      render={({ field }) => (
                        <Select
                          className="inputAddMember"
                          name="referralType"
                          {...field}
                          multiple
                          renderValue={(selected) => selected.join(", ")}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: 250, // Adjust the max height as needed
                              },
                            },
                          }}
                        >
                          {/* {lobOptionData?.map((option) => { */}
                          {/* return ( */}
                          {/* <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem> */}
                          {/* ); */}
                          {/* })} */}
                        </Select>
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={4}
                  sx={{ padding: "10px" }}
                >
                  <FormControl fullWidth>
                    <InputLabel>Referral Status:</InputLabel>
                    <Controller
                      name="referralStatus"
                      control={control}
                      defaultValue={[]}
                      render={({ field }) => (
                        <Select
                          className="inputAddMember"
                          name="referralStatus"
                          {...field}
                          multiple
                          renderValue={(selected) => selected.join(", ")}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: 250, // Adjust the max height as needed
                              },
                            },
                          }}
                        >
                          {/* {lobOptionData?.map((option) => { */}
                          {/* return ( */}
                          {/* <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem> */}
                          {/* ); */}
                          {/* })} */}
                        </Select>
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={4}
                  sx={{ padding: "10px" }}
                >
                  <FormControl fullWidth>
                    <InputLabel>Assigned QA:</InputLabel>
                    <Controller
                      name="qaId"
                      control={control}
                      defaultValue={[]}
                      value={watch("qaId")}
                      render={({ field }) => (
                        <Select
                          className="inputAddMember"
                          name="qaId"
                          {...field}
                          multiple
                          value={field.value}
                          onChange={(e) => field.onChange(e.target.value)}
                          renderValue={(selected) =>
                            Array.isArray(selected)
                              ? selected
                                  .map(
                                    (id) =>
                                      allQaList.find(
                                        (option) => option.userid === id,
                                      ).firstName,
                                  )
                                  .join(",")
                              : selected
                          }
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: 250, // Adjust the max height as needed
                              },
                            },
                          }}
                        >
                          {allQaList?.map((option) => {
                            return (
                              <MenuItem
                                key={option?.userid}
                                value={option?.userid}
                              >
                                {option.firstName} {option.lastName}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      )}
                    />
                  </FormControl>
                </Grid>
              </>
            ) : (
              <>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={4}
                  sx={{ padding: "10px" }}
                >
                  <Controller
                    name={"scheduleDateFrom"}
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DesktopDatePicker
                        label="Scheduled From:"
                        className="inputAddMember"
                        views={["year", "month", "day"]}
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={4}
                  sx={{ padding: "10px" }}
                >
                  <Controller
                    name={"scheduleDateTo"}
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DesktopDatePicker
                        label="Scheduled To:"
                        className="inputAddMember"
                        views={["year", "month", "day"]}
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={4}
                  sx={{ padding: "10px" }}
                >
                  <TextField
                    className="inputAddMember"
                    {...register("plan")}
                    style={{ width: "100%" }}
                    label="Client:"
                    value={storedPlan ?? ""} //`value` prop on `input` should not be null
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={4}
                  sx={{ padding: "10px" }}
                >
                  <TextField
                    className="inputAddMember"
                    label="list:"
                    style={{ width: "100%" }}
                    {...register("year")}
                    value={storedYear ?? ""} //`value` prop on `input` should not be null
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={4}
                  sx={{ padding: "10px" }}
                >
                  <FormControl fullWidth>
                    <InputLabel>Program:</InputLabel>
                    <Controller
                      name="program"
                      control={control}
                      defaultValue={[]}
                      render={({ field }) => (
                        <Select
                          className="inputAddMember"
                          name="program"
                          {...field}
                          multiple
                          renderValue={(selected) => selected.join(", ")}
                        >
                          {programOptions?.map((option) => {
                            return (
                              <MenuItem key={option?.id} value={option?.name}>
                                {option?.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={4}
                  sx={{ padding: "10px" }}
                >
                  <FormControl fullWidth>
                    <InputLabel>LOB:</InputLabel>
                    <Controller
                      name="lob"
                      control={control}
                      defaultValue={[]}
                      render={({ field }) => (
                        <Select
                          className="inputAddMember"
                          name="lob"
                          {...field}
                          multiple
                          renderValue={(selected) => selected.join(", ")}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: 250, // Adjust the max height as needed
                              },
                            },
                          }}
                        >
                          {lobOptionData?.map((option) => {
                            return (
                              <MenuItem key={option} value={option}>
                                {option}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={4}
                  sx={{ padding: "10px" }}
                >
                  <FormControl fullWidth>
                    <InputLabel>Visit Type:</InputLabel>
                    <Controller
                      name="visitType"
                      control={control}
                      defaultValue={[]}
                      render={({ field }) => (
                        <Select
                          className="inputAddMember"
                          name="visitType"
                          {...field}
                          multiple
                          value={field.value}
                          onChange={(e) => field.onChange(e.target.value)}
                          renderValue={(selected) =>
                            Array.isArray(selected)
                              ? selected
                                  .map(
                                    (id) =>
                                      visitTypeOptionsData.find(
                                        (option) => option.id === id,
                                      ).visitType,
                                  )
                                  .join(",")
                              : selected
                          }
                        >
                          {visitTypeOptionsData?.map((option) => {
                            return (
                              <MenuItem key={option?.id} value={option?.id}>
                                {option?.visitType}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={4}
                  sx={{ padding: "10px" }}
                >
                  <FormControl fullWidth>
                    <InputLabel>HRA Status:</InputLabel>
                    <Controller
                      name="hraStatus"
                      control={control}
                      defaultValue={[]}
                      render={({ field }) => (
                        <Select
                          className="inputAddMember"
                          name="hrastatus"
                          {...field}
                          multiple
                          value={field.value}
                          onChange={(e) => field.onChange(e.target.value)}
                          renderValue={(selected) =>
                            Array.isArray(selected)
                              ? selected
                                  .map(
                                    (id) =>
                                      hraStatusData.find(
                                        (option) => option.id === id,
                                      ).statusName,
                                  )
                                  .join(",")
                              : selected
                          }
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: 250, // Adjust the max height as needed
                              },
                            },
                          }}
                        >
                          {hraStatusData?.map((option) => {
                            return (
                              <MenuItem key={option?.id} value={option?.id}>
                                {option?.statusName}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={4}
                  sx={{ padding: "10px" }}
                >
                  <FormControl fullWidth>
                    <InputLabel>Assigned Clinician:</InputLabel>
                    <Controller
                      name="nurseId"
                      control={control}
                      defaultValue={[]}
                      value={watch("nurseId")}
                      render={({ field }) => (
                        <Select
                          className="inputAddMember"
                          name="nurseId"
                          {...field}
                          multiple
                          value={field.value}
                          onChange={(e) => field.onChange(e.target.value)}
                          renderValue={(selected) =>
                            Array.isArray(selected)
                              ? selected
                                  .map(
                                    (id) =>
                                      clinicianDataList.find(
                                        (option) => option.userid === id,
                                      ).firstName,
                                  )
                                  .join(",")
                              : selected
                          }
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: 250, // Adjust the max height as needed
                              },
                            },
                          }}
                        >
                          {clinicianDataList?.map((option) => {
                            return (
                              <MenuItem
                                key={option?.userid}
                                value={option?.userid}
                              >
                                {option.firstName} {option.lastName}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={4}
                  sx={{ padding: "10px" }}
                >
                  <FormControl fullWidth>
                    <InputLabel>Screening Type:</InputLabel>
                    <Select
                      fullWidth
                      {...register("subStatus")}
                      className="inputAddMember"
                      name="subStatus"
                      value={watch("subStatus")}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 250, // Adjust the max height as needed
                          },
                        },
                      }}
                    >
                      {screeningTypeOptions?.map((option, index) => {
                        return (
                          <MenuItem key={index} value={index}>
                            {option}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={4}
                  sx={{ padding: "10px" }}
                >
                  <TextField
                    className="inputAddMember"
                    label="Member City"
                    style={{ width: "100%" }}
                    {...register("mCity")}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={4}
                  sx={{ padding: "10px" }}
                >
                  <TextField
                    className="inputAddMember"
                    label="Member Zip Code"
                    style={{ width: "100%" }}
                    {...register("mZipCode")}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={4}
                  sx={{ padding: "10px" }}
                >
                  <TextField
                    className="inputAddMember"
                    label="Member County"
                    style={{ width: "100%" }}
                    {...register("mCounty")}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={4}
                  sx={{ padding: "10px" }}
                >
                  <TextField
                    className="inputAddMember"
                    label="Member Phone Number"
                    style={{ width: "100%" }}
                    {...register("primary")}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={4}
                  sx={{ padding: "10px" }}
                >
                  <TextField
                    className="inputAddMember"
                    label="Member Assessment (HRA, Screening Only, PEDS"
                    style={{ width: "100%" }}
                    {...register("mAssessment")}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={4}
                  sx={{ padding: "10px" }}
                >
                  <TextField
                    className="inputAddMember"
                    label="Screening Eligibility"
                    style={{ width: "100%" }}
                    {...register("screeningElibility")}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button sx={dialogBtn} onClick={handleClose}>
            Cancel
          </Button>
          <Button
            sx={dialogBtn}
            className="dialogBtn"
            onClick={handleApplyFilter}
          >
            Apply
          </Button>
          <Button
            sx={dialogBtn}
            className="dialogBtn reset-btn"
            onClick={handleClear}
          >
            Clear
          </Button>
        </DialogActions>
      </Dialog>
      <Toaster
        open={toasterOpen}
        severity={toasterSeverity}
        onClose={onCloseToaster}
        message={toasterMessage}
      />
      {isLoading && <Loader />}
    </div>
  );
}

export function ClinicianFilterDialogs() {
  const [isLoading, setIsLoading] = useState(false);
  const [clientData, setClientData] = useState({});
  const userStatusOptions = [
    { name: "Active", value: "1" },
    { name: "Inactive", value: "0" },
  ];
  const [clientsData, setClientsData] = useState([]);
  const storedPlanId = localStorage.getItem("selectedClientId") || "";

  const alertContext = useContext(AlertContext);
  const formmethod = useForm();
  const {
    register,
    control,
    handleSubmit,
    watch,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = formmethod;
  const {
    programTypesOptions,
    lobTypesOptions,
    lobOptionData,
    apiResponseData,
    clinicianDataList,
    hraStatusData,
    visitTypeOptionsData,
    allMappingData,
  } = useUserContext();

  const handleClose = () => {
    alertContext.setFilterDialog(false);
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  const selectedItems = watch("lob");

  React.useEffect(() => {
    getMemberApiData();
  }, [storedPlanId]);

  const getMemberApiData = async () => {
    await getAllClients()
      .then((response) => {
        const allMappingData = response?.data?.data.map((data) => data);
        setClientsData(allMappingData);
      })
      .catch((error) => {
        return;
      });
  };

  const handleApplyFilter = React.useCallback(() => {
    var updatedFormValues = getValues();
    if (!_.isEmpty(updatedFormValues?.clients)) {
      var updatedFormValues = {
        ...updatedFormValues,
        clients: updatedFormValues?.clients.join(","),
      };
    }
    if (!_.isEmpty(updatedFormValues?.userstatus)) {
      var updatedFormValues = {
        ...updatedFormValues,
        userstatus: updatedFormValues?.userstatus.join(","),
      };
    }
    if (!_.isEmpty(updatedFormValues?.visitType)) {
      var updatedFormValues = {
        ...updatedFormValues,
        visitType: updatedFormValues?.visitType.join(","),
      };
    }
    alertContext.setFilterDialogData(updatedFormValues);
    alertContext.setFilterDialog(false);
  }, []);

  const handleClear = React.useCallback(() => {
    alertContext.setFilterDialogData(null);
    setValue("lob", []);
    reset();
  }, []);

  return (
    <div>
      <Dialog
        open={alertContext.filterDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
      >
        <DialogTitle
          sx={{
            background: "var(--mainTheme)",
            fontSize: "20px",
            color: "white",
            fontFamily: "Inter,sans-serif",
            height: "40px",
            display: "flex",
            alignItems: "center",
          }}
          id="alert-dialog-title"
        >
          Filter
        </DialogTitle>
        <DialogContent sx={{ paddingTop: "20px !important" }}>
          <Grid container spacing={2} sx={{ padding: "16px" }}>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              xl={4}
              sx={{ padding: "10px" }}
            >
              <FormControl fullWidth>
                <InputLabel>User Status:</InputLabel>
                <Controller
                  name="userstatus"
                  control={control}
                  defaultValue={[]}
                  render={({ field }) => (
                    <Select
                      className="inputAddMember"
                      name="userstatus"
                      {...field}
                      multiple
                      value={field.value}
                      onChange={(e) => field.onChange(e.target.value)}
                      renderValue={(selected) =>
                        Array.isArray(selected)
                          ? selected
                              .map(
                                (id) =>
                                  userStatusOptions.find(
                                    (option) => option.value === id,
                                  ).name,
                              )
                              .join(",")
                          : selected
                      }
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 250, // Adjust the max height as needed
                          },
                        },
                      }}
                    >
                      {userStatusOptions?.map((option) => {
                        return (
                          <MenuItem key={option?.value} value={option?.value}>
                            {option?.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              xl={4}
              sx={{ padding: "10px" }}
            >
              <FormControl fullWidth>
                <InputLabel>Client:</InputLabel>
                <Controller
                  name="clients"
                  control={control}
                  defaultValue={[]}
                  render={({ field }) => (
                    <Select
                      className="inputAddMember"
                      name="clients"
                      {...field}
                      multiple
                      value={field.value}
                      onChange={(e) => field.onChange(e.target.value)}
                      renderValue={(selected) =>
                        Array.isArray(selected)
                          ? selected
                              .map(
                                (id) =>
                                  clientsData.find(
                                    (option) => option._id === id,
                                  ).clientName,
                              )
                              .join(",")
                          : selected
                      }
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 250, // Adjust the max height as needed
                          },
                        },
                      }}
                    >
                      {clientsData?.map((option) => {
                        return (
                          <MenuItem key={option?._id} value={option?._id}>
                            {option?.clientName}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              xl={4}
              sx={{ padding: "10px" }}
            >
              <Controller
                name={"expiryFromDate"}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <DesktopDatePicker
                    label="Expiry From:"
                    className="inputAddMember"
                    views={["year", "month", "day"]}
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              xl={4}
              sx={{ padding: "10px" }}
            >
              <Controller
                name={"expiryToDate"}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <DesktopDatePicker
                    label="Expiry To:"
                    className="inputAddMember"
                    views={["year", "month", "day"]}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button sx={dialogBtn} onClick={handleClose}>
            Cancel
          </Button>
          <Button
            sx={dialogBtn}
            className="dialogBtn"
            onClick={handleApplyFilter}
          >
            Apply
          </Button>
          <Button
            sx={dialogBtn}
            className="dialogBtn reset-btn"
            onClick={handleClear}
          >
            Clear
          </Button>
        </DialogActions>
      </Dialog>
      {isLoading && <Loader />}
    </div>
  );
}

export function AdminFilterDialogs() {
  const [isLoading, setIsLoading] = React.useState(false);
  const alertContext = useContext(AlertContext);
  const userStatusOptions = [
    { name: "Active", value: "1" },
    { name: "Inactive", value: "0" },
  ];
  const formmethod = useForm();
  const {
    register,
    control,
    handleSubmit,
    watch,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = formmethod;

  const handleClose = () => {
    alertContext.setFilterDialog(false);
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  const handleApplyFilter = React.useCallback(() => {
    var updatedFormValues = getValues();
    if (!_.isEmpty(updatedFormValues?.clients)) {
      var updatedFormValues = {
        ...updatedFormValues,
        clients: updatedFormValues?.clients.join(","),
      };
    }
    if (!_.isEmpty(updatedFormValues?.userstatus)) {
      var updatedFormValues = {
        ...updatedFormValues,
        userstatus: updatedFormValues?.userstatus.join(","),
      };
    }
    alertContext.setFilterDialogData(updatedFormValues);
    alertContext.setFilterDialog(false);
  }, []);

  const handleClear = React.useCallback(() => {
    alertContext.setFilterDialogData(null);
    setValue("lob", []);
    reset();
  }, []);

  return (
    <div>
      <Dialog
        open={alertContext.filterDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
      >
        <DialogTitle
          sx={{
            background: "var(--mainTheme)",
            fontSize: "20px",
            color: "white",
            fontFamily: "Inter,sans-serif",
            height: "40px",
            display: "flex",
            alignItems: "center",
          }}
          id="alert-dialog-title"
        >
          Filter
        </DialogTitle>
        <DialogContent sx={{ paddingTop: "20px !important" }}>
          <Grid container spacing={2} sx={{ padding: "16px" }}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{ padding: "10px" }}
            >
              <FormControl fullWidth>
                <InputLabel>User Status:</InputLabel>
                <Controller
                  name="userstatus"
                  control={control}
                  defaultValue={[]}
                  render={({ field }) => (
                    <Select
                      className="inputAddMember"
                      name="userstatus"
                      {...field}
                      multiple
                      value={field.value}
                      onChange={(e) => field.onChange(e.target.value)}
                      renderValue={(selected) =>
                        Array.isArray(selected)
                          ? selected
                              .map(
                                (id) =>
                                  userStatusOptions.find(
                                    (option) => option.value === id,
                                  ).name,
                              )
                              .join(",")
                          : selected
                      }
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 250, // Adjust the max height as needed
                          },
                        },
                      }}
                    >
                      {userStatusOptions?.map((option) => {
                        return (
                          <MenuItem key={option?.value} value={option?.value}>
                            {option?.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button sx={dialogBtn} onClick={handleClose}>
            Cancel
          </Button>
          <Button
            sx={dialogBtn}
            className="dialogBtn"
            onClick={handleApplyFilter}
          >
            Apply
          </Button>
          <Button
            sx={dialogBtn}
            className="dialogBtn reset-btn"
            onClick={handleClear}
          >
            Clear
          </Button>
        </DialogActions>
      </Dialog>

      {isLoading && <Loader />}
    </div>
  );
}

export function ClientFilterDialogs() {
  const alertContext = useContext(AlertContext);
  const storedPlanId = localStorage.getItem("selectedClientId") || "";
  const [clientsData, setClientsData] = useState([]);

  const getMemberApiData = async () => {
    await getAllClients()
      .then((response) => {
        const allMappingData = response?.data?.data.map((data) => data);
        setClientsData(allMappingData);
      })
      .catch((error) => {
        return;
      });
  };
  const handleClose = () => {
    alertContext.setFilterDialog(false);
  };
  React.useEffect(() => {
    getMemberApiData();
  }, [storedPlanId]);

  return (
    <div>
      <Dialog
        open={alertContext.filterDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
      >
        <DialogTitle
          sx={{
            background: "var(--mainTheme)",
            fontSize: "20px",
            color: "white",
            fontFamily: "Inter,sans-serif",
            height: "40px",
            display: "flex",
            alignItems: "center",
          }}
          id="alert-dialog-title"
        >
          Filter
        </DialogTitle>
        <DialogContent>
          <div className="form1">
            <DialogInput title="Client Name" styles={filterDialogLabel}>
              <SelectDropdown
                title="Select"
                options={["1", "2"]}
                className="dropdownMemberListDialog"
              />
            </DialogInput>
            <DialogInput title="Year" styles={filterDialogLabel}>
              <SelectDropdown
                title="Select"
                options={["1", "2"]}
                className="dropdownMemberListDialog"
              />
            </DialogInput>
          </div>
        </DialogContent>
        <DialogActions>
          <Button sx={dialogBtn} onClick={handleClose}>
            Cancel
          </Button>
          <Button sx={dialogBtn} className="dialogBtn" onClick={handleClose}>
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export function SchedulerFilterDialogs({
  hraStatusOptions,
  visitTypeOptions,
  clinicianData,
}) {
  const alertContext = useContext(AlertContext);
  const {
    programTypesOptions,
    lobTypesOptions,
    lobOptionData,
    apiResponseData,
    clinicianDataList,
    hraStatusData,
    visitTypeOptionsData,
  } = useUserContext();
  const [isLoading, setIsLoading] = React.useState(false);
  const formmethod = useForm();
  const [programOptions, setProgramOptions] = useState([]);
  const [callLogStatusOptionsData, setCallLogStatusOptionsData] = useState([]);
  const [reportStatusOptionsData, setReportStatusOptionsData] = useState([]);
  const [schedulerDataList, setSchedulerDataList] = useState([]);
  const [toasterMessage, setToasterMessage] = useState("");
  const [toasterOpen, setToasterOpen] = useState(false);
  const [toasterSeverity, setToasterSeverity] = useState(Labels.SUCCESS);

  const {
    register,
    control,
    handleSubmit,
    watch,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = formmethod;
  const storedPlan = localStorage.getItem("clientPlan");
  const storedYear = localStorage.getItem("clientYear");
  const getMemberApiData = async () => {
    await getAllClients()
      .then((response) => {
        const allMappingData = response?.data?.data.map((data) => data);
        let selectedClientBasedObjectValue = _.find(allMappingData, {
          plan: storedPlan,
        });
        setProgramOptions(selectedClientBasedObjectValue?.program);
      })
      .catch((error) => {
        return;
      });
  };
  const screeningOptions = [
    "Eye Exam",
    "HBA1C",
    "Microalbumin",
    "FOBT",
    "DEXA",
    "PAD",
    "LDL",
  ];
  const screeningIHAOptions = [
    { id: "1", value: "Screening" },
    { id: "2", value: "HRA" },
  ];

  const eyeExamOptions = [
    { id: "1", value: "No" },
    { id: "2", value: "Yes" },
  ];

  const callBackExamOptions = ["Due", "OverDue"];
  const storedPlanId = localStorage.getItem("selectedClientId") || "";

  const callLog = async () => {
    try {
      const response = await getApi(`/call-log-status`);
      const data = await response.data.data;
      console.log("callLogData", data);
      setCallLogStatusOptionsData(data);
    } catch {
      return;
    }
  };

  const reportStatus = async () => {
    try {
      const response = await getApi("/reportstatus");
      const data = await response.data.data;
      setReportStatusOptionsData(response?.data?.data);
    } catch {
      return;
    }
  };

  const fetchAllSchedulerData = async () => {
    if (!_.isEmpty(storedPlanId)) {
      try {
        const response = await postApi("/users/get-users-by-program-roleId", {
          planId: storedPlanId,
        });

        // Check if the response has valid data
        if (response?.data?.data && response.data.data.length > 0) {
          const newSchedulerData = response.data.data[0]?.scheduler;

          // Update the state only if data has changed
          if (!_.isEqual(schedulerDataList, newSchedulerData)) {
            setSchedulerDataList(newSchedulerData);
          }
        } else {
          console.warn("No scheduler data found in the response.");
        }
      } catch (error) {
        console.error("Error fetching scheduler data:", error);
      }
    }
  };
  // Use a memoized version of the function to prevent unnecessary re-creation
  const memoizedFetchAllSchedulerData = React.useCallback(() => {
    fetchAllSchedulerData();
  }, [storedPlanId, schedulerDataList]);

  // const [isReady, setIsReady] = React.useState(false);

  const handleClose = () => {
    alertContext.setFilterDialogData(null);
    alertContext.setFilterDialog(false);
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  React.useEffect(() => {
    // Only call when filterDialogData is defined
    if (alertContext?.filterDialogData) {
      reset(alertContext.filterDialogData);
      callLog();
      reportStatus();
    }
  }, [alertContext?.filterDialogData]); // Add dependencies

  const [hasFetchedSchedulerData, setHasFetchedSchedulerData] =
    React.useState(false);

  React.useEffect(() => {
    if (
      !_.isEmpty(storedPlanId) &&
      !schedulerDataList?.length &&
      !hasFetchedSchedulerData
    ) {
      memoizedFetchAllSchedulerData();
      setHasFetchedSchedulerData(true); // Mark as fetched
    }

    // Fetch member data only if storedPlan is defined and not empty
    if (storedPlan) {
      getMemberApiData();
    }
  }, [
    storedPlanId,
    memoizedFetchAllSchedulerData,
    schedulerDataList,
    storedPlan,
  ]);

  const handleApplyFilter = React.useCallback(() => {
    var updatedFormValues = getValues();
    if (!_.isEmpty(updatedFormValues?.callResultStatus)) {
      var updatedFormValues = {
        ...updatedFormValues,
        callResultStatus: updatedFormValues?.callResultStatus.join(","),
      };
    }
    if (!_.isEmpty(updatedFormValues?.callbackstatus)) {
      var updatedFormValues = {
        ...updatedFormValues,
        callbackstatus: updatedFormValues?.callbackstatus.join(","),
      };
    }
    if (!_.isEmpty(updatedFormValues?.eyeExam)) {
      var updatedFormValues = {
        ...updatedFormValues,
        eyeExam: updatedFormValues?.eyeExam.join(","),
      };
    }
    if (!_.isEmpty(updatedFormValues?.lob)) {
      var updatedFormValues = {
        ...updatedFormValues,
        lob: updatedFormValues?.lob.join(","),
      };
    }
    if (!_.isEmpty(updatedFormValues?.hrastatus)) {
      var updatedFormValues = {
        ...updatedFormValues,
        hrastatus: updatedFormValues?.hrastatus.join(","),
      };
    }
    if (!_.isEmpty(updatedFormValues?.nurseId)) {
      var updatedFormValues = {
        ...updatedFormValues,
        nurseId: updatedFormValues?.nurseId.join(","),
      };
    }
    if (!_.isEmpty(updatedFormValues?.schedulerId)) {
      var updatedFormValues = {
        ...updatedFormValues,
        schedulerId: updatedFormValues?.schedulerId.join(","),
      };
    }
    if (!_.isEmpty(updatedFormValues?.program)) {
      var updatedFormValues = {
        ...updatedFormValues,
        program: updatedFormValues?.program.join(","),
      };
    }
    if (!_.isEmpty(updatedFormValues?.screening)) {
      var updatedFormValues = {
        ...updatedFormValues,
        screening: updatedFormValues?.screening.join(","),
      };
    }
    if (!_.isEmpty(updatedFormValues?.status)) {
      var updatedFormValues = {
        ...updatedFormValues,
        status: updatedFormValues?.status.join(","),
      };
    }
    if (!_.isEmpty(updatedFormValues?.screeningIha)) {
      var updatedFormValues = {
        ...updatedFormValues,
        screeningIha: updatedFormValues?.screeningIha.join(","),
      };
    }

    if (
      Boolean(updatedFormValues?.scheduleDateFrom) &&
      Boolean(updatedFormValues?.scheduleDateTo) &&
      new Date(updatedFormValues?.scheduleDateFrom)?.getTime() >=
        new Date(updatedFormValues?.scheduleDateTo)?.getTime()
    ) {
      setToasterOpen(true);
      setToasterSeverity(Labels.ERROR);
      setToasterMessage(
        "Schedule To Date should be greater than Schedule From Date",
      );
      reset({
        scheduleDateFrom: null,
        scheduleDateTo: null,
      });
    } else if (
      Boolean(updatedFormValues?.callbackFromDate) &&
      Boolean(updatedFormValues?.callbackToDate) &&
      new Date(updatedFormValues?.callbackFromDate)?.getTime() >=
        new Date(updatedFormValues?.callbackToDate)?.getTime()
    ) {
      setToasterOpen(true);
      setToasterSeverity(Labels.ERROR);
      setToasterMessage(
        "Callback To Date should be greater than Callback From Date",
      );
      reset({
        callbackFromDate: null,
        callbackToDate: null,
      });
    } else if (
      Boolean(updatedFormValues?.nextOutreachFromDate) &&
      Boolean(updatedFormValues?.nextOutreachToDate) &&
      new Date(updatedFormValues?.nextOutreachFromDate)?.getTime() >=
        new Date(updatedFormValues?.nextOutreachToDate)?.getTime()
    ) {
      setToasterOpen(true);
      setToasterSeverity(Labels.ERROR);
      setToasterMessage(
        "Nextoutreach To Date should be greater than Nextoutreach From Date",
      );
      reset({
        nextOutreachFromDate: null,
        nextOutreachToDate: null,
      });
    } else {
      alertContext.setFilterDialogData(updatedFormValues);
      alertContext.setFilterDialog(false);
    }
  }, []);

  const onCloseToaster = () => {
    setToasterOpen(false);
    setToasterMessage("");
  };

  const handleClear = React.useCallback(() => {
    reset();
    reset({
      lob: [],
      program: [],
      hrastatus: [],
      callResultStatus: [],
      schedulerId: [],
      nurseId: [],
      status: [],
      eyeExam: [],
      callbackstatus: [],
      callbackFromDate: null,
      callbackToDate: null,
      scheduleDateFrom: null,
      scheduleDateTo: null,
      nextOutreachFromDate: null,
      nextOutreachToDate: null,
      nextOutreachToDate: null,
      screening: [],
      screeningIha: [],
      value: "",
    });
  }, []);

  return (
    <div>
      <Dialog
        open={alertContext.filterDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
      >
        <DialogTitle
          sx={{
            background: "var(--mainTheme)",
            fontSize: "20px",
            color: "white",
            fontFamily: "Inter,sans-serif",
            height: "40px",
            display: "flex",
            alignItems: "center",
          }}
          id="alert-dialog-title"
        >
          Filter
        </DialogTitle>
        <DialogContent sx={{ paddingTop: "20px !important" }}>
          <Grid container spacing={2} sx={{ padding: "16px" }}>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              xl={4}
              sx={{ padding: "10px" }}
            >
              <Controller
                name={"scheduleDateFrom"}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <DesktopDatePicker
                    label="Scheduled From:"
                    className="inputAddMember"
                    views={["year", "month", "day"]}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              xl={4}
              sx={{ padding: "10px" }}
            >
              <Controller
                name={"scheduleDateTo"}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <DesktopDatePicker
                    label="Scheduled To:"
                    className="inputAddMember"
                    views={["year", "month", "day"]}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              xl={4}
              sx={{ padding: "10px" }}
            >
              <FormControl fullWidth>
                <InputLabel>Program:</InputLabel>
                <Controller
                  name="program"
                  control={control}
                  defaultValue={[]}
                  render={({ field }) => (
                    <Select
                      className="inputAddMember"
                      name="program"
                      {...field}
                      multiple
                      renderValue={(selected) => selected.join(", ")}
                    >
                      {programOptions?.map((option) => {
                        return (
                          <MenuItem key={option?.id} value={option?.name}>
                            {option?.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              xl={4}
              sx={{ padding: "10px" }}
            >
              <FormControl fullWidth>
                <InputLabel>LOB:</InputLabel>
                <Controller
                  name="lob"
                  control={control}
                  defaultValue={[]}
                  render={({ field }) => (
                    <Select
                      className="inputAddMember"
                      name="lob"
                      {...field}
                      multiple
                      renderValue={(selected) => selected.join(", ")}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 250, // Adjust the max height as needed
                          },
                        },
                      }}
                    >
                      {lobOptionData?.map((option) => {
                        return (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              xl={4}
              sx={{ padding: "10px" }}
            >
              <FormControl fullWidth>
                <InputLabel>HRA Status:</InputLabel>
                <Controller
                  name="hrastatus"
                  control={control}
                  defaultValue={[]}
                  render={({ field }) => (
                    <Select
                      className="inputAddMember"
                      name="hrastatus"
                      {...field}
                      multiple
                      value={field.value}
                      onChange={(e) => field.onChange(e.target.value)}
                      renderValue={(selected) =>
                        Array.isArray(selected)
                          ? selected
                              .map(
                                (id) =>
                                  hraStatusData.find(
                                    (option) => option.id === id,
                                  ).statusName,
                              )
                              .join(",")
                          : selected
                      }
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 250, // Adjust the max height as needed
                          },
                        },
                      }}
                      //multiple select chip
                      // renderValue={(selected) => (
                      //   <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      //     {selected.map((value) => (
                      //       <Chip key={value} label={value} />
                      //     ))}
                      //   </Box>
                      // )}
                    >
                      {hraStatusData
                        .sort((a, b) => {
                          if (a.statusName < b.statusName) {
                            return -1;
                          }
                          if (a.statusName > b.statusName) {
                            return 1;
                          }
                          return 0;
                        })
                        ?.map((option) => {
                          return (
                            <MenuItem key={option?.id} value={option?.id}>
                              {option?.statusName}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              xl={4}
              sx={{ padding: "10px" }}
            >
              <FormControl fullWidth>
                <InputLabel>Call Result:</InputLabel>
                <Controller
                  name="callResultStatus"
                  control={control}
                  defaultValue={[]}
                  render={({ field }) => (
                    <Select
                      className="inputAddMember"
                      name="callResultStatus"
                      {...field}
                      multiple
                      renderValue={(selected) => selected.join(", ")}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 250, // Adjust the max height as needed
                          },
                        },
                      }}
                    >
                      {[
                        ...callLogStatusOptionsData,
                        { statusName: "Left Voicemail", id: 15, isActive: 1 },
                        { statusName: "Mailbox full", id: 16, isActive: 1 },
                        { statusName: "No VM Setup", id: 17, isActive: 1 },
                      ]
                        .sort((a, b) => {
                          if (a.statusName < b.statusName) {
                            return -1;
                          }
                          if (a.statusName > b.statusName) {
                            return 1;
                          }
                          return 0;
                        })
                        ?.map((option) => {
                          return (
                            <MenuItem
                              key={option?.id}
                              value={option?.statusName}
                            >
                              {option?.statusName}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              xl={4}
              sx={{ padding: "10px" }}
            >
              <FormControl fullWidth>
                <InputLabel>Assigned Scheduler:</InputLabel>
                <Controller
                  name="schedulerId"
                  control={control}
                  defaultValue={[]}
                  render={({ field }) => (
                    <Select
                      style={{ display: "flex", flexWrap: "wrap" }}
                      className="inputAddMember"
                      name="schedulerId"
                      {...field}
                      multiple
                      value={field.value}
                      onChange={(e) => field.onChange(e.target.value)}
                      renderValue={(selected) =>
                        selected?.map((itm) => (
                          <Box paddingY={0.5}>
                            <Chip
                              //                    icon={
                              //     <CloseIcon onClick={()=>{}} />
                              // }
                              key={itm}
                              label={`${
                                schedulerDataList.find(
                                  (opt) => opt?.userid == itm,
                                ).firstName
                              } ${
                                schedulerDataList.find(
                                  (opt) => opt?.userid == itm,
                                ).lastName
                              }`}
                            />
                          </Box>
                        ))
                      }
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 250, // Adjust the max height as needed
                          },
                        },
                      }}
                    >
                      {schedulerDataList?.map((option) => {
                        return (
                          <MenuItem key={option?.userid} value={option?.userid}>
                            {option.firstName} {option.lastName}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              xl={4}
              sx={{ padding: "10px" }}
            >
              <FormControl fullWidth>
                <InputLabel>Assigned Clinician:</InputLabel>
                <Controller
                  name="nurseId"
                  control={control}
                  defaultValue={[]}
                  value={watch("nurseId")}
                  render={({ field }) => (
                    <Select
                      className="inputAddMember"
                      name="nurseId"
                      {...field}
                      multiple
                      value={field.value}
                      onChange={(e) => field.onChange(e.target.value)}
                      renderValue={(selected) =>
                        selected?.map((itm) => (
                          <Box paddingY={0.5}>
                            <Chip
                              //                    icon={
                              //     <CloseIcon onClick={()=>{}} />
                              // }
                              key={itm}
                              label={`${
                                clinicianDataList.find(
                                  (opt) => opt?.userid == itm,
                                ).firstName
                              } ${
                                clinicianDataList.find(
                                  (opt) => opt?.userid == itm,
                                ).lastName
                              }`}
                            />
                          </Box>
                        ))
                      }
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 250, // Adjust the max height as needed
                          },
                        },
                      }}
                    >
                      {clinicianDataList?.map((option) => {
                        return (
                          <MenuItem key={option?.userid} value={option?.userid}>
                            {option.firstName} {option.lastName}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
            {/* <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              xl={4}
              sx={{ padding: "10px" }}
            >
              <FormControl required fullWidth>
                <InputLabel>City</InputLabel>
                <Select
                  fullWidth
                  {...register("city")}
                  className="inputAddMember"
                  name="city"
                  value={watch("city")}
                >
                  {clinicianDataList?.map((option) => {
                    return (
                      <MenuItem key={option?.userid} value={option?.userid}>
                        {option.firstName} {option.lastName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid> */}
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              xl={4}
              sx={{ padding: "10px" }}
            >
              <FormControl fullWidth>
                <InputLabel>Status:</InputLabel>
                <Controller
                  name="status"
                  control={control}
                  defaultValue={[]}
                  render={({ field }) => (
                    <Select
                      className="inputAddMember"
                      name="status"
                      {...field}
                      multiple
                      value={field.value}
                      onChange={(e) => field.onChange(e.target.value)}
                      renderValue={(selected) =>
                        Array.isArray(selected)
                          ? selected
                              .map(
                                (id) =>
                                  reportStatusOptionsData.find(
                                    (option) => option.id === id,
                                  ).statusName,
                              )
                              .join(",")
                          : selected
                      }
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 250, // Adjust the max height as needed
                          },
                        },
                      }}
                    >
                      {reportStatusOptionsData
                        ?.filter((itm) => itm?.statusName != "Other")
                        ?.map((option) => {
                          return (
                            <MenuItem key={option?.id} value={option?.id}>
                              {option?.statusName}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              xl={4}
              sx={{ padding: "10px" }}
            >
              <FormControl fullWidth>
                <InputLabel>Eye Exam:</InputLabel>
                <Controller
                  name="eyeExam"
                  control={control}
                  defaultValue={[]}
                  value={watch("eyeExam")}
                  render={({ field }) => (
                    <Select
                      className="inputAddMember"
                      name="eyeExam"
                      {...field}
                      multiple
                      value={field.value}
                      onChange={(e) => field.onChange(e.target.value)}
                      renderValue={(selected) =>
                        Array.isArray(selected)
                          ? selected
                              .map(
                                (id) =>
                                  eyeExamOptions.find(
                                    (option) => option.id === id,
                                  ).value,
                              )
                              .join(",")
                          : selected
                      }
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 250, // Adjust the max height as needed
                          },
                        },
                      }}
                    >
                      {eyeExamOptions?.map((option) => {
                        return (
                          <MenuItem key={option?.id} value={option?.id}>
                            {option.value}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              xl={4}
              sx={{ padding: "10px" }}
            >
              <FormControl fullWidth>
                <InputLabel>Call Back Status:</InputLabel>
                <Controller
                  name="callbackstatus"
                  control={control}
                  defaultValue={[]}
                  render={({ field }) => (
                    <Select
                      className="inputAddMember"
                      name="callbackstatus"
                      {...field}
                      multiple
                      renderValue={(selected) => selected.join(", ")}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 250, // Adjust the max height as needed
                          },
                        },
                      }}
                    >
                      {callBackExamOptions?.map((option) => {
                        return (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              xl={4}
              sx={{ padding: "10px" }}
            >
              <Controller
                name={"callbackFromDate"}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <DesktopDatePicker
                    label="Call back From:"
                    className="inputAddMember"
                    views={["year", "month", "day"]}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              xl={4}
              sx={{ padding: "10px" }}
            >
              <Controller
                name={"callbackToDate"}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <DesktopDatePicker
                    label="Call Back To:"
                    className="inputAddMember"
                    views={["year", "month", "day"]}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              xl={4}
              sx={{ padding: "10px" }}
            >
              <Controller
                name={"nextOutreachFromDate"}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <DesktopDatePicker
                    label="Next Outreach From:"
                    className="inputAddMember"
                    views={["year", "month", "day"]}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              xl={4}
              sx={{ padding: "10px" }}
            >
              <Controller
                name={"nextOutreachToDate"}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <DesktopDatePicker
                    label="Next Outreach To:"
                    className="inputAddMember"
                    views={["year", "month", "day"]}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              xl={4}
              sx={{ padding: "10px" }}
            >
              <FormControl fullWidth>
                <InputLabel>Screening:</InputLabel>
                <Controller
                  name="screening"
                  control={control}
                  defaultValue={[]}
                  render={({ field }) => (
                    <Select
                      className="inputAddMember"
                      name="screening"
                      {...field}
                      multiple
                      renderValue={(selected) => selected.join(", ")}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 250, // Adjust the max height as needed
                          },
                        },
                      }}
                    >
                      {screeningOptions?.map((option) => {
                        return (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              xl={4}
              sx={{ padding: "10px" }}
            >
              <FormControl fullWidth>
                <InputLabel>Screening IHA:</InputLabel>
                <Controller
                  name="screeningIha"
                  control={control}
                  defaultValue={[]}
                  value={watch("screeningIha")}
                  render={({ field }) => (
                    <Select
                      className="inputAddMember"
                      name="screeningIha"
                      {...field}
                      multiple
                      value={field.value}
                      onChange={(e) => field.onChange(e.target.value)}
                      renderValue={(selected) =>
                        Array.isArray(selected)
                          ? selected
                              .map(
                                (id) =>
                                  screeningIHAOptions.find(
                                    (option) => option.id === id,
                                  ).value,
                              )
                              .join(",")
                          : selected
                      }
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 250, // Adjust the max height as needed
                          },
                        },
                      }}
                    >
                      {screeningIHAOptions?.map((option) => {
                        return (
                          <MenuItem key={option?.id} value={option?.id}>
                            {option.value}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button sx={dialogBtn} onClick={handleClose}>
            Cancel
          </Button>
          <Button
            sx={dialogBtn}
            className="dialogBtn"
            onClick={handleApplyFilter}
          >
            Apply
          </Button>
          <Button
            sx={dialogBtn}
            className="dialogBtn reset-btn"
            onClick={handleClear}
          >
            Clear
          </Button>
        </DialogActions>
      </Dialog>
      <Toaster
        open={toasterOpen}
        severity={toasterSeverity}
        onClose={onCloseToaster}
        message={toasterMessage}
      />
      {isLoading && <Loader />}
    </div>
  );
}

export function ReportsFilterDialogs({
  hraStatusOptions,
  visitTypeOptions,
  clinicianData,
  headerValue,
}) {
  const alertContext = useContext(AlertContext);
  const {
    programTypesOptions,
    lobTypesOptions,
    lobOptionData,
    apiResponseData,
    clinicianDataList,
    hraStatusData,
    visitTypeOptionsData,
  } = useUserContext();
  const [isLoading, setIsLoading] = React.useState(false);
  const formmethod = useForm();
  const [programOptions, setProgramOptions] = useState([]);
  const [callLogStatusOptionsData, setCallLogStatusOptionsData] = useState([]);
  const [reportStatusOptionsData, setReportStatusOptionsData] = useState([]);
  const [schedulerDataList, setSchedulerDataList] = useState([]);
  const [toasterMessage, setToasterMessage] = useState("");
  const [toasterOpen, setToasterOpen] = useState(false);
  const [toasterSeverity, setToasterSeverity] = useState(Labels.SUCCESS);

  const {
    register,
    control,
    handleSubmit,
    watch,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = formmethod;
  const storedPlan = localStorage.getItem("clientPlan");
  const storedYear = localStorage.getItem("clientYear");
  const getMemberApiData = async () => {
    await getAllClients()
      .then((response) => {
        const allMappingData = response?.data?.data.map((data) => data);
        let selectedClientBasedObjectValue = _.find(allMappingData, {
          plan: storedPlan,
        });
        setProgramOptions(selectedClientBasedObjectValue?.program);
      })
      .catch((error) => {
        return;
      });
  };
  const screeningOptions = [
    "Eye Exam",
    "A1C",
    "URINE",
    "FIT",
    "DEXA",
    "PAD",
    "LDL",
  ];

  const eyeExamOptions = ["Yes", "No"];
  const callBackExamOptions = ["Due", "OverDue"];
  const storedPlanId = localStorage.getItem("selectedClientId") || "";
  const callLog = async () => {
    try {
      const response = await getApi(`/call-log-status`);
      const data = await response.data.data;
      setCallLogStatusOptionsData(data);
    } catch {
      return;
    }
  };

  const reportStatus = async () => {
    try {
      const response = await getApi("/reportstatus");
      const data = await response.data.data;
      setReportStatusOptionsData(response?.data?.data);
    } catch {
      return;
    }
  };

  const fetchAllSchedulerData = async () => {
    if (!_.isEmpty(storedPlanId)) {
      const response = await postApi("/users/get-users-by-program-roleId", {
        planId: storedPlanId,
      });
      const clientPlanFromLocalStorage = localStorage.getItem("clientPlan");
      setSchedulerDataList(response?.data?.data[0]?.scheduler);
    }
  };

  const handleClose = () => {
    alertContext.setFilterDialog(false);
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  React.useEffect(() => {
    reset(alertContext?.filterDialogData);
    callLog();
    reportStatus();
  }, []);

  React.useEffect(() => {
    fetchAllSchedulerData();
    getMemberApiData();
  }, [storedPlanId]);

  const handleApplyFilter = React.useCallback(() => {
    var updatedFormValues = getValues();
    var start_date = updatedFormValues?.scheduleDateFrom;
    var end_date = updatedFormValues?.scheduleDateTo;

    if (!_.isEmpty(updatedFormValues?.lob)) {
      var updatedFormValues = {
        ...updatedFormValues,
        lob: updatedFormValues?.lob.join(","),
      };
    }
    if (!_.isEmpty(updatedFormValues?.program)) {
      var updatedFormValues = {
        ...updatedFormValues,
        program: updatedFormValues?.program.join(","),
      };
    }
    if (!_.isEmpty(updatedFormValues?.hrastatus)) {
      var updatedFormValues = {
        ...updatedFormValues,
        hrastatus: updatedFormValues?.hrastatus.join(","),
      };
    }
    if (!_.isEmpty(updatedFormValues?.nurseId)) {
      var updatedFormValues = {
        ...updatedFormValues,
        nurseId: updatedFormValues?.nurseId.join(","),
      };
    }

    if (!_.isEmpty(updatedFormValues?.visitType)) {
      var updatedFormValues = {
        ...updatedFormValues,
        visitType: updatedFormValues?.visitType.join(","),
      };
    }

    if (
      (Boolean(start_date) &&
        Boolean(end_date) &&
        new Date(start_date)?.getTime() >= new Date(end_date)?.getTime()) ||
      (Boolean(updatedFormValues?.serviceFromDate) &&
        Boolean(updatedFormValues?.serviceToDate) &&
        new Date(updatedFormValues?.serviceFromDate)?.getTime() >=
          new Date(updatedFormValues?.serviceToDate)?.getTime()) ||
      (Boolean(updatedFormValues?.uploadFromDate) &&
        Boolean(updatedFormValues?.uploadToDate) &&
        new Date(updatedFormValues?.uploadFromDate)?.getTime() >=
          new Date(updatedFormValues?.uploadToDate)?.getTime()) ||
      (Boolean(updatedFormValues?.refusalFromDate) &&
        Boolean(updatedFormValues?.refusalToDate) &&
        new Date(updatedFormValues?.refusalFromDate)?.getTime() >=
          new Date(updatedFormValues?.refusalToDate)?.getTime()) ||
      (Boolean(updatedFormValues?.fromDate) &&
        Boolean(updatedFormValues?.toDate) &&
        new Date(updatedFormValues?.fromDate)?.getTime() >=
          new Date(updatedFormValues?.toDate)?.getTime())
    ) {
      setToasterOpen(true);
      setToasterSeverity(Labels.ERROR);
      if (Boolean(start_date) && Boolean(end_date)) {
        setToasterMessage(
          "Schedule To Date should be greater than Schedule From Date",
        );
      } else if (
        Boolean(updatedFormValues?.serviceFromDate) &&
        Boolean(updatedFormValues?.serviceToDate)
      ) {
        setToasterMessage(
          "Service To Date should be greater than Service From Date",
        );
      } else if (
        Boolean(updatedFormValues?.uploadFromDate) &&
        Boolean(updatedFormValues?.uploadToDate)
      ) {
        setToasterMessage(
          "Upload To Date should be greater than Upload From Date",
        );
      } else if (
        Boolean(updatedFormValues?.refusalFromDate) &&
        Boolean(updatedFormValues?.refusalToDate)
      ) {
        setToasterMessage(
          "Refusal To Date should be greater than Refusal From Date",
        );
      } else if (
        Boolean(updatedFormValues?.fromDate) &&
        Boolean(updatedFormValues?.toDate)
      ) {
        setToasterMessage("To Date should be greater than From Date");
      }
      reset({
        scheduleDateFrom: null,
        scheduleDateTo: null,
        serviceFromDate: null,
        serviceToDate: null,
        uploadFromDate: null,
        uploadToDate: null,
        refusalFromDate: null,
        refusalToDate: null,
        fromDate: null,
        toDate: null,
      });
    } else {
      alertContext.setFilterDialogData(updatedFormValues);
      alertContext.setFilterDialog(false);
    }
  }, []);

  const handleClear = React.useCallback(() => {
    alertContext.setFilterDialogData({ visitType: "", nurseId: "" });
    reset();
    reset({
      lob: [],
      program: [],
      hrastatus: [],
      nurseId: [],
      visitType: [],
      scheduleDateFrom: null,
      scheduleDateTo: null,
      value: "",
    });
  }, []);

  const onCloseToaster = () => {
    setToasterOpen(false);
    setToasterMessage("");
  };

  return (
    <div>
      <Dialog
        open={alertContext.filterDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
      >
        <DialogTitle
          sx={{
            background: "var(--mainTheme)",
            fontSize: "20px",
            color: "white",
            fontFamily: "Inter,sans-serif",
            height: "40px",
            display: "flex",
            alignItems: "center",
          }}
          id="alert-dialog-title"
        >
          Filter
        </DialogTitle>
        <DialogContent sx={{ paddingTop: "20px !important" }}>
          <Grid container spacing={2} sx={{ padding: "16px" }}>
            {(headerValue === "QA Summary Report" ||
              headerValue == "Weekly Scheduler Report") && (
              <>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={4}
                  sx={{ padding: "10px" }}
                >
                  <Controller
                    name={"scheduleDateFrom"}
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DesktopDatePicker
                        label="Scheduled From:"
                        className="inputAddMember"
                        views={["year", "month", "day"]}
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={4}
                  sx={{ padding: "10px" }}
                >
                  <Controller
                    name={"scheduleDateTo"}
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DesktopDatePicker
                        label="Scheduled To:"
                        className="inputAddMember"
                        views={["year", "month", "day"]}
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                </Grid>
              </>
            )}
            {headerValue === "FTP Summary" && (
              <>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={4}
                  sx={{ padding: "10px" }}
                >
                  <Controller
                    name={"serviceFromDate"}
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DesktopDatePicker
                        label="Date of Service From:"
                        className="inputAddMember"
                        views={["year", "month", "day"]}
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={4}
                  sx={{ padding: "10px" }}
                >
                  <Controller
                    name={"serviceToDate"}
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DesktopDatePicker
                        label="Date of Service To:"
                        className="inputAddMember"
                        views={["year", "month", "day"]}
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={4}
                  sx={{ padding: "10px" }}
                >
                  <Controller
                    name={"uploadFromDate"}
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DesktopDatePicker
                        label="Date of Upload From:"
                        className="inputAddMember"
                        views={["year", "month", "day"]}
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={4}
                  sx={{ padding: "10px" }}
                >
                  <Controller
                    name={"uploadToDate"}
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DesktopDatePicker
                        label="Date of Upload To:"
                        className="inputAddMember"
                        views={["year", "month", "day"]}
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                </Grid>
              </>
            )}
            {headerValue === "Refusal Letter Report" && (
              <>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={4}
                  sx={{ padding: "10px" }}
                >
                  <Controller
                    name={"refusalFromDate"}
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DesktopDatePicker
                        label="Refusal From:"
                        className="inputAddMember"
                        views={["year", "month", "day"]}
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={4}
                  sx={{ padding: "10px" }}
                >
                  <Controller
                    name={"refusalToDate"}
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DesktopDatePicker
                        label="Refusal To:"
                        className="inputAddMember"
                        views={["year", "month", "day"]}
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                </Grid>
              </>
            )}
            {headerValue === "Covid Report" && (
              <>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={4}
                  sx={{ padding: "10px" }}
                >
                  <Controller
                    name={"fromDate"}
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DesktopDatePicker
                        label="From Date:"
                        className="inputAddMember"
                        views={["year", "month", "day"]}
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={4}
                  sx={{ padding: "10px" }}
                >
                  <Controller
                    name={"toDate"}
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DesktopDatePicker
                        label="To Date:"
                        className="inputAddMember"
                        views={["year", "month", "day"]}
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                </Grid>
              </>
            )}
            {headerValue !== "Covid Report" && (
              <>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={4}
                  sx={{ padding: "10px" }}
                >
                  <TextField
                    className="inputAddMember"
                    {...register("plan")}
                    style={{ width: "100%" }}
                    label="Client:"
                    value={storedPlan ?? ""} //`value` prop on `input` should not be null
                    readOnly
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={4}
                  sx={{ padding: "10px" }}
                >
                  <TextField
                    className="inputAddMember"
                    label="list:"
                    style={{ width: "100%" }}
                    {...register("year")}
                    value={storedYear ?? ""} //`value` prop on `input` should not be null
                    readOnly
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={4}
                  sx={{ padding: "10px" }}
                >
                  <FormControl fullWidth>
                    <InputLabel>Program:</InputLabel>
                    <Controller
                      name="program"
                      control={control}
                      defaultValue={[]}
                      render={({ field }) => (
                        <Select
                          className="inputAddMember"
                          name="program"
                          {...field}
                          multiple
                          renderValue={(selected) => selected.join(", ")}
                        >
                          {programOptions?.map((option) => {
                            return (
                              <MenuItem key={option?.id} value={option?.name}>
                                {option?.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={4}
                  sx={{ padding: "10px" }}
                >
                  <FormControl fullWidth>
                    <InputLabel>LOB:</InputLabel>
                    <Controller
                      name="lob"
                      control={control}
                      defaultValue={[]}
                      render={({ field }) => (
                        <Select
                          className="inputAddMember"
                          name="lob"
                          {...field}
                          multiple
                          renderValue={(selected) => selected.join(", ")}
                          // value={field.value.toString().split(",")}
                          // onChange={(e) => field.onChange(e.target.value.join(","))}
                        >
                          {lobOptionData?.map((option) => {
                            return (
                              <MenuItem key={option} value={option}>
                                {option}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      )}
                    />
                  </FormControl>
                </Grid>
              </>
            )}
            {headerValue === "QA Summary Report" && (
              <>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={4}
                  sx={{ padding: "10px" }}
                >
                  <FormControl fullWidth>
                    <InputLabel>HRA Status:</InputLabel>
                    <Controller
                      name="hrastatus"
                      control={control}
                      defaultValue={[]}
                      render={({ field }) => (
                        <Select
                          className="inputAddMember"
                          name="hrastatus"
                          {...field}
                          multiple
                          value={field.value}
                          onChange={(e) => field.onChange(e.target.value)}
                          renderValue={(selected) =>
                            Array.isArray(selected)
                              ? selected
                                  .map(
                                    (id) =>
                                      hraStatusData.find(
                                        (option) => option.id === id,
                                      ).statusName,
                                  )
                                  .join(",")
                              : selected
                          }
                        >
                          {hraStatusData?.map((option) => {
                            return (
                              <MenuItem key={option?.id} value={option?.id}>
                                {option?.statusName}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      )}
                    />
                  </FormControl>
                </Grid>
              </>
            )}
            {(headerValue === "QA Summary Report" ||
              headerValue == "Weekly Scheduler Report") && (
              <>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={4}
                  sx={{ padding: "10px" }}
                >
                  <FormControl fullWidth>
                    <InputLabel>Assigned Clinician:</InputLabel>
                    <Controller
                      name="nurseId"
                      control={control}
                      defaultValue={[]}
                      render={({ field }) => (
                        <Select
                          className="inputAddMember"
                          name="nurseId"
                          {...field}
                          multiple
                          value={field.value}
                          onChange={(e) => field.onChange(e.target.value)}
                          renderValue={(selected) =>
                            Array.isArray(selected)
                              ? selected
                                  .map(
                                    (id) =>
                                      clinicianDataList.find(
                                        (option) => option.userid === id,
                                      ).firstName,
                                  )
                                  .join(",")
                              : selected
                          }
                        >
                          {clinicianDataList?.map((option) => {
                            return (
                              <MenuItem
                                key={option?.userid}
                                value={option?.userid}
                              >
                                {option?.firstName} {option.lastName}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={4}
                  sx={{ padding: "10px" }}
                >
                  <FormControl fullWidth>
                    <InputLabel>Visit Type:</InputLabel>
                    <Controller
                      name="visitType"
                      control={control}
                      defaultValue={[]}
                      render={({ field }) => (
                        <Select
                          className="inputAddMember"
                          name="visitType"
                          {...field}
                          multiple
                          value={field.value}
                          onChange={(e) => field.onChange(e.target.value)}
                          renderValue={(selected) =>
                            Array.isArray(selected)
                              ? selected
                                  .map(
                                    (id) =>
                                      visitTypeOptionsData.find(
                                        (option) => option.id === id,
                                      ).visitType,
                                  )
                                  .join(",")
                              : selected
                          }
                        >
                          {visitTypeOptionsData?.map((option) => {
                            return (
                              <MenuItem key={option?.id} value={option?.id}>
                                {option?.visitType}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      )}
                    />
                  </FormControl>
                </Grid>
              </>
            )}
            {(headerValue == "Monthly Screening Results" ||
              headerValue == "Care Gap Detail Report") && (
              <>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={4}
                  sx={{ padding: "10px" }}
                >
                  <FormControl fullWidth>
                    <InputLabel>Screening Type:</InputLabel>
                    <Select
                      fullWidth
                      {...register("screening")}
                      className="inputAddMember"
                      name="screening"
                      value={watch("screening")}
                    >
                      {screeningOptions.map((value) => (
                        <MenuItem key={value} value={value}>
                          {value}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </>
            )}
            {headerValue == "Weekly Scheduler Report" && (
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={4}
                xl={4}
                sx={{ padding: "10px" }}
              >
                <FormControl fullWidth>
                  <InputLabel>Status:</InputLabel>
                  <Select
                    fullWidth
                    {...register("status")}
                    className="inputAddMember"
                    name="status"
                    value={watch("status")}
                  >
                    {reportStatusOptionsData?.map((option) => {
                      return (
                        <MenuItem key={option?.id} value={option?.id}>
                          {option.statusName}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            )}
            {/* {headerValue === "Referral Form Report"  && (
              <>
               <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={4}
                  sx={{ padding: "10px" }}
                >
                  <TextField
                    className="inputAddMember"
                    {...register("plan")}
                    style={{ width: "100%" }}
                    label="Client:"
                    value={storedPlan ?? ""} //`value` prop on `input` should not be null
                    readOnly
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={4}
                  sx={{ padding: "10px" }}
                >
                  <TextField
                    className="inputAddMember"
                    label="list:"
                    style={{ width: "100%" }}
                    {...register("year")}
                    value={storedYear ?? ""} //`value` prop on `input` should not be null
                    readOnly
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={4}
                  sx={{ padding: "10px" }}
                >
                  <FormControl fullWidth>
                    <InputLabel>Program:</InputLabel>
                    <Controller
                      name="program"
                      control={control}
                      defaultValue={[]}
                      render={({ field }) => (
                        <Select
                          className="inputAddMember"
                          name="program"
                          {...field}
                          multiple
                          renderValue={(selected) => selected.join(", ")}
                        >
                          {programOptions?.map((option) => {
                            return (
                              <MenuItem key={option?.id} value={option?.name}>
                                {option?.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={4}
                  sx={{ padding: "10px" }}
                >
                  <FormControl fullWidth>
                    <InputLabel>LOB:</InputLabel>
                    <Controller
                      name="lob"
                      control={control}
                      defaultValue={[]}
                      render={({ field }) => (
                        <Select
                          className="inputAddMember"
                          name="lob"
                          {...field}
                          multiple
                          renderValue={(selected) => selected.join(", ")}
                          // value={field.value.toString().split(",")}
                          // onChange={(e) => field.onChange(e.target.value.join(","))}
                        >
                          {lobOptionData?.map((option) => {
                            return (
                              <MenuItem key={option} value={option}>
                                {option}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      )}
                    />
                  </FormControl>
                </Grid>
              </>
            )} */}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button sx={dialogBtn} onClick={handleClose}>
            Cancel
          </Button>
          <Button
            sx={dialogBtn}
            className="dialogBtn"
            onClick={handleApplyFilter}
          >
            Apply
          </Button>
          <Button
            sx={dialogBtn}
            className="dialogBtn reset-btn"
            onClick={handleClear}
          >
            Clear
          </Button>
        </DialogActions>
      </Dialog>
      <Toaster
        open={toasterOpen}
        severity={toasterSeverity}
        onClose={onCloseToaster}
        message={toasterMessage}
      />
      {isLoading && <Loader />}
    </div>
  );
}

export function NurseFilterDialogs({
  hraStatusOptions,
  visitTypeOptions,
  clinicianData,
  selectedDropdownValue,
}) {
  const alertContext = useContext(AlertContext);
  const {
    programTypesOptions,
    lobTypesOptions,
    lobOptionData,
    apiResponseData,
    clinicianDataList,
    hraStatusData,
    visitTypeOptionsData,
  } = useUserContext();
  const [isLoading, setIsLoading] = React.useState(false);
  const formmethod = useForm();
  const [programOptions, setProgramOptions] = useState([]);
  const [callLogStatusOptionsData, setCallLogStatusOptionsData] = useState([]);
  const [reportStatusOptionsData, setReportStatusOptionsData] = useState([]);
  const [schedulerDataList, setSchedulerDataList] = useState([]);
  const [toasterMessage, setToasterMessage] = useState("");
  const [toasterOpen, setToasterOpen] = useState(false);
  const [toasterSeverity, setToasterSeverity] = useState(Labels.SUCCESS);

  const {
    register,
    control,
    handleSubmit,
    watch,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = formmethod;
  const storedPlan = localStorage.getItem("clientPlan");
  const storedYear = localStorage.getItem("clientYear");
  const firstName = sessionStorage.getItem("firstName");
  const lastName = sessionStorage.getItem("lastName");
  const getMemberApiData = async () => {
    await getAllClients()
      .then((response) => {
        const allMappingData = response?.data?.data.map((data) => data);
        let selectedClientBasedObjectValue = _.find(allMappingData, {
          plan: storedPlan,
        });
        setProgramOptions(selectedClientBasedObjectValue?.program);
      })
      .catch((error) => {
        return;
      });
  };
  const referralTypeOptions = [
    "Critical Incident Report",
    "Urgent",
    "Non-Urgent",
  ];

  const referralStatusOptions = [
    "New",
    "Under Review",
    "Pending Revision",
    "Completed",
  ];

  const eyeExamOptions = ["Yes", "No"];

  const callBackExamOptions = ["Due", "OverDue"];
  const storedPlanId = localStorage.getItem("selectedClientId") || "";
  const callLog = async () => {
    try {
      const response = await getApi(`/call-log-status`);
      const data = await response.data.data;
      setCallLogStatusOptionsData(data);
    } catch {
      return;
    }
  };

  const reportStatus = async () => {
    try {
      const response = await getApi("/reportstatus");
      const data = await response.data.data;
      setReportStatusOptionsData(response?.data?.data);
    } catch {
      return;
    }
  };

  const fetchAllSchedulerData = async () => {
    if (!_.isEmpty(storedPlanId)) {
      const response = await postApi("/users/get-users-by-program-roleId", {
        planId: storedPlanId,
      });
      const clientPlanFromLocalStorage = localStorage.getItem("clientPlan");
      setSchedulerDataList(response?.data?.data[0]?.scheduler);
    }
  };

  const handleClose = () => {
    alertContext.setFilterDialogData(null);
    alertContext.setFilterDialog(false);
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  React.useEffect(() => {
    reset(alertContext?.filterDialogData);
    callLog();
    reportStatus();
  }, []);

  React.useEffect(() => {
    fetchAllSchedulerData();
    getMemberApiData();
  }, [storedPlanId]);

  const handleApplyFilter = React.useCallback(() => {
    var updatedFormValues = getValues();
    var start_date = updatedFormValues?.scheduleDateFrom;
    var end_date = updatedFormValues?.scheduleDateTo;

    const referralFrom_date = updatedFormValues?.dateOfReferralForm;
    const referralTo_date = updatedFormValues?.dateOfReferralTo;

    if (!_.isEmpty(updatedFormValues?.callResultStatus)) {
      var updatedFormValues = {
        ...updatedFormValues,
        callResultStatus: updatedFormValues?.callResultStatus.join(","),
      };
    }
    if (!_.isEmpty(updatedFormValues?.callbackstatus)) {
      var updatedFormValues = {
        ...updatedFormValues,
        callbackstatus: updatedFormValues?.callbackstatus.join(","),
      };
    }
    if (!_.isEmpty(updatedFormValues?.eyeExam)) {
      var updatedFormValues = {
        ...updatedFormValues,
        eyeExam: updatedFormValues?.eyeExam.join(","),
      };
    }
    if (!_.isEmpty(updatedFormValues?.lob)) {
      var updatedFormValues = {
        ...updatedFormValues,
        lob: updatedFormValues?.lob.join(","),
      };
    }
    if (!_.isEmpty(updatedFormValues?.hrastatus)) {
      var updatedFormValues = {
        ...updatedFormValues,
        hrastatus: updatedFormValues?.hrastatus.join(","),
      };
    }
    if (!_.isEmpty(updatedFormValues?.visitType)) {
      var updatedFormValues = {
        ...updatedFormValues,
        visitType: updatedFormValues?.visitType.join(","),
      };
    }
    if (!_.isEmpty(updatedFormValues?.nurseId)) {
      var updatedFormValues = {
        ...updatedFormValues,
        nurseId: updatedFormValues?.nurseId.join(","),
      };
    }
    if (!_.isEmpty(updatedFormValues?.schedulerId)) {
      var updatedFormValues = {
        ...updatedFormValues,
        schedulerId: updatedFormValues?.schedulerId.join(","),
      };
    }
    if (!_.isEmpty(updatedFormValues?.program)) {
      var updatedFormValues = {
        ...updatedFormValues,
        program: updatedFormValues?.program.join(","),
      };
    }
    if (!_.isEmpty(updatedFormValues?.screening)) {
      var updatedFormValues = {
        ...updatedFormValues,
        screening: updatedFormValues?.screening.join(","),
      };
    }
    if (!_.isEmpty(updatedFormValues?.status)) {
      var updatedFormValues = {
        ...updatedFormValues,
        status: updatedFormValues?.status.join(","),
      };
    }

    if (!_.isEmpty(updatedFormValues?.referralType)) {
      var updatedFormValues = {
        ...updatedFormValues,
        referralType: updatedFormValues?.referralType.join(","),
      };
    }

    if (!_.isEmpty(updatedFormValues?.referralStatus)) {
      var updatedFormValues = {
        ...updatedFormValues,
        referralStatus: updatedFormValues?.referralStatus.join(","),
      };
    }

    if (
      Boolean(start_date || referralFrom_date) &&
      Boolean(end_date || referralTo_date) &&
      new Date(start_date || referralFrom_date)?.getTime() >=
        new Date(end_date || referralTo_date)?.getTime()
    ) {
      setToasterOpen(true);
      setToasterSeverity(Labels.ERROR);
      setToasterMessage(
        "Schedule To Date should be greater than Schedule From Date",
      );
      reset({
        scheduleDateFrom: null,
        scheduleDateTo: null,
        dateOfReferralForm: null,
        dateOfReferralTo: null,
      });
    } else {
      alertContext.setFilterDialogData(updatedFormValues);
      alertContext.setFilterDialog(false);
    }
    handleClear();
  }, []);

  const handleClear = React.useCallback(() => {
    reset();
    reset({
      lob: [],
      program: [],
      hrastatus: [],
      callResultStatus: [],
      schedulerId: [],
      visitType: [],
      nurseId: [],
      status: [],
      eyeExam: [],
      callbackstatus: [],
      scheduleDateFrom: null,
      scheduleDateTo: null,
      screening: [],
      value: "",
      dateOfReferralForm: null,
      dateOfReferralTo: null,
    });
  }, []);

  const onCloseToaster = () => {
    setToasterOpen(false);
    setToasterMessage("");
  };

  return (
    <div>
      <Dialog
        open={alertContext.filterDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
      >
        <DialogTitle
          sx={{
            background: "var(--mainTheme)",
            fontSize: "20px",
            color: "white",
            fontFamily: "Inter,sans-serif",
            height: "40px",
            display: "flex",
            alignItems: "center",
          }}
          id="alert-dialog-title"
        >
          Filter
        </DialogTitle>
        <DialogContent sx={{ paddingTop: "20px !important" }}>
          <Grid container spacing={2} sx={{ padding: "16px" }}>
            {selectedDropdownValue == "Referral" ? (
              <>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={4}
                  sx={{ padding: "10px" }}
                >
                  <Controller
                    name={"dateOfReferralForm"}
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DesktopDatePicker
                        label="Date of Referral From :"
                        className="inputAddMember"
                        views={["year", "month", "day"]}
                        onChange={onChange}
                        value={value}
                        renderInput={(params) => <TextField {...params} />}
                        components={{
                          ActionBar: (props) => (
                            <div
                              style={{
                                display: "flex",
                                position: "absolute",
                                justifyContent: "space-around",
                                bottom: "0",
                                width: "100%",
                                backgroundColor: "white",
                                padding: "8px",
                                borderTop: "1px solid #ddd",
                              }}
                            >
                              <Button
                                color="primary"
                                onClick={() => onChange(null)}
                              >
                                Clear
                              </Button>
                              {/* <Button
                            color="primary"
                            onClick={props.onAccept}
                          >
                            OK
                          </Button> */}
                            </div>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={4}
                  sx={{ padding: "10px" }}
                >
                  <Controller
                    name={"dateOfReferralTo"}
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DesktopDatePicker
                        label="Date of Referral To :"
                        className="inputAddMember"
                        views={["year", "month", "day"]}
                        onChange={onChange}
                        value={value}
                        renderInput={(params) => <TextField {...params} />}
                        components={{
                          ActionBar: (props) => (
                            <div
                              style={{
                                display: "flex",
                                position: "absolute",
                                justifyContent: "space-around",
                                bottom: "0",
                                width: "100%",
                                backgroundColor: "white",
                                padding: "8px",
                                borderTop: "1px solid #ddd",
                              }}
                            >
                              <Button
                                color="primary"
                                onClick={() => onChange(null)}
                              >
                                Clear
                              </Button>
                              {/* <Button
                            color="primary"
                            onClick={props.onAccept}
                          >
                            OK
                          </Button> */}
                            </div>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={4}
                  sx={{ padding: "10px" }}
                >
                  <FormControl fullWidth>
                    <InputLabel>Referral Type:</InputLabel>
                    <Controller
                      name="referralType"
                      control={control}
                      defaultValue={[]}
                      render={({ field }) => (
                        <Select
                          className="inputAddMember"
                          name="referralType"
                          {...field}
                          multiple
                          // value="referralType"
                        >
                          {referralTypeOptions?.map((value) => {
                            return (
                              <MenuItem key={value} value={value}>
                                {value}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={4}
                  sx={{ padding: "10px" }}
                >
                  <FormControl fullWidth>
                    <InputLabel>Referral Status:</InputLabel>
                    <Controller
                      name="referralStatus"
                      control={control}
                      defaultValue={[]}
                      render={({ field }) => (
                        <Select
                          className="inputAddMember"
                          name="referralStatus"
                          {...field}
                          multiple
                          //  value="referralStatus"
                        >
                          {referralStatusOptions?.map((value) => {
                            return (
                              <MenuItem key={value} value={value}>
                                {value}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={4}
                  sx={{ padding: "10px" }}
                >
                  <FormControl fullWidth>
                    <InputLabel shrink>Assigned Clinician:</InputLabel>
                    <Controller
                      name="nurseId1"
                      control={control}
                      defaultValue={firstName + " " + lastName} // Set default value as name
                      render={({ field }) => (
                        <TextField
                          className="inputAddMember"
                          name="nurseId1"
                          {...field}
                          value={firstName + " " + lastName} // Set value as name
                          disabled // Disable the field
                          InputProps={{
                            readOnly: true, // Ensure it's read-only
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
              </>
            ) : (
              <>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={4}
                  sx={{ padding: "10px" }}
                >
                  <Controller
                    name={"scheduleDateFrom"}
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DesktopDatePicker
                        label="Scheduled From:"
                        className="inputAddMember"
                        views={["year", "month", "day"]}
                        onChange={onChange}
                        value={value}
                        renderInput={(params) => <TextField {...params} />}
                        components={{
                          ActionBar: (props) => (
                            <div
                              style={{
                                display: "flex",
                                position: "absolute",
                                justifyContent: "space-around",
                                bottom: "0",
                                width: "100%",
                                backgroundColor: "white",
                                padding: "8px",
                                borderTop: "1px solid #ddd",
                              }}
                            >
                              <Button
                                color="primary"
                                onClick={() => onChange(null)}
                              >
                                Clear
                              </Button>
                              {/* <Button
                            color="primary"
                            onClick={props.onAccept}
                          >
                            OK
                          </Button> */}
                            </div>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={4}
                  sx={{ padding: "10px" }}
                >
                  <Controller
                    name={"scheduleDateTo"}
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DesktopDatePicker
                        label="Scheduled To:"
                        className="inputAddMember"
                        views={["year", "month", "day"]}
                        onChange={onChange}
                        value={value}
                        renderInput={(params) => <TextField {...params} />}
                        components={{
                          ActionBar: (props) => (
                            <div
                              style={{
                                display: "flex",
                                position: "absolute",
                                justifyContent: "space-around",
                                bottom: "0",
                                width: "100%",
                                backgroundColor: "white",
                                padding: "8px",
                                borderTop: "1px solid #ddd",
                              }}
                            >
                              <Button
                                color="primary"
                                onClick={() => onChange(null)}
                              >
                                Clear
                              </Button>
                              {/* <Button
                            color="primary"
                            onClick={props.onAccept}
                          >
                            OK
                          </Button> */}
                            </div>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={4}
                  sx={{ padding: "10px" }}
                >
                  <FormControl fullWidth>
                    <InputLabel>Program:</InputLabel>
                    <Controller
                      name="program"
                      control={control}
                      defaultValue={[]}
                      render={({ field }) => (
                        <Select
                          className="inputAddMember"
                          name="program"
                          {...field}
                          multiple
                          renderValue={(selected) => selected.join(", ")}
                        >
                          {programOptions?.map((option) => {
                            return (
                              <MenuItem key={option?.id} value={option?.name}>
                                {option?.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={4}
                  sx={{ padding: "10px" }}
                >
                  <FormControl fullWidth>
                    <InputLabel>LOB:</InputLabel>
                    <Controller
                      name="lob"
                      control={control}
                      defaultValue={[]}
                      render={({ field }) => (
                        <Select
                          className="inputAddMember"
                          name="lob"
                          {...field}
                          multiple
                          renderValue={(selected) => selected.join(", ")}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: 250, // Adjust the max height as needed
                              },
                            },
                          }}
                        >
                          {lobOptionData?.map((option) => {
                            return (
                              <MenuItem key={option} value={option}>
                                {option}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={4}
                  sx={{ padding: "10px" }}
                >
                  <FormControl fullWidth>
                    <InputLabel>Visit Type:</InputLabel>
                    <Controller
                      name="visitType"
                      control={control}
                      defaultValue={[]}
                      render={({ field }) => (
                        <Select
                          className="inputAddMember"
                          name="visitType"
                          {...field}
                          multiple
                          value={field.value}
                          onChange={(e) => field.onChange(e.target.value)}
                          renderValue={(selected) =>
                            Array.isArray(selected)
                              ? selected
                                  .map(
                                    (id) =>
                                      visitTypeOptionsData.find(
                                        (option) => option.id === id,
                                      ).visitType,
                                  )
                                  .join(",")
                              : selected
                          }
                        >
                          {visitTypeOptionsData?.map((option) => {
                            return (
                              <MenuItem key={option?.id} value={option?.id}>
                                {option?.visitType}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={4}
                  sx={{ padding: "10px" }}
                >
                  <FormControl fullWidth>
                    <InputLabel>HRA Status:</InputLabel>
                    <Controller
                      name="hrastatus"
                      control={control}
                      defaultValue={[]}
                      render={({ field }) => (
                        <Select
                          className="inputAddMember"
                          name="hrastatus"
                          {...field}
                          multiple
                          value={field.value}
                          onChange={(e) => field.onChange(e.target.value)}
                          renderValue={(selected) =>
                            Array.isArray(selected)
                              ? selected
                                  .map(
                                    (id) =>
                                      hraStatusData.find(
                                        (option) => option.id === id,
                                      ).statusName,
                                  )
                                  .join(",")
                              : selected
                          }
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: 250, // Adjust the max height as needed
                              },
                            },
                          }}
                          //multiple select chip
                          // renderValue={(selected) => (
                          //   <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          //     {selected.map((value) => (
                          //       <Chip key={value} label={value} />
                          //     ))}
                          //   </Box>
                          // )}
                        >
                          {hraStatusData
                            ?.filter((opt) => opt.statusName != "Unscheduled")
                            .map((option) => {
                              return (
                                <MenuItem key={option?.id} value={option?.id}>
                                  {option?.statusName}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={4}
                  sx={{ padding: "10px" }}
                >
                  {/* <FormControl fullWidth>
                <InputLabel>Assigned Clinician:</InputLabel>
                <Controller
                  name="nurseId"
                  control={control}
                  defaultValue={[]}
                  value={watch("nurseId")}
                  render={({ field }) => (
                    <Select
                      className="inputAddMember"
                      name="nurseId"
                      {...field}
                      multiple
                      value={field.value}
                      onChange={(e) => field.onChange(e.target.value)}
                      renderValue={(selected) =>
                        Array.isArray(selected)
                          ? selected
                              .map(
                                (id) =>
                                  clinicianDataList.find(
                                    (option) => option.userid === id,
                                  ).firstName,
                              )
                              .join(",")
                          : selected
                      }
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 250, // Adjust the max height as needed
                          },
                        },
                      }}
                    >
                      {clinicianDataList?.map((option) => {
                        return (
                          <MenuItem key={option?.userid} value={option?.userid}>
                            {option.firstName} {option.lastName}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  )}
                />
              </FormControl> */}
                  <FormControl fullWidth>
                    <InputLabel shrink>Assigned Clinician:</InputLabel>
                    <Controller
                      name="nurseId1"
                      control={control}
                      defaultValue={firstName + " " + lastName} // Set default value as name
                      render={({ field }) => (
                        <TextField
                          className="inputAddMember"
                          name="nurseId1"
                          {...field}
                          value={firstName + " " + lastName} // Set value as name
                          disabled // Disable the field
                          InputProps={{
                            readOnly: true, // Ensure it's read-only
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
              </>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button sx={dialogBtn} onClick={handleClose}>
            Cancel
          </Button>
          <Button
            sx={dialogBtn}
            className="dialogBtn"
            onClick={handleApplyFilter}
          >
            Apply
          </Button>
          <Button
            sx={dialogBtn}
            className="dialogBtn reset-btn"
            onClick={handleClear}
          >
            Clear
          </Button>
        </DialogActions>
      </Dialog>
      <Toaster
        open={toasterOpen}
        severity={toasterSeverity}
        onClose={onCloseToaster}
        message={toasterMessage}
      />
      {isLoading && <Loader />}
    </div>
  );
}

export function CoderFilterDialogs({
  hraStatusOptions,
  visitTypeOptions,
  clinicianData,
}) {
  const alertContext = useContext(AlertContext);
  const {
    programTypesOptions,
    lobTypesOptions,
    lobOptionData,
    apiResponseData,
    clinicianDataList,
    hraStatusData,
    visitTypeOptionsData,
  } = useUserContext();
  const [isLoading, setIsLoading] = React.useState(false);
  const formmethod = useForm();
  const [programOptions, setProgramOptions] = useState([]);
  const [callLogStatusOptionsData, setCallLogStatusOptionsData] = useState([]);
  const [reportStatusOptionsData, setReportStatusOptionsData] = useState([]);
  const [schedulerDataList, setSchedulerDataList] = useState([]);
  const [qaDataList, setQaDataList] = useState([]);
  const [coderDataList, setCoderDataList] = useState([]);
  const [allClinicianList, setAllClinicianList] = useState([]);
  const [allCoderList, setAllCoderList] = useState([]);
  const [allQaList, setAllQaList] = useState([]);
  const [toasterMessage, setToasterMessage] = useState("");
  const [toasterOpen, setToasterOpen] = useState(false);
  const [toasterSeverity, setToasterSeverity] = useState(Labels.SUCCESS);

  const {
    register,
    control,
    handleSubmit,
    watch,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = formmethod;
  const storedPlan = localStorage.getItem("clientPlan");
  const storedYear = localStorage.getItem("clientYear");
  const getMemberApiData = async () => {
    await getAllClients()
      .then((response) => {
        const allMappingData = response?.data?.data.map((data) => data);
        let selectedClientBasedObjectValue = _.find(allMappingData, {
          plan: storedPlan,
        });
        setProgramOptions(selectedClientBasedObjectValue?.program);
      })
      .catch((error) => {
        return;
      });
  };
  const getAllClinicansList = async () => {
    try {
      const response = await postApi("/users/get-users-by-roleId", {
        planId: storedPlanId,
        roleId: "5",
      });
      setAllClinicianList(response?.data?.data);
    } catch (error) {
      return;
    }
  };
  const getAllCoderList = async () => {
    try {
      const response = await postApi("/users/get-users-by-roleId", {
        planId: storedPlanId,
        roleId: "8,9",
      });
      setAllCoderList(response?.data?.data);
    } catch (error) {
      return;
    }
  };
  const getAllQaList = async () => {
    try {
      const response = await postApi("/users/get-users-by-roleId", {
        planId: storedPlanId,
        roleId: "6,7",
      });
      setAllQaList(response?.data?.data);
    } catch (error) {
      return;
    }
  };

  const subStatusOptions = [
    {
      id: 0,
      value: "New",
    },
    {
      id: 1,
      value: "1st Review",
    },
    {
      id: 2,
      value: "2nd Review",
    },
    {
      id: 3,
      value: "3rd Review",
    },
    {
      id: 4,
      value: "4th Review",
    },
    {
      id: 5,
      value: "5th Review and above",
    },
  ];
  const storedPlanId = localStorage.getItem("selectedClientId") || "";
  const callLog = async () => {
    try {
      const response = await getApi(`/call-log-status`);
      const data = await response.data.data;
      setCallLogStatusOptionsData(data);
    } catch {
      return;
    }
  };

  const reportStatus = async () => {
    try {
      const response = await getApi("/reportstatus");
      const data = await response.data.data;
      setReportStatusOptionsData(response?.data?.data);
    } catch {
      return;
    }
  };

  const fetchAllSchedulerData = async () => {
    if (!_.isEmpty(storedPlanId)) {
      const response = await postApi("/users/get-users-by-program-roleId", {
        planId: storedPlanId,
      });
      const clientPlanFromLocalStorage = localStorage.getItem("clientPlan");
      setQaDataList(response?.data?.data[0]?.qa);
      setCoderDataList(response?.data?.data[0]?.coder);
    }
  };

  const handleClose = () => {
    alertContext.setFilterDialogData(null);
    alertContext.setFilterDialog(false);
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  React.useEffect(() => {
    reset(alertContext?.filterDialogData);
    callLog();
    reportStatus();
  }, []);

  React.useEffect(() => {
    if (!_.isEmpty(storedPlanId)) {
      fetchAllSchedulerData();
      getMemberApiData();
      getAllClinicansList();
      getAllCoderList();
      getAllQaList();
    }
  }, [storedPlanId]);

  const handleApplyFilter = React.useCallback(() => {
    var updatedFormValues = getValues();
    var start_date = updatedFormValues?.scheduleDateFrom;
    var end_date = updatedFormValues?.scheduleDateTo;

    if (!_.isEmpty(updatedFormValues?.lob)) {
      var updatedFormValues = {
        ...updatedFormValues,
        lob: updatedFormValues?.lob.join(","),
      };
    }

    if (!_.isEmpty(updatedFormValues?.coderId)) {
      var updatedFormValues = {
        ...updatedFormValues,
        coderId: updatedFormValues?.coderId.join(","),
      };
    }
    if (!_.isEmpty(updatedFormValues?.qaId)) {
      var updatedFormValues = {
        ...updatedFormValues,
        qaId: updatedFormValues?.qaId.join(","),
      };
    }
    if (!_.isEmpty(updatedFormValues?.nurseId)) {
      var updatedFormValues = {
        ...updatedFormValues,
        nurseId: updatedFormValues?.nurseId.join(","),
      };
    }
    if (!_.isEmpty(updatedFormValues?.schedulerId)) {
      var updatedFormValues = {
        ...updatedFormValues,
        schedulerId: updatedFormValues?.schedulerId.join(","),
      };
    }
    if (!_.isEmpty(updatedFormValues?.program)) {
      var updatedFormValues = {
        ...updatedFormValues,
        program: updatedFormValues?.program.join(","),
      };
    }
    if (!_.isEmpty(updatedFormValues?.status)) {
      var updatedFormValues = {
        ...updatedFormValues,
        status: updatedFormValues?.status.join(","),
      };
    }
    if (!_.isEmpty(updatedFormValues?.visitType)) {
      var updatedFormValues = {
        ...updatedFormValues,
        visitType: updatedFormValues?.visitType.join(","),
      };
    }
    if (!_.isEmpty(updatedFormValues?.subStatus)) {
      var updatedFormValues = {
        ...updatedFormValues,
        subStatus: updatedFormValues?.subStatus.join(","),
      };
    }

    if (
      Boolean(start_date) &&
      Boolean(end_date) &&
      new Date(start_date)?.getTime() >= new Date(end_date)?.getTime()
    ) {
      setToasterOpen(true);
      setToasterSeverity(Labels.ERROR);
      setToasterMessage(
        "Schedule To Date should be greater than Schedule From Date",
      );
      reset({
        scheduleDateFrom: null,
        scheduleDateTo: null,
      });
    } else {
      alertContext.setFilterDialogData(updatedFormValues);
      alertContext.setFilterDialog(false);
    }
  }, []);

  const onCloseToaster = () => {
    setToasterOpen(false);
    setToasterMessage("");
  };

  const handleClear = React.useCallback(() => {
    reset();
    reset({
      lob: [],
      program: [],
      schedulerId: [],
      nurseId: [],
      coderId: [],
      qaId: [],
      subStatus: [],
      status: [],
      eyeExam: [],
      callbackstatus: [],
      scheduleDateFrom: null,
      scheduleDateTo: null,
      screening: [],
      value: "",
    });
  }, []);

  return (
    <div>
      <Dialog
        open={alertContext.filterDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
      >
        <DialogTitle
          sx={{
            background: "var(--mainTheme)",
            fontSize: "20px",
            color: "white",
            fontFamily: "Inter,sans-serif",
            height: "40px",
            display: "flex",
            alignItems: "center",
          }}
          id="alert-dialog-title"
        >
          Filter
        </DialogTitle>
        <DialogContent sx={{ paddingTop: "20px !important" }}>
          <Grid container spacing={2} sx={{ padding: "16px" }}>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              xl={4}
              sx={{ padding: "10px" }}
            >
              <Controller
                name={"scheduleDateFrom"}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <DesktopDatePicker
                    label="Scheduled From:"
                    className="inputAddMember"
                    views={["year", "month", "day"]}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              xl={4}
              sx={{ padding: "10px" }}
            >
              <Controller
                name={"scheduleDateTo"}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <DesktopDatePicker
                    label="Scheduled To:"
                    className="inputAddMember"
                    views={["year", "month", "day"]}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              xl={4}
              sx={{ padding: "10px" }}
            >
              <TextField
                className="inputAddMember"
                {...register("plan")}
                style={{ width: "100%" }}
                label="Client:"
                value={storedPlan ?? ""} //`value` prop on `input` should not be null
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              xl={4}
              sx={{ padding: "10px" }}
            >
              <TextField
                className="inputAddMember"
                label="list:"
                style={{ width: "100%" }}
                {...register("year")}
                value={storedYear ?? ""} //`value` prop on `input` should not be null
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              xl={4}
              sx={{ padding: "10px" }}
            >
              <FormControl fullWidth>
                <InputLabel>Program:</InputLabel>
                <Controller
                  name="program"
                  control={control}
                  defaultValue={[]}
                  render={({ field }) => (
                    <Select
                      className="inputAddMember"
                      name="program"
                      {...field}
                      multiple
                      renderValue={(selected) => selected.join(", ")}
                    >
                      {programOptions?.map((option) => {
                        return (
                          <MenuItem key={option?.id} value={option?.name}>
                            {option?.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              xl={4}
              sx={{ padding: "10px" }}
            >
              <FormControl fullWidth>
                <InputLabel>LOB:</InputLabel>
                <Controller
                  name="lob"
                  control={control}
                  defaultValue={[]}
                  render={({ field }) => (
                    <Select
                      className="inputAddMember"
                      name="lob"
                      {...field}
                      multiple
                      renderValue={(selected) => selected.join(", ")}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 250, // Adjust the max height as needed
                          },
                        },
                      }}
                    >
                      {lobOptionData?.map((option) => {
                        return (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              xl={4}
              sx={{ padding: "10px" }}
            >
              <FormControl fullWidth>
                <InputLabel>Visit Type:</InputLabel>
                <Controller
                  name="visitType"
                  control={control}
                  defaultValue={[]}
                  render={({ field }) => (
                    <Select
                      className="inputAddMember"
                      name="visitType"
                      {...field}
                      multiple
                      value={field.value}
                      onChange={(e) => field.onChange(e.target.value)}
                      renderValue={(selected) =>
                        Array.isArray(selected)
                          ? selected
                              .map(
                                (id) =>
                                  visitTypeOptionsData.find(
                                    (option) => option.id === id,
                                  ).visitType,
                              )
                              .join(",")
                          : selected
                      }
                    >
                      {visitTypeOptionsData?.map((option) => {
                        return (
                          <MenuItem key={option?.id} value={option?.id}>
                            {option?.visitType}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              xl={4}
              sx={{ padding: "10px" }}
            >
              <FormControl fullWidth>
                <InputLabel>Assigned Clinician:</InputLabel>
                <Controller
                  name="nurseId"
                  control={control}
                  defaultValue={[]}
                  value={watch("nurseId")}
                  render={({ field }) => (
                    <Select
                      className="inputAddMember"
                      name="nurseId"
                      {...field}
                      multiple
                      value={field.value}
                      onChange={(e) => field.onChange(e.target.value)}
                      renderValue={(selected) =>
                        Array.isArray(selected)
                          ? selected
                              .map(
                                (id) =>
                                  allClinicianList.find(
                                    (option) => option.userid === id,
                                  ).firstName,
                              )
                              .join(",")
                          : selected
                      }
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 250, // Adjust the max height as needed
                          },
                        },
                      }}
                    >
                      {allClinicianList?.map((option) => {
                        return (
                          <MenuItem key={option?.userid} value={option?.userid}>
                            {option.firstName} {option.lastName}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              xl={4}
              sx={{ padding: "10px" }}
            >
              <FormControl fullWidth>
                <InputLabel>Assigned QA:</InputLabel>
                <Controller
                  name="qaId"
                  control={control}
                  defaultValue={[]}
                  value={watch("qaId")}
                  render={({ field }) => (
                    <Select
                      className="inputAddMember"
                      name="qaId"
                      {...field}
                      multiple
                      value={field.value}
                      onChange={(e) => field.onChange(e.target.value)}
                      renderValue={(selected) =>
                        Array.isArray(selected)
                          ? selected
                              .map(
                                (id) =>
                                  allQaList.find(
                                    (option) => option.userid === id,
                                  ).firstName,
                              )
                              .join(",")
                          : selected
                      }
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 250, // Adjust the max height as needed
                          },
                        },
                      }}
                    >
                      {allQaList?.map((option) => {
                        return (
                          <MenuItem key={option?.userid} value={option?.userid}>
                            {option.firstName} {option.lastName}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              xl={4}
              sx={{ padding: "10px" }}
            >
              <FormControl fullWidth>
                <InputLabel>Assigned Coder:</InputLabel>
                <Controller
                  name="coderId"
                  control={control}
                  defaultValue={[]}
                  value={watch("coderId")}
                  render={({ field }) => (
                    <Select
                      className="inputAddMember"
                      name="coderId"
                      {...field}
                      multiple
                      value={field.value}
                      onChange={(e) => field.onChange(e.target.value)}
                      renderValue={(selected) =>
                        Array.isArray(selected)
                          ? selected
                              .map(
                                (id) =>
                                  allCoderList.find(
                                    (option) => option.userid === id,
                                  ).firstName,
                              )
                              .join(",")
                          : selected
                      }
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 250, // Adjust the max height as needed
                          },
                        },
                      }}
                    >
                      {allCoderList?.map((option) => {
                        return (
                          <MenuItem key={option?.userid} value={option?.userid}>
                            {option?.firstName} {option?.lastName}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              xl={4}
              sx={{ padding: "10px" }}
            >
              <FormControl fullWidth>
                <InputLabel>Sub Status:</InputLabel>
                <Controller
                  name="subStatus"
                  control={control}
                  defaultValue={[]}
                  value={watch("subStatus")}
                  render={({ field }) => (
                    <Select
                      className="inputAddMember"
                      name="subStatus"
                      {...field}
                      multiple
                      value={field.value}
                      onChange={(e) => field.onChange(e.target.value)}
                      renderValue={(selected) =>
                        Array.isArray(selected)
                          ? selected
                              .map(
                                (id) =>
                                  subStatusOptions.find(
                                    (option) => option.id === id,
                                  ).value,
                              )
                              .join(",")
                          : selected
                      }
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 250, // Adjust the max height as needed
                          },
                        },
                      }}
                    >
                      {subStatusOptions?.map((option) => {
                        return (
                          <MenuItem key={option?.id} value={option?.id}>
                            {option?.value}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button sx={dialogBtn} onClick={handleClose}>
            Cancel
          </Button>
          <Button
            sx={dialogBtn}
            className="dialogBtn"
            onClick={handleApplyFilter}
          >
            Apply
          </Button>
          <Button
            sx={dialogBtn}
            className="dialogBtn reset-btn"
            onClick={handleClear}
          >
            Clear
          </Button>
        </DialogActions>
      </Dialog>
      <Toaster
        open={toasterOpen}
        severity={toasterSeverity}
        onClose={onCloseToaster}
        message={toasterMessage}
      />
      {isLoading && <Loader />}
    </div>
  );
}

export function QaFilterDialogs({
  hraStatusOptions,
  visitTypeOptions,
  clinicianData,
  selectedDropdownValue,
}) {
  const alertContext = useContext(AlertContext);
  const {
    programTypesOptions,
    lobTypesOptions,
    lobOptionData,
    apiResponseData,
    clinicianDataList,
    hraStatusData,
    visitTypeOptionsData,
  } = useUserContext();
  const [isLoading, setIsLoading] = React.useState(false);
  const formmethod = useForm();
  const [programOptions, setProgramOptions] = useState([]);
  const [callLogStatusOptionsData, setCallLogStatusOptionsData] = useState([]);
  const [reportStatusOptionsData, setReportStatusOptionsData] = useState([]);
  const [schedulerDataList, setSchedulerDataList] = useState([]);
  const [qaDataList, setQaDataList] = useState([]);
  const [allClinicianList, setAllClinicianList] = useState([]);
  const [toasterMessage, setToasterMessage] = useState("");
  const [toasterOpen, setToasterOpen] = useState(false);
  const [toasterSeverity, setToasterSeverity] = useState(Labels.SUCCESS);
  const [allQaList, setAllQaList] = useState([]);

  const {
    control,
    watch,
    getValues,
    reset,
    formState: { errors },
  } = formmethod;
  const storedPlan = localStorage.getItem("clientPlan");
  const storedYear = localStorage.getItem("clientYear");
  const roleId = sessionStorage.getItem("roleId");

  const getMemberApiData = async () => {
    await getAllClients()
      .then((response) => {
        const allMappingData = response?.data?.data.map((data) => data);
        let selectedClientBasedObjectValue = _.find(allMappingData, {
          plan: storedPlan,
        });
        setProgramOptions(selectedClientBasedObjectValue?.program);
      })
      .catch((error) => {
        return;
      });
  };

  const getAllClinicansList = async () => {
    try {
      const response = await postApi("/users/get-users-by-roleId", {
        planId: storedPlanId,
        roleId: "5",
      });
      setAllClinicianList(response?.data?.data);
    } catch (error) {
      return;
    }
  };

  const subStatusOptions = [
    {
      id: 0,
      value: "New",
    },
    {
      id: 1,
      value: "1st Review",
    },
    {
      id: 2,
      value: "2nd Review",
    },
    {
      id: 3,
      value: "3rd Review",
    },
    {
      id: 4,
      value: "4th Review",
    },
    {
      id: 5,
      value: "5th Review and above",
    },
  ];
  const storedPlanId = localStorage.getItem("selectedClientId") || "";
  const callLog = async () => {
    try {
      const response = await getApi(`/call-log-status`);
      const data = await response.data.data;
      setCallLogStatusOptionsData(data);
    } catch {
      return;
    }
  };

  const reportStatus = async () => {
    try {
      const response = await getApi("/reportstatus");
      const data = await response.data.data;
      setReportStatusOptionsData(response?.data?.data);
    } catch {
      return;
    }
  };

  const fetchAllSchedulerData = async () => {
    if (!_.isEmpty(storedPlanId)) {
      const response = await postApi("/users/get-users-by-program-roleId", {
        planId: storedPlanId,
      });
      const clientPlanFromLocalStorage = localStorage.getItem("clientPlan");
      setQaDataList(response?.data?.data[0]?.qa);
    }
  };

  const handleClose = () => {
    alertContext.setFilterDialogData(null);
    alertContext.setFilterDialog(false);
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  React.useEffect(() => {
    reset(alertContext?.filterDialogData);
    callLog();
    reportStatus();
  }, []);

  React.useEffect(() => {
    if (!_.isEmpty(storedPlanId)) {
      fetchAllSchedulerData();
      getMemberApiData();
      getAllClinicansList();
    }
  }, [storedPlanId]);

  const getAllQaList = async () => {
    try {
      const response = await postApi("/users/get-users-by-roleId", {
        planId: storedPlanId,
        roleId: "6,7",
      });
      setAllQaList(response?.data?.data);
    } catch (error) {
      return;
    }
  };

  const handleApplyFilter = React.useCallback(() => {
    var updatedFormValues = getValues();
    var start_date = updatedFormValues?.scheduleDateFrom;
    var end_date = updatedFormValues?.scheduleDateTo;

    if (!_.isEmpty(updatedFormValues?.lob)) {
      var updatedFormValues = {
        ...updatedFormValues,
        lob: updatedFormValues?.lob.join(","),
      };
    }
    if (!_.isEmpty(updatedFormValues?.nurseId)) {
      var updatedFormValues = {
        ...updatedFormValues,
        nurseId: updatedFormValues?.nurseId.join(","),
      };
    }

    if (!_.isEmpty(updatedFormValues?.program)) {
      var updatedFormValues = {
        ...updatedFormValues,
        program: updatedFormValues?.program.join(","),
      };
    }
    if (!_.isEmpty(updatedFormValues?.screening)) {
      var updatedFormValues = {
        ...updatedFormValues,
        screening: updatedFormValues?.screening.join(","),
      };
    }
    if (!_.isEmpty(updatedFormValues?.status)) {
      var updatedFormValues = {
        ...updatedFormValues,
        status: updatedFormValues?.status.join(","),
      };
    }
    if (!_.isEmpty(updatedFormValues?.visitType)) {
      var updatedFormValues = {
        ...updatedFormValues,
        visitType: updatedFormValues?.visitType.join(","),
      };
    }
    if (!_.isEmpty(updatedFormValues?.subStatus)) {
      var updatedFormValues = {
        ...updatedFormValues,
        subStatus: updatedFormValues?.subStatus.join(","),
      };
    }

    if (!_.isEmpty(updatedFormValues?.qaId)) {
      var updatedFormValues = {
        ...updatedFormValues,
        qaId: updatedFormValues?.qaId.join(","),
      };
    }

    if (
      Boolean(start_date) &&
      Boolean(end_date) &&
      new Date(start_date)?.getTime() >= new Date(end_date)?.getTime()
    ) {
      setToasterOpen(true);
      setToasterSeverity(Labels.ERROR);
      setToasterMessage(
        "Schedule To Date should be greater than Schedule From Date",
      );
      reset({
        scheduleDateFrom: null,
        scheduleDateTo: null,
      });
    } else {
      alertContext.setFilterDialogData(updatedFormValues);
      alertContext.setFilterDialog(false);
    }
  }, []);

  React.useEffect(() => {
    var updatedFormValues = getValues();
    var updatedFormValues = {
      ...updatedFormValues,
      subStatus: [], //updatedFormValues?.subStatus.join(","),
      visitType: [],
      status: [],
      screening: [],
      program: [],
      nurseId: [],
      scheduleDateFrom: null,
      scheduleDateTo: null,
    };
    alertContext.setFilterDialogData(updatedFormValues);
    reset(updatedFormValues);
  }, []);

  const handleClear = React.useCallback(() => {
    reset();
    reset({
      lob: [],
      program: [],
      schedulerId: [],
      visitType: [],
      nurseId: [],
      status: [],
      screening: [],
      scheduleDateFrom: null,
      scheduleDateTo: null,
      subStatus: [],
      value: "",

      qaId: [],
      dateOfReferralForm: null,
      dateOfReferralTo: null,
      referralType: [],
      referralStatus: [],
    });
  }, []);

  const onCloseToaster = () => {
    setToasterOpen(false);
    setToasterMessage("");
  };

  React.useEffect(() => {
    if (!_.isEmpty(storedPlanId)) {
      getAllQaList();
    }
  }, [storedPlanId]);

  return (
    <div>
      <Dialog
        open={alertContext.filterDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
      >
        <DialogTitle
          sx={{
            background: "var(--mainTheme)",
            fontSize: "20px",
            color: "white",
            fontFamily: "Inter,sans-serif",
            height: "40px",
            display: "flex",
            alignItems: "center",
          }}
          id="alert-dialog-title"
        >
          Filter
        </DialogTitle>
        <DialogContent sx={{ paddingTop: "20px !important" }}>
          <Grid container spacing={2} sx={{ padding: "16px" }}>
            {selectedDropdownValue == "Referral" ? (
              <>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={4}
                  sx={{ padding: "10px" }}
                >
                  <Controller
                    name={"dateOfReferralForm"}
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DesktopDatePicker
                        label="Date of Referral From :"
                        className="inputAddMember"
                        views={["year", "month", "day"]}
                        onChange={onChange}
                        value={value}
                        renderInput={(params) => <TextField {...params} />}
                        components={{
                          ActionBar: (props) => (
                            <div
                              style={{
                                display: "flex",
                                position: "absolute",
                                justifyContent: "space-around",
                                bottom: "0",
                                width: "100%",
                                backgroundColor: "white",
                                padding: "8px",
                                borderTop: "1px solid #ddd",
                              }}
                            >
                              <Button
                                color="primary"
                                onClick={() => onChange(null)}
                              >
                                Clear
                              </Button>
                              {/* <Button
                            color="primary"
                            onClick={props.onAccept}
                          >
                            OK
                          </Button> */}
                            </div>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={4}
                  sx={{ padding: "10px" }}
                >
                  <Controller
                    name={"dateOfReferralTo"}
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DesktopDatePicker
                        label="Date of Referral To :"
                        className="inputAddMember"
                        views={["year", "month", "day"]}
                        onChange={onChange}
                        value={value}
                        renderInput={(params) => <TextField {...params} />}
                        components={{
                          ActionBar: (props) => (
                            <div
                              style={{
                                display: "flex",
                                position: "absolute",
                                justifyContent: "space-around",
                                bottom: "0",
                                width: "100%",
                                backgroundColor: "white",
                                padding: "8px",
                                borderTop: "1px solid #ddd",
                              }}
                            >
                              <Button
                                color="primary"
                                onClick={() => onChange(null)}
                              >
                                Clear
                              </Button>
                              {/* <Button
                            color="primary"
                            onClick={props.onAccept}
                          >
                            OK
                          </Button> */}
                            </div>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={4}
                  sx={{ padding: "10px" }}
                >
                  <FormControl fullWidth>
                    <InputLabel>Referral Type:</InputLabel>
                    <Controller
                      name="referralType"
                      control={control}
                      defaultValue={[]}
                      render={({ field }) => (
                        <Select
                          className="inputAddMember"
                          name="referralType"
                          {...field}
                          multiple
                          value={field.value}
                          onChange={(e) => field.onChange(e.target.value)}
                          // renderValue={(selected) =>
                          //   Array.isArray(selected)
                          //     ? selected
                          //         .map(
                          //           (id) =>
                          //             visitTypeOptionsData.find(
                          //               (option) => option.id === id,
                          //             ).visitType,
                          //         )
                          //         .join(",")
                          //     : selected
                          // }
                        >
                          {/* {visitTypeOptionsData?.map((option) => {
                        return (
                          <MenuItem key={option?.id} value={option?.id}>
                            {option?.visitType}
                          </MenuItem>
                        );
                      })} */}
                        </Select>
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={4}
                  sx={{ padding: "10px" }}
                >
                  <FormControl fullWidth>
                    <InputLabel>Referral Status:</InputLabel>
                    <Controller
                      name="referralStatus"
                      control={control}
                      defaultValue={[]}
                      render={({ field }) => (
                        <Select
                          className="inputAddMember"
                          name="referralStatus"
                          {...field}
                          multiple
                          value={field.value}
                          onChange={(e) => field.onChange(e.target.value)}
                          // renderValue={(selected) =>
                          //   Array.isArray(selected)
                          //     ? selected
                          //         .map(
                          //           (id) =>
                          //             visitTypeOptionsData.find(
                          //               (option) => option.id === id,
                          //             ).visitType,
                          //         )
                          //         .join(",")
                          //     : selected
                          // }
                        >
                          {/* {visitTypeOptionsData?.map((option) => {
                        return (
                          <MenuItem key={option?.id} value={option?.id}>
                            {option?.visitType}
                          </MenuItem>
                        );
                      })} */}
                        </Select>
                      )}
                    />
                  </FormControl>
                </Grid>

                {roleId !== "7" && (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                    sx={{ padding: "10px" }}
                  >
                    <FormControl fullWidth>
                      <InputLabel>Assigned QA:</InputLabel>
                      <Controller
                        name="qaId"
                        control={control}
                        defaultValue={[]}
                        value={watch("qaId")}
                        render={({ field }) => (
                          <Select
                            className="inputAddMember"
                            name="qaId"
                            {...field}
                            multiple
                            value={field.value}
                            onChange={(e) => field.onChange(e.target.value)}
                            renderValue={(selected) =>
                              Array.isArray(selected)
                                ? selected
                                    .map(
                                      (id) =>
                                        allQaList.find(
                                          (option) => option.userid === id,
                                        ).firstName,
                                    )
                                    .join(",")
                                : selected
                            }
                            MenuProps={{
                              PaperProps: {
                                style: {
                                  maxHeight: 250, // Adjust the max height as needed
                                },
                              },
                            }}
                          >
                            {allQaList?.map((option) => {
                              return (
                                <MenuItem
                                  key={option?.userid}
                                  value={option?.userid}
                                >
                                  {option.firstName} {option.lastName}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>
                )}
              </>
            ) : (
              <>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={4}
                  sx={{ padding: "10px" }}
                >
                  <Controller
                    name={"scheduleDateFrom"}
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DesktopDatePicker
                        label="Scheduled From:"
                        className="inputAddMember"
                        views={["year", "month", "day"]}
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={4}
                  sx={{ padding: "10px" }}
                >
                  <Controller
                    name={"scheduleDateTo"}
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DesktopDatePicker
                        label="Scheduled To:"
                        className="inputAddMember"
                        views={["year", "month", "day"]}
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={4}
                  sx={{ padding: "10px" }}
                >
                  <FormControl fullWidth>
                    <InputLabel>Program:</InputLabel>
                    <Controller
                      name="program"
                      control={control}
                      defaultValue={[]}
                      render={({ field }) => (
                        <Select
                          className="inputAddMember"
                          name="program"
                          {...field}
                          multiple
                          renderValue={(selected) => selected.join(", ")}
                        >
                          {programOptions?.map((option) => {
                            return (
                              <MenuItem key={option?.id} value={option?.name}>
                                {option?.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={4}
                  sx={{ padding: "10px" }}
                >
                  <FormControl fullWidth>
                    <InputLabel>LOB:</InputLabel>
                    <Controller
                      name="lob"
                      control={control}
                      defaultValue={[]}
                      render={({ field }) => (
                        <Select
                          className="inputAddMember"
                          name="lob"
                          {...field}
                          multiple
                          renderValue={(selected) => selected.join(", ")}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: 250, // Adjust the max height as needed
                              },
                            },
                          }}
                        >
                          {lobOptionData?.map((option) => {
                            return (
                              <MenuItem key={option} value={option}>
                                {option}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={4}
                  sx={{ padding: "10px" }}
                >
                  <FormControl fullWidth>
                    <InputLabel>Visit Type:</InputLabel>
                    <Controller
                      name="visitType"
                      control={control}
                      defaultValue={[]}
                      render={({ field }) => (
                        <Select
                          className="inputAddMember"
                          name="visitType"
                          {...field}
                          multiple
                          value={field.value}
                          onChange={(e) => field.onChange(e.target.value)}
                          renderValue={(selected) =>
                            Array.isArray(selected)
                              ? selected
                                  .map(
                                    (id) =>
                                      visitTypeOptionsData.find(
                                        (option) => option.id === id,
                                      ).visitType,
                                  )
                                  .join(",")
                              : selected
                          }
                        >
                          {visitTypeOptionsData?.map((option) => {
                            return (
                              <MenuItem key={option?.id} value={option?.id}>
                                {option?.visitType}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={4}
                  sx={{ padding: "10px" }}
                >
                  <FormControl fullWidth>
                    <InputLabel>Assigned Clinician:</InputLabel>
                    <Controller
                      name="nurseId"
                      control={control}
                      defaultValue={[]}
                      value={watch("nurseId")}
                      render={({ field }) => (
                        <Select
                          className="inputAddMember"
                          name="nurseId"
                          {...field}
                          multiple
                          value={field.value}
                          onChange={(e) => field.onChange(e.target.value)}
                          renderValue={(selected) =>
                            Array.isArray(selected)
                              ? selected
                                  .map(
                                    (id) =>
                                      allClinicianList.find(
                                        (option) => option.userid === id,
                                      ).firstName,
                                  )
                                  .join(",")
                              : selected
                          }
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: 250, // Adjust the max height as needed
                              },
                            },
                          }}
                        >
                          {allClinicianList?.map((option) => {
                            return (
                              <MenuItem
                                key={option?.userid}
                                value={option?.userid}
                              >
                                {option.firstName} {option.lastName}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={4}
                  sx={{ padding: "10px" }}
                >
                  <FormControl fullWidth>
                    <InputLabel>Sub Status:</InputLabel>
                    <Controller
                      name="subStatus"
                      control={control}
                      defaultValue={[]}
                      value={watch("substatus")}
                      render={({ field }) => (
                        <Select
                          className="inputAddMember"
                          name="subStatus"
                          {...field}
                          multiple
                          value={field.value}
                          onChange={(e) => field.onChange(e.target.value)}
                          renderValue={(selected) =>
                            Array.isArray(selected)
                              ? selected
                                  .map(
                                    (id) =>
                                      subStatusOptions.find(
                                        (option) => option.id === id,
                                      ).value,
                                  )
                                  .join(",")
                              : selected
                          }
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: 250, // Adjust the max height as needed
                              },
                            },
                          }}
                        >
                          {subStatusOptions?.map((option) => {
                            return (
                              <MenuItem key={option?.id} value={option?.id}>
                                {option?.value}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      )}
                    />
                  </FormControl>
                </Grid>
              </>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button sx={dialogBtn} onClick={handleClose}>
            Cancel
          </Button>
          <Button
            sx={dialogBtn}
            className="dialogBtn"
            onClick={handleApplyFilter}
          >
            Apply
          </Button>
          <Button
            sx={dialogBtn}
            className="dialogBtn reset-btn"
            onClick={handleClear}
          >
            Clear
          </Button>
        </DialogActions>
      </Dialog>
      <Toaster
        open={toasterOpen}
        severity={toasterSeverity}
        onClose={onCloseToaster}
        message={toasterMessage}
      />
      {isLoading && <Loader />}
    </div>
  );
}
