import React, { useState, useEffect } from "react";
//import { putApi, getApi } from "../../../../../utils/axiosApiWrapper";
import { getApi } from "../../../../utils/axiosApiWrapper";
import pedsAssessmentInfoJsonData from "./PedsFormSection/peds.assessmentInfo.json.data.";
import pedsAssessmentInfo2023JsonData from "./PedsFormSection/peds.assessmentInfo.2023json.data";

const usePedsFormAssessmentInfo = (assessmentId) => {
  const storedYear = localStorage.getItem("clientYear");
  const jsonData =
    storedYear == "2024"
      ? pedsAssessmentInfoJsonData
      : pedsAssessmentInfo2023JsonData;

  const [allergiesRowData, setAllergiesRowData] = useState(null);
  //const answeredJson = {};
  const [answeredJson, setAnsweredJson] = useState({});
  const [selectedSubQuestion, setSelectedSubQuestions] = useState({});
  const [selectedIdx, setSelectedIdx] = useState([]);
  const [selectedDescribeFirstLevel, setSelectedDescribeFirstLevel] = useState(
    {},
  );
  const [selectedIdxDescribeFirstLevel, selSelectedIdxDescribeFirstLevel] =
    useState([]);
  const [firstLevelCommentObj, setFirstLevelCommentObj] = useState({});

  const [counterMedicationsRowData, setCounterMedicationsRowData] = useState();
  const [counterMedicationsRowDataQues15, setCounterMedicationsRowDataQues15] =
    useState();
  const [isLoading, setIsLoading] = useState(true);
  const [selected2ndLevelSubQuestion, setSelected2ndLevelSubQuestion] =
    useState({});
  const [secondLevelTextFieldAnswer, setSecondLevelTextFieldAnswer] = useState(
    {},
  );
  const [secondLevelComment, setSecondLevelComment] = useState([]); // this is for respective question for which comment icon is clicked
  const [secondLevelCommentObj, setSecondLevelCommentObj] = useState({});
  //const storedYear = localStorage.getItem("clientYear");
  const updateAnsweredJson = (question, subQuestion, idx, genre) => {
    if (question?.name == "allergies" && subQuestion.value == "2") {
      setAllergiesRowData(null);
    }

    if (question?.name == "counterMedications" && subQuestion.value == "2") {
      setCounterMedicationsRowDataQues15(null);
    }

    if (genre == "multiSelect") {
      setSelectedIdx([...new Set([...selectedIdx, idx])]);

      if (answeredJson?.[idx]?.[question?.name]?.includes(subQuestion.value)) {
        const filterValue = answeredJson?.[idx]?.[question?.name]?.filter(
          (itm) => itm !== subQuestion.value,
        );
        const _answeredObj = {
          ...answeredJson?.[idx],
          [question.name]: [...filterValue],
          willContainSubAnswer:
            subQuestion?.subQuestions?.length > 0 ? true : false,
        };
        if (subQuestion.subQuestions?.length > 0) {
          delete _answeredObj?.[subQuestion.subQuestions[0]?.name];
        }
        setAnsweredJson({ ...answeredJson, [idx]: _answeredObj });

        setSelected2ndLevelSubQuestion({
          ...selected2ndLevelSubQuestion,
          [subQuestion.subQuestions[0].subId]: {},
        });

        delete selected2ndLevelSubQuestion[subQuestion.subId];

        const filterSubQuestion = selectedSubQuestion?.[idx]?.filter(
          (subQ) => subQ?.value !== subQuestion.value,
        );

        //for 2ndlevel comment
        setSecondLevelCommentObj({
          ...secondLevelCommentObj,
          [subQuestion.subQuestions[0]?.name]: "",
        });

        const filterCommentList = secondLevelComment?.filter(
          (comment) => comment !== subQuestion.subQuestions[0]?.name,
        );
        setSecondLevelComment([...filterCommentList]);

        setSelectedSubQuestions({
          ...selectedSubQuestion,
          [idx]: [...filterSubQuestion],
        });
        update2ndLevelAnsweredJsonForTextField(
          selected2ndLevelSubQuestion[subQuestion.subQuestions[0].subId]
            .subQuestions[0],
          undefined,
          "",
        );
      } else {
        const _answeredObj = {
          ...answeredJson?.[idx],
          ...answeredJson?.[idx],
          [question.name]:
            answeredJson?.[idx]?.[question?.name]?.length >= 1
              ? [...answeredJson?.[idx]?.[question?.name], subQuestion.value]
              : [subQuestion.value],
          willContainSubAnswer:
            subQuestion?.subQuestions?.length > 0 ? true : false,
        };
        setAnsweredJson({ ...answeredJson, [idx]: _answeredObj });
        setSelectedSubQuestions({
          ...selectedSubQuestion,
          [idx]:
            selectedSubQuestion?.[idx]?.length >= 1
              ? [...selectedSubQuestion?.[idx], subQuestion]
              : [subQuestion],
        });
      }
    } else {
      if (
        selectedSubQuestion[idx]?.subQuestionId != subQuestion?.subQuestionId
      ) {
        setSelectedSubQuestions({ ...selectedSubQuestion, [idx]: subQuestion });
        // setSelectedIdx([...selectedIdx, idx]);
        setSelectedIdx([...new Set([...selectedIdx, idx])]);
        const _answeredObj = {
          [question.name]: subQuestion.value,
          willContainSubAnswer:
            subQuestion?.subQuestions?.length > 0 ? true : false,
        };
        setAnsweredJson({ ...answeredJson, [idx]: _answeredObj });
      } else {
        setSelectedSubQuestions({ ...selectedSubQuestion, [idx]: {} });
        //setSelectedIdx([...selectedIdx, idx]);
        const filteredIdx = selectedIdx?.filter((_idx) => _idx != idx);
        setSelectedIdx([...filteredIdx]);
        const _answeredObj = {
          //   [question.name]: subQuestion.value,
          //   willContainSubAnswer:
          //     subQuestion?.subQuestions?.length > 0 ? true : false,
        };
        setAnsweredJson({ ...answeredJson, [idx]: _answeredObj });
      }
    }
  };

  const onFirstLevelCommentIconClick = (idx) => {
    if (!selectedDescribeFirstLevel[idx]) {
      setSelectedDescribeFirstLevel({
        ...selectedDescribeFirstLevel,
        [idx]: true,
      });
      selSelectedIdxDescribeFirstLevel([...selectedIdxDescribeFirstLevel, idx]);
    } else {
      setSelectedDescribeFirstLevel({
        ...selectedDescribeFirstLevel,
        [idx]: false,
      });
      const _selectedIdxDescribeFirstLevel =
        selectedIdxDescribeFirstLevel?.filter((id) => id != idx);
      selSelectedIdxDescribeFirstLevel([..._selectedIdxDescribeFirstLevel]);
      setFirstLevelCommentObj({ ...firstLevelCommentObj, [idx]: "" });
    }
  };
  const createFirstLevelCommentObj = (idx, comment) => {
    setFirstLevelCommentObj({ ...firstLevelCommentObj, [idx]: comment });
  };
  let _firstLevelCommentObj = {};
  let _selectedDescribeFirstLevel = {};
  let _selectedIdxDescribeFirstLevel = [];

  const _createFirstLevelCommentObj = async (idx, comment) => {
    const obj = { [idx]: comment };
    _firstLevelCommentObj = { ..._firstLevelCommentObj, [idx]: comment };
    // setFirstLevelCommentObj({
    //   ...firstLevelCommentObj,
    //   ..._firstLevelCommentObj,
    // });
    await setLocalStorageAsync(
      "firstLevelCommentObj",
      JSON.stringify({ ...firstLevelCommentObj, ..._firstLevelCommentObj }),
    );
  };

  const onSecondLevelCommentIconClick = (questionName) => {
    if (secondLevelComment.includes(questionName)) {
      const filterCommentList = secondLevelComment?.filter(
        (comment) => comment !== questionName,
      );
      setSecondLevelComment([...filterCommentList]);
      setSecondLevelCommentObj({
        ...secondLevelCommentObj,
        [questionName]: "",
      });
    } else {
      setSecondLevelComment([...secondLevelComment, questionName]);
    }
  };
  const createSecondLevelCommentObj = (questionName, inputValue) => {
    setSecondLevelCommentObj({
      ...secondLevelCommentObj,
      [questionName]: inputValue,
    });
  };
  let _secondLevelComment = [];
  let _secondLevelCommentObj = {};

  const _createSecondLevelCommentObj = async (questionName, inputValue) => {
    _secondLevelCommentObj = {
      ..._secondLevelCommentObj,
      [questionName]: inputValue,
    };
    // setSecondLevelCommentObj({
    //   // try to remove this set state from here too
    //   ...secondLevelCommentObj,
    //   ..._secondLevelCommentObj,
    // });

    await setLocalStorageAsync(
      "secondLevelCommentObj",
      JSON.stringify({ ...secondLevelCommentObj, ..._secondLevelCommentObj }),
    );
  };

  let _answeredJson = {};
  let _selectedSubQuestion = {};
  let _selectedIdx = [];
  const _updateAnsweredJson = async (
    question,
    subQuestion,
    idx,
    genre,
    answerObj,
  ) => {
    if (Boolean(subQuestion)) {
      if (genre == "multiSelect") {
        // setSelectedIdx([...selectedIdx, idx]);
        _selectedIdx = [..._selectedIdx, idx];
        if (
          answeredJson?.[idx]?.[question?.name].includes(subQuestion.value) &&
          false
        ) {
          const filterValue = answeredJson?.[idx]?.[question?.name]?.filter(
            (itm) => itm !== subQuestion.value,
          );
          const _answeredObj = {
            [question.name]: [...filterValue],
            willContainSubAnswer:
              subQuestion?.subQuestions?.length > 0 ? true : false,
          };
          // setAnsweredJson({ ...answeredJson, [idx]: _answeredObj });

          const filterSubQuestion = selectedSubQuestion?.[idx]?.filter(
            (subQ) => subQ?.value !== subQuestion.value,
          );

          setSelectedSubQuestions({
            ...selectedSubQuestion,
            [idx]: [...filterSubQuestion],
          });
        } else {
          const _answeredObj = {
            [question.name]:
              _answeredJson?.[idx]?.[question?.name]?.length >= 1
                ? [..._answeredJson?.[idx]?.[question?.name], subQuestion.value]
                : [subQuestion.value],
            willContainSubAnswer:
              subQuestion?.subQuestions?.length > 0 ? true : false,
          };
          _answeredJson = { ..._answeredJson, [idx]: _answeredObj };
          _selectedIdx = [..._selectedIdx, idx];
          //setAnsweredJson({ ...answeredJson, ..._answeredJson });

          _selectedSubQuestion = {
            ..._selectedSubQuestion,
            [idx]:
              _selectedSubQuestion?.[idx]?.length >= 1
                ? [..._selectedSubQuestion?.[idx], subQuestion]
                : [subQuestion],
          };

          await setLocalStorageAsync(
            "answeredJson",
            JSON.stringify({ ...answeredJson, ..._answeredJson }),
          );
          await setLocalStorageAsync(
            "selectedSubQuestion",
            JSON.stringify({ ...selectedSubQuestion, ..._selectedSubQuestion }),
          );
          await setLocalStorageAsync(
            "selectedIdx",
            JSON.stringify({ ...selectedIdx, ..._selectedIdx }),
          );
          // setSelectedSubQuestions({
          //   ...selectedSubQuestion,
          //   ..._selectedSubQuestion,
          // });
          // setSelectedIdx([...selectedIdx, ..._selectedIdx]);
        }
      } else {
        _selectedSubQuestion = { ..._selectedSubQuestion, [idx]: subQuestion };
        _selectedIdx = [..._selectedIdx, idx];
        const _answeredObj = {
          [question.name]: subQuestion.value,
          willContainSubAnswer:
            subQuestion?.subQuestions?.length > 0 ? true : false,
        };
        _answeredJson = { ..._answeredJson, [idx]: _answeredObj };

        _selectedSubQuestion = { ..._selectedSubQuestion, [idx]: subQuestion };
        _selectedIdx = [..._selectedIdx, idx];
        // setAnsweredJson({ ...answeredJson, ..._answeredJson });
        // setSelectedSubQuestions({
        //   ...selectedSubQuestion,
        //   ..._selectedSubQuestion,
        // });
        // setSelectedIdx([...selectedIdx, ..._selectedIdx]);
        await setLocalStorageAsync(
          "answeredJson",
          JSON.stringify({ ...answeredJson, ..._answeredJson }),
        );
        await setLocalStorageAsync(
          "selectedSubQuestion",
          JSON.stringify({ ...selectedSubQuestion, ..._selectedSubQuestion }),
        );
        await setLocalStorageAsync(
          "selectedIdx",
          JSON.stringify([...selectedIdx, ..._selectedIdx]),
        );
      }
    }
    return true;
  };
  const updateSubAnswerForToggleButton = (question, subQuestion, idx) => {
    // if (!answeredJson[idx]?.[subQuestion.name] ||  answeredJson[idx]?.[subQuestion.name] != question.value ) {
    if (answeredJson[idx]?.[subQuestion.name] != question.value) {
      const _subAnswerObj = {
        ...answeredJson[idx],
        [subQuestion.name]: question.value,
      };
      answeredJson[idx] = _subAnswerObj;
      setAnsweredJson({ ...answeredJson, [idx]: _subAnswerObj });
      if (question.subQuestions?.length > 0) {
        setSelected2ndLevelSubQuestion({
          ...selected2ndLevelSubQuestion,
          [subQuestion.subId]: question,
        });
      } else {
        update2ndLevelAnsweredJsonForTextField(
          selected2ndLevelSubQuestion[subQuestion.subId].subQuestions[0],
          undefined,
          "",
        );
        delete selected2ndLevelSubQuestion[subQuestion.subId];
      }
    } else {
      const _subAnswerObj = {
        ...answeredJson[idx],
        [subQuestion.name]: "",
      };
      answeredJson[idx] = _subAnswerObj;
      setAnsweredJson({ ...answeredJson, [idx]: _subAnswerObj });

      if (question.subQuestions?.length > 0) {
        setSelected2ndLevelSubQuestion({
          ...selected2ndLevelSubQuestion,
          [subQuestion.subId]: {},
        });
      } else {
        update2ndLevelAnsweredJsonForTextField(
          selected2ndLevelSubQuestion[subQuestion.subId].subQuestions[0],
          undefined,
          "",
        );
        delete selected2ndLevelSubQuestion[subQuestion.subId];
      }
    }
    //setSelectedSubQuestions({ ...selectedSubQuestion, [idx]: subQuestion });
  };

  const setLocalStorageAsync = (key, value) => {
    return new Promise((resolve, reject) => {
      try {
        localStorage.setItem(key, value);
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  };

  let _selected2ndLevelSubQuestion = {};
  const _updateSubAnswerForToggleButton = async (
    question,
    subQuestion,
    idx,
  ) => {
    const _subAnswerObj = {
      ..._answeredJson[idx],
      [subQuestion.name]: question.value,
    };
    _answeredJson[idx] = _subAnswerObj;
    _answeredJson = { ..._answeredJson, [idx]: _subAnswerObj };
    // setAnsweredJson({ ..._answeredJson, [idx]: _subAnswerObj }); // try to remove this setState  from here also
    await setLocalStorageAsync(
      "answeredJson",
      JSON.stringify({ ..._answeredJson, [idx]: _subAnswerObj }),
    );
    if (question.subQuestions?.length > 0) {
      _selected2ndLevelSubQuestion = {
        ..._selected2ndLevelSubQuestion,
        [subQuestion.subId]: question,
      };

      // setSelected2ndLevelSubQuestion({
      //   // try to remove this setState  from here also
      //   ...selected2ndLevelSubQuestion,
      //   ..._selected2ndLevelSubQuestion,
      // });
      await setLocalStorageAsync(
        "selected2ndLevelSubQuestion",
        JSON.stringify({
          ...selected2ndLevelSubQuestion,
          ..._selected2ndLevelSubQuestion,
        }),
      );
    } else {
      delete selected2ndLevelSubQuestion[subQuestion.subId];
    }
  };
  const updateSubAnswerForTextField = (subQuestion, idx, inputValue) => {
    const _subAnswerObj = {
      ...answeredJson[idx],
      [subQuestion.name]: inputValue,
    };
    answeredJson[idx] = _subAnswerObj;
    setAnsweredJson({ ...answeredJson, [idx]: _subAnswerObj });
  };
  const _updateSubAnswerForTextField = async (subQuestion, idx, inputValue) => {
    const _subAnswerObj = {
      ..._answeredJson[idx],
      [subQuestion.name]: inputValue,
    };
    _answeredJson[idx] = _subAnswerObj;
    await setLocalStorageAsync(
      "answeredJson",
      JSON.stringify({ ..._answeredJson, [idx]: _subAnswerObj }),
    );
    // setAnsweredJson({ ..._answeredJson, [idx]: _subAnswerObj });
  };
  const updateAnsweredJsonForTextField = (question, idx, inputvalue) => {
    const obj = {
      [question.name]: inputvalue,
    };
    setAnsweredJson({ ...answeredJson, [idx]: obj });
  };
  const _updateAnsweredJsonForTextField = async (question, idx, inputValue) => {
    const obj = {
      [question.name]: inputValue,
    };
    _answeredJson = { ..._answeredJson, [idx]: obj };
    await setLocalStorageAsync(
      "answeredJson",
      JSON.stringify({ ...answeredJson, ..._answeredJson }),
    );
    // setAnsweredJson({ ...answeredJson, ..._answeredJson });
  };

  const update2ndLevelAnsweredJsonForTextField = (
    question,
    idx,
    inputValue,
  ) => {
    setSecondLevelTextFieldAnswer({
      ...secondLevelTextFieldAnswer,
      [question.name]: inputValue,
    });
  };

  let _secondLevelTextFieldAnswer = {};
  const _update2ndLevelAnsweredJsonForTextField = async (
    question,
    idx,
    inputValue,
  ) => {
    _secondLevelTextFieldAnswer = {
      ..._secondLevelTextFieldAnswer,
      [question.name]: inputValue,
    };
    // setSecondLevelTextFieldAnswer({
    //   //try to remove this setState from this place
    //   ...secondLevelTextFieldAnswer,
    //   ..._secondLevelTextFieldAnswer,
    // });

    await setLocalStorageAsync(
      "secondLevelTextFieldAnswer",
      JSON.stringify({
        ...secondLevelTextFieldAnswer,
        ..._secondLevelTextFieldAnswer,
      }),
    );
  };

  const mapedSavedData = async (question, answerObj) => {
    if (answerObj.comment?.trim().length > 0) {
      // setSelectedDescribeFirstLevel({
      //   ...selectedDescribeFirstLevel,
      //   [question.questionId - 1]: true,
      // });
      // selSelectedIdxDescribeFirstLevel([
      //   ...selectedIdxDescribeFirstLevel,
      //   question.questionId - 1,
      // ]);
      _selectedDescribeFirstLevel = {
        ..._selectedDescribeFirstLevel,
        [question.questionId]: true,
      };
      _selectedIdxDescribeFirstLevel = [
        ..._selectedIdxDescribeFirstLevel,
        question.questionId,
      ];
      await setLocalStorageAsync(
        "selectedDescribeFirstLevel",
        JSON.stringify({
          ..._selectedDescribeFirstLevel,
          [question.questionId]: true,
        }),
      );

      await setLocalStorageAsync(
        "selectedIdxDescribeFirstLevel",
        JSON.stringify([
          ..._selectedIdxDescribeFirstLevel,
          question.questionId,
        ]),
      );
      // setSelectedDescribeFirstLevel({   //selectedDescribeFirstLevel selectedIdxDescribeFirstLevel
      //   ...selectedDescribeFirstLevel,
      //   ..._selectedDescribeFirstLevel,
      // });
      // selSelectedIdxDescribeFirstLevel([
      //   ...selectedIdxDescribeFirstLevel,
      //   ..._selectedIdxDescribeFirstLevel,
      // ]);

      await _createFirstLevelCommentObj(question.questionId, answerObj.comment);
    }
    if (question.questionId == 1) {
      const subQuestion = question?.subQuestions?.filter(
        (subQ) => subQ?.value == answerObj.legalGuradianPermission,
      );
      await _updateAnsweredJson(question, subQuestion[0], question.questionId);
    } else if (question.questionId == 2) {
      const subQuestion = question?.subQuestions?.filter(
        (subQ) => subQ?.value == answerObj.authorizedLegalRepresentative,
      );
      await _updateAnsweredJson(question, subQuestion[0], question.questionId);
    } else if (question.questionId == 3) {
      const subQuestion = question?.subQuestions?.filter(
        (subQ) => subQ?.value == answerObj.fosterCareProgram,
      );
      await _updateAnsweredJson(question, subQuestion[0], question.questionId);
    } else if (question.questionId == 4) {
      if (answerObj.other == "1") {
        const subQuestion = question?.subQuestions?.filter(
          (subQ) => subQ?.value == "other",
        );
        await _updateAnsweredJson(
          question,
          subQuestion[0],
          question.questionId,
        );
        const selectedSubQuestion = subQuestion[0].subQuestions[0];
        const selectedSubValue = Object.keys(answerObj.otherSubAnswer)?.filter(
          (key) => answerObj.otherSubAnswer[key] == "1",
        );
        const selectedOptions = selectedSubQuestion?.subQuestions?.filter(
          (itm) => itm.value == selectedSubValue[0],
        );

        _updateSubAnswerForToggleButton(
          selectedOptions[0],
          selectedSubQuestion,
          question.questionId,
        );
      } else if (answerObj.english == "1") {
        const subQuestion = question?.subQuestions?.filter(
          (subQ) => subQ?.value == "english",
        );
        await _updateAnsweredJson(
          question,
          subQuestion[0],
          question.questionId,
        );
      }
    } else if (question.questionId == 5) {
      const selectedValue = Object.keys(answerObj).filter(
        (key) => answerObj[key] == "1",
      );
      const subQuestion = question?.subQuestions?.filter(
        (subQ) => subQ?.value == selectedValue[0], //answerObj.race nativeHawaiian
      );
      await _updateAnsweredJson(question, subQuestion[0], question.questionId);
    } else if (question.questionId == 6) {
      const subQuestion = question?.subQuestions?.filter(
        (subQ) => subQ?.value == answerObj.childAllergies,
      );
      await _updateAnsweredJson(question, subQuestion[0], question.questionId);
      const dataArray = answerObj?.childAllergiesYesSubAnswer?.map(
        (allergies) => {
          return {
            substance: allergies?.substance,
            reaction: allergies?.reaction,
            id: Date.now().toString(),
          };
        },
      );
      // childAllergiesArray = [...dataArray];
      // localStorage.setItem('childAllergiesArray',JSON.stringify(dataArray));
      // setAllergiesRowData(dataArray);allergiesRowData
      setLocalStorageAsync("allergiesRowData", JSON.stringify(dataArray));
    } else if (question.questionId == 7) {
      if (answerObj.childWitnessedEvent == "1") {
        const subQuestion = question?.subQuestions?.filter(
          (subQ) => subQ?.value == "1",
        );
        await _updateAnsweredJson(
          question,
          subQuestion[0],
          question.questionId,
        );
        const selectedSubQuestionToggleBtn = subQuestion[0].subQuestions[0];
        const selectedSubQuestionDescribe = subQuestion[0].subQuestions[1];
        const selectedSubValue = Object.keys(
          answerObj.childWitnessedEventYesSubAnswer.event,
        )?.filter(
          (key) => answerObj.childWitnessedEventYesSubAnswer.event[key] == "1",
        );
        const selectedOptions =
          selectedSubQuestionToggleBtn?.subQuestions?.filter(
            (itm) => itm.value == selectedSubValue[0],
          );
        _updateSubAnswerForToggleButton(
          selectedOptions[0],
          selectedSubQuestionToggleBtn,
          question.questionId,
        );
        // updateSubAnswerForTextField(
        //   subQuestion,
        //   idx,
        //   e?.target?.value
        // );
        _updateSubAnswerForTextField(
          selectedSubQuestionDescribe,
          question.questionId,
          answerObj.childWitnessedEventYesSubAnswer.describe,
        );

        if (
          answerObj?.childWitnessedEventYesSubAnswer?.event?.comment?.trim()
            .length > 0
        ) {
          _secondLevelComment = [..._secondLevelComment, "Whatwastheevent"];
          // setSecondLevelComment([
          //   ...secondLevelComment,
          //   ..._secondLevelComment,
          // ]);
          _secondLevelCommentObj = {
            ..._secondLevelCommentObj,
            Whatwastheevent:
              answerObj?.childWitnessedEventYesSubAnswer?.event?.comment,
          };
          // setSecondLevelCommentObj({
          //   ...secondLevelCommentObj,
          //   ..._secondLevelCommentObj,
          // });
          await setLocalStorageAsync(
            "secondLevelComment",
            JSON.stringify([...secondLevelComment, ..._secondLevelComment]),
          );
          await setLocalStorageAsync(
            "secondLevelCommentObj",
            JSON.stringify({
              ...secondLevelCommentObj,
              ..._secondLevelCommentObj,
            }),
          );
        }
      } else if (answerObj.childWitnessedEvent == "2") {
        const subQuestion = question?.subQuestions?.filter(
          (subQ) => subQ?.value == "2",
        );
        await _updateAnsweredJson(
          question,
          subQuestion[0],
          question.questionId,
        );
      }
      // setIsLoading(false);
    } else if (question.questionId == 8) {
      _updateAnsweredJsonForTextField(
        question,
        question?.questionId,
        answerObj?.specialAge,
      );
    } else if (
      question.questionId == 9 ||
      question.questionId == 10 ||
      question.questionId == 11 ||
      question.questionId == 12
    ) {
      const selectedValue = Object.keys(answerObj).filter(
        (key) => answerObj[key] == "1",
      );
      const subQuestion = question?.subQuestions?.filter(
        (subQ) => subQ?.value == selectedValue[0], //answerObj.race
      );
      await _updateAnsweredJson(question, subQuestion[0], question.questionId);
    } else if (question.questionId == 13) {
      if (answerObj.childMedicinePrescribed == "1") {
        const subQuestion = question?.subQuestions?.filter(
          (subQ) => subQ?.value == "1",
        );
        await _updateAnsweredJson(
          question,
          subQuestion[0],
          question.questionId,
        );
        const toggleSubQuestion = subQuestion[0]?.subQuestions?.filter(
          (subQ) => subQ.value == "condition_that_has_lasted",
        );
        const medicalBehavoiralSubQuestion =
          subQuestion[0]?.subQuestions?.filter(
            (subQ) => subQ.name == "any_medical_behavioral_health_condition",
          );
        const explainText = subQuestion[0]?.subQuestions?.filter(
          (subQ) => subQ.name == "Explain",
        );
        const explainValue =
          answerObj.childMedicinePrescribedYesSubAnswer?.explain;
        const medicalBehavoirValue =
          answerObj.childMedicinePrescribedYesSubAnswer?.healthCondition;
        if (
          answerObj?.childMedicinePrescribedYesSubAnswer?.atleastTwelveMonths
            ?.expectedAtleastTwelveMonths == "1"
        ) {
          await _updateSubAnswerForToggleButton(
            toggleSubQuestion[0].subQuestions[0],
            toggleSubQuestion[0],
            question.questionId,
          );
        } else if (
          answerObj?.childMedicinePrescribedYesSubAnswer?.atleastTwelveMonths
            ?.expectedAtleastTwelveMonths == "2"
        ) {
          await _updateSubAnswerForToggleButton(
            toggleSubQuestion[0].subQuestions[1], //selectedOptions
            toggleSubQuestion[0], //parent question
            question.questionId,
          );
        }
        if (
          answerObj?.childMedicinePrescribedYesSubAnswer?.atleastTwelveMonths?.comment.trim()
            .length > 0
        ) {
          // onSecondLevelCommentIconClick(
          //   "medicine_prescribed_condition_that_has_lasted",
          // );
          _secondLevelComment = [
            ..._secondLevelComment,
            "medicine_prescribed_condition_that_has_lasted",
          ];
          // setSecondLevelComment([
          //   ...secondLevelComment,
          //   ..._secondLevelComment,
          // ]);

          _secondLevelCommentObj = {
            ..._secondLevelCommentObj,
            medicine_prescribed_condition_that_has_lasted:
              answerObj?.childMedicinePrescribedYesSubAnswer
                ?.atleastTwelveMonths?.comment,
          };
          // setSecondLevelCommentObj({
          //   ...secondLevelCommentObj,
          //   ..._secondLevelCommentObj,
          // });
        }
        await _updateSubAnswerForTextField(
          explainText[0],
          question.questionId,
          explainValue,
        );
        await _updateSubAnswerForTextField(
          medicalBehavoiralSubQuestion[0],
          question.questionId,
          medicalBehavoirValue,
        );
      } else if (answerObj.childMedicinePrescribed == "2") {
        const subQuestion = question?.subQuestions?.filter(
          (subQ) => subQ?.value == "2",
        );
        await _updateAnsweredJson(
          question,
          subQuestion[0],
          question.questionId,
        );
      }
    } else if (question.questionId == 14) {
      const dataArray = answerObj?.prescriptionMedicationList?.map(
        (medication) => {
          return {
            description: medication?.prescription,
            doseOrUnits: medication?.doseUnits,
            route: medication?.route,
            frequency: medication?.frequency,
            status: medication?.status,
            reasons: medication?.reason,
            id: Date.now().toString(),
          };
        },
      );
      // setCounterMedicationsRowData(dataArray);
      setLocalStorageAsync(
        "counterMedicationRowData",
        JSON.stringify(dataArray),
      );
    } else if (question.questionId == 15) {
      if (answerObj.supplements == "1") {
        const subQuestion = question?.subQuestions?.filter(
          (subQ) => subQ?.value == "1",
        );
        await _updateAnsweredJson(
          question,
          subQuestion[0],
          question.questionId,
        );
        const dataArray = answerObj?.supplementsYesSubAnswer?.map(
          (medication) => {
            return {
              description: medication?.prescription,
              doseOrUnits: medication?.doseUnits,
              route: medication?.route,
              frequency: medication?.frequency,
              status: medication?.status,
              reasons: medication?.reason,
              id: Date.now().toString(),
            };
          },
        );
        setLocalStorageAsync(
          "counterMedicationsRowDataQues15",
          JSON.stringify(dataArray),
        );
        //setCounterMedicationsRowDataQues15(dataArray);
      } else if (answerObj.supplements == "2") {
        const subQuestion = question?.subQuestions?.filter(
          (subQ) => subQ?.value == "2",
        );
        await _updateAnsweredJson(
          question,
          subQuestion[0],
          question.questionId,
        );
      }
    } else if (question.questionId == 16) {
      if (answerObj.childMedicalCare == "1") {
        const subQuestion = question?.subQuestions?.filter(
          (subQ) => subQ?.value == "1",
        );
        await _updateAnsweredJson(
          question,
          subQuestion[0],
          question.questionId,
        );
        const toggleSubQuestion = subQuestion[0]?.subQuestions?.filter(
          (subQ) => subQ.value == "condition_that_has_lasted",
        );
        const medicalBehavoiralSubQuestion =
          subQuestion[0]?.subQuestions?.filter(
            (subQ) => subQ.name == "any_medical_behavioral_health_condition",
          );
        const explainText = subQuestion[0]?.subQuestions?.filter(
          (subQ) => subQ.name == "Explain",
        );
        const explainValue = answerObj.childMedicalCareYesSubAnswer?.explain;
        const medicalBehavoirValue =
          answerObj.childMedicalCareYesSubAnswer?.healthCondition;
        if (
          answerObj?.childMedicalCareYesSubAnswer?.atleastTwelveMonths
            ?.expectedAtleastTwelveMonths == "1"
        ) {
          await _updateSubAnswerForToggleButton(
            toggleSubQuestion[0].subQuestions[0],
            toggleSubQuestion[0],
            question.questionId,
          );
        } else if (
          answerObj?.childMedicalCareYesSubAnswer?.atleastTwelveMonths
            ?.expectedAtleastTwelveMonths == "2"
        ) {
          await _updateSubAnswerForToggleButton(
            toggleSubQuestion[0].subQuestions[1], //selectedOptions
            toggleSubQuestion[0], //parent question
            question.questionId,
          );
        }
        //
        await _updateSubAnswerForTextField(
          explainText[0],
          question.questionId,
          explainValue,
        );
        await _updateSubAnswerForTextField(
          medicalBehavoiralSubQuestion[0],
          question.questionId,
          medicalBehavoirValue,
        );
        if (
          answerObj?.childMedicalCareYesSubAnswer?.atleastTwelveMonths?.comment.trim()
            .length > 0
        ) {
          _secondLevelComment = [
            ..._secondLevelComment,
            "medical_care_condition_that_has_lasted",
          ];
          // setSecondLevelComment([
          //   ...secondLevelComment,
          //   ..._secondLevelComment,
          // ]);

          await setLocalStorageAsync(
            "secondLevelComment",
            JSON.stringify([...secondLevelComment, ..._secondLevelComment]),
          );

          _secondLevelCommentObj = {
            ..._secondLevelCommentObj,
            medical_care_condition_that_has_lasted:
              answerObj?.childMedicalCareYesSubAnswer?.atleastTwelveMonths
                ?.comment,
          };
          await setLocalStorageAsync(
            "secondLevelComment",
            JSON.stringify([...secondLevelComment, ..._secondLevelComment]),
          );
          await setLocalStorageAsync(
            "secondLevelCommentObj",
            JSON.stringify([
              ...secondLevelCommentObj,
              ..._secondLevelCommentObj,
            ]),
          );
          // setSecondLevelCommentObj({
          //   ...secondLevelCommentObj,
          //   ..._secondLevelCommentObj,
          // });secondLevelCommentObj
        }
      } else if (answerObj.childMedicalCare == "2") {
        const subQuestion = question?.subQuestions?.filter(
          (subQ) => subQ?.value == "2",
        );
        await _updateAnsweredJson(
          question,
          subQuestion[0],
          question.questionId,
        );
      }
    } else if (question.questionId == 17) {
      if (answerObj.childAbilityLimited == "1") {
        const subQuestion = question?.subQuestions?.filter(
          (subQ) => subQ?.value == "1",
        );
        await _updateAnsweredJson(
          question,
          subQuestion[0],
          question.questionId,
        );
        const toggleSubQuestion = subQuestion[0]?.subQuestions?.filter(
          (subQ) => subQ.value == "condition_that_has_lasted",
        );
        const medicalBehavoiralSubQuestion =
          subQuestion[0]?.subQuestions?.filter(
            (subQ) => subQ.name == "any_medical_behavioral_health_condition",
          );
        const explainText = subQuestion[0]?.subQuestions?.filter(
          (subQ) => subQ.name == "Explain",
        );
        const explainValue = answerObj.childAbilityLimitedYesSubAnswer?.explain;
        const medicalBehavoirValue =
          answerObj.childAbilityLimitedYesSubAnswer?.healthCondition;
        if (
          answerObj?.childAbilityLimitedYesSubAnswer?.atleastTwelveMonths
            ?.expectedAtleastTwelveMonths == "1"
        ) {
          await _updateSubAnswerForToggleButton(
            toggleSubQuestion[0].subQuestions[0],
            toggleSubQuestion[0],
            question.questionId,
          );
          await _updateSubAnswerForTextField(
            explainText[0],
            question.questionId,
            explainValue,
          );
          await _updateSubAnswerForTextField(
            medicalBehavoiralSubQuestion[0],
            question.questionId,
            medicalBehavoirValue,
          );
        } else if (
          answerObj?.childAbilityLimitedYesSubAnswer?.atleastTwelveMonths
            ?.expectedAtleastTwelveMonths == "2"
        ) {
          await _updateSubAnswerForToggleButton(
            toggleSubQuestion[0].subQuestions[1], //selectedOptions
            toggleSubQuestion[0], //parent question
            question.questionId,
          );
        }
        //
        await _updateSubAnswerForTextField(
          explainText[0],
          question.questionId,
          explainValue,
        );
        await _updateSubAnswerForTextField(
          medicalBehavoiralSubQuestion[0],
          question.questionId,
          medicalBehavoirValue,
        );
        if (
          answerObj?.childAbilityLimitedYesSubAnswer?.atleastTwelveMonths?.comment.trim()
            .length > 0
        ) {
          _secondLevelComment = [
            ..._secondLevelComment,
            "childAbility_condition_that_has_lasted",
          ];
          // setSecondLevelComment([
          //   ...secondLevelComment,
          //   ..._secondLevelComment,
          // ]);

          _secondLevelCommentObj = {
            ..._secondLevelCommentObj,
            childAbility_condition_that_has_lasted:
              answerObj?.childAbilityLimitedYesSubAnswer?.atleastTwelveMonths
                ?.comment,
          };

          await setLocalStorageAsync(
            "secondLevelComment",
            JSON.stringify([...secondLevelComment, ..._secondLevelComment]),
          );
          await setLocalStorageAsync(
            "secondLevelCommentObj",
            JSON.stringify([
              ...secondLevelCommentObj,
              ..._secondLevelCommentObj,
            ]),
          );
          // setSecondLevelCommentObj({
          //   ...secondLevelCommentObj,
          //   ..._secondLevelCommentObj,
          // });
        }
      } else if (answerObj.childAbilityLimited == "2") {
        const subQuestion = question?.subQuestions?.filter(
          (subQ) => subQ?.value == "2",
        );
        await _updateAnsweredJson(
          question,
          subQuestion[0],
          question.questionId,
        );
      }
    } else if (question.questionId == 18) {
      if (answerObj.childNeedTherapy == "1") {
        const subQuestion = question?.subQuestions?.filter(
          (subQ) => subQ?.value == "1",
        );
        await _updateAnsweredJson(
          question,
          subQuestion[0],
          question.questionId,
        );
        const toggleSubQuestion = subQuestion[0]?.subQuestions?.filter(
          (subQ) => subQ.value == "condition_that_has_lasted",
        );
        const medicalBehavoiralSubQuestion =
          subQuestion[0]?.subQuestions?.filter(
            (subQ) => subQ.name == "any_medical_behavioral_health_condition",
          );

        const medicalBehavoirValue =
          answerObj.childNeedTherapyYesSubAnswer?.healthCondition;
        if (
          answerObj?.childNeedTherapyYesSubAnswer?.atleastTwelveMonths
            ?.expectedAtleastTwelveMonths == "1"
        ) {
          await _updateSubAnswerForToggleButton(
            toggleSubQuestion[0].subQuestions[0],
            toggleSubQuestion[0],
            question.questionId,
          );
        } else if (
          answerObj?.childNeedTherapyYesSubAnswer?.atleastTwelveMonths
            ?.expectedAtleastTwelveMonths == "2"
        ) {
          await _updateSubAnswerForToggleButton(
            toggleSubQuestion[0].subQuestions[1], //selectedOptions
            toggleSubQuestion[0], //parent question
            question.questionId,
          );
        }
        await _updateSubAnswerForTextField(
          medicalBehavoiralSubQuestion[0],
          question.questionId,
          medicalBehavoirValue,
        );
        if (
          answerObj?.childNeedTherapyYesSubAnswer?.atleastTwelveMonths?.comment.trim()
            .length > 0
        ) {
          _secondLevelComment = [
            ..._secondLevelComment,
            "childNeed_condition_that_has_lasted",
          ];
          // setSecondLevelComment([
          //   ...secondLevelComment,
          //   ..._secondLevelComment,
          // ]);

          _secondLevelCommentObj = {
            ..._secondLevelCommentObj,
            childNeed_condition_that_has_lasted:
              answerObj?.childNeedTherapyYesSubAnswer?.atleastTwelveMonths
                ?.comment,
          };
          // setSecondLevelCommentObj({
          //   ...secondLevelCommentObj,
          //   ..._secondLevelCommentObj,
          // });
          await setLocalStorageAsync(
            "secondLevelComment",
            JSON.stringify([...secondLevelComment, ..._secondLevelComment]),
          );
          await setLocalStorageAsync(
            "secondLevelCommentObj",
            JSON.stringify([
              ...secondLevelCommentObj,
              ..._secondLevelCommentObj,
            ]),
          );
        }
      } else if (answerObj.childNeedTherapy == "2") {
        const subQuestion = question?.subQuestions?.filter(
          (subQ) => subQ?.value == "2",
        );
        await _updateAnsweredJson(
          question,
          subQuestion[0],
          question.questionId,
        );
      }
    } else if (question.questionId == 19) {
      if (answerObj.childDevelopmentCounseling == "1") {
        const subQuestion = question?.subQuestions?.filter(
          (subQ) => subQ?.value == "1",
        );
        await _updateAnsweredJson(
          question,
          subQuestion[0],
          question.questionId,
        );
        const medicalBehavoiralSubQuestion =
          subQuestion[0]?.subQuestions?.filter(
            (subQ) => subQ.name == "any_medical_behavioral_health_condition",
          );

        const medicalBehavoirValue =
          answerObj.childDevelopmentCounselingYesSubAnswer?.healthCondition;
        await _updateSubAnswerForTextField(
          medicalBehavoiralSubQuestion[0],
          question.questionId,
          medicalBehavoirValue,
        );
      } else if (answerObj.childDevelopmentCounseling == "2") {
        const subQuestion = question?.subQuestions?.filter(
          (subQ) => subQ?.value == "2",
        );
        await _updateAnsweredJson(
          question,
          subQuestion[0],
          question.questionId,
        );
      }
    } else if (question.questionId == 20) {
      const selectedValue = Object.keys(answerObj).filter(
        (key) => answerObj[key] == "1",
      );
      const subQuestion = question?.subQuestions?.filter(
        (subQ) => subQ?.value == selectedValue[0], //answerObj.race
      );
      await _updateAnsweredJson(question, subQuestion[0], question.questionId);
    } else if (question.questionId == 21) {
      const subQuestion = question?.subQuestions?.filter(
        (subQ) => subQ?.value == answerObj.childMedicalCheckup,
      );
      await _updateAnsweredJson(question, subQuestion[0], question.questionId);
    } else if (question.questionId == 22) {
      const answeredValue = answerObj.childsHeightWeight;
      // == "1"
      //   ? "yes"
      //   : answerObj.childsHeightWeight == "N"
      //   ? "no"
      //   : "";
      const subQuestion = question?.subQuestions?.filter(
        (subQ) => subQ?.value == answeredValue, //answerObj.childsHeightWeight
      );
      await _updateAnsweredJson(question, subQuestion[0], question.questionId);
      if (answerObj.childsHeightWeight == "1") {
        subQuestion[0]?.subQuestions?.map((subsubQuestion) => {
          if (["childsWeight", "calculateBmi"].includes(subsubQuestion.name)) {
            _updateSubAnswerForTextField(
              subsubQuestion,
              question.questionId,
              answerObj?.childsHeightWeightYesSubAnswer?.[subsubQuestion.name],
            );
          } else if (subsubQuestion.name == "childsHeightInches") {
            _updateSubAnswerForTextField(
              subsubQuestion,
              question.questionId,
              answerObj?.childsHeightWeightYesSubAnswer?.childsHeight?.inch,
            );
          } else if (subsubQuestion.name == "childsHeightfeet") {
            _updateSubAnswerForTextField(
              subsubQuestion,
              question.questionId,
              answerObj?.childsHeightWeightYesSubAnswer?.childsHeight?.feet,
            );
          }
        });
      }
    } else if (question.questionId == 23) {
      await _updateAnsweredJsonForTextField(
        question,
        question?.questionId,
        answerObj?.childsWeight,
      );
    } else if (question.questionId == 24) {
      const selectedValue = Object.keys(answerObj).filter(
        (key) => answerObj[key] == "1",
      );
      const subQuestion = question?.subQuestions?.filter(
        (subQ) => subQ?.value == selectedValue[0], //answerObj.race
      );

      await _updateAnsweredJson(question, subQuestion[0], question.questionId);
    } else if (question.questionId == 25) {
      const selectedValue = Object.keys(answerObj).filter(
        (key) => answerObj[key] == "1",
      );
      const subQuestion = question?.subQuestions?.filter(
        (subQ) => subQ?.value == selectedValue[0], //answerObj.race
      );
      await _updateAnsweredJson(question, subQuestion[0], question.questionId);
    } else if (question.questionId == 26) {
      if (answerObj.communityResourcesAvail == "1") {
        const subQuestion = question?.subQuestions[0];
        await _updateAnsweredJson(question, subQuestion, question.questionId);
      } else if (answerObj.communityResourcesAvail == "2") {
        const subQuestion = question?.subQuestions[1];
        await _updateAnsweredJson(question, subQuestion, question.questionId);
      }
    } else if (question.questionId == 27) {
      // updateAnsweredJson(
      //   question,
      //   subQuestion,
      //   idx,
      //   question?.genre
      // );

      const optionSelected = Object.keys(answerObj).filter(
        (key) => answerObj[key] == "1",
      );
      optionSelected?.map(async (option) => {
        const subQuestion = question?.subQuestions?.filter(
          (subQ) => subQ?.value == option,
        );
        const x = await _updateAnsweredJson(
          question,
          subQuestion[0],
          question?.questionId,
          "multiSelect",
          answerObj,
        );
        if (subQuestion[0]?.subQuestions?.length > 0) {
          //***********asthama********
          if (
            answerObj?.asthmaSubAnswer?.childSeeASpecialist == "1" &&
            option == "asthma"
          ) {
            const y = await _updateSubAnswerForToggleButton(
              subQuestion[0]?.subQuestions[0]?.subQuestions[0], //yes/no
              subQuestion[0]?.subQuestions[0], ///childSeeASpecialist
              question?.questionId,
            );
            const z = await _update2ndLevelAnsweredJsonForTextField(
              subQuestion[0]?.subQuestions[0]?.subQuestions[0]?.subQuestions[0],
              question?.questionId,
              answerObj?.asthmaSubAnswer?.childSeeASpecialistYesSubAnswer
                ?.yesName,
            );
          } else if (
            answerObj?.asthmaSubAnswer?.childSeeASpecialist == "2" &&
            option == "asthma"
          ) {
            await _updateSubAnswerForToggleButton(
              subQuestion[0]?.subQuestions[0]?.subQuestions[1],
              subQuestion[0]?.subQuestions[0],
              question?.questionId,
            );
          }
          //***********diabetes********
          if (
            answerObj?.diabetesSubAnswer?.childsBloodSugar == "1" &&
            option == "diabetes"
          ) {
            await _updateSubAnswerForToggleButton(
              subQuestion[0]?.subQuestions[0]?.subQuestions[0], //yes/no
              subQuestion[0]?.subQuestions[0], ///childSeeASpecialist
              question?.questionId,
            );
            // update2ndLevelAnsweredJsonForTextField(
            //   subQuestion[0]?.subQuestions[0]?.subQuestions[0]?.subQuestions[0],
            //   question?.questionId - 1,
            //   answerObj?.diabetesSubAnswer?.childSeeASpecialistYesSubAnswer
            //     ?.yesName
            // );
          } else if (
            answerObj?.diabetesSubAnswer?.childsBloodSugar == "2" &&
            option == "diabetes"
          ) {
            await _updateSubAnswerForToggleButton(
              subQuestion[0]?.subQuestions[0]?.subQuestions[1],
              subQuestion[0]?.subQuestions[0],
              question?.questionId,
            );
          }
          //***********sickle diseases********
          if (
            answerObj?.sickleCellDiseaseSubAnswer?.hgbAonec == "1" &&
            option == "sickleCellDisease"
          ) {
            await _updateSubAnswerForToggleButton(
              subQuestion[0]?.subQuestions[0]?.subQuestions[0], //yes/no
              subQuestion[0]?.subQuestions[0], ///childSeeASpecialist
              question?.questionId,
            );
            await _update2ndLevelAnsweredJsonForTextField(
              subQuestion[0]?.subQuestions[0]?.subQuestions[0]?.subQuestions[0],
              question?.questionId,
              answerObj?.sickleCellDiseaseSubAnswer?.hgbAonecYesSubAnswer
                ?.lastHgbAonec,
            );
          } else if (
            answerObj?.sickleCellDiseaseSubAnswer?.hgbAonec == "2" &&
            option == "sickleCellDisease"
          ) {
            await _updateSubAnswerForToggleButton(
              subQuestion[0]?.subQuestions[0]?.subQuestions[1],
              subQuestion[0]?.subQuestions[0],
              question?.questionId,
            );
          }

          // for haemophilia (special diet)

          if (
            answerObj?.hemophiliaSubAnswer?.specialDiet == "1" &&
            option == "hemophilia"
          ) {
            await _updateSubAnswerForToggleButton(
              subQuestion[0]?.subQuestions[0]?.subQuestions[0], //yes/no
              subQuestion[0]?.subQuestions[0], ///childSeeASpecialist
              question?.questionId,
            );
            await _update2ndLevelAnsweredJsonForTextField(
              subQuestion[0]?.subQuestions[0]?.subQuestions[0]?.subQuestions[0],
              question?.questionId,
              answerObj?.hemophiliaSubAnswer?.specialDietYesSubAnswer?.yesDiet,
            );
          } else if (
            answerObj?.hemophiliaSubAnswer?.specialDiet == "2" &&
            option == "hemophilia"
          ) {
            await _updateSubAnswerForToggleButton(
              subQuestion[0]?.subQuestions[0]?.subQuestions[1],
              subQuestion[0]?.subQuestions[0],
              question?.questionId,
            );
          }

          // asthama comment
          if (
            answerObj?.asthmaSubAnswer?.comment?.length > 0 &&
            option == "asthma"
          ) {
            _secondLevelComment = [
              ..._secondLevelComment,
              subQuestion[0]?.subQuestions[0]?.name,
            ];
            // setSecondLevelComment([
            //   ...secondLevelComment,
            //   ..._secondLevelComment,
            // ]); //  try to remove this setState from here too
            await setLocalStorageAsync(
              "secondLevelComment",
              JSON.stringify([...secondLevelComment, ..._secondLevelComment]),
            );
            await _createSecondLevelCommentObj(
              subQuestion[0]?.subQuestions[0]?.name,
              answerObj?.asthmaSubAnswer?.comment,
            );
          }
          //**********diabetes comment*******
          if (
            answerObj?.diabetesSubAnswer?.comment?.length > 0 &&
            option == "diabetes"
          ) {
            _secondLevelComment = [
              ..._secondLevelComment,
              subQuestion[0]?.subQuestions[0]?.name,
            ];
            // setSecondLevelComment([
            //   ...secondLevelComment,
            //   ..._secondLevelComment,
            // ]); ////  try to remove this setState from here too
            await setLocalStorageAsync(
              "secondLevelComment",
              JSON.stringify([...secondLevelComment, ..._secondLevelComment]),
            );
            await _createSecondLevelCommentObj(
              subQuestion[0]?.subQuestions[0]?.name,
              answerObj?.diabetesSubAnswer?.comment,
            );
          }

          //********* sickle diseases */
          if (
            answerObj?.sickleCellDiseaseSubAnswer?.comment?.length > 0 &&
            option == "sickleCellDisease"
          ) {
            _secondLevelComment = [
              ..._secondLevelComment,
              subQuestion[0]?.subQuestions[0]?.name,
            ];
            // setSecondLevelComment([
            //   ...secondLevelComment,
            //   ..._secondLevelComment,
            // ]); ////  try to remove this setState from here too
            await setLocalStorageAsync(
              "secondLevelComment",
              JSON.stringify([...secondLevelComment, ..._secondLevelComment]),
            );
            await _createSecondLevelCommentObj(
              subQuestion[0]?.subQuestions[0]?.name,
              answerObj?.sickleCellDiseaseSubAnswer?.comment,
            );
          }

          // haemophilia special diet comment
          if (
            answerObj?.hemophiliaSubAnswer?.comment?.length > 0 &&
            option == "hemophilia"
          ) {
            _secondLevelComment = [
              ..._secondLevelComment,
              subQuestion[0]?.subQuestions[0]?.name,
            ];
            // setSecondLevelComment([
            //   ...secondLevelComment,
            //   ..._secondLevelComment,
            // ]); ////  try to remove this setState from here too
            await setLocalStorageAsync(
              "secondLevelComment",
              JSON.stringify([...secondLevelComment, ..._secondLevelComment]),
            );
            await _createSecondLevelCommentObj(
              subQuestion[0]?.subQuestions[0]?.name,
              answerObj?.hemophiliaSubAnswer?.comment,
            );
          }
        }
      });
    } else if (question.questionId == 28) {
      await _updateAnsweredJsonForTextField(
        question,
        question?.questionId,
        answerObj?.additionalInformationChild,
      );
    } else if (question.questionId == 29) {
      // const selectedValue = Object.keys(answerObj).filter(
      //   (key) => answerObj[key] == "1",
      // );
      if (answerObj?.isPatientReligiousBelief == "1") {
        const subQuestion = question?.subQuestions[0];
        const selectedSubQuestionDescribe = subQuestion?.subQuestions[0];
        await _updateAnsweredJson(question, subQuestion, question.questionId);
        await _updateSubAnswerForTextField(
          selectedSubQuestionDescribe,
          question.questionId,
          answerObj.isPatientReligiousBeliefSubAnswer.describe,
        );
      } else if (answerObj?.isPatientReligiousBelief == "2") {
        const subQuestion = question?.subQuestions[1];
        await _updateAnsweredJson(question, subQuestion, question.questionId);
      }
      // const subQuestion = question?.subQuestions?.filter(
      //   (subQ) => subQ?.value == selectedValue[0], //answerObj.race
      // );
      //console.log('subQuestion',subQuestion)
      //_updateAnsweredJson(question, subQuestion[0], question.questionId - 1);
    } else {
    }
  };

  const getAssessmentInfoData = async () => {
    setIsLoading(true);
    try {
      const response = await getApi(`/hra/get-assessment?id=${assessmentId}`);
      // setIsLoading(false);
      const data = response.data.data.assessmentInfo.data;
      Object.keys(data).forEach((key) => {
        const question = pedsAssessmentInfoJsonData?.[0]?.questions?.filter(
          (question) => question?.name == key,
        );

        mapedSavedData(question[0], data[key]);
      });
    } catch (error) {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getAssessmentInfoData();
    setTimeout(() => {
      const answeredJsonFromLocalStorage = localStorage.getItem("answeredJson")
        ? JSON.parse(
            JSON.parse(JSON.stringify(localStorage.getItem("answeredJson"))),
          )
        : {};
      const secondLevelTextFieldAnswerFromLocalStorage = localStorage.getItem(
        "secondLevelTextFieldAnswer",
      )
        ? JSON.parse(localStorage.getItem("secondLevelTextFieldAnswer"))
        : {};
      const selected2ndLevelSubQuestionFromLocalStorage = localStorage.getItem(
        "selected2ndLevelSubQuestion",
      )
        ? JSON.parse(
            JSON.parse(
              JSON.stringify(
                localStorage.getItem("selected2ndLevelSubQuestion"),
              ),
            ),
          )
        : {};
      const secondLevelCommentFromLocalStorage = localStorage.getItem(
        "secondLevelComment",
      )
        ? JSON.parse(localStorage.getItem("secondLevelComment"))
        : [];
      const secondLevelCommentObjFromLocalStorage = localStorage.getItem(
        "secondLevelCommentObj",
      )
        ? JSON.parse(localStorage.getItem("secondLevelCommentObj"))
        : {};

      const allergiesRowDataFromLocalStorage = JSON.parse(
        localStorage.getItem("allergiesRowData"),
      );
      const counterMedicationRowDataFromLocalStorage = localStorage.getItem(
        "counterMedicationRowData",
      )
        ? JSON.parse(localStorage.getItem("counterMedicationRowData"))
        : [];

      const CounterMedicationsRowDataQues15FromLocalStorage =
        localStorage.getItem("counterMedicationsRowDataQues15")
          ? JSON.parse(localStorage.getItem("counterMedicationsRowDataQues15"))
          : [];

      const selectedSubQuestionFromLocalStorage = localStorage.getItem(
        "selectedSubQuestion",
      )
        ? JSON.parse(
            JSON.parse(
              JSON.stringify(localStorage.getItem("selectedSubQuestion")),
            ),
          )
        : {};
      const selectedIdxFromLocalStorage = localStorage.getItem("selectedIdx")
        ? JSON.parse(
            JSON.parse(JSON.stringify(localStorage.getItem("selectedIdx"))),
          )
        : [];

      const firstLevelCommentObjFromLocalStorage = localStorage.getItem(
        "firstLevelCommentObj",
      )
        ? JSON.parse(
            JSON.parse(
              JSON.stringify(localStorage.getItem("firstLevelCommentObj")),
            ),
          )
        : {};

      //selectedDescribeFirstLevel selectedIdxDescribeFirstLevel

      const selectedDescribeFirstLevelFromLocalStorage = localStorage.getItem(
        "selectedDescribeFirstLevel",
      )
        ? JSON.parse(
            JSON.parse(
              JSON.stringify(
                localStorage.getItem("selectedDescribeFirstLevel"),
              ),
            ),
          )
        : {};

      const selectedIdxDescribeFirstLevelFromLocalStorage =
        localStorage.getItem("selectedIdxDescribeFirstLevel")
          ? JSON.parse(
              JSON.parse(
                JSON.stringify(
                  localStorage.getItem("selectedIdxDescribeFirstLevel"),
                ),
              ),
            )
          : [];

      setAnsweredJson({
        ...answeredJson,
        ..._answeredJson,
        ...answeredJsonFromLocalStorage,
      });

      // setAnsweredJson({...answeredJsonFromLocalStorage});
      setSelectedDescribeFirstLevel({
        ...selectedDescribeFirstLevel,
        ..._selectedDescribeFirstLevel,
        ...selectedDescribeFirstLevelFromLocalStorage,
      });
      selSelectedIdxDescribeFirstLevel([
        ...selectedIdxDescribeFirstLevel,
        ..._selectedIdxDescribeFirstLevel,
        ...selectedIdxDescribeFirstLevelFromLocalStorage,
      ]);
      setSecondLevelTextFieldAnswer({
        ...secondLevelTextFieldAnswer,
        ..._secondLevelTextFieldAnswer,
        ...secondLevelTextFieldAnswerFromLocalStorage,
      });
      setSelectedSubQuestions({
        ...selectedSubQuestion,
        ..._selectedSubQuestion,
        ...selectedSubQuestionFromLocalStorage,
      });
      setSelected2ndLevelSubQuestion({
        ...selected2ndLevelSubQuestion,
        ..._selected2ndLevelSubQuestion,
        ...selected2ndLevelSubQuestionFromLocalStorage,
      });
      // setSelected2ndLevelSubQuestion({
      //   ...selected2ndLevelSubQuestion,
      //   ..._selected2ndLevelSubQuestion,
      // }); setSelectedIdx([...new Set([...selectedIdx, idx])]);
      setSelectedIdx([
        ...new Set([
          ...selectedIdx,
          ..._selectedIdx,
          ...selectedIdxFromLocalStorage,
        ]),
      ]);
      setSecondLevelCommentObj({
        ...secondLevelCommentObj,
        ..._secondLevelCommentObj,
        ...secondLevelCommentObjFromLocalStorage,
      });
      setFirstLevelCommentObj({
        ...firstLevelCommentObj,
        ..._firstLevelCommentObj,
        ...firstLevelCommentObjFromLocalStorage,
      });
      setSecondLevelComment([
        ..._secondLevelComment,
        secondLevelComment,
        ...secondLevelCommentFromLocalStorage,
      ]);

      setAllergiesRowData(allergiesRowDataFromLocalStorage); //allergiesRowData
      setCounterMedicationsRowData(counterMedicationRowDataFromLocalStorage);
      setCounterMedicationsRowDataQues15(
        CounterMedicationsRowDataQues15FromLocalStorage,
      );
      setIsLoading(false);
    }, 1500);
  }, []);

  // setTimeout(()=>{
  //   setIsLoading(false);
  // },10000)

  return {
    pedsAssessmentInfoJsonData: jsonData,
    allergiesRowData,
    setAllergiesRowData,
    selectedSubQuestion,
    setSelectedSubQuestions,
    answeredJson,
    setAnsweredJson,

    selectedIdx,
    setSelectedIdx,

    selectedDescribeFirstLevel,
    setSelectedDescribeFirstLevel,
    selectedIdxDescribeFirstLevel,
    selSelectedIdxDescribeFirstLevel,
    firstLevelCommentObj,
    setFirstLevelCommentObj,
    counterMedicationsRowData,
    setCounterMedicationsRowData,
    counterMedicationsRowDataQues15,
    setCounterMedicationsRowDataQues15,
    isLoading,
    setIsLoading,
    selected2ndLevelSubQuestion,
    setSelected2ndLevelSubQuestion,
    secondLevelTextFieldAnswer,
    setSecondLevelTextFieldAnswer,
    secondLevelComment,
    setSecondLevelComment,
    secondLevelCommentObj,
    setSecondLevelCommentObj,
    getAssessmentInfoData,
    storedYear,
    onFirstLevelCommentIconClick,
    createFirstLevelCommentObj,
    updateAnsweredJsonForTextField,
    updateAnsweredJson,
    onSecondLevelCommentIconClick,
    updateSubAnswerForToggleButton,
    createSecondLevelCommentObj,
    updateSubAnswerForTextField,
    update2ndLevelAnsweredJsonForTextField,
  };
};
export default usePedsFormAssessmentInfo;
