import Box from "@mui/material/Box";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import { SelectDropdown } from "../../select/select";
import AlertContext from "../../../contexts/AlertContext";
import { SchedulerFilterDialogs } from "../Dialog/Dialog";
import "./Scheduler.css";
import { useContext, useState, useEffect, useCallback } from "react";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Button from "../../button/button";
import Toaster from "../../toaster/toaster";
// import Alert from "../../Alert/Alert";
import { useUserContext } from "../../../contexts/UserContext";
import { postApi, getApi, putApi } from "../../../utils/axiosApiWrapper";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { useNavigate } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import InputLabel from "@mui/material/InputLabel";
// import "../Alert/Alert.css";
import Labels from "../../../resource_files/Labels.resx";
import { Zoom } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import _, { filter } from "lodash";
import SchedulerFilterComp from "./SchedulerFilter";
import { isEmpty, trim } from "lodash";
import FilterModal from "../../../pages/SchedulerDashboard/Graphs/FilterModal/FilterModal";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import {
  getHraStatus,
  getClientByID,
  getSchedulersData,
  getClientsData,
} from "../../../api/filterApi";

const SchedulerComponent = ({
  areCheckboxesSelected,
  selectedRowCheckboxes,
  selectedTab,
  setSelectedTab,
  selectedMemberData,
  selectedMemberDataArray,
  setSelectedMemberDataArray,
  setSelectedCheckboxes,
  setSelectedRowCheckboxes,
  onSearch,
  hraStatusOptions,
  visitTypeOptions,
  handleFilter,
  onFilterChange,
  graphFilter,
  setGraphFilter,
  handleCloseFilter,
  handleOpenFilter,

  setIsAnalyticsActive,
  isAnalyticsActive,
  graphTabId,
  setGraphTabId,
}) => {
  const roleId = sessionStorage.getItem("roleId");
  const [tabValue, setTabValue] = useState("Member List");
  const { clientData, handleBadgeClick } = useUserContext();
  const schedulerLeadOptions = ["Member List", "Disabled Members"];

  const memberListOptions =
    roleId == 4 ? ["Member List"] : schedulerLeadOptions;

  const filterDropdown = [
    "Identification#",
    "First Name",
    "Last Name",
    "City",
    "Zipcode",
  ];
  const alertContext = useContext(AlertContext);
  const [selectedPlan, setSelectedPlan] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [toasterMessage, setToasterMessage] = useState("");
  const [toasterSeverity, setToasterSeverity] = useState(Labels.SUCCESS);
  const [toasterOpen, setToasterOpen] = useState(false);
  const storedClientId = localStorage.getItem("selectedClientId");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [selectedClientId, setSelectedClientId] = useState(storedClientId);
  const [schedulerData, setSchedulerData] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isUnAssignedDialogOpen, setIsUnAssignedDialogOpen] = useState(false);
  const [schedulerOptions, setSchedulerOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedFilter, setSelectedFilter] = useState(filterDropdown[0]);
  const [isFirstNameSelected, setIsFirstNameSelected] = useState(true);
  const [openFilter, setOpenFilter] = useState(false);

  const onCloseFilter = () => {
    setOpenFilter(false);
  };

  const fetchSchedulerData = useCallback(async (clientId) => {
    try {
      const response = await postApi("/users/get-users-by-program-roleId", {
        planId: clientId,
      });
      if (response.data) {
        const data = response.data.data[0]?.scheduler || [];
        setSchedulerData(data);
      }
    } catch (error) {
      console.error("Error fetching scheduler data:", error);
    }
  }, []);

  const handleAddClick = useCallback(() => {
    const selectedScheduler = schedulerData.find(
      (scheduler) =>
        `${scheduler.firstName} ${scheduler.lastName}` === selectedValue,
    );

    if (selectedScheduler) {
      const { userid, roleId } = selectedScheduler;
      openUnAssignedDialog(userid, roleId.toString());
    }
  }, [schedulerData, selectedValue]);

  const handleSearch = (e) => {
    if (e.key == "Enter" || e.type == "mousemove") {
      if (searchTerm.length > 2) {
        if (selectedFilter === "First name") {
          onSearch(selectedFilter, trim(searchTerm));
        } else if (selectedFilter === "Last name") {
          onSearch(selectedFilter, trim(searchTerm));
        } else if (selectedFilter === "Identification#") {
          onSearch(selectedFilter, trim(searchTerm));
        }
      } else {
        setToasterOpen(true);
        setToasterSeverity("error");
        setToasterMessage("Minimum 3 characters required to search!");
      }
    }
    if (e.key === "Backspace" && searchTerm.length < 4) {
      if (!isEmpty(searchTerm)) {
        if (selectedFilter === "First name") {
          onSearch(selectedFilter, searchTerm);
        } else if (selectedFilter === "Last name") {
          onSearch(selectedFilter, searchTerm);
        } else if (selectedFilter === "Identification#") {
          onSearch(selectedFilter, searchTerm);
        }
      } else {
        setSelectedFilter(filterDropdown[0]);
        setSearchTerm("");
        onSearch(selectedFilter, "");
      }
    }
  };

  const showErrorToast = (message) => {
    setToasterOpen(true);
    setToasterSeverity("error");
    setToasterMessage(message);
  };

  const handleSearchOnIconClick = () => {
    if (searchTerm.length > 2) {
      onSearch(selectedFilter, searchTerm);
    } else {
      setToasterOpen(true);
      setToasterSeverity("error");
      setToasterMessage("Minimum 3 characters required to search!");
    }
  };

  const handleFilterDropdownChange = (event) => {
    const { value } = event.target;
    setSelectedFilter(value);
    setIsFirstNameSelected(value === "First Name");
  };

  const openUnAssignedDialog = (userId, userRoleId) => {
    setIsUnAssignedDialogOpen(true);
  };

  useEffect(() => {
    setSelectedFilter("Identification#");
    onSearch("Identification#", "");
  }, []);

  const openDialogBox = () => {
    setIsDialogOpen(true);
  };

  useEffect(() => {
    setIsButtonDisabled(
      !selectedPlan ||
        !selectedDate ||
        !areCheckboxesSelected ||
        !Object.values(selectedRowCheckboxes).some(Boolean),
    );
  }, [
    selectedPlan,
    selectedDate,
    areCheckboxesSelected,
    selectedRowCheckboxes,
  ]);

  // const handleFilterChange = (filters, schedulerId, isReset) => {
  //   // Handle the filter changes here
  //   console.log("Filters applied:", filters, schedulerId, isReset);
  // };

  const toggleOpen = () => {
    if (isAnalyticsActive) {
      setGraphFilter(true);
    } else setOpenFilter(true);
  };
  const [unassignedChecked, setUnassignedChecked] = useState(false);
  const [assignedChecked, setAssignedChecked] = useState(false);
  const [myMember, setMyMember] = useState(true);
  const handleUnassignedSwitchChange = () => {
    const newUnassignedCheckedState = !unassignedChecked;
    setUnassignedChecked(newUnassignedCheckedState);

    if (newUnassignedCheckedState && assignedChecked) {
      setSelectedTab("BothChecked");
    } else if (!newUnassignedCheckedState && assignedChecked) {
      setSelectedTab("AssignedChUnAssignedUn");
    } else if (newUnassignedCheckedState && !assignedChecked) {
      setSelectedTab("AssignedUnUnAssCh");
    } else if (!newUnassignedCheckedState && !assignedChecked) {
      setSelectedTab("BothUnchecked");
    }
  };
  useEffect(() => {
    if (roleId == 4) {
      setSelectedTab("MyMemberSelected");
    }
  }, []);
  const handleMyMemberTab = () => {
    if (myMember) {
      setMyMember(false);
      setSelectedTab("MyMemberNotSelected");
    } else {
      setMyMember(true);
      setSelectedTab("MyMemberSelected");
    }
  };

  const handleAssignedSwitchChange = () => {
    const newAssignedCheckedState = !assignedChecked;
    setAssignedChecked(newAssignedCheckedState);

    if (newAssignedCheckedState && unassignedChecked) {
      // Both are checked

      setSelectedTab("BothChecked");
    } else if (!newAssignedCheckedState && unassignedChecked) {
      //  Assigned is checked and unassigned is unchecked

      setSelectedTab("AssignedUnUnAssCh");
    } else if (newAssignedCheckedState && !unassignedChecked) {
      //  Assigned is unchecked and unassigned is checked

      setSelectedTab("AssignedChUnAssignedUn");
    } else if (!newAssignedCheckedState && !unassignedChecked) {
      //  Both are unchecked

      setSelectedTab("BothUnchecked");
    }
  };

  const handleResetSearch = async () => {
    //setLoading(true);
    setSelectedFilter(filterDropdown[0]);
    setSearchTerm("");
    onSearch(selectedFilter, "");
  };

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 33,
    height: 20,
    padding: 0,
    display: "flex",
    marginLeft: 28,
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#177ddc" : "var(--mainTheme)",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 15,
      height: 16,
      marginLeft: 2,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));
  const handleDropdownChange = (event) => {
    const newValue = event.target.value;
    setSelectedTab(newValue);
    setTabValue(newValue);
  };

  const [hra, setHra] = useState([]);
  const [lob, setLob] = useState([]);
  const [prog, setProg] = useState([]);
  const [users, setUsers] = useState([]);

  const [filters, setFilters] = useState({
    program: "",
    lob: "",
    hrastatus: "",
    fromDate: null,
    toDate: null,
  });

  const [scheduler, setScheduler] = useState("");

  const storedPlanId = localStorage.getItem("clientPlan");

  const fetchData = useCallback(async () => {
    try {
      const [cliDt, hraResponse, progResponse] = await Promise.all([
        getClientsData(),
        getHraStatus(),
        getClientByID(storedPlanId),
      ]);

      const currPlanId = cliDt.data.data.find(
        (item) => item.plan === storedPlanId,
      )?._id;
      if (!currPlanId) return;

      const schedResponse = await getSchedulersData({
        planId: currPlanId.trim(),
        roleId: "4",
      });

      const hraData = hraResponse?.data?.data || [];
      const progData = progResponse?.data?.data || {};
      const schData = schedResponse?.data?.data || [];

      if (Array.isArray(hraData)) {
        setHra(
          hraData
            .filter((stat) => stat.id && stat.statusName)
            .map((stat) => ({ id: stat.id, name: stat.statusName })),
        );
      }

      if (progData?.program) {
        const validPrograms = progData.program.filter((p) => p._id && p.name);
        setProg(validPrograms.map((p) => ({ id: p._id, name: p.name })));

        const lobData = validPrograms.flatMap((program) =>
          program.lob
            .filter((lob) => lob._id && lob.name)
            .map((lob) => ({ id: lob._id, name: lob.name })),
        );
        setLob(lobData);
      }

      if (Array.isArray(schData)) {
        setUsers(
          schData
            .filter((user) => user.userid && user.firstName && user.lastName)
            .map((user) => ({
              id: user.userid,
              name: `${user.firstName} ${user.lastName}`,
            })),
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [storedPlanId]);
  useEffect(() => {
    const fetchClientData = async () => {
      try {
        const response = await getApi("/clients/list");
        if (response.status === 200) {
          const clientPlanFromLocalStorage = localStorage.getItem("clientPlan");
          const matchingClient = response.data.data.find((client) =>
            client.plan.includes(clientPlanFromLocalStorage),
          );

          if (matchingClient) {
            fetchSchedulerData(matchingClient._id);
          }
        }
      } catch (error) {
        console.error("Error fetching client data:", error);
      }
    };

    fetchClientData();
    fetchData();
  }, [fetchData]);

  return (
    <>
      <div className="reports">
        <SchedulerFilterDialogs />

        <div className="reports-left">
          {!isAnalyticsActive && (
            <Tabs
              className="custom-tabs"
              value={tabValue}
              onChange={(e, newValue) =>
                handleDropdownChange({ target: { value: newValue } })
              }
            >
              {memberListOptions.map((option, index) => (
                <Tab key={index} value={option} label={option} />
              ))}
            </Tabs>
          )}
          <span style={{ width: "60%", display: "flex", alignItems: "center" }}>
            {/* {roleId == 3 || roleId == 1 || roleId == 2 ? (
            <Tabs
              className="custom-tabs"
              value={tabValue}
              onChange={(e, newValue) =>
                handleDropdownChange({ target: { value: newValue } })
              }
            >
              {memberListOptions.map((option, index) => (
                <Tab key={index} value={option} label={option} />
              ))}
            </Tabs>
          ) : (
            <Tabs
              className="custom-tabs"
              value="Member List"
              // onChange={(e, newValue) =>
              //   handleDropdownChange({ target: { value: newValue } })
              // }
              aria-label="wrapped label tabs example"
            >
              <Tab key="Member List" value="Member List" label="Member List" />
            </Tabs>
          )} */}

            {/* <SelectDropdown
          sx={{
            borderRadius: 20,
            width: "154px",
            height: "40px",
            fontSize: "16px",
            fontWeight: 500,
            marginRight: 1,
            marginLeft: 2,
          }}
          //  title={memberListOptions[0]}
          title="Member List"
          options={memberListOptions}
          className="dropdownReports"
          valueClass="dropdownValueReports"
        />  */}

            {!isAnalyticsActive && (
              <>
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  marginLeft={1}
                >
                  {roleId == 3 || roleId == 1 || roleId == 2 ? (
                    <>
                      <AntSwitch
                        // defaultChecked
                        inputProps={{ "aria-label": "ant design" }}
                        defaultChecked={unassignedChecked}
                        onChange={handleUnassignedSwitchChange}
                      />
                      <Typography style={{ marginLeft: "5px" }}>
                        Un Assigned
                      </Typography>

                      <AntSwitch
                        // defaultChecked
                        defaultChecked={assignedChecked}
                        onChange={handleAssignedSwitchChange}
                        inputProps={{ "aria-label": "ant design" }}
                        // sx={{ marginRight: '5px' }}
                      />

                      <Typography style={{ marginLeft: "5px" }}>
                        Assigned
                      </Typography>
                    </>
                  ) : (
                    <>
                      <AntSwitch
                        // defaultChecked
                        defaultChecked={myMember}
                        onChange={handleMyMemberTab}
                        inputProps={{ "aria-label": "ant design" }}
                        // sx={{ marginRight: '5px' }}
                      />

                      <Typography style={{ marginLeft: "5px" }}>
                        My Member{" "}
                      </Typography>
                    </>
                  )}
                </Stack>

                <Button
                  height="40px"
                  width="150px"
                  ml="22px"
                  mt="3px"
                  fontSize="14px"
                  cursor="pointer"
                  fontWeight="500"
                  // disabled={!areCheckboxesSelected}
                  // display={!areCheckboxesSelected ? "none" : "block"}
                  display={
                    selectedMemberDataArray.length > 0 ? "block" : "none"
                  }
                  onClick={openUnAssignedDialog}
                  // onClick={(userId,userRoleId) => openUnAssignedDialog(userId, userRoleId)}
                >
                  {/* Un Assigned */}
                  Assign Scheduler
                </Button>
              </>
            )}
            <Tooltip
              title={
                isAnalyticsActive
                  ? "Close Analytics View"
                  : "Open Analytics View"
              } // Dynamic tooltip text
              arrow
              enterDelay={100} // Delay before the tooltip appears
            >
              <BarChartOutlinedIcon
                onClick={() => setIsAnalyticsActive(!isAnalyticsActive)}
                sx={{
                  borderRadius: "8px",
                  marginLeft: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "35px",
                  height: "35px",
                  cursor: "pointer",
                  background: isAnalyticsActive
                    ? "var(--mainTheme)"
                    : "var(--mainTheme200)",
                  color: isAnalyticsActive ? "#fff" : "#4e4e4e",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  padding: "5px",
                }}
              />
            </Tooltip>
          </span>
          {/* <Button
          height="34px"
          width="95px"
          ml="22px"
          mt="3px"
          fontSize="14px"
          cursor="pointer"
          fontWeight="500"
          display={!areCheckboxesSelected ? "none" : "block"}
          onClick={openDialogBox}
        >
          Re Assigned
        </Button> */}
          <Alert
            open={isUnAssignedDialogOpen}
            handleClose={() => setIsUnAssignedDialogOpen(false)}
            onAddClick={handleAddClick}
            title="Select Scheduler"
            okText="Assign"
            cancelText="Close"
            showDropdown={true}
            selectedMemberData={selectedMemberData}
            selectedMemberDataArray={selectedMemberDataArray}
            schedulerData={schedulerData}
            schedulerOptions={schedulerOptions}
            selectedValue={selectedValue}
            setSelectedMemberDataArray={setSelectedMemberDataArray}
            setSelectedCheckboxes={setSelectedCheckboxes}
            setSelectedRowCheckboxes={setSelectedRowCheckboxes}
          />
        </div>
        <div className="reports-right">
          <Box className="filterBoxReports" onClick={toggleOpen}>
            <p>Filter</p>
            <FilterAltIcon sx={{ height: "26px", width: "26px" }} />
          </Box>
          {!isAnalyticsActive && (
            <>
              <SelectDropdown
                sx={{
                  borderRadius: 20,
                  width: "130px",
                  height: "40px",
                  fontSize: "16px",
                  fontWeight: 500,
                  marginRight: 1,
                  marginLeft: 2,
                }}
                title=""
                options={filterDropdown}
                className="dropdownReports"
                valueClass="dropdownValueReports"
                onChange={handleFilterDropdownChange}
                value={selectedFilter}
              />
              <Box className="searchBoxReports">
                <TextField
                  placeholder="Search"
                  InputProps={{
                    disableUnderline: true,
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon
                          style={{ marginLeft: "5px", cursor: "pointer" }}
                          onClick={(e) => handleSearchOnIconClick()}
                        />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        {searchTerm && (
                          <CancelOutlinedIcon
                            style={{ marginRight: "5px", cursor: "pointer" }}
                            onClick={handleResetSearch}
                          />
                        )}
                      </InputAdornment>
                    ),
                  }}
                  onKeyDown={(e) => handleSearch(e)}
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  variant="standard"
                />
              </Box>
            </>
          )}
          {graphFilter && (
            <FilterModal
              filters={filters}
              setFilters={setFilters}
              scheduler={scheduler}
              setScheduler={setScheduler}
              open={handleOpenFilter}
              OnClose={handleCloseFilter}
              onFilterChange={onFilterChange}
              graphFilter={graphFilter}
              setGraphFilter={setGraphFilter}
              prog={prog}
              lob={lob}
              users={users}
              hra={hra}
              graphTabId={graphTabId}
            />
          )}
        </div>
        <Alert
          open={isDialogOpen}
          handleClose={() => setIsDialogOpen(false)}
          okText="Assign"
          cancelText="Cancel"
          onAddClick={handleAddClick}
          showDropdown={true}
          selectedMemberData={selectedMemberData}
          selectedMemberDataArray={selectedMemberDataArray}
          schedulerData={schedulerData}
          schedulerOptions={schedulerOptions}
          selectedValue={selectedValue}
          setSelectedMemberDataArray={setSelectedMemberDataArray}
          setSelectedCheckboxes={setSelectedCheckboxes}
          setSelectedRowCheckboxes={setSelectedRowCheckboxes}
        />
      </div>
      <SchedulerFilterComp
        open={openFilter}
        onClose={onCloseFilter}
        hraStatusOptions={hraStatusOptions}
        visitTypeOptions={visitTypeOptions}
        handleFilter={handleFilter}
      />
      <Toaster
        open={toasterOpen}
        onClose={() => setToasterOpen(false)}
        severity={toasterSeverity}
        message={toasterMessage}
      />
    </>
  );
};

export default SchedulerComponent;

export const Alert = ({
  open,
  handleClose,
  title,
  okText,
  cancelText,
  showDropdown,
  onAddClick,
  onNoClick,
  showSecondDropdownCondition,
  selectedMemberData,
  selectedMemberDataArray,
  setSelectedMemberDataArray,
  setSelectedCheckboxes,
  setSelectedRowCheckboxes,
}) => {
  const { clientData } = useUserContext();

  const [selectedTab, setSelectedTab] = useState("Member List");
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedPlan, setSelectedPlan] = useState([]);
  const storedClientId = localStorage.getItem("selectedClientId");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [selectedClientId, setSelectedClientId] = useState(
    storedClientId || "",
  );
  const [toasterMessage, setToasterMessage] = useState("");
  const [toasterSeverity, setToasterSeverity] = useState(Labels.SUCCESS);
  const [toasterOpen, setToasterOpen] = useState(false);
  const [dataUpdated, setDataUpdated] = useState(false);
  const [isUnAssignedDialogOpen, setIsUnAssignedDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [responseData, setResponseData] = useState([]);

  const [schedulerOptions, setSchedulerOptions] = useState([]);
  const handleVphpSelection = (responseData) => {
    setResponseData(responseData);
  };
  const [schedulerData, setSchedulerData] = useState([]);
  const navigate = useNavigate();

  const fetchSchedulerData = async (clientId) => {
    try {
      const response = await postApi("/users/get-users-by-program-roleId", {
        planId: clientId,
      });
      const data =
        response?.data?.data[0]?.scheduler || response?.data?.data?.scheduler;

      if (Array.isArray(data) && data.length > 0) {
        const options = data.map(
          (scheduler) => `${scheduler.firstName} ${scheduler.lastName}`,
        );
        setSchedulerOptions(options);
        setSchedulerData(data);
      }
    } catch (error) {
      console.error("Error fetching scheduler data:", error);
    }
  };

  // Handle Add Click
  const handleAddClick = () => {
    const selectedScheduler = schedulerData.find(
      (scheduler) =>
        `${scheduler.firstName} ${scheduler.lastName}` === selectedValue,
    );

    if (selectedScheduler) {
      openUnAssignedDialog(
        selectedScheduler.userid,
        selectedScheduler.roleId.toString(),
      );
    }
  };

  const handleReload = () => {
    navigate(".", { replace: true });
  };

  const openUnAssignedDialog = async (userId, userRoleId) => {
    setLoading(true);
    try {
      const apiEndpoint = `/assignClinician/updateAssigneeBasedOnRole`;

      if (
        !Array.isArray(selectedMemberDataArray) ||
        selectedMemberDataArray.length === 0
      ) {
        return;
      }

      const members = selectedMemberDataArray.map(({ _id, clientId }) => ({
        memberObjId: _id,
        clientId,
        userRoleId,
        userid: userId,
        planId: selectedClientId || storedClientId,
      }));

      const requestData = { members };
      const response = await putApi(apiEndpoint, requestData);

      if (response.status === 200 || response.message === "Success") {
        setToasterMessage("Assigned scheduler successfully!");
        setToasterSeverity("success");
        setToasterOpen(true);
        handleClose();
        handleReload();

        // const updatedResponse = await fetchSchedulerMemberData(storedPlan, storedYear, defaultTabId);

        setSelectedRowCheckboxes({});
        setSelectedCheckboxes({});
        setSelectedMemberDataArray([]);
      } else {
        setToasterMessage("Failed to update assignee. Please try again.");
        setToasterSeverity("error");
        setToasterOpen(true);
      }
    } catch (error) {
      console.error("Error updating assignee:", error);
      setToasterMessage("An error occurred. Please try again.");
      setToasterSeverity("error");
      setToasterOpen(true);
    } finally {
      setLoading(false);
    }
  };

  //   const openUnAssignedDialog = async (userId, userRoleId) => {
  //     try {
  //         const apiEndpoint = `/assignClinician/updateAssigneeBasedOnRole`;

  //         if (!Array.isArray(selectedMemberDataArray)) {
  //             return;
  //         }

  //         const members = selectedMemberDataArray.map(selectedMember => ({
  //             memberObjId: selectedMember._id,
  //             clientId: selectedMember.clientId,
  //             userRoleId: userRoleId,
  //             userid: userId,
  //             planId: selectedClientId || storedClientId,
  //         }));

  //         const requestData = { members };
  //         const response = await putApi(apiEndpoint, requestData);

  //         if (response.status === 200 || response.message === "Success") {
  //           // await fetchSchedulerMemberData(storedPlan, storedYear, defaultTabId);
  //           setToasterMessage("Assigned scheduler successfully!");
  //           setToasterSeverity("success"); // Adjust this based on your severity levels
  //           setToasterOpen(true);
  //           // handleReload();
  //           await fetchSchedulerMemberData(storedPlan, storedYear, defaultTabId);
  //           handleClose();
  //           setSelectedRowCheckboxes({});
  //         setSelectedCheckboxes({});
  //         setSelectedMemberDataArray([]);

  //             }

  //             // Fetch updated member data
  //             // await fetchSchedulerMemberData(storedPlan, storedYear, defaultTabId);

  //             // Show success toaster

  //          else {
  //             // Show error toaster
  //             setToasterMessage("Failed to update assignee. Please try again.");
  //             setToasterSeverity("error");
  //             setToasterOpen(true);
  //         }
  //     } catch (error) {
  //         console.error("Error updating assignee:", error);
  //         // Show error toaster
  //         setToasterMessage("An error occurred. Please try again.");
  //         setToasterSeverity("error");
  //         setToasterOpen(true);
  //     }
  // };

  useEffect(() => {
    fetchSchedulerData(storedClientId);
  }, [storedClientId]);

  const handleNoClick = () => {
    handleClose();
  };

  return (
    <>
      <Dialog className="alert" open={open} onClose={handleClose} maxWidth="xl">
        <DialogContent>
          <div className="alert-content">
            <h5 className="alert-title" style={{ textAlign: "center" }}>
              {title}
            </h5>
          </div>
          {showDropdown ? (
            <div
              className="form1"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div>
                <SelectDropdown
                  id="select-scheduler"
                  title="Select"
                  value={""}
                  options={schedulerOptions}
                  className="alertDialog"
                  sx={{
                    borderRadius: 2,
                    width: "300px",
                    height: "40px",
                    fontSize: "16px",
                    fontWeight: 500,
                    marginRight: 1,
                    marginLeft: 2,
                  }}
                  onChange={(event) => setSelectedValue(event.target.value)}
                  // onChange={handleDropdownChange}
                />
              </div>
            </div>
          ) : null}
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          <Button
            height="34px"
            width="84px"
            fontSize="14px"
            cursor="pointer"
            fontWeight="500"
            onClick={handleAddClick}
          >
            {okText}
          </Button>
          <Button
            height="34px"
            width="84px"
            fontSize="14px"
            cursor="pointer"
            fontWeight="500"
            onClick={handleNoClick}
          >
            {" "}
            {cancelText}
          </Button>
        </DialogActions>
      </Dialog>
      <Toaster
        open={toasterOpen}
        onClose={() => setToasterOpen(false)}
        severity={toasterSeverity}
        message={toasterMessage}
      />
    </>
  );
};
