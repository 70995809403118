import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Tabs, Tab, Box, Grid } from "@mui/material";
import EditMembers from "./AddMembers/editMemberProfile";
import MemberProfileHistory from "../../components/AdminDashboard/memberProfileHistory";
import ViewMembers from "./AddMembers/viewMemberProfile";
import { useLocation } from "react-router-dom";

const StyledTabs = styled(Tabs)(({ theme }) => ({
  borderBottom: "1px solid #e8e8e8",
  backgroundColor: "white",
  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
  "& .MuiTab-root": {
    textTransform: "none",
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: "0.9rem",
    flex: 1,
    margin: 1,
    padding: "10px 16px",
    border: "1px solid transparent",
    borderRadius: "4px 4px 0 0",
    margin: "0",
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
    "&.Mui-selected": {
      backgroundColor: "var(--mainTheme)",
      color: "#fff",
      borderBottom: "2px solid #007bff",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
    },
    "&:not(:last-child)": {
      borderRight: "10px solid #e8e8e8",
    },
  },
}));

const MemberProfile = () => {
  const location = useLocation();
  const { member } = location.state || {};
  const [value, setValue] = useState(0);
  const [uniqueMemberId, setUniqueMemberId] = useState("");
  const [memId, setMemId] = useState("");

  useEffect(() => {
    if (member) {
      setUniqueMemberId(member._id);
      setMemId(member.memberId);
    }
  }, [member]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const TabsInOrder = () => (
    <Box
      sx={{
        marginTop: "65px",
        marginLeft: { xs: 1, sm: 2 },
        marginRight: { xs: 1, sm: 2 },
        // width: "fit-content",
        width: "100%",
      }}
    >
      <StyledTabs value={value} onChange={handleChange} variant="fullWidth">
        <Tab label="Edit Profile" />
        <Tab label="View Profile" />
        <Tab label="Member History" />
        <Tab label="Communication Letter History" />
        {/* <Tab label="Reports" /> */}
      </StyledTabs>
    </Box>
  );

  const renderContent = () => {
    const commonContent = (
      <div
        className="right-content"
        style={{ marginTop: "1px", padding: { xs: 1, sm: 2 } }}
      >
        {value === 0 && <EditMembers member={member} />}
        {value === 1 && <ViewMembers member={member} />}
        {value === 2 && (
          <MemberProfileHistory uniqueMemberId={uniqueMemberId} memId={memId} />
        )}
        {/* {value === 3 && <div>Dashboard content</div>} */}
        {/* {value === 4 && <div>Reports content</div>} */}
      </div>
    );

    return (
      <>
        <TabsInOrder />
        {commonContent}
      </>
    );
  };

  return (
    <Grid
      container
      justifyContent="center"
      sx={{ marginTop: "20px", px: { xs: 2, sm: 4 } }}
    >
      <Grid item xs={12} md={12} lg={12}>
        {renderContent()}
      </Grid>
    </Grid>
  );
};

export default MemberProfile;
