import { Button, Grid, Box, Typography, TextField } from "@mui/material";
import Divider from "@mui/material/Divider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import toast from "react-hot-toast";
import React, {
  useState,
  useEffect,
  useContext,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ConfirmDigitalSignatureDialog from "../../../Confirm.digital.signature";
import {
  CustomCheckboxField,
  CustomSelectField,
  CustomLabelField,
  CustomHeadingField,
  CustomImageField,
} from "../../../../DynamicFormMUI/customFields";

import { FormControl, Stack } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { DragNDropContext } from "../../../../../../contexts/DragNDropContext";

import { getInputsData } from "./patientFormData";
import { getFormData } from "../../../../DynamicFormMUI/fillHraFormData";

import { useUserContext } from "../../../../../../contexts/UserContext";
import QueryForm from "../../../Fill_HRA/QueryForm";
import { putApi, getApi } from "../../../../../../utils/axiosApiWrapper";
import Labels from "../../../../../../resource_files/Labels.resx";
import Toaster from "../../../../../toaster/toaster";

import Alert from "../../../../../Alert/Alert";
import _lodash, { set } from "lodash";
import AppointmentModal from "../../../../../AppointmentModal/AppointmentModal";
import FillHraFormContext from "../../../../../../contexts/fillHraFormContext";
import { useFillHraContext } from "../../../../../../contexts/fillHraFormContext";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import LoadingOverlay from "react-loading-overlay";
import focusCaresLoader from "../../../../../../loader/focuscares_loader.gif";
function getReroute() {
  let path;
  const roleId = sessionStorage.getItem("roleId");
  if (roleId === "5") {
    path = "/NurseDashboard/Nurselist";
  } else {
    path = "/AdminDashboard/MemberList";
  }
  return path;
}
const PatientSummary = forwardRef((props, _ref) => {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const [_providerSignatureHra, setProviderSignatureHra] = useState("");
  useEffect(() => {
    const __providerSignature = sessionStorage.getItem("providerSignatureHra");
    setProviderSignatureHra(__providerSignature);
  });
  const [visitTypeSaveBtn, setVisitTypeSaveBtn] = useState();
  const [assessorsCommentsSaveBtn, setAssessorsComentSaveBtn] = useState();
  const [memberAcknowledgementSaveBtn, setMemberAcknowledgementSaveBtn] =
    useState();
  const [acceptDisclosureSaveBtn, setAcceptDisclosureSaveBtn] = useState("2");
  const [evaluationDateSaveBtn, setEvaluationDateSaveBtn] = useState("");
  const [examFinishDateSaveBtn, setExamFinishDateSaveBtn] = useState("");
  const [consentedToVideoSaveBtn, setConsentToVideoSaveBtn] = useState("");
  const [
    memberConsentedToCompleteVirtualVisitSaveBtn,
    setMemberConsentedToCompleteVirtualVisitSaveBtn,
  ] = useState("");
  const [
    membersIdentityConfirmedWithIDSaveBtn,
    setMembersIdentityConfirmedWithIDSaveBtn,
  ] = useState("");
  const [commentSaveBtn, setCommentSaveBtn] = useState("");

  const [totalScore, setTotalScore] = useState();
  const [examFinishDate, setExamFinishDate] = useState("");
  const [evaluationDate, setEvaluationDate] = useState("");
  const {
    handleButtonAction,
    setSelectedOption,
    selectIndex,
    setSelectIndex,
    commentIndex,
    setCommentIndex,
    openSignSubmitDialog,
    setSignSubmitDialog,
  } = useContext(DragNDropContext);
  const { getDisableFlag } = useFillHraContext();
  const sectionId = props.currSection || 5;
  const clientPlan = localStorage.getItem("clientPlan") || storedPlan;
  const clientYear = localStorage.getItem("clientYear") || storedYear;
  const inputsJsonData = getInputsData(clientPlan, clientYear);

  const [loading, setLoading] = useState(undefined);
  const [dForm, setDForm] = useState([]);
  const [sections, setSections] = useState([]);
  const [currSection, setCurrSection] = useState(0);
  const [apiData, setApiData] = useState([]);
  const [isShowSection, setIsShowSection] = useState({});

  const sectionRefs = sections.map(() => React.createRef());
  const {
    user,
    storedPlan,
    storedYear,
    selectedTimeZoneUtc,
    setSideBarSectionDisable,
  } = useUserContext();

  const { roleId } = user;

  const { getAssessmentData, _memberIdForQueryForm } = useUserContext();
  const [memberId, setMemberId] = useState(null);
  const [formData, setFormData] = useState({});

  const [comment, setComment] = useState("");
  const [isVisitTypeAlertOpen, setIsVisitTypeAlertOpen] = useState(false);
  const [isEvaluationDateAlertOpen, setIsEvaluationDateAlertOpen] =
    useState(false);
  const [selectedVisitType, setSelectedVisitType] = useState(null);
  const [emptyDateErrorPopup, setEmptyDateErrorPopup] = useState(false);
  const [emptyDateErrorMessg, setEmptyDateErrorMessg] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const assessmentId = location?.state?.assessmentId
    ? location?.state?.assessmentId
    : sessionStorage.getItem("assessmentId");
  const tabId = location?.state?.tabId
    ? location?.state?.tabId
    : sessionStorage.getItem("tabId");
  const hraStatus = location?.state?.hraStatus
    ? location?.state?.hraStatus
    : sessionStorage.getItem("hraStatus");
  // const onCloseToaster = () => {
  // };

  const updateCommentInFormData = (fieldName, commentValue) => {
    setAssessorsComentSaveBtn(commentValue);
    commentSaveBtn(commentValue);
  };

  const [previousVisitType, setPreviousVisitType] = useState(null);

  const handleVisitTypeCancel = () => {
    setIsVisitTypeAlertOpen(false);

    if (previousVisitType !== null) {
      setSelectedVisitType(previousVisitType);
    } else {
    }
  };

  const [showConsentedToVideo, setShowConsentedToVideo] = useState(false);
  const [
    showMemberConsentedToCompleteVirtualVisit,
    setShowMemberConsentedToCompleteVirtualVisit,
  ] = useState(false);
  const [
    showMembersIdentityConfirmedWithID,
    setShowMembersIdentityConfirmedWithID,
  ] = useState(false);
  const [showComment, setShowComment] = useState(false);

  useEffect(() => {
    if (formData.visitType === "virtual") {
      setShowConsentedToVideo(true);
      setShowMemberConsentedToCompleteVirtualVisit(true);
      setShowMembersIdentityConfirmedWithID(true);
      setShowComment(true);
    } else {
      setShowConsentedToVideo(false);
      setShowMemberConsentedToCompleteVirtualVisit(false);
      setShowMembersIdentityConfirmedWithID(false);
      setShowComment(false);
    }
  }, [formData.visitType]);

  const handleVisitTypeChange = (e) => {
    const newVisitType = e.target.value;

    const requiresConfirmation =
      newVisitType === "virtual" || newVisitType === "inPerson";
    if (newVisitType == "virtual") {
      setConsentToVideoSaveBtn("2");
      setMemberConsentedToCompleteVirtualVisitSaveBtn("2");
      setMembersIdentityConfirmedWithIDSaveBtn("2");
      setVisitTypeSaveBtn("2");
    }
    if (newVisitType == "inPerson") {
      setVisitTypeSaveBtn("1");
    }
    if (requiresConfirmation) {
      setIsVisitTypeAlertOpen(true);

      setSelectedVisitType(newVisitType);

      return newVisitType;
    } else {
      setSelectedVisitType(newVisitType);
    }
  };

  const handleVisitTypeUpdate = async () => {
    let visitTypeId;

    if (selectedVisitType === "inPerson") {
      visitTypeId = "1";
    } else if (selectedVisitType === "virtual") {
      visitTypeId = "2";
    }
    setVisitTypeSaveBtn(visitTypeId);
    const _id = _memberIdForQueryForm;
    if (visitTypeId == "2") {
      setConsentToVideoSaveBtn("2");
      setMemberConsentedToCompleteVirtualVisitSaveBtn("2");
      setMembersIdentityConfirmedWithIDSaveBtn("2");
    } else if (visitTypeId == "1") {
      setConsentToVideoSaveBtn("2");
      setMemberConsentedToCompleteVirtualVisitSaveBtn("2");
      setMembersIdentityConfirmedWithIDSaveBtn("2");
    }
    if (visitTypeId && _id) {
      const requestBody = {
        data: {
          visitType: visitTypeId,
          _id,
        },
      };

      try {
        const response = await putApi(
          "/visitTypes/updateVisiteType",
          requestBody,
        );

        if (response.status === 200) {
          setIsVisitTypeAlertOpen(false);
          toast.success(response.data.message, {});
          setShowConsentedToVideo(selectedVisitType === "virtual");
          setShowMemberConsentedToCompleteVirtualVisit(
            selectedVisitType === "virtual",
          );
          setShowMembersIdentityConfirmedWithID(
            selectedVisitType === "virtual",
          );
          setShowComment(selectedVisitType === "virtual");

          setSelectedVisitType(selectedVisitType);
          const updatedFormData = {
            ...formData,
            visitType: selectedVisitType,
            //consentedToVideo: selectedVisitType === "virtual" ? "1" : "",
            //membersIdentityConfirmedWithID: selectedVisitType === "virtual" ? "1" : "",

            //memberConsentedToCompleteVirtualVisit: selectedVisitType === "virtual" ? "1" : "",
          };
          setFormData(updatedFormData);
        } else {
          return;
        }
      } catch (error) {
        return;
      } finally {
        setIsVisitTypeAlertOpen(false);
      }
    }
  };

  const [clinicianName, setClinicianName] = useState("");
  const [speciality, setSpeciality] = useState("");
  const [providerSignature, setProviderSignature] = useState("");

  useEffect(() => {
    const roleId = sessionStorage.getItem("roleId");
    if (roleId == 5) {
      const fname = sessionStorage.getItem("firstName");
      const lname = sessionStorage.getItem("lastName");
      const name = `${fname} ${lname}`;
      setClinicianName(name);
      const _speciality = sessionStorage.getItem("speciality");
      setSpeciality(_speciality);
      const providerSignature = sessionStorage.getItem("providerSignature");
      setProviderSignature(providerSignature);
    }
  }, [formData]);

  const handleChangeCheckbox = (e, inputProps) => {
    const { checked, value } = e.target;

    setFormData((prevFormData) => {
      const updatedFormData = { ...prevFormData };
      //setConsentToVideoSaveBtn
      if (inputProps.name === "memberAcknowledgement") {
        const _updatedMemberAknowlwdgement = checked ? "1" : "2";
        updatedFormData.memberAcknowledgement = checked ? "1" : "2";
        setMemberAcknowledgementSaveBtn(_updatedMemberAknowlwdgement);
      } else if (inputProps.name === "acceptDisclosure") {
        const _acceptDisclosure = checked ? "1" : "2";
        updatedFormData.acceptDisclosure = checked ? "1" : "2";
        setAcceptDisclosureSaveBtn(_acceptDisclosure);
      } else if (inputProps.name === "consentedToVideo") {
        const _consentToVideo = checked ? "1" : "2";
        updatedFormData.consentedToVideo = checked ? "1" : "2";
        setConsentToVideoSaveBtn(_consentToVideo);
      } else if (inputProps.name === "memberConsentedToCompleteVirtualVisit") {
        const _memberConsentedToCompleteVirtualVisit = checked ? "1" : "2";
        updatedFormData.memberConsentedToCompleteVirtualVisit = checked
          ? "1"
          : "2";
        setMemberConsentedToCompleteVirtualVisitSaveBtn(
          _memberConsentedToCompleteVirtualVisit,
        );
      } else if (inputProps.name === "membersIdentityConfirmedWithID") {
        const _membersIdentityConfirmedWithID = checked ? "1" : "2";
        updatedFormData.membersIdentityConfirmedWithID = checked ? "1" : "2";
        setMembersIdentityConfirmedWithIDSaveBtn(
          _membersIdentityConfirmedWithID,
        );
      }
      return updatedFormData;
    });
  };

  const [providerSignAllDashboard, setProviderSignAllDashboard] =
    useState(null);
  const [noShowUpdateHraStatusPopup, setNoShowUpdateHraStatusPopup] =
    useState(false);
  const [
    cancelAssessmentUpdateHraStatusPopup,
    setCancelAssessmentUpdateHraStatusPopup,
  ] = useState(false);
  const [
    cancelAppointmentUpdateHraStatusPopup,
    setCancelAppointmentUpdateHraStatusPopup,
  ] = useState(false);

  const fetchData = async () => {
    try {
      setLoading(true);
      setSideBarSectionDisable(true);
      const assessmentDataFromAPI = await getApi(
        `/hra/get-assessment?id=${assessmentId}`,
      );

      if (assessmentDataFromAPI?.data) {
        const assessmentDBData = assessmentDataFromAPI.data; //assessmentDBData  = assessmentDataFromAPI.data.data

        let dbFormData = {};

        if (
          Boolean(
            JSON.parse(
              JSON.stringify(sessionStorage.getItem("selectedMemberVisitType")),
            ),
          )
        ) {
          const visitType = sessionStorage.getItem("selectedMemberVisitType");
          if (visitType == "In Person") {
            dbFormData = {
              ...dbFormData,
              visitType: "inPerson",
            };
            setVisitTypeSaveBtn("1");
          } else if (visitType == "Virtual") {
            dbFormData = {
              ...dbFormData,
              visitType: "virtual",
            };
            setVisitTypeSaveBtn("2");
          }
        }

        setFormData(dbFormData);
        if (assessmentDBData) {
          const { data } = assessmentDBData;
          setMemberId(data._id);

          const patientDBData = data?.patient?.data;
          if (
            true ||
            (patientDBData && Object.keys(patientDBData).length > 0) ||
            JSON.parse(
              JSON.stringify(sessionStorage.getItem("selectedMemberVisitType")),
            )
          ) {
            //patient summary
            const acceptDisclosureValue = patientDBData?.acceptDisclosure || "";

            const memberAcknowledgementValue =
              patientDBData?.memberAcknowledgement || "";
            const consentedToVideoValue =
              patientDBData?.visitType?.consentedToVideo || "";
            const memberConsentedToCompleteVirtualVisitValue =
              patientDBData?.memberConsentedToCompleteVirtualVisit || "";
            const membersIdentityConfirmedWithIDValue =
              patientDBData?.membersIdentityConfirmedWithID || "";
            const assessorsComments = patientDBData?.assessorsComments || "";
            const commentValue = patientDBData?.comment || "";
            const _evaluationDate = patientDBData?.evaluationDate;
            const _examFinishDate = patientDBData?.examFinishDate;
            const finalDate = sessionStorage.getItem("finalDate");
            if (_evaluationDate?.toString().includes("T")) {
              setEvaluationDate(_evaluationDate);
              setEvaluationDateSaveBtn(_evaluationDate);
              setPreviousEvaluationDateTime(_evaluationDate);
            } else if (finalDate?.toString()?.includes("T")) {
              setEvaluationDate(finalDate);
              setEvaluationDateSaveBtn(_evaluationDate);
              setPreviousEvaluationDateTime(finalDate);
            } else {
              setEvaluationDate("");
            }
            if (_examFinishDate?.toString().includes("T")) {
              setExamFinishDate(_examFinishDate);
            } else {
              setExamFinishDate("");
            }
            setProviderSignAllDashboard(data?.patient?.data?.providerSignature);

            const visitTypeData = data.visitType + ""; //patientDBData.visitType || {};
            console.log("visitTypeData", visitTypeData);
            if (visitTypeData == "2") {
              dbFormData = {
                ...dbFormData,
                visitType: "virtual",
              };
              setVisitTypeSaveBtn("2");
            } else if (visitTypeData == "1") {
              setVisitTypeSaveBtn("1");
              dbFormData = {
                ...dbFormData,
                visitType: "inPerson",
              };
            }

            dbFormData = {
              // patient summary
              ...dbFormData,
              acceptDisclosure: acceptDisclosureValue || "2",
              memberAcknowledgement: memberAcknowledgementValue,
              consentedToVideo: consentedToVideoValue,
              memberConsentedToCompleteVirtualVisit:
                memberConsentedToCompleteVirtualVisitValue,
              membersIdentityConfirmedWithID:
                membersIdentityConfirmedWithIDValue,
              assessorsComments: assessorsComments || "",
              comment: commentValue,
              evaluationDate: finalDate?.toString().includes("T")
                ? finalDate
                : null,
              examFinishDate: _examFinishDate?.toString().includes("T")
                ? _examFinishDate
                : null,
            };
            console.log("visitTypeData", visitTypeData);
            // setVisitTypeSaveBtn(
            //   visitTypeData
            //     ? visitTypeData + ""
            //     : JSON.parse(
            //         JSON.stringify(
            //           sessionStorage.getItem("selectedMemberVisitType")
            //         )
            //       ) == "In Person"
            //     ? "1"
            //     : JSON.parse(
            //         JSON.stringify(
            //           sessionStorage.getItem("selectedMemberVisitType")
            //         )
            //       ) == "Virtual"
            //     ? "2"
            //     : ""
            // );

            setAssessorsComentSaveBtn(assessorsComments);
            setCommentSaveBtn(commentValue);
            setMemberAcknowledgementSaveBtn(memberAcknowledgementValue);
            setAcceptDisclosureSaveBtn(acceptDisclosureValue);
            setConsentToVideoSaveBtn(consentedToVideoValue);
            setMemberConsentedToCompleteVirtualVisitSaveBtn(
              memberConsentedToCompleteVirtualVisitValue,
            );
            setMembersIdentityConfirmedWithIDSaveBtn(
              membersIdentityConfirmedWithIDValue,
            );
            setTotalScore(patientDBData?.totalScore);
          }
          setFormData(dbFormData);
          setLoading(false);
          setSideBarSectionDisable(false);
        }
      }
      setLoading(false);
      setSideBarSectionDisable(false);
    } catch (error) {
      setLoading(false);
      setSideBarSectionDisable(false);
      return;
    }
  };
  const updateHraStatusToInProgress = async (cancelComment) => {
    const requestBody = {
      data: {
        _id: _memberIdForQueryForm,
        hraStatus: {
          hraStatus: 2,
          subStatus: "",
          cancelStatus: "",
        },
      },
    };
    try {
      const response = await putApi("/hra-status/updateHraStatus", requestBody);

      if (response.data.code === 200) {
        setSignSubmitDialog(false);
        toast.success("Hra Status Updated Successfully", {});
      } else {
        return;
      }
    } catch (error) {
      return;
    }
  };

  const updateHraStatus = async (cancelComment) => {
    const requestBody = {
      data: {
        _id: _memberIdForQueryForm,
        hraStatus: {
          hraStatus: 6,
          subStatus: "",
          cancelStatus: "",
        },
      },
    };
    try {
      const response = await putApi("/hra-status/updateHraStatus", requestBody);

      if (response.data.code === 200) {
        setSignSubmitDialog(false);
        toast.success("Hra Status Updated Successfully", {});
      } else {
        return;
      }
    } catch (error) {
      return;
    }
  };

  const customUpdateHraStatus = async (hraStatusToBeUpdated) => {
    const requestBody = {
      data: {
        _id: _memberIdForQueryForm,
        hraStatus: {
          hraStatus: hraStatusToBeUpdated, //6,
          subStatus: "",
          cancelStatus: "",
          cancelText: "",
        },
      },
    };
    try {
      const response = await putApi("/hra-status/updateHraStatus", requestBody);

      if (response.data.code === 200) {
        setSignSubmitDialog(false);
        toast.success("Hra Status Updated Successfully", {});
        navigate(getReroute());
      } else {
        setSignSubmitDialog(false);
        toast.error("Failed to update HRA Status", {});
      }
    } catch (error) {
      setSignSubmitDialog(false);
      toast.error("Failed to update HRA Status", {});
    }
  };

  const handlePatientSave = async (providerSignature) => {
    try {
      const formDataCamelCase = {};
      Object.keys(formData).forEach((key) => {
        const camelCaseKey = key.replace(/_./g, (x) => x[1].toUpperCase());
        formDataCamelCase[camelCaseKey] = formData[key];
      });

      const patientData = {};

      const visitType = {
        isVisitType: visitTypeSaveBtn,
        consentedToVideo: consentedToVideoSaveBtn
          ? consentedToVideoSaveBtn
          : "",
      };

      patientData.visitType = visitType;
      patientData.assessorsComments = assessorsCommentsSaveBtn
        ? assessorsCommentsSaveBtn
        : "";
      patientData.memberAcknowledgement = memberAcknowledgementSaveBtn
        ? memberAcknowledgementSaveBtn
        : "";
      patientData.acceptDisclosure = acceptDisclosureSaveBtn
        ? acceptDisclosureSaveBtn
        : "";
      patientData.evaluationDate = evaluationDate;
      patientData.examFinishDate = examFinishDate;

      const payload = {
        id: assessmentId,
        year: clientYear,
        data: {
          name: "Patient Summary",
          data: {
            ...patientData,
            providerSignature: providerSignature ? providerSignature : "",
          },
        },
      };
      if (tabId == 1) {
        updateHraStatusToInProgress();
      }
      const response = await putApi("/hra/assessment", payload);
      if (response.status === 200) {
        setSignSubmitDialog(false);
        toast.success("Assessment Updated Successfully", {});
        // navigate(getReroute());
        const roleId = sessionStorage.getItem("roleId");

        if (roleId === "5") {
          if (providerSignature) {
            await updateHraStatus();
          }
          navigate("/NurseDashboard/Nurselist");
        } else {
          navigate("/AdminDashboard/MemberList");
        }
      } else {
        return;
      }
    } catch (error) {
      return;
    }
  };

  const handlePatientSave2024 = async (providerSignature) => {
    try {
      const formDataCamelCase = {};
      Object.keys(formData).forEach((key) => {
        const camelCaseKey = key.replace(/_./g, (x) => x[1].toUpperCase());
        formDataCamelCase[camelCaseKey] = formData[key];
      });

      const patientData = {};

      const visitType = {
        isVisitType: visitTypeSaveBtn,
      };

      patientData.memberConsentedToCompleteVirtualVisit =
        memberConsentedToCompleteVirtualVisitSaveBtn
          ? memberConsentedToCompleteVirtualVisitSaveBtn
          : "";
      patientData.membersIdentityConfirmedWithID =
        membersIdentityConfirmedWithIDSaveBtn
          ? membersIdentityConfirmedWithIDSaveBtn
          : "";
      patientData.visitType = visitType;
      patientData.assessorsComments = assessorsCommentsSaveBtn
        ? assessorsCommentsSaveBtn
        : "";
      patientData.comment = commentSaveBtn ? commentSaveBtn : "";
      patientData.memberAcknowledgement = memberAcknowledgementSaveBtn
        ? memberAcknowledgementSaveBtn
        : "";
      patientData.acceptDisclosure = acceptDisclosureSaveBtn
        ? acceptDisclosureSaveBtn
        : "";
      patientData.evaluationDate = evaluationDate;
      patientData.examFinishDate = examFinishDate;

      const payload = {
        id: assessmentId,
        year: clientYear,
        data: {
          name: "Patient Summary",
          data: {
            ...patientData,
            providerSignature: providerSignature ? providerSignature : "",
          },
        },
      };
      if (tabId == 1) {
        updateHraStatusToInProgress();
      }

      const response = await putApi("/hra/assessment", payload);
      if (response.status === 200) {
        setSignSubmitDialog(false);
        toast.success("Assessment Updated Successfully", {});
        // navigate(getReroute());
        const roleId = sessionStorage.getItem("roleId");

        if (roleId === "5") {
          if (providerSignature) {
            await updateHraStatus();
          }
          navigate("/NurseDashboard/Nurselist");
        } else {
          navigate("/AdminDashboard/MemberList");
        }
      } else {
        return;
      }
    } catch (error) {
      return;
    }
  };

  const handleSave = (providerSignature) => {
    if (clientYear === "2024") {
      handlePatientSave2024(providerSignature);
    } else {
      handlePatientSave(providerSignature);
    }
  };
  const [previousEvaluationDateTime, setPreviousEvaluationDateTime] =
    useState(null);
  const [errorSignSubmitBtn, setErrorSignSubmitBtn] = useState(false);
  const [errorSignSubmitBtnMsg, setErrorSignSubmitBtnMsg] = useState("");

  const handleDateChange = (inputProps, date) => {
    const dateString = date;
    if (inputProps.name === "examFinishDate") {
      setExamFinishDateSaveBtn(dateString);
      setExamFinishDate(dateString);
      setFormData((prevFormData) => {
        const updatedFormData = { ...prevFormData };

        updatedFormData.examFinishDate = dateString;

        return updatedFormData;
      });
    }
    if (inputProps.name === "evaluationDate") {
      setEvaluationDateSaveBtn(dateString);
      setFormData((prevFormData) => {
        const updatedFormData = { ...prevFormData };

        updatedFormData.evaluationDate = dateString;

        const requiresConfirmation = inputProps.name === "evaluationDate";
        setEvaluationDate(dateString);
        return updatedFormData;
      });
      handleAssignedTermDateTypeUpdate(dateString);
    }
  };
  const clearExamFinishDate = () => {
    setExamFinishDateSaveBtn(null);
    setExamFinishDate(null);
    setFormData((prevFormData) => {
      const updatedFormData = { ...prevFormData };

      updatedFormData.examFinishDate = null;

      return updatedFormData;
    });
  };
  const handleAssignedTermDateTypeUpdate = async (evaluationDate) => {
    const memberObjIds = [_memberIdForQueryForm];

    if (evaluationDate) {
      const requestBody = {
        memberObjIds,
        evaluationDate: evaluationDate,
      };

      try {
        const response = await putApi(
          "/assignClinician/updateScheduleandTermDate",
          requestBody,
        );
        setIsEvaluationDateAlertOpen(false);
        if (response.status === 200) {
          setEvaluationDate(evaluationDate);
          setFormData((prevFormData) => ({
            ...prevFormData,
            evaluationDate: evaluationDate,
          }));
        } else {
          return;
        }
      } catch (error) {
        return;
      } finally {
        setIsEvaluationDateAlertOpen(false);
      }
    }
  };

  const handleAssignedTermDateTypeCancel = () => {
    setIsEvaluationDateAlertOpen(false);
  };

  useEffect(() => {
    const finalDate = sessionStorage.getItem("finalDate");
    if (finalDate?.toString().includes("T")) {
      setEvaluationDate(finalDate);
      setEvaluationDateSaveBtn(finalDate);
      setPreviousEvaluationDateTime(finalDate);
    }
    fetchData();
  }, [currSection, sections]);

  useEffect(() => {
    const getData = async () => {
      const data = await getFormData();
      setApiData(data);
    };

    getData();
  }, [currSection, sections]);

  const handleChange = (e, inputProps, subques) => {
    const { name, value } = e.target;

    const updatedFormData = { ...formData };

    if (inputProps && inputProps.type === "options_tree") {
      let selectedValue = value;

      if (inputProps.isExclusive) {
        updatedFormData[name] = value;
      } else {
        const currentValue = updatedFormData[name];

        if (currentValue === value) {
          selectedValue = "";

          if (inputProps.hasSubQuestions && updatedFormData.subOptions) {
            updatedFormData.subOptions = "";
          }
        }
      }

      if (inputProps.isExclusive) {
        updatedFormData[name] = value;
      } else {
        const currentValue = updatedFormData[name];

        if (currentValue === value) {
          selectedValue = "";

          if (inputProps.hasSubQuestions && updatedFormData.subOptions) {
            updatedFormData.subOptions = "";
          }
        }
      }

      updatedFormData[name] =
        inputProps.isExclusive && Array.isArray(selectedValue)
          ? selectedValue.join(", ")
          : selectedValue;

      const newSelectIndex = { ...selectIndex };
      newSelectIndex[inputProps.questionId + selectedValue] = true;

      for (const key in newSelectIndex) {
        if (
          key.startsWith(inputProps.questionId) &&
          key !== inputProps.questionId + selectedValue
        ) {
          newSelectIndex[key] = false;
        }
      }

      setSelectIndex(newSelectIndex);
      setSelectedOption(selectedValue);
    } else {
      updatedFormData[name] = value;
    }

    setFormData(updatedFormData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  // useEffect(() => {
  //   setLoading(() => true);
  //   const getData = async () => {
  //     const fullAPIData = await getFormData();
  //     if (fullAPIData && fullAPIData.length > 0) {
  //       const currentSectionData = fullAPIData[currSection]
  //         ? [fullAPIData[currSection]]
  //         : [];
  //       const data =
  //         currentSectionData && currentSectionData.length > 0
  //           ? currentSectionData[0].questions
  //           : [];
  //       setSections(() => fullAPIData);
  //       setDForm(() => data);
  //       setLoading(() => false);
  //     }
  //   };

  //   getData();
  // }, []);
  // useEffect(() => {
  //   setLoading(() => true);
  //   const getData = async () => {
  //     if (sections && sections.length > 0) {
  //       const currentSectionData = sections[currSection]
  //         ? [sections[currSection]]
  //         : [];
  //       const data =
  //         currentSectionData && currentSectionData.length > 0
  //           ? currentSectionData[0].questions
  //           : [];

  //       setDForm(() => data);
  //     }
  //   };

  //   getData();
  // }, [sections, currSection]);

  // useEffect(() => {
  //   let isAllConfigured = false;
  //   let isAnyModified = false;

  //   sections.forEach((section) => {
  //     section.questions.forEach((question) => {
  //       if (question.isConfigured) {
  //         isAllConfigured = true;
  //       }
  //       if (question.isModified) {
  //         isAnyModified = true;
  //       }
  //     });
  //   });
  // }, [dForm, sections]);

  function isValidUrl(url) {
    const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
    return urlRegex.test(url);
  }
  const getMaxDate = (inputProps) => {
    if (
      inputProps.name == "evaluationDate" ||
      inputProps.name == "examFinishDate"
    ) {
      const roleId = sessionStorage.getItem("roleId");
      const maxDate = new Date();
      maxDate.setFullYear(new Date().getFullYear() + 1);
      if (roleId == 1 || roleId == 2) {
        return dayjs(maxDate);
      }
      if (roleId == 5 && !evaluationDate) {
        return dayjs(maxDate);
      }
      if (roleId == 5 && evaluationDate) {
        return dayjs(evaluationDate);
      }
      if (
        roleId == 5 &&
        evaluationDate &&
        inputProps.name == "examFinishDate"
      ) {
        return dayjs(evaluationDate);
      }
    }
  };
  const getMinDate = (inputProps) => {
    const minDate = new Date();
    const roleId = sessionStorage.getItem("roleId");
    minDate.setFullYear(new Date().getFullYear() - 1);
    if (
      inputProps.name == "evaluationDate" ||
      inputProps.name == "examFinishDate"
    ) {
      if (roleId == 1 || roleId == 2) {
        return dayjs(minDate);
      }
      if (roleId == 5 && !evaluationDate) {
        return dayjs(new Date());
      }
      if (roleId == 5 && evaluationDate) {
        return dayjs(evaluationDate);
      }
    }
  };
  const [statusComment, setStatusComment] = useState("");
  const [cancleStatus, setCancleStatus] = useState("");

  const handleCancelAppointmentAlertYesClick = async (cancelComment) => {
    const requestBody = {
      data: {
        _id: _memberIdForQueryForm,
        hraStatus: {
          hraStatus: 3,
          subStatus: "",
          cancelStatus: cancleStatus,
          cancelText: statusComment,
        },
      },
    };
    try {
      const response = await putApi("/hra-status/updateHraStatus", requestBody);

      if (response.data.code === 200) {
        setSignSubmitDialog(false);
        toast.success("Hra Status Updated Successfully", {});
        navigate(getReroute());
      } else {
        setSignSubmitDialog(false);
        toast.error("Failed to update HRA Status", {});
      }
    } catch (error) {
      setSignSubmitDialog(false);
      toast.error("Failed to update HRA Status", {});
    }

    setCancelAppointmentUpdateHraStatusPopup(false);
  };

  useImperativeHandle(_ref, () => ({
    getPayloadData: () => {
      return {
        setAssessorsComentSaveBtn: setAssessorsComentSaveBtn,
        setCommentSaveBtn: setCommentSaveBtn,
        setMemberAcknowledgementSaveBtn: setMemberAcknowledgementSaveBtn,
        setAcceptDisclosureSaveBtn: setAcceptDisclosureSaveBtn,
        setEvaluationDateSaveBtn: setEvaluationDateSaveBtn,
        setExamFinishDateSaveBtn: setExamFinishDateSaveBtn,
        setConsentToVideoSaveBtn: setConsentToVideoSaveBtn,
        setMembersIdentityConfirmedWithIDSaveBtn:
          setMembersIdentityConfirmedWithIDSaveBtn,

        setMemberConsentedToCompleteVirtualVisitSaveBtn:
          setMemberConsentedToCompleteVirtualVisitSaveBtn,
        setTotalScore: setTotalScore,
        providerSignAllDashboard: providerSignAllDashboard,
        setProviderSignAllDashboard: setProviderSignAllDashboard,
        acceptDisclosureSaveBtn: acceptDisclosureSaveBtn,
        examFinishDateSaveBtn: examFinishDateSaveBtn,
        evaluationDateSaveBtn: evaluationDateSaveBtn,
        consentedToVideoSaveBtn: consentedToVideoSaveBtn,
        membersIdentityConfirmedWithIDSaveBtn:
          membersIdentityConfirmedWithIDSaveBtn,
        memberConsentedToCompleteVirtualVisitSaveBtn:
          memberConsentedToCompleteVirtualVisitSaveBtn,
        assessorsCommentsSaveBtn: assessorsCommentsSaveBtn,
        commentSaveBtn: commentSaveBtn,
        memberAcknowledgementSaveBtn: memberAcknowledgementSaveBtn,
        visitTypeSaveBtn: visitTypeSaveBtn,
        examFinishDate: examFinishDate,
        setExamFinishDate: setExamFinishDate,
        evaluationDate: evaluationDate,
        setEvaluationDate: setEvaluationDate,
      };
    },
  }));

  useEffect(() => {
    props.setAutoSavePatientData({
      providerSignAllDashboard: providerSignAllDashboard,
      acceptDisclosureSaveBtn: acceptDisclosureSaveBtn,
      examFinishDateSaveBtn: examFinishDateSaveBtn,
      evaluationDateSaveBtn: evaluationDateSaveBtn,
      consentedToVideoSaveBtn: consentedToVideoSaveBtn,
      membersIdentityConfirmedWithIDSaveBtn:
        membersIdentityConfirmedWithIDSaveBtn,
      memberConsentedToCompleteVirtualVisitSaveBtn:
        memberConsentedToCompleteVirtualVisitSaveBtn,
      assessorsCommentsSaveBtn: assessorsCommentsSaveBtn,
      commentSaveBtn: commentSaveBtn,
      memberAcknowledgementSaveBtn: memberAcknowledgementSaveBtn,
      visitTypeSaveBtn: visitTypeSaveBtn,
      examFinishDate: examFinishDate,
      evaluationDate: evaluationDate,
    });
  }, [
    providerSignAllDashboard,
    acceptDisclosureSaveBtn,
    examFinishDateSaveBtn,
    evaluationDateSaveBtn,
    consentedToVideoSaveBtn,
    membersIdentityConfirmedWithIDSaveBtn,
    memberConsentedToCompleteVirtualVisitSaveBtn,
    memberConsentedToCompleteVirtualVisitSaveBtn,
    assessorsCommentsSaveBtn,
    commentSaveBtn,
    memberAcknowledgementSaveBtn,
    visitTypeSaveBtn,
    examFinishDate,
    evaluationDate,
  ]);

  const [closeDatePicker, setCloseDatePicker] = useState(false);
  useEffect(() => {
    if (
      Boolean(evaluationDate) &&
      Boolean(examFinishDate) &&
      closeDatePicker &&
      new Date(dayjs(evaluationDate).tz(selectedTimeZoneUtc)).getTime() >=
        new Date(dayjs(examFinishDate).tz(selectedTimeZoneUtc)).getTime()
      // new Date(dayjs(evaluationDate).tz("IST")) >=
      //   new Date(dayjs(examFinishDate).tz("IST"))
      // ||
      //   dayjs(evaluationDateSaveBtn).tz("IST") >=
      //     dayjs(examFinishDateSaveBtn).tz("IST"))
    ) {
      setEmptyDateErrorPopup(true);
      setEmptyDateErrorMessg(
        "Exam Finished Time should be greater than Exam Start Time",
      );
      clearExamFinishDate();
    } else {
      setCloseDatePicker(false);
    }
  }, [closeDatePicker]);

  return (
    <>
      <LoadingOverlay
        styles={{
          // wrapper: { marginLeft: "50px", marginTop: "50px" },
          // overlay: {},
          content: { marginLeft: "40vw", marginTop: "50px" },
          // spinner: { marginLeft: "50px", marginTop: "50px" },
          spinner: (base) => ({
            ...base,
            marginLeft: "50px",
            marginTop: "50px",
          }),
        }}
        style={{ marginTop: "20px" }}
        active={loading}
        spinner={<img src={focusCaresLoader} alt="Loader" />}
        // text="Loading your content..."
      >
        <Grid
          container
          direction="row"
          justifyContent="space-evenly"
          alignItems="flex-start"
          style={{ fontFamily: "Inter !important" }}
        >
          <Grid
            item
            xs={12}
            style={{ padding: "2px", textAlign: "center !important" }}
          >
            <Box>
              {apiData
                .filter((section) => section.sectionId === 5)
                .map((section, index) => (
                  <div key={index}>
                    <div ref={sectionRefs[index]} className="section">
                      <span
                        style={{
                          width: "100% !important",
                          margin: "0 !important",
                          padding: "0 !important",
                        }}
                      ></span>

                      <form
                        onSubmit={(e) => handleSubmit(e)}
                        style={{
                          marginBottom: "20px",
                          marginTop: "10px",
                          marginLeft: "10px",
                          marginRight: "10px",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Grid
                          container
                          spacing={2}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          {isShowSection[index] === true ||
                          isShowSection[index] === undefined ||
                          props.inView ? (
                            inputsJsonData?.questions?.map(
                              (inputProps, quesindex) => {
                                if (
                                  inputProps.type === "text" ||
                                  inputProps.type === "number" ||
                                  inputProps.type === "email" ||
                                  inputProps.type === "password"
                                ) {
                                  return (
                                    <Grid xs={inputProps.xs} item>
                                      {inputProps?.name ==
                                      "assessorsComments" ? (
                                        <TextField
                                          disabled={
                                            getDisableFlag() ||
                                            tabId == "3" ||
                                            (roleId == 5 && tabId == 3)
                                          }
                                          multiline
                                          style={{
                                            display: "flex",
                                            width: "100%",
                                            height: "100px",
                                            overflowY: "scroll",
                                          }}
                                          variant="outlined"
                                          sx={{
                                            height: "48px",
                                            border:
                                              inputProps.isConfigured === true
                                                ? "1.5px solid var(--mainTheme) !important"
                                                : " 1.5px solid rgba(255, 45, 49, 0.7) !important",
                                            "& .MuiFormLabel-root": {
                                              backgroundColor: "white",
                                            },
                                            "& .MuiInputLabel-root": {
                                              backgroundColor: "white",
                                            },
                                          }}
                                          value={assessorsCommentsSaveBtn}
                                          onChange={(e) => {
                                            setAssessorsComentSaveBtn(
                                              e?.target?.value,
                                            );
                                          }}
                                        />
                                      ) : inputProps?.name == "total_score" ? (
                                        <Box
                                          style={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            alignItems: "center",
                                            gap: "18px",
                                          }}
                                        >
                                          <Typography
                                            style={{
                                              color: "var(--mainTheme)",
                                              fontSize: "18px",
                                            }}
                                            variant="body2"
                                          >
                                            {""}
                                            Total Score
                                          </Typography>
                                          <TextField
                                            // disabled={getDisableFlag()}
                                            disabled={
                                              inputProps.disabled ||
                                              getDisableFlag()
                                            }
                                            variant="outlined"
                                            // label={inputProps?.label}
                                            sx={{
                                              height: "48px",
                                              border:
                                                inputProps.isConfigured === true
                                                  ? "1.5px solid var(--mainTheme) !important"
                                                  : " 1.5px solid rgba(255, 45, 49, 0.7) !important",
                                              "& .MuiFormLabel-root": {
                                                backgroundColor: "white",
                                              },
                                              "& .MuiInputLabel-root": {
                                                backgroundColor: "white",
                                              },
                                            }}
                                            value={totalScore}
                                            onChange={(e) => {
                                              setTotalScore(e?.target?.value);
                                            }}
                                          />
                                        </Box>
                                      ) : null}

                                      {inputProps?.name == "comment" &&
                                      formData.visitType === "virtual" ? (
                                        <TextField
                                          disabled={
                                            getDisableFlag() ||
                                            tabId == "3" ||
                                            (roleId == 5 && tabId == 3)
                                          }
                                          multiline
                                          style={{
                                            display: "flex",
                                            width: "100%",
                                            height: "100px",
                                            overflow: "scroll",
                                          }}
                                          variant="outlined"
                                          //label={inputProps?.label}
                                          sx={{
                                            height: "48px",
                                            border:
                                              inputProps.isConfigured === true
                                                ? "1.5px solid var(--mainTheme) !important"
                                                : " 1.5px solid rgba(255, 45, 49, 0.7) !important",
                                            "& .MuiFormLabel-root": {
                                              backgroundColor: "white",
                                            },
                                            "& .MuiInputLabel-root": {
                                              backgroundColor: "white",
                                            },
                                          }}
                                          value={commentSaveBtn}
                                          onChange={(e) => {
                                            setCommentSaveBtn(e?.target?.value);
                                          }}
                                        />
                                      ) : null}
                                    </Grid>
                                  );
                                } else if (inputProps.type === "datatable") {
                                  const selectedTable =
                                    inputProps.tablelist.filter(
                                      (table) =>
                                        table &&
                                        table.length > 0 &&
                                        table[1] === true,
                                    );
                                  return (
                                    <Grid xs={inputProps.xs} item>
                                      <div
                                        style={{ textAlign: "center" }}
                                      ></div>
                                    </Grid>
                                  );
                                } else if (inputProps.type === "date") {
                                  return (
                                    <Grid
                                      xs={inputProps.xs}
                                      style={{ paddingLeft: "36px" }}
                                      item
                                    >
                                      <Stack direction="row">
                                        <Grid item>
                                          <div
                                            sx={{
                                              "& > :not(style)": {
                                                width: "100%",
                                                marginX: 1,
                                              },
                                            }}
                                            className="inputAddMembers"
                                          >
                                            {(inputProps.name ===
                                              "examFinishDate" ||
                                              inputProps.name ===
                                                "evaluationDate") && (
                                              <Typography
                                                style={{
                                                  color: "var(--mainTheme)",
                                                  fontWeight: 600,
                                                }}
                                                variant="body2"
                                              >
                                                {""}
                                                {inputProps.label}
                                              </Typography>
                                            )}
                                            <LocalizationProvider
                                              dateAdapter={AdapterDayjs}
                                            >
                                              <DemoContainer
                                                components={[
                                                  "DateTimePicker",
                                                  "DateTimePicker",
                                                ]}
                                              >
                                                <DateTimePicker
                                                  timeSteps={{ minutes: 1 }}
                                                  id="datetime-local"
                                                  format="MM/DD/YYYY hh:mm a"
                                                  views={[
                                                    "year",
                                                    "month",
                                                    "day",
                                                    "hours",
                                                    "minutes",
                                                  ]}
                                                  disabled={
                                                    getDisableFlag() ||
                                                    tabId == "3" ||
                                                    (roleId == 5 && tabId == 3)
                                                  }
                                                  variant="outlined"
                                                  minDate={getMinDate(
                                                    inputProps,
                                                  )}
                                                  maxDate={getMaxDate(
                                                    inputProps,
                                                  )}
                                                  ampm
                                                  autoOk={false}
                                                  label={
                                                    inputProps.name ===
                                                      "evaluationDate" ||
                                                    inputProps.name ===
                                                      "examFinishDate"
                                                      ? ""
                                                      : inputProps.label
                                                  }
                                                  value={
                                                    inputProps.name ===
                                                    "examFinishDate"
                                                      ? examFinishDate
                                                        ? dayjs(
                                                            dayjs(
                                                              examFinishDate,
                                                            )
                                                              ?.tz(
                                                                selectedTimeZoneUtc,
                                                              )
                                                              .format(
                                                                "MM-DD-YYYY hh:mm:ss A",
                                                              ),
                                                          )
                                                        : null
                                                      : inputProps.name ===
                                                        "evaluationDate"
                                                      ? evaluationDate
                                                        ? dayjs(
                                                            dayjs(
                                                              evaluationDate,
                                                            )
                                                              ?.tz(
                                                                selectedTimeZoneUtc,
                                                              )
                                                              .format(
                                                                "MM-DD-YYYY hh:mm:ss A",
                                                              ),
                                                          )
                                                        : // ?.format(
                                                          //   "MM-DD-YYYY hh:mm:ss A"
                                                          // )
                                                          // ?  Boolean(evaluationDate)
                                                          //     ? dayjs(evaluationDate)
                                                          //         ?.tz(selectedTimeZoneUtc)
                                                          //         ?.format("MM-DD-YYYY hh:mm:ss A")
                                                          //     : ""
                                                          null
                                                      : null
                                                  }
                                                  onChange={(newValue) => {
                                                    handleDateChange(
                                                      inputProps,
                                                      new Date(newValue),
                                                    );
                                                  }}
                                                  onClose={() => {
                                                    setCloseDatePicker(true);
                                                    if (
                                                      (inputProps.name ===
                                                        "examFinishDate" &&
                                                        Boolean(
                                                          evaluationDate,
                                                        ) &&
                                                        !closeDatePicker) ||
                                                      (inputProps.name ===
                                                        "evaluationDate" &&
                                                        Boolean(
                                                          examFinishDate,
                                                        ) &&
                                                        !closeDatePicker)
                                                    ) {
                                                      setCloseDatePicker(true);
                                                    }
                                                  }}
                                                  slotProps={{
                                                    textField: {
                                                      disabled: true,
                                                    },
                                                  }}
                                                />
                                              </DemoContainer>
                                            </LocalizationProvider>
                                          </div>
                                        </Grid>
                                      </Stack>
                                    </Grid>
                                  );
                                } else if (inputProps.type === "label") {
                                  if (inputProps.name == "your_health_plan") {
                                    return (
                                      <div sx={{ marginLeft: "60px" }}>
                                        <Typography
                                          sx={{
                                            color:
                                              "var(--mainTheme) !important",
                                            marginLeft: "30px",
                                          }}
                                        >
                                          {inputProps.label}
                                        </Typography>
                                      </div>
                                    );
                                  }
                                  if (
                                    inputProps.name == "member_acknowledgment"
                                  ) {
                                    return (
                                      <Box
                                        paddingY={2}
                                        display="flex"
                                        marginLeft="30px"
                                      >
                                        <Box
                                          display="flex"
                                          justifyContent="left"
                                        >
                                          <Typography color="#1076BC">
                                            Member Acknowledgment
                                          </Typography>
                                        </Box>
                                      </Box>
                                    );
                                  }
                                  if (inputProps.name == "advised") {
                                    return (
                                      <Box
                                        paddingY={2}
                                        display="flex"
                                        marginLeft="30px"
                                      >
                                        <Box
                                          display="flex"
                                          justifyContent="left"
                                        >
                                          <Typography color="#1076BC">
                                            {inputProps.label}
                                          </Typography>
                                        </Box>
                                      </Box>
                                    );
                                  } else {
                                    return (
                                      <CustomLabelField
                                        formData={formData}
                                        isCommentEnabled={
                                          inputProps.isCommentEnabled
                                        }
                                        inputProps={inputProps}
                                        isConfigured={inputProps.isConfigured}
                                        commentIndex={commentIndex}
                                        setCommentIndex={setCommentIndex}
                                        section={section}
                                        setComment={setComment}
                                        updateCommentInFormData={
                                          updateCommentInFormData
                                        }
                                      />
                                    );
                                  }
                                } else if (inputProps.type === "options_tree") {
                                  return <Grid xs={inputProps.xs} item></Grid>;
                                } else if (inputProps.type === "heading") {
                                  return (
                                    <Grid xs={12} sm={12} item>
                                      <CustomHeadingField
                                        formData={formData}
                                        inputProps={inputProps}
                                      />
                                    </Grid>
                                  );
                                } else if (inputProps.type === "radio") {
                                  return <Grid xs={inputProps.xs} item></Grid>;
                                } else if (inputProps.type === "checkbox") {
                                  return (
                                    <>
                                      <CustomCheckboxField
                                        inputProps={inputProps}
                                        isCommentEnabled={
                                          inputProps.isCommentEnabled
                                        }
                                        disabled={
                                          getDisableFlag() ||
                                          tabId == "3" ||
                                          (roleId == 5 && tabId == 3)
                                        }
                                        formData={formData}
                                        setFormData={setFormData}
                                        isConfigured={inputProps.isConfigured}
                                        commentIndex={commentIndex}
                                        handleChangeCheckbox={(e) =>
                                          handleChangeCheckbox(e, inputProps)
                                        }
                                        setCommentIndex={setCommentIndex}
                                        quesindex={quesindex}
                                        section={section}
                                        setComment={setComment}
                                        updateCommentInFormData={
                                          updateCommentInFormData
                                        }
                                        visitType={selectedVisitType}
                                        showConsentedToVideo={
                                          showConsentedToVideo
                                        }
                                        showMemberConsentedToCompleteVirtualVisit={
                                          showMemberConsentedToCompleteVirtualVisit
                                        }
                                        showMembersIdentityConfirmedWithID={
                                          showMembersIdentityConfirmedWithID
                                        }
                                      />
                                    </>
                                  );
                                } else if (inputProps.type === "image") {
                                  if (
                                    !_providerSignatureHra &&
                                    !providerSignAllDashboard &&
                                    inputProps.src !== ""
                                  ) {
                                    const isSrcValidUrl = isValidUrl(
                                      inputProps.src,
                                    );

                                    if (isSrcValidUrl) {
                                      return (
                                        <CustomImageField
                                          inputProps={inputProps}
                                        />
                                      );
                                    } else {
                                      return (
                                        <div
                                          style={{
                                            width: "50%",
                                            marginTop: "1%",
                                            marginBottom: "1%",
                                            marginLeft: "1%",
                                          }}
                                        >
                                          <p>Invalid src</p>
                                        </div>
                                      );
                                    }
                                  } else if (
                                    _providerSignatureHra != undefined &&
                                    _providerSignatureHra != "undefined"
                                  ) {
                                    if (_providerSignatureHra) {
                                      return (
                                        <Grid xs={inputProps.xs}>
                                          <div
                                            style={{
                                              width: "fit-content",
                                              marginTop: "2%",
                                              marginBottom: "1%",
                                              marginLeft: "1%",
                                              border: "1px solid grey",
                                            }}
                                          >
                                            <img
                                              src={_providerSignatureHra}
                                              alt={inputProps.alt}
                                            />
                                          </div>
                                        </Grid>
                                      );
                                    }
                                  } else if (
                                    providerSignAllDashboard != undefined
                                  ) {
                                    if (providerSignAllDashboard) {
                                      return (
                                        <Grid xs={inputProps.xs}>
                                          <div
                                            style={{
                                              width: "fit-content",
                                              marginTop: "2%",
                                              marginBottom: "1%",
                                              marginLeft: "1%",
                                              border: "1px solid grey",
                                            }}
                                          >
                                            <img
                                              src={providerSignAllDashboard}
                                              alt={inputProps.alt}
                                            />
                                          </div>
                                        </Grid>
                                      );
                                    }
                                  } else {
                                    return (
                                      <div
                                        style={{
                                          width: "50%",
                                          marginTop: "1%",
                                          marginBottom: "1%",
                                          marginLeft: "1%",
                                        }}
                                      >
                                        <p></p>
                                      </div>
                                    );
                                  }
                                } else if (inputProps.type === "select") {
                                  return (
                                    // <CustomSelectField
                                    //   inputProps={inputProps}
                                    //   xs={inputProps.xs}
                                    //   style={{ marginTop: "28px" }}
                                    //   sm={inputProps.sm}
                                    //   isConfigured={inputProps.isConfigured}
                                    //   formData={formData}
                                    //   handleChange={handleChange}
                                    //   handleVisitTypeChange={
                                    //     handleVisitTypeChange
                                    //   }
                                    //   disabled={getDisableFlag()}
                                    // />
                                    <Grid xs={inputProps.xs} item>
                                      <Stack
                                        style={{ marginTop: "11px" }}
                                        direction="row"
                                      >
                                        <Grid item width="200%">
                                          <div
                                            sx={{
                                              "& > :not(style)": {
                                                // width: "146%",
                                                marginX: 1,
                                                marginTop: "41px",
                                                borderRadius: "4px",
                                              },
                                            }}
                                            //className="inputAddMembers"
                                          >
                                            {inputProps.name ===
                                              "visitType" && (
                                              <Typography
                                                style={{
                                                  color: "var(--mainTheme)",
                                                  fontWeight: 600,
                                                }}
                                                variant="body2"
                                              >
                                                Visit Type
                                              </Typography>
                                            )}

                                            <CustomSelectField
                                              inputProps={inputProps}
                                              xs={inputProps.xs}
                                              style={{
                                                marginTop: "10px",
                                                border: "0px !important",
                                              }}
                                              sm={inputProps.sm}
                                              isConfigured={
                                                inputProps.isConfigured
                                              }
                                              formData={formData}
                                              handleChange={handleChange}
                                              handleVisitTypeChange={
                                                handleVisitTypeChange
                                              }
                                              disabled={
                                                getDisableFlag() ||
                                                tabId == "3" ||
                                                (roleId == 5 && tabId == 3)
                                              }
                                            />
                                          </div>
                                        </Grid>
                                      </Stack>
                                    </Grid>
                                  );
                                } else if (inputProps.type === "button") {
                                  return (
                                    <Grid
                                      xs={inputProps.isSingleButton ? 12 : 0}
                                      item
                                    >
                                      <FormControl
                                        className="inputStyle"
                                        component="fieldset"
                                        {...{
                                          value: formData[inputProps.name],
                                        }}
                                        variant="outlined"
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          alignItems: "center",
                                          textAlign: "center",
                                          height: "100%",
                                          flexWrap: "wrap",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <Button
                                          // display = {tabId == "3" && inputProps.name == "signSubmit" ? "none" : "block" }
                                          disabled={
                                            (inputProps.name == "no_show" ||
                                              inputProps.name ==
                                                "cancel_assessment") &&
                                            ![
                                              "In Progress",
                                              "Scheduled",
                                            ].includes(hraStatus)
                                              ? true
                                              : tabId == "3" &&
                                                inputProps.name == "signSubmit"
                                              ? true
                                              : [
                                                  "view_full_report",
                                                  "view_summary",
                                                  "member_summary",
                                                ]?.includes(inputProps.name)
                                              ? false
                                              : getDisableFlag() ||
                                                (roleId == 5 && tabId == 3)
                                          }
                                          style={{
                                            display:
                                              (inputProps.name ==
                                                "signSubmit" &&
                                                roleId != 5) ||
                                              (tabId == "3" &&
                                                inputProps.name == "signSubmit")
                                                ? "none"
                                                : true,
                                            "&:disabled": {
                                              BackgroundColor:
                                                "var(--mainTheme)",
                                            },
                                          }}
                                          onClick={() => {
                                            if (
                                              inputProps.name == "signSubmit" &&
                                              //formData.acceptDisclosure == 1 &&
                                              //acceptDisclosureSaveBtn == 1 &&
                                              // formData.visitType == "virtual"
                                              (visitTypeSaveBtn == 2
                                                ? consentedToVideoSaveBtn == 1
                                                : visitTypeSaveBtn == 1
                                                ? true
                                                : false) &&
                                              (visitTypeSaveBtn == 2
                                                ? memberConsentedToCompleteVirtualVisitSaveBtn ==
                                                  1 //formData?.consentedToVideo == 1
                                                : visitTypeSaveBtn == 1
                                                ? true
                                                : false) &&
                                              (visitTypeSaveBtn == 2
                                                ? membersIdentityConfirmedWithIDSaveBtn ==
                                                  1 //formData?.consentedToVideo == 1
                                                : visitTypeSaveBtn == 1
                                                ? true
                                                : false) &&
                                              // formData.memberAcknowledgement == 1 &&
                                              memberAcknowledgementSaveBtn ==
                                                1 &&
                                              (Boolean(examFinishDate) ||
                                                Boolean(
                                                  examFinishDateSaveBtn,
                                                )) &&
                                              (Boolean(evaluationDate) ||
                                                Boolean(
                                                  evaluationDateSaveBtn,
                                                )) &&
                                              new Date(
                                                evaluationDate,
                                              )?.getTime() <
                                                new Date(
                                                  examFinishDate,
                                                )?.getTime()
                                            ) {
                                              handleButtonAction(
                                                inputProps.name,
                                              );
                                            } else if (
                                              !["1", "2"].includes(
                                                visitTypeSaveBtn,
                                              )
                                            ) {
                                              setErrorSignSubmitBtn(true);
                                              setErrorSignSubmitBtnMsg(
                                                "Please Select Visit Type To Proceed",
                                              );
                                            } else if (
                                              inputProps.name == "signSubmit" &&
                                              memberAcknowledgementSaveBtn !=
                                                1 &&
                                              visitTypeSaveBtn == "2" &&
                                              consentedToVideoSaveBtn != "1" &&
                                              memberConsentedToCompleteVirtualVisitSaveBtn !=
                                                "1" &&
                                              membersIdentityConfirmedWithIDSaveBtn !=
                                                "1"
                                            ) {
                                              setErrorSignSubmitBtn(true);
                                              if (clientYear == 2024) {
                                                setErrorSignSubmitBtnMsg(
                                                  "Member informed of acknowledgement and Member consented to complete the virtual visit using audio and video, understands the purpose of the visit and their participation required.",
                                                );
                                              } else {
                                                setErrorSignSubmitBtnMsg(
                                                  "Member informed of acknowledgement  and  Consented to Video chat required",
                                                );
                                              }
                                            } else if (
                                              inputProps.name == "signSubmit" &&
                                              memberAcknowledgementSaveBtn != 1
                                            ) {
                                              setErrorSignSubmitBtn(true);
                                              setErrorSignSubmitBtnMsg(
                                                "Member informed of acknowledgement is required ",
                                              );
                                            } else if (
                                              inputProps.name == "signSubmit" &&
                                              memberConsentedToCompleteVirtualVisitSaveBtn !=
                                                1
                                            ) {
                                              setErrorSignSubmitBtn(true);
                                              setErrorSignSubmitBtnMsg(
                                                "Member consented to complete the virtual visit using audio and video, understands the purpose of the visit and their participation required.",
                                              );
                                            } else if (
                                              inputProps.name == "signSubmit" &&
                                              visitTypeSaveBtn == "2" &&
                                              consentedToVideoSaveBtn != "1" &&
                                              memberConsentedToCompleteVirtualVisitSaveBtn !=
                                                "1" &&
                                              membersIdentityConfirmedWithIDSaveBtn !=
                                                "1"
                                            ) {
                                              setErrorSignSubmitBtn(true);
                                              if (clientYear == 2024) {
                                                setErrorSignSubmitBtnMsg(
                                                  "Member informed of acknowledgement and Member consented to complete the virtual visit using audio and video, understands the purpose of the visit and their participation required.",
                                                );
                                              } else {
                                                setErrorSignSubmitBtnMsg(
                                                  "Member informed of acknowledgement  and  Consented to Video chat required",
                                                );
                                              }
                                              // setErrorSignSubmitBtnMsg(
                                              //   "Consented to Video chat required"
                                              // );
                                            } else if (
                                              inputProps.name == "signSubmit" &&
                                              (!Boolean(examFinishDate) ||
                                                examFinishDate == null) &&
                                              Boolean(evaluationDate)
                                            ) {
                                              setEmptyDateErrorPopup(true);
                                              setEmptyDateErrorMessg(
                                                "Please select Exam time Finish date",
                                              );
                                            } else if (
                                              inputProps.name == "signSubmit" &&
                                              (!Boolean(evaluationDate) ||
                                                evaluationDate == null) &&
                                              Boolean(examFinishDate)
                                            ) {
                                              setEmptyDateErrorPopup(true);
                                              setEmptyDateErrorMessg(
                                                "Please select Exam Start Time",
                                              );
                                            } else if (
                                              inputProps.name == "signSubmit" &&
                                              Boolean(evaluationDate) &&
                                              Boolean(examFinishDate) &&
                                              new Date(
                                                evaluationDate,
                                              )?.getTime() >=
                                                new Date(
                                                  examFinishDate,
                                                )?.getTime()
                                            ) {
                                              setEmptyDateErrorPopup(true);
                                              setEmptyDateErrorMessg(
                                                "Exam Finished Time should be greater than Exam Start Time",
                                              );
                                            } else if (
                                              inputProps.name == "signSubmit" &&
                                              (!Boolean(evaluationDate) ||
                                                evaluationDate == null) &&
                                              (!Boolean(examFinishDate) ||
                                                examFinishDate == null)
                                            ) {
                                              setEmptyDateErrorPopup(true);
                                              setEmptyDateErrorMessg(
                                                "Please select Exam Start Time and Exam time Finish date",
                                              );
                                            } else if (
                                              inputProps.name == "no_show"
                                            ) {
                                              setNoShowUpdateHraStatusPopup(
                                                true,
                                              );
                                            } else if (
                                              inputProps.name ==
                                              "cancel_assessment"
                                            ) {
                                              setCancelAppointmentUpdateHraStatusPopup(
                                                true,
                                              );
                                            } else {
                                              handleButtonAction(
                                                inputProps.name,
                                                assessmentId,
                                              );
                                            }
                                          }}
                                          variant="contained"
                                          sx={{
                                            width: "auto",
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            BackgroundColor: "var(--mainTheme)",
                                            textAlign: "center",
                                            height: "100%",
                                            flexWrap: "wrap",
                                            justifyContent: "center",
                                            "&:disabled": {
                                              BackgroundColor:
                                                "var(--mainTheme600)",
                                              color: "black",
                                            },
                                          }}
                                        >
                                          {inputProps.name ==
                                          "cancel_assessment"
                                            ? "Appointment Cancellation"
                                            : inputProps.label}
                                        </Button>
                                      </FormControl>
                                    </Grid>
                                  );
                                } else if (inputProps.type === "line") {
                                  return (
                                    <Grid xs={12} item>
                                      <Divider
                                        orientation="horizontal"
                                        style={{ backgroundColor: "black" }}
                                      />
                                    </Grid>
                                  );
                                }

                                return null;
                              },
                            )
                          ) : (
                            <></>
                          )}
                        </Grid>
                      </form>
                    </div>
                  </div>
                ))}
            </Box>
            {noShowUpdateHraStatusPopup && (
              <Alert
                open={noShowUpdateHraStatusPopup}
                dialogTitle={`No Show`}
                handleClose={() => setNoShowUpdateHraStatusPopup(false)}
                title={`Are you sure you would like to change the hra status to No Show ?`}
                okText="Yes"
                cancelText="No"
                onAddClick={() => {
                  customUpdateHraStatus(19);
                }}
                onNoClick={() => setNoShowUpdateHraStatusPopup(false)}
              />
            )}
            {cancelAssessmentUpdateHraStatusPopup && (
              <Alert
                open={cancelAssessmentUpdateHraStatusPopup}
                dialogTitle={`Cancel Assessment`}
                handleClose={() =>
                  setCancelAssessmentUpdateHraStatusPopup(false)
                }
                title={`Are you sure you would like to change the hra status to Cancel Assessment ?`}
                okText="Yes"
                cancelText="No"
                onAddClick={() => {
                  customUpdateHraStatus(12);
                }}
                onNoClick={() => setCancelAssessmentUpdateHraStatusPopup(false)}
              />
            )}
            {cancelAppointmentUpdateHraStatusPopup && (
              <AppointmentModal
                open={cancelAppointmentUpdateHraStatusPopup}
                handleClose={() =>
                  setCancelAppointmentUpdateHraStatusPopup(false)
                }
                onSave={(cancelComment) =>
                  handleCancelAppointmentAlertYesClick(cancelComment)
                }
                setCancleStatus={setCancleStatus}
                setStatusComment={setStatusComment}
                onExit={() => setCancelAppointmentUpdateHraStatusPopup(false)}
                statusComment={statusComment}
                cancleStatus={cancleStatus}
              />
            )}
            {isVisitTypeAlertOpen && (
              <Alert
                open={isVisitTypeAlertOpen}
                handleClose={() => setIsVisitTypeAlertOpen(false)}
                title={`Do you want to change Visit Type ?`}
                okText="Yes"
                cancelText="No"
                onAddClick={handleVisitTypeUpdate}
                onNoClick={handleVisitTypeCancel}
              />
            )}
            {openSignSubmitDialog && (
              <ConfirmDigitalSignatureDialog
                openConfirmDigitalSign={openSignSubmitDialog}
                onCloseConfirmDigitalSignatureDialogBox={() => {
                  setSignSubmitDialog(false);
                }}
                onSubmitCommentForClinician={(providerSignature) => {
                  handleSave(providerSignature);
                }}
                onBack={() => {
                  setSignSubmitDialog(false);
                }}
                clinicianName={clinicianName}
                signCanvasImg={providerSignature}
                speciality={speciality}
                fromFillHra={true}
              />
            )}
            {isEvaluationDateAlertOpen && (
              <Alert
                open={isEvaluationDateAlertOpen}
                handleClose={() => setIsEvaluationDateAlertOpen(false)}
                title={`Are you sure you want to change the evaluation date ?`}
                okText="Yes"
                cancelText="No"
                onAddClick={handleAssignedTermDateTypeUpdate}
                onNoClick={handleAssignedTermDateTypeCancel}
              />
            )}
            {errorSignSubmitBtn && (
              <Alert
                open={errorSignSubmitBtn}
                handleClose={() => setErrorSignSubmitBtn(false)}
                title={errorSignSubmitBtnMsg}
                okText="OK"
                onAddClick={() => {
                  setErrorSignSubmitBtn(false);
                  setErrorSignSubmitBtnMsg("");
                }}
                //onNoClick={handleAssignedTermDateTypeCancel}
              />
            )}

            {emptyDateErrorPopup && (
              <Alert
                open={emptyDateErrorPopup}
                handleClose={() => setEmptyDateErrorPopup(false)}
                title={emptyDateErrorMessg}
                okText="OK"
                // cancelText="No"
                onAddClick={() => {
                  setEmptyDateErrorPopup(false);
                  setEmptyDateErrorMessg("");
                  setCloseDatePicker(false);
                }}
                //onNoClick={handleAssignedTermDateTypeCancel}
              />
            )}
          </Grid>
        </Grid>
      </LoadingOverlay>
    </>
  );
});
export default PatientSummary;
