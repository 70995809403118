import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import "./hraChart.css";

ChartJS.register(ArcElement, Tooltip, Legend);

const NoShowsChart = ({ data }) => {
  // Check if data is available
  const hasData = data?.length > 0;
  const labels = data?.map((item) =>
    item.visitType === 1 ? "F2F" : "Virtual",
  );
  const values = data?.map((item) => item.count);

  const chartData = {
    labels: labels,
    datasets: [
      {
        data: values,
        backgroundColor: ["#304d6d", "#82a0bc"],
        hoverBackgroundColor: ["#304d6d", "#82a0bc"],
        //borderWidth: 2,
        //radius: "80%",

        borderWidth: 0,
        borderColor: "#fff",
        spacing: 0, // Remove white space between pie slices
        radius: "70%", // Reduce the overall radius of the pie chart
        hoverOffset: 20, // Pop out on hover
      },
    ],
  };

  const options = {
    responsive: true,
    cutout: "70%",
    plugins: {
      legend: {
        position: "bottom",
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            return `${tooltipItem.label}: ${tooltipItem.raw}%`;
          },
        },
      },
    },
  };

  return (
    <div className="chart-container">
      {hasData ? (
        <Doughnut data={chartData} options={options} />
      ) : (
        <div style={{ marginTop: "50%" }}>No Data Available</div>
      )}
    </div>
  );
};

export default NoShowsChart;
