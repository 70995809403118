import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import lodash from "lodash";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";
import { MdOutlineSubdirectoryArrowRight } from "react-icons/md";

import {
  DFCommentIcon,
  DFCommentIconMemo,
  DFCommentTextBox,
  DFCommentTextBoxMemo,
  isCommentVisible,
  isCommentVisibleMemo,
} from "./DFComment";

import "./DynamicForms.css";

export const DFSelect = ({
  inputProps,
  value,
  handleSelectChange,
  handleTextChange,
  showComment,
  setShowComment,
  quesSlug,
  sectionId,
  commentValue,
  disableFlag,
  needMemo,
  getFieldValues,
  setFieldValues,
  depth,
}) => {
  const { isCommentEnabled, name, label } = inputProps;

  const selectedValue = value;

  const text =
    "Vigorous exercise includes: jogging, running, fast cycling, aerobic classes, swimming laps, single tennis and racquetball. Moderate exercise includes: brisk walking, gardening, slow cycling, dancing, doubles tennis or hard work around the house";

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
        }}
      >
        {depth > 2 || name === "self_last12MonthsSubDesc" ? (
          <MdOutlineSubdirectoryArrowRight
            style={{
              color: "var(--mainTheme)",
              marginRight: "10px",
              height: "25px",
              width: "25px",
            }}
          />
        ) : (
          <div style={{ margin: "0px !important" }}></div>
        )}

        <InputLabel
          sx={{
            color: "var(--mainTheme)",
            fontWeight: "600",
            display: "flex",
          }}
        >
          {label}
          {name === "vital_physicalActivityLevel" && (
            <div style={{ marginLeft: "22px", marginTop: "0px" }}>
              <Tooltip
                title={
                  <div style={{ fontSize: "16px", width: "250px" }}>{text}</div>
                }
                placement="top"
                arrow
              >
                <InfoIcon variant="outlined" style={{ cursor: "pointer" }}>
                  top-start
                </InfoIcon>
              </Tooltip>
            </div>
          )}
        </InputLabel>
      </div>
      <Stack direction="row">
        <Grid item xs={isCommentEnabled ? 11 : 12}>
          <FormControl variant="outlined" fullWidth>
            <Select
              label={label}
              // variant="outlined"
              style={{ borderRadius: "5px" }}
              sx={{
                // height: "48px",
                border: "1.5px solid var(--mainTheme) !important",
                "& .MuiFormLabel-root": {
                  backgroundColor: "white",
                  color: "rgba(0,0,0,0.38)!important",
                },
                "& .MuiInputLabel-root": {
                  backgroundColor: "white",
                  color: "rgba(0,0,0,0.38)!important",
                },
              }}
              name={name}
              value={selectedValue || "Select"}
              onChange={(event) => {
                handleSelectChange(event.target.name, event.target.value);
              }}
              {...(disableFlag && { disabled: true })}
              fullWidth
            >
              <MenuItem disabled value="Select">
                Please Select
              </MenuItem>
              {inputProps.options.map((item, index) => (
                <MenuItem key={item[1]} value={item[1]}>
                  {item[0]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {inputProps.isCommentEnabled === true &&
          (needMemo ? (
            <DFCommentIconMemo
              getFieldValues={getFieldValues}
              setFieldValues={setFieldValues}
              name={name}
              value={commentValue}
              disableFlag={disableFlag}
            />
          ) : (
            <DFCommentIcon
              showComment={showComment}
              setShowComment={setShowComment}
              sectionId={sectionId}
              quesSlug={quesSlug}
              value={commentValue}
              disableFlag={disableFlag}
            />
          ))}
      </Stack>
      {inputProps.isCommentEnabled &&
        (needMemo
          ? isCommentVisibleMemo(getFieldValues, name)
          : isCommentVisible(
              showComment,
              sectionId,
              quesSlug,
              commentValue,
            )) && (
          <DFCommentTextBox
            label={label}
            handleTextChange={handleTextChange}
            name={name}
            value={commentValue}
            disableFlag={disableFlag}
          />
        )}
    </>
  );
};

DFSelect.propTypes = {
  inputProps: PropTypes.object.isRequired,
  // formData: PropTypes.object.isRequired,
  value: PropTypes.string.isRequired,
  handleSelectChange: PropTypes.func.isRequired,
  handleTextChange: PropTypes.func.isRequired,
  quesSlug: PropTypes.string.isRequired,
  sectionId: PropTypes.number.isRequired,
  showComment: PropTypes.array.isRequired,
  setShowComment: PropTypes.func.isRequired,
  // setFormData: PropTypes.func.isRequired,
  disableFlag: PropTypes.bool,
  commentValue: PropTypes.string,
};

const dontReRender = (oldProps, newProps) => {
  /*
   
  returns false - if there is a change between oldProps & newProps
  returns true - if there is no change between oldProps & newProps

  console.log(
    JSON.stringify(oldProps),
    JSON.stringify(newProps),
    lodash.isEqual(JSON.stringify(oldProps), JSON.stringify(newProps)),
    "check memo"
  );

  */

  return lodash.isEqual(JSON.stringify(oldProps), JSON.stringify(newProps));
};

export const DFSelectMemo = React.memo(
  ({
    inputProps,
    value,
    commentValue,
    handleSelectChange,
    handleTextChange,
    getFieldValues,
    setFieldValues,
    disableFlag,
  }) => {
    const { isCommentEnabled, name, label } = inputProps;

    const selectedValue = value;

    return (
      <>
        <InputLabel
          sx={{
            color: "var(--mainTheme)",
            fontWeight: "600",
            display: "flex",
          }}
        >
          {label}
        </InputLabel>
        <Stack direction="row">
          <Grid item xs={isCommentEnabled ? 11 : 12}>
            <FormControl variant="outlined" fullWidth>
              <Select
                label={label}
                style={{ borderRadius: "5px" }}
                sx={{
                  border: "1.5px solid var(--mainTheme) !important",
                  "& .MuiFormLabel-root": {
                    backgroundColor: "white",
                    color: "rgba(0,0,0,0.38)!important",
                  },
                  "& .MuiInputLabel-root": {
                    backgroundColor: "white",
                    color: "rgba(0,0,0,0.38)!important",
                  },
                }}
                name={name}
                value={selectedValue || "Select"}
                onChange={(event) => {
                  handleSelectChange(event.target.name, event.target.value);
                }}
                {...(disableFlag && { disabled: true })}
                fullWidth
              >
                <MenuItem
                  /* disabled */
                  value="Select"
                >
                  Select
                </MenuItem>
                {inputProps.options.map((item, index) => (
                  <MenuItem key={item[1]} value={item[1]}>
                    {item[0]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {inputProps.isCommentEnabled === true && (
            <DFCommentIconMemo
              name={name}
              value={commentValue}
              getFieldValues={getFieldValues}
              setFieldValues={setFieldValues}
              disableFlag={disableFlag}
            />
          )}
        </Stack>
        {inputProps.isCommentEnabled &&
          isCommentVisibleMemo(getFieldValues, name) && (
            <DFCommentTextBoxMemo
              label={label}
              handleTextChange={handleTextChange}
              name={name}
              value={commentValue}
              disableFlag={disableFlag}
            />
          )}
      </>
    );
  },
  dontReRender,
);

DFSelectMemo.propTypes = {
  inputProps: PropTypes.object.isRequired,
  value: PropTypes.string.isRequired,
  commentValue: PropTypes.string,
  handleSelectChange: PropTypes.func.isRequired,
  handleTextChange: PropTypes.func.isRequired,
  setFieldValues: PropTypes.func.isRequired,
  getFieldValues: PropTypes.func.isRequired,
  disableFlag: PropTypes.bool,
};
