import React, { useState, useEffect } from "react";
import PedsFormHome from "../../../components/Dashboard/MemberList/Fill_HRA/PedsForm";
import "../../../pages/AdminDashboard/DynamicForms/DynamicForms.css";
// import Sidebar from "../../../components/Dashboard/Sidebar/Sidebar";
// import Header from "../../../components/Dashboard/Header/Header";
import { AlertProvider } from "../../../contexts/AlertContext";
import FillHra from "../../../components/Dashboard/MemberList/FillHra";
import FillHraHome from "../../../components/Dashboard/MemberList/Fill_HRA/FillHra";
import ScreeningOnly from "../../../components/Dashboard/MemberList/ScreeningOnly/ScreeningOnly";
import { useLocation } from "react-router-dom";

function FillHRAForm() {
  const location = useLocation();
  const screenIha = location.state
    ? location.state.screenIha
    : sessionStorage.getItem("screenIha");
  const memberType = sessionStorage.getItem("memberType"); //location.state && location.state.dateOfBirth;
  const assessmentId = location?.state?.assessmentId
    ? location?.state?.assessmentId
    : sessionStorage.getItem("assessmentId");
  const dob = sessionStorage.getItem("dob");
  function excelSerialToDate(dob) {
    const baseDate = new Date("1899-12-30");
    const milliseconds = (dob - 1) * 24 * 60 * 60 * 1000;
    const date = new Date(baseDate.getTime() + milliseconds);
    return date;
  }
  function calculateAge() {
    if (dob != "undefined") {
      //const dateOfBirth = excelSerialToDate(JSON.parse(JSON.stringify(dob)));
      // const dateOfBirth = dob;
      const dateOfBirth = JSON.parse(JSON?.stringify(dob));
      var birthDateObj = dateOfBirth?.includes("-")
        ? new Date(dateOfBirth)
        : new Date(excelSerialToDate(dateOfBirth));
      var currentDate = new Date();
      var timeDifference = currentDate?.getTime() - birthDateObj?.getTime();
      var age = Math?.floor(timeDifference / (1000 * 60 * 60 * 24 * 365.25));
      return age;
    } else {
      return 1000;
    }
  }

  return (
    <AlertProvider>
      <div className="dashboard">
        {/* <div className="sidebar">
          <Sidebar />
        </div> */}
        <div className="content-dashboard">
          {/* <div>
            <Header />
          </div> */}
          <div style={{ paddingBottom: "3%" }}>
            {screenIha && screenIha !== "undefined" ? (
              <ScreeningOnly />
            ) : // ) : memberType != 2 ? (
            calculateAge() > 18 ? (
              <FillHraHome />
            ) : (
              <PedsFormHome assessmentId={assessmentId} />
            )}
          </div>
        </div>
      </div>
    </AlertProvider>
  );
}

export default FillHRAForm;
