import { Grid } from "@mui/material";
import lodash from "lodash";
import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useCallback,
} from "react";
import { useLocation } from "react-router-dom";
import { getApi } from "../../../../../../utils/axiosApiWrapper";

import { DFButton } from "../../DFInputs/DFButton";
import { DFCheckbox } from "../../DFInputs/DFCheckbox";
import { DFDate } from "../../DFInputs/DFDate";
import { DFHeading } from "../../DFInputs/DFHeading";
import { DFImage } from "../../DFInputs/DFImage";
import { DFLabel } from "../../DFInputs/DFLabel";
import { DFOptionsTree } from "../../DFInputs/DFOptionsTree";
import DFRadio from "../../DFInputs/DFRadio";
import { DFSelect } from "../../DFInputs/DFSelect";
import DFText from "../../DFInputs/DFText";
import { DFRuler } from "../../DFInputs/DFRuler";
// import { DynamicTableFamilyHistory } from "../../DFInputs/DFDataTable";
import { useFillHraContext } from "../../../../../../contexts/fillHraFormContext";
import LoadingOverlay from "react-loading-overlay";
import focusCaresLoader from "../../../../../../loader/focuscares_loader.gif";
import {
  getfamilyData,
  setfamilyData,
  // setfamilyData,
} from "./familyHistoryMapping";
import { useUserContext } from "../../../../../../contexts/UserContext";
import { getInputsData } from "./familyFormData";

const rowsData = [
  {
    id: 1,
    label: "Allergies",
    name: "HRAForm_Allergies",
    columns: [
      {
        label: "Substance",
        type: "text",
      },
      {
        label: "Reaction",
        type: "text",
      },
    ],
    isReadOnly: false,
  },
  {
    id: 7,
    label: "Previously Documented Conditions",
    name: "HRAForm_Previously_Documented_Conditions",
    columns: [
      {
        label: "Diagnosis",
        type: "text",
      },
      {
        label: "Prior HCC",
        type: "text",
      },
      {
        label: "Diagnosis Code",
        type: "text",
      },
      {
        label: "Date of Service",
        type: "date",
      },
    ],
    isReadOnly: false,
  },
  {
    id: 4,
    label: "family History Specialists",
    name: "HRAForm_family_History_Speciality",
    columns: [
      {
        label: "Member Specialist",
        type: "select",
      },
      {
        label: "Speciality",
        type: "text",
      },
      {
        label: "For",
        type: "text",
      },
    ],
    isReadOnly: false,
  },

  {
    id: 4,
    label: "Alcohol Use - Current",
    name: "currentSubAnswers",
    columns: [
      {
        label: "How Many Drinks",
        name: "howManyDrinks",
        type: "text",
      },
      {
        label: "How Often",
        name: "howOften",
        type: "text",
      },
    ],
    isReadOnly: false,
  },
  {
    id: 6,
    label: "Preventive Care",
    name: "HRAForm_Preventive_Care",
    columns: [
      {
        label: "Screen",
        type: "text",
      },
      {
        label: "Answer",
        type: "select",
      },
      {
        label: "Date",
        type: "text",
      },
      {
        label: "Method",
        type: "text",
      },
      {
        label: "Recommendation",
        type: "checkbox",
      },
      {
        label: "Education Completed",
        type: "checkbox",
      },
    ],
    isReadOnly: true,
  },
  {
    id: 5,
    label: "Medications",
    name: "HRAForm_Medications",
    columns: [
      {
        label: "Diagnoses",
        type: "text",
      },
      {
        label: "label Name",
        type: "select",
      },
      {
        label: "Dose Per Units",
        type: "text",
      },
      {
        label: "Route",
        type: "select",
      },
      {
        label: "Frequency",
        type: "select",
      },
      {
        label: "Prescribing Physician",
        type: "text",
      },
      {
        label: "Status",
        type: "togglebutton",
      },
    ],
    isReadOnly: false,
  },
  {
    id: 3,
    label: "Counter Medications",
    name: "HRAForm_Counter_Medications",
    columns: [
      {
        label: "Date",
        type: "date",
      },
      {
        label: "Description",
        type: "text",
      },
      {
        label: "Dose Per Units",
        type: "text",
      },
      {
        label: "Route",
        type: "select",
      },
      {
        label: "Frequency",
        type: "text",
      },
    ],
    isReadOnly: false,
  },
  {
    id: 3,
    label: "Word Recall",
    name: "step3ThreeWordRecall",
    columns: [
      {
        label: "Word Recall",
        type: "text",
      },
      {
        label: "Points",
        type: "select",
      },
      {
        label: "Description",
        type: "text",
      },
    ],
  },

  {
    id: 3,
    label: "Clock Draw",
    name: "clockDrawPoints",
    columns: [
      {
        label: "Clock Draw",
        type: "text",
      },
      {
        label: "Points",
        type: "select",
      },
      {
        label: "Description",
        type: "text",
      },
    ],
  },
  {
    id: 3,
    label: "Total Score",
    name: "totalScorePoints",
    columns: [
      {
        label: "Total Score",
        type: "text",
      },
      {
        label: "Points",
        type: "text",
      },
      {
        label: "Description",
        type: "text",
      },
    ],
  },
  {
    id: 12,
    label: "Family History",
    name: "family_history",
    columns: [
      {
        label: "Family Member",
        type: "text",
      },
      {
        label: "family Condition",
        type: "text",
      },
      {
        label: "Cause of Death",
        type: "text",
      },
    ],
    isReadOnly: false,
  },
];

const calcCommentVisibility = (formData) => {
  let tempData = {};
  Object.keys(formData).forEach((stateVar) => {
    if (stateVar.includes("Comment")) {
      let isValueThere = formData[stateVar] !== "" ? true : false;
      tempData = {
        ...tempData,
        [`${stateVar}_toggle`]: isValueThere,
      };
    }
  });

  return tempData;
};

const FamilyHistory = forwardRef((props, _ref) => {
  const {
    // FamilyHistoryFormData,
    // setFamilyHistoryFormData,
    // showComment,
    // setShowComment,
    getSectionInputsData,
    // getStateValue,
    // setStateValue,
    getDisableFlag,
  } = useFillHraContext();
  const { storedPlan, storedYear, setSideBarSectionDisable } = useUserContext();
  const [loading, setLoading] = useState(undefined);
  const [FamilyHistoryFormData, setFamilyHistoryFormData] = useState({});
  const [familyRowData, setFamilyRowData] = useState([]);
  //   const [FamilyHistoryFormData, setFamilyHistoryFormData] = useState({});
  const [showComment, setShowComment] = useState([]);
  const [disableFlag, setDisableFlag] = useState(false);

  const sectionId = props.currSection || 6;
  const clientPlan = localStorage.getItem("clientPlan") || storedPlan;
  const clientYear = localStorage.getItem("clientYear") || storedYear;
  const inputsJsonData = getInputsData(clientPlan, clientYear);
  const location = useLocation();
  const assessmentId = location?.state?.assessmentId
    ? location?.state?.assessmentId
    : sessionStorage.getItem("assessmentId");
  const tabId = location?.state?.tabId
    ? location?.state?.tabId
    : sessionStorage.getItem("tabId");
  const roleId = sessionStorage.getItem("roleId");
  useEffect(() => {
    async function getDbData() {
      setLoading(true);
      setSideBarSectionDisable(true);
      // const dbData = await props.getSectionDbData(sectionId);
      const secondApiResponse = await getApi(
        `/hra/get-assessment?id=${assessmentId}`,
      ); //await props.getSectionDbData(sectionId);
      const dbData = secondApiResponse[`data`];

      const mashedData =
        dbData?.data?.family?.data &&
        Object.keys(dbData?.data?.family?.data).length > 0
          ? getfamilyData(dbData?.data?.family?.data)
          : {};

      const tableData1 = mashedData?.familyDataTable || [];

      const commentVisibility = calcCommentVisibility(mashedData);
      let mashedCalcData = {
        ...mashedData,
        ...commentVisibility,
      };

      setFamilyHistoryFormData(mashedCalcData);
      setFamilyRowData(tableData1);
      setLoading(false);
      setSideBarSectionDisable(false);
    }

    getDbData();
    setDisableFlag(getDisableFlag());
  }, []);

  const handleTextChange = (name, value) => {
    const updatedFormData = { ...FamilyHistoryFormData };
    updatedFormData[name] = value;

    setFamilyHistoryFormData(updatedFormData);
  };

  const handleRadioChange = (name, value) => {
    const updatedFormData = { ...FamilyHistoryFormData };
    updatedFormData[name] = value;

    setFamilyHistoryFormData(updatedFormData);
  };

  const handleDateChange = (name, value) => {
    const updatedFormData = { ...FamilyHistoryFormData };
    updatedFormData[name] = value;

    setFamilyHistoryFormData(updatedFormData);
  };

  const handleSelectChange = (name, value) => {
    const updatedFormData = { ...FamilyHistoryFormData };
    updatedFormData[name] = value;

    setFamilyHistoryFormData(updatedFormData);
  };

  const handleOptionsTreeChange = (name, value, isMulti) => {
    /* togglebutton gruop type -> scenario -> actual behaviour -> our pattern

    <- multi ->
        pass values -> array -> array
        if value selected -> array -> array

    <- single -> 

        pass values -> string -> always pass as array
        if value selected -> string -> always change to array and set back to state

    */
    const updatedFormData = { ...FamilyHistoryFormData };
    let oldSelection = updatedFormData[name];
    let newSelection = [];
    if (name === "familyHistory") {
      updatedFormData.FamilyDatatableVal = [];
      updatedFormData.familyDataTable = [];
      updatedFormData.familyHistoryDatatableVal = [];
      setFamilyRowData([]);
    }

    setFamilyHistoryFormData(updatedFormData);
    if (isMulti) {
      newSelection = value;
    } else {
      if (!oldSelection?.includes(value)) {
        newSelection.push(value);
      }
    }

    updatedFormData[name] = newSelection;
    setFamilyHistoryFormData(updatedFormData);
  };

  const handleButtonClick = (name) => {
    return null;
  };

  const handleCheckboxChange = (name, value, checked) => {
    const updatedFormData = { ...FamilyHistoryFormData };
    const oldData = updatedFormData?.[name] || [];
    let newData = [];
    if (oldData.length < 1) {
      newData.push(value);
    } else {
      if (checked) {
        newData = [...oldData];
        newData.push(value);
      } else {
        newData = oldData.filter((ele) => ele !== value);
      }
    }

    updatedFormData[name] = newData;
    setFamilyHistoryFormData(updatedFormData);
  };

  useImperativeHandle(_ref, () => ({
    getPayloadData: () => {
      const formData = FamilyHistoryFormData;
      const tableData1 = familyRowData || [];

      let combinedData = {
        ...formData,
        familyDataTable: tableData1,
      };
      let hashedData = setfamilyData(combinedData);
      return hashedData;
    },
  }));

  useEffect(() => {
    const formData = FamilyHistoryFormData;
    const tableData1 = familyRowData || [];

    let combinedData = {
      ...formData,
      familyDataTable: tableData1,
    };
    props.setAutoSaveFamilyHistoryData(combinedData);
  }, [FamilyHistoryFormData, familyRowData]);

  const getFieldValues = useCallback(
    (name) => {
      return FamilyHistoryFormData[name];
    },
    [FamilyHistoryFormData],
  );

  const setFieldValues = useCallback((name, value, type = "") => {
    setFamilyHistoryFormData((prevData) => {
      let tempData = { ...prevData };
      if (type === "datatable") {
        tempData = {
          ...tempData,
          ...value,
        };
      }
      tempData = {
        ...tempData,
        [name]: value,
      };

      return { ...tempData };
    });
  }, []);

  return (
    // <LoadingOverlay
    //   styles={
    //     {
    //       // wrapper: { marginLeft: "50px", marginTop: "50px" },
    //       // overlay: {},
    //       // content: { marginLeft: "40px", marginTop: "50px" },
    //       // spinner: { marginLeft: "50px", marginTop: "50px" },
    //       // spinner: (base) => ({
    //       //   ...base,
    //       //   marginLeft: "50px",
    //       //   marginTop: "50px",
    //       // }),
    //     }
    //   }
    //   style={{ marginTop: "20px" }}
    //   active={loading}
    //   spinner={<img src={focusCaresLoader} alt="Loader" />}
    //   // text="Loading your content..."
    // >
    <div
      style={{
        margin: "10px 20px",
        width: "100%",
        display: "flex",
      }}
    >
      <Grid container spacing={2}>
        {inputsJsonData?.questions?.map((inputProps) => {
          const { questionId, name, xs, type, isSingleButton } = inputProps;
          const inputValue = FamilyHistoryFormData[name] || "";
          const commentValue = `${
            FamilyHistoryFormData?.[`${name}Comment`]
              ? `${FamilyHistoryFormData[`${name}Comment`]}`
              : ""
          }`;
          const quesSlug = `${sectionId}_${questionId}`;
          if (
            type === "text" ||
            type === "number" ||
            type === "email" ||
            type === "password"
          ) {
            return (
              <Grid
                key={quesSlug}
                xs={xs}
                /* sm={inputProps.sm}*/
                item
              >
                <DFText
                  inputProps={inputProps}
                  value={inputValue}
                  handleTextChange={handleTextChange}
                  showComment={showComment}
                  setShowComment={setShowComment}
                  quesSlug={`${questionId}`}
                  sectionId={sectionId}
                  commentValue={commentValue}
                  disableFlag={disableFlag || (roleId == 5 && tabId == 3)}
                  needMemo={true}
                  getFieldValues={getFieldValues}
                  setFieldValues={setFieldValues}
                />
              </Grid>
            );
          } else if (type === "radio") {
            return (
              <Grid key={quesSlug} xs={xs} item>
                <DFRadio
                  inputProps={inputProps}
                  value={inputValue}
                  handleRadioChange={handleRadioChange}
                  handleTextChange={handleTextChange} // added for comment box event handling
                  showComment={showComment}
                  setShowComment={setShowComment}
                  quesSlug={`${questionId}`}
                  sectionId={sectionId}
                  commentValue={commentValue}
                  disableFlag={disableFlag || (roleId == 5 && tabId == 3)}
                  needMemo={true}
                  getFieldValues={getFieldValues}
                  setFieldValues={setFieldValues}
                />
              </Grid>
            );
          } else if (type === "date") {
            const dateValue = FamilyHistoryFormData[name] || {};
            return (
              <Grid
                key={quesSlug}
                xs={xs}
                /* sm={sm} */
                item
              >
                <DFDate
                  inputProps={inputProps}
                  value={dateValue}
                  handleDateChange={handleDateChange}
                  handleTextChange={handleTextChange} // added for comment box event handling
                  showComment={showComment}
                  setShowComment={setShowComment}
                  quesSlug={`${questionId}`}
                  sectionId={sectionId}
                  commentValue={commentValue}
                  disableFlag={disableFlag || (roleId == 5 && tabId == 3)}
                  needMemo={true}
                  getFieldValues={getFieldValues}
                  setFieldValues={setFieldValues}
                  // dtPickProps={{
                  //   disableFuture:true,
                  //   disablePast:true,
                  //   minDate:minDOB,
                  //   maxDate:maxDOB,
                  //   views:["year", "month", "day"]
                  // }}
                />
              </Grid>
            );
          } else if (type === "select") {
            return (
              <Grid key={quesSlug} xs={xs} item>
                <DFSelect
                  inputProps={inputProps}
                  value={inputValue}
                  handleSelectChange={handleSelectChange}
                  handleTextChange={handleTextChange} // added for comment box event handling
                  showComment={showComment}
                  setShowComment={setShowComment}
                  quesSlug={`${questionId}`}
                  sectionId={sectionId}
                  commentValue={commentValue}
                  disableFlag={disableFlag || (roleId == 5 && tabId == 3)}
                  needMemo={true}
                  getFieldValues={getFieldValues}
                  setFieldValues={setFieldValues}
                />
              </Grid>
            );
          } else if (type === "options_tree") {
            return (
              <DFOptionsTree
                inputProps={inputProps}
                formData={FamilyHistoryFormData}
                handleOptionsTreeChange={handleOptionsTreeChange}
                handleTextChange={handleTextChange}
                handleRadioChange={handleRadioChange}
                handleDateChange={handleDateChange}
                handleSelectChange={handleSelectChange}
                handleButtonClick={handleButtonClick}
                handleCheckboxChange={handleCheckboxChange}
                showComment={showComment}
                setShowComment={setShowComment}
                setFormData={setFamilyHistoryFormData}
                quesSlug={`${questionId}`}
                sectionId={sectionId}
                // commentValue={commentValue}
                disableFlag={disableFlag || (roleId == 5 && tabId == 3)}
                familyRowData={familyRowData}
                setFamilyRowData={setFamilyRowData}
                needMemo={true}
                getFieldValues={getFieldValues}
                setFieldValues={setFieldValues}
              />
            );
          } else if (type === "label") {
            return (
              <Grid key={quesSlug} xs={xs} item>
                <DFLabel
                  inputProps={inputProps}
                  handleTextChange={handleTextChange} // added for comment box event handling
                  showComment={showComment}
                  setShowComment={setShowComment}
                  quesSlug={`${questionId}`}
                  sectionId={sectionId}
                  commentValue={commentValue}
                  disableFlag={disableFlag || (roleId == 5 && tabId == 3)}
                  needMemo={true}
                  getFieldValues={getFieldValues}
                  setFieldValues={setFieldValues}
                />
              </Grid>
            );
          } else if (type === "button") {
            return (
              <Grid key={quesSlug} xs={isSingleButton ? 12 : 0} item>
                <DFButton
                  inputProps={{
                    ...inputProps,
                    disabled: roleId == 5 && tabId == 3,
                  }}
                  handleButtonClick={handleButtonClick}
                  // disableFlag={disableFlag} - check & implement
                />
              </Grid>
            );
          } else if (type === "heading") {
            return (
              <Grid key={quesSlug} xs={12} item>
                <DFHeading inputProps={inputProps} />
              </Grid>
            );
          } else if (type === "checkbox") {
            return (
              <Grid key={quesSlug} xs={xs} item>
                <DFCheckbox
                  inputProps={inputProps}
                  showComment={showComment}
                  handleCheckboxChange={handleCheckboxChange}
                  value={inputValue}
                  handleTextChange={handleTextChange}
                  setShowComment={setShowComment}
                  quesSlug={`${questionId}`}
                  sectionId={sectionId}
                  commentValue={commentValue}
                  disableFlag={disableFlag || (roleId == 5 && tabId == 3)}
                  needMemo={true}
                  getFieldValues={getFieldValues}
                  setFieldValues={setFieldValues}
                />
              </Grid>
            );
          } else if (type === "image") {
            return (
              <Grid key={quesSlug} xs={xs} item>
                <DFImage inputProps={inputProps} />
              </Grid>
            );
          } else if (type === "divider") {
            return <DFRuler />;
          }

          return null;
        })}
      </Grid>
    </div>
    // </LoadingOverlay>
  );
});

export default FamilyHistory;
