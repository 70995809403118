import { Grid } from "@mui/material";
import lodash from "lodash";
import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useCallback,
} from "react";

import { DFButton } from "../../DFInputs/DFButton";
import { DFCheckbox } from "../../DFInputs/DFCheckbox";
import { DFDate } from "../../DFInputs/DFDate";
import { DFHeading } from "../../DFInputs/DFHeading";
import { DFImage } from "../../DFInputs/DFImage";
import { DFLabel } from "../../DFInputs/DFLabel";
import { DFOptionsTree } from "../../DFInputs/DFOptionsTree";
import DFRadio from "../../DFInputs/DFRadio";
import { DFSelect } from "../../DFInputs/DFSelect";
import DFText from "../../DFInputs/DFText";
import { DFRuler } from "../../DFInputs/DFRuler";
// import { DynamicTableFamilyHistory } from "../../DFInputs/DFDataTable";
import { useFillHraContext } from "../../../../../../contexts/fillHraFormContext";
import {
  DynamicMedicationsTable,
  DynamicMedicationsTable2024,
  // DynamicMedicationsTable2024,
} from "../../DFInputs/DFDataTable";
import {
  deleteSubTree,
  getAllergiesData,
  getSubTree,
  setAllergiesData,
} from "./allergiesMapping";
import { useUserContext } from "../../../../../../contexts/UserContext";
import { getInputsData } from "./allergyFormData";
import { useLocation } from "react-router-dom";
import { getApi } from "../../../../../../utils/axiosApiWrapper";
import LoadingOverlay from "react-loading-overlay";
import focusCaresLoader from "../../../../../../loader/focuscares_loader.gif";

const calcCommentVisibility = (formData) => {
  let tempData = {};
  Object.keys(formData).forEach((stateVar) => {
    if (stateVar.includes("Comment")) {
      let isValueThere = formData[stateVar] !== "" ? true : false;
      tempData = {
        ...tempData,
        [`${stateVar}_toggle`]: isValueThere,
      };
    }
  });

  return tempData;
};

const Allergies = forwardRef((props, _ref) => {
  const {
    // allergiesFormData,
    // setAllergiesFormData,
    // showComment,
    // setShowComment,
    // getSectionInputsData,
    // getStateValue,
    // setStateValue,
    getDisableFlag,
  } = useFillHraContext();
  const { storedPlan, storedYear, setSideBarSectionDisable } = useUserContext();
  const location = useLocation();
  const tabId = location?.state?.tabId
    ? location?.state?.tabId
    : sessionStorage.getItem("tabId");
  const roleId = sessionStorage.getItem("roleId");
  const [allergiesFormData, setAllergiesFormData] = useState({});
  const [allergiesRowData, setAllergiesRowData] = useState(null);
  const [medicationsRowData, setMedicationsRowData] = useState(null);
  const [counterMedicationsRowData, setCounterMedicationsRowData] =
    useState(null);

  //   const [allergiesFormData, setAllergiesFormData] = useState({});
  const [showComment, setShowComment] = useState([]);
  const [disableFlag, setDisableFlag] = useState(false);
  const [loading, setLoading] = useState(undefined);
  const sectionId = props.currSection || 9;
  const clientPlan = localStorage.getItem("clientPlan") || storedPlan;
  const clientYear = localStorage.getItem("clientYear") || storedYear;
  const inputsJsonData = getInputsData(clientPlan, clientYear);

  const assessmentId = location?.state?.assessmentId
    ? location?.state?.assessmentId
    : sessionStorage.getItem("assessmentId");
  const [labelNameOptions, setLabelNameOptions] = useState([
    "Acyclovir",
    "Adalimumab",
    "Albuterol",
    "Alendronate",
    "Allopurinol",
    "Alprazolam",
    "Amitriptyline",
    "Amlodipine",
    "Amoxicillin",
    "Amox/Clavulanate",
    "Apixaban",
    "Aripiprazole",
    "Atenolol",
    "Atomoxatine",
    "Atorvastatin",
    "Azithromycin",
    "Baclofen",
    "Benazepril",
    "Benztropine",
    "Benzonatate",
    "Budesonide",
    "Budesonide/Formoterol",
    "Burpropion",
    "Buspirone",
    "Butalbital/acetaminophen/caffeine",
    "Carbidopa/Levodopa",
    "Carisoprodol",
    "Cefdinir",
    "Cefuroxime",
    "Celecoxib",
    "Cephalexin",
    "Cetirizine",
    "Chlorthalidone",
    "Ciprofloxacin",
    "Citalopram",
    "Clarithromycin",
    "Clindamycin",
    "Clobetasol",
    "Clonazepam",
    "Clonidine",
    "Clopidogrel",
    "Clopidogrel",
    "Conjugated Estrogens",
    "Cyclobenzaprine",
    "Dabigatran",
    "Desvenlafaxine",
    "Dexmethylphenidate",
    "Dexamethasone",
    "Diazepam",
    "Diclofenac",
    "Digoxin",
    "Diltiazem",
    "Divalproex",
    "Doxazosin",
    "Doxycycline",
    "Duloxetine",
    "Enalapril",
    "Enoxaparin",
    "Escitalopram",
    "Esterified Estrogens/methyltestosterone",
    "Estradiol",
    "Etanercept",
    "Esomprazole",
    "Ezetimibe",
    "Famotidine",
    "Fenofibrate",
    "Fluconazole",
    "Fluoxetine",
    "Fluticasone",
    "Fluticasone/Salmeterol",
    "Furosemide",
    "Gabapentin",
    "Gemfibrozil",
    "Glimepiride",
    "Glipizide",
    "Hydrochlorothiazide",
    "Hydrocodone/Acetaminophen",
    "Hydrocortisone",
    "Hydroxyzine HCL",
    "Hydralazine",
    "Hydroxychloroquine",
    "Ibandronate",
    "Ibuprofen",
    "Ipratropium/Albuterol",
    "Isosorbide Mononitrate",
    "Ketoconazole",
    "Lamotrigine",
    "Lansoprazole",
    "Levalbuterol",
    "Levetiracetam",
    "Levothyroxine",
    "Levocetirizine",
    "Levofloxacin",
    "Lidocaine",
    "Lisdexamfetamine",
    "Lisinopril",
    "Lithium",
    "Lorazepam",
    "Losartan",
    "Lovastatin",
    "Meclizine",
    "Medroxyprogesterone",
    "Mefenamic Acid",
    "Methylphenidate",
    "Methylprednisolone",
    "Metformin",
    "Metoclopramide",
    "Minocycline",
    "Mirtazapine",
    "Modafinil",
    "Montelukast",
    "Mometasone",
    "Morphine ER",
    "Naproxen",
    "Nebivolol",
    "Nifedipine",
    "Nortriptyline",
    "Nystatin Systemic",
    "Nystatin Topical",
    "Omeprazole",
    "Omega 3 Acid Ethyl Esters",
    "Ondansetron",
    "Olmesartan",
    "Oxcarbazepine",
    "Oxycodone",
    "Pantoprazole",
    "Paroxetine",
    "Penicillin",
    "Phenazopyridine",
    "Phenytoin",
    "Pioglitazone",
    "Potassium",
    "Pramipexole",
    "Pravastatin",
    "Prednisolone (Oral)",
    "Prednisone",
    "Pramipexole",
    "Promethazine",
    "Propranolol",
    "Quetiapine",
    "Quinapril",
    "Rabeprazole",
    "Ramipril",
    "Ranitidine",
    "Risperidone",
    "Risperidone",
    "Rivaroxaban",
    "Ropinirole",
    "Rosuvastatin",
    "Sertraline",
    "Sildenafil",
    "Simvastatin",
    "Sitagliptin",
    "Spironolactone",
    "Sumatriptan",
    "Tamsulosin",
    "Tetracycline",
    "Temazepam",
    "Terazosin",
    "Testosterone",
    "Thyroid",
    "Ticagrelor",
    "Tiotropium",
    "Tizanidine",
    "Topiramate",
    "Trazodone",
    "Triamcinolone",
    "Trimethoprim/Sulfamethoxazole",
    "Tramadol",
    "Tranylcypromine",
    "Tretinoin",
    "Valsartan",
    "Varenicline",
    "Verapamil",
    "Vitamin D2",
    "Warfarin",
    "Zolpidem",
  ]);

  useEffect(() => {
    const savedLabelNames = medicationsRowData
      ?.filter(
        (row) => row.labelName && !labelNameOptions.includes(row.labelName),
      )
      ?.map((row) => row.labelName);
    if (savedLabelNames?.length > 0) {
      setLabelNameOptions((prevOptions) => [
        ...new Set([...prevOptions, ...savedLabelNames]),
      ]);
    }
  }, [labelNameOptions, medicationsRowData]);

  const removeDuplicates = (data) => {
    const newRows = new Set();
    return data.filter((item) => {
      const duplicated = `${item.labelName}-${item.doseOrUnits}`;
      const duplicatedLabelOnly = `${item.labelName}-`;

      if (!item.labelName) {
        return true;
      }

      if (
        newRows.has(duplicated) ||
        (newRows.has(duplicatedLabelOnly) && !item.doseOrUnits)
      ) {
        return false;
      } else {
        newRows.add(duplicated);
        newRows.add(duplicatedLabelOnly);
        return true;
      }
    });
  };

  useEffect(() => {
    async function getDbData() {
      setLoading(true);
      setSideBarSectionDisable(true);
      //const dbData = await props.getSectionDbData(sectionId);
      const secondApiResponse = await getApi(
        `/hra/get-assessment?id=${assessmentId}`,
      ); //await props.getSectionDbData(sectionId);
      const dbData = secondApiResponse[`data`];
      const mashedData =
        dbData?.data?.allergies?.data &&
        Object.keys(dbData?.data?.allergies?.data).length > 0
          ? getAllergiesData(dbData?.data?.allergies?.data, clientYear)
          : {};

      const defaultData1 = {
        reaction: "",
        substance: "",
      };

      const defaultData2 = {
        status: {
          taking: "2",
          notTaking: "2",
        },
        prescribingPhysician: "",
        frequency: "",
        route: "",
        doseOrUnits: "",
        labelName: "",
      };

      const defaultData3 = {
        date: "",
        description: "",
        doseOrUnits: "",
        route: "",
        frequency: "",
      };

      const tableData1 = (mashedData?.allergy_allergiesDatatable || [{}]).map(
        (data) => ({ ...defaultData1, ...data }),
      );

      let tableData2 = (mashedData?.allergy_medicDatatable || [{}]).map(
        (data) => ({ ...defaultData2, ...data }),
      );
      tableData2 = removeDuplicates(tableData2);
      const tableData3 = (
        mashedData?.allergy_counterMedicDatatable || [{}]
      ).map((data) => ({ ...defaultData3, ...data }));

      const commentVisibility = calcCommentVisibility(mashedData);
      let mashedCalcData = {
        ...mashedData,
        ...commentVisibility,
      };

      setAllergiesFormData(mashedCalcData);
      setAllergiesRowData(tableData1);
      setMedicationsRowData(tableData2);
      setCounterMedicationsRowData(tableData3);
      setLoading(false);
      setSideBarSectionDisable(false);
    }

    getDbData();
    setDisableFlag(getDisableFlag());
  }, []);

  const handleTextChange = (name, value) => {
    const updatedFormData = { ...allergiesFormData };
    updatedFormData[name] = value;

    setAllergiesFormData(updatedFormData);
  };

  const handleRadioChange = (name, value) => {
    const updatedFormData = { ...allergiesFormData };
    updatedFormData[name] = value;

    setAllergiesFormData(updatedFormData);
  };

  const handleDateChange = (name, value) => {
    const updatedFormData = { ...allergiesFormData };
    updatedFormData[name] = value;

    setAllergiesFormData(updatedFormData);
  };

  const handleSelectChange = (name, value) => {
    const updatedFormData = { ...allergiesFormData };
    updatedFormData[name] = value;

    setAllergiesFormData(updatedFormData);
  };

  const handleOptionsTreeChange = (name, value, isMulti) => {
    /* togglebutton gruop type -> scenario -> actual behaviour -> our pattern

    <- multi ->
        pass values -> array -> array
        if value selected -> array -> array

    <- single -> 

        pass values -> string -> always pass as array
        if value selected -> string -> always change to array and set back to state

    */
    const updatedFormData = { ...allergiesFormData };
    let oldSelection = updatedFormData[name];
    let newSelection = [];
    if (name === "allergy_allergies") {
      updatedFormData.allergy_allergiesDatatable = [];
      updatedFormData.allergy_allergiesDatatableVal = [];

      setAllergiesRowData([]);
    }

    if (
      name === "allergy_longTermUsageNoneSub" &&
      !value?.includes("anticoagulants")
    ) {
      updatedFormData.allergy_longTermUsageNoneSubAntiCoagSubDesc = [];
      updatedFormData.allergy_longTermUsageNoneSubAntiCoagSubDescOtherSubDesc =
        "";
    }
    if (
      name === "allergy_longTermUsageNoneSubAntiCoagSubDesc" &&
      value?.includes("other")
    ) {
      updatedFormData.allergy_longTermUsageNoneSubAntiCoagSubDescOtherSubDesc =
        "";
    }
    if (
      name === "allergy_longTermUsageNoneSub" &&
      !value?.includes("steroids")
    ) {
      updatedFormData.allergy_longTermUsageNoneSubSteroidsSubType = [];
    }
    if (name === "allergy_counterMedic") {
      updatedFormData.allergy_counterMedicDatatable = [];
      updatedFormData.allergy_counterMedicDatatableVal = [];
      setCounterMedicationsRowData([]);
    }
    setAllergiesFormData(updatedFormData);

    if (isMulti) {
      newSelection = value;
    } else {
      if (!oldSelection?.includes(value)) {
        newSelection.push(value);
      }
    }

    let deletedValue =
      isMulti === true
        ? lodash.difference(oldSelection, newSelection)?.[0] || ""
        : oldSelection?.[0] || "";

    if (deletedValue && deletedValue !== "") {
      let subTreeDeletedData = handleDeleteSubTree(
        updatedFormData,
        name,
        deletedValue,
      );
      subTreeDeletedData[name] = newSelection;
      setAllergiesFormData(subTreeDeletedData);
      return null;
    } else {
      updatedFormData[name] = newSelection;
      setAllergiesFormData(updatedFormData);
    }

    updatedFormData[name] = newSelection;
    setAllergiesFormData(updatedFormData);
  };

  const handleButtonClick = (name) => {
    return null;
  };

  const handleCheckboxChange = (name, value, checked) => {
    const updatedFormData = { ...allergiesFormData };
    const oldData = updatedFormData?.[name] || [];
    let newData = [];
    if (oldData.length < 1) {
      newData.push(value);
    } else {
      if (checked) {
        newData = [...oldData];
        newData.push(value);
      } else {
        newData = oldData.filter((ele) => ele !== value);
      }
    }

    updatedFormData[name] = newData;

    setAllergiesFormData(updatedFormData);
  };

  const handleDeleteSubTree = (objectData, name, deletedValue) => {
    let deleteRegex = "";
    if (deletedValue === "yes" || deletedValue === "no") {
      deleteRegex = `${name}Sub`;
    } else {
      deleteRegex = getSubTree(name, deletedValue);
    }

    if (deleteRegex !== "") {
      let updatedObjectData = deleteSubTree(objectData, deleteRegex);
      return updatedObjectData;
    }
    return objectData;
  };

  useImperativeHandle(_ref, () => ({
    getPayloadData: () => {
      const formData = allergiesFormData;

      const tableData1 = (allergiesRowData || [{}]).map((data) => ({
        reaction: "",
        substance: "",
        ...data,
      }));
      const tableData2 = (medicationsRowData || [{}]).map((data) => ({
        status: {
          taking: "2",
          notTaking: "2",
        },
        prescribingPhysician: "",
        frequency: "",
        route: "",
        doseOrUnits: "",
        labelName: "",
        ...data,
      }));

      const tableData3 = (counterMedicationsRowData || [{}]).map((data) => ({
        date: "",
        description: "",
        doseOrUnits: "",
        route: "",
        frequency: "",
        ...data,
      }));

      let combinedData = {
        ...formData,
        allergy_allergiesDatatable: tableData1,
        allergy_medicDatatable: tableData2,
        allergy_counterMedicDatatable: tableData3,
      };
      let hashedData = setAllergiesData(combinedData, clientYear);
      return hashedData;
    },
  }));
  useEffect(() => {
    const formData = allergiesFormData;

    const tableData1 = (allergiesRowData || [{}]).map((data) => ({
      reaction: "",
      substance: "",
      ...data,
    }));
    const tableData2 = (medicationsRowData || [{}]).map((data) => ({
      status: {
        taking: "2",
        notTaking: "2",
      },
      prescribingPhysician: "",
      frequency: "",
      route: "",
      doseOrUnits: "",
      labelName: "",
      ...data,
    }));

    const tableData3 = (counterMedicationsRowData || [{}]).map((data) => ({
      date: "",
      description: "",
      doseOrUnits: "",
      route: "",
      frequency: "",
      ...data,
    }));

    let combinedData = {
      ...formData,
      allergy_allergiesDatatable: tableData1,
      allergy_medicDatatable: tableData2,
      allergy_counterMedicDatatable: tableData3,
    };
    props.setAutoSaveAllergiesData({ ...combinedData });
  }, [
    allergiesFormData,
    allergiesRowData,
    medicationsRowData,
    counterMedicationsRowData,
  ]);

  const getFieldValues = useCallback(
    (name) => {
      return allergiesFormData[name];
    },
    [allergiesFormData],
  );

  const setFieldValues = useCallback((name, value, type = "") => {
    setAllergiesFormData((prevData) => {
      let tempData = { ...prevData };
      if (type === "datatable") {
        tempData = {
          ...tempData,
          ...value,
        };
      }
      tempData = {
        ...tempData,
        [name]: value,
      };

      return { ...tempData };
    });
  }, []);

  return (
    <LoadingOverlay
      styles={{
        // wrapper: { marginLeft: "50px", marginTop: "50px" },
        // overlay: {},
        content: { marginLeft: "40vw", marginTop: "50px" },
        // spinner: { marginLeft: "50px", marginTop: "50px" },
        spinner: (base) => ({
          ...base,
          marginLeft: "50px",
          marginTop: "50px",
        }),
      }}
      style={{ marginTop: "20px" }}
      active={loading}
      spinner={<img src={focusCaresLoader} alt="Loader" />}
      // text="Loading your content..."
    >
      <div
        style={{
          margin: "10px 20px",
        }}
      >
        <Grid container spacing={2}>
          {inputsJsonData?.questions?.map((inputProps) => {
            const { questionId, name, xs, type, isSingleButton } = inputProps;
            const inputValue = allergiesFormData[name] || "";
            const commentValue = `${
              allergiesFormData?.[`${name}Comment`]
                ? `${allergiesFormData[`${name}Comment`]}`
                : ""
            }`;
            const quesSlug = `${sectionId}_${questionId}`;
            if (
              type === "text" ||
              type === "number" ||
              type === "email" ||
              type === "password"
            ) {
              return (
                <Grid
                  key={quesSlug}
                  xs={xs}
                  /* sm={inputProps.sm}*/
                  item
                >
                  <DFText
                    inputProps={inputProps}
                    value={inputValue}
                    handleTextChange={handleTextChange}
                    showComment={showComment}
                    setShowComment={setShowComment}
                    quesSlug={`${questionId}`}
                    sectionId={sectionId}
                    commentValue={commentValue}
                    disableFlag={disableFlag || (roleId == 5 && tabId == 3)}
                    needMemo={true}
                    getFieldValues={getFieldValues}
                    setFieldValues={setFieldValues}
                  />
                </Grid>
              );
            } else if (type === "radio") {
              return (
                <Grid key={quesSlug} xs={xs} item>
                  <DFRadio
                    inputProps={inputProps}
                    value={inputValue}
                    handleRadioChange={handleRadioChange}
                    handleTextChange={handleTextChange} // added for comment box event handling
                    showComment={showComment}
                    setShowComment={setShowComment}
                    quesSlug={`${questionId}`}
                    sectionId={sectionId}
                    commentValue={commentValue}
                    disableFlag={disableFlag || (roleId == 5 && tabId == 3)}
                    needMemo={true}
                    getFieldValues={getFieldValues}
                    setFieldValues={setFieldValues}
                  />
                </Grid>
              );
            } else if (type === "date") {
              const dateValue = allergiesFormData[name] || {};
              return (
                <Grid
                  key={quesSlug}
                  xs={xs}
                  /* sm={sm} */
                  item
                >
                  <DFDate
                    inputProps={inputProps}
                    value={dateValue}
                    handleDateChange={handleDateChange}
                    handleTextChange={handleTextChange} // added for comment box event handling
                    showComment={showComment}
                    setShowComment={setShowComment}
                    quesSlug={`${questionId}`}
                    sectionId={sectionId}
                    commentValue={commentValue}
                    disableFlag={disableFlag || (roleId == 5 && tabId == 3)}
                    needMemo={true}
                    getFieldValues={getFieldValues}
                    setFieldValues={setFieldValues}
                    // dtPickProps={{
                    //   disableFuture:true,
                    //   disablePast:true,
                    //   minDate:minDOB,
                    //   maxDate:maxDOB,
                    //   views:["year", "month", "day"]
                    // }}
                  />
                </Grid>
              );
            } else if (type === "select") {
              return (
                <Grid key={quesSlug} xs={xs} item>
                  <DFSelect
                    inputProps={inputProps}
                    value={inputValue}
                    handleSelectChange={handleSelectChange}
                    handleTextChange={handleTextChange} // added for comment box event handling
                    showComment={showComment}
                    setShowComment={setShowComment}
                    quesSlug={`${questionId}`}
                    sectionId={sectionId}
                    commentValue={commentValue}
                    disableFlag={disableFlag || (roleId == 5 && tabId == 3)}
                    needMemo={true}
                    getFieldValues={getFieldValues}
                    setFieldValues={setFieldValues}
                  />
                </Grid>
              );
            } else if (type === "options_tree") {
              return (
                <DFOptionsTree
                  inputProps={inputProps}
                  formData={allergiesFormData}
                  handleOptionsTreeChange={handleOptionsTreeChange}
                  handleTextChange={handleTextChange}
                  handleRadioChange={handleRadioChange}
                  handleDateChange={handleDateChange}
                  handleSelectChange={handleSelectChange}
                  handleButtonClick={handleButtonClick}
                  handleCheckboxChange={handleCheckboxChange}
                  showComment={showComment}
                  setShowComment={setShowComment}
                  setFormData={setAllergiesFormData}
                  quesSlug={`${questionId}`}
                  sectionId={sectionId}
                  // commentValue={commentValue}
                  disableFlag={disableFlag || (roleId == 5 && tabId == 3)}
                  allergiesRowData={allergiesRowData}
                  setAllergiesRowData={setAllergiesRowData}
                  counterMedicationsRowData={counterMedicationsRowData}
                  setCounterMedicationsRowData={setCounterMedicationsRowData}
                  needMemo={true}
                  getFieldValues={getFieldValues}
                  setFieldValues={setFieldValues}
                />
              );
            } else if (type === "label") {
              return (
                <Grid key={quesSlug} xs={xs} item>
                  <DFLabel
                    inputProps={inputProps}
                    handleTextChange={handleTextChange} // added for comment box event handling
                    showComment={showComment}
                    setShowComment={setShowComment}
                    quesSlug={`${questionId}`}
                    sectionId={sectionId}
                    commentValue={commentValue}
                    disableFlag={
                      disableFlag ||
                      (roleId == 5 && tabId == 3) ||
                      roleId == 6 ||
                      roleId == 8 ||
                      roleId == 7 ||
                      roleId == 9
                    }
                    needMemo={true}
                    getFieldValues={getFieldValues}
                    setFieldValues={setFieldValues}
                  />
                </Grid>
              );
            } else if (type === "button") {
              return (
                <Grid key={quesSlug} xs={isSingleButton ? 12 : 0} item>
                  <DFButton
                    inputProps={{
                      ...inputProps,
                      disabled: roleId == 5 && tabId == 3,
                    }}
                    handleButtonClick={handleButtonClick}
                    // disableFlag={disableFlag} - check & implement
                  />
                </Grid>
              );
            } else if (type === "heading") {
              return (
                <Grid key={quesSlug} xs={12} item>
                  <DFHeading inputProps={inputProps} />
                </Grid>
              );
            } else if (type === "checkbox") {
              return (
                <Grid key={quesSlug} xs={xs} item>
                  <DFCheckbox
                    inputProps={inputProps}
                    showComment={showComment}
                    handleCheckboxChange={handleCheckboxChange}
                    value={inputValue}
                    handleTextChange={handleTextChange}
                    setShowComment={setShowComment}
                    quesSlug={`${questionId}`}
                    sectionId={sectionId}
                    commentValue={commentValue}
                    disableFlag={disableFlag || (roleId == 5 && tabId == 3)}
                    allergiesFormData={allergiesFormData}
                    needMemo={true}
                    getFieldValues={getFieldValues}
                    setFieldValues={setFieldValues}
                  />
                </Grid>
              );
            } else if (type === "image") {
              return (
                <Grid key={quesSlug} xs={xs} item>
                  <DFImage inputProps={inputProps} />
                </Grid>
              );
            } else if (type === "line") {
              return <DFRuler />;
            } else if (type === "datatable") {
              return clientYear === "2024" ? (
                <DynamicMedicationsTable2024
                  medicationsRowData={medicationsRowData}
                  setMedicationsRowData={setMedicationsRowData}
                  disableFlag={
                    disableFlag ||
                    (roleId == 5 && tabId == 3) ||
                    roleId == 6 ||
                    roleId == 8 ||
                    roleId == 7 ||
                    roleId == 9
                  }
                  labelNameOptions={labelNameOptions}
                />
              ) : (
                <DynamicMedicationsTable
                  medicationsRowData={medicationsRowData}
                  setMedicationsRowData={setMedicationsRowData}
                  disableFlag={
                    disableFlag ||
                    (roleId == 5 && tabId == 3) ||
                    roleId == 6 ||
                    roleId == 8 ||
                    roleId == 7 ||
                    roleId == 9
                  }
                  labelNameOptions={labelNameOptions}
                />
              );
            }

            return null;
          })}
        </Grid>
      </div>
    </LoadingOverlay>
  );
});

export default Allergies;
