import React, { useEffect } from "react";
import {
  Box,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { getApi } from "../../../utils/axiosApiWrapper";
import { saveAs } from "file-saver"; // You will need to install 'file-saver'

const TableComponentReferral = ({
  data = [],
  refStatusForFillHra,
  referralDateSentQaDate,
}) => {
  const tableCellStyle = {
    fontWeight: "bold",
    backgroundColor: "var(--mainTheme100)",
    border: "1px solid var(--mainTheme)",
    textAlign: "center",
    whiteSpace: "nowrap",
  };

  const tableHeadStyle = {
    fontWeight: "bold",
    backgroundColor: "var(--mainTheme)",
    textAlign: "center",
    whiteSpace: "nowrap",
  };

  // const clientYear =localStorage.getItem("clientYear")
  //   const fetchReferalReport = async (assessmentId) => {
  //     try {
  //       const assessId = sessionStorage.getItem("assessmentId", assessmentId);
  //       const response = await getApi(`/referral-form/generate-pdf?id=${assessId}`);

  //       if (response.status === 200) {
  //         const url = `${process.env.REACT_APP_API_BASE_URL}${response.data.path}`;
  //         const newTab = window.open(url, "_blank");
  //         if (newTab) {
  //           newTab.focus();
  //         }
  //       } else {
  //         console.error("Error: Failed to fetch PDF data.");
  //       }
  //     } catch (error) {
  //       console.error("Error fetching referral report:", error);
  //     } finally {
  // return;
  //    }
  //   };

  const fetchReferalReport = async (assessmentId) => {
    try {
      const assessId = sessionStorage.getItem("assessmentId", assessmentId);
      const response = await getApi(
        `/referral-form/generate-pdf?id=${assessId}`,
      );
      const truncateResponse = response.data.path.replace("/referral/", "");
      if (response.status === 200) {
        const url = `${process.env.REACT_APP_API_BASE_URL}${response.data.path}`;
        const filename = truncateResponse;

        const blobResponse = await fetch(url);
        const fileBlob = await blobResponse.blob();

        saveAs(fileBlob, filename);
      } else {
        console.error("Error: Failed to fetch PDF data.");
      }
    } catch (error) {
      console.error("Error fetching referral report:", error);
    } finally {
      return;
    }
  };

  return (
    <div>
      <CardHeader
        title={"Prior Referral Assessments"}
        action={
          <a
            style={{
              textDecoration: "none",

              color: "rgba(0, 0, 0, 0.87)",
            }}
            sx={{
              textDecoration: "none",
              color: "var(--mainTheme)",
            }}
            // href={`#${section.sectionId}`}
          >
            <div
              style={{
                width: "100% !important",
                margin: "0 !important",
                padding: "0 !important",
              }}
            ></div>
          </a>
        }
        sx={{
          backgroundColor: `var(--mainTheme900)`,
          color: "white",
          padding: "8px",
          textAlign: "center !important",
          // textTransform: "uppercase",
          fontWeight: 700,
        }}
        titleTypographyProps={{ variant: "outline" }}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Table
          sx={{
            tableLayout: "auto",
            margin: "10px",
            overflow: "hidden",
            width: "50vw",
            height: "100px",
          }}
          size="small"
        >
          <TableHead style={tableHeadStyle}>
            <TableRow>
              <TableCell
                style={{
                  border: "1px solid var(--mainTheme800)",
                  textAlign: "center",
                  color: "#ffff",
                  backgroundColor: "var(--mainTheme800)",
                }}
              >
                Date/Time
              </TableCell>
              <TableCell
                style={{
                  border: "1px solid var(--mainTheme800)",
                  textAlign: "center",
                  color: "#ffff",
                  backgroundColor: "var(--mainTheme800)",
                }}
              >
                Document
              </TableCell>
            </TableRow>
          </TableHead>
          {/* {refStatusForFillHra == 4 ? ( */}
          <TableBody>
            {data.length > 0 && refStatusForFillHra == 4 ? (
              data.map((item, index) => (
                <TableRow key={index}>
                  <TableCell style={tableCellStyle}>
                    {referralDateSentQaDate}
                  </TableCell>
                  <TableCell style={tableCellStyle}>
                    <a
                      href={item.document}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        color: "var(--mainTheme)",
                        cursor: "pointer",
                        textDecoration: "underline",
                      }}
                      onClick={() => fetchReferalReport()}
                    >
                      Referral
                    </a>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={2} style={tableCellStyle}>
                  {/* No data available */}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          {/* ) : null} */}
        </Table>
      </Box>
    </div>
  );
};

export default TableComponentReferral;
