import { Grid, Stack, TextField, InputLabel } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { MdOutlineSubdirectoryArrowRight } from "react-icons/md";
import lodash from "lodash";

import {
  DFCommentIcon,
  DFCommentTextBox,
  isCommentVisible,
  DFCommentIconMemo,
  DFCommentTextBoxMemo,
  isCommentVisibleMemo,
} from "./DFComment";

import "./DynamicForms.css";

const DFText = ({
  //depth,
  inputProps,
  value,
  handleTextChange,
  quesSlug,
  sectionId,
  showComment,
  setShowComment,
  disableFlag,
  commentValue,
  formData,
  disableProps,
  needMemo,
  getFieldValues,
  setFieldValues,
}) => {
  const { isCommentEnabled, name, label, disabled, depth, pattern } =
    inputProps;
  /*
  to-do - contained state implementation

  const { getStateValue } = useFillHraContext();
  const value = getStateValue(sectionId, name);
  const commentValue = getStateValue(sectionId, `${name}Comment`);
  */
  const inputPropsCalc = {
    readOnly: disableFlag || disabled,
  };
  if (pattern && pattern !== "") {
    inputPropsCalc.pattern = pattern;
  }

  return (
    <>
      <Stack direction="row">
        <Grid item xs={isCommentEnabled ? 11 : 12}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            {(depth > 2 && name === "vital_specialDietSubOtherSubDesc") ||
            name === "vital_preventingChangesSubOtherSubDesc" ||
            name == "ros_neuroPsychSubDiagOtherSubOther" ||
            name == "ros_neuroPsychSubDiagTraumaBrainDate" ||
            name == "ros_neuroPsychSubDiagStrokeDate" ||
            name == "self_currentLiveOtherSubDesc" ||
            name == "self_currentLiveHomelessSubDesc" ||
            name == "self_currentlyLiveAloneSubDesc" ||
            name == "self_careGiverSubDesc" ||
            name == "self_healthProxySubName" ||
            name == "self_healthProxySubRelation" ||
            name == "self_powerAttnySubName" ||
            name == "self_powerAttnySubRelation" ||
            name == "self_advDirectSubKept" ||
            name == "medical_past12MonthEmerRoomOneSubDesc" ||
            name == "medical_past12MonthEmerRoomTwoSubDesc" ||
            name == "medical_past12MonthEmerRoomThreeSubDesc" ||
            name == "medical_past12MonthEmerRoomFourSubDesc" ||
            name == "medical_past12MonthEmerRoomFiveOrMoreSubDesc" ||
            name == "medical_past12MonthHospOvernightIsOneSubDesc" ||
            name == "medical_past12MonthHospOvernightIsTwoSubDesc" ||
            name == "medical_past12MonthHospOvernightIsThreeSubDesc" ||
            name == "medical_past12MonthHospOvernightIsFourSubDesc" ||
            name == "medical_past12MonthHospOvernightIsFiveOrMoreSubDesc" ||
            name == "medical_past12MonthNursHomeisOneSubDesc" ||
            name == "medical_past12MonthNursHomeisTwoSubDesc" ||
            name == "medical_past12MonthNursHomeisThreeSubDesc" ||
            name == "medical_past12MonthNursHomeisFourSubDesc" ||
            name == "medical_past12MonthNursHomeisFiveOrMoreSubDesc" ||
            name == "medical_past12MonthHadSurgeryisOneSubDesc" ||
            name == "medical_past12MonthHadSurgeryisTwoSubDesc" ||
            name == "medical_past12MonthHadSurgeryisThreeSubDesc" ||
            name == "medical_past12MonthHadSurgeryisFourSubDesc" ||
            name == "medical_past12MonthHadSurgeryisFiveOrMoreSubDesc" ||
            name == "medical_hospPriorSubDesc" ||
            name == "exam_genAppearAbnormalSubDesc" ||
            name == "exam_headFaceExamAbnormalSubDesc" ||
            name == "exam_headFacePalpAbnormalSubDesc" ||
            name == "exam_eyesInspAbnormalSubDesc" ||
            name == "exam_eyesExamAbnormalSubDesc" ||
            name == "exam_entExtInspAbnormalSubDesc" ||
            name == "exam_entOtosExamAbnormalSubDesc" ||
            name == "exam_entAssessHearAbnormalSubDesc" ||
            name == "exam_entNasalInspAbnormalSubDesc" ||
            name == "exam_entInspLipsAbnormalSubDesc" ||
            name == "exam_entOropExamAbnormalSubDesc" ||
            name == "exam_neckExamAbnormalSubDesc" ||
            name == "exam_neckExamThyroidAbnormalSubDesc" ||
            name == "exam_pulmAssessRespAbnormalSubDesc" ||
            name == "exam_pulmAuscLungsAbnormalSubDesc" ||
            name == "exam_cardioAusHeartAbnormalSubDesc" ||
            name == "exam_cardioPalpAusAbnormalSubDesc" ||
            name == "exam_cardioPedalPulseAbnormalSubDesc" ||
            name == "exam_cardioExamEdemaAbnormalSubDesc" ||
            name == "exam_cardioExamRadialAbnormalSubDesc" ||
            name == "exam_lympPalpCerviAbnormalSubDesc" ||
            name == "exam_lympPalpPreauricAbnormalSubDesc" ||
            name == "exam_lympPalpSubmandiAbnormalSubDesc" ||
            name == "exam_muscSkelExamGaitAbnormalSubDesc" ||
            name == "exam_muscSkelInspDigitAbnormalSubDesc" ||
            name == "exam_muscSkelInspJointAbnormalSubDesc" ||
            name == "exam_muscSkelAssessRangeAbnormalSubDesc" ||
            name == "exam_muscSkelAssessStableAbnormalSubDesc" ||
            name == "exam_muscSkelAssessMuscleAbnormalSubDesc" ||
            name == "exam_skinInspSkinAbnormalSubDesc" ||
            name == "exam_noGpcogMmsePatientOrientPersonNoSubDesc" ||
            name == "exam_noGpcogMmsePatientOrientPlaceNoSubDesc" ||
            name == "exam_noGpcogMmsePatientOrientTimeNoSubDesc" ||
            name == "exam_noGpcogMmseRecallPoorSubDesc" ||
            name == "exam_neuroRombergAbnormalSubDesc" ||
            name == "exam_neuroReflexAbnormalSubDesc" ||
            name == "exam_neuroSenseAbnormalSubDesc" ||
            name == "exam_neuroCoordinateAbnormalSubDesc" ||
            name == "exam_diabetecFootExamAbnormalSubDesc" ||
            name == "exam_psychJudgeAbnormalSubDesc" ||
            name == "home_memberGaitAbnormalSubDesc" ||
            name == "home_feelingLikeHarmingSubAnsWho" ||
            name == "home_feelingLikeHarmingSubAnsAt" ||
            name == "home_feelingLikeHarmingSubAnsSafe" ||
            name == "home_feelingLikeHarmingSubAnsAssist" ||
            name == "home_afraidOfAnyOneSubAnsWho" ||
            name == "home_afraidOfAnyOneSubAnsAt" ||
            name == "home_afraidOfAnyOneSubAnsSafe" ||
            name == "home_afraidOfAnyOneSubAnsAssist" ? (
              <MdOutlineSubdirectoryArrowRight
                style={{
                  color: "var(--mainTheme)",
                  marginRight: "10px",
                  height: "25px",
                  width: "25px",
                }}
              />
            ) : (
              <div style={{ margin: "0px !important" }}></div>
            )}

            <InputLabel
              sx={{
                color: "var(--mainTheme)",
                fontWeight: "600",
                fontSize: [
                  "Patient's Height (Feet)",
                  "Patient's Height (Inch)",
                  "Patient's Weight (lbs)",
                  "BMI",
                ].includes(inputProps.label)
                  ? "13px"
                  : undefined,
              }}
            >
              {label || ""}
            </InputLabel>
          </div>
          <TextField
            value={value}
            multiline
            // variant="outlined"
            disabled={disableProps}
            minRows={1}
            maxRows={3}
            sx={{
              // height: "45px",
              borderRadius: "5px",
              border: "1.5px solid var(--mainTheme) !important",
              "& .MuiFormLabel-root": {
                backgroundColor: "white",
              },
              "& .MuiInputLabel-root": {
                backgroundColor: "white",
              },
            }}
            inputProps={{ ...inputPropsCalc }}
            fullWidth
            onChange={(event) => {
              const eventValue = event.target.value;
              const filteredValue =
                pattern && pattern !== ""
                  ? eventValue.replace(pattern, "")
                  : eventValue;
              handleTextChange(event.target.name, filteredValue);
            }}
            // label={label}
            name={name}
            InputLabelProps={{ shrink: true }}
            /* 
            InputProps={DFCommentAdornment(
             inputProps.isCommentEnabled,
             quesindex,
             section,
             showComment,
             setShowComment
            )}
            disabled={routedFrom==="previewHRA"}
            */
          />
        </Grid>
        {isCommentEnabled &&
          (needMemo ? (
            <DFCommentIconMemo
              name={name}
              value={commentValue}
              getFieldValues={getFieldValues}
              setFieldValues={setFieldValues}
              disableFlag={disableFlag}
            />
          ) : (
            <DFCommentIcon
              showComment={showComment}
              setShowComment={setShowComment}
              sectionId={sectionId}
              quesSlug={quesSlug}
              name={name}
              value={commentValue}
              disableFlag={disableFlag}
            />
          ))}
      </Stack>
      {isCommentEnabled &&
        (needMemo
          ? isCommentVisibleMemo(getFieldValues, name)
          : isCommentVisible(
              showComment,
              sectionId,
              quesSlug,
              commentValue,
            )) && (
          <DFCommentTextBox
            label={label}
            handleTextChange={handleTextChange}
            name={name}
            value={commentValue}
            disableFlag={disableFlag}
          />
        )}
    </>
  );
};

DFText.propTypes = {
  depth: PropTypes.number,
  inputProps: PropTypes.object.isRequired,
  // formData: PropTypes.object.isRequired,
  value: PropTypes.string.isRequired,
  handleTextChange: PropTypes.func.isRequired,
  quesSlug: PropTypes.string.isRequired,
  sectionId: PropTypes.number.isRequired,
  showComment: PropTypes.array.isRequired,
  setShowComment: PropTypes.func.isRequired,
  // setFormData: PropTypes.func.isRequired,
  disableFlag: PropTypes.bool,
  commentValue: PropTypes.string,
};

export default DFText;

const dontReRender = (oldProps, newProps) => {
  /*
   
  returns false - if there is a change between oldProps & newProps
  returns true - if there is no change between oldProps & newProps

  console.log(
    JSON.stringify(oldProps),
    JSON.stringify(newProps),
    lodash.isEqual(JSON.stringify(oldProps), JSON.stringify(newProps)),
    "check memo"
  );

  */

  return lodash.isEqual(JSON.stringify(oldProps), JSON.stringify(newProps));
};

export const DFTextMemo = React.memo(
  ({
    inputProps,
    value,
    commentValue,
    handleTextChange,
    getFieldValues,
    setFieldValues,
    disableFlag,
  }) => {
    const { isCommentEnabled, name, label, disabled, pattern } = inputProps;

    const inputPropsCalc = {
      readOnly: disableFlag || disabled,
    };
    if (pattern && pattern !== "") {
      inputPropsCalc.pattern = pattern;
    }

    return (
      <>
        <Stack direction="row">
          <Grid item xs={isCommentEnabled ? 11 : 12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
              }}
            >
              <InputLabel
                sx={{
                  color: "var(--mainTheme)",
                  fontWeight: "600",
                }}
              >
                {label || ""}
              </InputLabel>
            </div>
            <TextField
              value={value}
              multiline
              sx={{
                borderRadius: "5px",
                border: "1.5px solid var(--mainTheme) !important",
                "& .MuiFormLabel-root": {
                  backgroundColor: "white",
                },
                "& .MuiInputLabel-root": {
                  backgroundColor: "white",
                },
              }}
              inputProps={{ ...inputPropsCalc }}
              fullWidth
              onChange={(event) => {
                handleTextChange(event.target.name, event.target.value);
              }}
              name={name}
              InputLabelProps={{ shrink: true }}
              /* 
            InputProps={DFCommentAdornment(
             inputProps.isCommentEnabled,
             quesindex,
             section,
             showComment,
             setShowComment
            )}
            disabled={routedFrom==="previewHRA"}
            */
            />
          </Grid>
          {isCommentEnabled === true && (
            <DFCommentIconMemo
              name={name}
              value={commentValue}
              setFieldValues={setFieldValues}
              getFieldValues={getFieldValues}
              disableFlag={disableFlag}
            />
          )}
        </Stack>
        {isCommentEnabled && isCommentVisibleMemo(getFieldValues, name) && (
          <DFCommentTextBoxMemo
            label={label}
            handleTextChange={handleTextChange}
            name={name}
            value={commentValue}
            disableFlag={disableFlag}
          />
        )}
      </>
    );
  },
  dontReRender,
);

DFTextMemo.propTypes = {
  inputProps: PropTypes.object.isRequired,
  value: PropTypes.string.isRequired,
  commentValue: PropTypes.string,
  handleTextChange: PropTypes.func.isRequired,
  getFieldValues: PropTypes.func.isRequired,
  setFieldValues: PropTypes.func.isRequired,
  disableFlag: PropTypes.bool,
};
