import {
  Grid,
  InputLabel,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import PropTypes from "prop-types";
import React from "react";
import { MdOutlineSubdirectoryArrowRight } from "react-icons/md";
import lodash from "lodash";

import { DFButton } from "./DFButton";
import { DFCheckbox, DFCheckboxMemo } from "./DFCheckbox";
import {
  DFCommentIcon,
  DFCommentIconMemo,
  DFCommentTextBox,
  DFCommentTextBoxMemo,
  isCommentVisible,
  isCommentVisibleMemo,
} from "./DFComment";
import { DFDate, DFDateMemo } from "./DFDate";
import { DFHeading, DFHeadingMemo } from "./DFHeading";
import { DFImage } from "./DFImage";
import { DFLabel, DFLabelMemo } from "./DFLabel";
import DFRadio, { DFRadioMemo } from "./DFRadio";
import { DFSelect, DFSelectMemo } from "./DFSelect";
import DFText, { DFTextMemo } from "./DFText";
import {
  DynamicTableCounterMedications,
  DynamicTableFamilyHistory,
  DynamicTableSpecialists,
  DynamicDepressionSeverity,
} from "./DFDataTable";
import { useFillHraContext } from "../../../../../contexts/fillHraFormContext";

import "./DynamicForms.css";
import { DynamicTableAllergies } from "../../../DynamicFormMUI/Tables";
import { PadEligibility } from "../Sections/Screenings/padEligibility";

export const ToggleTree = ({
  depth,
  sectionId,
  quesIdSlug,
  quesNameSlug,
  inputProps,
  formData,
  handleOptionsTreeChange,
  handleTextChange,
  handleRadioChange,
  handleDateChange,
  handleSelectChange,
  handleButtonClick,
  handleCheckboxChange,
  showComment,
  setShowComment,
  setFormData,
  disableFlag,
  familyRowData,
  setFamilyRowData,
  medicalRowData,
  setMedicalRowData,
  allergiesRowData,
  setAllergiesRowData,
  medicationsRowData,
  setMedicationsRowData,
  counterMedicationsRowData,
  setCounterMedicationsRowData,
  tableName,
  data,
  getFieldVisibility,
  needMemo,
  getFieldValues,
  setFieldValues,
}) => {
  const roleId = sessionStorage.getItem("roleId");
  const tabId = sessionStorage.getItem("tabId");
  const { isCommentEnabled, name, label, xs, isMulti, disabled } = inputProps;
  const isButtonDisabled =
    disableFlag ||
    disabled ||
    (roleId == 5 && tabId == 3) ||
    roleId == 6 ||
    roleId == 8 ||
    roleId == 7 ||
    roleId == 9;
  // const { getStateValue, setStateValue } = useFillHraContext();

  const selectedValue = formData?.[name] || [];
  const commentValue = formData ? formData[name + "Comment"] : "";

  const clientYear = localStorage.getItem("clientYear");
  const screenIha = sessionStorage.getItem("screenIha");

  const shouldShowIcon = !(
    (name === "ros_eyes" ||
      name === "ros_ears" ||
      name === "ros_nose" ||
      name === "ros_mouth" ||
      name === "ros_neck" ||
      name === "ros_neuroPsych" ||
      name === "ros_musculoskeletal" ||
      name === "ros_respiratory" ||
      name === "ros_integument" ||
      name === "ros_cardio" ||
      name === "ros_gastro" ||
      name === "ros_genito" ||
      name === "ros_endocrineIsEndocrine" ||
      name === "ros_hematology" ||
      name === "ros_diagnosisOfCancer") &&
    Array.isArray(selectedValue) &&
    selectedValue.includes("yes")
  );

  const getTooltipText = (name) => {
    if (name === "self_eduGuide") {
      return (
        <p>
          Recreational Drugs: Methamphetamines (speed, crystal), Cannabis
          (marijuana, pot), inhalants (paint thinner, aerosol, glue),
          tranquilizers (Valium), barbiturates, cocaine, ecstasy, hallucinogens
          (LSD, Mushrooms), or narcotics(heroin).
        </p>
      );
    } else if (name === "exam_diabFoot") {
      return (
        <p>
          • The device is placed perpendicular to the skin, with pressure
          applied until the monofilament buckles. It should be in place for
          about 1 second, then released . • The test should be performed at the
          sites highlighted while the patient's eyes are closed.
        </p>
      );
    }
  };

  const getDisabledForScreeningToggleBtn = (subQuestion) => {
    if (
      [
        "leftKit",
        "collectedSample",
        "memberRefused",
        "examCompleted",
        "environmentalIssue",
        "mailKitDirectToMember",
        "resultReceived",
        "memberRecentlyCompleted",
        "scheduledToComplete",
        "memberApprehension",
        "notInterested",
        "other",
        "validImage",
        "invalidImage",
      ]?.includes(subQuestion.name)
    ) {
      return false;
    }
    if (
      selectedValue[0] === "yes" &&
      (subQuestion.label == "No" || subQuestion.label == "Yes") &&
      (sectionId == 14 || (sectionId == 4 && screenIha === "Screen Only")) &&
      roleId == "5"
    ) {
      return true;
    } else if (
      selectedValue.length == 0 &&
      (sectionId == 14 || (sectionId == 4 && screenIha === "Screen Only")) &&
      roleId == "5"
    ) {
      return true;
    } else if (
      selectedValue[0] === "no" &&
      (subQuestion.label == "No" || subQuestion.label == "Yes") &&
      (sectionId == 14 || (sectionId == 4 && screenIha === "Screen Only")) &&
      roleId == "5"
    ) {
      return true;
    } else if (
      selectedValue[0] === "" &&
      (subQuestion.label == "No" || subQuestion.label == "Yes") &&
      (sectionId == 14 || (sectionId == 4 && screenIha === "Screen Only")) &&
      roleId == "5"
    ) {
      return true;
    } else if (
      selectedValue[0] === "yes" &&
      name == "microAlbumin" &&
      subQuestion.name == "memberRefused" &&
      (sectionId == 14 || (sectionId == 4 && screenIha === "Screen Only")) &&
      roleId == "5"
    ) {
      return false;
    } else if (
      selectedValue[0] === "yes" &&
      name == "ked" &&
      subQuestion.name == "screenkedUrineAlbumin" &&
      (sectionId == 14 || (sectionId == 4 && screenIha === "Screen Only")) &&
      roleId == "5"
    ) {
      return false;
    } else return undefined;
  };

  const getDisabledForScreeningCommentBubble = () => {
    if (
      selectedValue[0] === "yes" &&
      //  subQuestion.label == "No" &&
      (sectionId == 14 || (sectionId == 4 && screenIha === "Screen Only")) &&
      roleId == "5"
    ) {
      return false;
    } else if (
      selectedValue.length == 0 &&
      (sectionId == 14 || (sectionId == 4 && screenIha === "Screen Only")) &&
      roleId == "5"
    ) {
      return true;
    } else if (
      selectedValue[0] === "no" &&
      //subQuestion.label == "Yes" &&
      (sectionId == 14 || (sectionId == 4 && screenIha === "Screen Only")) &&
      roleId == "5"
    ) {
      return true;
    } else if (
      selectedValue[0] === "yes" &&
      name == "microAlbumin" &&
      //subQuestion.name == "memberRefused" &&
      (sectionId == 14 || (sectionId == 4 && screenIha === "Screen Only")) &&
      roleId == "5"
    ) {
      return false;
    } else return undefined;
  };
  return (
    <Grid
      container
      style={{
        marginLeft:
          depth < 2 &&
          ![
            "pain_patientExperiencePainSubPainAcute",
            "pain_patientExperiencePainSubPainChronic",
            "pain_patientExperiencePainSubPainManagementTiming",
            "pain_patientExperiencePainSubOpioidMedication",
            "pain_patientExperiencePainSubPatientAdvised",
          ].includes(name)
            ? "0px"
            : "15px",
      }}
      fullWidth
    >
      <Grid
        item
        key={quesIdSlug}
        style={{ display: "grid", flexBasis: "100%", maxWidth: "100%" }}
        xs={xs || 12}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            {(depth > 1 &&
              name !== "pain_patientExperiencePainSubPainAcute" &&
              name !== "pain_patientExperiencePainSubPainChronic" &&
              name !== "pain_patientExperiencePainSubPainManagementTiming" &&
              name !== "pain_patientExperiencePainSubOpioidMedication" &&
              name !== "pain_patientExperiencePainSubPatientAdvised" &&
              name !== "ros_musculoskeletalMuskoHadAnAmputation") ||
            name == "ros_neuroPsychSubDiag" ? (
              <MdOutlineSubdirectoryArrowRight
                style={{
                  color: "var(--mainTheme)",
                  marginRight: "10px",
                  height: "25px",
                  width: "25px",
                }}
              />
            ) : (
              <div style={{ margin: "0px !important" }}></div>
            )}

            <InputLabel
              style={{
                color: "var(--mainTheme)",
                fontWeight: "700",
                // wordWrap: "break-word",
                // wordBreak: "break-all",
              }}
            >
              {inputProps.label}
              {((name === "exam_diabFoot" && clientYear == "2024") ||
                name === "self_eduGuide") && (
                <Tooltip
                  title={
                    <div style={{ fontSize: "16px", width: "300px" }}>
                      {getTooltipText(name)}
                    </div>
                  }
                  placement="top"
                  arrow
                >
                  <InfoIcon
                    variant="outlined"
                    style={{
                      cursor: "pointer",
                      marginLeft: "20px",
                      marginBottom: "-3px",
                    }}
                  />
                </Tooltip>
              )}
            </InputLabel>
          </div>
          {isCommentEnabled === true &&
            shouldShowIcon &&
            (needMemo ? (
              <DFCommentIconMemo
                getFieldValues={getFieldValues}
                setFieldValues={setFieldValues}
                name={name}
                value={commentValue}
                disableFlag={
                  disableFlag ||
                  (roleId == 5 && tabId == 3) ||
                  roleId == 6 ||
                  roleId == 8 ||
                  roleId == 7 ||
                  roleId == 9
                }
              />
            ) : (
              <DFCommentIcon
                showComment={showComment}
                setShowComment={setShowComment}
                sectionId={sectionId}
                quesSlug={quesIdSlug}
                value={commentValue}
                disableFlag={
                  // sectionId == 14 && roleId == "5"
                  //   ? getDisabledForScreeningCommentBubble()
                  //   : disableFlag
                  disableFlag ||
                  (roleId == 5 && tabId == 3) ||
                  roleId == 6 ||
                  roleId == 8 ||
                  roleId == 7 ||
                  roleId == 9
                }
              />
            ))}
        </div>
        <ToggleButtonGroup
          {...(!isMulti && { exclusive: true })}
          fullWidth
          role="group"
          size="large"
          onChange={(e, newValues) => {
            handleOptionsTreeChange(name, newValues, isMulti);
            //let cmntIdxVal = `${sectionId}_${quesIdSlug}`;

            // if (commentValue === undefined) { // commenting as it is creating issue in comment box
            //   setShowComment(false);
            // } else {
            //   setShowComment({
            //     ...showComment,
            //     [cmntIdxVal]: undefined,
            //   });
            // }
          }}
          selected={selectedValue}
          name={inputProps.name}
          value={selectedValue}
          sx={{
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          {inputProps?.subQuestions?.map((subQuestion, index) => {
            if (subQuestion.type === "button") {
              return (
                <PadEligibility
                  disableFlag={disableFlag}
                  screeningsFormData={formData}
                  setscreeningsFormData={setFormData}
                />
              );
            } else {
              return (
                <ToggleButton
                  key={`${quesIdSlug}_${index}`}
                  value={subQuestion.value}
                  aria-pressed
                  disableRipple
                  disabled={
                    (sectionId == 14 ||
                      (sectionId == 4 && screenIha === "Screen Only")) &&
                    roleId == "5"
                      ? getDisabledForScreeningToggleBtn(subQuestion)
                      : false
                  }
                  name={subQuestion.name}
                  role="button"
                  aria-label={subQuestion.label}
                  className="toggleButtonGroup"
                  {...(isButtonDisabled && { disabled: true })}
                  sx={{
                    // minWidth: "230px",
                    overflow: "hidden",
                    display: "inline-block",
                    // whiteSpace: "normal",
                    minHeight: "70px",
                    margin: "12px",
                    width: "230px",
                    textAlign: "center",
                    justifyContent: "center",
                    borderRadius: "5px",
                    color: "black",
                    lineHeight: "17px !important",
                    // margin: "5px 15px 0 0",
                    borderStyle: "none",
                    textTransform: "none",
                    border: `1px solid var(--mainTheme) !important`,
                    flexWrap: "wrap",
                    padding: "0px !important",
                    fontSize: "0.85rem",
                    "&.Mui-selected ": {
                      backgroundColor: "var(--mainTheme900)",
                      color: "white",
                      border: "1px solid var(--mainTheme) !important",
                      fontWeight: "600",
                      fontSize: "0.85rem",
                      lineHeight: "17px !important",
                      padding: "0px !important",
                      // textTransform: "uppercase",
                    },
                    "&.Mui-disabled ": {
                      //backgroundColor: "var(--mainTheme900)",
                      color: "black",
                      border: "1px solid var(--mainTheme) !important",
                      fontWeight: "600",
                      fontSize: "0.85rem",
                      // textTransform: "uppercase",
                    },
                    "&.Mui-selected:hover": {
                      backgroundColor: "var(--mainTheme750)",
                      color: "white",
                      border: "1px solid var(--mainTheme) !important",
                    },
                    "&.MuiToggleButtonGroup-grouped": {
                      borderRadius: "5px !important",
                    },
                    ":hover": {
                      backgroundColor: "var(--mainTheme400)",
                      // textTransform: "uppercase",
                      // color: "white",
                      border: "1px solid var(--mainTheme) !important",
                    },
                  }}
                >
                  {subQuestion.label}
                </ToggleButton>
              );
            }
          })}
        </ToggleButtonGroup>
        {!["allergy_counterMedic", "allergy_allergies"].includes(name) &&
          shouldShowIcon &&
          (needMemo
            ? isCommentVisibleMemo(getFieldValues, name)
            : isCommentVisible(
                showComment,
                sectionId,
                quesIdSlug,
                commentValue,
              )) && (
            <DFCommentTextBox
              label={label}
              handleTextChange={handleTextChange}
              name={name}
              value={commentValue}
              disableFlag={
                disableFlag ||
                (roleId == 5 && tabId == 3) ||
                roleId == 6 ||
                roleId == 8 ||
                roleId == 7 ||
                roleId == 9
              }
            />
          )}
        {selectedValue &&
          inputProps?.subQuestions?.map((subQuestion) => {
            const isValueSelected = selectedValue?.includes(subQuestion.value);
            const isLinkedFieldSelected =
              getFieldVisibility !== undefined
                ? getFieldVisibility(name)
                : false;

            const canCallRecursive =
              subQuestion.hasSubQuestions === true &&
              (isLinkedFieldSelected || isValueSelected);
            if (canCallRecursive) {
              const newQuesSlug = `${quesIdSlug}_${subQuestion.subQuestionId}`;
              return (
                <NestedTree
                  key={newQuesSlug}
                  subQuestions={subQuestion.subQuestions}
                  formData={formData}
                  setFormData={setFormData}
                  selectedValue={selectedValue}
                  handleOptionsTreeChange={handleOptionsTreeChange}
                  handleTextChange={handleTextChange}
                  handleRadioChange={handleRadioChange}
                  handleDateChange={handleDateChange}
                  handleSelectChange={handleSelectChange}
                  handleButtonClick={handleButtonClick}
                  handleCheckboxChange={handleCheckboxChange}
                  showComment={showComment}
                  setShowComment={setShowComment}
                  depth={depth + 1}
                  quesIdSlug={newQuesSlug}
                  quesNameSlug={`${quesNameSlug}_${
                    subQuestion.name || subQuestion.value || ""
                  }`}
                  sectionId={sectionId}
                  disableFlag={
                    disableFlag ||
                    (roleId == 5 && tabId == 3) ||
                    roleId == 6 ||
                    roleId == 8 ||
                    roleId == 7 ||
                    roleId == 9
                  }
                  familyRowData={familyRowData}
                  setFamilyRowData={setFamilyRowData}
                  medicalRowData={medicalRowData}
                  setMedicalRowData={setMedicalRowData}
                  allergiesRowData={allergiesRowData}
                  setAllergiesRowData={setAllergiesRowData}
                  medicationsRowData={medicationsRowData}
                  setMedicationsRowData={setMedicationsRowData}
                  counterMedicationsRowData={counterMedicationsRowData}
                  setCounterMedicationsRowData={setCounterMedicationsRowData}
                  tableName={tableName}
                  data={data}
                  getFieldVisibility={getFieldVisibility}
                  needMemo={needMemo}
                  getFieldValues={getFieldValues}
                  setFieldValues={setFieldValues}
                />
              );
            }
            return null;
          })}
        {isCommentEnabled &&
          shouldShowIcon &&
          ["allergy_counterMedic", "allergy_allergies"].includes(name) &&
          (needMemo
            ? isCommentVisibleMemo(getFieldValues, name)
            : isCommentVisible(
                showComment,
                sectionId,
                quesIdSlug,
                commentValue,
              )) && (
            <DFCommentTextBox
              label={label}
              handleTextChange={handleTextChange}
              name={name}
              value={commentValue}
              disableFlag={disableFlag}
            />
          )}
      </Grid>
    </Grid>
  );
};

ToggleTree.propTypes = {
  depth: PropTypes.number,
  sectionId: PropTypes.number,
  quesIdSlug: PropTypes.string,
  quesNameSlug: PropTypes.string,
  inputProps: PropTypes.object,
  formData: PropTypes.object,
  handleOptionsTreeChange: PropTypes.func,
  handleTextChange: PropTypes.func,
  handleRadioChange: PropTypes.func,
  handleDateChange: PropTypes.func,
  handleSelectChange: PropTypes.func,
  handleButtonClick: PropTypes.func,
  handleCheckboxChange: PropTypes.func,
  showComment: PropTypes.array,
  setShowComment: PropTypes.func,
  setFormData: PropTypes.func,
  disableFlag: PropTypes.bool,
};

export const NestedTree = ({
  depth,
  quesIdSlug,
  quesNameSlug,
  sectionId,
  subQuestions,
  formData,
  setFormData,
  familyRowData,
  setFamilyRowData,
  medicalRowData,
  setMedicalRowData,
  allergiesRowData,
  setAllergiesRowData,
  medicationsRowData,
  setMedicationsRowData,
  counterMedicationsRowData,
  setCounterMedicationsRowData,
  tableName,
  data,
  selectedValue,
  handleOptionsTreeChange,
  handleTextChange,
  handleRadioChange,
  handleDateChange,
  handleSelectChange,
  handleButtonClick,
  handleCheckboxChange,
  showComment,
  setShowComment,
  disableFlag,
  getFieldVisibility,
  needMemo,
  getFieldValues,
  setFieldValues,
}) => {
  // const { getStateValue, setStateValue } = useFillHraContext();
  const roleId = sessionStorage.getItem("roleId");
  const tabId = sessionStorage.getItem("tabId");
  const screenIha = sessionStorage.getItem("screenIha");
  const getDisabledForScreeningSecDFText = (subQuestion) => {
    //  const roleId = sessionStorage.getItem('roleId');
    if (
      subQuestion.name == "screenmicroAlbuminsResult" &&
      formData.microAlbumin?.length > 0 &&
      formData.microAlbumin[0] == "yes" &&
      (sectionId == 14 || (sectionId == 4 && screenIha === "Screen Only")) &&
      roleId == "5"
    ) {
      return true;
    } else if (
      subQuestion.name == "screenfobtResult" &&
      formData.fobt?.length > 0 &&
      formData.fobt[0] == "yes" &&
      (sectionId == 14 || (sectionId == 4 && screenIha === "Screen Only")) &&
      roleId == "5"
    ) {
      return true;
    } else if (
      subQuestion.name == "screenaOneCsResult" &&
      formData.aOneC?.length > 0 &&
      formData.aOneC[0] == "yes" &&
      (sectionId == 14 || (sectionId == 4 && screenIha === "Screen Only")) &&
      roleId == "5"
    ) {
      return true;
    } else if (
      subQuestion.name == "ldlsResult" &&
      formData.ldl?.length > 0 &&
      formData.ldl[0] == "yes" &&
      (sectionId == 14 || (sectionId == 4 && screenIha === "Screen Only")) &&
      roleId == "5"
    ) {
      return true;
    } else if (
      subQuestion.name == "retinalExamsResult" &&
      formData.retinalExam?.length > 0 &&
      formData.retinalExam[0] == "yes" &&
      (sectionId == 14 || (sectionId == 4 && screenIha === "Screen Only")) &&
      roleId == "5"
    ) {
      return true;
    } else if (
      subQuestion.name == "screenfitResult" &&
      formData.fit?.length > 0 &&
      formData.fit[0] == "yes" &&
      (sectionId == 14 || (sectionId == 4 && screenIha === "Screen Only")) &&
      roleId == "5"
    ) {
      return true;
    } else if (
      (subQuestion.name == "screenkedUrineAlbumin" ||
        subQuestion.name == "screenkedUrineCreatinine" ||
        subQuestion.name == "screenkedUrineALB" ||
        subQuestion.name == "screenkedCreatinine" ||
        subQuestion.name == "screenkedeGFR") &&
      formData.ked?.length > 0 &&
      formData.ked[0] == "yes" &&
      (sectionId == 14 || (sectionId == 4 && screenIha === "Screen Only")) &&
      roleId == "5"
    ) {
      return true;
    } else return undefined;
  };
  const getDisabledForScreeningSecToggleTree = (subQuestion) => {
    const { name } = subQuestion;
    const selectedValue = formData?.[name] || [];
    if (
      subQuestion.name == "screenmicroAlbuminsResult" &&
      formData.microAlbumin?.length > 0 &&
      formData.microAlbumin[0] == "yes" &&
      (sectionId == 14 || (sectionId == 4 && screenIha === "Screen Only")) &&
      roleId == "5"
    ) {
      return true;
    } else if (
      subQuestion.name == "screenfobtResult" &&
      formData.fobt?.length > 0 &&
      formData.fobt[0] == "yes" &&
      (sectionId == 14 || (sectionId == 4 && screenIha === "Screen Only")) &&
      roleId == "5"
    ) {
      return true;
    } else if (
      subQuestion.name == "screenaOneCsResult" &&
      formData.aOneC?.length > 0 &&
      formData.aOneC[0] == "yes" &&
      (sectionId == 14 || (sectionId == 4 && screenIha === "Screen Only")) &&
      roleId == "5"
    ) {
      return true;
    } else if (
      subQuestion.name == "ldlsResult" &&
      formData.ldl?.length > 0 &&
      formData.ldl[0] == "yes" &&
      (sectionId == 14 || (sectionId == 4 && screenIha === "Screen Only")) &&
      roleId == "5"
    ) {
      return true;
    } else if (
      subQuestion.name == "retinalExamsResult" &&
      formData.retinalExam?.length > 0 &&
      formData.retinalExam[0] == "yes" &&
      (sectionId == 14 || (sectionId == 4 && screenIha === "Screen Only")) &&
      roleId == "5"
    ) {
      return true;
    } else return undefined;
  };

  return (
    <div style={{ marginLeft: "15px" }}>
      <Grid container gap={1} style={{ marginLeft: "0px" }}>
        {subQuestions?.map((subQuestion) => {
          const {
            subQuestionId,
            name,
            value,
            xs,
            type,
            isSingleButton,
            isVisible,
          } = subQuestion;
          const inputValue = formData?.[name] || "";
          const commentValue = formData ? formData[name + "Comment"] : "";
          const quesIdSlugVal = `${quesIdSlug}_${subQuestionId}`;
          const quesNameSlugVal = `${quesNameSlug}_${name || value || ""}`;
          if (
            type === "text" ||
            type === "number" ||
            type === "email" ||
            type === "password"
          ) {
            let canCallRecursive = true;
            if (isVisible) {
              const isLinkedFieldSelected =
                getFieldVisibility !== undefined
                  ? getFieldVisibility(name)
                  : false;
              canCallRecursive = canCallRecursive && isLinkedFieldSelected;
            }

            if (canCallRecursive) {
              return (
                <Grid key={quesIdSlugVal} xs={xs} item>
                  <div style={{ display: "flex" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "start",
                      }}
                    >
                      {depth > 2 || name == "ros_neuroPsychSubDiag" ? (
                        <MdOutlineSubdirectoryArrowRight
                          style={{
                            color: "var(--mainTheme)",
                            marginRight: "10px",
                            height: "25px",
                            width: "25px",
                          }}
                        />
                      ) : (
                        <div style={{ margin: "0px !important" }}></div>
                      )}
                    </div>
                    <div style={{ width: "90%" }}>
                      <DFText
                        inputProps={subQuestion}
                        value={inputValue}
                        handleTextChange={handleTextChange}
                        showComment={showComment}
                        setShowComment={setShowComment}
                        quesSlug={quesIdSlugVal}
                        sectionId={sectionId}
                        commentValue={commentValue}
                        disableFlag={disableFlag}
                        formData={formData}
                        disableProps={
                          roleId == "5" &&
                          (sectionId == "14" ||
                            (sectionId == "4" && screenIha === "Screen Only"))
                            ? getDisabledForScreeningSecDFText(subQuestion)
                            : disableFlag
                        }
                        needMemo={needMemo}
                        getFieldValues={getFieldValues}
                        setFieldValues={setFieldValues}
                      />
                    </div>
                  </div>
                </Grid>
              );
            }
          } else if (type === "datatable") {
            if (name === "ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeverity") {
              return (
                <DynamicDepressionSeverity
                  formData={formData}
                  setFormData={setFormData}
                />
              );
            } else if (name === "FamilyDatatableVal") {
              return (
                <DynamicTableFamilyHistory
                  familyRowData={familyRowData}
                  setFamilyRowData={setFamilyRowData}
                  // memberId={
                  //   memberId
                  // }
                  disableFlag={disableFlag}
                />
              );
            } else if (name === "medical_specialistsSubDatatable") {
              return (
                <DynamicTableSpecialists
                  medicalRowData={medicalRowData}
                  setMedicalRowData={setMedicalRowData}
                  // memberId={
                  //   memberId
                  // }
                  disableFlag={disableFlag}
                />
              );
            } else if (name === "allergy_allergiesDatatable") {
              return (
                <DynamicTableAllergies
                  allergiesRowData={allergiesRowData}
                  setAllergiesRowData={setAllergiesRowData}
                  // memberId={
                  //   memberId
                  // }
                  disableFlag={
                    disableFlag ||
                    (roleId == 5 && tabId == 3) ||
                    roleId == 6 ||
                    roleId == 8 ||
                    roleId == 7 ||
                    roleId == 9
                  }
                />
              );
            } else if (name === "allergy_counterMedicDatatable") {
              return (
                <DynamicTableCounterMedications
                  counterMedicationsRowData={counterMedicationsRowData}
                  setCounterMedicationsRowData={setCounterMedicationsRowData}
                  // memberId={
                  //   memberId
                  // }
                  disableFlag={
                    disableFlag ||
                    (roleId == 5 && tabId == 3) ||
                    roleId == 6 ||
                    roleId == 8 ||
                    roleId == 7 ||
                    roleId == 9
                  }
                />
              );
            }
            return null;
          } else if (type === "radio") {
            return (
              <Grid key={quesIdSlugVal} xs={xs} item>
                <DFRadio
                  inputProps={subQuestion}
                  value={inputValue}
                  handleRadioChange={handleRadioChange}
                  handleTextChange={handleTextChange} // added for comment box event handling
                  showComment={showComment}
                  setShowComment={setShowComment}
                  quesSlug={quesIdSlugVal}
                  sectionId={sectionId}
                  commentValue={commentValue}
                  disableFlag={disableFlag}
                  needMemo={needMemo}
                  getFieldValues={getFieldValues}
                  setFieldValues={setFieldValues}
                />
              </Grid>
            );
          } else if (type === "date") {
            const dateValue = formData[name] || {};
            return (
              <Grid key={quesIdSlugVal} xs={xs} item>
                <DFDate
                  inputProps={subQuestion}
                  value={dateValue}
                  handleDateChange={handleDateChange}
                  handleTextChange={handleTextChange} // added for comment box event handling
                  showComment={showComment}
                  setShowComment={setShowComment}
                  quesSlug={quesIdSlugVal}
                  sectionId={sectionId}
                  commentValue={commentValue}
                  disableFlag={disableFlag}
                  needMemo={needMemo}
                  getFieldValues={getFieldValues}
                  setFieldValues={setFieldValues}
                />
              </Grid>
            );
          } else if (type === "select") {
            return (
              <Grid key={quesIdSlugVal} xs={xs} item>
                <DFSelect
                  inputProps={subQuestion}
                  value={inputValue}
                  handleSelectChange={handleSelectChange}
                  handleTextChange={handleTextChange} // added for comment box event handling
                  showComment={showComment}
                  setShowComment={setShowComment}
                  quesSlug={quesIdSlugVal}
                  sectionId={sectionId}
                  commentValue={commentValue}
                  disableFlag={disableFlag}
                  needMemo={needMemo}
                  getFieldValues={getFieldValues}
                  setFieldValues={setFieldValues}
                />
              </Grid>
            );
          } else if (type === "options_tree") {
            return (
              <ToggleTree
                key={quesIdSlugVal}
                inputProps={subQuestion}
                disableFlag={
                  roleId == "5" &&
                  (sectionId == 14 ||
                    (sectionId == 4 && screenIha === "Screen Only"))
                    ? false //getDisabledForScreeningSecToggleTree(subQuestion)
                    : disableFlag
                }
                // disabled = {getDisabledForScreeningSec(subQuestion)}
                // disableProps={getDisabledForScreeningSec(subQuestion)}
                formData={formData}
                handleOptionsTreeChange={handleOptionsTreeChange}
                handleTextChange={handleTextChange}
                handleRadioChange={handleRadioChange}
                handleDateChange={handleDateChange}
                handleSelectChange={handleSelectChange}
                handleButtonClick={handleButtonClick}
                handleCheckboxChange={handleCheckboxChange}
                showComment={showComment}
                setShowComment={setShowComment}
                setFormData={setFormData}
                commentValue={commentValue}
                getFieldVisibility={getFieldVisibility}
                //disableFlag={disableFlag}
                selectedValue={inputValue}
                depth={depth + 1}
                quesIdSlug={quesIdSlugVal}
                sectionId={sectionId}
                quesNameSlug={quesNameSlugVal}
                needMemo={needMemo}
                getFieldValues={getFieldValues}
                setFieldValues={setFieldValues}
              />
            );
          } else if (type === "label") {
            return (
              <Grid key={quesIdSlugVal} xs={xs} item>
                <DFLabel
                  inputProps={subQuestion}
                  handleTextChange={handleTextChange} // added for comment box event handling
                  showComment={showComment}
                  setShowComment={setShowComment}
                  quesSlug={quesIdSlugVal}
                  sectionId={sectionId}
                  commentValue={commentValue}
                  disableFlag={disableFlag}
                  needMemo={needMemo}
                  getFieldValues={getFieldValues}
                  setFieldValues={setFieldValues}
                />
              </Grid>
            );
          } else if (type === "button") {
            return (
              <Grid key={quesIdSlugVal} xs={isSingleButton ? 12 : 0} item>
                <DFButton
                  inputProps={subQuestion}
                  handleButtonClick={handleButtonClick}
                />
              </Grid>
            );
          } else if (type === "heading") {
            return (
              <Grid key={quesIdSlugVal} xs={12} sm={12} item>
                <DFHeading inputProps={subQuestion} />
              </Grid>
            );
          } else if (type === "checkbox") {
            return (
              <Grid key={quesIdSlugVal} xs={xs} item>
                <DFCheckbox
                  inputProps={subQuestion}
                  value={inputValue}
                  showComment={showComment}
                  handleCheckboxChange={handleCheckboxChange}
                  handleTextChange={handleTextChange}
                  setShowComment={setShowComment}
                  quesSlug={quesIdSlugVal}
                  sectionId={sectionId}
                  commentValue={commentValue}
                  disableFlag={disableFlag}
                  needMemo={needMemo}
                  getFieldValues={getFieldValues}
                  setFieldValues={setFieldValues}
                />
              </Grid>
            );
          } else if (type === "image") {
            return (
              <Grid key={quesIdSlugVal} xs={xs} item>
                <DFImage inputProps={subQuestion} />
              </Grid>
            );
          }

          return null;
        })}
      </Grid>
    </div>
  );
};

NestedTree.propTypes = {
  depth: PropTypes.number,
  quesIdSlug: PropTypes.string,
  quesNameSlug: PropTypes.string,
  sectionId: PropTypes.number,
  subQuestions: PropTypes.array,
  formData: PropTypes.object,
  setFormData: PropTypes.func,
  selectedValue: PropTypes.string || PropTypes.array,
  handleOptionsTreeChange: PropTypes.func,
  handleTextChange: PropTypes.func,
  handleRadioChange: PropTypes.func,
  handleDateChange: PropTypes.func,
  handleSelectChange: PropTypes.func,
  handleButtonClick: PropTypes.func,
  handleCheckboxChange: PropTypes.func,
  showComment: PropTypes.array,
  setShowComment: PropTypes.func,
  disableFlag: PropTypes.bool,
};

export const DFOptionsTree = ({
  inputProps,
  formData,
  handleOptionsTreeChange,
  handleTextChange,
  handleRadioChange,
  handleDateChange,
  handleSelectChange,
  handleButtonClick,
  handleCheckboxChange,
  quesSlug,
  sectionId,
  showComment,
  setShowComment,
  setFormData,
  disableFlag,
  // commentValue,
  familyRowData,
  setFamilyRowData,
  medicalRowData,
  setMedicalRowData,
  allergiesRowData,
  setAllergiesRowData,
  medicationsRowData,
  setMedicationsRowData,
  counterMedicationsRowData,
  setCounterMedicationsRowData,
  tableName,
  data,
  inputPropsFromScreening,
  getFieldVisibility,
  needMemo,
  getFieldValues,
  setFieldValues,
}) => {
  const { name, xs } = inputProps;
  const { getStateValue, setStateValue } = useFillHraContext();
  return (
    <Grid
      item
      key={quesSlug}
      xs={xs || 12}
      style={{ marginLeft: "0px" }}
      zeroMinWidth
    >
      <ToggleTree
        depth={1}
        sectionId={sectionId}
        quesIdSlug={`${quesSlug}`}
        quesNameSlug={`${name}`}
        inputProps={inputProps}
        formData={formData}
        handleOptionsTreeChange={handleOptionsTreeChange}
        handleTextChange={handleTextChange}
        handleRadioChange={handleRadioChange}
        handleDateChange={handleDateChange}
        handleSelectChange={handleSelectChange}
        handleButtonClick={handleButtonClick}
        handleCheckboxChange={handleCheckboxChange}
        showComment={showComment}
        setShowComment={setShowComment}
        setFormData={setFormData}
        disableFlag={disableFlag}
        familyRowData={familyRowData}
        setFamilyRowData={setFamilyRowData}
        medicalRowData={medicalRowData}
        setMedicalRowData={setMedicalRowData}
        allergiesRowData={allergiesRowData}
        setAllergiesRowData={setAllergiesRowData}
        medicationsRowData={medicationsRowData}
        setMedicationsRowData={setMedicationsRowData}
        counterMedicationsRowData={counterMedicationsRowData}
        setCounterMedicationsRowData={setCounterMedicationsRowData}
        tableName={tableName}
        data={data}
        getFieldVisibility={getFieldVisibility}
        needMemo={needMemo}
        getFieldValues={getFieldValues}
        setFieldValues={setFieldValues}
      />
    </Grid>
  );
};

DFOptionsTree.propTypes = {
  inputProps: PropTypes.object.isRequired,
  formData: PropTypes.object.isRequired,
  handleOptionsTreeChange: PropTypes.func.isRequired,
  handleTextChange: PropTypes.func.isRequired,
  handleRadioChange: PropTypes.func.isRequired,
  handleDateChange: PropTypes.func.isRequired,
  handleSelectChange: PropTypes.func.isRequired,
  handleButtonClick: PropTypes.func.isRequired,
  handleCheckboxChange: PropTypes.func.isRequired,
  quesSlug: PropTypes.string.isRequired,
  sectionId: PropTypes.number.isRequired,
  showComment: PropTypes.array.isRequired,
  setShowComment: PropTypes.func.isRequired,
  setFormData: PropTypes.func.isRequired,
  disableFlag: PropTypes.bool,
  // commentValue: PropTypes.string,
};

const dontReRender = (oldProps, newProps) => {
  /*
   
  returns false - if there is a change between oldProps & newProps
  returns true - if there is no change between oldProps & newProps

  console.log(
    JSON.stringify(oldProps),
    JSON.stringify(newProps),
    lodash.isEqual(JSON.stringify(oldProps), JSON.stringify(newProps)),
    "check memo"
  );

  */

  return lodash.isEqual(JSON.stringify(oldProps), JSON.stringify(newProps));
};

export const ToggleTreeMemo = ({
  depth,
  sectionId,
  quesIdSlug,
  quesNameSlug,
  inputProps,
  formData,
  handleOptionsTreeChange,
  handleTextChange,
  handleRadioChange,
  handleDateChange,
  handleSelectChange,
  handleButtonClick,
  handleCheckboxChange,
  getFieldValues,
  setFieldValues,
  disableFlag,
  getNestingValues,
  getFieldVisibility,
}) => {
  const { isCommentEnabled, name, label, xs, isMulti, disabled } = inputProps;
  const isButtonDisabled = disableFlag || disabled;
  // const { getStateValue, setStateValue } = useFillHraContext();

  const selectedValue = formData?.[name] || [];
  const commentValue = formData ? formData[name + "Comment"] : "";

  const shouldShowIcon = !(
    (name === "ros_eyes" ||
      name === "ros_ears" ||
      name === "ros_nose" ||
      name === "ros_mouth" ||
      name === "ros_neck" ||
      name === "ros_neuroPsych" ||
      name === "ros_musculoskeletal") &&
    Array.isArray(selectedValue) &&
    selectedValue.includes("yes")
  );

  return (
    <Grid
      container
      style={{
        marginLeft: depth < 2 ? "0px" : "15px",
      }}
      fullWidth
    >
      <Grid
        item
        key={quesIdSlug}
        style={{ display: "grid", flexBasis: "100%", maxWidth: "100%" }}
        xs={xs || 12}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            {depth > 1 || name == "ros_neuroPsychSubDiag" ? (
              <MdOutlineSubdirectoryArrowRight
                style={{
                  color: "var(--mainTheme)",
                  marginRight: "10px",
                  height: "25px",
                  width: "25px",
                }}
              />
            ) : (
              <div style={{ margin: "0px !important" }}></div>
            )}

            <InputLabel
              style={{
                color: "var(--mainTheme)",
                fontWeight: "700",
              }}
            >
              {inputProps.label}
            </InputLabel>
          </div>
          {isCommentEnabled === true && shouldShowIcon && (
            <DFCommentIconMemo
              name={name}
              value={commentValue}
              getFieldValues={getFieldValues}
              setFieldValues={setFieldValues}
              disableFlag={disableFlag}
            />
          )}
        </div>
        <ToggleButtonGroup
          {...(!isMulti && { exclusive: true })}
          fullWidth
          role="group"
          size="large"
          onChange={(e, newValues) => {
            handleOptionsTreeChange(name, newValues, isMulti);
          }}
          selected={selectedValue}
          name={inputProps.name}
          value={selectedValue}
          sx={{
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          {inputProps?.subQuestions?.map((subQuestion, index) => {
            return (
              <ToggleButton
                key={`${quesIdSlug}_${index}`}
                value={subQuestion.value}
                aria-pressed
                disableRipple
                name={subQuestion.name}
                role="button"
                aria-label={subQuestion.label}
                className="toggleButtonGroup"
                {...(isButtonDisabled && { disabled: true })}
                sx={{
                  overflow: "hidden",
                  display: "inline-block",
                  minHeight: "60px",
                  margin: "12px",
                  width: "230px",
                  textAlign: "center",
                  justifyContent: "center",
                  borderRadius: "5px",
                  color: "black",
                  borderStyle: "none",
                  textTransform: "none",
                  border: `1px solid var(--mainTheme) !important`,
                  flexWrap: "wrap",
                  padding: "5px",
                  fontSize: "0.85rem",
                  "&.Mui-selected ": {
                    backgroundColor: "var(--mainTheme900)",
                    color: "white",
                    border: "1px solid var(--mainTheme) !important",
                    fontWeight: "600",
                    fontSize: "0.85rem",
                  },
                  "&.Mui-disabled ": {
                    color: "black",
                    border: "1px solid var(--mainTheme) !important",
                    fontWeight: "600",
                    fontSize: "0.85rem",
                  },
                  "&.Mui-selected:hover": {
                    backgroundColor: "var(--mainTheme750)",
                    color: "white",
                    border: "1px solid var(--mainTheme) !important",
                  },
                  "&.MuiToggleButtonGroup-grouped": {
                    borderRadius: "5px !important",
                  },
                  ":hover": {
                    backgroundColor: "var(--mainTheme400)",
                    border: "1px solid var(--mainTheme) !important",
                  },
                }}
              >
                {subQuestion.label}
              </ToggleButton>
            );
          })}
        </ToggleButtonGroup>
        {isCommentEnabled &&
          shouldShowIcon &&
          isCommentVisibleMemo(getFieldValues, name) && (
            <DFCommentTextBoxMemo
              label={label}
              handleTextChange={handleTextChange}
              name={name}
              value={commentValue}
              disableFlag={disableFlag}
            />
          )}
        {selectedValue &&
          inputProps?.subQuestions?.map((subQuestion) => {
            const isValueSelected = selectedValue?.includes(subQuestion.value);
            const isLinkedFieldSelected =
              getFieldVisibility !== undefined
                ? getFieldVisibility(name)
                : false;

            const canCallRecursive =
              subQuestion.hasSubQuestions === true &&
              (isLinkedFieldSelected || isValueSelected);
            if (canCallRecursive) {
              const newQuesSlug = `${quesIdSlug}_${subQuestion.subQuestionId}`;
              const NestedTreeMemoValues = getNestingValues(name);
              return (
                <NestedTreeMemo
                  key={newQuesSlug}
                  depth={depth + 1}
                  quesIdSlug={newQuesSlug}
                  quesNameSlug={`${quesNameSlug}_${
                    subQuestion.name || subQuestion.value || ""
                  }`}
                  sectionId={sectionId}
                  subQuestions={subQuestion.subQuestions}
                  formData={NestedTreeMemoValues}
                  handleOptionsTreeChange={handleOptionsTreeChange}
                  handleTextChange={handleTextChange}
                  handleRadioChange={handleRadioChange}
                  handleDateChange={handleDateChange}
                  handleSelectChange={handleSelectChange}
                  handleButtonClick={handleButtonClick}
                  handleCheckboxChange={handleCheckboxChange}
                  getFieldValues={getFieldValues}
                  setFieldValues={setFieldValues}
                  selectedValue={selectedValue}
                  disableFlag={disableFlag}
                  getNestingValues={getNestingValues}
                />
              );
            }
            return null;
          })}
      </Grid>
    </Grid>
  );
};

ToggleTreeMemo.propTypes = {
  depth: PropTypes.number,
  sectionId: PropTypes.number,
  quesIdSlug: PropTypes.string,
  quesNameSlug: PropTypes.string,
  inputProps: PropTypes.object,
  formData: PropTypes.object,
  familyRowData: PropTypes.object,
  setFamilyRowData: PropTypes.func,
  medicalRowData: PropTypes.object,
  setMedicalRowData: PropTypes.func,
  allergiesRowData: PropTypes.object,
  setAllergiesRowData: PropTypes.func,
  medicationsRowData: PropTypes.object,
  setMedicationsRowData: PropTypes.func,
  counterMedicationsRowData: PropTypes.object,
  setCounterMedicationsRowData: PropTypes.func,
  tableName: PropTypes.string,
  data: PropTypes.object,
  handleOptionsTreeChange: PropTypes.func,
  handleTextChange: PropTypes.func,
  handleRadioChange: PropTypes.func,
  handleDateChange: PropTypes.func,
  handleSelectChange: PropTypes.func,
  handleButtonClick: PropTypes.func,
  handleCheckboxChange: PropTypes.func,
  getFieldValues: PropTypes.func,
  setFieldValues: PropTypes.func,
  getNestingValues: PropTypes.func,
  getFieldVisibility: PropTypes.func,
  disableFlag: PropTypes.bool,
};

export const NestedTreeMemo = ({
  depth,
  quesIdSlug,
  quesNameSlug,
  sectionId,
  subQuestions,
  formData,
  handleOptionsTreeChange,
  handleTextChange,
  handleRadioChange,
  handleDateChange,
  handleSelectChange,
  handleButtonClick,
  handleCheckboxChange,
  getFieldValues,
  setFieldValues,
  getNestingValues,
  disableFlag,
}) => {
  // const { getStateValue, setStateValue } = useFillHraContext();

  return (
    <div style={{ marginLeft: "15px" }}>
      <Grid container gap={1} style={{ marginLeft: "0px" }}>
        {subQuestions?.map((subQuestion) => {
          const { subQuestionId, name, xs, type, isSingleButton } = subQuestion;
          const inputValue = formData?.[name] || "";
          const commentValue = formData?.[name + "Comment"]
            ? formData[name + "Comment"]
            : "";
          const quesIdSlugVal = `${quesIdSlug}_${subQuestionId}`;
          const quesNameSlugVal = `${quesNameSlug}_${
            subQuestion.name || subQuestion.value || ""
          }`;
          if (
            type === "text" ||
            type === "number" ||
            type === "email" ||
            type === "password"
          ) {
            return (
              <Grid key={quesIdSlugVal} xs={xs} item>
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "start",
                    }}
                  >
                    {depth > 2 || name == "ros_neuroPsychSubDiag" ? (
                      <MdOutlineSubdirectoryArrowRight
                        style={{
                          color: "var(--mainTheme)",
                          marginRight: "10px",
                          height: "25px",
                          width: "25px",
                        }}
                      />
                    ) : (
                      <div style={{ margin: "0px !important" }}></div>
                    )}
                  </div>
                  <div style={{ width: "90%" }}>
                    <DFTextMemo
                      inputProps={subQuestion}
                      value={inputValue}
                      commentValue={commentValue}
                      handleTextChange={handleTextChange}
                      getFieldValues={getFieldValues}
                      setFieldValues={setFieldValues}
                      disableFlag={disableFlag}
                    />
                  </div>
                </div>
              </Grid>
            );
          } else if (type === "radio") {
            return (
              <Grid key={quesIdSlugVal} xs={xs} item>
                <DFRadioMemo
                  inputProps={subQuestion}
                  value={inputValue}
                  commentValue={commentValue}
                  handleRadioChange={handleRadioChange}
                  handleTextChange={handleTextChange}
                  getFieldValues={getFieldValues}
                  setFieldValues={setFieldValues}
                  disableFlag={disableFlag}
                />
              </Grid>
            );
          } else if (type === "date") {
            const dateValue = formData[name] || {};
            // getFieldValues(name) === "" ? {} : getFieldValues(name);
            return (
              <Grid key={quesIdSlugVal} xs={xs} item>
                <DFDateMemo
                  inputProps={subQuestion}
                  value={dateValue}
                  commentValue={commentValue}
                  handleDateChange={handleDateChange}
                  handleTextChange={handleTextChange}
                  getFieldValues={getFieldValues}
                  setFieldValues={setFieldValues}
                  disableFlag={disableFlag}
                />
              </Grid>
            );
          } else if (type === "select") {
            return (
              <Grid key={quesIdSlugVal} xs={xs} item>
                <DFSelectMemo
                  inputProps={subQuestion}
                  value={inputValue}
                  commentValue={commentValue}
                  handleSelectChange={handleSelectChange}
                  handleTextChange={handleTextChange}
                  getFieldValues={getFieldValues}
                  setFieldValues={setFieldValues}
                  disableFlag={disableFlag}
                />
              </Grid>
            );
          } else if (type === "options_tree") {
            return (
              <ToggleTreeMemo
                key={quesIdSlugVal}
                inputProps={subQuestion}
                formData={formData}
                handleOptionsTreeChange={handleOptionsTreeChange}
                handleTextChange={handleTextChange}
                handleRadioChange={handleRadioChange}
                handleDateChange={handleDateChange}
                handleSelectChange={handleSelectChange}
                handleButtonClick={handleButtonClick}
                handleCheckboxChange={handleCheckboxChange}
                // showComment={showComment}
                // setShowComment={setShowComment}
                // setFormData={setFormData}
                setFieldValues={setFieldValues}
                getFieldValues={getFieldValues}
                commentValue={commentValue}
                disableFlag={disableFlag}
                selectedValue={inputValue}
                depth={depth + 1}
                quesIdSlug={quesIdSlugVal}
                sectionId={sectionId}
                quesNameSlug={quesNameSlugVal}
                getNestingValues={getNestingValues}
              />
            );
          } else if (type === "label") {
            return (
              <Grid key={quesIdSlugVal} xs={xs} item>
                <DFLabelMemo
                  inputProps={subQuestion}
                  commentValue={commentValue}
                  handleTextChange={handleTextChange}
                  getFieldValues={getFieldValues}
                  setFieldValues={setFieldValues}
                  disableFlag={disableFlag}
                />
              </Grid>
            );
          } else if (type === "button") {
            return (
              <Grid key={quesIdSlugVal} xs={isSingleButton ? 12 : 0} item>
                <DFButton
                  inputProps={subQuestion}
                  handleButtonClick={handleButtonClick}
                />
              </Grid>
            );
          } else if (type === "heading") {
            return (
              <Grid key={quesIdSlugVal} xs={12} sm={12} item>
                <DFHeadingMemo inputProps={subQuestion} />
              </Grid>
            );
          } else if (type === "checkbox") {
            return (
              <Grid key={quesIdSlugVal} xs={xs} item>
                <DFCheckboxMemo
                  inputProps={subQuestion}
                  value={inputValue}
                  commentValue={commentValue}
                  quesSlug={quesIdSlugVal}
                  sectionId={sectionId}
                  handleCheckboxChange={handleCheckboxChange}
                  handleTextChange={handleTextChange}
                  getFieldValues={getFieldValues}
                  setFieldValues={setFieldValues}
                  disableFlag={disableFlag}
                />
              </Grid>
            );
          } else if (type === "image") {
            return (
              <Grid key={quesIdSlugVal} xs={xs} item>
                <DFImage inputProps={subQuestion} />
              </Grid>
            );
          }

          return null;
        })}
      </Grid>
    </div>
  );
};

NestedTreeMemo.propTypes = {
  depth: PropTypes.number,
  quesIdSlug: PropTypes.string,
  quesNameSlug: PropTypes.string,
  sectionId: PropTypes.number,
  subQuestions: PropTypes.array,
  formData: PropTypes.object,
  handleOptionsTreeChange: PropTypes.func,
  handleTextChange: PropTypes.func,
  handleRadioChange: PropTypes.func,
  handleDateChange: PropTypes.func,
  handleSelectChange: PropTypes.func,
  handleButtonClick: PropTypes.func,
  handleCheckboxChange: PropTypes.func,
  getFieldValues: PropTypes.func,
  setFieldValues: PropTypes.func,
  disableFlag: PropTypes.bool,
};

export const DFOptionsTreeMemo = React.memo(
  ({
    inputProps,
    formData,
    quesSlug,
    sectionId,
    handleOptionsTreeChange,
    handleTextChange,
    handleRadioChange,
    handleDateChange,
    handleSelectChange,
    handleButtonClick,
    handleCheckboxChange,
    getFieldValues,
    setFieldValues,
    getFieldVisibility,
    getNestingValues,
    disableFlag,
    familyRowData,
    setFamilyRowData,
    medicalRowData,
    setMedicalRowData,
    allergiesRowData,
    setAllergiesRowData,
    medicationsRowData,
    setMedicationsRowData,
    counterMedicationsRowData,
    setCounterMedicationsRowData,
    tableName,
    data,
  }) => {
    const { name, xs } = inputProps;
    // const { getStateValue, setStateValue } = useFillHraContext();

    return (
      <Grid
        item
        key={quesSlug}
        xs={xs || 12}
        style={{ marginLeft: "0px" }}
        zeroMinWidth
      >
        <ToggleTreeMemo
          depth={1}
          sectionId={sectionId}
          quesIdSlug={`${quesSlug}`}
          quesNameSlug={`${name}`}
          inputProps={inputProps}
          formData={formData}
          familyRowData={familyRowData}
          setFamilyRowData={setFamilyRowData}
          medicalRowData={medicalRowData}
          setMedicalRowData={setMedicalRowData}
          allergiesRowData={allergiesRowData}
          setAllergiesRowData={setAllergiesRowData}
          medicationsRowData={medicationsRowData}
          setMedicationsRowData={setMedicationsRowData}
          counterMedicationsRowData={counterMedicationsRowData}
          setCounterMedicationsRowData={setCounterMedicationsRowData}
          tableName={tableName}
          data={data}
          handleOptionsTreeChange={handleOptionsTreeChange}
          handleTextChange={handleTextChange}
          handleRadioChange={handleRadioChange}
          handleDateChange={handleDateChange}
          handleSelectChange={handleSelectChange}
          handleButtonClick={handleButtonClick}
          handleCheckboxChange={handleCheckboxChange}
          getFieldValues={getFieldValues}
          setFieldValues={setFieldValues}
          getNestingValues={getNestingValues}
          getFieldVisibility={getFieldVisibility}
          disableFlag={disableFlag}
        />
      </Grid>
    );
  },
  dontReRender,
);

DFOptionsTreeMemo.propTypes = {
  inputProps: PropTypes.object.isRequired,
  formData: PropTypes.object.isRequired,
  quesSlug: PropTypes.string.isRequired,
  sectionId: PropTypes.number.isRequired,
  handleOptionsTreeChange: PropTypes.func.isRequired,
  handleTextChange: PropTypes.func.isRequired,
  handleRadioChange: PropTypes.func.isRequired,
  handleDateChange: PropTypes.func.isRequired,
  handleSelectChange: PropTypes.func.isRequired,
  handleButtonClick: PropTypes.func.isRequired,
  handleCheckboxChange: PropTypes.func.isRequired,
  getFieldValues: PropTypes.func.isRequired,
  setFieldValues: PropTypes.func.isRequired,
  getFieldVisibility: PropTypes.func.isRequired,
  getNestingValues: PropTypes.func.isRequired,
  disableFlag: PropTypes.bool,
  familyRowData: PropTypes.object,
  setFamilyRowData: PropTypes.func,
  medicalRowData: PropTypes.object,
  setMedicalRowData: PropTypes.func,
  allergiesRowData: PropTypes.object,
  setAllergiesRowData: PropTypes.func,
  medicationsRowData: PropTypes.object,
  setMedicationsRowData: PropTypes.func,
  counterMedicationsRowData: PropTypes.object,
  setCounterMedicationsRowData: PropTypes.func,
  tableName: PropTypes.string,
  data: PropTypes.object,
};
