import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";

import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const MuiInput = (props) => {
  const [, setDropdownValue] = React.useState("");

  const handleChange = (event) => {
    const { name, value } = event.target;

    let selectedValues = [];

    if (props.isMulti) {
      selectedValues = Array.isArray(value) ? value : [value];
    } else {
      selectedValues = [value];
    }

    setDropdownValue(selectedValues);

    if (typeof props.onChange === "function") {
      props.onChange({ target: { name, value: selectedValues } });
    }
  };

  let input = "";
  let currentYear = new Date().getFullYear().toString();
  const dropdownComp = (
    <Box
      sx={{
        "& > :not(style)": {
          m: 1,
          width: props.width ? props.width : "100%",
          padding: "0px",
        },
      }}
      className={props.className}
    >
      <FormControl>
        {/* <InputLabel>{props.label}</InputLabel> */}
        <InputLabel
          sx={{
            backgroundColor: props.labelBackgroundColor || "transparent",
            // padding: '8px',
          }}
        >
          {props.label}
        </InputLabel>
        <Select
          multiple={props.isMulti} // Enable multi-select based on the isMulti prop
          value={props.isMulti ? props.value || [] : props?.value} // Ensure it's an array for multi-select
          onChange={handleChange}
          label={props.label}
          error={props.error}
          name={props.name}
          helperText={props.helperText}
          disabled={props.disabled}
        >
          {/* {props.options &&
            props.options.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))} */}
          <MenuItem value="" disabled>
            {props?.title}
            {props?.label}
          </MenuItem>

          {props?.options?.map((opt, id) => {
            const isOptionDisabled =
              props.disabledOptions &&
              props.value !== opt &&
              opt !== currentYear;

            return (
              <MenuItem
                key={id}
                value={opt}
                disabled={isOptionDisabled}
                sx={{ color: isOptionDisabled ? "black" : "none" }}
              >
                {opt}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      {/* <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={props.label}
            label={props.label}
            onChange={handleChange}
        >
            {props.options && props.options.map((option)=> {
                return <MenuItem value={option}>{option}</MenuItem>
            })}
        </Select> */}
    </Box>
  );

  const dateComp = (
    <Box
      sx={{
        "& > :not(style)": {
          m: 1,
          width: props.width ? props.width : "100%",
          padding: "0px",
        },
      }}
      className={props.className}
    >
      <TextField
        id="date"
        label={props.label}
        type="date"
        value={props.value}
        disabled={props.disabled}
        name={props.name}
        defaultValue=""
        onChange={handleChange}
        style={props.style}
        inputProps={{
          min: "2023-01-01",
          max: "2025-12-31",
        }}
        error={props.error}
        helperText={props.helperText}
        required={props.required}
        InputLabelProps={{
          shrink: true,
          ...props.InputLabelProps,
        }}
      />
    </Box>
  );

  const dateTimeComp = (
    <Box
      sx={{
        "& > :not(style)": {
          m: 1,
          width: props.width ? props.width : "100%",
          padding: "0px",
        },
      }}
      className={props.className}
    >
      <TextField
        id="datetime-local"
        label={props.label}
        type="datetime-local"
        name="datetime"
        disabled={props.disabled}
        value={props.value}
        defaultValue=""
        onChange={props.onChange}
        onBlur={props.onBlur}
        onFocus={props.onFocus}
        style={props.style}
        inputProps={{
          min: "2023-01-01T00:00",
          //max: currentDate,
          max: "2025-12-31T00:00",
        }}
        error={props.error}
        helperText={props.helperText}
        InputLabelProps={{
          shrink: true,
          ...props.InputLabelProps,
        }}
      />
    </Box>
  );

  const fileUploadComp = (
    <Box
      sx={{
        "& > :not(style)": {
          m: 1,
          width: props.width ? props.width : "100%",
          padding: "0px",
        },
      }}
      className={props.className}
    >
      <input type="file" id={props.id} onChange={handleChange} />
    </Box>
  );

  const passwordComp = (
    <Box
      sx={{
        "& > :not(style)": {
          m: 1,
          width: props.width ? props.width : "100%",
          padding: "0px",
        },
      }}
      className={props.className}
    >
      <TextField
        label={props.label}
        type="password"
        value={props.value}
        onChange={handleChange}
        style={props.style}
        error={props.error}
        helperText={props.helperText}
        disabled={props.disabled}
      />
    </Box>
  );

  const inputComp = (
    <Box
      component="form"
      sx={{
        "& > :not(style)": {
          m: 1,
          width: props.width ? props.width : "100%",
          padding: "0px",
        },
      }}
      noValidate
      autoComplete="off"
      className={props.className}
    >
      <TextField
        id="outlined-basic"
        label={props.label}
        variant="outlined"
        error={props.error}
        helperText={props.helperText}
        value={props.value}
        onChange={handleChange}
        name={props.name}
        disabled={props.disabled}
        multiline={props.multiline}
        required={props.required}
        InputProps={props.InputProps}
        style={props.style}
        InputLabelProps={props.InputLabelProps}
      />
    </Box>
  );

  if (props.type === "input") {
    input = inputComp;
  } else if (props.type === "date") {
    input = dateComp;
  } else if (props.type === "select") {
    input = dropdownComp;
  } else if (props.type === "file") {
    input = fileUploadComp;
  } else if (props.type === "datetime-local") {
    input = dateTimeComp;
  } else if (props.type === "password") {
    input = passwordComp; // Handle password input type
  } else {
    input = dropdownComp;
  }

  return <div>{input}</div>;
};

export default MuiInput;
