const pedsAssessmentInfoJsonData = [
  {
    title: "ASSESSMENT INFORMATION",
    isShowInPdf: true,
    ismargin: false,
    questions: [
      {
        id: 1,
        name: "legalGuradian",
        value: "legalguardian",
        type: "options_tree",
        label:
          "1. Does the member or legal guardian give verbal permission to discuss PHI?",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "1",
            name: "Yes",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "2",
            name: "No",
            type: "toggle_button",
          },
        ],
        questionId: 1,
        editToggle: false,
      },
      {
        id: 2,
        name: "legalRepresentative",
        value: "verbalPermission",
        type: "options_tree",
        label:
          "2. Does member or authorized legal representative give verbal permission to share the results of this assessment verbally and/or electronically with members of the healthcare team?",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "1",
            name: "Yes",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "2",
            name: "No",
            type: "toggle_button",
          },
        ],
        questionId: 2,
        editToggle: false,
      },
      {
        id: 3,
        name: "fosterCare",
        value: "child_enrolled_foster",
        type: "options_tree",
        label: "3. Is your child enrolled in foster care program?",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "1",
            name: "Yes",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "2",
            name: "No",
            type: "toggle_button",
          },
        ],
        questionId: 3,
        editToggle: false,
      },
      {
        id: 4,
        questionId: 4,
        name: "preferredLanguage",
        label: "4.Preferred language",
        value: "",
        type: "options_tree",
        xs: 12,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            label: "English",
            type: "toggle_button",
            value: "english",
            hasSubQuestions: false,
            subQuestions: [
              // {
              //   editToggle: false,
              // },
            ],
          },
          {
            depth: 1,
            subQuestionId: 2,
            label: "Other",
            type: "toggle_button",
            value: "other",
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 6,
                name: "demograph_prefLangOtherSub",
                type: "options_tree",
                label: "If other,",
                required: true,
                xs: 12,
                isCommentEnabled: true,
                isModified: true,
                hasSubQuestions: true,
                subQuestions: [
                  {
                    depth: 3,
                    subQuestionId: 1,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "African languages",
                    value: "africanLanguages",
                    type: "toggle_button",
                  },
                  {
                    subQuestionId: 2,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "Arabic",
                    value: "arabic",
                    type: "toggle_button",
                    depth: 3,
                  },
                  {
                    subQuestionId: 3,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "Chinese",
                    value: "chinese",
                    type: "toggle_button",
                    depth: 3,
                  },
                  {
                    subQuestionId: 4,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "French",
                    value: "french",
                    type: "toggle_button",
                    depth: 3,
                  },
                  {
                    subQuestionId: 5,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "French Creole",
                    value: "frenchCreole",
                    type: "toggle_button",
                    depth: 3,
                  },
                  {
                    subQuestionId: 6,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "German",
                    value: "german",
                    type: "toggle_button",
                    depth: 3,
                  },
                  {
                    subQuestionId: 7,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "Greek",
                    value: "greek",
                    type: "toggle_button",
                    depth: 3,
                  },
                  {
                    subQuestionId: 8,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "Gujarati",
                    value: "gujarati",
                    type: "toggle_button",
                    depth: 3,
                  },
                  {
                    subQuestionId: 9,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "Hebrew",
                    value: "hebrew",
                    type: "toggle_button",
                    depth: 3,
                  },
                  {
                    subQuestionId: 10,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "Hindi",
                    value: "hindi",
                    type: "toggle_button",
                    depth: 3,
                  },
                  {
                    subQuestionId: 11,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "Hungarian",
                    value: "hungarian",
                    type: "toggle_button",
                    depth: 3,
                  },
                  {
                    subQuestionId: 12,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "Italian",
                    value: "italian",
                    type: "toggle_button",
                    depth: 3,
                  },
                  {
                    subQuestionId: 13,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "Japanese",
                    value: "japanese",
                    type: "toggle_button",
                    depth: 3,
                  },
                  {
                    subQuestionId: 14,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "Korean",
                    value: "korean",
                    type: "toggle_button",
                    depth: 3,
                  },
                  {
                    subQuestionId: 15,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "Persian",
                    value: "persian",
                    type: "toggle_button",
                    depth: 3,
                  },
                  {
                    subQuestionId: 16,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "Polish",
                    value: "polish",
                    type: "toggle_button",
                    depth: 3,
                  },
                  {
                    subQuestionId: 17,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "Portuguese",
                    value: "portuguese",
                    type: "toggle_button",
                    depth: 3,
                  },
                  {
                    subQuestionId: 18,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "Russian",
                    value: "russian",
                    type: "toggle_button",
                    depth: 3,
                  },
                  {
                    subQuestionId: 19,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "Scandinavian Languages",
                    value: "scandinavianLanguages",
                    type: "toggle_button",
                    depth: 3,
                  },
                  {
                    subQuestionId: 20,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "Serbo-Croatian",
                    value: "serboCroatian",
                    type: "toggle_button",
                    depth: 3,
                  },
                  {
                    subQuestionId: 21,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "Spanish",
                    value: "spanish",
                    type: "toggle_button",
                    depth: 3,
                  },
                  // {
                  //   subQuestionId: 22,
                  //   hasSubQuestions: false,
                  //   subQuestions: [],
                  //   editToggle: false,
                  //   isCommentEnabled: false,
                  //   label: "Tagalog",
                  //   value: "tagalog",
                  //   type: "toggle_button",
                  //   depth: 3,
                  // },
                  // {
                  //   subQuestionId: 23,
                  //   hasSubQuestions: false,
                  //   subQuestions: [],
                  //   editToggle: false,
                  //   isCommentEnabled: false,
                  //   label: "Urdu",
                  //   value: "urdu",
                  //   type: "toggle_button",
                  //   depth: 3,
                  // },
                  // {
                  //   subQuestionId: 24,
                  //   hasSubQuestions: false,
                  //   subQuestions: [],
                  //   editToggle: false,
                  //   isCommentEnabled: false,
                  //   label: "Vietnamese",
                  //   value: "vietnamese",
                  //   type: "toggle_button",
                  //   depth: 3,
                  // },
                  // {
                  //   subQuestionId: 25,
                  //   hasSubQuestions: false,
                  //   subQuestions: [],
                  //   editToggle: false,
                  //   isCommentEnabled: false,
                  //   label: "Yiddish",
                  //   value: "yiddish",
                  //   type: "toggle_button",
                  //   depth: 3,
                  // },
                ],
                subQuestionId: 1,
                editToggle: false,
              },
            ],
          },
        ],
        isCommentEnabled: true,
        required: true,
        editToggle: false,
        isModified: false,
        depth: 1,
      },
      {
        id: 29,
        name: "patientReligiousBelief",
        value: "patientReligiousBelief",
        type: "options_tree",
        label:
          "5. Are there any cultural, religious, or spiritual beliefs that your healthcare providers need to be aware of to provide appropriate care?",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 1,
                name: "spiritual_beliefs", //"Is this because of ANY medical, behavioral, or health condition?",
                type: "text",
                label: "Comments",
                editToggle: false,
                xs: 4,
                required: "no",
                placeholder: "",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 1,
                depth: 7,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "1",
            name: "Yes",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "2",
            name: "No",
            type: "toggle_button",
          },
        ],
        questionId: 29,
        editToggle: false,
      },
      {
        id: 5,
        questionId: 5,
        label: "6. Race",
        value: "",
        type: "options_tree",
        xs: 12,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 2,
            label: "African American",
            type: "toggle_button",
            value: "africanAmerican",
            hasSubQuestions: false,
            subQuestions: [
              // {
              //   editToggle: false,
              // },
            ],
          },
          {
            depth: 1,
            subQuestionId: 7,
            label: "Alaskan Native",
            type: "toggle_button",
            value: "alaskanNative",
            hasSubQuestions: false,
            subQuestions: [
              // {
              //   editToggle: false,
              // },
            ],
          },
          {
            depth: 1,
            subQuestionId: 3,
            label: "Asian",
            type: "toggle_button",
            value: "asian",
            hasSubQuestions: false,
            subQuestions: [
              // {
              //   editToggle: false,
              // },
            ],
          },
          {
            depth: 1,
            subQuestionId: 1,
            label: "Caucasian",
            type: "toggle_button",
            value: "caucasian",
            hasSubQuestions: false,
            subQuestions: [
              // {
              //   editToggle: false,
              // },
            ],
          },

          {
            depth: 1,
            subQuestionId: 4,
            label: "Latino",
            type: "toggle_button",
            value: "latino",
            hasSubQuestions: false,
            subQuestions: [
              // {
              //   editToggle: false,
              // },
            ],
          },
          {
            depth: 1,
            subQuestionId: 5,
            label: "Native American",
            type: "toggle_button",
            value: "nativeAmerican",
            hasSubQuestions: false,
            subQuestions: [
              // {
              //   editToggle: false,
              // },
            ],
          },
          {
            depth: 1,
            subQuestionId: 6,
            label: "Native Hawaiian or other Pacific Islander",
            type: "toggle_button",
            value: "nativeHawaiian",
            hasSubQuestions: false,
            subQuestions: [
              // {
              //   editToggle: false,
              // },
            ],
          },

          // {
          //   depth: 1,
          //   subQuestionId: 8,
          //   label: "Other",
          //   type: "toggle_button",
          //   value: "other",
          //   hasSubQuestions: true,
          //   subQuestions: [
          //     {
          //       subQuestionId: 1,
          //       name: "demograph_raceOtherSubDesc",
          //       type: "text",
          //       label: "Describe",
          //       required: true,
          //       xs: 12,
          //       isCommentEnabled: false,
          //     },
          //   ],
          // },
        ],
        isCommentEnabled: true,
        required: true,
        name: "race",
        isModified: false,
        depth: 1,
      },
      //questionNo 6
      {
        id: 6,
        name: "allergies",
        type: "options_tree",
        label: "7. Does your child have Allergies",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 11,
                name: "allergiesDatatable",
                label: "",
                editToggle: false,
                type: "datatable",
                tablelist: [
                  [
                    {
                      id: 1,
                      label: "Allergies",
                      name: "HRAForm_Allergies",
                      columns: [
                        {
                          label: "Substance",
                          type: "text",
                        },
                        {
                          label: "Reaction",
                          type: "text",
                        },
                      ],
                      isReadOnly: false,
                    },
                    true,
                  ],
                  [
                    {
                      id: 2,
                      label: "BMI Index Chart",
                      name: "HRAForm_BMI_Index_Chart",
                      columns: [
                        {
                          label: "BMI",
                          type: "text",
                        },
                      ],
                      isReadOnly: true,
                    },
                    false,
                  ],
                  [
                    {
                      id: 3,
                      label: "Counter Medications",
                      name: "HRAForm_Counter_Medications",
                      columns: [
                        {
                          label: "Date",
                          type: "date",
                        },
                        {
                          label: "Description",
                          type: "text",
                        },
                        {
                          label: "Dose Per Units",
                          type: "text",
                        },
                        {
                          label: "Route",
                          type: "select",
                        },
                        {
                          label: "Frequency",
                          type: "text",
                        },
                      ],
                      isReadOnly: false,
                    },
                    false,
                  ],
                  [
                    {
                      id: 4,
                      label: "Medical History Specialists",
                      name: "HRAForm_Medical_History_Speciality",
                      columns: [
                        {
                          label: "Member Specialist",
                          type: "select",
                        },
                        {
                          label: "Speciality",
                          type: "text",
                        },
                        {
                          label: "For",
                          type: "text",
                        },
                      ],
                      isReadOnly: false,
                    },
                    false,
                  ],
                  [
                    {
                      id: 5,
                      label: "Medications",
                      name: "HRAForm_Medications",
                      columns: [
                        {
                          label: "Diagnoses",
                          type: "text",
                        },
                        {
                          label: "label Name",
                          type: "select",
                        },
                        {
                          label: "Dose Per Units",
                          type: "text",
                        },
                        {
                          label: "Route",
                          type: "select",
                        },
                        {
                          label: "Frequency",
                          type: "select",
                        },
                        {
                          label: "Prescribing Physician",
                          type: "text",
                        },
                        {
                          label: "Status",
                          type: "togglebutton",
                        },
                      ],
                      isReadOnly: false,
                    },
                    false,
                  ],
                  [
                    {
                      id: 6,
                      label: "Mini Cog Versions",
                      name: "HRAForm_Minicog_Version",
                      columns: [
                        {
                          label: "Version 1",
                          type: "text",
                        },
                        {
                          label: "Version 2",
                          type: "text",
                        },
                        {
                          label: "Version 3",
                          type: "text",
                        },
                        {
                          label: "Version 4",
                          type: "text",
                        },
                        {
                          label: "Version 5",
                          type: "text",
                        },
                        {
                          label: "Version 6",
                          type: "text",
                        },
                      ],
                      isReadOnly: true,
                    },
                    false,
                  ],
                  [
                    {
                      id: 7,
                      label: "Preventive Care",
                      name: "HRAForm_Preventive_Care",
                      columns: [
                        {
                          label: "Screen",
                          type: "text",
                        },
                        {
                          label: "Answer",
                          type: "select",
                        },
                        {
                          label: "Date",
                          type: "text",
                        },
                        {
                          label: "Method",
                          type: "text",
                        },
                        {
                          label: "Recommendation",
                          type: "checkbox",
                        },
                        {
                          label: "Education Completed",
                          type: "checkbox",
                        },
                      ],
                      isReadOnly: true,
                    },
                    false,
                  ],
                  [
                    {
                      id: 8,
                      label: "Previously Documented Conditions",
                      name: "HRAForm_Previously_Documented_Conditions",
                      columns: [
                        {
                          label: "Diagnosis",
                          type: "text",
                        },
                        {
                          label: "Prior HCC",
                          type: "text",
                        },
                        {
                          label: "Diagnosis Code",
                          type: "text",
                        },
                        {
                          label: "Date of Service",
                          type: "date",
                        },
                      ],
                      isReadOnly: false,
                    },
                    false,
                  ],
                  [
                    {
                      id: 9,
                      label: "Prior Assessments",
                      name: "HRAForm_Prior_Assessments",
                      columns: [
                        {
                          label: "Date Time",
                          type: "date",
                        },
                        {
                          label: "Document",
                          type: "button",
                        },
                      ],
                      isReadOnly: true,
                    },
                    false,
                  ],
                  [
                    {
                      id: 10,
                      label: "Stages Of Kidney Disease",
                      name: "HRAForm_Stage_Of_Kidney_Disease",
                      columns: [
                        {
                          label: "Stages",
                          type: "text",
                        },
                      ],
                      isReadOnly: true,
                    },
                    false,
                  ],
                  [
                    {
                      id: 11,
                      label: "Alcohol Usage",
                      name: "HRAForm_Alcohol_Usage",
                      columns: [
                        {
                          label: "How many drinks",
                          type: "select",
                        },
                        {
                          label: "How often",
                          type: "select",
                        },
                      ],
                      isReadOnly: true,
                    },
                    false,
                  ],
                  [
                    {
                      id: 12,
                      name: "Family History",
                      columns: [
                        {
                          label: "Family Member",
                          type: "select",
                        },
                        {
                          label: "Medical Condition",
                          type: "text",
                        },
                        {
                          label: "Cause of Death",
                          type: "text",
                        },
                      ],
                      isReadOnly: false,
                    },
                    false,
                  ],
                ],
                xs: 12,
                required: "no",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 1,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes ",
            value: "1",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No ",
            value: "2",
            type: "toggle_button",
          },
        ],
        questionId: 6,
        editToggle: false,
      },
      {
        id: 7,
        name: "childExperienced",
        value: "witnessed_an_event",
        type: "options_tree",
        label:
          "8. Has the child experienced or witnessed an event that caused, or threatened to cause, serious harm to him or herself or to someone else?",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 1,
                name: "Whatwastheevent",
                value: "Whatwastheevent",
                commentValueHolder: "Whatwastheevent",
                type: "options_tree", //"label",
                subQuestions: [
                  {
                    depth: 1,
                    subQuestionId: 2,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Car or Other Accident",
                    value: "carAccident",
                    name: "Car or Other Accident",
                    type: "toggle_button",
                  },
                  {
                    depth: 1,
                    subQuestionId: 3,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Fire",
                    value: "fire",
                    name: "Fire",
                    type: "toggle_button",
                  },
                  {
                    depth: 1,
                    subQuestionId: 4,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Storm",
                    value: "storm",
                    name: "Storm",
                    type: "toggle_button",
                  },
                  {
                    depth: 1,
                    subQuestionId: 5,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Physical Illness or Assault",
                    value: "physicalIllness",
                    name: "Physical Illness or Assault",
                    type: "toggle_button",
                  },
                  {
                    depth: 1,
                    subQuestionId: 6,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Sexual Assault",
                    value: "sexualAssualt",
                    name: "Sexual Assault",
                    type: "toggle_button",
                  },
                ],
                label: "What was the event",
                editToggle: false,
                xs: 12,
                required: "no",
                placeholder: "",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: false,
                questionId: 1,
              },
              {
                id: 1,
                name: "describe",
                type: "text",
                label: "Describe",
                editToggle: false,
                xs: 4,
                required: "no",
                placeholder: "",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 1,
                depth: 7,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "1",
            name: "Yes",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "2",
            name: "No",
            type: "toggle_button",
          },
        ],
        questionId: 7,
        editToggle: false,
      },

      //question no 8

      {
        id: 8,
        questionId: 8,
        name: "memberAgeEvent",
        placeholder: "Specify Age: ",
        type: "text",
        label: "9. What age was the member when this event occurred?",
        value: "",
        editToggle: false,
        xs: 12,
        required: false,
        defaultValue: "",
        isCommentEnabled: false,
        isConfigured: true,
        isModified: true,
        subQuestionId: 8,
        hasSubQuestions: false,
      },

      //question no 9
      {
        id: 9,
        name: "childReportComplaints",
        value: "physical_complaints",
        type: "options_tree",
        label:
          "10. Child reports more physical complaints such as headaches, stomach aches, nausea when reminded of the event.",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Not True (as far as you know)",
            value: "notTrue",
            name: "Not true",
            isConfigured: true,
            type: "toggle_button",
          },
          //     "Somewhat or Sometimes True",
          //     "Very True",
          //     "Often True",
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Somewhat or Sometimes True",
            value: "somewhatTrue",
            name: "Somewhat true",
            type: "toggle_button",
          },
          {
            subQuestionId: 3,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Very True",
            value: "veryTrue",
            name: "Very true",
            type: "toggle_button",
          },
          {
            subQuestionId: 4,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Often True",
            value: "oftenTrue",
            name: "Often True",
            type: "toggle_button",
          },
        ],
        questionId: 9,
        editToggle: false,
      },

      //question no 10
      {
        id: 10,
        name: "childAvoidsThings",
        value: "Child_avoids_doing",
        type: "options_tree",
        label: "11. Child avoids doing things that remind him/her of the event",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Not True (as far as you know)",
            value: "notTrue",
            name: "Not true",
            isConfigured: true,
            type: "toggle_button",
          },
          //     "Somewhat or Sometimes True",
          //     "Very True",
          //     "Often True",
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Somewhat or Sometimes True",
            value: "somewhatTrue",
            name: "Somewhat true",
            type: "toggle_button",
          },
          {
            subQuestionId: 3,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Very True",
            value: "veryTrue",
            name: "Very true",
            type: "toggle_button",
          },
          {
            subQuestionId: 4,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Often True",
            value: "oftenTrue",
            name: "Often True",
            type: "toggle_button",
          },
        ],
        questionId: 10,
        editToggle: false,
      },
      //question 11
      // {
      //   question:
      //     "11. Child startles easily (jumps when hears sudden loud noises)",
      //   summaryRef: "1_2",
      //   hideshowRef: "7-5",
      //   type: "pedsLonesingleselect",
      //   options: [
      //     "Not True (as far as you know)",
      //     "Somewhat or Sometimes True",
      //     "Very True",
      //     "Often True",
      //   ],
      //   score: [
      //     {
      //       score: 3,
      //       weight: 3,
      //     },
      //     {
      //       score: 3,
      //       weight: 3,
      //     },
      //     {
      //       score: 2,
      //       weight: 3,
      //     },
      //     {
      //       score: 1,
      //       weight: 3,
      //     },
      //     {
      //       score: 0,
      //       weight: 3,
      //     },
      //     {
      //       score: 0,
      //       weight: 3,
      //     },
      //     {
      //       score: 0,
      //       weight: 3,
      //     },
      //   ],
      //   subQuestion: [
      //     [
      //       {
      //         question: "",
      //       },
      //     ],
      //     [
      //       {
      //         question: "",
      //       },
      //     ],
      //     [
      //       {
      //         question: "",
      //       },
      //     ],
      //     [
      //       {
      //         question: "",
      //       },
      //     ],
      //     [
      //       {
      //         question: "",
      //       },
      //     ],
      //     [
      //       {
      //         question: "",
      //       },
      //     ],
      //     [
      //       {
      //         question: "",
      //       },
      //     ],
      //   ],
      // },

      {
        id: 11,
        name: "childStartlesEasily",
        value: "Child_noise_doing",
        type: "options_tree",
        label:
          "12. Child startles easily (jumps when hears sudden loud noises)",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Not True (as far as you know)",
            value: "notTrue",
            name: "Not true",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Somewhat or Sometimes True",
            value: "somewhatTrue",
            name: "Somewhat true",
            type: "toggle_button",
          },
          {
            subQuestionId: 3,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Very True",
            value: "veryTrue",
            name: "Very true",
            type: "toggle_button",
          },
          {
            subQuestionId: 4,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Often True",
            value: "oftenTrue",
            name: "Often True",
            type: "toggle_button",
          },
        ],
        questionId: 11,
        editToggle: false,
      },

      //question number 12
      // {
      //   question: "12. Child gets upset if reminded of event.",
      //   summaryRef: "1_2",
      //   hideshowRef: "7-6",
      //   type: "pedsLonesingleselect",
      //   options: [
      //     "Not True (as far as you know)",
      //     "Somewhat or Sometimes True",
      //     "Very True",
      //     "Often True",
      //   ],
      //   score: [
      //     {
      //       score: 3,
      //       weight: 3,
      //     },
      //     {
      //       score: 3,
      //       weight: 3,
      //     },
      //     {
      //       score: 2,
      //       weight: 3,
      //     },
      //     {
      //       score: 1,
      //       weight: 3,
      //     },
      //     {
      //       score: 0,
      //       weight: 3,
      //     },
      //     {
      //       score: 0,
      //       weight: 3,
      //     },
      //     {
      //       score: 0,
      //       weight: 3,
      //     },
      //   ],
      //   subQuestion: [
      //     [
      //       {
      //         question: "",
      //       },
      //     ],
      //     [
      //       {
      //         question: "",
      //       },
      //     ],
      //     [
      //       {
      //         question: "",
      //       },
      //     ],
      //     [
      //       {
      //         question: "",
      //       },
      //     ],
      //     [
      //       {
      //         question: "",
      //       },
      //     ],
      //     [
      //       {
      //         question: "",
      //       },
      //     ],
      //     [
      //       {
      //         question: "",
      //       },
      //     ],
      //   ],
      // },
      {
        id: 12,
        name: "childGetsUpset",
        value: "Child_upset",
        type: "options_tree",
        label: "13. Child gets upset if reminded of event.",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Not True (as far as you know)",
            value: "notTrue",
            name: "Not true",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Somewhat or Sometimes True",
            value: "somewhatTrue",
            name: "Somewhat true",
            type: "toggle_button",
          },
          {
            subQuestionId: 3,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Very True",
            value: "veryTrue",
            name: "Very true",
            type: "toggle_button",
          },
          {
            subQuestionId: 4,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Often True",
            value: "oftenTrue",
            name: "Often True",
            type: "toggle_button",
          },
        ],
        questionId: 12,
        editToggle: false,
      },
      //question number 13
      // {
      //   question:
      //     "13. Does your child currently need to use medicine prescribed by a doctor (other than vitamins)?",
      //   summaryRef: "1_2",
      //   hideCount: "4",
      //   type: "pedsLonesingleselect",
      //   options: ["Yes", "No"],
      //   commentInfo:
      //     "Is this because of ANY medical, behavioral, or health condition?",
      //   score: [
      //     {
      //       score: 3,
      //       weight: 3,
      //     },
      //     {
      //       score: 3,
      //       weight: 3,
      //     },
      //     {
      //       score: 2,
      //       weight: 3,
      //     },
      //     {
      //       score: 1,
      //       weight: 3,
      //     },
      //     {
      //       score: 0,
      //       weight: 3,
      //     },
      //     {
      //       score: 0,
      //       weight: 3,
      //     },
      //     {
      //       score: 0,
      //       weight: 3,
      //     },
      //   ],
      //   subQuestion: [
      //     [
      //       {
      //         question:
      //           "Is this because of ANY medical, behavioral, or health condition?",
      //         type: "input",
      //       },
      //       {
      //         question:
      //           "Is this a condition that has lasted or is expected to last for at least 12 months?",
      //         type: "radio",
      //         options: ["Yes", "No"],
      //         commentInfo: "Explain :",
      //       },
      //       {
      //         question: "Explain:",
      //         type: "input",
      //       },
      //     ],
      //     [
      //       {
      //         question: "",
      //       },
      //     ],
      //     [
      //       {
      //         question: "",
      //       },
      //     ],
      //     [
      //       {
      //         question: "",
      //       },
      //     ],
      //     [
      //       {
      //         question: "",
      //       },
      //     ],
      //     [
      //       {
      //         question: "",
      //       },
      //     ],
      //     [
      //       {
      //         question: "",
      //       },
      //     ],
      //   ],
      // },

      {
        id: 13,
        name: "childMedicine",
        value: "medicine_prescribed",
        type: "options_tree",
        label:
          "14. Does your child currently need to use medicine prescribed by a doctor (other than vitamins)?",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 1,
                name: "any_medical_behavioral_health_condition", //"Is this because of ANY medical, behavioral, or health condition?",
                type: "text",
                label:
                  "Is this because of ANY medical, behavioral, or health condition?",
                editToggle: false,
                xs: 4,
                required: "no",
                placeholder: "",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 1,
                depth: 7,
              },
              {
                id: 1,
                name: "condition_that_has_lasted",
                value: "condition_that_has_lasted",
                commentValueHolder:
                  "medicine_prescribed_condition_that_has_lasted",
                type: "options_tree", //"label",
                subQuestions: [
                  {
                    depth: 1,
                    subQuestionId: 2,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Yes",
                    value: "1",
                    name: "Yes",
                    type: "toggle_button",
                  },
                  {
                    depth: 1,
                    subQuestionId: 3,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "No",
                    value: "2",
                    name: "No",
                    type: "toggle_button",
                  },
                ],
                label:
                  "Is this a condition that has lasted or is expected to last for at least 12 months?",
                editToggle: false,
                xs: 12,
                required: "no",
                placeholder: "",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: false,
                questionId: 1,
              },
              {
                id: 1,
                name: "Explain",
                type: "text",
                label: "Explain:",
                editToggle: false,
                xs: 4,
                required: "no",
                placeholder: "",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 1,
                depth: 7,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "1",
            name: "Yes",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "2",
            name: "No",
            type: "toggle_button",
          },
        ],
        questionId: 13,
        editToggle: false,
      },

      //question number 14
      {
        question: "15. List Prescription Medication",
        label: "15. List Prescription Medication",
        type: "dataTableMedication",
        name: "medication",
        questionId: 14,
        hideshowRef: "14-3",
        hasScore: "no",
        questionType: "custom",
        subQuestion: true,
        subQuestionType: 10,
        showSubQuestion: true,
        levelOneTitle: "Medications",
        subQuestionOption: "0",
        mainQues: "14. List Prescription Medication",
        subtype: "allergies",
        options: ["Yes", "No"],
        childQuestion: {
          question: "",
          options: [
            "Prescription",
            "NDC",
            "Dose / Units",
            "Route",
            "Quantity Dispensed",
            "Frequency",
            "Status",
            "Reason",
          ],
          heading: ["Frequency", "Prescription Status", "Route"],
          prescriptionStatus: [
            "N = New",
            "O = Ongoing",
            "D = Discontinued",
            "H = Hold",
          ],
          routeOptions: [
            "Select",
            "PO = By Mouth",
            "SQ = Subcutaneous",
            "IM = Intramuscular",
            "INH = Inhalation",
            "IV = Intravenous",
            "N = Nasal",
            "R = Rectal",
            "S = Sublingual",
          ],
          statusOptions: ["Taking", "Not Taking"],
          frequency: [
            "Select",
            "AC",
            "PC",
            "AC & HS",
            "BID",
            "TID",
            "QID",
            "QAM",
            "QD",
            "QOD",
            "QPM",
            "QW",
            "QOW",
            "HS",
          ],
        },
        notes: [
          "Click on - button to delete respective row.",
          "Click on + button to add 5 Prescription maximum.",
        ],
      },

      //question number 15
      {
        question: "16. List Over the Counter Medications / Supplements",
        label: "16. List Over the Counter Medications / Supplements",
        type: "peds-SingleSelectTable_options_tree",
        questionId: 15,
        subtype: "medication",
        isAllergy: false,
        name: "counterMedications",
        options: ["Yes", "No"],
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "1",
            name: "Yes",
            isConfigured: true,
            type: "toggle_button",
            subQuestionType: "data_table",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "2",
            name: "no",
            type: "toggle_button",
          },
        ],
        elementType: [
          "input",
          "input",
          "dropdown",
          "dropdown",
          "dropdown",
          "input",
        ],
        score: [
          {
            score: 0,
            weight: 0,
          },
          {
            score: 0,
            weight: 0,
          },
        ],
        subQuestion: {
          question: "",
          options: [
            "Prescription",
            "Dose/Units",
            "Route",
            "Frequency",
            "Status",
            "Reason",
          ],
          score: [
            {
              score: 0,
              weight: 0,
            },
            {
              score: 0,
              weight: 0,
            },
          ],
          prescriptionStatus: [
            "N = New",
            "O = Ongoing",
            "D = Discontinued",
            "H = Hold",
          ],
          routeOptions: [
            "Select",
            "PO = By Mouth",
            "SQ = Subcutaneous",
            "IM = Intramuscular",
            "INH = Inhalation",
            "IV = Intravenous",
            "N = Nasal",
            "R = Rectal",
            "S = Sublingual",
          ],
          statusOptions: ["Taking", "Not Taking"],
          frequency: [
            "Select",
            "AC",
            "PC",
            "AC & HS",
            "BID",
            "TID",
            "QID",
            "QAM",
            "QD",
            "QOD",
            "QPM",
            "QW",
            "QOW",
            "HS",
          ],
        },
        notes: [
          "Click on - button to delete respective row.",
          "Click on + button to add 5 Prescription maximum.",
        ],
      },
      {
        id: 16,
        name: "childNeedMedicalCare",
        value: "medical_care",
        type: "options_tree",
        label:
          "17. Does your child need or use more medical care, mental health or educations services than is usual for most children of the same age?",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 1,
                name: "any_medical_behavioral_health_condition", //"Is this because of ANY medical, behavioral, or health condition?",
                type: "text",
                label:
                  "Is this because of ANY medical, behavioral, or health condition?",
                editToggle: false,
                xs: 4,
                required: "no",
                placeholder: "",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 1,
                depth: 7,
              },
              {
                id: 1,
                name: "condition_that_has_lasted",
                value: "condition_that_has_lasted",
                commentValueHolder: "medical_care_condition_that_has_lasted",
                label:
                  "Is this a condition that has lasted or is expected to last for at least 12 months?",
                type: "options_tree", //"label",
                subQuestions: [
                  {
                    depth: 1,
                    subQuestionId: 2,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Yes",
                    value: "1",
                    name: "Yes",
                    type: "toggle_button",
                  },
                  {
                    depth: 1,
                    subQuestionId: 3,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "No",
                    value: "2",
                    name: "No",
                    type: "toggle_button",
                  },
                ],
                editToggle: false,
                xs: 12,
                required: "no",
                placeholder: "",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: false,
                questionId: 1,
              },
              {
                id: 1,
                name: "Explain",
                type: "text",
                label: "Explain:",
                editToggle: false,
                xs: 4,
                required: "no",
                placeholder: "",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 1,
                depth: 7,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "1",
            name: "Yes",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "2",
            name: "No",
            type: "toggle_button",
          },
        ],
        questionId: 16,
        editToggle: false,
      },
      {
        id: 17,
        name: "childAbility",
        value: "medical_care",
        type: "options_tree",
        label:
          "18. Is your child limited or prevented in any way in his or her ability to do the things most children of the same age can do?",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 1,
                name: "any_medical_behavioral_health_condition", //"Is this because of ANY medical, behavioral, or health condition?",
                type: "text",
                label:
                  "Is this because of ANY medical, behavioral, or health condition?",
                editToggle: false,
                xs: 4,
                required: "no",
                placeholder: "",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 1,
                depth: 7,
              },
              {
                id: 1,
                name: "condition_that_has_lasted",
                value: "condition_that_has_lasted",
                commentValueHolder: "childAbility_condition_that_has_lasted",
                label:
                  "Is this a condition that has lasted or is expected to last for at least 12 months?",
                type: "options_tree", //"label",
                subQuestions: [
                  {
                    depth: 1,
                    subQuestionId: 2,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Yes",
                    value: "1",
                    name: "Yes",
                    type: "toggle_button",
                  },
                  {
                    depth: 1,
                    subQuestionId: 3,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "No",
                    value: "2",
                    name: "No",
                    type: "toggle_button",
                  },
                ],
                editToggle: false,
                xs: 12,
                required: "no",
                placeholder: "",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: false,
                questionId: 1,
              },
              {
                id: 1,
                name: "Explain",
                type: "text",
                label: "Explain:",
                editToggle: false,
                xs: 4,
                required: "no",
                placeholder: "",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 1,
                depth: 7,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "1",
            name: "Yes",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "2",
            name: "No",
            type: "toggle_button",
          },
        ],
        questionId: 17,
        editToggle: false,
      },
      {
        id: 18,
        name: "childNeed",
        value: "medical_care",
        type: "options_tree",
        label:
          "19. Does your child need or get special therapy, such as physical, occupational or speech?",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 1,
                name: "any_medical_behavioral_health_condition", //"Is this because of ANY medical, behavioral, or health condition?",
                type: "text",
                label:
                  "Is this because of ANY medical, behavioral, or health condition?",
                editToggle: false,
                xs: 4,
                required: "no",
                placeholder: "",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 1,
                depth: 7,
              },
              {
                id: 1,
                name: "condition_that_has_lasted",
                value: "condition_that_has_lasted",
                commentValueHolder: "childNeed_condition_that_has_lasted",
                label:
                  "Is this a condition that has lasted or is expected to last for at least 12 months?",
                type: "options_tree", //"label",
                subQuestions: [
                  {
                    depth: 1,
                    subQuestionId: 2,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "Yes",
                    value: "1",
                    name: "Yes",
                    type: "toggle_button",
                  },
                  {
                    depth: 1,
                    subQuestionId: 3,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    isConfigured: true,
                    isModified: false,
                    label: "No",
                    value: "2",
                    name: "No",
                    type: "toggle_button",
                  },
                ],

                editToggle: false,
                xs: 12,
                required: "no",
                placeholder: "",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: false,
                questionId: 1,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "1",
            name: "Yes",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "2",
            name: "No",
            type: "toggle_button",
          },
        ],
        questionId: 18,
        editToggle: false,
      },
      {
        id: 19,
        name: "childDevelopment",
        value: "medical_care",
        type: "options_tree",
        label:
          "20. Does your child have any kind of emotional, developmental, or behavioral problem for which he or she needs or gets treatment or counseling?",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 1,
                name: "any_medical_behavioral_health_condition", // "Is this because of ANY medical, behavioral, or health condition?",
                type: "text",
                label:
                  "Is this because of ANY medical, behavioral, or health condition?",
                editToggle: false,
                xs: 4,
                required: "no",
                placeholder: "",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: true,
                subQuestionId: 1,
                depth: 7,
              },
            ],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "1",
            name: "Yes",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "2",
            name: "No",
            type: "toggle_button",
          },
        ],
        questionId: 19,
        editToggle: false,
      },
      {
        id: 20,
        name: "childReceiveSupport",
        value: "support_service",
        type: "options_tree",
        label: "21. Does your child receive support services in the home?",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Nursing Care",
            value: "nursingCare",
            name: "Nursing Care",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Personal Care Attendant",
            value: "personalCareAttendant",
            name: "Personal Care Attendant",
            type: "toggle_button",
          },
          {
            subQuestionId: 3,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Home Health Aide",
            value: "homeHealthAide",
            name: "Home Health Aide",
            type: "toggle_button",
          },
          {
            subQuestionId: 4,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "no",
            name: "No",
            type: "toggle_button",
          },
        ],
        questionId: 20,
        editToggle: false,
      },
      {
        id: 21,
        name: "childMedicalCheckup",
        value: "medical_checkup",
        type: "options_tree",
        label:
          "22. Has your child had a medical checkup in the last 12 months?",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "1",
            name: "Yes",
            isConfigured: true,
            type: "toggle_button",
          },
          //"Yes", "No", "Doesn’t Know"
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "2",
            name: "No",
            type: "toggle_button",
          },
          {
            subQuestionId: 3,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Doesn’t Know",
            value: "3",
            name: "Doesn’t Know",
            type: "toggle_button",
          },
        ],
        questionId: 21,
        editToggle: false,
      },
      //  //question number 22

      {
        id: 22,
        name: "childsHeightWeight",
        type: "options_tree",
        label: "23. Do you know your child's height and weight?",
        value: "",
        editToggle: false,
        xs: 12,
        required: false,
        placeholder: "",
        defaultValue: "",
        isCommentEnabled: false,
        isConfigured: true,
        isModified: true,
        subQuestionId: 22,
        hasSubQuestions: true,
        questionId: 22,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: true,
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "1",
            name: "Yesbmi",
            isConfigured: true,
            type: "toggle_button",
            subQuestions: [
              {
                id: 2,
                name: "childsHeightfeet",
                value: "feet",
                type: "text",
                label: "Childs Height",
                editToggle: false,
                xs: 1,
                required: false,
                placeholder: "",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: false,
                questionId: 10,
              },
              {
                id: 2,
                name: "childsHeightInches",
                value: "inch",
                type: "text",
                label: "Childs Height Inches",
                editToggle: false,
                xs: 1,
                mt: 22,
                required: false,
                placeholder: "",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: false,
                questionId: 11,
              },
              {
                id: 2,
                name: "childsWeight",
                value: "lbs",
                type: "text",
                label: "Childs Weight",
                editToggle: false,
                xs: 1.5,
                required: false,
                placeholder: "",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: false,
                questionId: 12,
              },

              {
                id: 2,
                name: "calculateBmi",
                value: "",
                type: "text",
                label: "Calculate BMI",
                editToggle: false,
                xs: 1.5,
                required: false,
                placeholder: "",
                defaultValue: "",
                isCommentEnabled: false,
                isConfigured: true,
                isModified: false,
                questionId: 13,
                disabled: true,
              },
            ],
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "2",
            name: "No",
            type: "toggle_button",
          },
        ],
      },
      // {
      //   question: "22. Do you know your child’s height and weight?",
      //   type: "childBMI",
      //   name: "childsHeightWeight",
      //   firstInputType: "number",
      //   isBMI: true,
      //   mainOptions: ["Yes", "No"],
      //   options: ["Childs Height", "Childs Weight"],
      //   scores: [9, 9, 6, 3],
      //   scoresW: [12, 12, 8, 8],
      //   elementType: ["input", "input", "button"],
      //   optionsValues: ["Inch", "lbs"],
      //   subQuestion: [
      //     [
      //       {
      //         question: "",
      //       },
      //     ],
      //     [
      //       {
      //         question: "",
      //       },
      //     ],
      //     [
      //       {
      //         question: "",
      //       },
      //     ],
      //     [
      //       {
      //         question: "Describe",
      //         type: "radio",
      //         options: ["Active", "History of", "Rule out"],
      //       },
      //       {
      //         question: "Malnutrition",
      //         type: "radio",
      //         options: ["Yes", "No"],
      //       },
      //       {
      //         question: "Supported by",
      //         type: "radio",
      //         options: [
      //           "Albumin < 3.5 g/dl",
      //           "Muscle wasting",
      //           "History of severe weight loss",
      //         ],
      //       },
      //     ],
      //   ],
      // },

      //question number 23
      // {
      //   question: "23. How would you describe your child’s weight?",
      //   optionsValues: [""],
      //   options: [""],
      //   elementType: ["input"],
      //   type: "child-InputBox",
      //   firstInputType: "text",
      //   hasScore: "no",
      //   subQuestion: "yes",
      //   subQuestionType: "3",
      // },
      {
        id: 23,
        name: "describeChildsWeight",
        type: "text",
        label: "24. How would you describe your child’s weight?",
        value: "",
        editToggle: false,
        xs: 12,
        required: false,
        placeholder: "",
        defaultValue: "",
        isCommentEnabled: false,
        isConfigured: true,
        isModified: true,
        subQuestionId: 23,
        hasSubQuestions: false,
        questionId: 23,
      },

      //question number 24
      {
        id: 24,
        name: "femaleChildren",
        value: "female_children",
        type: "options_tree",
        label: "25. For female children >=12: Is your child pregnant?",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "yes",
            name: "Yes",
            isConfigured: true,
            type: "toggle_button",
          },
          //  "Yes", "No", "Doesn’t Know", "N/A"
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "no",
            name: "No",
            type: "toggle_button",
          },
          {
            subQuestionId: 3,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Doesn’t Know",
            value: "doesnotKnow",
            name: "Doesn’t Know",
            type: "toggle_button",
          },
          {
            subQuestionId: 4,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "N/A",
            value: "na",
            name: "N/A",
            type: "toggle_button",
          },
        ],
        questionId: 24,
        editToggle: false,
      },
      //question number 25

      // {
      //   question:
      //     "25. How often do you worry you don’t have enough food for your family?",
      //   summaryRef: "1_2",
      //   type: "pedsLonesingleselect",
      //   options: ["Never", "Sometimes", "Always", "Decline to answer"],
      //   score: [],
      //   subQuestion: [
      //     [
      //       {
      //         question: "",
      //       },
      //     ],
      //     [
      //       {
      //         question: "",
      //       },
      //     ],
      //     [
      //       {
      //         question: "",
      //       },
      //     ],
      //     [
      //       {
      //         question: "",
      //       },
      //     ],
      //   ],
      // },
      {
        id: 25,
        name: "enoughFoodForFamily",
        value: "enough_food",
        type: "options_tree",
        label:
          "26. How often do you worry you don’t have enough food for your family?",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Never",
            value: "never",
            name: "Never",
            isConfigured: true,
            type: "toggle_button",
          },
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Sometimes",
            value: "sometimes",
            name: "Sometimes",
            type: "toggle_button",
          },
          {
            subQuestionId: 3,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Always",
            value: "always",
            name: "Always",
            type: "toggle_button",
          },
          {
            subQuestionId: 4,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "Decline to answer",
            value: "declineToAnswer",
            name: "Decline to answer",
            type: "toggle_button",
          },
          // {
          //   subQuestionId: 5,
          //   hasSubQuestions: false,
          //   subQuestions: [],
          //   editToggle: false,
          //   isCommentEnabled: false,
          //   isConfigured: true,
          //   isModified: false,
          //   label: "N/A",
          //   value: "na",
          //   name: "N/A",
          //   type: "toggle_button",
          // },
        ],
        questionId: 25,
        editToggle: false,
      },
      //question number 26
      // {
      //   question:
      //     "26. Do you know what community resources are available to help you?",
      //   summaryRef: "1_2",
      //   type: "pedsLonesingleselect",
      //   options: ["Yes", "No"],
      //   score: [
      //     {
      //       score: 3,
      //       weight: 3,
      //     },
      //     {
      //       score: 3,
      //       weight: 3,
      //     },
      //     {
      //       score: 2,
      //       weight: 3,
      //     },
      //     {
      //       score: 1,
      //       weight: 3,
      //     },
      //     {
      //       score: 0,
      //       weight: 3,
      //     },
      //     {
      //       score: 0,
      //       weight: 3,
      //     },
      //     {
      //       score: 0,
      //       weight: 3,
      //     },
      //   ],
      //   subQuestion: [
      //     [
      //       {
      //         question: "",
      //       },
      //     ],
      //     [
      //       {
      //         question: "",
      //       },
      //     ],
      //     [
      //       {
      //         question: "",
      //       },
      //     ],
      //     [
      //       {
      //         question: "",
      //       },
      //     ],
      //     [
      //       {
      //         question: "",
      //       },
      //     ],
      //     [
      //       {
      //         question: "",
      //       },
      //     ],
      //     [
      //       {
      //         question: "",
      //       },
      //     ],
      //   ],
      // },
      {
        id: 26,
        name: "communityResources",
        value: "communityResources",
        type: "options_tree",
        label:
          "27. Do you know what community resources are available to help you?",
        fullWidth: true,
        required: true,
        xs: 12,
        isCommentEnabled: true,
        isConfigured: true,
        isModified: false,
        hasSubQuestions: true,
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 1,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            label: "Yes",
            value: "1",
            name: "Yes",
            isConfigured: true,
            type: "toggle_button",
          },
          //  "Yes", "No", "Doesn’t Know", "N/A"
          {
            subQuestionId: 2,
            hasSubQuestions: false,
            subQuestions: [],
            editToggle: false,
            isCommentEnabled: false,
            isConfigured: true,
            isModified: false,
            label: "No",
            value: "2",
            name: "No",
            type: "toggle_button",
          },
        ],
        questionId: 26,
        editToggle: false,
      },
      //question number 27
      // {
      //   question: "27. Does your child have any of the following conditions?",
      //   name: "childHaveAnyConditions",
      //   summaryRef: "1_10",
      //   type: "child-levelThreeSingleSelect",
      //   options: [
      //     "Asthma",
      //     "Diabetes",
      //     "Sickle cell disease",
      //     "Hemophilia",
      //     "DD/ADHD",
      //     "Substance use",
      //   ],
      //   score: [
      //     {
      //       score: 3,
      //       weight: 2,
      //     },
      //     {
      //       score: 0,
      //       weight: 2,
      //     },
      //     {
      //       score: 0,
      //       weight: 2,
      //     },
      //   ],
      //   subQuestion: [
      //     [
      //       {
      //         question: "Does your child see a specialist?",
      //         type: "radio",
      //         options: ["Yes", "No"],
      //         score: [
      //           {
      //             score: 0,
      //             weight: 0,
      //           },
      //           {
      //             score: 0,
      //             weight: 0,
      //           },
      //         ],
      //         subQuestion: [
      //           [
      //             {
      //               question: "If yes, name",
      //               type: "input",
      //               subQuestion: [],
      //             },
      //           ],
      //           [
      //             {
      //               question: "",
      //             },
      //           ],
      //         ],
      //       },
      //     ],
      //     [
      //       {
      //         question: "Do you test your child’s blood sugar?",
      //         type: "radio",
      //         options: ["Yes", "No"],
      //         score: [
      //           {
      //             score: 0,
      //             weight: 0,
      //           },
      //           {
      //             score: 0,
      //             weight: 0,
      //           },
      //         ],
      //         subQuestion: [
      //           [
      //             {
      //               question: "",
      //               type: "",
      //             },
      //           ],
      //           [
      //             {
      //               question: "",
      //               type: "",
      //             },
      //           ],
      //         ],
      //       },
      //     ],
      //     [
      //       {
      //         question: "Do you know what an Hgb A1C is?",
      //         type: "radio",
      //         options: ["Yes", "No"],
      //         score: [
      //           {
      //             score: 0,
      //             weight: 0,
      //           },
      //           {
      //             score: 0,
      //             weight: 0,
      //           },
      //         ],
      //         subQuestion: [
      //           [
      //             {
      //               question: "If yes what is your childs last Hgb A1C?",
      //               type: "input",
      //             },
      //           ],
      //           [
      //             {
      //               question: "",
      //               type: "",
      //             },
      //           ],
      //         ],
      //       },
      //     ],
      //     [
      //       {
      //         question: "Do you follow a special diet?",
      //         type: "radio",
      //         options: ["Yes", "No"],
      //         score: [
      //           {
      //             score: 0,
      //             weight: 0,
      //           },
      //           {
      //             score: 0,
      //             weight: 0,
      //           },
      //         ],
      //         subQuestion: [
      //           [
      //             {
      //               question: "If yes, diet",
      //               type: "input",
      //             },
      //           ],
      //           [
      //             {
      //               question: "",
      //             },
      //           ],
      //         ],
      //       },
      //     ],
      //     [
      //       {
      //         question: "",
      //       },
      //     ],
      //     [
      //       {
      //         question: "",
      //       },
      //     ],
      //   ],
      // },

      {
        id: 27,
        questionId: 27,
        name: "childHaveAnyConditions",
        label: "28. Does your child have any of the following conditions?",
        value: "",
        type: "options_tree",
        genre: "multiSelect",
        xs: 12,
        //     "Asthma",
        //     "Diabetes",
        //     "Sickle cell disease",
        //     "Hemophilia",
        //     "DD/ADHD",
        //     "Substance use",
        subQuestions: [
          {
            depth: 1,
            subQuestionId: 2,
            label: "Asthma",
            type: "toggle_button",
            value: "asthma",
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 6,
                name: "childSeeASpecialist",
                type: "options_tree",
                label: "Does your child see a specialist?",
                required: true,
                xs: 12,
                isCommentEnabled: true,
                isModified: true,
                hasSubQuestions: true,
                subId: "27_a",
                subQuestions: [
                  {
                    depth: 3,

                    subQuestionId: 1,
                    hasSubQuestions: false,
                    subQuestions: [
                      {
                        id: 28,
                        name: "ifYesName",
                        type: "text",
                        label: "If yes, name",
                        value: "",
                        editToggle: false,
                        xs: 12,
                        required: false,
                        placeholder: "",
                        defaultValue: "",
                        isCommentEnabled: false,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "Yes",
                    value: "1",
                    type: "toggle_button",
                  },

                  {
                    depth: 3,
                    subQuestionId: 1,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "No",
                    value: "2",
                    type: "toggle_button",
                  },
                ],
                subQuestionId: 1,
                editToggle: false,
              },
            ],
          },

          {
            depth: 1,
            subQuestionId: 2,
            label: "Diabetes",
            type: "toggle_button",
            value: "diabetes",
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 6,
                name: "childsBloodSugar",
                type: "options_tree",
                label: "Do you test your child’s blood sugar?",
                subId: "27_b",
                required: true,
                xs: 12,
                isCommentEnabled: true,
                isModified: true,
                hasSubQuestions: true,
                subQuestions: [
                  {
                    depth: 3,
                    subQuestionId: 1,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "Yes",
                    value: "1",
                    type: "toggle_button",
                  },

                  {
                    depth: 3,
                    subQuestionId: 1,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "No",
                    value: "2",
                    type: "toggle_button",
                  },
                ],
                subQuestionId: 1,
                editToggle: false,
              },
            ],
          },

          {
            depth: 1,
            subQuestionId: 2,
            label: "Sickle cell disease",
            type: "toggle_button",
            value: "sickleCellDisease",
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 6,
                name: "hgbAonec",
                type: "options_tree",
                label: "Do you know what an Hgb A1C is?",
                required: true,
                xs: 12,
                isCommentEnabled: true,
                isModified: true,
                hasSubQuestions: true,
                subId: "27_c",
                subQuestions: [
                  {
                    depth: 3,

                    subQuestionId: 1,
                    hasSubQuestions: false,
                    subQuestions: [
                      {
                        id: 28,
                        name: "ifYesHgbA1c",
                        type: "text",
                        label: "If yes what is your childs last Hgb A1C?",
                        value: "",
                        editToggle: false,
                        xs: 12,
                        required: false,
                        placeholder: "",
                        defaultValue: "",
                        isCommentEnabled: false,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "Yes",
                    value: "1",
                    type: "toggle_button",
                  },

                  {
                    depth: 3,
                    subQuestionId: 1,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "No",
                    value: "2",
                    type: "toggle_button",
                  },
                ],
                subQuestionId: 1,
                editToggle: false,
              },
            ],
          },

          {
            depth: 1,
            subQuestionId: 2,
            label: "Hemophilia",
            type: "toggle_button",
            value: "hemophilia",
            hasSubQuestions: true,
            subQuestions: [
              {
                id: 6,
                name: "specialDiet",
                type: "options_tree",
                label: "Do you follow a special diet?",
                required: true,
                xs: 12,
                isCommentEnabled: true,
                isModified: true,
                hasSubQuestions: true,
                subId: "27_d",
                subQuestions: [
                  {
                    depth: 3,

                    subQuestionId: 1,
                    hasSubQuestions: false,
                    subQuestions: [
                      {
                        id: 28,
                        name: "ifYesDiet",
                        type: "text",
                        label: "If yes, diet",
                        value: "",
                        editToggle: false,
                        xs: 12,
                        required: false,
                        placeholder: "",
                        defaultValue: "",
                        isCommentEnabled: false,
                        isConfigured: true,
                        isModified: true,
                        hasSubQuestions: false,
                      },
                    ],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "Yes",
                    value: "1",
                    type: "toggle_button",
                  },

                  {
                    depth: 3,
                    subQuestionId: 1,
                    hasSubQuestions: false,
                    subQuestions: [],
                    editToggle: false,
                    isCommentEnabled: false,
                    label: "No",
                    value: "2",
                    type: "toggle_button",
                  },
                ],
                subQuestionId: 1,
                editToggle: false,
              },
            ],
          },

          {
            depth: 1,
            subQuestionId: 2,
            label: "DD/ADHD",
            type: "toggle_button",
            value: "ddAdhd",
            hasSubQuestions: true,
            subQuestions: [],
          },

          {
            depth: 1,
            subQuestionId: 2,
            label: "Substance use",
            type: "toggle_button",
            value: "substanceUse",
            hasSubQuestions: true,
            subQuestions: [],
          },
        ],
        isCommentEnabled: true,
        required: true,
        editToggle: false,
        isModified: false,
        depth: 1,
      },

      //question number 28
      {
        id: 28,
        name: "additionalInformation",
        type: "text",
        label:
          "29. Is there any additional information you would like to share about your child?",
        value: "",
        editToggle: false,
        xs: 12,
        required: false,
        placeholder: "",
        defaultValue: "",
        isCommentEnabled: false,
        isConfigured: true,
        isModified: true,
        subQuestionId: 28,
        hasSubQuestions: false,
        questionId: 28,
      },
    ],
  },
];
export default pedsAssessmentInfoJsonData;
