import { Grid } from "@mui/material";
import lodash from "lodash";
import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";

import { DFButton } from "../../../Fill_HRA/DFInputs/DFButton";
import { DFCheckbox } from "../../..//Fill_HRA/DFInputs/DFCheckbox";
import { DFDate } from "../../../Fill_HRA/DFInputs/DFDate";
import { DFHeading } from "../../../Fill_HRA/DFInputs/DFHeading";
import { DFImage } from "../../../Fill_HRA/DFInputs/DFImage";
import { DFLabel } from "../../../Fill_HRA/DFInputs/DFLabel";
import { DFOptionsTree } from "../../../Fill_HRA/DFInputs/DFOptionsTree";
import DFRadio from "../../../Fill_HRA/DFInputs/DFRadio";
import { DFSelect } from "../../../Fill_HRA/DFInputs/DFSelect";
import DFText from "../../../Fill_HRA/DFInputs/DFText";
import { DFRuler } from "../../../Fill_HRA/DFInputs/DFRuler";
import { useFillHraContext } from "../../../../../../contexts/fillHraFormContext";
import {
  deleteSubTree,
  getCovidScreeningData,
  getSubTree,
  setCovidScreeningData,
} from "./covidScreeningMapping";
import { useUserContext } from "../../../../../../contexts/UserContext";
import { getInputsData } from "./covidFormData";
import { useLocation } from "react-router-dom";
import { getApi } from "../../../../../../utils/axiosApiWrapper";
import LoadingOverlay from "react-loading-overlay";
import focusCaresLoader from "../../../../../../loader/focuscares_loader.gif";
const CovidScreening = forwardRef((props, _ref) => {
  const {
    // examReviewFormData,
    // setExamReviewFormData,
    // showComment,
    // setShowComment,
    getSectionInputsData,
    getDisableFlag,
    // getStateValue,
    // setStateValue,
  } = useFillHraContext();
  const {
    storedPlan,
    storedYear,
    sideBarSectionDisable,
    setSideBarSectionDisable,
  } = useUserContext();

  const [covidScreeningFormData, setCovidScreeningFormData] = useState({});
  const [showComment, setShowComment] = useState([]);
  const [disableFlag, setDisableFlag] = useState(false);
  const [loading, setLoading] = useState(undefined);
  const sectionId = props.currSection || 3;
  const clientPlan = localStorage.getItem("clientPlan") || storedPlan;
  const clientYear = localStorage.getItem("clientYear") || storedYear;
  const inputsJsonData = getInputsData(clientPlan, clientYear);
  const location = useLocation();
  const assessmentId = location?.state?.assessmentId
    ? location?.state?.assessmentId
    : sessionStorage.getItem("assessmentId");
  const tabId = location?.state?.tabId
    ? location?.state?.tabId
    : sessionStorage.getItem("tabId");
  const roleId = sessionStorage.getItem("roleId");
  useEffect(() => {
    async function getDbData() {
      setLoading(true);
      setSideBarSectionDisable(true);
      // const dbData = await props.getSectionDbData(sectionId);
      const secondApiResponse = await getApi(
        `/hra/get-assessment?id=${assessmentId}`,
      ); //await props.getSectionDbData(sectionId);
      const dbData = secondApiResponse[`data`];
      const mashedData =
        dbData?.data?.covid?.data &&
        Object.keys(dbData?.data?.covid?.data).length > 0
          ? getCovidScreeningData(dbData?.data?.covid?.data, clientYear)
          : {};
      setCovidScreeningFormData(mashedData);
      setLoading(false);
      setSideBarSectionDisable(false);
    }

    getDbData();

    setDisableFlag(getDisableFlag());
  }, []);

  const handleTextChange = (name, value) => {
    const updatedFormData = { ...covidScreeningFormData };
    updatedFormData[name] = value;

    setCovidScreeningFormData(updatedFormData);
  };

  const handleRadioChange = (name, value) => {
    const updatedFormData = { ...covidScreeningFormData };
    updatedFormData[name] = value;

    setCovidScreeningFormData(updatedFormData);
  };

  const handleDateChange = (name, value) => {
    const updatedFormData = { ...covidScreeningFormData };
    updatedFormData[name] = value;

    setCovidScreeningFormData(updatedFormData);
  };

  const handleSelectChange = (name, value) => {
    const updatedFormData = { ...covidScreeningFormData };
    updatedFormData[name] = value;

    setCovidScreeningFormData(updatedFormData);
  };

  useEffect(() => {
    props.setAutoSaveCovidScreeningData({ ...covidScreeningFormData });
  }, [covidScreeningFormData]);

  const handleOptionsTreeChange = (name, value, isMulti) => {
    const updatedFormData = { ...covidScreeningFormData };
    let oldSelection = updatedFormData[name];
    let newSelection = [];

    if (isMulti) {
      if (value.includes("none")) {
        if (oldSelection?.includes("none")) {
          newSelection = value.filter((item) => item !== "none");
        } else {
          newSelection = ["none"];
        }
      } else {
        newSelection = value;
      }
    } else {
      if (!oldSelection?.includes(value)) {
        newSelection = [value];
      } else {
        newSelection = [];
      }
    }
    // if (value.includes("none") && isMulti === true) {
    //   if (oldSelection.includes("none")) {
    //     newSelection = value.filter((item) => item !== "none");
    //   } else {
    //     newSelection = ["none"];
    //   }
    // } else if (isMulti) {
    //   newSelection = value;
    // } else {
    //   if (!oldSelection?.includes(value)) {
    //     newSelection.push(value);
    //   }
    // }
    let deletedValue =
      isMulti === true
        ? lodash.difference(oldSelection, newSelection)?.[0] || ""
        : oldSelection?.[0] || "";

    if (value === "yes" || value === "no") {
      const deleteRegex = `${name}Sub`;

      updatedFormData[name] = value;
      let subTreeDeletedData = deleteSubTree(updatedFormData, deleteRegex);
      setCovidScreeningFormData(subTreeDeletedData);
      return null;
    } else if (deletedValue !== "") {
      updatedFormData[name] = value;
      const deleteRegex = getSubTree(name, deletedValue);

      if (deleteRegex != "") {
        let subTreeDeletedData = deleteSubTree(updatedFormData, deleteRegex);
        setCovidScreeningFormData(subTreeDeletedData);
        return null;
      } else {
        updatedFormData[name] = newSelection;
        setCovidScreeningFormData(updatedFormData);
      }

      return null;
    } else {
      updatedFormData[name] = value;
      setCovidScreeningFormData(updatedFormData);
    }
  };

  const handleButtonClick = (name) => {
    return null;
  };

  const handleCheckboxChange = (name, value, checked) => {
    const updatedFormData = { ...covidScreeningFormData };
    const oldData = updatedFormData?.[name] || [];
    let newData = [];
    if (oldData.length < 1) {
      newData.push(value);
    } else {
      if (checked) {
        newData = [...oldData];
        newData.push(value);
      } else {
        newData = oldData.filter((ele) => ele !== value);
      }
    }

    updatedFormData[name] = newData;
    setCovidScreeningFormData(updatedFormData);
  };

  useImperativeHandle(_ref, () => ({
    getPayloadData: () => {
      return setCovidScreeningData(covidScreeningFormData, clientYear);
    },
  }));

  return (
    <LoadingOverlay
      styles={{
        // wrapper: { marginLeft: "50px", marginTop: "50px" },
        // overlay: {},
        content: { marginLeft: "40vw", marginTop: "50px" },
        // spinner: { marginLeft: "50px", marginTop: "50px" },
        spinner: (base) => ({
          ...base,
          marginLeft: "50px",
          marginTop: "50px",
        }),
      }}
      style={{ marginTop: "20px" }}
      active={loading}
      spinner={<img src={focusCaresLoader} alt="Loader" />}
      // text="Loading your content..."
    >
      <div
        style={{
          margin: "10px 20px",
        }}
      >
        <Grid container spacing={2}>
          {inputsJsonData?.questions?.map((inputProps) => {
            const { questionId, name, xs, type, isSingleButton } = inputProps;
            const inputValue = covidScreeningFormData[name] || "";
            const commentValue = `${
              covidScreeningFormData?.[`${name}Comment`]
                ? `${covidScreeningFormData[`${name}Comment`]}`
                : ""
            }`;
            const quesSlug = `${sectionId}_${questionId}`;
            if (
              type === "text" ||
              type === "number" ||
              type === "email" ||
              type === "password"
            ) {
              return (
                <Grid
                  key={quesSlug}
                  xs={xs}
                  /* sm={inputProps.sm}*/
                  item
                >
                  <DFText
                    inputProps={inputProps}
                    value={inputValue}
                    handleTextChange={handleTextChange}
                    showComment={showComment}
                    setShowComment={setShowComment}
                    quesSlug={`${questionId}`}
                    sectionId={sectionId}
                    commentValue={commentValue}
                    disableFlag={disableFlag || (roleId == 5 && tabId == 3)}
                  />
                </Grid>
              );
            } else if (type === "radio") {
              return (
                <Grid key={quesSlug} xs={xs} item>
                  <DFRadio
                    inputProps={inputProps}
                    value={inputValue}
                    handleRadioChange={handleRadioChange}
                    handleTextChange={handleTextChange} // added for comment box event handling
                    showComment={showComment}
                    setShowComment={setShowComment}
                    quesSlug={`${questionId}`}
                    sectionId={sectionId}
                    commentValue={commentValue}
                    disableFlag={disableFlag || (roleId == 5 && tabId == 3)}
                  />
                </Grid>
              );
            } else if (type === "date") {
              const dateValue = covidScreeningFormData[name] || {};
              return (
                <Grid
                  key={quesSlug}
                  xs={xs}
                  /* sm={sm} */
                  item
                >
                  <DFDate
                    inputProps={inputProps}
                    value={dateValue}
                    handleDateChange={handleDateChange}
                    handleTextChange={handleTextChange} // added for comment box event handling
                    showComment={showComment}
                    setShowComment={setShowComment}
                    quesSlug={`${questionId}`}
                    sectionId={sectionId}
                    commentValue={commentValue}
                    disableFlag={disableFlag || (roleId == 5 && tabId == 3)}
                    // dtPickProps={{
                    //   disableFuture:true,
                    //   disablePast:true,
                    //   minDate:minDOB,
                    //   maxDate:maxDOB,
                    //   views:["year", "month", "day"]
                    // }}
                  />
                </Grid>
              );
            } else if (type === "select") {
              return (
                <Grid key={quesSlug} xs={xs} item>
                  <DFSelect
                    inputProps={inputProps}
                    value={inputValue}
                    handleSelectChange={handleSelectChange}
                    handleTextChange={handleTextChange} // added for comment box event handling
                    showComment={showComment}
                    setShowComment={setShowComment}
                    quesSlug={`${questionId}`}
                    sectionId={sectionId}
                    commentValue={commentValue}
                    disableFlag={disableFlag || (roleId == 5 && tabId == 3)}
                  />
                </Grid>
              );
            } else if (type === "options_tree") {
              return (
                <DFOptionsTree
                  inputProps={inputProps}
                  // formData={covidScreeningFormData}
                  formData={covidScreeningFormData}
                  handleOptionsTreeChange={handleOptionsTreeChange}
                  handleTextChange={handleTextChange}
                  handleRadioChange={handleRadioChange}
                  handleDateChange={handleDateChange}
                  handleSelectChange={handleSelectChange}
                  handleButtonClick={handleButtonClick}
                  handleCheckboxChange={handleCheckboxChange}
                  showComment={showComment}
                  setShowComment={setShowComment}
                  // setFormData={setCovidScreeningFormData}
                  setFormData={setCovidScreeningFormData}
                  quesSlug={`${questionId}`}
                  sectionId={sectionId}
                  // commentValue={commentValue}
                  disableFlag={disableFlag || (roleId == 5 && tabId == 3)}
                />
              );
            } else if (type === "label") {
              return (
                <Grid key={quesSlug} xs={xs} item>
                  <DFLabel
                    inputProps={inputProps}
                    handleTextChange={handleTextChange} // added for comment box event handling
                    showComment={showComment}
                    setShowComment={setShowComment}
                    quesSlug={`${questionId}`}
                    sectionId={sectionId}
                    commentValue={commentValue}
                    disableFlag={disableFlag || (roleId == 5 && tabId == 3)}
                  />
                </Grid>
              );
            } else if (type === "button") {
              return (
                <Grid key={quesSlug} xs={isSingleButton ? 12 : 0} item>
                  <DFButton
                    inputProps={{
                      ...inputProps,
                      disabled: roleId == 5 && tabId == 3,
                    }}
                    handleButtonClick={handleButtonClick}
                  />
                </Grid>
              );
            } else if (type === "heading") {
              return (
                <Grid key={quesSlug} xs={12} item>
                  <DFHeading inputProps={inputProps} />
                </Grid>
              );
            } else if (type === "checkbox") {
              return (
                <Grid key={quesSlug} xs={xs} item>
                  <DFCheckbox
                    inputProps={inputProps}
                    showComment={showComment}
                    handleCheckboxChange={handleCheckboxChange}
                    value={inputValue}
                    handleTextChange={handleTextChange}
                    setShowComment={setShowComment}
                    quesSlug={`${questionId}`}
                    sectionId={sectionId}
                    commentValue={commentValue}
                    disableFlag={disableFlag || (roleId == 5 && tabId == 3)}
                  />
                </Grid>
              );
            } else if (type === "image") {
              return (
                <Grid key={quesSlug} xs={xs} item>
                  <DFImage inputProps={inputProps} />
                </Grid>
              );
            } else if (type === "divider") {
              return <DFRuler />;
            }

            return null;
          })}
        </Grid>
      </div>
    </LoadingOverlay>
  );
});

export default CovidScreening;
