import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./dialog.scss";
import Loader from "../Loader/Loader";
import { getApi, postApi } from "../../utils/axiosApiWrapper";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import _ from "lodash";
import Toaster from "../toaster/toaster";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { getMemberHistoryByYear, getMemberYears } from "../../api/membersApi";
import dayjs from "dayjs";
import { useUserContext } from "../../contexts/UserContext";
import Button from "../button/button";
import ReferralForm from "../Dashboard/MemberList/Fill_HRA/Sections/PatientSummary/ReferralForm/ReferralForm";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  boxShadow: 24,
};

export default function SchedulerHistory({
  open,
  selectedName,
  onClose,
  ModalClose,
  uniqueMemberId,
  memId,
}) {
  // const [open, setOpen] = useState(false);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);
  const [memberData, setMemberData] = useState([]);
  const [memObjId, setMemObjId] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [toasterOpen, setToasterOpen] = useState(false);
  const [toasterMessage, setToasterMessage] = useState("");
  const [toasterSeverity, setToasterSeverity] = useState();
  const [storedHraStatus, setStoredHraStatus] = useState([]);
  const [dynamicSchedulerData, setDynamicSchedulerData] = useState([]);
  const [refRespData, setRefRespData] = useState([]);
  const [memberAssessId, setMemberAssessId] = useState("");

  const roleId = sessionStorage.getItem("roleId");
  const { selectedTimeZoneUtc } = useUserContext();
  const hraStatusText = [
    "Scheduled",
    "In Progress",
    "Submitted Ready for QA",
    "Clinician Review - QA",
    "Submitted Ready for Coding",
    "Clinician Review - Coding",
    "Submitted - Ready for Upload",
    "Completed",
  ];
  const _ = require("lodash");

  const fetchMemberYear = async () => {
    try {
      const response = await getMemberYears(memId);
      const allYears = response?.data?.data;
      // Filter unique years along with the first corresponding ID
      const uniqueYearWithId = allYears.reduce((acc, current) => {
        if (!acc.some((item) => item.year === current.year)) {
          acc.push({ year: current.year, id: current.id });
        }
        return acc;
      }, []);
      setMemObjId(uniqueYearWithId);
    } catch (err) {
      return null;
    }
  };

  const handleReturnMail = async (event) => {
    setChecked(event.target.checked);
    try {
      const response = await postApi("/returnMail/returnMail", {
        memberUniqueId: uniqueMemberId,
        isReturnMail: event?.target?.checked,
      });
      setToasterMessage(response.data.message);
      setToasterOpen(true);
    } catch {
      return null;
    }
  };

  const onCloseToaster = () => {
    setToasterOpen(false);
    setToasterMessage("");
  };

  const convertToLocalDateTime = (utcDateTimeString) => {
    const utcDate = new Date(utcDateTimeString);
    const localDate = new Date(
      utcDate.getTime() + utcDate.getTimezoneOffset() * 60000,
    );
    return localDate.toLocaleString();
  };

  useEffect(() => {
    if (!open) {
      return;
    }

    const fetchData = async () => {
      try {
        await fetchMemberYear(); // Fetch years first
      } catch (err) {
        console.error("Error fetching member years:", err);
        return;
      }
    };
    fetchData();
  }, [open]);

  useEffect(() => {
    if (!open || memObjId.length === 0) {
      return;
    }

    const fetchMemberHistory = async () => {
      setIsLoading(true);
      let memdt = [],
        storedhra = [],
        dynsch = [];

      for (const mem of memObjId) {
        // console.log(memObjId,"mmmmmmm")

        try {
          const response = await getMemberHistoryByYear({
            memberUniqueId: mem.id,
            year: mem.year,
          });

          const refData = response?.data?.data?.referralDetails;
          setRefRespData(refData);

          // // console.log(refData,"refData")

          const memData = response?.data?.data[0];

          // console.log(memData,"mmm")
          if (memData) {
            memdt = [...memdt, memData];
            storedhra = [...storedhra, memData?.hraStatus?.hraStatus];
            if (Array.isArray(memData?.schedulerCallLogDetails?.callLog)) {
              dynsch.push(memData.schedulerCallLogDetails.callLog);
            }
          } else {
            console.log(`No data found for year ${mem.year}`);
          }
        } catch (error) {
          console.error(
            `Error fetching member history for year ${mem.year}:`,
            error,
          );
        }
      }

      setMemberData(memdt);
      setStoredHraStatus(storedhra);
      setDynamicSchedulerData(dynsch);
      if (memdt.length > 0) {
        setChecked(memdt[0]?.returnMail);
      }
      setIsLoading(false);
    };

    fetchMemberHistory();
  }, [memObjId, open]);

  const [ind, setInd] = useState(0);
  const [referralFormPopup, setReferralFormPopup] = useState(false);
  const handleReferralFormClose = () => {
    setReferralFormPopup(false);
  };

  const handleReferralFormOpen = async () => {
    setReferralFormPopup(true);
  };

  // const fetchReferalReport = async (referralId) => {
  //   // sessionStorage.setItem("assessmentId", assessmentId);
  //   // console.log(referralId,"referralId in fetch pdf report")
  //   try {
  //     // const assessId = sessionStorage.getItem("assessmentId", assessmentId);
  //     const response = await getApi(
  //       `/referral-form/generate-pdf?id=${memberAssessId}&referralId=${referralId}`,
  //     );

  //     if (response.status === 200) {
  //       const url = `${process.env.REACT_APP_API_BASE_URL}${response.data.path}`;
  //       // console.log(url,"url")
  //       // setDownloadReferral(url);
  //       const newTab = window.open(url, "_blank");
  //       if (newTab) {
  //         newTab.focus();
  //       }
  //     } else {
  //       console.error("Error: Failed to fetch PDF data.");
  //     }
  //   } catch (error) {
  //     console.error("Error fetching referral report:", error);
  //   }
  // };

  return (
    <>
      {!isLoading && (
        <BootstrapDialog
          onClose={ModalClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          className="history-dialog"
        >
          <div className="dialog-header">
            {isLoading ? (
              "Loading..."
            ) : (
              <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                {memberData[ind]?.assessmentData?.demographics?.data?.name ||
                  memberData[ind]?.assessmentData?.demographics?.data
                    ?.firstName +
                    " " +
                    memberData[ind]?.assessmentData?.demographics?.data
                      ?.lastName}{" "}
                - History
              </DialogTitle>
            )}
            <IconButton
              edge="end"
              color="inherit"
              onClick={onClose}
              aria-label="close"
              style={{ float: "right" }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className="edit-fields">
            <div className="member-details">
              <ul>
                <li>
                  Member Id:
                  <span>
                    {memberData[ind]?.assessmentData?.demographics?.data
                      ?.memberIdentificationNo ||
                      memberData[ind]?.assessmentData?.demographics?.data
                        ?.memberId}
                  </span>
                </li>
                <li>
                  Address:
                  <span>
                    {memberData[ind]?.assessmentData?.demographics?.data
                      ?.addressOne ||
                      memberData[ind]?.assessmentData?.demographics?.data
                        ?.address}
                  </span>
                </li>
                <li>
                  Mobile Number:{" "}
                  <span>
                    {memberData[ind]?.assessmentData?.demographics?.data
                      ?.pPhoneNumber ||
                      memberData[ind]?.assessmentData?.demographics?.data
                        ?.pPhone}
                  </span>
                </li>
                <li>
                  Phone Number:
                  <span>
                    {memberData[ind]?.assessmentData?.demographics?.data
                      ?.pPhoneNumber ||
                      memberData[ind]?.assessmentData?.demographics?.data
                        ?.pPhone}
                  </span>
                </li>
                <li>
                  HICN:
                  <span>
                    {memberData[ind]?.assessmentData?.demographics?.data?.hicn}
                  </span>
                </li>
              </ul>
            </div>
            {/* {roleId == 1 && ( */}

            <div className="return-mail">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox checked={checked} onChange={handleReturnMail} />
                  }
                  label="Return Mail"
                />
              </FormGroup>
            </div>
            {/* )} */}
          </div>
          <DialogContent>
            <div className="prior-assesment">
              {/* <h4>Prior Assessments</h4> */}
              <div className="tabsList">
                {memberData.map((mem, index) => {
                  return (
                    <div
                      key={index}
                      className={`tab ${ind === index ? "activeTab" : ""}`}
                      onClick={() => setInd(index)}
                    >
                      {mem.year}
                    </div>
                  );
                })}
              </div>
              <div className="assesment-card">
                <div className="assement-right">
                  <ul className="scheduler-details">
                    <li>
                      Visit Type:
                      <span>{memberData[ind]?.hraStatus?.visitType}</span>
                    </li>
                    <li>
                      Assigned Clinician:
                      <span>
                        {memberData[ind]?.clinicanUserData?.firstName}{" "}
                        {memberData[ind]?.clinicanUserData?.lastName}
                      </span>
                    </li>
                    <li>
                      Assigned QA:
                      <span>
                        {memberData[ind]?.qaUserData?.firstName}{" "}
                        {memberData[ind]?.qaUserData?.lastName}
                      </span>
                    </li>
                    <li>
                      Assigned Coder:{" "}
                      <span>
                        {memberData[ind]?.coderUserData?.firstName}{" "}
                        {memberData[ind]?.coderUserData?.lastName}
                      </span>
                    </li>
                  </ul>
                  {!_.isUndefined(storedHraStatus[ind]) &&
                    storedHraStatus[ind] != "15" &&
                    storedHraStatus[ind] != "16" &&
                    storedHraStatus[ind] != "17" &&
                    storedHraStatus[ind] != "4" &&
                    storedHraStatus[ind] != "5" &&
                    storedHraStatus[ind] != "14" &&
                    storedHraStatus[ind] != "20" &&
                    storedHraStatus[ind] != "18" && (
                      <ul
                        id="progressbar"
                        className={
                          storedHraStatus[ind] == "12" ||
                          storedHraStatus[ind] == "19" ||
                          storedHraStatus[ind] == "3"
                            ? "more-items"
                            : ""
                        }
                      >
                        <li
                          className={
                            storedHraStatus[ind] == "1" ||
                            storedHraStatus[ind] == "2" ||
                            storedHraStatus[ind] == "6" ||
                            storedHraStatus[ind] == "8" ||
                            storedHraStatus[ind] == "7" ||
                            storedHraStatus[ind] == "9" ||
                            storedHraStatus[ind] == "10" ||
                            storedHraStatus[ind] == "11" ||
                            storedHraStatus[ind] == "12" ||
                            storedHraStatus[ind] == "19" ||
                            storedHraStatus[ind] == "3"
                              ? "active"
                              : ""
                          }
                          id="step1"
                        >
                          <span>Scheduled</span>
                          {!_.isEmpty(
                            memberData[ind]?.hraStatusHistory.scheduled,
                          ) && (
                            <>
                              <span className="dateTime">
                                (
                                {/* {convertToLocalDateTime(
                                  memberData[ind]?.hraStatusHistory
                                    ?.scheduled[0]?.date,
                                )} */}
                                {dayjs(
                                  memberData[ind]?.hraStatusHistory
                                    ?.scheduled[0]?.date,
                                )
                                  .tz(selectedTimeZoneUtc)
                                  .format("MM-DD-YYYY h:mm A")}
                                )
                              </span>
                              <span className="dateTime">
                                {
                                  memberData[ind]?.hraStatusHistory
                                    ?.scheduled[0]?.userName
                                }
                              </span>
                            </>
                          )}
                        </li>
                        {storedHraStatus[ind] == "12" && (
                          <li className="active" id="step1">
                            <span>Assessment Cancelled</span>
                            {!_.isEmpty(
                              memberData[ind]?.hraStatusHistory
                                .assessmentCalcelled,
                            ) && (
                              <>
                                <span className="dateTime">
                                  (
                                  {/* {convertToLocalDateTime(
                                    memberData[ind]?.hraStatusHistory
                                      ?.assessmentCalcelled[0]?.date,
                                  )} */}
                                  {dayjs(
                                    memberData[ind]?.hraStatusHistory
                                      ?.assessmentCalcelled[0]?.date,
                                  )
                                    .tz(selectedTimeZoneUtc)
                                    .format("MM-DD-YYYY h:mm A")}
                                  )
                                </span>
                                <span className="dateTime">
                                  {
                                    memberData[ind]?.hraStatusHistory
                                      ?.assessmentCalcelled[0]?.userName
                                  }
                                </span>
                              </>
                            )}
                          </li>
                        )}
                        {storedHraStatus[ind] == "3" && (
                          <li className="active" id="step1">
                            <span>Appointment Cancelled</span>
                            {!_.isEmpty(
                              memberData[ind]?.hraStatusHistory
                                .appointmentCancelled,
                            ) && (
                              <>
                                <span className="dateTime">
                                  (
                                  {/* {convertToLocalDateTime(
                                    memberData[ind]?.hraStatusHistory
                                      ?.appointmentCancelled[0]?.date,
                                  )} */}
                                  {dayjs(
                                    memberData[ind]?.hraStatusHistory
                                      ?.assessmentCalcelled[0]?.date,
                                  )
                                    .tz(selectedTimeZoneUtc)
                                    .format("MM-DD-YYYY h:mm A")}
                                  )
                                </span>
                                <span className="dateTime">
                                  {
                                    memberData[ind]?.hraStatusHistory
                                      ?.appointmentCancelled[0]?.userName
                                  }
                                </span>
                              </>
                            )}
                          </li>
                        )}
                        {storedHraStatus[ind] == "19" && (
                          <li className="active" id="step1">
                            <span>No Show</span>
                            {!_.isEmpty(
                              memberData[ind]?.hraStatusHistory.noShow,
                            ) && (
                              <>
                                <span className="dateTime">
                                  (
                                  {/* {convertToLocalDateTime(
                                    memberData[ind]?.hraStatusHistory?.noShow[0]
                                      ?.date,
                                  )} */}
                                  {dayjs(
                                    memberData[ind]?.hraStatusHistory?.noShow[0]
                                      ?.date,
                                  )
                                    .tz(selectedTimeZoneUtc)
                                    .format("MM-DD-YYYY h:mm A")}
                                  )
                                </span>
                              </>
                            )}
                          </li>
                        )}
                        <li
                          className={
                            storedHraStatus[ind] == "2" ||
                            storedHraStatus[ind] == "6" ||
                            storedHraStatus[ind] == "8" ||
                            storedHraStatus[ind] == "7" ||
                            storedHraStatus[ind] == "9" ||
                            storedHraStatus[ind] == "10" ||
                            storedHraStatus[ind] == "11"
                              ? "active"
                              : ""
                          }
                          id="step2"
                        >
                          <span>InProgress</span>
                          {!_.isEmpty(
                            memberData[ind]?.hraStatusHistory.inProgress,
                          ) && (
                            <>
                              <span className="dateTime">
                                (
                                {/* {convertToLocalDateTime(
                                  memberData[ind]?.hraStatusHistory
                                    ?.inProgress[0]?.date,
                                )} */}
                                {dayjs(
                                  memberData[ind]?.hraStatusHistory
                                    ?.inProgress[0]?.date,
                                )
                                  .tz(selectedTimeZoneUtc)
                                  .format("MM-DD-YYYY h:mm A")}
                                )
                              </span>
                              <span className="dateTime">
                                {
                                  memberData[ind]?.hraStatusHistory
                                    ?.inProgress[0]?.userName
                                }
                              </span>
                            </>
                          )}
                        </li>
                        <li
                          className={
                            storedHraStatus[ind] == "6" ||
                            storedHraStatus[ind] == "8" ||
                            storedHraStatus[ind] == "7" ||
                            storedHraStatus[ind] == "9" ||
                            storedHraStatus[ind] == "10" ||
                            storedHraStatus[ind] == "11"
                              ? "active"
                              : ""
                          }
                          id="step3"
                        >
                          <span>Ready for QA</span>
                          {!_.isEmpty(
                            memberData[ind]?.hraStatusHistory.readyForQa,
                          ) && (
                            <>
                              <span className="dateTime">
                                (
                                {/* {convertToLocalDateTime(
                                  memberData[ind]?.hraStatusHistory
                                    ?.readyForQa[0]?.date,
                                )} */}
                                {dayjs(
                                  memberData[ind]?.hraStatusHistory
                                    ?.readyForQa[0]?.date,
                                )
                                  .tz(selectedTimeZoneUtc)
                                  .format("MM-DD-YYYY h:mm A")}
                                )
                              </span>
                              <span className="dateTime">
                                {
                                  memberData[ind]?.hraStatusHistory
                                    ?.readyForQa[0]?.userName
                                }
                              </span>
                            </>
                          )}
                        </li>
                        <li
                          className={
                            storedHraStatus[ind] == "8" ||
                            storedHraStatus[ind] == "7" ||
                            storedHraStatus[ind] == "9" ||
                            storedHraStatus[ind] == "10" ||
                            storedHraStatus[ind] == "11"
                              ? "active"
                              : ""
                          }
                          id="step4"
                        >
                          <span>Clinician review QA</span>
                          {!_.isEmpty(
                            memberData[ind]?.hraStatusHistory.clinicianReviewQa,
                          ) && (
                            <>
                              <span className="dateTime">
                                (
                                {/* {convertToLocalDateTime(
                                  memberData[ind]?.hraStatusHistory
                                    ?.clinicianReviewQa[0]?.date,
                                )} */}
                                {dayjs(
                                  memberData[ind]?.hraStatusHistory
                                    ?.clinicianReviewQa[0]?.date,
                                )
                                  .tz(selectedTimeZoneUtc)
                                  .format("MM-DD-YYYY h:mm A")}
                                )
                              </span>
                              <span className="dateTime">
                                {
                                  memberData[ind]?.hraStatusHistory
                                    ?.clinicianReviewQa[0]?.userName
                                }
                              </span>
                            </>
                          )}
                        </li>
                        <li
                          className={
                            storedHraStatus[ind] == "7" ||
                            storedHraStatus[ind] == "9" ||
                            storedHraStatus[ind] == "10" ||
                            storedHraStatus[ind] == "11"
                              ? "active"
                              : ""
                          }
                          id="step5"
                        >
                          <span>Ready for coding</span>
                          {!_.isEmpty(
                            memberData[ind]?.hraStatusHistory.readyForCoder,
                          ) && (
                            <>
                              <span className="dateTime">
                                (
                                {/* {convertToLocalDateTime(
                                  memberData[ind]?.hraStatusHistory
                                    ?.readyForCoder[0]?.date,
                                )} */}
                                {dayjs(
                                  memberData[ind]?.hraStatusHistory
                                    ?.readyForCoder[0]?.date,
                                )
                                  .tz(selectedTimeZoneUtc)
                                  .format("MM-DD-YYYY h:mm A")}
                                )
                              </span>
                              <span className="dateTime">
                                {
                                  memberData[ind]?.hraStatusHistory
                                    ?.readyForCoder[0]?.userName
                                }
                              </span>
                            </>
                          )}
                        </li>
                        <li
                          className={
                            storedHraStatus[ind] == "9" ||
                            storedHraStatus[ind] == "10" ||
                            storedHraStatus[ind] == "11"
                              ? "active"
                              : ""
                          }
                          id="step6"
                        >
                          <span>Clinician review coding</span>
                          {!_.isEmpty(
                            memberData[ind]?.hraStatusHistory
                              .clinicianReviewCoder,
                          ) && (
                            <>
                              <span className="dateTime">
                                (
                                {/* {convertToLocalDateTime(
                                  memberData[ind]?.hraStatusHistory
                                    ?.clinicianReviewCoder[0]?.date,
                                )} */}
                                {dayjs(
                                  memberData[ind]?.hraStatusHistory
                                    ?.clinicianReviewCoder[0]?.date,
                                )
                                  .tz(selectedTimeZoneUtc)
                                  .format("MM-DD-YYYY h:mm A")}
                                )
                              </span>
                              <span className="dateTime">
                                {
                                  memberData[ind]?.hraStatusHistory
                                    ?.clinicianReviewCoder[0]?.userName
                                }
                              </span>
                            </>
                          )}
                        </li>
                        <li
                          className={
                            storedHraStatus[ind] == "10" ||
                            storedHraStatus[ind] == "11"
                              ? "active"
                              : ""
                          }
                          id="step7"
                        >
                          <span>Ready for upload</span>
                          {!_.isEmpty(
                            memberData[ind]?.hraStatusHistory.readyForUpload,
                          ) && (
                            <>
                              <span className="dateTime">
                                (
                                {/* {convertToLocalDateTime(
                                  memberData[ind]?.hraStatusHistory
                                    ?.readyForUpload[0]?.date,
                                )} */}
                                {dayjs(
                                  memberData[ind]?.hraStatusHistory
                                    ?.readyForUpload[0]?.date,
                                )
                                  .tz(selectedTimeZoneUtc)
                                  .format("MM-DD-YYYY h:mm A")}
                                )
                              </span>
                              <span className="dateTime">
                                {
                                  memberData[ind]?.hraStatusHistory
                                    ?.readyForUpload[0]?.userName
                                }
                              </span>
                            </>
                          )}
                        </li>
                        <li
                          className={
                            storedHraStatus[ind] == "11" ? "active" : ""
                          }
                          id="step8"
                        >
                          <span>Completed</span>
                          {!_.isEmpty(
                            memberData[ind]?.hraStatusHistory.completed,
                          ) && (
                            <>
                              <span className="dateTime">
                                (
                                {/* {convertToLocalDateTime(
                                  memberData[ind]?.hraStatusHistory
                                    ?.completed[0]?.date,
                                )} */}
                                {dayjs(
                                  memberData[ind]?.hraStatusHistory
                                    ?.completed[0]?.date,
                                )
                                  .tz(selectedTimeZoneUtc)
                                  .format("MM-DD-YYYY h:mm A")}
                                )
                              </span>
                              <span className="dateTime">
                                {
                                  memberData[ind]?.hraStatusHistory
                                    ?.completed[0]?.userName
                                }
                              </span>
                            </>
                          )}
                        </li>
                      </ul>
                    )}

                  {!_.isUndefined(storedHraStatus[ind]) &&
                    storedHraStatus[ind] == "14" && (
                      <ul id="progressbar" className="full-width">
                        <li className="active" id="step1">
                          <span>Ineligible</span>
                          {!_.isEmpty(
                            memberData[ind]?.hraStatusHistory.inEligible,
                          ) && (
                            <>
                              <span className="dateTime">
                                (
                                {/* {convertToLocalDateTime(
                                  memberData[ind]?.hraStatusHistory
                                    ?.inEligible[0]?.date,
                                )} */}
                                {dayjs(
                                  memberData[ind]?.hraStatusHistory
                                    ?.inEligible[0]?.date,
                                )
                                  .tz(selectedTimeZoneUtc)
                                  .format("MM-DD-YYYY h:mm A")}
                                )
                              </span>
                              <span className="dateTime">
                                {
                                  memberData[ind]?.hraStatusHistory
                                    ?.inEligible[0]?.userName
                                }
                              </span>
                            </>
                          )}
                        </li>
                      </ul>
                    )}

                  {!_.isUndefined(storedHraStatus[ind]) &&
                    storedHraStatus[ind] == "4" && (
                      <ul id="progressbar" className="full-width">
                        <li className="active" id="step1">
                          <span>Patient Refusal</span>
                          {!_.isEmpty(
                            memberData[ind]?.hraStatusHistory.patientRefusal,
                          ) && (
                            <>
                              <span className="dateTime">
                                (
                                {/* {convertToLocalDateTime(
                                  memberData[ind]?.hraStatusHistory
                                    ?.patientRefusal[0]?.date,
                                )} */}
                                {dayjs(
                                  memberData[ind]?.hraStatusHistory
                                    ?.patientRefusal[0]?.date,
                                )
                                  .tz(selectedTimeZoneUtc)
                                  .format("MM-DD-YYYY h:mm A")}
                                )
                              </span>
                              <span className="dateTime">
                                {
                                  memberData[ind]?.hraStatusHistory
                                    ?.patientRefusal[0]?.userName
                                }
                              </span>
                            </>
                          )}
                        </li>
                      </ul>
                    )}

                  {!_.isUndefined(storedHraStatus[ind]) &&
                    storedHraStatus[ind] == "5" && (
                      <ul id="progressbar" className="full-width">
                        <li className="active" id="step1">
                          <span>UTC (Unable to contact)</span>
                          {!_.isEmpty(
                            memberData[ind]?.hraStatusHistory.utc,
                          ) && (
                            <>
                              <span className="dateTime">
                                (
                                {/* {convertToLocalDateTime(
                                  memberData[ind]?.hraStatusHistory?.utc[0]
                                    ?.date,
                                )} */}
                                {dayjs(
                                  memberData[ind]?.hraStatusHistory?.utc[0]
                                    ?.date,
                                )
                                  .tz(selectedTimeZoneUtc)
                                  .format("MM-DD-YYYY h:mm A")}
                                )
                              </span>
                              <span className="dateTime">
                                {
                                  memberData[ind]?.hraStatusHistory?.utc[0]
                                    ?.userName
                                }
                              </span>
                            </>
                          )}
                        </li>
                      </ul>
                    )}

                  {!_.isUndefined(storedHraStatus[ind]) &&
                    storedHraStatus[ind] == "15" && (
                      <ul id="progressbar" className="full-width">
                        <li className="active" id="step1">
                          <span>Deceased</span>
                          {!_.isEmpty(
                            memberData[ind]?.hraStatusHistory?.deceased,
                          ) && (
                            <>
                              <span className="dateTime">
                                (
                                {/* {convertToLocalDateTime(
                                  memberData[ind]?.hraStatusHistory?.deceased[0]
                                    ?.date,
                                )} */}
                                {dayjs(
                                  memberData[ind]?.hraStatusHistory?.deceased[0]
                                    ?.date,
                                )
                                  .tz(selectedTimeZoneUtc)
                                  .format("MM-DD-YYYY h:mm A")}
                                )
                              </span>
                              <span className="dateTime">
                                {
                                  memberData[ind]?.hraStatusHistory?.deceased[0]
                                    ?.userName
                                }
                              </span>
                            </>
                          )}
                        </li>
                      </ul>
                    )}

                  {!_.isUndefined(storedHraStatus[ind]) &&
                    storedHraStatus[ind] == "16" && (
                      <ul id="progressbar" className="full-width">
                        <li className="active" id="step1">
                          <span>Hospice</span>
                          {!_.isEmpty(
                            memberData[ind]?.hraStatusHistory?.hospice,
                          ) && (
                            <>
                              <span className="dateTime">
                                (
                                {/* {convertToLocalDateTime(
                                  memberData[ind]?.hraStatusHistory?.hospice[0]
                                    ?.date,
                                )} */}
                                {dayjs(
                                  memberData[ind]?.hraStatusHistory?.hospice[0]
                                    ?.date,
                                )
                                  .tz(selectedTimeZoneUtc)
                                  .format("MM-DD-YYYY h:mm A")}
                                )
                              </span>
                              <span className="dateTime">
                                {
                                  memberData[ind]?.hraStatusHistory?.hospice[0]
                                    ?.userName
                                }
                              </span>
                            </>
                          )}
                        </li>
                      </ul>
                    )}

                  {!_.isUndefined(storedHraStatus[ind]) &&
                    storedHraStatus[ind] == "17" && (
                      <ul id="progressbar" className="full-width">
                        <li className="active" id="step1">
                          <span>Others</span>
                          {!_.isEmpty(
                            memberData[ind]?.hraStatusHistory?.other,
                          ) && (
                            <>
                              <span className="dateTime">
                                (
                                {/* {convertToLocalDateTime(
                                  memberData[ind]?.hraStatusHistory?.other[0]
                                    ?.date,
                                )} */}
                                {dayjs(
                                  memberData[ind]?.hraStatusHistory?.other[0]
                                    ?.date,
                                )
                                  .tz(selectedTimeZoneUtc)
                                  .format("MM-DD-YYYY h:mm A")}
                                )
                              </span>
                              <span className="dateTime">
                                {
                                  memberData[ind]?.hraStatusHistory?.other[0]
                                    ?.userName
                                }
                              </span>
                            </>
                          )}
                        </li>
                      </ul>
                    )}

                  {!_.isUndefined(storedHraStatus[ind]) &&
                    storedHraStatus[ind] == "18" && (
                      <ul id="progressbar" className="full-width">
                        <li className="active" id="step1">
                          <span>Term Expired</span>
                          {!_.isEmpty(
                            memberData[ind]?.hraStatusHistory?.termExpired,
                          ) && (
                            <>
                              <span className="dateTime">
                                (
                                {/* {convertToLocalDateTime(
                                  memberData[ind]?.hraStatusHistory
                                    ?.termExpired[0]?.date,
                                )} */}
                                {dayjs(
                                  memberData[ind]?.hraStatusHistory
                                    ?.termExpired[0]?.date,
                                )
                                  .tz(selectedTimeZoneUtc)
                                  .format("MM-DD-YYYY h:mm A")}
                                )
                              </span>
                              <span className="dateTime">
                                {
                                  memberData[ind]?.hraStatusHistory
                                    ?.termExpired[0]?.userName
                                }
                              </span>
                            </>
                          )}
                        </li>
                      </ul>
                    )}

                  <div className="scheduler-info">
                    <h4>Scheduler Info:</h4>
                    <table className="table">
                      <thead>
                        <tr>
                          <td>Date / Time</td>
                          <td>Scheduler</td>
                          <td>Result</td>
                          <td>Comments</td>
                          <td>Call Type</td>
                        </tr>
                      </thead>

                      <tbody>
                        {/* {dynamicSchedulerData[ind]
                          .slice(0)
                          .reverse()
                          .map((data, index) => (
                            <tr key={index}>
                              <td>{convertToLocalDateTime(data.date)}</td>
                              <td>{data.scheduler}</td>
                              <td>{data.result}</td>
                              <td>{data.comment}</td>
                              <td>{data.inBound ? "Inbound" : "Outbound"}</td>
                            </tr>
                          ))} */}
                        {dynamicSchedulerData[ind] &&
                          Array.isArray(dynamicSchedulerData[ind]) &&
                          dynamicSchedulerData[ind]
                            .slice(0)
                            .reverse()
                            .map((data, index) => (
                              <tr key={index}>
                                {/* <td>{convertToLocalDateTime(data.date)}</td> */}
                                <td>
                                  {dayjs(data.date)
                                    .tz(selectedTimeZoneUtc)
                                    .format("MM-DD-YYYY h:mm A")}
                                </td>
                                <td>{data.scheduler}</td>
                                <td>{data.result}</td>
                                <td>{data.comment}</td>
                                <td>{data.inBound ? "Inbound" : "Outbound"}</td>
                              </tr>
                            ))}

                        {dynamicSchedulerData[ind]?.length <= 0 && (
                          <tr>
                            <td colSpan="5" align="center">
                              No Data Found...
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>

                  {/* // Referral form */}
                  {!(roleId == "8" || roleId == "9") && (
                    <div className="scheduler-info">
                      <div style={{ display: "flex", gap: "22px" }}>
                        <h4>Referral Details:</h4>
                        <Button
                          type="submit"
                          width="70px"
                          height="28px"
                          cursor="pointer"
                          color="white"
                          background="var(--mainTheme)"
                          fontSize="12px"
                          border="none"
                          borderRadius="100px"
                          fontFamily="Inter,sans-serif"
                          id="memberHistory_Referral_button"
                          onClick={() => {
                            handleReferralFormOpen();
                          }}
                        >
                          Referral
                        </Button>
                      </div>
                      <table className="table">
                        <thead>
                          <tr>
                            <td>Referral Type</td>
                            <td>Date of Referral</td>
                            <td>Submitted By</td>
                            <td>Referral Status</td>
                            <td>Reason for Referral</td>
                            <td>Action</td>
                          </tr>
                        </thead>

                        <tbody>
                          {refRespData?.map((referral, index) => {
                            // console.log(refRespData,"memberData?.[0]?.referralDetails")
                            // Initialize referralReason
                            // Initialize referralReason
                            let referralReason = "";

                            // Check for diseaseCategory first
                            if (referral?.diseaseCategory) {
                              referralReason = Object.keys(
                                referral.diseaseCategory,
                              )
                                .filter(
                                  (key) =>
                                    referral.diseaseCategory[key] === "1",
                                )
                                .join(", ");
                            }
                            // If no diseaseCategory, check for ciReport and notifications in critical
                            else if (
                              referral?.critical?.ciReport ||
                              referral?.critical?.notifications
                            ) {
                              // Get keys with value "1" from ciReport
                              const ciReportReasons = referral?.critical
                                ?.ciReport
                                ? Object.keys(referral?.critical?.ciReport)
                                    .filter(
                                      (key) =>
                                        referral.critical.ciReport[key] === "1",
                                    )
                                    .join(", ")
                                : "";

                              // Get keys with value "1" from notifications
                              const notificationReasons = referral?.critical
                                ?.notifications
                                ? Object.keys(referral?.critical?.notifications)
                                    .filter(
                                      (key) =>
                                        referral.critical.notifications[key] ===
                                        "1",
                                    )
                                    .join(", ")
                                : "";

                              // Combine ciReportReasons and notificationReasons with comma separator if both exist
                              referralReason = [
                                ciReportReasons,
                                notificationReasons,
                              ]
                                .filter(Boolean) // This removes empty strings
                                .join(", ");
                            }
                            return (
                              <tr key={index}>
                                <td>
                                  {referral?.type === "1"
                                    ? "Urgent"
                                    : referral.type === "2"
                                    ? "Non-Urgent"
                                    : referral.type === "3"
                                    ? "Critical Incident Report"
                                    : ""}
                                </td>
                                <td>
                                  {new Date(
                                    referral?.date,
                                  )?.toLocaleDateString()}
                                </td>
                                <td>Submitted By</td>
                                <td>
                                  {referral?.referralStatus === "1"
                                    ? "New"
                                    : referral.referralStatus === "2"
                                    ? "Under Review"
                                    : referral.referralStatus === "3"
                                    ? "Pending Revision"
                                    : referral.referralStatus === "4"
                                    ? "Completed"
                                    : ""}
                                </td>
                                <td>{referralReason}</td>
                                <td>
                                  <Button
                                    type="submit"
                                    width="70px"
                                    height="28px"
                                    cursor="pointer"
                                    color="white"
                                    background="var(--mainTheme)"
                                    fontSize="12px"
                                    border="none"
                                    borderRadius="100px"
                                    fontFamily="Inter,sans-serif"
                                    id="memberHistory_Referral_button"
                                    // onClick={() => {
                                    //   fetchReferalReport(referral.referralId);
                                    // }}
                                  >
                                    View PDF
                                  </Button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </DialogContent>
          <DialogActions></DialogActions>
        </BootstrapDialog>
      )}
      {referralFormPopup && (
        <ReferralForm
          open={handleReferralFormOpen}
          onClose={handleReferralFormClose}
          // initialType={referralType}
          // referralId={referralId}
          // memberNurseId={memberNurseId}
          // referralDate={referralDate}
          // primary={primary}
          // nursePrimary={nursePrimary}
        />
      )}
      {isLoading && <Loader />}
      <Toaster
        open={toasterOpen}
        onClose={onCloseToaster}
        message={toasterMessage}
      />
    </>
  );
}
