import { Grid } from "@mui/material";
import lodash from "lodash";
import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useCallback,
} from "react";

import { DFButton } from "../../DFInputs/DFButton";
import { DFCheckbox } from "../../DFInputs/DFCheckbox";
import { DFDate } from "../../DFInputs/DFDate";
import { DFHeading } from "../../DFInputs/DFHeading";
import { DFImage } from "../../DFInputs/DFImage";
import { DFLabel } from "../../DFInputs/DFLabel";
import { DFOptionsTree } from "../../DFInputs/DFOptionsTree";
import DFRadio from "../../DFInputs/DFRadio";
import { DFSelect } from "../../DFInputs/DFSelect";
import DFText from "../../DFInputs/DFText";
import { DFRuler } from "../../DFInputs/DFRuler";
import { useFillHraContext } from "../../../../../../contexts/fillHraFormContext";
import {
  deleteSubTree,
  getCovidScreeningData,
  getSubTree,
  setCovidScreeningData,
} from "./covidScreeningMapping";
import { useUserContext } from "../../../../../../contexts/UserContext";
import { getInputsData } from "./covidFormData";
import { useLocation } from "react-router-dom";
import { getApi } from "../../../../../../utils/axiosApiWrapper";
import LoadingOverlay from "react-loading-overlay";
import focusCaresLoader from "../../../../../../loader/focuscares_loader.gif";

const calcCommentVisibility = (formData) => {
  let tempData = {};
  Object.keys(formData).forEach((stateVar) => {
    if (stateVar.includes("Comment")) {
      let isValueThere = formData[stateVar] !== "" ? true : false;
      tempData = {
        ...tempData,
        [`${stateVar}_toggle`]: isValueThere,
      };
    }
  });

  return tempData;
};

const CovidScreening = forwardRef((props, _ref) => {
  const location = useLocation();
  const tabId = location?.state?.tabId
    ? location?.state?.tabId
    : sessionStorage.getItem("tabId");
  const roleId = sessionStorage.getItem("roleId");
  const {
    // examReviewFormData,
    // setExamReviewFormData,
    // showComment,
    // setShowComment,
    getSectionInputsData,
    getDisableFlag,
    // getStateValue,
    // setStateValue,
  } = useFillHraContext();
  const {
    storedPlan,
    storedYear,
    sideBarSectionDisable,
    setSideBarSectionDisable,
  } = useUserContext();

  const [covidScreeningFormData, setCovidScreeningFormData] = useState({});
  const [showComment, setShowComment] = useState([]);
  const [disableFlag, setDisableFlag] = useState(false);
  const [loading, setLoading] = useState(undefined);
  const sectionId = props.currSection || 3;
  const clientPlan = localStorage.getItem("clientPlan") || storedPlan;
  const clientYear = localStorage.getItem("clientYear") || storedYear;
  const inputsJsonData = getInputsData(clientPlan, clientYear);

  const assessmentId = location?.state?.assessmentId
    ? location?.state?.assessmentId
    : sessionStorage.getItem("assessmentId");
  useEffect(() => {
    async function getDbData() {
      setLoading(true);
      setSideBarSectionDisable(true);
      // const dbData = await props.getSectionDbData(sectionId);
      const secondApiResponse = await getApi(
        `/hra/get-assessment?id=${assessmentId}`,
      ); //await props.getSectionDbData(sectionId);
      const dbData = secondApiResponse[`data`];
      const mashedData =
        dbData?.data?.covid?.data &&
        Object.keys(dbData?.data?.covid?.data).length > 0
          ? getCovidScreeningData(dbData?.data?.covid?.data, clientYear)
          : {};

      const commentVisibility = calcCommentVisibility(mashedData);
      let mashedCalcData = {
        ...mashedData,
        ...commentVisibility,
      };
      setCovidScreeningFormData(mashedCalcData);
      setLoading(false);
      setSideBarSectionDisable(false);
    }

    getDbData();

    setDisableFlag(getDisableFlag());
  }, []);

  const handleTextChange = (name, value) => {
    const updatedFormData = { ...covidScreeningFormData };
    updatedFormData[name] = value;

    setCovidScreeningFormData(updatedFormData);
  };

  const handleRadioChange = (name, value) => {
    const updatedFormData = { ...covidScreeningFormData };
    updatedFormData[name] = value;

    setCovidScreeningFormData(updatedFormData);
  };

  const handleDateChange = (name, value) => {
    const updatedFormData = { ...covidScreeningFormData };
    updatedFormData[name] = value;

    setCovidScreeningFormData(updatedFormData);
  };

  const handleSelectChange = (name, value) => {
    const updatedFormData = { ...covidScreeningFormData };
    updatedFormData[name] = value;

    setCovidScreeningFormData(updatedFormData);
  };

  useEffect(() => {
    props.setAutoSaveCovidScreeningData({ ...covidScreeningFormData });
  }, [covidScreeningFormData]);

  const handleOptionsTreeChange = (name, value, isMulti) => {
    const updatedFormData = { ...covidScreeningFormData };
    let oldSelection = updatedFormData[name];
    let newSelection = [];
    if (isMulti) {
      if (value.includes("none")) {
        if (oldSelection?.includes("none")) {
          newSelection = value.filter((item) => item !== "none");
        } else {
          newSelection = ["none"];
        }
      } else {
        newSelection = value;
      }
    } else {
      if (!oldSelection?.includes(value)) {
        newSelection = [value];
      } else {
        newSelection = [];
      }
    }
    // if (value?.includes("none") && isMulti === true) {
    //   if (oldSelection?.includes("none")) {
    //     newSelection = value?.filter((item) => item !== "none");
    //   } else {
    //     newSelection = ["none"];
    //   }
    // } else if (isMulti) {
    //   newSelection = value;
    // } else {
    //   if (!oldSelection?.includes(value)) {
    //     newSelection.push(value);
    //   }
    // }
    let deletedValue =
      isMulti === true
        ? lodash.difference(oldSelection, newSelection)?.[0] || ""
        : oldSelection?.[0] || "";

    if (value === "yes" || value === "no") {
      const deleteRegex = `${name}Sub`;

      updatedFormData[name] = value;
      let subTreeDeletedData = deleteSubTree(updatedFormData, deleteRegex);
      setCovidScreeningFormData(subTreeDeletedData);
      return null;
    } else if (deletedValue !== "") {
      updatedFormData[name] = value;
      const deleteRegex = getSubTree(name, deletedValue);

      if (deleteRegex != "") {
        let subTreeDeletedData = deleteSubTree(updatedFormData, deleteRegex);
        setCovidScreeningFormData(subTreeDeletedData);
        return null;
      } else {
        updatedFormData[name] = newSelection;
        setCovidScreeningFormData(updatedFormData);
      }

      return null;
    } else {
      updatedFormData[name] = value;
      setCovidScreeningFormData(updatedFormData);
    }
  };

  // const handleOptionsTreeChange = (name, value, isMulti) => {
  //   /* togglebutton group type -> scenario -> actual behaviour -> our pattern

  //   <- multi ->
  //       pass values -> array -> array
  //       if value selected -> array -> array

  //   <- single ->

  //       pass values -> string -> always pass as array
  //       if value selected -> string -> always change to array and set back to state

  //   */
  //   let updatedFormData = { ...selfAssessFormData };
  //   let oldSelection = updatedFormData[name];
  //   let newSelection = [];
  //   if (isMulti) {
  //     newSelection = value;
  //   } else {
  //     if (!oldSelection?.includes(value)) {
  //       newSelection.push(value);
  //     }
  //   }

  //   /*
  //     This code of new value part is to tackle these scenarios

  //     1. if "none" is selected, then we should unselect the other options if any selected
  //     2. when "none" is already selected, if any other value is selected newly, we should unselect "none"

  //     ----- from here -------
  //   */
  //   let newValue =
  //     isMulti === true
  //       ? lodash.difference(newSelection, oldSelection)?.[0] || ""
  //       : oldSelection?.[0] || "";
  //   if (newValue === "none") {
  //     newSelection = ["none"];
  //   } else if (newSelection.includes("none") && newSelection.length > 1) {
  //     newSelection = newSelection.filter((option) => option !== "none");
  //   }

  //   /* ----- till here ------- */

  //   let deletedValue =
  //     isMulti === true
  //       ? lodash.difference(oldSelection, newSelection)?.[0] || ""
  //       : oldSelection?.[0] || "";

  //   if (deletedValue && deletedValue !== "") {
  //     let subTreeDeletedData = handleDeleteSubTree(
  //       updatedFormData,
  //       name,
  //       deletedValue,
  //     );
  //     subTreeDeletedData[name] = newSelection;
  //     updatedFormData = {
  //       ...subTreeDeletedData,
  //     };
  //   } else {
  //     updatedFormData[name] = newSelection;
  //   }

  //   if (
  //     name === "self_alcUseCurrSubHowManyOneMoreSubHowOften" ||
  //     name === "self_alcUseCurrSubHowManyOneMoreSubHowMany" ||
  //     name === "self_alcUseCurrSubHowManyOneMoreSubSixMore" ||
  //     name === "self_alcUseFormerSubHowManyOneMoreSubHowOften" ||
  //     name === "self_alcUseFormerSubHowManyOneMoreSubHowMany" ||
  //     name === "self_alcUseFormerSubHowManyOneMoreSubSixMore"
  //   ) {
  //     const calcUpdatedFormData = calcAlcAuditScore(updatedFormData);
  //     updatedFormData = {
  //       ...calcUpdatedFormData,
  //     };
  //   }
  //   updatedFormData[name] = newSelection;
  //   setSelfAssessFormData(updatedFormData);
  // };

  const handleButtonClick = (name) => {
    return null;
  };

  const handleCheckboxChange = (name, value, checked) => {
    const updatedFormData = { ...covidScreeningFormData };
    const oldData = updatedFormData?.[name] || [];
    let newData = [];
    if (oldData.length < 1) {
      newData.push(value);
    } else {
      if (checked) {
        newData = [...oldData];
        newData.push(value);
      } else {
        newData = oldData.filter((ele) => ele !== value);
      }
    }

    updatedFormData[name] = newData;
    setCovidScreeningFormData(updatedFormData);
  };

  useImperativeHandle(_ref, () => ({
    getPayloadData: () => {
      return setCovidScreeningData(covidScreeningFormData, clientYear);
    },
  }));

  const getFieldValues = useCallback(
    (name) => {
      return covidScreeningFormData[name];
    },
    [covidScreeningFormData],
  );

  const setFieldValues = useCallback((name, value, type = "") => {
    setCovidScreeningFormData((prevData) => {
      let tempData = { ...prevData };
      if (type === "datatable") {
        tempData = {
          ...tempData,
          ...value,
        };
      }
      tempData = {
        ...tempData,
        [name]: value,
      };

      return { ...tempData };
    });
  }, []);

  return (
    <LoadingOverlay
      styles={{
        // wrapper: { marginLeft: "50px", marginTop: "50px" },
        // overlay: {},
        content: { marginLeft: "40vw", marginTop: "50px" },
        // spinner: { marginLeft: "50px", marginTop: "50px" },
        spinner: (base) => ({
          ...base,
          marginLeft: "50px",
          marginTop: "50px",
        }),
      }}
      style={{ marginTop: "20px" }}
      active={loading}
      spinner={<img src={focusCaresLoader} alt="Loader" />}
      // text="Loading your content..."
    >
      <div
        style={{
          margin: "10px 20px",
        }}
      >
        <Grid container spacing={2}>
          {inputsJsonData?.questions?.map((inputProps) => {
            const { questionId, name, xs, type, isSingleButton } = inputProps;
            const inputValue = covidScreeningFormData[name] || "";
            const commentValue = `${
              covidScreeningFormData?.[`${name}Comment`]
                ? `${covidScreeningFormData[`${name}Comment`]}`
                : ""
            }`;
            const quesSlug = `${sectionId}_${questionId}`;
            if (
              type === "text" ||
              type === "number" ||
              type === "email" ||
              type === "password"
            ) {
              return (
                <Grid
                  key={quesSlug}
                  xs={xs}
                  /* sm={inputProps.sm}*/
                  item
                >
                  <DFText
                    inputProps={inputProps}
                    value={inputValue}
                    handleTextChange={handleTextChange}
                    showComment={showComment}
                    setShowComment={setShowComment}
                    quesSlug={`${questionId}`}
                    sectionId={sectionId}
                    commentValue={commentValue}
                    disableFlag={disableFlag || (roleId == 5 && tabId == 3)}
                    needMemo={true}
                    getFieldValues={getFieldValues}
                    setFieldValues={setFieldValues}
                  />
                </Grid>
              );
            } else if (type === "radio") {
              return (
                <Grid key={quesSlug} xs={xs} item>
                  <DFRadio
                    inputProps={inputProps}
                    value={inputValue}
                    handleRadioChange={handleRadioChange}
                    handleTextChange={handleTextChange} // added for comment box event handling
                    showComment={showComment}
                    setShowComment={setShowComment}
                    quesSlug={`${questionId}`}
                    sectionId={sectionId}
                    commentValue={commentValue}
                    disableFlag={disableFlag || (roleId == 5 && tabId == 3)}
                    needMemo={true}
                    getFieldValues={getFieldValues}
                    setFieldValues={setFieldValues}
                  />
                </Grid>
              );
            } else if (type === "date") {
              const dateValue = covidScreeningFormData[name] || {};
              return (
                <Grid
                  key={quesSlug}
                  xs={xs}
                  /* sm={sm} */
                  item
                >
                  <DFDate
                    inputProps={inputProps}
                    value={dateValue}
                    handleDateChange={handleDateChange}
                    handleTextChange={handleTextChange} // added for comment box event handling
                    showComment={showComment}
                    setShowComment={setShowComment}
                    quesSlug={`${questionId}`}
                    sectionId={sectionId}
                    commentValue={commentValue}
                    disableFlag={disableFlag || (roleId == 5 && tabId == 3)}
                    needMemo={true}
                    getFieldValues={getFieldValues}
                    setFieldValues={setFieldValues}
                    // dtPickProps={{
                    //   disableFuture:true,
                    //   disablePast:true,
                    //   minDate:minDOB,
                    //   maxDate:maxDOB,
                    //   views:["year", "month", "day"]
                    // }}
                  />
                </Grid>
              );
            } else if (type === "select") {
              return (
                <Grid key={quesSlug} xs={xs} item>
                  <DFSelect
                    inputProps={inputProps}
                    value={inputValue}
                    handleSelectChange={handleSelectChange}
                    handleTextChange={handleTextChange} // added for comment box event handling
                    showComment={showComment}
                    setShowComment={setShowComment}
                    quesSlug={`${questionId}`}
                    sectionId={sectionId}
                    commentValue={commentValue}
                    disableFlag={disableFlag || (roleId == 5 && tabId == 3)}
                    needMemo={true}
                    getFieldValues={getFieldValues}
                    setFieldValues={setFieldValues}
                  />
                </Grid>
              );
            } else if (type === "options_tree") {
              return (
                <DFOptionsTree
                  inputProps={inputProps}
                  // formData={covidScreeningFormData}
                  formData={covidScreeningFormData}
                  handleOptionsTreeChange={handleOptionsTreeChange}
                  handleTextChange={handleTextChange}
                  handleRadioChange={handleRadioChange}
                  handleDateChange={handleDateChange}
                  handleSelectChange={handleSelectChange}
                  handleButtonClick={handleButtonClick}
                  handleCheckboxChange={handleCheckboxChange}
                  showComment={showComment}
                  setShowComment={setShowComment}
                  // setFormData={setCovidScreeningFormData}
                  setFormData={setCovidScreeningFormData}
                  quesSlug={`${questionId}`}
                  sectionId={sectionId}
                  // commentValue={commentValue}
                  disableFlag={disableFlag || (roleId == 5 && tabId == 3)}
                  needMemo={true}
                  getFieldValues={getFieldValues}
                  setFieldValues={setFieldValues}
                />
              );
            } else if (type === "label") {
              return (
                <Grid key={quesSlug} xs={xs} item>
                  <DFLabel
                    inputProps={inputProps}
                    handleTextChange={handleTextChange} // added for comment box event handling
                    showComment={showComment}
                    setShowComment={setShowComment}
                    quesSlug={`${questionId}`}
                    sectionId={sectionId}
                    commentValue={commentValue}
                    disableFlag={disableFlag || (roleId == 5 && tabId == 3)}
                    needMemo={true}
                    getFieldValues={getFieldValues}
                    setFieldValues={setFieldValues}
                  />
                </Grid>
              );
            } else if (type === "button") {
              return (
                <Grid key={quesSlug} xs={isSingleButton ? 12 : 0} item>
                  <DFButton
                    inputProps={{
                      ...inputProps,
                      disabled: roleId == 5 && tabId == 3,
                    }}
                    handleButtonClick={handleButtonClick}
                  />
                </Grid>
              );
            } else if (type === "heading") {
              return (
                <Grid key={quesSlug} xs={12} item>
                  <DFHeading inputProps={inputProps} />
                </Grid>
              );
            } else if (type === "checkbox") {
              return (
                <Grid key={quesSlug} xs={xs} item>
                  <DFCheckbox
                    inputProps={inputProps}
                    showComment={showComment}
                    handleCheckboxChange={handleCheckboxChange}
                    value={inputValue}
                    handleTextChange={handleTextChange}
                    setShowComment={setShowComment}
                    quesSlug={`${questionId}`}
                    sectionId={sectionId}
                    commentValue={commentValue}
                    disableFlag={disableFlag || (roleId == 5 && tabId == 3)}
                    needMemo={true}
                    getFieldValues={getFieldValues}
                    setFieldValues={setFieldValues}
                  />
                </Grid>
              );
            } else if (type === "image") {
              return (
                <Grid key={quesSlug} xs={xs} item>
                  <DFImage inputProps={inputProps} />
                </Grid>
              );
            } else if (type === "divider") {
              return <DFRuler />;
            }

            return null;
          })}
        </Grid>
      </div>
    </LoadingOverlay>
  );
});

export default CovidScreening;
