import Image from "../../components/image/image";
import { useEffect, useState } from "react";
import "../OTP/OTPScreen.css";
import BG from "../../assets/focuscares_bg.jpg";
import Logo from "../../assets/focuscares_logo.png";
import Input from "../../components/input/input";
import Button from "../../components/button/button";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Toaster from "../../components/toaster/toaster";
import axios from "axios";
import ValidationMessage from "../../resource_files/ValidationMessage.resx";
import Labels from "../../resource_files/Labels.resx";
import { useSession } from "../../contexts/SessionContext";
import { upperCase } from "lodash";
import { postApi } from "../../utils/axiosApiWrapper";

const OtpScreen = (props) => {
  const navigate = useNavigate();
  const [otp, setOTP] = useState("");
  const [toasterOpen, setToasterOpen] = useState(false);
  const [toasterSeverity, setToasterSeverity] = useState(Labels.SUCCESS);
  const [toasterMessage, setToasterMessage] = useState("");
  const [timer, setTimer] = useState(40); // Initialize timer with 40 seconds
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const location = useLocation();

  const { setOtpSessionExpired } = useSession();
  const { state } = location;
  const { username } = state || {}; // Extract the username from state

  useEffect(() => {
    // If the user is coming from the Forgot Password screen or Login screen, start the timer
    if (
      (location.state && location.state.fromForgotPassword) ||
      (location.state && location.state.fromChangePassword)
    ) {
      startTimer();
    }
  }, []);

  useEffect(() => {
    let interval;
    if (isTimerRunning && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 2000);
    } else if (timer === 0) {
      setIsTimerRunning(false);
    }
    return () => clearInterval(interval);
  }, [isTimerRunning, timer]);

  const startTimer = () => {
    setTimer(process.env.REACT_APP_OTP_TIMER);
    setIsTimerRunning(true);
  };

  useEffect(() => {
    // Check if the OTP screen has been displayed before
    const hasOTPBeenDisplayed = localStorage.getItem("hasOTPBeenDisplayed");

    // If OTP has been displayed before,then remove the flag and navigate
    if (hasOTPBeenDisplayed) {
      localStorage.removeItem("hasOTPBeenDisplayed");
      setOtpSessionExpired(true);
      navigate(`/`);
    } else {
      // Add an event listener for beforeunload to handle page refresh
      const handlePageRefresh = (event) => {
        localStorage.removeItem("messageDisplayed");
        localStorage.setItem("hasOTPBeenDisplayed", "true");
      };
      window.addEventListener("beforeunload", handlePageRefresh);

      // Define the cleanup function to remove the event listener
      return () => {
        window.removeEventListener("beforeunload", handlePageRefresh);
      };
    }
  }, [setOtpSessionExpired, navigate]);

  const submitOTP = async () => {
    try {
      if (sessionStorage.getItem("otpType") === "forgotPassword") {
        const response = await postApi(
          "/users/updatePassword/forgot/validateOTP",
          { email: username, otp: otp },
        );
        if (response.status === 200) {
          setToasterSeverity(Labels.SUCCESS);
          sessionStorage.setItem("OTP", otp);
          if (location.state && location.state.fromLogin) {
            setToasterMessage(ValidationMessage.Login_Success);
            setToasterOpen(true);

            // Navigating from the Login screen, navigate to the dashboard
            const roleName = sessionStorage.getItem("roleName");
            if (roleName.trim() === "") {
              setToasterMessage(ValidationMessage.No_Userrole_Found);
              setToasterOpen(true);
              setTimeout(() => {
                axios
                  .get(`/`)
                  .then(() => {
                    navigate(`/`);
                  })
                  .catch((error) => {
                    return;
                  });
              }, 3000);
            }

            if (upperCase(roleName) === upperCase(Labels.Admin)) {
              setTimeout(() => {
                navigate(`/AdminDashboard`);
              }, 1000);
            } else if (upperCase(roleName) === upperCase(Labels.Scheduler)) {
              setTimeout(() => {
                navigate(`/SchedulerDashboard`);
              }, 1000);
            }
          } else {
            setToasterMessage(ValidationMessage.OTP_Verified);
            setToasterOpen(true);
            navigate("/Account/ResetPassword");
          }
        } else {
          //Invalid otp
          setToasterSeverity(Labels.ERROR);
          if (response?.data?.updateResult === false) {
            setToasterMessage(response.response.data.message);
          } else {
            if (
              response.response.data.message === ValidationMessage.Invalid_OTP
            ) {
              setToasterMessage(response.response.data.message);
            } else if (
              response.response.data.message === ValidationMessage.OTP_Expired
            ) {
              setToasterMessage(response.response.data.message);
            } else {
              setToasterMessage(response.response.data.message);
              setOtpSessionExpired(false); // Clear OTP expiration state
            }
          }
          setToasterOpen(true);
        }
      } else if (sessionStorage.getItem("otpType") === "changePassword") {
        const response = await postApi(
          "/users/updatePassword/forgot/validateOTP",
          { email: username, otp: otp },
        );
        if (response.status === 200) {
          setToasterSeverity(Labels.SUCCESS);
          sessionStorage.setItem("OTP", otp);
          setToasterMessage(response.response.data.message);
          setToasterOpen(true);
          navigate("/Account/ChangePassword");
        } else {
          setToasterSeverity(Labels.ERROR);
          setToasterMessage(response.response.data.message);
          setToasterOpen(true);
        }
      }
    } catch (error) {
      // Error occurred while verifying OTP
      setToasterSeverity(Labels.ERROR);
      setToasterMessage(error.message);
      setToasterOpen(true);
    }
  };

  const resendOTP = async () => {
    try {
      const response = await postApi("/users/updatePassword/forgot/sendOTP", {
        email: username, // Use the entered email as receiver's email
      });
      if (response.status === 200) {
        setToasterSeverity(Labels.SUCCESS);
        setToasterMessage(response.data.message);
        setToasterOpen(true);
        setOTP(""); // Clear the OTP input field
        startTimer(); // Start the timer when OTP is resent
      } else {
        setToasterSeverity(Labels.ERROR);
        setToasterMessage(response.data.message);
        setToasterOpen(true);
      }
    } catch (error) {
      setToasterSeverity(Labels.ERROR);
      setToasterMessage(error.message);
      setToasterOpen(true);
    }
  };

  const onCloseToaster = () => {
    setToasterOpen(false);
    setToasterSeverity(Labels.SUCCESS); // Reset severity for the next toaster
    setToasterMessage("");
  };

  return (
    <div className="login">
      <div className="top-ripple"></div>
      <div className="bottom-ripple"></div>
      <div className="image-container">
        <Image imgClass="bgImg" imgPath={BG} imgName="Focuscare Background" />
      </div>
      <div className="input-container">
        <Image imgClass="logoImg" imgPath={Logo} imageName="Focuscare Logo" />
        <div className="main-container">
          <h2>ONE TIME PASSWORD</h2>
          <p className="otp-graph">
            We have sent the 6-digit passcode to your registered email. Please
            check your email and type in the pass code in the text box below.
          </p>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h3 className="head-align">Enter OTP</h3>
            {/* Display the timer */}
            <h3>{isTimerRunning ? `Time left: ${timer} seconds` : ""}</h3>
          </div>

          <div>
            <Input
              inputtype="iconInput"
              mb="30px"
              value={otp}
              onChangeHandler={(event) => setOTP(event.target.value)}
              type="number"
              inputWidth="120vh"
              width="100%"
              borderRadius="20px"
              height="55px"
            />
          </div>
          <div className="submit-buttons">
            <Button
              type="submit"
              width="100%"
              mb="20px"
              cursor="pointer"
              disabled={!otp}
              height="40px"
              color="white"
              background="#1076BC"
              fontSize="14px"
              border="none"
              borderRadius="100px"
              fontFamily="Inter,sans-serif"
              onClick={submitOTP}
              variant="contained"
            >
              CONTINUE
            </Button>

            <Button
              type="submit"
              onClick={resendOTP}
              width="100%"
              mb="20px"
              height="40px"
              cursor="pointer"
              color="white"
              background="#1076BC"
              fontSize="14px"
              border="none"
              borderRadius="100px"
              fontFamily="Inter,sans-serif"
            >
              RESEND OTP
            </Button>
          </div>
        </div>
      </div>
      <Toaster
        open={toasterOpen}
        onClose={onCloseToaster}
        severity={toasterSeverity}
        message={toasterMessage}
      />
    </div>
  );
};

export default OtpScreen;
