import React, { useState, useEffect } from "react";
import pedsDemographicsJsonData from "./PedsFormSection/peds.demographics.json.data";
import pedsDemographics2024JsonData from "./PedsFormSection/peds.demographics2024.json.data";
import toast from "react-hot-toast";
import { putApi } from "../../../../utils/axiosApiWrapper";
import { useUserContext } from "../../../../contexts/UserContext";
const usePedsForm = ({ assessmentId }) => {
  // const [currSection, setCurrSection] = useState(0);
  const [demographicsFormState, setDemographicsFormState] = useState({});
  const [currentSectionId, setCurrentSectionId] = useState(0);
  const [prevSelectedSection, setPrevSelectedSection] = useState(-1);
  // for assessmentInfo
  const {
    autoSavePedsAssessInfo,
    setAutoSavePedsAssessInfo,
    autoSavepedsallergiesRowData,
    setAutoSavePedsAllergiesRowData,
    autoSavefirstLevelCommentObj,
    setAutoSavePedsFirstLevelCommentObj,
    autoSavecounterMedicationsRowData,
    setAutoSavePedsCounterMedicationsRowData,
    autoSavesecondLevelTextFieldAnswer,
    setAutoSavePedsSecondLevelTextFieldAnswer,
    autoSavecounterMedicationsRowDataQues15,
    setAutoSavePedsCounterMedicationsRowDataQues15,
    autoSavesecondLevelCommentObj,
    setAutoSavePedsSecondLevelCommentObj,
    currSection,
    setCurrSection,
  } = useUserContext();

  const sections = [
    {
      sectionId: 0,
      sectionName: "Child-Demographics",
    },
    { sectionId: 1, sectionName: "Assessment Information" },
    {
      sectionId: 2,
      sectionName: "Patient Summary",
    },
  ];
  const sectionRefs = sections.map(() => React.createRef());
  const handleCardClick = (index) => {
    console.log("currindex", index);
    console.log("currSectioninCardClick", currSection);
    setCurrSection(index);
    setPrevSelectedSection(currSection);

    setCurrentSectionId(index);
    const sectionRef = sectionRefs[index];
    if (sectionRef && sectionRef.current) {
      sectionRef.current.scrollIntoView({
        behavior: "instant",
        block: "start",
        inline: "nearest",
      });
    }
  };
  const getQuestionTypeForDemoSec = (question) => {
    if (
      question == "Marital Status" ||
      question == "What is your current gender identity ?"
    ) {
      return "Select";
    } else if (question == "Date of Birth") {
      return "DateField";
    } else if (question == "Gender at birth") {
      return "Radio";
    } else if (question == "Were updates made to the PCP details?") {
      return "Checkbox";
    } else return "Text";
  };
  const getQuestionOptionForDemoSec = (question) => {
    if (question == "Gender at birth") {
      return ["Female", "Male"];
    } else if (question == "Marital Status") {
      return [
        "Divorced",
        "Married",
        "Registered Partnership",
        "Separated",
        "Single",
        "Widowed",

        // { key: "Single", value: "1" },
        // { key: "Married", value: "2" },
        // { key: "Separated", value: "3" },
        // { key: "Divorced", value: "4" },
        // { key: "Widowed", value: "5" },
        // { key: "Registered Partnership", value: "6" },
      ];
    } else if (question == "What is your current gender identity ?") {
      return ["Female", "Male", "Non binary", "Other", "Transgender"];
    } else return [];
  };
  const storedYear = localStorage.getItem("clientYear");

  const getYearWiseDemographicsJsonData = () => {
    if (storedYear == "2023") {
      return pedsDemographicsJsonData?.[0]?.questions?.fields[0];
    } else if (storedYear == "2024") {
      return pedsDemographics2024JsonData?.[0]?.questions?.fields[0];
    } else {
      return pedsDemographicsJsonData?.[0]?.questions?.fields[0];
    }
  };

  const _demographicsJsonObject = getYearWiseDemographicsJsonData();
  // pedsDemographicsJsonData?.[0]?.questions?.fields[0];
  const demographicsJsonObj = Object.keys(_demographicsJsonObject)?.map(
    (key) => {
      const quesObject = {
        questionName: _demographicsJsonObject[key],
        questionLabel: _demographicsJsonObject[key],
        questionType: getQuestionTypeForDemoSec(_demographicsJsonObject[key]),
        questionField: key,
        questionOption: getQuestionOptionForDemoSec(
          _demographicsJsonObject[key],
        ),
      };
      return quesObject;
    },
  );
  // autosave of assessment information function

  let payload = {};
  const getpayloadByQuestionId = (questionNumber, answerObj) => {
    const firstLevelCommentObj = autoSavefirstLevelCommentObj;
    const secondLevelCommentObj = autoSavesecondLevelCommentObj;
    const secondLevelTextFieldAnswer = autoSavesecondLevelTextFieldAnswer;
    const counterMedicationsRowData = autoSavecounterMedicationsRowData;
    const counterMedicationsRowDataQues15 =
      autoSavecounterMedicationsRowDataQues15;
    const allergiesRowData = autoSavepedsallergiesRowData;

    if (
      questionNumber == 1 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 1)
    ) {
      payload = {
        ...payload,
        legalGuradian: {
          legalGuradianPermission: answerObj?.legalGuradian
            ? answerObj?.legalGuradian
            : "",
          // == "yes"
          //   ? "1"
          //   : answerObj.legalGuradian == "no"
          //   ? "2"
          //   : "",
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 2 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 2)
    ) {
      payload = {
        ...payload,
        legalRepresentative: {
          authorizedLegalRepresentative: answerObj?.legalRepresentative
            ? answerObj?.legalRepresentative
            : "",
          //  == "yes"
          //   ? "1"
          //   : answerObj.legalRepresentative == "no"
          //   ? "2"
          //   : "",
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 3 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 3)
    ) {
      payload = {
        ...payload,
        fosterCare: {
          fosterCareProgram: answerObj?.fosterCare ? answerObj?.fosterCare : "",
          // == "yes"
          //   ? "1"
          //   : answerObj.fosterCare == "no"
          //   ? "2"
          //   : "",
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 4 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 4)
    ) {
      console.log("answerObj4", answerObj);
      payload = {
        ...payload,
        preferredLanguage: {
          english: answerObj?.preferredLanguage == "english" ? "1" : "2", //"Y or N",
          other: answerObj?.preferredLanguage == "other" ? "1" : "2", //"Y or N",
          otherSubAnswer: {
            africanLanguages:
              answerObj?.demograph_prefLangOtherSub == "africanLanguages"
                ? "1"
                : "2",
            arabic:
              answerObj?.demograph_prefLangOtherSub == "arabic" ? "1" : "2",
            chinese:
              answerObj?.demograph_prefLangOtherSub == "chinese" ? "1" : "2",
            french:
              answerObj?.demograph_prefLangOtherSub == "french" ? "1" : "2",
            frenchCreole:
              answerObj?.demograph_prefLangOtherSub == "frenchCreole" //"frenchCrelo"
                ? "1"
                : "2",
            german:
              answerObj?.demograph_prefLangOtherSub == "german" ? "1" : "2",
            greek: answerObj?.demograph_prefLangOtherSub == "greek" ? "1" : "2",
            gujarati:
              answerObj?.demograph_prefLangOtherSub == "gujarati" ? "1" : "2",
            hebrew:
              answerObj?.demograph_prefLangOtherSub == "hebrew" ? "1" : "2",
            hindi: answerObj?.demograph_prefLangOtherSub == "hindi" ? "1" : "2",
            hungarian:
              answerObj?.demograph_prefLangOtherSub == "hungarian" ? "1" : "2",
            italian:
              answerObj?.demograph_prefLangOtherSub == "italian" ? "1" : "2",
            japanese:
              answerObj?.demograph_prefLangOtherSub == "japanese" ? "1" : "2",
            korean:
              answerObj?.demograph_prefLangOtherSub == "korean" ? "1" : "2",
            persian:
              answerObj?.demograph_prefLangOtherSub == "persian" ? "1" : "2",
            polish:
              answerObj?.demograph_prefLangOtherSub == "polish" ? "1" : "2",
            portuguese:
              answerObj?.demograph_prefLangOtherSub == "portuguese" ? "1" : "2",
            russian:
              answerObj?.demograph_prefLangOtherSub == "russian" ? "1" : "2",
            scandinavianLanguages:
              answerObj?.demograph_prefLangOtherSub == "scandinavianLanguages"
                ? "1"
                : "2",
            serboCroatian:
              answerObj?.demograph_prefLangOtherSub == "serboCroatian"
                ? "1"
                : "2",
            spanish:
              answerObj?.demograph_prefLangOtherSub == "spanish" ? "1" : "2",
            comment: "",
          },
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 5 ||
      (firstLevelCommentObj[questionNumber - 1] && questionNumber == 5)
    ) {
      payload = {
        ...payload,
        race: {
          caucasian: answerObj?.race == "caucasian" ? "1" : "2",
          africanAmerican: answerObj?.race == "africanAmerican" ? "1" : "2",
          asian: answerObj?.race == "asian" ? "1" : "2",
          latino: answerObj?.race == "latino" ? "1" : "2",
          nativeAmerican: answerObj?.race == "nativeAmerican" ? "1" : "2",
          nativeHawaiian: answerObj?.race == "nativeHawaiian" ? "1" : "2",
          alaskanNative: answerObj?.race == "alaskanNative" ? "1" : "2",
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 7 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 7)
    ) {
      payload = {
        ...payload,
        childExperienced: {
          childWitnessedEvent: answerObj?.childExperienced
            ? answerObj?.childExperienced
            : "",
          // == "yes"
          //   ? "1"
          //   : answerObj?.childExperienced == "no"
          //   ? "2"
          //   : "",
          childWitnessedEventYesSubAnswer: {
            event: {
              carAccident:
                answerObj?.Whatwastheevent == "carAccident" ? "1" : "2", //"Y or N",
              fire: answerObj?.Whatwastheevent == "fire" ? "1" : "2", //"Y or N",
              storm: answerObj?.Whatwastheevent == "storm" ? "1" : "2", //"Y or N",
              physicalIllness:
                answerObj?.Whatwastheevent == "physicalIllness" ? "1" : "2", //"Y or N",
              sexualAssualt:
                answerObj?.Whatwastheevent == "sexualAssualt" ? "1" : "2", //"Y or N",
              comment: secondLevelCommentObj.Whatwastheevent
                ? secondLevelCommentObj.Whatwastheevent
                : "",
            },
            describe: answerObj?.describe ? answerObj?.describe : "",
          },
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 8 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 8)
    ) {
      payload = {
        ...payload,
        memberAgeEvent: {
          specialAge: answerObj?.memberAgeEvent
            ? answerObj?.memberAgeEvent
            : "",
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 9 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 9)
    ) {
      payload = {
        ...payload,
        childReportComplaints: {
          notTrue: answerObj?.childReportComplaints == "notTrue" ? "1" : "2", // "Y or N",
          somewhatTrue:
            answerObj?.childReportComplaints == "somewhatTrue" ? "1" : "2", //"Y or N",
          veryTrue: answerObj?.childReportComplaints == "veryTrue" ? "1" : "2", //"Y or N",
          oftenTrue:
            answerObj?.childReportComplaints == "oftenTrue" ? "1" : "2", //"Y or N",
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 10 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 10)
    ) {
      payload = {
        ...payload,
        childAvoidsThings: {
          notTrue: answerObj?.childAvoidsThings == "notTrue" ? "1" : "2", // "Y or N",
          somewhatTrue:
            answerObj?.childAvoidsThings == "somewhatTrue" ? "1" : "2", //"Y or N",
          veryTrue: answerObj?.childAvoidsThings == "veryTrue" ? "1" : "2", //"Y or N",
          oftenTrue: answerObj?.childAvoidsThings == "oftenTrue" ? "1" : "2", //"Y or N",
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 11 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 11)
    ) {
      payload = {
        ...payload,
        childStartlesEasily: {
          notTrue: answerObj?.childStartlesEasily == "notTrue" ? "1" : "2", // "Y or N",
          somewhatTrue:
            answerObj?.childStartlesEasily == "somewhatTrue" ? "1" : "2", //"Y or N",
          veryTrue: answerObj?.childStartlesEasily == "veryTrue" ? "1" : "2", //"Y or N",
          oftenTrue: answerObj?.childStartlesEasily == "oftenTrue" ? "1" : "2", //"Y or N",
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 12 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 12)
    ) {
      payload = {
        ...payload,
        childGetsUpset: {
          notTrue: answerObj?.childGetsUpset == "notTrue" ? "1" : "2", // "Y or N",
          somewhatTrue: answerObj?.childGetsUpset == "somewhatTrue" ? "1" : "2", //"Y or N",
          veryTrue: answerObj?.childGetsUpset == "veryTrue" ? "1" : "2", //"Y or N",
          oftenTrue: answerObj?.childGetsUpset == "oftenTrue" ? "1" : "2", //"Y or N",
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 13 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 13)
    ) {
      payload = {
        ...payload,
        childMedicine: {
          childMedicinePrescribed: answerObj?.childMedicine
            ? answerObj?.childMedicine
            : "", //== "yes" ? "1" : "2",
          childMedicinePrescribedYesSubAnswer: {
            healthCondition: answerObj?.any_medical_behavioral_health_condition
              ? answerObj?.any_medical_behavioral_health_condition
              : "",
            atleastTwelveMonths: {
              expectedAtleastTwelveMonths: answerObj?.condition_that_has_lasted
                ? answerObj?.condition_that_has_lasted
                : "", //== "yes" ? "1" : "2", //"Y or N",
              comment:
                secondLevelCommentObj.medicine_prescribed_condition_that_has_lasted
                  ? secondLevelCommentObj.medicine_prescribed_condition_that_has_lasted
                  : "",
            },
            explain: answerObj?.Explain ? answerObj?.Explain : "",
          },
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 15 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 15)
    ) {
      payload = {
        ...payload,
        counterMedications: {
          supplements: answerObj?.counterMedications
            ? answerObj?.counterMedications
            : "",
          // == "yes"
          //   ? "Y"
          //   : answerObj?.counterMedications == "no"
          //   ? "N"
          //   : "", //"Y or N",
          supplementsYesSubAnswer: counterMedicationsRowDataQues15?.map(
            (row) => {
              return {
                prescription: row?.description || "",
                doseUnits: row?.doseOrUnits || "",
                route: row?.route || "",
                reason: row?.reasons || "",
                status: row?.status || "",
                frequency: row?.frequency || "",
                // comment: "",
              };
            },
          ),
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 16 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 16)
    ) {
      console.log("answerObj16", answerObj);
      payload = {
        ...payload,
        childNeedMedicalCare: {
          childMedicalCare: answerObj?.childNeedMedicalCare
            ? answerObj?.childNeedMedicalCare
            : "", //== "yes" ? "Y" : "N",
          childMedicalCareYesSubAnswer: {
            healthCondition: answerObj?.any_medical_behavioral_health_condition
              ? answerObj?.any_medical_behavioral_health_condition
              : "",
            atleastTwelveMonths: {
              expectedAtleastTwelveMonths: answerObj?.condition_that_has_lasted
                ? answerObj?.condition_that_has_lasted
                : "", //== "yes" ? "Y" : "N", //"Y or N",
              comment:
                secondLevelCommentObj.medical_care_condition_that_has_lasted
                  ? secondLevelCommentObj.medical_care_condition_that_has_lasted
                  : "",
            },
            explain: answerObj?.Explain ? answerObj?.Explain : "",
          },
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 17 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 17)
    ) {
      payload = {
        ...payload,
        childAbility: {
          childAbilityLimited: answerObj?.childAbility
            ? answerObj?.childAbility
            : "", // == "yes" ? "Y" : "N",
          childAbilityLimitedYesSubAnswer: {
            healthCondition: answerObj?.any_medical_behavioral_health_condition
              ? answerObj?.any_medical_behavioral_health_condition
              : "",
            atleastTwelveMonths: {
              expectedAtleastTwelveMonths: answerObj?.condition_that_has_lasted
                ? answerObj?.condition_that_has_lasted
                : "", // == "yes" ? "Y" : "N", //"Y or N",
              comment:
                secondLevelCommentObj.childAbility_condition_that_has_lasted
                  ? secondLevelCommentObj.childAbility_condition_that_has_lasted
                  : "",
            },
            explain: answerObj?.Explain ? answerObj?.Explain : "",
          },
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 18 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 18)
    ) {
      payload = {
        ...payload,
        childNeed: {
          childNeedTherapy: answerObj?.childNeed ? answerObj?.childNeed : "", //== "yes" ? "Y" : "N",
          childNeedTherapyYesSubAnswer: {
            healthCondition: answerObj?.any_medical_behavioral_health_condition
              ? answerObj?.any_medical_behavioral_health_condition
              : "",
            atleastTwelveMonths: {
              expectedAtleastTwelveMonths: answerObj?.condition_that_has_lasted
                ? answerObj?.condition_that_has_lasted
                : "", // == "yes" ? "Y" : "N", //"Y or N",
              comment: secondLevelCommentObj.childNeed_condition_that_has_lasted
                ? secondLevelCommentObj.childNeed_condition_that_has_lasted
                : "",
            },
          },
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 19 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 19)
    ) {
      payload = {
        ...payload,
        childDevelopment: {
          childDevelopmentCounseling: answerObj?.childDevelopment
            ? answerObj?.childDevelopment
            : "", //== "yes" ? "Y" : "N",
          childDevelopmentCounselingYesSubAnswer: {
            healthCondition: answerObj?.any_medical_behavioral_health_condition
              ? answerObj?.any_medical_behavioral_health_condition
              : "",
          },
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 20 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 20)
    ) {
      payload = {
        ...payload,
        childReceiveSupport: {
          nursingCare:
            answerObj?.childReceiveSupport == "nursingCare" ? "1" : "2",
          personalCareAttendant:
            answerObj?.childReceiveSupport == "personalCareAttendant"
              ? "1"
              : "2",
          homeHealthAide:
            answerObj?.childReceiveSupport == "homeHealthAide" ? "1" : "2",
          no: answerObj?.childReceiveSupport == "no" ? "1" : "2",
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 21 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 21)
    ) {
      payload = {
        ...payload,
        childMedicalCheckup: {
          childMedicalCheckup: answerObj?.childMedicalCheckup
            ? answerObj?.childMedicalCheckup
            : "",
          //  == "Y"
          //   ? "1"
          //   : answerObj.childMedicalCheckup == "N"
          //   ? "2"
          //   : answerObj.childMedicalCheckup == "DoesnotKnow"
          //   ? "3"
          //   : "", //  "Y or N or DoesnotKnow",
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 22 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 22)
    ) {
      payload = {
        ...payload,
        childsHeightWeight: {
          childsHeightWeight: answerObj?.childsHeightWeight
            ? answerObj?.childsHeightWeight
            : "",
          // == "yes"
          //   ? "Y"
          //   : answerObj?.childsHeightWeight == "no"
          //   ? "N"
          //   : "", //or N",
          childsHeightWeightYesSubAnswer: {
            childsHeight: {
              feet:
                answerObj?.childsHeightWeight == "1"
                  ? answerObj?.childsHeightfeet
                    ? answerObj?.childsHeightfeet
                    : ""
                  : "", //"",
              inch:
                answerObj?.childsHeightWeight == "1"
                  ? answerObj?.childsHeightInches
                    ? answerObj?.childsHeightInches
                    : ""
                  : "",
            },
            childsWeight:
              answerObj?.childsHeightWeight == "1"
                ? answerObj?.childsWeight
                  ? answerObj?.childsWeight
                  : ""
                : "",
            calculateBmi:
              answerObj?.childsHeightWeight == "1"
                ? answerObj?.calculateBmi
                  ? answerObj?.calculateBmi
                  : ""
                : "",
          },
        },
      };
    } else if (
      questionNumber == 23 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 23)
    ) {
      payload = {
        ...payload,
        describeChildsWeight: {
          childsWeight: answerObj?.describeChildsWeight
            ? answerObj?.describeChildsWeight
            : "",
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 24 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 24)
    ) {
      payload = {
        ...payload,
        femaleChildren: {
          yes: answerObj?.femaleChildren == "yes" ? "1" : "2", // "Y or N",
          no: answerObj?.femaleChildren == "no" ? "1" : "2", //"Y or N",
          doesnotKnow: answerObj?.femaleChildren == "doesnotKnow" ? "1" : "2", //"Y or N","N/A"
          na: answerObj?.femaleChildren == "na" ? "1" : "2", //"Y or N",
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 25 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 25)
    ) {
      payload = {
        ...payload,
        enoughFoodForFamily: {
          never: answerObj?.enoughFoodForFamily == "never" ? "1" : "2", //"Y or N",
          sometimes: answerObj?.enoughFoodForFamily == "sometimes" ? "1" : "2", //"Y or N",
          always: answerObj?.enoughFoodForFamily == "always" ? "1" : "2", //"Y or N",
          declineToAnswer:
            answerObj?.enoughFoodForFamily == "declineToAnswer" ? "1" : "2",
          // na: answerObj?.enoughFoodForFamily == "na" ? "1" : "2", //"Y or N",
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 26 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 26)
    ) {
      payload = {
        ...payload,
        communityResources: {
          communityResourcesAvail: answerObj?.communityResources
            ? answerObj?.communityResources
            : "",
          // == "yes"
          //   ? "Y"
          //   : answerObj?.communityResources == "no"
          //   ? "N"
          //   : "", //"Y or N",
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      questionNumber == 27 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 27)
    ) {
      console.log("answerObj27", answerObj);
      payload = {
        ...payload,
        childHaveAnyConditions: {
          asthma: answerObj?.childHaveAnyConditions?.includes("asthma")
            ? "1"
            : "2", //Y or N",
          asthmaSubAnswer: {
            childSeeASpecialist: answerObj?.childSeeASpecialist
              ? answerObj?.childSeeASpecialist
              : "",
            // == "yes"
            //   ? "1"
            //   : answerObj?.childSeeASpecialist == "no"
            //   ? "2"
            //   : "", //"Y or N",
            childSeeASpecialistYesSubAnswer: {
              yesName: secondLevelTextFieldAnswer["ifYesName"]
                ? secondLevelTextFieldAnswer["ifYesName"]
                : "", //"",
            },
            comment: secondLevelCommentObj.childSeeASpecialist
              ? secondLevelCommentObj.childSeeASpecialist
              : "", //"",
          },
          diabetes: answerObj?.childHaveAnyConditions?.includes("diabetes")
            ? "1"
            : "2", //"Y or N",
          diabetesSubAnswer: {
            childsBloodSugar: answerObj?.childsBloodSugar
              ? answerObj?.childsBloodSugar
              : "",
            //  == "yes"
            //   ? "1"
            //   : answerObj?.childSeeASpecialist == "no"
            //   ? "2"
            //   : "",
            comment: secondLevelCommentObj?.childsBloodSugar
              ? secondLevelCommentObj?.childsBloodSugar
              : "",
          },
          sickleCellDisease: answerObj?.childHaveAnyConditions?.includes(
            "sickleCellDisease",
          )
            ? "1"
            : "2", //"Y or N",
          sickleCellDiseaseSubAnswer: {
            hgbAonec: answerObj?.hgbAonec ? answerObj?.hgbAonec : "",
            //  == "yes"
            //   ? "1"
            //   : answerObj?.hgbAonec == "no"
            //   ? "2"
            //   : "", //"Y or N",
            hgbAonecYesSubAnswer: {
              lastHgbAonec: secondLevelTextFieldAnswer["ifYesHgbA1c"]
                ? secondLevelTextFieldAnswer["ifYesHgbA1c"]
                : "", //"",
            },
            comment: secondLevelCommentObj?.hgbAonec
              ? secondLevelCommentObj?.hgbAonec
              : "",
          },
          hemophilia: answerObj?.childHaveAnyConditions?.includes("hemophilia")
            ? "1"
            : "2", //"Y or N",
          hemophiliaSubAnswer: {
            specialDiet: answerObj?.specialDiet ? answerObj?.specialDiet : "",
            specialDietYesSubAnswer: {
              yesDiet: secondLevelTextFieldAnswer["ifYesDiet"]
                ? secondLevelTextFieldAnswer["ifYesDiet"]
                : "", //"",,
            },
            comment: secondLevelCommentObj?.specialDiet
              ? secondLevelCommentObj?.specialDiet
              : "",
          },
          ddAdhd: answerObj?.childHaveAnyConditions?.includes("ddAdhd")
            ? "1"
            : "2", //"Y or N",
          substanceUse: answerObj?.childHaveAnyConditions?.includes(
            "substanceUse",
          )
            ? "1"
            : "2", //"Y or N",
        },
      };
    } else if (
      questionNumber == 28 ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 28)
    ) {
      payload = {
        ...payload,
        additionalInformation: {
          additionalInformationChild: answerObj?.additionalInformation
            ? answerObj?.additionalInformation
            : "", //"Y or N",
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    } else if (
      storedYear == "2024" &&
      (questionNumber == 29 ||
        (firstLevelCommentObj[questionNumber] && questionNumber == 29))
    ) {
      payload = {
        ...payload,
        patientReligiousBelief: {
          isPatientReligiousBelief: answerObj?.patientReligiousBelief
            ? answerObj?.patientReligiousBelief
            : "", //: answerObj?.childNeed ? answerObj?.childNeed : "", //== "yes" ? "Y" : "N",
          isPatientReligiousBeliefSubAnswer: {
            describe: answerObj?.spiritual_beliefs
              ? answerObj?.spiritual_beliefs
              : "",
          },
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    }
    if (
      // counterMedicationsRowData?.length >= 1 ||
      questionNumber == 14 &&
      (counterMedicationsRowData?.length >= 1 ||
        (firstLevelCommentObj[questionNumber] && questionNumber == 14))
    ) {
      console.log(
        "firstLevelCommentObj[questionNumber]",
        firstLevelCommentObj[questionNumber],
      );
      payload = {
        ...payload,
        medication: {
          prescriptionMedicationList: counterMedicationsRowData?.map((row) => {
            return {
              prescription: row?.description || "",
              doseUnits: row?.doseOrUnits || "",
              route: row?.route || "",
              reason: row?.reasons || "",
              status: row?.status || "",
              frequency: row?.frequency || "",
              comment: firstLevelCommentObj[questionNumber]
                ? firstLevelCommentObj[questionNumber]
                : "",
            };
          }),
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    }
    if (
      (allergiesRowData?.length >= 1 && questionNumber == 6) ||
      (firstLevelCommentObj[questionNumber] && questionNumber == 6)
    ) {
      const childAllergiesYesSubAnswer = allergiesRowData?.map((allergies) => {
        const obj = {
          substance: allergies?.substance ? allergies?.substance : "",
          reaction: allergies?.reaction ? allergies?.reaction : "",
        };
        return obj;
      });
      payload = {
        ...payload,
        allergies: {
          childAllergies: answerObj?.allergies ? answerObj?.allergies : "", //"1",
          childAllergiesYesSubAnswer:
            answerObj?.allergies == "1" ? childAllergiesYesSubAnswer : [],
          comment: firstLevelCommentObj[questionNumber]
            ? firstLevelCommentObj[questionNumber]
            : "",
        },
      };
    }
  };
  const onSaveAssesmentInfoData = async (answeredJson) => {
    //Object.keys(answeredJson).forEach((key) => {
    console.log("answeredJson", answeredJson);
    [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
      22, 23, 24, 25, 26, 27, 28, 29,
    ].forEach((key) => {
      getpayloadByQuestionId(Number(key), answeredJson[key]);
    });
    try {
      const _payload = {
        id: assessmentId, //"658421885b5cf5633f5bbece", //assessmentId,
        data: {
          name: "Assessment Information",
          data: payload, //assessmentInfoPayloadObj,
        },
        year: storedYear,
      };
      const response = await putApi("/hra/assessment", _payload);

      if (response.status === 200) {
        toast.success("Assessment Updated Successfully", {});
      } else {
        toast.error("Error saving data", {});
      }
    } catch (error) {}
  };
  const onSavepedsDemographicsData = async (demographicsFormState) => {
    const payload = {
      plan: demographicsFormState?.plan ? demographicsFormState?.plan : "",
      program: demographicsFormState?.program
        ? demographicsFormState?.program
        : "",
      lob: demographicsFormState?.lob ? demographicsFormState?.lob : "",
      region: demographicsFormState?.region
        ? demographicsFormState?.region
        : "",
      aligned: demographicsFormState?.aligned
        ? demographicsFormState?.aligned
        : "",
      name: demographicsFormState?.name ? demographicsFormState?.name : "",
      gender: demographicsFormState?.gender
        ? demographicsFormState?.gender
        : "",
      addressOne: demographicsFormState?.addressOne
        ? demographicsFormState?.addressOne
        : "",
      addressTwo: demographicsFormState?.addressTwo
        ? demographicsFormState?.addressTwo
        : "",
      addressThree: demographicsFormState?.addressThree
        ? demographicsFormState?.addressThree
        : "",
      city: demographicsFormState?.city ? demographicsFormState?.city : "",
      state: demographicsFormState?.state ? demographicsFormState?.state : "",
      zip: demographicsFormState?.zip ? demographicsFormState?.zip : "",
      mAddressOne: demographicsFormState?.mAddressOne
        ? demographicsFormState?.mAddressOne
        : "",
      mAddressTwo: demographicsFormState?.mAddressTwo
        ? demographicsFormState?.mAddressTwo
        : "",
      mAddressThree: demographicsFormState?.mAddressThree
        ? demographicsFormState?.mAddressThree
        : "",
      mCity: demographicsFormState?.mCity ? demographicsFormState?.mCity : "",
      mState: demographicsFormState?.mState
        ? demographicsFormState?.mState
        : "",
      mZip: demographicsFormState?.mZip ? demographicsFormState?.mZip : "",
      dob: demographicsFormState?.dob ? demographicsFormState?.dob : "",
      age: demographicsFormState?.age ? demographicsFormState?.age : "",
      maritalStatus: demographicsFormState?.maritalStatus
        ? demographicsFormState?.maritalStatus
        : "",
      memberIdentificationNo: demographicsFormState?.memberIdentificationNo
        ? demographicsFormState?.memberIdentificationNo
        : "",

      hicn: demographicsFormState?.hicn ? demographicsFormState?.hicn : "",
      email: demographicsFormState?.email ? demographicsFormState?.email : "",
      phoneNumber: demographicsFormState?.phoneNumber
        ? demographicsFormState?.phoneNumber
        : "",
      cellNumber: demographicsFormState?.cellNumber
        ? demographicsFormState?.cellNumber
        : "",
      alternateContactNumber: demographicsFormState?.alternateContactNumber
        ? demographicsFormState?.alternateContactNumber
        : "",
      emergencyContact: demographicsFormState?.emergencyContact
        ? demographicsFormState?.emergencyContact
        : "",
      ePhoneNumber: demographicsFormState?.ePhoneNumber
        ? demographicsFormState?.ePhoneNumber
        : "",
      primaryCarePhysician: demographicsFormState?.primaryCarePhysician
        ? demographicsFormState?.primaryCarePhysician
        : "",
      pPhoneNumber: demographicsFormState?.pPhoneNumber
        ? demographicsFormState?.pPhoneNumber
        : "",
      guardianName: demographicsFormState?.guardianName
        ? demographicsFormState?.guardianName
        : "",
      relationshipToChild: demographicsFormState?.relationshipToChild
        ? demographicsFormState?.relationshipToChild
        : "",
      gPhoneNumber: demographicsFormState?.gPhoneNumber
        ? demographicsFormState?.gPhoneNumber
        : "",
      gCellNumber: demographicsFormState?.gCellNumber
        ? demographicsFormState?.gCellNumber
        : "",
      gEmail: demographicsFormState?.gEmail
        ? demographicsFormState?.gEmail
        : "",
      pcpAddress: demographicsFormState?.pcpAddress
        ? demographicsFormState?.pcpAddress
        : "",
      pcpCity: demographicsFormState?.pcpCity
        ? demographicsFormState?.pcpCity
        : "",
      pcpState: demographicsFormState?.pcpState
        ? demographicsFormState?.pcpState
        : "",
      pcpZip: demographicsFormState?.pcpZip
        ? demographicsFormState?.pcpZip
        : "",
      pcpCountry: demographicsFormState?.pcpCountry
        ? demographicsFormState?.pcpCountry
        : "",
      faxNumber: demographicsFormState?.faxNumber
        ? demographicsFormState?.faxNumber
        : "",
      pcpNpi: demographicsFormState?.pcpNpi
        ? demographicsFormState?.pcpNpi
        : "",
      officeId: demographicsFormState?.officeId
        ? demographicsFormState?.officeId
        : "",
      officeName: demographicsFormState?.officeName
        ? demographicsFormState?.officeName
        : "",
    };
    let payload2024 = {};
    if (storedYear == "2024") {
      payload2024 = {
        ...payload,

        currentGender: {
          gender: demographicsFormState?.currentGenderIdentity
            ? demographicsFormState?.currentGenderIdentity
            : "", //"",
          comment: demographicsFormState?.currentGenderComment
            ? demographicsFormState?.currentGenderComment
            : "",
        }, //physicianComment
        uniqueIdentificationNo: demographicsFormState?.uniqueIdentificationNo
          ? demographicsFormState?.uniqueIdentificationNo
          : "",
        physicianComment: demographicsFormState?.physicianComment
          ? demographicsFormState?.physicianComment
          : "",
        medicaidId: demographicsFormState?.medicaidId
          ? demographicsFormState?.medicaidId
          : "",
        medicareId: demographicsFormState?.medicareId
          ? demographicsFormState?.medicareId
          : "",
        pFlag: demographicsFormState?.pFlag ? demographicsFormState?.pFlag : "",
      };
    }

    try {
      const _payload = {
        id: assessmentId, //"658421885b5cf5633f5bbece", //assessmentId,officeName
        data: {
          name: "Child Demographics",
          //data: { ...demographicsFormState },
          data: storedYear == "2024" ? { ...payload2024 } : { ...payload },
          //  data: { ...demographicsFormState, maritalStatus: "1", gender: "1" }, //payload, //assessmentInfoPayloadObj,
        },
        year: storedYear,
      };
      const response = await putApi("/hra/assessment", _payload);

      if (response.status === 200) {
        toast.success("Assessment Updated Successfully", {});
      } else {
        toast.error("Please Enter Valid Data", {});
      }
    } catch (error) {}
  };

  const onSavePatientSummaryData = async (autoSavePedsPatientSumm) => {
    try {
      const {
        evaluationDate,
        examFinishDate,
        memberAcknowledge,
        assessorComents,
        disclosureStatement,
        visitType,
        consentToVideo,
        driverLicense,
        comment,
        providerSignature,
      } = autoSavePedsPatientSumm;
      const _payload = {
        assessorsComments: assessorComents ? assessorComents : "",
        memberAcknowledgement: memberAcknowledge == "Y" ? "1" : "2",
        disclosure: disclosureStatement == "Y" ? "1" : "2",
        evaluationDate: evaluationDate,
        examFinishDate: examFinishDate,
        providerSignature: providerSignature ? providerSignature : "",
        visitType: {
          isVisitType: visitType == 1 ? "1" : visitType == 2 ? "2" : "",
          consentedToVideo: consentToVideo == "Y" ? "1" : "2",
        },
      };
      let payload2024 = {};
      if (storedYear == "2024") {
        delete _payload.disclosure;
        delete _payload.visitType;
        payload2024 = {
          ..._payload,
          visitType: {
            //driverLicense
            isVisitType: visitType == 1 ? "1" : visitType == 2 ? "2" : "",
            memberConsented: consentToVideo == "Y" ? "1" : "2",
            memberIdentified: driverLicense == "Y" ? "1" : "2",
            comment: comment ? comment : "",
          },
          providerSignature: providerSignature ? providerSignature : "",
          addAddendum: "",
          addendumSignature: "",
        };
      }

      let payload = {
        id: assessmentId, //"658421885b5cf5633f5bbece", //assessmentId,
        data: {
          name: "Patient Summary",
          // data: {
          // ..._payload,
          data: storedYear == "2024" ? { ...payload2024 } : { ..._payload },
          // },
          // providerSignature: providerSignature ? providerSignature : "",
          // addAddendum: "",
          // addendumSignature: "",
        },
        year: storedYear,
      };
      // if (tabId == 1) {
      //   updateHraStatusToInProgress();
      // }
      const response = await putApi("/hra/assessment", payload);
      if (response.status === 200) {
        toast.success("Assessment Updated Successfully", {});
        // navigate(getReroute());
        //const roleId = sessionStorage.getItem("roleId");

        // else {
        //   navigate("/AdminDashboard/MemberList");
        // }
      } else {
        return;
      }
    } catch (error) {
      console.log("error", error);
      return;
    }
  };

  return {
    sections,
    sectionRefs,
    handleCardClick,
    demographicsFormState,
    setDemographicsFormState,
    demographicsJsonObj,
    currSection,
    setCurrSection,
    currentSectionId,
    setCurrentSectionId,
    storedYear,
    prevSelectedSection,
    setPrevSelectedSection,
    onSaveAssesmentInfoData,
    onSavepedsDemographicsData,
    onSavePatientSummaryData,
  };
};

export default usePedsForm;
