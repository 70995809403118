import { FormControl, Grid, Stack, InputLabel } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import PropTypes from "prop-types";
import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import lodash from "lodash";
import { Box, Button } from "@mui/material";

import {
  DFCommentIcon,
  DFCommentIconMemo,
  DFCommentTextBox,
  DFCommentTextBoxMemo,
  isCommentVisible,
  isCommentVisibleMemo,
} from "./DFComment";

import "./DynamicForms.css";
import dayjs from "dayjs";

export const DFDate = ({
  inputProps,
  value,
  handleDateChange,
  handleTextChange,
  showComment,
  setShowComment,
  quesSlug,
  sectionId,
  commentValue,
  disableFlag,
  needMemo,
  getFieldValues,
  setFieldValues,
}) => {
  const { isCommentEnabled, name, label, variant, disableFutureFlag } =
    inputProps;
  const dateValue = value && value !== "" ? dayjs(value) : {};

  const CustomActionButtons = ({ onClear, onCancel }) => {
    return (
      <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2, p: 2 }}>
        <Button onClick={onClear} variant="text">
          Clear
        </Button>
        <Button onClick={onCancel} variant="text">
          Cancel
        </Button>
      </Box>
    );
  };

  return (
    <>
      <Stack direction="row">
        <Grid item xs={isCommentEnabled ? 11 : 12}>
          <InputLabel
            sx={{
              color: "var(--mainTheme)",
              fontWeight: "600",
            }}
          >
            {label || ""}
          </InputLabel>
          <FormControl fullWidth>
            {(() => {
              if (variant === "1") {
                return (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      fullWidth
                      // {...(disableFlag===true && { disabled: true })}
                      name={name}
                      value={dateValue}
                      sx={{
                        border: "1.5px solid var(--mainTheme) !important",
                        "& .MuiFormLabel-root": {
                          backgroundColor: "white",
                        },
                        "& .MuiInputLabel-root": {
                          backgroundColor: "white",
                        },
                        borderRadius: "5px",
                      }}
                      onChange={(newValue) => handleDateChange(name, newValue)}
                      {...(disableFlag && { disabled: true })}
                      {...(disableFutureFlag && { disableFuture: true })}
                      views={["year", "month", "day"]}
                      slots={{
                        actionBar: (props) => (
                          <CustomActionButtons
                            {...props}
                            onClear={() => handleDateChange(name, null)}
                          />
                        ),
                      }}
                      slotProps={{
                        textField: {
                          readOnly: true,
                          InputLabelProps: { shrink: true },
                        },
                      }}
                    />
                  </LocalizationProvider>
                );
              }
            })()}
          </FormControl>
        </Grid>
        {inputProps.isCommentEnabled === true &&
          (needMemo ? (
            <DFCommentIconMemo
              getFieldValues={getFieldValues}
              setFieldValues={setFieldValues}
              name={name}
              value={commentValue}
              disableFlag={disableFlag}
            />
          ) : (
            <DFCommentIcon
              showComment={showComment}
              setShowComment={setShowComment}
              sectionId={sectionId}
              quesSlug={quesSlug}
              value={commentValue}
              disableFlag={disableFlag}
            />
          ))}
      </Stack>
      {isCommentEnabled &&
        (needMemo
          ? isCommentVisibleMemo(getFieldValues, name)
          : isCommentVisible(
              showComment,
              sectionId,
              quesSlug,
              commentValue,
            )) && (
          <DFCommentTextBox
            label={label}
            handleTextChange={handleTextChange}
            name={name}
            value={commentValue}
            disableFlag={disableFlag}
          />
        )}
    </>
  );
};

DFDate.propTypes = {
  inputProps: PropTypes.object.isRequired,
  // formData: PropTypes.object.isRequired,
  value: PropTypes.object.isRequired,
  handleDateChange: PropTypes.func.isRequired,
  handleTextChange: PropTypes.func.isRequired,
  quesSlug: PropTypes.string.isRequired,
  sectionId: PropTypes.number.isRequired,
  showComment: PropTypes.array.isRequired,
  setShowComment: PropTypes.func.isRequired,
  // setFormData: PropTypes.func.isRequired,
  disableFlag: PropTypes.bool,
  commentValue: PropTypes.string,
};

const dontReRender = (oldProps, newProps) => {
  /*
   
  returns false - if there is a change between oldProps & newProps
  returns true - if there is no change between oldProps & newProps

  console.log(
    JSON.stringify(oldProps),
    JSON.stringify(newProps),
    lodash.isEqual(JSON.stringify(oldProps), JSON.stringify(newProps)),
    "check memo"
  );

  */

  return lodash.isEqual(JSON.stringify(oldProps), JSON.stringify(newProps));
};

export const DFDateMemo = React.memo(
  ({
    inputProps,
    value,
    commentValue,
    handleDateChange,
    handleTextChange,
    getFieldValues,
    setFieldValues,
    disableFlag,
  }) => {
    const { isCommentEnabled, name, label, variant, disableFutureFlag } =
      inputProps;
    const dateValue = value && value !== "" ? dayjs(value) : {};

    return (
      <>
        <Stack direction="row">
          <Grid item xs={isCommentEnabled ? 11 : 12}>
            <InputLabel
              sx={{
                color: "var(--mainTheme)",
                fontWeight: "600",
              }}
            >
              {label || ""}
            </InputLabel>
            <FormControl fullWidth>
              {(() => {
                if (variant === "1") {
                  return (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        fullWidth
                        {...(disableFutureFlag && { disableFuture: true })}
                        name={name}
                        value={dateValue}
                        sx={{
                          border: "1.5px solid var(--mainTheme) !important",
                          "& .MuiFormLabel-root": {
                            backgroundColor: "white",
                          },
                          "& .MuiInputLabel-root": {
                            backgroundColor: "white",
                          },
                          borderRadius: "5px",
                        }}
                        onChange={(newValue) =>
                          handleDateChange(name, newValue)
                        }
                        {...(disableFlag && { disabled: true })}
                        views={["year", "month", "day"]}
                        slots={{
                          actionBar: (props) => (
                            <CustomActionButtons
                              {...props}
                              onClear={() => handleDateChange(name, null)}
                            />
                          ),
                        }}
                        slotProps={{
                          textField: {
                            readOnly: true,
                            InputLabelProps: { shrink: true },
                          },
                        }}
                      />
                    </LocalizationProvider>
                  );
                }
              })()}
            </FormControl>
          </Grid>
          {inputProps.isCommentEnabled === true && (
            <DFCommentIconMemo
              // showComment={showComment}
              // setShowComment={setShowComment}
              // sectionId={sectionId}
              // quesSlug={quesSlug}
              name={name}
              value={commentValue}
              getFieldValues={getFieldValues}
              setFieldValues={setFieldValues}
              disableFlag={disableFlag}
            />
          )}
        </Stack>
        {isCommentEnabled && isCommentVisibleMemo(getFieldValues, name) && (
          <DFCommentTextBoxMemo
            label={label}
            handleTextChange={handleTextChange}
            name={name}
            value={commentValue}
            disableFlag={disableFlag}
          />
        )}
      </>
    );
  },
  dontReRender,
);

DFDateMemo.propTypes = {
  inputProps: PropTypes.object.isRequired,
  value: PropTypes.object.isRequired,
  commentValue: PropTypes.string,
  handleDateChange: PropTypes.func.isRequired,
  handleTextChange: PropTypes.func.isRequired,
  getFieldValues: PropTypes.func.isRequired,
  setFieldValues: PropTypes.func.isRequired,
  disableFlag: PropTypes.bool,
};
