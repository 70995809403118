import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import React from "react";
import { Grid, InputAdornment } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import PropTypes from "prop-types";
import { TextareaAutosize } from "@mui/material";
import lodash from "lodash";

import "./DynamicForms.css";

export const DFCommentAdornment = (
  isCommentEnabled,
  quesSlug,
  sectionId,
  showComment,
  setShowComment,
) => {
  return isCommentEnabled
    ? {
        endAdornment: (
          <InputAdornment position="end">
            <ChatBubbleIcon
              style={{
                cursor: "pointer",
                color: "var(--mainTheme)",
                float: "right",
              }}
              onClick={() => {
                let cmntIdxVal = `${sectionId}_${quesSlug}`;

                if (showComment[cmntIdxVal] !== undefined) {
                  setShowComment({
                    ...showComment,
                    [cmntIdxVal]: !showComment[cmntIdxVal],
                  });
                } else {
                  setShowComment({
                    ...showComment,
                    [cmntIdxVal]: true,
                  });
                }
              }}
            />
          </InputAdornment>
        ),
      }
    : {};
};

export const toggleCommentBox = (
  showComment,
  setShowComment,
  sectionId,
  quesSlug,
  value,
) => {
  let cmntIdxVal = `${sectionId}_${quesSlug}`;
  let newFlag;
  if (showComment[cmntIdxVal] === undefined) {
    if (value && value !== "") {
      newFlag = false;
    } else {
      newFlag = true;
    }
  } else {
    newFlag = !showComment[cmntIdxVal];
  }

  setShowComment({
    ...showComment,
    [cmntIdxVal]: newFlag,
  });
};

export const isCommentVisible = (
  showComment,
  // setShowComment,
  sectionId,
  quesSlug,
  value,
) => {
  let toggleSlug = `${sectionId}_${quesSlug}`;
  let computedVisibility = value && value !== "" ? true : false;
  if (showComment[toggleSlug] !== undefined) {
    computedVisibility = showComment[toggleSlug];
  }

  return computedVisibility;
};

export const DFCommentIcon = ({
  showComment,
  setShowComment,
  sectionId,
  quesSlug,
  value,
  disableFlag,
}) => {
  return (
    <Grid
      item
      xs={1}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "end",
      }}
    >
      <ChatBubbleIcon
        style={{
          cursor: "pointer",
          color: "var(--mainTheme)",
        }}
        onClick={() => {
          if (disableFlag) {
            return null;
          }
          toggleCommentBox(
            showComment,
            setShowComment,
            sectionId,
            quesSlug,
            value,
          );
        }}
      />
    </Grid>
  );
};

DFCommentIcon.propTypes = {
  quesSlug: PropTypes.string.isRequired,
  sectionId: PropTypes.number.isRequired,
  showComment: PropTypes.array.isRequired,
  setShowComment: PropTypes.func.isRequired,
};

export const DFCommentTextBox = ({
  label,
  handleTextChange,
  name,
  value,
  disableFlag,
  style,
  routedFrom,
}) => {
  const inputPropsCalc = {
    readOnly: disableFlag,
  };

  let commentLabel = "";
  if (label === "35. Allergies") {
    commentLabel = "Comments - Allergies";
  } else if (label === "36. Over the Counter Medications / Supplements") {
    commentLabel = "Comments - Over the Counter Medications / Supplements";
  } else {
    commentLabel = "Comments" + (label ? " - " + label : "");
  }

  const needTopMargin = [
    "vital_physicalActivityLevel",
    "vital_nutritionWeightManagementInterest",
    "home_improveLifeQuality",
    "home_improveLifeQualitySelect",
    "ros_sitToStand",
    "ros_extremeMove",
    "self_last12MonthsSubDesc",
    "self_tobUseSubCurrSubTypeCigaretteSubInterestSmoke",
    "self_currLiveSituation",
  ];

  return (
    <Grid
      item
      xs={11}
      style={needTopMargin?.includes(name) ? { marginTop: "20px" } : {}}
    >
      <span style={{ display: "flex" }}>
        <p style={{ marginRight: "10px" }}>Comments: </p>
        <TextareaAutosize
          label={commentLabel}
          disabled={disableFlag}
          // placeholder={commentPlaceholder}

          onChange={(event) =>
            handleTextChange(`${name}Comment`, event.target.value)
          }
          style={{
            width: "100%",

            height: "55px",

            borderRadius: "5px",

            padding: "10px",

            overflow: "scroll",

            // textAlign: "center",

            alignContent: "center",

            alignItems: "center",

            border: "1.5px solid var(--mainTheme)",
          }}
          className="textAreainputStyle"
          //inputProps={{ ...inputPropsCalc }}

          inputProps={{
            ...inputPropsCalc,
            min: 0,

            style: {
              alignContent: "center",

              alignItems: "center",

              border: "1.5px solid var(--mainTheme)",
            },
          }}
          // multiline

          // // rows={2}

          // maxRows={4}

          fullWidth
          value={value || ""}
          variant="outlined"
          sx={{
            ...style,

            "& .MuiFormLabel-root": {
              color: "var(--mainTheme)",
            },

            border: "1.5px solid var(--mainTheme)", // Apply border color here

            borderRadius: "5px",

            "& .MuiFormLabel-root.Mui-focused": {
              color: "var(--mainTheme)",
            },

            marginTop: "20px",

            marginLeft: "10px",

            "&:focus-visible": {
              outline: "none",

              borderColor: "var(--mainTheme)",
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <ArrowForwardIcon
                  sx={{
                    size: "medium",

                    color: "var(--mainTheme)",
                  }}
                />
              </InputAdornment>
            ),
          }}
        />
      </span>
    </Grid>
  );
};

DFCommentTextBox.propTypes = {
  label: PropTypes.string,
  handleTextChange: PropTypes.func,
  name: PropTypes.string,
  value: PropTypes.string,
  disableFlag: PropTypes.bool,
};

const dontReRender = (oldProps, newProps) => {
  /*
   
  returns false - if there is a change between oldProps & newProps
  returns true - if there is no change between oldProps & newProps

  console.log(
    JSON.stringify(oldProps),
    JSON.stringify(newProps),
    lodash.isEqual(JSON.stringify(oldProps), JSON.stringify(newProps)),
    "check memo"
  );

  */

  return lodash.isEqual(JSON.stringify(oldProps), JSON.stringify(newProps));
};

export const toggleCommentBoxMemo = (getFieldValues, setFieldValues, name) => {
  let toggleSlug = `${name}Comment_toggle`;
  let newFlag = !getFieldValues(toggleSlug);

  setFieldValues(toggleSlug, newFlag);
};

export const isCommentVisibleMemo = (getFieldValues, name) => {
  let toggleSlug = `${name}Comment_toggle`;
  let toggleValue = getFieldValues(toggleSlug);

  return toggleValue;
};

export const DFCommentIconMemo = React.memo(
  ({ getFieldValues, setFieldValues, name, value, disableFlag }) => {
    return (
      <Grid
        item
        xs={1}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
        }}
      >
        <ChatBubbleIcon
          style={{
            cursor: "pointer",
            color: "var(--mainTheme)",
          }}
          onClick={() => {
            if (disableFlag) {
              return null;
            }
            toggleCommentBoxMemo(getFieldValues, setFieldValues, name);
          }}
        />
      </Grid>
    );
  },
  // dontReRender,
);

DFCommentIconMemo.propTypes = {
  quesSlug: PropTypes.string.isRequired,
  sectionId: PropTypes.number.isRequired,
  showComment: PropTypes.array.isRequired,
  setShowComment: PropTypes.func.isRequired,
};

export const DFCommentTextBoxMemo = React.memo(
  ({ label, handleTextChange, name, value, disableFlag, style }) => {
    const inputPropsCalc = {
      readOnly: disableFlag,
    };

    let commentLabel = "";
    if (label === "35. Allergies") {
      commentLabel = "Comments - Allergies";
    } else if (label === "36. Over the Counter Medications / Supplements") {
      commentLabel = "Comments - Over the Counter Medications / Supplements";
    } else {
      commentLabel = "Comments" + (label ? " - " + label : "");
    }

    return (
      <Grid item xs={11}>
        <span style={{ display: "flex" }}>
          <p style={{ marginRight: "10px" }}>Comments: </p>
          <TextareaAutosize
            label={commentLabel}
            onChange={(event) =>
              handleTextChange(`${name}Comment`, event.target.value)
            }
            style={{
              width: "100%",
              height: "55px",
              borderRadius: "5px",
              padding: "10px",
              overflow: "scroll",
              alignContent: "center",
              alignItems: "center",
              border: "1.5px solid var(--mainTheme)",
            }}
            className="textAreainputStyle"
            inputProps={{
              ...inputPropsCalc,
              min: 0,
              style: {
                alignContent: "center",
                alignItems: "center",
                border: "1.5px solid var(--mainTheme)",
              },
            }}
            fullWidth
            value={value || ""}
            variant="outlined"
            sx={{
              ...style,
              "& .MuiFormLabel-root": {
                color: "var(--mainTheme)",
              },
              border: "1.5px solid var(--mainTheme)",
              borderRadius: "5px",
              "& .MuiFormLabel-root.Mui-focused": {
                color: "var(--mainTheme)",
              },
              marginTop: "20px",
              marginLeft: "10px",
              "&:focus-visible": {
                outline: "none",
                borderColor: "var(--mainTheme)",
              },
            }}
            multiline
            maxRows={4}
            // InputProps={{
            //   startAdornment: (
            //     <InputAdornment position="start">
            //       <ArrowForwardIcon
            //         sx={{
            //           size: "medium",
            //           color: "var(--mainTheme)",
            //         }}
            //       />
            //     </InputAdornment>
            //   ),
            // }}
          />
        </span>
      </Grid>
    );
  },
  dontReRender,
);

DFCommentTextBoxMemo.propTypes = {
  label: PropTypes.string,
  handleTextChange: PropTypes.func,
  name: PropTypes.string,
  value: PropTypes.string,
  disableFlag: PropTypes.bool,
};
