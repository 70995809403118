import MuiInput from "../../../muiinput/MuiInput";
import Button from "@mui/material/Button";
import { useState, useEffect, useRef } from "react";
import { zcs } from "zcs";
import Alert from "../../../Alert/Alert";
import Grid from "@mui/material/Grid";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import "../AddClinician/AddClinician.css";
import { Card, CardHeader, IconButton } from "@mui/material";
import Toaster from "../../../toaster/toaster";
import Labels from "../../../../resource_files/Labels.resx";
import ValidationMessage from "../../../../resource_files/ValidationMessage.resx";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardControlKeyIcon from "@mui/icons-material/KeyboardControlKey";
import { getApi, putApi } from "../../../../utils/axiosApiWrapper";
import { format } from "date-fns";
import {
  languageOptions,
  stateNames,
} from "../../../Dashboard/ReactDND/userCategories";
import { getStateName } from "../../../../utils/getUSStateName";
import { capitalize } from "lodash";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Box from "@mui/material/Box";

const ZCS = zcs();
const initialClinicianData = {
  userName: "",
  password: "",
  firstName: "",
  lastName: "",
  speciality: "",
  dateOfBirth: "",
  gender: "",
  address: "",
  city: "",
  state: "",
  zipCode: "",
  county: "",
  clientName: "",
  phoneNumber: "",
  homeNumber: "",
  email: "",
  npi: "",
  licenseNumber: "",
  validStates: [],
  licenseExpiryDate: "",
  pedApproved: "",
  evaluation: null,
  evaluationReason: "",
  emerContactName: "",
  emerContactRelation: "",
  emerContactAddress: "",
  emerContactPhone: "",
  timeZone: [],
  notes: "",
  zipCodeExclusion: "",
  payRates: "",
  // lastUpdated: "",
  monthlyAvailability: "",
  maxMiles: "",
  planProgramId: "",
  languages: [],
  licenseType: [],
  clientsSupported: [],
  prefContactMethod: [],
  status: 1,
};

const EditClinician = ({
  onClose,
  fetchClinicianData,
  index,
  selectedClinicianData,
  editMode,
}) => {
  const [isShowDemographic, setIsShowDemographic] = useState(false);
  const [isShowLicense, setIsShowLicense] = useState(false);
  const [isShowAvailability, setIsShowAvailability] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [dateOfBirthError, setDateOfBirthError] = useState("");
  const [expiryDateError, setExpiryDateError] = useState("");
  const toggleDemographicSection = () => {
    setIsShowDemographic(!isShowDemographic);
  };

  const toggleLicenseSection = () => {
    setIsShowLicense(!isShowLicense);
  };

  const toggleAvailabilitySection = () => {
    setIsShowAvailability(!isShowAvailability);
  };
  const [clinicianData, setClinicianData] = useState(initialClinicianData);
  const [toasterMessage, setToasterMessage] = useState("");
  const [toasterSeverity, setToasterSeverity] = useState(Labels.SUCCESS);
  const [zipCodeExclusionError, setZipCodeExclusionError] = useState("");
  const [, setZipCodeExclusion] = useState("");
  const [toasterOpen, setToasterOpen] = useState(false);

  const [emailError, setEmailError] = useState("");
  const [nameError, setNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [countyError, setCountyError] = useState("");
  const [cityError, setCityError] = useState("");
  const [stateError, setStateError] = useState("");
  const [userClients, setUserClients] = useState([]);
  const [emerContactNameError, setEmerContactNameError] = useState("");
  const [emerContactRelationError, setEmerContactRelationError] = useState("");
  const [npiError, setNpiError] = useState("");
  const [zipCodeError, setZipCodeError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [homeNumberError, setHomeNumberError] = useState("");
  const [inputFieldsDisabled, setInputFieldsDisabled] = useState(false);

  const [emerContactPhoneError, setemerContactPhoneError] = useState("");

  const [, setApiData] = useState([]);
  const [planData, setPlanData] = useState([]);
  const [selectedPlans, setSelectedPlans] = useState([]);
  const [selectedClientId] = useState([]);
  const [selectedProgramData] = useState(null);
  const [selectedPrograms, setSelectedPrograms] = useState([]);
  const [, setSelectedPlanProgramsFlat] = useState([]);
  const [isActivateAlertOpen, setIsActivateAlertOpen] = useState(false);
  const [selectedClientPrograms, setSelectedClientPrograms] = useState([]);
  const [timeZoneOptions, setTimeZoneOptions] = useState([]);
  const [, setErrors] = useState({});

  const ValidationError = ({ error }) => {
    return error ? (
      <h3
        className="password"
        style={{
          color: "#d32f2f",
          lineHeight: "14px",
          width: "400px",
          marginTop: "-21px",
        }}
      >
        {error}
      </h3>
    ) : null;
  };

  const sortByFirstAndSecondLetter = (a, b) => {
    const firstLetterA = a[0] ? a[0].toLowerCase() : "";
    const firstLetterB = b[0] ? b[0].toLowerCase() : "";

    if (firstLetterA !== firstLetterB) {
      return firstLetterA.localeCompare(firstLetterB);
    }

    const secondLetterA = a[1] ? a[1].toLowerCase() : "";
    const secondLetterB = b[1] ? b[1].toLowerCase() : "";

    return secondLetterA.localeCompare(secondLetterB);
  };

  const additionalConditions = [
    !nameError,
    !passwordError,
    !zipCodeExclusionError,
    !emailError,
    !nameError,
    !lastNameError,
    !countyError,
    !cityError,
    !stateError,
    !emerContactNameError,
    !emerContactRelationError,
    !zipCodeError,
    !phoneNumberError,
    !homeNumberError,
    !npiError,
  ];
  const getLocationInfoByZip = (zipCode) => {
    const parsedZipCode = parseInt(zipCode, 10);
    const locationInfo = ZCS.getByZip(parsedZipCode);
    return locationInfo;
  };

  const dialogBtn = {
    width: "155px",
    height: "36px",
    letterSpacing: "0px",
    flexShrink: "0",
    borderRadius: "20px",
    background: "var(--mainTheme)",
    marginLeft: "10px",
    color: "white",
    "&:hover": {
      boxShadow: 8,
      color: "var(--mainTheme)",
    },
  };

  const excludedKeys = [
    "planProgramId",
    "status",
    "evaluation",
    "evaluationReason",
    "password",
  ];

  useEffect(() => {
    // Fetch time zone options using Axios
    const timezoneHandler = async () => {
      try {
        await getApi(`/settings/getTimezones`, {}).then((response) => {
          setTimeZoneOptions(response?.data?.data);
        });
      } catch (error) {}
    };

    timezoneHandler();
  }, []);

  const abbrOptions = timeZoneOptions.map((option) => option.abbr);

  const timeZones = {
    options: abbrOptions,
  };

  const isFormValid = Object.keys(clinicianData)
    .filter((key) => !excludedKeys.includes(key))
    .every((key) => clinicianData[key] !== "");

  useEffect(() => {
    if (clinicianData.zipCode) {
      try {
        if (
          !/^(0|\d+)$/.test(clinicianData.zipCode) &&
          !/^\d{5}$/.test(clinicianData.zipCode)
        ) {
          setZipCodeError(ValidationMessage.Zipcode_Format);

          return;
        }
        const locationInfo = getLocationInfoByZip(clinicianData.zipCode);
        if (locationInfo?.city && locationInfo?.state) {
          const stateFullName = getStateName(locationInfo?.state);
          setClinicianData((prevClinicianData) => ({
            ...prevClinicianData,
            city: locationInfo.city,
            state: stateFullName.toUpperCase(),
          }));
          setZipCodeError("");
        } else {
          setZipCodeError("Invalid zip code");
        }
      } catch (error) {
        if (
          !/^(0|[1-9][0-9]*)$/.test(clinicianData.zipCode) &&
          !/^\d{5}$/.test(clinicianData.zipCode)
        ) {
          setZipCodeError(ValidationMessage.Zipcode_Format);
        } else {
          setZipCodeError("Error fetching location information");
        }
      }
    }
  }, [clinicianData.zipCode]);

  useEffect(() => {
    const isFNPSelected = clinicianData.licenseType.includes("FNP");

    setClinicianData((prevClinicianData) => ({
      ...prevClinicianData,
      pedApproved: isFNPSelected ? "Yes" : "No",
    }));
  }, [clinicianData.licenseType]);

  useEffect(() => {
    if (clinicianData.firstName && clinicianData.lastName) {
      const userName = `${clinicianData.firstName}${capitalize(
        clinicianData.lastName,
      )}`;

      setClinicianData((prevData) => ({
        ...prevData,
        userName: userName,
      }));
    }
  }, [clinicianData.firstName, clinicianData.lastName]);

  const selectedProgramsFormatted = selectedPrograms
    ? selectedPrograms
        .filter((program) => program && program.clientId && program.programId)
        .map(({ clientId, programId }) => `${clientId}_${programId}`)
        .join(",")
    : "";

  useEffect(() => {
    if (selectedProgramsFormatted && planData) {
      const formattedArray = selectedProgramsFormatted.split(",");
      const result = formattedArray.map((selected) => {
        const [clientId, programId] = selected.split("_");
        const matchedPlan = planData.find(
          (item) => item.clientId === clientId && item.programId === programId,
        );
        return matchedPlan ? matchedPlan.plan : null;
      });

      const unique = [...new Set(result)];

      unique.sort();

      setClinicianData((prevData) => ({
        ...prevData,
        clientName: unique.toString(),
      }));
    }
  }, [selectedProgramsFormatted, planData]);

  useEffect(() => {
    // Fetch time zone options using Axios
    const timezoneHandler = async () => {
      try {
        await getApi(`/settings/getTimezones`, {}).then((response) => {
          setTimeZoneOptions(response?.data?.data);
        });
      } catch (error) {}
    };

    timezoneHandler();
  }, []);

  const formattedData = selectedClinicianData?.userClients?.flatMap(
    (userClient) => {
      return userClient?.programs?.map((programId) => ({
        clientId: userClient.plan,
        programId: programId,
      }));
    },
  );

  useEffect(() => {
    const fetchData = () => {
      setSelectedPrograms(formattedData || []);
    };

    fetchData();
  }, []);

  let prevSelectedPrograms = [];

  const handleProgramClick = (clientId, programId) => {
    const clientProgramId = `${clientId}_${programId}`;

    setSelectedPrograms((prevSelectedPrograms) => {
      if (!Array.isArray(prevSelectedPrograms)) {
        prevSelectedPrograms = [];
      }

      const programIndex = prevSelectedPrograms.findIndex(
        (program) =>
          program.clientId === clientId && program.programId === programId,
      );

      if (programIndex !== -1) {
        const newSelectedPrograms = [...prevSelectedPrograms];
        newSelectedPrograms.splice(programIndex, 1);
        return newSelectedPrograms;
      } else {
        return [...prevSelectedPrograms, { clientId, programId }];
      }
    });

    setSelectedClientPrograms((prevSelectedClientPrograms) => {
      if (!Array.isArray(prevSelectedClientPrograms)) {
        prevSelectedClientPrograms = [];
      }

      if (
        prevSelectedPrograms.length > 0 &&
        prevSelectedPrograms.some(
          (program) =>
            program.clientId === clientId && program.programId === programId,
        )
      ) {
        return prevSelectedClientPrograms.filter(
          (cp) => cp !== clientProgramId,
        );
      } else {
        return [...prevSelectedClientPrograms, clientProgramId];
      }
    });
  };

  const handlePlanClick = (plan) => {
    const programsInSelectedPlan = planData
      .filter((item) => item.plan === plan)
      .map((programItem) => ({
        clientId: programItem.clientId,
        programId: programItem.programId,
      }));

    const isAllProgramsSelected = programsInSelectedPlan.every((program) =>
      selectedPrograms.some(
        (selectedProgram) =>
          selectedProgram.clientId === program.clientId &&
          selectedProgram.programId === program.programId,
      ),
    );

    setSelectedPrograms((prevSelectedPrograms) => {
      if (isAllProgramsSelected) {
        return prevSelectedPrograms.filter(
          (program) =>
            !programsInSelectedPlan.some(
              (programInPlan) =>
                programInPlan.clientId === program.clientId &&
                programInPlan.programId === program.programId,
            ),
        );
      } else {
        return [
          ...prevSelectedPrograms,
          ...programsInSelectedPlan.filter(
            (programInPlan) =>
              !prevSelectedPrograms.some(
                (program) =>
                  program.clientId === programInPlan.clientId &&
                  program.programId === programInPlan.programId,
              ),
          ),
        ];
      }
    });

    setSelectedPlans((prevSelectedPlans) => {
      if (prevSelectedPlans.includes(plan)) {
        return prevSelectedPlans.filter((p) => p !== plan);
      } else {
        return [...prevSelectedPlans, plan];
      }
    });

    setSelectedClientPrograms((prevSelectedClientPrograms) => {
      if (isAllProgramsSelected) {
        return prevSelectedClientPrograms.filter(
          (cp) =>
            !programsInSelectedPlan.some(
              (program) => cp === `${program.clientId}_${program.programId}`,
            ),
        );
      } else {
        return [
          ...prevSelectedClientPrograms,
          ...programsInSelectedPlan.map(
            (program) => `${program.clientId}_${program.programId}`,
          ),
        ];
      }
    });
  };

  const fetchClientData = async () => {
    try {
      const response = await getApi("/clients/list");
      const apiData = response.data.data;
      setApiData(apiData);

      if (apiData && apiData.length > 0) {
        const clientProgramData = {};

        apiData.forEach((client) => {
          client.program.forEach((program) => {
            const clientProgramKey = `${client._id}_${program.id}`;
            if (!clientProgramData[clientProgramKey]) {
              clientProgramData[clientProgramKey] = {
                programId: program.id.toString(),
                program: program.name,
                clientId: client._id,
                plan: client.plan,
              };
            }
          });
        });

        setPlanData(Object.values(clientProgramData));

        const programArray = Object.values(clientProgramData);
        programArray.forEach((programs) => {
          const { clientId, programId, program, plan } = programs;
        });
      }
    } catch (error) {
      return;
    }
  };

  const uniquePlans = [...new Set(planData.map((item) => item.plan))];
  const uniquePrograms = [...new Set(planData.map((item) => item.program))];

  const headingStyle = {
    padding: "10px",
    backgroundColor: "var(--mainTheme)",
    textAlign: "center",
    color: "white",
    marginBottom: "auto",
  };

  useEffect(() => {
    fetchClientData();
  }, []);

  useEffect(() => {
    if (
      editMode &&
      selectedClinicianData &&
      selectedClinicianData.languages &&
      selectedClinicianData.licenseType &&
      selectedClinicianData.clientsSupported &&
      selectedClinicianData.prefContactMethod
    ) {
      const userClientsData = selectedClinicianData.userClients.map(
        (client) => ({
          plan: client.plan,
          programs: client.programs?.map(String),
        }),
      );
      let formattedBirthDate = "";
      let formattedLastUpdated = "";
      let formattedLicenseExpiryDate = "";

      // Parse the ISO date string directly
      const selectedClinicianDataBirthDate = new Date(
        selectedClinicianData.dateOfBirth,
      );
      const selectedClinicianDataLastUpdated = new Date(
        selectedClinicianData.lastUpdated,
      );
      const selectedClinicianDataLicenseExpiryDate = new Date(
        selectedClinicianData.licenseExpiryDate,
      );

      if (!isNaN(selectedClinicianDataBirthDate)) {
        // Proceed with formatting the date
        formattedBirthDate = format(
          selectedClinicianDataBirthDate,
          "yyyy-MM-dd",
        );
      }

      if (!isNaN(selectedClinicianDataLastUpdated)) {
        // Proceed with formatting the lastUpdated date
        formattedLastUpdated = format(
          selectedClinicianDataLastUpdated,
          "yyyy-MM-dd",
        );
      }

      if (!isNaN(selectedClinicianDataLicenseExpiryDate)) {
        // Proceed with formatting the licenseExpiryDate
        formattedLicenseExpiryDate = format(
          selectedClinicianDataLicenseExpiryDate,
          "yyyy-MM-dd",
        );
      }

      // Prefill the form with selected client data
      setClinicianData({
        userName: selectedClinicianData.userName || "",
        password: "",
        firstName: selectedClinicianData.firstName || "",
        lastName: selectedClinicianData.lastName || "",
        speciality: selectedClinicianData.speciality || "",
        dateOfBirth: formattedBirthDate,
        gender: selectedClinicianData.gender || "",
        address: selectedClinicianData.address || "",
        clientName: selectedClinicianData.clientName || "",
        city: selectedClinicianData.city || "",
        state: selectedClinicianData.state || "",
        zipCode: selectedClinicianData.zipCode || "",
        county: selectedClinicianData.county || "",
        phoneNumber: selectedClinicianData.phoneNumber || "",
        homeNumber: selectedClinicianData.homeNumber || "",
        email: selectedClinicianData.email || "",
        npi: selectedClinicianData.npi || "",
        licenseNumber: selectedClinicianData.licenseNumber || "",
        validStates: selectedClinicianData.validStates || "",
        licenseExpiryDate: formattedLicenseExpiryDate,
        pedApproved: selectedClinicianData.pedApproved || "",
        status: selectedClinicianData.status,
        evaluation: selectedClinicianData.evaluation || "",
        evaluationReason: selectedClinicianData.evaluationReason || "",
        emerContactName: selectedClinicianData.emerContactName || "",
        emerContactRelation: selectedClinicianData.emerContactRelation || "",

        emerContactAddress: selectedClinicianData.emerContactAddress || "",
        emerContactPhone: selectedClinicianData.emerContactPhone || "",
        timeZone: selectedClinicianData.timeZone || "",
        notes: selectedClinicianData.notes || "",
        zipCodeExclusion: selectedClinicianData.zipCodeExclusion || "",
        payRates: selectedClinicianData.payRates || "",
        // lastUpdated: formattedLastUpdated,
        monthlyAvailability: selectedClinicianData.monthlyAvailability || "",
        maxMiles: selectedClinicianData.maxMiles || "",
        // planProgramId: selectedProgramsFormatted,
        userClients: userClientsData || [],
        languages: selectedClinicianData.languages || [],
        licenseType: selectedClinicianData.licenseType || [],
        clientsSupported: selectedClinicianData.clientsSupported || [],
        prefContactMethod: selectedClinicianData.prefContactMethod || [],
      });
    }
  }, [editMode, selectedClinicianData]);

  const handleUpdate = async () => {
    const userClientsData = [];

    selectedPrograms.forEach((selectedProgram) => {
      const plan = selectedProgram.clientId;
      const programId = selectedProgram.programId;

      let userClient = userClientsData.find((client) => client.plan === plan);

      if (!userClient) {
        userClient = {
          plan,
          programs: [],
        };
        userClientsData.push(userClient);
      }

      userClient.programs.push(programId.toString());
    });

    setUserClients(userClientsData);
    const updatedClinicianData = {
      ...clinicianData,
      // planProgramId: selectedProgramsFormatted,
      userClients: userClientsData,
    };
    let updatedApiData = {
      _id: clinicianData._id,
      // planProgramId: selectedProgramsFormatted,
      userName: clinicianData.userName,
      // password: clinicianData.password,
      firstName: clinicianData.firstName,
      lastName: clinicianData.lastName,
      speciality: clinicianData.speciality,
      dateOfBirth: clinicianData.dateOfBirth,
      gender: clinicianData.gender,
      address: clinicianData.address,
      clientName: clinicianData.clientName,
      city: clinicianData.city,
      state: clinicianData.state,
      zipCode: clinicianData.zipCode,
      county: clinicianData.county,
      phoneNumber: clinicianData.phoneNumber,
      homeNumber: clinicianData.homeNumber,
      email: clinicianData.email,
      npi: clinicianData.npi,
      licenseNumber: clinicianData.licenseNumber,
      validStates: clinicianData.validStates,
      licenseExpiryDate: clinicianData.licenseExpiryDate,
      pedApproved: clinicianData.pedApproved,
      status: clinicianData.status,
      //evaluation: clinicianData.evaluation,
      //evaluationReason: clinicianData.evaluationReason,
      emerContactName: clinicianData.emerContactName,
      emerContactRelation: clinicianData.emerContactRelation,
      emerContactAddress: clinicianData.emerContactAddress,
      emerContactPhone: clinicianData.emerContactPhone,
      timeZone: clinicianData.timeZone,
      notes: clinicianData.notes,
      zipCodeExclusion: clinicianData.zipCodeExclusion,
      payRates: clinicianData.payRates,
      userClients: userClientsData,
      // lastUpdated: clinicianData.lastUpdated,
      monthlyAvailability: clinicianData.monthlyAvailability,
      maxMiles: clinicianData.maxMiles,
      languages: clinicianData.languages.map((value) => value.trim()),
      licenseType: clinicianData.licenseType.map((value) => value.trim()),
      clientsSupported: clinicianData.clientsSupported.map((value) =>
        value.trim(),
      ),
      prefContactMethod: clinicianData.prefContactMethod.map((value) =>
        value.trim(),
      ),
      ...(clinicianData.status === 1
        ? {}
        : {
            evaluation: clinicianData.evaluation,
            evaluationReason: clinicianData.evaluationReason,
          }),
    };
    if (clinicianData.password !== "") {
      updatedApiData = {
        ...updatedApiData,
        password: clinicianData.password,
      };
    }
    const response = await putApi(
      `/clinicians/update?id=${selectedClinicianData._id}`,
      updatedApiData,
    );

    if (response.status === 200) {
      setClinicianData((prevClinicianData) => ({
        ...prevClinicianData,
      }));
      setToasterOpen(true);
      setToasterSeverity(Labels.SUCCESS);
      setToasterMessage(response.data.message);

      // fetchadminData();
      fetchClinicianData();
      setTimeout(() => {
        onClose();
      }, 3000);
    } else {
      setToasterSeverity(Labels.ERROR);
      setToasterMessage(response.response.data.message);
      setToasterOpen(true);
    }
  };

  const activateClinician = async () => {
    const body = {
      id: selectedClinicianData._id,
      status: "1",
    };
    const response = await putApi(`/clinicians/update-user-status`, body);
    if (response.status === 200) {
      // setClinicianData((prevClinicianData) => ({
      //   ...prevClinicianData,
      // }));
      setToasterOpen(true);
      setToasterSeverity(Labels.SUCCESS);
      setToasterMessage(response.data.message);

      // fetchadminData();
      fetchClinicianData();
      setTimeout(() => {
        onClose();
      }, 1000);
    } else {
      setToasterSeverity(Labels.ERROR);
      setToasterMessage(response.response.data.message);
      setToasterOpen(true);
    }
  };

  const deActivateClinician = async () => {
    const body = {
      id: selectedClinicianData._id,
      status: "0",
    };
    const response = await putApi(`/clinicians/update-user-status`, body);
    if (response.status === 200) {
      // setClinicianData((prevClinicianData) => ({
      //   ...prevClinicianData,
      // }));
      setToasterOpen(true);
      setToasterSeverity(Labels.SUCCESS);
      setToasterMessage(response.data.message);

      // fetchadminData();
      fetchClinicianData();
      setTimeout(() => {
        onClose();
      }, 1000);
    } else {
      setToasterSeverity(Labels.ERROR);
      setToasterMessage(response.response.data.message);
      setToasterOpen(true);
    }
  };

  // Function to handle "Activate" button click
  const handleActivate = () => {
    setClinicianData((prevClinicianData) => ({
      ...prevClinicianData,
      // status: 1,
    }));
    setIsActivateAlertOpen(true);
  };

  // Function to handle "Deactivate" button click
  const handleDeactivate = () => {
    setClinicianData((prevClinicianData) => ({
      ...prevClinicianData,
      // status: 0,
    }));
    setIsActivateAlertOpen(true);
  };

  const handleAlertYesClick = (activateOrDeactivate) => {
    if (clinicianData.status === 1) {
      setInputFieldsDisabled(false);
      clinicianData.status = 0;

      // Enable editing mode if needed
    } else if (clinicianData.status === 0) {
      // Disable input fields
      setInputFieldsDisabled(true);
      clinicianData.status = 1;
    }
    setIsActivateAlertOpen(false);

    // setToasterMessage("Status updated successfully");
    // setToasterSeverity(Labels.SUCCESS);
    // setToasterOpen(true);

    //setTimeout(() => {
    //handleUpdate();
    //}, 2000);
    if (activateOrDeactivate == true) {
      activateClinician();
    } else {
      deActivateClinician();
    }
  };
  const handleAlertNoClick = () => {
    setIsActivateAlertOpen(false);
  };

  const handleInputChange = (fieldName, inputValue) => {
    if (fieldName === "zipCodeExclusion") {
      if (!inputValue || /^\s*$/.test(inputValue)) {
        setZipCodeExclusionError(ValidationMessage.ZipCodeExclusion_Required);
      } else if (!/^(\d{5}(,\d{5})*)$/.test(inputValue)) {
        setZipCodeExclusionError(ValidationMessage.ZipCodeExclusion_Format);
      } else {
        setZipCodeExclusionError("");

        setClinicianData({
          ...clinicianData,
          [fieldName]: inputValue,
        });
      }
    } else if (fieldName === "zipCode") {
      if (!inputValue) {
        setZipCodeError(ValidationMessage.Zipcode_Required);
      } else {
        setZipCodeError("");

        setClinicianData((prevClinicianData) => ({
          ...prevClinicianData,
          zipCode: inputValue,
        }));
      }
    } else if (fieldName === "phoneNumber") {
      if (!inputValue) {
        setPhoneNumberError(ValidationMessage.PhoneNumber_Required);
      } else if (
        !/^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/.test(
          inputValue,
        )
      ) {
        setPhoneNumberError(ValidationMessage.PhoneNumber_Format);
      } else if (inputValue.replace(/[^\d]/g, "").length > 13) {
        setPhoneNumberError(ValidationMessage.PhoneNumber_Format);
      } else {
        setPhoneNumberError("");
      }
    } else if (fieldName === "homeNumber") {
      if (!inputValue) {
        setHomeNumberError(ValidationMessage.HomeNumber_Required);
      } else if (
        !/^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/.test(
          inputValue,
        )
      ) {
        setHomeNumberError(ValidationMessage.HomeNumber_Format);
      } else if (inputValue.replace(/[^\d]/g, "").length > 13) {
        setHomeNumberError(ValidationMessage.HomeNumber_Format);
      } else {
        setHomeNumberError("");
      }
    } else if (fieldName === "emerContactPhone") {
      if (!inputValue || /^\s*$/.test(inputValue)) {
        setemerContactPhoneError(ValidationMessage.EmerContactNumber_Required);
      } else {
        const inputValueString = String(inputValue);

        if (
          !/^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/.test(
            inputValueString,
          )
        ) {
          setemerContactPhoneError(ValidationMessage.EmerContactNumber_Format);
        } else if (inputValueString.replace(/[^\d]/g, "").length > 13) {
          setemerContactPhoneError(ValidationMessage.EmerContactNumber_Format);
        } else {
          setemerContactPhoneError("");
        }
      }
    } else if (fieldName === "email") {
      if (!inputValue) {
        setEmailError(ValidationMessage.Email_Required);
      } else if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(inputValue)) {
        setEmailError(ValidationMessage.Email_Format_Invalid);
      } else {
        setEmailError("");
      }
    } else if (fieldName === "password") {
      // Password validation logic
      if (!inputValue) {
        setPasswordError(ValidationMessage.Password_Required);
      } else if (inputValue.length < 8) {
        setPasswordError(ValidationMessage.Password_Length);
      } else if (
        !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_\-+=~])[A-Za-z\d!@#$%^&*()_\-+=~]{8,}$/.test(
          inputValue,
        )
      ) {
        setPasswordError(ValidationMessage.Password_Format);
      } else {
        setPasswordError("");
      }
    } else if (
      fieldName === "firstName" ||
      fieldName === "lastName" ||
      fieldName === "county" ||
      fieldName === "state" ||
      fieldName === "city" ||
      fieldName === "emerContactName" ||
      fieldName === "emerContactRelation"
    ) {
      if (fieldName === "firstName") {
        if (!inputValue) {
          setNameError(ValidationMessage.Firstname_Required);
        } else if (!/^[a-zA-Z ]+$/.test(inputValue)) {
          setNameError(ValidationMessage.Firstname_Format);
        } else {
          setNameError("");
        }
      } else if (fieldName === "lastName") {
        if (!inputValue) {
          setLastNameError(ValidationMessage.Lastname_Required);
        } else if (!/^[a-zA-Z ]+$/.test(inputValue)) {
          setLastNameError(ValidationMessage.Lastname_Format);
        } else {
          setLastNameError("");
        }
      } else if (fieldName === "county") {
        if (!inputValue) {
          setCountyError(ValidationMessage.County_Required);
        } else if (!/^[a-zA-Z ]+$/.test(inputValue)) {
          setCountyError(ValidationMessage.County_Format);
        } else {
          setCountyError("");
        }
      } else if (fieldName === "state") {
        if (!inputValue) {
          setStateError(ValidationMessage.State_Required);
        } else if (!/^[a-zA-Z ]+$/.test(inputValue)) {
          setStateError(ValidationMessage.State_Format);
        } else {
          setStateError("");
        }
      } else if (fieldName === "city") {
        if (!inputValue) {
          setCityError(ValidationMessage.City_Required);
        } else if (!/^[a-zA-Z ]+$/.test(inputValue)) {
          setCityError(ValidationMessage.City_Format);
        } else {
          setCityError("");
        }
      } else if (fieldName === "emerContactName") {
        if (!inputValue) {
          setEmerContactNameError(ValidationMessage.EmerContactName_Required);
        } else if (!/^[a-zA-Z ]+$/.test(inputValue)) {
          setEmerContactNameError(ValidationMessage.EmerContactName_Format);
        } else {
          setEmerContactNameError("");
        }
      } else if (fieldName === "emerContactRelation") {
        if (!inputValue) {
          setEmerContactRelationError(
            ValidationMessage.EmerContactRelation_Required,
          );
        } else if (!/^[a-zA-Z ]+$/.test(inputValue)) {
          setEmerContactRelationError(
            ValidationMessage.EmerContactRelation_Format,
          );
        } else {
          setEmerContactRelationError("");
        }
      }
    } else if (fieldName === "npi") {
      if (!inputValue || /^\s*$/.test(inputValue)) {
        setNpiError(ValidationMessage.Npi_Required);
      } else if (!/^\d{10}$/.test(inputValue)) {
        setNpiError(ValidationMessage.Npi_Length);
      }
      // else if (!/^1\d{9}$/.test(inputValue)) {
      //   setNpiError(ValidationMessage.Npi_Format);
      // }
      else {
        setNpiError("");
      }
    }

    handleChange(fieldName, inputValue);
  };
  const handleChange = (field, value) => {
    if (
      field === "languages" ||
      field === "licenseType" ||
      field === "clientsSupported" ||
      field === "prefContactMethod" ||
      field === "validStates" ||
      field === "timeZone" ||
      field === "state"
    ) {
      const sortedValues = Array.isArray(value) ? [...value].sort() : [value];
      setClinicianData({
        ...clinicianData,
        [field]: sortedValues,
      });
      if (field === "state") {
        setClinicianData({
          ...clinicianData,
          [field]: sortedValues,
        });
      }
    } else if (field === "status") {
      // Access the first element of the array and convert to 1 or 0
      const statusValue = value[0] === "Active" ? 1 : 0;
      setClinicianData({
        ...clinicianData,
        [field]: statusValue,
      });
    } else if (field === "evaluation") {
      // Access the first element of the array and convert to 1 or 0
      const evaluationValue = value[0] === "Re Hire" ? 1 : 0;
      setClinicianData({
        ...clinicianData,
        [field]: evaluationValue,
      });
    } else if (field === "dateOfBirth") {
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      const selectedDate = new Date(value);
      selectedDate.setHours(0, 0, 0, 0);

      const minimumDate = new Date(1900, 0, 1);
      const selectedYear = selectedDate.getFullYear();
      if (
        selectedDate > currentDate ||
        selectedDate.getTime() === currentDate.getTime() ||
        selectedDate < minimumDate ||
        selectedYear < 1000 ||
        selectedYear > new Date().getFullYear() ||
        selectedDate == "Invalid Date"
      ) {
        setDateOfBirthError("Please enter valid date");
      } else {
        setDateOfBirthError("");
        setClinicianData({
          ...clinicianData,
          [field]: value,
        });
      }
    } else if (field === "licenseExpiryDate") {
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      const selectedDate = new Date(value);
      selectedDate.setHours(0, 0, 0, 0);
      const selectedYear = selectedDate.getFullYear();
      const minimumDate = currentDate; //new Date(1900, 0, 1);

      if (
        selectedDate < currentDate ||
        selectedDate.getTime() === currentDate.getTime() ||
        selectedDate < minimumDate ||
        selectedYear < 1000 ||
        selectedDate == "Invalid Date"
      ) {
        setExpiryDateError("Please enter valid date");
      } else {
        setExpiryDateError("");
        setClinicianData({
          ...clinicianData,
          [field]: value,
        });
      }
    } else {
      setClinicianData({
        ...clinicianData,
        [field]: value.toString(),
      });
    }
  };

  const onCloseToaster = () => {
    setToasterOpen(false);
    setToasterSeverity(Labels.SUCCESS);
    setToasterMessage("");
  };

  const handleReset = () => {
    setSelectedPrograms([]);
    resetClinicianData.current = true;
  };

  const resetClinicianData = useRef(false);

  useEffect(() => {
    if (resetClinicianData.current) {
      setClinicianData(initialClinicianData);
      setErrors({});
      resetClinicianData.current = false;
    }
  }, [selectedPrograms]);
  return (
    <div className="addClincian">
      <div className="" key={index}>
        <Card
          style={{
            height: "auto",
            // minHeight: "76vh",
            maxHeight: "450px",
            overflowY: "auto",
            maxWidth: "100%",
            margin: "0 auto",
            paddingRight: "15px",
          }}
        >
          <CardHeader
            title={"Demographic Information"}
            action={
              <div
                style={{
                  width: "100% !important",
                  margin: "0 !important",
                  padding: "0 !important",
                }}
              >
                <IconButton
                  style={{
                    float: "right",
                    color: "#FFFFFF",
                  }}
                  aria-label="Section minimise"
                  size="small"
                  onClick={toggleDemographicSection}
                >
                  {isShowDemographic ? (
                    <KeyboardControlKeyIcon />
                  ) : (
                    <ExpandMoreIcon />
                  )}
                </IconButton>
              </div>
            }
            sx={{
              backgroundColor: `var(--mainTheme)`,
              color: "white",
              padding: "10px",
              textAlign: "left !important",
              fontWeight: 500,
              marginBottom: "5px",
              width: "1100px",
            }}
            titleTypographyProps={{ variant: "outline" }}
          />

          {isShowDemographic && (
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                <MuiInput
                  label="First Name"
                  className="inputAddMembers"
                  type="input"
                  value={clinicianData.firstName || ""}
                  onChange={(event) =>
                    handleInputChange(
                      "firstName",
                      event.target.value.toString(),
                    )
                  }
                  required={true}
                  disabled={clinicianData?.status === 0}
                />

                <ValidationError error={nameError} />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                <MuiInput
                  label="Last Name"
                  className="inputAddMembers"
                  type="input"
                  value={clinicianData.lastName || ""}
                  onChange={(event) =>
                    handleInputChange("lastName", event.target.value.toString())
                  }
                  required={true}
                  disabled={clinicianData?.status === 0}
                />

                <ValidationError error={lastNameError} />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                <MuiInput
                  label="User Name"
                  className="inputAddMembers"
                  type="input"
                  value={clinicianData.userName}
                  // onChange={(event) =>
                  //   handleChange("userName", event.target.value)
                  // }
                  required={true}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                <MuiInput
                  label="Email"
                  className="inputAddMembers"
                  type="input"
                  value={clinicianData.email}
                  onChange={(event) =>
                    handleInputChange("email", event.target.value.toString())
                  }
                  required={true}
                  disabled={clinicianData?.status === 0}
                />

                <ValidationError error={emailError} />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                <MuiInput
                  label="Password"
                  className="inputAddMembers"
                  type="input"
                  value={clinicianData.password}
                  onChange={(event) =>
                    handleInputChange("password", event.target.value.toString())
                  }
                  required={true}
                  // disabled
                />

                <ValidationError error={passwordError} />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                {/* <MuiInput
                  label="Date Of Birth"
                  className="inputAddMembers"
                  type="date"
                  value={clinicianData.dateOfBirth}
                  onChange={(event) =>
                    handleChange("dateOfBirth", event.target.value.toString())
                  }
                  required={true}
                  disabled={clinicianData?.status === 0}
                /> */}
                <Box
                  sx={{ "& > :not(style)": { width: "100%", marginX: 1 } }}
                  className="inputAddMembers"
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer
                      components={["DatePicker"]}
                      sx={{ width: "100%" }}
                    >
                      <DesktopDatePicker
                        sx={{ width: "100%" }}
                        // className="dateFieldBorder"
                        variant="outlined"
                        label="Date Of Birth"
                        views={["year", "month", "day"]}
                        disableFuture
                        value={dayjs(clinicianData?.dateOfBirth)}
                        onChange={(newValue) =>
                          handleChange("dateOfBirth", newValue)
                        }
                        slotProps={{
                          textField: {
                            disabled: true,
                          },
                        }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                  <Box marginTop={1}>
                    <ValidationError error={dateOfBirthError} />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                <MuiInput
                  label="Gender"
                  className="inputAddMembers"
                  options={["Female", "Male"]}
                  type="select"
                  value={clinicianData.gender}
                  onChange={(event) =>
                    handleChange("gender", event.target.value)
                  }
                  required={true}
                  disabled={clinicianData.status === 0}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                <MuiInput
                  label="Street Address"
                  className="inputAddMembers"
                  type="input"
                  value={clinicianData.address}
                  onChange={(event) =>
                    handleChange("address", event.target.value)
                  }
                  required={true}
                  disabled={clinicianData.status === 0}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                <MuiInput
                  label="County"
                  className="inputAddMembers"
                  type="input"
                  value={clinicianData.county}
                  onChange={(event) =>
                    handleInputChange("county", event.target.value.toString())
                  }
                  required={true}
                  disabled={clinicianData.status === 0}
                />

                <ValidationError error={countyError} />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                <MuiInput
                  label="Zip Code"
                  className="inputAddMembers"
                  type="input"
                  value={clinicianData.zipCode}
                  onChange={(event) =>
                    handleInputChange("zipCode", event.target.value.toString())
                  }
                  required={true}
                  disabled={clinicianData.status === 0}
                />
                <ValidationError error={zipCodeError} />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                <MuiInput
                  label="City"
                  className="inputAddMembers"
                  type="input"
                  value={clinicianData.city}
                  onChange={(event) =>
                    handleInputChange("city", event.target.value.toString())
                  }
                  required={true}
                  disabled={clinicianData.status === 0}
                />

                <ValidationError error={cityError} />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                <MuiInput
                  label="State"
                  // options={stateNames.options.toLocaleString()}
                  options={stateNames.options.map((state) =>
                    state.toUpperCase(),
                  )}
                  className="inputAddMember"
                  type="select"
                  value={clinicianData.state}
                  onChange={(event) =>
                    handleChange("state", event.target.value.toString())
                  }
                  required={true}
                  disabled={clinicianData.status === 0}
                />

                <ValidationError error={stateError} />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                <MuiInput
                  label="Home Number"
                  className="inputAddMembers"
                  type="input"
                  value={clinicianData.homeNumber}
                  onChange={(event) =>
                    handleInputChange(
                      "homeNumber",
                      event.target.value.toString(),
                    )
                  }
                  required={true}
                  disabled={clinicianData.status === 0}
                />
                <ValidationError error={homeNumberError} />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                <MuiInput
                  label="Mobile Number"
                  className="inputAddMembers"
                  type="input"
                  value={clinicianData.phoneNumber}
                  onChange={(event) =>
                    handleInputChange(
                      "phoneNumber",
                      event.target.value.toString(),
                    )
                  }
                  required={true}
                  disabled={clinicianData.status === 0}
                />
                <ValidationError error={phoneNumberError} />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                <MuiInput
                  label="Languages Spoken"
                  // options={["English", "Spanish", "French"]}
                  options={languageOptions.options}
                  className="inputAddMembers"
                  type="select"
                  isMulti={true}
                  value={clinicianData.languages}
                  onChange={(event) =>
                    handleChange("languages", event.target.value)
                  }
                  required={true}
                  disabled={clinicianData.status === 0}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                <MuiInput
                  label="Emergency Contact Name"
                  className="inputAddMembers"
                  type="input"
                  value={clinicianData.emerContactName}
                  onChange={(event) =>
                    handleInputChange(
                      "emerContactName",
                      event.target.value.toString(),
                    )
                  }
                  required={true}
                  disabled={clinicianData.status === 0}
                />

                <ValidationError error={emerContactNameError} />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                <MuiInput
                  label="Emergency Contact Relation"
                  className="inputAddMembers"
                  type="input"
                  value={clinicianData.emerContactRelation}
                  onChange={(event) =>
                    handleInputChange(
                      "emerContactRelation",
                      event.target.value.toString(),
                    )
                  }
                  disabled={clinicianData.status === 0}
                  required={true}
                />

                <ValidationError error={emerContactRelationError} />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                <MuiInput
                  label=" Emergency Contact address"
                  className="inputAddMembers"
                  type="input"
                  value={clinicianData.emerContactAddress}
                  onChange={(event) =>
                    handleChange("emerContactAddress", event.target.value)
                  }
                  disabled={clinicianData.status === 0}
                  required={true}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                <MuiInput
                  label=" Emergency Contact Number"
                  className="inputAddMembers"
                  type="input"
                  value={clinicianData.emerContactPhone}
                  onChange={(event) =>
                    handleInputChange("emerContactPhone", event.target.value)
                  }
                  disabled={clinicianData.status === 0}
                  required={true}
                />
                <ValidationError error={emerContactPhoneError} />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                <MuiInput
                  label="Time Zone"
                  // options={timeZoneOptions.options}
                  options={timeZones.options}
                  className="inputAddMembers"
                  type="select"
                  isMulti={true}
                  value={clinicianData.timeZone}
                  onChange={(event) =>
                    handleChange("timeZone", event.target.value)
                  }
                  disabled={clinicianData.status === 0}
                  required={true}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                <MuiInput
                  label="Preferred Method of Contact"
                  options={["Phone", "Email", "Text"]}
                  className="inputAddMembers"
                  type="select"
                  isMulti={true}
                  value={clinicianData.prefContactMethod}
                  onChange={(event) =>
                    handleChange("prefContactMethod", event.target.value)
                  }
                  disabled={clinicianData.status === 0}
                  required={true}
                />
              </Grid>
            </Grid>
          )}

          <CardHeader
            title={"License Information"}
            action={
              <div
                style={{
                  width: "100% !important",
                  margin: "0 !important",
                  padding: "0 !important",
                }}
              >
                <IconButton
                  style={{
                    float: "right",
                    color: "#FFFFFF",
                  }}
                  aria-label="Section minimise"
                  size="small"
                  onClick={toggleLicenseSection}
                >
                  {isShowLicense ? (
                    <KeyboardControlKeyIcon />
                  ) : (
                    <ExpandMoreIcon />
                  )}
                </IconButton>
              </div>
            }
            sx={{
              backgroundColor: `var(--mainTheme)`,
              color: "white",
              padding: "10px",
              textAlign: "left !important",
              fontWeight: 500,
              marginBottom: "5px",
              width: "1100px",
            }}
            titleTypographyProps={{ variant: "outline" }}
          />

          {isShowLicense && (
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                <MuiInput
                  label="License Type"
                  options={[
                    "ANP",
                    "Case Manager",
                    "CNA",
                    "FNP",
                    "GNP",
                    "LPN",
                    "LVN",
                    "MA",
                    "MD",
                    "MSW PA",
                    "Psych",
                    "RN",
                    "Screening Technologist",
                    "SW",
                  ]}
                  className="inputAddMembers"
                  type="select"
                  isMulti={true}
                  value={clinicianData.licenseType}
                  onChange={(event) =>
                    handleChange("licenseType", event.target.value)
                  }
                  required={true}
                  disabled={clinicianData.status === 0}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                <MuiInput
                  label="NPI Number"
                  className="inputAddMembers"
                  type="input"
                  value={clinicianData.npi}
                  // onChange={(event) => handleChange("npi", event.target.value)}
                  onChange={(event) =>
                    handleInputChange("npi", event.target.value)
                  }
                  required={true}
                  disabled={clinicianData.status === 0}
                />
                <ValidationError error={npiError} />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                <MuiInput
                  label="License Number"
                  className="inputAddMembers"
                  type="input"
                  value={clinicianData.licenseNumber}
                  onChange={(event) =>
                    handleChange("licenseNumber", event.target.value)
                  }
                  required={true}
                  disabled={clinicianData.status === 0}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                <MuiInput
                  label="Speciality"
                  className="inputAddMembers"
                  type="input"
                  value={clinicianData.speciality}
                  onChange={(event) =>
                    handleChange("speciality", event.target.value)
                  }
                  required={true}
                  disabled={clinicianData.status === 0}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                <MuiInput
                  label="State(s) it is valid in"
                  // options={["English", "Spanish", "French"]}
                  options={stateNames.options}
                  className="inputAddMembers"
                  type="select"
                  isMulti={true}
                  value={clinicianData.validStates}
                  onChange={(event) =>
                    handleChange("validStates", event.target.value)
                  }
                  required={true}
                  disabled={clinicianData.status === 0}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                {/* <MuiInput
                  label="Expiration date"
                  className="inputAddMembers"
                  type="date"
                  value={clinicianData.licenseExpiryDate}
                  onChange={(event) =>
                    handleChange("licenseExpiryDate", event.target.value)
                  }
                  required={true}
                  disabled={clinicianData?.status === 0}
                /> */}
                <Box
                  sx={{ "& > :not(style)": { width: "100%", marginX: 1 } }}
                  className="inputAddMembers"
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer
                      components={["DatePicker"]}
                      sx={{ width: "100%" }}
                    >
                      <DesktopDatePicker
                        sx={{ width: "100%" }}
                        // className="dateFieldBorder"
                        variant="outlined"
                        minDate={dayjs(new Date())}
                        label="Expiration date"
                        views={["year", "month", "day"]}
                        value={dayjs(clinicianData.licenseExpiryDate)}
                        onChange={(newValue) =>
                          handleChange("licenseExpiryDate", newValue)
                        }
                        slotProps={{
                          textField: {
                            disabled: true,
                          },
                        }}
                        disabled={clinicianData?.status === 0}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                  <Box marginTop={1}>
                    <ValidationError error={expiryDateError} />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                <MuiInput
                  label="Clients Supported"
                  options={["Molina", "Test"]}
                  className="inputAddMembers"
                  type="select"
                  isMulti={true}
                  value={clinicianData.clientsSupported}
                  onChange={(event) =>
                    handleChange("clientsSupported", event.target.value)
                  }
                  required={true}
                  disabled={clinicianData.status === 0}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                <MuiInput
                  label="PEDS approved"
                  className="inputAddMembers"
                  type="input"
                  value={clinicianData.pedApproved}
                  // onChange={(event) =>
                  //   handleChange("pedApproved", event.target.value)
                  // }
                  required={true}
                  // disabled={clinicianData.status === 0}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                <MuiInput
                  label="Status"
                  options={["Active", "Inactive"]}
                  className="inputAddMembers"
                  type="select"
                  value={clinicianData.status === 1 ? "Active" : "Inactive"}
                  onChange={(event) =>
                    handleChange("status", event.target.value)
                  }
                  required={true}
                  disabled={clinicianData?.status === 0}
                />
              </Grid>

              {clinicianData.status === 0 ? (
                <>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                    <MuiInput
                      label="Evaluation"
                      options={["Re Hire", "No Rehire"]}
                      className="inputAddMembers"
                      type="select"
                      value={
                        clinicianData.evaluation == 1 ? "Re Hire" : "No Rehire"
                      }
                      // className="inputAddMembers"
                      // type="select"
                      // value={
                      //   clinicianData.evaluation == 1 ? "Re Hire" : "No Rehire"
                      // }
                      onChange={(event) =>
                        handleChange("evaluation", event.target.value)
                      }
                      required={true}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                    <MuiInput
                      label="Evaluation Reason"
                      type="input"
                      className="inputAddMembers"
                      multiline
                      value={clinicianData.evaluationReason}
                      onChange={(event) =>
                        handleChange("evaluationReason", event.target.value)
                      }
                      required={true}
                    />
                  </Grid>
                </>
              ) : (
                <></>
              )}

              {/* <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                <MuiInput
                  label="Last Updated"
                  className="inputAddMembers"
                  type="date"
                  value={clinicianData.lastUpdated}
                  onChange={(event) =>
                    handleChange("lastUpdated", event.target.value)
                  }
                  required={true}
                />
              </Grid> */}
            </Grid>
          )}

          <CardHeader
            title={"Availability and Travel Preferences"}
            action={
              <div
                style={{
                  width: "100% !important",
                  margin: "0 !important",
                  padding: "0 !important",
                }}
              >
                <IconButton
                  style={{
                    float: "right",
                    color: "#FFFFFF",
                  }}
                  aria-label="Section minimise"
                  size="small"
                  onClick={toggleAvailabilitySection}
                >
                  {isShowAvailability ? (
                    <KeyboardControlKeyIcon />
                  ) : (
                    <ExpandMoreIcon />
                  )}
                </IconButton>
              </div>
            }
            sx={{
              backgroundColor: `var(--mainTheme)`,
              color: "white",
              padding: "10px",
              textAlign: "left !important",
              fontWeight: 500,
              marginBottom: "5px",
              width: "1100px",
            }}
            titleTypographyProps={{ variant: "outline" }}
          />

          {isShowAvailability && (
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                <MuiInput
                  label="Zip Code Exclusion (Zip Codes Clinician will NOT travel to)"
                  className="inputAddMembers"
                  type="input"
                  value={clinicianData.zipCodeExclusion}
                  onChange={(event) =>
                    handleInputChange(
                      "zipCodeExclusion",
                      event.target.value.toString(),
                    )
                  }
                  required={true}
                  disabled={clinicianData.status === 0}
                />
                <ValidationError error={zipCodeExclusionError} />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                <MuiInput
                  label="Pay Rates"
                  className="inputAddMembers"
                  type="input"
                  value={clinicianData.payRates}
                  onChange={(event) =>
                    handleChange("payRates", event.target.value)
                  }
                  required={true}
                  disabled={clinicianData.status === 0}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                <MuiInput
                  label="Notes"
                  className="inputAddMembers"
                  type="input"
                  value={clinicianData.notes}
                  onChange={(event) =>
                    handleChange("notes", event.target.value)
                  }
                  multiline
                  disabled={clinicianData.status === 0}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                <MuiInput
                  label="Monthly Availability"
                  className="inputAddMembers"
                  type="input"
                  value={clinicianData.monthlyAvailability}
                  onChange={(event) =>
                    handleChange("monthlyAvailability", event.target.value)
                  }
                  required={true}
                  disabled={clinicianData.status === 0}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                <MuiInput
                  label="# Miles Clinician will travel"
                  className="inputAddMembers"
                  type="input"
                  value={clinicianData.maxMiles}
                  onChange={(event) =>
                    handleChange("maxMiles", event.target.value)
                  }
                  required={true}
                  disabled={clinicianData.status === 0}
                />
              </Grid>
            </Grid>
          )}
        </Card>
        <Grid container style={{ marginTop: "5px" }}>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <MuiInput
              label="Client Name"
              className="inputAddMembers"
              type="input"
              value={clinicianData.clientName}
              // onChange={(event) =>
              //   handleChange("clientName", event.target.value.toString())
              // }
              // disabled={clinicianData?.status === 0}
              required={true}
              disabled
            />
          </Grid>
        </Grid>
        <Grid container xs={12}>
          <Grid item xs={3} style={{ marginLeft: "15px" }}>
            <h4 style={headingStyle}>Clients List</h4>
            <div
              style={{
                maxHeight: "200px",
                overflowY: "auto",
              }}
            >
              {uniquePlans.sort(sortByFirstAndSecondLetter).map((plan) => (
                <div key={plan}>
                  <Button
                    key={plan}
                    style={{
                      backgroundColor: "#1076BC",
                      color: "white",
                      width: "100%",
                      marginTop: "3px",
                    }}
                    onClick={() => handlePlanClick(plan)}
                  >
                    {plan}
                  </Button>
                  {planData
                    .filter((item) => item.plan === plan)
                    .sort((a, b) => a.program.localeCompare(b.program))

                    .map((programItem) => (
                      <div
                        key={`${programItem.clientId}-${programItem.programId}`}
                      >
                        <Button
                          style={{
                            backgroundColor: "#ccc",
                            color: "#000000de",
                            width: "100%",
                            marginTop: "3px",
                          }}
                          onClick={() =>
                            handleProgramClick(
                              programItem.clientId,
                              programItem.programId,
                            )
                          }
                        >
                          {programItem.program}
                        </Button>
                      </div>
                    ))}
                </div>
              ))}
            </div>
          </Grid>

          <Grid item xs={3} style={{ marginLeft: "15px" }}>
            <h4 style={headingStyle}>Selected clients</h4>

            <div style={{ marginBottom: "10px" }}>
              {selectedClientId && (
                <div
                  style={{
                    maxHeight: "200px",
                    overflowY: "auto",
                  }}
                >
                  {selectedProgramData ? (
                    <div>
                      <Button
                        style={{
                          backgroundColor: "#1076BC",
                          color: "white",
                          width: "100%",
                          marginBottom: "3px",
                        }}
                      >
                        {selectedProgramData.plan}
                      </Button>

                      <div>
                        <Button
                          style={{
                            backgroundColor: "#ccc",
                            color: "#000000de",
                            width: "100%",
                            marginTop: "3px",
                          }}
                        >
                          {selectedProgramData.program}
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}

                  {selectedPrograms
                    .map((selectedProgram) => {
                      return planData
                        .filter(
                          (item) =>
                            item.clientId === selectedProgram.clientId &&
                            item.programId === selectedProgram.programId,
                        )
                        .map((programItem) => programItem);
                    })
                    .flat()
                    .reduce((accumulator, programItem) => {
                      const existingPlan = accumulator.find(
                        (plan) => plan.plan === programItem.plan,
                      );

                      if (!existingPlan) {
                        accumulator.push({
                          plan: programItem.plan,
                          programs: [programItem.program],
                        });
                      } else {
                        existingPlan.programs.push(programItem.program);
                      }

                      return accumulator;
                    }, [])
                    .sort((a, b) => a.plan.localeCompare(b.plan))
                    .map((uniquePlan) => (
                      <div key={uniquePlan.plan}>
                        <Button
                          style={{
                            backgroundColor: "#1076BC",
                            color: "white",
                            width: "100%",
                            marginTop: "3px",
                          }}
                        >
                          {uniquePlan.plan}
                        </Button>
                        {uniquePlan.programs
                          .sort((a, b) => a.localeCompare(b))
                          .map((program) => (
                            <div
                              key={`${uniquePlan.plan}-${program}`}
                              style={{
                                backgroundColor: "#ccc",
                                color: "#000000de",
                                width: "100%",
                                marginTop: "3px",
                                textAlign: "center",
                                padding: "3px",
                              }}
                            >
                              {program}
                            </div>
                          ))}
                      </div>
                    ))}
                </div>
              )}
            </div>
          </Grid>
        </Grid>
      </div>
      <div className="btnsAddMember">
        <div style={{ marginRight: "auto" }}>
          {selectedClinicianData?.status === 1 ? (
            <Button sx={dialogBtn} onClick={handleDeactivate}>
              Deactivate
            </Button>
          ) : (
            <Button sx={dialogBtn} onClick={handleActivate}>
              Activate
            </Button>
          )}
          <Button sx={dialogBtn} disabled={true} className="disabledButton">
            Unlock
          </Button>
          <Button sx={dialogBtn} disabled={true} className="disabledButton">
            Reset Signature
          </Button>
        </div>

        <Button sx={dialogBtn} onClick={handleReset}>
          Reset
        </Button>
        <Button
          sx={dialogBtn}
          className={`dialogBtn ${
            !additionalConditions.every((condition) => condition) ||
            !isFormValid
              ? "disabledButton"
              : ""
          }`}
          disabled={
            !additionalConditions.every((condition) => condition) ||
            !isFormValid ||
            selectedPrograms.length === 0
          }
          onClick={handleUpdate}
        >
          Update
        </Button>
      </div>

      <Toaster
        open={toasterOpen}
        onClose={onCloseToaster}
        severity={toasterSeverity}
        message={toasterMessage}
      />
      <Alert
        open={isActivateAlertOpen}
        handleClose={() => setIsActivateAlertOpen(false)}
        title={`Do you want to ${
          selectedClinicianData?.status === 1 ? "Deactivate" : "Activate"
        } the "${clinicianData.firstName} ${
          clinicianData.lastName
        }" clinician ?`}
        okText="Yes"
        cancelText="No"
        onAddClick={() => {
          handleAlertYesClick(
            selectedClinicianData?.status === 1 ? false : true,
          );
        }}
        onNoClick={handleAlertNoClick}
      />
    </div>
  );
};

export default EditClinician;
