const json2023 = {
  sectionId: 8,
  sectionName: "Preventive Care",
  isEditable: false,
  questions: [
    {
      id: 7,
      name: "inThePastThreeYearsHaveYouHad",
      label: "In the past three years have you had?",
      type: "label",
      editToggle: false,
      xs: 12,
      required: "no",
      placeholder: "",
      defaultValue: "",
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      questionId: 1,
    },

    {
      id: 11,
      name: "inThePastThreeYearsHaveYouHad",
      label: "In the past three years have you had?",
      editToggle: false,
      type: "datatable",
      tablelist: [
        [
          {
            id: 1,
            label: "Allergies",
            name: "HRAForm_Allergies",
            columns: [
              {
                label: "Substance",
                type: "text",
              },
              {
                label: "Reaction",
                type: "text",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
        [
          {
            id: 2,
            label: "BMI Index Chart",
            name: "HRAForm_BMI_Index_Chart",
            columns: [
              {
                label: "BMI",
                type: "text",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 3,
            label: "Counter Medications",
            name: "HRAForm_Counter_Medications",
            columns: [
              {
                label: "Date",
                type: "date",
              },
              {
                label: "Description",
                type: "text",
              },
              {
                label: "Dose Per Units",
                type: "text",
              },
              {
                label: "Route",
                type: "select",
              },
              {
                label: "Frequency",
                type: "text",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
        [
          {
            id: 4,
            label: "Medical History Specialists",
            name: "HRAForm_Medical_History_Speciality",
            columns: [
              {
                label: "Member Specialist",
                type: "select",
              },
              {
                label: "Speciality",
                type: "text",
              },
              {
                label: "For",
                type: "text",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
        [
          {
            id: 5,
            label: "Medications",
            name: "HRAForm_Medications",
            columns: [
              {
                label: "Diagnoses",
                type: "text",
              },
              {
                label: "label Name",
                type: "select",
              },
              {
                label: "Dose Per Units",
                type: "text",
              },
              {
                label: "Route",
                type: "select",
              },
              {
                label: "Frequency",
                type: "select",
              },
              {
                label: "Prescribing Physician",
                type: "text",
              },
              {
                label: "Status",
                type: "togglebutton",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
        [
          {
            id: 6,
            label: "Mini Cog Versions",
            name: "HRAForm_Minicog_Version",
            columns: [
              {
                label: "Version 1",
                type: "text",
              },
              {
                label: "Version 2",
                type: "text",
              },
              {
                label: "Version 3",
                type: "text",
              },
              {
                label: "Version 4",
                type: "text",
              },
              {
                label: "Version 5",
                type: "text",
              },
              {
                label: "Version 6",
                type: "text",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 7,
            label: "Preventive Care",
            name: "HRAForm_Preventive_Care",
            columns: [
              {
                label: "Screen",
                type: "text",
              },
              {
                label: "Answer",
                type: "select",
              },
              {
                label: "Date",
                type: "date",
              },
              {
                label: "Method",
                type: "text",
              },
              {
                label: "Recommendation",
                type: "checkbox",
              },
              {
                label: "Education Completed",
                type: "checkbox",
              },
            ],
            isReadOnly: true,
          },
          true,
        ],
        [
          {
            id: 8,
            label: "Previously Documented Conditions",
            name: "HRAForm_Previously_Documented_Conditions",
            columns: [
              {
                label: "Diagnosis",
                type: "text",
              },
              {
                label: "Prior HCC",
                type: "text",
              },
              {
                label: "Diagnosis Code",
                type: "text",
              },
              {
                label: "Date of Service",
                type: "date",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
        [
          {
            id: 9,
            label: "Prior Assessments",
            name: "HRAForm_Prior_Assessments",
            columns: [
              {
                label: "Date Time",
                type: "date",
              },
              {
                label: "Document",
                type: "button",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 10,
            label: "Stages Of Kidney Disease",
            name: "HRAForm_Stage_Of_Kidney_Disease",
            columns: [
              {
                label: "Stages",
                type: "text",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 11,
            label: "Alcohol Usage",
            name: "HRAForm_Alcohol_Usage",
            columns: [
              {
                label: "How many drinks",
                type: "select",
              },
              {
                label: "How often",
                type: "select",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 12,
            name: "Family History",
            columns: [
              {
                label: "Family Member",
                type: "select",
              },
              {
                label: "Medical Condition",
                type: "text",
              },
              {
                label: "Cause of Death",
                type: "text",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
      ],
      xs: 12,
      required: "no",
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 2,
    },

    {
      id: 6,
      name: "oneTimeScreenForAbdominal",
      type: "options_tree",
      label:
        "One time screen for Abdominal Aortic Aneurysm if male with history of smoking, age 65-75",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 1,
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          isConfigured: true,
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "oneTimeScreenForAbdominaleducationProvided",
              type: "options_tree",
              label: "Education Provided",
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 3,
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  label: "Yes",
                  value: "yes",
                  isConfigured: true,
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "No",
                  value: "no",
                  type: "toggle_button",
                  depth: 3,
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "NA",
                  value: "na",
                  type: "toggle_button",
                  depth: 3,
                },
              ],
              subQuestionId: 1,
              editToggle: false,
            },
          ],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "No",
          value: "no",
          type: "toggle_button",
          depth: 1,
        },
        {
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "NA",
          value: "na",
          type: "toggle_button",
          depth: 1,
        },
      ],
      questionId: 3,
      editToggle: false,
    },
    {
      id: 6,
      name: "oneTimeScreenForHepatitis",
      type: "options_tree",
      label: "One time screen for Hepatitis C if born between 1945-1965",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 1,
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          isConfigured: true,
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "oneTimeScreenForHepatitiseducationProvided",
              type: "options_tree",
              label: "Education Provided",
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 3,
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  label: "Yes",
                  value: "yes",
                  isConfigured: true,
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "No",
                  value: "no",
                  type: "toggle_button",
                  depth: 3,
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "NA",
                  value: "na",
                  type: "toggle_button",
                  depth: 3,
                },
              ],
              subQuestionId: 1,
              editToggle: false,
            },
          ],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "No",
          value: "no",
          type: "toggle_button",
          depth: 1,
        },
        {
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "NA",
          value: "na",
          type: "toggle_button",
          depth: 1,
        },
      ],
      questionId: 4,
      editToggle: false,
    },
    {
      id: 10,
      name: "abdominalRec",
      label: "Recommendations",
      placeholder: "",
      editToggle: false,
      defaultValue: "",
      type: "checkbox",
      options: [["Abdominal Aneurysm Screening", "abnominalAneurysmScreening"]],
      xs: 12,
      required: false,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      questionId: 5,
    },

    {
      id: 10,
      name: "hepatitis",
      label: "",
      placeholder: "",
      editToggle: false,
      defaultValue: "",
      type: "checkbox",
      options: [["Hepatitis C Screening", "hepatitisCScreening"]],
      xs: 12,
      required: false,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      questionId: 6,
    },
  ],
};

const json2024 = {
  sectionId: 8,
  sectionName: "Preventive Care",
  isEditable: false,
  questions: [
    {
      id: 7,
      name: "inThePastThreeYearsHaveYouHad",
      label: "In the past three years have you had?",
      type: "label",
      editToggle: false,
      xs: 12,
      required: "no",
      placeholder: "",
      defaultValue: "",
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      questionId: 1,
    },

    {
      id: 11,
      name: "inThePastThreeYearsHaveYouHad",
      label: "In the past three years have you had?",
      editToggle: false,
      type: "datatable",
      tablelist: [
        [
          {
            id: 1,
            label: "Allergies",
            name: "HRAForm_Allergies",
            columns: [
              {
                label: "Substance",
                type: "text",
              },
              {
                label: "Reaction",
                type: "text",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
        [
          {
            id: 2,
            label: "BMI Index Chart",
            name: "HRAForm_BMI_Index_Chart",
            columns: [
              {
                label: "BMI",
                type: "text",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 3,
            label: "Counter Medications",
            name: "HRAForm_Counter_Medications",
            columns: [
              {
                label: "Date",
                type: "date",
              },
              {
                label: "Description",
                type: "text",
              },
              {
                label: "Dose Per Units",
                type: "text",
              },
              {
                label: "Route",
                type: "select",
              },
              {
                label: "Frequency",
                type: "text",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
        [
          {
            id: 4,
            label: "Medical History Specialists",
            name: "HRAForm_Medical_History_Speciality",
            columns: [
              {
                label: "Member Specialist",
                type: "select",
              },
              {
                label: "Speciality",
                type: "text",
              },
              {
                label: "For",
                type: "text",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
        [
          {
            id: 5,
            label: "Medications",
            name: "HRAForm_Medications",
            columns: [
              {
                label: "Diagnoses",
                type: "text",
              },
              {
                label: "label Name",
                type: "select",
              },
              {
                label: "Dose Per Units",
                type: "text",
              },
              {
                label: "Route",
                type: "select",
              },
              {
                label: "Frequency",
                type: "select",
              },
              {
                label: "Prescribing Physician",
                type: "text",
              },
              {
                label: "Status",
                type: "togglebutton",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
        [
          {
            id: 6,
            label: "Mini Cog Versions",
            name: "HRAForm_Minicog_Version",
            columns: [
              {
                label: "Version 1",
                type: "text",
              },
              {
                label: "Version 2",
                type: "text",
              },
              {
                label: "Version 3",
                type: "text",
              },
              {
                label: "Version 4",
                type: "text",
              },
              {
                label: "Version 5",
                type: "text",
              },
              {
                label: "Version 6",
                type: "text",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 7,
            label: "Preventive Care",
            name: "HRAForm_Preventive_Care",
            columns: [
              {
                label: "Screen",
                type: "text",
              },
              {
                label: "Answer",
                type: "select",
              },
              {
                label: "Date",
                type: "date",
              },
              {
                label: "Method",
                type: "text",
              },
              {
                label: "Recommendation",
                type: "checkbox",
              },
              {
                label: "Education Completed",
                type: "checkbox",
              },
            ],
            isReadOnly: true,
          },
          true,
        ],
        [
          {
            id: 8,
            label: "Previously Documented Conditions",
            name: "HRAForm_Previously_Documented_Conditions",
            columns: [
              {
                label: "Diagnosis",
                type: "text",
              },
              {
                label: "Prior HCC",
                type: "text",
              },
              {
                label: "Diagnosis Code",
                type: "text",
              },
              {
                label: "Date of Service",
                type: "date",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
        [
          {
            id: 9,
            label: "Prior Assessments",
            name: "HRAForm_Prior_Assessments",
            columns: [
              {
                label: "Date Time",
                type: "date",
              },
              {
                label: "Document",
                type: "button",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 10,
            label: "Stages Of Kidney Disease",
            name: "HRAForm_Stage_Of_Kidney_Disease",
            columns: [
              {
                label: "Stages",
                type: "text",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 11,
            label: "Alcohol Usage",
            name: "HRAForm_Alcohol_Usage",
            columns: [
              {
                label: "How many drinks",
                type: "select",
              },
              {
                label: "How often",
                type: "select",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 12,
            name: "Family History",
            columns: [
              {
                label: "Family Member",
                type: "select",
              },
              {
                label: "Medical Condition",
                type: "text",
              },
              {
                label: "Cause of Death",
                type: "text",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
      ],
      xs: 12,
      required: "no",
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 2,
    },

    {
      id: 6,
      name: "oneTimeScreenForAbdominal",
      type: "options_tree",
      label:
        "One time screen for Abdominal Aortic Aneurysm if male with history of smoking, age 65-75",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 1,
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          isConfigured: true,
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "oneTimeScreenForAbdominaleducationProvided",
              type: "options_tree",
              label:
                "Education Provided/recommendation to discuss with Provider",
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 3,
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  label: "Yes",
                  value: "yes",
                  isConfigured: true,
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "No",
                  value: "no",
                  type: "toggle_button",
                  depth: 3,
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "NA",
                  value: "na",
                  type: "toggle_button",
                  depth: 3,
                },
              ],
              subQuestionId: 1,
              editToggle: false,
            },
          ],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "No",
          value: "no",
          type: "toggle_button",
          depth: 1,
        },
        {
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "NA",
          value: "na",
          type: "toggle_button",
          depth: 1,
        },
      ],
      questionId: 3,
      editToggle: false,
    },
    {
      id: 6,
      name: "oneTimeScreenForHepatitis",
      type: "options_tree",
      label: "One time screen for Hepatitis C if born between 1945-1965",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 1,
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          isConfigured: true,
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "oneTimeScreenForHepatitiseducationProvided",
              type: "options_tree",
              label:
                "Education Provided/recommendation to discuss with Provider",
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 3,
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  label: "Yes",
                  value: "yes",
                  isConfigured: true,
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "No",
                  value: "no",
                  type: "toggle_button",
                  depth: 3,
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "NA",
                  value: "na",
                  type: "toggle_button",
                  depth: 3,
                },
              ],
              subQuestionId: 1,
              editToggle: false,
            },
          ],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "No",
          value: "no",
          type: "toggle_button",
          depth: 1,
        },
        {
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "NA",
          value: "na",
          type: "toggle_button",
          depth: 1,
        },
      ],
      questionId: 4,
      editToggle: false,
    },
  ],
};
export const getInputsData = (client = "VPHP", year = "2023") => {
  let formLayoutData = {};
  if (year === "2023") {
    formLayoutData = {
      ...formLayoutData,
      ...json2023,
    };
  }
  if (year === "2024") {
    formLayoutData = {
      ...formLayoutData,
      ...json2024,
    };
  }

  return formLayoutData;
};
