import { useContext, useState } from "react";
import _ from "lodash";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import moment from "moment";
import toast from "react-hot-toast";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useForm, Controller } from "react-hook-form";
import Toaster from "../../toaster/toaster";
import Labels from "../../../resource_files/Labels.resx";
import { getAllClients } from "../../../api/clientsApi";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { SelectDropdown } from "../../select/select";
import * as React from "react";
import { useUserContext } from "../../../contexts/UserContext";
import { zcs } from "zcs";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import { getApi, postApi } from "../../../utils/axiosApiWrapper";
import MuiInput from "../../muiinput/MuiInput";
import Loader from "../../Loader/Loader";

function SchedulerFilterComp({
  hraStatusOptions,
  visitTypeOptions,
  clinicianData,
  open,
  onClose,
  handleFilter,
}) {
  const {
    programTypesOptions,
    lobTypesOptions,
    lobOptionData,
    apiResponseData,
    clinicianDataList,
    hraStatusData,
    visitTypeOptionsData,
    schedulerDataList,
    setSchedulerDataList,
  } = useUserContext();
  const dialogBtn = {
    width: "131px",
    height: "32px",
    flexShrink: "0",
    borderRadius: "20px",
    background: "var(--mainTheme)",
    color: "white",
    "&:hover": {
      boxShadow: 8,
      color: "var(--mainTheme)",
    },
  };
  const [isLoading, setIsLoading] = React.useState(false);
  const [citiesOption, setCitiesOption] = useState([]);
  const [programOptions, setProgramOptions] = useState([]);
  const [callLogStatusOptionsData, setCallLogStatusOptionsData] = useState([]);
  const [reportStatusOptionsData, setReportStatusOptionsData] = useState([]);

  const [toasterMessage, setToasterMessage] = useState("");
  const [toasterOpen, setToasterOpen] = useState(false);
  const [toasterSeverity, setToasterSeverity] = useState(Labels.SUCCESS);
  const [scheduleDateFrom, setScheduleDateFrom] = useState();
  const [scheduleDateTo, setScheduleDateTo] = useState();
  const [selectedProgram, setSelectedProgram] = useState([]);
  const [selectedLob, setSelectedLob] = useState([]);
  const [selectedHraStatus, setSelectedHraStatus] = useState([]);
  const [selectedCallLogStatus, setSelectedCallLogStatus] = useState([]);
  const [selectedAssignedScheduler, setSelectedAssignScheduler] = useState([]);
  const [selectedAssignClinician, setSelectedAssignedClinician] = useState([]);
  const [selectedReportStatus, setSelectedReportStatus] = useState([]);
  const [selectedEyeExam, setSelectedEyeExam] = useState([]);
  const [selectedCallBackStatus, setSelectedCallBackStatus] = useState([]);
  const [callBackFromDate, setCallBackFromDate] = useState(null);
  const [callBackToDate, setCallBackToDate] = useState(null);
  const [nextOutReachFromDate, setNextOutReachFromDate] = useState(null);
  const [nextOutReachToDate, setNextOutReachToDate] = useState(null);
  const [selectedScreening, setSelectedScreening] = useState([]);
  const [selectedScreeningIha, setSelectedScreeningIha] = useState([]);
  const [selectedCity, setSelectedCity] = useState([]);
  const storedPlan = localStorage.getItem("clientPlan");
  const storedYear = localStorage.getItem("clientYear");
  const getMemberApiData = async () => {
    await getAllClients()
      .then((response) => {
        const allMappingData = response?.data?.data.map((data) => data);
        let selectedClientBasedObjectValue = _.find(allMappingData, {
          plan: storedPlan,
        });
        setProgramOptions(selectedClientBasedObjectValue?.program);
      })
      .catch((error) => {
        return;
      });
  };
  const screeningOptions = [
    "Eye Exam",
    "HBA1C",
    "Microalbumin",
    "FOBT",
    "DEXA",
    "PAD",
    "LDL",
  ];
  const screeningIHAOptions = [
    { id: "1", value: "Screening" },
    { id: "2", value: "HRA" },
  ];

  const eyeExamOptions = [
    { id: "1", value: "No" },
    { id: "2", value: "Yes" },
  ];

  const callBackExamOptions = ["Due", "OverDue"];
  const storedPlanId = localStorage.getItem("selectedClientId") || "";
  const callLog = async () => {
    try {
      const response = await getApi(`/call-log-status`);
      const data = await response.data.data;
      console.log("callLogData", data);
      setCallLogStatusOptionsData(data);
    } catch {
      return;
    }
  };

  const reportStatus = async () => {
    try {
      const response = await getApi("/reportstatus");
      const data = await response.data.data;
      setReportStatusOptionsData(response?.data?.data);
    } catch {
      return;
    }
  };

  const getAllCities = async () => {
    try {
      const response = await getApi("/scheduler/all-cities");
      const data = response.data.data;
      console.log(
        "city",
        data?.filter((itm) => Boolean(itm)),
      );
      setCitiesOption(response?.data?.data?.filter((itm) => Boolean(itm)));
    } catch (e) {
      console.log(e);
      return;
    }
  };

  const fetchAllSchedulerDataInAdmin = async () => {
    if (!_.isEmpty(storedPlanId)) {
      const response = await postApi("/users/get-users-by-program-roleId", {
        planId: storedPlanId,
      });
      const clientPlanFromLocalStorage = localStorage.getItem("clientPlan");
      setSchedulerDataList(response?.data?.data[0]?.scheduler);
    }
  };
  const fetchAllSchedulerDataForScheduler = async () => {
    try {
      const response = await postApi("/users/get-users-by-roleId", {
        planId: storedPlanId,
        roleId: "3,4",
      });
      setSchedulerDataList(response?.data?.data);
    } catch (error) {
      return;
    }
  };

  const handleClose = () => {
    onClose();
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  React.useEffect(() => {
    // fetchAllSchedulerData();
    fetchAllSchedulerDataForScheduler();
    fetchAllSchedulerDataInAdmin();
    getMemberApiData();
    callLog();
    reportStatus();
    getAllCities();
    return () => {
      handleClear();
    };
  }, [storedPlanId]);

  const handleClear = () => {
    setScheduleDateFrom(null);
    setScheduleDateTo(null);
    setSelectedProgram([]);
    setSelectedLob([]);
    setSelectedHraStatus([]);
    setSelectedCallLogStatus([]);
    setSelectedAssignScheduler([]);
    setSelectedAssignedClinician([]);
    setSelectedReportStatus([]);
    setSelectedEyeExam([]);
    setSelectedCallBackStatus([]);
    setCallBackFromDate(null);
    setCallBackToDate(null);
    setNextOutReachFromDate(null);
    setNextOutReachToDate(null);
    setSelectedScreening([]);
    setSelectedScreeningIha([]);
    setSelectedCity([]);
  };

  const handleApplyFilter = async () => {
    console.log("callbackFromDate", callBackFromDate);

    const payload = {
      scheduleDateFrom: scheduleDateFrom
        ? moment(scheduleDateFrom).format("YYYY-MM-DD")
        : null,
      scheduleDateTo: scheduleDateTo
        ? moment(scheduleDateTo).format("YYYY-MM-DD")
        : null,
      callbackFromDate: callBackFromDate
        ? moment(callBackFromDate).format("YYYY-MM-DD")
        : null,
      callbackToDate: callBackToDate
        ? moment(callBackToDate).format("YYYY-MM-DD")
        : null,
      program: selectedProgram?.map((itm) => itm?.name).join(","),
      lob: selectedLob?.join(","),
      nextOutreachFromDate: nextOutReachFromDate
        ? moment(nextOutReachFromDate).format("YYYY-MM-DD")
        : null,
      nextOutreachToDate: nextOutReachToDate
        ? moment(nextOutReachToDate).format("YYYY-MM-DD")
        : null,
      hrastatus: selectedHraStatus?.map((itm) => itm?.id).join(","),
      nurseId: selectedAssignClinician?.map((itm) => itm?.userid).join(","),
      schedulerId: selectedAssignedScheduler
        ?.map((itm) => itm?.userid)
        .join(","),
      callResultStatus: selectedCallLogStatus
        ?.map((itm) => itm?.statusName)
        .join(","),
      callBackStatus: selectedCallBackStatus?.join(","),
      eyeExam: selectedEyeExam?.map((itm) => itm?.id).join(","),
      screening: selectedScreening?.join(","),
      status: selectedReportStatus?.map((itm) => itm?.id).join(","),
      screeningIha: selectedScreeningIha?.map((itm) => itm?.id).join(","),
      city: selectedCity?.join(","),
    };
    const finalPayload = {};
    Object.keys(payload)?.map((itm) => {
      console.log("payload.itm", payload[itm]);
      if (payload[itm]) {
        finalPayload[itm] = payload[itm];
      }
    });
    console.log("finalPayload", finalPayload);
    const data = await handleFilter(finalPayload);
    onClose();
    handleClear();
  };

  const onCloseToaster = () => {
    setToasterOpen(false);
    setToasterMessage("");
  };

  const handleChangeProgram = (key, value) => {
    // console.log("key", key);
    // console.log("value", value);

    // const _program = value?.map((itm) => {
    //   return {
    //     name: itm?.name,
    //     id: itm?.id,
    //   };
    // });

    setSelectedProgram(value);
  };

  const handleChangeLob = (key, value) => {
    setSelectedLob(value);
  };

  const handleChangeHraStatus = (key, value) => {
    setSelectedHraStatus(value);
  };

  const handleChangeCallLogStatus = (value) => {
    // console.log("value", value);
    // const _callLogStatus = value?.map((itm) => {
    //   return {
    //     statusName: itm?.statusName,
    //     id: itm?.id,
    //   };
    // });

    // setSelectedCallLogStatus(_callLogStatus);
    setSelectedCallLogStatus(value);
  };

  const handleChangeAssignScheduler = (value) => {
    console.log("value", value);
    setSelectedAssignScheduler(value);
  };

  const handleChangeAssignClinician = (value) => {
    console.log("value", value);
    setSelectedAssignedClinician(value);
  };

  const handleChangeReportStatus = (value) => {
    setSelectedReportStatus(value);
  };

  const handleChangeEyeExam = (value) => {
    setSelectedEyeExam(value);
  };

  const handleChangeCallBackStatus = (value) => {
    setSelectedCallBackStatus(value);
  };
  const handleChangeScreening = (value) => {
    setSelectedScreening(value);
  };

  const handleChangeScreeningIha = (value) => {
    setSelectedScreeningIha(value);
  };
  const handleChangeCityOption = (value) => {
    setSelectedCity(value);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
      >
        <DialogTitle
          sx={{
            background: "var(--mainTheme)",
            fontSize: "20px",
            color: "white",
            fontFamily: "Inter,sans-serif",
            height: "40px",
            display: "flex",
            alignItems: "center",
          }}
          id="alert-dialog-title"
        >
          Filter
        </DialogTitle>
        <DialogContent sx={{ paddingTop: "20px !important" }}>
          <Grid container spacing={2} sx={{ padding: "16px" }}>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              xl={4}
              // sx={{ padding: "10px" }}
            >
              <InputLabel>Scheduled From</InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DateTimePicker"]}>
                  <DatePicker
                    // label="Scheduled From:"
                    id="datetime-local"
                    format="MM/DD/YYYY"
                    views={["year", "month", "day"]}
                    ampm
                    // closeOnSelect={false}
                    variant="outlined"
                    //autoOk={false}
                    value={scheduleDateFrom ? dayjs(scheduleDateFrom) : null}
                    onChange={(date) => setScheduleDateFrom(date)}
                    slotProps={{
                      textField: {
                        disabled: true,
                        variant: "outlined",
                      },
                      actionBar: {
                        actions: ["clear"],
                      },
                      popper: { placement: "auto-start" },
                    }}
                    sx={{
                      "&.MuiTextField-root .MuiFormLabel-root": {
                        background: "var(--mainTheme200) !important",
                        fontSize: "14px",
                      },
                      "& .MuiOutlinedInput-root": {
                        border: "1px solid var(--mainTheme)",
                        fontSize: "13px",
                      },
                      "& .MuiInputBase-input.MuiOutlinedInput-input": {
                        padding: "15px 2px !important",
                        fontSize: "13px",
                      },

                      "&.MuiTextField-root": {
                        minWidth: "1px",
                        paddingLeft: "4px",
                      },
                      "& .MuiIconButton-root": {
                        justifyContent: "flex-end",
                      },
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              xl={4}
              // sx={{ padding: "10px" }}
            >
              <InputLabel>Scheduled To</InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DateTimePicker"]}>
                  <DatePicker
                    //  label="Scheduled To:"
                    id="datetime-local"
                    format="MM/DD/YYYY"
                    views={["year", "month", "day"]}
                    ampm
                    // closeOnSelect={false}
                    variant="outlined"
                    //autoOk={false}
                    value={scheduleDateTo ? dayjs(scheduleDateTo) : null}
                    onChange={(date) => setScheduleDateTo(date)}
                    slotProps={{
                      textField: {
                        disabled: true,
                        variant: "outlined",
                      },
                      actionBar: {
                        actions: ["clear"],
                      },
                      popper: { placement: "auto-start" },
                    }}
                    sx={{
                      "&.MuiTextField-root .MuiFormLabel-root": {
                        background: "var(--mainTheme200) !important",
                        fontSize: "14px",
                      },
                      "& .MuiOutlinedInput-root": {
                        border: "1px solid var(--mainTheme)",
                        fontSize: "13px",
                      },
                      "& .MuiInputBase-input.MuiOutlinedInput-input": {
                        padding: "15px 2px !important",
                        fontSize: "13px",
                      },

                      "&.MuiTextField-root": {
                        minWidth: "1px",
                        paddingLeft: "4px",
                      },
                      "& .MuiIconButton-root": {
                        justifyContent: "flex-end",
                      },
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
            {/* for Program */}
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              xl={4}
              // sx={{ padding: "10px" }}
            >
              <InputLabel>Program</InputLabel>
              <Autocomplete
                multiple
                clearOnBlur
                id="program-autocomplete"
                style={{ marginTop: "7px", marginLeft: "0px" }}
                options={programOptions}
                getOptionLabel={(option) => option?.name}
                getOptionSelected={(option, value) => option.name === value}
                value={selectedProgram}
                //inputValue={programInputValue}
                onChange={(event, newValue) => {
                  // setProgramInputValue(newValue);
                  handleChangeProgram("program", newValue);
                }}
                freeSolo
                renderInput={(params) => (
                  <TextField
                    {...params}
                    //label="Program"
                    className="inputAddMember"
                    required={true}
                    //   onChange={(e) => {
                    //   //  setProgramInputValue(e.target.value);
                    //   }}
                  />
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      key={option.id}
                      label={option.name}
                      {...getTagProps({ index })}
                    />
                  ))
                }
              />
            </Grid>
            {/* for lob */}
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              xl={4}
              // sx={{ padding: "10px" }}
            >
              <InputLabel>LOB</InputLabel>
              <Autocomplete
                multiple
                clearOnBlur
                id="lob-autocomplete"
                style={{ marginTop: "7px", marginLeft: "0px" }}
                options={lobOptionData}
                getOptionLabel={(option) => option}
                getOptionSelected={(option, value) => option === value}
                value={selectedLob}
                //inputValue={programInputValue}
                onChange={(event, newValue) => {
                  // setProgramInputValue(newValue);
                  handleChangeLob("lob", newValue);
                }}
                freeSolo
                renderInput={(params) => (
                  <TextField
                    {...params}
                    // label="LOB:"
                    className="inputAddMember"
                    required={true}
                    //   onChange={(e) => {
                    //   //  setProgramInputValue(e.target.value);
                    //   }}
                  />
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      key={option}
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ))
                }
              />
            </Grid>
            {/* for hrastatus */}
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              xl={4}
              // sx={{ padding: "10px" }}
            >
              <InputLabel>HRA Status</InputLabel>
              <Autocomplete
                multiple
                clearOnBlur
                id="lob-autocomplete"
                style={{ marginTop: "7px", marginLeft: "0px" }}
                options={hraStatusData?.sort((a, b) => {
                  if (a.statusName < b.statusName) {
                    return -1;
                  }
                  if (a.statusName > b.statusName) {
                    return 1;
                  }
                  return 0;
                })}
                getOptionLabel={(option) => option?.statusName}
                getOptionSelected={(option, value) => option.id === value}
                value={selectedHraStatus}
                //inputValue={programInputValue}
                onChange={(event, newValue) => {
                  // setProgramInputValue(newValue);
                  handleChangeHraStatus("hraStatus", newValue);
                }}
                freeSolo
                renderInput={(params) => (
                  <TextField
                    {...params}
                    //label="HRA Status:"
                    className="inputAddMember"
                    required={true}
                    //   onChange={(e) => {
                    //   //  setProgramInputValue(e.target.value);
                    //   }}
                  />
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      key={option.id}
                      label={option.statusName}
                      {...getTagProps({ index })}
                    />
                  ))
                }
              />
            </Grid>
            {/* for call Log */}
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              xl={4}
              // sx={{ padding: "10px" }}
            >
              <InputLabel>Call Result</InputLabel>
              <Autocomplete
                multiple
                clearOnBlur
                id="program-autocomplete"
                style={{ marginTop: "7px", marginLeft: "0px" }}
                options={[
                  ...callLogStatusOptionsData,
                  [
                    ...callLogStatusOptionsData,
                    { statusName: "Left Voicemail", id: 15, isActive: 1 },
                    { statusName: "Mailbox full", id: 16, isActive: 1 },
                    { statusName: "No VM Setup", id: 17, isActive: 1 },
                  ],
                ]?.sort((a, b) => {
                  if (a.statusName < b.statusName) {
                    return -1;
                  }
                  if (a.statusName > b.statusName) {
                    return 1;
                  }
                  return 0;
                })}
                getOptionLabel={(option) => option?.statusName}
                getOptionSelected={(option, value) =>
                  option.statusName === value
                }
                value={selectedCallLogStatus}
                //inputValue={programInputValue}
                onChange={(event, newValue) => {
                  // setProgramInputValue(newValue);
                  handleChangeCallLogStatus(newValue);
                }}
                freeSolo
                renderInput={(params) => (
                  <TextField
                    {...params}
                    // label="Call Result:"
                    className="inputAddMember"
                    required={true}
                    //   onChange={(e) => {
                    //   //  setProgramInputValue(e.target.value);
                    //   }}
                  />
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      key={option.id}
                      label={option.statusName}
                      {...getTagProps({ index })}
                    />
                  ))
                }
              />
            </Grid>
            {/* for assign scheduler  */}
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              xl={4}
              // sx={{ padding: "10px" }}
            >
              <InputLabel>Assigned Scheduler</InputLabel>
              <Autocomplete
                multiple
                clearOnBlur
                id="program-autocomplete"
                style={{ marginTop: "7px", marginLeft: "0px" }}
                options={schedulerDataList}
                getOptionLabel={(option) =>
                  `${option?.firstName} ${option?.lastName}`
                }
                getOptionSelected={(option, value) => option.userid === value}
                value={selectedAssignedScheduler}
                //inputValue={programInputValue}
                onChange={(event, newValue) => {
                  // setProgramInputValue(newValue);
                  handleChangeAssignScheduler(newValue);
                }}
                freeSolo
                renderInput={(params) => (
                  <TextField
                    {...params}
                    //label="Assigned Scheduler:"
                    className="inputAddMember"
                    required={true}
                    //   onChange={(e) => {
                    //   //  setProgramInputValue(e.target.value);
                    //   }}
                  />
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      key={option.userid}
                      label={`${option?.firstName} ${option?.lastName}`}
                      {...getTagProps({ index })}
                    />
                  ))
                }
              />
            </Grid>
            {/* for assigned clinician */}
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              xl={4}
              // sx={{ padding: "10px" }}
            >
              <InputLabel>Assigned Clinician</InputLabel>
              <Autocomplete
                multiple
                clearOnBlur
                id="program-autocomplete"
                style={{ marginTop: "7px", marginLeft: "0px" }}
                options={clinicianDataList}
                getOptionLabel={(option) =>
                  `${option?.firstName} ${option?.lastName}`
                }
                getOptionSelected={(option, value) => option.userid === value}
                value={selectedAssignClinician}
                //inputValue={programInputValue}
                onChange={(event, newValue) => {
                  // setProgramInputValue(newValue);
                  handleChangeAssignClinician(newValue);
                }}
                freeSolo
                renderInput={(params) => (
                  <TextField
                    {...params}
                    //label="Assigned Clinician:"
                    className="inputAddMember"
                    required={true}
                    //   onChange={(e) => {
                    //   //  setProgramInputValue(e.target.value);
                    //   }}
                  />
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      key={option.userid}
                      label={`${option?.firstName} ${option?.lastName}`}
                      {...getTagProps({ index })}
                    />
                  ))
                }
              />
            </Grid>
            {/* for city */}

            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              xl={4}
              // sx={{ padding: "10px" }}
            >
              <InputLabel>City</InputLabel>
              <Autocomplete
                multiple
                clearOnBlur
                id="lob-autocomplete"
                style={{ marginTop: "7px", marginLeft: "0px" }}
                options={citiesOption}
                getOptionLabel={(option) => option}
                getOptionSelected={(option, value) => option === value}
                value={selectedCity}
                //inputValue={programInputValue}
                onChange={(event, newValue) => {
                  // setProgramInputValue(newValue);
                  handleChangeCityOption(newValue);
                }}
                freeSolo
                renderInput={(params) => (
                  <TextField
                    {...params}
                    // label="LOB:"
                    className="inputAddMember"
                    required={true}
                    //   onChange={(e) => {
                    //   //  setProgramInputValue(e.target.value);
                    //   }}
                  />
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      key={option}
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ))
                }
              />
            </Grid>

            {/* for status */}
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              xl={4}
              // sx={{ padding: "10px" }}
            >
              <InputLabel>Status</InputLabel>
              <Autocomplete
                multiple
                clearOnBlur
                id="program-autocomplete"
                style={{ marginTop: "7px", marginLeft: "0px" }}
                options={reportStatusOptionsData
                  ?.sort((a, b) => {
                    if (a.statusName < b.statusName) {
                      return -1;
                    }
                    if (a.statusName > b.statusName) {
                      return 1;
                    }
                    return 0;
                  })
                  ?.filter((itm) => itm?.statusName != "Other")}
                getOptionLabel={(option) => option.statusName}
                getOptionSelected={(option, value) => option.id === value}
                value={selectedReportStatus}
                //inputValue={programInputValue}
                onChange={(event, newValue) => {
                  // setProgramInputValue(newValue);
                  handleChangeReportStatus(newValue);
                }}
                freeSolo
                renderInput={(params) => (
                  <TextField
                    {...params}
                    // label="Status:"
                    className="inputAddMember"
                    required={true}
                    //   onChange={(e) => {
                    //   //  setProgramInputValue(e.target.value);
                    //   }}
                  />
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      key={option.userid}
                      label={option.statusName}
                      {...getTagProps({ index })}
                    />
                  ))
                }
              />
            </Grid>

            {/* for Eye Exam */}

            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              xl={4}
              // sx={{ padding: "10px" }}
            >
              <InputLabel>Eye Exam</InputLabel>
              <Autocomplete
                multiple
                clearOnBlur
                id="program-autocomplete"
                style={{ marginTop: "7px", marginLeft: "0px" }}
                options={eyeExamOptions}
                getOptionLabel={(option) => option.value}
                getOptionSelected={(option, value) => option.id === value}
                value={selectedEyeExam}
                //inputValue={programInputValue}
                onChange={(event, newValue) => {
                  // setProgramInputValue(newValue);
                  handleChangeEyeExam(newValue);
                }}
                freeSolo
                renderInput={(params) => (
                  <TextField
                    {...params}
                    //  label="Eye Exam:"
                    className="inputAddMember"
                    required={true}
                    //   onChange={(e) => {
                    //   //  setProgramInputValue(e.target.value);
                    //   }}
                  />
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      key={option}
                      label={option.value}
                      {...getTagProps({ index })}
                    />
                  ))
                }
              />
            </Grid>

            {/* for callBack Status  */}
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              xl={4}
              // sx={{ padding: "10px" }}
            >
              <InputLabel>Call Back Status</InputLabel>
              <Autocomplete
                multiple
                clearOnBlur
                id="program-autocomplete"
                style={{ marginTop: "7px", marginLeft: "0px" }}
                options={callBackExamOptions}
                getOptionLabel={(option) => option}
                getOptionSelected={(option, value) => option === value}
                value={selectedCallBackStatus}
                //inputValue={programInputValue}
                onChange={(event, newValue) => {
                  // setProgramInputValue(newValue);
                  handleChangeCallBackStatus(newValue);
                }}
                freeSolo
                renderInput={(params) => (
                  <TextField
                    {...params}
                    // label="Call Back Status:"
                    className="inputAddMember"
                    required={true}
                    //   onChange={(e) => {
                    //   //  setProgramInputValue(e.target.value);
                    //   }}
                  />
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      key={option}
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ))
                }
              />
            </Grid>

            {/* for call Back From */}

            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              xl={4}
              // sx={{ padding: "10px" }}
            >
              <InputLabel>Call Back From</InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DateTimePicker"]}>
                  <DatePicker
                    // label="Call Back From:"
                    id="datetime-local"
                    format="MM/DD/YYYY"
                    views={["year", "month", "day"]}
                    ampm
                    // closeOnSelect={false}
                    variant="outlined"
                    //autoOk={false}
                    value={callBackFromDate ? dayjs(callBackFromDate) : null}
                    onChange={(date) => setCallBackFromDate(date)}
                    slotProps={{
                      textField: {
                        disabled: true,
                        variant: "outlined",
                      },
                      actionBar: {
                        actions: ["clear"],
                      },
                      popper: { placement: "auto-start" },
                    }}
                    sx={{
                      "&.MuiTextField-root .MuiFormLabel-root": {
                        background: "var(--mainTheme200) !important",
                        fontSize: "14px",
                      },
                      "& .MuiOutlinedInput-root": {
                        border: "1px solid var(--mainTheme)",
                        fontSize: "13px",
                      },
                      "& .MuiInputBase-input.MuiOutlinedInput-input": {
                        padding: "15px 2px !important",
                        fontSize: "13px",
                      },

                      "&.MuiTextField-root": {
                        minWidth: "1px",
                        paddingLeft: "4px",
                      },
                      "& .MuiIconButton-root": {
                        justifyContent: "flex-end",
                      },
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>

            {/* for callBack To */}
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              xl={4}
              // sx={{ padding: "10px" }}
            >
              <InputLabel>Call Back To</InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DateTimePicker"]}>
                  <DatePicker
                    // label="Call Back To:"
                    id="datetime-local"
                    format="MM/DD/YYYY"
                    views={["year", "month", "day"]}
                    ampm
                    // closeOnSelect={false}
                    variant="outlined"
                    //autoOk={false}
                    value={callBackToDate ? dayjs(callBackToDate) : null}
                    onChange={(date) => setCallBackToDate(date)}
                    slotProps={{
                      textField: {
                        disabled: true,
                        variant: "outlined",
                      },
                      actionBar: {
                        actions: ["clear"],
                      },
                      popper: { placement: "auto-start" },
                    }}
                    sx={{
                      "&.MuiTextField-root .MuiFormLabel-root": {
                        background: "var(--mainTheme200) !important",
                        fontSize: "14px",
                      },
                      "& .MuiOutlinedInput-root": {
                        border: "1px solid var(--mainTheme)",
                        fontSize: "13px",
                      },
                      "& .MuiInputBase-input.MuiOutlinedInput-input": {
                        padding: "15px 2px !important",
                        fontSize: "13px",
                      },

                      "&.MuiTextField-root": {
                        minWidth: "1px",
                        paddingLeft: "4px",
                      },
                      "& .MuiIconButton-root": {
                        justifyContent: "flex-end",
                      },
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
            {/* for next outreach from date */}

            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              xl={4}
              // sx={{ padding: "10px" }}
            >
              <InputLabel>Next Outreach From</InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DateTimePicker"]}>
                  <DatePicker
                    //label="Next Outreach From:"
                    id="datetime-local"
                    format="MM/DD/YYYY"
                    views={["year", "month", "day"]}
                    ampm
                    // closeOnSelect={false}
                    variant="outlined"
                    //autoOk={false}
                    value={
                      nextOutReachFromDate ? dayjs(nextOutReachFromDate) : null
                    }
                    onChange={(date) => setNextOutReachFromDate(date)}
                    slotProps={{
                      textField: {
                        disabled: true,
                        variant: "outlined",
                      },
                      actionBar: {
                        actions: ["clear"],
                      },
                      popper: { placement: "auto-start" },
                    }}
                    sx={{
                      "&.MuiTextField-root .MuiFormLabel-root": {
                        background: "var(--mainTheme200) !important",
                        fontSize: "14px",
                      },
                      "& .MuiOutlinedInput-root": {
                        border: "1px solid var(--mainTheme)",
                        fontSize: "13px",
                      },
                      "& .MuiInputBase-input.MuiOutlinedInput-input": {
                        padding: "15px 2px !important",
                        fontSize: "13px",
                      },

                      "&.MuiTextField-root": {
                        minWidth: "1px",
                        paddingLeft: "4px",
                      },
                      "& .MuiIconButton-root": {
                        justifyContent: "flex-end",
                      },
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>

            {/* for next outreach to date */}
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              xl={4}
              // sx={{ padding: "10px" }}
            >
              <InputLabel>Next Outreach To</InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DateTimePicker"]}>
                  <DatePicker
                    // label="Next Outreach To:"
                    id="datetime-local"
                    format="MM/DD/YYYY"
                    views={["year", "month", "day"]}
                    ampm
                    // closeOnSelect={false}
                    variant="outlined"
                    //autoOk={false}
                    value={
                      nextOutReachToDate ? dayjs(nextOutReachToDate) : null
                    }
                    onChange={(date) => setNextOutReachToDate(date)}
                    slotProps={{
                      textField: {
                        disabled: true,
                        variant: "outlined",
                      },
                      actionBar: {
                        actions: ["clear"],
                      },
                      popper: { placement: "auto-start" },
                    }}
                    sx={{
                      "&.MuiTextField-root .MuiFormLabel-root": {
                        background: "var(--mainTheme200) !important",
                        fontSize: "14px",
                      },
                      "& .MuiOutlinedInput-root": {
                        border: "1px solid var(--mainTheme)",
                        fontSize: "13px",
                      },
                      "& .MuiInputBase-input.MuiOutlinedInput-input": {
                        padding: "15px 2px !important",
                        fontSize: "13px",
                      },

                      "&.MuiTextField-root": {
                        minWidth: "1px",
                        paddingLeft: "4px",
                      },
                      "& .MuiIconButton-root": {
                        justifyContent: "flex-end",
                      },
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>

            {/* for Screening */}
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              xl={4}
              // sx={{ padding: "10px" }}
            >
              <InputLabel>Screening</InputLabel>
              <Autocomplete
                multiple
                clearOnBlur
                id="program-autocomplete"
                style={{ marginTop: "7px", marginLeft: "0px" }}
                options={screeningOptions}
                getOptionLabel={(option) => option}
                getOptionSelected={(option, value) => option === value}
                value={selectedScreening}
                //inputValue={programInputValue}
                onChange={(event, newValue) => {
                  // setProgramInputValue(newValue);
                  handleChangeScreening(newValue);
                }}
                freeSolo
                renderInput={(params) => (
                  <TextField
                    {...params}
                    //label="Screening:"
                    className="inputAddMember"
                    required={true}
                    //   onChange={(e) => {
                    //   //  setProgramInputValue(e.target.value);
                    //   }}
                  />
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      key={option}
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ))
                }
              />
            </Grid>
            {/* for screenIha */}
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              xl={4}
              // sx={{ padding: "10px" }}
            >
              <InputLabel>Screening IHA</InputLabel>
              <Autocomplete
                multiple
                clearOnBlur
                id="program-autocomplete"
                style={{ marginTop: "7px", marginLeft: "0px" }}
                options={screeningIHAOptions}
                getOptionLabel={(option) => option?.value}
                getOptionSelected={(option, value) => option.id === value}
                value={selectedScreeningIha}
                //inputValue={programInputValue}
                onChange={(event, newValue) => {
                  // setProgramInputValue(newValue);
                  handleChangeScreeningIha(newValue);
                }}
                freeSolo
                renderInput={(params) => (
                  <TextField
                    {...params}
                    // label="Screening IHA:"
                    className="inputAddMember"
                    required={true}
                    //   onChange={(e) => {
                    //   //  setProgramInputValue(e.target.value);
                    //   }}
                  />
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      key={option.id}
                      label={option.value}
                      {...getTagProps({ index })}
                    />
                  ))
                }
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button sx={dialogBtn} onClick={handleClose}>
            Cancel
          </Button>
          <Button
            sx={dialogBtn}
            className="dialogBtn"
            onClick={handleApplyFilter}
          >
            Apply
          </Button>
          <Button
            sx={dialogBtn}
            className="dialogBtn reset-btn"
            onClick={handleClear}
          >
            Clear
          </Button>
        </DialogActions>
      </Dialog>
      <Toaster
        open={toasterOpen}
        severity={toasterSeverity}
        onClose={onCloseToaster}
        message={toasterMessage}
      />
      {isLoading && <Loader />}
    </div>
  );
}

export default SchedulerFilterComp;
