import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import MemberUrgentReferralForm from "./MemberUrgentReferralForm";
import MemberNonUrgentReferralForm from "./MemberNonUrgentReferralForm";
import MemberCriticalReferralForm from "./MemberCriticalReferralForm";
import MinimizeIcon from "@mui/icons-material/Minimize";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

const BootstrapDialog = styled(Dialog)(({ theme, dialogWidth }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  // '& .MuiDialogActions-root': {
  //   padding: theme.spacing(1),
  // },
  "& .MuiPaper-root": {
    width: dialogWidth || "80%", // Change this to the desired width
    maxWidth: "none", // Prevents the dialog from shrinking
  },
}));

const BootstrapDialogTitle = ({
  children,
  onClose,
  handleMinimize,
  handleMaximize,
  ...other
}) => {
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      <IconButton
        aria-label="minimize"
        onClick={handleMinimize}
        sx={{
          position: "absolute",
          right: 86, // Adjust position
          top: 8,
          color: "#fff",
        }}
      >
        <MinimizeIcon />
      </IconButton>
      <IconButton
        aria-label="maximize"
        onClick={handleMaximize}
        sx={{
          position: "absolute",
          right: 48, // Adjust position
          top: 8,
          color: "#fff",
        }}
      >
        <CheckBoxOutlineBlankIcon />
      </IconButton>
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#fff",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const CustomRadio = styled(Radio)(({ theme }) => ({
  color: "var(--unselectedColor)", // Unchecked color
  "&.Mui-checked": {
    color: "var(--selectedColor)", // Checked color
  },
}));

const Referral = ({
  open,
  onClose,
  responseData,
  selectedID,
  selectedSpecialist,
  patientName,
  assessId,
  selectedUniquMemberId,
  patientMemberId,
  memberName,
  clinicianName,
  qaMemberName,
  initialType,
  referralId,
  fetchReferralMemberData,
  fetchReferralMemberListData,
  memberDob,
  nurseDob,
  isReSubmit,
  primary,
  nursePrimary,
  dateOfReferral,
  nurseDateOfReferral,
  referralQuery,
}) => {
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedType, setSelectedType] = useState(initialType); // Default to urgent (type 1)
  const [dialogWidth, setDialogWidth] = useState("80%");

  const handleMinimize = () => {
    console.log("Minimize icon clicked");
    setDialogWidth("60%");
  };

  const handleMaximize = () => {
    console.log("Maximize icon clicked");
    setDialogWidth("80%");
  };

  useEffect(() => {
    // Ensures dialogWidth is updated correctly
    setDialogWidth(dialogWidth);
  }, [dialogWidth]);

  useEffect(() => {
    if (initialType) {
      setSelectedType(initialType);
    }
  }, [initialType]);

  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
  };

  return (
    <BootstrapDialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      dialogWidth={dialogWidth} // Dynamic width
    >
      <BootstrapDialogTitle
        id="customized-dialog-title"
        sx={{
          backgroundColor: "var(--mainTheme900)",
          color: "white",
          textAlign: "center",
          fontSize: "24px",
          fontWeight: "600",
          padding: "6px 24px",
        }}
        onClose={onClose}
        handleMinimize={handleMinimize}
        handleMaximize={handleMaximize}
      >
        Case Management Referral Form
        <Typography
          sx={{
            fontSize: "20px",
            textAlign: "left",
            marginTop: "10px",
          }}
          gutterBottom
        >
          Please check which Category the referral fits in.
        </Typography>
        <FormControl sx={{ display: "flex" }} component="fieldset">
          <RadioGroup
            row
            sx={{ display: "flex", gap: "100px" }}
            // value={selectedValue}
            // onChange={handleRadioChange}
            value={selectedType}
            onChange={(e) => setSelectedType(e.target.value)}
          >
            <FormControlLabel
              //value="URGENT"
              value="1"
              control={<CustomRadio />}
              label="URGENT"
            />
            <FormControlLabel
              //value="NON-URGENT"
              value="2"
              control={<CustomRadio />}
              label="NON-URGENT"
            />
            <FormControlLabel
              // value="Critical Incident Report"
              value="3"
              control={<CustomRadio />}
              label="Critical Incident Report"
            />
          </RadioGroup>
        </FormControl>
      </BootstrapDialogTitle>
      <DialogContent style={{ overflowY: "hidden" }}>
        {selectedType === "1" && (
          <MemberUrgentReferralForm
            responseData={responseData}
            selectedID={selectedID}
            selectedSpecialist={selectedSpecialist}
            patientName={patientName}
            assessId={assessId}
            selectedUniquMemberId={selectedUniquMemberId}
            patientMemberId={patientMemberId}
            memberName={memberName}
            clinicianName={clinicianName}
            qaMemberName={qaMemberName}
            referralId={referralId}
            fetchReferralMemberData={fetchReferralMemberData}
            fetchReferralMemberListData={fetchReferralMemberListData}
            memberDob={memberDob}
            nurseDob={nurseDob}
            isReSubmit={isReSubmit}
            primary={primary}
            nursePrimary={nursePrimary}
            dateOfReferral={dateOfReferral}
            nurseDateOfReferral={nurseDateOfReferral}
            initialType={initialType}
            referralQuery={referralQuery}
          />
        )}
        {selectedType === "2" && (
          <MemberNonUrgentReferralForm
            responseData={responseData}
            selectedID={selectedID}
            selectedSpecialist={selectedSpecialist}
            patientName={patientName}
            assessId={assessId}
            selectedUniquMemberId={selectedUniquMemberId}
            patientMemberId={patientMemberId}
            memberName={memberName}
            clinicianName={clinicianName}
            qaMemberName={qaMemberName}
            referralId={referralId}
            fetchReferralMemberData={fetchReferralMemberData}
            fetchReferralMemberListData={fetchReferralMemberListData}
            memberDob={memberDob}
            nurseDob={nurseDob}
            isReSubmit={isReSubmit}
            primary={primary}
            nursePrimary={nursePrimary}
            dateOfReferral={dateOfReferral}
            nurseDateOfReferral={nurseDateOfReferral}
            initialType={initialType}
            referralQuery={referralQuery}
          />
        )}
        {selectedType === "3" && (
          <MemberCriticalReferralForm
            responseData={responseData}
            selectedID={selectedID}
            selectedSpecialist={selectedSpecialist}
            patientName={patientName}
            assessId={assessId}
            selectedUniquMemberId={selectedUniquMemberId}
            patientMemberId={patientMemberId}
            memberName={memberName}
            clinicianName={clinicianName}
            qaMemberName={qaMemberName}
            referralId={referralId}
            fetchReferralMemberData={fetchReferralMemberData}
            fetchReferralMemberListData={fetchReferralMemberListData}
            memberDob={memberDob}
            nurseDob={nurseDob}
            isReSubmit={isReSubmit}
            primary={primary}
            nursePrimary={nursePrimary}
            dateOfReferral={dateOfReferral}
            nurseDateOfReferral={nurseDateOfReferral}
            initialType={initialType}
            referralQuery={referralQuery}
          />
        )}
      </DialogContent>
      {/* <DialogActions>
        <Button
          sx={{
        backgroundColor: "var(--mainTheme900)",
        color: "white",
        width: "100px",
        '&:hover': {
          backgroundColor: "var(--mainTheme500)", 
        },
      }}
      //  autoFocus
        onClick={onClose}>
          Submit
        </Button>
        <Button 
        sx={{
          backgroundColor: "var(--mainTheme900)",
          color: "white",
          width: "100px",
          '&:hover': {
            backgroundColor: "var(--mainTheme500)",
          },
        }}
        // autoFocus
         onClick={onClose}>
          Cancel
        </Button>
      </DialogActions> */}
    </BootstrapDialog>
  );
};
export default Referral;
