import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Fade,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import FocusCareBg from "../../../../assets/focuscares_bg.jpg";
import { TbLockCog } from "react-icons/tb";
import { FiLogOut } from "react-icons/fi";
import PropTypes from "prop-types";
import { getApi, postApi } from "../../../../utils/axiosApiWrapper";
import ValidationMessage from "../../../../resource_files/ValidationMessage.resx";
import Labels from "../../../../resource_files/Labels.resx";
import Toaster from "../../../toaster/toaster";
export default function UserDropMenu(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [errors, setErrors] = useState(null);
  const [isValid, setIsValid] = useState(false);
  const [toasterOpen, setToasterOpen] = useState(false);
  const [toasterSeverity, setToasterSeverity] = useState("");
  const [toasterMessage, setToasterMessage] = useState("");
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const isValidEmail = (mail) => {
    return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(mail);
  };
  const handleChangePasswordButton = async () => {
    const mail = sessionStorage.getItem("emailId");

    if (!mail) {
      setErrors(ValidationMessage.Email_Required);
      return;
    } else if (!isValidEmail(mail)) {
      setErrors(ValidationMessage.Email_Format_Invalid);
      return;
    }

    try {
      const response = await postApi("/users/updatePassword/change/sendOTP", {
        email: mail, // Use the entered email as receiver's email
      });
      if (response.status === 200) {
        setToasterSeverity(Labels.SUCCESS);
        setToasterMessage(response.data.message);
        setToasterOpen(true);
        sessionStorage.setItem("emailId", mail);
        sessionStorage.setItem("otpType", "changePassword");
        navigate(`/Account/Otp`, {
          state: { username: mail, fromChangePassword: true },
        });
      } else {
        setToasterSeverity(Labels.ERROR);
        setToasterMessage(response.response.data.message);
        setToasterOpen(true);
      }
    } catch (error) {
      setToasterSeverity(Labels.ERROR);
      setToasterMessage(error.message);
      setToasterOpen(true);
    }
  };

  const navigate = useNavigate();

  return (
    <div className="userProfileCard">
      <div
        className="userDetails"
        id="userProfileCardClick"
        onClick={(e) => handleClick(e)}
        style={{
          backgroundColor: open ? "var(--mainTheme600)" : "var(--mainTheme400)",
          borderRadius: open ? "5px 5px 0px 0px" : "8px",
        }}
      >
        <div className="image-profile">
          <img src={FocusCareBg} alt="brand-logo" />
        </div>
        <div className="name-profile">
          <p>Hi, {props.userName}</p>
        </div>
        <div className="icon-dropdown">
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </div>
      </div>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        sx={{
          "& .MuiMenu-paper": {
            borderRadius: "0px 0px 5px 5px",
            width: "13.8%",
          },
          "& .MuiMenuItem-root": {
            backgroundColor: `var(--mainTheme50) !important`,
            padding: "8px",
          },
          "& .MuiMenuItem-root:hover": {
            backgroundColor: `var(--mainTheme150) !important`,
            color: "#000000",
          },
        }}
      >
        <MenuItem onClick={handleChangePasswordButton}>
          <ListItemIcon style={{ minWidth: "25px", fontSize: "20px" }}>
            <TbLockCog />
          </ListItemIcon>
          <ListItemText>Change Password</ListItemText>
        </MenuItem>
        <MenuItem id="logout" onClick={props.handleLogout}>
          <ListItemIcon style={{ minWidth: "25px", fontSize: "20px" }}>
            <FiLogOut />
          </ListItemIcon>
          <ListItemText>Logout</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  );
}

UserDropMenu.propTypes = {
  userName: PropTypes.string,
  handleLogout: PropTypes.func,
};
