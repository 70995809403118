import { Grid, Stack } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import lodash from "lodash";

import "./DynamicForms.css";

export const DFHeading = ({ inputProps }) => {
  return (
    <Stack direction="row">
      <Grid
        item
        xs={12}
        style={{
          backgroundColor: "var(--mainTheme850)",
          // textTransform: "uppercase",
          // borderRadius: "5px 5px 0 0",
          borderRadius: "5px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            margin: "10px",
            color: "white",
            // textTransform: "uppercase",
            fontWeight: "500",
          }}
        >
          {inputProps.label}
        </div>
      </Grid>
    </Stack>
  );
};

DFHeading.propTypes = {
  inputProps: PropTypes.object.isRequired,
};

const dontReRender = (oldProps, newProps) => {
  /*
   
  returns false - if there is a change between oldProps & newProps
  returns true - if there is no change between oldProps & newProps

  console.log(
    JSON.stringify(oldProps),
    JSON.stringify(newProps),
    lodash.isEqual(JSON.stringify(oldProps), JSON.stringify(newProps)),
    "check memo"
  );

  */

  return lodash.isEqual(JSON.stringify(oldProps), JSON.stringify(newProps));
};

export const DFHeadingMemo = React.memo(({ inputProps }) => {
  return (
    <Stack direction="row">
      <Grid
        item
        xs={12}
        style={{
          backgroundColor: "var(--mainTheme850)",
          // textTransform: "uppercase",
          // borderRadius: "5px 5px 0 0",
          borderRadius: "5px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            margin: "10px",
            color: "white",
            // textTransform: "uppercase",
            fontWeight: "500",
          }}
        >
          {inputProps.label}
        </div>
      </Grid>
    </Stack>
  );
}, dontReRender);

DFHeadingMemo.propTypes = {
  inputProps: PropTypes.object.isRequired,
};
