import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Box from "@mui/material/Box";
import React, { useContext, useEffect, useState } from "react";
import AddClinician from "../../../components/Dashboard/ViewClinicians/AddClinician/AddClinician";
import ViewClinciansComponent from "../../../components/Dashboard/ViewClinicians/ViewClinicians";
import AddDialog from "../../../components/Dialog/Dialog";
import EditClinicianDialog from "../../../components/Dialog/EditClinicianDialog/EditClinicianDialog";
import MaterialTable from "../../../components/MaterialTable";
import Search from "../../../components/search";
import AlertContext from "../../../contexts/AlertContext";
import { getApi, postApi } from "../../../utils/axiosApiWrapper";
import "../ViewClinicians/ViewClinicians.css";
import "./ViewClinicians.css";
import { ClinicianFilterDialogs } from "../../../components/Dashboard/Dialog/Dialog";
import Loader from "../../../components/Loader/Loader";
import { SelectDropdown } from "../../../components/select/select";
import { InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import Toaster from "../../../components/toaster/toaster";
import _ from "lodash";
import moment from "moment";

const ViewClincians = ({
  hraStatusOptions,
  visitTypeOptions,
  clinicianData,
}) => {
  const filterDropdown = ["First name", "Last name"];
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [componentToShow, setComponentToShow] = useState("");
  const alertContext = useContext(AlertContext);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedClinicianData, setSelectedClinicianData] = useState(null);
  const [apiData, setApiData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [page, setPage] = useState(0);
  const [isSearchEnabled, setIsSearchEnabled] = useState(false);
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [filterOption, setFilterOption] = useState("");
  const [selectedFilter, setSelectedFilter] = useState(filterDropdown[0]);
  const [isFirstNameSelected, setIsFirstNameSelected] = useState(true);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("defaultColumnName");
  const [toasterMessage, setToasterMessage] = useState("");
  const [toasterSeverity, setToasterSeverity] = useState("");
  const [toasterOpen, setToasterOpen] = useState(false);

  const fields = [
    {
      id: "Action",
      numeric: false,
      label: "Edit",
      type: "action",
    },
    {
      id: "licenseNumber",
      numeric: "false",
      label: "Identification",
      type: "text",
    },
    {
      id: "firstName",
      numeric: "false",
      label: "First Name",
      type: "text",
    },
    {
      id: "lastName",
      numeric: "false",
      label: "Last Name",
      type: "text",
    },
    {
      id: "status",
      numeric: "false",
      label: "Status",
      type: "text",
    },
  ];
  const clinicianTableHeaders = [
    { id: "Action", label: "Edit" },
    { id: "Identification", label: "Identification" },
    { id: "FirstName", label: "First Name" },
    { id: "LastName", label: "Last Name" },
    { id: "Status", label: "Status" },
  ];

  const handleClinicianEditClick = (rowData) => {
    if (rowData?._id) {
      setSelectedClinicianData(rowData._id);
      setSelectedClinicianData({
        ...rowData,
        //...
      });
      setComponentToShow("clinician");
      alertContext.setEditClinicianDialog(true);
      setEditMode(true);
    } else {
      return;
    }
  };

  const handleEditCloseDialog = () => {
    setOpenEditDialog(false);
  };

  const handleFilter = async () => {
    try {
      const filterData = alertContext?.filterDialogData;
      const params = {
        page: page + 1,
        limit: rowsPerPage,
      };

      if (!_.isEmpty(filterData?.clients)) {
        params.clients = filterData?.clients;
      }
      if (!_.isEmpty(filterData?.userstatus)) {
        params.isactive = filterData?.userstatus;
      }

      if (!_.isEmpty(filterData?.expiryFromDate)) {
        params.expiryFromDate = moment(filterData?.expiryFromDate).format(
          "YYYY-MM-DD",
        );
      }
      if (!_.isEmpty(filterData?.expiryToDate)) {
        params.expiryToDate = moment(filterData?.expiryToDate).format(
          "YYYY-MM-DD",
        );
      }

      const response = await getApi("/clinicians/getclicnicianbyfilter", {
        params,
      });
      const { data } = response.data;
      if (data && data.length > 0) {
        setApiData(data);
      } else {
        setApiData([]);
      }
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Fetch clinician data when the component mounts
    fetchClinicianData();
  }, [page, rowsPerPage]);

  useEffect(() => {
    if (!_.isNull(alertContext?.filterDialogData)) {
      handleFilter();
    }
  }, [alertContext?.filterDialogData]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleResetSearch = async () => {
    setLoading(true);
    setSearchValue("");
    setIsSearchEnabled(false);
    setFilterOption("");
    setSelectedFilter("");
    // await fetchClinicianData();
    const response = await postApi(
      `/clinicians/paginate?page=${page + 1}&limit=${rowsPerPage}`,
      {},
    );
    setTotalRowCount(response.data.count);
    const { data } = response.data;
    if (data && data.length > 0) {
      setApiData(data);
    } else {
      setApiData([]);
    }
    setLoading(false);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const fetchClinicianData = async (sortByField, sortByOrder) => {
    setLoading(true);
    try {
      if (
        filterOption &&
        (filterOption.toLowerCase() === "first name" ||
          filterOption.toLowerCase() === "last name")
      ) {
        const searchField =
          filterOption.toLowerCase() === "first name"
            ? "firstName"
            : "lastName";

        let requestBody = {};
        if (searchValue.trim() !== "") {
          requestBody = {
            searchField,
            searchQuery: searchValue,
          };
        }
        if (!_.isEmpty(searchValue) && searchValue.length < 3) {
          setToasterOpen(true);
          setToasterSeverity("error");
          setToasterMessage("Minimum 3 characters required to search!");
        } else {
          const response = await postApi(
            `/clinicians/paginate?page=${page + 1}&limit=${rowsPerPage}`,
            requestBody,
          );
          setTotalRowCount(response.data.count);
          const { data } = response.data;
          if (data && data.length > 0) {
            setApiData(data);
          } else {
            setApiData([]);
          }
          setLoading(false);
        }
      } else {
        const response = await postApi(
          `/clinicians/paginate?page=${page + 1}&limit=${rowsPerPage}`,
          {
            sortOrder: sortByOrder === "asc" ? 1 : -1,
            sortField: sortByField,
          },
        );
        setOrderBy(sortByField);
        setOrder(sortByOrder);
        setTotalRowCount(response.data.count);
        const { data } = response.data;
        if (data && data.length > 0) {
          setApiData(data);
        } else {
          setApiData([]);
        }
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const toggleOpen = () => {
    alertContext.setFilterDialog(true);
  };

  const toggleOpenAdd = () => {
    setComponentToShow("clinician");
    alertContext.setAddDialog(true);
  };
  const onAdd = (formData) => {
    alertContext.setAddDialog(false);
  };
  const toggleOpenFilter = () => {
    alertContext.setFilterDialog(true);
  };

  const handleSorting = (sortByField, sortByOrder) => {
    fetchClinicianData(sortByField, sortByOrder);
  };

  const handleFilterDropdownChange = (event) => {
    const { value } = event.target;
    setSelectedFilter(value);
    setFilterOption(value);
    setIsFirstNameSelected(value === "First name");
  };
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Enter" && searchValue.trim() !== "") {
        e.preventDefault();
        fetchClinicianData();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [searchValue]);
  return (
    <div className="right-content">
      <ClinicianFilterDialogs />

      <div className="filter-amtenties">
        <div className="left-amentities">
          <div className="list-item">
            <AddDialog
              title="Add Clinician"
              componentToShow={componentToShow}
              fetchClinicianData={fetchClinicianData}
            >
              {componentToShow === "clinician" && (
                <AddClinician onAdd={onAdd} />
              )}
            </AddDialog>
            <EditClinicianDialog
              title="Edit Clinician"
              componentToShow={componentToShow}
              open={openEditDialog}
              onClose={handleEditCloseDialog}
              editMode={editMode}
              selectedClinicianData={selectedClinicianData}
              setSelectedClinicianData={setSelectedClinicianData}
              fetchClinicianData={fetchClinicianData}
            />
            <Box
              className="filterBoxViewClinicians"
              onClick={() => toggleOpenAdd()}
            >
              <p>Add Clinician</p>
            </Box>
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <div className="list-item">
            <Box className="filterBoxVA" onClick={toggleOpenFilter}>
              <p>Filter</p>
              <FilterAltIcon sx={{ height: "20px", width: "20px" }} />
            </Box>
          </div>
          <SelectDropdown
            sx={{
              borderRadius: 20,
              //width: "100%",
              height: "40px",
              fontSize: "16px",
              fontWeight: 500,
              marginRight: 1,
              marginLeft: 2,
              "&:hover": {
                backgroundColor: "var(--mainTheme)",
              },
            }}
            title="Select"
            options={filterDropdown}
            className="dropdownMemberList"
            valueClass="dropdownValueMemberList"
            onChange={handleFilterDropdownChange}
            value={filterOption}
          />
          <div className="list-item">
            {/* <Search
              onSearch={(searchInfo) => setSearchValue(searchInfo)}
              onReset={handleResetSearch}
              columns={fields}
              isSearchEnabled={isSearchEnabled}
              setSearchValue={setSearchValue}
              searchValue={searchValue}
            /> */}
            <Box className="searchBoxML">
              <TextField
                placeholder="Search.."
                value={searchValue}
                InputProps={{
                  disableUnderline: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon style={{ marginLeft: "7px" }} />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      {searchValue && (
                        <CancelOutlinedIcon
                          style={{ marginRight: "5px", cursor: "pointer" }}
                          onClick={handleResetSearch}
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
                variant="standard"
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
                // inputRef={inputRef}
              />
            </Box>
          </div>
        </div>
      </div>
      {loading ? (
        <Loader />
      ) : (
        <MaterialTable
          rows={apiData}
          TableBodyFields={fields}
          //  TableSearchValue={searchValue}
          TableHead={clinicianTableHeaders}
          useClinicianHeaders={true}
          buttonLabel="Edit"
          handleButtonClick={(rowData) => handleClinicianEditClick(rowData)}
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={totalRowCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          handleSorting={handleSorting}
          selectedHeader={orderBy}
          selectedSorting={order}
        />
      )}
      <Toaster
        open={toasterOpen}
        onClose={() => setToasterOpen(false)}
        severity={toasterSeverity}
        message={toasterMessage}
      />
    </div>
  );
};

export default ViewClincians;
